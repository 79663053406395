import Reflux from 'reflux'

const appcuesMainJsUrl = '//fast.appcues.com/34471.js'
const appcuesWidgetJsUrl = '//fast.appcues.com/widget-bundle.js'
const appcuesWidgetCssUrl = '//fast.appcues.com/widget.css'

// Must be specifically enabled before any calls will work
let enabled = false

// Instantiated widget
let widgetInstance = null

function loadScript(src) {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.src = src
    script.async = true
    script.onload = resolve
    script.onerror = reject
    document.head.appendChild(script)
  })
}

let getAppcues = function() {
  // First call: load scripts async
  // Main script is required and will throw if it can't load; widget script we'll treat as optional
  console.info('Loading Appcues scripts')
  const promise = Promise.all([
    loadScript(appcuesMainJsUrl).then(
      () => {
        if (!window.Appcues) {
          enabled = false
          throw new Error('Appcues script loaded but Appcues object not found')
        }
      },
      () => {
        enabled = false
        throw new Error('Failed loading Appcues script')
      }
    ),
    loadScript(appcuesWidgetJsUrl).then(
      () => {
        if (!window.AppcuesWidget) {
          console.error('AppcuesWidget script loaded but AppcuesWidget object not found')
          return null
        }
      },
      () => {
        console.error('Failed loading AppcuesWidget script')
        return null
      }
    )
  ])

  // Load widget CSS too but don't block on it
  const link = document.createElement('link')
  link.rel = 'stylesheet'
  link.href = appcuesWidgetCssUrl
  document.head.appendChild(link)

  // Replace getAppcues function with one that returns a new promise for the current
  // Appcues objects installed on `window`. We can't just reuse what we found there
  // at the time the main script is loaded because that's just a stub that's overwritten
  // after some additional scripts are loaded in the background.
  getAppcues = () => promise.then(() => [window.Appcues, window.AppcuesWidget])

  return getAppcues()
}



const AppcuesActions = Reflux.createActions([
  'setUser',
  'setPage',
  'initWidget'
])

AppcuesActions.setUser.preEmit = (userObj) => {
  // For now we'll only enable loading the Appcues script for users in the "Test Drive"
  // mode, or those with an explicit localStorage key set.
  enabled = userObj.is_test_drive || !!localStorage.enableAppcues

  if (enabled) {
    getAppcues().then(([Appcues, AppcuesWidget]) => {
      console.info('Setting Appcues user data')
      Appcues.identify(
        userObj.id, // Unique identifier for current user
        {
          name: `${userObj.firstname} ${userObj.lastname}`,
          email: userObj.email, // Current user's email
          created_at: userObj.created_at ? +new Date(userObj.created_at) / 1000 : undefined, // Unix timestamp of user signup date

          // Additional user properties...
          customer_id: userObj.customer_id
      })
      // (Docs explicitly say don't call Appcues.page() here)

      // Hook up widget
      if (AppcuesWidget) {
        widgetInstance = AppcuesWidget(Appcues.user())
      }
    })
  } else {
    console.info('Appcues not enabled; script will not be loaded')
  }
}

AppcuesActions.setPage.preEmit = () => {
  if (enabled) {
    getAppcues().then(([Appcues, AppcuesWidget]) => {
      console.info('Sending Appcues page change')
      Appcues.page()
    })
  }
}

AppcuesActions.initWidget.preEmit = (elementId) => {
  if (enabled) {
    getAppcues().then(() => {
      // TODO possible race condition here?
      if (widgetInstance) {
        widgetInstance.init('#' + elementId, {
          // Optionally specify the position of the content relative to the widget icon.
          // Possible values: "center" (default; alias "bottom", "bottom-center"), "left" (alias "bottom-left"),
          //   "right" (alias "bottom-right"), "top" (alias "top-center"), "top-left", "top-right"
          position: "left",

          // Optionally add a header and/or footer.
          // header: "<h1>Tutorials</h1>",
          // footer: "<p>Your footer here</p>"
        })
      }
    })
  }
}

export default AppcuesActions

import _ from 'lodash'
import T from 'prop-types'
import React from 'react'
import {scaleLinear} from 'd3-scale'

function _setNonScalingStroke (el) {
  if (!window._pwCompatibility.supportsNonScalingStroke) {
    return
  }

  if (el && el.setAttribute) {
    el.setAttribute('vector-effect', 'non-scaling-stroke')
  }
}

export default class extends React.Component {
  static displayName = "AnimatedPath";

  static propTypes = {
    animationDuration: T.number, // ms
    animationOffset: T.number, // ms
    circleFilterId: T.string,
    d: T.string.isRequired,
    dotInterval: T.number,
    dotRadius: T.number,
    // scale: T.number
    taperStroke: T.bool, // Taper animated dot gradient at either end of the path. dotRadius used as maximum (center) value
    className: T.string
  };

  static defaultProps = {
    animationDuration: 500,
    animationOffset: 0,
    dotInterval: 1,
    dotRadius: 0.5,
    taperStroke: false
  };

  state = {
    pathDots: [],
    pathLength: 1000
  };

  UNSAFE_componentWillMount() {
    this._needsPoints = true
    this._isUnmounted = false
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !_.isEqual(nextProps, this.props) || !_.isEqual(nextState, this.state)
  }

  componentWillUnmount() {
    this._isUnmounted = true
  }

  _onPathRef = (pathEl) => {
    _setNonScalingStroke(pathEl)
    if (pathEl && this._needsPoints && !this._isUnmounted) {
      this._pathLength = pathEl.getTotalLength()
      this._generatePathDots(pathEl)
    }
  };

  _generatePathDots = (pathEl) => {
    let {dotInterval, dotRadius, taperStroke, animationDuration, animationOffset} = this.props
    let numDots = Math.ceil(this._pathLength / dotInterval)
    let pathDotTaperScale = null
    this._pathDots = []

    if (taperStroke) {
      pathDotTaperScale = scaleLinear()
        .domain([0, numDots / 2, numDots])
        .range([0.5, 1, 0.5])
    }

    for (var i = 0; i < numDots; i++) {
      let dotLen = i * dotInterval
      let {x, y} = pathEl.getPointAtLength(dotLen)
      this._pathDots.push({
        x, y,
        scale: taperStroke ? pathDotTaperScale(i) : 1,
        r: dotRadius,
        delay: Math.round(animationOffset + (i * animationDuration / numDots)) + 'ms'
      })
    }
    this._needsPoints = false
    this.setState({
      pathLength: this._pathLength,
      pathDots: this._pathDots
    })
  };

  render() {
    let {state, props} = this
    return (
      <div className={ `animated_path ${ props.className }` }>
        <svg
          height="100%"
          width="100%"
        >
          <path
            className={ 'compute_path' }
            d={ props.d }
            ref={ this._onPathRef }
          />
        </svg>
        <div className="animated_path_dots_ct">
        {
          _.map(state.pathDots, (dot, i) => {
            let SCALE_FACTOR = 10
            let r = dot.r * SCALE_FACTOR
            return (
              <div
                className="animated_path_dot"
                key={ i }
                style={ {
                  height: r * 2,
                  width: r * 2,
                  animationDelay: dot.delay,
                  WebkitAnimationDelay: dot.delay,
                  animationDuration: props.animationDuration + 'ms',
                  WebkitAnimationDuration: props.animationDuration + 'ms',
                  transform: `translate(${ dot.x - r }px,${ dot.y - r }px) scale(${ dot.scale / SCALE_FACTOR * 2 })`,
                  WebkitTransform: `translate(${ dot.x - r }px,${ dot.y - r }px) scale(${ dot.scale / SCALE_FACTOR * 2 })`
                } }
              />
            )
          })
        }
        </div>
      </div>
    )
  }
}

import React from 'react'
import {TextOverflow, LoadingValue} from 'react-base'
import ThreatLevel from './ThreatLevel'
import UrlValue from 'components/values/UrlValue'
import IpValue from 'components/values/IpValue'

export default props => {
  const {queryValue, data} = props
  const ipAddresses = data('threat.ipAddresses')

  return <React.Fragment>
    <h4>
      <TextOverflow>
        <UrlValue
          noIntelCard
          url={queryValue}
          menuClassName="light"
          menuAlign="bottom"
        />
      </TextOverflow>
    </h4>

    <h5>
      {ipAddresses.isLoading()
        ? <span>IP Address: <LoadingValue /></span>
        : _.isEmpty(ipAddresses.valueOf())
          ? 'No Current IP Address'
          : ipAddresses.valueOf().slice(0, 6).reduce((arr, ip, i) => {
            if (i > 0) arr.push(', ')
            arr.push(
              <IpValue ip={ip} key={i} menuClassName="light" />
            )
            return arr
          }, [])
      }
    </h5>

    <ThreatLevel data={data} />
  </React.Fragment>
}

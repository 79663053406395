import React from 'react'
import T from 'prop-types'
import {
  MenuItem,
  StoreProvider
} from 'react-base'
import PreferencesActions from 'actions/PreferencesActions'
import PreferencesStore from 'stores/PreferencesStore'
import {
  THEME_OPTIONS,
  ZOOM_OPTIONS
} from 'pwConstants'

const themeOptionIds = Object.keys(THEME_OPTIONS)
const zoomOptionIds = Object.keys(ZOOM_OPTIONS)
const setPref = (prefName, newValue, e) => {
  e.stopPropagation()
  PreferencesActions.setSinglePreference(prefName, newValue, 'user')
}

const AccessibilityWidget = ({preferences}) => {
  if (!preferences) {
    return null
  }
  const {
    theme,
    zoomBaselinePx
  } = preferences

  return (
    <div className="meta_menu">
      <h3>Visibility Preferences</h3>
      <h4>Text Size / Zoom Level</h4>
      <ul>
        {zoomOptionIds.map((option, i) => {
          const display = ZOOM_OPTIONS[option]
          return (
            <MenuItem
              args={['zoomBaselinePx', option]}
              active={option === zoomBaselinePx}
              key={option}
              onClick={setPref}
            >
              <span style={{fontSize: `${option}px`}}>{display}</span>
            </MenuItem>
          )
        })}
      </ul>
      <div>
        <h4>Color Theme</h4>
        <ul>
          {themeOptionIds.map((id, i) => {
            const display = THEME_OPTIONS[id]
            return (
              <MenuItem
                args={['theme', id]}
                active={id === theme}
                key={id}
                onClick={setPref}
              >{display}</MenuItem>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

AccessibilityWidget.propTypes = {
  preferences: T.object
}

export default props => (
  <StoreProvider store={PreferencesStore}>
    <AccessibilityWidget {...props} />
  </StoreProvider>
)
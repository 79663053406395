import Reflux from 'reflux'

import CommonViewActions from 'actions/CommonViewActions'

let _state = {
  isLive: true,
  currentTime: Date.now(),
  dvrTime: Date.now(),
  loginDuration: 0,
  loginTime: Date.now()
}

export default Reflux.createStore({
  listenables: CommonViewActions.Time,

  init() {
    this._tick() //Start internal timer

  },

  getInitialState() {
    return _state
  },

  _notify() {
    this.trigger(_state)
  },

  _queueNotify() {
    clearTimeout(this._queueNotifyTimer)
    this._queueNotifyTimer = setTimeout(this._notify.bind(this), 100)
  },

  onStop() {
    clearTimeout(this.beat)
  },

  onUpdateDvr(newDvrTime) {
    if (newDvrTime) {
      _state.isLive = false
      _state.dvrTime = +newDvrTime
      this._notify()
    }
  },

  onStartLive() {
    _state.isLive = true
    _state.dvrTime = Date.now()
    this._notify()
  },

  onSetLoginTime(newLoginTime) {
    if (newLoginTime) {
      _state.loginTime = +newLoginTime
      _state.loginDuration = _state.currentTime - +newLoginTime
      this._notify()
    }
  },

  _tick() {
    clearTimeout(this.beat)
    this.beat = setTimeout(() => {
      requestAnimationFrame(() => {
        let now = Date.now()
        _state.currentTime = now
        if (_state.isLive) {
          _state.dvrTime = now
        }
        _state.loginDuration = now - _state.loginTime
        this._notify()
        this._tick()
      })
    }, 1000)
  }
})

import React from 'react'
import T from 'prop-types'
import { Helmet } from 'react-helmet'

// Load image paths from webpack bundler
import favicon from 'ui-theme/src/images/app-icons/favicon.ico'
import favicon32 from 'ui-theme/src/images/app-icons/favicon-32x32.png'
import favicon16 from 'ui-theme/src/images/app-icons/favicon-16x16.png'
import appleTouchIcon from 'ui-theme/src/images/app-icons/apple-touch-icon.png'
import safariPinnedTab from 'ui-theme/src/images/app-icons/safari-pinned-tab.svg'
import msIcon144 from 'ui-theme/src/images/app-icons/mstile-144x144.png'

// These icons referenced in root/manifest.json (output via build/renderTemplates.js)
import "ui-theme/src/images/app-icons/android-chrome-192x192.png"
import "ui-theme/src/images/app-icons/android-chrome-512x512.png"

// These icons referenced in root/browserconfig.xml (output via templates/browserconfig.xml.njk)
import "ui-theme/src/images/app-icons/mstile-70x70.png"
import "ui-theme/src/images/app-icons/mstile-150x150.png"
import "ui-theme/src/images/app-icons/mstile-310x310.png"
import "ui-theme/src/images/app-icons/mstile-310x150.png"







import getConfig from 'utils/uiConfig'

// // Entry point for Webpack sass bundling

const nocache = '?v=vz1'

export default class Head extends React.PureComponent {
  static displayName = 'Head'

  static propTypes = {
    routeName: T.string
  }

  render() {
    const {
      routeName
    } = this.props

    const title = `${routeName ? `${routeName} | ` : ''}${getConfig().brandedProductName}`

    return (
      <Helmet>
        <title>{title}</title>
        <link rel="apple-touch-icon" sizes="180x180" href={appleTouchIcon + nocache} />
        <link rel="icon" type="image/png" sizes="32x32" href={favicon32 + nocache} />
        <link rel="icon" type="image/png" sizes="16x16" href={favicon16 + nocache} />
        <link rel="manifest" href="/manifest.json" />
        <link rel="mask-icon" href={safariPinnedTab + nocache} color="#262626" />
        <link rel="shortcut icon" href={favicon + nocache} />
        <meta name="apple-mobile-web-app-title" content="Network Detection and Response" />
        <meta name="application-name" content="Network Detection and Response" />
        <meta name="msapplication-TileColor" content="#262626" />
        <meta name="msapplication-TileImage" content={msIcon144 + nocache} />
        <meta name="msapplication-config" content="/browserconfig.xml" />
        <meta name="theme-color" content="#262626" />
      </Helmet>
    )
  }
}

import React from 'react'
import T from 'prop-types'
import {
  DimensionAware,
  CircleLoader,
  ErrorState
} from 'react-base'
import IT from 'react-immutable-proptypes'
import {
  scaleLinear
} from 'd3-scale'
import PerceptiveProfileGraphSingle from './PerceptiveProfileGraphSingle'
import {
  PPC_SHAPE
} from 'constants/captureProfiles'


class PerceptiveProfileGraph extends React.PureComponent {
  static displayName = 'PerceptiveProfileGraph'

  static propTypes = {
    monthData: IT.map,
    weekData: IT.map,
    dayData: IT.map,
    recentData: IT.map,

    currentProfile: PPC_SHAPE,
    subscriptionKeys: IT.map,
    data: IT.map,

    error: T.any,
    isLoading: T.bool,

    isMaximized: T.bool,

    // From DimensionAware
    height: T.number,
  }

  static defaultProps = {
    height: 10
  }

  state = {
    yScale: null
  }

  componentDidMount() {
    this._updateYScale()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.height !== this.props.height || nextProps.currentProfile !== this.props.currentProfile) {
      this._updateYScale(nextProps)
    }
  }

  _updateYScale = props => {
    const {
      height,
      currentProfile
    } = props || this.props
    if (!currentProfile) {
      return
    }
    const currentProfileJS = currentProfile.toJS()
    const _domain = [0]
    const _range = [0]

    for (let i = 0, iLen = currentProfileJS.profiles.length; i < iLen; i++) {
      const profileState = currentProfileJS.profiles[i]
      _domain.push(profileState.id)
      _range.push((profileState.backbufferHighThreshold / 100) * height)
    }

    this.setState({
      yScale: scaleLinear()
        .domain(_domain)
        .range(_range)
    })
  }

  render() {
    const {
      error,
      isLoading,
      currentProfile,
      data,
      subscriptionKeys,
      isMaximized
    } = this.props
    const {
      yScale
    } = this.state
    const currentProfileJS = currentProfile && currentProfile.toJS()

    // const globalMax = null
    // _.max(
    //   [monthData, weekData, dayData, recentData].map(t => t ? t.get('maxValue') : 0)
    // )

    const monthSub = subscriptionKeys && subscriptionKeys.get('month')
    const weekSub = subscriptionKeys && subscriptionKeys.get('week')
    const daySub = subscriptionKeys && subscriptionKeys.get('day')
    const recentSub = subscriptionKeys && subscriptionKeys.get('recent')

    const monthData = data && data.get(monthSub)
    const weekData = data && data.get(weekSub)
    const dayData = data && data.get(daySub)
    const recentData = data && data.get(recentSub)


    return (
      <div className="perceptive_graph">
        <div className="month_wrap">
          {monthData &&
            <PerceptiveProfileGraphSingle
              data={monthData}
              subscription={monthSub}
              className="month_ppc_graph"
              yScale={yScale}
              isMaximized={isMaximized}
              currentProfile={currentProfile}
            />
          }
        </div>
        <div className="week_wrap">
          {weekData &&
            <PerceptiveProfileGraphSingle
              data={weekData}
              subscription={weekSub}
              className="week_ppc_graph"
              yScale={yScale}
              isMaximized={isMaximized}
              currentProfile={currentProfile}
            />
          }
        </div>
        <div className="day_wrap">
          {dayData &&
            <PerceptiveProfileGraphSingle
              data={dayData}
              subscription={daySub}
              className="day_ppc_graph"
              yScale={yScale}
              isMaximized={isMaximized}
              logScale
              currentProfile={currentProfile}
            />
          }
        </div>
        <div className="recent_wrap">
          {recentData &&
            <PerceptiveProfileGraphSingle
              data={recentData}
              subscription={recentSub}
              className="recent_ppc_graph"
              yScale={yScale}
              isMaximized={isMaximized}
              xScaleExponent={2}
              currentProfile={currentProfile}
            />
          }
        </div>

        <div className="perceptive_graph_bg_scale">
          {currentProfileJS && yScale &&
            <div>
              {currentProfileJS.profiles.map(profileState => {
                const {
                  name,
                  id
                } = profileState
                return (
                  <div
                    key={id}
                    className={`ppc_profile_state state_${name.toLowerCase()}`}
                    style={ {
                      bottom: `${yScale(id)}px`
                    } }
                  >
                    <div className="state_label">{id/*name*/}</div>
                  </div>
                )
              })}
            </div>
          }
        </div>
        <CircleLoader loading={isLoading} overlay />
        <ErrorState error={error} />
      </div>
    )
  }
}

export default DimensionAware(PerceptiveProfileGraph)

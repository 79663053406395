import Reflux from 'reflux'

export default Reflux.createActions([
  'setSinglePreference',
  'setPreferences', // Set new preference value
  'setViewedHint', // (string hintKey)
  'reset', // (string PreferenceGroup, string PreferenceKey) Reset single preference key
  'resetAll', // (string PreferenceGroup) Reset all preferences for the passed group (user|customer) to default values
  'reload'
])

import _ from 'lodash'
import React from 'react'
import ReactDOM from 'react-dom'
import genericUtil from 'ui-base/src/util/genericUtil'
import {stepFocus} from 'react-base'

let isDebuggingARIA = false
let a11yMountNode

function syncAriaRoleOverlays() {
  if (isDebuggingARIA) {
    // Create mount node if needed
    if (!a11yMountNode) {
      a11yMountNode = document.createElement('div')
      a11yMountNode.className = 'a11y_mount_node'
      document.body.appendChild(a11yMountNode)
    }

    // Find all elements with ARIA roles or equivalent builtin semantics
    const elsWithRole = document.querySelectorAll('[role],button,a[href],input')

    // Create an overlay matching the bounds of each element
    const overlays = _.map(elsWithRole, (el, i) => {
      const rect = el.getBoundingClientRect()
      const tag = el.tagName.toLowerCase()
      let role = el.getAttribute('role')
      if (role) {
        role = 'r:' + role //prefix so we know it's an aria role
      } else {
        role = tag === 'button' || tag === 'input'
          ? `${tag}.${el.type}`
        : tag === 'a'
          ? 'link'
        : '?'
      }
      // TODO hide if clipped by scrolling etc.
      return <div
        key={i}
        className="a11y_overlay"
        style={{
          position: 'fixed',
          top: rect.top,
          left: rect.left,
          width: rect.width,
          height: rect.height
        }}
      >
        <span className="label">{role}</span>
      </div>
    })
    ReactDOM.render(
      <React.Fragment>{overlays}</React.Fragment>,
      a11yMountNode
    )

    // Keep polling until debugging is disabled
    setTimeout(syncAriaRoleOverlays, 500)
  } else {
    ReactDOM.unmountComponentAtNode(a11yMountNode)
  }
}

/**
 * Toggle ARIA role overlays for debugging
 */
window._pwDebugAccessibility = function() {
  isDebuggingARIA = !isDebuggingARIA
  syncAriaRoleOverlays()
}





export const FOCUSABLE_SELECTOR = 'a,button,input,textarea,[tabindex]'



function syncFocusOverlay() {
  let overlay = null

  if (isShowingFocus) {
    // Create mount node if needed
    if (!a11yMountNode) {
      a11yMountNode = document.createElement('div')
      a11yMountNode.className = 'a11y_mount_node'
      document.body.appendChild(a11yMountNode)
    }

    const focusedEl = document.activeElement
    if (focusedEl && focusedEl !== document.body) {
      // Find the element's bounds, honoring clipping by ancestor elements
      let {top, right, bottom, left} = focusedEl.getBoundingClientRect()
      for (let parentEl = focusedEl.parentNode; parentEl && parentEl.tagName; parentEl = parentEl.parentNode) {
        if (getComputedStyle(parentEl).overflow !== 'visible' && parentEl !== document.documentElement) {
          const parentRect = parentEl.getBoundingClientRect()
          left = Math.max(left, parentRect.left)
          top = Math.max(top, parentRect.top)
          right = Math.min(right, parentRect.right)
          bottom = Math.min(bottom, parentRect.bottom)
        }
      }

      if (bottom > top && right > left) {
        // Create overlay matching those bounds
        const outset = 5
        overlay = <div
          className={`a11y_focus_overlay`}
          style={{
            position: 'fixed',
            top: top - outset,
            left: left - outset,
            width: right - left + outset * 2,
            height: bottom - top + outset * 2
          }}
        />
        ReactDOM.render(
          <React.Fragment>{overlay}</React.Fragment>,
          a11yMountNode
        )
      }
    }

    // Keep polling until highlight is disabled
    requestAnimationFrame(syncFocusOverlay)
  }
  if (!overlay) {
    ReactDOM.unmountComponentAtNode(a11yMountNode)
  }
}

let isShowingFocus = false
let a11yHideFocusableTimer = null
function startShowingFocus() {
  isShowingFocus = true
  setTimeout(syncFocusOverlay, 100)
  document.body.classList.add('a11y_showing_focus')
  clearTimeout(a11yHideFocusableTimer)
  a11yHideFocusableTimer = setTimeout(() => {
    isShowingFocus = false
    document.body.classList.remove('a11y_showing_focus')
  }, 1500)
}

function isEventInEditableNode(e) {
  return genericUtil.isNodeInElByFunction(e.target, node =>
    /input|textarea/i.test(node.tagName) || (node.getAttribute && node.getAttribute('contenteditable'))
  )
}

window.addEventListener('keydown', e => {
  const {key, target} = e
  if (key) {
    // Keyboard focus navigation
    if (
      //traversing focus via tab key:
      key === 'Tab'
      //any other navigation-related key, if not within an editable area:
      || (/Enter|Escape|Up|Down|Left|Right|Alt/.test(key) && !isEventInEditableNode(e))
    ) {
      startShowingFocus()
    }
    // Hotkeys, when not in a text field or contenteditable area
    else if (!isEventInEditableNode(e) && /[a-z0-9]/i.test(key)) {
      const matches = document.querySelectorAll(`[data-hotkey="${key.toUpperCase()}"],[data-hotkey="${key.toLowerCase()}"]`)
      if (matches.length > 1) {
        console.warn(`Found ${matches.length} nodes with hotkey decl for ${key}`)
      }
      if (matches[0]) {
        try {
          matches[0].focus()
        } catch(ex) {}
        // if that element couldn't take focus, try to find a descendant that can
        if (document.activeElement !== matches[0]) {
          stepFocus(matches[0], 1)
          startShowingFocus()
        }
      }
    }
  }
})


/* Prototype: holding down Alt key brings all text up to full brightness
window.addEventListener('keydown', e => {
  if (e.key === 'Alt') {
    document.body.classList.add('a11y_highlight_links')
  }
})
window.addEventListener('keyup', e => {
  if (e.key === 'Alt') {
    document.body.classList.remove('a11y_highlight_links')
  }
})
*/

import _ from 'lodash'
import Reflux from 'reflux'
import IntelRuleQuickAddActions from 'actions/IntelRuleQuickAddActions'
import IntelSubscriptionStore from 'stores/intel-management/IntelSubscriptionStore'
import IntelListStore from 'stores/intel-management/IntelListStore'
import UserStore from 'stores/UserStore'
import {
  VALUE_ACTION_OPTION_IDS,
  VALUE_TYPE_OPTIONS
} from 'constants/searchableValueConstants'
import {
  THREAT_TEAM_CID,
  INTEL_BEHAVIOR_OPTION_IDS,
  INTEL_TYPE_OPTIONS,
  INTEL_LISTS_AVAILABILITY_PERMISSION,
  INTEL_LIST_QUICK_ADD_DEFAULT_TAG,
  INTEL_RULE_SAVE_MINUTES,
  INTEL_DEFAULT_TARGET_MODE,
  INTEL_STATE_OPTIONS,
  IDS_INTEL_OVERRIDE_USES_BEHAVIOR_WHITELIST,
  HASH_TYPE_OPTIONS
} from 'constants/intelManagementConstants'
import {
  convertThreatMappingUItoAPI,
  // getQueryParamsFromRuleAPIKey,
  convertRulesSearchAPItoUI,
  convertRulesUItoAPI
} from 'utils/intelManagementUtils'
import CommonViewActions from 'actions/CommonViewActions'
import genericUtil from 'ui-base/src/util/genericUtil'
import AnalyticsActions from 'actions/AnalyticsActions'
import {requestGet, requestPost, requestPut} from 'utils/restUtils'
import {listenToStore} from 'utils/storeUtils'
import getConfig from 'utils/uiConfig'

const SUB_KEY_DELIM = '|'
const LIST_API_KEYS = [
  'id',
  'name',
  'description',
  'vendor'
  // 'forCustomers' // Only with specific permission
]
const DEFAULT_STATE = {
  indicator: null,
  intelRule: {}, // holds threat mappings, rule indicator, description, tags, ruleBehavior, etc.
  intelRuleValidationMsg: null,
  newListName: '',
  ruleBehavior: null,
  ruleType: null,
  saveProgress: 0,
  selectedListId: null,
  sensorIdsNeedSubUpdate: [], // SensorIds that should get their subscriptions updated
  setDefaultListTag: false,
  saveError: null
}

let _state = _.cloneDeep(DEFAULT_STATE)

function getNewIntelFromIndicator (indicator, ruleType) {
  switch (ruleType) {
    // case INTEL_TYPE_OPTIONS.IDS:
    // NEVER supply default/guessed ids sigs
    case INTEL_TYPE_OPTIONS.DOMAIN:
      return { domain: { name: indicator.trim() } }
    case INTEL_TYPE_OPTIONS.URI:
      return { uri: { name: indicator.trim() } }
    case INTEL_TYPE_OPTIONS.IP:
      return { ip: { ip: indicator.trim() } }
    case INTEL_TYPE_OPTIONS.FILEHASH:
      return { file: {
        hash: indicator.trim(),
        hashType: HASH_TYPE_OPTIONS.MD5
      } }
    case INTEL_TYPE_OPTIONS.CERTIFICATE:
      return { cert: {
        hash: indicator.trim(),
        hashType: HASH_TYPE_OPTIONS.SHA1 // Default type used for CertRep observations
      } }
  }
}


export default Reflux.createStore({
  listenables: [IntelRuleQuickAddActions],

  init() {
    listenToStore(this, IntelListStore, this._onIntelListStoreUpdate.bind(this))
  },

  getInitialState() {
    return _state
  },

  _notify() {
    _state.intelRuleValidationMsg = this.validateIntelRule()
    this.trigger(_state)
  },

  _onIntelListStoreUpdate(intelListStoreState) {
    const { selectedListId } = _state
    const { isLoading, lists } = intelListStoreState
    if (!selectedListId && !isLoading && lists.length > 0) {
      for (let i = 0, iLen = lists.length; i < iLen; i++) {
        const list = lists[i]
        if (list._isDefaultTargetList) {
          _state.setDefaultListTag = true
          _state.selectedListId = list.id
          this._notify()
        }
      }
    }
  },

  validateIntelRule() {
    const { threatMappings = {} } = _state.intelRule
    if (
      _state.ruleBehavior === INTEL_BEHAVIOR_OPTION_IDS.BLACKLIST &&
      (_.isEmpty(threatMappings) ||
        !threatMappings.killchainStage ||
        !threatMappings.category ||
        threatMappings.severity === undefined ||
        threatMappings.confidence === undefined)
    ) {
      return `Complete threat mappings are required when adding a Blacklist Intel Rule`
    } else {
      return null
    }
  },

  onReset() {
    _state = _.cloneDeep(DEFAULT_STATE)
    this._notify()
  },

  onInitWizard(action, valueType, indicator) {
    _state.indicator = indicator
    if (action === VALUE_ACTION_OPTION_IDS.BLACKLIST) {
      _state.ruleBehavior = INTEL_BEHAVIOR_OPTION_IDS.BLACKLIST
    }
    else if (action === VALUE_ACTION_OPTION_IDS.WHITELIST) {
      _state.ruleBehavior = INTEL_BEHAVIOR_OPTION_IDS.WHITELIST
    }

    switch (valueType) {
      case VALUE_TYPE_OPTIONS.IP:
        _state.ruleType = INTEL_TYPE_OPTIONS.IP
        break
      case VALUE_TYPE_OPTIONS.URL:
        _state.ruleType = INTEL_TYPE_OPTIONS.URI
        break
      case VALUE_TYPE_OPTIONS.DOMAIN:
        _state.ruleType = INTEL_TYPE_OPTIONS.DOMAIN
        break
      case VALUE_TYPE_OPTIONS.FILEHASH:
        _state.ruleType = INTEL_TYPE_OPTIONS.FILEHASH
        break
      case VALUE_TYPE_OPTIONS.CERTIFICATE:
        _state.ruleType = INTEL_TYPE_OPTIONS.CERTIFICATE
        break
      case VALUE_TYPE_OPTIONS.PAYLOAD:
        _state.ruleType = INTEL_TYPE_OPTIONS.IDS
        break
      default:
        // None
        break
    }

    this._notify()
  },

  _loadSourceRule(indicator) {
    const query = encodeURIComponent(
      [
        `ruleType:${_state.ruleType}`,
        `key:"${indicator}"`
      ].join(' AND ')
    )

    return Promise.all([
      requestGet(
        'quick_add_get_ids_source_rule',
        `intel/lists/rules?q=${query}&sort=mode%20desc&rows=${2}&start=0`
      ),
      requestGet(
        'quick_add_get_ids_source_rule_history_fallback',
        `intel/lists/rules/history?q=${query}${encodeURIComponent(`AND NOT deleted:true`)}&sort=mode%20desc&sort=updated%20desc&rows=${3}&start=0`
      )
    ])
  },

  onSetSelectedListId(listId) {
    _state.selectedListId = listId
    this._notify()
  },

  onSetNewListName(listName) {
    _state.selectedListId = listName.length > 0 ? 'new' : null // pseudoId for this new list
    _state.newListName = listName
    this._notify()
  },

  onToggleSetDefaultListTag() {
    _state.setDefaultListTag = !_state.setDefaultListTag
    this._notify()
  },

  onToggleSensorNeedsSubUpdate(sensorId) {
    const { sensorIdsNeedSubUpdate } = _state
    const newSensorIds = _.clone(sensorIdsNeedSubUpdate)
    if (newSensorIds.indexOf(+sensorId) === -1) {
      newSensorIds.push(+sensorId)
    } else {
      _.pull(newSensorIds, +sensorId)
    }
    this.onSetSensorsNeedSubUpdate(newSensorIds)
  },

  onSetSensorsNeedSubUpdate(newSensorIds) {
    _state.sensorIdsNeedSubUpdate = newSensorIds
    this._notify()
  },

  onUpdateThreatMappings(partialThreatMappings = {}) {
    _.forIn(partialThreatMappings, (value, fieldName) => {
      _.set(_state, `intelRule.threatMappings.${fieldName}`, value)
    })
    this._notify()
  },

  onUpdateDescription(description, validationMsg) {
    if (!validationMsg) {
      _.set(_state, `intelRule.description`, description)
    }
    this._notify()
  },

  onUpdateTags(newTags) {
    _.set(_state, `intelRule.tags`, newTags)
    this._notify()
  },

  onSave() {
    const {
      selectedListId,
      newListName,
      setDefaultListTag,
      ruleBehavior,
      ruleType,
      indicator,
      sensorIdsNeedSubUpdate, // SensorIds that should get their subscriptions updated
      intelRule, // holds threat mappings, rule indicator, description, tags, ruleBehavior, etc.
      intelRuleValidationMsg
    } = _state

    if (intelRuleValidationMsg !== null) {
      return
    }

    const canChangeListAvailability = UserStore.hasPermission(
      INTEL_LISTS_AVAILABILITY_PERMISSION
    )
    const currentCustomerId = UserStore.getCurrentCustomerID()
    let _forCustomers = null

    if (canChangeListAvailability) {
      // If user has this permission, this value is required
      _forCustomers = [currentCustomerId]
      if (currentCustomerId === THREAT_TEAM_CID) {
        // Default all new threat team lists to "private"
        _forCustomers = [THREAT_TEAM_CID]
      }
    }

    const overallDeferred = genericUtil.defer()
    const handleSaveFail = (restError) => {
      overallDeferred.reject(restError)
    }

    // Mega save commence!
    _state.saveProgress = 0
    _state.saveError = null
    this._notify()

    let isChangingDefaultTag = false
    let targetListId = selectedListId

    let allListReqs = [Promise.resolve(null)]

    // 1a: Save new list if needed (with new tag)
    if (selectedListId === 'new') {
      const createData = {
        name: newListName,
        description: '--',
        vendor: '--',
        withTags: setDefaultListTag ? [INTEL_LIST_QUICK_ADD_DEFAULT_TAG] : []
      }
      if (_forCustomers) {
        createData.forCustomers = _forCustomers
      }
      allListReqs = [
        requestPost(`quickadd_list`, 'intel/lists', createData)
      ]
      if (setDefaultListTag) {
        isChangingDefaultTag = true
      }
    } else {
      // 1b: Save existing list with new tag
      const currentList = IntelListStore.getListById(selectedListId)
      const currentListHasTag =
        currentList.tags &&
        currentList.tags.length > 0 &&
        currentList.tags.indexOf(INTEL_LIST_QUICK_ADD_DEFAULT_TAG) !== -1
      let updateData = null
      const keysToRetain = LIST_API_KEYS
      if (canChangeListAvailability) {
        keysToRetain.push('forCustomers')
      }
      if (setDefaultListTag && !currentListHasTag) {
        updateData = _.assign(_.pick(currentList, keysToRetain), {
          addTags: [INTEL_LIST_QUICK_ADD_DEFAULT_TAG]
        })
        isChangingDefaultTag = true
      } else if (!setDefaultListTag && currentListHasTag) {
        updateData = _.assign(_.pick(currentList, LIST_API_KEYS), {
          removeTags: [INTEL_LIST_QUICK_ADD_DEFAULT_TAG]
        })
      }
      if (updateData) {
        allListReqs = [
          requestPut(null, `intel/lists/${selectedListId}`, updateData)
        ]
      }
    }

    if (isChangingDefaultTag) {
      const prevTaggedLists = IntelListStore.getListsWithTag(
        INTEL_LIST_QUICK_ADD_DEFAULT_TAG
      )
      allListReqs.push(
        prevTaggedLists.map(list => {
          return requestPut(
            null,
            `intel/lists/${list.id}`,
            _.assign(_.pick(list, LIST_API_KEYS), {
              removeTags: [INTEL_LIST_QUICK_ADD_DEFAULT_TAG]
            }),
            {
              retry429: true
            }
          )
        })
      )
    }

    const listReq = Promise.all(allListReqs)

    listReq.then(listResponses => {
      _state.saveProgress = 33 // ?
      this._notify()

      const ruleLoadReq = this._loadSourceRule(indicator)

      if (listResponses && listResponses[0]) {
        // Set listId to the actual one from the response
        targetListId = listResponses[0].id
      }

      ruleLoadReq.then(([activeRules, historyRules]) => {
        let ruleSaveReq = null
        let successKey = ''
        // let activeRules = null
        // let historyRules = null
        // if (resp) {
        //   activeRules = resp[0]
        //   historyRules = resp[1]
        // }
        /*ruleType === INTEL_TYPE_OPTIONS.IDS &&*/

        if (
          (activeRules.rules && activeRules.rules.length > 0) ||
          (historyRules.rulesHistory && historyRules.rulesHistory.length > 0)
        ) {
          successKey = 'updated'
          const srcRules =
            activeRules.rules && activeRules.rules.length > 0
              ? activeRules.rules
              : historyRules.rulesHistory
          const convertedToUI = convertRulesSearchAPItoUI(srcRules)
          const newRule = convertedToUI[0]

          newRule.targetMode = INTEL_DEFAULT_TARGET_MODE
          newRule.ruleBehavior = ruleBehavior
          newRule.listId = targetListId
          newRule.created = null
          newRule.updated = null
          newRule.description = intelRule.description || newRule.description
          newRule.tags = intelRule.tags || newRule.tags

          if (ruleType === INTEL_TYPE_OPTIONS.IDS && !IDS_INTEL_OVERRIDE_USES_BEHAVIOR_WHITELIST) {
            // Old method of overrides, setting status:disabled -- inly for payload/ids
            newRule.status = INTEL_STATE_OPTIONS.DISABLED
          }
          else {
            newRule.status = INTEL_STATE_OPTIONS.ENABLED
          }

          // Upsert
          ruleSaveReq = requestPost(
            'quickadd_rule_ids_copy',
            `intel/lists/${targetListId}/rules/update`,
            convertRulesUItoAPI([newRule])
          )
        }
        else {
          successKey = 'created'

          if (ruleType === INTEL_TYPE_OPTIONS.IDS) {
            // Payload rules CANNOT be copied without a full source rule.
            overallDeferred.reject({
              heading: 'Error saving new Intel Rule',
              body: `Unable to find source IDS/Payload rule for Intel Key: "${indicator}"`,
            })
            return
          }

          // 2: Save rule with specified mappings etc., with current ruleBehavior
          ruleSaveReq = requestPost(
            `quickadd_rule`,
            `intel/lists/${targetListId}/rules/create`,
            {
              threatMapping:
                ruleBehavior === INTEL_BEHAVIOR_OPTION_IDS.BLACKLIST
                  ? convertThreatMappingUItoAPI(intelRule.threatMappings)
                  : {},
              ruleBehavior: ruleBehavior,
              description: intelRule.description,
              tags: intelRule.tags,
              targetMode: INTEL_DEFAULT_TARGET_MODE,
              ruleType: ruleType,
              intels: [getNewIntelFromIndicator(indicator, ruleType)]
            },
            {
              retry429: true
            }
          )
        }

        ruleSaveReq.then(ruleResponse => {
          if (ruleResponse[successKey].length === 1) {
            // 3: Update all specified subscriptions
            let subReq = Promise.resolve(null)
            if (
              !window._pw.isDemoCustomer &&
              sensorIdsNeedSubUpdate &&
              sensorIdsNeedSubUpdate.length > 0
            ) {
              const allSubscriptions = IntelSubscriptionStore.getAllSubscriptions()
              const defaultSubscription = IntelSubscriptionStore.getDefaultSubscription()
              const subscriptionRequests = {} // map of {subOrderHashKey: [sensorIds]}
              _.forEach(sensorIdsNeedSubUpdate, sensorId => {
                const currentSub = _.find(
                  allSubscriptions,
                  sub => sub.sensorId === sensorId
                )
                let newSubLists = currentSub ? _.clone(currentSub).lists : []
                if (!currentSub || currentSub.isDefault) {
                  // 3.1: Set this sensor's subscription to the Default + our new list
                  newSubLists = _.clone(defaultSubscription)
                } else if (newSubLists.indexOf(targetListId) !== -1) {
                  // 3.2 List is in the current subscription, but not at top priority
                  newSubLists.splice(newSubLists.indexOf(targetListId), 1) // remove from old pos
                }
                // else {
                //   // 3.3 List is not in the current Subscription.
                // }

                newSubLists.unshift(targetListId) // Add to top priority (beginning)

                const subReqKey = newSubLists.join(SUB_KEY_DELIM)
                if (!subscriptionRequests[subReqKey]) {
                  subscriptionRequests[subReqKey] = [sensorId]
                } else {
                  subscriptionRequests[subReqKey].push(sensorId)
                }
              })

              subReq = Promise.all(
                _.map(subscriptionRequests, (sensorIds, subOrderHashKey) => {
                  return requestPost(
                    null,
                    'intel/subscriptions/set',
                    {
                      sensorIds,
                      listIds: subOrderHashKey.split(SUB_KEY_DELIM)
                    },
                    {
                      retry429: true
                    }
                  )
                })
              )
            }

            subReq.then(() => {
              _state.saveProgress += 33 // ?
              this._notify()
              overallDeferred.resolve() // Finished
            })
            subReq.catch(handleSaveFail)
          } else if (ruleResponse.rejected.length > 0) {
            overallDeferred.reject({
              heading: 'Error saving intel rule',
              body: 'Sorry, we were unable to save the Intel Rule',
              additional: _.map(ruleResponse.rejected, rule => {
                return `${_.get(
                  rule,
                  'intel.ids.rule',
                  'Unknown Rule'
                )} :: (Error ${_.get(rule, 'reason.type', -1)}) ${_.get(
                  rule,
                  'reason.message',
                  'Unknown error'
                )}`
              })
            })
          }
          _state.saveProgress += 33 // ?
          this._notify()
        })

        ruleSaveReq.catch(handleSaveFail)
      })

      ruleLoadReq.catch(handleSaveFail)
    })

    listReq.catch(handleSaveFail)

    overallDeferred.promise
      .then(() => {
        _state.saveProgress = 100
        this._notify()
        setTimeout(() => {
          _state.saveProgress = -1 // No longer saving
          this._notify()
          CommonViewActions.Notification.add({
            type: 'success',
            heading: `New Intel Rule saved`,
            message: `Please note that it may take up to ${INTEL_RULE_SAVE_MINUTES} minutes for this change to take effect in the ${getConfig().productName} platform.`,
            dismissTimer: 4000
          })
        }, 500)
      })
      .catch(notificationMessage => { //either a RestError or a plain object with heading/body/etc. properties
        _state.saveError = notificationMessage
        _state.saveProgress = 100
        this._notify()
      })

    AnalyticsActions.event({
      eventCategory: 'intel',
      eventAction: 'quick_add_rule',
      eventLabel: `save_with_behavior_${ruleBehavior}`
    })
    return overallDeferred.promise
  }
})

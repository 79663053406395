import { QUERY_INPUT_TYPE_NAMES } from 'pw-query'
import { getStringLengthValidator } from 'pw-validators'

export const ENTITY_PRIORITY_OPTIONS = {
  Low: {
    display: "Low"
  },
  Normal: {
    display: "Normal"
  },
  High: {
    display: "High"
  },
  Critical: {
    display: "Critical"
  }
}

export const ENTITY_ROLE_OPTIONS = {
  BackupHistorianServer: {
    display: "Backup Historian Server"
  },
  BastionServer: {
    display: "Bastion Server"
  },
  Database: {
    display: "Database"
  },
  DistributedControlServer: {
    display: "Distributed Control Server"
  },
  DnsServer: {
    display: "DNS Server"
  },
  DomainController: {
    display: "Domain Controller"
  },
  EmailServer: {
    display: "Email Server"
  },
  Firewall: {
    display: "Firewall"
  },
  HoneyPot: {
    display: "Honeypot"
  },
  IcsDeviceRouter: {
    display: "ICS Device Router"
  },
  IcsDeviceSwitch: {
    display: "ICS Device Switch"
  },
  IcsOperatorWorkstationHmi: {
    display: "ICS Operator Workstation HMI"
  },
  IcsProcessController: {
    display: "ICS Process Controller"
  },
  Other: {
    display: "Other"
  },
  Proxy: {
    display: "Proxy"
  },
  SecurityDevice: {
    display: "Security Device"
  },
  Switch: {
    display: "Switch"
  },
  VunlerabilityScanner: { // [sic]
    display: "Vulnerability Scanner"
  },
  WebServer: {
    display: "Web Server"
  },
  Workstation: {
    display: "Workstation"
  }
}


export const ENTITY_OS_TYPE_OPTIONS = {
  "Android": 9,
  "AppleIos": 10,
  "Bsd": 3,
  "CiscoIos": 7,
  "Embedded": 6,
  "Junos": 8,
  "Linux": 4,
  "MacOs": 2,
  "Sunos": 5,
  "Unknown": 0,
  "Windows": 1
}

export const queryFieldConfigs = {
  "cityId": {
    label: "City"
  },
  "countryIsoCode": {
    label: "Country",
    tooltip: "Two-character (ISO-A2) Country Code",
    validate: getStringLengthValidator(2, 2)
  },
  "customerDeviceId": {
    label: "Customer_Device_ID"
  },
  "hostnames": {
    label: "Hostname"
  },
  "id": {
    label: "ID"
  },
  "ips": {
    label: "IP",
    tooltip: "IP Address found in any Device field"
  },
  "macs": {
    label: "MAC_Address",
    type: QUERY_INPUT_TYPE_NAMES.MAC_ADDRESS,
    transformQueryValue: value => value.replace(/:/g, '') // Device Query API needs inner colons removed
  },
  "networkTypes": {
    label: "Network_Type"
  },
  "occurredAt": {
    label: "Updated_At"
  },
  "osName": {
    label: "OS_Name"
  },
  "osType": {
    label: "OS_Type"
  },
  "osVersion": {
    label: "OS_Version"
  },
  "priority": {
    label: "Priority"
  },
  "roles": {
    label: "Role"
  },
  "tags": {
    label: "Tags"
  },

  "attributes.source": {
    label: "Attribute_Source"
  },
  "attributes.subSource": {
    label: "Attribute_SubSource"
  },
  // 'attributes.detail.interface': {  label: "" }, // Manually hidden
  // 'attributes.detail.geo': {  label: "" }, // Has preferred top-level aggregates (coords, countryIsoCode, cityId, etc)
  // 'attributes.detail.os': {  label: "" }, // Has preferred top-level aggregates (osType, etc)
  // 'attributes.detail.host': {  label: "" }, // Has preferred top-level aggregate (hostnames)
  // 'attributes.detail.ip': {  label: "" }, // Has preferred top-level aggregate (ips)
  // 'attributes.detail.mac': {  label: "" }, // Has preferred top-level aggregate
  'attributes.detail.awsId': {  label: "AWS_ID" },
  // 'attributes.detail.awsDetail': {  label: "" }, // Has preferred top-level aggregate (awsInterface)
  // 'attributes.detail.role': {  label: "" }, // Has preferred top-level aggregate
  // 'attributes.detail.priority': {  label: "" }, // Has preferred top-level aggregate
  'attributes.detail.description': {  label: "Description" },
  'attributes.detail.department': {  label: "Department" },
  // 'attributes.detail.networkDescription': {  label: "" }, // Has preferred top-level aggregate (networkTypes)
  // 'attributes.detail.customerDeviceId': {  label: "" }, // Has preferred top-level aggregate
  'attributes.detail.transactionId': {  label: "Transaction_ID" },
  'attributes.detail.customerIp': {  label: "Customer_IP" },
  // 'attributes.detail.firstSeen': {  label: "" }, // Manually hidden
  // 'attributes.detail.lastSeen': {  label: "" }, // Manually hidden
  'attributes.detail.externalIp': {  label: "External_IP" },
  // 'attributes.detail.userDetails': {  label: "User" },
  'attributes.detail.userDetails.userId': {  label: "User_ID" },
  'attributes.detail.userDetails.firstName': {  label: "User_FirstName" },
  'attributes.detail.userDetails.lastName': {  label: "User_LastName" },
  'attributes.detail.userDetails.email': {  label: "User_Email" },
  'attributes.detail.userDetails.description': {  label: "User_Description" },
  'attributes.detail.userDetails.city': {  label: "User_City" },
  'attributes.detail.userDetails.state': {  label: "User_State" },
  'attributes.detail.userDetails.country': {  label: "User_Country" },
  'attributes.detail.userDetails.title': {  label: "User_Title" },
  'attributes.detail.userDetails.department': {  label: "User_Department" },
  'attributes.detail.userDetails.company': {  label: "User_Company" },
  'attributes.detail.userDetails.highValueTarget': {  label: "User_IsHighValue" },
  'attributes.detail.vendor': {  label: "Vendor" },
  // 'attributes.detail.risk', // Has preferred top-level aggregate

}
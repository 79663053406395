import React, {useState, useCallback} from 'react'
import { TextInput, Button } from 'react-base'
import {useAuthorizationStream} from 'pw-immersive-data'
import { useHeadsetStatusStream, unpairHeadset, pairHeadset } from 'stores/XrayHeadsetStatusStore.js'

export const XrayPairingManager = () => {
  const {paired, connected} = useHeadsetStatusStream()
  const {error, code: erroredCode} = useAuthorizationStream()
  const [code, setCode] = useState()
  const pairWithCode = useCallback(() => pairHeadset(code), [code])
  const pairOnEnter = useCallback(e => {
    if (e.key === 'Enter') {
      pairHeadset(e.target.value)
      return
    }
    if (code !== e.target.value) {
      setCode(e.target.value)
    }
  }, [code])
  
  return (
    <div className="meta_menu">
    {paired ? <div className='xray_paired'>
      <h3>
        Headset
        {connected ? ' connected' : ' paired, not connected'}
      </h3>
      <Button onClick={unpairHeadset}>Unpair</Button>
    </div> : <React.Fragment>
      <h3>Headset not paired</h3>
      <h4>Enter headset code</h4>
      <span className='xray_code_input_wrapper'>
        <TextInput
          onSave={setCode}
          onKeyUp={pairOnEnter}
        />
        <Button onClick={pairWithCode}>Pair</Button>
      </span>
    </React.Fragment>}
    {error && erroredCode === code ?
      <div className="xray_pairing_error">{error}</div>
    : null}
    </div>
  )
}

XrayPairingManager.displayName = "XrayPairingManager"

import * as _ from 'lodash'
import {
  observable,
  action,
  flow,
  autorun,
  computed,
} from 'mobx'
import { parseQueryToLucene } from 'pw-query'
import {
  requestGet
} from 'utils/restUtils'
import { queryFieldConfigs } from 'constants/devices'
import { getQueryInputSchemaFromSolrSchema } from 'utils/searchUtils'
import { DeviceManagementStore, DeviceViews } from 'stores/mobx/viewStores/devices/DeviceManagementStore'
import { Store } from 'stores/mobx/StoreManager'
import { RestError } from 'typings/restError'

// Not exposing attributes or additionalAttributes yet. Most common values within these are exposed at the top level.
// Also, the nature of `attributes` (a set of structs) would make open ended queries difficult to reason about
// e.g. a query for `attributes.source=PW AND attributes.ip=1.2.3.4` would actually match
//      all devices with a device source of PW _OR_ an attribute with the designated IP
const BLACKLIST_FIELDS = [
  'cid',
  // 'attributes.',
  'attributes.expirationInfo',
  // Items removed from this in the future should have display labels added to constants/devices
  'attributes.detail.interface', // manually hidden
  'attributes.detail.geo', // Has preferred top-level aggregates (coords, countryIsoCode, cityId, etc)
  'attributes.detail.os', // Has preferred top-level aggregates (osType, etc)
  'attributes.detail.host', // Has preferred top-level aggregate (hostnames)
  'attributes.detail.ip', // Has preferred top-level aggregate (ips)
  'attributes.detail.mac', // Has preferred top-level aggregate
  // 'attributes.detail.awsId',
  'attributes.detail.awsDetail', // Has preferred top-level aggregate (awsInterface)
  'attributes.detail.role', // Has preferred top-level aggregate
  'attributes.detail.priority', // Has preferred top-level aggregate
  // 'attributes.detail.description',
  // 'attributes.detail.department',
  'attributes.detail.networkDescription', // Has preferred top-level aggregate (networkTypes)
  'attributes.detail.customerDeviceId', // Has preferred top-level aggregate
  // 'attributes.detail.transactionId',
  // 'attributes.detail.customerIp',
  'attributes.detail.firstSeen', // Manually hidden
  'attributes.detail.lastSeen', // Manually hidden
  // 'attributes.detail.externalIp',
  'attributes.detail.userDetails', // Buggy right now, "
  // 'attributes.detail.vendor',
  'attributes.detail.risk', // Has preferred top-level aggregate

  'additionalattributes.',
  'cityId', // TEMP until ID=>Name translation works
]
const filterDeviceFields = fieldKey => !fieldKey.match(new RegExp(`(${BLACKLIST_FIELDS.join('|')})`, 'gi'))
const filterDeviceEnums = enumVal => !enumVal.match(/(zunassiged)/gi) // "zunassiged" [sic]

export class DeviceQueryStore extends Store {
  static dependencies = [DeviceManagementStore]

  deviceManagementStore: DeviceManagementStore

  constructor (deps: [DeviceManagementStore]) {
    super(deps)
    const [deviceManagementStore] = deps
    this.deviceManagementStore = deviceManagementStore

    this.destroy = autorun(() => {
      if (deviceManagementStore.activeView === DeviceViews.Inventory) {
        this.init()
      }
    })
  }

  @observable isLoading: boolean = false
  @observable querySchema?: object = null
  @observable error?: RestError = null
  @observable pendingQuery: string = ''

  fetchSchema = () => requestGet(
    'get_devices_schema',
    `devices/schema`,
    {
      baseURL: 'api/v2/'
    }
  )

  loadSchema = flow(function * () {
    this.querySchema = null
    this.isLoading = true
    this.error = null
    let serverSchema
    try {
      serverSchema = yield this.fetchSchema()
    }
    catch (error) {
      this.error = error
    }

    if (serverSchema) {
      this.querySchema = getQueryInputSchemaFromSolrSchema(
        serverSchema,
        {
          fieldConfigs: queryFieldConfigs,
          filterFields: filterDeviceFields,
          filterEnumValues: filterDeviceEnums
        }
      )
      console.debug(JSON.stringify(this.querySchema))
    }
    this.isLoading = false
  })

  @computed
  get currentQuery () {
    return this.deviceManagementStore && this.deviceManagementStore.listParams && this.deviceManagementStore.listParams.q || ''
  }

  @computed
  get isDirty () {
    return this.currentQuery !== this.pendingQuery
  }

  @computed
  get parsedLuceneQuery () : string {
    if (!this.querySchema) {
      return ''
    }
    return parseQueryToLucene(this.currentQuery, this.querySchema) || ''
  }

  @action.bound
  init () {
    this.loadSchema()
  }

  @action.bound
  handleChange (queryTextBlocks, blockData) {
    this.pendingQuery = queryTextBlocks[0]
  }

  @action.bound
  reset () {
    this.pendingQuery = ''
    this.submitPending()
  }

  @action.bound
  submitPending () {
    this.deviceManagementStore.commitQuery(this.pendingQuery)
  }
}

import T from 'prop-types'
import React from 'react'
import _ from 'lodash'
import {
  DropdownMenu,
  MenuItem
} from 'react-base'


class DevWidget extends React.PureComponent {
  static displayName = 'DevWidget'

  static propTypes = {
  }

  render() {
    if (!process.env.DEV_SERVER_ACTIVE) {
      return null
    }

    const devProxyHostOptions = window.pwDev.PROXY_HOST_OPTIONS
    const activeProxyHost = window.pwDev.proxyHost

    return (
      <div
        className={`dev_widget proxy_${activeProxyHost.toLowerCase()}`}
        data-tooltip={`Proxying ${activeProxyHost}`}
      >
        <DropdownMenu
          label={""}
          buttonClasses={`proxy_switch_btn`}
          omitCaret
          allowActiveItemClick
          activeMenuKeys={[activeProxyHost]}
        >
          {devProxyHostOptions && _.map(devProxyHostOptions, (name) => (
              <MenuItem
                key={name}
                args={[name]}
                onClick={window.pwDev.setProxyHost}
                className={`proxy_option opt_${name}`}
              ><span className="icon icon-fire" />{name}</MenuItem>
            ))
          }
        </DropdownMenu>
      </div>
    )
  }
}

export default DevWidget


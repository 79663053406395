import _ from 'lodash'
import getConfig from 'utils/uiConfig'

const getGoogleAuthClientKey = () => {
  const uiConfig = getConfig()
  return uiConfig.apiKeys.googleAuthClient
}

// Set up a promise for the initialized auth2 object instance. The loading process
// is started immediately but will run async in parallel with subsequent module loading.
const getAuthInstancePromise = () => {
  return new Promise((resolve, reject) => {
    const authClientKey = getGoogleAuthClientKey()
    if (!authClientKey) {
      reject(new Error("Google API Client Key unavailable in uiConfig.json"))
      return
    }
    let script = document.createElement('script')
    script.onload = () => {
      window.gapi.load('auth2', () => {
        let authInstance = window.gapi.auth2.init({
          client_id: `${ authClientKey }.apps.googleusercontent.com`,
          fetch_basic_profile: false,
          scope: 'email'
        })
        authInstance.then = null //resolving with a thenable puts it in an infinite loop
        resolve(authInstance)
      })
    }
    script.src = 'https://apis.google.com/js/platform.js'
    document.head.appendChild(script)
  })
}

export const canGoogleAuth = () => {
  const uiConfig = getConfig()
  return _.get(uiConfig, "apiKeys.googleAuthClient", null) != null
}

const attachClickHandler = (el, success, failure) => {
  if (canGoogleAuth()) {
    getAuthInstancePromise().then(auth => {
      auth.attachClickHandler(el, {}, success, failure)
    })
  }
}


export default {
  attachClickHandler
}

import React, {useEffect, useRef, useState} from 'react'
import T from 'prop-types'
import { formatDurationHuman } from 'pw-formatters'

// Source: https://css-tricks.com/using-requestanimationframe-with-react-hooks/
const useAnimationFrame = callback => {
  // Use useRef for mutable variables that we want to persist
  // without triggering a re-render on their change
  const requestRef = useRef()
  const previousTimeRef = useRef()

  const animate = time => {
    if (previousTimeRef.current != undefined) {
      const deltaTime = time - previousTimeRef.current
      callback(deltaTime)
    }
    previousTimeRef.current = time
    requestRef.current = requestAnimationFrame(animate)
  }

  useEffect(() => {
    requestRef.current = requestAnimationFrame(animate)
    return () => cancelAnimationFrame(requestRef.current)
  }, [])
}

const Stopwatch = ({startAt, endAt}) => {
  const [count, setCount] = useState(0)

  // Just using this for force update on each animation frame
  useAnimationFrame(deltaTime => {
    setCount(() => deltaTime)
  })

  return (
    <div
      className={`stopwatch stopped`}
    >
      {formatDurationHuman(Date.now() - startAt)}
    </div>
  )
}

Stopwatch.propTypes = {
  startAt: T.number,
  endAt: T.number
}

Stopwatch.displayName = "Stopwatch"

export default Stopwatch
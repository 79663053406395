import React from 'react'
import T from 'prop-types'
import cx from 'classnames'
import { select as d3Select } from 'd3-selection'
import { geoMercator, geoPath } from 'd3-geo'
import geoDataUtil from 'utils/geoDataUtil'
import worldMapOptions from 'components/world-map/worldMapOptions'
import worldMapUtil from 'ui-base/src/util/worldMapUtil'


// Create projection. Note that these dimensions are just to create the initial
// projection and viewBox with reasonable aspect ratio and cropping; the map SVG
// itself will still scale itself to 100% width.
const PROJECTION_WIDTH = 300
const PROJECTION_HEIGHT = 168
const PROJECTION = geoMercator()
  .center([0, 30]) //center north of equator to avoid blank space at south
  .translate([PROJECTION_WIDTH / 2, PROJECTION_HEIGHT / 2])
  .scale(PROJECTION_WIDTH / 2 / Math.PI) //NOTE: scale(1) means longitude=180 maps to x=PI


class SimpleWorldMap extends React.Component {
  static propTypes = {
    className: T.string,
    markers: T.arrayOf(T.shape({
      latitude: T.number,
      longitude: T.number,
      className: T.string
    }))
  }

  _bindMapCt = el => {
    if (el) {
      this._drawMap(el)
    }
  }

  _drawMap(el) {
    geoDataUtil.getWorld('low', true).then(regionData => {
      // Draw the map
      worldMapUtil.drawCountryData({
        svg: d3Select(el),
        mapOptions: worldMapOptions,
        geoPath: geoPath().projection(PROJECTION),
        countriesData: regionData,
        applyNonScalingStroke: window._pwCompatibility.supportsNonScalingStroke
      })
    })
  }

  render() {
    const {markers, className} = this.props

    return <svg
      className={cx("simple_worldmap", className)}
      width="100%"
      viewBox={`0 0 ${PROJECTION_WIDTH} ${PROJECTION_HEIGHT}`}
    >
      <g className="earth" ref={this._bindMapCt} />
      <g className="points">
        {markers && markers.map(({latitude, longitude, radius, className}, i) => {
          const [x, y] = PROJECTION([longitude, latitude])
          return <circle key={i} cx={x} cy={y} r={radius || 3} className={className} />
        })}
      </g>
    </svg>
  }
}

export default SimpleWorldMap

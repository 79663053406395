import _ from 'lodash'
import moment from 'moment'
import { scaleUtc, scaleTime } from 'd3-scale'
import { timeFormat, utcFormat } from 'd3-time-format'
import * as d3Time from 'd3-time'
import PreferencesStore from 'stores/PreferencesStore'
import {
  formatDate as pwFormatDate,
  formatRelativeTime as pwFormatRelativeTime
} from 'pw-formatters'
import { timeFormattingOptions } from 'pwConstants'

let _isUTC = false

let _currentFormatPrefs = {
  datetime: {},
  day: {},
  time: {}
}

const _formatMillisecond = '%H:%M:%S.%L'
const _formatSecond = ':%S'
const _formatMinute = '%I:%M'
const _formatHour = '%I %p'
const _formatDay = '%m-%d'
const _formatMonth = '%Y-%m'
const _formatYear = '%Y'

function _timelineTickFormat(date) {
  const _fmt =
    d3Time.timeSecond(date) < date
      ? _formatMillisecond
      : d3Time.timeMinute(date) < date
        ? _formatSecond
        : d3Time.timeHour(date) < date
          ? _formatMinute
          : d3Time.timeDay(date) < date
            ? _formatHour
            : d3Time.timeMonth(date) < date
              ? _formatDay
              : d3Time.timeYear(date) < date ? _formatMonth : _formatYear
  return timeFormat(_fmt)(date)
}

function _timelineTickFormatUTC(date) {
  const _fmt =
    d3Time.utcSecond(date) < date
      ? _formatMillisecond
      : d3Time.utcMinute(date) < date
        ? _formatSecond
        : d3Time.utcHour(date) < date
          ? _formatMinute
          : d3Time.utcDay(date) < date
            ? _formatHour
            : d3Time.utcMonth(date) < date
              ? _formatDay
              : d3Time.utcYear(date) < date ? _formatMonth : _formatYear
  return utcFormat(_fmt)(date)
}

function _formatDate(
  datetime,
  formatType = 'datetime',
  includeMillis = false,
  forceUTC = false
) {
  return pwFormatDate(
    new Date(datetime),
    getFormat(formatType, includeMillis),
    forceUTC || _isUTC
  )
}

/**
 * Return the current preferred Moment.js time format string for the given displayType
 * @method _getPreferredFormat
 * @param  {string [datetime|day|time]} displayType           type of format requested
 * @param  {bool}                       includeMillis         include milliseconds
 * @return {string}                     moment.js string time format
 */
function _getPreferredFormat(displayType, includeMillis = false) {
  const prefFormat = getCurrentTimeFormat() || 'standard'

  // Special handling for ISO format options
  const formatSpec = timeFormattingOptions[prefFormat]
  if (!formatSpec) {
    console.warn('Invalid Time Format Preference found')
    return ''
  }
  const { formats } = formatSpec
  if (prefFormat === 'iso') {
    formats.time = _isUTC ? 'HH:mm:ss[Z]' : 'HH:mm:ssZ' // Match full ISO's behavior for Zulu "Z" and Timezone offsets
  }
  const _timeFormat = includeMillis
    ? formats.time.replace(':ss', ':ss.SSS')
    : formats.time

  switch (displayType) {
    case 'datetime':
      if (formats.datetime) {
        return formats.datetime
      } else {
        return `${formats.day} ${_timeFormat}`
      }
    case 'day':
      return formats.day
    case 'time':
      return _timeFormat
    default:
      console.warn('Invalid `displayType` supplied to getCurrentDateTimeForm')
      return ''
  }
}

// Populate local format variables with the latest time format preference
PreferencesStore.listen(prefsStoreState => {
  _isUTC = PreferencesStore.isUTC()
  _currentFormatPrefs = _.mapValues(_currentFormatPrefs, (val, dateType) => {
    return {
      withMillis: _getPreferredFormat(dateType, true),
      noMillis: _getPreferredFormat(dateType, false)
    }
  })

  // Update Moment.JS long date formats to match customer prefs
  moment.updateLocale('en', {
    longDateFormat: {
      LT: 'HH:mm',
      LTS: _currentFormatPrefs.time.noMillis,
      L: _currentFormatPrefs.day.noMillis,
      LL: _currentFormatPrefs.day.noMillis,
      LLL: _currentFormatPrefs.day.noMillis,
      LLLL: _currentFormatPrefs.day.noMillis
    },
    calendar: {
      lastDay: '[Yesterday at] LTS',
      sameDay: '[Today at] LTS',
      nextDay: '[Tomorrow at] LTS',
      lastWeek: '[Last] ddd [at] LTS',
      nextWeek: 'ddd [at] LTS',
      sameElse:
        _currentFormatPrefs.datetime.noMillis === 'iso'
          ? null
          : _currentFormatPrefs.datetime.noMillis
    }
  })
})

/*
 * Exported functions
 */

export function isUTC() {
  return _isUTC
}

export function getD3TimeScale() {
  return _isUTC ? scaleUtc : scaleTime
}

export function getMoment(forceUtc = false) {
  return _isUTC || forceUtc ? moment.utc : moment
}

export function getTimelineTickFormat() {
  return _isUTC ? _timelineTickFormatUTC : _timelineTickFormat
}

export function getCurrentTimeFormat() {
  return PreferencesStore.getCurrentTimeFormat()
}

export function isRelativeTimeEnabled() {
  return PreferencesStore.isRelativeTimeEnabled()
}

/**
 * Format a timestamp or ISO date string, returning the date/time
 * in the users preferred format
 * @method formatDate
 * @param  {[timestamp|ISO Date String]}  datetime      [ISO Date string or Timestamp]
 * @param  {[bool]}  includeMillis [include milliseconds]
 * @return {[string]}                [formatted date/time string]
 */
export function formatDate(datetime, includeMillis = false, forceUTC = false) {
  return _formatDate(datetime, 'datetime', includeMillis, forceUTC)
}

export function formatDay(datetime, forceUTC = false) {
  return _formatDate(datetime, 'day', false, forceUTC)
}

export function formatTime(datetime, includeMillis = false, forceUTC = false) {
  return _formatDate(datetime, 'time', includeMillis, forceUTC)
}

// Return a date formatted using the provided formatString
// Warning: Ignores user-specified format preference
export function formatDateWith(datetime, formatString, forceUTC = false) {
  if (!datetime || !formatString) {
    console.warn(
      'formatDateWith: Invalid Arguments. datetime & formatString are required. ',
      datetime,
      formatString
    )
    return ''
  }
  return pwFormatDate(new Date(datetime), formatString, forceUTC || _isUTC)
}

export function getFormat(formatType, includeMillis = false) {
  return _currentFormatPrefs[formatType][
    includeMillis ? 'withMillis' : 'noMillis'
  ]
}

export function formatRelativeTime(datetime) {
  if (isRelativeTimeEnabled()) {
    return pwFormatRelativeTime(datetime, _isUTC)
  } else {
    return formatDate(datetime)
  }
}

const GREGORIAN_OFFSET = 122192928000000000

const _getTimeIntFromUUID = function(uuid) {
  // (string) uuid format	=>		'11111111-2222-#333-4444-555555555555'
  const uuidArr = uuid.split('-')
  const timeStr = [uuidArr[2].substring(1), uuidArr[1], uuidArr[0]].join('')
  // timeStr is convert  '11111111-2222-#333-4444-555555555555'  to  '333222211111111'
  return parseInt(timeStr, 16)
}

export function timeUUIDToTimeStamp(uuid) {
  // (string) uuid format	=>		'11111111-2222-#333-4444-555555555555'
  const intTime = _getTimeIntFromUUID(uuid) - GREGORIAN_OFFSET
  const intMillis = Math.floor(intTime / 10000)
  return intMillis
}

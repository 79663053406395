import _ from 'lodash'
import Reflux from 'reflux'

import SupportActions from 'actions/SupportActions'
// import CommonViewActions from 'actions/CommonViewActions'
import genericUtil from 'ui-base/src/util/genericUtil'
const {
  durations,
} = genericUtil
import {
  SUPPORT_DISMISSED_NOTIFICATIONS_LS_KEY
} from 'constants/supportConstants'
import {requestDelete, requestGet, requestPost, requestPut} from 'utils/restUtils'
import CommonViewActions from 'actions/CommonViewActions'

const ID_DELIM = "|"
const NOTIFICATION_POLL_INTERVAL = durations(5, 'minutes')

// const SEVERITY_TO_NOTIFICATION_TYPE = {
//   low: 'info',
//   medium: 'warning',
//   high: 'error'
// }

let _state = {
  activeNotifications: [],
  activeNotificationsError: null,
  activeNotificationsLoading: false,
  allActiveNotifications: [], // Not filtered by `dismissed` localStorage property
  allNotifications: [],
  allNotificationsError: null,
  allNotificationsLoading: false,
  savingNotifications: false,
  savingNotificationsError: null,
  showDeleted: false
}

export default Reflux.createStore({
  listenables: [
    SupportActions
  ],

  init() {

    this._queueNotificationPoll() // Initial start of notification checks
  },

  getInitialState() {
    return _state
  },

  _notify() {
    this.trigger(_state)
  },

  _queueNotificationPoll() {
    clearTimeout(this._pollTimer)
    cancelAnimationFrame(this._updateRaf)
    this._pollTimer = setTimeout(() => {
      this._updateRaf = requestAnimationFrame(this.onLoadActiveNotifications.bind(this))
    }, NOTIFICATION_POLL_INTERVAL)
  },

  _getDismissedNotificationIds() {
    const lsValue = localStorage.getItem(SUPPORT_DISMISSED_NOTIFICATIONS_LS_KEY)
    return lsValue ? lsValue.split(ID_DELIM).map(id => parseInt(id)) : []
  },

  // _emitActiveNotifications() {
  //   const {
  //     activeNotifications
  //   } = _state
  //   for (let i = 0, iLen = activeNotifications.length; i < iLen; i++) {
  //     const {
  //       severity,
  //       message,
  //       link,
  //     } = activeNotifications[i]
  //     CommonViewActions.Notification.add({
  //       heading: "System Notification",
  //       message: message,
  //       messageDetails: link,
  //       type: SEVERITY_TO_NOTIFICATION_TYPE[severity] || "info",
  //     })
  //   }
  // },


  // Action Handlers
  onSetShowDeleted(showDeleted) {
    _state.showDeleted = showDeleted
    this.onLoadAllNotifications()
  },

  onLoadActiveNotifications() {
    _state.activeNotificationsLoading = true
    _state.activeNotificationsError = null
    // _state.activeNotifications = [] // Don't clear while loading
    const dismissedIds = this._getDismissedNotificationIds()
    this._notify()
    requestGet('load_active_support_notifications', `status-messages/current`)
      .then(data => {
        _state.allActiveNotifications = _.sortByOrder(data, 'start_at', 'asc')
        _state.activeNotifications = _.filter(_state.allActiveNotifications, notification => {
          return dismissedIds.indexOf(notification.id) === -1
        })
        _state.activeNotificationsLoading = false
        // this._emitActiveNotifications()
        this._notify()
      })
      .catch(restError => {
        _state.activeNotificationsError = restError
        _state.activeNotificationsLoading = false
        this._notify()
      })
    this._queueNotificationPoll() // Start timer for the next poll
  },

  onLoadAllNotifications() {
    _state.allNotificationsLoading = true
    _state.allNotificationsError = null
    _state.allNotifications = []
    this._notify()
    requestGet('load_all_support_notifications', `status-messages?includeDeleted=${ _state.showDeleted }`)
      .then(data => {
        _state.allNotifications = data
        _state.allNotificationsLoading = false
        this._notify()
      })
      .catch(restError => {
        _state.allNotificationsError = restError
        _state.allNotificationsLoading = false
        this._notify()
      })
  },

  onCreateNotification(newNotification) {
    if (!newNotification) {
      return
    }
    _state.savingNotifications = true
    _state.savingNotificationsError = null
    this._notify()
    requestPost(
        'create_support_notification',
        `status-messages`,
        newNotification
      )
      .then(data => {
        _state.savingNotifications = false
        this._notify()
        this.onLoadAllNotifications() // reload
        this.onLoadActiveNotifications()
      })
      .catch(restError => {
        _state.savingNotificationsError = restError
        _state.savingNotifications = false
        CommonViewActions.Notification.addRestError(restError)
        this._notify()
      })
  },

  onUpdateNotification(updateNotification) {
    if (!updateNotification) {
      return
    }
    _state.savingNotifications = true
    _state.savingNotificationsError = null
    this._notify()
    requestPut(
        'update_support_notification',
        `status-messages/${ updateNotification.id }`,
        updateNotification
      )
      .then(data => {
        _state.savingNotifications = false
        this._notify()
        this.onLoadAllNotifications() // reload
        this.onLoadActiveNotifications()
      })
      .catch(restError => {
        _state.savingNotificationsError = restError
        _state.savingNotifications = false
        CommonViewActions.Notification.addRestError(restError)
        this._notify()
      })
  },

  onDeleteNotification(notificationId) {
    if (!notificationId) {
      return
    }
    _state.savingNotifications = true
    _state.savingNotificationsError = null
    this._notify()
    requestDelete(
        'delete_support_notification',
        `status-messages/${ notificationId }`
      )
      .then(data => {
        _state.savingNotifications = false
        this._notify()
        this.onLoadAllNotifications() // reload
        this.onLoadActiveNotifications()
      })
      .catch(restError => {
        _state.savingNotificationsError = restError
        _state.savingNotifications = false
        CommonViewActions.Notification.addRestError(restError)
        this._notify()
      })
  },


  /**
   * Dismiss a notification. Once dismissed it will no longer be returned in /current messages,
   * only for the current user
   * @param {*int} notificationId
   */
  onDismissNotification(notificationId) {
    const currentDismissed = this._getDismissedNotificationIds()
    const newDismissed = currentDismissed.concat([notificationId]).join(ID_DELIM)
    try {
      localStorage.setItem(
        SUPPORT_DISMISSED_NOTIFICATIONS_LS_KEY,
        newDismissed
      )
    } catch (error) {
      console.warn("Error saving dismissed Support Notification", error)
    }
    _state.activeNotifications = _.filter(_state.activeNotifications, notification => {
      return newDismissed.indexOf(notification.id) === -1
    })
    this._notify()
  }

})

import T from 'prop-types'

export const deviceShape = {
  // all possible fields; individual device types/vendors may use a subset
  id: T.string,
  type: T.string,
  vendor: T.string,
  // version: T.string, not used yet
  timezone: T.oneOfType([T.number, T.string]),
  name: T.string,
  uri: T.string,
  credentialType: T.oneOf(['password', 'token', 'iam']),
  username: T.string,
  token: T.string,
  sensorIds: T.array,
  insecureSkipVerify: T.bool
}

export const proxyShape = {
  id: T.string,
  name: T.string
}

// NOTE: these are not all possible integration device types, just those that are configurable via
// the Context Fusion -> Integrations screen -- i.e. those that use PIPs. In the future we'll add
// PIP-less devices to that screen too.
export const deviceTypes = {
  'Firewall': {
    label: 'Firewall',
    desc: 'Provides network firewall logs',
    icon: 'fire',
    vendors: {
      'PAN': {
        label: 'Palo Alto Networks',
        allowUri: true,
        allowTimezone: true,
        allowInsecureSkipVerify: true,
        allowAuth: ['token', 'password'],
        allowRemediation: true,
        remediationNeedsQueryableDevice: true, //configuration remediation needs a functioning queryable device+pip
        defaults: {
          timezone: 'UTC',
          credentialType: 'token'
        }
      }
    }
  },
  'EndpointKnowledge': {
    label: 'Device Info',
    desc: 'Provides info about devices on the network',
    icon: 'device',
    vendors: {
      'CarbonBlack': {
        label: 'Carbon Black Response',
        allowUri: true,
        allowTimezone: true,
        allowInsecureSkipVerify: true,
        allowAuth: ['token'],
        allowRemediation: true,
        remediationNeedsQueryableDevice: false, //nothing to configure
        defaults: {
          timezone: 'UTC',
          credentialType: 'token'
        }
      },
      'carbonblack.defense': {
        label: 'Carbon Black Defense',
        allowUri: true,
        uriLabel: 'API Hostname',
        allowTimezone: false,
        allowInsecureSkipVerify: false,
        allowAuth: ['token'],
        compoundToken: {
          delimiter: '/',
          fields: [
            {name: 'token$apiKey', label: 'API Key', type: 'password'},
            {name: 'token$connectorId', label: 'Connector ID', type: 'text'}
          ]
        },
        customFields: [ //arbitrary fields that will be saved to integrationJsonData
          {
            name: 'dashboardUri',
            required: true,
            label: 'Dashboard Hostname',
            helpHref: "https://community.carbonblack.com/t5/Knowledge-Base/Cb-Defense-API-URLs/ta-p/37328",
            helpTooltip: "Find your correct hostnames in the Carbon Black knowledge base (opens in a new browser tab)"
          }
        ],
        defaults: {
          credentialType: 'token'
        }
      },
      'AWS': {
        label: 'AWS',
        allowUri: false,
        allowTimezone: false,
        allowInsecureSkipVerify: false,
        allowAuth: ['iam', 'password'],
        allowRemediation: true,
        remediationNeedsQueryableDevice: false,
        envAuth: true,
        requireRegion: true,
        defaults: {
          credentialType: 'iam'
        }
      }
    }
  },
  'Dns': {
    label: 'Reverse DNS',
    desc: 'Translates IP addresses to host names',
    icon: 'dns',
    vendors: {
      'protectwise': {
        label: '',
        allowUri: false,
        allowTimezone: false,
        allowInsecureSkipVerify: false,
        allowAuth: false,
        defaults: {}
      }
    }
  },
  // 'ThreatIntel': {
  //   label: 'File Analysis',
  //   desc: 'Provides file based analytics and events',
  //   icon: 'RecordedFuture',
  //   vendors: {
  //     'RecordedFuture': {
  //       label: 'Recorded Future',
  //       allowUri: true,
  //       allowTimezone: true,
  //       allowInsecureSkipVerify: true,
  //       allowAuth: true,
  //       defaults: {}
  //     }
  //   }
  // }
  //TODO 'AuthServer',
  //TODO 'ProxyServer'
}

export const deviceConnectionMessages = {
  Connected: null,
  PipBoyOffline: 'Could not connect because the configured proxy has no online instances.',
  //PipBoyNotProvisioned: '',
  //NoMarriedPipBoys: 'No proxy connection has been defined for this source. Drag and drop the (+) to make a connection.',
  DeviceOffline: 'Could not connect to the integration source. Please check its configuration.',
  //DeviceNotProvisioned: '',
  DeviceCannotValidateCert: `Certificate failed validation. You can either:
(a) fix the source to use a valid trusted certificate, or
(b) uncheck the "Require Validated Certificate" option in the source's config.`,
  DeviceDidNotSendStatus: 'Could not connect to the integration source.',
  DeviceInvalidCredentials: 'Credentials are invalid. Please update them in the integration source\'s config.',
  DeviceAccessForbidden: 'The integration proxy refused to connect to this source. Please check that the source\'s configured URI is correct.',
  DeviceConnectionRefused: 'Could not connect with the configured credentials. Please check that they are correctly configured.',
  DeviceCannotTestConnection: 'Could not test the integration source connection. Please check its configuration.'
}

export const proxyOfflineMessage = 'No proxy instances are online. Make sure you have installed a proxy using the provided Install Key, and that it is running.'

export const DEVICE_VENDORS = {
  PAN: 'PAN',
  CB: 'CarbonBlack',
  AWS: 'AWS'
}

export const DEVICE_TYPE_TO_RESOURCE_TYPE = {
  AWS: 'aws/ec2Instances/favorites',
  PAN: 'pan/addressGroups/favorites'
}

export const AWS_REGIONS = {
  'us-east-2': 'US East (Ohio)',
  'us-east-1': 'US East (N. Virginia)',
  'us-west-1': 'US West (N. California)',
  'us-west-2': 'US West (Oregon)',
  'ca-central-1': 'Canada (Central)',
  'ap-south-1': 'Asia Pacific (Mumbai)',
  'ap-northeast-2': 'Asia Pacific (Seoul)',
  'ap-southeast-1': 'Asia Pacific (Singapore)',
  'ap-southeast-2': 'Asia Pacific (Sydney)',
  'ap-northeast-1': 'Asia Pacific (Tokyo)',
  'eu-central-1': 'EU (Frankfurt)',
  'eu-west-1': 'EU (London)',
  'sa-east-1': 'South America (São Paulo)'
}

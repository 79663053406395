import {initImmersiveData} from 'data/immersiveData'
import {streamUtil, immersiveData} from 'pw-immersive-data'
import PropertyStream from 'pw-property-stream'

const streamingClient = initImmersiveData()

const statusOutput = PropertyStream({paired: false, connected: false})
export const useHeadsetStatusStream = streamUtil.useStateStream.bind(null, statusOutput)

if (streamingClient) {
  const statusStream = streamingClient.create({
    name: "headset-status",
    path: "headset-status"
  })
  statusStream.pipe(statusOutput)
}

export const pairHeadset = (code) => {
  immersiveData.authStream.writeData({action: 'pair', code})
}

export const unpairHeadset = () => {
  immersiveData.authStream.writeData({action: 'unpair'})
}

import { hot } from 'react-hot-loader/root'
import _ from 'lodash'
import T from 'prop-types'
import React from 'react'
import cx from 'classnames'
import {
  ErrorState,
  RouteAware,
  DimensionAware,
  SimpleTooltips,
  StoreProvider,
  ErrorBoundary
} from 'react-base'
import AppLoadingIndicator from './AppLoadingIndicator'
import VersionStore from 'stores/VersionStore'
import DataManager from 'data/DataManager'
import NavBar from './nav/NavBar'
import LogViewerPane from './logs/LogViewerPane'
import SensorCommandsPane from './SensorCommandsPane'
import Login from './account/Login'
import Signup from './account/Signup'
import IntelCardPane from './intelcard/IntelCardPane'
import Tooltip from './Tooltip'
import NotificationPane from './notifications/NotificationPane'
import ValueActionsDialog from './values/ValueActionsDialog'
import CommonViewActions from 'actions/CommonViewActions'
import PreferencesStore from 'stores/PreferencesStore'
import AnalyticsActions from 'actions/AnalyticsActions'
import { DndProvider } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import TouchBackend from 'react-dnd-touch-backend'
import UserActions from 'actions/UserActions'
import UserStore from 'stores/UserStore'
import UserProvider from 'components/UserProvider'
import SupportStore from 'stores/SupportStore'
import ValueActionsStore from 'stores/ValueActionsStore'
import getConfig from 'utils/uiConfig'
import Head from 'components/Head'
import DevWidget from 'components/DevWidget'
import { PcapDownloader } from 'components/pcap-downloader/PcapDownloader'
import { THEME_OPTIONS } from 'pwConstants'
import {
  Hud,
  Killbox,
  Explorer,
  ExplorerDuex,
  SitRep,
  Grid,
  Grid2,
  IntelManagement,
  Settings,
  SensorManagement,
  DeviceManagement,
  PasswordReset,
  CompleteRegistration,
  CustomerAdministration
} from 'components/Loadables'
// import CustomerAdministration from 'components/customer-admin/CustomerAdministration'
import AppcuesActions from 'actions/AppcuesActions'
import {getTraceUrl} from 'utils/restUtils'
import SensorStore from 'stores/SensorStore'

const ACCOUNT_ROUTES = {
  login: 1,
  signup: 1,
  password_reset: 1,
  complete_registration: 1
}

const SUPPORT_MANAGEMENT_ROUTES = {
  // support_management: 1, // Should not unset support customer, but should also not start live data
  'customer-admin': 1
}

const MAIN_ROUTES = {
  hud: 1,
  killbox: 1,
  explorer: 1,
  reporting: 1,
  grid: 1,
  grid2: 1,
  intel: 1,
  sensors: 1,
  devices: 1,
  settings: 1
}

const FULL_VIEW_ROUTES = {
  grid: true,
  grid2: true
}

// Polyfill ES6's Object.assign, which is used by CJSX's spread syntax
// if (!Object.assign) {
//   Object.assign = _.assign
// }

const THEME_OPTION_IDS = Object.keys(THEME_OPTIONS)

// Root component
class App extends React.Component {
  static displayName = 'App'

  static propTypes = {
    routeSubscriber: T.func.isRequired, //for RouteAware mixin

    height: T.number,
    width: T.number,

    route: T.object,

    animationEnabled: T.bool,
    themeId: T.oneOf(THEME_OPTION_IDS),
    zoomBaselinePx: T.string,
  }

  static defaultProps = {
    themeId: 'default',
    zoomBaselinePx: '16'
  }

  static childContextTypes = {
    errorStateDefaultSoft: T.bool,
    userIsSupportAccount: T.bool,
    userIsPWThreatTeam: T.bool,
    onModalDialogOpened: T.func,
    onModalDialogClosed: T.func,
    version: T.string,
    onErrorCaught: T.func,
    renderTraceURL: T.func
  }

  constructor(props) {
    super(props)
    // this._hasStartedLiveData = false
    this.state = {
      isLoading: false,
      liveDataStarted: false,
      error: null,
      // menuOpen: true,
      height: document.body.clientHeight,
      width: document.body.clientWidth
    }
  }

  getChildContext() {
    return {
      // Flag for whether all ErrorState instances should use 'soft' styling by default
      errorStateDefaultSoft: !UserStore.isSupportCustomer(),
      userIsSupportAccount: UserStore.isSupportCustomer(),
      userIsPWThreatTeam: window._pw.isPwThreatTeam,
      onModalDialogOpened: this._onModalDialogOpened,
      onModalDialogClosed: this._onModalDialogClosed,
      version: VersionStore.getInitialState().currentVersionDisplay, // Used in error stacks produced by react-base/ErrorBoundary
      onErrorCaught: this._onErrorCaught,
      renderTraceURL: getTraceUrl
    }
  }

  UNSAFE_componentWillMount() {
    this._activeModalDialogs = 0
  }

  componentDidMount() {
    this._updatePrintPageSize()

    this._onRouteChange(this.props.route)

    this._syncTheme(this.props)
    this._syncZoom(this.props)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.height !== this.props.height ||
      nextProps.width !== this.props.width
    ) {
      this._onResize()
    }
    if (nextProps.route !== this.props.route) {
      this._onRouteChange(nextProps.route, this.props.route)
    }
    if (nextProps.themeId !== this.props.themeId) {
      this._syncTheme(nextProps)
    }
    if (nextProps.zoomBaselinePx !== this.props.zoomBaselinePx) {
      this._syncZoom(nextProps)
    }
  }

  componentWillUnmount() {
    CommonViewActions.Time.stop()
    clearInterval(this._lastPassHackTimer)
  }

  _syncTheme = ({themeId}) => {
    window.document.body.className = `theme_${themeId}`
  }

  _syncZoom = ({zoomBaselinePx}) => {
    window.document.documentElement.style.fontSize = `${zoomBaselinePx}px`
  }

  _onErrorCaught = () => {
    AnalyticsActions.exception(
      { exDescription: `RUNTIME ERROR`}
    )
  }

  _forceReload = () => {
    const prevLoading = this.isLoading
    this.setState({ isLoading: true }, () => {
      this.setState({ isLoading: prevLoading })
    })
  }

  _onResize = () => {
    this.setState({
      height: document.body.clientHeight,
      width: document.body.clientWidth
    })
    this._updatePrintPageSize()
  }

  // Handle route changes
  _onRouteChange = (newRoute, oldRoute) => {
    if (
      oldRoute &&
      MAIN_ROUTES.hasOwnProperty(oldRoute.basePath) &&
      ACCOUNT_ROUTES.hasOwnProperty(newRoute.basePath) &&
      SUPPORT_MANAGEMENT_ROUTES.hasOwnProperty(newRoute.basePath)
    ) {
      UserActions.unsetEmulatedCustomerIfEmulating()
    }
    // Any of the main routes need to trigger startLiveData
    if (MAIN_ROUTES.hasOwnProperty(newRoute.basePath)) {
      if (!this.state.liveDataStarted) {
        this.setState({
          isLoading: true,
          error: null
        })
        DataManager
          .startLiveData()
          .catch(err => {
            console.error('UI - error during startLiveData()', err, err.stack)
            this.setState({
              isLoading: false,
              error: {
                heading: 'Error',
                body: err.message || err
              }
            })
          })
          .then(() => {
            // this._hasStartedLiveData = true
            this.setState({ isLoading: false, liveDataStarted: true })
          })
          .catch(err => {
            console.error('App rendering error', err, err.stack)
          })
      }

      // Hack to kill off LastPass login overlay that gets stuck sometimes
      // Don't know exactly when it's inserted by LP's code so using a Big Hammer here
      if (!this._lastPassHackTimer) {
        this._lastPassHackTimer = setInterval(function() {
          const iframes = document.querySelectorAll('iframe[src*="contentScriptDialog.html"]')
          if (iframes.length) {
            _.forEach(iframes, iframe => {
              const div = iframe.parentNode
              div.parentNode.removeChild(div)
            })
          }
        }, 5000)
      }
    }

    // Fire analytics event if changing page
    if (!oldRoute || oldRoute.fullPath !== newRoute.fullPath) {
      const event = { page: `/${newRoute.fullPath}` }
      if (event.page === '/login') {
        Object.assign(event, { sessionControl: 'start' })
      }
      AnalyticsActions.pageView(event)

      // Signal page changes to Appcues, with a delay to allow things some time to load
      // TODO: this allows the URL to get out of sync, but prevents issues with URLs changing in
      // quick succession due to intra-section state changes. Should revisit.
      setTimeout(AppcuesActions.setPage, 1000)
    }
  }

  /**
   * This inserts print media styles that sync the dimensions of the printed page area to the same size as the
   * current browser window, so that what is printed is always what the user sees in the browser and we don't
   * get issues with JS-managed elements not being synced to a different page size. Also, setting the @page
   * size to the same dimensions[*] triggers the browser to automatically choose the correct page orientation
   * and scale the content to fit the actual paper size.
   * ([*] We actually have to add ~50px in the @page height or Chrome will give us a blank second page.
   */
  _updatePrintPageSize = () => {
    let styleEl = document.getElementById('pw_print_page_size')
    if (!styleEl) {
      styleEl = document.createElement('style')
      styleEl.setAttribute('media', 'print')
      styleEl.id = 'pw_print_page_size'
      document.head.appendChild(styleEl)
    }

    const width = window.innerWidth
    const height = window.innerHeight
    styleEl.innerHTML = `
      @page {
        size: ${width}px ${height + 50}px
      }
      html {
        width: ${width}px;
        height: ${height}px;
      }
    `
  }

  _onModalDialogOpened = () => {
    this._activeModalDialogs++
    this.setState({
      modalOpen: true
    })
  }

  _onModalDialogClosed = () => {
    this._activeModalDialogs--
    if (this._activeModalDialogs <= 0) {
      this._activeModalDialogs = 0
      this.setState({
        modalOpen: false
      })
    }
  }

  renderMainContent = (routeName) => {
    const { width } = this.state
    const uiConfig = getConfig()
    let out = null
    switch (routeName) {
      case 'hud':
        out = <Hud themeId={this.props.themeId} />
        break
      case 'killbox':
        out = (
          <Killbox
            hasPermission={UserStore.hasPermission}
            width={width}
          />
        )
        break
      case 'explorer':
        if (uiConfig.explorerDuex?.enabled) {
          out = (
            <StoreProvider store={SensorStore}>
              <ExplorerDuex />
            </StoreProvider>
          )
        } else {
          out = (
            <Explorer hasPermission={UserStore.hasPermission} />
          )
        }
        break
      case 'reporting':
        out = (
          <SitRep />
        )
        break
      case 'grid':
        if (
          uiConfig.enableGridDemo === true &&
          (UserStore.isDemoCustomer() || localStorage.enableGridTabNonDemo)
        ) {
          out = <Grid />
        }
        break
      case 'grid2':
        if (localStorage.enableGrid2) {
          out = <Grid2 />
        } else {
          out = null
        }
        break
      case 'intel':
        out = (
          <UserProvider>
            <IntelManagement />
          </UserProvider>
        )
        break
      case 'sensors':
        out = <SensorManagement />
        break
      case 'devices':
        out = <DeviceManagement />
        break
      case 'settings':
        out = (
          <UserProvider>
            <Settings />
          </UserProvider>
        )
        break
      default:
        out = <div>Invalid Route</div>
    }

    return (
      <ErrorBoundary>
        {out}
      </ErrorBoundary>
    )
  }

  render() {
    const {
      route,
      animationEnabled
    } = this.props
    const {
      error,
      modalOpen,
      isLoading,
      liveDataStarted
    } = this.state
    const routeName = route.basePath
    const isFullView = !!FULL_VIEW_ROUTES[route.fullPath]
    const uiConfig = getConfig()

    const rootClasses = cx(
      'view_wrap main_app',
      animationEnabled ? 'allow_animation' : 'no_animation',
      isFullView && 'full_view',
      window._pwCompatibility.standaloneApp && 'standalone_app',
      modalOpen && 'modal_open'
    )

    const dom = (
      <div className={rootClasses} key="appRoot">
        <Head routeName={routeName} />
        {error
          ? <ErrorState error={error} key="app_error" />
          : isLoading
            ? <AppLoadingIndicator key="app_loading" />
            : MAIN_ROUTES.hasOwnProperty(routeName)
              ? !liveDataStarted
                ? <AppLoadingIndicator key="app_loading" />
                : <div
                  className={`view_wrap ${route.logViewerParam
                    ? 'has_open_log_viewer'
                    : ''}`}
                  key="app_main"
                >
                  <UserProvider>
                    <NavBar
                      activeRoute={routeName}
                      themeId={this.props.themeId}
                    />
                  </UserProvider>
                  <div className={cx(`tab_region tab_` + routeName)}>
                    {this.renderMainContent(routeName)}
                  </div>
                  <IntelCardPane />
                  <LogViewerPane />
                  <SensorCommandsPane
                    hasPermission={UserStore.hasPermission}
                  />
                  <StoreProvider store={ValueActionsStore}>
                    <ValueActionsDialog
                      hasPermission={UserStore.hasPermission}
                    />
                  </StoreProvider>
                  <PcapDownloader />
                </div>
              : ACCOUNT_ROUTES.hasOwnProperty(routeName)
                ? <div className="account_region" key="app_account">
                  { routeName === 'login' ? <Login key="login" />
                  : routeName === 'signup' ? <Signup key="signup" />
                  : routeName === 'password_reset' ? <PasswordReset key="password_reset" />
                  : routeName === 'complete_registration' ? <CompleteRegistration key="complete_registration" />
                  : null}
                </div>
                : SUPPORT_MANAGEMENT_ROUTES.hasOwnProperty(routeName)
                ? <UserProvider mapping={['isMSSPCustomer', 'isSupportCustomer']}>
                    <CustomerAdministration />
                  </UserProvider>
                  : <ErrorState
                    error={{
                      heading: 'Page not found',
                      body: (
                        <span>
                          Sorry, we could not find the requested page.{' '}
                          <a href="#hud">Home</a>
                        </span>
                      )
                    }}
                    key="app_error"
                    soft={false}
                  />}
        <svg style={{ width: 0, height: 0 }}>
          <defs id="global_defs" />
        </svg>
        <Tooltip />
        <StoreProvider store={SupportStore}>
          <UserProvider mapping={['isSupportCustomer']}>
            <NotificationPane
              forceAllActiveSystemNotifications={ACCOUNT_ROUTES.hasOwnProperty(
                routeName
              )}
            />
          </UserProvider>
        </StoreProvider>
        {process.env.DEV_SERVER_ACTIVE && <DevWidget />}
      </div>
    )
    return (
      <DndProvider
        backend={window._pwCompatibility.isTouchscreen ? TouchBackend : HTML5Backend}
        options={{ enableMouseEvents: true }}
      >
        <ErrorBoundary>
          <SimpleTooltips>
            {dom}
          </SimpleTooltips>
        </ErrorBoundary>
      </DndProvider>
    )
  }
}

const mapPrefsStoreToApp = storeState => ({
  animationEnabled: storeState.preferences.animationsEnabled !== 'disabled',
  themeId: storeState.preferences.theme,
  zoomBaselinePx: storeState.preferences.zoomBaselinePx
})

const AppWithStores = (props) => (
  <StoreProvider store={PreferencesStore} mapping={mapPrefsStoreToApp}>
    <App {...props} />
  </StoreProvider>
)

const EnhancedApp = DimensionAware(RouteAware(AppWithStores))

// let AppWithDndProvider = null

// if (window._pwCompatibility.isTouchscreen) {
//   // Keeping enableMouseEvents enabled in case this trips on false positives
//   AppWithDndProvider = DndProvider(
//     TouchBackend({ enableMouseEvents: true })
//   )(EnhancedApp)
// } else {
//   // Wrap it with the HTML5 drag-drop implementation
//   AppWithDndProvider = DndProvider(HTML5Backend)(EnhancedApp)
// }

export default hot(EnhancedApp)

/*eslint no-unused-vars: [1, { "varsIgnorePattern": "React" }]*/
import _ from 'lodash'
import React from 'react'
import T from 'prop-types'
import constants, { FILE_TYPES } from 'pwConstants'
import {
  Schema,
  TYPES
} from 'pw-schemer'
import {
  getStringLengthValidator
} from 'pw-validators'

const {
  killchainStagesToNames,
  threatCategoriesToNames,
  killchainStagesToShortNames
} = constants

export const SURICATA_VERSION = "4.0"

export const DEFAULT_UI_QUERY = '' //"targetMode=published AND status=pending"

// export const MAX_EDITABLE_INTEL_ROWS = 80
export const RULE_MAX_DESCRIPTION_CHARACTERS = 5000 // From planck

export const ALLOW_QUERY_FOR_UNKNOWN_FIELDS = false

export const INTEL_MODE_PROFILING_AVAILABLE = false // Coming soon™
export const IDS_INTEL_OVERRIDE_USES_BEHAVIOR_WHITELIST = false


export const PROTECTWISE_OWNED_CID = 0
export const DEFAULT_SUBSCRIPTION_SENSOR_ID = 0 // SensorID that the protectwise default subscription will be assoicated with
export const THREAT_TEAM_CID = 1796 // FIXME GROSS - should be supplied by planck as a perm/role

export const MAX_LIST_NAME_LENGTH = 300
export const INTEL_RULE_DETAIL_HISTORY_PAGE_SIZE = 15
export const INTEL_MAX_USER_LISTS = 10
export const BULK_RULE_EDIT_SAVE_WARN_THRESHOLD = 1000 // If saving over 1000 rules, warn that it might take a while

export const PENDING_TRANSACTIONS_POLL_INTERVAL = 3000 // While there are pending transactions in localStorage, poll for state updates at this frequency

export const INTEL_RULE_SAVE_MINUTES = '15'

export const INTEL_LIST_QUICK_ADD_DEFAULT_TAG = 'default-quick-add'

export const INTEL_LISTS_READ_PERMISSION = 'intel-lists:read'
export const INTEL_LISTS_CREATE_PERMISSION = 'intel-lists:create'
export const INTEL_LISTS_UPDATE_PERMISSION = 'intel-lists:update'
export const INTEL_LISTS_DELETE_PERMISSION = 'intel-lists:delete'
export const INTEL_LISTS_AVAILABILITY_PERMISSION = 'intel-lists:for-customers'

export const INTEL_SUBSCRIPTION_GET_PERMISSION = 'intel-subscriptions:get'
export const INTEL_SUBSCRIPTION_SET_PERMISSION = 'intel-subscriptions:set'

export const INTEL_RULES_READ_PERMISSION = 'intel-rules:read'
export const INTEL_RULES_CREATE_PERMISSION = 'intel-rules:create'
export const INTEL_RULES_UPDATE_PERMISSION = 'intel-rules:update'
export const INTEL_RULES_DELETE_PERMISSION = 'intel-rules:delete'

export const INTEL_VALIDATION_STAGES = {
  'vericata-rule-validation-stage': 'Intel Rule Validation Error',
  'threat-mapping': 'Threat Mapping'
}

export const INTEL_VALIDATION_STATES = {
  VALID: 'Valid', // 1
  INVALID: 'Invalid', // 2
  PENDING: 'Pending' // 3
}

export const IMS_COLUMN_PREFERENCE_KEY_OLD = 'intelManagementColumns'
export const IMS_COLUMN_PREFERENCE_KEY = 'intelManagementColumnsByType'
export const IMS_COLUMN_WIDTH_PREFERENCE_KEY = 'intelManagementColumnWidths'
export const IMS_REV_MANAGEMENT_PREFERENCE_KEY =
  'intelManagementIDSRevManagement'
export const IMS_PENDING_TRANSACTIONS_KEY = 'intelManagementPendingTransactions'

export const INTEL_SUBSCRIPTION_DND_ID = 'intel_sub_dnd_id'

export const INTEL_LIST_SET_IDS = {
  EDITOR: 'editor',
  USER: 'user',
  PW: 'pw'
}

export const INTEL_EDIT_MODES = {
  MAPPINGS: 'mappings',
  COMPARE: 'compare'
}

export const API_ALLOWED_PROPS_INTEL_LIST = [
  'id',
  'name',
  'description',
  'vendor'
  // 'listPurpose',
  // 'forCustomers', // Only set based on for-customers permission
  // 'tags',
  // 'created''
  // 'updated',
  // 'uploadId',
  // 'isImmuneToCircuitBreaker',
  // 'owner'
]

export const INTEL_TYPE_AVAILABILITY = {
  HIDDEN: 0,
  AVAILABLE: 1,
  COMING_SOON: 2
}

export const CIDS_CAN_SEE_LIMITED_ACCESS_INTEL = [
  THREAT_TEAM_CID,
  1794, // DaveGold
  1819, // KellyBrazil
  1808, // PROD ThreatVerification
  1942, // POC ThreatVerification
  2140, // <cust specific> ThreatVerification
  2670 // Demo ThreatVerification
  // 1806, // ProtectWise Demo (POC/Demo envs)
  // 2240 // ProtectWise Demo Prod (Prod env)
  // NOTE not using window._pw.isDemoCustomer because product does not want this out to CustomerDemo
]

const canCustomerSeeLimitedAccessIntel = cid => {
  return CIDS_CAN_SEE_LIMITED_ACCESS_INTEL.indexOf(cid) !== -1
}

export const INTEL_TYPES = {
  ids: {
    isAvailable: () => INTEL_TYPE_AVAILABILITY.AVAILABLE,
    isInputAvailable: () => INTEL_TYPE_AVAILABILITY.AVAILABLE,
    defaultSortDir: 'desc',
    defaultSortKey: 'key',
    desc: '',
    display: 'IDS / Payload',
    info: `Suricata version: ${SURICATA_VERSION}`,
    enum: 0,
    exclusiveFields: [
      'ids.sid',
      'ids.gid',
      'ids.msg',
      'ids.classtype',
      'ids.rule',
      'ids.references'
    ],
    id: 'Ids',
    observationType: 'Ids'
  },
  ip: {
    isAvailable: () => INTEL_TYPE_AVAILABILITY.AVAILABLE,
    isInputAvailable: () => INTEL_TYPE_AVAILABILITY.AVAILABLE,
    defaultSortDir: 'desc',
    defaultSortKey: 'key',
    desc: 'IP Address reputation.',
    display: 'IP',
    enum: 1,
    exclusiveFields: [
      'ip.ip'
    ],
    id: 'Ip',
    observationType: 'IpReputation'
  },
  uri: {
    isAvailable: () => INTEL_TYPE_AVAILABILITY.AVAILABLE,
    isInputAvailable: () => INTEL_TYPE_AVAILABILITY.AVAILABLE,
    defaultSortDir: 'asc',
    defaultSortKey: 'key',
    desc: 'URI / URL reputation.',
    display: 'URI / URL',
    enum: 2,
    exclusiveFields: [
      'uri.uri_name',
      'uri.references'
    ],
    id: 'Uri',
    observationType: 'UrlReputation'
  },
  domain: {
    isAvailable: () => INTEL_TYPE_AVAILABILITY.AVAILABLE,
    isInputAvailable: () => INTEL_TYPE_AVAILABILITY.AVAILABLE,
    defaultSortDir: 'asc',
    defaultSortKey: 'key',
    desc: 'Domain reputation.',
    display: 'Domain',
    enum: 3,
    exclusiveFields: [
      'domain.domain_name',
      'domain.references'
    ],
    id: 'Domain',
    observationType: 'DnsReputation'
  },
  filehash: {
    isAvailable: () => INTEL_TYPE_AVAILABILITY.AVAILABLE,
    isInputAvailable: () => INTEL_TYPE_AVAILABILITY.AVAILABLE,
    defaultSortDir: 'asc',
    defaultSortKey: 'key',
    desc: 'File Hash.',
    display: 'File Hash',
    enum: 4,
    exclusiveFields: [
      'file.file_hash',
      'file.references',
      'file.file_hash_type'
    ],
    id: 'FileHash',
    observationType: 'FileReputation'
  },
  certificate: {
    isAvailable: () => INTEL_TYPE_AVAILABILITY.AVAILABLE,
    isInputAvailable: () => INTEL_TYPE_AVAILABILITY.AVAILABLE,
    defaultSortDir: 'asc',
    defaultSortKey: 'key',
    desc: 'SSL Certificate Hash.',
    display: 'Cert Hash',
    enum: 5,
    exclusiveFields: [
      'cert.cert_hash',
      'cert.cert_hash_type',
      'cert.references',
      'cert.cert_issued',
      'cert.cert_expires',
      'cert.cert_ssl_version',
      'cert.cert_subject',
      'cert.cert_issuer',
      'cert.cert_subject_common_name',
      'cert.cert_issuer_common_name',
      'cert.cert_alt_names',
      'cert.cert_name',
      'cert.cert_serial_number'
    ],
    id: 'Certificate',
    observationType: 'CertReputation'
  },
  yara: {
    isAvailable: () => INTEL_TYPE_AVAILABILITY.AVAILABLE,
    isInputAvailable: () => INTEL_TYPE_AVAILABILITY.HIDDEN,
    defaultSortDir: 'asc',
    defaultSortKey: 'key',
    desc: 'Yara',
    display: 'Yara',
    enum: 6,
    exclusiveFields: [
      'yara.yara_name',
      'yara.yara_metadata',
    ],
    id: 'Yara',
    observationType: 'FileReputation'
  }
  // none: {
  //   enum: -1,
  //   id: 'None',
  //   display: "None",
  //   desc: "",
  //   isAvailable: () => INTEL_TYPE_AVAILABILITY.HIDDEN,
  //   defaultSortKey: "key",
  //   defaultSortDir: "desc",
  //   observationType: ""
  // },
}

// Missing obs types: 'CertReputation', 'AnomalousFlow'

export const INTEL_TYPE_OPTIONS = _.reduce(
  INTEL_TYPES,
  (out, val, key) => {
    out[key.toUpperCase()] = val.id
    return out
  },
  {}
)

// export const INTEL_TYPES_AVAILABLE = _.filter(INTEL_TYPES, t => t.available !== INTEL_TYPE_AVAILABILITY.HIDDEN)
// export const INTEL_TYPES_AVAILABLE_IDS = _.reduce(INTEL_TYPES_AVAILABLE, (out, t) => {
//   out[t.id.toUpperCase()] = 1
//   return out
// }, {})

export const INTEL_TYPE_DEFAULT = INTEL_TYPE_OPTIONS.IDS

export const HASH_TYPES = _.mapValues(
  {
    MD5: {
      enum: 1,
      display: 'MD5',
      desc: ''
    },
    SHA1: {
      enum: 2,
      display: 'SHA1',
      desc: ''
    },
    SHA256: {
      enum: 3,
      display: 'SHA256',
      desc: ''
    },
    SHA512: {
      enum: 4,
      display: 'SHA512',
      desc: ''
    }
  },
  (h, key) => {
    h.id = key
    return h
  }
)

export const HASH_TYPE_OPTIONS = _.reduce(
  HASH_TYPES,
  (out, val, key) => {
    out[key.toUpperCase()] = val.id
    return out
  },
  {}
)

export const HASH_TYPE_OPTION_DEFAULT = 'MD5'


export const CERT_HASH_TYPES = _.pick(HASH_TYPES, 'MD5', 'SHA1')

export const CERT_HASH_TYPE_OPTIONS = _.pick(HASH_TYPE_OPTIONS, 'MD5', 'SHA1')

export const CERT_HASH_TYPE_OPTION_DEFAULT = 'MD5'



export const INTEL_LIST_PURPOSE = _.mapValues(
  {
    NewlyCreatedDomainGlobalWhitelist: {
      enum: 1,
      display: 'Newly Created Domain Global Whitelist'
    },
    DNSDGAGlobalWhitelist: {
      enum: 3,
      display: 'DNS DGA Global Whitelist'
    },
    URLRepGlobalWhitelist: {
    enum: 5,
      display: 'URL Rep Global Whitelist'
    },
    IPRepGlobalWhitelist: {
      enum: 6,
      display: 'IP Rep Global Whitelist'
    },
    RuleCappingWhitelist: {
      enum: 7,
      display:
        'Verizon Capped Rules (automatically added). If Intel Rules are being triggered too frequently, they will be disabled here.'
    },
    CertOrganizationGlobalWhitelist: {
      enum: 8,
      display: 'Cert Organization Global Whitelist'
    },
    CertIssuerGlobalWhitelist: {
      enum: 9,
      display: 'Cert Issuer Global Whitelist'
    },
    CertIssuerRegexGlobalWhitelist: {
      enum: 10,
      display: 'Cert Issuer Regex Global Whitelist'
    },
    CertCommonGlobalWhitelist: {
      enum: 11,
      display: 'Cert Common Global Whitelist'
    }
  },
  (h, key) => {
    h.id = key
    return h
  }
)

export const INTEL_LIST_PURPOSE_IDS = _.reduce(
  INTEL_LIST_PURPOSE,
  (out, val, key) => {
    out[key.toUpperCase()] = val.id
    return out
  },
  {}
)

export const INTEL_LIST_PURPOSE_ENUM_TO_ID = _.reduce(
  INTEL_LIST_PURPOSE,
  (out, val) => {
    out[val.enum] = val.id
    return out
  },
  []
)

export const DEFAULT_SORT_KEY_BY_TYPE = _.reduce(
  INTEL_TYPES,
  (out, type) => {
    out[type.id] = type.defaultSortKey
    return out
  },
  {}
)
export const DEFAULT_SORT_DIR_BY_TYPE = _.reduce(
  INTEL_TYPES,
  (out, type) => {
    out[type.id] = type.defaultSortDir
    return out
  },
  {}
)

export const DEFAULT_SORT_KEY = DEFAULT_SORT_KEY_BY_TYPE[INTEL_TYPE_DEFAULT]
export const DEFAULT_SORT_DIR = DEFAULT_SORT_DIR_BY_TYPE[INTEL_TYPE_DEFAULT]

export const INTEL_BEHAVIOR_OPTIONS = {
  Blacklist: {
    display: 'Blacklist',
    desc:
      'Blacklisted Intel Rules will produce observations when encountered in network traffic.',
    id: 'Blacklist',
    enum: 0
  },
  Whitelist: {
    display: 'Whitelist',
    desc:
      'Whitelisted Intel Rules will not produce observations, even if a similar lower-priority Intel Rule would normally trigger one.',
    id: 'Whitelist',
    enum: 1
  }
}

export const INTEL_BEHAVIOR_OPTION_IDS = _.mapKeys(
  _.mapValues(INTEL_BEHAVIOR_OPTIONS, 'id'),
  k => k.toUpperCase()
)

export const INTEL_BEHAVIOR_OPTION_DEFAULT = 'Blacklist'

export const INTEL_STATES = {
  invalid: {
    enum: 3,
    id: 'Invalid',
    display: 'Invalid',
    desc: 'Rule did not pass validation, or exceeded performance budget.'
  },
  enabled: {
    enum: 1,
    id: 'Enabled',
    display: 'Enabled',
    desc: 'Rule enabled.'
  },
  disabled: {
    enum: 2,
    id: 'Disabled',
    display: 'Disabled',
    desc: 'Rule disabled.'
  },
  pending: {
    enum: 4,
    id: 'Pending',
    display: 'Pending',
    desc: 'Pending.'
  },
  capped: {
    enum: 5,
    id: 'Capped',
    display: 'Capped',
    desc: 'Rule exceeded performance thresholds.'
  }
}

export const INTEL_STATE_OPTIONS = _.reduce(
  INTEL_STATES,
  (out, val, key) => {
    out[key.toUpperCase()] = val.id
    return out
  },
  {}
)

export const USER_EDITABLE_STATUS_VALUES = _.map(
  _.pick(INTEL_STATES, 'enabled', 'disabled'),
  s => s.id
)

const _intelModes = {
  draft: {
    enum: 10,
    id: 'Draft',
    display: 'Draft',
    desc: 'Draft intel rules that are not active.'
  },
  published: {
    enum: 30,
    id: 'Published',
    display: 'Published',
    desc: `Actively run against network data in real-time.`
  }
}

if (INTEL_MODE_PROFILING_AVAILABLE) {
  _intelModes['profiling'] = {
    enum: 20,
    id: 'Profiling',
    display: 'Profiling',
    desc: 'Pending performance analysis.'
  }
}

export const INTEL_MODE_OPTIONS = _intelModes

export const INTEL_MODES = _.reduce(
  INTEL_MODE_OPTIONS,
  (out, val, key) => {
    out[key.toUpperCase()] = val.id
    return out
  },
  {}
)

export const INTEL_DEFAULT_TARGET_MODE = INTEL_MODES.PUBLISHED

export const INTEL_NEW_LIST_ID = '_NEW_LIST_ID'

export const INTEL_TABLE_EDIT_ALL_KEYWORD = 'ALL'

export const MAX_OVERVIEW_SELECTED_LISTS = 5
export const OVERVIEW_HISTORY_EXPANDED_PAGE_SIZE = 30

export const IMS_THREAT_CATEGORIES = [
  'ExploitsAndAttacks',
  'DenialOfService',
  'Malware',
  'Apt',
  'Scanning',
  'Phishing',
  'Botnet',
  'MaliciousHost',

  'Suspicious',
  'Misc',
  'Unknown'

  // "Adware": 2,
  // "Apt": 16,
  // "Botnet": 12,
  // "Botnets": 3,
  // "DenialOfService": 9,
  // "Downloader": 7,
  // "ExploitsAndAttacks": 8,
  // "MaliciousDocument": 5,
  // "MaliciousHost": 15,
  // "MaliciousWebpage": 6,
  // "Malware": 10,
  // "Misc": 17,
  // "Phishing": 13,
  // "Scanner": 4,
  // "Scanning": 11,
  // "Suspicious": 14,
  // "Trojan": 0,
  // "Unknown": 18,
  // "Worm": 1
]

export const IMS_THREAT_CATEGORIES_TO_NAMES = _.reduce(
  IMS_THREAT_CATEGORIES,
  (out, cat) => {
    out[cat] = threatCategoriesToNames[cat.toLowerCase()]
    return out
  },
  {}
)

export const IMS_KILLCHAIN_STAGES = [
  'Recon',
  'Delivery',
  'Exploit',
  'Beacon',
  'Cnc',
  'Fortification',
  'DataTheft'
]

export const IMS_KILLCHAIN_STAGES_THREAT = [
  'Recon',
  'Delivery',
  'Exploit',
  'Beacon',
  'Cnc',
  'Fortification',
  'DataTheft',
  'Methodology'
]

export const IMS_KILLCHAIN_STAGES_TO_NAMES = _.reduce(
  IMS_KILLCHAIN_STAGES,
  (out, kcStage) => {
    out[kcStage] = killchainStagesToNames[kcStage.toLowerCase()]
    return out
  },
  { Methodology: 'Methodology' }
)

export const IMS_KILLCHAIN_STAGES_TO_SHORT_NAMES = _.reduce(
  IMS_KILLCHAIN_STAGES,
  (out, kcStage) => {
    out[kcStage] = killchainStagesToShortNames[kcStage.toLowerCase()]
    return out
  },
  { Methodology: 'Methodology' }
)

export const IMS_THREAT_LEVELS = ['None', 'Low', 'Medium', 'High']

export const THREAT_LEVEL_ENUM = ['none', 'low', 'medium', 'high']

export const INTEL_MOVE_DIALOG_MODES = {
  CLOSED: 'closed',
  MOVE: 'move',
  COPY: 'copy'
}

export const INTEL_THREAT_SUB_CATEGORIES = [
  'ActiveX', //: 0,
  'Adware', //: 43,
  'Android', //: 83,
  'Anomaly', //: 84,
  'Anonymizer', //: 65,
  'Application', //: 37,
  'Apt', //: 85,
  'Attack', //: 86,
  'Backdoor', //: 44,
  'Banking', //: 87,
  'Botnet', //: 88,
  'Browser', //: 2,
  'BruteForce', //: 56,
  'BufferOverflow', //: 3,
  'Certificate', //: 89,
  'Chat', //: 90,
  'Clickfraud', //: 132,
  'CodeInjection', //: 19,
  'CommandExecution', //: 4,
  'CrossSiteScripting', //: 5,
  'CurrentEvents', //: 36,
  'DDoS', //: 38,
  'DNSTunneling', //: 77,
  'DataStorage', //: 91,
  'DirectoryTraversal', //: 7,
  'Dns', //: 8,
  'DoS', //: 6,
  'Doc', //: 30,
  'DocumentSoftware', //: 92,
  'Domain', //: 58,
  'DomainSimilarity', //: 93,
  'Downloader', //: 94,
  'DrivebyDownload', //: 95,
  'Dropper', //: 96,
  'EmailAddress', //: 97,
  'Executeable', //: 98,
  'Exploit', //: 99,
  'ExploitKit', //: 100,
  'FakeAntivirus', //: 45,
  'FakeSupport', //: 130,
  'FileInclusion', //: 9,
  'Flash', //: 32,
  'Flood', //: 39,
  'Ftp', //: 11,
  'General', //: 21,
  'Generic', //: 46,
  'Hash', //: 101,
  'IOS', //: 103,
  'IPv6', //: 104,
  'Imap', //: 29,
  'Info', //: 75,
  'Infrastructure', //: 102,
  'Ip', //: 42,
  'Irc', //: 105,
  'JavaScript', //: 106,
  'KeyLogger', //: 47,
  'LandingPage', //: 107,
  'Linux', //: 12,
  'MachineGeneratedDomain', //: 79,
  'MachineGeneratedSSLCertificate', //: 78,
  'Mail', //: 13,
  'MaliciousFile', //: 82,
  'Malvertising', //: 108,
  'Malware', //: 73,
  'Misc', //: 14,
  'Mobile', //: 52,
  'MultimediaSoftware', //: 109,
  'Netbios', //: 26,
  'NewIpOnStaticNetwork', //: 128,
  'NewlyCreatedDomain', //: 80,
  'OperatingSystem', //: 110,
  'Osx', //: 111,
  'Other', //: 40,
  'P2p', //: 67,
  'ParkedDomain', //: 68,
  'PasswordStealer', //: 129,
  'Pdf', //: 15,
  'Phishing', //: 112,
  'PhoneNumber', //: 113,
  'PointofSale', //: 114,
  'Policy', //: 74,
  'Pop3', //: 28,
  'PortScan', //: 53,
  'PrivilegeEscalation', //: 115,
  'ProtocolMisuse', //: 116,
  'Proxy', //: 64,
  'Pup', //: 117,
  'Ransomware', //: 118,
  'Rat', //: 119,
  'Reputation', //: 62,
  'Rootkit', //: 131,
  'Samba', //: 16,
  'Scada', //: 24,
  'Shellcode', //: 17,
  'Sinkhole', //: 70,
  'Snmp', //: 27,
  'Spam', //: 59,
  'SpamSourceURL', //: 61,
  'SpearPhishing', //: 120,
  'Spoofing', //: 18,
  'Spyware', //: 48,
  'SpywareAdwareURL', //: 72,
  'Sql', //: 121,
  'Ssh', //: 122,
  'StrategicWebCompromise', //: 123,
  'SuspiciousFile', //: 81,
  'Sweep', //: 124,
  'Targeted', //: 60,
  'Telnet', //: 22,
  'Tftp', //: 25,
  'Tor', //: 69,
  'Torrent', //: 125,
  'Trojan', //: 49,
  'Unknown', //: 76,
  'Url', //: 57,
  'UserAgent', //: 126,
  'Virus', //: 50,
  'Web', //: 23,
  'WebApplication', //: 41,
  'WebAttacksGeneric', //: 35,
  'WebAttacksIP', //: 34,
  'WebServer', //: 127,
  'Windows', //: 20,
  'WindowsExploitsIP', //: 33,
  'Worm', //: 51,
  'Xls' //: 31,
]

export const NUMBER_PRESETS = ['100', '75', '50', '25', '1']

export const REQUIRED_THREAT_MAPPINGS = [
  'killchainStage',
  'category',
  'severity',
  'confidence'
  // 'threatSubCategory', // Auto-filled by UI
  // 'threatLevel'
]

/**
* React Object shapes
*/

// IDS intel as suplied by API
// const _INTEL_RULE_RAW_SHAPE_OBJ = {
//   id: T.shape({
//     listId: T.string.isRequired,
//     ruleType: T.oneOf(_.map(INTEL_TYPES, 'id')).isRequired,
//     key: T.string.isRequired,
//     ruleMode: T.oneOf(_.values(INTEL_MODES).concat(['profiling'])).isRequired
//   }),
//   metadata: T.shape({
//     status: T.oneOf(_.map(INTEL_STATES, 'id')).isRequired,
//     tags: T.arrayOf(T.string).isRequired,
//     threatMapping: T.any, // FIXME update once known
//     vendor: T.string,
//     uploadId: T.string.isRequired,
//     created: T.number.isRequired,
//     updated: T.number.isRequired,
//     targetMode: T.oneOf(_.values(INTEL_MODES)).isRequired,
//     // targetStatus: T.oneOf(_.map(INTEL_STATES, 'id')) // TODO require when available
//   }),
//   // FIXME extend support for other intel types as they become available
//   intel: T.shape({
//     ids: T.shape({
//       rule: T.string.isRequired,
//       sid: T.number.isRequired,
//       gid: T.number.isRequired,
//       msg: T.string,
//       classtype: T.string
//     })
//   }),
// }

// IDS intel as provided by the Data Store (mapped/flattened)
const _INTEL_RULE_UI_SHAPE_OBJ = {
  _uiKey: T.string.isRequired,
  _needsThreatMappings: T.bool.isRequired,

  _isInSelectionSet: T.bool,
  _isEditableByCustomer: T.bool,
  _listName: T.string,
  _isUserOwned: T.bool,

  key: T.string.isRequired,
  uploadId: T.string.isRequired,
  listId: T.string.isRequired,
  vendor: T.string,
  tags: T.arrayOf(T.string).isRequired,
  description: T.string,
  validations: T.array,

  created: T.number,
  updated: T.number,

  intelCreatedAt: T.number, // Actually required, but CIA is not always returning it properly
  intelUpdatedAt: T.number,
  intelObservedAt: T.number,

  status: T.oneOf(_.map(INTEL_STATES, 'id')).isRequired,
  mode: T.oneOf(_.values(INTEL_MODES)).isRequired,
  targetMode: T.oneOf(_.values(INTEL_MODES)).isRequired,
  ruleType: T.oneOf(_.map(INTEL_TYPES, 'id')).isRequired,

  category: T.oneOf(IMS_THREAT_CATEGORIES),
  confidence: T.number,
  severity: T.number,
  killchainStage: T.oneOf(IMS_KILLCHAIN_STAGES.concat('Methodology')),

  // ruleType specific
  gid: T.number,
  sid: T.number,
  ruleBody: T.string,
  msg: T.string,
  classtype: T.string,
  references: T.arrayOf(T.string),

  domainName: T.string,

  uriName: T.string,

  fileHash: T.string,
  fileHashType: T.string,

  certHash: T.string,
  certHashType: T.string,
  certIssued: T.number,
  certExpires: T.number,
  certSslVersion: T.string,
  certSubject: T.string,
  certIssuer: T.string,
  certSubjectCommonName: T.string,
  certIssuerCommonName: T.string,
  certAltNames: T.arrayOf(T.string),
  certName: T.string,
  certSerialNumber: T.string
}

export const INTEL_LIST_SHAPE = T.shape({
  // API-provided (user-editable)
  id: T.string.isRequired, // GUID
  name: T.string.isRequired,
  description: T.string.isRequired,
  vendor: T.string,
  // tags: T.arrayOf(T.string).isRequired, // FIXME no longer supported?

  // API-provided (read-only)
  created: T.number.isRequired,
  updated: T.number, //.isRequired, // FIXME null allowed?
  uploadId: T.string, //.isRequired, // GUID
  isImmuneToCircuitBreaker: T.bool,
  forCustomers: T.arrayOf(T.number),
  owner: T.number,

  // Store-provided
  _isUserList: T.bool.isRequired, // Derived from `owner`
  _enabledPublishedCount: T.number.isRequired,
  _isEditableByCustomer: T.bool,
  _ruleCount: T.number.isRequired,
  _ruleCountsByStatus: T.object,
  _isDefaultTargetList: T.bool
})

export const EDITABLE_INTEL_FIELDS = [
  'vendor',
  'tags',
  'description',
  'intelCreatedAt',
  'intelUpdatedAt',
  'intelObservedAt',

  'category',
  'confidence',
  'severity',
  'killchainStage',
  'ruleBehavior',

  'references', // Available for each specific type (i.e. ids.references, domain.references)

  // ruleType specific
  'ruleBody',
  'msg',
  'classtype',

  'domainName',
  // TODO add more editable fields as they become available

  'fileName',
  'fileType',
  'fileIntelName',

  'certIssued',
  'certExpires',
  'certSslVersion',
  'certSubject',
  'certIssuer',
  'certSubjectCommonName',
  'certIssuerCommonName',
  'certAltNames',
  'certName',
  'certSerialNumber'
]

export const SUBSCRIPTION_SHAPE = T.shape({
  sensorId: T.number,
  lists: T.arrayOf(T.string),
  listCount: T.number
})

export const SUBSCRIPTIONS_SHAPE = T.arrayOf(SUBSCRIPTION_SHAPE)

export const SUBSCRIPTION_STORE_STATE_SHAPE_OBJ = {
  allEditedSensorSubscriptionsMatch: T.bool,
  editingSubForSensors: T.arrayOf(T.number),
  editingSubscriptionForSensorId: T.number,
  subscriptionError: T.any,
  isLoadingSubscriptions: T.bool,
  isSavingSubscriptions: T.bool,
  subscriptions: SUBSCRIPTIONS_SHAPE,
  subscriptionsBySensorId: T.objectOf(SUBSCRIPTION_SHAPE),
  subscribedSensorsByListId: T.objectOf(T.arrayOf(T.number))
}

export const SUBSCRIPTION_STORE_STATE_SHAPE = T.shape(
  SUBSCRIPTION_STORE_STATE_SHAPE_OBJ
)

export const LIST_STORE_STATE_SHAPE = T.shape({
  currentCustomerId: T.number,
  lists: T.arrayOf(INTEL_LIST_SHAPE),
  error: T.any,
  isLoading: T.bool,
  nonEditableSelectedListIds: T.arrayOf(T.string),
  savingListIds: T.arrayOf(T.string),
  selectedListIds: T.arrayOf(T.string),
  userListCount: T.number
})

export const INTEL_RULE_SHAPE = T.shape(_INTEL_RULE_UI_SHAPE_OBJ)

export const RULE_STORE_STATE_SHAPE_OBJ = {
  isLoadingRules: T.bool,
  isLoadingRulesNextPage: T.bool,
  nextPageAvailable: T.bool,
  intelRulesError: T.any,
  intelRules: T.arrayOf(INTEL_RULE_SHAPE),
  currentRuleFacets: T.objectOf(T.number),
  intelRuleCount: T.number,
  savingListIds: T.arrayOf(T.string),
  selectedRuleUIKeys: T.arrayOf(T.string),
  selectedRules: T.arrayOf(INTEL_RULE_SHAPE),
  allRulesSelected: T.bool,
  sortKey: T.string, //T.oneOf(Object.keys(_INTEL_RULE_UI_SHAPE_OBJ)),
  sortDir: T.oneOf(['asc', 'desc']),
  pageIndex: T.number,
  pageSize: T.number,
  ruleInputActive: T.bool,
  isSavingRules: T.bool,
  isSavingNewRules: T.bool,
  ruleSaveError: T.any,
  currentQuery: T.string,
  currentQueryUI: T.string, // UI QueryInput & URL Bar representation of query
  currentListIds: T.arrayOf(T.string),
  moveRulesDialogMode: T.oneOf(_.values(INTEL_MOVE_DIALOG_MODES)),
  moveRuleUIKeys: T.arrayOf(T.string),
  detailIsLoading: T.bool,
  detailKey: T.string,
  detailRule: T.any,
  detailError: T.any,
  batchUploadProgress: T.number,
  isSavingRulesBatched: T.bool,
  counts: T.objectOf(
    T.shape({
      pending: T.number,
      invalid: T.number,
      capped: T.number,
      needsMapping: T.number,
      total: T.number
    })
  ),
  allTags: T.arrayOf(
    T.shape({
      name: T.string
    })
  ),
  editMode: T.oneOf(_.values(INTEL_EDIT_MODES)),
  detailHistoryLoading: T.bool,
  detailHistory: T.array,
  detailHistoryError: T.object,
  activeRuleType: T.oneOf(_.values(INTEL_TYPE_OPTIONS)),
  pendingJobs: T.object
}

export const RULE_STORE_STATE_SHAPE = T.shape(RULE_STORE_STATE_SHAPE_OBJ)

export const RATING_PRESETS = {
  '25;100': {
    name: 'Low',
    desc: `Low level threats such as adware, fake AV, and mobile malware. Can also be used for suspicious or risky activity.`
  },
  '45;100': {
    name: 'Medium',
    desc: `Exploits and Phishing attempts.`
  },
  '60;100': {
    name: 'High',
    desc: `Indicators of compromise such as successful Phishing or malware Beaconing and C2.`
  },
  '75;100': {
    name: 'Severe',
    desc: `Very damaging activity, such as Data Theft or Advanced Threats.`
  }
}

export const FILE_TYPE_OPTIONS = Object.keys(FILE_TYPES)


export const FILEHASH_METADATA_SCHEMA = new Schema({
  fileHash: {
    display: "File Hash",
    desc: "File Hash String",
    type: TYPES.HASH,
    required: true,
    creatable: true,
  },
  fileHashType: {
    display: "File Hash Type",
    desc: "File Hash Type (MD5, SHA1, SHA256, or SHA512)",
    type: TYPES.ENUM,
    propType: T.string,
    required: true, // TODO just derive this from length?
    options: HASH_TYPE_OPTIONS,
    editable: true
  },
  fileName: {
    display: "File Name",
    type: TYPES.STRING,
    editable: true
  },
  fileType: {
    display: "File Type",
    type: TYPES.ENUM,
    propType: T.string,
    options: FILE_TYPE_OPTIONS,
    editable: true
  },
  name: {
    display: "File Intel Name",
    desc: "Descriptive name used as Observation Titles",
    type: TYPES.STRING,
    editable: {
      validate: getStringLengthValidator(null, 300)
    }
  },

})


export const CERT_METADATA_SCHEMA = new Schema({
  certHash: {
    display: "Cert Hash",
    desc: "MD5 or SHA1 Certificate Hash",
    type: TYPES.HASH,
    required: true,
    creatable: true,
  },
  certHashType: {
    display: "Cert Hash Type",
    desc: "Certificate Hash Type: MD5 or SHA1",
    type: TYPES.ENUM,
    propType: T.string,
    required: true, // TODO just derive this from length?
    options: CERT_HASH_TYPE_OPTIONS,
    editable: true
  },
  certName: {
    display: "Cert Name",
    desc: "Certificate Name. Displayed in Observations produced by this indicator.",
    type: TYPES.STRING,
    editable: true
  },
  certSubject: {
    display: "Subject",
    type: TYPES.STRING,
    editable: {
      validate: getStringLengthValidator(null, 300)
    }
  },
  certSubjectCommonName: {
    display: "Subject Common Name",
    type: TYPES.STRING,
    editable: {
      validate: getStringLengthValidator(null, 300)
    }
  },
  certIssuer: {
    display: "Issuer",
    type: TYPES.STRING,
    editable: {
      validate: getStringLengthValidator(null, 300)
    }
  },
  certIssuerCommonName: {
    display: "Issuer Common Name",
    type: TYPES.STRING,
    editable: {
      validate: getStringLengthValidator(null, 300)
    }
  },
  certAltNames: {
    display: "Alternate Names",
    desc: "List of alternate names for this Certificate",
    propType: T.arrayOf(T.string),
    type: TYPES.LIST,
    editable: {
      maxAllowed: 10
    }
  },
  certIssued: {
    display: "Issued at",
    desc: "Date that the Certificate was issued",
    type: TYPES.DATETIME,
    editable: true
  },
  certExpires: {
    display: "Expires at",
    desc: "Date that the Certificate expires",
    type: TYPES.DATETIME,
    editable: true
  },
  certSslVersion: {
    display: "SSL Version",
    type: TYPES.STRING,
    editable: {
      validate: getStringLengthValidator(null, 50)
    }
  },
  certSerialNumber: {
    display: "Serial Number",
    type: TYPES.STRING,
    editable: {
      validate: getStringLengthValidator(null, 50)
    }
  }
})

import _ from 'lodash'
import T from 'prop-types'
import Reflux from 'reflux'
import SensorDashboardActions from 'actions/SensorDashboardActions'
import SensorStore from './SensorStore'
import {requestGet} from 'utils/restUtils'
import querystringUtil from 'ui-base/src/util/querystringUtil'
import genericUtil from 'ui-base/src/util/genericUtil'
import moment from 'moment'
import memoize from 'memoize-one'
import { combineTimeSeries } from 'utils/statUtils'
import { getMoment } from 'utils/timeUtils'
import UserStore from 'stores/UserStore'


const counterDataLeafShape = T.shape({loading: T.bool, timeSeries: T.array, total: T.number})

/**
 * Object shape for each counter data item (_state.bytesSeen/bytesCaptured/etc)
 */
export const counterDataShape = T.shape({
  all: counterDataLeafShape,
  bySensorSet: T.objectOf(counterDataLeafShape),
  bySensor: T.objectOf(counterDataLeafShape),
})

/**
 * Translate a timeframe name to start/end times
 * @param timeframe
 * @return {start, end}
 */
export function getTimeframeRange(timeframe) {
  // NOTE: we use either local or utc for start time here matching the user's time display preference so that
  // the time axis's endpoints have nicer labels - TODO examine possible performance impact of this
  // For the end time we avoid anything within the past minute as some counters may be incomplete
  const minuteAgo = getMoment()().subtract(1, 'minute')
  const endMoment =
    timeframe === 'month' ? minuteAgo.startOf('day')
    : timeframe === 'week' ? minuteAgo.startOf('day')
    : timeframe === 'day' ? minuteAgo.startOf('hour')
    : minuteAgo.startOf('minute')
  const end = +endMoment
  return {
    start: +(
      timeframe === 'month' ? endMoment.subtract(30, 'days')
      : timeframe === 'week' ? endMoment.subtract(7, 'days')
      : timeframe === 'day' ? endMoment.subtract(1, 'day')
      : endMoment.subtract(1, 'hour')
    ),
    end
  }
}

function getSetsWithQueryableSensors() {
  return _sensorStoreState.sensorSets.filter(({enabledSensors}) =>
    enabledSensors && enabledSensors.some(s => s.downloaded)
  )
}



const _state = {
  timeframe: 'day', //month,week,day,hour
  interval: null, //{intervalSize, intervalUnit}
  intervalMs: 0,
  loading: false,
  bytesSeen: {all: {}, bySensorSet: {}, bySensor: {}}, //NOTE: timeSeries in here will be preconverted to bytes/sec
  bytesCaptured: {all: {}, bySensorSet: {}, bySensor: {}}, //NOTE: timeSeries in here will be preconverted to bytes/sec
  bytesCapturedP95: {loading: false, value: null},
  netflowsCaptured: {all: {}, bySensorSet: {}, bySensor: {}},
  netflowsSeen: {all: {}, bySensorSet: {}, bySensor: {}},
  backbuffer: {bySensor: {}}, //no rollups
  loss: {bySensor: {}}, //no rollups
  truncation: {bySensor: {}}, //no rollups
  captureHealth: {bySensor: {}}, //no rollups
  intakeHealth: {bySensor: {}}, //no rollups
  performanceHealth: {bySensor: {}}, //no rollups
  processingHealth: {bySensor: {}}, //no rollups
  totalHealth: {bySensor: {}} //no rollups
}


let _sensorStoreState = SensorStore.getInitialState()
SensorStore.listen(s => {_sensorStoreState = s})


export default Reflux.createStore({
  listenables: SensorDashboardActions,

  getInitialState() {
    return _state
  },

  init() {
    // Set default timeframe
    _.assign(_state, getTimeframeRange(_state.timeframe))
  },

  _notify() {
    this.trigger(_state)
  },

  _queueNotify() {
    clearTimeout(this._queueNotifyTimer)
    this._queueNotifyTimer = setTimeout(this._notify.bind(this), 100)
  },

  async onLoad(timeframe) {
    _state.timeframe = timeframe
    const {start, end} = getTimeframeRange(timeframe)

    const interval = _state.interval = genericUtil.getIntervalFromTimespan((end - start) / 60)
    const intervalMs = _state.intervalMs = +moment.duration(interval.intervalSize, interval.intervalUnit)
    const intervalString = interval.intervalSize + interval.intervalUnit

    // Reset all objects
    _state.loading = true
    _state.bytesSeen = {}
    _state.bytesCaptured = {}
    _state.netflowsCaptured = {}
    _state.netflowsSeen = {}
    _state.backbuffer = {}
    _state.loss = {}
    this._queueNotify()

    const meanPerSecond = (value, timestamp) =>
      value / (Math.min(intervalMs, Math.abs(end - timestamp)) / 1000)
    const clampPercent = (value, timestamp) =>
      Math.min(100, Math.floor(value)) //clamp to 0-100%
    const clampSensor2Health = (value, timestamp) =>
      value == null ? null : //null = no data (offline); treat differently than 0 health
        Math.round(clampPercent(value / 100)) //health counters are in 10000 range; convert to 0-100
    const v1Filter = s => !s.isVersion2
    const v2Filter = s => s.isVersion2

    const allPromises = [
      {
        dataObj: _state.bytesSeen,
        counters: ['bytesCaptured'],
        convertValue: meanPerSecond
      },
      {
        dataObj: _state.bytesCaptured,
        counters: ['bytesCompressedSentPcap'],
        convertValue: meanPerSecond
      },
      {
        dataObj: _state.netflowsCaptured,
        counters: ['netflowCreate'],
      },
      {
        dataObj: _state.netflowsSeen,
        counters: ['netflowNew'],
      },
      // v1 health metrics:
      {
        dataObj: _state.backbuffer,
        counters: ['memoryBackbufferUsage'],
        filter: v1Filter,
        aggregate: 'mean', //TODO 'max'
        convertValue: clampPercent
      },
      {
        dataObj: _state.loss,
        counters: ['bytesDropped'],
        filter: v1Filter,
        convertValue: meanPerSecond
      },
      {
        dataObj: _state.truncation,
        //bytes truncated = bytesDiscardedBySensor - bytesOptimized per PublishedCounters.md
        counters: ['bytesDiscardedBySensor', 'bytesOptimized'],
        filter: v1Filter,
        combineCounters: (discarded, optimized) => Math.max(0, discarded - optimized),
        convertValue: meanPerSecond
      },
      // v2 health metrics:
      ...[
        {
          dataObj: _state.captureHealth,
          counters: ['alpha_captureHealth'],
        },
        {
          dataObj: _state.intakeHealth,
          counters: ['alpha_intakeHealth']
        },
        {
          dataObj: _state.performanceHealth,
          counters: ['alpha_performanceHealth']
        },
        {
          dataObj: _state.processingHealth,
          counters: ['alpha_processingHealth']
        },
        {
          dataObj: _state.totalHealth,
          counters: ['alpha_totalHealth']
        }
      ].map(d => Object.assign(d, {
        filter: v2Filter,
        aggregate: 'mean',
        convertValue: clampSensor2Health
      }))
    ].map(async ({dataObj, counters, combineCounters, convertValue, aggregate='sum', filter}) => {
      dataObj.all = {loading: true}
      dataObj.bySensorSet = {}
      dataObj.bySensor = {}

      const sensorSetPromises = getSetsWithQueryableSensors().map(async sensorSet => {
        // Set loading state
        dataObj.bySensorSet[sensorSet.id] = {loading: true}
        sensorSet.enabledSensors.forEach(sensor => {
          dataObj.bySensor[sensor.id] = {loading: true}
        })

        try {
          let counterResults = await Promise.all(counters.map(counterType => {
            // Construct counters query for a single counter across all the set's sensors
            // TODO if too many sensors in a set, chunk them?
            let sensors = sensorSet.enabledSensors
            if (filter) {
              sensors = sensors.filter(filter)
            }
            return sensors.length ? requestGet(
              `sensor_dash_stats_${counterType}_${sensorSet.id}`,
              `counters?${querystringUtil.stringify({
                sensorId: _.pluck(sensors, 'id'),
                start,
                end,
                interval: intervalString,
                counterType,
                aggregate,
                stackBy: 'sensor'
              })}`,
              {useSocket: true}
            ) : Promise.resolve([])
          }))

          // Combine multiple counters to a single value via the combineCounters fn
          let timeSeries
          if (counters.length === 1) {
            timeSeries = counterResults[0]
          } else {
            timeSeries = combineTimeSeries(counterResults, combineCounters)
          }

          // Aggregate per sensor set
          dataObj.bySensorSet[sensorSet.id] = {loading: false, timeSeries: [], total: 0}
          timeSeries.forEach(segment => {
            if (segment.timestamp < end) { //ignore trailing intervals
              const value = segment.value
              dataObj.bySensorSet[sensorSet.id].timeSeries.push({
                timestamp: segment.timestamp,
                value: convertValue ? convertValue(value, segment.timestamp) : value
              })
              dataObj.bySensorSet[sensorSet.id].total += value
            }
          })

          // Aggregate per sensor
          sensorSet.enabledSensors.forEach(sensor => {
            dataObj.bySensor[sensor.id] = {loading: false, timeSeries: [], total: 0}
            timeSeries.forEach(segment => {
              if (segment.timestamp < end) { //ignore trailing intervals
                const value = segment[sensor.id]
                dataObj.bySensor[sensor.id].timeSeries.push({
                  timestamp: segment.timestamp,
                  value: convertValue ? convertValue(value, segment.timestamp) : value
                })
                dataObj.bySensor[sensor.id].total += value
              }
            })
          })

          // Notify updates after every incremental request, for possible progressive rendering
          this._queueNotify()

          // Return the sensor set aggregate to be further aggregated across sets
          return dataObj.bySensorSet[sensorSet.id]
        } catch(restError) {
          dataObj.bySensorSet[sensorSet.id] = {loading: false, error: restError}
          sensorSet.enabledSensors.forEach(sensor => {
            dataObj.bySensor[sensor.id] = {loading: false, error: restError}
          })
          throw restError //rethrow to be handled at top level
        }
      })

      try {
        const sensorSetResults = await Promise.all(sensorSetPromises)
        dataObj.all = {
          loading: false,
          timeSeries: [],
          total: 0
        }
        const totalsByTimestamp = {} //temp index for combining time series
        sensorSetResults.forEach(({timeSeries, total}) => {
          timeSeries.forEach(segment => {
            totalsByTimestamp[segment.timestamp] = (totalsByTimestamp[segment.timestamp] || 0) + segment.value
          })
          dataObj.all.total += total
        })
        dataObj.all.timeSeries = Object.keys(totalsByTimestamp).sort().map(
          timestamp => ({
            timestamp: +timestamp,
            value: totalsByTimestamp[timestamp]
          })
        )
      } catch(restError) {
        dataObj.all = {loading: false, error: restError}
      }
    })

    // P95 and current loss as separate queries with their own special timeframe+interval
    if (!/trial/i.test(UserStore.getCurrentUserInfo().customer.status)) { //no p95 during trial to avoid confusion
      allPromises.push(
        this._loadBytesP95(
          +moment.utc().startOf('day').subtract(30, 'days'),
          +moment.utc().startOf('day') //TODO ensure we don't get partial interval on end
        )
      )
    }

    await Promise.all(allPromises)
    _state.loading = false //made it!
    this._queueNotify()
  },

  // Load 95th percentile for bytes captured - memoized per day
  _loadBytesP95: memoize(async function(start, end) {
    _state.bytesCapturedP95 = {loading: true}
    try {
      const timeSeries = await requestGet(
        `sensor_dash_stats_p95`,
        `counters?${querystringUtil.stringify({
          start,
          end,
          interval: '1hour',
          counterType: 'bytesCompressedSentPcap'
        })}`,
        {useSocket: true}
      )
      const perSecValues = timeSeries.map(d => Math.round((d.value || 0) / +moment.duration(1,'hour').asSeconds()))
      _state.bytesCapturedP95 = {
        loading: false,
        value: genericUtil.getPercentile(perSecValues, 95)
      }
    } catch(restError) {
      _state.bytesCapturedP95 = {loading: false, error: restError}
    }
    this._queueNotify()
  })

})


import _ from 'lodash'
import React from 'react'
import ReactDOM from 'react-dom'
import T from 'prop-types'
import cx from 'classnames'
import {
  Button,
  CircleLoader,
  DialogContainer,
  DropdownMenu,
  MenuItem,
  Tabs,
  StoreProviderHOC
} from 'react-base'
import BackbufferLossGraph from 'components/sensor-graphs/BackbufferLossGraph'
import BandwidthGraph from 'components/sensor-graphs/BandwidthGraph'
import NetflowGraph from 'components/sensor-graphs/NetflowGraph'
import SensorGraphsDialogActions from 'actions/SensorGraphsDialogActions'
import SensorStore from 'stores/SensorStore'
import SensorGraphsDialogStore from 'stores/SensorGraphsDialogStore'
import SensorHeader from 'components/SensorHeader'
import TimeframeSelector from 'components/sensor-dashboard/TimeframeSelector'
import SensorDropdown from 'components/SensorDropdown'
import AnalyticsActions from 'actions/AnalyticsActions'

const GRAPH_TYPES = {
  backbufferLoss: {
    title: 'Backbuffer/Loss',
    component: BackbufferLossGraph,
    dataProps: {
      'backbuffer': {},
      'loss': {},
      'truncation': {}
    },
    enabled: sensor => sensor && !sensor.isVersion2
  },
  bandwidth: {
    title: 'Bandwidth',
    component: BandwidthGraph,
    dataProps: {
      'bytesSeen': {
        legend: 'Seen',
        cls: 'bandwidth_seen'
      },
      'bytesCaptured': {
        legend: 'Captured',
        cls: 'bandwidth_captured'
      }
    },
    enabled: sensor => true
  },
  netflow: {
    title: 'Netflows',
    component: NetflowGraph,
    dataProps: {
      'netflowsSeen': {
        legend: 'Seen',
        cls: 'netflows_seen'
      },
      'netflowsCaptured': {
        legend: 'Captured',
        cls: 'netflows_captured'
      }
    },
    enabled: sensor => true
  }
}

const TIMEFRAMES = ['month', 'week', 'day', 'hour']

// Ensure a requested graphType is allowed for a given sensor, and if not
// then return the first graphType that is allowed
function checkGraphType(reqGraphType, sensorId) {
  const sensor = SensorStore.getSensor(sensorId)
  if (sensor && GRAPH_TYPES[reqGraphType] && GRAPH_TYPES[reqGraphType].enabled(sensor)) {
    return reqGraphType
  } else {
    return Object.keys(GRAPH_TYPES).find(type => GRAPH_TYPES[type].enabled(sensor))
  }
}

class SensorGraphsDialog extends React.Component {
  static propTypes = {
    // From SensorStore:
    enabledSensors: T.array,

    // From SensorGraphsStore:
    sensorId: T.number,
    timeframe: T.oneOf(TIMEFRAMES),
    graphType: T.oneOf(Object.keys(GRAPH_TYPES)),
    isOpen: T.bool,
    isLoading: T.bool,
    data: T.object
  }

  constructor(props) {
    super(props)
    this.state = {
      rulerTime: null,
      hiddenDataPoints: new Set()
    }
  }

  _getCurrentGraphData() {
    const {graphType, data, sensorId} = this.props
    const graphInfo = GRAPH_TYPES[checkGraphType(graphType, sensorId)]
    return data
      ? _.pick(data, Object.keys(graphInfo.dataProps).filter(d => !this.state.hiddenDataPoints.has(d)))
      : {}
  }

  _onSensorChange = sensorId => {
    const {timeframe, graphType} = this.props
    SensorGraphsDialogActions.load(sensorId, timeframe, checkGraphType(graphType, sensorId))
  }

  _onTimeframeChange = timeframe => {
    const {sensorId, graphType} = this.props
    SensorGraphsDialogActions.load(sensorId, timeframe, checkGraphType(graphType, sensorId))
  }

  _onGraphTypeChange = graphType => {
    const {sensorId, timeframe} = this.props
    SensorGraphsDialogActions.load(sensorId, timeframe, checkGraphType(graphType, sensorId))
  }

  _onLegendItemClick = key => {
    const hiddenDataPoints = new Set(this.state.hiddenDataPoints)
    if (hiddenDataPoints.has(key)) {
      hiddenDataPoints.delete(key)
    } else {
      hiddenDataPoints.add(key)
    }
    this.setState({hiddenDataPoints})
  }

  _onTimeHover = rulerTime => {
    this.setState({rulerTime})
  }

  render() {
    const {props, state} = this

    const graphType = checkGraphType(props.graphType, props.sensorId)
    const {title:graphTypeTitle, component:Graph, dataProps} = GRAPH_TYPES[graphType]
    const graphData = props.isLoading ? null : this._getCurrentGraphData()

    return <DialogContainer
      modal
      onMaskClick={SensorGraphsDialogActions.close}
    >
      <div className="modal_dialog sensor_graph_dialog">
        <Button
          className="remove_btn"
          onClick={SensorGraphsDialogActions.close}
        />

        <div className="dialog_hdr">
          <h1>Sensor Graphs</h1>

          {/*<SensorHeader
            hideProfile
            sensorId={props.sensorId}
            onChangeSensor={this._onSensorChange}
            usageId="sensor_graphs_dialog"
          />*/}
          <div>
            <h3><span className="icon icon-sensor-small" /> Sensor</h3>
            <SensorDropdown
              label={SensorStore.getSensorName(props.sensorId)}
              onSelect={this._onSensorChange}
            />
          </div>

          <TimeframeSelector
            timeframe={props.timeframe}
            onChange={this._onTimeframeChange}
          />
          {/*<TimeRangeInput
            initialValue={[null, null]}
            onSave={this._onTimeRangeChange}
          />*/}
          {/*<DropdownMenu label={'Past ' + props.timeframe} closeOnItemClick>
            {_.map(TIMEFRAMES, timeframe =>
              <MenuItem key={timeframe} onClick={this._onTimeframeChange}>Past {timeframe}</MenuItem>
            )}
          </DropdownMenu>*/}

          <div>
            <h3><span className="icon icon-activity" /> Metrics</h3>
            <DropdownMenu
              closeOnItemClick
              activeMenuKeys={[graphType]}
              label={graphTypeTitle}
            >
              {_.reduce(GRAPH_TYPES, (out, graphInfo, key) => {
                if (graphInfo.enabled(SensorStore.getSensor(props.sensorId))) {
                  out.push(<MenuItem key={key} onClick={this._onGraphTypeChange}>{graphInfo.title}</MenuItem>)
                }
                return out
              }, [])}
            </DropdownMenu>

            <div className="legend">
              {_.map(dataProps, ({legend, cls}, key) => {
                const isHidden = state.hiddenDataPoints.has(key)
                return legend
                  ? <Button
                    key={key}
                    onClick={this._onLegendItemClick}
                    args={[key]}
                    className={cx('legend_item', isHidden && 'is_hidden')}
                    data-tooltip={`Toggle ${isHidden ? 'On' : 'Off'}`}
                  >
                    <span className={cx(`sensor_graph_swatch`, cls)} /> {legend}
                  </Button>
                  : null
              })}
            </div>
          </div>
        </div>

        <div className="graph_ct">
          {props.isLoading
            ? <CircleLoader transparent loading />
            : <React.Fragment>
              {/*<div className="legend">
                {_.map(dataProps, ({legend, cls}, key) => {
                  const isHidden = state.hiddenDataPoints.has(key)
                  return legend
                    ? <Button
                      key={key}
                      onClick={this._onLegendItemClick}
                      args={[key]}
                      className={cx('legend_item', isHidden && 'is_hidden')}
                      data-tooltip={`Toggle ${isHidden ? 'On' : 'Off'}`}
                    >
                      <span className={cx(`sensor_graph_swatch`, cls)} /> {legend}
                    </Button>
                    : null
                })}
              </div>*/}
              {_.isEmpty(graphData)
                ? null
                : <Graph
                  xAxis
                  yAxis
                  title=""
                  {...graphData}
                  rulerTime={state.rulerTime}
                  onTimeHover={this._onTimeHover}
                />
              }
            </React.Fragment>
          }
        </div>
      </div>
    </DialogContainer>
  }
}



export const SensorGraphsDialogButton = StoreProviderHOC(
  class SensorGraphsDialogButton extends React.PureComponent {
    static propTypes = {
      initialSensorId: T.number,
      initialTimeframe: T.oneOf(TIMEFRAMES),
      initialGraphType: T.oneOf(Object.keys(GRAPH_TYPES)),
      usageId: T.string
    }

    _id = Math.random()

    _onClick = e => {
      const {props} = this
      SensorGraphsDialogActions.load(props.initialSensorId, props.initialTimeframe, props.initialGraphType, this._id)
      AnalyticsActions.event({
        eventCategory: 'sensor',
        eventAction: 'expand_sensor_graph',
        eventLabel: `${props.initialGraphType} from_${props.usageId || 'unknown'}`
      })
    }

    render() {
      const {props} = this
      const isOpen = props.isOpen && props.openerId === this._id
      return <React.Fragment>
        <Button
          className={cx('sensor_graph_dlg_btn btn-icon icon-expand2', isOpen && 'opened', props.className)}
          data-tooltip="Enlarge Graph"
          onClick={this._onClick}
        />
        {isOpen
          ? <SensorGraphsDialog
            {...props}
          />
          : null
        }
      </React.Fragment>
    }
  },
  [
    {store: SensorStore, mapping: ['enabledSensors']},
    {store: SensorGraphsDialogStore}
  ]
)

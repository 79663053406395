/*
PW Relative time span.

Pass in a timestamp that should be rendered as relative time, and kept up-to-date as
long as the browser session is active

Isolates render() calls to this component alone, and implements the PureRender mixin
to ensure renders only get called when the _relative time string_ is updated
*/

import _ from 'lodash'
import T from 'prop-types'
import React from 'react'
import TimingStore from 'stores/TimingStore'
import { StoreProvider } from 'react-base'
import { formatRelativeTime } from 'utils/timeUtils'

const UPDATE_INTERVAL = 5000

class RelativeTime extends React.Component {
  static displayName = 'RelativeTime'

  static propTypes = {
    tagName: T.string,
    time: T.number, //timestamp

    // From TimingStore
    currentTime: T.number
  }

  static defaultProps = {
    tagName: 'span'
  }

  shouldComponentUpdate(nextProps) {
    // Update when global clock aligns with update interval
    return nextProps.currentTime % UPDATE_INTERVAL === 0
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.currentTime !== this._latestTime) {
      this.forceUpdate()
    }
  }

  render() {
    const { tagName, time } = this.props
    const Comp = tagName
    return (
      <Comp {..._.omit(this.props, 'tagName', 'time', 'currentTime')}>
        {formatRelativeTime(time)}
      </Comp>
    )
  }
}

const mapTimingStore = timingStoreState => {
  return {
    currentTime: timingStoreState.currentTime
  }
}

const RelativeTimeWithStores = props => {
  return (
    <StoreProvider store={TimingStore} mapping={mapTimingStore}>
      <RelativeTime {...props} />
    </StoreProvider>
  )
}

RelativeTimeWithStores.displayName = 'RelativeTimeWithStores'

export default RelativeTimeWithStores

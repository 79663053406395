import _ from 'lodash'
import React from 'react'
import T from 'prop-types'
import moment from 'moment'
import {formatDate} from 'utils/timeUtils'
import {
  ScrollBars,
  Tabs,
  MenuItem,
  TextOverflow
} from 'react-base'
import IntelCardPropTypes from '../PropTypes'


const NONE = 'None'

class CertificateInfo extends React.Component {
  static displayName = 'CertificateInfo'
  static propTypes = IntelCardPropTypes.dataCardShape

  state = {
    activeCert: null
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let certChain = nextProps.data && nextProps.data('certChain')
    if (certChain !== this._lastCertChain) {
      this._lastCertChain = certChain
      this.setState({
        activeCert: certChain ? certChain[0] : null
      })
    }
  }

  _onTabClick = sha1 => {
    this.setState({
      activeCert: _.find(this.props.certChain, { sha1 })
    })
  }

  render() {
    return (
      <div className="intel_card intel_data_card intel_data_network">
        <h3>Certificate Intel</h3>
        <div className="intel_card_content">
          {this.renderData()}
        </div>
      </div>
    )
  }

  renderData() {
    const { props, state } = this

    let certChain = props.data && props.data('certChain')
    if (certChain && certChain.isReady()) {
      certChain = certChain.valueOf()
      const activeCert = state.activeCert || certChain[0]

      return activeCert && <ScrollBars>
        {certChain.length > 1
          ? <Tabs vertical activeTabKey={activeCert ? activeCert.sha1 : ''}>
              {certChain && _.map(certChain, cert =>
                <MenuItem key={cert.sha1} onClick={this._onTabClick}>
                  <TextOverflow>{cert.commonName}</TextOverflow>
                </MenuItem>
              )}
            </Tabs>
          : null}

        <div className="grid_cell">
          <span>Common Name</span>
          <div className="cell_content md">
            <TextOverflow>{activeCert.commonName}</TextOverflow>
          </div>
        </div>
        <div className="grid_cell">
          <span>Organization Name</span>
          <div className="cell_content">
            <TextOverflow>{activeCert.organizationName || NONE}</TextOverflow>
          </div>
        </div>
        <div className="grid_cell">
          <span>Issuer</span>
          <div className="cell_content">
            <TextOverflow>{activeCert.issuer || NONE}</TextOverflow>
          </div>
        </div>
        <div className="grid_cell">
          <span>SHA1</span>
          <div className="cell_content">
            <TextOverflow>{activeCert.sha1}</TextOverflow>
          </div>
        </div>
        <div className="grid_cell">
          <span>Serial Number</span>
          <div className="cell_content">
            <TextOverflow>{activeCert.serialNumber}</TextOverflow>
          </div>
        </div>
        <hr />
        <div className="grid_cell">
          <span>Valid Not Before</span>
          <div className="cell_content sm">
            {formatDate(activeCert.validNotBefore)}{' '}
            <span className="muted">{moment(activeCert.validNotBefore).fromNow()}</span>
          </div>
        </div>
        <div className="grid_cell">
          <span>Valid Not After</span>
          <div className="cell_content sm">
            {formatDate(activeCert.validNotAfter)}{' '}
            <span className="muted">{moment(activeCert.validNotAfter).fromNow()}</span>
          </div>
        </div>
        <div className="grid_cell">
          <span>Alternate Names</span>
          <div className="cell_content">
            <TextOverflow>
              {activeCert.alternateNames && activeCert.alternateNames.length > 0
                ? activeCert.alternateNames.join(', ')
                : 'No alternate names'}
            </TextOverflow>
          </div>
        </div>
      </ScrollBars>
    }
  }

}

export default CertificateInfo

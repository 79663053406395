// I am root
import {StoreManager} from './StoreManager'
import { RouteStore } from './data/global/RouteStore'
import { LocalStoragePrefsStore } from './data/global/LocalStoragePrefsStore'
import { CityStore } from './data/global/CityStore'
import { DeviceManagementStore } from "./viewStores/devices/DeviceManagementStore"
import { ExplorerManagementStore } from "./viewStores/explorer-duex/ExplorerManagementStore"
import { DeviceListStore } from './data/devices/DeviceListStore'
import { DeviceQueryStore } from './data/devices/DeviceQueryStore'
import CustomerStore from './data/customers/CustomerStore'
import CustomerUsageStore from './data/customers/CustomerUsageStore'
import CustomerStatusesStore from './data/customers/CustomerStatusesStore'
import { HelpVideosStore } from './data/help/HelpVideosStore'
import { QueryStore } from './data/explorer-duex/QueryStore'

import DownloadStore from './viewStores/download-manager/DownloadStore'
import PcapDownloaderStore from './viewStores/pcap-downloader/PcapDownloaderStore'
import { NotificationStore } from '../notifications/NotificationStore'
import { SecuritySettingsStore } from './data/settings/SecuritySettingsStore'
import QueryTemplateStore from './viewStores/explorer/QueryTemplateStore'
import SitRepSummaryStore from './viewStores/reporting/SitRepSummaryStore'
import EventResolutionsStore from './viewStores/reporting/summary/EventResolutionsStore'
import ResolvedPriorityEventsStore from './viewStores/reporting/summary/ResolvedPriorityEventsStore'
import CustomerListViewStore from './viewStores/customer-admin/CustomerListViewStore'
import CustomerAdminViewStore from './viewStores/customer-admin/CustomerAdminViewStore'
import { HelpStore } from './viewStores/help/HelpStore'

export const rootStoreManager = new StoreManager()

rootStoreManager.register(CityStore)

rootStoreManager.register(DeviceManagementStore)
rootStoreManager.register(QueryTemplateStore)
rootStoreManager.register(ExplorerManagementStore)
rootStoreManager.register(DownloadStore)
rootStoreManager.register(PcapDownloaderStore)
rootStoreManager.register(SecuritySettingsStore)
rootStoreManager.register(HelpStore)
rootStoreManager.register(CustomerStore)
rootStoreManager.register(CustomerUsageStore)

// SitRep ViewStores
rootStoreManager.register(SitRepSummaryStore)
rootStoreManager.register(EventResolutionsStore)
rootStoreManager.register(ResolvedPriorityEventsStore)
// Customer Management
rootStoreManager.register(CustomerListViewStore)
rootStoreManager.register(CustomerAdminViewStore)

rootStoreManager.register(HelpVideosStore)
rootStoreManager.register(CustomerStatusesStore)
rootStoreManager.register(DeviceListStore)

rootStoreManager.register(DeviceQueryStore)
rootStoreManager.register(LocalStoragePrefsStore)
rootStoreManager.register(NotificationStore)

// Research ExplorerDuex
rootStoreManager.register(QueryStore)

rootStoreManager.register(RouteStore)

export default rootStoreManager

import _ from 'lodash'
import React from 'react'
import T from 'prop-types'
import IT from 'react-immutable-proptypes'
import ReactDOM from 'react-dom'
// import cx from 'classnames'
// import util from 'ui-base/src/util/genericUtil'
// import {getMoment} from 'utils/timeUtils'
import {
  DimensionAware
} from 'react-base'
import {
  scaleLinear,
  scaleLog,
  scalePow
} from 'd3-scale'
// import {axisLeft, axisBottom, axisRight} from 'd3-axis'
import {
  stack,
  area,
  line,
  curveCardinal,
  curveStepBefore,
  curveMonotoneX,
  curveLinear
} from 'd3-shape'
import {
  getD3TimeScale,
  getTimelineTickFormat
} from 'utils/timeUtils'
import {getThemeColor} from 'utils/themeUtils'
// import LinearGradient from 'components/svg/LinearGradient'
// import StreamGraph from './StreamGraph'
// import StreamGraphTroika from './StreamGraphTroika'
import constants from 'pwConstants'
import Axis from './Axis'
import {
  Canvas3D,
  Group3DFacade,
  ListFacade,
  Object3DFacade,
  PerspectiveCamera3DFacade,
  OrthographicCamera3DFacade
} from 'troika-3d'
// import StreamArea from './StreamArea'
import StreamAreaD3, {CURVE_OPTIONS} from './StreamAreaD3'
// import {Vector3} from 'three'
import {
  SphereGeometry,
  Mesh,
  PlaneGeometry,
  MeshBasicMaterial,
  Vector3
} from 'three'

import SimpleStateTweaker from 'components/SimpleStateTweaker'


const Z_INDEX_OFFSET = 0 //-1 // World uni distance between each stream stack
const CAMERA_UP = new Vector3(0, 0, 1)
const ORTHO_CAMERA_INITIAL = false
const PROTOCOL_COLORS = _.zipObject(constants.protocolFamilies, constants.protocolFamilyColors)

const CONTROLS = [
  {
    type: 'bool',
    stateKey: 'maximized',
  },
  {
    type: 'bool',
    stateKey: 'enableCameraSpin',
  },
  {
    type: 'slider',
    controlProps: {
      initialRange: [45],
      minValue: 0,
      maxValue: 500,
    },
    stateKey: 'cameraElevation'
  },
  {
    type: 'slider',
    controlProps: {
      initialRange: [45],
      minValue: 0,
      maxValue: 500,
    },
    stateKey: 'cameraDistance'
  },
  {
    type: 'slider',
    controlProps: {
      initialRange: [45],
      minValue: 0,
      maxValue: 500,
    },
    stateKey: 'cameraAngle'
  },
  {
    type: 'select',
    controlProps: {
      options: CURVE_OPTIONS
    },
    stateKey: 'graphStyle'
  }
]

// class TimelineCamera extends OrthographicCamera3DFacade {
//   constructor(...args) {
//     super(...args)
//     this.near = 0.000001
//     this.far = 1e16

//     this.zoom = 1.0
//   }

//   afterUpdate() {
//     // console.log('angle~~~~', this.angle, this.left, this.right, this.top, this.bottom)

//     this.x = this.distance * Math.sin(this.angle)
//     this.y = this.distance * Math.cos(this.angle)
//     this.z = this.elevation
//     super.afterUpdate()
//   }
// }


// class TimelineCameraPersp extends PerspectiveCamera3DFacade {
//   constructor(...args) {
//     super(...args)
//     this.fov = 60
//     this.near = 0.1
//     this.far = 1e10
//   }

//   afterUpdate() {
//     this.x = this.distance * Math.sin(this.angle)
//     this.z = this.distance * Math.cos(this.angle)
//     this.y = this.elevation
//     super.afterUpdate()
//   }
// }



// const D3_CURVE_INTERPOLATORS = {
//   'cardinal': curveCardinal,
//   'step-before': curveStepBefore,
//   'monotone': curveMonotoneX,
//   'linear': curveLinear
// }


function interpolateStreamArray(fromObj, toObj, progress) {
  const interpolated = {}
  for (const timestamp in toObj) {
    if (toObj.hasOwnProperty(timestamp)) {
      if (fromObj && fromObj.hasOwnProperty(timestamp)) {
        const fromV = fromObj[timestamp]
        const toV = toObj[timestamp]
        interpolated[timestamp] = {
          y0: fromV.y0 + (toV.y0 - fromV.y0) * progress,
          y1: fromV.y1 + (toV.y1 - fromV.y1) * progress,
          // timestamp: timestamp,
          timestamp: timestamp,
          value: fromV.value + (toV.value - fromV.value) * progress
        }
      }
      else {
        interpolated[timestamp] = toObj[timestamp]
      }
    }
  }
  return interpolated
}


// const geometry = new SphereGeometry(10, 32, 32)
// const material = new MeshBasicMaterial({
//   transparent: true,
//   opacity: 0.5,
//   color: 0x0000ff
// })

// class Planet extends Object3DFacade {
//   constructor(parent) {
//     super(parent, new Mesh(geometry, material.clone()))
//   }

//   set radius(r) {
//     this.scaleX = this.scaleY = this.scaleZ = r
//   }
//   get radius() {
//     return this.scaleZ
//   }

//   set color(c) {
//     this.threeObject.material.color.set(c)
//   }

//   set opacity(o) {
//     this.threeObject.visible = o > 0
//     this.threeObject.material.opacity = o
//   }
// }

// const geometry2 = new PlaneGeometry(50, 50, 5, 5)
// const material2 = new MeshBasicMaterial({
//   color: 0xff0000,
//   transparent: false
// })

// class PlaneG extends Object3DFacade {
//   constructor(parent) {
//     super(parent, new Mesh(geometry2, material2.clone()))
//   }
// }

const sanitizeDataKey = dataKey => dataKey.replace(/\./g, '_')

const timeScaleFormatter = (a, b) => {
  console.log('timescale', a, b)

  return ``
}

class Timeline extends React.PureComponent {
  static displayName = "Timeline"

  static propTypes = {
    className: T.string,

    axisTop: T.bool,
    axisRight: T.bool,
    axisBottom: T.bool,
    axisLeft: T.bool,

    // gradientColors: T.object, // FIXME immutable
    fillColor: T.string,
    fillOpacity: T.number,
    strokeColor: T.string,
    strokeWidth: T.number,

    // graphName: T.string.isRequired,
    minHeight: T.number,
    minWidth: T.number,

    onItemMouseOver: T.func,
    onItemMouseOut: T.func,
    onItemClick: T.func,

    // Optional domain values
    minX: T.number,
    maxX: T.number,
    minY: T.number,
    maxY: T.number,

    overrideXScale: T.func, // Parent override for X scale
    overrideYScale: T.func, // Parent override for Y scale

    xScaleFormatter: T.func,
    yScaleFormatter: T.func,

    xTicks: T.number,
    yTicks: T.number,

    xScaleExponent: T.number, // If set, x scale will be a nonlinear pow() function with this exponent

    // From DimensionAware
    height: T.number,
    width: T.number,

    dataKeys: IT.listOf(T.string),
    data: IT.mapContains({
      history: IT.listOf(IT.mapOf(T.number, T.string)),
      maxValue: T.number,
      startTime: T.number,
      endTime: T.number
    }),
    overrideMaxValue: T.number,
    graphStyleInitial: T.oneOf(Object.keys(CURVE_OPTIONS)),

    stackStreams: T.bool, // If true, this will be a stacked area graph. If false, each dataKey will be rendered from the same zero baseline

    debug: T.bool,
    innerAxes: T.bool, // If true, axes will be overlaid "inside" the graph bounds, with appropriate padding and transparency

    overlayRenderer: T.func, // optional function called to render any overlay elements. Passed (data, xScale, yScale)
    renderKey: T.bool,
    keyVisibleInitial: T.bool,
    // forceUpdater: T.any
  }

  static defaultProps = {
    minHeight: 100,
    minWidth: 100,
    height: 100,
    width: 100,
    xTicks: null,
    yTicks: null,
    // xScaleFormatter: getTimelineTickFormat(), // Default to timeline formatter
    xScaleFormatter: timeScaleFormatter,
    strokeWidth: 1,
    fillOpacity: 0.75,
    stackStreams: true,
    debug: false,
    innerAxes: false,
    renderKey: false,
    keyVisibleInitial: false
  }

  constructor(props) {
    super(props)
    this.state = {
      maximized: false,

      perspective: !ORTHO_CAMERA_INITIAL,

      enableCameraSpin: true,
      cameraElevation: ORTHO_CAMERA_INITIAL ? 0 : 40,
      cameraDistance: ORTHO_CAMERA_INITIAL ? 0 : 50,
      cameraAngle: ORTHO_CAMERA_INITIAL ? 0 : 50,
      cameraLookAt: {x:0, y:0, z:0},

      graphStyle: props.graphStyleInitial || CURVE_OPTIONS.STEP,
      orthoCamera: true,
      keyVisible: props.keyVisibleInitial
    }
  }

  componentDidMount() {
    this._handleResize()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    console.log('Timeline receive props', nextProps.width)

    if (
      nextProps.height !== this.props.height
      || nextProps.width !== this.props.width
      // || nextProps.forceUpdater !== this.props.forceUpdater
    ) {
      this._handleResize(nextProps)
    }
  }

  toggleKeyVisible = () => {
    this.setState({
      keyVisible: !this.state.keyVisible
    })
  }

  _handleResize = props => {
    const {
      minHeight,
      minWidth,
      padding,
      height,
      width
    } = props || this.props

    this.setState({
      height: Math.max(height, minHeight),
      width: Math.max(width, minWidth)
    })
  }

  _getScales = (dataJS) => {
    const {
      height,
      width,
      dataKeys,
      minX,
      maxX,
      minY,
      maxY,
      overrideXScale,
      overrideYScale,
      overrideMaxValue
    } = this.props


    if (!dataJS || !height || !width) {
      return {}
    }

    const d3TimeScale = getD3TimeScale()

    let maxVal = overrideMaxValue || dataJS.maxValue
    if (dataKeys) {
      // Recalc maxValue if dataKeys subset is provided
      maxVal = _.max(_.map(dataJS.history, d => dataKeys.reduce((out, key) => out + d[key], 0)))
      console.log('RECALC MAX VALUE', dataJS.maxValue , ' -> ', maxVal)
    }

    const _xDomain = [
      minX || dataJS.startTime,
      maxX || dataJS.endTime
    ]
    // const _xDomain = [
    //   Date.now() - (dataJS.history.length - 2) * 1000,
    //   Date.now() - 1000
    // ]
    // console.log('xDom', _xDomain, dataJS.startTime, dataJS.endTime)

    const _yDomain = [
      minY || 0,
      maxY || (maxVal > 3 ? maxVal : 4)
    ]
    const diff = _yDomain[1] - _yDomain[0]
    const pad = diff * 0.05 // 5% vertical padding
    _yDomain[0] = _yDomain[0] === 0 ? 0 : Math.floor(_yDomain[0] - pad) // Only pad floors below zero
    _yDomain[1] = Math.ceil(_yDomain[1] + pad)

    const xScale = overrideXScale || d3TimeScale()
      .domain(_xDomain)
      .range([0, width])
      .nice()
    const yScale = overrideYScale || scaleLinear()
      .domain(_yDomain)
      .range([height, 0])
      .nice()

    return {
      xScale,
      yScale
    }
  }

  _getStreamAreas = (xScale, yScale, dataJS, keys) => {
    const {
      dataKeys,
      stackStreams
      // lineInterpolation
    } = this.props

    if (!dataJS || !xScale || !yScale) {
      return []
    }


    const tickTotals = {}

    const streams = keys.map((dataKey, i) => {
      // console.log('key?', dataKey, dataJS.history)
      const stackReducer = (out, d) => {
        const _val = d[dataKey] || 0
        tickTotals[d.timestamp] = tickTotals[d.timestamp] || 0 //y0
        out[d.timestamp] = {
          timestamp: d.timestamp,
          value: _val,
          y0: tickTotals[d.timestamp],
          y1: tickTotals[d.timestamp] += _val
        }
        return out
      }

      const lineReducer = (out, d) => {
        const _val = d[dataKey] || 0
        out[d.timestamp] = {
          timestamp: d.timestamp,
          value: _val,
          y0: 0,
          y1: _val
        }
        return out
      }

      return {
        z: i * Z_INDEX_OFFSET,
        dataKey: dataKey,
        name: sanitizeDataKey(dataKey),
        values: (dataJS.history || []).reduce(stackStreams ? stackReducer : lineReducer, {})
        // values: (dataJS.history || []).map(d => {
        //   const _val = d[dataKey] || 0
        //   tickTotals[d.timestamp] = tickTotals[d.timestamp] || 0 //y0
        //   return {
        //     timestamp: d.timestamp,
        //     value: _val,
        //     y0: tickTotals[d.timestamp],
        //     y1: tickTotals[d.timestamp] += _val
        //   }
        // })
      }
    }).reverse() // reverse for proper stack order

    // return [streams[0]]
    return streams
  }

  _onTweakerChanged = partialState => {
    this.setState(partialState, () => {
      if (partialState.maximized) {
        this._handleResize()
      }
    })
  }

  render() {
    const {props, state} = this
    const {
      className,
      height,
      width,
      data,
      dataKeys,
      axisTop,
      axisRight,
      axisBottom,
      axisLeft,
      xScaleFormatter,
      yScaleFormatter,
      overrideYScale,
      xTicks,
      yTicks,
      xScaleExponent,
      fillColor,
      fillOpacity,
      strokeColor,
      strokeWidth,
      debug,
      innerAxes,
      overlayRenderer,
      renderKey
    } = this.props
    const {
      graphStyle,
      orthoCamera,
      keyVisible
    } = this.state

    const dataJS = data && data.toJS()

    const {xScale, yScale} = this._getScales(dataJS)
    const minValue = yScale.domain()[0]
    const maxValue = yScale.domain()[1]

    const keys = (dataKeys && dataKeys.toJS()) || dataJS.sortedKeys || _.without(_.keys(_.first(dataJS.history)), 'timestamp', 'total')
    const stackedStreams = xScale && yScale && dataJS && this._getStreamAreas(xScale, yScale, dataJS, keys)

    const keysToColors = keys.reduce((out, key, i) => {
      out[key] = constants.protocolFamilyColors[(i+1) * (i+1)] //_.sample(constants.protocolFamilyColors), //  PROTOCOL_COLORS[s.name],
      return out
    }, {})
    console.log('stacked streams', keysToColors, stackedStreams, height, width, minValue, maxValue)

    return (
      <div
        className={ `timeline2 ${innerAxes ? 'inner_axes' : 'outer_axes'} ${ state.maximized ? 'timeline_maximized' : '' } ${className}` }
        onClick={ this._onClick }
        onMouseMove={ this._onMouseMove }
        onMouseOut={ this._onMouseOut }
        onMouseOver={ this._onMouseOver }
      >
        {debug &&
          <SimpleStateTweaker
            controls={ CONTROLS }
            currentState={ this.state }
            onChange={ this._onTweakerChanged }
          />
        }
        { dataJS && height && width ? (
          <Canvas3D
            height={ height }
            width={ width }
            antialias
            backgroundColor={ null }
            lights={ [
              { type: 'ambient', color: 0x666666 },
              { type: 'point', color: 0xffffff }
            ] }
            camera={orthoCamera ? {
              facade: OrthographicCamera3DFacade,
              z: 1,
              top: (height / 2),
              bottom: (-height / 2),
              left: (-width / 2),
              right: (width / 2),
              near: 100,
              far: -100
            } : {
              facade: PerspectiveCamera3DFacade,
              z: 400,
              x: 10,
              y: 10,
              fov: 90
            }}
            /*
            lights={ [
              {
                type: 'ambient',
                color: 0xffffff,
                intensity: 0.8
              },
              {
                type: 'point',
                x: 0,
                y: 0,
                z: 200,
                color: 0xffffff,
                intensity: 1
              }
            ] }
            camera={ _.assign({
              elevation: state.cameraElevation,
              angle: state.cameraAngle,
              distance: state.cameraDistance,
              lookAt: state.cameraLookAt,
              up: CAMERA_UP,
              transition: state.enableTransitions ? {
                angle: 1,
                distance: 1,
                elevation: 1
              } : null,
              animation: state.enableCameraSpin ? {
                0: {
                  angle: state.cameraAngle
                },
                100: {
                  angle: state.cameraAngle + Math.PI * 2
                },
                duration: 30000,
                iterations: Infinity
              } : null
            }, state.perspective ? {
              facade: TimelineCameraPersp,
              aspect: width / height
            } : {
              facade: TimelineCamera,
              left: width / -2,
              right: width / 2,
              top: height / 2,
              bottom: height / -2
            }) }
            */
            objects={ [
              {
                key: 'main',
                facade: Group3DFacade,
                x: -width / 2,//  0, //-state.center[0],
                y: -height / 2, //-state.center[1],
                z: 0,
                //transition: {'x':1, 'y':1},
                children: [
                  {
                    key: 'mainGraph',
                    facade: ListFacade,
                    data: stackedStreams,
                    template: {
                      key: (proto, i) => `proto${ i }`,
                      facade: StreamAreaD3,
                      x: 0,
                      y: 0,
                      // z: 0,
                      z: s => s.z,

                      color: s => keysToColors[s.dataKey],
                      fillColor: fillColor,
                      strokeColor: strokeColor,
                      strokeWidth: strokeWidth,

                      fillOpacity: fillOpacity,

                      name: s => s.name,

                      values: s => s.values,
                      pathInterpolate: graphStyle,

                      minValue: minValue,
                      maxValue: maxValue,
                      yScale: () => overrideYScale, // Note

                      startTime: dataJS.startTime, // + dataJS.transitionDuration, // Cut off last data point
                      endTime: dataJS.endTime,
                      xScaleExponent: xScaleExponent,

                      numCurvePoints: () => width / 2, // "pixel density" of curve precision

                      height: height,
                      width: width,

                      scaleY: 0.0,
                      animation: (d, i) => ([
                        {
                          0: {
                            scaleY: 0.001
                          },
                          100: {
                            scaleY: 1.0
                          },
                          easing: 'easeOutExpo',
                          duration: 1000,
                          iterations: 1,
                        },
                        {
                          0: {
                            z: 0.001
                          },
                          100: {
                            z: 2 * i
                          },
                          duration: 100,
                          iterations: 1,
                          delay: 1000 + i * 50
                        }

                      ]),
                      transition: {
                        // Transition value changes
                        values: {
                          duration: 1000,
                          easing: 'easeOutExpo',
                          interpolate: interpolateStreamArray
                        },
                        startTime: {
                          duration: dataJS.transitionDuration,
                          easing: dataJS.transitionType === 'play' ? 'linear' : 'easeOutExpo'
                        },
                        endTime: {
                          duration: dataJS.transitionDuration,
                          easing: dataJS.transitionType === 'play' ? 'linear' : 'easeOutExpo'
                        }
                      }
                    }
                  }
                ]
              }
            ] }
          />
        ) : null }
        { minValue < 0 && maxValue > 0 &&
          <div
            className="zero_line"
            style={{
              top: Math.floor(yScale(0))
            }}
          />
        }
        { xScale && yScale && [
          axisTop && (
            <Axis
              key={ 'top' }
              scale={ xScale }
              side={ 'top' }
              tickFormat={ xScaleFormatter }
              ticks={ xTicks }
            />
          ),
          axisBottom && (
            <Axis
              key={ 'bottom' }
              scale={ xScale }
              side={ 'bottom' }
              tickFormat={ xScaleFormatter }
              ticks={ xTicks }
            />
          ),
          axisLeft && (
            <Axis
              key={ 'left' }
              scale={ yScale }
              side={ 'left' }
              tickFormat={ yScaleFormatter }
              ticks={ yTicks }
            />
          ),
          axisRight && (
            <Axis
              key={ 'right' }
              scale={ yScale }
              side={ 'right' }
              tickFormat={ yScaleFormatter }
              ticks={ yTicks }
            />
          ),
        ] }
        { renderKey && keysToColors &&
          <div className={`timeline_key ${keyVisible ? 'key_visible' : ''}`}>
            <span
              className="btn-icon icon-compass2 key_btn"
              data-tooltip={`${keyVisible ? 'Hide' : 'Show'} Graph Key`}
              onClick={this.toggleKeyVisible}
            />
            {keyVisible &&
              <div className="key_entries">
                {_.map(keysToColors, (color, dataKey) => (
                  <div
                    className="key_entry"
                    style={{color}}
                    key={dataKey}
                  >{_.startCase(dataKey)}</div>
                ))}
              </div>
            }
          </div>
        }
        { overlayRenderer &&
          <div className="timeline_overlay">
            {overlayRenderer(data, xScale, yScale)}
          </div>
        }
      </div>
    )
  }
}

export default DimensionAware(Timeline)

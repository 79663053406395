import _ from 'lodash'
import T from 'prop-types'
import React from 'react'
import ReactDOM from 'react-dom'
import {
  StoreProvider,
  Timing,
  ScrollBars,
  CircleLoader,
  ErrorState,
  TypeaheadInput,
  PaneSplitter,
  Button,
  DropdownMenu,
  MenuItem,
  DimensionAware,
  ConfirmAction,
  ErrorBoundary
} from 'react-base'
import genericUtil from 'ui-base/src/util/genericUtil'
import { pluralize } from 'pw-formatters'
import SensorCommandsActions from 'actions/SensorCommandsActions'
import SensorStore from 'stores/SensorStore'
import SensorCommandsStore from 'stores/SensorCommandsStore'
import { formatDate } from 'utils/timeUtils'

const MIN_HEIGHT = 200
const INITIAL_HEIGHT = 400

// User will be required to confirm before issuing the following commands
const CONFIRM_COMMANDS = [
  'STOPSENSOR',
  'RESTARTSENSOR',
  'STARTSENSOR',
  'DELETE_LOGS_SENSOR',
  'DELETE_LOGS_STATS',
  'DELETE_LOGS_MANAGER',
  'DELETE_LOGS_ALL',
  'KILL_SENSOR',
  'KILL_MANAGER',
  'DELETE_CONFIG',
  'AWS_TRIAL_END',
  'AWS_TRIAL_START',
  ''
].map(c => c.toLowerCase())

class SensorCommandsPane extends React.Component {
  static displayName = 'SensorCommandsPane'

  static propTypes = {
    commandResults: T.arrayOf(
      T.shape({
        commandId: T.string.isRequired,
        sensorIds: T.array.isRequired,
        timestamp: T.number.isRequired,
        data: T.arrayOf(
          T.shape({
            id: T.number.isRequired,
            result: T.shape({
              body: T.string,
              interfaces: T.arrayOf(T.string),
              split: T.arrayOf(T.string)
            }),
            sensorName: T.string
          })
        )
      })
    ),
    commands: T.arrayOf(
      T.shape({
        id: T.number.isRequired,
        description: T.string.isRequired,
        name: T.string.isRequired
      })
    ),
    commandsError: T.any,
    error: T.any,
    hasPermission: T.func.isRequired,
    isLoading: T.bool,
    isLoadingCommands: T.bool,
    paneOpen: T.bool.isRequired,
    selectedSensorIds: T.arrayOf(T.number),
    sensors: T.array,
    setTimer: T.func,
    clearTimer: T.func,
    height: T.number,
    width: T.number
  }

  static defaultProps = {
    commands: [],
    commandsError: null,
    data: [],
    error: null,
    isLoading: false,
    isLoadingCommands: false,
    paneOpen: false,
    selectedSensorIds: [],
    sensorFilterText: ''
  }

  state = {
    collapsedResultIds: [],
    confirmCommand: null,
    height: INITIAL_HEIGHT,
    maxHeight: 800
  }

  UNSAFE_componentWillMount() {
    if (
      this.props.hasPermission &&
      this.props.hasPermission('sensors:commands')
    ) {
      SensorCommandsActions.loadCommands()
    }
  }

  componentDidMount() {
    _.forEach(['mousedown', 'touchstart'], name => {
      window.addEventListener(name, this._onWinMouseDown, false)
    })
    window.addEventListener('keydown', this._onGlobalKeydown)
    this._onResize()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.height !== this.props.height ||
      nextProps.width !== this.props.width
    ) {
      this._onResize()
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.paneOpen && !prevProps.paneOpen) {
      this.props.setTimer(this._focusInitial, 400, 'focusinitialtimer')
    }
  }

  componentWillUnmount() {
    this._isUnmounting = true
    _.forEach(['mousedown', 'touchstart'], name => {
      window.removeEventListener(name, this._onWinMouseDown, false)
    })
    window.removeEventListener('keydown', this._onGlobalKeydown)
    this._commandInputRef = null
  }

  _onCloseClick = e => {
    e.preventDefault()
    this.close()
  }

  close = () => {
    SensorCommandsActions.closeCommandConsole()
  }

  _onResize = () => {
    const _windowHeight = window.innerHeight - 40
    this.setState({
      maxHeight: _windowHeight,
      height: Math.min(this.state.height, _windowHeight)
    })
  }

  _onWinMouseDown = e => {
    // Close when clicking outside the log viewer modal
    if (
      this.props.paneOpen &&
      !genericUtil.isNodeInEl(e.target, ReactDOM.findDOMNode(this)) &&
      !genericUtil.isNodeInElByClass(e.target, 'dropdown-menu')
    ) {
      // Wait a bit to allow a new logviewer route to cancel the close
      this.props.setTimer(this.close, 200, 'mousedownclose')
    }
  }

  _onGlobalKeydown = e => {
    // Close on escape key
    if (e.keyCode === 27 && this.props.paneOpen) {
      this.close()
    }
  }

  _focusInitial = () => {
    if (this.props.selectedSensorIds.length === 0) {
      this._openSensorsDropdown()
    } else {
      this._focusCommandsInput()
    }
  }

  _focusCommandsInput = () => {
    if (!this._isUnmounting && this._commandInputRef) {
      this._commandInputRef.focus()
    }
  }

  _openSensorsDropdown = () => {
    if (!this._isUnmounting && this._sensorRef) {
      this._sensorRef.setMenuState(true)
    }
  }

  _onCommandsDropdownRef = input => {
    if (!this._isUnmounting && input) {
      this._commandInputRef = input
    }
  }

  _bindSensorDropdownRef = dropdown => {
    if (!this._isUnmounting && dropdown) {
      this._sensorRef = dropdown
    }
  }

  _onCommandSelected = command => {
    const { selectedSensorIds } = this.props
    if (command && selectedSensorIds.length > 0) {
      if (CONFIRM_COMMANDS.indexOf(command.name) !== -1) {
        this.setState({
          confirmCommand: command.name
        })
      } else {
        this._issueCommand(command.name, selectedSensorIds)
      }
    }
  }

  _onCommandConfirmed = () => {
    const { selectedSensorIds } = this.props
    const { confirmCommand } = this.state
    if (confirmCommand && selectedSensorIds.length > 0) {
      this._issueCommand(confirmCommand, selectedSensorIds)
    }
  }

  _onCommandCanceled = () => {
    this.setState({ confirmCommand: null })
  }

  _issueCommand = (commandName, selectedSensorIds) => {
    this.setState({ confirmCommand: null })
    SensorCommandsActions.issueCommand(commandName, selectedSensorIds)
  }

  _onPaneUpdate = paneUpdate => {
    this.setState(paneUpdate.b)
  }

  _onResultCollapseClick = sensorResultId => {
    const _collapsedResultIds = this.state.collapsedResultIds
    if (_collapsedResultIds.indexOf(sensorResultId) === -1) {
      _collapsedResultIds.push(sensorResultId)
    } else {
      _.pull(_collapsedResultIds, sensorResultId)
    }
    this.setState({
      collapsedResultIds: _collapsedResultIds
    })
  }

  _getResultLines = sensorResult => {
    let lines = []
    if (sensorResult.error) {
      lines = [`Error: ${sensorResult.error.message}`]
    } else if (sensorResult.result && sensorResult.result.interfaces) {
      lines = sensorResult.result.interfaces
    } else if (sensorResult.result && sensorResult.result.split) {
      lines = sensorResult.result.split
    } else {
      try {
        lines = JSON.stringify(sensorResult, null, 2).split('\n')
      } catch (e) {
        lines = ['Error: Unable to read sensor command response']
      }
    }
    return lines
  }

  _filterSensors = sensor => {
    const filterText = this.state.sensorFilterText
    return (
      !filterText ||
      sensor.friendly_name.toLowerCase().indexOf(filterText.toLowerCase()) >= 0
    )
  }

  _onSensorFilterChange = value => {
    this.setState({
      sensorFilterText: value
    })
  }

  _onSensorClick = sensorId => {
    const _sensorIds = _.clone(this.props.selectedSensorIds)
    if (_sensorIds.indexOf(sensorId) === -1) {
      _sensorIds.push(sensorId)
    } else {
      _.pull(_sensorIds, sensorId)
    }
    SensorCommandsActions.setSelectedSensorIds(_sensorIds)
  }

  _onBulkSelectClick = option => {
    SensorCommandsActions.setSelectedSensorIds(
      option === 'all' ? _.map(this.props.sensors, 'id') : []
    )
  }

  _getSensorMenuLabel = () => {
    const { props } = this
    const numSelectedSensors = props.selectedSensorIds.length
    const MAX_SENSOR_NAMES = 3
    const selectedSensorNames = _(props.sensors)
      .filter(s => props.selectedSensorIds.indexOf(s.id) !== -1)
      .take(MAX_SENSOR_NAMES)
      .map('friendly_name')
      .value()

    const textSuffix =
      numSelectedSensors > MAX_SENSOR_NAMES
        ? `, and ${numSelectedSensors - MAX_SENSOR_NAMES} other ${pluralize(
            numSelectedSensors - MAX_SENSOR_NAMES,
            'sensor'
          )}`
        : ``

    if (props.sensors.length === 0) {
      return `Loading sensors...`
    } else {
      return `${selectedSensorNames.length === 0
        ? 'No Sensors Selected'
        : selectedSensorNames.join(', ')}${textSuffix}`
    }
  }

  _onLoaderRef = el => {
    if (el && !this._isUnmounting) {
      el.scrollIntoView({
        behavior: 'smooth',
        block: 'end'
      })
    }
  }

  render() {
    const { props, state } = this

    // let canIssueSensorCommands = props.hasPermission("sensors:commands")
    const commandOptions = _(props.commands)
      .sortBy('group')
      // .sortBy('name')
      .map((cmd, ind, arr) => {
        cmd.getMenuItemContent = function(matchedText) {
          return (
            <div>
              {matchedText}
              <div className="muted_row">
                {cmd.description}
              </div>
            </div>
          )
        }
        // first of group should have heading
        if (ind === 0 || cmd.group !== arr[ind - 1].group) {
          cmd.headingText = cmd.group
        }
        return cmd
      })
      .value()

    return props.paneOpen
      ? <div
          className="sensor_command_console open"
          style={{
            height: state.height
          }}
        >
          <PaneSplitter
            baseSide="bottom"
            detached
            initialOffset={INITIAL_HEIGHT}
            maxOffset={state.maxHeight - 40}
            minOffset={MIN_HEIGHT}
            onSplitterUpdated={this._onPaneUpdate}
            placeholder
            snapButtons
          />
          <a
            className="remove_btn"
            data-tooltip="Close"
            data-tooltip-class="light"
            href="#"
            onClick={this._onCloseClick}
          />

          <div className="console_header">
            <h3>Sensor Command Console</h3>
          </div>

          {state.confirmCommand
            ? <ConfirmAction
                buttonMode={false}
                className={'confirm_command'}
                confirmText={`Yes, issue it`}
                message={`Are you sure you want to issue the "${state.confirmCommand.toUpperCase()}" command? This may result in momentary data loss and clearing of backbuffer.`}
                onCanceled={this._onCommandCanceled}
                onConfirmed={this._onCommandConfirmed}
                title={`Please confirm command`}
              />
            : null}

          <div className="sensor_command_console_content">
            <ScrollBars
              className="pw_scrollbars_light"
              slimShady
              slimShadyDarker
            >
              {props.commandResults.length === 0 && !props.isLoading
                ? <div className="no_items">No commands</div>
                : null}
              {_.map(props.commandResults, (commandResult, i) =>
                <div
                  className={`fade_in_left cmd_result`}
                  key={`command_response_${commandResult.timestamp}`}
                  style={{
                    animationDelay: i * 150 + 'ms',
                    WebkitAnimationDelay: i * 150 + 'ms'
                  }}
                >
                  <div className="res_command">
                    <div className="res_cmd_name">
                      {commandResult.commandId}
                    </div>
                    <div className="res_timestamp">
                      {formatDate(commandResult.timestamp)}
                    </div>
                    <Button
                      args={[commandResult.timestamp]}
                      className="btn-icon icon-trash"
                      data-tooltip="Clear"
                      data-tooltip-class="light"
                      onClick={SensorCommandsActions.clearResult}
                    />
                  </div>
                  <div className="res_text">
                    {_.map(commandResult.data, sensorResult => {
                      const sensorResultId = `${commandResult.timestamp} ${sensorResult.id}`
                      const isCollapsed =
                        state.collapsedResultIds.indexOf(sensorResultId) !== -1
                      const responseLines = this._getResultLines(sensorResult)
                      return (
                        <div className="sensor_result" key={sensorResultId}>
                          <Button
                            args={[sensorResultId]}
                            className={`sensor_result_header`}
                            onClick={this._onResultCollapseClick}
                          >
                            <span
                              className={`icon icon-${isCollapsed
                                ? 'plus'
                                : 'minus'}`}
                            />
                            <span
                              data-tooltip={`Sensor ID: ${sensorResult.id}`}
                            >
                              {sensorResult.sensorName}
                            </span>
                          </Button>
                          <div className="sensor_result_response">
                            {isCollapsed
                              ? <p
                                  className="collapsed_results"
                                  onClick={_.partial(
                                    this._onResultCollapseClick,
                                    sensorResultId
                                  )}
                                >
                                  ...({responseLines.length}{' '}
                                  {pluralize(responseLines.length, 'line')}{' '}
                                  hidden)
                                </p>
                              : _.map(responseLines, (line, k) =>
                                  <p
                                    className={`command_response_line ${sensorResult.error
                                      ? 'command_error'
                                      : ''}  fade_in_left`}
                                    key={k}
                                    style={{
                                      animationDelay: k * 10 + 'ms',
                                      WebkitAnimationDelay: k * 10 + 'ms'
                                    }}
                                  >
                                    {line}
                                  </p>
                                )}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              )}
              {props.isLoading || props.error
                ? <div className="next_action_status" ref={this._onLoaderRef}>
                    <CircleLoader loading={props.isLoading} />
                    <ErrorState error={props.error} />
                  </div>
                : null}
            </ScrollBars>
            <div className="commands_input_wrap">
              <DropdownMenu
                activeMenuKeys={props.selectedSensorIds}
                allowActiveItemClick
                buttonClasses={`btn btn-${props.selectedSensorIds.length === 0
                  ? 'error'
                  : 'default'} dropdown-toggle`}
                classes="sensor_select_menu"
                closeOnItemClick={false}
                filterable
                label={this._getSensorMenuLabel()}
                menuClasses="sensor_menu_dropdown sensor_command_dropdown_menu"
                multiselect
                onFilterChange={this._onSensorFilterChange}
                ref={this._bindSensorDropdownRef}
                scrollable
              >
                {_.map(['all', 'none'], opt =>
                  <MenuItem
                    args={[opt]}
                    dividerAfter={opt === 'none'}
                    key={opt}
                    onClick={this._onBulkSelectClick}
                  >
                    Select {opt}
                  </MenuItem>
                )}
                {props.sensors.filter(this._filterSensors).map(sensor => {
                  const id = sensor.id
                  return (
                    <MenuItem
                      allowActiveClick
                      args={[id]}
                      key={id}
                      onClick={this._onSensorClick}
                    >
                      <span
                        className={`icon icon-checkbox-${props.selectedSensorIds.indexOf(
                          id
                        ) === -1
                          ? 'unchecked'
                          : 'checked'}`}
                      />
                      {sensor.friendly_name}
                    </MenuItem>
                  )
                })}
              </DropdownMenu>
              {props.isLoadingCommands
                ? <div>Loading commands....</div>
                : <TypeaheadInput
                    clearOnSave
                    disabled={props.selectedSensorIds.length === 0}
                    maxOptsToShow={10}
                    menuClassName={
                      'sensor_command_options sensor_command_dropdown_menu'
                    }
                    onChange={this._onCommandSelected}
                    optionHeight={46}
                    options={commandOptions}
                    placeholder="Command...."
                    ref={this._onCommandsDropdownRef}
                    resetMenuOnSave={false}
                  />}
              <div className="btn-group">
                <button
                  className="btn btn-default"
                  data-tooltip={`Clear all`}
                  data-tooltip-class="light"
                  onClick={SensorCommandsActions.clearAllResults}
                >
                  <span className="icon icon-trash" />
                </button>
                <button
                  className="btn btn-primary"
                  data-tooltip={`Close Sensor Command Console`}
                  data-tooltip-class="light"
                  onClick={this.close}
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        </div>
      : <div className="sensor_command_console closed" />
  }
}

const EnhancedSensorCommandsPane = DimensionAware(Timing(SensorCommandsPane))

const WrappedSensorCommandsPane = props =>
  <ErrorBoundary>
    <StoreProvider store={SensorCommandsStore}>
      <EnhancedSensorCommandsPane {...props} />
    </StoreProvider>
  </ErrorBoundary>

WrappedSensorCommandsPane.displayName = 'Wrapped.SensorCommandsDialog'

export default WrappedSensorCommandsPane


import {
  // action,
  observable, computed,
  // computed
} from 'mobx'
import {
  SchemerModel,
  schemer
} from 'pw-schemer-mobx'
import transformDevice from './deviceTransform'
import {RawDevice, ThreatCount} from '../../typings/devices/device'

import StringAttribute from "./attributes/StringAttribute"
import {InterfaceAttr} from "./attributes/Interface"
import {SensorIdWithIpAddressAttr} from "./attributes/SensorIdWithIpAddress"
import {GeoDetailsAttr} from "./attributes/GeoDetails"
import {OsAttr} from "./attributes/Os"
import {MacAddressAttr} from "./attributes/MacAddress"
import {DeviceRoleAttr} from "./attributes/DeviceRole"
import {PriorityAttr} from "./attributes/Priority"
import {AwsIdAttr} from "./attributes/AwsId"
import {AwsDetailsAttr} from "./attributes/AwsDetails"
import {TimestampAttr} from "./attributes/Timestamp"
import {IpAddrAttr} from "./attributes/IpAddr"
import {UserDetailsAttr} from "./attributes/UserDetails"
import {RiskAttr} from "./attributes/Risk"
import {DeviceId} from '../../components/devices/fragments/DeviceId'
import {ThreatCountFragment} from '../../components/devices/fragments/ThreatCount'
import {withTooltip} from '../../components/devices/fragments/HeaderWithTooltip'
import {DeviceRowActions} from '../../components/devices/fragments/DeviceRowActions'


export default class DeviceModel extends SchemerModel {
  constructor(initial: RawDevice) {
    super(initial, {
      copy: true,
      transform: transformDevice
    })
  }

  @schemer.id({
    label: "ID",
    formatDisplay: DeviceId,
    table: {
      canSort: false,
      forceSticky: true,
      headerHeight: 60,
      width: 200
    }
  })
  readonly id: string

  @observable
  @schemer.arrayOf(
    schemer.model({
      label: 'Risk',
      model: RiskAttr,
      table: {
        canSort: true,
        canStick: true,
        width: 125,
        header: withTooltip("An estimation of risk level based on events from this device and devices with which it communicates.")
      }
    })
  )
  risk?: RiskAttr[]

  @observable
  @schemer.any({
    label: 'Events',
    formatDisplay: ThreatCountFragment,
    table: {
      canStick: true,
      width: 100
    }
  })
  eventCount?: ThreatCount

  @observable
  @schemer.datetime({
    label: "Updated At",
    table: {
      canSort: true,
      canStick: true,
      width: 200
    }
  })
  occurredAt?: Date

  // Attributes
  @observable
  @schemer.arrayOf(
    schemer.model({
      label: 'Host Name',
      model: StringAttribute,
      table: {
        width: 360,
      }
    })
  )
  host?: StringAttribute[]

  @observable
  @schemer.arrayOf(
    schemer.model({
      label: 'IP (by Sensor)',
      model: SensorIdWithIpAddressAttr,
      table: {
        width: 340,
      }
    })
  )
  ip?: SensorIdWithIpAddressAttr[]

  @observable
  @schemer.arrayOf(
    schemer.model({
      label: 'MAC Address',
      model: MacAddressAttr,
      table: {
        width: 230,
      }
    })
  )
  mac?: MacAddressAttr[]

  @observable
  @schemer.arrayOf(
    schemer.model({
      label: 'Operating System',
      model: OsAttr,
      table: {
        width: 460,
      }
    })
  )
  os?: OsAttr[]

  @observable
  @schemer.arrayOf(
    schemer.model({
      label: 'Role',
      model: DeviceRoleAttr,
      table: {
        width: 180,
      }
    })
  )
  role?: DeviceRoleAttr[]

  @observable
  @schemer.arrayOf(
    schemer.model({
      label: 'Priority',
      model: PriorityAttr,
      table: {
        width: 140,
      }
    })
  )
  priority?: PriorityAttr[]

  @observable
  @schemer.arrayOf(
    schemer.model({
      label: 'Description',
      model: StringAttribute,
      table: {
        width: 300,
      }
    })
  )
  description?: StringAttribute[]

  @observable
  @schemer.arrayOf(
    schemer.model({
      label: 'Department',
      model: StringAttribute,
      table: {
        width: 200,
      }
    })
  )
  department?: StringAttribute[]

  @observable
  @schemer.arrayOf(
    schemer.model({
      label: 'User',
      model: UserDetailsAttr,
      table: {
        width: 500,
      }
    })
  )
  userDetails?: UserDetailsAttr[]

  @observable
  @schemer.arrayOf(
    schemer.model({
      label: 'Network Description',
      model: StringAttribute,
      table: {
        width: 330,
      }
    })
  )
  networkDescription?: StringAttribute[]

  @observable
  @schemer.arrayOf(
    schemer.model({
      label: 'Customer Device ID',
      model: StringAttribute,
      table: {
        canSort: true,
        width: 240,
      }
    })
  )
  customerDeviceId?: StringAttribute[]

  @observable
  @schemer.arrayOf(
    schemer.model({
      label: 'Transaction ID',
      model: StringAttribute,
      table: {
        width: 200,
      }
    })
  )
  transactionId?: StringAttribute[]

  @observable
  @schemer.arrayOf(
    schemer.model({
      label: 'Customer IP',
      model: IpAddrAttr,
      table: {
        width: 260,
      }
    })
  )
  customerIp?: IpAddrAttr[]

  @observable
  @schemer.arrayOf(
    schemer.model({
      label: 'External IP',
      model: IpAddrAttr,
      table: {
        width: 260,
      }
    })
  )
  externalIp?: IpAddrAttr[]

  @observable
  @schemer.arrayOf(
    schemer.model({
      label: 'Vendor',
      model: StringAttribute,
      table: {
        width: 180,
      }
    })
  )
  vendor?: StringAttribute[]

  @observable
  @schemer.arrayOf(
    schemer.model({
      label: 'AWS ID',
      model: AwsIdAttr,
      table: {
        width: 300,
      }
    })
  )
  awsId?: AwsIdAttr[]

  @observable
  @schemer.arrayOf(
    schemer.model({
      label: 'AWS Details',
      model: AwsDetailsAttr,
      table: {
        width: 1000,
      }
    })
  )
  awsDetails?: AwsDetailsAttr[]

  @observable
  @schemer.arrayOf(
    schemer.model({
      label: 'Interface',
      model: InterfaceAttr,
      table: {
        width: 500,
      }
    })
  )
  interface?: InterfaceAttr[]

  @observable
  @schemer.arrayOf(
    schemer.model({
      label: 'Geo',
      model: GeoDetailsAttr,
      table: {
        width: 800,
      }
    })
  )
  geo?: GeoDetailsAttr[]

  @observable
  @schemer.arrayOf(
    schemer.model({
      label: 'First Seen',
      model: TimestampAttr,
      table: {
        width: 250,
      }
    })
  )
  firstSeen?: TimestampAttr[]

  @observable
  @schemer.arrayOf(
    schemer.model({
      label: 'Last Seen',
      model: TimestampAttr,
      table: {
        width: 250,
      }
    })
  )
  lastSeen?: TimestampAttr[]



  // ancillary fields
  @observable
  @schemer.tags({
    label: "Tags",
    table: {
      width: 180
    }
  })
  tags?: string[]

  @schemer.number({
    label: "Customer ID",
    table: false
  })
  readonly cid: number

  @schemer.string({
    label: "Current Device ID",
    formatDisplay: DeviceId
  })
  migrated_device_id?: string

  @schemer.arrayOf(
    schemer.string({
      label: "Previous Device IDs",
      formatDisplay: DeviceId
    })
  )
  previousDeviceIds?: string[]


  /**
   * Computed properties
   */
  @computed
  get isMigrated () {
    return !!this.migrated_device_id
  }

  @computed
  get classNames () {
    return `pw_device${this.isMigrated ? ' is_migrated' : ''}`
  }

  @computed
  get itemActions () {
    return DeviceRowActions
  }

  get itemActionsWidth () {
    return 20
  }

  // @computed
  // get columnPrefs () {
  //   const colPrefs = new Map()
  //   colPrefs.set('role', {
  //     width: 500,
  //     fixed: false
  //   })
  //   colPrefs.set('host',{
  //     width: 300,
  //     fixed: true
  //   })
  //   colPrefs.set('tags', {
  //     width: 500,
  //     fixed: false
  //   })
  //   colPrefs.set('priority', {
  //     width: 500,
  //     fixed: false
  //   })
  //   return colPrefs
  // }
}

import React from 'react'
import T from 'prop-types'
import cx from 'classnames'
import memoize from 'memoize-one'
import { formatBytesToBits } from 'pw-formatters'
import SensorTimeline from './SensorTimeline'
import { combineTimeSeries } from 'utils/statUtils'
import { InfoWindow } from 'react-base'
import AnalyticsActions from 'actions/AnalyticsActions'


const formatPercent = val => ({ textData: `${val}`, label: '%' })
const formatLoss = val => formatBytesToBits(val, false)


const LossTitle = React.memo(({ sensorId }) => {
  const onProfileLinkClick = () => {
    AnalyticsActions.event({
      eventCategory: 'sensor',
      eventAction: 'pivot_to_profiler',
      eventLabel: `from_loss_graph_tooltip`
    })
  }

  return <InfoWindow
    windowContents={
      <div className="sensor_loss_timeline_legend">
        <div className="loss">
          <b>Uncontrolled Loss</b><br />
          Packet data being randomly dropped due to full backbuffer, after all optimizations are applied. Check available network bandwidth and <a href={`#sensors/capture-profiles${sensorId ? `?sensorId=${sensorId}` : ''}`} onClick={onProfileLinkClick}>capture profile settings</a>.
        </div>
        <div className="truncation">
          <b>Controlled Loss</b><br />
          Packet data being proactively dropped from very large netflows only, to preserve bandwidth. Packets for new netflows still being captured.</div>
      </div>
    }
  >
    <React.Fragment>Loss <span className="icon icon-help" /></React.Fragment>
  </InfoWindow>
})


class BackbufferLossGraph extends React.PureComponent {

  // Note: all props treated as immutable
  static propTypes = {
    loss: T.arrayOf(
      T.shape({ timestamp: T.number, value: T.number })
    ),
    truncation: T.arrayOf(
      T.shape({ timestamp: T.number, value: T.number })
    ),
    backbuffer: T.arrayOf(
      T.shape({ timestamp: T.number, value: T.number })
    ),
    onTimeHover: T.func,
    rulerTime: T.number,
    sensorId: T.number
  }

  _getLossItems = memoize((loss, truncation) => [
    {
      className: 'truncation',
      data: combineTimeSeries(
        [loss, truncation],
        // Only keep intervals where there is no actual loss
        (lossVal, truncVal) => lossVal ? 0 : truncVal
      ),
      shape: 'smooth',
      showInRuler: 'nonzero'
    },
    {
      className: 'loss',
      data: combineTimeSeries(
        [loss, truncation],
        // If there is actual loss, add both types, otherwise ignore
        (lossVal, truncVal) => lossVal ? lossVal + truncVal : 0
      ),
      shape: 'smooth',
      showInRuler: 'nonzero'
    }
  ])

  _getBackbufferItems = memoize(backbuffer => [
    {
      className: 'backbuffer',
      data: backbuffer,
      shape: 'smooth'
    }
  ])

  _hasLoss = memoize(loss => {
    for (let { value } of loss) {
      if (value > 0) return true
    }
    return false
  })
  _hasBackbuffer = memoize(backbuffer => {
    for (let { value } of backbuffer) {
      if (value > 0) return true
    }
    return false
  })

  render() {
    const { loss, backbuffer, truncation, onTimeHover, rulerTime, sensorId } = this.props

    const hasBackbuffer = this._hasBackbuffer(backbuffer)
    const hasLoss = this._hasLoss(loss)

    return <div className="sensor_loss_timeline_wrap">
      <SensorTimeline
        stacked
        yAxis
        title={<LossTitle sensorId={sensorId} />}
        className={cx('loss_timeline', hasLoss && 'has_loss')}
        items={this._getLossItems(loss, truncation)}
        valueFormatter={formatLoss}
        onTimeHover={onTimeHover}
        rulerTime={rulerTime}
        defaultMaxValue={0.0001} //Don't show a misleading middle-gradient when no data available
      />
      <SensorTimeline
        xAxis
        yAxis
        title="Backbuffer"
        className={cx('backbuffer_timeline', hasBackbuffer && 'has_backbuffer')}
        items={this._getBackbufferItems(backbuffer)}
        valueFormatter={formatPercent}
        fixedMaxValue={90} //force 0-90%
        onTimeHover={onTimeHover}
        rulerTime={rulerTime}
      />
    </div>
  }
}

export default BackbufferLossGraph

import React, { FunctionComponent } from 'react'
import {observer} from 'mobx-react'
import cx from 'classnames'
import { formatRelativeTime } from 'utils/timeUtils'
import DeviceAttribute from 'models/devices/attributes/DeviceAttribute';

const IS_EXPIRING_THRESHOLD_MS = 60000 * 60 * 24 * 2 // 2 days
const IS_EXPIRING_SOON_THRESHOLD_MS = 60000 * 60 * 16 // 16hr

export interface ExpiresAtProps {
  value: Date|"never",
  row: DeviceAttribute
}

export const ExpiresAt: FunctionComponent<ExpiresAtProps> = observer(props => {
  const asTimestamp = +props.value
  const neverExpires = props.value === 'never'
  const timeToExpiration = Math.round(asTimestamp - Date.now())

  const statusClassName = neverExpires ? (
    'indefinite'
  ) : (
    timeToExpiration <= 0
      ? 'expired'
      : timeToExpiration < IS_EXPIRING_SOON_THRESHOLD_MS
        ? 'expiring_soon'
        : timeToExpiration < IS_EXPIRING_THRESHOLD_MS
          ? 'expiring'
          : 'current'
  )

  return (
    <span
      className={cx(
        `device_attr_expires_at`,
        `icon icon-clock2`,
        `status_${statusClassName}`
      )}
      data-tooltip={neverExpires ? (
        `Never Expires`
      ) : (
        [
          `${statusClassName === 'expired' ? 'Expired' : 'Expires'} ${formatRelativeTime(asTimestamp)}`,
          `Updated ${formatRelativeTime(props.row.updatedAt)}`
        ].join("\r\n")
      )}
    />
  )
})

ExpiresAt.displayName = "ExpiresAt"
/**
 * Convenience wrapper for a SearchableValue that is a single File Hash. Handles
 * automatically building the intelcard and explorer query props.
 *
 *   <FileValue fileId="1b7b951ab74671afc533eca06757de2a0ed53a32" fileName="somename.txt" start={ startTime } end={ endTime } />
 *
 * If the start/end props are omitted, it defaults to the past hour.
 *
 * All other props supported by SearchableValue can be included and will be passed through.
 */

import _ from 'lodash'
import React from 'react'
import T from 'prop-types'
import SearchableValue from 'components/values/SearchableValue'
import UserProvider from 'components/UserProvider'
import { VALUE_TYPE_OPTIONS } from 'constants/searchableValueConstants'

const defaultDuration = 60 * 60 * 1000

class FileValue extends React.Component {
  static displayName = 'FileValue'

  static propTypes = _.assign(
    {
      fileName: T.string.isRequired,
      fileId: T.string.isRequired,

      children: T.any,
      //hashes: T.object,
      start: T.number,
      end: T.number,
      sensorId: T.number,
      noQuery: T.bool,
      noIntelCard: T.bool,
      noLog: T.bool
      // ...plus any others accepted by SearchableValue
    },
    UserProvider.PROP_TYPES
  )

  render() {
    const { props } = this
    const {
      children,
      fileName,
      fileId,
      start,
      end,
      noQuery
    } = props
    if (!fileId) {
      return <span>{fileName || '--'}</span>
    }
    const _end = end ? end : (start ? start + defaultDuration : Date.now())
    const _start = start ? start : (_end - defaultDuration)
    const query = noQuery
      ? null
      : {
        family: 'observations',
        clauses: [
          {
            name: 'occurredAt',
            op: 'between',
            from: _start,
            to: _end
          },
          {
            name: 'fileId',
            op: 'eq',
            value: fileId
          }
        ]
      }

    return (
      <SearchableValue
        {...this.props}
        className={'searchable_value ' + (props.className || '')}
        data-testid={props['data-testid']}
        intelCard={props.noIntelCard ? null : 'file:' + fileId}
        intelCardTooltip="View File Info"
        query={query}
        value={fileId}
        copyValue={children || fileName}
        valueType={VALUE_TYPE_OPTIONS.FILEHASH}
        relatedData={{ fileId }}
      >
        {children || fileName}
      </SearchableValue>
    )
  }
}

const WrappedFileValue = props =>
  <UserProvider>
    <FileValue {...props} />
  </UserProvider>

WrappedFileValue.displayName = 'WrappedFileValue'

export default WrappedFileValue

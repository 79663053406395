import moment from 'moment'

type M = () => moment.Moment // FIXME https://gitlab.int.protectwise.net/apps/console-ui/issues/13

export interface TimeRange {
  start: moment.Moment,
  end: moment.Moment
}

export interface Preset {
  id: string,
  display: string,
  getRange: (m: M) => TimeRange
}

const makeDurationGetter = (val, unit) => (m: M) => ({
  start: m().subtract(15, 'minutes').subtract(val, unit), // Avoid incomplete netflows. See https://gitlab.int.protectwise.net/engineering/squads/detections/default/-/issues/404#note_70836
  end: m().subtract(15, 'minutes')
})

const RELATIVE_TIME_PRESETS : Array<Array<Preset>> = [
  [
    // Current X
    {
      id: 'current_day',
      display: 'Today',
      getRange: (m: M) => ({
        start: m().startOf('day'),
        end: m().endOf('day')
      })
    },
    {
      id: 'current_week',
      display: 'This week',
      getRange: (m: M) => ({
        start: m().startOf('week'),
        end: m().endOf('week')
      })
    },
    {
      id: 'current_month',
      display: 'This month',
      getRange: (m: M) => ({
        start: m().startOf('month'),
        end: m().endOf('month')
      })
    },
    {
      id: 'current_year',
      display: 'This year',
      getRange: (m: M) => ({
        start: m().startOf('year'),
        end: m().endOf('year')
      })
    },
    // X to date
    {
      id: 'day_to_now',
      display: 'Today so far',
      getRange: (m: M) => ({
        start: m().startOf('day'),
        end: m()
      })
    },
    {
      id: 'week_to_date',
      display: 'Week to date',
      getRange: (m: M) => ({
        start: m().startOf('week'),
        end: m()
      })
    },
    {
      id: 'month_to_date',
      display: 'Month to date',
      getRange: (m: M) => ({
        start: m().startOf('month'),
        end: m()
      })
    },
    {
      id: 'year_to_date',
      display: 'Year to date',
      getRange: (m: M) => ({
        start: m().startOf('year'),
        end: m()
      })
    },
  ],
  [
    // Previous X
    {
      id: 'prev_day',
      display: 'Yesterday',
      getRange: (m: M) => {
        const d = m().subtract(1, 'days')

        return {
          start: d.clone().startOf('day'),
          end: d.clone().endOf('day')
        }
      }
    },
    {
      id: 'day_before_yesterday',
      display: 'Day before yesterday',
      getRange: (m: M) => {
        const d = m().subtract(2, 'days')

        return {
          start: d.clone().startOf('day'),
          end: d.clone().endOf('day')
        }
      }
    },
    {
      id: 'this_day_last_week',
      display: 'This day last week',
      getRange: (m: M) => {
        const d = m().subtract(1, 'weeks')

        return {
          start: d.clone().startOf('day'),
          end: d.clone().endOf('day')
        }
      }
    },
    {
      id: 'prev_week',
      display: 'Previous week',
      getRange: (m: M) => {
        const d = m().subtract(1, 'weeks')

        return {
          start: d.clone().startOf('week'),
          end: d.clone().endOf('week')
        }
      }
    },
    {
      id: 'prev_month',
      display: 'Previous month',
      getRange: (m: M) => {
        const d = m().subtract(1, 'months')

        return {
          start: d.clone().startOf('month'),
          end: d.clone().endOf('month')
        }
      }
    },
    {
      id: 'prev_year',
      display: 'Previous year',
      getRange: (m: M) => {
        const d = m().subtract(1, 'years')

        return {
          start: d.clone().startOf('year'),
          end: d.clone().endOf('year')
        }
      }
    },
  ],
  [
    // Last X duration
    {
      id: 'last_15_min',
      display: 'Last 15 minutes',
      getRange: makeDurationGetter(15, 'minutes')
    },
    {
      id: 'last_30_min',
      display: 'Last 30 minutes',
      getRange: makeDurationGetter(30, 'minutes')
    },
    {
      id: 'last_1_hour',
      display: 'Last hour',
      getRange: makeDurationGetter(1, 'hours')
    },
    {
      id: 'last_4_hours',
      display: 'Last 4 hours',
      getRange: makeDurationGetter(4, 'hours')
    },
    {
      id: 'last_12_hours',
      display: 'Last 12 hours',
      getRange: makeDurationGetter(12, 'hours')
    },
    {
      id: 'last_24_hours',
      display: 'Last 24 hours',
      getRange: makeDurationGetter(24, 'hours')
    },
  ],
  [
    {
      id: 'last_7_days',
      display: 'Last 7 days',
      getRange: makeDurationGetter(7, 'days')
    },
    {
      id: 'last_30_days',
      display: 'Last 30 days',
      getRange: makeDurationGetter(30, 'days')
    },
    {
      id: 'last_60_days',
      display: 'Last 60 days',
      getRange: makeDurationGetter(60, 'days')
    },
    {
      id: 'last_90_days',
      display: 'Last 90 days',
      getRange: makeDurationGetter(90, 'days')
    },
    {
      id: 'last_6_months',
      display: 'Last 6 months',
      getRange: makeDurationGetter(6, 'months')
    },
    {
      id: 'last_year',
      display: 'Last 1 year',
      getRange: makeDurationGetter(1, 'years')
    },
    // {
    //   id: 'last_2_years',
    //   display: 'Last 2 years',
    //   getRange: makeDurationGetter(2, 'years')
    // },
  ]
]

export const RELATIVE_TIME_PRESETS_BY_ID = RELATIVE_TIME_PRESETS.flat().reduce((output, preset) => {
  output[preset.id] = preset
  return output
}, {})

export const getTimePresetByDisplay = (presetDisplay) => {

}

export default RELATIVE_TIME_PRESETS
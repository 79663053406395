import _ from 'lodash'
import Reflux from 'reflux'

import IntelCardActions from 'actions/IntelCardActions'
import AnalyticsActions from 'actions/AnalyticsActions'
import eventUtils from 'utils/eventUtils'
import observationUtils from 'utils/observationUtils'
import moment from 'moment'
import {getThreatLevelFromThreatScore} from 'utils/intelManagementUtils'
import {requestGet, rethrowAsRestError, TRACEID_HEADER} from 'utils/restUtils'
import { requestSearch } from 'utils/searchUtils'
import SensorStore from 'stores/SensorStore'
import { getMoment } from 'utils/timeUtils'
import querystringUtil from 'ui-base/src/util/querystringUtil'

const MAX_RETRIES = 1
const SHA256_LENGTH = 64

// Wraps a set of partial results in an accessor function so that any single value
// can be queried from the available data by name/path without the hassle of complex null
// check sequences. The accessor always returns a Value instance, which can be used
// to see if the value is still loading/indeterminate and get at its wrapped value.
//     let accessor = createValueAccessor(data)
//     let value = accessor('path.to.the.desired.property')
//     let loading = value.isLoading()
//     let str = value.valueOf()
function createValueAccessor(data, prevAccessor) {
  const loading = !data || !_.isEmpty(data.retriableSources)
  const error = data && data.error
  return function accessor(path) {
    // Allow multiple values to be wrapped as one
    if (_.isArray(path)) {
      const vals = path.map(accessor).map(v => v.valueOf())
      return new Value(vals.every(_.isNull) ? undefined : vals, loading, error)
    }

    if (_.isFunction(path)) {
      path = path(data)
    }

    const parts = path.split('.')
    let val = data || undefined

    for (let p = 0; p < parts.length && (_.isObject(val) || _.isArray(val)); p++) {
      val = val[parts[p]]
    }
    if (prevAccessor && _.isUndefined(val)) {
      val = prevAccessor(path).rawValueOf()
    }

    return new Value(val, loading, error)
  }
}

// A single value wrapper returned by the value accessor above.
class Value {
  constructor(value, loading, error) {
    this._value = value
    this._loading = loading
    this._error = error
  }
  isLoading() {
    return this._loading && _.isUndefined(this._value)
  }
  isError() {
    return this._error && _.isUndefined(this._value)
  }
  isReady() {
    return !this.isLoading() && !this.isError()
  }
  valueOf() {
    return _.isUndefined(this._value) ? null : this._value //normalize undefined to null
  }
  rawValueOf() {
    return this._value
  }
}

function normalizeThreatData(data) {
  // Fill in events/observations data
  const events = _.get(data, 'threat.events.top', [])
  if (events) {
    eventUtils.formatList(events)
  }
  const obs = _.get(data, 'threat.observations.top') || _.get(data, 'observations.results')
  if (obs) {
    observationUtils.formatList(obs)
  }

  // Compute max threat score/level across both events and observations
  data.maxThreatScore = Math.max(_.get(events, '0.threatScore', 0), _.get(obs, '0.threatScore', 0))
  data.maxThreatLevel = getThreatLevelFromThreatScore(data.maxThreatScore || 0)
}

function normalizeDomainData(data) {
  // Sort DNS resolutions
  const resolves = data.domain && data.domain.resolveData
  if (resolves) {
    data.domain.resolveData = _.sortBy(resolves, r => -(r.lastSeen || 0))
  }
}

function normalizeDeviceData(data) {
  // The API currently populates both a `devices` array, as well as a single `device` property
  // which is just a hacky shortcut to the first of the `devices` with no purpose but for public
  // API back-compat. To ensure we're not relying on that hack, replace it with a throwing getter:
  Object.defineProperty(data, 'device', {
    enumerable: false,
    get() {throw new Error('`device` deprecated in favor of `devices` array or `devicesBySource` map')}
  })

  // To make the `devices` list easier to query into, create a map of devices by their intelSource:
  // TODO handle multiple devices with the same intelSource - currently throws away all but one.
  data.devicesBySource = _.indexBy(data.devices || [], 'intelSource')
}

const _state = {
  queryType: null, //'ip', 'domain', 'device', 'file'
  queryValue: null,
  isLoading: false,
  error: null,
  data: createValueAccessor(),
  protocols: null,
  sensorId: null
}

const IntelCardStore = Reflux.createStore({
  listenables: IntelCardActions,

  init() {

  },

  getInitialState() {
    return _state
  },


  // Handle IntelCardActions.query()
  onQuery(type, value) {
    _state.isOpen = true
    this._queueNotify()

    // Extract sensor ID encoded into device intelcard param; this is not used in the query
    // but is used within the device card's rendering.
    // TODO need to ensure this arg is absolutely needed and eliminate it if possible, otherwise
    // we should formalize the ability to pass contextual arguments without having to one-off
    // encode them in the main query key.
    if (type === 'device' && value.indexOf('|') !== -1) {
      const [deviceId, sensorId] = value.split('|')
      value = deviceId
      _state.sensorId = sensorId
    }

    // Don't requery if params are the same as last time
    if (_state.queryType === type && _state.queryValue === value && !_state.error) {
      return
    }

    _state.queryType = type
    _state.queryValue = value
    const endTime = _state.endTime = +moment.utc().add(1, 'day').startOf('day')
    let startTime = _state.startTime = endTime - 30 * 24 * 60 * 60 * 1000 //last 30 days
    _state.isLoading = true
    _state.error = null
    _state.data = createValueAccessor()
    _state.protocols = null

    const isDemoCustomer = window._pw.isDemoCustomer
    if (isDemoCustomer) {
      // last 14 days (to line up with POC default retention time)
      startTime = _state.startTime = endTime - 14 * 24 * 60 * 60 * 1000
    }

    // Launch request(s) for the data for each type. The promises here are responsible for adding
    // to `_state.data` as they get chunks of data; any promise resolution values are ignored.

    let promise
    if (type === 'ip') {
      const url = `reputations/ips/${ value }?details=threat,ip,domain,geo,device,reputations&start=${ startTime }&end=${ endTime }`
      promise = this._queryRetriable(url, [normalizeThreatData, normalizeDomainData, normalizeDeviceData])
    }
    else if (type === 'domain') {
      const url = `reputations/domains/${ encodeURIComponent(value) }?details=threat,domain,geo,reputations${isDemoCustomer ? '' : ',device'}&start=${ startTime }&end=${ endTime }`
      promise = this._queryRetriable(url, [normalizeThreatData, normalizeDomainData, normalizeDeviceData])
    }
    else if (type === 'device') {
      const url = `reputations/devices/${ value }?details=threat,device&start=${ startTime }&end=${ endTime }`
      promise = Promise.all([
        this._queryRetriable(url, [normalizeThreatData, normalizeDeviceData]),
        // we will eventually only call device db, in transition we still need to call nark for some fields
        this._queryDeviceDB(value, startTime, endTime)
      ])
    }
    else if (type === 'file') {
      promise = this._resolveFileSHA256(value).then(sha256 => {
        const url = `reputations/files/${ sha256 }?sources=observations,info,behavior&start=${ startTime }&end=${ endTime }`
        return this._queryRetriable(url, [normalizeThreatData])
      })
    } else if (type === 'certificate') {
      promise = this._queryCert(value)
    } else if (type === 'sensor') {
      promise = this._querySensor(value)
    }
    else {
      throw new Error(`Unknown intel card type: ${type}`)
    }

    // Common handler for completed queries
    promise = promise.then(() => {
      _state.isLoading = false
      this._queueNotify()
    })

    // Common handler for unrecoverable errors
    promise = promise.catch(restError => {
      _state.isLoading = false
      _state.error = restError
      _state.data = createValueAccessor({error: true})
      this._queueNotify()
    })

    // Track
    AnalyticsActions.event({
      eventCategory: 'intelcard',
      eventAction: type
    })
  },

  _queryDeviceDB(value, startTime, endTime) {
    let redirectCount = 0
    const queryEntityId = (id) => {
      return requestGet('entity-history', `entities/history/${ id }?start=${ startTime }&end=${ endTime }`)
        .then(data => {
          const device = _.first(data)
          if (device && device.migratedId && redirectCount < 10) { // stop at 10 for now, not very well defined in device db yet
            redirectCount++
            return queryEntityId(device.migratedId.newDeviceId.id)
          }
          _state.data = createValueAccessor({deviceDbData: device}, _state.data)
          this._queueNotify()
        })
    }
    return queryEntityId(value)
  },

  _queryRetriable(baseUrl, normalizers, skipValueAccessor=false) {
    let tries = 0
    const makeRequest = (url) => {
      return requestGet('intelcard_query', url, {resolveWithParsedBody: false})
        .then(response => {
          // parse json and pass on both it and the traceId
          const traceId = response.headers.get(TRACEID_HEADER) || null
          return response.json().then(data => ({traceId, data})).catch(rethrowAsRestError)
        })
        .then(({data, traceId}) => {
          _state.isLoading = false

          // Normalize the new data if needed
          if (normalizers) {
            normalizers.forEach(fn => {
              fn(data)
            })
          }

          _state.data = createValueAccessor(data, _state.data)

          // If it was a partial response, issue a retry
          if (data.retriableSources && data.retriableSources.length) {
            if (++tries <= MAX_RETRIES) {
              return makeRequest(`${ baseUrl }&sources=${ data.retriableSources.join(',') }`)
            } else {
              _state.data = createValueAccessor({error: true}, _state.data)

              _state.error = {
                heading: 'Error',
                body: 'Failed querying one or more data sources. Please try again later.',
                traceId
              }
            }
          }

          this._queueNotify()
        })
    }
    return makeRequest(baseUrl)
  },

  // Retrieve sha256 file hash associated with the fileId before issuing reputation queries
  _resolveFileSHA256(pwFileId) {
    let params
    if (pwFileId.length === SHA256_LENGTH) {
      // This is likely an old link containing a SHA256 hash instead of a PW File ID!
      // TODO can we just resolve with this directly, without requerying?
      params = `hashes=${pwFileId}`
      console.warn("Old sha256 based File Intel Card URL key found.")
    } else {
      params = `ids=${pwFileId}`
    }

    return requestGet(`rem_file_id_to_hashes`, `observations/file/metadata?${params}`)
      .then(fileResp => {
        _state.isLoading = false
        const sha256 = _.get(fileResp, `0.hashes.sha256`, null)
        if (sha256) {
          return sha256
        } else {
          rethrowAsRestError(new Error("Unable to retrieve SHA256 hash for file"))
        }
      })
  },

  _queryCert(certHash) {
    return requestGet('intelcard_query', `reputations/certs/${certHash}`)
      .then(data => {
        _state.data = createValueAccessor({
          certChain: _.get(data, 'sslCertificateChain.signingChain', null)
        })
      })
  },

  async _querySensor(sensorId) {
    // Basic sensor data is available right away
    const sensor = SensorStore.getSensor(sensorId)
    _state.data = createValueAccessor({sensor})

    // NOTE:
    // - data for the sensor's performance tab is handled separately by SensorPerformanceStore
    // - data for the sensor's config tab is handled separately by SensorConfigStore
  },

  onRetryQuery() {
    const {queryType, queryValue} = _state
    if (queryType && queryValue) {
      _state.queryType = _state.queryValue = null
      this.onQuery(queryType, queryValue)
    }
  },

  // Handle IntelCardActions.queryProtocols()
  onQueryProtocols() {
    let {queryType, queryValue} = _state
    if (!queryType || !queryValue) {
      return
    }

    // For domain queries we need to find the resolved IPs
    if (queryType !== 'ip') {
      queryValue = _state.data && _state.data('threat.ipAddresses').valueOf()
      if (_.isEmpty(queryValue)) {
        return
      }
    }

    _state.protocols = {
      isLoading: true
    }
    this._queueNotify()

    requestSearch('intelcard_protocols_query', 'netflows', {
      search: [
        {
          name: 'ip',
          op: _.isArray(queryValue) ? 'in' : 'eq',
          value: queryValue
        },
        {
          name: 'startTime',
          op: 'between',
          from: Date.now() - 24 * 60 * 60 * 1000, //just 1 day to avoid timeouts
          to: Date.now()
        }
      ],
      options: {
        limit: 0,
        facets: {
          fields: ['protocols'],
          minCount: 1
        }
      }
    })
      .then((data) => {
        _state.protocols = {
          data: ((data.facets || {}).fields || {}).protocols
        }
        this._queueNotify()
      })
      .catch(restError => {
        _state.protocols = {
          error: restError
        }
        this._queueNotify()
      })
  },

  // Handle IntelCardActions.openCard()
  onOpenCard() {
    _state.isOpen = true
    this._queueNotify()
  },

  // Handle IntelCardActions.closeCard()
  onCloseCard() {
    _state.isOpen = false
    this._queueNotify()
  },


  _notify() {
    this.trigger(_state)
  },

  _queueNotify() {
    clearTimeout(this._queueNotifyTimer)
    this._queueNotifyTimer = setTimeout(this._notify.bind(this), 100)
  }
})

export default IntelCardStore

import _ from 'lodash'
import cx from 'classnames'
import T from 'prop-types'
import React from 'react'
import {DateInput, TextInput} from 'react-base'
import moment from 'moment'
import {getMoment, formatDateWith, formatDate} from 'utils/timeUtils'
import UserProvider from 'components/UserProvider'
import {RelativeTimePresetDropdown} from './time-input/RelativeTimeInput'

const ONE_DAY = 24 * 60 * 60 * 1000
const ONE_HOUR = 60 * 60 * 1000

/**
 * Input component for a time range.
 *
 * TODO: move to react-base, but need to remove dependency on window._pw.moment
 */
class TimeRangeInput extends React.Component {
  static displayName = 'TimeRangeInput';

  static propTypes = {
    calendarAlign: T.oneOf(['top', 'left', 'bottom']),
    calendarClass: T.string,
    className: T.string,
    dateFormat: T.string,
    datePlaceholder: T.string,
    fromLabel: T.string,
    initialValue: T.arrayOf(T.number),
    minTime: T.number, // Minimum selectable time
    onSave: T.func.isRequired,
    timeFormat: T.string,
    timePlaceholder: T.string,
    toLabel: T.string,
    utc: T.bool
  };

  static defaultProps = function() {
    const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD'
    const DEFAULT_TIME_FORMAT = 'HH:mm:ss'
    return {
      calendarAlign: 'top',
      fromLabel: 'From',
      minTime: 0,
      toLabel: 'To',
      dateFormat: DEFAULT_DATE_FORMAT,
      timeFormat: DEFAULT_TIME_FORMAT,
      datePlaceholder: DEFAULT_DATE_FORMAT.toLowerCase(),
      timePlaceholder: DEFAULT_TIME_FORMAT.toLowerCase()
    }
  }();

  _getValues = () => {
    const val = this.props.initialValue
    return _.isArray(val) ? val : [null, null]
  };

  _getMoment = () => {
    return getMoment(this.props.utc)
  }

  _moment = (val) => {
    const pwMoment = this._getMoment()
    return pwMoment(val)
  };

  _copyTime = (srcDate, tgtDate) => {
    tgtDate = this._moment(tgtDate)
    if (srcDate) {
      srcDate = this._moment(srcDate)
      tgtDate.hour(srcDate.hour()).minute(srcDate.minute()).second(srcDate.second())
    } else {
      tgtDate.hour(0).minute(0).second(0)
    }
    const tgt = +tgtDate
    return tgt && !isNaN(tgt) ? tgt : null
  };

  _copyDay = (srcDate, tgtDate) => {
    srcDate = this._moment(srcDate || Date.now())
    tgtDate = this._moment(tgtDate)
    tgtDate.year(srcDate.year()).month(srcDate.month()).date(srcDate.date())
    return +tgtDate
  };

  _onFromDateChange = (newDate) => {
    let [
      oldFromTime,
      toTime
    ] = this._getValues()
    let newFromTime = newDate && this._copyTime(oldFromTime, newDate)
    if (toTime && newFromTime) {
      if (toTime === newFromTime) {
        newFromTime -= ONE_HOUR
      }
      else if (toTime < newFromTime) {
        toTime = newFromTime + (oldFromTime ? toTime - oldFromTime : ONE_DAY)
      }
    }
    this.props.onSave([
      newFromTime,
      toTime || null
    ])
  };

  _onFromTimeChange = (value) => {
    const pwMoment = getMoment()
    let newFromTime = pwMoment(value, this.props.timeFormat)
    if (newFromTime.isValid()) {
      let [oldFromTime, toTime] = this._getValues()
      newFromTime = this._copyDay(oldFromTime, newFromTime)
      if (toTime) {
        if (toTime === newFromTime) {
          newFromTime -= ONE_HOUR
        }
        else if (toTime < newFromTime) {
          toTime = newFromTime + (oldFromTime ? toTime - oldFromTime : ONE_DAY)
        }
      }
      this.props.onSave([
        newFromTime,
        toTime || null
      ])
    }
  };

  _onToDateChange = (newDate) => {
    let [fromTime, oldToTime] = this._getValues()
    let newToTime = newDate && this._copyTime(oldToTime, newDate)
    if (fromTime && newToTime) {
      if (fromTime === newToTime) {
        newToTime += ONE_HOUR
      }
      else if (fromTime && fromTime > newToTime) {
        fromTime = Math.max(newToTime - (oldToTime ? oldToTime - fromTime : ONE_DAY), this.props.minTime)
      }
    }
    this.props.onSave([
      fromTime || null,
      newToTime
    ])
  };

  _onToTimeChange = (value) => {
    const pwMoment = getMoment()
    let newToTime = pwMoment(value, this.props.timeFormat)
    if (newToTime.isValid()) {
      let [fromTime, oldToTime] = this._getValues()
      newToTime = this._copyDay(oldToTime, newToTime)
      if (fromTime) {
        if (fromTime === newToTime) {
          newToTime += ONE_HOUR
        }
        else if (fromTime && fromTime > newToTime) {
          fromTime = newToTime - (oldToTime ? oldToTime - fromTime : ONE_DAY)
        }
      }
      this.props.onSave([
        fromTime || null,
        newToTime
      ])
    }
  };

  _validateTimeEntry = (value, isToTime) => {
    if (value) {
      const pwMoment = getMoment()
      const {
        minTime,
        timeFormat
      } = this.props
      const newTimeValue = pwMoment(value, timeFormat)
      if (!newTimeValue.isValid()) {
        return `Invalid time. Correct format is ${ timeFormat }`
      }
      if (minTime) {
        const [
          currentFromTime,
          currentToTime
        ] = this._getValues()
        const timestamp = +this._copyDay(isToTime ? currentToTime : currentFromTime, newTimeValue)
        if (timestamp < minTime) {
          // return `Time must be later than ${ formatDate(minTime) }`
        }
      }
    }
    return null // Valid entry
  };

  _validateFromTime = (value) => {
    return this._validateTimeEntry(value, false)
  };

  _validateToTime = (value) => {
    return this._validateTimeEntry(value, true)
  };

  handleRelativeTimeChange = preset => {
    const pwMoment = this._getMoment()
    const {
      // id,
      // display,
      getRange
    } = preset
    const {start, end} = getRange(pwMoment)
    this.props.onSave([
      +start,
      +end
    ])
  }

  render() {
    const {props} = this
    const values = this._getValues()
    const fromValue = values[0] && new Date(values[0])
    const toValue = values[1] && new Date(values[1])

    return (
      <div className={ cx('time_range_input', props.className) }>
        <div className={`relative group`}>
          <div className="group_label"></div>
          <div className="inputs_wrap">
            <RelativeTimePresetDropdown onChange={this.handleRelativeTimeChange} />
          </div>
        </div>
        <div className="from group">
          <div className="group_label">{ props.fromLabel }</div>
          <div className="inputs_wrap">
            <DateInput
              calendarAlign={ props.calendarAlign }
              calendarClass={ props.calendarClass }
              className="form-control date"
              dateFormat={ props.dateFormat }
              initialValue={ fromValue }
              onSave={ this._onFromDateChange }
              placeholder={ props.datePlaceholder }
              utc={ props.utc }
              validRange={ [props.minTime || null, null] }
            />
            <TextInput
              alwaysSetInitialValue
              className="form-control time"
              initialValue={ values[0] && formatDateWith(values[0], props.timeFormat) }
              onSave={ this._onFromTimeChange }
              placeholder={ props.timePlaceholder }
              validator={ this._validateFromTime }
            />
          </div>
        </div>

        <div className="to group">
          <div className="group_label">{ props.toLabel }</div>
          <div className="inputs_wrap">

            <DateInput
              calendarAlign={ props.calendarAlign }
              calendarClass={ props.calendarClass }
              className="form-control date"
              dateFormat={ props.dateFormat }
              initialValue={ toValue }
              onSave={ this._onToDateChange }
              placeholder={ props.datePlaceholder }
              utc={ props.utc }
              validRange={ [props.minTime, null] }
            />
            <TextInput
              alwaysSetInitialValue
              className="form-control time"
              initialValue={ values[1] && formatDateWith(values[1], props.timeFormat) }
              onSave={ this._onToTimeChange }
              placeholder={ props.timePlaceholder }
              validator={ this._validateToTime }
            />
          </div>
        </div>
      </div>
    )
  }
}

function mapRetentionToMinTime (props) {
  return {
    // minTime: Date.now() - props.userInfo.retentionMillis
    minTime: props.userInfo.retentionStartTime
  }
}


export default (props) => (
  <UserProvider mapping={ mapRetentionToMinTime }>
    <TimeRangeInput { ...props } />
  </UserProvider>
)

import {RawDevice, FlattenedDeviceAttribute} from '../../typings/devices/device'
import {DeviceAttribute} from '../../typings/devices/deviceAttribute'
import DeviceModel from 'models/devices/Device';
import { DeviceAttributeSource } from 'typings/devices/deviceAttributeSource';


/** Transformer for Devices
 *
 * Modify Device records to have keys for all available attributes
 */

const isMigratedDevice = device => device.hasOwnProperty('migrated_device_id')
const getAttributeTime = att => {
  // TODO should this account for `att.expirationInfo.ttl`?
  return att.expirationInfo.updatedTime
}

const flattenAttribute = (attributes, attributeProp: string) => {
  // Filter, sort, and flatten attributes
  const sortedAttrs = attributes
    .filter(att => att.detail.hasOwnProperty(attributeProp))
    .filter(att => filterDeviceAttributes(att, attributeProp))
    .sort((att1, att2) => getAttributeTime(att1) > getAttributeTime(att2))
    .map(att => ({
      attributeId: attributeProp,
      value: att.detail[attributeProp],
      source: att.source,
      updatedAt: new Date(att.expirationInfo.updatedTime),
      ttl: att.expirationInfo.ttl || 0
    }))

  return sortedAttrs.length > 0 ? sortedAttrs : null
}

const filterDeviceAttributes = (attribute: FlattenedDeviceAttribute, attributeProp: string) => {
  if (attribute.source === DeviceAttributeSource.ProtectwiseAnalytics && attributeProp === DeviceAttribute.description) {
    return false // PW Analytics descriptions are internal only. Not sensitive, but not useful to users.
  }
  return true
}

const transformDevice = (apiDevice: RawDevice) : DeviceModel => {
  const output: any = {
    id: apiDevice.id,
    cid: apiDevice.cid
  }
  if (isMigratedDevice(apiDevice)) {
    output.migrated_device_id = apiDevice.migrated_device_id
    return output
  }
  output.occurredAt = new Date(apiDevice.occurredAt)
  output.tags = apiDevice.tags

  if (apiDevice.previousDeviceIds) {
    // Strip CIDs from previous device IDs
    output.previousDeviceIds = apiDevice.previousDeviceIds.map(v => v.id)
  }

  // Flatten attributes
  const deviceAttributes = apiDevice.attributes
  if (deviceAttributes) {
    const attributeKeys = Object.keys(DeviceAttribute)
    for (let i = 0, iLen = attributeKeys.length; i < iLen; i++) {
      const key = attributeKeys[i]
      const flattened = flattenAttribute(apiDevice.attributes, key)
      if (flattened) {
        // const attributeModel = getValueModel(key)
        // output[key] = new DeviceAttributeSet(flattened, attributeModel)
        output[key] = flattened
      }
    }
  }

  // Additional attributes

  return output
}

export default transformDevice
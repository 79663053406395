import adaptiveBezierCurve from 'adaptive-bezier-curve'
import bezier from 'bezier'

const cubicBezierCurve = bezier.prepare(4)

const DEFAULT_OPTIONS = {
  bezierNumPoints: 0 // If omitted/falsy, will use adaptive-bezier-curve. Otherwise will use a bezier function that outputs a fixed length of points
}

class PathBuilder {
  constructor(options = DEFAULT_OPTIONS) {
    this._bezierNumPoints = options.bezierNumPoints
    this._points = []
  }

  moveTo(x, y) {
    if (this._points.length) throw new Error('moveTo only supported at start of path')
    this.lineTo(x, y)
  }

  closePath() {
    const points = this._points
    if (points.length > 1) {
      points.push(points[0].slice())
    }
  }

  lineTo(x, y) {
    this._points.push([x, y])
  }

  bezierCurveTo(x1, y1, x2, y2, x, y) {
    const points = this._points
    const startPoint = points[points.length - 1]
    if (this._bezierNumPoints) {

      // Fixed-precision curve (number of output points)
      const xPoints = [startPoint[0], x1, x2, x]
      const yPoints = [startPoint[1], y1, y2, y]
      // Start with idx=1 (t!=0), to avoid duplicate points
      for (let i = 1; i < this._bezierNumPoints + 1; i++) {
        const t = i * (1 / this._bezierNumPoints)
        const newsdf=[
          cubicBezierCurve(xPoints, t),
          cubicBezierCurve(yPoints, t)
        ]
        points.push(newsdf)
      }
    }
    else {
      adaptiveBezierCurve(
        startPoint,
        [x1, y1],
        [x2, y2],
        [x, y],
        1.0,
        points
      )
    }

  }

  getPoints() {
    return this._points
  }
}

export default PathBuilder

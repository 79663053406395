import _ from 'lodash'
import getConfig from 'utils/uiConfig'
import React from 'react'
import VersionStore from 'stores/VersionStore'
import AnalyticsActions from 'actions/AnalyticsActions'
import { getSupportEmailFooterText } from 'utils/errorUtils'
// import ExternalLink from 'components/ExternalLink'

const version = VersionStore.getInitialState().currentVersionDisplay

const getEmailBody = () => {
  return `
Please let us know what you're trying to do, and how we can help:


${getSupportEmailFooterText()}
`
}

const onLinkClick =e => {
  AnalyticsActions.event({
    eventCategory: 'help',
    eventAction: e.target.dataset.action,
    eventLabel: e.target.dataset.label || undefined
  })
}

const HelpWidget = () => {
  const uiConfig = getConfig()
  const quickLinks = (uiConfig.urls.supportQuickLinks || []).concat({
    label: 'Support Portal',
    url: uiConfig.urls.support
  })

  return (
    <div className="meta_menu">
      <h3>How can we help?</h3>
      <h4>Quick Links</h4>
      <div className="meta_info">
        <div>
          <a
            href={`/#settings/help-videos`}
            data-action="help_videos"
            onClick={onLinkClick}
          >Instructional Videos</a>
        </div>
        <div>
          <a
            href={`/#settings/help-guides`}
            data-action="help_guides"
            onClick={onLinkClick}
          >Help Guides</a>
        </div>
        {/* {quickLinks.map(({label, url}, i) =>
          <div key={i}>
            <ExternalLink
              href={url}
              data-action="support_site"
              data-label={url}
              onClick={onLinkClick}
            >
              {label}
            </ExternalLink>
          </div>
        )} */}
      </div>
      <h4>Contact Support</h4>
      <div className="meta_info">
        <a
          href={`mailto:${uiConfig.support.email}?body=${encodeURIComponent(getEmailBody())}`}
          data-action="support_email"
          onClick={onLinkClick}
        >
          {uiConfig.support.email}
        </a>
      </div>
      <h4>Phone Support</h4>
      <div className="meta_info">
        <a
          href={`tel:${uiConfig.support.telephone.replace(/[^\d]/g, '')}`}
          data-action="support_phone"
          onClick={onLinkClick}
        >
          {uiConfig.support.telephone}
        </a>
      </div>
      <div className="meta_info ui_version">
        UI Version: {version}
      </div>
    </div>
  )
}

export default HelpWidget
import React from 'react'
import T from 'prop-types'
import { DimensionAware } from 'react-base'
import { scaleLinear } from 'd3-scale'
import { getD3TimeScale } from '../../utils/timeUtils'
import memoize from 'memoize-one'

/**
 * HOC wrapper that calculates x and y timeline scales based on a set of data `items` and
 * and the current dimensions, and passes the results to the wrapped component as props.
 * @param {class} WrappedComponent
 * @return {class}
 */
export default function (WrappedComponent) {
  class AxisScaleAware extends React.Component {
    static propTypes = {
      items: T.arrayOf( //Treated as immutable
        T.shape({
          data: T.arrayOf(T.shape({ timestamp: T.number, value: T.number })),
          affectsScale: T.bool, //defaults to true if omitted
          //className: T.string,
          //shape: T.oneOf(['smooth', 'step'])
        })
      ),
      fixedMaxValue: T.number, //optional fixed y max domain value
      defaultMaxValue: T.number, //optional default y max domain value

      // From DimensionAware HOC:
      width: T.number,
      height: T.number
    }

    // Memoized function for calculating current x/y scales based on data and current dimensions
    _getScales = memoize((width, height, items, fixedMaxValue, defaultMaxValue = 0) => {
      // Find data limits
      let minTime = Infinity, maxTime = -Infinity, maxValue = defaultMaxValue
      for (let { data, affectsScale } of items) {
        if (data && affectsScale !== false) {
          for (let { timestamp, value } of data) {
            if (timestamp < minTime) minTime = timestamp
            if (timestamp > maxTime) maxTime = timestamp
            if (value > maxValue) maxValue = value
          }
        }
      }

      // Determine appropriate tick counts based on dimensions
      const xTickCount = Math.floor(width / 200)
      const yTickCount = Math.round(height / 50)

      // Create scales
      const xScale = getD3TimeScale()().domain([minTime, maxTime]).range([0, width])
      let yScale = (fixedMaxValue == null
        ? scaleLinear().domain([0, maxValue]).nice(yTickCount)
        : scaleLinear().domain([0, fixedMaxValue])
      ).rangeRound([height, 0])

      return {
        xScale,
        yScale,
        xTickCount,
        yTickCount
      }
    })

    render() {
      const { width, height, items, fixedMaxValue, defaultMaxValue } = this.props
      const scaleProps = width && height ? this._getScales(width, height, items, fixedMaxValue, defaultMaxValue) : {}
      return <WrappedComponent {...this.props} {...scaleProps} />
    }
  }

  return DimensionAware(AxisScaleAware)
}

/*
export const scaleAwareProps = {
  width: T.number,
  height: T.number,
  xScale: T.func,
  yScale: T.func,
  xTickCount: T.number,
  yTickCount: T.number
}
*/

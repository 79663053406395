import _ from 'lodash'
import React from 'react'
import {
  ScrollBars,
  LoadingValue
} from 'react-base'
import ReactDOM from 'react-dom'
import { select as d3Select } from 'd3-selection'
import { geoMercator, geoPath } from 'd3-geo'
import worldMapUtil from 'ui-base/src/util/worldMapUtil'
import worldMapOptions from 'components/world-map/worldMapOptions'
import IntelCardPropTypes from '../PropTypes'
import DataCell from './DataCell'
import ErroredValue from './ErroredValue'
import geoDataUtil from 'utils/geoDataUtil'

const MAP_WIDTH = 320
const MAP_HEIGHT = 170
const EMPTY_OBJ = {}

const mapProjection = geoMercator()
  .center([0, 30]) //center north of equator to avoid blank space at south
  .translate([MAP_WIDTH / 2, MAP_HEIGHT / 2])
  .scale(MAP_WIDTH / 2 / Math.PI) //NOTE: scale(1) means longitude=180 maps to x=PI

const geoVal = val => {
  val = val.valueOf()
  return val === '-' ? null : val
}

class Network extends React.Component {
  static displayName = 'Network'
  static propTypes = IntelCardPropTypes.dataCardShape

  componentDidMount() {
    this._drawMap()
  }

  componentDidUpdate() {
    this._drawMap()
  }

  _drawMap = () => {
    // Draw the world map
    const earthG = this.refs.earthG && ReactDOM.findDOMNode(this.refs.earthG)
    if (earthG && earthG.childNodes.length === 0) {
      geoDataUtil.getWorld('low', true).then(regionData => {
        worldMapUtil.drawCountryData({
          svg: d3Select(earthG),
          mapOptions: worldMapOptions,
          geoPath: geoPath().projection(mapProjection),
          countriesData: regionData,
          applyNonScalingStroke:
            window._pwCompatibility.supportsNonScalingStroke
        })
      })
    }
  }

  render() {
    return (
      <div className="intel_card intel_data_card intel_data_network">
        <h3>Network Intel</h3>
        <div className="intel_card_content">
          {this.renderData()}
        </div>
      </div>
    )
  }

  renderData = () => {
    const { props } = this
    const data = props.data

    const threatLevelCls =
      'lvl_' + (data('maxThreatLevel').valueOf() || 'none').toLowerCase()
    const riskFactors = data('threat.riskFactors')
    const city = data('geo.city')
    const region = data('geo.region')
    const country = data('geo.country')
    const lat = data('geo.latitude').valueOf()
    const lng = data('geo.longitude').valueOf()
    const geoXY =
      _.isNumber(lat) && _.isNumber(lng)
        ? mapProjection([lng, lat])
        : [-100, -100]
    const organization =
      props.queryType === 'ip'
        ? data('ip.organization')
        : data('domain.organization')
    const host = data('ip.host')
    const isp = data('ip.isp')
    const asName = data('ip.asName')
    const asNumber = data('ip.asNumber')

    const ipReputation = data('reputations.0.partnerCategory')

    return (
      <ScrollBars
        classes="pw_scrollbars_light"
        outside
        slimShady
        slimShadyDarker
      >
        <div ref="mapCt" className="intel_card_map">
          <svg width="100%" height="100%" ref="mapSvg">
            <g className="earth" ref="earthG" />
            <g className="points" ref="pointsG">
              <circle
                cx={geoXY[0]}
                cy={geoXY[1]}
                r="2"
                className={threatLevelCls}
              />
            </g>
          </svg>
          <span className="geo_label">
            {city.isLoading() || region.isLoading() || country.isLoading()
              ? <LoadingValue />
              : city.isError() || region.isError() || country.isError()
                ? <ErroredValue />
                : _.compact([
                    geoVal(city),
                    geoVal(region),
                    geoVal(country)
                  ]).join(', ') || 'No Location Information Available'}
          </span>
        </div>

        {props.queryType === 'ip'
          ? [riskFactors, organization, host, isp, asName, asNumber].some(
              val => !val.isReady() || val.valueOf() !== null
            )
            ? <div>
                {/* No longer available from platform
              <DataCell label="Risk Factors" animateHeight>
                { riskFactors.isLoading() ? (
                  <LoadingValue />
                ) : riskFactors.isError() ? (
                  <ErroredValue />
                ) : riskFactors.valueOf() ? (
                  <ul className="risks">
                    {
                      _.map(riskFactors.valueOf(), (val, key) => {
                        let lvl = val >= 20 ? 'high' : val >= 10 ? 'medium' : val > 0 ? 'low' : 'none'
                        return <li key={ key }><span className={ 'lvl lvl_' + lvl }></span>{ constants.riskFactorsToNames[key] || 'Unknown Risk' }</li>
                      })
                    }
                  </ul>
                ) : 'None' }
              </DataCell>
              */}
                <DataCell
                  label="Organization"
                  value={organization}
                  default="Unknown"
                  animateHeight
                />
                <DataCell
                  label="Host"
                  value={host}
                  default="Unknown"
                  textOverflow
                />
                {/* No longer available from platform
              <DataCell label="ISP" value={ isp } default="Unknown" animateHeight />
              <DataCell label="AS Name" value={ asName } default="Unknown" animateHeight />
              <DataCell label="AS Number" value={ asNumber } default="Unknown" />
              */}

                <DataCell
                  label="IP Reputation"
                  value={ipReputation}
                  default="Unknown"
                  textOverflow
                />
              </div>
            : <h5>
                <br />No Network Information Available
              </h5>
          : <div>
              <DataCell
                label="Organization"
                value={organization}
                default="Unknown"
              />
            </div>}
      </ScrollBars>
    )
  }
}

export default Network

import React, { FunctionComponent } from 'react'
import {
  observer
} from 'mobx-react'
import SensorValue from 'components/values/SensorValue'

export interface SensorProps {
  value: number // SensorId
}

export const Sensor: FunctionComponent<SensorProps> = observer(props => {
  return (
    <SensorValue
      sensorId={props.value}
    />
  )
})

Sensor.displayName = "Sensor"
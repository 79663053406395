import _ from 'lodash'
import Reflux from 'reflux'

import LocationActions from 'actions/LocationActions'
import {requestDelete, requestGet, requestPost, requestPut} from 'utils/restUtils'

let _state = {
  locations: [],
  locationsById: {},
  locationsError: null,
  loadingLocations: false
}

export default Reflux.createStore({
  listenables: [LocationActions],
  init() {

  },

  getInitialState() {
    return _state
  },

  _notify() {
    _state.locationsById = _.indexBy(_state.locations, 'id')
    this.trigger(_state)
  },

  // Load all existing tags from the server, for client-side matching
  onLoadAll() {
    _state.locationsError = null
    _state.loadingLocations = true
    this._notify()
    // Return promise for usage in DataManager
    return requestGet("get_locations", "locations")
      .then(data => {
        _state.locations = data
        _state.loadingLocations = false
        this._notify()
      })
      .catch(restError => {
        _state.loadingLocations = false
        _state.locationsError = restError
        this._notify()
      })
  },

  onResetSearch() {
    _state.searchResults = []
    this._notify()
  },

  _sanitizeLocation(location) {
    let _location = _.pick(location, [
      'address',
      'id',
      'customer_id',
      'latitude',
      'longitude',
      'name',
      'description',
      'country_code',
      'shape'
    ])

    if (_.isEmpty(_location.description)) {
      _location.description = null
    }

    if (_.isEmpty(_location.name)) {
      _location.name = null
    }

    return _location
  },

  getLocationById(locationId) {
    return _state.locationsById[locationId]
  },

  isLoading() {
    return _state.loadingLocations
  },

  onSaveLocation(location) {
    if (!location) return
    _state.locationsError = null
    _state.loadingLocations = true
    this._notify()
    let isNew = !location.id || location.id === -1
    let req = isNew ?
      requestPost(null, `locations`, this._sanitizeLocation(location)) :
      requestPut(null, `locations/${ location.id }`, this._sanitizeLocation(location))
    req.then(() => {
      _state.loadingLocations = false
      this._notify()
      this.onLoadAll() // Reload master list
    })
    .catch(restError => {
      let _error = restError
      _state.loadingLocations = false
      _state.locationsError = _error
      this._notify()
    })
  },

  onDeleteLocation(locationId) {
    if (!locationId || locationId < 0) { return }
    _state.locationsError = null
    _state.loadingLocations = true
    requestDelete(null, "locations/" + locationId)
      .then(() => {
        _state.loadingLocations = false
        this._notify()
        this.onLoadAll() // Reload master list
      })
      .catch(restError => {
        _state.loadingLocations = false
        _state.locationsError = restError
        this._notify()
      })
  }
})

import React from 'react'
import ExternalLink from 'components/ExternalLink'
import VersionStore from 'stores/VersionStore'
import getConfig from 'utils/uiConfig'

export default function LoginCardFooter (props = { hidden: false }) {
  const uiConfig = getConfig()
  const version = VersionStore.getInitialState().currentVersionDisplay
  return (
    <div className={`slide_card login_footer_wrap ${props.hidden ? 'hidden' : ''}`}>
      <div className="login_footer_row">
        <ExternalLink
          className="btn btn-link btn-xs"
          href={`mailto:${uiConfig.support.email} `}
        >
          {uiConfig.support.email}
        </ExternalLink>
        <ExternalLink
          className="btn btn-link btn-xs"
          href={`tel:${uiConfig.support.telephone.replace(
            /[^\d]/g,
            ''
          )}`}
        >
          {uiConfig.support.telephone}
        </ExternalLink>
      </div>
      <div className="login_footer_row">
        <ExternalLink
          className="btn btn-link btn-xs"
          href={uiConfig.urls.privacyPolicy}
        >
          Privacy Policy
        </ExternalLink>
        <span className="version_number">
          {version}
        </span>
      </div>
    </div>
  )
}

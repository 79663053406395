import {
  observable,
  flow,
  action,
  computed,
  autorun,
  reaction
} from 'mobx'
import {
  computedFn
} from 'mobx-utils'
import { Store } from 'stores/mobx/StoreManager'
import UserStore from 'stores/UserStore'
import {
  getDefaultPcapFileName,
  getDefaultPcapFileNameForNetflows,
  getDefaultPcapFileNameForObservations,
  getDefaultExplorerDeauxFileName
} from 'utils/downloadUtils'
import {
  observationsRequest,
  netflowsRequest
} from 'utils/pcapUtils'
import AnalyticsActions from 'actions/AnalyticsActions'
import {
  requestPost,
  requestGet,
  abortRequest,
  RestError
} from 'utils/restUtils'
import { saveAs } from 'filesaver.js'
import genericUtil from 'ui-base/src/util/genericUtil'

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
const LS_KEY = 'ndr_downloads'
const DEFAULT_EXPIRES_DURATION = genericUtil.durations(1, 'weeks')
const getLSKey = () => UserStore.isEmulatingCustomer() ? `${UserStore.getCurrentCustomerID()}:${LS_KEY}` : LS_KEY

/**
 * Download state.
 * @typedef DownloadState
 * @readonly
 * @enum {string}
 */
export const DOWNLOAD_STATE = {
  ADDED: 'ADDED',

  PREPARING: 'PREPARING', // Prepare the file for download
  READY: 'READY', // The file is ready to download

  PENDING: 'PENDING', // The file is being downloaded

  INTERRUPTED: 'INTERRUPTED', // e.g. due to browser navigation away during stream read
  CANCELED: 'CANCELED', // The download or file prep was canceled
  ABORTED: 'ABORTED',
  FAILED: 'FAILED',

  COMPLETED: 'COMPLETED' // The download is complete
}

/**
 * Download Request
 *
 * @typedef {object} Request
 * @property {('POST'|'GET')} method
 * @property {string} url
 * @property {object} params URL parameters if a GET request, Otherwise passed as POST body.
 *
 */

 /**
  * Async download task
  *
  * @typedef {object} TaskState
  * @property {guid} id Unique Identifier for this download task
  * @property {('Initiated'|'Completed'|'Failed')} status Current async download task status. Initiated: File is being transferred. Completed: File is ready to be downloaded by client
  * @property {string} [location] URI for Completed file downloads to be retrieved
  * @property {number} bytes Current bytelength/size of file being transferred. Updated every ~5s
  * @property {object} req The original task request parameters
  * @property {object} [metadata] File metadata, populated for Completed tasks
  * @property {number} [metadata.size] Final file size
  * @property {number} [metadata.unit] Final file size unit (expect "bytes")
  * @property {string} [metadata.modifiedAt] Last modification time for transferred file
  * @property {string} [metadata.expiresAt] Datetime that the transferred file will be removed from S3, requiring a new task.
  */

/**
 * Download object
 *
 * @typedef {object} Download
 * @property {boolean} [async] This download is an async request
 * @property {DownloadState} status Current download state
 * @property {string} [statusMsg] Optional string message about the current status
 * @property {string} fileName Name of the file being downloaded
 * @property {string} [mimeType] File type
 * @property {Request} request The HTTP request information for this download. May be mutated during the course of a download event.
 * @property {Request} originalRequest Original Request for this download
 * @property {Request} [fallbackSyncRequest] Optional fallback request for async downloads
 * @property {number} [bytesDownloaded]
 * @property {number} [bytesExpected]
 * @property {number} [bytesExpectedEst] Estimated byte total expected, overridden when bytesExpected has a value
 * @property {number} [addedAt] Timestamp that this download was added originally
 * @property {number} [startedAt] Timestamp that this download started
 * @property {number} [completedAt] Timestamp that this download completed (either success or failure)
 * @property {number} [expiresAt] For `async` downloads, the timestamp that the prepared file will no longer be available.
 * @property {object} [error] Error details
 */

const DEFAULTS = Object.freeze({
  title: '<New Download>',
  fileName: '<unknown>',
  async: true,
  status: DOWNLOAD_STATE.ADDED,
  statusMsg: undefined,
  addedAt: undefined,
  startedAt: undefined,
  completedAt: undefined,
  expiresAt: undefined,
  bytesExpected: -1,
  bytesDownloaded: 0,
  mimeType: undefined,
  request: undefined,
  originalRequest: undefined,
  fallbackSyncRequest: undefined,
  error: undefined
})

const TOOLTIP_TIME_MS = 750

const _getDownloadKey = params => {
  return `_dl_${params.map(p => JSON.stringify(p)).join('_')}`
}

const _getFileNameFromHeaders = headers => {
  const val = headers.get('content-disposition')
  const filenameParam = 'filename='
  if (!val) return
  const fileNamePos = val.indexOf(filenameParam)
  if (fileNamePos && fileNamePos + filenameParam.length > val.length) {
    return val.slice(fileNamePos)
  }
}

async function _fetchStoredDownloads () {
  const key = getLSKey()
  const raw = localStorage.getItem(key)
  const stored = JSON.parse(raw)
  const output = new Map()

  for (const key in stored) {
    if (stored.hasOwnProperty(key)) {
      output.set(key, stored[key])
    }
  }

  return await Promise.resolve(output)
}

export default class DownloadStore extends Store {
  @observable downloads = new Map()
  @observable isLoading = false
  @observable error = null
  @observable isTooltipVisible = null

  _timer = null
  _readers = new Map()
  _pendingChunks = new Map()

  constructor () {
    super()

    this._hasUnloadHandler = false

    const cleanupUnloader = autorun(() => {
      if (this.pendingCount > 0) {
        if (!this._hasUnloadHandler) {
          this._hasUnloadHandler = true
          window.addEventListener('beforeunload', this.handleBeforeUnload)
        }
      } else {
        this._hasUnloadHandler = false
        window.removeEventListener('beforeunload', this.handleBeforeUnload)
      }
    })
    const cleanupStorageWatcher = reaction(
      () => {
        const output = {}
        this.downloads.forEach(dl => {
          // Persist all download state changes except pending
          if (
            dl.status === DOWNLOAD_STATE.COMPLETED
            || dl.status === DOWNLOAD_STATE.PREPARING // Initiated async downloads may be downloaded at a later time
            || dl.status === DOWNLOAD_STATE.ADDED
            || dl.status === DOWNLOAD_STATE.READY
            || dl.status === DOWNLOAD_STATE.FAILED
          ) {
            output[dl.key] = dl
          } else {
            // All other states are transient and should be ignored after reload
            if (dl.status === DOWNLOAD_STATE.INTERRUPTED && dl.taskStatus === 'Completed') {
              output[dl.key] = {
                ...dl,
                status: DOWNLOAD_STATE.READY,
                statusMsg: 'Ready to download',
                bytesExpected: -2,
                bytesDownloaded: 0,
                error: null,
              }
            } else {
              output[dl.key] = {
                ...dl,
                status: DOWNLOAD_STATE.ADDED,
                statusMsg: 'Added',
                bytesExpected: 0,
                bytesDownloaded: 0,
                error: null
              }
            }
          }
        })
        return output
      },
      (output) => {
        const key = getLSKey()
        localStorage.setItem(key, JSON.stringify(output))
      }
    )

    this.destroy = () => {
      cleanupStorageWatcher()
      cleanupUnloader()
      this.cleanupTempData()
      window.removeEventListener('beforeunload', this.handleBeforeUnload)
      clearTimeout(this._timer)
    }
  }

  handleBeforeUnload (event) {
    // TODO show custom GUI reminder with message about pending downloads? No way to customize the alertbox text.
    event.preventDefault()
    event.returnValue = '' // Per MDN
  }

  cleanupTempData (key) {
    this._pendingChunks.delete(key)
    this._readers.delete(key)
  }

  /**
   * Load any pending downloads that might need to be resumed or restarted
   * @memberof DownloadStore
   */
  loadExisting = flow(function * () {
    this.downloads = new Map()
    this.isLoading = true
    this.error = null
    try {
      this.downloads = yield _fetchStoredDownloads()
    }
    catch (error) {
      this.error = error
    }
    this.downloads.forEach((dl, key) => {
      if (dl.async && dl.status === DOWNLOAD_STATE.PREPARING) {
        // Any async downloads that were previously left in the PREPARING state should be polled for current progress
        if (dl.taskId) {
          this.monitorTask(key)
        } else {
          // Initiate any async tasks that may have been abandoned before the initial task request completed
          this.initiateAsyncTask(key)
        }
      }
    })
    this.isLoading = false
  })

  initiateAsyncTask = flow(function * _initiateAsyncTask (key) {
    const dl = this.downloads.get(key)
    dl.status = DOWNLOAD_STATE.PREPARING
    dl.statusMsg = 'Preparing file...'

    try {
      var response = yield requestPost(null, dl.request.url, dl.request.params, {
        useSocket: false,
        useFetch: true,
        resolveWithParsedBody: true,
        rejectOnAbort: true
      })
    } catch (error) {
      this.handleError(key, error)
      return
    }

    dl.taskId = response.id
    dl.taskStatus = response.status
    dl.expiresAt = undefined
    dl.taskAttempts = 0

    this.monitorTask(key)
  })

  monitorTask = flow(function * _monitorTask (key) {
    const dl = this.downloads.get(key)
    if (!dl.taskId) {
      this.handleError(key, new Error('Download is missing taskId'))
      return
    }
    if (dl.status === DOWNLOAD_STATE.CANCELED) {
      dl.bytesDownloaded = 0
      return
    }
    if (!dl.taskAttempts) {
      dl.taskAttempts = 0
    }
    let _task
    try {
      const url = `pcaps/download/tasks/${dl.taskId}`
      const delay = Math.pow(dl.taskAttempts, 1.3) * 500
      yield sleep(delay)
      _task = yield requestGet(null, url, {
        useSocket: false,
        useFetch: true,
        dataType: 'json',
        resolveWithParsedBody: true,
        rejectOnAbort: false
      })
    } catch (error) {
      if (dl.taskAttempts > 5) {
        return this.handleError(key, error)
      }
      dl.taskAttempts += 1
      return this.monitorTask(key)
    }

    dl.taskStatus = _task.status

    switch (dl.taskStatus) {
      case 'Completed': {
        if (_task.metadata) {
          dl.expiresAt = _task.metadata.expiresAt ? new Date(_task.metadata.expiresAt).getTime() : (Date.now() + DEFAULT_EXPIRES_DURATION)
          if (_task.metadata.size > 0) {
            dl.bytesExpected = +_task.metadata.size
          }
        }

        if (_task.metadata && +_task.metadata.size === 0) {
          // Bail out if the file is empty
          this.handleError(key, new Error('Downloaded file is empty.'))
          return
        }

        dl.originalRequest = observable(Object.assign({}, dl.request))
        dl.request = observable({
          method: 'GET',
          url: `pcaps/download/tasks/${dl.taskId}/result`
        })
        dl.status = DOWNLOAD_STATE.READY
        dl.statusMsg = 'Ready to download.'

        return this.initiateDownload(key)
      }
      case 'Initiated': {
        dl.taskAttempts += 1
        if (_task.bytes > 0) {
          dl.bytesExpected = +_task.bytes // Pending byte count
        }
        return this.monitorTask(key)
      }
      case 'Failed': {
        this.handleError(key, new Error('File preparation failed. Please try again later.'))
        break
      }
      default:
        throw new Error(`Unhandled Async Download task status: "${dl.taskStatus}"`)
    }
  })

  initiateDownload = flow(function * _initiateDownload (key) {
    const dl = this.downloads.get(key)
    dl.status = DOWNLOAD_STATE.PENDING
    dl.statusMsg = 'Downloading...'
    const options = dl.request.options || {}
    try {
      const opts = {
        ...options,
        useSocket: false,
        useFetch: true,
        resolveWithParsedBody: false,
        rejectOnAbort: true
      }
      const fn = dl.request.method === 'GET'
        ? requestGet.bind(null, null, dl.request.url, opts)
        : requestPost.bind(null, null, dl.request.url, dl.request.params, opts)
      var response = yield fn()
    } catch (error) {
      this.handleError(key, error)
      return
    }

    const contentLength = response.headers.get('content-length')
    const mimeType = response.headers.get('content-type')
    const fileName = _getFileNameFromHeaders(response.headers)

    dl.mimeType = mimeType
    dl.fileName = fileName || dl.fileName

    dl.bytesExpected = contentLength != null ? +contentLength : (dl.bytesExpected || -1)
    dl.bytesDownloaded = 0

    const reader = response.body.getReader()
    this._readers.set(key, reader)
    this._pendingChunks.set(key, [])
    this.stepStream(key)
  })

  /**
   * @description Advance the stream reader associated with the given `key`, downloading the next chunk
   * @param {string} key Download key
   * @memberof DownloadStore
   */
  stepStream = flow(function * _stepStream (key) {
    const dl = this.downloads.get(key)
    const reader = this._readers.get(key)
    const chunks = this._pendingChunks.get(key)

    if (dl.status !== DOWNLOAD_STATE.PENDING) {
      return
    }

    // `done` is true for the last chunk
    // `value` is Uint8Array of the chunk bytes
    let done, value
    try {
      const step = yield reader.read()
      done = step.done
      value = step.value
    } catch (error) {
      this.handleError(key, error, DOWNLOAD_STATE.INTERRUPTED, 'Download Stream Error')
      return
    }

    if (done) {
      if (dl.status === DOWNLOAD_STATE.PENDING) {
        // Normal "complete" exit. CANCELED or FAILED will also trigger
        // `done` here and we don't want to emit a file in those cases
        this.handleComplete(key)
      }
      this.cleanupTempData(key)
      return
    }

    if (value) {
      chunks.push(value)
      dl.bytesDownloaded += value.length
    }

    if (!done) {
      this.stepStream(key)
    }
  })

  @action.bound
  handleError (key, error, forceStatus, forceStatusMsg) {
    const dl = this.downloads.get(key)

    let _error, _status, _statusMsg

    if (error instanceof RestError) {
      if (error.isAbort) {
        _status = DOWNLOAD_STATE.ABORTED
        _statusMsg = 'Downloaded request aborted'
      } else {
        _status = DOWNLOAD_STATE.FAILED
        _statusMsg = 'Downloaded request failed'
        _error = {
          heading: error.heading,
          body: error.body,
          traceId: error.traceId
        }
      }
    } else {
      _status = DOWNLOAD_STATE.FAILED
      _statusMsg = error.toString()
    }

    dl.completedAt = Date.now()
    if (_error) {
      dl.error = observable(_error)
    }
    dl.status = forceStatus || _status
    dl.statusMsg = forceStatusMsg || _statusMsg
  }

  @action.bound
  handleComplete (key) {
    const dl = this.downloads.get(key)
    const chunks = this._pendingChunks.get(key)
    dl.completedAt = Date.now()

    if (dl.bytesDownloaded > 0) {
      dl.status = DOWNLOAD_STATE.COMPLETED
      dl.statusMsg = 'Done'
      const blob = new Blob(chunks, {type: `${dl.mimeType};charset=utf-8`})
      saveAs(blob, dl.fileName)
    }
    else {
      this.handleError(key, new Error('Downloaded file is empty.'))
    }
  }

  @action.bound
  restart (key) {
    const dl = this.downloads.get(key)
    dl.status = DOWNLOAD_STATE.ADDED
    dl.statusMsg = 'Restarting...'
    dl.bytesExpected = dl.bytesExpectedEst || -2
    dl.bytesDownloaded = 0
    dl.error = undefined
    if (dl.async && dl.originalRequest) {
      dl.request = observable(dl.originalRequest)
      delete dl.originalRequest
    }
    this.startDownload(key)
  }

  @action.bound
  restartSync (key) {
    const dl = this.downloads.get(key)
    dl.async = false
    dl.status = DOWNLOAD_STATE.ADDED
    dl.statusMsg = 'Restarting Fallback...'
    dl.bytesDownloaded = 0
    dl.error = undefined
    dl.originalRequest = observable(dl.request)
    dl.request = observable(dl.fallbackSyncRequest)
    this.initiateDownload(key)
  }

  @action.bound
  redownload (key) {
    const dl = this.downloads.get(key)
    if (dl.async && dl.taskStatus === 'Completed') {
      dl.startedAt = Date.now()
      dl.completedAt = undefined
      dl.error = undefined
      dl.bytesDownloaded = 0
      this.initiateDownload(key)
    }
  }

  @action.bound
  cancel (key) {
    const dl = this.downloads.get(key)

    dl.bytesDownloaded = 0
    dl.status = DOWNLOAD_STATE.CANCELED
    dl.statusMsg = 'Canceled'

    // Try to cancel the pending reader stream
    const reader = this._readers.get(key)
    if (reader) {
      reader.cancel()
    }

    // Try to abort the initial request if it's still pending
    // (should be a noop for downloads that have started streaming)
    abortRequest(key)

    this.cleanupTempData(key)
  }

  @action.bound
  clear (key) {
    const dl = this.downloads.get(key)
    this.cancel(dl.key) // Cancel if pending or paused
    this.downloads.delete(key)
    this.cleanupTempData(key)
  }

  @action.bound
  clearAll () {
    this.downloads.forEach(dl => {
      this.cancel(dl.key)
    })
    this.downloads = new Map()
    this._readers = new Map()
  }

  @action.bound
  downloadPcapForObservations (observations, fileName) {
    fileName = `${ fileName || getDefaultPcapFileNameForObservations(observations) }.pcap`
    const pcaps = observationsRequest(observations)
    const key = _getDownloadKey(['pcap_obs', fileName, pcaps])

    AnalyticsActions.event({
      eventCategory: 'pcap',
      eventAction: 'pcap_download',
      eventLabel: 'observation',
      eventValue: observations.length
    })

    this.addDownload({
      key,
      title: fileName,
      fileName: fileName,
      request: {
        method: 'POST',
        url: 'pcaps/download/tasks',
        params: {
          pcaps
        }
      },
      fallbackSyncRequest: {
        method: 'POST',
        url: 'pcaps/download',
        params: {
          pcaps
        }
      },
    })
  }

  @action.bound
  downloadPcapForNetflows (flows, fileName) {
    // Trigger PCAP file download. flows param must be an array of netflow objects
    fileName = `${ fileName || getDefaultPcapFileNameForNetflows(flows) }.pcap`
    const pcaps = netflowsRequest(flows)
    const key = _getDownloadKey(['pcap_nf', fileName, pcaps])
    const totalBytesExpectedEstimate = flows.reduce((total, netflow) => {
      return total + netflow.stats.bytesSrcIncluded + netflow.stats.bytesDstIncluded
    }, 0)

    // Track
    AnalyticsActions.event({
      eventCategory: 'pcap',
      eventAction: 'pcap_download',
      eventLabel: 'netflow',
      eventValue: pcaps.length
    })

    this.addDownload({
      key,
      title: fileName,
      fileName: fileName,
      bytesExpectedEst: totalBytesExpectedEstimate,
      request: {
        method: 'POST',
        url: 'pcaps/download/tasks',
        params: {
          pcaps
        }
      },
      fallbackSyncRequest: {
        method: 'POST',
        url: 'pcaps/download',
        params: {
          pcaps
        }
      },
    })
  }

  @action.bound
  downloadPcaps (pcapRequest, fileName) {
    fileName = `${ fileName || getDefaultPcapFileName(pcapRequest) }.pcap`
    const key = _getDownloadKey(['pcap_', fileName, pcapRequest])

    AnalyticsActions.event({
      eventCategory: 'pcap',
      eventAction: 'pcap_download',
      eventLabel: 'generic'
    })

    this.addDownload({
      key,
      title: fileName,
      fileName: fileName,
      bytesExpected: -2,
      request: {
        method: 'POST',
        url: 'pcaps/download/tasks',
        params: {
          pcaps: pcapRequest
        }
      },
      fallbackSyncRequest: {
        method: 'POST',
        url: 'pcaps/download',
        params: {
          pcaps: pcapRequest
        }
      }
    })
  }

  @action.bound
  downloadExplorerDeuxQueryResults (id, bytesExpected = -2) {
    const fileName = getDefaultExplorerDeauxFileName(id)
    const key = _getDownloadKey(['explorer_results_', fileName])

    AnalyticsActions.event({
      eventCategory: 'explorer',
      eventAction: 'explorer_download',
      eventLabel: 'generic'
    })

    this.addDownload({
      async: false,
      key,
      title: fileName,
      fileName,
      bytesExpected,
      request: {
        method: 'GET',
        url: `query/results/download?id=${id}`,
        options: {
          baseURL: '/api/alpha/'
        }
      }
    })
  }

  @action.bound
  addDownload (download) {
    this.showTooltip()
    download = observable(Object.assign({}, DEFAULTS, download))
    download.addedAt = Date.now() // Track request duration

    const key = download.key
    this.downloads.set(key, download)
    this.startDownload(key)
  }

  @action.bound
  startDownload (key) {
    const dl = this.downloads.get(key)
    dl.startedAt = Date.now()
    dl.completedAt = undefined
    dl.error = undefined
    dl.bytesDownloaded = 0
    if (dl.async) {
      this.initiateAsyncTask(key)
    } else {
      this.initiateDownload(key)
    }
  }


  @action.bound
  showTooltip () {
    this.isTooltipVisible = true
    clearTimeout(this._timer)
    this._timer = setTimeout(this.hideTooltip, TOOLTIP_TIME_MS)
  }

  @action.bound
  hideTooltip () {
    this.isTooltipVisible = false
    clearTimeout(this._timer)
  }

  @computed
  get pendingCount () {
    let ct = 0
    this.downloads.forEach(dl => {
      if (dl.status === DOWNLOAD_STATE.PREPARING || dl.status === DOWNLOAD_STATE.PENDING) {
        ct++
      }
    })
    return ct
  }

  @computed
  get errorCount () {
    let ct = 0
    this.downloads.forEach(dl => {
      if (
        dl.status === DOWNLOAD_STATE.FAILED
        || dl.status === DOWNLOAD_STATE.INTERRUPTED
        || dl.status === DOWNLOAD_STATE.CANCELED
        || dl.status === DOWNLOAD_STATE.ABORTED
      ) {
        ct++
      }
    })
    return ct
  }

  @computed
  get remainderCount () {
    return this.downloads.size - this.pendingCount - this.errorCount
  }

  getDuration = (key) => {
    const dl = this.downloads.get(key)
    if (dl) {

      if (dl.startedAt && dl.completedAt) {
        return dl.completedAt - dl.startedAt
      }
      if (dl.status === DOWNLOAD_STATE.PREPARING || dl.status === DOWNLOAD_STATE.PENDING) {
        return Date.now() - dl.startedAt
      }
    }
  }
}


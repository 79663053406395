import React from 'react'
import StaticAsset from 'components/StaticAsset'

const LoginBrand = () => {
  return (
    <div className="brand_wrap_main">
      <div className="logo_wrap">
        <StaticAsset asset="mainLogo" />
      </div>
      <div className="product_name">
        <StaticAsset asset="productTitle" />
      </div>
    </div>
  )
}

const BrandMain = () => {
  return (
    <div>
      <LoginBrand />
    </div>
  )
}

export default BrandMain


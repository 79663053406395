import T from 'prop-types'
import React from 'react'
import TooltipStore from 'stores/TooltipStore'
import {StoreProvider} from 'react-base'

class Tooltip extends React.Component {
  static displayName = 'Tooltip';

  static propTypes = {
    content: T.any,
    isExactPosition: T.bool,
    isVisible: T.bool,
    position: T.shape({
      top: T.number,
      left: T.number,
      height: T.number,
      width: T.number
    })
  };

  static defaultProps = {
    position: {
      top: 0,
      left: 0,
      height: 0,
      width: 0
    },
    content: '',
    isExactPosition: false
  };

  _computePosition = () => {
    var top, left
    let {position, isExactPosition, isVisible} = this.props
    if (isExactPosition) {
      top = Math.round(position.top) + 'px'
      left = Math.round(position.left) + 'px'
    } else {
      top = Math.round(position.top + (position.height / 2)) + 'px'
      left = Math.round(position.left + (position.width / 2)) + 'px'
    }

    return isVisible ? {
      visibility: 'visible',
      WebkitTransform: `translate(${left},${top})`,
      transform: `translate(${left},${top})`
    } : {
      visibility: 'hidden',
      WebkitTransform: `translate(-1000px,-1000px)`,
      transform: `translate(-1000px,-1000px)`
    }
  };

  render() {
    return (
      <div
        className="pw_tooltip_wrap"
        style={ this._computePosition() }
      >
        { this.props.content }
      </div>
    )
  }
}

// export default Tooltip
export default (props) => (
  <StoreProvider store={ TooltipStore }>
    <Tooltip { ...props } />
  </StoreProvider>
)

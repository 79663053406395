import _ from 'lodash'
import Reflux from 'reflux'
import IntegrationsActions from 'actions/IntegrationsActions'
import AnalyticsActions from "actions/AnalyticsActions"
import CommonViewActions from 'actions/CommonViewActions'
import {requestDelete, requestGet, requestPatch, requestPost, requestPut} from 'utils/restUtils'
import { deviceTypes } from 'constants/integrationsConstants'

const MOCK_PAN_LIST = [ { id: '12njbh321nkjn32', name: 'Demo IP Block List' } ]

const REMEDIATION_LISTS_AVAILABLE = 'REMEDIATION_LISTS_AVAILABLE'

const _state = {
  proxies: {
    isLoading: true,
    isSaving: false,
    error: null,
    data: null
  },
  devices: {
    isLoading: true,
    isSaving: false,
    error: null,
    data: null
  },
  pipInstallTokens: {},
  connectionStatus: {
    requestId: 0,
    devices: {}, // {deviceId: {proxyId: true}}
    proxies: {} // {proxyId: numberOfOnlinePips}
  },
  remediation: {
    lists: [],
    blocked: [],
    available: [], //favorites
    isSaving: false,
    isLoading: false,
    errors: null
  }
}

/**
 * Put all proxy.deviceIds in the same order as their corresponding devices, and vice-versa, to
 * minimize connection line crossings.
 */
function sortConnections() {
  const proxies = _.get(_state, 'proxies.data')
  const devices = _.get(_state, 'devices.data')
  if (proxies && devices) {
    const proxyIds = _.pluck(proxies, 'id')
    const deviceIds = _.pluck(devices, 'id')
    proxies.forEach(proxy => {
      proxy.deviceIds = _.sortBy(proxy.deviceIds || [], deviceId => deviceIds.indexOf(deviceId))
    })
    devices.forEach(device => {
      device.proxyIds = _.sortBy(device.proxyIds || [], proxyId => proxyIds.indexOf(proxyId))
    })
  }
}





export function mapDeviceAPIToUI(data) {
  const cfg = data.configuration
  const creds = cfg.credentials || {}
  const deviceType = cfg.deviceType[0] //TODO can there be multiple?
  const vendorCfg = deviceTypes[deviceType] && deviceTypes[deviceType].vendors[cfg.vendor]
  let integrationJsonData = cfg && cfg.integrationJsonData || null
  if (integrationJsonData) {
    try {
      integrationJsonData = JSON.parse(integrationJsonData)
    }
    catch(e) {
      console.warn(`Could not parse device integrationJsonData: ${integrationJsonData}`)
    }
  }
  return {
    id: data.id,
    type: deviceType,
    vendor: cfg.vendor,
    version: cfg.version,
    timezone: cfg.timezone,
    name: cfg.name,
    uri: cfg.uri,
    insecureSkipVerify: cfg.deviceOptions.insecureSkipVerify,
    credentialType: creds.username ? 'password' : creds.token ? 'token' : (vendorCfg && _.first(vendorCfg.allowAuth) || null),
    username: creds.username,
    password: creds.password,
    token: creds.token,
    sensorIds: cfg.sensorIds || [],
    proxyIds: cfg.pipIds || [],
    integrationJsonData,
    hasDirectLink: cfg.deviceOptions.hasDirectLink
  }
}

export function mapDeviceUIToAPI(data) {
  const cfg = {}
  _.forOwn(data, (value, name) => {
    switch(name) {
      case 'type':
        cfg.deviceType = [value]
        break
      case 'username':
      case 'password':
      case 'token':
        (cfg.credentials || (cfg.credentials = {}))[name] = value
        break
      case 'credentialType':
        break
      case 'integrationJsonData':
        cfg.integrationJsonData = JSON.stringify(value)
        break
      case 'insecureSkipVerify':
        cfg.deviceOptions = {
          insecureSkipVerify: value
        }
        break
      default:
        cfg[name] = value
    }
  })

  return {configuration: cfg}
}

function mapProxyAPIToUI(data) {
  return {
    id: data.id,
    name: data.configuration.name,
    deviceIds: data.devices || [],
    connectionCount: data.instances ? data.instances.length : 0
  }
}

function mapProxyUIToAPI(data) {
  return {
    configuration: {
      enabled: true,
      name: data.name
    }
  }
}




export default Reflux.createStore({
  listenables: IntegrationsActions,

  init() {
    this._onApiWriteError = this._onApiWriteError.bind(this)
  },

  getInitialState() {
    return _state
  },

  _notify() {
    this.trigger(_state)
  },

  _queueNotify() {
    clearTimeout(this._queueNotifyTimer)
    this._queueNotifyTimer = setTimeout(this._notify.bind(this), 10)
  },

  // common handler for create/update/delete xhr failures; notify and reload everything fresh
  _onApiWriteError(restError) {
    CommonViewActions.Notification.add({
      type: 'error',
      heading: 'Error',
      message: restError.body || 'An error occurred.',
      dismissTimer: 15000
    })
    this.onLoadProxies()
    this.onLoadDevices()
  },

  onLoadProxies() {
    _state.proxies.isLoading = true
    _state.proxies.error = null
    this._queueNotify()

    requestGet('integration-proxies', 'pips')
      .then((data) => {
        _state.proxies = {
          data: _(data).sortBy('createdAt').map(mapProxyAPIToUI).value()
        }
        sortConnections()

        // Update connection status with freshened counts
        _state.connectionStatus.proxies = data.reduce((out, proxy) => {
          const count = proxy.instances ? proxy.instances.length : 0
          out[proxy.id] = {
            online: count > 0,
            message: null,
            count: count
          }
          return out
        }, {})

        this._queueNotify()
      })
      .catch(restError => {
        _state.proxies = {
          error: restError
        }
        this._queueNotify()
      })
  },

  onLoadDevices() {
    _state.devices.isLoading = true
    _state.devices.error = null
    this._queueNotify()

    requestGet('integration-sources', 'integrations')
      .then((data) => {
        _state.devices = {
          data: _(data).sortBy('createdAt').map(mapDeviceAPIToUI).value()
        }
        sortConnections()
        this._queueNotify()
      })
      .catch(restError => {
        _state.devices = {
          error: restError
        }
        this._queueNotify()
      })
  },

  onTestConnections() {
    // Devices
    const devicePromise = requestGet('integration-source-status', 'integrations/status')
      .then(data => {
        const deviceStatus = {}
        _.forOwn(data, (deviceInfo, deviceId) => {
          deviceStatus[deviceId] = {}
          _.forOwn(deviceInfo.pipConnectionInfo, (pipInfo, pipId) => {
            deviceStatus[deviceId][pipId] = {
              online: !!pipInfo.deviceAccessible,
              message: pipInfo.message,
              count: pipInfo.deviceAccessible ? 1 : 0
            }
          })
        })
        _state.connectionStatus.devices = deviceStatus
        this._queueNotify()
      })
      .catch(restError => {
        _state.connectionStatus.devices = {}
        this._queueNotify()
      })

    // PIPs
    // TODO should we update the main proxies data too, since this gives us a potentially fresher version?
    const proxyPromise = requestGet('integration-proxy-status', 'pips')
      .then((data) => {
        _state.connectionStatus.proxies = data.reduce((out, proxy) => {
          const count = proxy.instances ? proxy.instances.length : 0
          out[proxy.id] = {
            online: count > 0,
            message: null,
            count: count
          }
          return out
        }, {})
        this._queueNotify()
      })
      .catch(restError => {
        _state.connectionStatus.proxies = {}
        this._queueNotify()
      })

    Promise.all([proxyPromise, devicePromise]).then(() => {
      _state.connectionStatus = _.clone(_state.connectionStatus)
      _state.connectionStatus.requestId++
      this._queueNotify()
    })
  },

  onCreateProxy(data) {
    _state.proxies.isSaving = true
    this._queueNotify()

    const tempCreateId = data._tempCreateId
    data = mapProxyUIToAPI(data)
    requestPost('integration-proxy-create', 'pips', data)
      .then((resultData) => {
        _state.proxies.isSaving = false

        // Update in-memory data with response
        _state.pipInstallTokens[resultData.id] = resultData.installToken
        resultData = mapProxyAPIToUI(resultData)
        resultData._tempCreateId = tempCreateId
        _state.proxies.data = _state.proxies.data.concat([resultData])

        // If this is the first proxy, and there's exactly one device, assume the user is
        // intending to connect the two and do it for them.
        if (_state.proxies.data.length === 1 && _.get(_state, 'devices.data.length') === 1) {
          IntegrationsActions.changeConnections({
            action: 'connect',
            proxyId: resultData.id,
            deviceId: _state.devices.data[0].id
          })
        }

        this._queueNotify()

        // Track
        AnalyticsActions.event({
          eventCategory: 'contextfusion_management',
          eventAction: 'pip',
          eventLabel: 'add'
        })
      })
      .catch(this._onApiWriteError)
  },

  onUpdateProxy(id, data) {
    _state.proxies.isSaving = true
    this._queueNotify()

    data = mapProxyUIToAPI(data)
    requestPut('integration-proxy-update', `pips/${ id }`, data)
      .then((resultData) => {
        _state.proxies.isSaving = false

        // Update in-memory data with response
        _state.proxies.data = _.map(_state.proxies.data, proxy => proxy.id === id ? mapProxyAPIToUI(resultData) : proxy)

        this._queueNotify()

        // Track
        AnalyticsActions.event({
          eventCategory: 'contextfusion_management',
          eventAction: 'pip',
          eventLabel: 'modify'
        })
      })
      .catch(this._onApiWriteError)
  },

  onDeleteProxy(id) {
    _state.proxies.isSaving = true
    this._queueNotify()

    requestDelete('integration-proxy-delete', `pips/${ id }`)
      .then((data) => {
        _state.proxies.isSaving = false

        // Remove from in-memory data
        const proxy = _.find(_state.proxies.data, {id})
        if (proxy) {
          _state.proxies.data = _.without(_state.proxies.data, proxy)
          if(proxy.deviceIds) {
            proxy.deviceIds.forEach(deviceId => {
              const device = _state.devices.data && _.find(_state.devices.data, {id: deviceId})
              if (device) {
                device.proxyIds = _.without(device.proxyIds || [], id)
              }
            })
          }
        }

        this._queueNotify()
        IntegrationsActions.testConnections()

        // Track
        AnalyticsActions.event({
          eventCategory: 'contextfusion_management',
          eventAction: 'pip',
          eventLabel: 'delete'
        })
      })
      .catch(this._onApiWriteError)
  },

  onRequestProxyInstallToken(proxyId) {
    requestPost('integration-proxy-token', `pips/${ proxyId }/token`)
      .then((data) => {
        _state.pipInstallTokens[proxyId] = data.installToken
        this._queueNotify()
      })
      .catch(() => {
        CommonViewActions.Notification.add({
          type: 'error',
          heading: 'Error',
          message: 'Could not generate install token. Please try again later.',
          dismissTimer: 15000
        })
      })

    // Track
    AnalyticsActions.event({
      eventCategory: 'contextfusion_management',
      eventAction: 'pip',
      eventLabel: 'generate_install_token'
    })
  },


  onCreateDevice(data) {
    _state.devices.isSaving = true
    this._queueNotify()

    const tempCreateId = data._tempCreateId
    data = mapDeviceUIToAPI(data)

    requestPost('integration-source-create', 'integrations', data)
      .then((resultData) => {
        _state.devices.isSaving = false

        // Update in-memory data with response
        resultData = mapDeviceAPIToUI(resultData)
        resultData._tempCreateId = tempCreateId
        _state.devices.data = _state.devices.data.concat([resultData])

        // If this is the first device, and there's exactly one proxy, assume the user is
        // intending to connect the two and do it for them.
        if (_state.devices.data.length === 1 && _.get(_state, 'proxies.data.length') === 1) {
          IntegrationsActions.changeConnections({
            action: 'connect',
            proxyId: _state.proxies.data[0].id,
            deviceId: resultData.id
          })
        }

        this._queueNotify()

        // Track
        AnalyticsActions.event({
          eventCategory: 'contextfusion_management',
          eventAction: 'integration_source',
          eventLabel: 'add'
        })
      })
      .catch(this._onApiWriteError)
  },

  onUpdateDevice(id, data) {
    _state.devices.isSaving = true
    this._notify() // just notify here to avoid race below when we skip the update

    data = mapDeviceUIToAPI(data)

    // TODO: fix this with new validation in device forms
    // stop gap for updating an AWS device with IAM creds
    if (_.isEmpty(data.configuration)) {
      _state.devices.isSaving = false
      return this._queueNotify()
    }

    requestPut('integration-source-update', `integrations/${ id }`, data)
      .then((resultData) => {
        _state.devices.isSaving = false

        // Update in-memory data with response
        _state.devices.data = _.map(_state.devices.data, device => device.id === id ? mapDeviceAPIToUI(resultData) : device)

        this._queueNotify()

        // Track
        AnalyticsActions.event({
          eventCategory: 'contextfusion_management',
          eventAction: 'integration_source',
          eventLabel: 'modify'
        })
      })
      .catch(this._onApiWriteError)
  },

  onDeleteDevice(id) {
    _state.devices.isSaving = true
    this._queueNotify()

    requestDelete('integration-source-delete', `integrations/${ id }`)
      .then((data) => {
        _state.devices.isSaving = false

        // Remove connections from in-memory proxies
        const device = _.find(_state.devices.data, {id})
        if (device) {
          _state.devices.data = _.without(_state.devices.data, device)
          if (device.proxyIds) {
            device.proxyIds.forEach(proxyId => {
              const proxy = _state.proxies.data && _.find(_state.proxies.data, {id: proxyId})
              if (proxy) {
                proxy.deviceIds = _.without(proxy.deviceIds || [], id)
              }
            })
          }
        }

        // IntegrationsActions.loadDevices()
        // cant load devices again here until Nark fixes it's race condition between nodes
        IntegrationsActions.testConnections()
        this._queueNotify()

        // Track
        AnalyticsActions.event({
          eventCategory: 'contextfusion_management',
          eventAction: 'integration_source',
          eventLabel: 'delete'
        })
      })
      .catch(this._onApiWriteError)
  },

  onChangeConnections(...instructions) {
    const devicesToUpdate = {}

    instructions.forEach(({action, proxyId, deviceId}) => {
      const proxy = _state.proxies.data && _.find(_state.proxies.data, {id: proxyId})
      const device = _state.devices.data && _.find(_state.devices.data, {id: deviceId})
      var newDeviceIds, newProxyIds
      if (proxy && device) {
        if (action === 'disconnect') {
          newDeviceIds = _.without(proxy.deviceIds || [], deviceId)
          newProxyIds = _.without(device.proxyIds || [], proxyId)
        } else if (action === 'connect') {
          newDeviceIds = _.clone(proxy.deviceIds || [])
          newDeviceIds.push(deviceId)
          newProxyIds = _.clone(device.proxyIds || [])
          newProxyIds.push(proxyId)
        }

        // Update in-memory data immediately
        proxy.deviceIds = newDeviceIds
        device.proxyIds = newProxyIds

        // Add to queue
        devicesToUpdate[deviceId] = newProxyIds
      } else {
        console.warn(`No proxy ${proxyId} or device ${deviceId}`)
      }
    })
    sortConnections()
    this._queueNotify()

    // Issue a request for each modified device. The corresponding proxies will be updated
    // transparently by the server so we don't need to issue separate requests for those.
    const requests = _.values(_.mapValues(devicesToUpdate, (proxyIds, deviceId) => {
      return requestPut(`integrations-cxn-${ deviceId }`, `integrations/${ deviceId }`, {configuration: {pipIds: proxyIds}})
    }))
    Promise.all(requests)
      .then(() => {
        // TODO would like to freshen the data, but the nark triggers may not have happened yet so we get stale/asymmetrical data
        //IntegrationsActions.loadProxies()
        //IntegrationsActions.loadDevices()
        IntegrationsActions.testConnections()
      })
      .catch(this._onApiWriteError)
  },

  onLoadBlockLists() {
    _state.remediation.errors = null
    _state.remediation.isLoading = true
    _state.remediation.lists = []
    _state.remediation.available = []
    this._queueNotify()

    // Load all lists and favorited lists together
    Promise.all([
      requestGet('remediation-block-lists', 'remediation/lists', { baseURL: '/api/alpha/' }),
      requestGet('remediation-block-lists-favs', 'remediation/lists/favorites', { baseURL: '/api/alpha/' })
    ])
    .then(
      ([listsData, favsData]) => {
        _state.remediation.isLoading = false
        _state.remediation.lists = listsData.results || []
        _state.remediation.available = favsData.results
          ? favsData.results.map(blockList =>
            Object.assign({}, blockList, { name: blockList.name || 'Unnamed', id: blockList.list_id })
          )
          : []
        this._queueNotify()
      },
      restError => {
        CommonViewActions.Notification.add({
          type: 'error',
          heading: 'Error',
          message: 'Could not get remediation block lists.',
          dismissTimer: 15000
        })
        _state.remediation.isLoading = false
        // ??? _state.remediation.errors = [{ error: 'Error', details: restError.body || 'An error occurred.' }]
        this.onLoadProxies()
        this.onLoadDevices()
      }
    )
  },

  onClearRemediationErrors() {
    // TODO this is sort of a hack to get around the fact that _state.remediation.errors holds
    //   the last failed remediation action as a persistent singleton value, which unless it's
    //   explicitly cleared would be incorrectly displayed in subsequent remediation dialogs.
    _state.remediation.errors = null
    return this._queueNotify()
  },

  onAddAwsTag(deviceId, applianceId) {
    _state.remediation.isSaving = true
    this._queueNotify()

    const data = {
      action: 'block',
      lists: [{ list_id: null, device_id: applianceId }],
      data: [
        {
          deviceId: deviceId,
          enabled: 'true' //no unban in aws
        }
      ],
      resource: 'aws/ec2Instances/favorites'
    }

    requestPatch('remediation-add-aws-tag', 'remediation/lists', data, { baseURL: '/api/alpha/' })
      .then((resultData) => {
        _state.remediation.isSaving = false
        if (resultData.errors.length) {
          _state.remediation.errors = resultData.errors
          return this._queueNotify()
        }

        // Update in-memory data with response
        _state.remediation.blocked = _state.remediation.blocked.concat(deviceId)
        this._queueNotify()

        AnalyticsActions.event({
          eventCategory: 'remediation-lists',
          eventAction: 'block',
          eventLabel: 'aws-tag-added'
        })
      })
      .catch(restError => {
        _state.remediation.errors = [{ error: 'Error', details: 'An error occurred.' }]
        _state.remediation.isSaving = false
        return this._queueNotify()
      })
  },

  onBlockIp(ip, lists) {
    _state.remediation.isSaving = true
    this._queueNotify()

    const data = {
      action: 'block',
      lists: lists.map(l => ({ list_id: l.list_id, device_id: l.device_id })),
      data: [
        {
          ip,
          enabled: 'true' //no unban in palo alto
        }
      ]
    }

    if (window._pw.isDemoCustomer) {
      _state.remediation.isSaving = false
      _state.remediation.errors = null
      _state.remediation.blocked = MOCK_PAN_LIST
      return this._queueNotify()
    }

    requestPatch('remediation-block-ip', 'remediation/lists', data, { baseURL: '/api/alpha/' })
      .then((resultData) => {
        _state.remediation.isSaving = false
        if (resultData.errors.length) {
          _state.remediation.errors = resultData.errors
          return this._queueNotify()
        }

        // Update in-memory data with response
        _state.remediation.blocked = _state.remediation.blocked.concat(resultData.results.map(d => d.data[0].ip))
        this._queueNotify()

        AnalyticsActions.event({
          eventCategory: 'remediation-lists',
          eventAction: 'block',
          eventLabel: 'ip-blocked'
        })
      })
      .catch(restError => {
        _state.remediation.errors = [{ error: 'Error', details: restError.body || 'An error occurred.' }]
        return this._queueNotify()
      })

  },

  onRemediateFile(fileHash, lists) {
    _state.remediation.isSaving = true
    this._queueNotify()

    const data = {
      action: 'block',
      lists: lists.map(l => ({ list_id: l.list_id, device_id: l.device_id })),
      data: [
        {
          fileHash,
          enabled: lists.enabled.toString()
        }
      ]
    }

    if (window._pw.isDemoCustomer) {
      _state.remediation.isSaving = false
      _state.devices = {
        data: [ { type: 'EndpointKnowledge', id: 'DEMO_ID' } ]
      }
      _state.remediation.lists = [ {
        device_id: 'DEMO_ID',
        list_id: '213kjvjf43534',
        data: { enabled: lists.enabled.toString(), fileHash }
      } ]
      return this._queueNotify()
    }

    requestPatch('remediation-block-file', 'remediation/lists', data, { baseURL: '/api/alpha/' })
      .then((resultData) => {
        _state.remediation.isSaving = false
        if (resultData.errors.length) {
          _state.remediation.errors = resultData.errors
          return this._queueNotify()
        }

        // Update in-memory data with response
        _state.remediation.lists = _state.remediation.lists.map(list => {
          let data = list.data.map(data => {
            if (data.fileHash === fileHash) {
              data.enabled = lists.enabled.toString()
            }
            return data
          })
          list.data = data
          return list
        })
        this._queueNotify()

        AnalyticsActions.event({
          eventCategory: 'remediation-lists',
          eventAction: 'block',
          eventLabel: 'file-blocked'
        })
      })
      .catch(restError => {
        _state.remediation.errors = [{ error: 'Error', details: restError.body || 'An error occurred.' }]
        return this._queueNotify()
      })
  },

  onSetAvailableBlockLists(newFavoritesById, deviceId, resource=null) {
    _state.remediation.isSaving = true
    this._queueNotify()

    const data = {
      block_lists: {
        [deviceId]: newFavoritesById.map(id => ({ id: id }))
      },
      resource
    }

    requestPost('remediation-save-ip-favs', 'remediation/lists/favorites', data, { baseURL: '/api/alpha/' })
      .then((resultData) => {
        _state.remediation.isSaving = false
        if (resultData.errors.length) {
          _state.remediation.errors = resultData.errors
          return this._queueNotify()
        }

        _state.remediation.available = _state.remediation.available
          .concat(resultData.results)
          .map(blockList => {
            return Object.assign({}, blockList, { name: blockList.name || 'Unnamed', id: blockList.list_id })
          })
        this._queueNotify()

        CommonViewActions.Notification.add({
          type: 'success',
          heading: 'Success',
          message: 'Block Lists Added.',
          dismissTimer: 15000
        })

        AnalyticsActions.event({
          eventCategory: 'remediation-lists-favs',
          eventAction: 'save',
          eventLabel: 'ip-favs-saved'
        })
      })
      .catch(this._onApiWriteError)
  },

  onRemoveBlockListFav(favListByIds, deviceId, resource=null) {
    _state.remediation.isSaving = true
    this._queueNotify()

    const data = {
      block_lists: {
        [deviceId]: favListByIds.map(id => ({ id: id }))
      },
      resource
    }

    requestPost(
      'remediation-delete-ip-favs',
      `remediation/lists/favorites/delete`,
      data,
      { baseURL: '/api/alpha/' }
    )
      .then((resultData) => {
        _state.remediation.isSaving = false
        if (resultData.errors.length) {
          _state.remediation.errors = resultData.errors
          return this._queueNotify()
        }

        // returns deleted favorites, filter out instead
        const deletedIds = resultData.results.map(list => list.list_id)
        _state.remediation.available = _state.remediation.available
          .filter(list => deletedIds.indexOf(list.list_id) === -1)

        this._queueNotify()

        CommonViewActions.Notification.add({
          type: 'success',
          heading: 'Success',
          message: 'Block Lists Removed.',
          dismissTimer: 15000
        })

        AnalyticsActions.event({
          eventCategory: 'remediation-lists-favs',
          eventAction: 'delete',
          eventLabel: 'ip-favs-deleted'
        })
      })
      .catch(this._onApiWriteError)
  }

})

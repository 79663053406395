import _ from 'lodash'
import Reflux from 'reflux'

import SensorCommandsActions from 'actions/SensorCommandsActions'
import SensorStore from 'stores/SensorStore'
import {requestGet, requestPost} from 'utils/restUtils'
import { listenToStore } from 'utils/storeUtils'

let _state = {
  commandResults: [],
  commands: [],
  count: 0,
  error: null,
  commandsError: null,
  isLoading: false,
  isLoadingCommands: false,
  paneOpen: false,
  sensors: [],
  selectedSensorIds: []
}

// Filter out sensors that cannot accept commands
const isCommandable = sensorId => {
  const sensor = SensorStore.getSensor(+sensorId)
  return sensor && sensor.downloaded && sensor.enabled && !sensor.isVersion2
}

export default Reflux.createStore({
  listenables: [SensorCommandsActions],

  init() {
    listenToStore(this, SensorStore, s => {
      _state.sensors = s.sensors.filter(sensor => isCommandable(sensor.id))
    })
  },

  getInitialState() {
    return _state
  },

  _notify() {
    this.trigger(_state)
  },

  _queueNotify() {
    clearTimeout(this._queueNotifyTimer)
    this._queueNotifyTimer = setTimeout(this._notify.bind(this), 100)
  },

  onOpenCommandConsole(sensorIds = []) {
    _state.selectedSensorIds = (_.isArray(sensorIds) ? sensorIds : []).filter(isCommandable)
    _state.paneOpen = true
    this._queueNotify()
  },

  onCloseCommandConsole() {
    _state.selectedSensorIds = []
    _state.commandResults = [] // Clear data on close?
    _state.paneOpen = false
    this._queueNotify()
  },

  onSetSelectedSensorIds(sensorIds) {
    _state.selectedSensorIds = (sensorIds || []).filter(isCommandable)
    this._queueNotify()
  },

  onLoadCommands() {
    _state.isLoadingCommands = true
    _state.commands = []
    _state.commandsError = null
    this._queueNotify()
    // TODO allow multiple commands?
    requestGet("load_sensor_commands", `sensor-commands`)
      .then(data => {
        _state.commands = _.map(data, d => {
          d.name = d.name.toLowerCase()
          return d
        })
        _state.isLoadingCommands = false
        this._queueNotify()
      })
      .catch(restError => {
        _state.isLoadingCommands = false
        _state.commandsError = restError
        this._queueNotify()
      })
  },

  onIssueCommand(commandId, sensorIds = []) {
    if (sensorIds.length === 0 || !commandId || _.findIndex(_state.commands, {name: commandId}) === -1) {
      return
    }
    _state.isLoading = true
    // _state.commandResults = []
    _state.error = null
    this._queueNotify()
    // TODO allow multiple commands?
    requestPost("issue_sensor_command", `sensor-commands/${ commandId }`, {
      ids: sensorIds
    },{
      retry429: true,
      useSocket: true
    })
      .then(data => {
        _state.isLoading = false
        this._addData(data, commandId, sensorIds)
        this._queueNotify()
      })
      .catch(restError => {
        _state.isLoading = false
        _state.error = restError
        this._queueNotify()
      })
  },

  onClearResult(commandTimestamp) {
    _state.commandResults = _.reject(_state.commandResults, {timestamp:commandTimestamp})
    this._queueNotify()
  },

  clearAllResults() {
    _state.commandResults = []
    this._queueNotify()
  },

  _addData(data, commandId, sensorIds) {
    _state.commandResults.push({
      timestamp: Date.now(),
      commandId: commandId,
      sensorIds: sensorIds,
      data: _.map(data, sensorResult => {
        sensorResult.sensorName = SensorStore.getSensorName(sensorResult.id)
        return sensorResult
      })
    })
  }
})

import T from 'prop-types'
import React from 'react'
import cx from 'classnames'
import ErroredValue from './ErroredValue'
import {
  TextOverflow,
  AnimateHeight,
  LoadingValue
} from 'react-base'

class DataCell extends React.Component {
  static displayName = 'DataCell';

  static propTypes = {
    label: T.oneOfType([T.string, T.node]),
    large: T.bool,
    className: T.string,
    value: T.object,
    'default': T.string,
    textOverflow: T.bool,
    animateHeight: T.bool,
    formatter: T.func
  };

  render() {
    let {props} = this
    let {value,
      formatter,
      label,
      boldLabel,
      textOverflow,
      animateHeight,
      large
    } = props

    let content = value ? (
      value.isLoading() ? (
        <LoadingValue />
      ) : value.isError() ? (
        <ErroredValue />
      ) : (
        value.valueOf() === null
        ? (props.default || null)
        : formatter ? formatter(value.valueOf()) : value.valueOf()
      )
    ) : props.children

    return (
      <div className='cell' key={ label }>
        <span className={ cx(boldLabel && 'bold')}>{ label }</span>
        <div className={ cx('cell_content multiline', large && 'lg', props.className) }>
          { textOverflow ? (
            <TextOverflow>{ content }</TextOverflow>
          ) : animateHeight ? (
            <AnimateHeight>{ content }</AnimateHeight>
          ) : (
            content
          ) }
        </div>
      </div>
    )
  }
}

export default DataCell

import T from 'prop-types'
import React from 'react'
import {
  TagInput
} from 'react-base'
import {
  // validateNoSpaces,
  getStringLengthValidator
} from 'pw-validators'

const tagSizeValidator = getStringLengthValidator(0, 100)

function validateIntelRuleTags (input) {
  // return input && input.length > 0 ? (validateNoSpaces(input) || tagSizeValidator(input)) : null
  return input && input.length > 0 ? tagSizeValidator(input) : null
}

function IntelRuleTagInput (props) {
  const {
    allTags = [],
    className,
    currentTags = [],
    handleTagCommit,
    readOnly
  } = props
  return (
    <TagInput
      allTags={ allTags }
      appliedTags={ currentTags }
      className={ className }
      commitTags={ handleTagCommit }
      loading={ false }
      readOnly={ readOnly }
      renderUnmatchedTags
      validateOnChange
      validator={ validateIntelRuleTags }
    />
  )
}

IntelRuleTagInput.displayName = "IntelRuleTagInput"
IntelRuleTagInput.propTypes = {
  allTags: T.array,
  className: T.string,
  currentTags: T.array,
  handleTagCommit: T.func.isRequired,
  readOnly: T.bool
}

export default IntelRuleTagInput

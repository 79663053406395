import {
  observable,
  autorun,
  computed,
  action
} from 'mobx'
// import {
//   requestGet
// } from 'stores/mobx/viewStores/node_modules/utils/restUtils'
import CommonViewActions from 'actions/CommonViewActions'
import { RouteStore } from 'stores/mobx/data/global/RouteStore'
import { Store } from 'stores/mobx/StoreManager'
import {
  DEVICES_ROOT_PATH,
  DEVICE_MANAGEMENT_VIEWS,
  DEFAULT_DEVICE_VIEW,
} from 'pwConstants'

export enum SortDir {
  Asc = 'asc',
  Desc = 'desc'
}

export enum DeviceViews {
  Inventory = "inventory"
}

export interface DeviceQueryParams {
  q?: string // Current Query
  sortBy?: string
  sortDir?: 'asc'|'desc'
  pageSize?: number
}

const DEFAULT_SORT = 'risk'
const DEFAULT_SORT_DIR = SortDir.Desc
const DEFAULT_PAGE_SIZE = 50


export class DeviceManagementStore extends Store {
  static dependencies = [RouteStore]

  routeStore: RouteStore
  @observable subViews = DEVICE_MANAGEMENT_VIEWS

  constructor(deps) {
    super(deps)
    const [routeStore] = deps

    this.routeStore = routeStore

    this.destroy = autorun(() => {
      if (DEVICE_MANAGEMENT_VIEWS.hasOwnProperty(this.activeView)) {
        const viewConfig = DEVICE_MANAGEMENT_VIEWS[this.activeView]
        CommonViewActions.General.setPageTitle(viewConfig.title)
      }
    })
  }

  @computed
  get activeView () {
    const {pathArr} = this.routeStore.currentRoute
    return !pathArr.length || pathArr[0] !== DEVICES_ROOT_PATH ? null : pathArr[1] || DEFAULT_DEVICE_VIEW
  }

  @computed
  get listParams () : DeviceQueryParams|null {
    const urlParams = this.routeStore.currentRoute.queryParams || {}
    return this.activeView === DeviceViews.Inventory ? {
      q: urlParams.q,
      sortBy: urlParams.sortBy || DEFAULT_SORT,
      sortDir: SortDir[urlParams.sortDir] || DEFAULT_SORT_DIR,
      pageSize: +urlParams.pageSize || DEFAULT_PAGE_SIZE
    } : null
  }

  @action.bound
  commitQuery (uiQuery) {
    // Switch back to inventory tab if not already there
    const newQueryParams = {q: uiQuery}
    if (this.routeStore.currentRoute.pathArr[1] !== 'inventory') {
      this.routeStore.updatePath('inventory', 1, newQueryParams)
    }
    else {
      this.routeStore.mergeParams(newQueryParams)
    }
  }

  @action.bound
  changeSort (newSortField, newSortDir) {
    this.routeStore.mergeParams({
      sortBy: newSortField,
      sortDir: newSortDir
    })
  }
}

// Observation utilities
import _ from 'lodash'
import React from 'react'
import { lowerCase, pluralize } from 'pw-formatters'
import constants, { ANOMALOUS_OBSERVATION_RULES, ANOMALY_RULES, ANOMALOUS_FLOW_RULES, OBSERVATION_SOURCES_ENUM } from 'pwConstants'
import netflowUtils from './netflowUtils'
import SensorStore from 'stores/SensorStore'
import Protocols from 'data/Protocols'
import { formatDate } from 'utils/timeUtils'
import IpValue from 'components/values/IpValue'
import DeviceValue from 'components/values/DeviceValue'
import UrlValue from 'components/values/UrlValue'
import DomainValue from 'components/values/DomainValue'
import FileHashValue from 'components/values/FileHashValue'
import ValueActionsStore from 'stores/ValueActionsStore'
import { getGlobalUniqueRuleKey } from 'utils/intelManagementUtils'
import { VALUE_TYPE_OPTIONS } from 'constants/searchableValueConstants'
import { INTEL_TYPE_OPTIONS, INTEL_MODES } from 'constants/intelManagementConstants'

const FILE_DIRECTIONS = {
  int_int: 'Transferred Internally',
  int_ext: 'Outbound',
  ext_int: 'Downloaded',
  ext_ext: 'Observed'
}
const FILE_EMAIL_PROTOCOLS = { smtp: 1, pop3: 1, pop: 1, imap: 1 }

const PCAP_BACK_WINDOW = 5 * 60000
const PCAP_FORWARD_WINDOW = 10 * 60000

const defaultIsInternalIp = SensorStore.isInternalIp.bind(SensorStore)

const _trimString = (str, trimLength) =>
  str.length > trimLength ? str.substring(0, trimLength) + '\u2026' : str

function getProtocolsFromObsInfo(obs) {
  let protos =
    obs.info.protocols && _.pluck(obs.info.protocols, 'knownProtocol')
  if (protos && protos.length) {
    protos = protos
      .map(protoId => {
        const proto = Protocols.getProtocolById(protoId)
        if (proto && proto.name === 'FTP_CONTROL') { proto.name = 'FTP' } // used in abbreviated instances
        return proto ? proto.name : 'Unknown Protocol'
      })
      .join(', ')
  }
  return protos || ''
}

const getCertName = (obs, obsData) => {
  const intelName = _.get(obs, 'info.properties.intelName.0.str', "")
  return _trimString(
    (intelName && intelName.length > 0 ? intelName : obsData.certificateRepresentation), // Attempt to use Intel-stored cert name, falling back to extracted certRepresentation
    40
  )
}

const getFileName = (obs, obsData) => {
  return _.get(obs, 'info.properties.intelName.0.str', (obsData.extractedName || 'Unknown File Name'))
}

// Infer a presentable type/description for the observation
const getObservationData = (obs, isInternalIp) => {
  // Generate title and type for a passed observation
  // Accepts a Backbone Model observation instance or a plain JSON observation
  const data = obs.data
  const source = obs.source
  let obsData = null
  let obsType = obs.type // V2 observations contain an explicit type field
  let subType = null
  let title = ''
  let titlePrefix = ''
  let titleParts = null
  let searchableValueData = null
  let fileDirection = null
  let intelUIKey = null // Intel Management UI key

  // function stringifyDeviceIdByteArray(input) {
  //   return input && _.isArray(input)
  //     ? input.map(n => (n < 16 ? '0' : '') + n.toString(16)).join('')
  //     : input
  // }

  // _.set(obs, 'info.properties.intelName.0.str', "TEST INTEL NAME")

  if (obs && data) {
    // The old httpRequest struct is supplanted by httpTransaction; up-convert it for now so we only
    // have to deal with one type from here on
    if (data.httpRequest) {
      data.httpTransaction = { request: data.httpRequest }
      delete data.httpRequest
    }

    // Extract the observation type and type-specific data; should be the only key in the `data` object
    for (const prop in data) {
      if (data.hasOwnProperty(prop) && data[prop]) {
        if (!obsType) {
          // This was a V1 observation, we must fall back to inferring the obs type from available data properties
          obsType = constants.observationDataPropertiesToTypes[prop.toLowerCase()]
        }
        obsData = data[prop]
        break
      }
    }

    const subCat = obs.threatSubCategory

    let _catName = null
    let _srcIpIntExt = null
    let _dstIpIntExt = null
    let url = null
    let deviceId = null

    switch (obsType) {
      case 'IpReputation':
        titlePrefix =
          subCat === 'NewIpOnStaticNetwork'
            ? 'New IP Detected: '
            : 'Malicious IP: '
        searchableValueData = {
          type: VALUE_TYPE_OPTIONS.IP,
          value: obsData.ip
        }
        titleParts = [titlePrefix, searchableValueData]
        title = `${titlePrefix}${_trimString(obsData.ip, 40)}`
        if (obs.info.listId && obs.info.intelKey) {
          intelUIKey = getGlobalUniqueRuleKey({
            ruleType: INTEL_TYPE_OPTIONS.IP,
            listId: obs.info.listId,
            key: obs.info.intelKey,
            mode: INTEL_MODES.PUBLISHED
          })
        }
        break
      case 'UrlReputation':
        titlePrefix = 'Malicious URL: '
        searchableValueData = {
          type: VALUE_TYPE_OPTIONS.URL,
          value: obsData.url
        }
        titleParts = [titlePrefix, searchableValueData]
        title = `${titlePrefix}${_trimString(obsData.url, 40)}`
        if (obs.info.listId && obs.info.intelKey) {
          intelUIKey = getGlobalUniqueRuleKey({
            ruleType: INTEL_TYPE_OPTIONS.URI,
            listId: obs.info.listId,
            key: obs.info.intelKey,
            mode: INTEL_MODES.PUBLISHED
          })
        }
        break
      case 'DnsReputation':
        _catName = obsData.category.toLowerCase().replace(/\s+/g, '') // Try to normalize against changes
        if (_catName === 'unknown' || _catName === 'machinegenerated') {
          //FIXME 'unknown' is deprecated/fallback and should be removed when clear in production
          if (subCat === 'MachineGeneratedDomain' || subCat === 'General') {
            //FIXME 'General' is deprecated/fallback
            titlePrefix = 'Machine Generated Domain Name: ' // DGA Domain
          } else if (subCat === 'MachineGeneratedSSLCertificate') {
            titlePrefix = 'Machine Generated SSL Cert: ' // Malicious SSL Cert
          } else {
            titlePrefix = 'Malicious Domain: ' // Fallback for "unknown"
          }
        } else if (_catName === 'passivedns' || _catName === 'domainage') {
          //FIXME 'passivedns' is deprecated/fallback
          if (subCat === 'NewlyCreatedDomain' || subCat === 'Domain') {
            //FIXME 'Domain' is deprecated/fallback
            titlePrefix = 'Newly Created Domain: ' // Farsight DB
          } else {
            titlePrefix = 'Domain Age: ' // Fallback...
          }
        } else if (_catName === 'dnstunneling' || _catName === 'tunneling') {
          //FIXME 'dnstunneling' is deprecated/fallback
          titlePrefix = 'Potential DNS Tunneling: ' // Tunneling
        } else {
          titlePrefix = 'Suspicious Domain: ' // Overall fallback
        }
        searchableValueData = {
          type: VALUE_TYPE_OPTIONS.DOMAIN,
          value: obsData.dns
        }
        titleParts = [titlePrefix, searchableValueData]
        title = `${titlePrefix}${_trimString(obsData.dns, 40)}`
        if (obs.info.listId && obs.info.intelKey) {
          intelUIKey = getGlobalUniqueRuleKey({
            ruleType: INTEL_TYPE_OPTIONS.DOMAIN,
            listId: obs.info.listId,
            key: obs.info.intelKey,
            mode: INTEL_MODES.PUBLISHED
          })
        }
        break
      case 'CertReputation':
        if (subCat === 'MachineGeneratedSSLCertificate') {
          titlePrefix = 'Machine Generated Certificate: ' // Malicious SSL Cert
        } else {
          titlePrefix = 'Suspicious Certificate: ' //Fallback...
        }
        searchableValueData = {
          type: VALUE_TYPE_OPTIONS.CERTIFICATE,
          value: obsData.sha1
        }
        title = `${titlePrefix}${getCertName(obs, obsData)}`

        if (obs.info.listId && obs.info.intelKey) {
          intelUIKey = getGlobalUniqueRuleKey({
            ruleType: INTEL_TYPE_OPTIONS.CERTIFICATE,
            listId: obs.info.listId,
            key: obs.info.intelKey,
            mode: INTEL_MODES.PUBLISHED
          })
        }
        break
      case 'Ids':
        title = obsData.description
        searchableValueData = {
          type: VALUE_TYPE_OPTIONS.PAYLOAD,
          value: obs.info.intelKey
          // value: JSON.stringify({
          //   gid: obsData.generatorId,
          //   sid: obsData.signatureId,
          //   key: obs.info.intelKey
          // })
          // `${ obsData.generatorId }:${ obsData.signatureId }` // Deliberately using gid/sid instead of intelKey for Quick Add Whitelist rules, to ensure that new rules will properly override
        }
        if (obs.info.listId && obs.info.intelKey) {
          intelUIKey = getGlobalUniqueRuleKey({
            ruleType: INTEL_TYPE_OPTIONS.IDS,
            listId: obs.info.listId,
            key: obs.info.intelKey,
            mode: INTEL_MODES.PUBLISHED
          })
        }
        break
      case 'File': {
        /*
            (Malicious | Suspicious) ("type") File (Seen in Email Attachment | Downloaded | Uploaded | Transferred Internally | Observed)
            Option 1
              If threatSubCategory is MaliciousFile use 'Malicious' else if the threatSubCategory is SuspiciousFile use 'Suspicious'
            Option 2
              Use the value of "type" from the FileRep observation
            Option 3
              If  "transportProtocol" is 'Smtp' or 'Pop3' or 'Imap' use "Seen in Email Attachment"
              Else
                If the direction is external to internal use 'Downloaded'
                If the direction is internal to external  use 'Uploaded'
                If the direction is internal to internal use 'Transferred Internally'
                If the direction is external to external use 'Observed'
            */
        _srcIpIntExt = isInternalIp(obs.connectionInfo.srcIp, obs.sensorId)
          ? 'int'
          : 'ext'
        _dstIpIntExt = isInternalIp(obs.connectionInfo.dstIp, obs.sensorId)
          ? 'int'
          : 'ext'
        fileDirection =
          FILE_DIRECTIONS[
          obs.observationDirection === 'Dst_to_src'
            ? `${_dstIpIntExt}_${_srcIpIntExt}`
            : obs.observationDirection === 'Src_to_dst'
              ? `${_srcIpIntExt}_${_dstIpIntExt}`
              : 'ext_ext'
          ]
        const fileName = getFileName(obs, obsData)
        titleParts = [
          `File: `,
          fileName,
          FILE_EMAIL_PROTOCOLS.hasOwnProperty((obsData.transportProtocol || '').toLowerCase())
            ? ' Seen in Email Attachment'
            : ` ${fileDirection}`
        ]
        title = titleParts.join(' ')
        searchableValueData = {
          type: VALUE_TYPE_OPTIONS.FILEHASH,
          value: obsData.id,
          relatedData: {
            fileName: fileName
          }
        }

        titleParts[1] = searchableValueData // Replace middle element of titleParts array with structured searchableValue data
        break
      }
      case 'FileReputation': {
        /*
        (Malicious | Suspicious) ("type") File (Seen in Email Attachment | Downloaded | Uploaded | Transferred Internally | Observed)
        Option 1
          If threatSubCategory is MaliciousFile use 'Malicious' else if the threatSubCategory is SuspiciousFile use 'Suspicious'
        Option 2
          Use the value of "type" from the FileRep observation
        Option 3
          If  "transportProtocol" is 'Smtp' or 'Pop3' or 'Imap' use "Seen in Email Attachment"
          Else
            If the direction is external to internal use 'Downloaded'
            If the direction is internal to external  use 'Uploaded'
            If the direction is internal to internal use 'Transferred Internally'
            If the direction is external to external use 'Observed'
        */
        _srcIpIntExt = isInternalIp(obs.connectionInfo.srcIp, obs.sensorId)
          ? 'int'
          : 'ext'
        _dstIpIntExt = isInternalIp(obs.connectionInfo.dstIp, obs.sensorId)
          ? 'int'
          : 'ext'
        fileDirection =
          FILE_DIRECTIONS[
          obs.observationDirection === 'Dst_to_src'
            ? `${_dstIpIntExt}_${_srcIpIntExt}`
            : obs.observationDirection === 'Src_to_dst'
              ? `${_srcIpIntExt}_${_dstIpIntExt}`
              : 'ext_ext'
          ]
        const fileName = getFileName(obs, obsData)

        titleParts = [
          // `Blacklisted ${obsData.type} File: `, // Hide file type until it returns consistently https://protectwise.atlassian.net/browse/SUS-341
          source === 'Clam_av' ? `Malicious File: ` : `Blacklisted File: `,
          fileName,
          FILE_EMAIL_PROTOCOLS.hasOwnProperty((obsData.transportProtocol || '').toLowerCase())
            ? ' Seen in Email Attachment'
            : ` ${fileDirection}`
        ]
        title = titleParts.join(' ')

        searchableValueData = {
          type: VALUE_TYPE_OPTIONS.FILEHASH,
          value: obsData.id, // PW File ID
          relatedData: {
            fileName
          }
        }

        titleParts[1] = searchableValueData // Replace middle element of titleParts array with structured searchableValue data

        if (obs.info.listId && obs.info.intelKey) {
          const ruleType = obs.source === 'Yara' ? INTEL_TYPE_OPTIONS.YARA : INTEL_TYPE_OPTIONS.FILEHASH
          intelUIKey = getGlobalUniqueRuleKey({
            ruleType,
            listId: obs.info.listId,
            key: obs.info.intelKey,
            mode: INTEL_MODES.PUBLISHED
          })
        }
        break
      }
      case 'Http':
        url = `${_.get(obsData, 'request.url.hostname')}${_.get(
          obsData,
          'request.url.path'
        ) || ''}`
        titlePrefix = 'HTTP Request: '
        searchableValueData = {
          type: VALUE_TYPE_OPTIONS.URL,
          value: url
        }
        titleParts = [titlePrefix, searchableValueData]
        title = `${titlePrefix}${_trimString(url, 40)}`
        break
      case 'Protocol':
        title = 'Protocol'
        break
      case 'AnomalousFlow':
        switch (obsData.flowAnomalyRule) {
          case 'wrong-protocol-scanner':
          case 'protocol-on-wrong-port-scanner': {
            const protos = getProtocolsFromObsInfo(obs)
            const ports =
              obs.info.hostIds &&
              _(obs.info.hostIds)
                .pluck('dstPorts')
                .flatten()
                .compact()
                .uniq()
                .value()
            title = 'Protocol Mismatch'
            if (protos && protos.length) {
              title += `: ${protos}`
            }
            if (ports && ports.length) {
              title += ` on ${ports.length === 1
                ? `port ${ports[0]}`
                : ports.length < 4
                  ? `ports ${ports.join(', ')}`
                  : `${ports.length} unexpected ports`}`
            }
            break
          }
          case 'port-scan-scanner': {
            const srcIp = _.get(obs, 'associatedId.hostId.host.ip')
            titlePrefix = 'Possible Internal Port Scanning from: '
            searchableValueData = {
              type: VALUE_TYPE_OPTIONS.IP,
              value: srcIp
            }
            titleParts = [titlePrefix, searchableValueData]
            title = `${titlePrefix}${srcIp}`
            break
          }
          case 'outbound-smb-scanner': {
            const srcIp = _.get(obs, 'associatedId.hostId.host.ip')
            titlePrefix = 'Outbound SMB Scanner: '
            searchableValueData = {
              type: VALUE_TYPE_OPTIONS.IP,
              value: srcIp
            }
            titleParts = [titlePrefix, searchableValueData]
            title = `${titlePrefix}${srcIp}`
            break
          }
          case 'brute-force-ssh-scanner': {
            const srcIp = _.get(obs, 'associatedId.conversationId.src.host.ip')
            titlePrefix = 'Brute Force SSH: '
            searchableValueData = {
              type: VALUE_TYPE_OPTIONS.IP,
              value: srcIp
            }
            titleParts = [titlePrefix, searchableValueData]
            title = `${titlePrefix}${srcIp}`
            break
          }
          case 'outbound-rdp-smb-tunneling-scanner': {
            title = `Possible ${getProtocolsFromObsInfo(obs) ||
              'Unknown Protocol'} Tunneling`
            break
          }
          case 'unexpected-protocol-scanner': {
            //ICS-specific
            title = `Unexpected Protocol - ${getProtocolsFromObsInfo(obs) ||
              'Unknown'} on Level 2 Device`
            break
          }
          case 'suspicious-outbound-rdp-scanner': {
            //ICS-specific
            title = 'Suspicious Outbound RDP'
            break
          }
          case 'level2-connecting-to-port80-scanner': {
            //ICS-specific
            title = 'Level 2 Device Connecting to Port 80'
            break
          }
          case 'modbus-port-scanner': {
            //ICS-specific
            title =
              obs.confidence < 50
                ? 'Possible Modbus Port Scanning'
                : 'Modbus Port Scanning'
            break
          }
          case 'enip-port-scanner': {
            //ICS-specific
            title =
              obs.confidence < 50
                ? 'Possible EthernetIP Port Scanning'
                : 'EthernetIP Port Scanning'
            break
          }
          case 'failed-modbus-connection-attempts-scanner': {
            title = 'Possible Failed Modbus Connection Attempts'
            break
          }
          case 'iodine-dns-tunneling-scanner': {
            title = 'DNS Tunneling via Iodine'
            break
          }
          case 'Not-ICS-Traffic-On-ICS-Port-scanner': {
            //ICS-specific
            const ports =
              obs.info.hostIds &&
              _(obs.info.hostIds)
                .pluck('dstPorts')
                .flatten()
                .compact()
                .uniq()
                .value()
            title = `ICS Protocol Mismatch: ${getProtocolsFromObsInfo(
              obs
            )} on Port ${ports.join(', ')}`
            break
          }
          case 'incongruent-protocol-scanner': {
            title = 'Suspicious Protocol Pair Identified'
            break
          }
          default:
            // console.debug(obsData)
            title = `Heuristic: ${ANOMALOUS_FLOW_RULES[obsData.flowAnomalyRule] || obsData.flowAnomalyRule}`
        }
        break
      case 'AnomalousObservation': {
        if (obsData.ruleId === 'divergent-anomaly') {
          title = 'Divergent Anomaly' + (obsData.description && obsData.description.length ? `: ${obsData.description}` : '')
          //??? subType = 'Divergent' //default subtype, may be overwritten by specific types below
        }
        else {
          title = ANOMALOUS_OBSERVATION_RULES[obsData.ruleId]
        }
        if (!title) {
          console.warn(`Unknown anomalousObservation ruleId: ${obsData.ruleId}`)
          title = `Anomaly: ${obsData.description}`
        }

        // Determine a subtype if it exists
        const anomData = obsData && obsData.anomalousData
        if (anomData) {
          const anomaly = anomData.anomalyType || Object.keys(anomData).find(k => anomData[k] && Object.keys(anomData[k]).length) || ''
          switch (anomaly.toLowerCase()) {
            case 'file': {
              subType = 'File'
              searchableValueData = {
                type: VALUE_TYPE_OPTIONS.FILEHASH,
                value: anomData.file.id, // PW File ID
                relatedData: {
                  fileName: anomData.file.extractedName || 'Unknown File Name'
                }
              }
              break
            }
            case 'ics': {
              // ics is actually a wrapper around yet another level
              const ics = anomData.ics
              const icsType = ics.icsType || Object.keys(ics).find(k => ics[k]) || ''
              switch (icsType.toLowerCase()) {
                case 'modbustransaction':
                case 'modbus':
                  subType = 'Modbus'
                  break
                default:
                  break
              }
              break
            }
            case 'dcerpc':
              subType = 'DceRpc'
              break
          }
        }
        break
      }
      case 'Ics':
        switch (obsData.icsType) {
          case 'Modbus':
            title = 'Modbus TCP Transaction'
            break
          default:
            title = 'ICS Observation'
            break
        }
        break
      case 'Anomaly':
        title = obsData && obsData.anomalyRule && ANOMALY_RULES[obsData.anomalyRule] ? ANOMALY_RULES[obsData.anomalyRule].title : 'Anomalous Behavior'
        deviceId = _.get(obs, 'associatedId.hostId.deviceId') // Convicted device ID
        if (deviceId) {
          titlePrefix = 'Device '
          searchableValueData = {
            type: VALUE_TYPE_OPTIONS.DEVICE,
            value: deviceId
          }
        }
        else {
          // Fallback to IP Address
          deviceId = _.get(obs, 'associatedId.hostId.host.ip')
          titlePrefix = 'Host '
          searchableValueData = {
            type: VALUE_TYPE_OPTIONS.IP,
            value: deviceId
          }
        }

        titleParts = [
          titlePrefix,
          searchableValueData,
          ': Anomalous Behavior' // Generic fallback
        ]
        if (!title) {
          console.warn(`Unknown Anomaly anomalyRule: ${obsData.anomalyRule}`)
        } else {
          titleParts[2] = `: ${title}`
        }
        title = `${titleParts[0]}${deviceId}${titleParts[2]}`
        break
      case 'Email': {
        const recipientCount = _.size(obsData.receivers)
        title = `Email from ${_.get(obsData, 'senders.0.address') || 'unknown sender'} to ${recipientCount} ${pluralize(recipientCount, 'recipient')}`
        break
      }
      case 'Smb': {
        title = `SMB Transaction: ${obsData.commandName ? obsData.commandName + ', ' : ''}Type: ${obsData.transactionType}`
        break
      }
      case 'Krb5': {
        console.warn(`TODO finish Krb5 info`)
        title = `Kerberos: Message Type ${obsData.messageType}`
        break
      }
      default:
        title = `${constants.observationTypesToNames[obsType]} Observation`
    }
  }

  return {
    formattedTitle: title,
    formattedTitleParts: titleParts || [title],
    observationType: obsType,
    observationSubType: subType,
    searchableValueData,
    fileDirection,
    intelUIKey
  }
}

const _mapRichTitlePart = (obs, part, i) => {
  return _.isObject(part) ? _renderSearchableValueData(obs, part, i) : part
}

function _renderSearchableValueData(obs, svData, key) {
  const buffer = 60 * 60 * 1000 //1 hour around occurred time
  const timeRange = { start: obs.occurredAt - buffer, end: obs.occurredAt + buffer }
  switch (svData.type) {
    case VALUE_TYPE_OPTIONS.IP:
      return <IpValue ip={svData.value} key={key} {...timeRange} />
    case VALUE_TYPE_OPTIONS.DEVICE:
      return <DeviceValue deviceId={svData.value} sensorId={obs.sensorId} size={18} key={key} {...timeRange} />
    case VALUE_TYPE_OPTIONS.URL:
      return <UrlValue url={svData.value} key={key} {...timeRange} />
    case VALUE_TYPE_OPTIONS.DOMAIN:
      return <DomainValue domain={svData.value} key={key} {...timeRange} />
    case VALUE_TYPE_OPTIONS.FILEHASH:
      return <FileHashValue fileName={svData.relatedData.fileName} fileId={svData.value} key={key} {...timeRange} />
    default:
      return `${svData.value}`
  }
  // return svData.type === VALUE_TYPE_OPTIONS.IP
  //   ? <IpValue ip={svData.value} key={key} />
  //   : svData.type === VALUE_TYPE_OPTIONS.URL
  //     ? <UrlValue url={svData.value} key={key} />
  //     : svData.type === VALUE_TYPE_OPTIONS.DOMAIN
  //       ? <DomainValue domain={svData.value} key={key} />
  //       : svData.type === VALUE_TYPE_OPTIONS.FILEHASH
  //         ? <FileHashValue fileName={svData.relatedData.fileName} fileId={svData.value} key={key} />
  //         : `${svData.value}`
}

export function renderObservationSearchableValue(obs) {
  const svData = obs.searchableValueData
  return svData ? _renderSearchableValueData(obs, svData, null) : null
}

const _formatObservation = (obs, isInternalIp = defaultIsInternalIp) => {
  //Test null flowId fallback (occurrs when platform is lagging)
  // if (obs.occurredAt % 2) {
  //   obs.flowId = obs.netflow = null //Test fallback
  // }

  const {
    formattedTitle,
    formattedTitleParts,
    observationType,
    observationSubType,
    fileDirection,
    intelUIKey,
    searchableValueData
  } = getObservationData(obs, isInternalIp)
  const killchainStageFormatted = lowerCase(obs.killChainStage)
  const categoryFormatted = lowerCase(obs.category)

  // TODO REMOVE ME - temp until node thrift transforms are complete
  if (obs.data && obs.data.email) {
    const recTypes = { 1: 'To', 2: 'Cc', 3: 'Bcc' }
      ; (obs.data.email.receivers || []).forEach(d => {
        if (typeof d.recipientType === 'number') d.recipientType = recTypes[d.recipientType]
      })
      ; (obs.data.email.receivedFrom || []).forEach(d => {
        if (_.isArray(d.fromIp)) d.fromIp = d.fromIp.map(n => n & 0xff).join('.')
        if (_.isArray(d.byIp)) d.byIp = d.byIp.map(n => n & 0xff).join('.')
      })
  }

  return _.assign(obs, {
    formattedTitle: formattedTitle,
    formattedTitleRich: formattedTitleParts.map(_.partial(_mapRichTitlePart, obs)),
    observationType: observationType,
    observationSubType: observationSubType,
    observationTypeFormatted:
      constants.observationTypesToNames[observationType],
    killchainStageFormatted: killchainStageFormatted,
    killchainStageFormattedHuman:
      constants.killchainStagesToNames[killchainStageFormatted],
    threatCategoryFormatted: categoryFormatted,
    threatCategoryFormattedHuman:
      constants.threatCategoriesToNames[categoryFormatted],
    occurredAtFormatted: formatDate(obs.occurredAt),
    observedAtFormatted: formatDate(obs.observedAt),
    threatLevelFormatted: (obs.threatLevel || '').toLowerCase(),
    sensorName: SensorStore.getSensorName(obs.sensorId),
    fileDirection: fileDirection,
    intelUIKey: intelUIKey,
    searchableValueData,
    searchableValueActions: searchableValueData
      ? ValueActionsStore.getValueActionsByValueType(searchableValueData.type)
      : []
  })
}

const createMissingNetflow = (observation) => {
  const key = _.get(observation, 'associatedId.flowId.key')
  if (!key) { return null }
  const { agentId, occurredAt, associatedId } = observation
  const startTime = occurredAt - PCAP_BACK_WINDOW
  const endTime = occurredAt + PCAP_FORWARD_WINDOW
  return {
    key,
    sensorId: agentId,
    agentId,
    details: {
      startTime,
      endTime
    },
    id: associatedId.flowId.ip,
    stats: {
      bytesSrcIncluded: 0,
      bytesDstIncluded: 0,
      packetsSrcIncluded: 0,
      packetsDstIncluded: 0
    },
    missingRecord: true,
    hazPcapDownload: true
  }
}

const updateMissingNetflow = (flow, { occurredAt }) => {
  const startTime = occurredAt - PCAP_BACK_WINDOW
  const endTime = occurredAt + PCAP_FORWARD_WINDOW
  flow.details.startTime = Math.min(startTime, flow.details.startTime)
  flow.details.endTime = Math.max(endTime, flow.details.endTime)
}

export default {
  formatList(rawObservations) {
    const isInternalIp = SensorStore.isInternalIpBatch()
    return _.each(rawObservations, obs => _formatObservation(obs, isInternalIp))
  },

  formatSingle(rawObservation) {
    if (!rawObservation.netflow) {
      rawObservation.netflow = createMissingNetflow(rawObservation)
    }
    const obs = _formatObservation(rawObservation)
    obs.formattedNetflow = netflowUtils.formatSingle(rawObservation.netflow) //add netflow details
    return obs
  },

  createMissingNetflow,
  updateMissingNetflow
}

const _generateFilledGeoObj = (latLonString) => {
  const spl = (latLonString || '').split(',')
  if (!spl[0] || !spl[1]) {
    return null
  }
  return {
    "lat": spl[0],
    "lon": spl[1],
    "countryIsoCode": null,
    "cityId": null
  }
}

export const convertObservationsV2toV1 = (observations = []) => {
  return _.map(observations, obs => {
    let _data = {}

    let _associatedId = {}
    let _connectionInfo = null
    let _srcGeo = null
    let _dstGeo = null

    switch (obs.type) {
      case "Ids":
        _data.idsEvent = obs.idsEvent
        break
      case "Protocol":
        _data = obs.protocol
        break
      case "File":
        _data.file = _.assign(obs.file, {
          "transportProtocol": `${obs.file.transportProtocol}`,
          "isTruncated": obs.file.isTruncated,
          "isTypeMismatched": obs.file.isTypeMismatched,
          "extractedName": obs.file.extractedName,
          "extractedPath": obs.file.extractedPath,
          "advertisedSize": null,
          "advertisedFileType": obs.file.advertisedFileType, // NOTE new addition (not present on v1 observations)
          "advertisedOtherMimeType": obs.file.advertisedOtherMimeType, // NOTE new addition (not present on v1 observations)
          "id": obs.file.fileId,
          "detectedType": obs.file.detectedFileType,
          "detectedFileSize": obs.file.detectedFileSize,
          "flags": obs.file.flags, // NOTE new addition (not present on v1 observations)
          "hashes": {
            "md5": "",
            "sha1": "",
            "sha256": "",
            "sha512": "",
            "additionalHashes": {}
          },
          "type": `${obs.file.fileType}`,
          "isArchive": obs.file.isArchive,
          "isEncrypted": obs.file.isEncrypted,
          "detectedDescription": null,
          "recapInfo": null,
          "start": 0,
          "end": 0,
        })
        break
      case "Dns":
        _data.dns = obs.dns
        break
      case "Http":
        _data.httpTransaction = _.assign(obs.http, {
          request: {
            header: _.map(obs.http.headers, (val, key) => ({
              name: key,
              value: val
            })),
            method: obs.http.method,
            url: {
              hostname: obs.http.host,
              path: obs.http.path,
              port: obs.http.port || -1,
              queryString: obs.http.queryString
            }
          },
          index: -1,
          response: {
            code: obs.http.responseCode,
            header: []
          }
        })
        break
      case "IpReputation":
        _data.ipReputation = obs.ipRep
        break
      case "UrlReputation":
        _data.urlReputation = obs.urlRep
        break
      case "FileReputation":
        _data.fileReputation = _.assign(obs.fileRep, {
          "transportProtocol": `${obs.fileRep.file.transportProtocol}`,
          "isTruncated": obs.fileRep.file.isTruncated,
          "isTypeMismatched": obs.fileRep.file.isTypeMismatched,
          "extractedName": obs.fileRep.file.extractedName,
          "extractedPath": obs.fileRep.file.extractedPath,
          "advertisedSize": null,
          "advertisedFileType": obs.fileRep.file.advertisedFileType, // NOTE new addition (not present on v1 observations)
          "advertisedOtherMimeType": obs.fileRep.file.advertisedOtherMimeType, // NOTE new addition (not present on v1 observations)
          "id": obs.fileRep.file.fileId,
          "detectedType": obs.fileRep.file.detectedFileType,
          "detectedFileSize": obs.fileRep.file.detectedFileSize,
          "flags": obs.fileRep.file.flags, // NOTE new addition (not present on v1 observations)
          "hashes": {
            "md5": "",
            "sha1": "",
            "sha256": "",
            "sha512": "",
            "additionalHashes": {}
          },
          "type": `${obs.fileRep.file.fileType}`,
          "isArchive": obs.fileRep.file.isArchive,
          "isEncrypted": obs.fileRep.file.isEncrypted,
          "detectedDescription": null,
          "recapInfo": null,
          "start": 0,
          "end": 0,
          "serviceType": obs.fileRep.fileRepType,
          "category": obs.fileRep.category,
          "finding": obs.fileRep.finding || {},
          "findings": obs.fileRep.findings || []
        })
        break
      case "DnsReputation":
        _data.dnsReputation = _.assign(obs.dnsRep, {
          category: obs.dnsRep.reputation,
          partnerCategory: "",
          dnsObservationData: null
        })
        break
      case "CertReputation":
        _data.certificateReputation = _.assign(obs.certificateRep, {
          certificateRepresentation: obs.certificateRep.rep,
          sha1: obs.certificateRep.cert,
          category: obs.certificateRep.reputation
        })
        break
      case "Certificate":
        _data.certificate = obs.certificate
        break
      case "AnomalousFlow":
        _data.anomalousFlow = _.assign(obs.anomalousFlow, {
          flowAnomalyRule: obs.anomalousFlow.rule
        })
        break
      case "Dhcp":
        _data.dhcp = obs.dhcp
        break
      case "Ics":
        _data.ics = obs.ics
        break
      case "AnomalousObservation":
        _data.anomalousObservation = _.assign(obs.anomalousObservation, {
          anomalousData: _.assign(_.get(obs.anomalousObservation, 'anomalousData', {}), {
            ics: _.assign(_.get(obs.anomalousObservation, 'anomalousData.ics', {
              modbusTransaction: _.get(obs.anomalousObservation, 'anomalousData.ics.modbus', {}),
              cipTransaction: _.get(obs.anomalousObservation, 'anomalousData.ics.cip', {}),
              enipTransaction: _.get(obs.anomalousObservation, 'anomalousData.ics.enip', {})
            }))
          })
        })
        break
      case "Anomaly":
        _data.anomaly = obs.anomaly
        break
      case "Email":
        _data.email = _.assign({}, obs.email, {
          transportProtocol: Protocols.getProtocolIdFromName(_.get(obs, 'email.transportProtocol')) || null
        })
        break
      case "Smb":
        _data.smbTransaction = obs.smbTransaction
        break
      case "Krb5":
        // TODO Verify that these come back flattened with the same key
        console.warn("TODO verify structure for Kerberos/Krb5 observations")
        _data.krb5Pdu = obs.krb5Pdu
        break
      default:
        console.error(`Unknown Observation Type Passed to "convertObservationsV2toV1": "${obs.type}"`)
        break
    }


    // TODO there's a potential issue here where the v2 API sometimes returns non-flowId associatedIds
    // for non-anomalousFlow observations. I've added a filter here to ignore those for non-anomalousFlow
    // observations but I'm not 100% sure this is a correct workaround. This may be something that needs
    // to be addressed at the Retrospect query level.
    if (obs.anomalousFlow && obs.associatedId.conversationId) {
      _srcGeo = _generateFilledGeoObj(_.get(obs, 'associatedId.conversationId.src.geo', ''))
      _dstGeo = _generateFilledGeoObj(_.get(obs, 'associatedId.conversationId.dst.geo', ''))

      _associatedId = {
        conversationId: _.assign(obs.associatedId.conversationId, {
          src: _.assign(obs.associatedId.conversationId.src, {
            geo: _srcGeo
          }),
          dst: _.assign(obs.associatedId.conversationId.dst, {
            geo: _dstGeo
          })
        })
      }
    }
    else if ((obs.anomaly || obs.anomalousFlow) && obs.associatedId.hostId) {
      // Anomaly observations sometimes come back with a mostly-empty flowId that we want to ignore
      _associatedId = {
        hostId: _.assign(obs.associatedId.hostId, {
          // FIXME may need to modify mappings here once an example is available
        })
      }
    }
    // Check flowId last because AnomalousFlow observations may include an empty one
    // even if they have one of the other types populated
    else if (obs.associatedId.flowId) {
      _srcGeo = _generateFilledGeoObj(obs.associatedId.flowId.srcGeo)
      _dstGeo = _generateFilledGeoObj(obs.associatedId.flowId.dstGeo)

      _associatedId = {
        flowId: _.assign(obs.associatedId.flowId, {
          startTime: +new Date(obs.associatedId.flowId.startTime),
          ip: obs.associatedId.flowId.ntuple,
          srcGeo: _srcGeo,
          dstGeo: _dstGeo,
        })
      }
      _connectionInfo = obs.associatedId.flowId.ntuple
    }

    return _.assign(
      obs,
      {
        sensorId: obs.agentId || obs.sensorId,
        flowId: null,
        tags: Array.isArray(obs.tags) ? obs.tags.map(tag => tag.replace(/u:/g, '')) : obs.tags, // Query response does not strip `u:` tag namespaces.
        associatedId: _associatedId,
        data: _data,
        occurredAt: +new Date(obs.occurredAt),
        observedAt: +new Date(obs.observedAt),
        category: obs.threatCategory || "None",
        netflow: null,
        source: OBSERVATION_SOURCES_ENUM[obs.source],
        srcGeo: _srcGeo,
        dstGeo: _dstGeo,
        endedAt: +new Date(obs.endedAt),
        threatLevel: obs.threatLevel || 'none',
        confidence: obs.confidence || 0,
        killChainStage: (obs.killChainStage === 'DataTheft' ? 'Data_theft' : obs.killChainStage) || "None",
        severity: obs.severity || 0,
        threatCategory: obs.threatCategory || "None",
        threatScore: obs.threatScore || 0,
        info: _.assign(obs.observationInfo, {
          coordinates: [
            _.compact([
              _srcGeo,
              _dstGeo
            ])
          ],
          protocols: (obs.observationInfo.protocols || []).map(p => ({
            "knownProtocol": typeof p === "string" ? Protocols.getProtocolIdFromName(p) : p,
            "unknownProtocol": null
          })),
          properties: _.reduce(obs.observationInfo.properties, (out1, prop) => {
            out1[prop._name] = prop
            return out1
          }, {})
        }),
        connectionInfo: _connectionInfo
      }
    )
  })
}

import {
  observable,
  action,
  computed,
} from 'mobx'
import _ from 'lodash'
import { Store } from 'stores/mobx/StoreManager'
import CustomerStore from 'stores/mobx/data/customers/CustomerStore'
import CustomerUsageStore from 'stores/mobx/data/customers/CustomerUsageStore'

export const COLUMNS = {
  id: {
    display: 'ID',
    width: 100,
    sortFn: c => parseInt(c.id),
    filterable: true
  },
  name: {
    display: 'Name',
    sortFn: c => c.name,
    width: 300,
    filterable: true
  },
  sso: {
    display: 'SSO',
    tooltip: 'Single Sign-On',
    accessor: 'customer_settings.saml_id',
    width: 130,
    sortFn: c => !!_.get(c, 'customer_settings.saml_id'),
  },
  customerAgreement: {
    display: 'C.A.',
    tooltip: 'Customer Agreement',
    accessor: 'agreement_requested',
    width: 80,
    sortFn: c => (c.agreement_requested ? 0 : 1),
  },
  createdAt: {
    display: 'Created',
    accessor: 'created_at',
    width: 220,
    sortFn: c => c.created_at
  },
  status: {
    display: 'Status',
    width: 170,
    filterable: true,
    sortFn: c => c.status
  },
  retentionDays: {
    display: 'Retention',
    accessor: 'policies.customerPolicy.retentionDays',
    width: 170,
    sortFn: c => _.get(c, 'policies.customerPolicy.retentionDays'),
  },
  bandwidthLimit: {
    display: 'Contractual Limit',
    accessor: 'policies.customerPolicy.allocatedAggregateBandwidth',
    width: 170,
    sortFn: c => _.get(c, 'policies.customerPolicy.allocatedAggregateBandwidth'),
  },
  bandwidthUsage: {
    display: 'Usage P95%',
    accessor: 'usage.bandwidthUsage',
    tooltip: 'Bandwidth usage for the previous whole month - 95th percentile in Mb/s',
    width: 170,
    sortFn: c => _.get(c, 'usage.bandwidthUsage', 0)
  },
  bandwidthUsagePercent: {
    display: 'Usage %',
    accessor: 'usage.bandwidthUsagePercentage',
    tooltip: 'Ratio of bandwidth usage versus contractual limit for the previous whole month',
    width: 170,
    sortFn: c => _.get(c, 'usage.bandwidthUsagePercentage', 0)
  },
  // badwidthLimitContractCalculated is not in the table by default
  // it is accessed to show differences in the visible bandwidthLimit and what
  // was calculated for the previous month if they are different.
  bandwidthContract: {
    display: 'Contractual Limit for Usage',
    accessor: 'usage.bandwidthContract',
    tooltip: "* Percentage calculated using the previous whole month's contractual limit value"
  },
  parentId: {
    display: 'Child Of',
    accessor: 'parent_id',
    width: 200,
    sortFn: c => c.parent_id,
  },
  enabled: {
    display: 'Enabled',
    width: 200
  },
  _rowControls: {
    display: 'Actions',
    width: 100
  }
}

const DEFAULT_COLUMNS = [
  '_rowControls',
  'id',
  'name',
  'parentId',
  'status',
  'customerAgreement',
  'sso',
  'retentionDays',
  'bandwidthLimit',
  'enabled',
  'createdAt'
]

const PRIVILEGED_COLUMNS = [
  'bandwidthUsage',
  'bandwidthUsagePercent'
]

export const DIALOG_KEYS = {
  NONE: 'none',
  NAME: 'customer-name',
  CREATE: 'customer-create',
  RETENTION: 'customer-retention',
  STATUS: 'customer-status',
  BANDWIDTH_LIMIT: 'customer-contractual-rate',
  CUSTOMER_NAME: 'customer-name'
}

export default class CustomerListViewStore extends Store {
  static dependencies = [CustomerStore, CustomerUsageStore]
  @observable visibleOrderedColumns = DEFAULT_COLUMNS
  @observable activeDialogKey = DIALOG_KEYS.NONE
  @observable activeDialogArgs = []

  constructor(deps) {
    super(deps)
    const [ customerStore, usageStore ] = deps
    this.customerStore = customerStore
    this.usageStore = usageStore
    if (usageStore.canSeeReport) {
      const start = DEFAULT_COLUMNS.indexOf('bandwidthLimit') + 1
      const newColumns = DEFAULT_COLUMNS.slice()
      newColumns.splice(start, 0, ...PRIVILEGED_COLUMNS)
      this.visibleOrderedColumns = newColumns
    }
  }

  @computed
  get customers () {
    return this.customerStore.customers.map(c => {
      let report = this.usageStore.customerUsageById.get(c.id) || {}
      let contract = report['upload_pcap_allocation_megabits_per_second_p95']
      let bandwidth = report['upload_pcap_megabits_per_second_p95']
      let percentage = report['upload_pcap_allocation_usage_percentage']
      if (bandwidth) {
        bandwidth = parseFloat(bandwidth)
      }
      if (percentage) {
        percentage = parseFloat(percentage)
      }
      if (contract) {
        contract = parseInt(contract)
      }
      const usage = {
        bandwidthContract: contract,
        bandwidthUsage: bandwidth,
        bandwidthUsagePercentage: percentage
      }
      return { ...c, usage }
    })
  }

  @computed
  get error () {
    // Do not let usage report error prevent loading of customers
    return this.customerStore.error
  }

  @computed
  get isLoading () {
    return this.customerStore.isLoading || this.usageStore.isLoading
  }

  @action.bound
  openDialog (dialogKey, ...args) {
    if (Object.values(DIALOG_KEYS).includes(dialogKey)) {
      this.activeDialogKey = dialogKey
      this.activeDialogArgs = args
    }
  }

  @action.bound
  closeDialog () {
    this.activeDialogKey = DIALOG_KEYS.NONE
    this.activeDialogArgs = []
  }
}

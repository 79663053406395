import React from 'react'
import T from 'prop-types'
import _ from 'lodash'

const ExternalLink = props => {
  return <a
    target="_blank"
    rel="noopener noreferrer" // See https://medium.com/@jitbit/target-blank-the-most-underestimated-vulnerability-ever-96e328301f4c#.x7e0gici1
    data-tooltip="Opens in a new browser tab"
    {..._.omit(props, 'withIcon')}
  >
    {props.children} {props.withIcon && <span className="icon icon-arrow-up-right" />}
  </a>
}

ExternalLink.propTypes = {
  children: T.any,
  href: T.string,
  className: T.string,
  withIcon: T.bool,
  onClick: T.func
}

export default ExternalLink

import _ from 'lodash'
import React from 'react'
import moment from 'moment'
import {
  ScrollBars,
  StoreProvider,
  LoadingValue,
  TextOverflow
} from 'react-base'
import T from 'prop-types'
import IntelCardPropTypes from '../PropTypes'
import DataCell from './DataCell'
import ErroredValue from './ErroredValue'
import { formatBytes, formatNumber } from 'pw-formatters'
import CustomerSettingsStore from 'stores/CustomerSettingsStore'
import {hasPermission} from 'stores/UserStore'
import CommonViewActions from 'actions/CommonViewActions'
import ThreatMeter from 'components/ThreatMeter'
import FileHashValue from 'components/values/FileHashValue'
import AnalyticsActions from 'actions/AnalyticsActions'

import addtlPropUtils from './additionalPropertiesUtil'
import ExternalLink from 'components/ExternalLink'


const FILE_ATTRS = [
  {prop: 'isArchive', label: 'Archive'},
  {prop: 'isEncrypted', label: 'Encrypted'},
  {prop: 'isTruncated', label: 'Truncated'}
]
const HASHES = ['md5', 'sha1', 'sha256', 'sha512']


class FileInfo extends React.Component {
  static displayName = 'FileInfo';
  static propTypes = IntelCardPropTypes.dataCardShape;

  _onDownloadClick = (id, sensorId) => {
    if (id, sensorId) {
      // downloadObservationFile(id, sensorId)
    }
  };

  render() {
    let {props} = this
    return (
      <div className="intel_card intel_data_card intel_data_fileinfo">
        <h3>File Detail</h3>
        <div className="intel_card_content">
          { props.data ? this.renderData() : null }
        </div>
      </div>
    )
  }

  componentDidUpdate() {
    if (this._threatMeter && this.props.data) {
      let fileInfo = addtlPropUtils.getFileInfoValue(this.props.data)

      if (fileInfo && !fileInfo.error && fileInfo.value) {
        var fileInfoData = addtlPropUtils.getAdditionalProperties(fileInfo.value)

        var riskData = addtlPropUtils.getCategory(fileInfoData, 'Recorded Future Risk Score')
        var riskScore = _.find(riskData, data => data.name === 'score')


        this._threatMeter._onDataUpdate(riskScore)
      }
    }
  }

  componentDidMount() {
    if (this._threatMeter && this.props.data) {
      let fileInfo = addtlPropUtils.getFileInfoValue(this.props.data)

      if (fileInfo && !fileInfo.error && fileInfo.value) {
        var fileInfoData = addtlPropUtils.getAdditionalProperties(fileInfo.value)

        var riskData = addtlPropUtils.getCategory(fileInfoData, 'Recorded Future Risk Score')
        var riskScore = _.find(riskData, data => data.name === 'score')

        this._threatMeter._onDataUpdate(riskScore)
      }
    }
  }

  _trackRecordedFutureLinks = () => {
    AnalyticsActions.event({
      eventCategory: 'intelcard',
      eventAction: 'external-link-clicked',
      eventLabel: 'recorded-future-reference'
    })
  }

  _trackAutoFocusLinks = () => {
    AnalyticsActions.event({
      eventCategory: 'intelcard',
      eventAction: 'external-link-clicked',
      eventLabel: 'autofocus-tag'
    })
  }

  _trackRecordedFutureAllLink = () => {
    AnalyticsActions.event({
      eventCategory: 'intelcard',
      eventAction: 'external-link-clicked',
      eventLabel: 'recorded-future-report'
    })
  }

  _trackAutoFocusAllLink = () => {
    AnalyticsActions.event({
      eventCategory: 'intelcard',
      eventAction: 'external-link-clicked',
      eventLabel: 'autofocus-report'
    })
  }

  _trackVirusTotalLink = () => {
    AnalyticsActions.event({
      eventCategory: 'intelcard',
      eventAction: 'external-link-clicked',
      eventLabel: 'virus-total-report'
    })
  }

  _trackWildfireLink() {
    AnalyticsActions.event({
      eventCategory: 'contextfusion',
      eventAction: 'wildfire',
      eventLabel: 'file'
    })
  }

  renderData = () => {
    let {props} = this
    let obsId = _.get(props, 'route.queryParams.id', null)
    let data = s => props.data('info.' + s)
    let sha256 = data('hashes.sha256')

    let fileInfo = () => addtlPropUtils.getFileInfo(props.data)
    let fileInfoData = addtlPropUtils.getFileInfoValue(props.data)

    // for download purposes, any observation should be ok
    let allObs = props.data('observations.results').valueOf() || []
    let contextObs = _.find(allObs, (obs) => obs.id === obsId)
    let { agentId, id } = contextObs || {}

    if (!fileInfoData.error && fileInfoData.value.length) {
      var recordedFuturesData = addtlPropUtils.getIntelSource(fileInfoData.value, 'nark-recordedfuture-hostinfoservice')
      var autoFocusData = addtlPropUtils.getIntelSource(fileInfoData.value, 'nark-autofocus-hostinfoservice')

      //autofocus fields
      if (autoFocusData && Object.keys(autoFocusData).length) {
        var wildFireVerdict = _.find(autoFocusData.data, data => data.label === 'WildFire Verdict')
        var autoFocusTags = _.find(autoFocusData.data, data => data.label === 'AutoFocus Tags')
        var autoFocusTagsValues = _.find(autoFocusTags.value, data => data.label === 'Tags')
        var autoFocusFirstSeen = _.find(autoFocusTags.value, data => data.label === 'First seen by AutoFocus')
        var autoFocusLinkAll = _.find(autoFocusData.data, data => data.label === 'View Sample in AutoFocus')
      }

      // recorded future fields
      var riskData = addtlPropUtils.getCategory(recordedFuturesData, 'Recorded Future Risk Score', 'data')
      var riskScore = _.find(riskData, data => data.name === 'score')
      var activeRiskColor = addtlPropUtils.setActiveRiskScore(riskScore && riskScore.value)
      var riskCriticality = _.find(riskData, data => data.name === 'category')

      var metricData = addtlPropUtils.getCategory(recordedFuturesData, 'Metrics', 'data')
      var totalHits =  _.find(metricData, data => data.name === 'totalHits')
      var oneDayHits = _.find(metricData, data => data.name === 'oneDayHits')
      var sevenDaysHits = _.find(metricData, data => data.name === 'sevenDaysHits')
      var sixtyDaysHits = _.find(metricData, data => data.name === 'sixtyDaysHits')
      var socialMediaHits = _.find(metricData, data => data.name === 'socialMediaHits')
      var infoSecHits = _.find(metricData, data => data.name === 'infoSecHits')
      var maliciousHits = _.find(metricData, data => data.name === 'maliciousHits')

      var referencesData = addtlPropUtils.getCategory(recordedFuturesData, 'Sightings')

      var linkData = recordedFuturesData
        ? _.find(recordedFuturesData.data, data => data.label === 'Recorded Future Intel Card')
        : null

      var allMetrics = [totalHits, oneDayHits, sevenDaysHits, sixtyDaysHits, socialMediaHits, infoSecHits, maliciousHits]
      var hasOneMetric = allMetrics.some(_.identity)

    }

    return (
        fileInfo().isReady()
        ? (
          <ScrollBars
            classes="pw_scrollbars_light"
            outside
            slimShady
            slimShadyDarker
          >
            {
              riskScore && riskCriticality
              ? (
                <div className="cell">
                  <span>Recorded Future Risk Score</span>
                  <div className="cell_content flex space_between">
                    <div className="rf_risk">
                      <span className="risk_text">
                        { `${riskScore.value} - ${riskCriticality.value}` }
                      </span>
                      <div className="risk_meter">
                        <ThreatMeter
                          ref={ c => this._threatMeter = c }
                          enableLiveData={false}
                          showSubviewLabel={false}
                          liveDataGroupName={'none'}
                          liveDataGraphId={'none'}
                          liveDataOptions={ { dataOptions: { counterTypes: ['value'] }, drawOptions: {} } }
                        />
                      </div>
                    </div>
                    { addtlPropUtils.recordedFutureSourceTag() }
                  </div>
                </div>
              ) : null
            }

            {
              wildFireVerdict
              ? (
                <div className="cell">
                  <span>{ wildFireVerdict.label }</span>
                  <div className="cell_content flex space_between">
                    <div>
                      { wildFireVerdict.value }
                    </div>
                    { addtlPropUtils.autoFocusTag() }
                  </div>
                </div>
              ) : null
            }

            <DataCell
              label="File Size"
              value={ data('detectedFileSize') }
              default="Unknown"
              formatter={ val => <div>
                { formatBytes(val) }
              </div> }
              className="filesize"
              split
            />
            <span className="cell split">
              <div className="cell_content multiline">
                { /*id && agentId ? <button className="btn btn-link download_btn" onClick={ this._onDownloadClick.bind(null, id, agentId) }>Download File</button> : null*/ }
              </div>
            </span>

            <DataCell label="File Type" value={ data('type') } default="Unknown" />

            <section>
              <h5>File Attributes</h5>
              <table className="attributes">
                <tbody>
                { FILE_ATTRS.map(({prop, label}) => {
                  let value = data(prop)
                  return (
                    <tr key={ prop }>
                      <th>{ label }</th>
                      <td>{
                        value.isLoading() ? <LoadingValue /> :
                        value.isError() ? <ErroredValue /> :
                        value.valueOf() ? 'YES' : 'NO'
                      }</td>
                    </tr>
                  )
                }) }
                </tbody>
              </table>
            </section>

            {
              autoFocusTagsValues && autoFocusTagsValues.value.length
              ? (
                <section>
                  <div className="cell">
                    <div className="flex space_between">
                      <h5 className="rf_title">AutoFocus Tags</h5>
                      { addtlPropUtils.autoFocusTag() }
                    </div>
                    <div className="cell_content">
                      <span className="tag_title">
                          <h5>First Seen {moment(autoFocusFirstSeen.value).format('YYYY-MM-DD')}</h5>
                      </span>
                    </div>
                    <div className="cell_content flex col">
                      {
                        <div>
                          <h5>UNIT 42</h5>
                          {
                            autoFocusTagsValues.value
                            .filter(tag => tag.label && tag.label.split('.')[0] === 'Unit42')
                            .map(value => {
                              const [label, val] = value.label.split('.')
                              return (
                                <div key={val}>
                                  <TextOverflow>
                                    <ExternalLink onClick={this._trackAutoFocusLinks} href={value.value} className="btn btn-link external_link tags">
                                      {val + ' '}
                                      <span className="icon icon-arrow-up-right"></span>
                                    </ExternalLink>
                                  </TextOverflow>
                                </div>
                              )
                            })
                          }
                          {
                              autoFocusTagsValues.value
                                .filter(tag => tag.label && tag.label.split('.')[0] === 'Unit42').length
                                ? null : 'No Unit 42 tags available'
                          }
                        </div>
                      }
                      {
                        // group unit 42s together
                        autoFocusTagsValues.value
                        .filter(tag => tag.label && tag.label.split('.')[0] !== 'Unit42')
                        .map(value => {
                          const [label, val] = value.label.split('.')
                          return (
                            <div key={val} className="other_tags">
                              { label.toUpperCase() }
                              <TextOverflow>
                                <ExternalLink onClick={this._trackAutoFocusLinks} href={value.value} className="btn btn-link external_link tags">
                                  { val + ' '}
                                  <span className="icon icon-arrow-up-right"></span>
                                </ExternalLink>
                              </TextOverflow>
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                </section>
              ) : null
            }

            {
              fileInfoData.value.length && metricData.length
              ? (
                <section>
                  {
                    hasOneMetric
                    ? (
                      <div className="cell">
                        <div className="flex space_between">
                          <h5 className="rf_title">Recorded Future Reference Summary</h5>
                          { addtlPropUtils.recordedFutureSourceTag() }
                        </div>
                        <div className="cell_content flex col rf_refs">
                          { totalHits && <div>{`Total References - ${formatNumber(totalHits.value, true)}`}</div> }
                          { oneDayHits && <div>{`Today - ${formatNumber(oneDayHits.value, true)}`}</div> }
                          { sevenDaysHits && <div>{`Last 7 days - ${formatNumber(sevenDaysHits.value, true)}`}</div> }
                          { sixtyDaysHits && <div>{`Last 60 days - ${formatNumber(sixtyDaysHits.value, true)}`}</div> }
                          { socialMediaHits && <div>{`In Social Media - ${formatNumber(socialMediaHits.value, true)}`}</div> }
                          { infoSecHits && <div>{`From InfoSec Sources - ${formatNumber(infoSecHits.value, true)}`}</div> }
                          { maliciousHits && <div>{`Including Malicious Language - ${formatNumber(maliciousHits.value, true)}`}</div> }
                        </div>
                      </div>
                    ) : null
                  }

                  <div className="cell">
                    <h5 className="rf_title">Recent References</h5>
                    { !hasOneMetric ? addtlPropUtils.recordedFutureSourceTag() : null }
                      {
                        referencesData.map(reference => {
                          const title = _.find(reference.sighting, data => data.name === 'title')
                          const source = _.find(reference.sighting, data => data.name === 'source')
                          const published = _.find(reference.sighting, data => data.name === 'published')
                          const url = _.find(reference.sighting, data => data.name === 'url')
                          const type = _.find(reference.sighting, data => data.name === 'type')
                          return (
                            <div className="cell_content" key={`${title.value}-${type.value}`}>
                              <p className="fragment">{title.value}</p>
                              <p className="sighting_type"><span className="sighting_type_title">Type:</span> {type.value}</p>
                                <TextOverflow>
                                  <ExternalLink onClick={this._trackRecordedFutureLinks} href={url.value} className="btn btn-link external_link">
                                    {source.value} | {published.value} {' '}
                                    <span className="icon icon-arrow-up-right"></span>
                                  </ExternalLink>
                                </TextOverflow>
                            </div>
                          )
                        })
                      }
                  </div>
                </section>
              ) : null

            }

            <section className="hashes">
              <h5>File Hashes</h5>
              { HASHES.map((hash, i) => {
                const hashIdData = data('id')
                const resolvedIdData = !hashIdData.isLoading() && !hashIdData.isError()
                  ? hashIdData.valueOf() || null : null

                const hashData = data('hashes.' + hash)
                const resolvedData = !hashData.isLoading() && !hashData.isError()
                  ? hashData.valueOf() || null : null

                return (
                  <div className="cell" key={`hash_${i}`}>
                    <span>{hash.toUpperCase()}</span>
                    <div className="cell_content">
                      <FileHashValue fileId={resolvedIdData} fileName={resolvedData} />
                    </div>
                  </div>
                )
              }) }
            </section>

            <section>
              {
                linkData
                ? (
                  <ExternalLink onClick={this._trackRecordedFutureAllLink} href={ linkData.value.value } className="btn btn-link external_link"
                    data-tooltip="View Intel for this file hash in Recorded Future">
                    All Recorded Future Intel <span className="icon icon-arrow-up-right"></span>
                  </ExternalLink>
                ) : null
              }
              { sha256.valueOf() ? (
                <ExternalLink onClick={this._trackVirusTotalLink} href={ `https://www.virustotal.com/en/file/${ sha256.valueOf() }/analysis/` } className="btn btn-link external_link"
                  data-tooltip="Performs a search for this file in the VirusTotal public database. Opens in a new browser window.">
                  View on VirusTotal.com <span className="icon icon-arrow-up-right"></span>
                </ExternalLink>
              ) : null }

              { sha256.valueOf() && props.wildfireApiKey ? (
                <form action="https://wildfire.paloaltonetworks.com/publicapi/get/report" method="POST" target="_blank">
                  <input type="hidden" name="format" value="pdf" />
                  <input type="hidden" name="hash" value={ sha256.valueOf() } />
                  <input type="hidden" name="apikey" value={ props.wildfireApiKey } />
                  <button type="submit" className="btn btn-link external_link"
                    onClick={this._trackWildfireLink}
                    data-tooltip="Requests a PDF report for this file from Palo Alto Networks WildFire. Opens in a new browser window.">
                    View WildFire Report <span className="icon icon-arrow-up-right"></span>
                  </button>
                </form>
              ) : null }
              {
                autoFocusLinkAll
                ? (
                  <ExternalLink onClick={this._trackAutoFocusAllLink} href={ autoFocusLinkAll.value } className="btn btn-link external_link"
                    data-tooltip="View Sample in AutoFocus">
                    View Sample in AutoFocus <span className="icon icon-arrow-up-right"></span>
                  </ExternalLink>
                ) : null
              }
            </section>
          </ScrollBars>
        ) : <LoadingValue />
    )
  };
}


const wildfireApiKeyMapping = state => ({wildfireApiKey: state.data.wildfire_api_key})

export default (props) => (
  <StoreProvider store={ CustomerSettingsStore } mapping={ wildfireApiKeyMapping }>
    <FileInfo { ...props } />
  </StoreProvider>
)


class ValueCells extends React.Component {
  constructor(props) {
    super(props)
    this.renderValue = this.renderValue.bind(this)
    this.renderType = this.renderType.bind(this)
  }

  renderType(item) {
    const values = item.value ? [item.value] : item.values
    switch (item.type) {
      case 'item':
        return values.map( value => {
          return this.renderValue(value)
        })
      case 'section':
        return values.map( value => {
          return this.renderValue(value)
        })
      default:

    }
  }

  renderValue(item) {
    switch (item.type) {
      case 'string':
        return (
          <div className="value_type_string" ref={item.label}>
            { item.displayName } - { item.value }
          </div>
        )
      case 'gauge':
        // we display these as strings now, should we do a guage/scale type of thing?
        return (
          <div className="value_type_guage" ref={item.label}>
            { item.displayName } - { item.value }
          </div>
        )
      case 'long':
        return (
          <div className="value_type_long" ref={item.label}>
            { item.value }
          </div>
        )
        case 'link':
          return (
            <div className="value_type_link" ref={item.label}>
              <ExternalLink href={item.url} className="btn btn-link external_link">
                { item.value }
                <span className="icon icon-arrow-up-right" />
              </ExternalLink>
            </div>
          )
      default:
        return (
          <div className="value_type_string" ref={item.label}>
            { item.value || '' }
          </div>
        )
    }
  }

  render() {
    const tagLetters = this.props.item && this.props.item.tagLetters.join('') || null
    return (
      <div className="cell">
        <div className="flex space_between">
          <h5 className="rf_title">{this.props.item && this.props.item.label}</h5>
          { tagLetters ? recordedFutureSourceTag(tagLetters, 'Data via Recorded Future') : null }
        </div>
        <div className="cell_content flex col rf_refs">
          { this.renderType(this.props.item) }
        </div>
      </div>
    )
  }
}

const checkArrLength = (props, propName, component) => {
  if (props[propName].length > 2) {
    return new Error('You can use up to 2 letters in your integration tag')
  }

  if (!Array.isArray(props[propName])) {
    return new Error('tagLetters should be array of up to two letters')
  }
}

ValueCells.propTypes = {
  item: T.shape({
    label: T.string.isRequired,
    type: T.oneOf(['item', 'link', 'section']).isRequired,
    tagLetters: checkArrLength,
    instructions: T.arrayOf(
      T.shape({
        name: T.oneOf(['scale']),
        type: T.oneOf(['severity_progression']),
        values: T.arrayOf(T.number)
      })
    ),
    value: T.shape({
      name: T.string.isRequired,
      displayName: T.string,
      type: T.oneOf(['string', 'gauge', 'link', 'long']).isRequired
    }),
    values: T.arrayOf(
      T.shape({
        name: T.string.isRequired,
        displayName: T.string,
        type: T.oneOf(['string', 'gauge', 'link', 'long']).isRequired
      })
    )
  }).isRequired
}

/*

prop type for scrollable sections
what is a guage? types of guages?

*/

import _ from 'lodash'
import Reflux from 'reflux'
import SensorActions from 'actions/SensorActions'
import ProfilerActions from 'actions/ProfilerActions'
import CommonViewActions from 'actions/CommonViewActions'
import AnalyticsActions from 'actions/AnalyticsActions'
import SensorStore from 'stores/SensorStore'
import { requestGet, requestPatch, requestPost, requestPut, RestError } from 'utils/restUtils'
import { PERCEPTIVE_PSEUDO_PROFILE } from 'stores/CaptureProfileStore'
import semverCompare from 'semver-compare'


// mapping retention between days and months
// _retentionMonthsToDays = {
//   1: 31
//   3: 93
//   6: 183
//   9: 276
//   12: 365
// }
// _retentionDaysToMonths = scaleQuantile()
//   .domain(_.values(_retentionMonthsToDays))
//   .range(_.keys(_retentionMonthsToDays).map(Number))

const CONFIG_SAVED_MSG = 'Your changes have been successfully saved.'
const CONFIG_SAVED_RESTART_MSG = 'Your changes have been successfully saved, but not yet applied. Click "Restart Sensor" to apply and restart the sensor.'

const _state = {
  sensorId: null,
  isLoading: false,
  isSaving: false,
  data: null,
  interfaces: null
}

export default Reflux.createStore({
  listenables: SensorActions,

  init() {

  },

  getInitialState() {
    return _state
  },

  onReloadCurrentConfig() {
    if (_state.sensorId) {
      this.onLoadSensorConfig(_state.sensorId)
    }
  },

  async onLoadSensorConfig(sensorId) {
    _state.sensorId = sensorId
    _state.isLoading = true
    _state.isSaving = false
    _state.data = {} //null //Change to empty object so nested bindings don't throw errors
    _state.interfaces = null
    this._notify()

    const requestSensorData = async () => {
      try {
        _state.data = await this._doLoadSensorConfig(sensorId)
      } catch(restError) {
        CommonViewActions.Notification.add({
          type: 'error',
          heading: 'Error',
          message: "An error occurred while loading sensor settings: " + restError.body,
          dismissTimer: 15000
        })
      }

      _state.isLoading = false
      this._notify()
    }

    const requestInterfaces = async () => {
      _state.interfaces = await this._doLoadInterfaces(sensorId)
      this._notify()
    }

    await Promise.all([
      requestSensorData(),
      requestInterfaces()
    ])
  },

  async _doLoadSensorConfig(sensorId) {
    const sensorData = await requestGet(
      'sensor_config_get',
      `sensors/${sensorId}?include=config_applied,sensor_config`
    )
    return this._sensorResponseToUiData(sensorData)
  },

  async _doLoadInterfaces(sensorId) {
    try {
      const interfacesResponse = await requestPost(
        "sensor_interfaces_get",
        `sensor-commands/getinterfaces`,
        {ids: [sensorId]},
        {trackErrors: false}
      )
      return interfacesResponse && interfacesResponse.length > 0
        ? _.get(interfacesResponse[0], 'result.interfaces', [])
        : []
    } catch(restError) {
      // Just ignore failures; 500 responses are expected for sensors that are offline.
      return []
    }
  },

  onSaveSensorConfig(sensorId, changes) {
    // Sanity check that the current sensor hasn't changed out from under us
    if (_state.isLoading || !_state.data || +sensorId !== +_state.sensorId) { return }

    const sensorObj = SensorStore.getSensor(+sensorId)
    if (sensorObj) {
      // Update the in-memory data right away
      _.assign(_state.data, changes)
      _state.isSaving = true
      this._notify()

      AnalyticsActions.event({
        eventCategory: 'profiler',
        eventAction: 'save_sensor_config'
      })
      if (sensorObj.isVersion2) {
        this._doSaveV2(sensorId, sensorObj.revision, changes)
      } else {
        this._doSaveV1(sensorId, changes)
      }
    }
  },

  async _doSaveV1(sensorId, changes) {
    // Separate the changes for the two APIs
    const basicChanges = _.pick(changes, 'friendly_name', 'agent_set_id', 'internal_ips')
    const configChanges = _.omit(changes, 'friendly_name', 'agent_set_id', 'internal_ips', 'retention_months', 'bpf')
    if ('bpf' in changes) {
      configChanges.filters = changes.bpf
    }
    const mayNeedApply = !_.isEmpty(configChanges) || basicChanges.internal_ips != null

    // if typeof changes.retention_months is 'number'
    //   configChanges.retention_days = _retentionMonthsToDays[changes.retention_months]

    // Issue the API calls to save the data, combined into a single deferred. If there are no
    // changes for either of the endpoints we still want to issue a GET for that part of the
    // data; this ensures all the local is up to date and simplifies the success handler signature.
    let basicRequest = null
    let configRequest = null

    if (_.isEmpty(basicChanges)) {
      basicRequest = requestGet('sensor_get', 'sensors/' + sensorId)
    } else {
      basicRequest = requestPut('sensor_put', 'sensors/' + sensorId, basicChanges)
    }

    if (_.isEmpty(configChanges)) {
      configRequest = requestGet('sensor_config_put', 'sensors/' + sensorId + '/config')
    } else {
      configChanges.sensor_id = sensorId //API requires sensor_id in the body as well as the URL for now
      configRequest = requestPut('sensor_config_put', 'sensors/' + sensorId + '/config', configChanges)
    }

    try {
      const [, configData] = await Promise.all([basicRequest, configRequest])

      ProfilerActions.Profiles.getAll() //Force capture profile list to re-load sensor counts
      SensorActions.reloadSensors() //Freshen main sensors store
      await this.onLoadSensorConfig(sensorId) //Reload in this store

      const applied = _.isEmpty(configChanges) || (configData.applied_at && new Date(configData.applied_at) >= new Date(configData.updated_at))
      CommonViewActions.Notification.add({
        type: 'success',
        heading: 'Sensor Settings Saved',
        message: mayNeedApply && !applied ? CONFIG_SAVED_RESTART_MSG : CONFIG_SAVED_MSG,
        dismissTimer: 5000
      })
    } catch(restError) {
      CommonViewActions.Notification.add({
        type: 'error',
        heading: 'Sensor Settings Not Saved',
        message: "Your changes could not be saved because: " + restError.message,
        dismissTimer: 15000
      })
    }

    _state.isSaving = false
    this._notify()
  },

  async _doSaveV2(sensorId, revision, changes) {
    // Separate the changes for the two APIs
    const metaChanges = _.pick(changes, 'friendly_name', 'agent_set_id')
    const configChanges = _.pick(changes, (v, k) => ['internal_ips', 'bandwidth_cap', 'interfaces', 'bpf'].includes(k) || k.startsWith('aggregation'))
    const doMetaRequest = async () => {
      const fieldNames = _.compact([
        'friendly_name' in metaChanges ? 'sensor name' : null,
        'agent_set_id' in metaChanges ? 'sensor set' : null,
      ]).join(' and ')
      try {
        await requestPut('sensor_put', 'sensors/' + sensorId, metaChanges)
        return {
          success: true,
          message: `Successfully saved the ${fieldNames}.`
        }
      } catch(restError) {
        return {
          success: false,
          message: `The ${fieldNames} could not be saved because: ${restError.message}`
        }
      }
    }
    const doConfigRequest = async () => {
      try {
        // Convert flat UI structure to the sensor config structure
        const patch = {
          revision,
          config: {
            sensor: Object.entries(configChanges).reduce((out, [key, value]) => {
              if (key === 'bpf') {
                // supervisor only accepts empty string, not null for removing bpfs
                out.bpf = value || ''
              }
              else if (key.startsWith('aggregation')) {
                _.set(out, key, value || null) //null for making time/bytes fields default to unlimited
              } else {
                out[key] = value
              }
              return out
            }, {})
          }
        }
        await requestPatch(
          'sensor2_config_patch',
          `/sensors/${sensorId}/config`,
          patch,
          { baseURL: '/api/v2' }
        )
        return {
          success: true,
          message: 'Successfully saved sensor runtime parameters.'
        }
      } catch(restError) {
        let reason
        // Handle validation errors (HTTP 422 Unprocessable Entity)
        if (restError.statusCode === 422) {
          //TODO build up message from errors
          //const validationErrors = await restError.response.json()
          reason = 'one or more fields are invalid. Please fix and resubmit.'
        }
        // Handle collision with another behind-the-scenes update (HTTP 409 Conflict)
        else if (restError.statusCode === 409) {
          // TODO - maybe attempt to auto-resolve the conflict and resubmit?
          reason = 'sensor config was modified by another user.'
          try {
            // TODO need to preserve local changes in the form; right now this will
            //  just clobber them with the newer config:
            _state.data = await this._doLoadSensorConfig(sensorId)
            reason += ' Please check your changes and resubmit.'
          } catch(restError) {
            reason += ' But could not load most recent config because: ' + restError.message
          }
        }
        // Handle manager offline (HTTP 424 Failed Dependency)
        else if (restError.statusCode === 424) {
          reason = 'the Sensor Manager could not be reached. Please make sure it is running and try again.'
        }
        // Handle other errors
        else {
          reason = 'an error occurred: ' + restError.message
        }
        return {
          success: false,
          message: `Could not save sensor runtime parameters because ${reason}`
        }
      }
    }

    const [metaResult, configResult] = await Promise.all([
      _.isEmpty(metaChanges) ? null : doMetaRequest(),
      _.isEmpty(configChanges) ? null : doConfigRequest()
    ])

    // Freshen data
    SensorActions.reloadSensors() //Freshen main sensors store
    await this.onLoadSensorConfig(sensorId) //Reload in this store

    // Display success and/or failure message(s)
    const hasError = (metaResult && !metaResult.success) || (configResult && !configResult.success)
    if (hasError) {
      // Individual success/error messages for each submitted request
      if (metaResult) {
        CommonViewActions.Notification.add({
          type: metaResult.success ? 'success' : 'error',
          message: metaResult.message,
          dismissTimer: metaResult.success ? 5000 : 15000
        })
      }
      if (configResult) {
        CommonViewActions.Notification.add({
          type: configResult.success ? 'success' : 'error',
          message: configResult.message,
          dismissTimer: configResult.success ? 5000 : 15000
        })
      }
    } else {
      // Single coalesced success message if no errors
      CommonViewActions.Notification.add({
        type: 'success',
        heading: 'Sensor Config Saved',
        message: CONFIG_SAVED_MSG,
        dismissTimer: 5000
      })
    }

    _state.isSaving = false
    this._notify()
  },

  _sensorResponseToUiData(sensor) {
    // The config API endpoint returns a more complex structure than we need; let's convert
    // it here to a flat object containing just the values the UI needs, along with some
    // conversions to make it easier for the UI to consume.
    const isV2 = semverCompare(sensor.version || "0.1", "2.0.0") >= 0
    const config = (isV2 ? _.get(sensor.sensor_config, '_deployed_config.sensor') : sensor.sensor_config) || {}
    const uiData = Object.assign(
      { // Common fields:
        agent_set_id: sensor.agent_set_id,
        downloaded: sensor.downloaded,
        friendly_name: sensor.friendly_name,
        installToken: sensor.installToken,
        ip_address: sensor.ip_address,
        revisionId: sensor.revision || `${sensor.id}:${sensor.updated_at}`,
      },
      isV2 ? { // V2-only fields:
        bandwidth_cap: config.bandwidth_cap,
        bpf: config.bpf,
        internal_ips: config.internal_ips || [],
        interfaces: config.interfaces || [],
        // Aggregation deep struct: store flat using property paths so we can track dirty state easily
        // but also easily reconstruct a deep patch by just using _.set() later:
        'aggregation.mode': _.get(config, 'aggregation.mode'),
        'aggregation.buffer.location': _.get(config, 'aggregation.buffer.location'), //Memory/Disk
        'aggregation.buffer.retain_packets': _.get(config, 'aggregation.buffer.retain_packets'),
        'aggregation.buffer.disk_size': _.get(config, 'aggregation.buffer.disk_size'),
        'aggregation.buffer.memory_size': _.get(config, 'aggregation.buffer.memory_size'),
        'aggregation.relation_filter.mode': _.get(config, 'aggregation.relation_filter.mode'), //Disabled/IpOnly/IpPair
        'aggregation.relation_filter.ttl': _.get(config, 'aggregation.relation_filter.ttl')
      } : { // V1-only fields:
        backbuffer_memory_size: config.backbuffer_memory_size ? +config.backbuffer_memory_size : null,
        backbuffer_disk_size: config.backbuffer_disk_size ? +config.backbuffer_disk_size : null,
        bandwidth_limit: config.bandwidth_limit,
        capture_profile_id: config.capture_profile_id,
        adaptive_profile_id: config.adaptive_profile_id,
        capture_profile_name: config.adaptive_profile_id === 1
          ? PERCEPTIVE_PSEUDO_PROFILE.name
          : _.get(config, 'capture_profile.name'),
        bpf: config.filters,
        internal_ips: sensor.internal_ips || [],
        interfaces: config.interfaces || [],
        proxies: config.proxies || [],
        proxy_header_list: config.proxy_header_list || [],
        // retention_months: if data.retention_days? then _retentionDaysToMonths(data.retention_days) else null
        sensor_mode: config.sensor_mode
      }
    )

    if (uiData.bpf && _.isArray(uiData.bpf)) {
      // console.log('Got array formatted BPF filters, stringifying...', uiData.bpf)
      uiData.bpf = _(uiData.bpf).map(val => val.bpf).compact().valueOf().join('\n')
    }

    return uiData
  },

  _onDataLoadError(restError) {
    _state.isLoading = false
    this._notify()
    CommonViewActions.Notification.add({
      type: 'error',
      heading: 'Error',
      message: "An error occurred while loading sensor settings: " + restError.body,
      dismissTimer: 15000
    })
  },

/*
  _onDataSaved([basicData, configData]) {
    _state.isSaving = false
    // configData.sensor = basicData #same nested structure as from a single GET with ?include=sensor
    // this._onDataLoaded(configData)
    basicData.sensor_config = configData //same nested structure as from a single GET /sensor/<id>?include=config

    this._onDataLoaded(basicData)
    ProfilerActions.Profiles.getAll(basicData.id) //Force capture profile list to re-load sensor counts
    const sensorObj = SensorStore.getSensor(+_state.sensorId)
    const applied = (sensorObj && sensorObj.isVersion2) ? true :
      (configData.applied_at && new Date(configData.applied_at) >= new Date(configData.updated_at))
    CommonViewActions.Notification.add({
      type: 'success',
      heading: 'Sensor Settings Saved',
      message: !applied ? CONFIG_SAVED_RESTART_MSG : CONFIG_SAVED_MSG,
      dismissTimer: 10000
    })
    SensorActions.reloadSensors()
  },

  _onDataSaveError(restError) {
    _state.isSaving = false
    this._notify()
    CommonViewActions.Notification.add({
      type: 'error',
      heading: 'Sensor Settings Not Saved',
      message: "Your changes could not be saved because: " + restError.body,
      dismissTimer: 15000
    })
  },
*/

  _notify() {
    _state._updated = Date.now()
    this.trigger(_state)
  }
})

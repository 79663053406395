import _ from 'lodash'
import moment from 'moment'
import T from 'prop-types'
import React from 'react'
import { pluralize, formatDurationHuman } from 'pw-formatters'
import SupportActions from 'actions/SupportActions'
import { Button, CircleLoader } from 'react-base'
import { SUPPORT_NOTIFICATION_SHAPE } from 'constants/supportConstants'
import { formatDate, formatRelativeTime } from 'utils/timeUtils'
import ExternalLink from 'components/ExternalLink'

class SupportNotification extends React.Component {
  static displayName = 'SupportNotification'

  static propTypes = {
    activeNotificationsLoading: T.bool,
    notifications: T.arrayOf(SUPPORT_NOTIFICATION_SHAPE),
    preventDismiss: T.bool,
    showNoneMessage: T.bool
  }

  dismissNotification = notificationId => {
    SupportActions.dismissNotification(notificationId)
  }

  dismissAll = () => {
    const { notifications } = this.props

    for (let i = 0, iLen = notifications.length; i < iLen; i++) {
      this.dismissNotification(notifications[i].id)
    }
  }

  render() {
    const {
      activeNotificationsLoading,
      notifications,
      preventDismiss,
      showNoneMessage
    } = this.props

    const numNotifications = notifications ? notifications.length : 0
    const now = Date.now()
    return numNotifications > 0 ? (
      <div className={`notification support_notification_wrap`}>
        <div>
          <h6>
            <span className="icon icon-warning" />
            <div className="flex_1">
              {numNotifications} System{' '}
              {pluralize(numNotifications, 'Notification')}
            </div>
            {!preventDismiss ? (
              <a
                className={`btn btn-link`}
                data-tooltip={`View all current System Notifications`}
                href={`#settings/about`}
              >
                View all...
              </a>
            ) : null}
          </h6>
          {_.map(notifications, (notification, i) => (
            <div
              className={`support_notification sev_${notification.severity ||
                'low'}`}
              key={notification.id || i}
            >
              <div className="time_string">
                {notification.start_at && notification.end_at ? (
                  <span
                    data-tooltip={`From ${formatDate(
                      notification.start_at
                    )} to ${formatDate(notification.end_at)}`}
                  >
                    {notification.start_at < now ? (
                      <span>
                        {`Ends `}
                        <em>{moment(notification.end_at).fromNow()}</em>
                      </span>
                    ) : (
                      <span>
                        {`Starts `}
                        <em>{formatRelativeTime(notification.start_at)}</em>
                        {` and lasts for `}
                        <em>
                          {formatDurationHuman(
                            notification.end_at - notification.start_at,
                            {}
                          )}
                        </em>
                      </span>
                    )}
                  </span>
                ) : notification.start_at ? (
                  <span
                    data-tooltip={`Starting at ${formatDate(
                      notification.start_at
                    )}`}
                  >
                    {notification.start_at > now ? `Starts ` : `Started `}
                    <em>{moment(notification.start_at).fromNow()}</em>
                  </span>
                ) : notification.end_at ? (
                  <span
                    data-tooltip={`Ending at ${formatDate(
                      notification.end_at
                    )}`}
                  >
                    {notification.end_at > now ? `Ends ` : `Ended `}
                    <em>{moment(notification.end_at).fromNow()}</em>
                  </span>
                ) : null}
              </div>
              <p>{notification.message}</p>
              {notification.link ? (
                <ExternalLink
                  className={`more_info_link btn btn-link`}
                  data-tooltip="Open link in a new tab"
                  href={notification.link}
                >
                  More Info <span className="icon icon-arrow-up-right" />
                </ExternalLink>
              ) : null}
              {!preventDismiss ? (
                <Button
                  args={[notification.id]}
                  className={`btn btn-icon icon icon-close`}
                  onClick={this.dismissNotification}
                />
              ) : null}
            </div>
          ))}
          <CircleLoader loading={activeNotificationsLoading} overlay />
        </div>
        {!preventDismiss ? (
          <i
            className="btn btn-icon icon icon-close"
            onClick={this.dismissAll}
          />
        ) : null}
      </div>
    ) : (showNoneMessage ? <div className={`notification support_notification_wrap`}>No active system notifications</div> : null)
  }
}

export default SupportNotification

import React, { ReactNode } from 'react'
import {
  InfoWindow,
  Tabs,
  MenuItem,
  TabContent,
  TabContentPane,
  ScrollBars
} from 'react-base'
import { observable, action } from 'mobx'
import {
  DEVICE_DB_VISION,
  DEVICE_DB_SOURCES
} from 'constants/descriptions'
import { observer } from 'mobx-react'

export interface DataSourcesTooltipProps {
  children: ReactNode
  className: string
}

enum SourceTabs {
  Overview = "Overview",
  DHCP = "DHCP",
  ReverseDNS = "ReverseDNS",
  VerizonAnalytics = "VerizonAnalytics",
  CarbonBlack = "CarbonBlack",
  CrowdStrike = "CrowdStrike"
}

@observer
export default class DataSourcesTooltip extends React.Component<DataSourcesTooltipProps> {
  static displayName = "DataSourcesTooltip"

  @observable currentTab: SourceTabs = SourceTabs.Overview

  @action setCurrentTab = (tabKey: SourceTabs) => {
    this.currentTab = tabKey
  }

  _getInfoHintContents = () => {
    return (
      <div className={`data_sources_tooltip_main`}>
        <div className="info_window_header"><span className={ `icon icon-device` } /> Device Data Sources</div>
        <div className="info_window_content">
          <Tabs vertical activeTabKey={this.currentTab}>
            {Object.keys(SourceTabs).map((tabKey: SourceTabs) => {
              const tab = SourceTabs[tabKey]
              return (
                <MenuItem
                  key={tab}
                  onClick={this.setCurrentTab}
                >
                  {tab === SourceTabs.Overview ? 'Overview' : DEVICE_DB_SOURCES[tab].title}
                </MenuItem>
              )
            })}
          </Tabs>
          <TabContent activeTabKey={this.currentTab}>
            {Object.keys(SourceTabs).map((tabKey: SourceTabs) => {
              const tab = SourceTabs[tabKey]
              return (
                <TabContentPane key={tab}>
                  <ScrollBars outside slimShady slimShadyDarker>
                    <p>{tab === SourceTabs.Overview ? DEVICE_DB_VISION : DEVICE_DB_SOURCES[tab].desc}</p>
                  </ScrollBars>
                </TabContentPane>
              )
            })}
          </TabContent>
        </div>
      </div>
    )
  }

  render() {
    let {
      children,
      className,
    } = this.props

    return (
      <InfoWindow
        className={ className }
        menuClassName={ `info_window_guide data_sources_window` }
        windowContents={ this._getInfoHintContents() }
        menuAlign={`bottom`}
      >
        <span className={ `icon icon-info` } /> { children || 'Data Sources' }
      </InfoWindow>
    )
  }
}

import { QueryColumn, QueryColumnTypes } from "typings/query"

const stringToEnum = e => ({ text: e, value: e })

export const columns: Array<QueryColumn> = [
  // specific fields need to be dynamic, and sorted dynamically
  { key: 'domain', type: QueryColumnTypes.String, header: 'Domain', filterable: true, sortable: true, resizable: true },
  // { key: 'id', type: QueryColumnTypes.String, header: ' },
  // { key: 'customerId', type: QueryColumnTypes.Int, header: 'Customer ID', filterable: false, sortable: false },
  { key: 'occurredAt', type: QueryColumnTypes.Datetime, header: 'Occurred At', filterable: false, sortable: true, resizable: true },
  { key: 'srcIp', type: QueryColumnTypes.Ip, header: 'Source IP', filterable: true, sortable: false, resizable: true },
  { key: 'dstIp', type: QueryColumnTypes.Ip, header: 'Destination IP', filterable: true, sortable: false, resizable: true },
  // { key: 'ips', type: QueryColumnTypes.Ip },
  { key: 'srcPort', type: QueryColumnTypes.Int, header: 'Source Port', filterable: true, sortable: true, resizable: true },
  { key: 'dstPort', type: QueryColumnTypes.Int, header: 'Destination Port', filterable: true, sortable: true, resizable: true },
  // { key: 'ports', type: QueryColumnTypes.Int },
  { key: 'sensorId', type: QueryColumnTypes.Int, header: 'Sensor ID', filterable: true, sortable: false, resizable: true },
  { key: 'threatLevel', type: QueryColumnTypes.Enum, header: 'Threat Level', filterable: true, sortable: true, resizable: true },
  { key: 'threatScore', type: QueryColumnTypes.Enum, header: 'Threat Score', filterable: true, sortable: true, resizable: true },
  { key: 'flowId', type: QueryColumnTypes.String, header: 'Flow ID', filterable: false, sortable: false, resizable: true },
  {
    key: 'type',
    type: QueryColumnTypes.Enum,
    header: 'Type',
    filterable: true,
    sortable: false,
    enums: ['dns'].map(stringToEnum),
    resizable: true
  }
]
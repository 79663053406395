/*
SVG Static Assets
*/

import React from 'react'
import cx from 'classnames'
import getConfig from 'utils/uiConfig'

class StaticAsset extends React.PureComponent {
  static displayName = 'StaticAsset'

  static defaultProps = {
    asset: 'logotype'
  }

  render() {
    let {props} = this;

    if (props.enlightened) {
      const out = this._renderEnlightened(props.asset)
      if (out) return out
    }

    switch (props.asset) {
      // Verizon standard logo. The color of the "verizon" text will be taken from the current
      // CSS `color` property in scope, and should always be either full black or full white.
      case 'mainLogo': {
        // Allow logo image override in uiConfig.json
        const logoImageSrc = (getConfig().urls || {}).logoImageSrc
        if (logoImageSrc) {
          return <img src={logoImageSrc} alt="" className={cx('logo', props.className)} />
        }

        return (
          <svg xmlns="http://www.w3.org/2000/svg"
               viewBox="0 0 186 40.5"
               className={cx('logo verizon_logo', props.className)}
               aria-label="Verizon"
          >
            <title>Verizon</title>
            <path fill="#D52B1E" d="M181.9 0L172 21.2l-3.8-8h-4.1l6.3 13.1h3.2L186 0z" />
            <path fill="currentColor" d="M139.3 39.7h8.3V25.2c0-3.3 2-5.6 5-5.6 2.8 0 4.4 1.9 4.4 4.7v15.5h8.3V22.7c0-5.7-3.6-9.8-9.4-9.8-3.7 0-6.3 1.5-8.3 4.3h-.1v-3.5h-7.9v26h-.3zM123 12.9c-8.7 0-14.3 5.9-14.3 13.8 0 7.7 5.8 13.8 14.3 13.8 8.7 0 14.3-6 14.3-13.8.2-7.7-5.6-13.8-14.3-13.8zm0 21.8c-3.9 0-5.9-3.2-5.9-8s2-8 5.9-8 6 3.1 6 8c.1 4.8-2.1 8-6 8zm-37.8 5h23.1v-6.3h-13v-.1l12.6-13.6v-6.2H85.3v6.3H98v.2L85.2 33.6v6.1zm-10.5 0H83v-26h-8.3v26zm-19.2 0h8.3v-12c0-5.5 3.4-7.9 8.5-7.2h.1v-6.8c-.5-.1-.9-.2-1.7-.2-3.2 0-5.3 1.4-7.2 4.4h-.1v-4.3h-7.8v26.1h-.1zm-15.2-5c-3.7 0-5.8-2.3-6.3-5.8h19.6c0-5.6-1.4-10-4.7-12.9-2.3-2.1-5.3-3.2-9.1-3.2-8.2 0-13.7 5.9-13.7 13.7 0 7.9 5.3 13.8 14.3 13.8 3.4 0 6-.9 8.3-2.4 2.4-1.6 4-3.9 4.5-6.2h-7.9c-1 1.9-2.6 3-5 3zm-.5-16.1c3 0 4.9 2.1 5.2 5.1H34c.6-3 2.4-5.1 5.8-5.1zM9.4 39.7H18l9.4-26h-8.3l-5.3 16.7h-.1L8.5 13.7H0l9.4 26zM74.7 4.4H83v7.1h-8.3V4.4z" />
          </svg>
        )
      }
      case 'productTitle': {
        const productName = getConfig().productName
        if (productName !== 'Network Detection and Response') {
          return (
            <h2>{productName}</h2>
          )
        }
        else {
          // This is a SVG-ized version of the default product title for use on the login screen and similar
          // spots, to avoid having to load the Verizon_NHG font for just that one thing. In the future
          // if we replace the fonts elsewhere in the app we can convert this to plain text.
          return (
            <svg xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 19"
                className={cx('ndr_title', props.className)}
                aria-label="Network Detection and Response"
            >
              <title>Network Detection and Response</title>
              <g fill="currentColor">
                <path d="M3.8,0,8.3,7.7l.6,1.1a5.6,5.6,0,0,1,.5.8h0V0h3.7V15.2H9.3L4.8,7.7a9.7,9.7,0,0,1-.7-1.2c-.1-.3-.3-.5-.4-.8H3.6a3.4,3.4,0,0,0,.1,1v8.5H0V0Z"/>
                <path d="M20.1,4a5.3,5.3,0,0,1,3.7,1.4,6.9,6.9,0,0,1,1.9,5.3H17.9c.2,1.5,1,2.4,2.4,2.4A1.8,1.8,0,0,0,22.1,12h3.4a4.6,4.6,0,0,1-1.8,2.6,6,6,0,0,1-3.4,1,5.6,5.6,0,0,1-5.9-5.8A5.5,5.5,0,0,1,20.1,4ZM17.9,8.6h4.3a2.2,2.2,0,0,0-2.1-2.1A2.1,2.1,0,0,0,17.9,8.6Z"/>
                <path d="M27.5,6.5H26.1V4.3h1.4V.9h3.4V4.3h2V6.5h-2v5.1c0,.9.5,1.1,1.2,1.1h.8v2.5l-1.9.2c-2,0-3.5-.7-3.5-3Z"/>
                <path d="M37.3,4.3l1.1,4.6a6.5,6.5,0,0,1,.4,1.3,2.2,2.2,0,0,1,.1.8H39v-.8a10.3,10.3,0,0,1,.4-1.4l1.1-4.5h3l1.2,4.5.3,1.4.2.8h0l.2-.8.3-1.3L47,4.3h3.5L47.1,15.2H43.9l-1.3-4.9A6.1,6.1,0,0,1,42.3,9a6.4,6.4,0,0,1-.2-.7h0a6.4,6.4,0,0,1-.2.7l-.3,1.3-1.3,4.9H37.1L33.7,4.3Z"/>
                <path d="M56.2,4A5.6,5.6,0,0,1,62,9.8a5.8,5.8,0,1,1-11.6,0A5.6,5.6,0,0,1,56.2,4Zm0,2.5c-1.5,0-2.3,1.3-2.3,3.3s.8,3.3,2.3,3.3,2.3-1.3,2.3-3.3S57.7,6.5,56.2,6.5Z"/>
                <path d="M66.6,4.3V6h0a3.2,3.2,0,0,1,3-1.8h.7v3h-.8c-1.7,0-2.8,1-2.8,3.1v4.9H63.2V4.3Z"/>
                <path d="M74.8,0V8l3.3-3.7h4L78.2,8.4l4.3,6.8h-4l-2.6-4.4-1.1,1.1v3.3H71.4V0Z"/>
                <path d="M93.4,0c4.2,0,7,3,7,7.7a7.5,7.5,0,0,1-2.6,6.2,7.5,7.5,0,0,1-4.7,1.3H86.9V0Zm-.5,3.2H90.6v8.9h2.3c2.5,0,3.7-1.6,3.7-4.4S95.3,3.2,92.9,3.2Z"/>
                <path d="M106.8,4a5,5,0,0,1,3.7,1.4,6.9,6.9,0,0,1,2,5.3h-7.9c.3,1.5,1,2.4,2.5,2.4a1.8,1.8,0,0,0,1.8-1.1h3.4a4.8,4.8,0,0,1-1.9,2.6,6,6,0,0,1-3.4,1,5.5,5.5,0,0,1-5.8-5.8A5.5,5.5,0,0,1,106.8,4Zm-2.2,4.6h4.3a2,2,0,0,0-2-2.1C105.5,6.5,104.9,7.3,104.6,8.6Z"/>
                <path d="M114.3,6.5h-1.5V4.3h1.5V.9h3.4V4.3h2V6.5h-2v5.1c0,.9.4,1.1,1.2,1.1h.8v2.5l-1.9.2c-2.1,0-3.5-.7-3.5-3Z"/>
                <path d="M125.9,4a5.4,5.4,0,0,1,3.8,1.4,6.9,6.9,0,0,1,1.9,5.3h-7.9c.3,1.5,1,2.4,2.5,2.4A1.8,1.8,0,0,0,128,12h3.4a4.6,4.6,0,0,1-1.8,2.6,6.5,6.5,0,0,1-3.5,1,5.5,5.5,0,0,1-5.8-5.8A5.5,5.5,0,0,1,125.9,4Zm-2.1,4.6h4.3A2.2,2.2,0,0,0,126,6.5,2.1,2.1,0,0,0,123.8,8.6Z"/>
                <path d="M138,4a4.9,4.9,0,0,1,5.2,4.2h-3.4A1.8,1.8,0,0,0,138,6.6c-1.5,0-2.2,1.2-2.2,3.2s.7,3.2,2.2,3.2a1.8,1.8,0,0,0,1.9-1.9h3.4c-.1,2.5-2.1,4.5-5.2,4.5a5.6,5.6,0,0,1-5.8-5.8A5.5,5.5,0,0,1,138,4Z"/>
                <path d="M145.2,6.5h-1.4V4.3h1.4V.9h3.4V4.3h2V6.5h-2v5.1c0,.9.5,1.1,1.2,1.1h.8v2.5l-1.9.2c-2,0-3.5-.7-3.5-3Z"/>
                <path d="M155.3,0V2.8h-3.4V0Zm0,4.3V15.2h-3.4V4.3Z"/>
                <path d="M162.4,4a5.6,5.6,0,0,1,5.8,5.8,5.9,5.9,0,0,1-11.7,0A5.6,5.6,0,0,1,162.4,4Zm-.1,2.5c-1.5,0-2.3,1.3-2.3,3.3s.8,3.3,2.3,3.3,2.4-1.3,2.4-3.3S163.9,6.5,162.3,6.5Z"/>
                <path d="M172.7,4.3V5.8h.1A3.7,3.7,0,0,1,176.1,4c2.4,0,3.8,1.7,3.8,4.1v7.1h-3.4V8.9c0-1.2-.6-2-1.7-2a2,2,0,0,0-2,2.3v6h-3.4V4.3Z"/>
                <path d="M188.5,8.8c2-.3,2.8-.5,2.8-1.3s-.5-1.1-1.5-1.1-1.7.5-1.8,1.4h-3.2c.1-2.1,1.7-3.8,5-3.8a5.6,5.6,0,0,1,3.5.9A3.8,3.8,0,0,1,194.7,8v5.5c0,.9,0,1.4.4,1.6h-3.4a2.4,2.4,0,0,1-.4-1.2h0a3.7,3.7,0,0,1-3.2,1.5c-2.2,0-3.8-1.1-3.8-3.3S186.2,9,188.5,8.8Zm2.9,1.2a7.6,7.6,0,0,1-1.8.6c-1.4.3-1.9.7-1.9,1.5s.6,1.2,1.5,1.2a2,2,0,0,0,2.2-2Z"/>
                <path d="M199.7,4.3V5.8h.1A3.7,3.7,0,0,1,203.2,4c2.3,0,3.8,1.7,3.8,4.1v7.1h-3.5V8.9c0-1.2-.6-2-1.7-2s-1.9.9-1.9,2.3v6h-3.5V4.3Z"/>
                <path d="M212.8,4a3.3,3.3,0,0,1,3.1,1.6h0V0h3.5V15.2h-3.3V13.8H216a3.5,3.5,0,0,1-3.2,1.8c-2.8,0-4.8-2.2-4.8-5.8S210,4,212.8,4Zm1,2.6c-1.4,0-2.2,1.3-2.2,3.1s.8,3.1,2.1,3.1,2.3-1.2,2.3-3S215.3,6.6,213.8,6.6Z"/>
                <path d="M231.9,0c3,0,5.3,1.6,5.3,4.4a3.6,3.6,0,0,1-2.6,3.7h0a3.1,3.1,0,0,1,2.2,2.8c.3,2,.1,4,.6,4.2h-3.6c-.4-.2-.4-2-.6-3.5a2.2,2.2,0,0,0-2.5-2.2h-2.3v5.7h-3.8V0Zm-.6,3h-2.9V6.7h2.8c1.5,0,2.3-.6,2.3-1.8S232.8,3,231.3,3Z"/>
                <path d="M243.7,4a5.4,5.4,0,0,1,3.8,1.4,6.9,6.9,0,0,1,1.9,5.3h-7.9c.3,1.5,1,2.4,2.5,2.4a1.8,1.8,0,0,0,1.8-1.1h3.4a4.8,4.8,0,0,1-1.9,2.6,6,6,0,0,1-3.4,1,5.5,5.5,0,0,1-5.8-5.8A5.5,5.5,0,0,1,243.7,4Zm-2.2,4.6h4.4a2.2,2.2,0,0,0-2.1-2.1C242.4,6.5,241.8,7.3,241.5,8.6Z"/>
                <path d="M253.2,11.7c.1,1.1.8,1.6,2,1.6s1.7-.4,1.7-1.1-1.4-1-2.8-1.3-3.9-.8-3.9-3.4S252.3,4,254.9,4s4.8,1.4,4.9,3.5h-3.2c-.1-1-.8-1.3-1.7-1.3s-1.5.3-1.5,1,1.2.9,2.6,1.2,4.2.8,4.2,3.6-2.1,3.6-5,3.6-5.2-1.5-5.3-3.9Z"/>
                <path d="M264.6,4.3V5.7h.1A3.7,3.7,0,0,1,268,4c2.9,0,4.7,2.5,4.7,5.8s-1.9,5.8-4.8,5.8a3.7,3.7,0,0,1-3.1-1.5h0v4.8h-3.5V4.3ZM267,6.8c-1.6,0-2.3,1.3-2.3,3.1s.8,3,2.3,3,2.2-1.1,2.2-3S268.5,6.8,267,6.8Z"/>
                <path d="M279.2,4A5.6,5.6,0,0,1,285,9.8a5.8,5.8,0,0,1-11.6,0A5.6,5.6,0,0,1,279.2,4Zm0,2.5c-1.6,0-2.3,1.3-2.3,3.3s.7,3.3,2.3,3.3,2.3-1.3,2.3-3.3S280.7,6.5,279.2,6.5Z"/>
                <path d="M289.6,4.3V5.8h0A3.9,3.9,0,0,1,293,4c2.4,0,3.8,1.7,3.8,4.1v7.1h-3.5V8.9c0-1.2-.6-2-1.7-2s-1.9.9-1.9,2.3v6h-3.5V4.3Z"/>
                <path d="M301,11.7c.1,1.1.8,1.6,2,1.6s1.7-.4,1.7-1.1-1.3-1-2.8-1.3-3.9-.8-3.9-3.4S300.1,4,302.7,4s4.8,1.4,4.9,3.5h-3.2c-.1-1-.7-1.3-1.7-1.3s-1.5.3-1.5,1,1.2.9,2.6,1.2,4.2.8,4.2,3.6-2.1,3.6-5,3.6-5.2-1.5-5.3-3.9Z"/>
                <path d="M314.3,4a5.4,5.4,0,0,1,3.8,1.4,6.9,6.9,0,0,1,1.9,5.3h-7.8c.2,1.5,1,2.4,2.4,2.4a1.8,1.8,0,0,0,1.8-1.1h3.4a4.6,4.6,0,0,1-1.8,2.6,6.2,6.2,0,0,1-3.4,1,5.6,5.6,0,0,1-5.9-5.8A5.5,5.5,0,0,1,314.3,4Zm-2.1,4.6h4.3a2.2,2.2,0,0,0-2.1-2.1A2.1,2.1,0,0,0,312.2,8.6Z"/>
              </g>
            </svg>
          )
        }
      }
      case 'googleAuthLogo':
        return (
          <svg xmlns="http://www.w3.org/2000/svg" width="9" height="13" viewBox="0 0 18 26" className={ cx('google_auth_logo', props.className) }>
            <path fill="currentColor" d="M12.826,1.231 C13.048,1.364 13.298,1.558 13.576,1.814 C13.842,2.080 14.104,2.408 14.359,2.797 C14.604,3.163 14.820,3.596 15.009,4.096
              C15.165,4.596 15.243,5.179 15.243,5.845 C15.222,7.067 14.942,8.044 14.404,8.777 C14.141,9.132 13.861,9.459 13.566,9.759
              C13.239,10.059 12.891,10.365 12.522,10.676 C12.311,10.887 12.116,11.125 11.937,11.392 C11.726,11.669 11.621,11.992 11.621,12.358
              C11.621,12.713 11.729,13.008 11.945,13.241 C12.129,13.463 12.307,13.657 12.481,13.824 C12.481,13.824 13.681,14.773 13.681,14.773
              C14.427,15.362 15.082,16.011 15.644,16.722 C16.174,17.444 16.450,18.388 16.472,19.554 C16.472,21.208 15.714,22.674 14.200,23.952
              C12.630,25.273 10.364,25.956 7.403,26.000 C4.924,25.978 3.073,25.468 1.850,24.471 C0.617,23.540 -0.001,22.426 -0.001,21.129
              C-0.001,20.497 0.200,19.793 0.601,19.017 C0.991,18.241 1.696,17.559 2.717,16.972 C3.863,16.340 5.067,15.919 6.328,15.709
              C7.578,15.531 8.615,15.432 9.441,15.409 C9.186,15.086 8.959,14.740 8.759,14.371 C8.526,14.014 8.410,13.584 8.410,13.081
              C8.410,12.780 8.454,12.528 8.543,12.328 C8.620,12.115 8.692,11.920 8.759,11.742 C8.357,11.786 7.978,11.808 7.622,11.808
              C5.738,11.786 4.302,11.214 3.314,10.093 C2.280,9.049 1.763,7.833 1.763,6.445 C1.763,4.769 2.496,3.248 3.961,1.882
              C4.967,1.082 6.012,0.561 7.097,0.316 C8.170,0.105 9.177,-0.000 10.116,-0.000 C10.116,-0.000 17.194,-0.000 17.194,-0.000
              C17.194,-0.000 15.008,1.231 15.008,1.231 C15.008,1.231 12.826,1.231 12.826,1.231 ZM14.193,20.889 C14.193,20.023 13.900,19.267 13.314,18.623
              C12.694,18.012 11.725,17.263 10.407,16.374 C10.181,16.352 9.916,16.341 9.612,16.341 C9.432,16.318 8.970,16.341 8.226,16.407
              C7.493,16.507 6.744,16.668 5.978,16.890 C5.798,16.957 5.544,17.057 5.217,17.190 C4.890,17.335 4.558,17.540 4.220,17.807
              C3.893,18.084 3.617,18.429 3.392,18.840 C3.133,19.273 3.003,19.795 3.003,20.406 C3.003,21.605 3.566,22.594 4.693,23.371
              C5.764,24.149 7.229,24.549 9.088,24.571 C10.756,24.549 12.029,24.193 12.908,23.505 C13.764,22.827 14.193,21.955 14.193,20.889 ZM9.258,10.878
              C10.190,10.845 10.966,10.523 11.587,9.910 C11.887,9.476 12.079,9.031 12.165,8.575 C12.216,8.119 12.242,7.735 12.242,7.424
              C12.242,6.077 11.885,4.720 11.171,3.351 C10.837,2.694 10.396,2.160 9.850,1.748 C9.292,1.359 8.651,1.153 7.926,1.131
              C6.967,1.153 6.169,1.526 5.533,2.249 C4.997,3.006 4.741,3.852 4.764,4.786 C4.764,6.021 5.138,7.307 5.888,8.642
              C6.250,9.265 6.718,9.794 7.292,10.228 C7.865,10.662 8.521,10.878 9.258,10.878 Z" fillRule="evenodd"/>
          </svg>
        )

      case 'proxyIcon':
        return (
          <svg viewBox="-0.5 -0.5 16 16" width="16" height="16">
            <circle cx="7.4" cy="7.5" r="2.5" fill="currentColor" />
            <path d="M7.4,1.2c1.7,0,3.3,0.7,4.4,1.8c2.4,2.5,2.4,6.4,0,8.9c-1.2,1.2-2.8,1.8-4.4,1.8c-1.7,0-3.3-0.7-4.4-1.8l1.1-1.1
              c0.9,0.9,2.1,1.4,3.3,1.4s2.4-0.5,3.3-1.4c1.8-1.8,1.8-4.8,0-6.6C9.9,3.3,8.7,2.8,7.4,2.8S5,3.3,4.1,4.2L3,3.1
              C4.2,1.9,5.7,1.2,7.4,1.2z" fill="currentColor" />
            <rect x="0" y="6.7" width="6.6" height="1.5" fill="currentColor" />
            <rect x="12.5" y="6.8" width="2.5" height="1.5" fill="currentColor" />
          </svg>
        )
    }
  }

  _renderEnlightened(asset) {
    return asset === 'mainLogo' ? (
      <svg viewBox="0 0 186 40.5" fill="#60737F">
        <g transform="scale(0.17)">
          <path d="M157.4,146.7l-14-57.2c-0.6-2.5-2.5-4.5-5-5.2c-1.7-0.5-3.9-1-6.5-1.5L143,63.6c1.1-1.9,0.5-4.4-1.5-5.6
              c-1.9-1.1-4.4-0.5-5.6,1.5l-43.6,75.6l10.7-30L89.4,81.4c-6.8,0.8-11.9,2-15.1,2.9c-2.5,0.7-4.4,2.7-5,5.2l-14,57.2
              c-1.3,5.6,1.5,11.4,6.7,14c9.2,4.5,25.1,10.4,44.3,10.4c19.2,0,35.1-5.9,44.3-10.4C155.9,158.2,158.7,152.4,157.4,146.7z"/>
          <path d="M126,50.9c0-10.9-8.8-19.7-19.7-19.7s-19.7,8.8-19.7,19.7s6.7,27.6,19.7,27.6S126,61.8,126,50.9z"/>
          <path d="M176.4,190.7L151.2,171c-1.3-1.1-3.1-1.7-4.7-1.2c-8.6,2.5-12.7,3.9-20.1,5.3c-13.2,2.5-26.8,2.5-40,0
            c-7.5-1.4-12.3-3.1-20.1-5.4c-1.6-0.5-3.4,0.1-4.7,1.2l-25.2,19.7c-10.6,9.5-4.5,27,10.3,27c29.9,0,63.4-11.7,92.8-27.3L116.5,209
            c21.1,6.7,38.2,8.7,49.5,8.7C180.8,217.7,187,200.2,176.4,190.7z"/>
        </g>
        <g transform="scale(0.245) translate(150, 55)">
          <polygon points="547.9,40.6 570.8,40.6 570.8,29.7 547.9,29.7 547.9,18.5 574.4,18.5 574.4,7.5 535.7,7.5
                  535.7,63.2 574.8,63.2 574.8,52.1 547.9,52.1"/>
          <path d="M29.7,7.5H6.9v55.7h12.3V46.5h9.3C41,46.5,51,39.8,51,26.9v-0.2C51,15.4,43,7.5,29.7,7.5z M38.6,27.1
                  c0,4.8-3.6,8.4-9.7,8.4h-9.7v-17h9.5c6.1,0,10,2.9,10,8.4V27.1z"/>
          <polygon points="171.2,18.5 188.1,18.5 188.1,63.2 200.4,63.2 200.4,18.5 217.3,18.5 217.3,7.5 171.2,7.5   "/>
          <polygon points="238.3,40.6 261.2,40.6 261.2,29.7 238.3,29.7 238.3,18.5 264.7,18.5 264.7,7.5 226,7.5 226,63.2
                  265.1,63.2 265.1,52.1 238.3,52.1  "/>
          <path d="M299.9,52.9c-9.6,0-16.2-8-16.2-17.5v-0.2c0-9.6,6.8-17.4,16.2-17.4c5.6,0,10,2.4,14.3,6.3l7.8-9
                  c-5.2-5.1-11.5-8.6-22-8.6c-17.1,0-29.1,13-29.1,28.8v0.2c0,16,12.2,28.7,28.6,28.7c10.8,0,17.1-3.8,22.9-10l-7.8-7.9
                  C310.1,50.3,306.2,52.9,299.9,52.9z"/>
          <polygon points="328,18.5 345,18.5 345,63.2 357.2,63.2 357.2,18.5 374.2,18.5 374.2,7.5 328,7.5   "/>
          <rect x="463.5" y="7.5" width="12.3" height="55.7"/>
          <path d="M510.1,29.9c-9.6-2.5-12-3.7-12-7.3v-0.2c0-2.7,2.5-4.9,7.2-4.9c4.7,0,9.6,2.1,14.5,5.5l6.4-9.2
                  c-5.7-4.5-12.6-7.1-20.7-7.1c-11.4,0-19.5,6.7-19.5,16.8v0.2c0,11.1,7.2,14.2,18.5,17c9.3,2.4,11.2,4,11.2,7.1v0.2
                  c0,3.3-3,5.3-8,5.3c-6.4,0-11.6-2.6-16.6-6.8l-7.2,8.7c6.7,6,15.2,8.9,23.7,8.9c12,0,20.5-6.2,20.5-17.3v-0.2
                  C527.8,36.9,521.4,32.8,510.1,29.9z"/>
          <polygon points="431.6,44.8 423.7,23.6 429.1,7.5 416.3,7.5 404.9,44.8 393.5,7.5 380.3,7.5 399.4,63.6 410.1,63.6
                  417.8,41 426.7,63.6 437.4,63.6 456.4,7.5 443.6,7.5  "/>
          <path d="M94,39.7c6-3.6,8.8-9.3,8.8-15.1c0-9-7.4-17.2-17.2-17.2H58.1v55.7h12.3v-9l11-6.8L92,63.2h14.3L91.5,41.3
                  L94,39.7z M70.4,18.5L70.4,18.5l12.7,0c4.3,0,6.9,3.5,6.9,6.8c0,1.9-0.7,4.3-3.4,6l-16.1,9.9V18.5z"/>
          <path d="M138.5,6.5c-16.4,0-29.6,12.9-29.6,28.8s13.3,28.8,29.6,28.8c16.4,0,29.6-12.9,29.6-28.8
                  S154.8,6.5,138.5,6.5z M138.5,52.9c-9.3,0-16.8-7.9-16.8-17.6s7.5-17.6,16.8-17.6c9.3,0,16.8,7.9,16.8,17.6S147.7,52.9,138.5,52.9z
                  "/>
        </g>
      </svg>
    ) : asset === 'productTitle' ? (
      <div style={{color:'#60737F', fontSize:18, fontWeight:200}}>Security Enlightened™</div>
    ) : null
  }
}

export default props => <StaticAsset {...props} enlightened={window._pw.enlightened} />

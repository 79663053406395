import _ from 'lodash'
import { requestGet, requestPost, rethrowAsRestError } from 'utils/restUtils'
import AnalyticsActions from 'actions/AnalyticsActions'
import { convertObservationsV2toV1 } from 'utils/observationUtils'
import { convertNetflowsV2toV1 } from 'utils/netflowUtils'
import { convertEventsV2toV1 } from 'utils/eventUtils'
import genericUtil from 'ui-base/src/util/genericUtil'
import {
  convertQueryJsToLucene,
  convertQuerySchemaToEditorSchema,
  QUERY_INPUT_TYPE_NAMES
} from 'pw-query'

/**
 * Mapping of v1 search field names to v2/lucene names.
 * Only the fields that changed names are listed here.
 */
const V1_TO_V2_FIELD_MAPPINGS = {
  netflows: {
    srcIp: 'ntuple.srcIp',
    dstIp: 'ntuple.dstIp',
    srcPort: 'ntuple.srcPort',
    dstPort: 'ntuple.dstPort',
    srcDeviceId: 'details.srcDeviceId',
    dstDeviceId: 'details.dstDeviceId',
    duration: 'details.duration',
    totalBytes: 'stats.totalBytes',
    protocols: 'details.protocols',
    vlan: 'details.vlan'
  },

  observations: {
    srcIp: 'associatedId.flowId.ntuple.srcIp',
    dstIp: 'associatedId.flowId.ntuple.dstIp',
    srcPort: 'associatedId.flowId.ntuple.srcPort',
    dstPort: 'associatedId.flowId.ntuple.dstPort',
    srcDeviceId: 'associatedId.flowId.srcDeviceId',
    dstDeviceId: 'associatedId.flowId.dstDeviceId',
    httpHost: 'http.host',
    httpPath: 'http.path',
    httpQueryString: 'http.queryString',
    httpMethod: 'http.method',
    httpResponseCode: 'http.responseCode',
    'X-Forwarded-For': 'http.headers.x-forwarded-for',
    'X-Requested-With': 'http.headers.x-requested-with',
    Referer: 'http.headers.referer',
    'user-agent': 'http.headers.user-agent',
    fileExtractedName: 'file.extractedName',
    fileHash: 'file.fileId',
    fileId: 'file.fileId',
    fileType: 'file.fileType',
    description: 'idsEvent.description',
    generatorId: 'idsEvent.generatorId',
    signatureId: 'idsEvent.signatureId',
    listId: 'observationInfo.listId',
    intelKey: 'observationInfo.intelKey',
    icsType: 'ics.icsType',
    functionCode: 'ics.modbus.functionCode',
    subFunctionCode: 'ics.modbus.functionSubCode'
  },

  events: {
    assignee: 'workflow.assignedTo',
    ip: 'ips',
    killChainStage: 'killChainStages',
    priority: 'workflow.priority',
    state: 'workflow.status',
    resolvedReason: 'workflow.resolution'
  }
}


const COMMON_ENUM_MAPPINGS = {
  threatLevel: {
    high: "High",
    medium: "Medium",
    low: "Low",
    none: "None"
  },
  killChainStage: {
    'recon': 'Recon',
    'delivery': 'Delivery',
    'exploit': 'Exploit',
    'beacon': 'Beacon',
    'cnc': 'Cnc',
    'fortification': 'Fortification',
    'datatheft': 'DataTheft',
    'data_theft': 'DataTheft'
  },
  observedStage: {
    realtime: "Realtime"
  },
  threatCategory: {
    apt: 'Apt',
    botnet: 'Botnet',
    denialofservice: 'DenialOfService',
    exploitsandattacks: 'ExploitsAndAttacks',
    malicious_webpage: 'MaliciousWebpage',
    malicioushost: 'MaliciousHost',
    malware: 'Malware',
    misc: 'Misc',
    phishing: 'Phishing',
    scanning: 'Scanning',
    suspicious: 'Suspicious',
    trojan: 'Trojan',
    unknown: 'Unknown'
    //TODO there are more in the v2 schema enum than what we had defined in pwConstants.threatCategoriesToNames
  }
}

if (window._pw.enableKillboxMethodology) {
  // TEMP
  COMMON_ENUM_MAPPINGS.killChainStage.methodology = 'Methodology'
}

/**
 * Mapping of v1 enum strings to v2 enum strings.
 * The field names here are the v2 names so make sure they are pre-converted from v1.
 * Only those fields with enums that changed are listed here.
 */
const V1_TO_V2_ENUM_MAPPINGS = {
  netflows: {
    threatLevel: COMMON_ENUM_MAPPINGS.threatLevel,
    killChainStages: COMMON_ENUM_MAPPINGS.killChainStage, //note plural
    observationTypes: COMMON_ENUM_MAPPINGS.observationType //note plural
  },
  observations: {
    threatLevel: COMMON_ENUM_MAPPINGS.threatLevel,
    killChainStage: COMMON_ENUM_MAPPINGS.killChainStage,
    observedStage: COMMON_ENUM_MAPPINGS.observedStage,
    threatCategory: COMMON_ENUM_MAPPINGS.threatCategory
  },
  events: {
    threatLevel: COMMON_ENUM_MAPPINGS.threatLevel,
    killChainStage: COMMON_ENUM_MAPPINGS.killChainStage,
    killChainStages: COMMON_ENUM_MAPPINGS.killChainStage,
    observedStage: COMMON_ENUM_MAPPINGS.observedStage,
    threatCategory: COMMON_ENUM_MAPPINGS.threatCategory,
    'workflow.resolution': {
      noAction: 'NoAction',
      remediated: 'Remediated',
      falsePositive: 'FalsePositive',
      suspicious: 'Suspicious',
      unsuccessful: 'Unsuccessful'
    },
    'workflow.status': {
      open: 'Open',
      inProgress: 'InProgress',
      resolved: 'Resolved'
    },
  }
}

/**
 * ...and the inverse.
 */
const V2_TO_V1_ENUM_MAPPINGS = _.mapValues(V1_TO_V2_ENUM_MAPPINGS, family =>
  _.mapValues(family, enums => _.invert(enums))
)



/**
 * Functions to rewrite certain clauses in v1 object format. They should mutate the
 * provided `clause` object as needed, and may return a Promise if the action is async.
 */
const FIELD_REWRITERS = {
  observations: {
    fileHash: clause => {
      // when we get file hash, we need to query by the pw fileId instead
      const FILE_ID_NOMATCH = 'deadbeefdeadbeef'
      clause.name = 'file.fileId'
      const arrValue = (_.isArray(clause.value) ? clause.value : [clause.value]).map(genericUtil.stripQuotes)
      return requestGet(
        null, //allow multiple simultaneous
        `observations/file/metadata?hashes=${arrValue.join(',')}`
      ).then(fileResp => {
        // TODO is there a reason we have to iterate like this? Can we just pluck the ids directly?
        const convertedValue = _.reduce(arrValue, (out, hashValue) => {
          for (let i = 0, iLen = fileResp.length; i < iLen; i++) {
            const fileResult = fileResp[i]
            if (_.values(fileResult.hashes).includes(hashValue)) {
              out.push(fileResult.id)
            }
          }
          return out
        }, [])
        // Now that we've converted the hash to an id, set the value on the clause (by reference).
        // If no id was found, use a dummy fileId that can never match, to avoid turning the clause into a wildcard
        clause.value = convertedValue.length === 1 ? convertedValue[0] :
          convertedValue.length ? convertedValue : FILE_ID_NOMATCH
      }).catch(restError => {
        // Swallow inner errors, if the hash did not resolve to an ID then it wouldn't produce any results anyway
        console.warn('File Hash query error', restError)
        clause.value = FILE_ID_NOMATCH
      })
    },
    'email.transportProtocol': clause => {
      // For some reason the search api validation doesn't accept numeric protocol ids for email protocol like it
      // does for netflow applicationProtocols, so we must pre-translate it to the v2 enum string
      return getQuerySchemaFields('observations')
        .then(({fieldsByName}) => {
          const enums = fieldsByName['email.transportProtocol'].enumValues
          clause.value = _.findKey(enums, d => d === clause.value)
        })
    }
  }
}

/**
 * Mapping of field types returned from Solr schema to those expected by
 * pw-query/util/luceneQueryGenerator.js - anything not defined here will default to 'string'
 */
const SOLR_TYPES_TO_LUCENEGENERATOR_TYPES = {
  'int': 'int',
  'long': 'long',
  'float': 'float',
  'double': 'double',
  'datetime': 'date',
  'latlong': 'geo'
}

/**
 * Process a SOLR-like query schema, returning fields in a flattened/normalized format
 * @param {object} schema
 * @return {object} of format:
 *     {
 *       fieldsByName: {[fieldName]: {type, enumValues, config, origType}, ...},
 *       originalSchema: {}
 *     }
 */
export function normalizeQuerySchema (schema) {
  const fieldsByName = {}
  function visitField(field, namePrefix='') {
    let type = field.type
    // unwrap sets to their member value type
    if (_.isObject(type) && type.name === 'set') {
      type = type.valueType
    }
    // normalize to object form
    if (_.isString(type)) {
      type = {name: type}
    }
    // structs: recurse down to sub-fields
    if (type.name === 'struct') {
      type.fields.forEach(f => visitField(f, `${namePrefix}${field.name}.`))
    } else {
      const baseType = type.underlyingType || type.name
      fieldsByName[namePrefix + field.name] = {
        type: baseType,
        enumValues: baseType === 'enum' ? type.values : null,
        config: field.options,
        origType: field.type
      }
    }
  }
  schema.fields.forEach(f => visitField(f))
  return {
    fieldsByName,
    originalSchema: schema
  }
}

const SOLR_TYPES_TO_QUERY_INPUT_TYPES = {
  long: QUERY_INPUT_TYPE_NAMES.INT,
  int: QUERY_INPUT_TYPE_NAMES.INT,
  binary: QUERY_INPUT_TYPE_NAMES.STRING,
  double: QUERY_INPUT_TYPE_NAMES.DOUBLE,
  datetime: QUERY_INPUT_TYPE_NAMES.DATETIME,
  enum: QUERY_INPUT_TYPE_NAMES.ENUM,
  string: QUERY_INPUT_TYPE_NAMES.STRING,
  inet: QUERY_INPUT_TYPE_NAMES.IP_OR_CIDR,
  latlong: QUERY_INPUT_TYPE_NAMES.STRING, // TODO add special Location type?
  boolean: QUERY_INPUT_TYPE_NAMES.ENUM // TODO handle bools better
}


/**
 * Given a SOLR-like query schema, return a schema suitable for passage directly to
 * a QueryInput component from the `pw-query` library
 *
 * @export
 * @param {object} solrSchema SOLR schema object
 * @param {object} options Options object
 * @param {{label?: string>, tooltip?: string, type?: QUERY_INPUT_TYPE_NAMES}} [options.fieldConfigs] Optional map of `fieldName` to config. Used to provide display strings and type/validation overrides
 * @param {function} [options.filterFields] Filter function called for each normalized field key. Returning false results in the field being removed as a query option
 * @param {function} [options.filterEnumValues] Filter function called for each enum value
 * @returns {object} QueryInput-ready schema object
 */
export function getQueryInputSchemaFromSolrSchema (solrSchema, {filterFields, fieldConfigs, filterEnumValues}) {
  const {fieldsByName} = normalizeQuerySchema(solrSchema)
  const asQueryInputSchema = Object.keys(fieldsByName).reduce((out, fieldKey) => {
    const {
      type,
      enumValues,
      origType,
      config
    } = fieldsByName[fieldKey]
    if (!config.filterable || (filterFields && !filterFields(fieldKey))) {
      return out  // Bailout if not queryable or filtered
    }
    const fieldConfig = fieldConfigs[fieldKey] || {}
    if (!SOLR_TYPES_TO_QUERY_INPUT_TYPES[type]) {
      console.warn(`Unknown SOLR field type, defaulting "${type}" (origType: ${origType})to string for field "${fieldKey}"`, origType)
    }
    const querySchema = {
      label: fieldKey, // Default to literal field key
      tooltip: null,
      fieldName: fieldKey,
      type: SOLR_TYPES_TO_QUERY_INPUT_TYPES[type] || SOLR_TYPES_TO_QUERY_INPUT_TYPES.STRING,
      // options: _.map(HASH_TYPES, t => t.id)
      ...fieldConfig // Override all with provided config values
    }
    if (querySchema.type === QUERY_INPUT_TYPE_NAMES.ENUM) {
      querySchema.options = fieldConfig.options || type === 'boolean' ? ['true', 'false'] : Object.keys(enumValues)
      if (filterEnumValues) {
        querySchema.options.filter(filterEnumValues)
      }
    }
    out.push(querySchema)
    return out
  }, [])

  return convertQuerySchemaToEditorSchema(asQueryInputSchema)
}


const schemaPromises = {}

/**
 * Retrieve the current query schema for the given search family
 * @param family
 * @return Promise<Object> of format:
 *     {
 *       fieldsByName: {[fieldName]: {type, enumValues, config, origType}, ...},
 *       originalSchema: {}
 *     }
 */
export function getQuerySchemaFields(family) {
  let promise = schemaPromises[family]
  if (!promise) {
    promise = schemaPromises[family] = requestGet(
      `query_schema_${family}`,
      `query/schema?collection=${family}`
    ).then(normalizeQuerySchema)
  }
  return promise
}


/**
 * Given a v1 search params object in the format:
 *   {family:'', clauses:[], sort:{name,dir}, limit:N, offset:'', facets:{}}
 * convert it to v2 search params in the format:
 *   {family:'', query:'', sort:'', rows:N, start:N, facets:{}}
 * and returns a Promise that resolves with that object.
 */
function convertSearchParamsV1ToV2({family, clauses, sort, limit, offset, facets}) {
  // Clone clauses so we can freely mutate it without disturbing the original
  clauses = _.cloneDeep(clauses)

  return getQuerySchemaFields(family).then(({fieldsByName}) => {
    // Walk the clauses hierarchy and convert/normalize each
    const promises = []
    const luceneGeneratorSchema = {} //temp schema for luceneQueryGenerator.js for the used fields
    walkV1Clauses(clauses, clause => {
      let clausePromise = Promise.resolve(clause)

      // Execute custom rewriter if specified
      const rewriter = FIELD_REWRITERS[family] && FIELD_REWRITERS[family][clause.name]
      clausePromise = clausePromise.then(rewriter)

      clausePromise = clausePromise.then(() => {
        // Translate field names
        const v2FieldName = mapSearchFieldNameV1ToV2(family, clause.name)
        if (!fieldsByName[v2FieldName]) {
          throw new Error(`Could not map v1 field ${clause.name} to v2`)
        }
        clause.name = v2FieldName

        // Translate enum values
        const solrType = fieldsByName[v2FieldName].type
        if (solrType === 'enum') {
          translateClauseValues(clause, val => {
            // Always allow values that are already numeric (e.g. app protocols) and wildcards
            if (!_.isNumber(val) && val !== '*') {
              const v2EnumStr = mapSearchEnumV1ToV2(family, v2FieldName, val)
              const v2EnumInt = fieldsByName[v2FieldName].enumValues[v2EnumStr]
              if (!_.isNumber(v2EnumInt)) {
                throw new Error(`Could not map ${v2FieldName}:${val} to a valid v2 enum`)
              }
              val = v2EnumStr //v2EnumInt, if planck validation is opened up to accept it?
            }
            return val
          })
        }

        // Add to temp luceneQueryGenerator schema
        luceneGeneratorSchema[v2FieldName] = {
          type: SOLR_TYPES_TO_LUCENEGENERATOR_TYPES[solrType] || 'string'
        }
      })

      promises.push(clausePromise)
    })
    return Promise.all(promises).then(() => {
      // Generate Lucene string from clauses object structure using the collected schema types
      const luceneQuery = convertQueryJsToLucene(clauses, luceneGeneratorSchema, false)

      // Convert sort field
      let luceneSort
      if (sort) {
        const v2SortFieldName = mapSearchFieldNameV1ToV2(family, sort.name)
        if (!fieldsByName[v2SortFieldName]) {
          throw new Error(`Could not map v1 sort field ${sort.name} to v2`)
        }
        luceneSort = `${v2SortFieldName} ${sort.direction.toLowerCase()}`
      }

      // Return v2-ready query params
      return {
        family,
        query: luceneQuery,
        sort: luceneSort,
        rows: parseInt(limit, 10),
        start: offset ? parseInt(offset, 10) : 0,
        facets
      }
    })
  })
  .catch(err => {
    console.error(err)
    throw err
  })
}

/**
 * Convert our facets:{} object structure to url params expected by the v2 search api
 */
function facetsObjToUrlParams(facets) {
  const enc = encodeURIComponent
  const {fields, date, pivots, minCount, limit} = facets
  const params = []
  if (fields) {
    params.push(`facetField=${[].concat(fields).join(',')}`)
  }
  if (date) {
    const {field:dateField, start, end, gapSize, gapUnit} = date
    params.push(`facetDate=${dateField}`)
    params.push(`f.${dateField}.date.start=${enc(new Date(start).toISOString())}`)
    params.push(`f.${dateField}.date.end=${enc(new Date(end).toISOString())}`)
    params.push(`f.${dateField}.date.gap=+${gapSize}.${gapUnit}`)//TODO check units line up
  }
  if (pivots) {
    [].concat(pivots).forEach(pivotFields => {
      params.push(`facetPivot=${pivotFields}`)
    })
  }
  if (minCount) { //can this ever be 0?
    params.push(`facetMinCount=${minCount || 1}`)
  }
  if (limit) {
    params.push(`facetLimit=${limit}`)
  }
  return params.join('&')
}

function translateClauseValues(clause, translateFn) {
  if ('from' in clause) {
    clause.from = translateFn(clause.from)
  }
  if ('to' in clause) {
    clause.to = translateFn(clause.to)
  }
  if (_.isArray(clause.value)) {
    clause.value = clause.value.map(translateFn)
  } else if ('value' in clause) {
    clause.value = translateFn(clause.value)
  }
}

function walkV1Clauses(clauses, fn) {
  if (!_.isArray(clauses)) clauses = [clauses]
  _.forEach(clauses, clause => {
    const sub = clause.not || clause.and || clause.or
    if (sub) {
      walkV1Clauses(sub, fn)
    } else {
      fn(clause)
    }
  })
}




// Track an incremented id for each key passed to requestSearch()
// Used to abort polling setTimeouts when a new request with the same key is issued
const SEARCH_REQ_IDS = Object.create(null)


/**
 * Issue a search request.
 * @param {string} requestKey - Identifier for this request
 * @param {string} family - The search collection, either 'netflows', 'observations', or 'events'
 * @param {object} params - The search parameters. May take one of two formats:
 *
 *        1) v1 search API format:
 *            {
 *              search: [{name, op, value}, {name, op, from, to}, {not: {...}},  ...etc],
 *              options:{
 *                sort: {name, direction},
 *                limit: N,
 *                offset: 'str',
 *                facets: {} //see below
 *              }
 *            }
 *
 *        2) v2 search API format:
 *            {
 *              query: 'lucene query string',
 *              sort: 'fieldname direction',
 *              rows: N,
 *              start: N,
 *              facets: {} //see below
 *            }
 *
 *        Note that either format can be submitted to the v2 API (v1 will be upconverted automatically)
 *        but only the v1 format can be submitted to the v1 API.
 *
 *        The `facets` option is only supported against v2 APIs, and takes the following format:
 *            facets: {
 *              fields: ['fieldName', ...], //array of field names for simple facets
 *              pivots: ['fieldA,fieldB', 'fieldC,fieldD'], //array of pivot facets, each comma-separated
 *              date: { //define a field to be counted by time series - only one allowed currently (unless API is changed)
 *                field: 'fieldName', //field to bucket into time series buckets
 *                start: N, //start timestamp for the time series
 *                end: N, //end timestamp for the time series
 *                gapSize: N, //size of each date bucket, in `gapUnit`s
 *                gapUnit: 'day' //unit for the gapSize: 'day', 'hour', etc.
 *              },
 *              minCount: N, //minimum count a facet must have to be returned, defaults to 1
 *              limit: N //limit of facet results returned, applies to all facets globally, defaults to solr default
 *            }
 *        The response for facets will be `facets:{fields:{}, pivots:{}, date:{}}` where the substructures match those
 *        from the Quaero/Solr APIs.
 *
 * @param {object} extraOpts - Options for the search. Accepts all options supported by
 *        `restUtils.js#RestUtilsOptions`, plus:
 *        - `searchApiVersion: <1|2>` to force the search API version; defaults to 2
 *        - `async: true` to use the async polling interface to search v2, defaults to true for netflows and observations
 *        - `asyncPollFrequency: <number>` to specify a custom wait time in ms between async poll
 *           requests; defaults to 2000
 *        - `onProgress: percentComplete => {}` to receive progress notifications when using `async:true`
 *
 * @return {Promise<{results:[], count:N, nextOffset:''}>}
 */
export function requestSearch(requestKey, family, params, extraOpts={}) {
  const {
    searchApiVersion=2,
    asyncPollFrequency=2000,
    async=(family !== 'events'),
    onProgress
  } = extraOpts
  const restUtilsOpts = _.omit(extraOpts, 'searchApiVersion', 'async', 'asyncPollFrequency', 'onProgress')

  // Assign a request id for this requestKey
  const ourSearchRequestId = requestKey ? (SEARCH_REQ_IDS[requestKey] = (SEARCH_REQ_IDS[requestKey] || 0) + 1) : null

  let promise
  if (searchApiVersion === 1) {
    // Legacy v1 search API
    if (params.options && params.options.facets) {
      params = _.cloneDeep(params)
      delete params.options.facets //support facets against v2 api only
    }
    promise = requestPost(
      requestKey,
      family + '/search',
      params,
      _.assign(
        {
          retry429: true,
          useSocket: family !== 'events'
        },
        restUtilsOpts
      )
    )
  } else if (searchApiVersion === 2) {
    // Newer v2 search API
    // Start by normalizing the v1/v2 input params to the corresponding v2/lucene params
    if (_.isString(params.query)) {
      promise = Promise.resolve(params) //v2, no conversion needed
    } else {
      const searchOpts = params.options || {}
      promise = convertSearchParamsV1ToV2({ //v1 -> v2
        family,
        clauses: params.search || [],
        limit: searchOpts.limit || 0,
        sort: searchOpts.sort,
        offset: searchOpts.offset || 0,
        facets: searchOpts.facets
      })
    }

    promise = promise.then(({query, sort, rows, start, facets}) => {
      let reqPromise

      let queryUrlParams = `q=${encodeURIComponent(query)}`
      if (sort != null) {
        queryUrlParams += `&sort=${encodeURIComponent(sort)}`
      }
      if (rows != null) {
        queryUrlParams += `&rows=${rows}`
      }
      if (start != null) {
        queryUrlParams += `&start=${start}`
      }
      if (facets) {
        queryUrlParams += '&' + facetsObjToUrlParams(facets)
      }

      if (async) {
        // Async polling model...

        // If `trackTiming` was specified, we want that to be the total duration of the query not each
        // individual API call, to extract that and handle it manually
        let trackTiming = null
        if (restUtilsOpts.trackTiming) {
          trackTiming = _.assign({_start: Date.now()}, restUtilsOpts.trackTiming)
          delete restUtilsOpts.trackTiming
        }

        // Send request to start the query task
        reqPromise = requestGet(
          requestKey,
          `query?collection=${family}&${queryUrlParams}`,
          restUtilsOpts
        ).then(initialResponse => {
          // Grab the async task ID from the initial response
          const taskId = _.get(initialResponse, `responseHeader.task.id`)
          if (!taskId) {
            throw new Error("No TaskID returned from GET v1/query")
          }

          // Start polling for this task's progress and results
          return new Promise((resolve, reject) => {
            function poll() {
              // Ignore if this search request was superceded by a newer one with the same requestKey
              if (requestKey && SEARCH_REQ_IDS[requestKey] !== ourSearchRequestId) {
                return
              }

              // Request task status
              requestGet(requestKey, `query/${taskId}`, restUtilsOpts)
                .then(pollResponse => {
                  const done = _.get(pollResponse, `responseHeader.task.completed`, false)

                  // Invoke onProgress handler if specified
                  if (onProgress) {
                    onProgress(done ? 1 : _.get(pollResponse, `responseHeader.task.percentComplete`, 0.1))
                  }

                  // If complete, resolve the promise with the response body, otherwise queue up the next poll
                  if (done) {
                    resolve(pollResponse.response)

                    // Send timing event with total elapsed time
                    if (trackTiming) {
                      AnalyticsActions.timing(_.assign({timingValue: Date.now() - trackTiming._start}, trackTiming))
                    }
                  } else {
                    setTimeout(poll, asyncPollFrequency)
                  }
                })
                .catch(reject)
            }
            poll()
          })
        })
      } else {
        // Simpler v2 non-polling model
        reqPromise = requestGet(
          requestKey,
          `${family}/search?${queryUrlParams}`,
          _.assign({
            baseURL: 'api/v2/',
            retry429: true,
            useSocket: family !== 'events'
          }, restUtilsOpts)
        )
      }

      // Transform raw v2 response to expected format
      reqPromise = reqPromise.then(searchResponse => {
        // Convert result objects to v1-ish format
        let results = searchResponse.docs || []
        results = family === 'observations' ? convertObservationsV2toV1(results) :
          family === 'netflows' ? convertNetflowsV2toV1(results) :
          family === 'events' ? convertEventsV2toV1(results) : null

        // De-dupe
        results = concatSearchResults(family, [], results)

        // Convert facet results
        let facetResults = searchResponse.facetCounts || null
        if (facetResults) {
          facetResults = {
            fields: facetResults.facetFields,
            pivots: facetResults.facetPivot,
            date: facetResults.facetDates
          }
        }

        return {
          results: results,
          facets: facetResults,
          count: searchResponse.total || 0,
          nextOffset: `${parseInt(start || '0', 10) + rows}` //stringified for consistency with v1
        }
      })

      return reqPromise
    })
    .catch(rethrowAsRestError) //ensure all thrown errors are properly wrapped to match restUtils convention
  } else {
    throw new Error('Bad searchApiVersion: ' + searchApiVersion)
  }

  return promise
}

/**
 * Given an old and new set of search result items, concatenate them together while
 * omitting duplicate records that appear in both sets.
 */
export function concatSearchResults(family, currentItems, newItems) {
  const idProp = family === 'netflows' ? 'key' : 'id'
  return _.uniq(currentItems.concat(newItems), idProp)
}

/**
 * Map a field name from its v1 name to corresponding v2 name. If no mapping is found,
 * the original field name will be returned.
 */
export function mapSearchFieldNameV1ToV2(family, v1FieldName) {
  return V1_TO_V2_FIELD_MAPPINGS[family][v1FieldName] || v1FieldName
}

/**
 * Map an enum value from its v1 value to corresponding v2 value. The `fieldName` used
 * should be the v2 field name. If no mapping is found, the input value will be returned.
 */
export function mapSearchEnumV1ToV2(family, fieldName, v1EnumValue) {
  const enumMappings = V1_TO_V2_ENUM_MAPPINGS[family][fieldName]
  return (enumMappings && enumMappings[v1EnumValue]) || v1EnumValue
}

/**
 * Map an enum value from its v2 value to corresponding v1 value. The `fieldName` used
 * should be the v2 field name. If no mapping is found, the input value will be returned.
 */
export function mapSearchEnumV2ToV1(family, fieldName, v2EnumValue) {
  const enumMappings = V2_TO_V1_ENUM_MAPPINGS[family][fieldName]
  return (enumMappings && enumMappings[v2EnumValue]) || v2EnumValue
}


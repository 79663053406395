import _ from 'lodash'
import T from 'prop-types'
import React from 'react'
import { StoreProvider } from 'react-base'
import ipaddr from 'pw-ipaddr.js'
import { pluralize } from 'pw-formatters'
import SensorLocation from './SensorLocation'
import GoogleMapDialog from '../world-map/GoogleMapDialog'
import LocationStore from '../../stores/LocationStore'
import SimpleWorldMap from 'components/world-map/SimpleWorldMap'

const ipAddressUtil = ipaddr.util

const { isValidCIDR, doSubnetsOverlap } = ipAddressUtil

class SensorLocationsInput extends React.Component {
  static displayName = 'SensorLocationsInput'

  static propTypes = {
    allAddresses: T.arrayOf(T.string), // List of typeahead address suggestions
    hasPermission: T.func.isRequired,
    locations: T.array,
    onChange: T.func.isRequired,
    sensorId: T.number.isRequired,
    subnetLocations: T.array.isRequired
    // previouslyUsedLocations: T.arrayOf(T.shape(LOCATION_SHAPE)),
  }

  state = {
    newLocationInputActive: false,
    pendingLocations: [],
    validationErrors: {},
    invalidLocationIds: [],
    viewAllDialogOpen: false
  }

  UNSAFE_componentWillMount() {
    this._updateLocations(this.props.subnetLocations, false)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEqual(nextProps.subnetLocations, this.props.subnetLocations)) {
      // Data was saved or updated
      this._updateLocations(nextProps.subnetLocations, false)
    }
  }

  _updateLocations = (newLocations, emitChange = true) => {
    const { props } = this
    const validatedLocations = _(newLocations)
      .map(location => {
        const loc = _.clone(location)
        const { cidr_range, location_id } = loc
        loc._isCidrRangeValid = !!cidr_range && isValidCIDR(cidr_range)
        loc._isAddressValid = !!location_id

        if (loc._isCidrRangeValid && loc._isAddressValid) {
          loc._isNew = false // No longer treat as a fresh input once valid
        }

        return loc
      })
      .sortBy('id')
      .value() //.reverse()

    const invalidLocationIds = []
    const validationErrors = _.reduce(
      validatedLocations,
      (output, location) => {
        const { id: originalId, cidr_range: originalCidrRange } = location
        if (location._isCidrRangeValid) {
          // CIDR is valid, check for range overlaps
          _.forEach(
            validatedLocations,
            ({ id, cidr_range, _isCidrRangeValid }) => {
              if (originalId !== id && !!_isCidrRangeValid) {
                const validationErrorId =
                  originalCidrRange > cidr_range
                    ? `${originalCidrRange}_${cidr_range}`
                    : `${cidr_range}_${originalCidrRange}` // Prevent duplicate errors for a->b and b->a
                if (originalCidrRange === cidr_range) {
                  invalidLocationIds.push(id, originalId)
                  output[
                    validationErrorId
                  ] = `Duplicate CIDR range: ${originalCidrRange}`
                } else if (doSubnetsOverlap(originalCidrRange, cidr_range)) {
                  invalidLocationIds.push(id, originalId)
                  output[
                    validationErrorId
                  ] = `${originalCidrRange} overlaps with ${cidr_range}`
                }
              }
            }
          )
        }
        return output
      },
      {}
    )

    this.setState(
      {
        pendingLocations: validatedLocations,
        validationErrors: validationErrors,
        invalidLocationIds: invalidLocationIds
      },
      () => {
        if (
          emitChange &&
          _.size(validationErrors) === 0 &&
          _.every(
            validatedLocations,
            loc => loc._isCidrRangeValid && loc._isAddressValid
          )
        ) {
          props.onChange(
            _.map(validatedLocations, loc =>
              _.omit(loc, '_isCidrRangeValid', '_isAddressValid', '_isNew')
            )
          )
        }
      }
    )
  }

  _onAddLocationClick = () => {
    const locations = this.state.pendingLocations
    locations.push({
      id: 0 - Date.now(),
      _isNew: true
    })
    this._updateLocations(locations)
  }

  _closeViewAllDialog = () => {
    this.setState({
      viewAllDialogOpen: false
    })
  }

  _onViewAllClick = () => {
    this.setState({
      viewAllDialogOpen: true
    })
  }

  _getLocationMarkers = () => {
    return _.reduce(
      this.state.pendingLocations || [],
      (output, sensorLocation) => {
        const _location = _.find(this.props.locations, {
          id: sensorLocation.location_id
        })
        if (_location) {
          output.push({
            id: sensorLocation.id,
            text: sensorLocation.cidr_range,
            additionalText: _location.address,
            latitude: _location.latitude,
            longitude: _location.longitude
          })
        }
        return output
      },
      []
    )
  }

  _onLocationDeleted = location => {
    this._updateLocations(
      _.reject(this.state.pendingLocations, { id: location.id })
    )
  }

  _onLocationChanged = updatedLocation => {
    this._updateLocations(
      _.map(this.state.pendingLocations, loc => {
        if (updatedLocation.id === loc.id) {
          return updatedLocation
        } else {
          return loc
        }
      })
    )
  }

  render() {
    const { props, state } = this

    const canCreateLocations = props.hasPermission('sensor-locations:create')
    const canUpdateLocations = props.hasPermission('sensor-locations:update')
    const canDeleteLocations = props.hasPermission('sensor-locations:delete')

    const numErrors = _.size(state.validationErrors)

    const mapMarkers = this._getLocationMarkers()

    return (
      <div className="cell sensor_locations_input">
        <SimpleWorldMap
          markers={mapMarkers}
        />

        {state.pendingLocations.length > 0
          ? <button
            className="btn btn-link expand_map_btn"
            data-tooltip="Enlarge Map"
            onClick={this._onViewAllClick}
          >
            <span className="icon icon-expand" />
          </button>
          : null}

        <span className="locations_label">
          Locations
          {canCreateLocations
            ? <button
                className="btn btn-link add_new_location"
                data-tooltip="Add new location"
                disabled={state.newLocationInputActive}
                onClick={this._onAddLocationClick}
              >
                <span className="icon icon-plus" />
              </button>
            : null}
        </span>
        <div>
          {numErrors > 0
            ? <div className="location_validation_msg">
                <span
                  className="icon icon-warning"
                  data-tooltip={`${numErrors} ${pluralize(numErrors, 'error')}`}
                />
                <div className="flex_1">
                  {_.map(state.validationErrors, (msg, msgId) =>
                    <div key={msgId}>
                      {msg}
                    </div>
                  )}
                </div>
              </div>
            : null}
          {_.isEmpty(state.pendingLocations)
            ? <div className="no_locs">No Location Mappings Defined</div>
            : _.map(state.pendingLocations, location =>
                <SensorLocation
                  canDelete={canDeleteLocations}
                  canEdit={canUpdateLocations}
                  hasPermission={props.hasPermission}
                  invalid={state.invalidLocationIds.indexOf(location.id) !== -1}
                  key={location.id}
                  location={location}
                  onChange={this._onLocationChanged}
                  onCidrInputChange={this.onCidrInputChange}
                  onDelete={this._onLocationDeleted}
                />
              )
          }
        </div>
        {state.viewAllDialogOpen
          ? <GoogleMapDialog
              autoZoomOffset={-1}
              heading={`Sensor Locations`}
              markers={mapMarkers}
              onClose={this._closeViewAllDialog}
            />
          : null}
      </div>
    )
  }
}

const WrappedSensorLocationsInput = props =>
  <StoreProvider store={LocationStore}>
    <SensorLocationsInput {...props} />
  </StoreProvider>

WrappedSensorLocationsInput.displayName = 'Wrapped.SensorLocationsInput'

export default WrappedSensorLocationsInput

import React from 'react'
import ReactDOM from 'react-dom'
import genericUtil from 'ui-base/src/util/genericUtil'
import explorerUtils from 'utils/explorerUtils'
import AnalyticsActions from 'actions/AnalyticsActions'

class QuickSearch extends React.Component {
  static displayName = 'QuickSearch'

  state = {
    open: false
  }

  componentDidMount() {
    window.addEventListener('keydown', this._onGlobalKeyDown)
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this._onGlobalKeyDown)
  }

  componentDidUpdate(oldProps, oldState) {
    if (!oldState.open && this.state.open) {
      ReactDOM.findDOMNode(this._inputRef).focus()
    }
  }

  _toggle = () => {
    this.setState({ open: !this.state.open })
  }

  _onGlobalKeyDown = e => {
    if (e.ctrlKey && e.keyCode === 32) {
      //ctrl+space
      this._toggle()
    }
  }

  _onBlur = () => {
    this.setState({ open: false })
  }

  _onSubmit = e => {
    e.preventDefault()

    let ips = ReactDOM.findDOMNode(this._inputRef)
      .value.split(/[\s,]+/)
      .filter(ip => /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/.test(ip))
    if (ips.length) {
      let query = {
        family: 'observations',
        clauses: [
          {
            //TODO: make the timeframe match the HUD/Killbox timeline range when on those pages?
            name: 'occurredAt',
            op: 'between',
            from: Date.now() - genericUtil.durations(1, 'hours'),
            to: Date.now()
          },
          {
            name: 'ip',
            op: ips.length === 1 ? 'eq' : 'in',
            value: ips.length === 1 ? ips[0] : ips
          }
        ]
      }
      location.href = `#explorer?q=${explorerUtils.stringifyQuery(query)}`
      this.setState({ open: false })

      AnalyticsActions.event({
        eventCategory: 'quicksearch',
        eventAction: 'submit'
      })
    }
  }

  handleReloadClick = () => {
    window.location.reload()
  }

  _bindInputRef = el => {
    this._inputRef = el
  }

  render() {
    let { state } = this
    return (
      <div className="quick_search_wrap">
        <form
          className={`quick_search ${state.open ? 'open' : ''}`}
          onSubmit={this._onSubmit}
        >
          <label htmlFor="quickSearchInput">IP:</label>
          <input
            className="form-control"
            id="quickSearchInput"
            onBlur={this._onBlur}
            ref={this._bindInputRef}
            type="text"
          />
          <span
            className="icon icon-search"
            data-tooltip="Quick Search (Ctrl+Space)"
            onClick={state.open ? null : this._toggle}
          />
        </form>
        <a
          className="standalone_app_reload btn-icon icon-spinner"
          onTouchEnd={this.handleReloadClick}
        />
      </div>
    )
  }
}

export default QuickSearch

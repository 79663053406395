import PropTypes from 'prop-types'
const {string, number, array, arrayOf, objectOf, shape, bool, object, oneOf, func} = PropTypes


const queryTypes = oneOf(['ip', 'domain', 'file', 'device', 'certificate', 'sensor'])

/* Informative only... all data is retrieved from a single accessor function (see dataCardShape.data)

const timeSeriesShape = shape({
  timeRange: shape({
    interval: string,
    intervalSize: number,
    start: number,
    end: number
  }),
  values: arrayOf(shape({
    timestamp: number,
    count: number
  }))
})

const threatStatsShape = shape({
  events: shape({
    maxThreatLevel: string,
    maxThreatScore: number,
    threatCategory: objectOf(number),
    threatSubCategory: objectOf(number),
    count: shape({
      total: number,
      timeSeries: timeSeriesShape
    }),
    top: array
  }),
  observations: shape({
    types: objectOf(number),
    timeSeries: timeSeriesShape
  }),
  netflows: shape({
    timeSeries: timeSeriesShape
  }),
  riskFactors: objectOf(number)
})

const deviceInfoShape = shape({
  deviceType: string,
  deviceCategory: string,
  deviceOs: string,
  lastUpdated: number
})

const domainInfoShape = shape({
  registrar: string,
  organization: string,
  firstSeen: number,
  lastSeen: number,
  resolveData: arrayOf(shape({
    resolvesTo: string,
    firstSeen: number,
    lastSeen: number
  }))
})

const geoInfoShape = shape({
  country: string,
  region: string,
  city: string,
  latitude: number,
  longitude: number,
  isp: string,
  organization: string
})

const ipInfoShape = shape({
  host: string,
  organization: string,
  isp: string,
  asName: string,
  asNumber: string
})

// All together now
const dataShape = shape({
  threat: threatStatsShape,
  device: deviceInfoShape,
  domain: domainInfoShape,
  geo: geoInfoShape,
  ip: ipInfoShape
})
*/

// Everything passed to the card components
const dataCardShape = {
  isLoading: bool,
  error: object,
  queryType: queryTypes,
  queryValue: string,
  startTime: number,
  endTime: number,
  data: func.isRequired
}


export default {
  dataCardShape
}

import Reflux from 'reflux'

import CommonViewActions from 'actions/CommonViewActions'

let _state = {
  content: null,
  position: {},
  isVisible: false,
  isExactPosition: false //pass true if caller is specifying exact pixel coordinates and not a target element
}

export default Reflux.createStore({
  listenables: CommonViewActions.Tooltip,

  getInitialState() {
    return _state
  },

  init() {

  },

  notify() {
    this.trigger(_state)
  },

  onUpdateTooltip(content, position, isExactPosition = false) {
    _state = {
      isVisible: true,
      content: content,
      position: position,
      isExactPosition: isExactPosition
    }
    this.notify()
  },

  onUpdateTooltipPosition(position) {
    _state.isVisible = true
    _state.position = position
    _state.isExactPosition = true
    this.notify()
  },

  onHideTooltip() {
    _state = {
      content: null,
      position: {},
      isVisible: false,
      isExactPosition: false //pass true if caller is specifying exact pixel coordinates and not a target element
    }
    this.notify()
  }
})

import _ from 'lodash'
import React from 'react'
import T from 'prop-types'
import cx from 'classnames'
import {
  Button,
  ScrollBars,
  TextInput,
  Checkbox,
  TextOverflow
} from 'react-base'
import IntelRuleQuickAddActions from 'actions/IntelRuleQuickAddActions'
import {
  INTEL_LIST_SHAPE,
  THREAT_TEAM_CID
} from 'constants/intelManagementConstants'
import IntelListStore from 'stores/intel-management/IntelListStore'

function _focusSelf(el) {
  if (el) {
    el.focus()
  }
}

class QuickAddLists extends React.Component {
  static displayName = 'QuickAddLists'

  static propTypes = {
    currentCustomerId: T.number.isRequired,
    lists: T.arrayOf(INTEL_LIST_SHAPE),
    newListName: T.string,
    selectedListId: T.string,
    setDefaultListTag: T.bool
  }

  state = {
    newListInputActive: false
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.newListName !== this.props.newListName) {
      this.resetInput()
    }
  }

  saveNewListName = input => {
    IntelRuleQuickAddActions.setNewListName(input)
  }

  handleSaveButtonClick = () => {
    const { invalidMessage, value } = this._inputRef.state // FIXME this is gross. add instance getters?
    if (!invalidMessage) {
      this.saveNewListName(value)
    }
  }

  setInputActive = () => {
    this.setState({
      newListInputActive: true
    })
  }

  resetInput = () => {
    this.setState({
      newListInputActive: false
    })
  }

  handleInputRef = el => {
    if (el) {
      _focusSelf(el)
      this._inputRef = el
    }
  }

  render() {
    const {
      currentCustomerId,
      lists,
      newListName,
      selectedListId,
      setDefaultListTag
    } = this.props
    const { newListInputActive } = this.state

    const _lists =
      +currentCustomerId === THREAT_TEAM_CID
        ? lists
        : _.filter(lists, list => list._isUserList)

    return (
      <div className="quick_add_lists">
        <h6>Choose an Intel List</h6>
        <div className="quick_add_lists_content">
          <ScrollBars slimShady slimShadyDarker>
            <div>
              {_lists.length > 0 ? (
                _.map(_lists, list => {
                  const isSelected = list.id === selectedListId
                  return (
                    <Button
                      args={[list.id]}
                      className={cx(`quick_add_list`, {
                        selected_list: isSelected
                      })}
                      el={'div'}
                      key={list.id}
                      onClick={IntelRuleQuickAddActions.setSelectedListId}
                    >
                      <span
                        className={`icon icon-radio-${isSelected
                          ? 'checked'
                          : 'unchecked'}`}
                      />
                      <TextOverflow className="overflow">
                        {list.name}
                      </TextOverflow>
                      {list._isDefaultTargetList ? (
                        <span
                          className={`pw_tag`}
                          data-tooltip={`Default target list.`}
                        >
                          Default
                        </span>
                      ) : null}
                    </Button>
                  )
                })
              ) : (
                <div className="no_items">
                  Looks like you don&apos;t have any lists yet.
                </div>
              )}
              {newListInputActive ? (
                <div
                  className={`new_profile_form simple_input ${newListInputActive
                    ? 'editing'
                    : ''}`}
                >
                  <TextInput
                    blurOnSave
                    className="grid_input stealth cell_content"
                    clearOnSave
                    initialValue={newListName}
                    onSave={this.saveNewListName}
                    placeholder={`New List Name`}
                    ref={this.handleInputRef}
                    saveOnBlur
                    validateOnChange
                    validator={IntelListStore.validateListName}
                  />
                  <span
                    className="btn btn-icon icon-checkmark"
                    onClick={this.handleSaveButtonClick}
                  />
                  <span
                    className="btn btn-icon icon-blocked"
                    onClick={this.resetInput}
                  />
                </div>
              ) : !newListName ? (
                <div className={`quick_add_list`} onClick={this.setInputActive}>
                  <span className="icon icon-plus" /> New List
                </div>
              ) : (
                <Button
                  args={['new']}
                  className={`quick_add_list`}
                  el={'div'}
                  onClick={IntelRuleQuickAddActions.setSelectedListId}
                >
                  <TextOverflow
                    className={cx(`move_to_list`, {
                      selected_list: selectedListId === 'new'
                    })}
                  >
                    <span
                      className={`icon icon-radio-${selectedListId === 'new'
                        ? 'checked'
                        : 'unchecked'}`}
                    />
                    {newListName}
                    <span
                      className={`btn btn-icon icon-pencil`}
                      data-tooltip={`Edit New List Name...`}
                      onClick={this.setInputActive}
                    />
                  </TextOverflow>
                </Button>
              )}
            </div>
          </ScrollBars>
        </div>
        <div className="quick_add_options">
          <h6>Options</h6>
          <div
            className={`checkbox_wrap ${setDefaultListTag
              ? 'checked'
              : 'unchecked'}`}
          >
            <Checkbox
              checked={setDefaultListTag}
              onChange={IntelRuleQuickAddActions.toggleSetDefaultListTag}
            />
            <div onClick={IntelRuleQuickAddActions.toggleSetDefaultListTag}>
              <div>Set Default (recommended)</div>
              <span>
                Set selected list as the default for future Whitelist/Blacklist
                additions.
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default QuickAddLists

import _ from 'lodash'
import T from 'prop-types'
import React from 'react'
import ReactDOM from 'react-dom'
import explorerUtils from 'utils/explorerUtils'
import urlHistoryUtil from 'utils/urlHistoryUtil'
import AnalyticsActions from 'actions/AnalyticsActions'
import {
  Button,
  InfoWindow,
  stepFocus
} from 'react-base'
import ValueActionsStore from 'stores/ValueActionsStore'
import {
  VALUE_TYPE_OPTIONS
} from 'constants/searchableValueConstants'
import ValueActions from 'actions/ValueActions'
import CommonViewActions from 'actions/CommonViewActions'
import { openPcapDownloadDialog } from 'actions/pcapDownloadActions'
import { QueryOperators } from 'typings/query'

// observations are broken (forever?)
const SEARCHABLE_FAMILIES = ['netflows']

class SearchableValue extends React.Component {
  static displayName = 'SearchableValue'

  static propTypes = {
    tagName: T.string,
    testId: T.string,
    className: T.string,
    copyValue: T.string,
    intelCard: T.string,
    intelCardTooltip: T.string,
    logParams: T.shape({
      end: T.number,
      ip: T.string,
      sensorId: T.number,
      start: T.number
    }),
    menuAlign: T.oneOf(['top', 'right', 'bottom', 'left']),
    menuClassName: T.string,
    onShowAdditionalActions: T.func,
    query: T.shape({family: T.oneOf(['observations', 'netflows']), clauses: T.array}),
    value: T.string,
    valueType: T.oneOf(_.values(VALUE_TYPE_OPTIONS)),
    relatedData: T.object,
    sensorId: T.number, // Only passed down for IPValue right now, and it only internal!
    pcapDownloadFilter: T.shape({
      sensorId: T.number, // Optional sensorId prefilter
      startTime: T.number.isRequired,
      endTime: T.number.isRequired,
      ips: T.arrayOf(T.string),
      ports: T.arrayOf(T.number)
    })
    // tagName: T.string,
    // testId: T.string,
  }

  static defaultProps = {
    // tagName: 'span',
    // testId: null,
    className: '',
    menuAlign: 'top',
    menuClassName: '',
    intelCardTooltip: 'View Info',
    //defaults to the innerText
    copyValue: null,
  }

  //getInitialState: () => ({
  //  pressing: false
  //}),

  // explorer deux
  // there are obviously more of these translations, but these appear to be all that we need
  _transformClauseName = (clause) => {
    switch (clause) {
      case 'agentId':
        return 'sensorId'
      case 'protocols':
        return 'applicationProtocolNames'
      default:
        return clause
    }
  }

  // explorer deux
  _transformClauseOp = (op) => {
    switch (op) {
      case 'eq':
        return QueryOperators.Equal.symbol
      case '!eq':
        return QueryOperators.NotEqual.symbol
      case 'gt':
        return QueryOperators.GreaterThan.symbol
      case 'gte':
        return QueryOperators.GreaterThanOrEqual.symbol
      case 'lt':
        return QueryOperators.LessThan.symbol
      case 'lte':
        return QueryOperators.LessThanOrEqual.symbol
      case 'between':
        return QueryOperators.Between.symbol
      case 'in':
        return QueryOperators.Contains.symbol
      default:
        return op
    }
  }

  _getExplorerHref = () => {
    const scope = this.props.query.clauses.find(c => c.name === 'occurredAt')
    const clauses = this.props.query.clauses.filter(c => c.name !== 'occurredAt')
    const q = {
      limit: 1000,
      family: this.props.query.family,
      from: new Date(scope.from).toISOString(),
      to: new Date(scope.to).toISOString(),
      clauses: clauses.filter(c => c.name !== 'occurredAt')
        .map(c => ({
          column: this._transformClauseName(c.name),
          symbol: this._transformClauseOp(c.op),
          value: c.value,
        }))
    }
    return `#explorer?q=${ encodeURI(JSON.stringify(q)) }`
  }

  _onDoubleClick = () => {
    // Doubleclick will only launch intelcard, if available
    if (this.props.intelCard) {
      window.location.href = getIntelCardHref(this.props.intelCard)
      this._closeInfoWindowMenu()
    }
  }

  _onCopyClick = e => {
    const el = document.createElement('input')
    el.value = this.props.copyValue || ReactDOM.findDOMNode(this).innerText
    el.style.position = 'fixed'
    el.style.top = '-999px'
    document.body.appendChild(el)
    el.select()
    try {
      document.execCommand('copy')
    } catch(err) {
      console.log('Copy to clipboard failed')
    }
    document.body.removeChild(el)

    // Return focus to trigger
    e.currentTarget.focus()

    CommonViewActions.Notification.add({
      message: `Copied to clipboard`,
      type: 'success',
      dismissTimer: 2000
    })

    // Analytics
    AnalyticsActions.event({
      eventCategory: 'workflow',
      eventAction: 'copy_to_clipboard',
      eventLabel: this.props.valueType
    })
  }

  _onPivotToExplorer = () => {
    const basePath = urlHistoryUtil.parseUrl().pathArr[0]
    if (basePath === 'killbox') { //TODO allow tracking from all screens?
      AnalyticsActions.event({
        eventCategory: basePath,
        eventAction: 'pivot_to_explorer',
        eventLabel: _(explorerUtils.flattenClauses(this.props.query.clauses)).pluck('name').uniq().sort().join(',')
      })
    }
  }

  showAdditionalActions = () => {
    const {
      valueType,
      relatedData,
      value
    } = this.props
    ValueActions.show(valueType, value, null, relatedData)
  }

  handleDownloadPcapClick = () => {
    openPcapDownloadDialog(this.props.pcapDownloadFilter)
  }

  _onPopupRef = ref => {
    this._popupEl = ref
  }

  _getWindowContents = () => {
    const {props} = this
    const {
      valueType,
      logParams,
      pcapDownloadFilter
    } = this.props

    const valueActions = ValueActionsStore.getValueActionsByValueType(valueType)

    return (
      <div
        className="icons"
        onClick={ this._closeInfoWindowMenu }
        ref={this._onPopupRef}
      >
        { props.intelCard ? (
          <a
            className="icon icon-info"
            data-tooltip={ props.intelCardTooltip }
            href={ getIntelCardHref(props.intelCard) }
            key="_intel_card"
          />
        ) : null }
        { logParams ? (
          <a
            className="icon icon-data-table"
            data-tooltip="View Logs"
            href={ getLogViewerHref(logParams) }
            key="_pan_log_viewer"
          />
        ) : null }
        { props.query && SEARCHABLE_FAMILIES.includes(this.props.query.family) ? (
          <a
            className="icon icon-search"
            data-tooltip="Search in Explorer"
            href={ this._getExplorerHref() }
            key="_search"
            onClick={ this._onPivotToExplorer }
          />
        ) : null }
        <Button
          className="icon icon-signup"
          data-tooltip="Copy to Clipboard"
          key="_copy_to_clipboard"
          onClick={ this._onCopyClick }
          passEventAsFirstArg
        />
        { pcapDownloadFilter &&
          <Button
            className="icon icon-download"
            data-tooltip="Download PCAP..."
            key="_pcap_downloader"
            onClick={ this.handleDownloadPcapClick }
          />
        }
        { valueActions && valueActions.length > 0 ? (
          <Button
            className="icon icon-lightning"
            data-tooltip="Take Action..."
            key="_additional_actions"
            onClick={ this.showAdditionalActions }
          />
        ) : null }
      </div>
    )
  }

  _openInfoWindowMenu = () => {
    const cmp = this._infoWindowRef
    if (cmp) cmp.openMenu()
  }

  _closeInfoWindowMenu = () => {
    const cmp = this._infoWindowRef
    if (cmp) cmp.closeMenu()
  }

  _bindInfoWindowRef = (ref) => {
    this._infoWindowRef = ref
  }

  render() {
    const {
      props
    } = this

    return (
      <InfoWindow
        { ...props }
        className={ props.className + " searchable_value" }
        menuClassName={ props.menuClassName + " searchable_value_menu" }
        onDoubleClick={ this._onDoubleClick }
        getRef={ this._bindInfoWindowRef }
        windowContents={ this._getWindowContents }
      >{ props.children }</InfoWindow>
    )
  }
}

export default SearchableValue



export function getIntelCardHref(intelCardParam) {
  // TODO is there a cleaner way than regex replacement?
  let hash = window.location.hash
  hash = hash.replace(/&?(intelcard|logviewer)=[^&]*/, '').replace(/\?$/, '')
  return hash + (hash.indexOf('?') < 0 ? '?' : '&') + 'intelcard=' + encodeURIComponent(intelCardParam)
}

export function getLogViewerHref(params) {
  // TODO is there a cleaner way than regex replacement?
  let hash = window.location.hash
  hash = hash.replace(/&?(intelcard|logviewer)=[^&]*/, '').replace(/\?$/, '')
  return hash + (hash.indexOf('?') < 0 ? '?' : '&') + `logviewer=ip-${ params.ip }-start-${ params.start }-end-${ params.end }${ params.sensorId ? `-sensorId-${ params.sensorId }` : '' }`
}

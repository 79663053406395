import React, { FunctionComponent } from 'react'
import {observer} from 'mobx-react'
import { HvtIcon } from './IconHeaders'

export interface HighValueTargetProps {
  value: boolean
}

export const HighValueTarget: FunctionComponent<HighValueTargetProps> = observer(props => (
  <HvtIcon
    className={props.value === true ? 'is_hvt' : ''}
    tooltip={props.value === true ? 'High Value Target' : 'Low Value Target' }
  />
))

HighValueTarget.displayName = "HighValueTarget"



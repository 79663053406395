import { rootStoreManager } from 'stores/mobx/Root'

export const DownloadPcaps = (pcapRequest, fileName) => {
  rootStoreManager.dispatchAction('downloadPcaps', pcapRequest, fileName)
}

export const DownloadPcapForObservations = (observations, fileName) => {
  rootStoreManager.dispatchAction('downloadPcapForObservations', observations, fileName)
}

export const DownloadPcapForNetflows = (flows, fileName) => {
  rootStoreManager.dispatchAction('downloadPcapForNetflows', flows, fileName)
}

export const DownloadExplorerDeuxQueryResults = (id, bytes) => {
  rootStoreManager.dispatchAction('downloadExplorerDeuxQueryResults', id, bytes)
}
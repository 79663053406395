import _ from 'lodash'
import Reflux from 'reflux'
import { getTimeframeRange } from 'stores/SensorDashboardStore'
import moment from 'moment'
import { requestGet } from 'utils/restUtils'
import querystringUtil from 'ui-base/src/util/querystringUtil'
import genericUtil from 'ui-base/src/util/genericUtil'
import SensorGraphsActions from 'actions/SensorGraphsDialogActions'
import SensorStore from 'stores/SensorStore'


/**
 * Shared utility function that loads the sensor graphs data from the API. Stores can use this
 * and put the result in their state as appropriate.
 * @param sensorId
 * @param timeframe
 * @param ticks
 * @returns {Object}
 * @throws RestError
 */
export async function loadSensorGraphsData(sensorId, timeframe, ticks) {
  const sensor = SensorStore.getSensor(+sensorId)
  if (!sensor) return

  // Borrow timeframe resolution logic from SensorDashboardStore - not sure who should really own this
  const {start, end} = getTimeframeRange(timeframe)

  const interval = genericUtil.getIntervalFromTimespan((end - start) / ticks)
  const intervalMs = +moment.duration(interval.intervalSize, interval.intervalUnit)
  const intervalString = interval.intervalSize + interval.intervalUnit
  const data = {}

  async function loadSummables() {
    const counters = [
      'bytesCaptured', //-->bytesSeen
      'bytesCompressedSentPcap', //-->bytesCaptured
      'netflowCreate', //-->netflowsCaptured
      'netflowNew' //-->netflowsSeen
    ]
    if (!sensor.isVersion2) {
      counters.push(
        'bytesDropped', //-->loss
        'bytesDiscardedBySensor', 'bytesOptimized' //-->truncation
      )
    }

    let results = await requestGet(
      null,
      `counters?${querystringUtil.stringify({
        sensorId,
        start,
        end,
        interval: intervalString,
        counterType: counters.join(','),
        stackBy: 'type'
      })}`
    )

    // Trim off trailing intervals
    results = results.filter(segment => segment.timestamp < end)

    // Split/aggregate:
    const meanPerSecond = (value, timestamp) =>
      value / (Math.min(intervalMs, Math.abs(end - timestamp)) / 1000)
    const makeAggregate = (counterName, convertValue) => {
      const sum = results.reduce((out, segment) => out + segment[counterName], 0)
      return {
        total: sum,
        mean: Math.round(sum / (end - start) * 1000),
        timeSeries: results.map(segment => ({
          timestamp: segment.timestamp,
          value: convertValue ? convertValue(segment[counterName], segment.timestamp) : segment[counterName]
        }))
      }
    }
    data.bytesSeen = makeAggregate('bytesCaptured', meanPerSecond)
    data.bytesCaptured = makeAggregate('bytesCompressedSentPcap', meanPerSecond)
    data.netflowsCaptured = makeAggregate('netflowCreate')
    data.netflowsSeen = makeAggregate('netflowNew')
    data.loss = makeAggregate('bytesDropped', meanPerSecond)
    results.forEach(segment => {
      segment.truncation = Math.max(0, segment.bytesDiscardedBySensor - segment.bytesOptimized)
    })
    data.truncation = makeAggregate('truncation', meanPerSecond)
  }

  async function loadMeanables() {
    const counters = sensor.isVersion2
      ? {
        'alpha_totalHealth': 'totalHealth',
        'alpha_captureHealth': 'captureHealth',
        'alpha_intakeHealth': 'intakeHealth',
        'alpha_performanceHealth': 'performanceHealth',
        'alpha_processingHealth': 'processingHealth',
      }
      : {
        'memoryBackbufferUsage': 'backbuffer' //TODO use 'max' aggregation instead of 'mean' once API supports it?
      }

    let results = await requestGet(
      null,
      `counters?${querystringUtil.stringify({
        sensorId,
        start,
        end,
        interval: intervalString,
        counterType: Object.keys(counters).join(','),
        aggregate: 'mean',
        stackBy: 'type'
      })}`
    )

    // Trim off trailing intervals
    results = results.filter(segment => segment.timestamp < end)

    if (sensor.isVersion2) {
      _.forOwn(counters, (propName, counterName) => {
        data[propName] = {
          timeSeries: results.map(d => ({
            timestamp: d.timestamp,
            value: d[counterName] == null ? null : Math.min(100, Math.floor(d[counterName] / 100)) //clamp to 0-100%
          }))
        }
      })
    } else {
      data.backbuffer = {
        mean: Math.round(results.reduce((out, {value}) => out + value, 0) / results.length), //NOTE: fix if we switch to aggregate:'max'
        timeSeries: results.map(({timestamp, memoryBackbufferUsage}) => ({
          timestamp,
          value: Math.min(100, Math.floor(memoryBackbufferUsage)) //clamp to 0-100%
        }))
      }
    }
  }

  await Promise.all([loadSummables(), loadMeanables()])

  return data
}




const EMPTY_STATE = {
  isOpen: false,
  openerId: null,
  sensorId: null,
  timeframe: 'day',
  graphType: 'bandwidth',
  isLoading: true,
  data: null
}

let _state = _.clone(EMPTY_STATE)


export default Reflux.createStore({
  listenables: SensorGraphsActions,

  getInitialState() {
    return _state
  },

  _notify() {
    this.trigger(_state)
  },

  async onLoad(sensorId, timeframe, graphType, openerId) {
    _state.isOpen = true
    _state.graphType = graphType || _state.graphType
    _state.openerId = openerId || _state.openerId

    // If sensorId or timeframe is changing, we need to (re)load data
    if (sensorId !== _state.sensorId || timeframe !== _state.timeframe) {
      _state.isLoading = true
      _state.sensorId = sensorId
      _state.timeframe = timeframe
      this._notify()
      try {
        const result = await loadSensorGraphsData(sensorId, timeframe, 200)
        _state.data = _.mapValues(result, d => d.timeSeries)
      } catch(restError) {
        _state.error = restError
      }
      _state.isLoading = false
    }

    this._notify()
  },

  onClose() {
    _state = _.clone(EMPTY_STATE)
    this._notify()
  }
})

import _ from 'lodash'
import Reflux from 'reflux'
import IntelManagementActions from 'actions/IntelManagementActions'
import CommonViewActions from 'actions/CommonViewActions'
import { pluralize } from 'pw-formatters'
import urlHistoryUtil from 'utils/urlHistoryUtil'
import genericUtil from 'ui-base/src/util/genericUtil'
import IntelListStore from './IntelListStore'
import PreferencesStore from 'stores/PreferencesStore'
import AnalyticsActions from 'actions/AnalyticsActions'
import {
  convertRulesAPItoUI,
  // convertRuleAPItoUI,
  convertRuleSearchAPItoUI,
  convertRulesSearchAPItoUI,
  convertRuleUItoAPI,
  convertRulesUItoAPI,
  bumpRevisionForRules,
  getGlobalUniqueRuleKey,
  parseGlobalUniqueRuleKey,
  convertThreatMappingUItoAPI,
  getQueryParamsFromRuleAPIKey,
  collapseIntelRuleHistoryRecords,
  validateRuleType
} from 'utils/intelManagementUtils'
import {
  // THREAT_TEAM_CID,
  ALLOW_QUERY_FOR_UNKNOWN_FIELDS,
  DEFAULT_SORT_DIR_BY_TYPE,
  DEFAULT_SORT_DIR,
  DEFAULT_SORT_KEY_BY_TYPE,
  DEFAULT_SORT_KEY,
  DEFAULT_UI_QUERY,
  IMS_KILLCHAIN_STAGES_THREAT,
  IMS_KILLCHAIN_STAGES,
  IMS_KILLCHAIN_STAGES_TO_NAMES,
  IMS_REV_MANAGEMENT_PREFERENCE_KEY,
  IMS_THREAT_CATEGORIES,
  IMS_THREAT_CATEGORIES_TO_NAMES,
  INTEL_BEHAVIOR_OPTIONS,
  INTEL_DEFAULT_TARGET_MODE,
  INTEL_EDIT_MODES,
  INTEL_MODES,
  INTEL_MOVE_DIALOG_MODES,
  INTEL_RULE_DETAIL_HISTORY_PAGE_SIZE,
  INTEL_STATES,
  INTEL_STATE_OPTIONS,
  INTEL_TYPE_DEFAULT,
  INTEL_TYPE_OPTIONS,
  INTEL_TYPES,
  HASH_TYPES,
  CERT_HASH_TYPES,
  BULK_RULE_EDIT_SAVE_WARN_THRESHOLD,
  INTEL_RULE_SAVE_MINUTES,
  FILE_TYPE_OPTIONS
} from 'constants/intelManagementConstants'
import {
  convertQueryJsToLucene,
  parseQueryString,
  getQueryStats,
  convertQuerySchemaToEditorSchema,
  QUERY_INPUT_TYPE_NAMES
} from 'pw-query'
import { getIntRangeValidator } from 'pw-validators'
import { requestGet, requestPost } from 'utils/restUtils'
import { listenToStore } from 'utils/storeUtils'
import getConfig from 'utils/uiConfig'

const POLL_INTERVAL = 60000 * 3
const PENDING_POLL_INTERVAL = 15000
const DETAIL_PENDING_POLL_INTERVAL = 1000
const POLL_USES_WEBSOCKET = true
const MAX_RULES_PER_REQUEST = 499

const MAIN_REQ_ID = 'ims_search_rules'

// const FILTER_KC_METHODOLOGY = true
const baseNeedsMappingsQuery = `(validations.validationName:"threat-mapping" AND validations.validationStatus:Invalid) AND NOT status:Disabled AND NOT ruleBehavior:Whitelist`
const _statusQueries = {
  pending: `status:Pending`,
  invalid: `status:Invalid`,
  capped: `status:Capped`,
  total: '',
  needsMapping: baseNeedsMappingsQuery,
  needsMappingCurrentSearch: baseNeedsMappingsQuery
  // needsMapping: `(OR NOT threatMapping.confidence:[0 TO 100] OR NOT threatMapping.severity:[0 TO 100] OR NOT threatMapping.category`, // CIA prevends thretaMappings from being set unless they are al valid, so we just need to check a single value
  // needsMapping: `(OR NOT ` + _.map([
  //   `killchainStage:(${ IMS_KILLCHAIN_STAGES.join(' OR ') })`,
  //   `category(${ IMS_THREAT_CATEGORIES.join(' OR ') })`,
  //   'severity:[0 TO 100]',
  //   'confidence:[0 TO 100]',
  // ], f => 'threatMapping.' + f).join(' OR NOT ') + `)` // FIXME doesn't work for partials!
  // needsMapping: `(NOT ` + _.map([
  //   `killchainStage`,
  //   `category`,
  //   'severity',
  //   'confidence',
  // ], f => 'threatMapping.' + f + `:[* TO *]`).join(' OR NOT ') + ')' // TODO update to query in `rule.validations`
}

const NEEDS_MAPPINGS_UI_QUERY_KEY = 'needsThreatMappings'
const QUERY_REPLACERS = [
  {
    regex: new RegExp(`${NEEDS_MAPPINGS_UI_QUERY_KEY}:true`, 'gi'),
    replacement: `(validations.validationName:"threat-mapping" AND validations.validationStatus:Invalid) AND NOT status:Disabled`
  },
  {
    regex: new RegExp(`${NEEDS_MAPPINGS_UI_QUERY_KEY}:false`, 'gi'),
    replacement: `(status:Pending OR status:Enabled OR status:Disabled OR status:Capped OR (status:Invalid AND threatMapping.severity:[* TO *] AND threatMapping.confidence:[* TO *] AND threatMapping.killChainStage:[* TO *] AND threatMapping.category:[* TO *]))`
  }
]

function generateNoModePartialUIKey(rule) {
  return `${rule.listId}${rule.ruleType}${rule.key}`
}

function _validateSortBy(sortBy, ruleType) {
  // TODO validate based on loaded schema
  if (!sortBy) {
    return DEFAULT_SORT_KEY_BY_TYPE[ruleType]
  }
  return sortBy
}

function _validateSortDir(sortDir, ruleType) {
  // TODO validate based on loaded schema
  if (!sortDir || (sortDir !== 'asc' && sortDir !== 'desc')) {
    return DEFAULT_SORT_DIR_BY_TYPE[ruleType]
  }
  return sortDir
}

const oneToOneHundred = getIntRangeValidator(1, 100)

function _getResetTypeCounts() {
  return _.reduce(
    INTEL_TYPE_OPTIONS,
    (out, idLower) => {
      out[idLower] = _.mapValues(_statusQueries, () => 0)
      return out
    },
    {}
  )
}

const INTEL_QUERY_SCHEMA = [
  {
    fieldName: NEEDS_MAPPINGS_UI_QUERY_KEY,
    label: NEEDS_MAPPINGS_UI_QUERY_KEY,
    tooltip: 'Needs Threat Mappings',
    type: QUERY_INPUT_TYPE_NAMES.ENUM,
    options: ['true', 'false']
  },
  // {
  //   fieldName: 'ruleType',
  //   // className: ''
  //   label: "type",
  //   tooltip: "Rule Type",
  //   type: QUERY_INPUT_TYPE_NAMES.ENUM,
  //   options:_.map(INTEL_TYPES, o => o.id)
  // },
  {
    fieldName: 'mode',
    label: 'mode',
    // className: ''
    tooltip: 'Intel Mode',
    type: QUERY_INPUT_TYPE_NAMES.ENUM,
    options: _.mapValues(INTEL_MODES)
  },
  {
    fieldName: 'key',
    label: 'key',
    // className: ''
    tooltip: 'Intel Key',
    type: QUERY_INPUT_TYPE_NAMES.STRING
  },
  // {
  //   fieldName: 'targetMode',
  //   label: 'targetMode',
  //   // className: ''
  //   tooltip: "Rule Target Mode (Mode set after validation)",
  //   type: QUERY_INPUT_TYPE_NAMES.ENUM,
  //   options: _.map(INTEL_MODES, o => o.id)
  // },
  {
    fieldName: 'status',
    // className: ''
    label: 'status',
    tooltip: 'Intel Status',
    type: QUERY_INPUT_TYPE_NAMES.ENUM,
    options: _.map(INTEL_STATES, o => o.id)
  },
  {
    fieldName: 'description',
    label: 'description',
    tooltip: 'Intel Description',
    type: QUERY_INPUT_TYPE_NAMES.STRING
  },
  {
    fieldName: 'tags',
    label: 'tags',
    tooltip: 'Intel Tags',
    type: QUERY_INPUT_TYPE_NAMES.ENUM,
    options: [],
    validate: _.noop // No enum validation for this
  },
  {
    fieldName: 'vendor',
    label: 'vendor',
    tooltip: 'Intel Vendor',
    type: QUERY_INPUT_TYPE_NAMES.STRING
  },
  {
    fieldName: 'uploadId',
    label: 'uploadId',
    tooltip: 'Intel Upload ID',
    type: QUERY_INPUT_TYPE_NAMES.STRING
  },
  {
    fieldName: 'ruleBehavior',
    // className: ''
    label: 'behavior',
    tooltip: 'Intel Behavior',
    type: QUERY_INPUT_TYPE_NAMES.ENUM,
    options: _.map(INTEL_BEHAVIOR_OPTIONS, o => o.id)
  },

  {
    fieldName: 'created',
    label: 'createdAt',
    tooltip: "Date/Time Rule was created in Intel Management",
    type: QUERY_INPUT_TYPE_NAMES.DATETIME,
  },
  {
    fieldName: 'updated',
    label: 'updatedAt',
    tooltip: "Date/Time Rule was updated in Intel Management",
    type: QUERY_INPUT_TYPE_NAMES.DATETIME,
  },
  {
    fieldName: 'intelCreatedAt',
    label: 'intelCreatedAt',
    tooltip: "Date/Time Rule was created originally (possibly pulled from external intel source).",
    type: QUERY_INPUT_TYPE_NAMES.DATETIME,
  },
  {
    fieldName: 'intelUpdatedAt',
    label: 'intelUpdatedAt',
    tooltip: "Date/Time Rule was updated originally (possibly pulled from external intel source).",
    type: QUERY_INPUT_TYPE_NAMES.DATETIME,
  },
  {
    fieldName: 'intelObservedAt',
    label: 'intelObservedAt',
    tooltip: "Date/Time Rule was observed originally (possibly pulled from external intel source).",
    type: QUERY_INPUT_TYPE_NAMES.DATETIME,
  },

  // {
  //   fieldName: 'validations',
  //   label: 'validation',
  //   tooltip: "Rule Validation messages",
  //   type: QUERY_INPUT_TYPE_NAMES.STRING,
  // },

  // ThreatMapping
  {
    fieldName: 'threatMapping.severity',
    // className: ''
    label: 'severity',
    tooltip: 'Threat Mapping : Severity',
    type: QUERY_INPUT_TYPE_NAMES.INT,
    validate: oneToOneHundred
  },
  {
    fieldName: 'threatMapping.confidence',
    // className: ''
    label: 'confidence',
    tooltip: 'Threat Mapping : Confidence',
    type: QUERY_INPUT_TYPE_NAMES.INT,
    validate: oneToOneHundred
  },
  {
    fieldName: 'threatMapping.killChainStage',
    // className: ''
    label: 'killChainStage',
    tooltip: 'Threat Mapping : Killchain Stage',
    type: QUERY_INPUT_TYPE_NAMES.ENUM,
    options: IMS_KILLCHAIN_STAGES.map(kcStage => ({
      id: kcStage,
      name: IMS_KILLCHAIN_STAGES_TO_NAMES[kcStage]
    }))
  },
  {
    fieldName: 'threatMapping.category',
    // className: ''
    label: 'category',
    tooltip: 'Threat Mapping : Category',
    type: QUERY_INPUT_TYPE_NAMES.ENUM,
    options: IMS_THREAT_CATEGORIES.map(category => ({
      id: category,
      name: IMS_THREAT_CATEGORIES_TO_NAMES[category]
    }))
  },
  // {
  //   fieldName: 'threatMapping.threatSubCategory',
  //   label: "subCategory",
  //   tooltip: "Threat Mapping : Sub Category",
  //   type: QUERY_INPUT_TYPE_NAMES.ENUM,
  //   options: INTEL_THREAT_SUB_CATEGORIES
  // },

  // IDS-specific
  {
    fieldName: 'ids.rule',
    label: 'rule',
    tooltip: 'IDS Rule body',
    type: QUERY_INPUT_TYPE_NAMES.STRING
  },
  {
    fieldName: 'ids.sid',
    label: 'sid',
    tooltip: 'IDS Rule Signature ID',
    type: QUERY_INPUT_TYPE_NAMES.INT,
    validate: getIntRangeValidator(1, 4294967295)
  },
  {
    fieldName: 'ids.gid',
    label: 'gid',
    tooltip: 'IDS Rule Group ID',
    type: QUERY_INPUT_TYPE_NAMES.INT,
    validate: getIntRangeValidator(1, 4294967295)
  },
  {
    fieldName: 'ids.msg',
    tooltip: 'IDS Rule Message',
    label: 'msg',
    type: QUERY_INPUT_TYPE_NAMES.STRING
  },
  {
    fieldName: 'ids.classtype',
    tooltip: 'IDS Rule Class Type',
    label: 'classtype',
    type: QUERY_INPUT_TYPE_NAMES.STRING
  },
  {
    fieldName: 'ids.references',
    tooltip: 'IDS Rule Reference List',
    label: 'references',
    type: QUERY_INPUT_TYPE_NAMES.STRING
  },

  // Domain-specific
  {
    fieldName: 'domain.domain_name',
    label: 'domain',
    tooltip: 'Domain Name',
    type: QUERY_INPUT_TYPE_NAMES.STRING
  },
  {
    fieldName: 'domain.references',
    tooltip: 'Domain Reference List',
    label: 'domainReferences',
    type: QUERY_INPUT_TYPE_NAMES.STRING
  },

  // IP-specific
  {
    fieldName: 'ip.ip',
    label: 'ip',
    tooltip: 'IP Address',
    type: QUERY_INPUT_TYPE_NAMES.STRING
  },

  // URL/URI-specific
  {
    fieldName: 'uri.uri_name',
    label: 'uri',
    tooltip: 'URI / URL',
    type: QUERY_INPUT_TYPE_NAMES.STRING
  },
  {
    fieldName: 'uri.references',
    tooltip: 'URI / URL Reference List',
    label: 'uriReferences',
    type: QUERY_INPUT_TYPE_NAMES.STRING
  },

  // FileHash-specific
  {
    fieldName: 'file.file_hash',
    label: 'fileHash',
    tooltip: 'File Hash',
    type: QUERY_INPUT_TYPE_NAMES.STRING
  },
  {
    fieldName: 'file.file_hash_type',
    label: 'fileHashType',
    tooltip: 'File Hash Type',
    type: QUERY_INPUT_TYPE_NAMES.ENUM,
    options: _.map(HASH_TYPES, t => t.id)
  },
  {
    fieldName: 'file.references',
    tooltip: 'File Hash Reference List',
    label: 'fileHashReferences',
    type: QUERY_INPUT_TYPE_NAMES.STRING
  },
  {
    fieldName: 'file.file_name',
    label: 'fileName',
    tooltip: 'File Name',
    type: QUERY_INPUT_TYPE_NAMES.STRING
  },
  {
    fieldName: 'file.file_type',
    label: 'fileType',
    tooltip: 'File Type',
    type: QUERY_INPUT_TYPE_NAMES.ENUM,
    options: FILE_TYPE_OPTIONS
  },
  {
    fieldName: 'file.file_intel_name',
    label: 'fileIntelName',
    tooltip: 'File Intel Name',
    type: QUERY_INPUT_TYPE_NAMES.STRING
  },


  // Certificate-specific
  {
    fieldName: 'cert.cert_hash',
    label: 'certHash',
    tooltip: 'Cert Hash',
    type: QUERY_INPUT_TYPE_NAMES.STRING
  },
  {
    fieldName: 'cert.cert_hash_type',
    label: 'certHashType',
    tooltip: 'Cert Hash Type',
    type: QUERY_INPUT_TYPE_NAMES.ENUM,
    options: _.map(CERT_HASH_TYPES, t => t.id)
  },
  {
    fieldName: 'cert.references',
    tooltip: 'Certificate Reference List',
    label: 'certReferences',
    type: QUERY_INPUT_TYPE_NAMES.STRING
  },
  {
    fieldName: 'cert.cert_issued',
    label: 'certIssued',
    tooltip: 'Cert Issued (Timestamp)',
    type: QUERY_INPUT_TYPE_NAMES.DATETIME
  },
  {
    fieldName: 'cert.cert_expires',
    label: 'certExpires',
    tooltip: 'Cert Expires (Timestamp)',
    type: QUERY_INPUT_TYPE_NAMES.DATETIME
  },
  {
    fieldName: 'cert.cert_ssl_version',
    label: 'certSslVersion',
    tooltip: 'Cert SSL Version',
    type: QUERY_INPUT_TYPE_NAMES.STRING
  },
  {
    fieldName: 'cert.cert_subject',
    label: 'certSubject',
    tooltip: 'Cert Subject',
    type: QUERY_INPUT_TYPE_NAMES.STRING
  },
  {
    fieldName: 'cert.cert_issuer',
    label: 'certIssuer',
    tooltip: 'Cert Issuer',
    type: QUERY_INPUT_TYPE_NAMES.STRING
  },
  {
    fieldName: 'cert.cert_subject_common_name',
    label: 'certSubjectCommonName',
    tooltip: 'Cert Subject Common Name',
    type: QUERY_INPUT_TYPE_NAMES.STRING
  },
  {
    fieldName: 'cert.cert_issuer_common_name',
    label: 'certIssuerCommonName',
    tooltip: 'Cert Issuer Common Name',
    type: QUERY_INPUT_TYPE_NAMES.STRING
  },
  {
    fieldName: 'cert.cert_alt_names',
    label: 'certAltNames',
    tooltip: 'Cert Alternate Names',
    type: QUERY_INPUT_TYPE_NAMES.STRING
  },
  {
    fieldName: 'cert.cert_name',
    label: 'certName',
    tooltip: 'Cert Name',
    type: QUERY_INPUT_TYPE_NAMES.STRING
  },
  {
    fieldName: 'cert.cert_serial_number',
    label: 'certSerialNumber',
    tooltip: 'Cert Serial Number',
    type: QUERY_INPUT_TYPE_NAMES.STRING
  },

  // Yara
  {
    fieldName: 'yara.yara_name',
    label: 'yaraName',
    tooltip: 'Yara Rule Name',
    type: QUERY_INPUT_TYPE_NAMES.STRING
  },
  {
    fieldName: 'yara.yara_metadata',
    label: 'yaraMetadata',
    tooltip: 'Yara Metadata',
    type: QUERY_INPUT_TYPE_NAMES.STRING
  },

]

const PAGE_SIZE = 50

const DEFAULT_STATE = {
  isLoadingRules: false,
  isLoadingRulesNextPage: false,
  nextPageAvailable: false,
  intelRulesError: null,
  currentRuleFacets: {},
  intelRules: [],
  intelRuleCount: 0,
  sortKey: DEFAULT_SORT_KEY,
  sortDir: DEFAULT_SORT_DIR,
  selectedRuleUIKeys: [],
  selectedRules: [],
  allRulesSelected: false,

  pageIndex: 0, // Track pagination
  pageSize: PAGE_SIZE,

  ruleInputActive: false,
  isSavingRules: false,
  isSavingNewRules: false,
  ruleSaveError: null,

  currentQuery: '',
  currentQueryUI: DEFAULT_UI_QUERY,
  currentListIds: [],

  moveRulesDialogMode: INTEL_MOVE_DIALOG_MODES.CLOSED, // (copy|move|closed)
  moveRuleUIKeys: [],

  detailIsLoading: false,
  detailKey: '',
  detailRule: null,
  detailError: null,

  querySchema: null,
  schemaError: null,
  isLoadingSchema: false,

  batchUploadProgress: 0,
  isSavingRulesBatched: false,

  counts: _getResetTypeCounts(),
  // countsLoading:
  editMode: null,

  allTags: [], // All used tags

  detailHistoryLoading: false,
  detailHistoryLoadingNextPage: false,
  detailHistory: null,
  detailHistoryError: null,
  detailHistoryCount: 0,

  detailHistoryUploadId: null, // UploadID of URL-selected detail history entry uploadId

  activeRuleType: INTEL_TYPE_DEFAULT
}

let _state = _.cloneDeep(DEFAULT_STATE)

function _isNextPageAvailable() {
  return _state.pageSize * (_state.pageIndex + 1) <= _state.intelRuleCount
}

let _queuedSearchString = null

export default Reflux.createStore({
  listenables: [IntelManagementActions],

  init() {
    this._listDataCache = {} // Lists by ID
    this._latestTagNames = []
    this._enableAutoRevIncrementing = true
    listenToStore(this, IntelListStore, this._onListStoreUpdate)
    listenToStore(this, PreferencesStore, this._onPreferencesStoreUpdate)
  },

  getInitialState() {
    return _state
  },

  _attachListDataToRule(rule) {
    // Note that the selectedRuleUIKeys collection's meaning is inverted if allRulesSelected is true
    rule._isInSelectionSet = _state.selectedRuleUIKeys.indexOf(rule._uiKey) > -1

    const ruleList = this._listDataCache[rule.listId]
    if (ruleList) {
      rule._isUserOwned = ruleList._isUserList
      rule._isEditableByCustomer = ruleList._isEditableByCustomer
      rule._listName = ruleList.name
      rule._listColor = ruleList._color
    } else {
      rule._isUserOwned = false
      rule._isEditableByCustomer = false
      rule._listName = null
      rule._listColor = null
    }
    return rule
  },

  _notify() {
    _state.intelRules = _.map(_state.intelRules, this._attachListDataToRule)

    if (_state.detailRule) {
      _state.detailRule = this._attachListDataToRule(_state.detailRule)
      _state.detailHistory = _.map(
        _state.detailHistory,
        this._attachListDataToRule
      )
    }

    // _state.selectedRules = _state.allRulesSelected ? _state.intelRules : this._getRulesByUIKeys(_state.selectedRuleUIKeys)
    _state.selectedRules = this._getRulesByUIKeys(_state.selectedRuleUIKeys)
    this.trigger(_state)
  },

  _queueNotify() {
    clearTimeout(this._queueNotifyTimer)
    this._queueNotifyTimer = setTimeout(this._notify.bind(this), 100)
  },

  _onListStoreUpdate(listStoreState) {
    const listStoreIsLoading =
      listStoreState.isLoading || listStoreState.savingListIds.length > 0
    if (!listStoreIsLoading && this._listStoreWasLoading) {
      this._queueNotify()
    }
    const editableListIds = []
    this._listDataCache = _.reduce(
      listStoreState.lists,
      (out, list) => {
        out[list.id] = list
        if (list._isEditableByCustomer) {
          editableListIds.push(list.id)
        }
        return out
      },
      {}
    )
    this._listStoreWasLoading = listStoreIsLoading
    if (editableListIds && editableListIds.length > 0) {
      // Update needsMappings query to only reflect editable rules
      _statusQueries.needsMapping = _statusQueries.needsMappingCurrentSearch =
        baseNeedsMappingsQuery +
        ` AND listId:("${editableListIds.join('" OR "')}")`
    }
  },

  _onPreferencesStoreUpdate({ preferences }) {
    this._enableAutoRevIncrementing =
      _.get(preferences, IMS_REV_MANAGEMENT_PREFERENCE_KEY, 'enabled') ===
      'enabled'
  },

  _mergeData(flattenedData) {
    const merged = _state.intelRules
    for (var i = 0, iLen = flattenedData.length; i < iLen; i++) {
      const newItem = flattenedData[i]
      const currIdx = _.findIndex(merged, { _uiKey: newItem._uiKey })
      if (currIdx === -1) {
        merged.push(newItem) // FIXME Will fall out of sort order :/
      } else {
        merged[currIdx] = _.assign(merged[currIdx], newItem)
      }
    }
    _state.intelRules = merged
  },

  _addData(data) {
    const flattenedData = convertRulesSearchAPItoUI(data)
    _state.intelRules = _.uniq(
      _state.intelRules.concat(flattenedData),
      '_uiKey'
    )
  },

  _addRuleHistory(history) {
    // const mergedHistory = _.reduce((_state.detailHistory || []).concat(convertRulesSearchAPItoUI(history)), (out, historyRule) => {
    //   if (out[historyRule.uploadId]) {
    //     // Use first human userId associated with this uploadId
    //     out[historyRule.uploadId].userId = historyRule.userId || out[historyRule.uploadId].userId
    //     // Use first non-pending (final) status if available
    //     out[historyRule.uploadId].status = historyRule.status === INTEL_STATES.PENDING ? out[historyRule.uploadId].status : historyRule.status
    //     out[historyRule.uploadId].validations = _.isEmpty(historyRule.validations) ? out[historyRule.uploadId].validations : historyRule.validations
    //   }
    //   else {
    //     out[historyRule.uploadId] = historyRule
    //   }
    //   return out
    // }, {})

    const mergedHistory = collapseIntelRuleHistoryRecords(
      (_state.detailHistory || []).concat(convertRulesSearchAPItoUI(history))
    )

    _state.detailHistory = _.sortByOrder(
      _.mapValues(mergedHistory),
      ['updated'],
      ['desc']
    )
  },

  _getRulesByUIKeys(ruleUIKeys) {
    return _.reduce(
      ruleUIKeys,
      (output, ruleUIKey) => {
        const rule = _.find(_state.intelRules, { _uiKey: ruleUIKey })
        if (rule) {
          output.push(_.assign({}, rule))
        } else if (
          _state.detailRule &&
          _state.detailRule._uiKey &&
          _state.detailRule._uiKey === ruleUIKey
        ) {
          // Support for copying a linked-in detail rule that may not be visible in query results
          output.push(_.assign({}, _state.detailRule))
        } else {
          console.warn(
            'Unable to find Intel. Not found or does not belong to current listId',
            ruleUIKey
          )
        }
        return output
      },
      []
    )
  },

  _handleSaveFail(restError) {
    _state.isSavingNewRules = false
    _state.isSavingRules = false
    _state.moveRulesDialogMode = INTEL_MOVE_DIALOG_MODES.CLOSED
    _state.ruleSaveError = restError
    this._addRestErrorNotification(_state.ruleSaveError, 'Error Saving Intel')
    this._queueNotify()
  },

  _addRestErrorNotification(restError, heading = 'Error') {
    const _notification = {
      type: 'error',
      heading: heading,
      message: restError.heading + ': ' + restError.body,
      traceId: restError.traceId
    }
    if (_.get(restError, 'additional.length', 0) > 0) {
      _notification.messageDetails = restError.additional.join('\n')
    }
    const limitCtIdx = (_notification.messageDetails || '')
      .indexOf('max amount of rules allowed for a customer ')
    if (limitCtIdx !== -1) {
      // Customer hit the rule count limit
      const parsedLimit = parseInt(
        _notification.messageDetails.slice(limitCtIdx).trim()
      )
      const limitCt = isNaN(parsedLimit) ? 30000 : parsedLimit
      _notification.heading = `Intel Limit Reached`
      _notification.message = `Unable save Intel. Limit of ${limitCt.toLocaleString()} would be exceeded.`
    }
    CommonViewActions.Notification.add(_notification)
  },

  _getRuleSaveRequest(rulesArray, listId, commonProperties, extraParams = {}) {
    const DEFAULT_PROPERTIES = {
      targetMode: INTEL_DEFAULT_TARGET_MODE
    }

    const _commonProps = _.assign({}, DEFAULT_PROPERTIES, commonProperties)

    if (_commonProps.threatMapping) {
      _commonProps.threatMapping = convertThreatMappingUItoAPI(
        _commonProps.threatMapping
      )
    }

    const _ruleType = commonProperties.ruleType || INTEL_TYPE_DEFAULT

    return requestPost(
      `ims_save_rules_${listId}`,
      `intel/lists/${listId}/rules/create`,
      _.assign(_commonProps, {
        // ruleType: _ruleType,
        intels: _.map(rulesArray, ruleString => {
          switch (_ruleType) {
            case INTEL_TYPE_OPTIONS.IDS:
              return { ids: { rule: ruleString.trim() } }
            case INTEL_TYPE_OPTIONS.DOMAIN:
              return { domain: { name: ruleString.trim() } }
            case INTEL_TYPE_OPTIONS.URI:
              return { uri: { name: ruleString.trim() } }
            case INTEL_TYPE_OPTIONS.IP:
              return { ip: { ip: ruleString.trim() } }
            case INTEL_TYPE_OPTIONS.FILEHASH:
              return {
                file: convertRuleUItoAPI(Object.assign({ ruleType: _ruleType }, ruleString)).intel.file
              }
            case INTEL_TYPE_OPTIONS.CERTIFICATE:
              return {
                cert: convertRuleUItoAPI(Object.assign({ ruleType: _ruleType }, ruleString)).intel.cert
              }
          }
        })
      })
    )
  },

  _getRulesRequest(
    searchString,
    reqId = MAIN_REQ_ID,
    paginationString,
    facetString,
    options = {}
  ) {
    // Sample query for threatMapping null:
    // http://staging-cia-001:8082/cia.rules/select/?q=(*:*%20AND%20vendor:%5B*%20TO%20*%5D)&noAuth=true
    // threatMapping:[* TO *]
    const {
      addTypeParam = true,
      addListParam = true,
      addTimeParam = true
    } = options
    const { sortDir, sortKey, currentListIds } = _state

    let _sortStr = `&sort=${sortKey}%20${sortDir}`

    if (sortKey === '_metaStatus') {
      _sortStr = `&sort=status%20${sortDir}&sort=key%20${sortDir}&sort=mode%20desc` // Mode desc to "nest" drafts
    } else if (sortKey === 'key') {
      _sortStr = `&sort=key%20${sortDir}&sort=mode%20desc` // Mode desc to "nest" drafts
    } else if (sortKey === 'domain.domain_name') {
      _sortStr = `&sort=domain.reversed_domain_name%20${sortDir}`
    } else if (sortKey === 'uri.uri_name') {
      _sortStr = `&sort=uri.reversed_uri_name%20${sortDir}`
    }

    let _queryParts = []

    if (addListParam && currentListIds && currentListIds.length > 0) {
      _queryParts.push(`listId:("${currentListIds.join('" OR "')}")`)
    }

    // If this is the main content query, filter by active rule type
    if (addTypeParam) {
      _queryParts.push(`ruleType:${_state.activeRuleType}`)
    }

    if (addTimeParam) {
      console.log(`~~ adding time param`, )

      // _queryParts.push(`ruleType:${_state.activeRuleType}`)
    }

    if (searchString && searchString.length > 0) {
      _queryParts = _queryParts.concat(searchString)
    }

    // if (FILTER_KC_METHODOLOGY) {
    //   _queryParts.push(`NOT threatMapping.killchainStage:Methodology`)
    // }

    const _query = _.compact(_.map(_queryParts, p => p.trim())).join(' AND ')
    const _paginationStr =
      paginationString ||
      `&rows=${_state.pageSize}&start=${_state.pageIndex * _state.pageSize}`
    const _encodedQuery = encodeURIComponent(_query)

    return requestGet(
      reqId,
      `intel/lists/rules?${!_encodedQuery
        ? ''
        : 'q=' + _encodedQuery}${_sortStr}${_paginationStr}${facetString ||
        ''}`,
      {
        useSocket: POLL_USES_WEBSOCKET && reqId !== MAIN_REQ_ID // polling reqs use socket
      }
    )
  },

  _getLuceneConverterSchema() {
    const { querySchema } = _state

    if (!this._luceneConverterSchema && querySchema) {
      this._luceneConverterSchema = _.reduce(
        querySchema,
        (out, schemaPart) => {
          // Any QUERY_TYPES that could contain a string should be passed here
          if (schemaPart.type === QUERY_INPUT_TYPE_NAMES.STRING || schemaPart.type === QUERY_INPUT_TYPE_NAMES.ENUM) {
            out[schemaPart.fieldName] = {
              type: 'string',
              allowSpecialCharacters: true // Needed to avoid "/"blah"/" double escaping of quotes
            }
          }
          return out
        },
        {}
      )
    }

    return this._luceneConverterSchema || {}
  },

  _loadRules(searchString) {
    if (!_state.querySchema || _state.isLoadingSchema) {
      _queuedSearchString = searchString
      return
    }
    if (!searchString) {
      searchString = _state.currentQueryUI
    }

    _state.moveRulesDialogMode = INTEL_MOVE_DIALOG_MODES.CLOSED
    _state.intelRules = []
    _state.intelRulesError = null
    _state.isLoadingRules = true
    _state.isLoadingRulesNextPage = false
    _state.nextPageAvailable = false
    _state.pageIndex = 0
    _state.intelRuleCount = 0
    _state.allRulesSelected = false
    _state.selectedRuleUIKeys = [] // Reset selection
    _state.currentRuleFacets = {}
    _state.editMode = null

    const luceneConverterSchema = this._getLuceneConverterSchema()
    let luceneQuery = ''
    const parsedQuery = parseQueryString(searchString, _state.querySchema, {
      allowUnknownFields: ALLOW_QUERY_FOR_UNKNOWN_FIELDS,
      luceneConverterSchema: luceneConverterSchema
    })
    if (!parsedQuery.IDEStateData || parsedQuery.IDEStateData.invalid) {
      console.warn(
        'convertQuerySyntaxToLucene invalid pwQuery or parsing failed'
      )
      luceneQuery = searchString
    } else {
      luceneQuery = convertQueryJsToLucene(
        parsedQuery.IDEStateData.queryState,
        luceneConverterSchema || {}
      )
    }

    // UI to API query translations
    for (let i = 0, iLen = QUERY_REPLACERS.length; i < iLen; i++) {
      const replacer = QUERY_REPLACERS[i]
      luceneQuery = luceneQuery.replace(replacer.regex, replacer.replacement)
    }
    _state.currentQuery = luceneQuery
    this._queueNotify()

    const MAIN_FACET_KEY = `status,mode`

    // Main rules request
    const req = this._getRulesRequest(
      _state.currentQuery,
      MAIN_REQ_ID,
      null, // allow default pagination
      `&facetField=${MAIN_FACET_KEY}`,
    )

    req.then(data => {
      _state.intelRuleCount = data.total
      _state.isLoadingRules = false
      _state.nextPageAvailable = _isNextPageAvailable()

      _state.currentRuleFacets = Object.assign(
        _.get(data, `facetCounts.facetFields.status`, {}),
        _.get(data, `facetCounts.facetFields.mode`, {}),
      )

      this._checkRuleCounts()
      this._addData(data.rules)
      this._queueNotify()
      if (this._searchRulesCompleteCallback) {
        this._searchRulesCompleteCallback()
        this._searchRulesCompleteCallback = null
      }
      AnalyticsActions.event({
        eventCategory: 'intel',
        eventAction: 'rule_search_status',
        eventLabel: 'success',
        nonInteraction: true
      })
    },
      restError => {
        _state.isLoadingRules = false
        _state.intelRulesError = restError
        this._queueNotify()
        AnalyticsActions.event({
          eventCategory: 'intel',
          eventAction: 'rule_search_status',
          eventLabel: 'failure',
          nonInteraction: true
        })
      })
    if (searchString.length > 0) {
      const { depth, fieldNames } = getQueryStats(
        parsedQuery.IDEStateData.queryState || []
      )
      AnalyticsActions.event({
        eventCategory: 'intel',
        eventAction: 'rule_search_custom',
        eventLabel: fieldNames.join(', '),
        eventValue: depth
      })
    }
  },

  _loadRuleDetail(detailUIKey) {
    _state.detailKey = detailUIKey
    _state.detailIsLoading = true
    _state.detailRule = null
    _state.detailError = null
    this._queueNotify()

    const {
      mode,
      key,
      listId,
      ruleType,
      requestKey
    } = parseGlobalUniqueRuleKey(detailUIKey)

    if ((!key && !requestKey) || !mode || !listId || !ruleType) {
      console.warn(`Invalid detail key: "${detailUIKey}"`)
      _state.detailIsLoading = false
      _state.detailKey = ''
      this._queueNotify()
      return
    }

    this._loadRuleHistory(detailUIKey)

    const req = requestGet(
      'ims_get_details',
      `intel/lists/rules?q=${encodeURIComponent(
        `ruleType:${ruleType} AND ${getQueryParamsFromRuleAPIKey(
          ruleType,
          requestKey || key
        )} AND listId:"${listId}" AND mode:${mode}`
      )}&start=0`
    )

    req.then(data => {
      // FIXME support multiple rules (same key, different modes) e.g. draft/published
      if (data.rules.length === 0) {
        _state.detailIsLoading = false
        _state.detailError = {
          soft404: true,
          heading: 'Error',
          body:
            'Unable to find the specified Intel. It may have been moved or deleted.'
        }
        this._queueNotify()
      } else {
        if (data.rules.length > 1) {
          console.warn(
            '_loadRuleDetail: Search returned more than one result for rule'
          )
        }
        this._setDetailRule(convertRuleSearchAPItoUI(data.rules[0]))
      }
    }, restError => {
      _state.detailIsLoading = false
      _state.detailError = restError
      this._queueNotify()
    })

    const detailList = this._listDataCache[listId]
    AnalyticsActions.event({
      eventCategory: 'intel',
      eventAction: 'view_rule_detail',
      eventLabel: detailList
        ? detailList._isEditableByCustomer
          ? 'customer_rule'
          : 'protectwise_rule'
        : 'unknown_rule'
    })
  },

  _maybePollPendingDetail() {
    clearTimeout(this._detailPendingPoll)
    if (_state.detailRule.status === INTEL_STATE_OPTIONS.PENDING) {
      this._startDetailPoll()
    }
  },

  _startDetailPoll() {
    clearTimeout(this._detailPendingPoll)
    if (!_state.detailKey) {
      return
    }
    this._detailPendingPoll = setTimeout(() => {
      if (!_state.detailKey) {
        return
      }
      const {
        mode,
        key,
        listId,
        ruleType,
        requestKey
      } = parseGlobalUniqueRuleKey(_state.detailKey)

      requestGet(
        'ims_get_details',
        `intel/lists/rules?q=${encodeURIComponent(
          `ruleType:${ruleType} AND ${getQueryParamsFromRuleAPIKey(
            ruleType,
            requestKey || key
          )} AND listId:"${listId}" AND mode:${mode}`
        )}&start=0`
      ).then(
        data => {
          const newDetailRule = convertRuleSearchAPItoUI(data.rules[0])
          if (newDetailRule && newDetailRule.status === INTEL_STATE_OPTIONS.PENDING) {
            // Keep polling
            this._startDetailPoll()
          }
          else {
            // Done polling
            this._setDetailRule(newDetailRule)
          }
        },
        restError => {
          clearTimeout(this._detailPendingPoll)
          console.warn('Detail Rule Pending Poll error', restError)
        }
      )
    }, DETAIL_PENDING_POLL_INTERVAL)
  },

  _setDetailRule(newDetailRule) {
    _state.detailRule = newDetailRule
    _state.detailIsLoading = false
    this._maybePollPendingDetail()
    this._queueNotify()
    this._loadRuleHistory(_state.detailRule._uiKey)
  },

  _loadRuleHistory(detailUIKey) {
    _state.detailHistoryLoading = true
    _state.detailHistoryLoadingNextPage = false
    _state.detailHistory = null
    _state.detailHistoryCount = 0
    _state.detailHistoryError = null
    this._queueNotify()

    const {
      mode,
      key,
      listId,
      ruleType,
      requestKey
    } = parseGlobalUniqueRuleKey(detailUIKey)

    if ((!key && !requestKey) || !mode || !listId || !ruleType) {
      return
    }

    // const historySort = `&sort=updated%20desc&sort=mode%20asc` // FIXME add when this works
    const historySort = `&sort=updated%20desc` //&sort=userId&desc`
    const historyQ = encodeURIComponent(
      [
        `ruleType:${ruleType}`,
        `(${getQueryParamsFromRuleAPIKey(
          ruleType,
          requestKey || key
        )} OR key:"${key}")`,
        `listId:"${listId}"`
      ].join(' AND ')
    )
    // Do not query for `mode`, so we can get full history spanning drafts/published/profiling
    // Also omit status:Pending entries as these will always be accompanied by a
    // final status set by Mario. TODO rethink this once userId attribution is available
    this._latestHistoryQuery = `intel/lists/rules/history?q=${historyQ}${historySort}&rows=${INTEL_RULE_DETAIL_HISTORY_PAGE_SIZE}`

    const req = requestGet(
      'ims_get_rule_history',
      // `intel/lists/rules/history?q=${ encodeURIComponent(`ruleType:${ ruleType } AND key:"${ key }" AND listId:"${ listId }" AND mode:${ mode }`) }&sort=${ historySort }&start=0`
      this._latestHistoryQuery + '&start=0'
    )

    req.then(data => {
      this._addRuleHistory(data.rulesHistory)
      _state.detailHistoryCount = data.total
      _state.detailHistoryLoading = false
      this._queueNotify()
    }, restError => {
      _state.detailHistoryLoading = false
      _state.detailHistoryError = restError
      this._queueNotify()
    })
  },

  onLoadNextHistoryPage() {
    if (_state.detailHistoryLoading || _state.detailIsLoading) {
      return
    }
    const loadedCt = _state.detaillength
    const currentPageIdx =
      Math.floor(loadedCt / INTEL_RULE_DETAIL_HISTORY_PAGE_SIZE) - 1
    const maxPageIdx = Math.floor(
      _state.detailHistoryCount / INTEL_RULE_DETAIL_HISTORY_PAGE_SIZE
    )

    if (currentPageIdx < maxPageIdx) {
      _state.detailHistoryLoadingNextPage = true
      this._queueNotify()

      const req = requestGet(
        'ims_get_rule_history',
        // `intel/lists/rules/history?q=${ encodeURIComponent(`ruleType:${ ruleType } AND key:"${ key }" AND listId:"${ listId }" AND mode:${ mode }`) }&sort=${ historySort }&start=0`
        this._latestHistoryQuery +
        `&start=${(currentPageIdx + 1) * INTEL_RULE_DETAIL_HISTORY_PAGE_SIZE}`
      )

      req.then(data => {
        this._addRuleHistory(data.rulesHistory)
        _state.detailHistoryCount = data.total
        _state.detailHistoryLoadingNextPage = false
        this._queueNotify()
      }, restError => {
        _state.detailHistoryLoadingNextPage = false
        _state.detailHistoryError = restError
        this._queueNotify()
      })
    }
  },

  onRefreshSearch() {
    this._refreshCurrentSearch()
  },

  _refreshCurrentSearch() {
    this._loadRules()
  },

  _resetStatusPoll() {
    clearTimeout(this._pollReq)
    // delete this._pollReq
    const visisblePending = _.get(
      _state.counts,
      `${_state.activeRuleType}.pending`,
      0
    )
    this._pollReq = setTimeout(
      this._checkRuleCounts,
      visisblePending > 0 ? PENDING_POLL_INTERVAL : POLL_INTERVAL
    )
  },

  _getQueryWithCurrent(query, useUIQuery = false) {
    return _.compact([
      useUIQuery ? _state.currentQueryUI : _state.currentQuery,
      query
    ]).join(' AND ')
  },

  // Looping poll to get count of pending & invalid rules
  _checkRuleCounts() {
    IntelManagementActions.checkingRuleCounts() // Broadcast

    const queryPromises = _.map(_statusQueries, (query, qId) => {
      const q =
        qId === 'needsMappingCurrentSearch'
          ? this._getQueryWithCurrent(query)
          : query
      return this._getRulesRequest(
        q,
        qId,
        `&rows=${qId === 'pending' ? 50 : 0}&start=0`,
        `&facetField=ruleType`,
        {
          addTypeParam: false
          // addListParam: qId !== 'needsMapping' // "needs mappings" count must be restricted to only editable selected intel lists
        }
      )
    })

    // Get all tags used
    // const tagQueryIdx = queryPromises.length
    // queryPromises.push(
    //   requestGet(null, `intel/lists/rules?rows=0&start=0&facetField=tags`)
    // )

    Promise
      .all(queryPromises)
      .then(results => {
        // Update counts by type and status
        const orderedStatusQueryIds = _.keys(_statusQueries)

        // Reset all counts to zero
        const newCounts = _getResetTypeCounts()

        for (var i = 0; i < orderedStatusQueryIds.length; i++) {
          const statusId = orderedStatusQueryIds[i]
          const typeFacets = _.get(results[i], `facetCounts.facetFields.ruleType`, [])
          Object.keys(typeFacets).forEach(ruleType => {
            const ct = typeFacets[ruleType]
            if (!newCounts[ruleType]) {
              newCounts[ruleType] = {}
            } else {
              newCounts[ruleType][statusId] = ct
            }
          })
        }

        const currentPendingCount = _.filter(_state.intelRules, {
          status: 'Pending'
        }).length
        const visiblePendingCount = _.get(
          newCounts,
          `${_state.activeRuleType}.pending`,
          0
        )
        if (visiblePendingCount < currentPendingCount) {
          this._reloadAllPending()
        }

        // // Update polled tag values
        // const allTagNamesObj = {}
        // const allTagNames = []
        // const tagFacets = _.get(results[i], `facetCounts.facetFields.tags`, [])
        // const newTags = Object.keys(tagFacets).map(tag => {
        //   const ct = tagFacets[tag]
        //   const tagName = tag //.replace(/"/g, '') // Replace extra quotes
        //   allTagNamesObj[tagName] = tagName
        //   allTagNames.push(tagName)
        //   return {
        //     id: tagName,
        //     name: tagName,
        //     count: ct
        //   }
        // })

        // if (!_.isEqual(this._latestTagNames, allTagNames)) {
        //   // Apply latest tag options as typeahead query options.
        //   _state.querySchema = _.assign({}, _state.querySchema, {
        //     _schemaUpdatedAt: Date.now(),
        //     tags: _.assign({}, _state.querySchema.tags, {
        //       options: allTagNames,
        //       optionsToOutput: allTagNamesObj
        //     })
        //   })
        //   _state.allTags = newTags
        //   this._latestTagNames = allTagNames // Store for future poll comparisons
        // }

        _state.counts = newCounts
        this._queueNotify()
      })
      .catch(restError => {
        console.warn('Intel Rules: Polling Error', restError)
      })
      .then(() => {
        this._resetStatusPoll() // Always restart poll timer
      })
  },

  // Standalone (no poll req'd) call to update intel rule tags
  onLoadAllIntelTags() {
    requestGet(null, `intel/lists/rules?rows=0&start=0&facetField=tags`)
      .then(response => {
        // Update polled tag values
        const allTagNames = []
        const tagFacets = _.get(response, `facetCounts.facetFields.tags`, [])
        const newTags = Object.keys(tagFacets).map(tag => {
          const ct = tagFacets[tag]
          const tagName = tag //.replace(/"/g, '') // Replace extra quotes
          allTagNames.push(tagName)
          return {
            id: tagName,
            name: tagName,
            count: ct
          }
        })
        _state.allTags = newTags
        // this._latestTagNames = allTagNames // Store for future poll comparisons
        this._queueNotify()
      })
      .catch(_.noop)
  },

  _reloadAllPending() {
    const MAX_PAGE_LIMIT = 30
    const {
      // counts,
      intelRules
      // currentQuery
    } = _state

    clearTimeout(this._pollReq) // Clear poll timer while re-loading pending rules

    const pendingRuleClauses = _.reduce(
      intelRules,
      (out, rule) => {
        if (rule.status === 'Pending') {
          out.push(
            `(key:"${rule.key}" AND mode:${rule.mode} AND listId:"${rule.listId}" AND ruleType:${rule.ruleType})`
          )
        }
        return out
      },
      []
    )
    const chunked = _.chunk(pendingRuleClauses, MAX_PAGE_LIMIT)

    // Split into parallel requests if a single one
    // would exceed the single-query max page limit
    Promise
      .all(
        _.map(chunked, (clauses, i) => {
          // const query = `key:(${ keySet.join(' OR ') })`
          return this._getRulesRequest(
            clauses.join(' OR '),
            `refresh_pending_${i}`,
            `&rows=${MAX_PAGE_LIMIT + 1}&start=0`
          ) // 3x to ensure we get all possible variants for a given key (published, draft, profiling)
        })
      )
      .then(results => {
        const flattenedRules = convertRulesSearchAPItoUI(
          _.flatten(_.map(results, set => set.rules))
        )
        // Merge with current result set
        this._mergeData(flattenedRules)

        const detailInResults = _.find(flattenedRules, {
          _uiKey: _state.detailKey
        })
        if (_state.detailKey && detailInResults) {
          // Detail panel was in pending state, reload it
          this._setDetailRule(detailInResults)
        }

        this._queueNotify()
      })
      .catch(restError => {
        console.warn('Intel Rules: Pending Refresh Error', restError)
        //   this._addRestErrorNotification(restError, "Error Selecting Intel Rules")
      })
      .then(() => {
        this._resetStatusPoll() // Always restart poll timer
      })
  },

  _notifyRejectedRules(responseData = {}) {
    const rejected = _.get(responseData, 'rejected', [])
    const rejectedCount = rejected.length

    if (rejectedCount > 0) {
      CommonViewActions.Notification.add({
        type: 'error',
        heading: `Some rules were not saved`,
        message: `Unable to save ${rejectedCount} Intel ${pluralize(
          rejectedCount,
          'Rule'
        )}. Please check "More Details" for additional information.`,
        messageDetails: _.map(rejected, rule => {
          return `${_.get(
            rule,
            'intel.ids.rule',
            'Unknown Rule'
          )} :: (Error ${_.get(rule, 'reason.type', -1)}) ${_.get(
            rule,
            'reason.message',
            'Unknown error'
          )}`
        }) //.join("\r\n")
      })
    }
  },

  // Action handlers

  onRoute(queryParams = {}, section) {
    this._currentSection = section
    if (!section || section !== 'manage') {
      return
    }
    const {
      q,
      listId = [],
      sortBy,
      sortDir,
      detail,
      // detailHistoryId,
      activeType
    } = queryParams

    const newQuery = q || ''
    const newRuleType = validateRuleType(activeType)
    const newSortBy = _validateSortBy(sortBy, newRuleType)
    const newSortDir = _validateSortDir(sortDir, newRuleType)
    const newListIds = _.isArray(listId) ? listId : [listId]
    // const detailHistoryUploadId = detailHistoryId
    if (
      newQuery !== _state.currentQueryUI ||
      newSortBy !== _state.sortKey ||
      newSortDir !== _state.sortDir ||
      !_.isEqual(newListIds, _state.currentListIds) ||
      newRuleType !== _state.activeRuleType
    ) {
      _state.currentListIds = newListIds
      _state.currentQueryUI = newQuery
      _state.activeRuleType = newRuleType
      _state.sortKey = newSortBy
      _state.sortDir = newSortDir
      _state.moveRulesDialogMode = INTEL_MOVE_DIALOG_MODES.CLOSED
      this._queueNotify()
      this._loadRules(newQuery)
    }

    if (!detail) {
      _state.detailKey = ''
      _state.detailRule = null
      _state.detailError = null
      this._queueNotify()
    } else if (detail && detail !== _state.detailKey) {
      IntelManagementActions.cancelSubscriptionEdit() // Close subscription panel if open
      this._loadRuleDetail(detail)
    }

    // TODO finish moving selected history entry to act based on a URL queryParam
    // if (detailHistoryUploadId && detail && detailHistoryUploadId !== _state.detailHistoryUploadId) {
    //   _state.detailHistoryUploadId = detailHistoryUploadId
    //   this._queueNotify()
    // }
  },

  onReset() {
    clearTimeout(this._pollReq)
    delete this._pollReq
    _state = _.cloneDeep(DEFAULT_STATE)
    this._queueNotify()
  },

  onSearchRules(queryUI, callback) {
    const queryParam = { q: queryUI }
    if (callback) {
      this._searchRulesCompleteCallback = callback
    }
    if (this._currentSection !== 'manage') {
      // handle rule search from overview tab
      IntelManagementActions.switchMainTab('manage', queryParam)
    } else {
      urlHistoryUtil.mergeParams(queryParam, false)
    }
  },

  onChangeRuleSort(sortKey, sortDir) {
    urlHistoryUtil.mergeParams({
      sortBy: _validateSortBy(sortKey, _state.activeRuleType),
      sortDir: _validateSortDir(sortDir, _state.activeRuleType)
    })
  },

  onUpdateActiveRuleType(newRuleType) {
    const { sortKey } = _state
    const newParams = {
      activeType: newRuleType
    }
    _.forEach(INTEL_TYPES, type => {
      if (
        type.exclusiveFields &&
        type.exclusiveFields.indexOf(sortKey) !== -1 &&
        newRuleType !== type.id
      ) {
        // previous sort was exclusive to the former ruleType, remove it and reset sort to default
        newParams.sortBy = DEFAULT_SORT_KEY_BY_TYPE[newRuleType]
        newParams.sortDir = DEFAULT_SORT_DIR_BY_TYPE[newRuleType]
      }
    })
    urlHistoryUtil.mergeParams(newParams)
  },

  onRequestNextRulePage() {
    // if (!_state.currentListIds || _state.currentListIds.length === 0) {
    //   return
    // }
    _state.isLoadingRules = false
    _state.isLoadingRulesNextPage = true
    _state.nextPageAvailable = false
    _state.pageIndex += 1
    this._queueNotify()

    const req = this._getRulesRequest(_state.currentQuery)

    req.then(data => {
      _state.intelRuleCount = data.total
      _state.isLoadingRulesNextPage = false
      _state.nextPageAvailable = _isNextPageAvailable()
      this._addData(data.rules)
      this._queueNotify()
    }, restError => {
      _state.isLoadingRulesNextPage = false
      _state.intelRulesError = restError
      this._queueNotify()
    })
  },

  onLoadSchema() {
    // Load the Intel Rule query schema
    _state.isLoadingSchema = true
    _state.schemaError = null
    _state.querySchema = null
    this._queueNotify()

    const req = requestGet(`ims_get_schema`, `intel/schemas`)

    req.then(() => {
      _state.isLoadingSchema = false

      const _schema = _.clone(INTEL_QUERY_SCHEMA)

      const _killchainSchemaDef = _.find(_schema, {
        fieldName: 'threatMapping.killchainStage'
      })
      if (window._pw.isPwThreatTeam && _killchainSchemaDef) {
        _killchainSchemaDef.options = IMS_KILLCHAIN_STAGES_THREAT
      }

      _state.querySchema = convertQuerySchemaToEditorSchema(_schema)
      // if (_queuedSearchString != null) {
      this._loadRules(_queuedSearchString || '')
      // _queuedSearchString = null
      // }
      this._queueNotify()
    }, restError => {
      _state.isLoadingSchema = false
      _state.schemaError = restError
      this._queueNotify()
    })
  },

  onToggleRuleSelected(ruleKey) {
    const _selectedKeys = _.clone(_state.selectedRuleUIKeys)
    if (_selectedKeys.indexOf(ruleKey) === -1) {
      _selectedKeys.push(ruleKey)
    } else {
      _.pull(_selectedKeys, ruleKey)
    }
    _state.selectedRuleUIKeys = _.uniq(_selectedKeys)
    this._queueNotify()
  },

  onToggleRulesSelected(newSelectedUIKeys, shiftKeyHeld) {
    if (_state.allRulesSelected) {
      // If all rules are selected this should always just reset the selection to the new set
      _state.allRulesSelected = false
      _state.selectedRuleUIKeys = _.uniq(newSelectedUIKeys)
    } else {
      // const addToSelection = _state.allRulesSelected ? !shiftKeyHeld : shiftKeyHeld
      _state.selectedRuleUIKeys = _.uniq(
        shiftKeyHeld
          ? _state.selectedRuleUIKeys.concat(newSelectedUIKeys)
          : newSelectedUIKeys
      )
    }

    // All rules are now selected (manually), revert to allRulesSelected behavior
    if (_state.selectedRuleUIKeys.length === _state.intelRules.length) {
      _state.allRulesSelected = true
      _state.selectedRuleUIKeys = []
    }
    this._queueNotify()
  },

  onSelectAllRules() {
    _state.selectedRuleUIKeys = []
    _state.allRulesSelected = true
    this._queueNotify()
  },

  onSelectNoRules() {
    _state.selectedRuleUIKeys = []
    _state.allRulesSelected = false
    _state.editMode = null
    this._queueNotify()
  },

  onSetSelectedRules(ruleUIKeys) {
    _state.selectedRuleUIKeys = ruleUIKeys
    _state.allRulesSelected = false
    _state.editMode = null
    this._queueNotify()
  },

  onSelectRuleDetail(rule) {
    urlHistoryUtil.mergeParams(
      {
        detail: rule._uiKey || getGlobalUniqueRuleKey(rule)
      },
      false
    )
  },

  onCloseRuleDetail() {
    urlHistoryUtil.removeParams(['detail'], false)
  },

  onToggleNewRuleInput() {
    _state.ruleInputActive = !_state.ruleInputActive
    this._queueNotify()
  },

  onShowNewRuleInput() {
    _state.ruleInputActive = true
    this._queueNotify()
  },

  onCancelNewRuleInput() {
    _state.ruleInputActive = false
    this._queueNotify()
  },

  _batchSaveRules(rules, listId, commonProperties = {}, extraParams = {}) {
    const numRules = rules.length
    if (numRules === 0 || !listId) {
      return
    }

    const remainingRules = rules.slice()

    _state.batchUploadProgress = 0
    _state.isSavingRulesBatched = true
    _state.ruleInputActive = false
    this._queueNotify()

    const requestBatch = () => {
      const nextBatch = remainingRules.splice(0, MAX_RULES_PER_REQUEST)

      const req = this._getRuleSaveRequest(
        nextBatch,
        listId,
        commonProperties,
        extraParams
      )

      req.then(data => {
        _state.batchUploadProgress = Math.round(100 - (100 * (remainingRules.length / numRules)))
        this._queueNotify()
        this._notifyRejectedRules(data)
        if (remainingRules.length === 0) {
          // Slight delay to allow the progress bar a second of glorious 100%
          setTimeout(() => {
            _state.isSavingRulesBatched = false
            _state.ruleInputActive = false
            this._queueNotify()
            this._refreshCurrentSearch()

            this._notifyRuleSaveSuccess(numRules)
            IntelManagementActions.refreshRecentChanges()
          }, 1000)
        } else {
          requestBatch()
        }
      },
        restError => {
          const _notification = {
            type: 'error',
            heading: 'Error Uploading Intel',
            message: `Encountered an error while uploading Intel. ${_state.batchUploadProgress}% of uploaded rules sucessfuly saved.`,
            traceId: restError.traceId
          }
          if (restError.additional.length > 0) {
            _notification.messageDetails = restError.additional.join('\n')
          }
          const limitCtIdx = (_notification.messageDetails || '')
            .indexOf('max amount of rules allowed for a customer ')
          if (limitCtIdx !== -1) {
            // Customer hit the rule count limit
            const parsedLimit = parseInt(
              _notification.messageDetails.slice(limitCtIdx).trim()
            )
            const limitCt = isNaN(parsedLimit) ? 30000 : parsedLimit
            _notification.heading = `Rule Limit Reached`
            _notification.message = `Unable to add all uploaded Intel. Limit of ${limitCt.toLocaleString()} would be exceeded. ${_state.batchUploadProgress}% of uploaded rules sucessfuly saved`
          }
          CommonViewActions.Notification.add(_notification)
          _state.isSavingRulesBatched = false
          _state.batchUploadProgress = 0
          _state.ruleInputActive = false
          this._queueNotify()
        })
    }

    requestBatch(0) // Start
    AnalyticsActions.event({
      eventCategory: 'intel',
      eventAction: 'upload_rules_batched',
      eventLabel: commonProperties.ruleType || INTEL_TYPE_DEFAULT
    })
  },

  onSaveNewRules(
    rulesArray = [],
    listId,
    commonProperties = {},
    extraParams = {}
  ) {
    if (rulesArray.length === 0 || !listId) {
      return
    }

    // If the new rules exceed the max batch size
    // TODO also add a rule count limit? IP lists will be huge with low filesize
    if (rulesArray.length > MAX_RULES_PER_REQUEST) {
      this._batchSaveRules(rulesArray, listId, commonProperties, extraParams)
      return
    }

    _state.isSavingNewRules = true
    _state.ruleSaveError = null
    // TODO savingNewRulesForListId?
    this._queueNotify()

    const req = this._getRuleSaveRequest(
      rulesArray,
      listId,
      commonProperties,
      extraParams
    )

    req.then(data => {
      _state.isSavingNewRules = false
      if (data.rejected.length === 0) {
        _state.ruleInputActive = false
      }
      this._refreshCurrentSearch()
      this._queueNotify()
      this._notifyRejectedRules(data)
      if (data.created.length < 0) {
        CommonViewActions.Notification.add({
          type: 'success',
          heading: `${data.created.length} New Intel ${pluralize(
            data.created.length,
            'Rule'
          )} saved`,
          message: `Please note that it may take up to ${INTEL_RULE_SAVE_MINUTES} minutes for this change to take effect in the ${getConfig().productName} platform.`,
          dismissTimer: 4000
        })
      }
      IntelManagementActions.refreshRecentChanges()
    }, this._handleSaveFail)
    AnalyticsActions.event({
      eventCategory: 'intel',
      eventAction: 'save_new_rules',
      eventLabel: commonProperties.ruleType || INTEL_TYPE_DEFAULT
    })
  },

  _mapCommonFieldsUiToApi(commonFields) {
    return _.reduce(
      commonFields,
      (out, value, fieldName) => {
        if (
          ['killchainStage', 'category', 'confidence', 'severity'].indexOf(
            fieldName
          ) !== -1
        ) {
          if (!out['threatMapping']) {
            out['threatMapping'] = {}
          }
          out['threatMapping'][
            fieldName === 'killchainStage' ? 'killChainStage' : fieldName
          ] = value
        } else {
          out[fieldName] = value
        }
        return out
      },
      {}
    )
  },

  onSaveRuleChanges(
    rulesArray = [],
    selectedRuleUIKeys = [],
    allRulesSelected,
    allRulesSelectedCommonFields = {}
  ) {
    _state.isSavingRules = true
    _state.ruleSaveError = null
    this._queueNotify()

    const allChangeReqs = []

    if (allRulesSelected) {
      // Omit all manually edited rules (will be saved in the following `if` statement)
      // AND all selected(negated) rules
      const ruleUIKeysToOmit = _.uniq(
        _.map(rulesArray, rule => rule._uiKey).concat(selectedRuleUIKeys)
      )

      const commonFields = this._mapCommonFieldsUiToApi(
        allRulesSelectedCommonFields
      )

      const saveByQueryReq = this._saveRulesByQuery(
        this._getSelectAllQuery(ruleUIKeysToOmit),
        commonFields
      )
      allChangeReqs.push(saveByQueryReq)
    }

    if (rulesArray && rulesArray.length > 0) {
      const rulesWithRevBumped = this._enableAutoRevIncrementing
        ? bumpRevisionForRules(rulesArray)
        : rulesArray

      // Group update requests by unique listIds
      const rulesByListId = _.groupBy(rulesWithRevBumped, 'listId')

      const requests = _.reduce(
        rulesByListId,
        (out, rules, listId) => {
          if (
            this._listDataCache[listId] &&
            this._listDataCache[listId]._isEditableByCustomer
          ) {
            out.push(
              requestPost(
                null,
                `intel/lists/${listId}/rules/update`,
                convertRulesUItoAPI(rules)
              )
            )
          }
          return out
        },
        []
      )

      const flattenedDeferred = genericUtil.defer()

      Promise
        .all(requests)
        .then(results => {
          const mergedData = {
            updated: [],
            rejected: []
          }
          for (var i = 0; i < results.length; i++) {
            const result = results[i]
            mergedData.updated = mergedData.updated.concat(result.updated || [])
            mergedData.rejected = mergedData.rejected.concat(
              result.rejected || []
            )
          }

          // Merge the successfully updated rules into the current store collection
          const convertedData = convertRulesAPItoUI(mergedData.updated || [])
          if (convertedData && convertedData.length > 0) {
            this._refreshCurrentSearch()
            this._notifyRuleSaveSuccess(convertedData.length)
          }

          const detailInResults = _.find(convertedData, {
            _uiKey: _state.detailKey
          })
          if (_state.detailKey && detailInResults) {
            // Detail panel was in pending state, reload it
            this._setDetailRule(detailInResults)
          }

          this._notifyRejectedRules(mergedData)
          this._checkRuleCounts()
          flattenedDeferred.resolve(mergedData)
        })
        .catch(restError => {
          this._addRestErrorNotification(restError)
          flattenedDeferred.reject(new Error('Error Saving Intel'))
        })
        .then(() => {
          _state.isSavingRules = false
          _state.moveRulesDialogMode = INTEL_MOVE_DIALOG_MODES.CLOSED
          this._queueNotify()
        })

      flattenedDeferred.promise.catch(() => {
        // noop
      })

      // return flattenedDeferred.promise
      allChangeReqs.push(flattenedDeferred.promise)
    }

    const allReqs = Promise.all(allChangeReqs)

    _state.isSavingRules = true // Reset to true until we know all child promises are complete
    this._queueNotify()

    allReqs.catch(() => { }).then(() => {
      _state.isSavingRules = false
      this._queueNotify()
    })

    return allReqs
  },

  onSaveChangesWithNewMode(
    newMode,
    rules = [],
    selectedRuleUIKeys = [],
    allRulesSelected,
    allRulesSelectedCommonFields = {}
  ) {
    const allChangeReqs = []

    if (allRulesSelected) {
      // Omit all manually edited rules (will be saved in the following `if` statement)
      // AND all selected(negated) rules
      const ruleUIKeysToOmit = _.uniq(
        _.map(rules, rule => rule._uiKey).concat(selectedRuleUIKeys)
      )

      const commonFields = this._mapCommonFieldsUiToApi(
        allRulesSelectedCommonFields
      )

      const saveByQueryReq = this._saveRulesByQuery(
        this._getSelectAllQuery(ruleUIKeysToOmit),
        _.assign({}, commonFields, {
          mode: newMode
        })
      )
      saveByQueryReq.then(() => {
        // Update by query should be complete now
        if (newMode === INTEL_MODES.PUBLISHED) {
          // TODO verify that this does not run until the save successfully completed
          // If publishing, cleanup old rules, ensuring only draft rules get removed
          this.onDeleteRules(ruleUIKeysToOmit, true, ` AND mode:Draft`)
        }
      })
      allChangeReqs.push(saveByQueryReq)
    }

    // Handle standard save for all discretely-modified rules
    if (rules && rules.length > 0) {
      const rulesWithNewMode = []
      const ruleUIKeysToCleanup = []
      for (var i = 0; i < rules.length; i++) {
        const rule = rules[i]
        if (rule.mode !== newMode) {
          rulesWithNewMode.push(
            _.assign({}, rule, {
              mode: newMode
            })
          )
          ruleUIKeysToCleanup.push(rule._uiKey)
        } else {
          // Skip this rule, as it already has the set mode
        }
      }

      if (rulesWithNewMode.length === 0) {
        return
      }

      const saveReq = this.onSaveRuleChanges(rulesWithNewMode)
      allChangeReqs.push(saveReq)
      saveReq.then(responses => {
        const updated = _.reduce(
          responses,
          (out, resp) => out.concat(resp.updated),
          []
        )

        if (updated && updated.length > 0) {
          // Draft/Profiling=>Published cleans up the former draft on success
          const convertedUpdated = convertRulesAPItoUI(updated)

          // Set of updated keys without mode part
          const convertedUpdatedPartialKeys = _.map(
            convertedUpdated,
            generateNoModePartialUIKey
          )

          if (_state.detailKey) {
            const currentDetailKeyParts = parseGlobalUniqueRuleKey(
              _state.detailKey
            )
            const updatedDetailRule = _.find(convertedUpdated, {
              _uiKey: getGlobalUniqueRuleKey(
                _.assign(currentDetailKeyParts, {
                  mode: newMode
                })
              )
            })
            if (updatedDetailRule) {
              IntelManagementActions.selectRuleDetail(updatedDetailRule)
            }
          }

          // Delete previous draft versions if moving to published
          if (newMode === INTEL_MODES.PUBLISHED) {
            // Filter ruleUIKeysToCleanup to only contain rule keys that were in
            // the "updated" collection, that were successfully saved
            const ruleUIKeysToBeDeleted = _.filter(
              ruleUIKeysToCleanup,
              ruleUIKey => {
                const compareKey = generateNoModePartialUIKey(
                  parseGlobalUniqueRuleKey(ruleUIKey)
                )
                return convertedUpdatedPartialKeys.indexOf(compareKey) !== -1
              }
            )
            this.onDeleteRules(ruleUIKeysToBeDeleted, false)
          }
        }
      })
    }

    _state.isSavingRules = true // Reset to true until we know all child promises are complete
    this._queueNotify()

    Promise.all(allChangeReqs).catch(() => { }).then(() => {
      _state.isSavingRules = false
      this._queueNotify()
    })
    AnalyticsActions.event({
      eventCategory: 'intel',
      eventAction: 'rule_action',
      eventLabel: `mode_${newMode}`
    })
  },

  onUpdateRulesPartial(ruleUIKeys, partialRule) {
    this.onSaveRuleChanges(
      _.map(this._getRulesByUIKeys(ruleUIKeys), rule =>
        _.assign({}, rule, partialRule)
      )
    )
  },

  // Generate a query that filters to only the rule ids selected
  _getSelectedRulesQuery(ruleUIKeys) {
    const query = _.map(ruleUIKeys, uiKey => {
      const {
        mode,
        key,
        listId,
        ruleType
        // requestKey
      } = parseGlobalUniqueRuleKey(uiKey)
      return `(ruleType:${ruleType} AND key:"${key}" AND mode:${mode} AND listId:"${listId}")`
    }).join(' OR ')
    return query
  },

  _getSelectAllQuery(excludedRuleUIKeys) {
    const { currentListIds, activeRuleType } = _state

    const exclusionQueryClauses = _.map(excludedRuleUIKeys, uiKey => {
      const {
        mode,
        key,
        listId,
        ruleType
        // requestKey
      } = parseGlobalUniqueRuleKey(uiKey)
      return `NOT (ruleType:${ruleType} AND key:"${key}" AND mode:${mode} AND listId:"${listId}")`
    })

    const baseQueryParts = _.compact([
      _state.currentQuery,
      `ruleType:${activeRuleType}`
    ])
    if (currentListIds && currentListIds.length > 0) {
      // baseQueryParts.push(`listId:("${ currentListIds.join('" OR "') }")`)
      baseQueryParts.push(`(listId:"${currentListIds.join('" OR listId:"')}")`)
    }
    if (exclusionQueryClauses.length > 0) {
      baseQueryParts.push(`${exclusionQueryClauses.join(' AND ')}`)
    }
    return baseQueryParts.join(' AND ')
  },

  onDeleteRules(
    ruleUIKeys,
    allRulesSelected = false,
    additionalDeleteByQueryClauses = ''
  ) {
    _state.isSavingRules = true
    _state.ruleSaveError = null
    this._queueNotify()

    // Expand to component parts
    const expandedRuleUIKeys = _.map(ruleUIKeys, parseGlobalUniqueRuleKey)

    const rulesByListId = _.groupBy(expandedRuleUIKeys, 'listId')

    // Filter requests to only editable listIds
    let requests = []

    if (allRulesSelected) {
      const { intelRuleCount, selectedRuleUIKeys } = _state
      const approxRuleDeleteCount = intelRuleCount - selectedRuleUIKeys.length // Assumes that allRulesSelected is true

      // Delete by query, filtered to remove any passed/selected ruleUIKeys
      const deleteByQueryReq = requestPost(
        null,
        `intel/lists/rules/delete?q=${this._getSelectAllQuery(
          ruleUIKeys
        )}${additionalDeleteByQueryClauses}`
      )

      requests = [deleteByQueryReq]

      deleteByQueryReq.then(response => {
        IntelManagementActions.addJobId(
          response.transactionId,
          approxRuleDeleteCount,
          'delete'
        )
      })
    } else {
      requests = _.reduce(
        rulesByListId,
        (out, rules, listId) => {
          if (
            this._listDataCache[listId] &&
            this._listDataCache[listId]._isEditableByCustomer
          ) {
            out.push(
              requestPost(
                null,
                `intel/lists/${listId}/rules/delete`,
                _.map(rules, uiRuleKeyParts => {
                  return {
                    listId: uiRuleKeyParts.listId,
                    ruleType: uiRuleKeyParts.ruleType,
                    key: uiRuleKeyParts.key,
                    ruleMode: uiRuleKeyParts.mode
                  }
                })
              )
            )
          }
          return out
        },
        []
      )
    }

    Promise
      .all(requests)
      .then(() => {
        this._refreshCurrentSearch()
        this._queueNotify()
        if (ruleUIKeys.indexOf(_state.detailKey) !== -1 || allRulesSelected) {
          this.onCloseRuleDetail()
        }
      })
      .catch(restError => {
        this._addRestErrorNotification(
          restError,
          'Error Deleting Intel'
        )
      })
      .then(() => {
        _state.isSavingRules = false
        this._queueNotify()
      })
    AnalyticsActions.event({
      eventCategory: 'intel',
      eventAction: 'delete_rules'
    })
  },

  onUpdateMoveRulesDialog(
    dialogMode = INTEL_MOVE_DIALOG_MODES.CLOSED,
    ruleUIKeys
  ) {
    if (_.values(INTEL_MOVE_DIALOG_MODES).indexOf(dialogMode) === -1) {
      return
    }
    _state.moveRulesDialogMode = dialogMode
    if (dialogMode === INTEL_MOVE_DIALOG_MODES.CLOSED) {
      _state.moveRuleUIKeys = []
    } else {
      _state.moveRuleUIKeys = ruleUIKeys
    }
    this._queueNotify()
  },

  _getEditableRules(ruleUIKeys) {
    const rules = this._getRulesByUIKeys(ruleUIKeys)
    return rules
  },

  _notifyRuleSaveSuccess(successCount) {
    if (successCount && successCount > 0) {
      this._refreshCurrentSearch() // TODO only refresh if one of the `updated` rules has one of the active listIds?
      CommonViewActions.Notification.add({
        type: 'success',
        heading: `${successCount} Intel ${pluralize(
          successCount,
          'Rule'
        )} saved`,
        message: `Please note that it may take up to ${INTEL_RULE_SAVE_MINUTES} minutes for this change to take effect in the ${getConfig().productName} platform.`,
        dismissTimer: 4000
      })
    }
  },

  onUpdateRulesStatus(ruleUIKeys, newStatus, allRulesSelected) {
    if (!ruleUIKeys || !newStatus) {
      return
    }

    if (allRulesSelected) {
      this._saveRulesByQuery(this._getSelectAllQuery(ruleUIKeys), {
        status: newStatus
      })
    } else {
      const editRules = this._getEditableRules(ruleUIKeys)
      if (editRules && editRules.length > 0) {
        IntelManagementActions.saveRuleChanges(
          _.map(editRules, rule => {
            return _.assign({}, rule, {
              status: newStatus
            })
          })
        )
      }
    }
    AnalyticsActions.event({
      eventCategory: 'intel',
      eventAction: 'rule_action',
      eventLabel: `status_${newStatus}`
    })
  },

  _reloadDetails() {
    if (_state.detailKey && _state.detailKey !== '') {
      IntelManagementActions.cancelSubscriptionEdit() // Close subscription panel if open
      this._loadRuleDetail(_state.detailKey)
    }
  },

  _copyRulesByQuery(query, targetListId, approxRuleCopyCount) {
    _state.isSavingRules = true
    _state.ruleSaveError = null
    this._queueNotify()
    if (approxRuleCopyCount > BULK_RULE_EDIT_SAVE_WARN_THRESHOLD) {
      CommonViewActions.Notification.add({
        type: 'info',
        heading: `This might take a while`,
        message: `It may take a few minutes for this large set of changes to complete.`,
        dismissTimer: 6000
      })
    }
    const req = requestPost(
      null,
      `intel/lists/rules/copy?q=${query}&targetListId=${targetListId}`,
      null,
      {
        useSocket: false
      }
    )

    req.then(response => {
      this._reloadDetails()
      this._refreshCurrentSearch()
      IntelManagementActions.addJobId(
        response.transactionId,
        approxRuleCopyCount,
        'update'
      )
      this._checkRuleCounts()
      _state.isSavingRules = false
      _state.moveRulesDialogMode = INTEL_MOVE_DIALOG_MODES.CLOSED
      this._queueNotify()
    }, restError => {
      this._addRestErrorNotification(restError)
      _state.isSavingRules = false
      _state.moveRulesDialogMode = INTEL_MOVE_DIALOG_MODES.CLOSED
      this._queueNotify()
    })

    return req
  },

  _moveRulesByQuery(query, targetListId, approxRuleMoveCount) {
    _state.isSavingRules = true
    _state.ruleSaveError = null
    this._queueNotify()
    if (approxRuleMoveCount > BULK_RULE_EDIT_SAVE_WARN_THRESHOLD) {
      CommonViewActions.Notification.add({
        type: 'info',
        heading: `This might take a while`,
        message: `It may take a few minutes for this large set of changes to complete.`,
        dismissTimer: 6000
      })
    }
    const req = requestPost(
      null,
      `intel/lists/rules/move?q=${query}&targetListId=${targetListId}`,
      null,
      {
        useSocket: false
      }
    )

    req.then(response => {
      this._reloadDetails()
      this._refreshCurrentSearch()
      IntelManagementActions.addJobId(
        response.transactionId,
        approxRuleMoveCount,
        'update'
      )
      this._checkRuleCounts()
      _state.isSavingRules = false
      _state.moveRulesDialogMode = INTEL_MOVE_DIALOG_MODES.CLOSED
      this._queueNotify()
    }, restError => {
      this._addRestErrorNotification(restError)
      _state.isSavingRules = false
      _state.moveRulesDialogMode = INTEL_MOVE_DIALOG_MODES.CLOSED
      this._queueNotify()
    })

    return req
  },

  _saveRulesByQuery(query, commonRuleFields) {
    const { intelRuleCount, selectedRuleUIKeys } = _state

    const approxRuleSaveCount = intelRuleCount - selectedRuleUIKeys.length // Assumes that allRulesSelected is true

    _state.isSavingRules = true
    _state.ruleSaveError = null
    this._queueNotify()

    if (approxRuleSaveCount > BULK_RULE_EDIT_SAVE_WARN_THRESHOLD) {
      CommonViewActions.Notification.add({
        type: 'info',
        heading: `This might take a while`,
        message: `It may take a few minutes for this large set of changes to complete.`,
        dismissTimer: 6000
      })
    }

    const req = requestPost(
      null,
      `intel/lists/rules/update?q=${query}`, //&start=0&rows=50`,
      commonRuleFields,
      {
        useSocket: false
      }
    )

    req.then(response => {
      this._reloadDetails()
      this._refreshCurrentSearch()
      IntelManagementActions.addJobId(
        response.transactionId,
        approxRuleSaveCount,
        'update'
      )
      this._checkRuleCounts()
      _state.isSavingRules = false
      _state.moveRulesDialogMode = INTEL_MOVE_DIALOG_MODES.CLOSED
      this._queueNotify()
    }, restError => {
      this._addRestErrorNotification(restError)
      _state.isSavingRules = false
      _state.moveRulesDialogMode = INTEL_MOVE_DIALOG_MODES.CLOSED
      this._queueNotify()
    })

    return req
  },

  onCopyRules(ruleUIKeys, targetListId, allRulesSelected) {
    const { intelRuleCount } = _state
    const copyQuery = allRulesSelected ? this._getSelectAllQuery(ruleUIKeys) : this._getSelectedRulesQuery(ruleUIKeys)
    const count = allRulesSelected ?  intelRuleCount - ruleUIKeys.length  : ruleUIKeys.length
    const copyReq = this._copyRulesByQuery(copyQuery, targetListId, count)
    this.onCloseRuleDetail()
    AnalyticsActions.event({
      eventCategory: 'intel',
      eventAction: 'rule_action',
      eventLabel: 'copy'
    })
    return copyReq
  },

  onMoveRules(ruleUIKeys, targetListId, allRulesSelected) {
    const { intelRuleCount } = _state
    const moveQuery = allRulesSelected ? this._getSelectAllQuery(ruleUIKeys) : this._getSelectedRulesQuery(ruleUIKeys)
    const count = allRulesSelected ? intelRuleCount - ruleUIKeys.length : ruleUIKeys.length
    const moveReq = this._moveRulesByQuery(moveQuery, targetListId, count)
    this.onCloseRuleDetail()
    AnalyticsActions.event({
      eventCategory: 'intel',
      eventAction: 'rule_action',
      eventLabel: 'move'
    })
    return moveReq
  },

  _editAllRules() {
    _state.allRulesSelected = true // Autoselect all
    _state.editMode = INTEL_EDIT_MODES.MAPPINGS // Auto-set mapping edit mode
    this._queueNotify()
  },

  onFixMappings() {
    const { currentQueryUI } = _state
    const fixMappingsUiQuery = `${NEEDS_MAPPINGS_UI_QUERY_KEY}=true`
    if (currentQueryUI.indexOf(fixMappingsUiQuery) !== -1) {
      // Already filtered, just edit all
      this._editAllRules()
    } else {
      this.onSearchRules(
        this._getQueryWithCurrent(fixMappingsUiQuery, true),
        this._editAllRules.bind(this)
      )
    }
    AnalyticsActions.event({
      eventCategory: 'intel',
      eventAction: 'rule_action',
      eventLabel: 'fix_mappings'
    })
  },

  onSetEditMode(newMode) {
    if (
      newMode === null ||
      _.values(INTEL_EDIT_MODES).indexOf(newMode) !== -1
    ) {
      _state.editMode = newMode
      this._queueNotify()
    }
  },

  onFilterRulesBySubscription(listIds) {
    urlHistoryUtil.mergeParams(
      {
        listId: listIds,
        q: `status=enabled AND mode=published` // UI query for enabled/published
      },
      false
    )
  }
})

import _ from 'lodash'
import Reflux from 'reflux'
import SensorActions from 'actions/SensorActions'
import CommonViewActions from 'actions/CommonViewActions'
import AnalyticsActions from 'actions/AnalyticsActions'
import SensorStore from 'stores/SensorStore'
import { requestGet, requestPut } from 'utils/restUtils'

// mapping retention between days and months
// _retentionMonthsToDays = {
//   1: 31
//   3: 93
//   6: 183
//   9: 276
//   12: 365
// }
// _retentionDaysToMonths = scaleQuantile()
//   .domain(_.values(_retentionMonthsToDays))
//   .range(_.keys(_retentionMonthsToDays).map(Number))

const CONFIG_SAVED_MSG = 'Your changes have been successfully saved.'
const CONFIG_SAVED_RESTART_MSG = 'Your changes have been successfully saved, but not yet applied. Click "Restart Sensor" to apply and restart the sensor.'

const _state = {
  sensorId: null,
  isLoading: false,
  isSaving: false,
  data: null,
  interfaces: null,
  error: null
}

export default Reflux.createStore({
  listenables: SensorActions,

  getInitialState() {
    return _state
  },

  /**
   * Handles SensorActions.loadSensorLocations(sensorId)
   */
  async onLoadSensorLocations(sensorId) {
    _state.sensorId = sensorId
    _state.isLoading = true
    _state.isSaving = false
    _state.data = null
    _state.error = null
    this._notify()

    try {
      const locations = await requestGet('sensor_locations_get', 'sensors/' + sensorId + '/locations')
      _state.data = _.sortBy(locations || [], 'id').reverse()
    } catch(restError) {
      _state.error = restError
      CommonViewActions.Notification.addRestError(restError)
    }
    _state.isLoading = false
    this._notify()
  },

  /**
   * Handles SensorActions.saveSensorLocations(sensorId, locations)
   */
  async onSaveSensorLocations(sensorId, newLocations) {
    // Sanity check that the current sensor hasn't changed out from under us
    if (_state.isLoading || !_state.data || +sensorId !== +_state.sensorId) { return }

    _state.isSaving = true
    _state.error = null
    this._notify()

    AnalyticsActions.event({
      eventCategory: 'profiler',
      eventAction: 'save_sensor_locations'
    })

    const sanitized = _.map(newLocations, loc => {
      const out = _.pick(loc, ['cidr_range', 'location_id'])
      // New UI locations use negative UI-only IDs
      if (loc.id >= 0) {
        out.id = loc.id // Persist ID for updates
      }
      return out
    })

    try {
      _state.data = await requestPut('sensor_locations_put', `sensors/${sensorId}/locations`, sanitized)
      CommonViewActions.Notification.add({
        type: 'success',
        heading: 'Sensor Locations Saved',
        dismissTimer: 2000
      })
      SensorStore.reloadSensors() //freshen global sensors
    } catch(restError) {
      _state.error = restError
    }
    _state.isSaving = false
    this._notify()
  },


  _onDataLoaded(sensor) {
    const uiData = {
      locations: _.sortBy(sensor.locations, 'id').reverse()
    }

    if (uiData.filters && _.isArray(uiData.filters)) {
      // console.log('Got array formatted BPF filters, stringifying...', uiData.filters)
      uiData.filters = _(uiData.filters).map(val => val.bpf).compact().valueOf().join('\n')
    }

    _state.isLoading = false
    _state.data = uiData
    this._notify()
  },

  _onDataLoadError(restError) {
    _state.error = restError.body
    _state.isLoading = false
    this._notify()
    CommonViewActions.Notification.add({
      type: 'error',
      heading: 'Error',
      message: "An error occurred while loading sensor locations: " + _state.error,
      dismissTimer: 15000
    })
  },

  _saveLocations(sensorId, newLocations = []) {
    const sanitized = _.map(newLocations, loc => {
      const out = _.pick(loc, ['cidr_range', 'location_id'])
      // New UI locations use negative UI-only IDs
      if (loc.id >= 0) {
        out.id = loc.id // Persist ID for updates
      }
      return out
    })

    return requestPut('sensor_locations_put', `sensors/${ sensorId }/locations`, sanitized)
      .then(() => {
        // Get latest version of locations collection on success
        return this._getLocations(sensorId)
      })
  },

  _getLocations(sensorId) {
    return requestGet('sensor_locations_get', 'sensors/' + sensorId + '/locations')
      .then(locations => {
        const _locations = _.sortBy(locations, 'id').reverse()
        const globalSensorObj = SensorStore.getSensor(+sensorId)
        if (globalSensorObj) {
          globalSensorObj.locations = _locations // Refresh global sensor locations collection
        }
        return _locations
      })
  },

  _onSaveSuccess([basicData, configData, locationsData]) {
    _state.isSaving = false
    // configData.sensor = basicData #same nested structure as from a single GET with ?include=sensor
    // this._onDataLoaded(configData)
    basicData.sensor_config = configData //same nested structure as from a single GET /sensor/<id>?include=config
    basicData.locations = locationsData

    this._onDataLoaded(basicData)
    const sensorObj = SensorStore.getSensor(+_state.sensorId)
    const applied = (sensorObj && sensorObj.isVersion2) ? true :
      (configData.applied_at && new Date(configData.applied_at) >= new Date(configData.updated_at))
    CommonViewActions.Notification.add({
      type: 'success',
      heading: 'Sensor Locations Saved',
      dismissTimer: 5000
    })
  },

  _onDataSaveError(restError) {
    _state.error = restError.message
    _state.isSaving = false
    this._notify()
    CommonViewActions.Notification.add({
      type: 'error',
      heading: 'Sensor Locations Not Saved',
      message: "Your changes could not be saved because: " + _state.error,
      dismissTimer: 15000
    })
  },

  _notify() {
    _state._updated = Date.now()
    this.trigger(_state)
  }
})

export default [{ name: 'UNKNOWN', value: 0},
{ name: 'FTP_CONTROL', value: 1},
{ name: 'POP3', value: 2},
{ name: 'SMTP', value: 3},
{ name: 'IMAP', value: 4},
{ name: 'DNS', value: 5},
{ name: 'IPP', value: 6},
{ name: 'HTTP', value: 7},
{ name: 'MDNS', value: 8},
{ name: 'NTP', value: 9},
{ name: 'NETBIOS', value: 10},
{ name: 'NFS', value: 11},
{ name: 'SSDP', value: 12},
{ name: 'BGP', value: 13},
{ name: 'SNMP', value: 14},
{ name: 'XDMCP', value: 15},
{ name: 'SMB', value: 16},
{ name: 'SYSLOG', value: 17},
{ name: 'DHCP', value: 18},
{ name: 'POSTGRESQL', value: 19},
{ name: 'MYSQL', value: 20},
{ name: 'TDS', value: 21},
{ name: 'DIRECT_DOWNLOAD_LINK', value: 22},
{ name: 'POPS', value: 23},
{ name: 'APPLEJUICE', value: 24},
{ name: 'DIRECTCONNECT', value: 25},
{ name: 'SOCRATES', value: 26},
{ name: 'WINMX', value: 27},
{ name: 'VMWARE', value: 28},
{ name: 'SMTPS', value: 29},
{ name: 'FILETOPIA', value: 30},
{ name: 'IMESH', value: 31},
{ name: 'KONTIKI', value: 32},
{ name: 'OPENFT', value: 33},
{ name: 'FASTTRACK', value: 34},
{ name: 'GNUTELLA', value: 35},
{ name: 'EDONKEY', value: 36},
{ name: 'BITTORRENT', value: 37},
{ name: 'EPP', value: 38},
{ name: 'AVI', value: 39},
{ name: 'FLASH', value: 40},
{ name: 'OGGVORBIS', value: 41},
{ name: 'MPEG', value: 42},
{ name: 'QUICKTIME', value: 43},
{ name: 'REALMEDIA', value: 44},
{ name: 'WINDOWSMEDIA', value: 45},
{ name: 'MMS', value: 46},
{ name: 'XBOX', value: 47},
{ name: 'QQ', value: 48},
{ name: 'MOVE', value: 49},
{ name: 'RTSP', value: 50},
{ name: 'IMAPS', value: 51},
{ name: 'ICECAST', value: 52},
{ name: 'PPLIVE', value: 53},
{ name: 'PPSTREAM', value: 54},
{ name: 'ZATTOO', value: 55},
{ name: 'SHOUTCAST', value: 56},
{ name: 'SOPCAST', value: 57},
{ name: 'TVANTS', value: 58},
{ name: 'TVUPLAYER', value: 59},
{ name: 'HTTP_APPLICATION_VEOHTV', value: 60},
{ name: 'QQLIVE', value: 61},
{ name: 'THUNDER', value: 62},
{ name: 'SOULSEEK', value: 63},
{ name: 'SSL_NO_CERT', value: 64},
{ name: 'IRC', value: 65},
{ name: 'AYIYA', value: 66},
{ name: 'UNENCRYPED_JABBER', value: 67},
{ name: 'MSN', value: 68},
{ name: 'OSCAR', value: 69},
{ name: 'YAHOO', value: 70},
{ name: 'BATTLEFIELD', value: 71},
{ name: 'QUAKE', value: 72},
{ name: 'VRRP', value: 73},
{ name: 'STEAM', value: 74},
{ name: 'HALFLIFE2', value: 75},
{ name: 'WORLDOFWARCRAFT', value: 76},
{ name: 'TELNET', value: 77},
{ name: 'STUN', value: 78},
{ name: 'IPSEC', value: 79},
{ name: 'GRE', value: 80},
{ name: 'ICMP', value: 81},
{ name: 'IGMP', value: 82},
{ name: 'EGP', value: 83},
{ name: 'SCTP', value: 84},
{ name: 'OSPF', value: 85},
{ name: 'IP_IN_IP', value: 86},
{ name: 'RTP', value: 87},
{ name: 'RDP', value: 88},
{ name: 'VNC', value: 89},
{ name: 'PCANYWHERE', value: 90},
{ name: 'SSL', value: 91},
{ name: 'SSH', value: 92},
{ name: 'USENET', value: 93},
{ name: 'MGCP', value: 94},
{ name: 'IAX', value: 95},
{ name: 'TFTP', value: 96},
{ name: 'AFP', value: 97},
{ name: 'STEALTHNET', value: 98},
{ name: 'AIMINI', value: 99},
{ name: 'SIP', value: 100},
{ name: 'TRUPHONE', value: 101},
{ name: 'ICMPV6', value: 102},
{ name: 'DHCPV6', value: 103},
{ name: 'ARMAGETRON', value: 104},
{ name: 'CROSSFIRE', value: 105},
{ name: 'DOFUS', value: 106},
{ name: 'FIESTA', value: 107},
{ name: 'FLORENSIA', value: 108},
{ name: 'GUILDWARS', value: 109},
{ name: 'HTTP_APPLICATION_ACTIVESYNC', value: 110},
{ name: 'KERBEROS', value: 111},
{ name: 'LDAP', value: 112},
{ name: 'MAPLESTORY', value: 113},
{ name: 'MSSQL', value: 114},
{ name: 'PPTP', value: 115},
{ name: 'WARCRAFT3', value: 116},
{ name: 'WORLDOFKUNGFU', value: 117},
{ name: 'MEEBO', value: 118},
{ name: 'FACEBOOK', value: 119},
{ name: 'TWITTER', value: 120},
{ name: 'DROPBOX', value: 121},
{ name: 'GMAIL', value: 122},
{ name: 'GOOGLEMAPS', value: 123},
{ name: 'YOUTUBE', value: 124},
{ name: 'SKYPE', value: 125},
{ name: 'GOOGLE', value: 126},
{ name: 'DCE_RPC', value: 127},
{ name: 'NETFLOW', value: 128},
{ name: 'SFLOW', value: 129},
{ name: 'HTTP_CONNECT', value: 130},
{ name: 'HTTP_PROXY', value: 131},
{ name: 'CITRIX', value: 132},
{ name: 'NETFLIX', value: 133},
{ name: 'LASTFM', value: 134},
{ name: 'GROOVESHARK', value: 135},
{ name: 'SKYFILE_PREPAID', value: 136},
{ name: 'SKYFILE_RUDICS', value: 137},
{ name: 'SKYFILE_POSTPAID', value: 138},
{ name: 'CITRIX_ONLINE', value: 139},
{ name: 'APPLE', value: 140},
{ name: 'WEBEX', value: 141},
{ name: 'WHATSAPP', value: 142},
{ name: 'APPLEICLOUD', value: 143},
{ name: 'VIBER', value: 144},
{ name: 'APPLEITUNES', value: 145},
{ name: 'RADIUS', value: 146},
{ name: 'WINDOWSUPDATE', value: 147},
{ name: 'TEAMVIEWER', value: 148},
{ name: 'TUENTI', value: 149},
{ name: 'LOTUSNOTES', value: 150},
{ name: 'SAP', value: 151},
{ name: 'GTP', value: 152},
{ name: 'UPNP', value: 153},
{ name: 'LLMNR', value: 154},
{ name: 'REMOTESCAN', value: 155},
{ name: 'SPOTIFY', value: 156},
{ name: 'WEBM', value: 157},
{ name: 'H323', value: 158},
{ name: 'OPENVPN', value: 159},
{ name: 'NOE', value: 160},
{ name: 'CISCOVPN', value: 161},
{ name: 'TEAMSPEAK', value: 162},
{ name: 'TOR', value: 163},
{ name: 'CISCOSKINNY', value: 164},
{ name: 'RTCP', value: 165},
{ name: 'RSYNC', value: 166},
{ name: 'ORACLE', value: 167},
{ name: 'CORBA', value: 168},
{ name: 'UBUNTUONE', value: 169},
{ name: 'WHOIS_DAS', value: 170},
{ name: 'COLLECTD', value: 171},
{ name: 'SOCKS5', value: 172},
{ name: 'SOCKS4', value: 173},
{ name: 'RTMP', value: 174},
{ name: 'FTP_DATA', value: 175},
{ name: 'WIKIPEDIA', value: 176},
{ name: 'ZEROMQ', value: 177},
{ name: 'AMAZON', value: 178},
{ name: 'EBAY', value: 179},
{ name: 'CNN', value: 180},
{ name: 'MEGACO', value: 181},
{ name: 'REDIS', value: 182},
{ name: 'PANDO_MEDIA_BOOSTER', value: 183},
{ name: 'MODBUS_TCP', value: 2386},
{ name: 'ETHERNET_IP', value: 2387},
{ name: 'CIP', value: 2388},
{ name: 'DNP3', value: 2389},
{ name: 'OPC', value: 2390},
{ name: 'KAZAA', value: 807},
{ name: 'MAPI', value: 452},
{ name: 'MUTE', value: 831},
{ name: 'RLOGIN', value: 891},
{ name: 'RSH', value: 893},
{ name: 'SLSK', value: 811},
{ name: 'MANOLITO', value: 818},
{ name: 'UTP', value: 799},
{ name: 'NOD32_UPDATE', value: 1073},
{ name: 'KASPERSKY_UPDATE', value: 2200},
{ name: 'KUGOU', value: 815},
{ name: 'LIVE_MESH', value: 813},
{ name: 'QQMUSIC', value: 841},
{ name: 'SORIBADA', value: 798},
{ name: 'VAKAKA', value: 796},
{ name: 'GOTOMYPC', value: 900},
{ name: 'RADMIN', value: 902},
{ name: 'SOPHOS_UPDATE', value: 184},
{ name: 'IOS_OTA_UPDATE', value: 198},
{ name: 'QQSTREAM', value: 838},
{ name: 'SHARE', value: 836},
{ name: 'OFFICE365', value: 1090},
{ name: 'LASTPASS', value: 2237},
{ name: 'MIRO', value: 788},
{ name: 'MYDOWNLOADER', value: 1016},
{ name: 'PIOLET', value: 806},
{ name: 'HIGH_ENTROPY', value: 293},
{ name: 'VUZE', value: 859},
{ name: 'LANSHARK', value: 832},
{ name: 'LUKE', value: 839},
{ name: 'JXTA', value: 828},
{ name: 'KINGDOMXXX', value: 843},
{ name: 'QTRAX', value: 837},
{ name: 'RAULKEN', value: 773},
{ name: 'RAYFILE', value: 800},
{ name: 'RMVBUSTERS', value: 817},
{ name: 'SCENEHD', value: 849},
{ name: 'SHAREBOX', value: 835},
{ name: 'SHAREREACTOR', value: 834},
{ name: 'SWEPIRACY', value: 830},
{ name: 'TAMILTHUNDER_COM', value: 789},
{ name: 'USABIT_COM', value: 810},
{ name: 'VECTOR', value: 793},
{ name: 'RUTRACKER', value: 777},
{ name: 'RETROSHARE', value: 786},
{ name: 'SOMUD', value: 801},
{ name: 'PERFECT_DARK', value: 827},
{ name: 'UTORRENT', value: 803},
{ name: 'VPNOVERDNS', value: 905},
{ name: 'IODINE', value: 918},
{ name: 'IFIX', value: 2391},
{ name: 'NETBACKUP', value: 2392},
{ name: 'SENTINEL_SRM', value: 2393},
{ name: 'HONEYWELL_PHD', value: 2394},
{ name: 'HONEYWELL_CDA', value: 2395},
{ name: 'HONEYWELL_FTE', value: 2396},
{ name: 'VNET_IP', value: 2397},
{ name: 'SMB_V1', value: 2398},
{ name: 'SMB_V2', value: 2399},
{ name: 'DELTAV', value: 2400},
{ name: 'PROFINET', value: 2401},
{ name: 'COTP', value: 2402},
{ name: 'S7_COMM', value: 2403},
{ name: 'BACNET_APP', value: 2404},
{ name: 'BACNET_NET', value: 2405},
{ name: 'BACNET_VLC', value: 2406},
{ name: 'PI_AF', value: 2407},
{ name: 'PI_DATA', value: 2408},
{ name: 'ACSE', value: 2409},
{ name: 'AKAMAI', value: 2410},
{ name: 'AMQP', value: 2411},
{ name: 'CAPWAP', value: 2412},
{ name: 'COAP', value: 2413},
{ name: 'DB2', value: 2414},
{ name: 'DRDA', value: 2415},
{ name: 'DSI', value: 2416},
{ name: 'DTLS', value: 2417},
{ name: 'ELASTICSEARCH', value: 2418},
{ name: 'EXACQVISION', value: 2419},
{ name: 'GIOP', value: 2420},
{ name: 'HOTSPOT_SHIELD', value: 2421},
{ name: 'HULU', value: 2422},
{ name: 'ICAP', value: 2423},
{ name: 'IIOP', value: 2424},
{ name: 'INFORMIX', value: 2425},
{ name: 'IPERF', value: 2426},
{ name: 'ISAKMP', value: 2427},
{ name: 'JAVA_RMI', value: 2428},
{ name: 'JETDIRECT', value: 242},
{ name: 'L2TP', value: 2430},
{ name: 'LPR', value: 2431},
{ name: 'MINECRAFT_POCKET', value: 2432},
{ name: 'MOUNT', value: 2433},
{ name: 'MQ', value: 2434},
{ name: 'NBNS', value: 2435},
{ name: 'NFS4', value: 2436},
{ name: 'ORB', value: 2437},
{ name: 'PBX', value: 2438},
{ name: 'PERFORCE', value: 2439},
{ name: 'PORTMAP', value: 2440},
{ name: 'PP', value: 2441},
{ name: 'PPP', value: 2442},
{ name: 'QUIC', value: 2443},
{ name: 'RFB', value: 2444},
{ name: 'ROCKET_LEAGUE', value: 2445},
{ name: 'RPC', value: 2446},
{ name: 'SNAPCHAT', value: 2447},
{ name: 'SP', value: 2448},
{ name: 'SPEEDTEST', value: 2449},
{ name: 'SQLI', value: 2450},
{ name: 'TACACS_PLUS', value: 2451},
{ name: 'TANGO', value: 2452},
{ name: 'TELEGRAM', value: 2453},
{ name: 'TNS', value: 2454},
{ name: 'VIMEO', value: 2455},
{ name: 'VMOTION', value: 2456},
{ name: 'WCCP', value: 2457},
{ name: 'WECHAT', value: 2458},
{ name: 'ZABBIX_AGENT', value: 2459},
{ name: 'ZOOM', value: 2460},
{ name: 'MQTT', value: 2461},
{ name: 'EGD', value: 2462},
{ name: 'SRTP', value: 2463},
{ name: 'TRISTATION', value: 2464},
{ name: 'MODBUS_RTU', value: 2465},
{ name: 'MODBUS_ASCII', value: 2466},
{ name: 'PCCC', value: 2467},
{ name: 'HONEYWELL_SRP', value: 2468},
{ name: 'HONEYWELL_SAFENET', value: 2469},
{ name: 'ABB_RNRP', value: 2470},
{ name: 'ABB_CNCP', value: 2471},
{ name: 'DIGITALSENTRY_VIDEO', value: 2472},
{ name: 'TSAA', value: 2473},
{ name: 'WFBS_SVC', value: 2474},
{ name: 'HONEYWELL_ESERVER', value: 2475},
{ name: 'FF_HSE', value: 2476},
{ name: 'TOPVIEW_REMOTEVIEWER', value: 2477},
{ name: 'SMP', value: 2478},
{ name: 'SAFE_ETHERNET', value: 2479},
{ name: 'MMS_ISO', value: 2480},
{ name: 'MONGO_DB', value: 2481},
{ name: 'WMI', value: 2482},
{ name: 'GE_CIMPLICITY', value: 2483},
{ name: 'PI_AGENT', value: 2484},
{ name: 'PUMPWORKS', value: 2485},
{ name: 'FASP', value: 2486},
{ name: 'HSRP', value: 2487},
{ name: 'SOAP', value: 2488},
{ name: 'NRPE', value: 2489},
{ name: 'BITCOMET_PEX', value: 2490},
{ name: 'MSRPC', value: 2491},
{ name: 'EPM', value: 2492}]
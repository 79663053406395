// // Generated by CoffeeScript 1.12.7
// (function() {
//   var area, axisBottom, axisLeft, axisRight, curveCardinal, curveLinear, curveMonotoneX, curveStepBefore, easeExpOut, easeLinear, line, ref, ref1, ref2, stack;

import _ from 'lodash'
import React from 'react'
import T from 'prop-types'
import ReactDOM from 'react-dom'
import {
  event as d3Event,
  select as d3Select,
  selectAll as d3SelectAll
} from 'd3-selection'
// import d3Select from d3Selection.select;
// import d3SelectAll from d3Selection.selectAll;
import { scaleLinear } from 'd3-scale'
import { axisLeft, axisBottom, axisRight } from 'd3-axis'
import {
  // stack,
  area,
  line,
  curveCardinal,
  curveStepBefore,
  curveMonotoneX,
  curveLinear
} from 'd3-shape'
import { brushX } from 'd3-brush'
import * as d3Time from 'd3-time'
import { easeLinear, easeExpOut } from 'd3-ease'
import { formatNumber } from 'pw-formatters'
import colorUtil from 'ui-base/src/util/colorUtil'
// import util from 'ui-base/src/util/genericUtil'
// import constants from 'pwConstants'
import {getThemeColor} from 'utils/themeUtils'
import {
  getD3TimeScale,
  isUTC,
  getTimelineTickFormat
  // formatDay,
  // formatTime
} from 'utils/timeUtils'

var getD3TimeUnit = function(unit) {
  return d3Time[(isUTC() ? 'utc' : 'time') + _.capitalize(unit)]
}

var moveToBack = function() {
  var firstChild = this.parentNode.firstChild
  if (firstChild) {
    return this.parentNode.insertBefore(this, firstChild)
  }
}

var _formatNumber = function(num) {
  return formatNumber(num, true)
}

var defaultColorStopFunc = function(color, level, bgColor) {
  return [
    {
      stop: 0,
      color: color.darker(1)
    },
    {
      stop: 0.5,
      color: bgColor
    },
    {
      stop: 1.0,
      color: bgColor
    }
  ]
}

var D3_CURVE_INTERPOLATORS = {
  cardinal: curveCardinal,
  'step-before': curveStepBefore,
  monotone: curveMonotoneX,
  linear: curveLinear
}

let _id = 0

class TimelineSingle extends React.Component {
  static displayName = 'TimelineSingle'

  static propTypes = {
    enableTransitions: T.bool,
    graphName: T.string.isRequired,
    itmClassFn: T.func,
    selection: T.shape({
      fromTime: T.number,
      toTime: T.number,
      counterName: T.string
    }),
    onItemMouseOver: T.func,
    onItemMouseOut: T.func,
    onItemClick: T.func,
    onXDomainChange: T.func,
    averagePerInterval: T.number,
    yScaleFormatter: T.func,
    minYScaleCeil: T.number,
    lineInterpolation: T.string,
    isLive: T.bool,
    noAxes: T.bool,
    timelineOptions: T.object,
    initialGraphMode: T.any,
    initialColorSet: T.any,
    fixedYDomain: T.any,
    padding: T.any,
    snapUnits: T.any,
    isFullGraphView: T.any,
    initialDomain: T.any,
    minDims: T.any,
    gradientUnits: T.any,
    xTicks: T.any,
    yTicks: T.any,
    xTickValues: T.any,
    initialBrushExtent: T.any,
    zeroStartAndEnd: T.any,
    barWidthByInterval: T.any,
    barWidthRatio: T.any,
    gradientOrientation: T.any,
    enableBrush: T.any,
    onBrush: T.any,
    onBrushEnd: T.any,
    themeId: T.string
  }

  static defaultProps = {
    enableTransitions: true,
    selection: null,
    initialColorSet: 'threatLevel',
    graphName: 'DEFAULT',
    barWidthRatio: 0.8,
    enableBrush: false,
    initialBrushExtent: [Date.now() - 1, Date.now()],
    initialDomain: [Date.now() - 1, Date.now()],
    isFullGraphView: true,
    minDims: {
      height: 20,
      width: 80
    },
    initialGraphMode: 'Bar',
    streamStackOrder: 'default',
    padding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0
    },
    yScaleFormatter: _formatNumber,
    minYScaleCeil: 4,
    lineInterpolation: 'monotone',
    fixedYDomain: null,
    yTicks: 4,
    xTicks: 4,
    xTickValues: null,
    gradientOrientation: 'vertical',
    gradientUnits: 'objectBoundingBox',
    zeroStartAndEnd: false,
    isLive: false,
    noAxes: false,
    timelineOptions: {
      colors: {}
    }
  }

  UNSAFE_componentWillMount() {
    this.id = ++_id
    this.clipPathId = `kb_timeline_clip_${this.id}`
    this.gradientIds = []
    this._debouncedOnResize = _.debounce(this._onResize, 500)
    window.addEventListener('resize', this._debouncedOnResize)
  }

  componentDidMount() {
    this.isReady = false
    this.graphMode = this.props.initialGraphMode
    this.colorSet = this.props.initialColorSet
    this.latestData = {}
    this.latestDataKeys = []
    this.drawInitialSvg()
    this.isReady = true
    this._prevEndTime = null
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.themeId !== nextProps.themeId) {
      this.updateGradients()
    }
  }

  UNSAFE_componentWillUpdate(nextProps) {
    var selection = nextProps.selection
    if (selection !== this.props.selection) {
      this.graphDataG.selectAll('rect.kb_bar').classed('selected', function(d) {
        if (selection) {
          return (
            d.fromTime === selection.fromTime &&
            d.toTime === selection.toTime &&
            d.name === selection.counterName
          )
        } else {
          return false
        }
      })
    }
    if (!_.isEqual(nextProps.fixedYDomain, this.props.fixedYDomain)) {
      this.yDomain = nextProps.fixedYDomain
      this.resizeGraphs()
    }
  }

  componentWillUnmount() {
    this._isUnmounting = true
    window.removeEventListener('resize', this._debouncedOnResize)
    d3Select(ReactDOM.findDOMNode(this)).selectAll('*').remove()
    if (this.gradientIds.length > 0) {
      return d3SelectAll(this.gradientIds.join(', ')).remove()
    }
  }

  getBgColor = () => {
    return getThemeColor(83)
  }

  updateDims = () => {
    var el = ReactDOM.findDOMNode(this)
    var h = el.offsetHeight - this.props.padding.top - this.props.padding.bottom
    var w = el.offsetWidth - this.props.padding.left - this.props.padding.right
    this.height = h < this.props.minDims.height ? this.props.minDims.height : h
    return (this.width =
      w < this.props.minDims.width ? this.props.minDims.width : w)
  }

  drawInitialSvg = () => {
    this.xTickFormatter = getTimelineTickFormat()
    this.updateDims()
    const clipPathId = this.clipPathId

    var svgEl = d3Select(ReactDOM.findDOMNode(this)).append('svg').attrs({
      class: 'svg_container bar_graph killbox_event_timeline_svg',
      width: '100%',
      height: '100%'
    })

    this.svg = svgEl
      .append('g')
      .attr(
        'transform',
        `translate(${this.props.padding.left}, ${this.props.padding.top})`
      )
    this.graphG = this.svg
      .append('g')
      .attr('class', `${this.props.graphName}_graph`)
    this.clipPath = this.graphG
      .append('clipPath')
      .attr('id', clipPathId)
      .append('rect')
      .attrs({
        width: this.width,
        height: this.height
      })
    this.graphDataG = this.graphG.append('g').attrs({
      class: `${this.props.graphName}_data_group`,
      'clip-path': `url(#${clipPathId})`
    })
    this.updateGradients()
    this.scales = this.createScales(this.props.initialDomain, [0, 4])
    if (this.props.enableBrush) {
      var brushRange = (this.latestBrushRange = this.props.initialBrushExtent)
      this.brush = brushX()
        .on('start', this._onBrush)
        .on('brush', this._onBrush)
        .on('end', this._onBrushEnd)
        .handleSize(4)
        .extent([[0, 0], [this.width, this.height]])
      this.brushEl = this.graphG
        .append('g')
        .attrs({
          class: `brush ${this.props.graphName}`,
          'clip-path': `url(#${clipPathId})`
        })
        .call(this.brush)
      this.setBrush(brushRange)
    }
    if (!this.props.noAxes) {
      this.axisEls = this.createAxisEls()
      this.axes = this.createAxes()
    }
    return this.updateAxes()
  }

  updateGradients = () => {
    var DEFS_ID = `timeline_defs_${this.props.graphName}`
    this.svg.selectAll(`#${DEFS_ID}`).remove()
    this.svg.append('defs').attr('id', DEFS_ID)

    var gradientDims = {
      gradientUnits: this.props.gradientUnits,
      x1: '0%',
      y1: '0%',
      x2: '0%',
      y2: '100%'
    }

    if (this.props.gradientOrientation === 'horizontal') {
      gradientDims = {
        gradientUnits: this.props.gradientUnits,
        x1: '0%',
        y1: '0%',
        x2: '100%',
        y2: '0%'
      }
    }
    var self = this
    if (this.graphMode === 'Stream' || this.graphMode === 'Multi') {
      return _.forIn(this.props.timelineOptions.colors[this.colorSet], function(
        color,
        level
      ) {
        let stops = null
        if (_.isArray(color)) {
          stops = color
        } else {
          stops = defaultColorStopFunc.call(
            self,
            color,
            level,
            self.getBgColor()
          )
        }
        var gradId = `timeline_grad_${level}`
        self.gradientIds.push(`#${gradId}`)
        return colorUtil.appendGradient(
          self.props.gradientOrientation,
          gradId,
          stops,
          DEFS_ID,
          gradientDims
        )
      })
    }
  }

  createScales = (xDomain, yDomain) => {
    this.xDomain = xDomain
    this.yDomain = yDomain
    var d3TimeScale = getD3TimeScale()
    return {
      x: d3TimeScale().domain(xDomain).range([0, this.width]),
      y: scaleLinear().domain(yDomain).range([this.height, 0])
    }
  }

  createAxisEls = () => {
    if (this.props.isFullGraphView) {
      return {
        x: {
          gridAxis: this.graphG.append('g').attrs({
            class: `x grid ${this.props.graphName}`,
            transform: 'translate(0,' + (this.height + 10) + ')',
            'pointer-events': 'none'
          }),
          axis: this.graphG.append('g').attrs({
            class: `x axis ${this.props.graphName}`,
            transform: 'translate(0,' + (this.height + 9) + ')',
            'pointer-events': 'none'
          })
        },
        y: {
          axis: this.graphG.append('g').attrs({
            class: `y axis ${this.props.graphName} left`,
            'pointer-events': 'none'
          })
        },
        y2: {
          axis: this.graphG.append('g').attrs({
            class: `y2 axis ${this.props.graphName} right`,
            transform: 'translate(' + (this.width + 20) + ', 0)',
            'pointer-events': 'none'
          })
        }
      }
    } else {
      return {
        x: {
          gridAxis: this.graphG.append('g').attrs({
            class: `x grid ${this.props.graphName}`,
            transform: `translate(0, ${this.height})`,
            'pointer-events': 'none'
          }),
          axis: this.graphG.append('g').attrs({
            class: `x axis ${this.props.graphName}`,
            'clip-path': `url(#${this.clipPathId})`,
            'pointer-events': 'none'
          })
        }
      }
    }
  }

  createAxes = () => {
    if (this.props.isFullGraphView) {
      return {
        x: {
          gridAxis: axisBottom()
            .ticks(this.props.xTicks)
            .tickSizeInner(-(this.height + 10))
            .tickSizeOuter(0)
            .tickFormat(''),
          axis: axisBottom()
            .ticks(this.props.xTicks)
            .tickFormat(this.xTickFormatter)
            .tickPadding(5)
            .tickValues(this.props.xTickValues)
            .tickSizeInner(5)
        },
        y: {
          axis: axisLeft()
            .ticks(this.props.yTicks)
            .tickSize(0)
            .tickPadding(10)
            .tickFormat(this.props.yScaleFormatter)
        },
        y2: {
          axis: axisRight()
            .ticks(this.props.yTicks)
            .tickSize(0)
            .tickPadding(10)
            .tickFormat(this.props.yScaleFormatter)
        }
      }
    } else {
      return {
        x: {
          gridAxis: axisBottom()
            .ticks(Math.round(this.width / 100))
            .tickSizeInner(-this.height)
            .tickSizeOuter(0)
            .tickFormat(''),
          axis: axisBottom()
            .ticks(
              this.props.snapUnits ? getD3TimeUnit(this.props.snapUnits) : 3
            )
            .ticks(Math.round(this.width / 150))
            .tickFormat(this.xTickFormatter)
            .tickPadding(0)
            .tickSizeInner(16)
        },
        y: {
          axis: axisLeft().ticks(2).tickFormat(_formatNumber)
        }
      }
    }
  }

  _onResize = () => {
    this.updateDims()
    this.resizeGraphs()
    return this.updateGradients()
  }

  updateAxes = (nextTransition = false) => {
    if (!this.axes) {
      return
    }
    this.axes.x.gridAxis.scale(this.scales.x)
    this.axes.x.axis.scale(this.scales.x)
    this.axes.y.axis.scale(this.scales.y)
    var t = this.svg.transition().duration(0)
    if (nextTransition && this.props.enableTransitions) {
      t = this.svg.transition().ease(easeLinear).duration(nextTransition)
    }
    t.select(`.x.${this.props.graphName}.grid`).call(this.axes.x.gridAxis)
    t.select(`.x.${this.props.graphName}.axis`).call(this.axes.x.axis)
    if (this.props.isFullGraphView) {
      if (this.props.enableTransitions) {
        t
          .transition()
          .duration(400)
          .ease(easeExpOut)
          .select(`.y.${this.props.graphName}.axis`)
          .call(this.axes.y.axis)
        return t
          .transition()
          .duration(400)
          .ease(easeExpOut)
          .select(`.y2.${this.props.graphName}.axis`)
          .call(this.axes.y.axis)
      } else {
        t.select(`.y.${this.props.graphName}.axis`).call(this.axes.y.axis)
        return t
          .select(`.y2.${this.props.graphName}.axis`)
          .call(this.axes.y.axis)
      }
    }
  }

  setViewMode = newMode => {
    if (newMode !== this.graphMode) {
      this.graphMode = newMode
      this.clearGraph()
      return this.updateGradients()
    }
  }

  setColorSet = newColorSet => {
    this.clearGraph()
    this.colorSet = newColorSet
    return this.updateGradients()
  }

  updateGraph = (
    data = this.latestData,
    nextTransition = false,
    redraw = false,
    newXDomain,
    newKeys,
    forcedYDomain,
    ...rest
  ) => {
    var j, len, ref4
    var xDomain = newXDomain ? newXDomain : [data.startTime, data.endTime]
    let yDomain = null
    if (
      _.some(xDomain, function(t) {
        return t <= 0
      })
    ) {
      return
    }

    const args = [
      data,
      nextTransition,
      redraw,
      newXDomain,
      newKeys,
      forcedYDomain
    ].concat(rest)

    var renderingData = {
      args: JSON.stringify(args),
      props: this.props
    }

    var lastRenderingData = this._lastRenderingData
    if (
      lastRenderingData &&
      lastRenderingData.args === renderingData.args &&
      lastRenderingData.props === this.props
    ) {
      return
    }
    this._lastRenderingData = renderingData
    if (!(!this._isUnmounting && (data != null ? data.history : void 0))) {
      return
    }
    this.latestDataKeys = newKeys
      ? newKeys
      : _.without(_.keys(_.first(data.history)), 'timestamp', 'total')
    if (this.props.zeroStartAndEnd) {
      var lastIdx = data.history.length - 1
      var ref3 = this.latestDataKeys
      for (j = 0, len = ref3.length; j < len; j++) {
        var dataKey = ref3[j]
        data.history[0][dataKey] = 0
        data.history[lastIdx][dataKey] = 0
      }
    }
    this.latestData = data
    this.latestXDomain = xDomain
    var onXDomainChange = this.props.onXDomainChange
    if (onXDomainChange) {
      var lastXDomain = this.xDomain
      if (
        !lastXDomain ||
        lastXDomain[0] !== xDomain[0] ||
        lastXDomain[1] !== xDomain[1]
      ) {
        this.props.onXDomainChange(xDomain)
      }
    }
    var maxVal = this.latestData.maxValue
    var isGraphModeMultiLine = this.graphMode === 'Multi'
    if (newKeys || isGraphModeMultiLine) {
      var valFn = d => {
        var add = function(out, key) {
          return out + (d[key] || 0)
        }

        var flatMax = function(out, key) {
          return Math.max(d[key], out)
        }

        return _.reduce(
          this.latestDataKeys,
          isGraphModeMultiLine ? flatMax : add,
          0
        )
      }

      maxVal = _.max(_.map(this.latestData.history, valFn))
    }
    if (this.props.averagePerInterval && this.props.averagePerInterval > 0) {
      maxVal =
        maxVal / (this.latestData.interval / this.props.averagePerInterval)
    }
    if (forcedYDomain != null) {
      yDomain = forcedYDomain
    } else if (this.props.fixedYDomain) {
      yDomain = this.props.fixedYDomain
    } else {
      yDomain = [
        0,
        maxVal > 3 ? maxVal + maxVal * 0.02 : this.props.minYScaleCeil
      ]
    }
    this.numPoints =
      (ref4 = this.latestData.history) != null ? ref4.length : void 0
    this.scales = this.createScales(xDomain, yDomain)
    this.updateAxes(nextTransition)
    this.updateBrushes(nextTransition)
    if (redraw) {
      switch (this.graphMode) {
        case 'Bar':
          return this.drawStackedBarGraph(nextTransition)
        case 'Stream':
          return this.drawStreamGraph(nextTransition)
        case 'Multi':
          return this.drawMultilineGraph(nextTransition)
      }
    } else {
      switch (this.graphMode) {
        case 'Bar':
          var bars = this.graphDataG.selectAll('g.kb_bar_group')
          if (this.props.enableTransitions) {
            return bars
              .transition()
              .duration(nextTransition ? nextTransition : 0)
              .attr('transform', d => {
                return `translate(${this.scales.x(d.timestamp)},0)`
              })
              .selectAll('rect')
              .attr('width', this.getBarWidth())
          } else {
            return bars
              .attr('transform', d => {
                return `translate(${this.scales.x(d.timestamp)},0)`
              })
              .selectAll('rect')
              .attr('width', this.getBarWidth())
          }
        case 'Stream':
          return this.graphDataG.selectAll('.stream_stack').attr('d', d => {
            return this.streamArea(d.values)
          })
        case 'Multi':
          return this.graphDataG.selectAll('.multi_line').attr('d', d => {
            return this.line(d.values)
          })
      }
    }
  }

  updateBrushes = () => {
    if (this.brush) {
      this.brush.extent([[0, 0], [this.width, this.height]])
    }
    if (this.latestBrushRange) {
      return this.setBrush(this.latestBrushRange)
    }
  }

  getBarWidth = () => {
    var numPts = this.numPoints
    if (this.props.barWidthByInterval) {
      var tSpan = this.scales.x.domain()[1] - this.scales.x.domain()[0]
      numPts = tSpan / this.latestData.interval
    }
    return this.props.barWidthRatio * (this.scales.x.range()[1] / numPts)
  }

  navigateSelection = dir => {
    var currentSelection = this.props.selection
    if (currentSelection && this.graphMode === 'Bar' && this.latestData) {
      let fromTime = currentSelection.fromTime
      let counterName = currentSelection.counterName
      // ;(fromTime = currentSelection.fromTime), (counterName =
      // currentSelection.counterName)

      var history = this.latestData.history.filter(function(hist) {
        return _(hist.mapping).pluck('count').sum() > 0
      })

      var historyIndex = _.findIndex(history, function(item) {
        return item.timestamp === fromTime
      })

      let items = null
      let itemIndex = null

      if (historyIndex >= 0) {
        switch (dir) {
          case 'up':
          case 'down':
            items = history[historyIndex].mapping.filter(function(item) {
              return item.count > 0
            })

            itemIndex = _.findIndex(items, function(item) {
              return item.name === counterName
            })

            if (itemIndex >= 0) {
              if (dir === 'up') {
                itemIndex++
                if (itemIndex >= items.length) {
                  itemIndex = 0
                }
              } else {
                itemIndex--
                if (itemIndex < 0) {
                  itemIndex = items.length - 1
                }
              }
              counterName = items[itemIndex].name
            }
            break
          case 'left':
          case 'right':
            if (dir === 'left') {
              historyIndex--
              if (historyIndex < 0) {
                historyIndex = history.length - 1
              }
            } else {
              historyIndex++
              if (historyIndex >= history.length) {
                historyIndex = 0
              }
            }
            fromTime = history[historyIndex].timestamp
            items = history[historyIndex].mapping

            itemIndex = _.findIndex(items, function(item) {
              return item.name === counterName
            })

            while (items[itemIndex].count < 1) {
              itemIndex = itemIndex > 0 ? itemIndex - 1 : items.length - 1
              counterName = items[itemIndex].name
            }
        }

        var nextRect = this.graphDataG
          .selectAll('rect.kb_bar')
          .filter(function(d) {
            return d.fromTime === fromTime && d.name === counterName
          })

        if (nextRect.node()) {
          var data = this._getDataForEvent(nextRect.node())
          if (data) {
            const fakeEvent = {
              target: nextRect.node()
            }
            this.props.onItemClick(data, fakeEvent)
          }
        }
      }
    }
  }

  drawStackedBarGraph = (nextTransition = false) => {
    if (
      _.isUndefined(this.latestData.history) ||
      _.isEmpty(this.latestData.history) ||
      _.isEmpty(this.latestDataKeys)
    ) {
      this.clearGraph()
      return
    }
    var self = this
    var props = this.props
    var selection = props.selection

    var data = _.map(this.latestData.history, d => {
      var y0 = 0
      var fromTime = d.timestamp
      var toTime = fromTime + this.latestData.interval
      d.mapping = _.map(this.latestDataKeys, function(name) {
        return {
          fromTime: fromTime,
          toTime: toTime,
          count: d[name],
          name: name.replace(/\./g, '_'),
          y0: y0,
          y1: (y0 += d[name]),
          selected:
            selection != null &&
            fromTime === selection.fromTime &&
            toTime === selection.toTime &&
            name === selection.counterName
        }
      })
      return d
    })

    var barGroups = this.graphDataG
      .selectAll('g.kb_bar_group')
      .data(data, function(d) {
        return `bg_${d.timestamp}`
      })

    barGroups.exit().remove()

    barGroups = barGroups
      .enter()
      .append('g')
      .attrs({
        class: 'kb_bar_group',
        transform: d => {
          return `translate(${this.scales.x(d.timestamp)},0)`
        }
      })
      .merge(barGroups)

    if (this.props.enableTransitions) {
      barGroups.transition().duration(nextTransition).ease(easeLinear).attrs({
        transform: d => {
          return `translate(${this.scales.x(d.timestamp)},0)`
        }
      })
    } else {
      barGroups.attrs({
        transform: d => {
          return `translate(${this.scales.x(d.timestamp)},0)`
        }
      })
    }

    var bars = barGroups.selectAll('rect.kb_bar').data(
      function(d) {
        return d.mapping.filter(function(m) {
          return m.count > 0
        })
      },
      function(d) {
        return `bar_${d.name}`
      }
    )

    bars.exit().remove()

    bars = bars
      .enter()
      .append('rect')
      .attrs({
        class(d, i) {
          return `kb_bar lvl_${d.name} ${self.props.itmClassFn
            ? self.props.itmClassFn(d.name, i)
            : ''} ${d.selected ? ' selected' : ''}`
        },
        width: this.getBarWidth(),
        height: 0,
        y: this.scales.y(0)
      })
      .merge(bars)

    if (this.props.enableTransitions) {
      return bars.transition().duration(400).ease(easeExpOut).attrs({
        width: this.getBarWidth(),
        y: d => {
          return this.scales.y(d.y1 || 0)
        },
        height: d => {
          return this.scales.y(d.y0 || 0) - this.scales.y(d.y1 || 0)
        },
        class(d, i) {
          return `kb_bar lvl_${d.name} ${self.props.itmClassFn
            ? self.props.itmClassFn(d.name, i)
            : ''} ${d.selected ? ' selected' : ''}`
        }
      })
    } else {
      return bars.attrs({
        width: this.getBarWidth(),
        y: d => {
          return this.scales.y(d.y1 || 0)
        },
        height: d => {
          return this.scales.y(d.y0 || 0) - this.scales.y(d.y1 || 0)
        },
        class(d, i) {
          return `kb_bar lvl_${d.name} ${self.props.itmClassFn
            ? self.props.itmClassFn(d.name, i)
            : ''} ${d.selected ? ' selected' : ''}`
        }
      })
    }
  }

  drawStreamGraph = (nextTransition = false) => {
    if (
      _.isUndefined(this.latestData.history) ||
      _.isEmpty(this.latestData.history) ||
      _.isEmpty(this.latestDataKeys)
    ) {
      this.clearGraph()
      return
    }
    var self = this
    this.streamArea = area()
      .curve(D3_CURVE_INTERPOLATORS[this.props.lineInterpolation])
      .x(function(d) {
        return self.scales.x(d.timestamp)
      })
      .y0(function(d) {
        return self.scales.y(d.y0)
      })
      .y1(function(d) {
        return self.scales.y(d.y1)
      })
    var tickTotals = {}

    var levels = this.latestDataKeys.map(function(name) {
      return {
        values: self.latestData.history.map(function(d) {
          var _rawVal = d[name] || 0
          var _val = self.props.averagePerInterval && self.props.averagePerInterval > 0
            ? _rawVal /
              (self.latestData.interval / self.props.averagePerInterval)
            : _rawVal
          tickTotals[d.timestamp] = tickTotals[d.timestamp] || 0
          return {
            timestamp: d.timestamp,
            y0: tickTotals[d.timestamp],
            y1: (tickTotals[d.timestamp] += _val)
          }
        }),
        name: name.replace(/\./g, '_')
      }
    })

    var levelStacks = this.graphDataG
      .selectAll('.stream_stack')
      .data(levels, function(d) {
        return `f_${d.name}`
      })

    levelStacks.exit().remove()

    levelStacks = levelStacks
      .enter()
      .append('path')
      .attr('class', function(d, i) {
        return `stream_stack lvl_${d.name} ${self.props.itmClassFn
          ? self.props.itmClassFn(d.name, i)
          : ''}`
      })
      .each(moveToBack)
      .merge(levelStacks)

    if (nextTransition && this.props.enableTransitions) {
      levelStacks
        .attrs({
          d(d) {
            return self.streamArea(d.values)
          },
          transform: null
        })
        .transition()
        .duration(nextTransition)
        .ease(easeLinear)
        .attrs({
          transform: `translate(${this.scales.x(
            this.xDomain[0] - this._getAnimationOffset()
          )},0)`
        })
    } else {
      levelStacks.attrs({
        d(d) {
          return self.streamArea(d.values)
        },
        transform: `translate(${this.scales.x(
          this.xDomain[0] - this._getAnimationOffset()
        )},0)`
      })
    }
    return (this._prevEndTime = this.latestData.endTime)
  }

  _getAnimationOffset = () => {
    if (
      this.props.isLive &&
      this._prevEndTime !== null &&
      this.latestData.endTime !== this._prevEndTime
    ) {
      return this.latestData.interval
    } else {
      return 0
    }
  }

  drawMultilineGraph = (nextTransition = false) => {
    if (
      _.isUndefined(this.latestData.history) ||
      _.isEmpty(this.latestData.history) ||
      _.isEmpty(this.latestDataKeys)
    ) {
      this.clearGraph()
      return
    }
    var self = this
    this.line = line()
      .curve(D3_CURVE_INTERPOLATORS[this.props.lineInterpolation])
      .x(function(d) {
        return self.scales.x(d.timestamp)
      })
      .y(function(d) {
        return self.scales.y(d.y)
      })
    var tickTotals = {}

    var lines = this.latestDataKeys.map(function(name) {
      return {
        values: self.latestData.history.map(function(d) {
          var _rawVal = d[name] || 0
          var _val = self.props.averagePerInterval && self.props.averagePerInterval > 0
            ? _rawVal /
              (self.latestData.interval / self.props.averagePerInterval)
            : _rawVal
          tickTotals[d.timestamp] = tickTotals[d.timestamp] || 0
          return {
            timestamp: d.timestamp,
            y: _val
          }
        }),
        name: name.replace(/\./g, '_')
      }
    })

    var multiLines = this.graphDataG
      .selectAll('.multi_line')
      .data(lines, function(d) {
        return `f_${d.timestamp}_${d.name}`
      })

    multiLines.exit().remove()

    multiLines = multiLines
      .enter()
      .append('path')
      .attr('class', function(d, i) {
        return `multi_line lvl_${d.name} ${self.props.itmClassFn
          ? self.props.itmClassFn(d.name, i)
          : ''}`
      })
      .each(moveToBack)
      .merge(multiLines)

    if (nextTransition && this.props.enableTransitions) {
      multiLines
        .attrs({
          d(d) {
            return self.line(d.values)
          },
          transform: null
        })
        .transition()
        .duration(nextTransition)
        .ease(easeLinear)
        .attrs({
          transform: `translate(${this.scales.x(
            this.xDomain[0] - this._getAnimationOffset()
          )},0)`
        })
    } else {
      multiLines.attrs({
        d(d) {
          return self.line(d.values)
        },
        transform: `translate(${this.scales.x(
          this.xDomain[0] - this._getAnimationOffset()
        )},0)`
      })
    }
    return (this._prevEndTime = this.latestData.endTime)
  }

  clearGraph = () => {
    return this.graphDataG.selectAll('*').remove()
  }

  skipBrushEvents = 0

  setBrush = newRange => {
    if (!(this.brushEl && newRange && newRange.length)) {
      return
    }
    var sanitizedDateRange = [new Date(newRange[0]), new Date(newRange[1])]
    if (!(newRange && this.isValidDateRange(sanitizedDateRange))) {
      return
    }
    this.skipBrushEvents++
    this.brushEl.call(this.brush.move, newRange.map(this.scales.x))
    this.skipBrushEvents--
    return (this.latestBrushRange = newRange)
  }

  isValidDateRange = range => {
    return (
      range != null &&
      range.length === 2 &&
      _.isDate(range[0]) &&
      _.isDate(range[1])
    )
  }

  _onBrushChange = (newBrushRange, callback) => {
    if (newBrushRange) {
      var timeRange = newBrushRange.map(this.scales.x.invert)
      this.latestBrushRange = timeRange
      var units = this.props.snapUnits
      if (units) {
        var snappedTimeRange = this._snapExtent(timeRange, units)
        if (!_.isEqual(snappedTimeRange, timeRange)) {
          timeRange = snappedTimeRange
          this.setBrush(timeRange)
        }
      }
      if (callback) {
        return callback(timeRange)
      }
    }
  }

  _onBrush = () => {
    if (!this.skipBrushEvents) {
      var range = (this._lastBrushSelection = d3Event.selection)
      return this._onBrushChange(range, this.props.onBrush)
    }
  }

  _onBrushEnd = () => {
    if (!this.skipBrushEvents) {
      var range = d3Event.selection || this._lastBrushSelection
      delete this._lastBrushSelection
      return this._onBrushChange(range, this.props.onBrushEnd)
    }
  }

  _snapExtent = (extent, units) => {
    if (!extent) {
      return
    }
    var extentSnapped = extent.map(getD3TimeUnit(units).round)
    if (extentSnapped[0] >= extentSnapped[1]) {
      extentSnapped[0] = getD3TimeUnit(units).floor(extent[0])
      extentSnapped[1] = getD3TimeUnit(units).ceil(extent[1])
    }
    return extentSnapped
  }

  _onMouseMove = e => {
    return this._fireItemEvent('onItemMouseMove', e)
  }

  _onMouseOver = e => {
    return this._fireItemEvent('onItemMouseOver', e)
  }

  _onMouseOut = e => {
    return this._fireItemEvent('onItemMouseOut', e)
  }

  _onClick = e => {
    return this._fireItemEvent('onItemClick', e)
  }

  _fireItemEvent = (handlerName, e) => {
    var handler = this.props[handlerName]
    if (handler) {
      var data = this._getDataForEvent(e.target, e)
      if (data) {
        return handler(data, e)
      }
    }
  }

  _getDataForEvent = (el, e) => {
    // var el = e.target
    var elClass = el.getAttribute('class')
    if (
      el.tagName === 'rect' &&
      elClass != null &&
      elClass.indexOf('kb_bar') >= 0
    ) {
      return _.pick(d3Select(el).datum(), 'fromTime', 'toTime', 'name', 'count')
    } else if (
      el.tagName === 'path' &&
      elClass != null &&
      elClass.indexOf('stream_stack') >= 0
    ) {
      var x = e.nativeEvent.offsetX || e.nativeEvent.layerX
      var mouseTime = +this.scales.x.invert(x - this.props.padding.left)
      var srcData = d3Select(el).datum()
      var data = this.getDataAtTime(mouseTime, srcData.values, 'y')
      if (data === null) {
        return null
      }
      return _.assign(_.pick(d3Select(el).datum(), 'name', 'count'), {
        fromTime: mouseTime - 1000,
        toTime: mouseTime,
        name: srcData.name,
        count: data
      })
    }
  }

  getDataAtTime = (targetTime, overrideValues, specificKey) => {
    var i, j, ref3
    let output = 0
    var srcVals =
      overrideValues != null ? overrideValues : this.latestData.history
    if (!srcVals) {
      return null
    }
    if (
      !(
        srcVals &&
        srcVals.length >= 2 &&
        srcVals[1].timestamp &&
        srcVals[0].timestamp
      )
    ) {
      return
    }
    var offset = (srcVals[1].timestamp - srcVals[0].timestamp) / 2
    for (
      i = j = 0, ref3 = srcVals.length;
      0 <= ref3 ? j <= ref3 : j >= ref3;
      i = 0 <= ref3 ? ++j : --j
    ) {
      var val = srcVals[i]
      if (!val) {
        return 0
      }
      if (val.timestamp >= targetTime) {
        output =
          val.timestamp - targetTime < offset || i === 0 ? val : srcVals[i - 1]
        break
      }
    }
    if (specificKey != null) {
      return output[specificKey]
    } else {
      return output
    }
  }

  resizeGraphs = () => {
    var pad = this.props.isFullGraphView ? 10 : 0
    this.scales = this.createScales(this.xDomain, this.yDomain)
    this.clipPath.attrs({
      width: this.width,
      height: this.height
    })
    if (this.axes) {
      this.axisEls.x.gridAxis.attr(
        'transform',
        'translate(0,' + (this.height + pad) + ')'
      )
      this.axisEls.x.axis.attr(
        'transform',
        'translate(0,' + (this.height + 9) + ')'
      )
      this.axes.x.gridAxis.tickSizeInner(-(this.height + pad))
      if (this.props.isFullGraphView) {
        this.axisEls.y2.axis.attr(
          'transform',
          'translate(' + (this.width + 20) + ', 0)'
        )
      }
    }
    if (this.props.enableBrush) {
      this.updateBrushes()
    }
    this.updateAxes()
    if (this.isReady && this._lastRenderingData) {
      return this.updateGraph(this.latestData, false, false, this.latestXDomain)
    }
  }

  render() {
    return (
      <div
        className="timeline_single"
        onMouseOver={this._onMouseOver}
        onMouseMove={this._onMouseMove}
        onMouseOut={this._onMouseOut}
        onClick={this._onClick}
      />
    )
  }
}

export default TimelineSingle
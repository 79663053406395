/*
 * IDs for elements used for unit/integration tests
 */
// export default {
//   login: {
//     email: 'email',
//     password: 'password',
//     submitBtn: 'loginSubmit',
//     errorMsg: 'login_errors',
//     resetPwLink: 'resetPw'
//   },
//   pwReset: {
//     main: 'pw_reset',
//     email: 'reset_email',
//     submitBtn: 'reset_submit',
//     successMsg: 'email_succeeded',
//     errorMsg: 'email_error',
//     invalidKey: 'invalid_reset_key'
//   },
//   hud: {
//     main: 'pw_hud'
//   }
// }

export var login = {
  email: 'email',
  password: 'password',
  submitBtn: 'loginSubmit',
  errorMsg: 'login_errors',
  resetPwLink: 'resetPw'
}

export var pwReset = {
  main: 'pw_reset',
  email: 'reset_email',
  submitBtn: 'reset_submit',
  successMsg: 'email_succeeded',
  errorMsg: 'email_error',
  invalidKey: 'invalid_reset_key'
}

export var hud = {
  main: 'pw_hud'
}

import _ from 'lodash'
import T from 'prop-types'
import React from 'react'
import {DialogContainer, TabContent, TabContentPane} from 'react-base'
import {locationShape, getMarkerForLocation} from 'utils/locationUtils'
import LocationEditor from './LocationEditor'
import {
  LocationManagement
} from 'components/Loadables'

export default class extends React.Component {
  static displayName = 'LocationManagementDialog';

  static propTypes = {
    currentSelectedLocation: locationShape,
    hasPermission: T.func,
    onClose: T.func.isRequired,
    onSelect: T.func
  };

  state = {
    editingLocation: null,
    selectedLocation: this.props.currentSelectedLocation || null,
    selectedLocationId: _.get(this.props.currentSelectedLocation, 'id', null),
    activeView: 'manage'
  };

  _onSaveClick = () => {
    if (this.props.onSelect && this.state.selectedLocation) {
      this.props.onSelect(this.state.selectedLocation)
    }
    this.close()
  };

  _onLocationSelect = (location) => {
    this.setState({
      selectedLocation: location,
      selectedLocationId: location.id
    })
  };

  _onAddLocationClick = () => {
    this.setState({
      activeView: 'edit',
      editingLocation: null
    })
  };

  _onLocationEditClick = (location) => {
    this.setState({
      activeView: 'edit',
      editingLocation: location
    })
  };

  _onEditorClosed = () => {
    this.setState({
      activeView: 'manage',
      editingLocation: location
    })
  };

  close = () => {
    this.props.onClose()
  };

  render() {
    let {props, state} = this
    let isDirty = state.selectedLocationId != null //&& state.selectedLocationId !== props.currentSelectedLocation.id

    return (
      <DialogContainer
        modal
        onMaskClick={ this.close }
      >
        <div className="location_editor_dialog location_management_dialog modal_dialog">
          <span
            className="remove_btn"
            onClick={ this.close }
          />
          <TabContent
            activeTabKey={ state.activeView }
            className="flex_1"
          >
            <TabContentPane key="manage">
              <div className="location_management_dialog_ct">
                <div>
                  <h1>{ `Manage Locations` }</h1>
                </div>
                <div className="map_selection_overlay_content">
                  <LocationManagement
                    dialogMode
                    hasPermission={ props.hasPermission }
                    onAddLocationClick={ this._onAddLocationClick }
                    onLocationEditClick={ this._onLocationEditClick }
                    onLocationSelected={ this._onLocationSelect }
                    selectedLocationId={ state.selectedLocationId }
                    selectionMode={ _.isFunction(props.onSelect) }
                  />
                </div>
                <div className="dialog_buttons">
                  <button
                    className="btn btn-large"
                    onClick={ this.close }
                  >Cancel</button>
                  <div className="dialog_primary_button_wrap">
                    <button
                      className={ `btn btn-${ isDirty ? 'primary' : 'default' }` }
                      disabled={ !isDirty }
                      onClick={ this._onSaveClick }
                    >Save and Close</button>
                  </div>
                </div>
              </div>
            </TabContentPane>
            <TabContentPane key="edit">
              <LocationEditor
                dialogMode
                hasPermission={ props.hasPermission }
                location={ state.editingLocation }
                onClose={ this._onEditorClosed }
              />
            </TabContentPane>
          </TabContent>
        </div>
      </DialogContainer>
    )
  }
}

import _ from 'lodash'
import genericUtil from 'ui-base/src/util/genericUtil'
import statsLite from 'stats-lite'
import { simple } from 'moving-averages'

const { durations } = genericUtil

// const INCLUDE_CURRENT_VALUE_IN_STDDEV = true // If true, latest value will be added into collection used for deriving distribution stats

// const COUNT_FACET_PIVOT = `listId,status,mode`

/**
 * Convert time-bucketed facets (from v2 search API) to a more standard time-series data structure
 * @param {*} facetData
 *
 * In:
 *   {counts: {}, start:N, end:N, gap:"+Nunit"}
 *
 * Out:
 *   {maxValue:N, intervalMs:N, timeSeriesData:[{timestamp:N, value:N}], total:N, startTime:N, endTime:N}
 */
export function timeFacetsToTimeSeries(facetData) {
  if (!facetData) {
    return {}
  }

  let {counts, start, end, gap} = facetData

  // Validate
  if (!_.isObject(counts) || !_.isNumber(start) || !_.isNumber(end)) {
    console.error('unexpected date facet response:', facetData)
    return {}
  }

  // Parse the 'gap' string into interval unit and size
  const gapMatch = _.isString(gap) && gap.match(/^\+([1-9][0-9]*)\.?([A-Za-z]+)$/)
  if (!gapMatch || gapMatch.length !== 3) {
    console.error('could not parse "gap": ' + gap)
    return {}
  }

  const intervalMs = durations(gapMatch[1], gapMatch[2].toLowerCase())

  let maxValue = 0
  let total = 0

  // Convert key-val counts to array of {timestamp, value}, filling in missing intervals
  counts = _.mapKeys(counts, (val, time) => +new Date(time))
  const timeSeriesData = []
  for (let t = start; t <= end; t += intervalMs) {
    const value = counts[t] || 0
    timeSeriesData.push({
      timestamp: t,
      value
    })
    if (value > maxValue) maxValue = value
    total += value
  }

  return {
    maxValue,
    intervalMs,
    timeSeriesData,
    total,
    startTime: start,
    endTime: end
  }
}


/**
 * Given a list of time series, correlate them by timestamp and pass all the values from
 * each through an aggregator function to produce a single time series. The timestamps and
 * value keys are expected to be equal across the timelines or they won't line up.
 *
 * @param {Array} timelines - the timelines to aggregate. Each is an array of objects
 *        with at a `timestamp` property and any other arbitrary numberic properties.
 * @param {Function} combineFn - how to combine the values. Will be passed the corresponding
 *        values from a segment in the same order as `timelines` were passed in.
 *
 * Example:
 *
 *     const ts1 = [{timestamp: 1234567890, bob: 80, frank: 22}]
 *     const ts2 = [{timestamp: 1234567890, bob: 10, frank: 1}]
 *     const combined = combineTimeSeries([ts1, ts2], (val1, val2) => val1 - val2)
 *     // result => [{timestamp: 1234567890, bob: 70, value2: 21}]
 */
export function combineTimeSeries(timelines, combineFn) {
  // Index by timestamps and value keys
  const byTimestamp = Object.create(null)
  timelines.forEach((timeline, i) => {
    timeline.forEach(interval => {
      const byKey = (byTimestamp[interval.timestamp] || (byTimestamp[interval.timestamp] = Object.create(null)))
      _.forOwn(interval, (value, key) => {
        if (key !== 'timestamp') {
          const vals = byKey[key] || (byKey[key] = _.fill(new Array(timelines.length), 0)) //ensure no sparse vals
          vals[i] = value
        }
      })
    })
  })
  return Object.keys(byTimestamp).sort().map(timestamp => {
    const segment = {timestamp: +timestamp}
    _.forOwn(byTimestamp[timestamp], (values, key) => {
      segment[key] = combineFn(...values)
    })
    return segment
  })
}



/**
 * Given an array of standard timeSeriesData ticks ([{timestamp: <timestamp>, value: <num>}...]),
 * and a repeat interval in milliseconds, return
 * @param {*} timeSeriesData
 * @param {*} repeatInterval
 */
// export function mapStandardDeviationByInterval (timeSeriesData, endTime, windowMs) {
//   if (!timeSeriesData || timeSeriesData.length === 0) {
//     return
//   }
//   const windowEnd = endTime //_.first(timeSeriesData).timestamp
//   const windowStart = windowEnd - windowMs

//   let valuesByTimestamp = {} // map of timestamp to count/value
//   let output = {}

//   for (let i = 0, iLen = timeSeriesData.length; i < iLen; i++) {
//     const tick = timeSeriesData[i]

//     // console.log('tick', tick, tick.timestamp < windowStart, new Date(tick.timestamp), new Date(windowStart))

//     if (tick.timestamp < windowStart) {
//       // historical value, cache
//       valuesByTimestamp[tick.timestamp] = tick.value
//     }
//     else {
//       // add to output array
//       output[tick.timestamp] = {
//         currentTimeDEBUG: new Date(tick.timestamp),
//         currentValue: tick.value,
//         priorSamples: [],
//         stdDev: null,
//         variance: null
//       }
//     }
//   }

//   // console.log('valuesByTimestamp', valuesByTimestamp)
//   console.log('output', output)
//   let maxValueAll = 0

//   // With cache filled, now group all prior values by windowMs into the current
//   for (let outputTimestamp in output) {
//     if (output.hasOwnProperty(outputTimestamp)) {
//       let outputTick = output[outputTimestamp]
//       let histTime = outputTimestamp - windowMs
//       let _valuesOnly = []

//       if (INCLUDE_CURRENT_VALUE_IN_STDDEV) {
//         _valuesOnly.push(outputTick.currentValue)
//       }

//       while (valuesByTimestamp.hasOwnProperty(histTime)) {
//         _valuesOnly.push(valuesByTimestamp[histTime])
//         outputTick.priorSamples.push({
//           timeStringDEBUG: new Date(histTime).toString(),
//           timeStamp: histTime,
//           value: valuesByTimestamp[histTime]
//         })
//         histTime -= windowMs // Continue walking backwards until there are no data points left
//       }

//       outputTick.variance = statsLite.variance(_valuesOnly)
//       outputTick.stdDev = isNaN(outputTick.variance) ? 0 : Math.sqrt(outputTick.variance)
//       outputTick.mean = statsLite.mean(_valuesOnly)
//       outputTick.mean = isNaN(outputTick.mean) ? 0 : outputTick.mean

//       outputTick.upperWhisker = outputTick.mean + outputTick.stdDev
//       outputTick.upperQuartile = statsLite.percentile(_valuesOnly, 0.75)
//       outputTick.lowerQuartile = statsLite.percentile(_valuesOnly, 0.25)
//       outputTick.lowerWhisker = outputTick.mean - outputTick.stdDev

//       // console.log('maxval', outputTick.variance, outputTick.mean, maxValueAll, outputTick.upperWhisker, outputTick.currentValue)

//       maxValueAll = Math.max(maxValueAll, outputTick.upperWhisker, outputTick.currentValue)

//       // outputTick.unitsOfDeviationFromMean = 1 / ((outputTick.currentValue - outputTick.mean) / outputTick.stdDev)
//       outputTick.unitsOfDeviationFromMean = 1 / (outputTick.stdDev / (outputTick.currentValue - outputTick.mean))
//       // outputTick.stdDev2 = statsLite.stdev(_valuesOnly)
//     }
//   }

//   return {
//     timeSeriesData: output,
//     maxValueAll
//   }
// }

export function getDevianceFromMovingAverages(params = {}) {
  const {
    movingAverages = [],
    // endTime,
    intervalMs,
    // startTime,
    windowSpan
  } = params
  if (!movingAverages || movingAverages.length === 0) {
    return
  }
  // const windowEnd = endTime //_.first(timeSeriesData).timestamp
  // const windowStart = windowEnd - windowSpan // Visible "current" window

  // const windowStartDEBUG = new Date(startTime).toString()
  // const windowEndDEBUG = new Date(endTime).toString()

  const windowSizeInTicks = windowSpan / intervalMs + 1

  // 1 weeks worth of data ticks (based on expected weekly periodicity)
  const lookbackCount = durations(14, 'days') / intervalMs // FIXME allow for greater lookbacks when data/retention permits

  // Output will be one tick for each interval (ms) in the visible windowSpan (ms)
  const output = new Array(windowSizeInTicks)
  const movingAverageValues = movingAverages.map(v => v.value)
  const windowStartIdx = movingAverages.length - windowSizeInTicks

  // let _newOutput = []

  // For each tick in the visible window, compute Mean and Standard Deviation for all
  // values preceding that up to the maximum lookBack amount
  for (let i = 0; i < windowSizeInTicks; i++) {
    const tickIdx = windowStartIdx + i
    const currentTick = movingAverages[tickIdx]
    if (currentTick) {
      const samples = movingAverageValues.slice(
        tickIdx - lookbackCount,
        tickIdx
      )
      // const samplesFullDEBUG = movingAverages.slice(tickIdx - lookbackCount, tickIdx)

      const variance = statsLite.variance(samples)
      let mean = statsLite.mean(samples)
      mean = isNaN(mean) ? 0 : mean
      const stdDev = isNaN(variance) ? 0 : Math.sqrt(variance)

      output[i] = {
        timestamp: currentTick.timestamp,
        // currentTimeDEBUG: new Date(currentTick.timestamp).toString(),
        currentValue: currentTick.value,
        sampleSet: samples,
        // samplesFullDEBUG: samplesFullDEBUG,
        variance,
        stdDev,
        mean,
        unitsOfDeviationFromMean: (currentTick.value - mean) / stdDev
      }
    }
  }

  return _.compact(output) // Strip out any undefined entries
}

/**
 * Given an array of standard timeSeriesData ticks ([{timestamp: <timestamp>, value: <num>}...]),
 * and a repeat interval in milliseconds, return
 * @param {*} timeSeriesData
 * @param {*} repeatInterval
 */
export function getMovingAverageFromTimeSeriesData(
  { timeSeriesData, endTime, intervalMs, startTime },
  windowMs
) {
  if (!timeSeriesData || timeSeriesData.length === 0) {
    return {}
  }
  // const windowEnd = endTime //_.first(timeSeriesData).timestamp
  // const windowStart = windowEnd - windowMs

  // let valuesByTimestamp = {} // map of timestamp to count/value
  // let output = {}

  const windowSizeInTicks = windowMs / intervalMs
  const numPts = timeSeriesData.length

  if (windowSizeInTicks >= numPts) {
    // Incomplete/invalid data
    return {}
  }

  const movingAveragesRaw = simple(
    timeSeriesData.map(tick => tick.value),
    windowSizeInTicks
  )

  // const movingAverages = movingAveragesRaw.reduce((out, movingAverageValue, i) => {
  //   const timestamp = startTime + ((i + windowSizeInTicks) * intervalMs) // Align MA value tick with end of moving window
  //   out[timestamp] = movingAverageValue
  //   return out
  // }, {})

  const movingAverages = movingAveragesRaw.map((movingAverageValue, i) => {
    // const timestamp = startTime + ((i + windowSizeInTicks) * intervalMs) // Align MA value tick with end of moving window
    // out[timestamp] = movingAverageValue
    const timestamp = startTime + (i + windowSizeInTicks) * intervalMs
    return {
      timestamp: timestamp, // Align MA value tick with end of moving window
      value: movingAverageValue
      // timestampDEBUG: new Date(timestamp).toString()
    }
  })

  return {
    movingAverages,
    endTime,
    intervalMs,
    startTime,
    windowSpan: windowMs
  }
}

/**
 * Sample data:
 * Observation count per day
 *
 * // Su, Mo, Tu, We, Th, Fr, Sa  // Day of week (starting Sunday)
 * var obsCountsByDay = [
 *    16, 76, 31, 30, 20, 19, 04, // 3 weeks ago
 *    12, 42, 35, 61, 31, 16, 18, // 2 weeks ago
 *    26, 28, 31, 52, 41, 12, 11, // 1 weeks ago
 *    02, 32, 44, 65, 45, 20, 12, // Current full week (assume "today" is the following sunday a.m.)
 * ]
 *
 * // Corresponding moving averages for a 7 day window (rounded for simplicity)
 * var movingAverageData = [
 *    --, --, --, --, --, --, --,
 *    27, 23, 23, 28, 29, 29, 31
 *    33, 31, 30, 29, 30, 30, 29
 *    25, 26, 28, 30, 30, 31, 31
 * ]
 *
 *
 * // Compare change in moving average for the same window
 * // by calculating mean, variance, and standard deviation of those values.
 * // (e.g. we use [29, 30, 31] as the inputs for Friday)
 *
 * var meanMovingAverageByDay = [
 *    28.33, // Su
 *    26.66, // Mo
 *    27.00, // Tu
 *    29.00, // We
 *    29.66, // Th
 *    30.00, // Fr
 *    30.33, // Sa
 * ]
 *
 * var varianceByDay = [
 *    11.55, // Su
 *    10.88, // Mo
 *    08.66, // Tu
 *    00.66, // We
 *    00.22, // Th
 *    00.66, // Fr
 *    00.88, // Sa
 * ]
 *
 * Units of "moving average count"
 * var stdDevByDay = [
 *    3.39, // Su
 *    3.29, // Mo
 *    2.94, // Tu
 *    0.81, // We
 *    0.46, // Th
 *    0.81, // Fr
 *    0.93, // Sa
 * ]
 *
 * // Output chart will display the difference of the most recent
 * // MA value for each day from the mean for that day of the week, compared to
 * // the standard deviation for that day of the week
 *
 * // Units of standard deviation ~(-4 to +4)
 * var pastWeekDeviationFromNormal = [
 *    -0.98, // Su
 *    -0.20, // Mo
 *    +0.34, // Tu
 *    +1.23, // We
 *    +0.73, // Th
 *    -1.23, // Fr
 *    +0.72, // Sa
 * ]
 *
 */

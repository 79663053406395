import React from 'react'

export const XrayPairedIcon = () => (<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  viewBox="0 0 979.9 730.7" xmlSpace="preserve">
<g>
	<g transform="matrix(1 0 0 -1 0 1920)">
		<path d="M220,1396.9c-19.9,0-39.4,2.6-58.3,7.9s-36.5,12.7-52.7,22.2s-31,21-44.4,34.5c-13.4,13.4-24.9,28.2-34.5,44.4
			c-9.6,16.2-17,33.8-22.2,52.7c-5.3,19-7.9,38.4-7.9,58.3v83.1c0,29.9,5.8,58.4,17.4,85.5s27.3,50.5,46.9,70.2s43,35.3,70.2,46.9
			s55.6,17.4,85.5,17.4h249.2c29.9,0,58.4-5.8,85.5-17.4s50.5-27.3,70.2-46.9c19.7-19.7,35.3-43,46.9-70.2
			c11.6-27.1,17.4-55.6,17.4-85.5v-83.1c0-29.9-5.8-58.4-17.4-85.5c-11.6-27.1-27.3-50.5-46.9-70.2c-19.7-19.7-43-35.3-70.2-46.9
			c-27.1-11.6-55.6-17.4-85.5-17.4h-15.4c-0.8,4.4-1.2,8.6-1.2,12.5v83.1c0,3.9,0.4,8,1.2,12.5h15.4c31,0,57.4,10.9,79.3,32.8
			c21.9,21.9,32.8,48.3,32.8,79.3v83.1c0,31-10.9,57.4-32.8,79.3c-21.9,21.9-48.3,32.8-79.3,32.8H220c-31,0-57.4-10.9-79.3-32.8
			s-32.8-48.3-32.8-79.3v-83.1c0-31,10.9-57.4,32.8-79.3S189,1505,220,1505h17.4c-0.3-0.6-0.5-2.4-0.6-5.4s-0.2-5.4-0.2-7.1v-83.1
			c0-1.7,0.1-4,0.2-7.1c0.1-3,0.3-4.8,0.6-5.4H220z M510.7,1189.3c-29.9,0-58.4,5.8-85.5,17.4s-50.5,27.3-70.2,46.9
			c-19.7,19.7-35.3,43-46.9,70.2c-11.6,27.1-17.4,55.6-17.4,85.5v83.1c0,23.8,3.7,46.9,11.2,69.3s18,42.6,31.6,60.6
			s29.3,33.8,47.3,47.3s38.2,24.1,60.6,31.6s45.5,11.2,69.3,11.2h15.4c0.8-4.2,1.2-8.3,1.2-12.5v-83.1c0-4.2-0.4-8.3-1.2-12.5h-15.4
			c-31,0-57.4-10.9-79.3-32.8s-32.8-48.3-32.8-79.3v-83.1c0-31,10.9-57.4,32.8-79.3s48.3-32.8,79.3-32.8h249.2
			c31,0,57.4,10.9,79.3,32.8c21.9,21.9,32.8,48.3,32.8,79.3v83.1c0,31-10.9,57.4-32.8,79.3c-21.9,21.9-48.3,32.8-79.3,32.8h-17
			c0,0.3,0.1,2,0.2,5.2c0.1,3.2,0.2,5.6,0.2,7.3v83.1v2.5c0,0-0.1,0.8-0.2,2.5c-0.1,1.7-0.2,2.5-0.2,2.5v2.5v2.5h17
			c29.9,0,58.4-5.8,85.5-17.4s50.5-27.3,70.2-46.9c19.7-19.7,35.3-43,46.9-70.2c11.6-27.1,17.4-55.6,17.4-85.5v-83.1
			c0-29.9-5.8-58.4-17.4-85.5c-11.6-27.1-27.3-50.5-46.9-70.2c-19.7-19.7-43-35.3-70.2-46.9c-27.1-11.6-55.6-17.4-85.5-17.4H510.7z"
			/>
	</g>
</g>
</svg>)

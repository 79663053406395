import _ from 'lodash'
import T from 'prop-types'
import React from 'react'

export default class extends React.Component {
  static displayName = "GoogleMapMarker";

  static propTypes = {
    className: T.string,
    id: T.number.isRequired,
    index: T.number.isRequired,
    lat: T.number.isRequired,
    lng: T.number.isRequired,
    onClick: T.func,
    text: T.any,
    tooltip: T.string
  };

  _onClick = () => {
    let fn = this.props.onClick
    if (_.isFunction(fn)) {
      fn(this.props.id)
    }
  };

  render() {
    const {props} = this

    return (
      <div
        className={ `g_map_marker ${ props.className || '' }` }
        onClick={ this._onClick }
        style={ {
          animationDelay: props.index * 50 + 'ms',
          WebkitAnimationDelay: props.index * 50 + 'ms'
        } }
      >
        <div
          className="g_map_marker_text"
          data-tooltip={ props.tooltip }
        >{ props.text }</div>
      </div>
    )
  }
}

import React, { FunctionComponent } from 'react'
import { RestError } from 'typings/restError'

export interface ErrorValue {
  error: RestError,
  className?: string
}

const formatError = (error: RestError) => [
  error.heading,
  error.body
].join('\r\n')

export const ErrorValue: FunctionComponent<ErrorValue> = ({className, error}) => {
  return (
    <div
      className={`error_value error_type_${error.type} ${className}`}
      data-tooltip={formatError(error)}
    >
      <span className={`icon icon-${error.type === 'error' ? 'spam' : 'warning'}`} />
    </div>
  )
}

ErrorValue.displayName = "ErrorValue"
/**
 * This singleton object serves as a getter for the list of known protocols and
 * protocol families. Because this list is not expected to change over time, it
 * can avoid the complexity of an async promise/store/stream pattern and can be
 * used much like a constant instead.
 *
 * It is expected that the application will call the `load()` method a single
 * time up front and wait for its Promise to resolve before invoking any parts
 * of the app that would expect it to be populated.
 */

import _ from 'lodash'
import { requestGet } from 'utils/restUtils'

const url = 'protocol-families?include=protocols'

const MESSAGE_EVENT_NAME = 'protocolSync'

let _data = null
let _worker = null

const normalizeProtocolName = name => {
  // Strip underscores and force lowercase
  return name.replace(/_/, '').toLowerCase()
}

const readData = name => {
  if (!_data) {
    throw new Error(
      `Protocols: attempted to read ${name} before data was loaded.`
    )
    return null
  }
  return _data[name]
}

export default {
  /**
   * Trigger the initial load of protocol/family data. Returns a Promise that is
   * resolved once the data has been loaded.
   */
  load() {
    if (_data) {
      console.warn('Protocols.load() was called more than once.')
      return Promise.resolve(_data)
    } else {
      return requestGet('protocols', url)
        .then(families => {
          let allProtos = _(families).pluck('protocols').flatten().value()
          _data = {
            families: families,
            protocols: allProtos,
            protocolsById: _.indexBy(allProtos, 'id'),
            protocolsByNameLower: _.reduce(allProtos, (out, proto) => {
              out[normalizeProtocolName(proto.name)] = proto
              return out
            }, {})
          }
          if (_worker) {
            _worker.postMessage({
              eventType: 'workerDataManager',
              eventName: MESSAGE_EVENT_NAME,
              message: _data
            })
          }

          return _data
        })
        .catch(restError => {
          console.error('Failed loading protocol data', restError)
        })
    }
  },

  /**
   * Register a worker context that should receive a message when protocols.load()
   * resolves
   */
  registerWorker(worker) {
    _worker = worker
  },

  /**
   * Register a receiver that should listen for the sync event from a different
   * thread
   */
  registerDataReceiver(eventEmitter) {
    if (!eventEmitter || !eventEmitter.on) {
      throw new Error('Protocols.registerDataReceiver: invalid eventEmitter')
    }
    eventEmitter.on(MESSAGE_EVENT_NAME, data => {
      _data = data
    })
  },

  /**
   * Get all known protocol families.
   * @return Array
   */
  getFamilies() {
    return readData('families')
  },

  /**
   * Get all known protocols.
   * @return Array
   */
  getProtocols() {
    return readData('protocols')
  },

  /**
   * Get a mapping of {protocolID: protocolFamilyId}.
   * @return Object
   */
  getProtocolIdToFamilyIdMap() {
    const protos = this.getProtocols()
    return protos.reduce((out, proto) => {
      out[proto.id] = proto.protocol_family_id
      return out
    }, {})
  },

  /**
   * Get a single protocol by its numeric id.
   * @return Object
   */
  getProtocolById(id) {
    let map = readData('protocolsById')
    return map ? map[id] : null
  },

  /**
   * Get a single protocol by name.
   * input and lookup names are normalized
   * @return Object
   */
  getProtocolByName(name) {
    const map = readData('protocolsByNameLower')
    const nameNormalized = normalizeProtocolName(name)
    return map ? map[nameNormalized] : null
  },

  /**
   * Get a numeric protocol ID from a passed protocol name string.
   * @return number
   */
  getProtocolIdFromName(input) {
    return _.get(this.getProtocolByName(input), 'id', -1)
  }
}

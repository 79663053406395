import React from 'react'
import T from 'prop-types'
import {DropdownMenu, MenuItem, ConfirmMenuItem} from 'react-base'
import SensorCommandsActions from 'actions/SensorCommandsActions'
import SensorActions from 'actions/SensorActions'
import {hasPermission} from 'stores/UserStore'
import AnalyticsActions from 'actions/AnalyticsActions'

class SensorActionsMenu extends React.Component {
  static propTypes = {
    sensor: T.object.isRequired,
    usageId: T.string, //identifier for page/location using this menu, e.g. 'intelcard' - used for analytics scoping
  }

  _analytics(action) {
    AnalyticsActions.event({
      eventCategory: 'sensor',
      eventAction: action,
      eventLabel: `from_${this.props.usageId || 'unknown'}`
    })
  }

  _onConsoleClicked = sensorId => {
    SensorCommandsActions.openCommandConsole([sensorId])
    this._analytics('open_console')
  }

  _onStopClicked = sensorId => {
    SensorActions.stopSensor(sensorId)
    this._analytics('stop_sensor')
  }

  _onStartClicked = sensorId => {
    SensorActions.startSensor(sensorId)
    this._analytics('start_sensor')
  }

  _onDisableClicked = sensorId => {
    SensorActions.setSensorEnabled(sensorId, false)
    this._analytics('disable_sensor')
  }

  _onResetClicked = sensorId => {
    SensorActions.resetSensor(sensorId)
    this._analytics('reset_token')
  }

  _onResetConfigClicked = sensorId => {
    SensorActions.resetConfig(sensorId)
    this._analytics('reset_config')
  }

  render() {
    const {sensor} = this.props
    const {isVersion2} = sensor
    const needsInstall = !sensor.downloaded
    const canDisableSensors = hasPermission('sensors:disable')
    const canResetSensors = hasPermission('sensors:update')
    const canResetSensorConfig = hasPermission('sensors:update') && !isVersion2 && !needsInstall //TODO enable for v2 once configurable
    const canIssueSensorCommands = hasPermission('sensors:commands') && !isVersion2 && !needsInstall //TODO enable for v2 once commandable

    return sensor.enabled && (canIssueSensorCommands || canDisableSensors || canResetSensors)
      ? <DropdownMenu
        buttonClasses="sensor_actions_dd_btn dropdown-toggle btn-icon btn icon-menu-dots"
        label={''}
        omitCaret
      >
        {canIssueSensorCommands
          ? <MenuItem
            args={[sensor.id]}
            onClick={this._onConsoleClicked}
          >
            <span className="icon icon-terminal" /> Open Command
            Console
          </MenuItem>
          : null}
        {canIssueSensorCommands
          ? sensor.is_active
            ? <ConfirmMenuItem
              args={[sensor.id]}
              confirmMessage="Are you sure you want to stop this Sensor?"
              confirmText="Yes, Stop it"
              onClick={this._onStopClicked}
            >
              <span className="icon icon-stop" /> Stop Sensor
            </ConfirmMenuItem>
            : <ConfirmMenuItem
              args={[sensor.id]}
              confirmMessage="Are you sure you want to start this Sensor?"
              confirmText="Yes, Start it"
              onClick={this._onStartClicked}
            >
              <span className="icon icon-play" /> Start Sensor
            </ConfirmMenuItem>
          : null}
        {canDisableSensors
          ? <ConfirmMenuItem
            args={[sensor.id]}
            confirmMessage="Are you sure you want to disable this Sensor?"
            confirmText="Yes, Disable it"
            disabled={!!sensor.is_active}
            onClick={this._onDisableClicked}
          >
            <span className="icon icon-close" /> Disable Sensor
          </ConfirmMenuItem>
          : null}
        {canResetSensors
          ? <ConfirmMenuItem
            args={[sensor.id]}
            confirmMessage="Are you sure you want to reset this Sensor's Token? This action will revoke the sensor's current certificate and require a new token to be generated."
            confirmText="Yes, Reset it"
            disabled={!!sensor.is_active}
            onClick={this._onResetClicked}
          >
            <span className="icon icon-loop" /> Reset Sensor Token
          </ConfirmMenuItem>
          : null}
        {canResetSensorConfig
          ? <ConfirmMenuItem
            args={[sensor.id]}
            confirmMessage="Are you sure you want to reset this Sensor's Configuration? This action will set all congiuration settings back to default."
            confirmText="Yes, Reset it"
            disabled={!!sensor.is_active}
            onClick={this._onResetConfigClicked}
          >
            <span className="icon icon-spinner" /> Reset Sensor Config
          </ConfirmMenuItem>
          : null}
      </DropdownMenu>
      : null
  }
}


export default SensorActionsMenu

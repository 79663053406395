import React from 'react'
import T from 'prop-types'
import cx from 'classnames'

function DataSourceTag(props) {
  return <span className={cx('data_source_tag', props.className)} data-tooltip={props.tooltip}>
    { props.children }
  </span>
}
DataSourceTag.propTypes = {
  tooltip: T.string,
  className: T.string,
  children: T.any
}

export default React.memo(DataSourceTag)

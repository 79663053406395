import _ from 'lodash'
import T from 'prop-types'
import React from 'react'

function _setNonScalingStroke (el) {
  if (!window._pwCompatibility.supportsNonScalingStroke) {
    return
  }

  if (el && el.setAttribute) {
    el.setAttribute('vector-effect', 'non-scaling-stroke')
  }
}

export default class extends React.Component {
  static displayName = "AnimatedStrokeD";

  static propTypes = {
    className: T.string,
    d: T.string.isRequired,
    scale: T.number,
    style: T.object
  };

  static defaultProps = {
    scale: 1
  };

  state = {
    totalLength: 0
  };

  _onRef = (el) => {
    if (el) {
      _setNonScalingStroke(el)
      this.setState({
        totalLength: el.getTotalLength()
      })
    }
  };

  render() {
    let {state, props} = this
    let lengthScaled = state.totalLength * props.scale
    return (
      <path
        className={ props.className }
        d={ props.d }
        data-length={ state.totalLength }
        ref={ this._onRef }
        style={ _.assign({}, props.style, {
          strokeDasharray: `${ lengthScaled }`,
          strokeDashoffset: lengthScaled
        }) }
      />
    )
  }
}

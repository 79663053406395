import T from 'prop-types'
import React from 'react'
import StaticAsset from 'components/StaticAsset'
import googleAuthUtils from 'utils/googleAuthUtils'

export default class extends React.Component {
  static displayName = 'GoogleOauthButton';

  static propTypes = {
    onError: T.func.isRequired,
    onSuccess: T.func.isRequired
  };

  _bindRef = (buttonElement) => {
    googleAuthUtils.attachClickHandler(
      buttonElement,
      (googleUser) => {
        let {id_token, access_token} = googleUser.getAuthResponse()
        this.props.onSuccess({
          token: id_token,
          access_token: access_token
        })
      },
      (err) => {
        this.props.onError(err.reason)
      }
    )
  };

  render() {


    return (
      <button
        type="button"
        className="google_auth btn btn-link btn-xs"
        ref={ this._bindRef }
      >
        <StaticAsset
          asset="googleAuthLogo"
          className="google_icon"
        /> Google Login
      </button>
    )
  }
}

import React, { FunctionComponent } from 'react'
import {
  observer
} from 'mobx-react'
import { LatLonValue } from 'components/values/LatLonValue'

export interface LongitudeProps {
  value: number
}

export const Longitude: FunctionComponent<LongitudeProps> = observer(props => {
  return (
    <LatLonValue
      lon={props.value}
    />
  )
})

Longitude.displayName = "Longitude"
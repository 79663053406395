import React from 'react'
import T from 'prop-types'

export default class ThreatLevelBars extends React.PureComponent {
  static propTypes = {
    low: T.number,
    medium: T.number,
    high: T.number
  }

  render() {
    const {low=0, medium=0, high=0} = this.props
    const total = low + medium + high
    return <span
      className="threat_lvl_bars"
      data-tooltip={`${low ? low + ' Low\n' : ''}${medium ? medium + ' Medium\n' : ''}${high ? high + ' High' : ''}`}
    >
      {total === 0 ?
        <span className="none bar" style={{width: '100%'}}>0 None</span>
      : null}
      {low ?
        <span className="low bar" style={{width: (low / total * 100) + '%'}}>{low} Low</span>
      : null}
      {medium ?
        <span className="medium bar" style={{width: (medium / total * 100) + '%'}}>{medium} Medium</span>
      : null}
      {high ?
        <span className="high bar" style={{width: (high / total * 100) + '%'}}>{high} High</span>
      : null}
    </span>
  }
}

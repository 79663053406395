import _ from 'lodash'
import React from 'react'
import { ScrollBars, LoadingValue } from 'react-base'
import IntelCardPropTypes from 'components/intelcard/PropTypes'
import CarbonBlackResponseDeviceData from 'components/intelcard/data/CarbonBlackResponseDeviceData'
import { UserProviderHOC } from 'components/UserProvider'

class IpDevice extends React.Component {
  static displayName = 'IpDevice'
  static propTypes = IntelCardPropTypes.dataCardShape

  render() {
    const { props } = this
    return (
      <div className="intel_card intel_data_card intel_data_device">
        <h3>Device Intel</h3>
        <div className="intel_card_content">
          {props.data ? this.renderData() : null}
        </div>
      </div>
    )
  }

  renderData = () => {
    const { props } = this
    const {data, isLoading} = props

    const hasCBResponseData = !_.isEmpty(data('devicesBySource.CarbonBlack').valueOf())
    const vendorsSet = _.get(this.props, 'userFeatures.endpointKnowledgeVendors') || new Set()

    return isLoading
      ? <LoadingValue />
      : <ScrollBars
        classes="pw_scrollbars_light"
        outside
        slimShady
        slimShadyDarker
      >
        {(hasCBResponseData || vendorsSet.has('CarbonBlack')) &&
          <React.Fragment>
            <CarbonBlackResponseDeviceData
              data={props.data}
              noDataMessage={'No Carbon Black Response device found for this IP.'}
            />
            {hasCBResponseData &&
              <p className="help_text">Note: Carbon Black data shown above is for the device most recently associated with this IP</p>
            }
          </React.Fragment>
        }
      </ScrollBars>
  }

}

export default UserProviderHOC(IpDevice)

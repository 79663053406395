import _ from 'lodash'
import React from 'react'
import T from 'prop-types'
import { formatDate } from 'utils/timeUtils'
import { formatBytes } from 'pw-formatters'
import IntelSourceTag from './IntelSourceTag'
import DataCell from 'components/intelcard/data/DataCell'

const carbonBlackPropPrefix = 'devicesBySource.CarbonBlack.'

const CB_FIELDS = [
  {
    prop: carbonBlackPropPrefix + 'extendedInfo.cbDeviceInfo.computerName',
    label: 'Computer Name'
  },
  {
    prop: carbonBlackPropPrefix + 'extendedInfo.cbDeviceInfo.networkAdapters',
    label: 'Mac',
    format: val => val[_.first(Object.keys(val))]
  },
  {
    prop: carbonBlackPropPrefix + 'extendedInfo.cbDeviceInfo.userName',
    label: 'User Login'
  },
  /*
  {
    prop: [carbonBlackPropPrefix + 'firstName', carbonBlackPropPrefix + 'lastName'],
    label: 'User Name',
    format: ([first, last]) => `${first || ''} ${last || ''}`
  },
  */
  {
    prop: carbonBlackPropPrefix + 'extendedInfo.cbDeviceInfo.physicalMemorySize',
    label: 'Physical Memory',
    format: val => `${formatBytes(val)} total`
  },
  {
    prop: [
      carbonBlackPropPrefix + 'extendedInfo.cbDeviceInfo.systemTotalSize',
      carbonBlackPropPrefix + 'extendedInfo.cbDeviceInfo.systemFreeSize'
    ],
    label: 'System Storage',
    format: ([total, free]) => `${formatBytes(total)} total · ${formatBytes(free)} free`
  },
  {
    prop: carbonBlackPropPrefix + 'deviceOs',
    label: 'Operating System'
  },
  {
    prop: carbonBlackPropPrefix + 'lastUpdated',
    label: 'Last Updated',
    format: formatDate
  }
]

class CarbonBlackResponseDeviceData extends React.Component {
  static propTypes = {
    data: T.func, //data accessor from IntelCardStore
    noDataMessage: T.string
  }

  render() {
    const root = this.props.data('devicesBySource.CarbonBlack')

    // Display nothing until we have an answer
    if (!root.isReady()) {
      return null
    }

    return <div className="device_source_summary">
      <h5>Carbon Black Response Summary <IntelSourceTag source="CarbonBlack" /></h5>

      { // If the result object is empty, display a collapsed rollup message
        _.isEmpty(root.valueOf())
          ? <p className="no_data">{this.props.noDataMessage || 'No Carbon Black Response data found for this device.'}</p>
          : CB_FIELDS.map((def, i) =>
            <DataCell
              key={i}
              label={def.label}
              value={this.props.data(def.prop)}
              formatter={def.format}
              default="Unknown"
            />
          )
      }
    </div>
  }
}

export default CarbonBlackResponseDeviceData

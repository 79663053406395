import React, { FunctionComponent } from 'react'
import * as _ from 'lodash'
import { observer } from 'mobx-react'
import { IBasePromiseBasedObservable } from 'mobx-utils'
import { useStore } from 'stores/mobx/StoreManager'
import { LoadingValue, TextOverflow } from 'react-base'
import { RestError } from 'typings/restError'
import { CityStore, City } from 'stores/mobx/data/global/CityStore'
import { ErrorValue } from './ErrorValue'
import { CountryValue } from './CountryValue'

export interface CityValueProps {
  includeCountry?: boolean
  cityId: number // MaxMind City ID (GeoNames.org ID)
}

const cityToString = (city: City) => _.compact([
  city.cityName,
  city.subdiv1Name,
  city.subdiv2Name
]).join(', ')

export const CityValue: FunctionComponent<CityValueProps> = observer(props => {
  if (!props.cityId) {
    return <span>{props.cityId || ''}</span>
  }
  const cityStore = useStore(CityStore)
  const city: IBasePromiseBasedObservable<City|RestError> = cityStore.getById(props.cityId)

  return (
    <TextOverflow className={`city_value`}>
      {city.case({
        pending: () => <LoadingValue />,
        rejected: error => <ErrorValue error={error} />,
        fulfilled: (city: City) => (
          <>
            <span className={`city_name`}>{cityToString(city)}</span>
            {props.includeCountry &&
              <>, <CountryValue code={city.countryCode} /></>
            }
          </>
        )
      })}
    </TextOverflow>
  )
})

CityValue.displayName = "CityValue"
import _ from 'lodash'
import React from 'react'
import {
  ScrollBars,
  StoreProvider,
  LoadingValue,
  TextOverflow
} from 'react-base'
import IntelCardPropTypes from '../PropTypes'
import DataCell from './DataCell'
import ErroredValue from './ErroredValue'
import ThreatMeter from 'components/ThreatMeter'
import { formatBytes, formatNumber } from 'pw-formatters'
import CustomerSettingsStore from 'stores/CustomerSettingsStore'
import {hasPermission} from 'stores/UserStore'
import CommonViewActions from 'actions/CommonViewActions'
import AnalyticsActions from 'actions/AnalyticsActions'

import addtlPropUtils from './additionalPropertiesUtil'
import ExternalLink from 'components/ExternalLink'


const FILE_ATTRS = [
  {prop: 'isArchive', label: 'Archive'},
  {prop: 'isEncrypted', label: 'Encrypted'},
  {prop: 'isTruncated', label: 'Truncated'}
]
const HASHES = ['md5', 'sha1', 'sha256', 'sha512']

class IpInfo extends React.Component {
  static displayName = 'IpInfo';
  static propTypes = IntelCardPropTypes.dataCardShape;

  _onDownloadClick = () => {
    //TODO need api for downloading by hash
  };

  render() {
    let {props} = this
    return (
      <div className="intel_card intel_data_card intel_data_fileinfo">
        <div className="intel_card_title">
          <h3>IP Details</h3>
          <span className="source">
            <h5>source: recorded future</h5>
            { addtlPropUtils.recordedFutureSourceTag() }
          </span>
        </div>
        <div className="intel_card_content">
          { props.data ? this.renderData() : null }
        </div>
      </div>
    )
  }

  _trackRecordedFutureLinks = () => {
    AnalyticsActions.event({
      eventCategory: 'intelcard',
      eventAction: 'external-link-clicked',
      eventLabel: 'recorded-future-reference'
    })
  }

  _trackRecordedFutureAllLink = () => {
    AnalyticsActions.event({
      eventCategory: 'intelcard',
      eventAction: 'external-link-clicked',
      eventLabel: 'recorded-future-report'
    })
  }

  _trackVirusTotalLink = () => {
    AnalyticsActions.event({
      eventCategory: 'intelcard',
      eventAction: 'external-link-clicked',
      eventLabel: 'virus-total-report'
    })
  }

  componentDidUpdate() {
    if (this._threatMeter && this.props.data) {
      let recordedFutureInfo = () => addtlPropUtils.getIpInfo(this.props.data)
      let recordedFuturesIpInfo = addtlPropUtils.getIpInfoValue(this.props.data)

      if (!recordedFuturesIpInfo.error && recordedFuturesIpInfo.value) {
        var recordedFuturesData = addtlPropUtils.getAdditionalProperties(recordedFuturesIpInfo.value)

        var riskData = addtlPropUtils.getCategory(recordedFuturesData, 'Recorded Future Risk Score')
        var riskScore = _.find(riskData, data => data.name === 'score')

        this._threatMeter._onDataUpdate(riskScore)
      }
    }
  }

  componentDidMount() {
    if (this._threatMeter && this.props.data) {
      let recordedFutureInfo = () => addtlPropUtils.getIpInfo(this.props.data)
      let recordedFuturesIpInfo = addtlPropUtils.getIpInfoValue(this.props.data)

      if (!recordedFuturesIpInfo.error && recordedFuturesIpInfo.value) {
        var recordedFuturesData = addtlPropUtils.getAdditionalProperties(recordedFuturesIpInfo.value)

        var riskData = addtlPropUtils.getCategory(recordedFuturesData, 'Recorded Future Risk Score')
        var riskScore = _.find(riskData, data => data.name === 'score')

        this._threatMeter._onDataUpdate(riskScore)
      }
    }
  }

  renderData = () => {
    let {props} = this
    let data = s => props.data('info.' + s)
    let sha256 = data('hashes.sha256')

    let recordedFutureInfo = () => addtlPropUtils.getIpInfo(props.data)
    let recordedFuturesIpInfo = addtlPropUtils.getIpInfoValue(props.data)

    if (!recordedFuturesIpInfo.error && recordedFuturesIpInfo.value) {
      var recordedFuturesData = addtlPropUtils.getAdditionalProperties(recordedFuturesIpInfo.value)

      var riskData = addtlPropUtils.getCategory(recordedFuturesData, 'Recorded Future Risk Score')
      var riskScore = _.find(riskData, data => data.name === 'score')
      var activeRiskColor = addtlPropUtils.setActiveRiskScore(riskScore && riskScore.value)
      var riskCriticality = _.find(riskData, data => data.name === 'category')

      var locationData = addtlPropUtils.getCategory(recordedFuturesData, 'Location')
      var asn = _.find(locationData, data => data.name === 'asn')
      var organization = _.find(locationData, data => data.name === 'organization')
      var country = _.find(locationData, data => data.name === 'country')

      var location = {
        org: organization,
        asn: asn,
        geo: country
      }

      var metricData = addtlPropUtils.getCategory(recordedFuturesData, 'Metrics')
      var totalHits =  _.find(metricData, data => data.name === 'totalHits')
      var oneDayHits = _.find(metricData, data => data.name === 'oneDayHits')
      var sevenDaysHits = _.find(metricData, data => data.name === 'sevenDaysHits')
      var sixtyDaysHits = _.find(metricData, data => data.name === 'sixtyDaysHits')
      var socialMediaHits = _.find(metricData, data => data.name === 'socialMediaHits')
      var infoSecHits = _.find(metricData, data => data.name === 'infoSecHits')
      var maliciousHits = _.find(metricData, data => data.name === 'maliciousHits')

      var referencesData = addtlPropUtils.getCategory(recordedFuturesData, 'Sightings')

      var linkData = recordedFuturesData
        ? _.find(recordedFuturesData.data, data => data.label === 'Recorded Future Intel Card')
        : null

      var allMetrics = [totalHits, oneDayHits, sevenDaysHits, sixtyDaysHits, socialMediaHits, infoSecHits, maliciousHits]
      var hasOneMetric = allMetrics.some(_.identity)
    }

    return (
        recordedFutureInfo().isReady()
        ? (
          <ScrollBars
            classes="pw_scrollbars_light"
            outside
            slimShady
            slimShadyDarker
          >
            {
              riskScore && riskCriticality
              ? (
                <div className="cell">
                  <span>Recorded Future Risk Score</span>
                  <div className="cell_content flex space_between">
                    <div className="rf_risk">
                      <span className="risk_text">
                        { `${riskScore.value} - ${riskCriticality.value}` }
                      </span>
                      <div className="risk_meter">
                        <ThreatMeter
                          ref={ c => this._threatMeter = c }
                          enableLiveData={false}
                          showSubviewLabel={false}
                          liveDataGroupName={'none'}
                          liveDataGraphId={'none'}
                          liveDataOptions={ { dataOptions: { counterTypes: ['value'] }, drawOptions: {} } }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : null
            }

            <section>
              <table className="attributes">
                <tbody>
                  {
                    ['asn', 'org', 'geo'].map(type => {
                      return (
                        <tr key={ type }>
                          <th>{ type }</th>
                          <td>{ location[type] && location[type].value || 'None' }</td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </section>

            {
              recordedFuturesIpInfo.value.length && metricData.length
              ? (
                <section>
                  {
                    hasOneMetric
                    ? (
                      <div className="cell">
                        <div className="flex space_between">
                          <h5 className="rf_title">Recorded Future Reference Summary</h5>
                        </div>
                        <div className="cell_content flex col rf_refs">
                          { totalHits && <div>{`Total References - ${formatNumber(totalHits.value, true)}`}</div> }
                          { oneDayHits && <div>{`Today - ${formatNumber(oneDayHits.value, true)}`}</div> }
                          { sevenDaysHits && <div>{`Last 7 days - ${formatNumber(sevenDaysHits.value, true)}`}</div> }
                          { sixtyDaysHits && <div>{`Last 60 days - ${formatNumber(sixtyDaysHits.value, true)}`}</div> }
                          { socialMediaHits && <div>{`In Social Media - ${formatNumber(socialMediaHits.value, true)}`}</div> }
                          { infoSecHits && <div>{`From InfoSec Sources - ${formatNumber(infoSecHits.value, true)}`}</div> }
                          { maliciousHits && <div>{`Including Malicious Language - ${formatNumber(maliciousHits.value, true)}`}</div> }
                        </div>
                      </div>
                    ) : null
                  }

                  <div className="cell">
                    <h5 className="rf_title">Recent References</h5>
                      {
                        referencesData.map(reference => {
                          const title = _.find(reference.sighting, data => data.name === 'title')
                          const source = _.find(reference.sighting, data => data.name === 'source')
                          const published = _.find(reference.sighting, data => data.name === 'published')
                          const url = _.find(reference.sighting, data => data.name === 'url')
                          const type = _.find(reference.sighting, data => data.name === 'type')
                          return (
                            <div className="cell_content" key={`${title.value}-${type.value}`}>
                              <p className="fragment">{title.value}</p>
                              <p className="sighting_type"><span className="sighting_type_title">Type:</span> {type.value}</p>
                                <TextOverflow>
                                  <ExternalLink onClick={this._trackRecordedFutureLinks} href={url.value} className="btn btn-link external_link">
                                    {source.value} | {published.value}
                                    <span className="icon icon-arrow-up-right"></span>
                                  </ExternalLink>
                                </TextOverflow>
                            </div>
                          )
                        })
                      }
                  </div>
                </section>
              ) : null

            }

            <section>
              {
                linkData
                ? (
                  <ExternalLink onClick={this._trackRecordedFutureAllLink} href={ linkData.value.value } className="btn btn-link external_link"
                    data-tooltip="View Intel for this file hash in Recorded Future">
                    All Recorded Future Intel <span className="icon icon-arrow-up-right"></span>
                  </ExternalLink>
                ) : null
              }
              { sha256.valueOf() ? (
                <ExternalLink onClick={this._trackVirusTotalLink} href={ `https://www.virustotal.com/en/file/${ sha256.valueOf() }/analysis/` } className="btn btn-link external_link"
                  data-tooltip="Performs a search for this file in the VirusTotal public database. Opens in a new browser window.">
                  View on VirusTotal.com <span className="icon icon-arrow-up-right"></span>
                </ExternalLink>
              ) : null }

              { sha256.valueOf() && props.wildfireApiKey ? (
                <form action="https://wildfire.paloaltonetworks.com/publicapi/get/report" method="POST" target="_blank">
                  <input type="hidden" name="format" value="pdf" />
                  <input type="hidden" name="hash" value={ sha256.valueOf() } />
                  <input type="hidden" name="apikey" value={ props.wildfireApiKey } />
                  <button type="submit" className="btn btn-link external_link"
                    onClick={ props.onWildfireClick }
                    data-tooltip="Requests a PDF report for this file from Palo Alto Networks WildFire. Opens in a new browser window.">
                    View WildFire Report <span className="icon icon-arrow-up-right"></span>
                  </button>
                </form>
              ) : null }
            </section>
          </ScrollBars>
        ) : <LoadingValue />
    )
  };
}

export default (props) => (
  <IpInfo { ...props } />
)

import _ from 'lodash'
import { formatDate } from 'pw-formatters'
import { hsl } from 'd3-color'

export const COLORS = {
  RED: '#ff0000',
  ORANGE: '#ff8100',
  YELLOW: '#dbbb47',
  BLUE: '#3ba7db',
  GREEN: '#00b500'
}

export const SENSOR_SET_COLORS = [
  '#2cc3a5',
  '#d2434f',
  '#b5b124',
  '#8e3363',
  '#456b7f',
  '#82982e',
  '#c76e2e'
]

function expandPalette(src, factor = 4) {
  const len = src.length
  // Expand the palette by repeating those base colors with decreased saturation, divided evenly by 1/factor
  const multInterval = 1 / factor

  for (let i = 1; i < factor; i++) {
    const mult = 1 - (i * multInterval)
    for (let ii = 0; ii < len; ii++) {
      const color = hsl(src[ii])
      color.s *= mult
      src.push(
        color.toString()
      )
    }
  }
}

expandPalette(SENSOR_SET_COLORS, 4)

export const PROTO_FAMILY_COLORS_2 = [
  '#A69F21',
  '#9AA621',
  '#21A662',
  '#5AA69E',
  '#2189A6',
  '#524FA1',
  '#7533A6',
]

expandPalette(PROTO_FAMILY_COLORS_2, 5)

export const normalizeProtocolFamilyName = name => {
  return name
    .replace(/\//g, "_") // Audio/Video => Audio_Video
    .toLowerCase()
}

const _protocolFamilies = [
  'applicationservice',
  'audio_video',
  'authentication',
  'database',
  'encrypted',
  'erp',
  'fileserver',
  'game',
  'ics',
  'instantmessaging',
  'mail',
  'middleware',
  'networkmanagement',
  'networkservice',
  'peertopeer',
  'printer',
  'routing',
  'terminal',
  'thinclient',
  'tunneling',
  'unclassified',
  'web',
  'telephony'
]
const _protocolFamilyColors = [
  '#A69F21',
  '#9AA621',
  '#21A662',
  '#5AA69E',
  '#2189A6',
  '#524FA1',
  '#7533A6',
  '#807C40',
  '#798033',
  '#40805F',
  '#458079',
  '#407280',
  '#413F80',
  '#654080',
  '#8C8962',
  '#9FA664',
  '#638C76',
  '#638C87',
  '#6398A6',
  '#64638C',
  '#7B638C',
  '#9FA664',
  '#779183'
]

const _protocolFamilyColorsExpanded = [
  "#CC6699",
  "#BF6C9F",
  "#B272A5",
  "#A478AB",
  "#977EB1",
  "#8A84B7",
  "#7D89BD",
  "#708FC3",
  "#6395C9",
  "#559BCF",
  "#48A1D5",
  "#3BA7DB",
  "#39A7D0",
  "#36A7C5",
  "#34A7BA",
  "#32A7AF",
  "#2FA7A4",
  "#2DA699",
  "#2AA68E",
  "#28A683",
  "#26A678",
  "#23A66D",
  "#21A662",
  "#299C68",
  "#30916E",
  "#388775",
  "#407C7B",
  "#477281",
  "#4F6787",
  "#565D8D",
  "#5E5293",
  "#66489A",
  "#6D3DA0",
  "#7533A6",
  "#703EAB",
  "#6A48B0",
  "#6553B4",
  "#605DB9",
  "#5B68BE",
  "#5572C3",
  "#507DC8",
  "#4B87CD",
  "#4692D1",
  "#409CD6"
]

// Protocol families current as of Jan 15, 2021
const protocolFamilies2 = [
  {
    "id": 0,
    "name": "Unclassified",
    "description": "Unknown protocol family"
  },
  {
    "id": 1,
    "name": "Antivirus",
    "description": "Antivirus updates"
  },
  {
    "id": 2,
    "name": "Application Service",
    "description": "Background services"
  },
  {
    "id": 3,
    "name": "Audio/Video",
    "description": "Application/Protocols used to transport audio or video content"
  },
  {
    "id": 4,
    "name": "Authentication",
    "description": "Protocol used for authentification purpose"
  },
  {
    "id": 5,
    "name": "Behavioral",
    "description": "Protocol classified by non-deterministic criteria based on statistical analysis of packet form and session behavior. "
  },
  {
    "id": 6,
    "name": "Compression",
    "description": "compression layers"
  },
  {
    "id": 7,
    "name": "Database",
    "description": "Protocol used for database remote queries"
  },
  {
    "id": 8,
    "name": "Encrypted",
    "description": "Encryption protocols"
  },
  {
    "id": 9,
    "name": "ERP",
    "description": "Enterprise Ressource Planning applications"
  },
  {
    "id": 10,
    "name": "File Server",
    "description": "File transfer protocols"
  },
  {
    "id": 11,
    "name": "File Transfer",
    "description": "Protocols used for user to user file transfers using instant messaging applications."
  },
  {
    "id": 12,
    "name": "Forum",
    "description": "Web forum"
  },
  {
    "id": 13,
    "name": "Game",
    "description": "Gaming protocols"
  },
  {
    "id": 14,
    "name": "Instant Messaging",
    "description": "Instant messaging applications"
  },
  {
    "id": 15,
    "name": "Mail",
    "description": "Email exchange protocols"
  },
  {
    "id": 16,
    "name": "Microsoft Office",
    "description": "Microsoft office sub-protocols"
  },
  {
    "id": 17,
    "name": "Middleware",
    "description": "Platform protocols for remote procedure calls"
  },
  {
    "id": 18,
    "name": "Network Management",
    "description": "Protocol used for IT management"
  },
  {
    "id": 19,
    "name": "Network Service",
    "description": "Low level network protocols"
  },
  {
    "id": 20,
    "name": "Peer to Peer",
    "description": "Peer to peer applications"
  },
  {
    "id": 21,
    "name": "Printer",
    "description": "Printer commmunication protocols"
  },
  {
    "id": 22,
    "name": "Routing",
    "description": "Network routing protocols"
  },
  {
    "id": 23,
    "name": "Security Service",
    "description": "Workstation security applications"
  },
  {
    "id": 24,
    "name": "Standard",
    "description": "Basic layers defined by Qosmos"
  },
  {
    "id": 25,
    "name": "Telephony",
    "description": "Telephony core network protocols"
  },
  {
    "id": 26,
    "name": "Terminal",
    "description": "Remote terminal protocols"
  },
  {
    "id": 27,
    "name": "Thin Client",
    "description": "Remote control protocols"
  },
  {
    "id": 28,
    "name": "Tunneling",
    "description": "Tunneling protocols"
  },
  {
    "id": 29,
    "name": "Wap",
    "description": "Mobile specific transport protocols"
  },
  {
    "id": 30,
    "name": "Web",
    "description": "Generic web traffic"
  },
  {
    "id": 31,
    "name": "Webmail",
    "description": "Web email applications"
  },
  {
    "id": 32,
    "name": "PCN / ICS",
    "description": "Process Control Networks / Industrial Control Systems"
  }
].map(p => ({
  ...p,
  color: _protocolFamilyColorsExpanded[p.id],
  nameNormalized: normalizeProtocolFamilyName(p.name)
}))


export var keyCodes = {
  backspace: 8,
  tab: 9,
  enter: 13,
  esc: 27,
  pageUp: 33,
  pageDown: 34,
  left: 37,
  up: 38,
  right: 39,
  down: 40,
  delete: 46
}

const _constants = {
  timeIntervals: {
    labels: ['ms', 's', 'm', 'h', 'd', 'w', 'm', 'y'],
    durations: [
      1,
      1000,
      60000,
      3600000,
      86400000,
      604800000,
      2592000000,
      31536000000
    ]
  },

  threatLevels: [
    'high',
    'medium',
    'low'
    //'none'
  ],

  threatLevelColors: {
    high: COLORS.RED,
    medium: COLORS.ORANGE,
    low: COLORS.YELLOW,
    none: COLORS.BLUE
  },

  threatLevelsToAbbr: {
    high: 'Hi',
    medium: 'Md',
    low: 'Lo'
  },

  killchainStages: [
    'recon',
    'delivery',
    'exploit',
    'beacon',
    'cnc',
    'fortification',
    'data_theft'
  ],

  killchainStagesV2: [
    'recon',
    'delivery',
    'exploit',
    'beacon',
    'cnc',
    'fortification',
    'datatheft'
  ],

  threatCategories: [
    'exploitsandattacks',
    'denialofservice',
    'malware',
    'scanning',
    'botnet',
    'phishing',
    'suspicious',
    'malicioushost',
    'apt'
    // TODO need to get counters in place for this: 'trojan'
    // 'misc'
    // 'unknown'
  ],

  threatCategoriesToNames: {
    exploitsandattacks: 'Exploits and Attacks',
    denialofservice: 'DOS',
    malware: 'Malware',
    scanning: 'Scanning',
    botnet: 'Botnet',
    phishing: 'Phishing',
    suspicious: 'Suspicious',
    malicioushost: 'Malicious Host',
    apt: 'APT',

    trojan: 'Trojan', // TODO showing up in search results.. was unaware of this new category

    misc: 'Miscellaneous',
    unknown: 'Unknown',
    none: 'None',
    malicious_webpage: 'Malicious Webpage'
  },

  killchainStagesToNames: {
    recon: 'Recon',
    delivery: 'Delivery',
    exploit: 'Exploit',
    beacon: 'Beacon',
    cnc: 'Command and Control',
    fortification: 'Lateral Movement',
    data_theft: 'Actions on Objective',
    datatheft: 'Actions on Objective'
  },

  killchainStagesToShortNames: {
    recon: 'Recon',
    delivery: 'Delivery',
    exploit: 'Exploit',
    beacon: 'Beacon',
    cnc: 'C2',
    fortification: 'Lateral Movement',
    data_theft: 'Act. on Obj.',
    datatheft: 'Act. on Obj.'
  },

  killchainStageCountersToColors: {
    recon: '#5297AF',
    delivery: '#497C7B',
    exploit: '#897937',
    beacon: '#C48735',
    cnc: '#AC5D29', //'#824720' #'#93471d'
    fortification: '#93331d',
    datatheft: '#d92230'
  },

  killchainStagesToEventCounters: {
    recon: 'reconEvents',
    delivery: 'deliveryEvents',
    exploit: 'exploitEvents',
    beacon: 'beaconEvents',
    cnc: 'cncEvents',
    fortification: 'fortificationEvents',
    data_theft: 'dataTheftEvents'
  },

  // List of observation types. These should always match the enum of possible searchable 'type' values.
  observationTypes: [
    'Ids',
    'Protocol',
    'File',
    'Dns',
    'Email',
    'Http',
    'IpReputation',
    'UrlReputation',
    'FileReputation',
    'DnsReputation',
    // ??? 'Certificate',
    'CertReputation',
    'AnomalousFlow',
    'Ics',
    'AnomalousObservation',
    'Anomaly',
    'DceRpc',
    'Smb',
    'Krb5'
  ],

  // Mapping to infer the appropriate observation type from the name of the populated property
  // in the `obs.data` object of an Observation response
  // !NOTE: keys are all forced to lowercase
  observationDataPropertiesToTypes: {
    idsevent: 'Ids',
    protocol: 'Protocol',
    ipreputation: 'IpReputation',
    //'httpRequest': 'Http', // should always be upconverted to 'httpTransaction' by observationUtils
    httptransaction: 'Http',
    urlreputation: 'UrlReputation',
    filereputation: 'FileReputation',
    file: 'File',
    dns: 'Dns',
    email: 'Email',
    dnsreputation: 'DnsReputation',
    certificatereputation: 'CertReputation',
    anomalousflow: 'AnomalousFlow',
    modbustransaction: 'Modbus',
    eniptransaction: 'Enip',
    ciptransaction: 'Cip',
    ics: 'Ics',
    anomalousobservation: 'AnomalousObservation',
    anomaly: 'Anomaly',
    dcerpc: 'DceRpc',
    smbtransaction: 'Smb',
    krb5pdu: 'Krb5'
  },

  // Standardized display names for obs types
  observationTypesToNames: {
    Ids: 'Payload',
    Protocol: 'Protocol',
    File: 'File',
    Dns: 'DNS',
    Email: 'Email',
    Http: 'HTTP',
    IpReputation: 'IP Rep',
    UrlReputation: 'URL Rep',
    FileReputation: 'File Rep',
    DnsReputation: 'DNS Rep',
    CertReputation: 'Cert Rep',
    AnomalousFlow: 'Heuristics',
    Ics: 'Modbus TCP Transaction',
    AnomalousObservation: 'Anomaly',
    Anomaly: 'Anomalous Behavior (beta)',
    DceRpc: 'DCE/RPC',
    Smb: 'SMB',
    Krb5: 'Kerberos'
  },

  observationTypeCountersToTypes: {
    idsObs: 'Ids',
    ipObs: 'IpReputation',
    dnsObs: 'DnsReputation',
    certObs: 'CertReputation',
    urlObs: 'UrlReputation',
    fileObs: 'FileReputation',
    anomalousFlowObs: 'AnomalousFlow',
    modbusTransaction: 'Modbus',
    anomalousObs: 'AnomalousObservation',
    anomaly: 'Anomaly'
  },

  // Upgrade old killbox 'type' url param to current obs types
  observationTypeLegacyParams: {
    iprep: 'IpReputation',
    urlrep: 'UrlReputation',
    dnsrep: 'DnsReputation',
    ids: 'Ids',
    filerep: 'FileReputation',
    http: 'Http',
    protocol: 'Protocol',
    certrep: 'CertReputation'
  },

  eventTypesToNames: {
    MaliciousFlow: 'Malicious Flow',
    KillChainEscalation: 'Killchain Escalation',
    MaliciousConversation: 'Malicious Conversation',
    MaliciousHost: 'Malicious Host',
    CompromisedHost: 'Compromised Host'
  },

  // // Killbox constants
  // defaultKillboxMode: 'events',
  // defaultKillboxSort: 'occurredAt',
  // // defaultKillboxSort: 'observedAt',

  resolvedReasons: {
    noAction: 'No Action',
    remediated: 'Remediated',
    falsePositive: 'False Positive',
    suspicious: 'Suspicious',
    unsuccessful: 'Unsuccessful'
  },

  workflowStates: {
    open: 'Open',
    // TODO uncomment when API accepts it... inProgress: 'In Progress',
    resolved: 'Resolved'
  },

  eventPriorities: [
    'Low',
    'Normal',
    'High'
  ],

  // Params to keep when resetting query
  killboxStandardParams: [
    'folder',
    'sensorId',
    'id',
    'sid',
    'sort',
    'sortDir',
    'obs_id', // legacy
    'obs_sensorId', // legacy
    'mode' // legacy
  ],

  counterTypes: [
    'reconObs',
    'deliveryObs',
    'exploitObs',
    'beaconObs',
    'cncObs',
    'fortificationObs',
    'dataTheftObs',
    'lowObs',
    'mediumObs',
    'highObs',
    'bandwidth',
    'internal',
    'external',
    'http',
    'https',
    'smtp',
    'ssh'
  ],

  bandwidthCounterTypes: [
    'bandwidth',
    'internal',
    'external',
    'http',
    'https',
    'smtp',
    'ssh'
  ],

  counterTypesToNames: {
    reconObs: 'Recon Observations',
    deliveryObs: 'Delivery Observations',
    exploitObs: 'Exploit Observations',
    beaconObs: 'Beacon Observations',
    cncObs: 'CNC Observations',
    fortificationObs: 'Lateral Movement Observations',
    dataTheftObs: 'Actions on Objective Observations',
    lowObs: 'Low Severity Observations',
    mediumObs: 'Medium Severity Observations',
    highObs: 'High Severity Observations',
    bandwidth: 'Total Bandwidth',
    internal: 'Internal Bandwidth',
    external: 'External Bandwidth',
    http: 'HTTP Bandwidth',
    https: 'HTTPS Bandwidth',
    smtp: 'E-mail Bandwidth',
    ssh: 'SSH Bandwidth'
  },

  counterTypesToShortNames: {
    reconObs: 'Recon',
    deliveryObs: 'Delivery',
    exploitObs: 'Exploit',
    beaconObs: 'Beacon',
    cncObs: 'CNC',
    fortificationObs: 'Lateral Movement',
    dataTheftObs: 'Actions on Objective',
    lowObs: 'Low',
    mediumObs: 'Medium',
    highObs: 'High',
    bandwidth: 'Total',
    internal: 'Internal',
    external: 'External',
    http: 'HTTP',
    https: 'HTTPS',
    smtp: 'E-mail',
    ssh: 'SSH'
  },

  riskFactorsToNames: {
    countryRisk: 'Country Risk',
    regionRisk: 'Region Risk',
    dnsHistoryRisk: 'DNS History Risk',
    asnRisk: 'ASN Risk',
    ianaRisk: 'IANA Risk',
    activityRisk: 'Recent Activity Risk',
    searchVolumeRisk: 'Search Volume Risk'
  },

  protocolFamilies: protocolFamilies2.map(p => p.normalizedName),
  protocolFamilyColors: protocolFamilies2.map(p => p.color),
  protocolIds: protocolFamilies2.map(p => p.id),
  protocolFamilyIdToClassName: protocolFamilies2.reduce((out, p) => {
    out[p.id] = p.normalizedName
    return out
  }, {}),
  protocolFamilyIdToColor: protocolFamilies2.reduce((out, p) => {
    out[p.id] = p.color
    return out
  }, {}),
  protocolFamilyToColor: protocolFamilies2.reduce((out, p) => {
    out[p.normalizedName] = p.color
    return out
  }, {}),

  captureModeIdToName: {
    packet: 'Full Packet',
    head: 'Stream Heads',
    netflow: 'Netflow Only',
    none: 'Drop Packet'
  },

  regionsNamesToDisplay: {
    emea: 'Europe, Middle East, Asia',
    apac: 'Asia Pacific',
    latam: 'Latin America',
    na: 'North America',
    internal: 'Internal',
    none: 'NONE'
  },

  notificationTemplateTokens: {
    message: 'Short message summarizing the event',
    sensorId: 'ID of the sensor that triggered the event',
    type: 'Type of the event',
    threatScore: 'Numeric score of the threat: 0 to 100',
    threatLevel: 'Threat level of the event: None, Low, Medium or High',
    killChainStage: 'Attack progression stage of the event',
    category: 'The category assigned to the event',
    isoStartedAt: "Start time of the event's first observation",
    isoEndedAt: "End time of the event's last observation",
    observedStage: 'Stage in the platform when the event was observed',
    visualizerLink: 'Visualizer URL for the event',
    startedAt: 'The time the event started (milliseconds from Unix Epoch)',
    endedAt: 'The time the event ended (milliseconds from Unix Epoch)',
    observedAt:
      'The time the event was observed in the platform (milliseconds from Unix Epoch)',
    isoObservedAt:
      'The time the event was observed in the platform (ISO8601 date time)',
    trialVisualizerLink: 'Link to the trial visualizer',
    srcIps:
      'A list of all source IP addresses contained in the observations that generated the event',
    dstIps:
      'A list of all destination IP addresses contained in the observations that generated the event',
    ips:
      'A list of all IP addresses contained in the observations that generated the event, formatted as source -> destination',
    srcPorts:
      'A list of all source ports contained in the observations that generated the event',
    dstPorts:
      'A list of all destination ports contained in the observations that generated the event',
    ipsAndPorts:
      'A list of all source and destination ports and addressed contained in the observations that generated the event, formatted as sourceIp:sourcePort -> destinationIp:destinationPort',
    urls:
      'A list of all urls contained in the observations that generated the event, empty if no urls are present'
  },

  defaultNotificationSubject: '#BRAND#: Security Threat Event Detected',
  defaultNotificationBody: `
    message:        {{message}}
    type:           {{type}}
    threatScore:    {{threatScore}}
    threatLevel:    {{threatLevel}}
    killChainStage: {{killChainStage}}
    isoStartedAt:   {{isoStartedAt}}
    isoEndedAt:     {{isoEndedAt}}
    observedStage:  {{observedStage}}
    visualizerLink: {{visualizerLink}}
  `,

  liveCollectionEventNames: {
    add: 'add',
    remove: 'remove',
    update: 'update',
    sync: 'sync'
  },

  keyCodes: keyCodes
}

export default _constants

export const DEVICES_ROOT_PATH = 'devices'

export const DEVICE_MANAGEMENT_VIEWS = {
  inventory: {
    id: 'inventory',
    url: `${DEVICES_ROOT_PATH}/inventory`,
    enabled: () => true,
    title: 'Inventory (beta)'
  }
}

export const DEFAULT_DEVICE_VIEW = 'inventory'

export const CUSTOMER_ADMIN_ROOT_PATH = 'customer-admin'

export const CUSTOMER_ADMIN_VIEWS = {
  customers: {
    id: 'customers',
    url: `${CUSTOMER_ADMIN_ROOT_PATH}/customers`,
    enabled: () => true,
    title: 'Customer Administration (beta)'
  }
}

export const DEFAULT_CUSTOMER_ADMIN_VIEW = 'customers'

// Mapping of top level nav keys to hash routes
export const topLevelNavItems = {
  // Primary
  hud: 'hud',
  killbox: 'killbox/events?state=open&assignee=0&topNav=1',
  explorer: 'explorer',
  reporting: 'reporting/dashboard',
  grid: 'grid',
  // Secondary
  intel: 'intel/manage',
  sensors: 'sensors/dashboard',
  devices: DEVICE_MANAGEMENT_VIEWS[DEFAULT_DEVICE_VIEW].url,
  settings: 'settings/account'
}

export var timeFormattingOptions = _.mapValues(
  {
    classic: {
      display: 'Classic',
      formats: {
        day: 'ddd MMM DD YYYY',
        time: 'HH:mm:ss'
      }
    },
    standard: {
      display: 'Standard',
      formats: {
        day: 'YYYY-MM-DD',
        time: 'HH:mm:ss'
      }
    },
    standardWithTZOffset: {
      display: 'Standard + UTC Offset',
      formats: {
        day: 'YYYY-MM-DD',
        time: 'HH:mm:ssZZ'
      }
    },
    compact: {
      display: 'Compact',
      formats: {
        day: 'YYYY/MM/DD',
        time: 'HH:mm:ss'
      }
    },
    iso: {
      display: 'ISO 8601',
      formats: {
        datetime: 'iso', // Special shortcut for momentjs ISO formatting
        day: 'YYYY-MM-DD',
        time: 'HH:mm:ss'
      }
    }
  },
  spec => {
    spec.display +=
      ' (' +
      formatDate(
        Date.now(),
        spec.formats.datetime || `${spec.formats.day} ${spec.formats.time}`
      ) +
      ')'
    return spec
  }
)

export const THEME_OPTIONS = {
  // snap: 'Snap', // Debugging
  default: 'Default',
  contrast1: 'Contrast +1',
  contrast2: 'Contrast +2',
  // highContrast: 'High Contrast',
  inverted: 'Inverted',
  // retro: 'Retro',
  // easter: 'Easter'
}

export const ZOOM_OPTIONS = {
  '12': 'Smallest',
  '14': 'Smaller',
  '15': 'Slightly Smaller',
  '16': 'Default',
  '17': 'Slightly Larger',
  '18': 'Larger',
  '20': 'Largest'
}

// Register all preferences here
// Any preferences that are missing static `options` will not be rendered
// in the User Preferences view.
export var availablePreferences = {
  theme: {
    key: 'theme',
    heading: 'UI Contrast',
    desc: 'Adjust the contrast level of the interface to improve legibility',
    default: 'default',
    options: THEME_OPTIONS,
  },
  zoomBaselinePx: {
    key: 'zoomBaselinePx',
    heading: 'Text Size',
    desc: 'Adjust the size of displayed text.',
    default: '16',
    options: ZOOM_OPTIONS
  },
  timeFormat: {
    key: 'timeFormat',
    heading: 'Time Format',
    desc: 'Choose local system time formatting or UTC',
    default: 'local',
    options: ['local', 'utc'],
    reloadOnChange: true
  },
  defaultRoute: {
    key: 'defaultRoute',
    heading: 'Default View',
    desc:
      'Choose the default view to show when you login. Note: this will be overridden if you arrived at the login page by following a link to a different part of the Visualizer.',
    default: 'hud',
    options: _.keys(topLevelNavItems)
  },
  animationsEnabled: {
    key: 'animationsEnabled',
    heading: 'HUD Animations',
    desc:
      'Resource-intensive animations on the HUD can be disabled to improve performance.',
    default: 'enabled',
    options: {
      enabled: 'Animations On',
      disabled: 'Animations Off'
    }
  },
  hudEventsListMode: {
    key: 'hudEventsListMode',
    forceDropdown: true,
    heading: 'HUD Event List',
    desc: 'Choose a preset view for the HUD Event List.',
    default: 'new',
    options: {
      priority: 'Priority Events',
      new: 'New Events',
      assigned: 'Events Assigned to me'
    }
  },
  hudNetflowGraphs: {
    key: 'hudNetflowGraphs',
    default: [
      15, // Mail
      3, // Audio/Video
      7, // Database
      20, // Peer to Peer
      30, // Web
      8 // Encrypted
    ]
  },
  // killboxListSort: {
  //   key: 'killboxListSort',
  //   heading: 'Killbox Default Sort',
  //   desc: 'Choose the default sort method and direction for the Killbox.',
  //   default: 'occurredAt_asc',
  //   options: {
  //     'observedAt_asc': 'Observed Time: Newest First',
  //     'observedAt_desc': 'Observed Time: Oldest First',
  //     'occurredAt_asc': 'Occurred Time: Newest First',
  //     'occurredAt_desc': 'Occurred Time: Oldest First',
  //     'threatScore_asc': 'Threat Score: Highest First',
  //     'threatScore_desc': 'Threat Score: Lowest First'
  //   }
  // },
  explorerColumnsNetflows: {
    key: 'explorerColumnsNetflows'
  },
  explorerColumnsObservations: {
    key: 'explorerColumnsObservations'
  },
  intelManagementColumns: {
    key: 'intelManagementColumns'
  },
  intelManagementColumnWidths: {
    key: 'intelManagementColumnWidths'
  },
  relativeTime: {
    key: 'relativeTime',
    heading: 'Relative Time Display',
    desc:
      'If relative time units (e.g. "4 Days Ago", "Moments ago") are disabled, standard dates (e.g. "2016-04-14 17:04:57") will be displayed.',
    default: 'enabled',
    options: {
      enabled: 'Relative Time On',
      disabled: 'Relative Time Off'
    }
  },
  timeDisplayFormat: {
    key: 'timeDisplayFormat',
    heading: 'Time Display Formatting',
    desc: 'Change how Dates and Times are displayed in the UI',
    default: 'standard',
    options: _.mapValues(timeFormattingOptions, v => v.display),
    reloadOnChange: true
  },
  intelManagementIDSRevManagement: {
    key: 'intelManagementIDSRevManagement',
    heading: 'Intel: IDS Rule "rev" management',
    desc:
      'Allow Intel Management to automatically increment the revision ("rev") field in IDS Intel Rules by one whenever they are saved.',
    default: 'enabled',
    options: {
      enabled: 'Automatic',
      disabled: 'Manual'
    }
  },
  explorerSavedQueries: {
    key: 'explorerSavedQueries',
    immuneToReset: true
  },
  explorerQueryHistory: {
    key: 'explorerQueryHistory',
    immuneToReset: true
  },
  viewedHints: {
    key: 'viewedHints',
    default: [],
    heading: 'Viewed Hints',
    desc: 'Reset all Hints that are shown to new users'
  }
}

export var preferenceKeys = _.mapValues(availablePreferences, 'key')
export var preferenceDefaults = _.mapValues(availablePreferences, 'default')
export var preferenceOptions = _.mapValues(availablePreferences, 'options')
export var immuneToResetPrefKeys = _.reduce(availablePreferences, (out, pref, key) => {
  if (pref.immuneToReset) {
    out.push(key)
  }
  return out
}, [])
export var defaultKillboxSortField = 'occurredAt'

export var killboxSortOptions = {
  default: `${defaultKillboxSortField}_desc`,
  options: {
    observedAt_asc: 'Observed Time - Oldest First',
    observedAt_desc: 'Observed Time - Newest First',
    occurredAt_asc: 'Occurred Time - Oldest First',
    occurredAt_desc: 'Occurred Time - Newest First',
    threatScore_asc: 'Threat Score - Lowest First',
    threatScore_desc: 'Threat Score - Highest First'
  }
}

export const MIN_SENSOR_VERSION_FOR_PERCEPTIVE_CAPTURE = "1.9.0"

export const THREAT_CATEGORIES_ENUM = {
  "Adware": 2,
  "Apt": 16,
  "Botnet": 12,
  "Botnets": 3,
  "DenialOfService": 9,
  "Downloader": 7,
  "ExploitsAndAttacks": 8,
  "MaliciousDocument": 5,
  "MaliciousHost": 15,
  "MaliciousWebpage": 6,
  "Malware": 10,
  "Misc": 17,
  "Phishing": 13,
  "Scanner": 4,
  "Scanning": 11,
  "Suspicious": 14,
  "Trojan": 0,
  "Unknown": 18,
  "Worm": 1
}

export const THREAT_CATEGORIES_V2 = _.keys(THREAT_CATEGORIES_ENUM)
export const THREAT_CATEGORIES_V2_TO_NAMES = _.mapValues(THREAT_CATEGORIES_ENUM, (v, k) => {
  if (k === "Apt") {
    return "APT"
  }
  else {
    return _.startCase(k)
  }
})

export const FILE_TYPES = {
  "ApplicationDicom": 0,
  "ApplicationEpubZip": 1,
  "ApplicationInf": 2,
  "ApplicationJavaArchive": 3,
  "ApplicationJavascript": 4,
  "ApplicationMacBinhex40": 5,
  "ApplicationMachO": 6,
  "ApplicationMarc": 7,
  "ApplicationMsword": 8,
  "ApplicationOctetStream": 9,
  "ApplicationOgg": 10,
  "ApplicationPdf": 11,
  "ApplicationPgp": 12,
  "ApplicationPgpEncrypted": 13,
  "ApplicationPgpKeys": 14,
  "ApplicationPgpSignature": 15,
  "ApplicationPostscript": 16,
  "ApplicationSereal": 17,
  "ApplicationVndCupsRaster": 18,
  "ApplicationVndFdf": 19,
  "ApplicationVndFontFontforgeSfd": 20,
  "ApplicationVndGoogleEarthKmlXml": 21,
  "ApplicationVndGoogleEarthKmz": 22,
  "ApplicationVndIccprofile": 23,
  "ApplicationVndLotusWordpro": 24,
  "ApplicationVndMsCabCompressed": 25,
  "ApplicationVndMsExcel": 26,
  "ApplicationVndMsFontobject": 27,
  "ApplicationVndMsOpentype": 28,
  "ApplicationVndMsTnef": 29,
  "ApplicationVndOasisOpendocumentChart": 30,
  "ApplicationVndOasisOpendocumentChartTemplate": 31,
  "ApplicationVndOasisOpendocumentDatabase": 32,
  "ApplicationVndOasisOpendocumentFormula": 33,
  "ApplicationVndOasisOpendocumentFormulaTemplate": 34,
  "ApplicationVndOasisOpendocumentGraphics": 35,
  "ApplicationVndOasisOpendocumentGraphicsTemplate": 36,
  "ApplicationVndOasisOpendocumentImage": 37,
  "ApplicationVndOasisOpendocumentImageTemplate": 38,
  "ApplicationVndOasisOpendocumentPresentation": 39,
  "ApplicationVndOasisOpendocumentPresentationTemplate": 40,
  "ApplicationVndOasisOpendocumentSpreadsheet": 41,
  "ApplicationVndOasisOpendocumentSpreadsheetTemplate": 42,
  "ApplicationVndOasisOpendocumentText": 43,
  "ApplicationVndOasisOpendocumentTextMaster": 44,
  "ApplicationVndOasisOpendocumentTextTemplate": 45,
  "ApplicationVndOasisOpendocumentTextWeb": 46,
  "ApplicationVndOpenxmlformatsOfficedocument": 47,
  "ApplicationVndOpenxmlformatsOfficedocumentPresentationmlPresentation": 48,
  "ApplicationVndOpenxmlformatsOfficedocumentSpreadsheetmlSheet": 49,
  "ApplicationVndOpenxmlformatsOfficedocumentWordprocessingmlDocument": 50,
  "ApplicationVndRnRealmedia": 51,
  "ApplicationVndSymbianInstall": 52,
  "ApplicationVndTcpdumpPcap": 53,
  "ApplicationWarc": 54,
  "ApplicationX123": 55,
  "ApplicationX7zCompressed": 56,
  "ApplicationXAbookAddressbook": 57,
  "ApplicationXAdrift": 58,
  "ApplicationXAppleDiskimage": 59,
  "ApplicationXArc": 60,
  "ApplicationXArchive": 61,
  "ApplicationXArj": 62,
  "ApplicationXBittorrent": 63,
  "ApplicationXBzip2": 64,
  "ApplicationXCompress": 65,
  "ApplicationXCoredump": 66,
  "ApplicationXCpio": 67,
  "ApplicationXDbase": 68,
  "ApplicationXDbf": 69,
  "ApplicationXDbm": 70,
  "ApplicationXDebianPackage": 71,
  "ApplicationXDvi": 72,
  "ApplicationXEet": 73,
  "ApplicationXElc": 74,
  "ApplicationXEpocAgenda": 75,
  "ApplicationXEpocApp": 76,
  "ApplicationXEpocData": 77,
  "ApplicationXEpocJotter": 78,
  "ApplicationXEpocOpl": 79,
  "ApplicationXEpocOpo": 80,
  "ApplicationXEpocSheet": 81,
  "ApplicationXEpocWord": 82,
  "ApplicationXExecutable": 83,
  "ApplicationXFontSfn": 84,
  "ApplicationXFontTtf": 85,
  "ApplicationXFreemind": 86,
  "ApplicationXFreeplane": 87,
  "ApplicationXGdbm": 88,
  "ApplicationXGnucash": 89,
  "ApplicationXGnumeric": 90,
  "ApplicationXGnupgKeyring": 91,
  "ApplicationXGzip": 92,
  "ApplicationXHdf": 93,
  "ApplicationXHwp": 94,
  "ApplicationXIaArc": 95,
  "ApplicationXIchitaro4": 96,
  "ApplicationXIchitaro5": 97,
  "ApplicationXIchitaro6": 98,
  "ApplicationXIma": 99,
  "ApplicationXIso9660Image": 100,
  "ApplicationXJavaApplet": 101,
  "ApplicationXJavaJceKeystore": 102,
  "ApplicationXJavaKeystore": 103,
  "ApplicationXJavaPack200": 104,
  "ApplicationXKdelnk": 105,
  "ApplicationXLha": 106,
  "ApplicationXLharc": 107,
  "ApplicationXLrzip": 108,
  "ApplicationXLz4": 109,
  "ApplicationXLzip": 110,
  "ApplicationXLzma": 111,
  "ApplicationXMdx": 112,
  "ApplicationXMif": 113,
  "ApplicationXMsReader": 114,
  "ApplicationXMsaccess": 115,
  "ApplicationXMsi": 116,
  "ApplicationXNekovmBytecode": 117,
  "ApplicationXObject": 118,
  "ApplicationXPgpKeyring": 119,
  "ApplicationXPnf": 120,
  "ApplicationXQpress": 121,
  "ApplicationXQuarkXpress3": 122,
  "ApplicationXQuicktimePlayer": 123,
  "ApplicationXRar": 124,
  "ApplicationXRpm": 125,
  "ApplicationXSc": 126,
  "ApplicationXScribus": 127,
  "ApplicationXSetupscript": 128,
  "ApplicationXSharedlib": 129,
  "ApplicationXShockwaveFlash": 130,
  "ApplicationXSnappyFramed": 131,
  "ApplicationXStuffit": 132,
  "ApplicationXSvr4Package": 133,
  "ApplicationXTar": 134,
  "ApplicationXTexTfm": 135,
  "ApplicationXTokyocabinetBtree": 136,
  "ApplicationXTokyocabinetFixed": 137,
  "ApplicationXTokyocabinetHash": 138,
  "ApplicationXTokyocabinetTable": 139,
  "ApplicationXWineExtensionInf": 140,
  "ApplicationXWineExtensionIni": 141,
  "ApplicationXXz": 142,
  "ApplicationXZoo": 143,
  "ApplicationXml": 144,
  "ApplicationXmlSitemap": 145,
  "ApplicationZip": 146,
  "ApplicationZlib": 147,
  "AudioBasic": 148,
  "AudioMidi": 149,
  "AudioMp4": 150,
  "AudioMpeg": 151,
  "AudioOgg": 152,
  "AudioVndDolbyDdRaw": 153,
  "AudioXAdpcm": 154,
  "AudioXAiff": 155,
  "AudioXApe": 156,
  "AudioXDecBasic": 157,
  "AudioXFlac": 158,
  "AudioXHxAacAdif": 159,
  "AudioXHxAacAdts": 160,
  "AudioXM4a": 161,
  "AudioXMod": 162,
  "AudioXMp4aLatm": 163,
  "AudioXMusepack": 164,
  "AudioXPnRealaudio": 165,
  "AudioXUnknown": 166,
  "AudioXW64": 167,
  "AudioXWav": 168,
  "ChemicalXPdb": 169,
  "ImageBpg": 170,
  "ImageGif": 171,
  "ImageJp2": 172,
  "ImageJpeg": 173,
  "ImageJpm": 174,
  "ImageJpx": 175,
  "ImagePng": 176,
  "ImageSvgXml": 177,
  "ImageTiff": 178,
  "ImageVndAdobePhotoshop": 179,
  "ImageVndDjvu": 180,
  "ImageVndDwg": 181,
  "ImageVndRadiance": 182,
  "ImageXAwardBioslogo": 183,
  "ImageXAwardBmp": 184,
  "ImageXCanonCr2": 185,
  "ImageXCanonCrw": 186,
  "ImageXCoreldraw": 187,
  "ImageXCpi": 188,
  "ImageXCur": 189,
  "ImageXDpx": 190,
  "ImageXEpocMbm": 191,
  "ImageXEpocSketch": 192,
  "ImageXExr": 193,
  "ImageXIcon": 194,
  "ImageXLss16": 195,
  "ImageXMsBmp": 196,
  "ImageXNiff": 197,
  "ImageXOlympusOrf": 198,
  "ImageXPaintnet": 199,
  "ImageXPcx": 200,
  "ImageXPfs": 201,
  "ImageXPgf": 202,
  "ImageXPolarMonitorBitmap": 203,
  "ImageXPortableBitmap": 204,
  "ImageXPortableGreymap": 205,
  "ImageXPortablePixmap": 206,
  "ImageXQuicktime": 207,
  "ImageXUnknown": 208,
  "ImageXX3f": 209,
  "ImageXXcf": 210,
  "ImageXXcursor": 211,
  "ImageXXpmi": 212,
  "ImageXXwindowdump": 213,
  "MessageNews": 214,
  "MessageRfc822": 215,
  "ModelVrml": 216,
  "ModelX3d": 217,
  "Pe": 218,
  "RinexBroadcast": 219,
  "RinexClock": 220,
  "RinexMeteorological": 221,
  "RinexNavigation": 222,
  "RinexObservation": 223,
  "TextCalendar": 224,
  "TextHtml": 225,
  "TextInf": 226,
  "TextPgp": 227,
  "TextPlain": 228,
  "TextRtf": 229,
  "TextTexmacs": 230,
  "TextTroff": 231,
  "TextVndGraphviz": 232,
  "TextXAsm": 233,
  "TextXAwk": 234,
  "TextXBcpl": 235,
  "TextXC": 236,
  "TextXCpp": 237,
  "TextXDiff": 238,
  "TextXFortran": 239,
  "TextXGawk": 240,
  "TextXInfo": 241,
  "TextXJava": 242,
  "TextXLisp": 243,
  "TextXLua": 244,
  "TextXM4": 245,
  "TextXMakefile": 246,
  "TextXMsdosBatch": 247,
  "TextXNawk": 248,
  "TextXPascal": 249,
  "TextXPerl": 250,
  "TextXPhp": 251,
  "TextXPo": 252,
  "TextXPython": 253,
  "TextXRuby": 254,
  "TextXShellscript": 255,
  "TextXTcl": 256,
  "TextXTex": 257,
  "TextXTexinfo": 258,
  "TextXVcard": 259,
  "TextXXmcd": 260,
  "Undefined": 261,
  "Video3gpp": 262,
  "Video3gpp2": 263,
  "VideoH264": 264,
  "VideoMj2": 265,
  "VideoMp2p": 266,
  "VideoMp2t": 267,
  "VideoMp4": 268,
  "VideoMp4vEs": 269,
  "VideoMpeg": 270,
  "VideoMpeg4Generic": 271,
  "VideoMpv": 272,
  "VideoOgg": 273,
  "VideoQuicktime": 274,
  "VideoVndDvbFile": 275,
  "VideoWebm": 276,
  "VideoXFlc": 277,
  "VideoXFli": 278,
  "VideoXFlv": 279,
  "VideoXJng": 280,
  "VideoXM4v": 281,
  "VideoXMatroska": 282,
  "VideoXMng": 283,
  "VideoXMsAsf": 284,
  "VideoXMsvideo": 285,
  "VideoXSgiMovie": 286,
  "VideoXUnknown": 287,
  "XEpocXSisxApp": 288
}

export const ANOMALOUS_FLOW_RULES = {
  'wrong-protocol-scanner': 'Protocol Mismatch',
  'protocol-on-wrong-port-scanner': 'Protocol on Unexpected Port',
  'port-scan-scanner': 'Internal Port Scanning',
  'outbound-smb-scanner': 'Outbound SMB',
  'brute-force-ssh-scanner': 'Brute Force SSH',
  'outbound-rdp-smb-tunneling-scanner': 'Outbound RDP/SMB Tunneling',
  'opc-forbidden-on-ipv6-scanner': 'DCOM Connection Over IPv6 Usually Forbidden',


  //The following are ICS-specific...
  'unexpected-protocol-scanner': 'Unexpected Protocol on Level 2 Device',
  'suspicious-outbound-rdp-scanner': 'Suspicious Outbound RDP',
  'level2-connecting-to-port80-scanner': 'Level 2 Device Connecting to Port 80',
  'modbus-port-scanner': 'Modbus Port Scanning',
  'enip-port-scanner': 'EthernetIP Port Scanning',
  'failed-modbus-connection-attempts-scanner': 'Failed Modbus Connection Attempts',
  'iodine-dns-tunneling-scanner': 'DNS Tunneling via Iodine',
  'Not-ICS-Traffic-On-ICS-Port-scanner': 'ICS Protocol Mismatch',
  'incongruent-protocol-scanner': 'Suspicious Protocol Pair',

}

export const ANOMALOUS_OBSERVATION_RULES = {
  'modbus-pipelined-function-other-than-read': 'Pipelined Modbus Function Not a Read Function',
  'modbus-pipelined-function-different-from-first': 'Pipelined Modbus Function Different than First in Packet',
  'modbus-inconsistent-length-in-request': 'Inconsistent Length in Modbus Request',
  'modbus-inconsistent-length-in-response': 'Inconsistent Length in Modbus Response',
  'modbus-use-of-serial-function': 'Serial Modbus Function Encountered',
  'modbus-use-of-restricted-function': 'Restricted Modbus Function Encountered',
  'modbus-use-of-uncommon-function': 'Uncommon Modbus Function Encountered',
  'modbus-use-of-programming-function': 'Programming Modbus Function Encountered',
  'divergent-anomaly': 'Divergent Anomaly',
  'opc-over-forbidden-transport-protocol': 'DCOM Response Contains Invalid Transport Protocol'
}

// Sourced from https://gitlab.int.protectwise.net/data-science/tsa
const ANOMALY_RULES_BASE = {
  'externalBytes1': {
    title: 'Internal Origination Bytes Sent Anomaly',
    description: 'DeviceID based sum of internal/external source bytes',
    // pivot: {
    //   netflows: {
    //     direction: 'InternalExternal' // Enum 1
    //   }
    // }
  },
  'externalBytes2': {
    title: 'External Origination Bytes Sent Anomaly',
    description: 'DeviceID based sum of external/internal destination bytes',
    // pivot: {
    //   netflows: {
    //     direction: 'ExternalInternal' // Enum 2
    //   }
    // }
  },
  'ftpDataBytes': {
    title: 'FTP Data Bytes Anomaly',
    description: 'DeviceID based sum of total FTP Data bytes',
    protocol: 'FTP Data',
    pivot: {
      family: 'netflows',
      clauses: [
        { name: 'protocols', op: 'eq', value: 'FTP_DATA' }
      ]
    }
  },
  'ftpControlBytes': {
    title: 'FTP Control Bytes Anomaly',
    description: 'DeviceID based sum of total FTP Control bytes',
    protocol: 'FTP Control',
    pivot: {
      family: 'netflows',
      clauses: [
        { name: 'protocols', op: 'eq', value: 'FTP_CONTROL' }
      ]
    }
  },
  'httpBytes': {
    title: 'HTTP Bytes Anomaly',
    description: 'DeviceID based sum of total HTTP bytes',
    protocol: 'HTTP',
    pivot: {
      family: 'netflows',
      clauses: [
        { name: 'protocols', op: 'eq', value: 'HTTP' }
      ]
    }
  },
  'sslBytes': {
    title: 'SSL Bytes Anomaly',
    description: 'DeviceID based sum of total SSL bytes',
    protocol: 'SSL',
    pivot: {
      family: 'netflows',
      clauses: [
        { name: 'protocols', op: 'eq', value: 'SSL' }
      ]
    }
  },
  'sshBytes': {
    title: 'SSH Bytes Anomaly',
    description: 'DeviceID based sum of total SSH bytes',
    protocol: 'SSH',
    pivot: {
      family: 'netflows',
      clauses: [
        { name: 'protocols', op: 'eq', value: 'SSH' }
      ]
    }
  },
  'rdpBytes': {
    title: 'RDP Bytes Anomaly',
    description: 'DeviceID based sum of total RDP bytes',
    protocol: 'RDP',
    pivot: {
      family: 'netflows',
      clauses: [
        { name: 'protocols', op: 'eq', value: 'RDP' }
      ]
    }
  },
  'smbBytes': {
    title: 'SMB Bytes Anomaly',
    description: 'DeviceID based sum of total SMB bytes',
    protocol: 'SMB',
    pivot: {
      family: 'netflows',
      clauses: [
        { name: 'protocols', op: 'eq', value: 'SMB' }
      ]
    }
  },
  'unknownBytes': {
    title: 'Unknown Protocol Bytes Anomaly',
    description: 'DeviceID based sum of total Unknown bytes (closed netflows with no known protolcols)',
    protocol: 'Unknown',
    pivot: {
      family: 'netflows',
      clauses: [
        { name: 'protocols', op: 'eq', value: 'Unknown' }
      ]
    }
  },
  'dnsBytes': {
    title: 'DNS Bytes Anomaly',
    description: 'DeviceID based sum of total DNS bytes',
    protocol: 'DNS',
    pivot: {
      family: 'netflows',
      clauses: [
        { name: 'protocols', op: 'eq', value: 'DNS' }
      ]
    }
  },
  'rdpTunnelBytes': {
    title: 'RDP Tunnel Bytes Anomaly',
    description: 'DeviceID based sum of total RDP Tunnel bytes',
    protocol: 'RDP',
    pivot: {
      family: 'netflows',
      clauses: [
        { name: 'protocols', op: 'eq', value: 'RDP' },
        { name: 'srcPort', op: 'eq', value: [21, 22, 23, 25, 53, 80, 443, 8080] },
        { name: 'dstPort', op: '!eq', value: 3389 }
      ]
    }
  },
  'dcerpcBytes': {
    title: 'DCE RPC Bytes Anomaly',
    description: 'DeviceID based sum of total DCE RPC bytes',
    protocol: 'DCE_RPC',
    pivot: {
      family: 'netflows',
      clauses: [
        { name: 'protocols', op: 'eq', value: 'DCE_RPC' }
      ]
    }
  },
  'ldapBytes': {
    title: 'LDAP Bytes Anomaly',
    description: 'DeviceID based sum of total LDAP bytes',
    protocol: 'LDAP',
    pivot: {
      family: 'netflows',
      clauses: [
        { name: 'protocols', op: 'eq', value: 'LDAP' }
      ]
    }
  },
  'kerberosBytes': {
    title: 'Kerberos Bytes Anomaly',
    description: 'DeviceID based sum of total Kerberos bytes',
    protocol: 'Kerberos',
    pivot: {
      family: 'netflows',
      clauses: [
        { name: 'protocols', op: 'eq', value: 'Kerberos' }
      ]
    }
  },

  // NOTE sessions are 1:1 with *closed* netflows
  'ftpDataSessions': {
    title: 'FTP Data Sessions Anomaly',
    description: 'DeviceID based count of closed FTP Data sessions',
    protocol: 'FTP Data',
    pivot: {
      family: 'netflows',
      clauses: [
        { name: 'protocols', op: 'eq', value: 'FTP_DATA' }
      ]
    }
  },
  'ftpControlSessions': {
    title: 'FTP Control Sessions Anomaly',
    description: 'DeviceID based count of closed FTP Control sessions',
    protocol: 'FTP Control',
    pivot: {
      family: 'netflows',
      clauses: [
        { name: 'protocols', op: 'eq', value: 'FTP_CONTROL' }
      ]
    }
  },
  'httpSessions': {
    title: 'HTTP Sessions Anomaly',
    description: 'DeviceID based count of closed HTTP sessions',
    protocol: 'HTTP',
    pivot: {
      family: 'netflows',
      clauses: [
        { name: 'protocols', op: 'eq', value: 'HTTP' }
      ]
    }
  },
  'sslSessions': {
    title: 'SSL Sessions Anomaly',
    description: 'DeviceID based count of closed SSL sessions',
    protocol: 'SSL',
    pivot: {
      family: 'netflows',
      clauses: [
        { name: 'protocols', op: 'eq', value: 'SSL' }
      ]
    }
  },
  'sshSessions': {
    title: 'SSH Sessions Anomaly',
    description: 'DeviceID based count of closed SSH sessions',
    protocol: 'SSH',
    pivot: {
      family: 'netflows',
      clauses: [
        { name: 'protocols', op: 'eq', value: 'SSH' }
      ]
    }
  },
  'rdpSessions': {
    title: 'RDP Sessions Anomaly',
    description: 'DeviceID based count of closed RDP sessions',
    protocol: 'RDP',
    pivot: {
      family: 'netflows',
      clauses: [
        { name: 'protocols', op: 'eq', value: 'RDP' }
      ]
    }
  },
  'smbSessions': {
    title: 'SMB Sessions Anomaly',
    description: 'DeviceID based count of closed SMB sessions',
    protocol: 'SMB',
    pivot: {
      family: 'netflows',
      clauses: [
        { name: 'protocols', op: 'eq', value: 'SMB' }
      ]
    }
  },
  'unknownSessions': {
    title: 'Unknown Protocol Sessions Anomaly',
    description: 'DeviceID based count of closed Unkown sessions (closed netflows with no known protolcols)',
    protocol: 'Unknown',
    pivot: {
      family: 'netflows',
      clauses: [
        { name: 'protocols', op: 'eq', value: 'Unknown' }
      ]
    }
  },
  'dnsSessions': {
    title: 'DNS Sessions Anomaly',
    description: 'DeviceID based count of closed DNS sessions',
    protocol: 'DNS',
    pivot: {
      family: 'netflows',
      clauses: [
        { name: 'protocols', op: 'eq', value: 'DNS' }
      ]
    }
  },
  'nxdomain': {
    title: 'DNS NXDOMAIN Anomaly',
    description: 'DeviceID based count of NXDOMAIN DNS responses.',
    protocol: 'DNS',
    pivot: {
      family: 'netflows',
      clauses: [
        { name: 'protocols', op: 'eq', value: 'DNS' }
      ]
    }
  },
  'txtRecord': {
    title: 'DNS TXT Record Anomaly',
    description: 'DeviceID based count of DNS TXT record requests.',
    protocol: 'DNS',
    pivot: {
      family: 'netflows',
      clauses: [
        { name: 'protocols', op: 'eq', value: 'DNS' }
      ]
    }
  },
  'rdpTunnelSessions': {
    title: 'RDP Tunnel Sessions Anomaly',
    description: 'DeviceID based count of RDP Tunnel sessions',
    protocol: 'RDP',
    pivot: {
      family: 'netflows',
      clauses: [
        { name: 'protocols', op: 'eq', value: 'RDP' },
        { name: 'srcPort', op: 'eq', value: [21, 22, 23, 25, 53, 80, 443, 8080] },
        { name: 'dstPort', op: '!eq', value: 3389 }
      ]
    }
  },
  'dcerpcSessions': {
    title: 'DCE RPC Sessions Anomaly',
    description: 'DeviceID based count of DCE RPC sessions',
    protocol: 'DCE_RPC',
    pivot: {
      family: 'netflows',
      clauses: [
        { name: 'protocols', op: 'eq', value: 'DCE_RPC' }
      ]
    }
  },
  'ldapSessions': {
    title: 'LDAP Sessions Anomaly',
    description: 'DeviceID based count of LDAP sessions',
    protocol: 'LDAP',
    pivot: {
      family: 'netflows',
      clauses: [
        { name: 'protocols', op: 'eq', value: 'LDAP' }
      ]
    }
  },
  'kerberosSessions': {
    title: 'Kerberos Sessions Anomaly',
    description: 'DeviceID based count of Kerberos sessions',
    protocol: 'Kerberos',
    pivot: {
      family: 'netflows',
      clauses: [
        { name: 'protocols', op: 'eq', value: 'Kerberos' }
      ]
    }
  }
}

const ICS_ANOMALIES = {
  'modbusTcpBytes': {
    title: 'Modbus/TCP Bytes Anomaly',
    description: 'DeviceID based sum of total Modbus/TCP bytes',
    protocol: 'MODBUS_TCP',
    pivot: {
      family: 'netflows',
      clauses: [
        { name: 'protocols', op: 'eq', value: 'MODBUS_TCP' }
      ]
    }
  },
  'ethernetIpBytes': {
    title: 'Ethernet/IP Bytes Anomaly',
    description: 'DeviceID based sum of total Ethernet/IP bytes',
    protocol: 'ETHERNET_IP',
    pivot: {
      family: 'netflows',
      clauses: [
        { name: 'protocols', op: 'eq', value: 'ETHERNET_IP' }
      ]
    }
  },
  'cipBytes': {
    title: 'CIP Bytes Anomaly',
    description: 'DeviceID based sum of total CIP bytes',
    protocol: 'CIP',
    pivot: {
      family: 'netflows',
      clauses: [
        { name: 'protocols', op: 'eq', value: 'CIP' }
      ]
    }
  },
  'dnp3Bytes': {
    title: 'DNP3 Bytes Anomaly',
    description: 'DeviceID based sum of total DNP3 bytes',
    protocol: 'DNP3',
    pivot: {
      family: 'netflows',
      clauses: [
        { name: 'protocols', op: 'eq', value: 'DNP3' }
      ]
    }
  },
  'honeywellCdaBytes': {
    title: 'Honeywell CDA Bytes Anomaly',
    description: 'DeviceID based sum of total Honeywell CDA bytes',
    protocol: 'HONEYWELL_CDA',
    pivot: {
      family: 'netflows',
      clauses: [
        { name: 'protocols', op: 'eq', value: 'HONEYWELL_CDA' }
      ]
    }
  },
  'honeywellFteBytes': {
    title: 'Honeywell FTE Bytes Anomaly',
    description: 'DeviceID based sum of total Honeywell FTE bytes',
    protocol: 'HONEYWELL_FTE',
    pivot: {
      family: 'netflows',
      clauses: [
        { name: 'protocols', op: 'eq', value: 'HONEYWELL_FTE' }
      ]
    }
  },
  'vnetIpBytes': {
    title: 'Vnet/IP Bytes Anomaly',
    description: 'DeviceID based sum of total Vnet/IP bytes',
    protocol: 'VNET_IP',
    pivot: {
      family: 'netflows',
      clauses: [
        { name: 'protocols', op: 'eq', value: 'VNET_IP' }
      ]
    }
  },
  'deltavBytes': {
    title: 'DeltaV Bytes Anomaly',
    description: 'DeviceID based sum of total DeltaV bytes',
    protocol: 'DELTAV',
    pivot: {
      family: 'netflows',
      clauses: [
        { name: 'protocols', op: 'eq', value: 'DELTAV' }
      ]
    }
  },
  's7commBytes': {
    title: 'S7 Comm Bytes Anomaly',
    description: 'DeviceID based sum of total S7 Comm bytes',
    protocol: 'S7 Comm ',
    pivot: {
      family: 'netflows',
      clauses: [
        { name: 'protocols', op: 'eq', value: 'S7_COMM ' }
      ]
    }
  },
  'egdBytes': {
    title: 'EGD Bytes Anomaly',
    description: 'DeviceID based sum of total EGD bytes',
    protocol: 'EGD',
    pivot: {
      family: 'netflows',
      clauses: [
        { name: 'protocols', op: 'eq', value: 'EGD' }
      ]
    }
  },
  'srtpBytes': {
    title: 'SRTP Bytes Anomaly',
    description: 'DeviceID based sum of total SRTP bytes',
    protocol: 'SRTP',
    pivot: {
      family: 'netflows',
      clauses: [
        { name: 'protocols', op: 'eq', value: 'SRTP' }
      ]
    }
  },
  'tristationBytes': {
    title: 'TRISTATION Bytes Anomaly',
    description: 'DeviceID based sum of total TRISTATION bytes',
    protocol: 'TRISTATION',
    pivot: {
      family: 'netflows',
      clauses: [
        { name: 'protocols', op: 'eq', value: 'TRISTATION' }
      ]
    }
  },
  'modbusRtuBytes': {
    title: 'MODBUS RTU Bytes Anomaly',
    description: 'DeviceID based sum of total MODBUS RTU bytes',
    protocol: 'MODBUS_RTU',
    pivot: {
      family: 'netflows',
      clauses: [
        { name: 'protocols', op: 'eq', value: 'MODBUS_RTU' }
      ]
    }
  },
  'pcccBytes': {
    title: 'PCCC Bytes Anomaly',
    description: 'DeviceID based sum of total PCCC bytes',
    protocol: 'PCCC',
    pivot: {
      family: 'netflows',
      clauses: [
        { name: 'protocols', op: 'eq', value: 'PCCC' }
      ]
    }
  },
  'modbusTcpSessions': {
    title: 'Modbus/TCP Sessions Anomaly',
    description: 'DeviceID based count of closed Modbus/TCP sessions',
    protocol: 'MODBUS_TCP',
    pivot: {
      family: 'netflows',
      clauses: [
        { name: 'protocols', op: 'eq', value: 'MODBUS_TCP' }
      ]
    }
  },
  'ethernetIpSessions': {
    title: 'Ethernet/IP Sessions Anomaly',
    description: 'DeviceID based count of closed Ethernet/IP sessions',
    protocol: 'ETHERNET_IP',
    pivot: {
      family: 'netflows',
      clauses: [
        { name: 'protocols', op: 'eq', value: 'ETHERNET_IP' }
      ]
    }
  },
  'cipSessions': {
    title: 'CIP Sessions Anomaly',
    description: 'DeviceID based count of closed CIP sessions',
    protocol: 'CIP',
    pivot: {
      family: 'netflows',
      clauses: [
        { name: 'protocols', op: 'eq', value: 'CIP' }
      ]
    }
  },
  'dnp3Sessions': {
    title: 'DNP3 Sessions Anomaly',
    description: 'DeviceID based count of closed DNP3 sessions',
    protocol: 'DNP3',
    pivot: {
      family: 'netflows',
      clauses: [
        { name: 'protocols', op: 'eq', value: 'DNP3' }
      ]
    }
  },
  'honeywellCdaSessions': {
    title: 'Honeywell CDA Sessions Anomaly',
    description: 'DeviceID based count of closed Honeywell CDA sessions',
    protocol: 'HONEYWELL_CDA',
    pivot: {
      family: 'netflows',
      clauses: [
        { name: 'protocols', op: 'eq', value: 'HONEYWELL_CDA' }
      ]
    }
  },
  'honeywellFteSessions': {
    title: 'Honeywell FTE Sessions Anomaly',
    description: 'DeviceID based count of closed Honeywell FTE sessions',
    protocol: 'HONEYWELL_FTE',
    pivot: {
      family: 'netflows',
      clauses: [
        { name: 'protocols', op: 'eq', value: 'HONEYWELL_FTE' }
      ]
    }
  },
  'vnetIpSessions': {
    title: 'Vnet/IP Sessions Anomaly',
    description: 'DeviceID based count of closed Vnet/IP sessions',
    protocol: 'VNET_IP',
    pivot: {
      family: 'netflows',
      clauses: [
        { name: 'protocols', op: 'eq', value: 'VNET_IP' }
      ]
    }
  },
  'deltavSessions': {
    title: 'DeltaV Sessions Anomaly',
    description: 'DeviceID based count of closed DeltaV sessions',
    protocol: 'DELTAV',
    pivot: {
      family: 'netflows',
      clauses: [
        { name: 'protocols', op: 'eq', value: 'DELTAV' }
      ]
    }
  },
  's7commSessions': {
    title: 'S7 Comm Sessions Anomaly',
    description: 'DeviceID based count of closed S7 Comm sessions',
    protocol: 'S7 Comm ',
    pivot: {
      family: 'netflows',
      clauses: [
        { name: 'protocols', op: 'eq', value: 'S7_COMM ' }
      ]
    }
  },
  'egdSessions': {
    title: 'EGD Sessions Anomaly',
    description: 'DeviceID based count of closed EGD sessions',
    protocol: 'EGD',
    pivot: {
      family: 'netflows',
      clauses: [
        { name: 'protocols', op: 'eq', value: 'EGD' }
      ]
    }
  },
  'srtpSessions': {
    title: 'SRTP Sessions Anomaly',
    description: 'DeviceID based count of closed SRTP sessions',
    protocol: 'SRTP',
    pivot: {
      family: 'netflows',
      clauses: [
        { name: 'protocols', op: 'eq', value: 'SRTP' }
      ]
    }
  },
  'tristationSessions': {
    title: 'TRISTATION Sessions Anomaly',
    description: 'DeviceID based count of closed TRISTATION sessions',
    protocol: 'TRISTATION',
    pivot: {
      family: 'netflows',
      clauses: [
        { name: 'protocols', op: 'eq', value: 'TRISTATION' }
      ]
    }
  },
  'modbusRtuSessions': {
    title: 'MODBUS RTU Sessions Anomaly',
    description: 'DeviceID based count of closed MODBUS RTU sessions',
    protocol: 'MODBUS_RTU',
    pivot: {
      family: 'netflows',
      clauses: [
        { name: 'protocols', op: 'eq', value: 'MODBUS_RTU' }
      ]
    }
  },
  'pcccSessions': {
    title: 'PCCC Sessions Anomaly',
    description: 'DeviceID based count of closed PCCC sessions',
    protocol: 'PCCC',
    pivot: {
      family: 'netflows',
      clauses: [
        { name: 'protocols', op: 'eq', value: 'PCCC' }
      ]
    }
  },
}

// Merge all anomaly rules together.
export const ANOMALY_RULES = {
  ...ANOMALY_RULES_BASE,
  ...ICS_ANOMALIES
}

/**
 * Given an enum string FileType value (in either PascalCase or KEBAB_CASE from API), return a
 * normalized display string
 * @param {string} enumValue
 */
const getFileTypeDisplay = (enumValue) => {
  // const _parts = _.snakeCase(enumValue).split('_')
  // const str = _parts.length === 1 ? _parts[0] : _parts.map((p, i) => (i === 0 ? p : (i === 1 ? `/${p}` : `-${p}`))).join('')
  // return str.toLowerCase()
  return _.startCase(enumValue)
}

export const FILE_TYPES_OPTIONS = _.reduce(FILE_TYPES, (out, enumNumber, value) => {
  out.push({
    enumNumber,
    value,
    text: getFileTypeDisplay(value)
  })
  return out
}, [])

export const INTERNAL_DOMAIN_REGEX = /\.int\.protectwise\.net|staging|localdev/

// Needed for v2 obs response mapping, as the SOLR schema lists `observation.source` as a plain int,
// causing it to come through untranslated. v1/lookup obs translate this correctly
export const OBSERVATION_SOURCES_ENUM = [
  "Surricata",
  "Clam_av",
  "Qosmos",
  "Webroot",
  "Farsight",
  "Protectwise",
  "Norse",
  "Cylance",
  "Riskiq"
]

export const ROLE_NAMES = {
  protectwise_admin: 'verizon admin',
  View: 'read only',
  customer_admin: 'admin',
  User: 'user'
}

export const LOCKED_ROLE_TYPES = [
  'global', 'internal', 'mssp'
]

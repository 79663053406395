import _ from 'lodash'
import React from 'react'
import Reflux from 'reflux'

import LogViewerActions from 'actions/LogViewerActions'
import AnalyticsActions from 'actions/AnalyticsActions'
import genericUtil from 'ui-base/src/util/genericUtil'
import querystringUtil from 'ui-base/src/util/querystringUtil'
import { formatBytes } from 'pw-formatters'
import { formatDate, getCurrentTimeFormat } from 'utils/timeUtils'
import IpValue from 'components/values/IpValue'
import SensorStore from 'stores/SensorStore'
import PreferencesActions from 'actions/PreferencesActions'
import PreferencesStore from 'stores/PreferencesStore'
import IntegrationsActions from 'actions/IntegrationsActions'
import IntegrationsStore from 'stores/IntegrationsStore'
import {abortRequest, requestGet} from 'utils/restUtils'

import additionalPropertiesUtil from 'components/intelcard/data/additionalPropertiesUtil'
import {listenToStore} from 'utils/storeUtils'

function formatIpCol(ip, port) {
  return [
    React.createElement(IpValue, {
      key: ip,
      ip: ip,
      className: `ip ${ip === _state.ip ? 'highlight' : ''}`
    }),
    React.createElement('span', { key: 'port' }, ` : ${port}`)
  ]
}

const getReqIdForDevice = deviceId => `log_viewer_${deviceId}`

const PAGE_SIZE = 100

const TIME_OPTION_COL_WIDTHS = {
  classic: 185,
  classicWithTZOffset: 230,
  standard: 185,
  standardWithTZOffset: 220,
  compact: 180,
  compactWithTZOffset: 220,
  iso: 200
}

export const DEVICE_TYPE_TABS = {
  Firewall: ['traffic', 'threat'],
  EndpointKnowledge: ['traffic', 'threat'],
  Dns: ['traffic', 'threat'],
  NetworkAnalytics: ['alert', 'anomaly']
}

const COMMON_COLUMNS = [
  {
    key: 'srcIp+sport',
    header: 'Src IP',
    renderValue: data => formatIpCol(data.srcIp, data.sport),
    width: 175
  },
  {
    key: 'dstIp+dport',
    header: 'Dst IP',
    renderValue: data => formatIpCol(data.dstIp, data.dport),
    width: 175
  },
  {
    key: 'action',
    header: 'Action',
    renderValue: data => data.action && data.action.toUpperCase()
  },
  {
    fixed: true,
    key: 'receiveTime',
    header: 'Time',
    renderValue: data => formatDate(+data.receiveTime),
    width: TIME_OPTION_COL_WIDTHS[getCurrentTimeFormat()] || 200
  },
  {
    key: 'natSrcIp',
    header: 'NAT Source IP',
    defaultHidden: true,
    width: 175
  },
  {
    key: 'rule',
    header: 'Rule Name',
    width: 135
  },
  {
    key: 'user',
    header: 'Src User',
    width: 150
  },
  {
    key: 'app',
    header: 'Application'
  },
  {
    key: 'deviceName',
    header: 'Device Name'
  },
  {
    key: 'srcZone',
    header: 'Src Zone'
  },
  {
    key: 'dstZone',
    header: 'Dst Zone'
  },
  {
    key: 'proto',
    header: 'Proto',
    renderValue: data => data.proto && data.proto.toUpperCase()
  },
  {
    key: 'cat',
    header: 'Category'
  },
  {
    key: 'srcLoc',
    header: 'Src Location',
    width: 175
  },
  {
    key: 'dstLoc',
    header: 'Dst Location',
    width: 175
  }
]

const ALL_COLUMNS = {
  traffic: COMMON_COLUMNS.map(c => _.clone(c)).concat([
    // NOTE all byte values come through as strings
    {
      key: 'bytes',
      header: 'Bytes',
      renderValue: data =>
        data.bytes
          ? React.createElement(
              'span',
              { 'data-tooltip': data.bytes + ' bytes' },
              formatBytes(+data.bytes)
            )
          : null
    },
    {
      key: 'bytesSent',
      header: 'Bytes Sent',
      renderValue: data =>
        data.bytesSent
          ? React.createElement(
              'span',
              { 'data-tooltip': data.bytesSent + ' bytes' },
              formatBytes(+data.bytesSent)
            )
          : null
    },
    {
      key: 'bytesReceived',
      header: 'Bytes Received',
      renderValue: data =>
        data.bytesReceived
          ? React.createElement(
              'span',
              { 'data-tooltip': data.bytesReceived + ' bytes' },
              formatBytes(+data.bytesReceived)
            )
          : null
    }
  ]),
  threat: COMMON_COLUMNS.map(c => _.clone(c)).concat([
    {
      key: 'threatId',
      header: 'Threat ID'
    },
    {
      key: 'severity',
      header: 'Severity'
    },
    {
      key: 'direction',
      header: 'Direction'
    },
    {
      key: 'urlIndex',
      header: 'URL Index'
    },
    {
      key: 'userAgent',
      header: 'User Agent'
    },
    {
      key: 'fileType',
      header: 'File Type'
    },
    {
      key: 'xForwardedFor',
      header: 'X-Forwarded-For'
    }
  ])
}
_.forOwn(ALL_COLUMNS, cols => {
  cols.forEach(col => {
    col.resizable = true
  })
})

// Written on PreferencesStore update
let _preferences = {}

function _getCurrentColumnOrder() {
  return (
    _preferences[`log_cols_${_state.activeLogType}`] ||
    _.pluck(
      ALL_COLUMNS[_state.activeLogType].filter(col => !col.defaultHidden),
      'key'
    )
  )
}

const _state = {
  // query input params:
  ip: null,
  start: null,
  end: null,
  sensorIds: null,
  activeDeviceId: null,
  activeLogType: 'traffic', //or 'threat' or alerts, or more in future

  // info about available devices:
  devices: {
    isLoading: true,
    error: null,
    data: null //see structure from IntegrationsStore
  },

  // query results per device:
  data: {
    /*
    [deviceId]: {
      isLoading: true|false,
      isPreviewing: true|false,
      isLoadingNextPage: true|false,
      paginationToken: <string>,
      error: null,
      hasResults: true|false|null, //null=unknown
      results: null|[]
    }
    */
  },

  // result columns:
  allColumns: ALL_COLUMNS.traffic,
  columnOrder: []
}

export default Reflux.createStore({
  listenables: LogViewerActions,

  init() {
    listenToStore(this, PreferencesStore, this.onPrefStoreUpdate.bind(this))
    listenToStore(this, IntegrationsStore, this.onIntegrationsStoreUpdate.bind(this))
  },

  getInitialState() {
    return _state
  },

  _notify() {
    this.trigger(_state)
  },

  _queueNotify() {
    clearTimeout(this._queueNotifyTimer)
    this._queueNotifyTimer = setTimeout(this._notify.bind(this), 100)
  },

  _promiseDevices() {
    // MOCK DEVICES FOR DEMO CUSTOMER
    if (window._pw.isDemoCustomer) {
      const sensorIds = _.sortBy(
        SensorStore.getActiveSensorIds(),
        SensorStore.getSensorName
      )
      const numDevices = Math.min(5, sensorIds.length)
      const sensorsPerDevice = Math.ceil(sensorIds.length / numDevices)
      _state.devices = {
        data: _.map(Array(numDevices), (x, i) => {
          const deviceSensors = sensorIds.slice(
            i * sensorsPerDevice,
            Math.min((i + 1) * sensorsPerDevice, sensorIds.length)
          )
          return {
            id: `mock_device_${i + 1}`,
            name: `${SensorStore.getSensorName(deviceSensors[0])} PAN Firewall`,
            type: 'Firewall',
            vendor: 'PAN',
            sensorIds: deviceSensors
          }
        })
      }
      return Promise.resolve(_state.devices.data)
    }
    // END MOCK

    if (_state.devices.data) {
      // already loaded, resolve with current data
      return Promise.resolve(_state.devices.data)
    } else {
      // not yet loaded; return promise to be resolved when the devices data finishes loading
      let promise = this._devicesPromise
      if (!promise) {
        const deferred = genericUtil.defer()
        promise = this._devicesPromise = deferred.promise
        const stopListening = this.listen(() => {
          if (_state.devices.data) {
            stopListening()
            deferred.resolve(_state.devices.data)
          }
        })
        IntegrationsActions.loadDevices()
      }
      return promise
    }
  },

  _doQueryDeviceLog(deviceId, activeLogType, paginationToken = null) {
    if (!_state.data[deviceId] || !_state.data[deviceId].isLoading) {
      _state.data[deviceId] = {
        isLoading: !paginationToken,
        isLoadingNextPage: !!paginationToken,
        results: paginationToken ? _state.data[deviceId].results : null
      }
      this._queueNotify()

      const params = {
        ip: _state.ip,
        start: _state.start,
        end: _state.end,
        deviceId: deviceId,
        subType: activeLogType,
        limit: PAGE_SIZE
      }
      if (paginationToken) {
        params.paginationToken = paginationToken
      }
      const tryRequest = () => {
        // MOCK FOR DEMO CUSTOMER
        if (window._pw.isDemoCustomer) {
          setTimeout(() => {
            const deviceInfo = _.find(_state.devices.data, { id: deviceId })
            const deviceSensors = deviceInfo.sensorIds || []
            const hasResults = _state.sensorIds
              ? _.intersection(_state.sensorIds, deviceSensors).length > 0
              : deviceSensors.length > 0
            _state.data[deviceId] = {
              hasResults: hasResults,
              results: hasResults
                ? this._createMockPANResults(
                    _state.ip,
                    _state.start,
                    _state.end,
                    deviceInfo.name
                  )
                : []
            }
            this._queueNotify()
          }, _.random(300, 1000))
          return
        }
        // END MOCK

        requestGet(
            getReqIdForDevice(deviceId),
            `context-fusion/logs?${querystringUtil.stringify(params)}`
          )
          .then(response => {
            let results =
              (response && _.get(response, '0.logs.0.logData')) || []
            const newPagToken =
              response && _.get(response, '0.logs.0.paginationToken')
            const additionalProperties =
              (response && _.get(response, '0.logs.0.additionalProperties') || [])
            let formattedLogs = additionalPropertiesUtil.tryForJSON(_.get(additionalProperties, '0.values.0.json', null))
            // build log rows like normal response
            let tableRows = _.get(formattedLogs, 'data.0.table.rows', [])
            let formattedRows = tableRows.map(rows =>
              rows.reduce((acc, cum) => {
                acc[cum.name] = cum.value // do some formatting here with cum.type?
                return acc
              }, {}))

            if (results.length === 0) {
              // Empty resultset: if a paginationToken is present then go ahead and re-request until we get a
              // definitive empty/nonempty resultset. Partial page results will be paginated by the UI if needed.
              if (newPagToken) {
                params.paginationToken = newPagToken
                tryRequest()
              } else {
                results = _state.data[deviceId].results || [] //preserve previous pages
                _state.data[deviceId] = {
                  hasResults: !!results.length,
                  results: results
                }
                this._queueNotify()
              }
            } else {
              // Non-empty resultset = positive answer
              _state.data[deviceId] = {
                hasResults: true,
                results: (_state.data[deviceId].results || []).concat(results),
                paginationToken: newPagToken,
                additionalProperties: (_state.data[deviceId].additionalProperties || []).concat(formattedRows)
              }
              // we need to set headers for generic tables here, more header data is being worked on
              // console.log(_.get(_state.data[_state.activeDeviceId], 'additionalProperties.0.data.0.table.headers', []));
              this._queueNotify()
            }
          })
          .catch(restError => {
            _state.data[deviceId] = {
              error: restError
            }
            this._queueNotify()
          })
      }
      tryRequest()
    }
  },

  _doPreviewDevice(deviceId) {
    if (
      !_state.data[deviceId] ||
      (!_.isBoolean(_state.data[deviceId].hasResults) &&
        !_state.data[deviceId].isPreviewing)
    ) {
      _state.data[deviceId] = {
        isPreviewing: true
      }
      this._queueNotify()

      const params = {
        ip: _state.ip,
        start: _state.start,
        end: _state.end,
        deviceId: deviceId,
        subType: 'traffic', //always preview using most inclusive log type - TODO determine dynamically when we have more firewall types
        limit: 1
      }
      const tryRequest = () => {
        // MOCK FOR DEMO CUSTOMER
        if (window._pw.isDemoCustomer) {
          setTimeout(() => {
            const deviceSensors =
              _.find(_state.devices.data, { id: deviceId }).sensorIds || []
            _state.data[deviceId] = {
              hasResults: _state.sensorIds
                ? _.intersection(_state.sensorIds, deviceSensors).length > 0
                : deviceSensors.length > 0
            }
            this._queueNotify()
          }, _.random(300, 1000))
          return
        }
        // END MOCK

        requestGet(
            getReqIdForDevice(deviceId),
            `context-fusion/logs?${querystringUtil.stringify(params)}`
          )
          .then(response => {
            const results =
              (response && _.get(response, '0.logs.0.logData')) || []
            if (results.length === 0) {
              // Empty resultset = negative answer unless there's a paginationToken, in which case
              // it's indeterminate so we need to ask again using that token
              const paginationToken =
                response && _.get(response, '0.logs.0.paginationToken')
              if (paginationToken) {
                params.paginationToken = paginationToken
                tryRequest()
              } else {
                _state.data[deviceId] = {
                  hasResults: false
                }
                this._queueNotify()
              }
            } else {
              // Non-empty resultset = positive answer
              _state.data[deviceId] = {
                hasResults: true
              }
              this._queueNotify()
            }
          })
          .catch(restError => {
            _state.data[deviceId] = {
              error: restError
            }
            this._queueNotify()
          })
      }
      tryRequest()
    }
  },

  onQueryLogs(ip, start, end, sensorIds, activeDeviceId, activeLogType) {
    // If changing input params, reset data; otherwise it's just a switch of selected device/log
    if (
      ip !== _state.ip ||
      start !== _state.start ||
      end !== _state.end ||
      !_.isEqual(sensorIds, _state.sensorIds)
    ) {
      // Abort any queries in progress
      _.forOwn(_state.data, (d, deviceId) =>
        abortRequest(getReqIdForDevice(deviceId))
      )
      _state.data = {}
      _state.ip = ip
      _state.start = start
      _state.end = end
      _state.sensorIds = sensorIds
    }

    _state.activeDeviceId = activeDeviceId
    _state.activeLogType = activeLogType || 'traffic'
    this._queueNotify()

    this._promiseDevices().then(devices => {
      // Filter devices to those associated with selected sensors
      const queryableDevices = sensorIds
        ? devices.filter(d => _.intersection(sensorIds, d.sensorIds).length > 0)
        : devices
      const unqueryableDevices = sensorIds
        ? _.difference(devices, queryableDevices)
        : []

      // If no devices match the selected sensors, there's nothing to query
      if (queryableDevices.length) {
        // If no selected device, select the first one that could have results
        // and default log type tab to the first for the active device
        if (!_state.activeDeviceId) {
          _state.activeDeviceId = queryableDevices[0].id
          const deviceType = _.find(_state.devices.data, {
            id: _state.activeDeviceId
          }).type
          _state.activeLogType = DEVICE_TYPE_TABS[deviceType][0]
        }

        // Perform full log query for the selected device, if it's queryable
        if (queryableDevices.some(d => d.id === _state.activeDeviceId)) {
          this._doQueryDeviceLog(_state.activeDeviceId, _state.activeLogType)
        }

        // Perform background preview queries for other devices, using default activeLogType for each of them
        queryableDevices.forEach(device => {
          if (device.id !== _state.activeDeviceId) {
            this._doPreviewDevice(device.id)
          }
        })
      }

      // Mark others as having no results
      unqueryableDevices.forEach(device => {
        _state.data[device.id] = {
          hasResults: false
        }
      })

      // _state.allColumns = ALL_COLUMNS[_state.activeLogType]
      _state.columnOrder = _getCurrentColumnOrder()
      this._queueNotify()

      // Track
      AnalyticsActions.event({
        eventCategory: 'contextfusion',
        eventAction: 'firewall',
        eventLabel: _state.activeLogType
      })
    })
  },

  onLoadNextPage() {
    const deviceData = _state.data[_state.activeDeviceId]
    if (deviceData && deviceData.paginationToken) {
      this._doQueryDeviceLog(
        _state.activeDeviceId,
        _state.activeLogType,
        deviceData.paginationToken
      )
    }
  },

  onSetColumnWidth(colKey, width) {
    const col = _.find(ALL_COLUMNS[_state.activeLogType], { key: colKey })
    if (col) {
      col.width = width
    }
    // TODO persist to prefs?
    this._notify()
  },

  onSetColumnOrder(colKeys) {
    PreferencesActions.setPreferences({
      [`log_cols_${_state.activeLogType}`]: colKeys //TODO also key by device type
    })
    // optimistically update
    _state.columnOrder = colKeys
    this._notify()
  },

  onResetColumnOrder() {
    PreferencesActions.reset(`log_cols_${_state.activeLogType}`)
  },

  onPrefStoreUpdate({ preferences }) {
    _preferences = _.clone(preferences)
    _state.columnOrder = _getCurrentColumnOrder()
    this._notify()
  },

  onIntegrationsStoreUpdate({ devices }) {
    if (window._pw.isDemoCustomer) {
      return // Ignore "real" devices for demo customer
    }
    if (devices) {
      _state.devices = {
        isLoading: devices.isLoading,
        error: devices.error,
        data: devices.data
          ? // this will eventually ask for device capabilities. device.capabilities.include('logs') or something.
            devices.data.filter(
              device =>
                device.type === 'Firewall' || device.type === 'NetworkAnalytics'
            )
          : null
      }
      this._notify()
    }
  },

  _createMockPANResults(ip, start, end, deviceName) {
    const { random, sample } = _
    const apps = [
      {
        port: 80,
        apps: ['HTTP', 'YouTube', 'Google', 'DropBox'],
        cats: ['web-html', 'web-video', 'web-advertisements']
      },
      { port: 22, apps: ['SSH'], cats: ['private'] },
      { port: 52, apps: ['DNS'], cats: ['dns'] },
      { port: 443, apps: ['SSL'], cats: ['private'] }
    ]
    const user = sample([
      'corp\\administrator',
      'corp\\gclark',
      'corp\\slisberger',
      'corp\\ayoran',
      'corp\\shester',
      'corp\\mroesch',
      'corp\\jkosinski'
    ])

    return _.map(Array(PAGE_SIZE), (n, i) => {
      const ipIsInternal = SensorStore.isInternalIp(ip)

      // Build up src/dst info, then randomly reverse
      let src = {
        ip: ip,
        internal: ipIsInternal
      }
      let dst = {
        ip: `${ipIsInternal ? random(11, 255) : 10}.${random(0, 255)}.${random(
          0,
          255
        )}.${random(0, 255)}`,
        internal: !ipIsInternal
      }
      if (random(0, 1)) {
        ;[src, dst] = [dst, src]
      }

      // Add app info
      const app = sample(apps)
      src.port = random(10000, 50000)
      dst.port = app.port

      const bytesSent = random(1, 1000000)
      const bytesReceived = random(1, 1000000)
      return {
        action: src.internal ? 'allow' : 'block',
        cat: sample(app.cats),
        rule: src.internal ? 'Allow Outbound' : 'Block Inbound',
        srcLoc: src.internal
          ? '10.0.0.0-10.255.255.255'
          : '0.0.0.0-255.255.255.255',
        dstLoc: dst.internal
          ? '10.0.0.0-10.255.255.255'
          : '0.0.0.0-255.255.255.255',
        srcZone: src.internal
          ? 'Internal'
          : src.ip === ip ? 'External' : sample(['External', 'DMZ']),
        dstZone: dst.internal
          ? 'Internal'
          : dst.ip === ip ? 'External' : sample(['External', 'DMZ']),
        receiveTime: Math.round(start + (end - start) / PAGE_SIZE * i),
        proto: 'tcp',
        deviceName: deviceName.toLowerCase().replace(/\s/g, '-'),
        app: dst.internal ? app.apps[0] : sample(app.apps),
        dstIp: dst.ip,
        dport: dst.port,
        srcIp: src.ip,
        sport: src.port,
        bytes: bytesSent + bytesReceived,
        bytesSent,
        bytesReceived,
        user,
        threatId: random(30000, 50000),
        severity: 'informational',
        direction: random(0, 10) ? 'client-to-server' : 'server-to-client',
        urlIndex: '0',
        userAgent: null,
        fileType: random(0, 10) ? null : 'application/pdf',
        xForwardedFor: null
      }
    })
  }
})

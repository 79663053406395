import Reflux from 'reflux'

export default Reflux.createActions([
  'initWizard', // (ruleBehavior) // Intended rule behavior (whitelist/blacklist)
  'reset',

  'setSelectedListId',
  'setNewListName',
  'toggleSetDefaultListTag',

  'toggleSensorNeedsSubUpdate',
  'setSensorsNeedSubUpdate',

  'updateThreatMappings',
  'updateDescription',
  'updateTags',

  'save'
])

import T from 'prop-types'
import React from 'react'
import cx from 'classnames'
import {deviceCanvasDataUrlCache} from 'utils/deviceUtils'

export default class extends React.PureComponent {
  static displayName = 'DeviceIdenticon';

  static propTypes = {
    className: T.string,
    "data-tooltip": T.string,
    deviceId: T.string.isRequired,
    size: T.number
  };

  static defaultProps = {
    size: 32
  };

  UNSAFE_componentWillMount() {
    this.setState({pixelRatio: window.devicePixelRatio || 1})
  }

  UNSAFE_componentWillReceiveProps() {
    let pixelRatio = window.devicePixelRatio || 1
    if (pixelRatio !== this.state.pixelRatio) {
      this.setState({pixelRatio})
    }
  }

  render() {
    let {size, className, deviceId} = this.props
    return (
      <img
        className={ cx('device_identicon', className) }
        data-deviceid={ deviceId }
        data-tooltip={ this.props["data-tooltip"] }
        data-tooltip-align={ "bottom-right" }
        ref={ this._test }
        src={ deviceCanvasDataUrlCache.getImgData(deviceId, size, this.state.pixelRatio) }
        style={ {
          height: size,
          width: size
        } }
      />
    )
  }
}

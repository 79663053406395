import _ from 'lodash'
import React from 'react'
import T from 'prop-types'
import ReactDOM from 'react-dom'
import {
  RouteAware,
  Timing,
  MenuItem,
  AnimateHeight,
  ErrorState,
  StoreProvider,
  ErrorBoundary
} from 'react-base'
import cx from 'classnames'
import { genericUtil } from 'ui-base'
import IntelCardActions from 'actions/IntelCardActions'
import AnalyticsActions from 'actions/AnalyticsActions'
import IntelCardStore from 'stores/intelcard/IntelCardStore'
import SensorStore from 'stores/SensorStore'
import UserStore from 'stores/UserStore'
import urlHistoryUtil from 'utils/urlHistoryUtil'
import Header from './Header'
import Threat from './data/Threat'
import Activity from './data/Activity'
import Network from './data/Network'
import Device from './data/Device'
import IpDevice from './data/IpDevice'
import Dns from './data/Dns'
import FileInfo from './data/FileInfo'
import FileAnalysis from './data/FileAnalysis'
import FileThreat from './data/FileThreat'
import IpInfo from './data/IpInfo'
import CertificateInfo from './data/CertificateInfo'
import IntelCardPropTypes from './PropTypes'
import SensorPerformance from './data/SensorPerformance'
import SensorConfig from './data/SensorConfig'
import SensorLocations from './data/SensorLocations'


const IP_DOMAIN_TABS = [
  {
    key: 'threat',
    icon: 'threat',
    title: 'Threat Intel',
    component: props => <Threat {...props} />
  },
  {
    key: 'activity',
    icon: 'activity',
    title: 'Activity Intel',
    component: Activity
  },
  {
    key: 'network',
    icon: 'network',
    title: 'Network Intel',
    component: Network
  },
  {
    key: 'dns',
    icon: 'dns',
    title: 'DNS Intel',
    component: Dns
  },
  {
    key: 'ip_info',
    icon: 'ip',
    title: 'Ip Intel',
    component: props => <IpInfo {...props} />,
    shouldHide: (queryType, queryValue, data) => {
      // need to know if we have recorded future hooked up for IP tab, rf only right now
      let hasRecordedFutureData = data('ipInfo').isReady()
        ? data('ipInfo').valueOf() && data('ipInfo').valueOf().length && data('ipInfo').valueOf()[0].additionalProperties.length
        : false

      return !hasRecordedFutureData
    }
  },
  {
    key: 'device',
    icon: 'device',
    title: 'Device Intel',
    component: IpDevice,
    shouldHide: (queryType, queryValue, data) => {
      return !(
        queryType === 'ip' && SensorStore.isInternalIp(queryValue) &&
        // TODO TEMP: For now we only show this tab if a CB Response integration is configured, as that's currently
        //  the tab's only possible content; in the future when we add DeviceDB data this limitation will be removed.
        ((UserStore.getCurrentUserFeatures() || {}).endpointKnowledgeVendors || new Set()).has('CarbonBlack')
      )
    }
  }
]

const DEVICE_TABS = [
  {
    key: 'device',
    icon: 'device',
    title: 'Device Intel',
    component: props => <Device {...props} />
  },
  {
    key: 'threat',
    icon: 'threat',
    title: 'Threat Intel',
    component: props => <Threat {...props} />
  },
  {
    key: 'activity',
    icon: 'activity',
    title: 'Activity Intel',
    component: props => <Activity {...props} />
  }
]

const FILE_TABS = [
  {
    key: 'fileinfo',
    icon: 'attachment',
    title: 'File Detail',
    component: FileInfo
  },
  {
    key: 'fileanalysis',
    icon: 'radio-unchecked',
    title: 'Dynamic Analysis',
    component: FileAnalysis
  },
  {
    key: 'threat',
    icon: 'threat',
    title: 'Threat Intel',
    component: props => <FileThreat {...props} />
  }
]

const CERT_TABS = [
  {
    key: 'cert',
    icon: 'lock',
    title: 'Certificate Detail',
    component: CertificateInfo
  }
]

const SENSOR_TABS = [
  {
    key: 'perf',
    icon: 'activity',
    title: 'Sensor Performance',
    component: props => <SensorPerformance {...props} />,
    shouldHide: (queryType, queryValue, data) => !data('sensor.downloaded').valueOf() || !data('sensor.enabled').valueOf()
  },
  {
    key: 'config',
    icon: 'cog',
    title: 'Sensor Config',
    component: props => <SensorConfig {...props} />,
    shouldHide: (queryType, queryValue, data) => !data('sensor.enabled').valueOf()
  },
  {
    key: 'locations',
    icon: 'map',
    title: 'Sensor Location Mappings',
    component: props => <SensorLocations {...props} />,
    shouldHide: (queryType, queryValue, data) => !data('sensor.downloaded').valueOf() || !data('sensor.enabled').valueOf()
  }
]

const TAB_DEFS = {
  ip: IP_DOMAIN_TABS,
  domain: IP_DOMAIN_TABS,
  device: DEVICE_TABS,
  file: FILE_TABS,
  certificate: CERT_TABS,
  sensor: SENSOR_TABS,
  none: []
}

const getIntelCardParamsFromRoute = route => {
  let params = route && route.intelCardParam
  if (params) {
    params = params && params.match(/^(ip|domain|file|device|certificate|sensor):(.+)$/)
    params = params && { type: params[1], value: params[2] }
  }
  return params || null
}

class IntelCardPane extends React.Component {
  static displayName = 'IntelCardPane'

  static propTypes = {
    route: T.object,
    setTimer: T.func,
    clearTimer: T.func,

    // From IntelCardStore
    queryType: T.string,
    queryValue: T.string,
    isOpen: T.bool,
    isLoading: T.bool,
    error: T.any,
    data: T.func
  }

  static defaultProps = {
    queryType: null,
    queryValue: null,
    isOpen: false,
    isLoading: false,
    error: null,
    data: null
  }

  constructor(props) {
    super(props)
    this.state = {
      tab: null
    }
  }

  componentDidMount() {
    window.addEventListener('keydown', this._onGlobalKeydown)
    this._handleNewRoute(this.props.route)
  }

  componentWillUnmount() {
    window.removeEventListener('mouseup', this._onDocMouseUp, true)
    window.removeEventListener('touchend', this._onDocMouseUp, true)
    window.removeEventListener('keydown', this._onGlobalKeydown)
  }

  // componentWillReceiveProps(nextProps) {
  //   const { route, intelCardStoreState } = nextProps
  //   const prevIntelCardStoreState = this.props.intelCardStoreState
  //   if (route !== this.props.route) {
  //     this._handleNewRoute(route, this.props.route)
  //   }
  //   if (intelCardStoreState._updated !== prevIntelCardStoreState._updated) {
  //     this.setState(intelCardStoreState)
  //     if (
  //       intelCardStoreState.queryType !== prevIntelCardStoreState.queryType ||
  //       intelCardStoreState.queryValue !== prevIntelCardStoreState.queryValue
  //     ) {
  //       this.setState({
  //         tab: TAB_DEFS[intelCardStoreState.queryType || 'none'][0].key
  //       })
  //     }
  //   }
  // }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { route, queryType, queryValue } = nextProps

    if (route !== this.props.route) {
      this._handleNewRoute(route, this.props.route)
    }

    if (
      queryType !== this.props.queryType ||
      queryValue !== this.props.queryValue
    ) {
      this.setState({
        tab: TAB_DEFS[queryType || 'none'][0].key
      })
    }
  }

  componentDidUpdate() {
    window[this.props.isOpen ? 'addEventListener' : 'removeEventListener'](
      'mouseup',
      this._onDocMouseUp,
      true
    )
    window[this.props.isOpen ? 'addEventListener' : 'removeEventListener'](
      'touchend',
      this._onDocMouseUp,
      true
    )
  }

  _handleNewRoute = newRoute => {
    const params = getIntelCardParamsFromRoute(newRoute)
    if (params) {
      IntelCardActions.query(params.type, params.value)
    } else {
      IntelCardActions.closeCard()
    }
  }

  _onGlobalKeydown = e => {
    // Close on escape key
    if (e.keyCode === 27 && this.state.isOpen) {
      this.close()
    }
  }

  _onTabClick = tab => {
    this.setState({ tab })
    AnalyticsActions.event({
      eventCategory: 'intelcard',
      eventAction: 'tab-toggle',
      eventLabel: `${tab === 'ip_info' ? 'ip' : tab}-tab`
    })
  }

  _onIntelItemClick = (type, value) => {
    urlHistoryUtil.mergeParams({ intelcard: `${type}:${value}` }, false)
    this.setState({ tab: TAB_DEFS[type][0].key })
  }

  _onCloseClick = e => {
    e.preventDefault()
    this.close()
  }

  _onExpandClick = e => {
    e.preventDefault()
    const { queryType, queryValue } = this.state
    if (queryValue) {
      urlHistoryUtil.mergeParams(
        { intelcard: `${queryType}:${queryValue}` },
        false
      )
    }
    IntelCardActions.openCard()
  }

  _onDocMouseUp = e => {
    if (!this._isEventWithinCard(e)) {
      // User clicked outside the intelcard pane. This is a little tricky because that click may
      // also be handled by another component to cause a navigation, or a doubleclick might be
      // coming that would open a different intelcard; if that's the case then we don't want to
      // do our own navigation, so we'll wait a second and make sure the URL hasn't changed
      // underneath us before navigating. But if we wait too long before closing the panel it
      // will feel unresponsive, so we trigger a close without a URL change sooner.
      const url = location.href
      this.props.setTimer(() => {
        if (location.href === url) {
          IntelCardActions.closeCard()
          this.props.setTimer(() => {
            if (location.href === url) {
              // TODO unsaved work check here for sensorConfig
              this.close()
            }
          }, 1000)
        }
      }, 200)
    }
  }

  /**
   * Determine whether a given event occurred within the intel card pane's element,
   * or within a popup menu/dialog triggered by its contents
   */
  _isEventWithinCard = e => {
    const el = ReactDOM.findDOMNode(this)
    const {target} = e
    return el === target || el.contains(target)
      || genericUtil.isNodeInElByClass(target, [
        'dropdown-menu',
        'dialog_ct_content',
        'notification_pane'
      ])
  }

  close = () => {
    if (this.props.queryValue) {
      urlHistoryUtil.removeParams('intelcard', false)
    }
    IntelCardActions.closeCard()
  }

  render() {
    const { tab } = this.state
    const { data, queryType, queryValue, isOpen, error } = this.props
    const tabs = TAB_DEFS[queryType || 'none'].filter(
      ({shouldHide}) => !(_.isFunction(shouldHide) && shouldHide(queryType, queryValue, data))
    )
    const activeDataTab = isOpen ? (tab && _.find(tabs, {key: tab}) || tabs[0]) : null
    const activeTabKey = activeDataTab && activeDataTab.key
    const Content = activeDataTab && activeDataTab.component

    return (
      <div
        className={cx('intel_card_pane', { open: isOpen })}
        role="dialog"
        aria-hidden={!isOpen}
        aria-label={queryType + ': ' + queryValue}
      >
        <Header
          queryType={queryType}
          queryValue={queryValue}
          data={data}
        />

        <AnimateHeight>
          {error ? (
            <ErrorState className="error_summary" error={error} soft={false} />
          ) : null}
        </AnimateHeight>

        <section className="intel_card_body">
          <ul className="intel_card_tabs">
            {tabs.map(({ key, icon, title, shouldHide }, i) => {
              const hidden =
                _.isFunction(shouldHide) && shouldHide(queryType, queryValue, data)
              return (
                <MenuItem
                  onClick={this._onTabClick}
                  args={key}
                  key={key}
                  active={key === activeTabKey}
                  className={cx(`tab_${key}`, hidden && 'cloaked')}
                >
                  <span
                    className={`icon icon-${icon}`}
                    data-tooltip={title}
                    data-tooltip-class="light"
                    style={{ transitionDelay: 75 + i * 75 + 'ms' }}
                  />
                </MenuItem>
              )
            })}
          </ul>

          {// Render the tab, passing down the store state
          activeDataTab ? (
            <Content
              {...this.props}
              onIntelItemClick={this._onIntelItemClick}
            />
          ) : null}
        </section>
        <a
          href="#"
          className="remove_btn"
          onClick={this._onCloseClick}
          data-tooltip="Close Info Pane"
          data-tooltip-class="light"
        />
        <a
          href="#"
          className="expand_btn"
          onClick={this._onExpandClick}
          data-tooltip="Open Info Pane"
        >
          <span className="icon icon-chevron triangle_arrow left" />
        </a>
      </div>
    )
  }
}

const EnhancedIntelCardPane = RouteAware(Timing(IntelCardPane))

const WithStores = props => {
  return (
    <ErrorBoundary>
      <StoreProvider store={IntelCardStore}>
        <EnhancedIntelCardPane {...props} />
      </StoreProvider>
    </ErrorBoundary>
  )
}

WithStores.displayName = 'IntelCardPaneWithStores'

export default WithStores

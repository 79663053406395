import _ from 'lodash'
import React from 'react'
import ReactDOM from 'react-dom'
import cx from 'classnames'
import { TextOverflow, TextOverflowCenterLite, DragSelectable } from 'react-base'
import querystringUtil from 'ui-base/src/util/querystringUtil'
import RelativeTime from 'components/RelativeTime'
import T from 'prop-types'
import constants from 'pwConstants'

class EventListItem extends React.Component {
  static displayName = 'EventListItem'

  static propTypes = {
    tagName: T.string,
    className: T.string,
    commonParams: T.object,
    'data-index': T.number,
    evt: T.object.isRequired,
    onItemClicked: T.func,
    onSelect: T.func,
    selectableKey: T.string, // If present, will look in context for a DragSelection instance in the owner tree
    selected: T.bool, // Checkbox/multiselect active
    testId: T.string,
    timeProp: T.oneOf(['observedAt', 'occurredAt']),
    autoHeight: T.bool
  }

  static defaultProps = {
    tagName: 'li',
    evt: {},
    commonParams: {},
    className: '',
    selected: false,
    timeProp: 'observedAt',
    c: false
  }

  componentDidUpdate(prevProps) {
    if (
      this._isActive() &&
      this.props.commonParams.id !== prevProps.commonParams.id
    ) {
      this.focus()
    }
  }

  // shouldComponentUpdate(nextProps) {
  //   if (nextProps.evt.tags) {
  //     console.log('tag?', !_.isEqual(this.props.evt.tags, nextProps.evt.tags), this.props.evt.tags, nextProps.evt.tags)
  //   }
  //   return !_.isEqual(this.props.evt.tags, nextProps.evt.tags) || this.props.evt.id !== nextProps.evt.id || !_.isEqual(this.props.commonParams, nextProps.commonParams)
  // },

  _getUrl = () => {
    let fullparams = _.assign(_.clone(this.props.commonParams), {
      id: this.props.evt.id,
      sid: this.props.evt.agentId
    })

    return '/#killbox/events?' + querystringUtil.stringify(fullparams)
  }

  _isActive = () => {
    return this.props.commonParams.id === this.props.evt.id
  }

  _onEventClick = e => {
    this.setState({ isActive: true })
    if (this.props.onItemClicked) {
      e.preventDefault()
      this.props.onItemClicked(this.props.evt, e)
    }
  }

  focus = () => {
    let link = this.refs.link
    if (link) {
      ReactDOM.findDOMNode(link).focus()
    }
  }

  _onSelectClicked = e => {
    let fn = this.props.onSelect
    if (fn && _.isFunction(fn)) {
      fn(this.props.evt.id)
    }
  }

  render() {
    let { props } = this
    let evt = props.evt
    let assignee = evt.assignee
    let hasAssignee = assignee && _.isObject(assignee)
    let hasTags = evt.tags && evt.tags.length !== 0
    let hasResolution = evt.state === 'resolved'
    let assigneeInitials = hasAssignee
      ? (assignee.firstname ? assignee.firstname.charAt(0) : '') +
        (assignee.lastname ? assignee.lastname.charAt(0) : '')
      : ''
    let classes = cx(
      'event_item',
      'glow_borders_h',
      `evt_${evt.threatLevelFormatted}`,
      props.className,
      {
        active: this._isActive(),
        priority: evt.priority,
        resolved: evt.state === 'resolved',
        selected: props.selected,
        auto_height: props.autoHeight
      }
    )
    let TagName = props.tagName

    return (
      <TagName className={classes} data-index={props['data-index']} data-testid={props.testId}>
        {props.onSelect
          ? <span
              className={`item_checkbox ${props.selected ? 'selected' : ''}`}
              onClick={this._onSelectClicked}
            >
              <span
                className={`icon icon-checkbox-${props.selected
                  ? 'checked'
                  : 'unchecked'}`}
              />
            </span>
          : null}
        <a
          className="event_content"
          href={this._getUrl()}
          onClick={this._onEventClick}
          ref="link"
        >
          <div className="event_state">
            <span
              className={`priority_dot ${evt.priority ? 'active' : 'inactive'}`}
              data-tooltip={`Priority Event`}
            />
          </div>
          <span className={'threat_level ' + evt.threatLevelFormatted}>
            <span>
              {evt.threatScore}
            </span>
            <span
              className={
                'kill_chain_icon icon icon-kc_' + evt.killchainStageFormatted
              }
              data-tooltip={
                'Killchain Stage: ' + evt.killchainStageFormattedHuman
              }
            />
          </span>
          <div className="inner_content">
            {props.autoHeight ? (
              <div className="title_row">{evt.formattedTitle}</div>
            ) : (
              <TextOverflowCenterLite className="title_row" value={evt.formattedTitle} />
            )}
            <div className="event_type">
              {evt.typeFormatted}
            </div>

            <div className="bottom_row">
              {hasAssignee || hasTags || hasResolution
                ? <div className="workflow_metadata">
                    {hasResolution
                      ? <span
                          className="resolved_reason"
                          data-tooltip={
                            evt.resolvedReason
                              ? `Event Resolved: ${constants.resolvedReasons[
                                  evt.resolvedReason
                                ]}`
                              : 'Event Resolved'
                          }
                        >
                          <span className="icon icon-checkmark" />
                          <span className="hide_narrow">
                            {evt.resolvedReason
                              ? `${constants.resolvedReasons[
                                  evt.resolvedReason
                                ]}`
                              : ''}
                          </span>
                        </span>
                      : null}
                    {hasAssignee
                      ? <span
                          className="assignee_initials"
                          data-tooltip={`Assigned to ${assignee.firstname} ${assignee.lastname} (${assignee.email})`}
                        >
                          <span className="icon icon-user" />
                          <span className="hide_narrow">
                            {assigneeInitials.length > 0
                              ? assigneeInitials
                              : '?'}
                          </span>
                        </span>
                      : null}
                    {hasTags
                      ? <span
                          className="evt_tags"
                          data-tooltip={`Tags: ${evt.tags.join(', ')}`}
                        >
                          <span className="icon icon-tag" />
                          <span className="hide_narrow">
                            {evt.tags.length}
                          </span>
                        </span>
                      : null}
                  </div>
                : <div />}
              <TextOverflow className="time_stamp">
                <div
                  className="time_label"
                  data-tooltip={props.timeProp.replace('At', ' Time')}
                >
                  {props.timeProp.substr(0, 3)}
                </div>
                <RelativeTime
                  data-tooltip={
                    props.timeProp.replace('At', '') +
                    ': ' +
                    evt[props.timeProp + 'Formatted']
                  }
                  time={evt[props.timeProp]}
                />
              </TextOverflow>
            </div>
          </div>
          <div className="event_cat">
            <span
              className={
                'threat_icon icon icon-cat_' + evt.threatCategoryFormatted
              }
              data-tooltip={'Category: ' + evt.threatCategoryFormattedHuman}
            />
            <span className="pw_badge" data-tooltip="Observations">
              {evt.observationCount}
            </span>
          </div>
        </a>
      </TagName>
    )
  }
}

export default DragSelectable(EventListItem)

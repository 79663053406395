import _ from 'lodash'
import React from 'react'
import T from 'prop-types'
import { Button, DialogContainer } from 'react-base'
import ValueActions from 'actions/ValueActions'
import IntelRuleQuickAddWizard from 'components/intel-management/rule-quick-add-wizard/IntelRuleQuickAddWizard'
import {
  // VALUE_ACTION_OPTIONS,
  VALUE_ACTION_OPTION_IDS,
  VALUE_ACTION_OPTIONS_BY_ID,
  // VALUE_TYPES,
  // VALUE_TYPE_OPTIONS,
  VALUE_TYPE_OPTIONS_BY_ID
} from 'constants/searchableValueConstants'
import { DEVICE_VENDORS } from '../../constants/integrationsConstants'
import IpRemediationDialog from './IpRemediationDialog'
import FileRemediationDialog from './FileRemediationDialog'
import AwsIpRemediationDialog from './AwsIpRemediationDialog'
import { StoreProvider } from 'react-base'

class ValueActionsDialog extends React.Component {
  closeSelf = () => {
    ValueActions.reset() // Close/clear
  }

  backToActions = () => {
    ValueActions.backToActions()
  }

  renderActionSteps = () => {
    const { action, valueType, value, hasPermission, devices } = this.props

    let stepContent = <div>{value}</div>

    if (
      action === VALUE_ACTION_OPTION_IDS.WHITELIST ||
      action === VALUE_ACTION_OPTION_IDS.BLACKLIST ||
      action === VALUE_ACTION_OPTION_IDS.IDS_SUPPRESS ||
      action === VALUE_ACTION_OPTION_IDS.IDS_COPY
    ) {
      stepContent = (
        <IntelRuleQuickAddWizard
          {...{
            action,
            value,
            valueType,
            hasPermission
          }}
          onClose={this.closeSelf}
        />
      )
    }
    else if (action === VALUE_ACTION_OPTION_IDS.REMEDIATE_PAN) {
      stepContent = (
        <IpRemediationDialog
          type={ this.props.valueType }
          resource={ value }
          onCloseClick={ this.closeSelf }
          onBack={ this.backToActions }
        />
      )
    } else if (action === VALUE_ACTION_OPTION_IDS.REMEDIATE_CB) {
      stepContent = (
        <FileRemediationDialog
          fileId={ value }
          fileName={ _.get(this.props, 'relatedData.fileName', null) }
          onCloseClick={ this.closeSelf }
          onBack={ this.backToActions }
        />
      )
    } else if (action === VALUE_ACTION_OPTION_IDS.REMEDIATE_AWS) {
      const sensors = this.props.relatedData.sensorIds.map(num => Number(num))
      const awsDevice = devices.data.filter(dev => {
        return dev.vendor === DEVICE_VENDORS.AWS
          && dev.proxyIds.length
          && _.intersection(dev.sensorIds, sensors).length
      })

      const tag = { // just getting first for now, no way to handle multiple yet in nark
        key: _.get(awsDevice[0],'integrationJsonData.tagKey', null),
        value: _.get(awsDevice[0], 'integrationJsonData.tagValue', null)
      }

      stepContent = (
        <AwsIpRemediationDialog
          type={ this.props.valueType }
          resource={ value }
          tag={ tag }
          onCloseClick={ this.closeSelf }
          onBack={ this.backToActions } />
      )
    } else {
      stepContent = "Invalid ValueAction"
    }

    return stepContent
  }

  handleActionClick = actionType => {
    ValueActions.setActionType(actionType)
  }

  render() {
    const {
      action,
      valueType,
      value,
      isDialogOpen,
      availableValueActions,
      devices
    } = this.props

    const valueConfig = valueType ? VALUE_TYPE_OPTIONS_BY_ID[valueType] : {}

    return isDialogOpen ? (
      <DialogContainer modal onMaskClick={this.closeSelf}>
        <div
          className={`global_dialog modal_dialog value_actions_dialog action_${action ||
            'none'} ${valueType}`}
        >
          <h1>
            <span
              className={`icon icon-${action
                ? VALUE_ACTION_OPTIONS_BY_ID[action].icon
                : 'lightning'}`}
            />
            {action ? (
              `${valueConfig.display ||
                valueType} : ${VALUE_ACTION_OPTIONS_BY_ID[action].title}`
            ) : (
              `${valueConfig.display || valueType}: Actions`
            )}
            <span className="remove_btn" onClick={this.closeSelf} />
          </h1>
          <h2>{value && value.filename || value}</h2>
          <div className="value_actions_content grid flex_1">
            {!action ? (
              <div className="value_action_content_options">
                <h6>Choose an action:</h6>
                <ul className="list-unstyled">
                  {_.map(
                    valueType ? availableValueActions[valueType] : [],
                    ({ id, title, description, icon }) => {
                      return (
                        <li key={id}>
                          <Button
                            args={[id]}
                            className="value_action_option"
                            onClick={this.handleActionClick}
                          >
                            <span className={`icon icon-${icon}`} />
                            <div>
                              <div className="value_title">{title}</div>
                              <div>{description}</div>
                            </div>
                            <span className="icon icon-chevron" />
                          </Button>
                        </li>
                      )
                    }
                  )}
                </ul>
                <div className="dialog_buttons">
                  <div className="dialog_primary_button_wrap">
                    <button
                      className={`btn btn-default btn-block`}
                      onClick={this.closeSelf}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              this.renderActionSteps()
            )}
          </div>
        </div>
      </DialogContainer>
    ) : null
  }
}

ValueActionsDialog.displayName = 'ValueActionsDialog'
ValueActionsDialog.propTypes = {
  action: T.oneOf(_.keys(VALUE_ACTION_OPTIONS_BY_ID)),
  availableValueActions: T.objectOf(T.array),
  hasPermission: T.func.isRequired,
  isDialogOpen: T.bool,
  value: T.oneOfType([T.string, T.object]),
  valueType: T.oneOf(_.keys(VALUE_TYPE_OPTIONS_BY_ID)),
  relatedData: T.object
}
ValueActionsDialog.defaultProps = {}

export default ValueActionsDialog

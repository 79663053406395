export enum QueryFamily {
  Observations = "observations",
  Netflows = "netflows"
}

export enum QueryConjunction {
  And = "and",
  Or = "or"
}

export enum QueryOperatorType {
  Eq = "eq",
  Neq = "neq",
  Gt = "gt",
  Gte = "gte",
  Lt = "lt",
  Lte = "lte",
  Between = "btw",
  // Contains = "ctns"
}

export interface QueryClause {
  column: QueryColumn,
  operator: QueryOperator,
  value: any|null
  required?: boolean
}

export interface QueryGroup {
  clauses: Array<QueryClause|QueryGroup>,
  conjunction: QueryConjunction,
  required?: boolean
  maxDepth?: number
}

// export enum QueryColumnType {
//   String,
//   Integer,
//   Float,
//   IpAddress,
//   DateTime
// }

export type ipaddress = string
export type macaddress = string

export enum QueryColumnTypes {
  Any = "any",
  Void = "void",
  Int = "int",
  Float = "float",
  Double = "double",
  String = "string",
  Enum = "enum",
  Datetime = "datetime",
  Ip = "ip",
  Cidr = "cidr",
  MacAddress = "macaddress",
  Domain = "domain",
  Url = "url",
  Port = "port",
  Hash = "hash",
  List = "list",
  Map = "map",
  Struct = "struct",
  Bool = "bool"
}

export type QueryColumnContainers = QueryColumnTypes.List | QueryColumnTypes.Struct | QueryColumnTypes.Map

type QueryColumnEnum = {
  text: string
  value: string | number
}

export interface QueryColumn {
  key: string
  header: string
  type: QueryColumnTypes
  subtype?: QueryColumnTypes
  filterable: boolean
  sortable: boolean
  operators?: Array<QueryOperator>
  enums?: Array<QueryColumnEnum>
  resizable?: boolean
}

// export interface QuerySortDirection {
//   Ascending,
//   Descending,
// }

// export interface QuerySortOption {
//   NullsFirst,
//   NullsLast
// }

// export interface QuerySort {
//   column: QueryColumn,
//   direction: QuerySortDirection,
//   option: QuerySortOption
// }

export enum QuerySortDirection {
  ASC = "asc",
  DESC = "desc"
}

export interface Query {
  select?: Array<QueryColumn>
  from: QueryFamily
  where: QueryGroup
  sort?: string
  direction?: string
  limit?: number
  offset?: number
}

export enum QueryTaskState {
  NEW = "new",
  EXISTING = "existing",
  SUBMITTING = "submitting",
  QUEUED = "queued",
  RUNNING = "running",
  SUCCEEDED = "succeeded",
  CANCELING = "canceling",
  CANCELED = "canceled",
  FAILED = "failed",
  DOWNLOADING = "downloading",
  COMPLETE = "complete"
}

export interface QueryStatistics {
  submittedAt?: Date
  scannedBytes: number
  resultBytes?: number
  rowCount: number
  execMillis: number
  completedAt?: Date
  total?: number
}

export interface QueryTask {
  id?: string
  state: QueryTaskState
  statistics?: QueryStatistics
  error?: Error
}

// export enum QueryPageState {
//   NEW = "new",
//   REQUESTING = "REQUESTING",
//   COMPLETE = "COMPLETE",
//   ERROR = "error"
// }

// export interface QueryResultPage {
//   readonly id: string
//   size: number
//   state: QueryPageState
//   error?: Error
//   rows: Array<Record<string, any>>
//   nextPage: string|null
// }

export interface QueryResult extends QueryTask {
  rows: Array<Record<string, any>>
  pageSize: number
  nextPage: string|null
}

export enum QueryOperatorArity {
  Nullary,
  Unary,
  Binary,
  Ternary,
  Nary,
}

export interface QueryOperator {
  kind: QueryOperatorType,
  arity: QueryOperatorArity,
  text: string,
  symbol: string
}

const Equal: QueryOperator = {
  kind: QueryOperatorType.Eq,
  arity: QueryOperatorArity.Unary,
  text: "is",
  symbol: "="
}

const NotEqual: QueryOperator = {
  kind: QueryOperatorType.Neq,
  arity: QueryOperatorArity.Unary,
  text: "is not",
  symbol: "!="
}

const LessThan: QueryOperator = {
  kind: QueryOperatorType.Lt,
  arity: QueryOperatorArity.Unary,
  text: "is less than",
  symbol: "<"
}

const GreaterThan: QueryOperator = {
  kind: QueryOperatorType.Gt,
  arity: QueryOperatorArity.Unary,
  text: "is more than",
  symbol: ">"
}

const LessThanOrEqual: QueryOperator = {
  kind: QueryOperatorType.Lte,
  arity: QueryOperatorArity.Unary,
  text: "is at most",
  symbol: "<=",
}

const GreaterThanOrEqual: QueryOperator = {
  kind: QueryOperatorType.Gte,
  arity: QueryOperatorArity.Unary,
  text: "is at least",
  symbol: ">=",
}

const Between: QueryOperator = {
  kind: QueryOperatorType.Between,
  arity: QueryOperatorArity.Binary,
  text: "is",
  symbol: "between"
}

// const Contains: QueryOperator = {
//   kind: QueryOperatorType.Contains,
//   arity: QueryOperatorArity.Unary,
//   text: "includes",
//   symbol: "contains"
// }

export const QueryOperators = {
  Equal,
  NotEqual,
  LessThan,
  LessThanOrEqual,
  GreaterThan,
  GreaterThanOrEqual,
  Between,
  // Contains
}

export const QueryOperatorsList = Object.keys(QueryOperators).map(k => QueryOperators[k])

export interface QueryColumnTypeOperators {
  kind: QueryColumnTypes,
  operators: Array<QueryOperator>
}

export const DateTimeOperators: QueryColumnTypeOperators = {
  kind: QueryColumnTypes.Datetime,
  operators: [Between]
}

export const IpOperators: QueryColumnTypeOperators = {
  kind: QueryColumnTypes.Ip,
  operators: [Equal, NotEqual]
}

export const IntOperators: QueryColumnTypeOperators = {
  kind: QueryColumnTypes.Int,
  operators: [Equal, NotEqual, GreaterThan, LessThan, GreaterThanOrEqual, LessThanOrEqual]
}

export const StringOperators: QueryColumnTypeOperators = {
  kind: QueryColumnTypes.String,
  operators: [Equal, NotEqual]
}

export const BoolOperators: QueryColumnTypeOperators = {
  kind: QueryColumnTypes.Enum,
  operators: [Equal, NotEqual]
}

export const AppProtocolOperators: QueryColumnTypeOperators = {
  kind: QueryColumnTypes.String,
  operators: [Equal, NotEqual]
}
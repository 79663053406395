import Reflux from 'reflux'

export default Reflux.createActions([
  'loadSensorConfig',
  'saveSensorConfig',
  'loadSensorLocations',
  'saveSensorLocations',
  // 'setActiveProfile',
  'reloadCurrentConfig',

  'saveSensorSet',
  'deleteSensorSet',
  'saveSensor',
  // 'deleteSensor',
  'setSensorEnabled',
  'stopSensor',
  'startSensor',
  'restartSensor',
  'resetSensor',
  'reloadSensors',

  'bulkApplyCaptureProfile',
  'bulkRestart', // Restart a set of sensors to pickup their latest configuration
  'resetBulkConfig',

  //HUD selection states
  'toggleSelected', //(sensor ID)
  'toggleSelectAllInSet', //(sensorSet ID)

  'generateInstallToken', // (sensorId)
  'resetConfig',

  // See SensorHealthStore:
  'loadSensorHealth'
])

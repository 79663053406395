import _ from 'lodash'
import {
  INTEL_LISTS_CREATE_PERMISSION,
  INTEL_SUBSCRIPTION_SET_PERMISSION,
  INTEL_RULES_CREATE_PERMISSION,
  INTEL_TYPES_AVAILABLE_IDS,
  INTEL_TYPE_OPTIONS
} from 'constants/intelManagementConstants'
import ipaddr from 'pw-ipaddr.js'

export const VALUE_ACTION_OPTIONS = {
  WHITELIST: {
    icon: "thumbs-up",
    id: "whitelist",
    title: "Add to Whitelist",
    description: "Add a new Intel Whitelist Rule to stop this value from appearing in future observations.",
    permissions: [
      INTEL_LISTS_CREATE_PERMISSION,
      INTEL_SUBSCRIPTION_SET_PERMISSION,
      INTEL_RULES_CREATE_PERMISSION,
      (intelType, availableIntelTypes) => {
        return availableIntelTypes.indexOf(intelType) !== -1
      }
    ]
  },
  BLACKLIST: {
    icon: "thumbs-down",
    id: "blacklist",
    title: "Add to Blacklist",
    description: "Add a new Intel Blacklist Rule to ensure that this value will produce observations in the future. ",
    permissions: [
      INTEL_LISTS_CREATE_PERMISSION,
      INTEL_SUBSCRIPTION_SET_PERMISSION,
      INTEL_RULES_CREATE_PERMISSION,
      (intelType, availableIntelTypes) => {
        return availableIntelTypes.indexOf(intelType) !== -1
      }
    ]
  },
  // IDS_SUPPRESS: {
  //   icon: "blocked",
  //   id: "ids_suppress",
  //   title: "Suppress",
  //   description: "Override this Intel Rule with a disabled copy, preventing it from producing observations.",
  //   permissions: [
  //     INTEL_LISTS_CREATE_PERMISSION,
  //     INTEL_SUBSCRIPTION_SET_PERMISSION,
  //     INTEL_RULES_CREATE_PERMISSION
  //   ]
  // },
  // IDS_COPY: {
  //   icon: "copy",
  //   id: "ids_copy",
  //   title: "Customize Copy",
  //   description: "Generate a copy of this Intel Rule and customize it's Threat Mappings.",
  //   permissions: [
  //     INTEL_LISTS_CREATE_PERMISSION,
  //     INTEL_SUBSCRIPTION_SET_PERMISSION,
  //     INTEL_RULES_CREATE_PERMISSION
  //   ]
  // },
  REMEDIATE_CB: {
    icon: "remediation",
    id: "firewall_cb",
    title: "Carbon Black Remediation",
    description: "Add this resource to your Carbon Black block list.",
    permissions: [
      "remediation:action",
      "remediation:index",
      (deviceType, availableDeviceTypes) => {
        return availableDeviceTypes.indexOf(deviceType) !== -1
      }
    ]
  },
  REMEDIATE_PAN: {
    icon: "remediation",
    id: "firewall_pan",
    title: "Palo Alto Remediation",
    description: "Add this resource to your Palo Alto block list.",
    permissions: [
      "remediation:action",
      "remediation:index",
      (deviceType, availableDeviceTypes) => {
        return availableDeviceTypes.indexOf(deviceType) !== -1
      }
    ]
  },
  REMEDIATE_AWS: { // first overloaded type, may need to brake out each remediation type for consistency
    icon: "remediation",
    id: "firewall_aws",
    title: "AWS Remediation",
    description: "Add this resource to your AWS block list.",
    permissions: [
      "remediation:action",
      "remediation:index",
      (deviceType, availableDeviceTypes) => {
        return availableDeviceTypes.indexOf(deviceType) !== -1
      }
    ]
  }
}

export const VALUE_ACTION_OPTION_IDS = _.mapValues(VALUE_ACTION_OPTIONS, v => v.id)
export const VALUE_ACTION_OPTIONS_BY_ID = _.mapKeys(VALUE_ACTION_OPTIONS, v => v.id)

export const VALUE_TYPES = {
  URL: {
    id: 'url',
    display: "URL",
    getCardId: value => {
      // The URL may either be part of a domain or a raw IP, so we check that to see
      // which card type to use.
      let urlObj
      try {
        urlObj = new URL('http://' + value)
        const type = ipaddr.util.isValidIp(urlObj.host) ? 'ip' : 'domain'
        return `${type}:${urlObj.host}`
      } catch(e) {
        return null
      }
    },
    getExplorerQuery: (value, startTime, endTime) => {
      // Parse the URL into its constituent parts and add query clauses for each
      try {
        const urlObj = new URL('http://' + value)
        let clauses = [{name: 'occurredAt', op: 'between', from: startTime, to: endTime}]
        if (urlObj.host) clauses.push({name: 'httpHost', op: 'eq', value: urlObj.host})
        if (urlObj.pathname && urlObj.pathname !== '/') clauses.push({name: 'httpPath', op: 'eq', value: urlObj.pathname})
        if (urlObj.search) clauses.push({name: 'httpQueryString', op: 'eq', value: urlObj.search.substring(1)})
        return {
          family: 'observations',
          clauses
        }
      } catch(e) {
        return null
      }
    },
    intelType: INTEL_TYPE_OPTIONS.URI,
    actions: _.compact([
      VALUE_ACTION_OPTION_IDS.WHITELIST,
      VALUE_ACTION_OPTION_IDS.BLACKLIST
    ])
  },
  IP: {
    id: 'ip',
    display: "IP",
    getCardId: value => `ip:${value}`,
    getExplorerQuery: (value, startTime, endTime) => {
      return ipaddr.util.isValidIp(value) ? {
        family: 'observations',
        clauses: [
          {name: 'occurredAt', op: 'between', from: startTime, to: endTime},
          {name: 'ip', op: 'eq', value: value}
        ]
      } : null
    },
    intelType: INTEL_TYPE_OPTIONS.IP,
    actions: _.compact([
      VALUE_ACTION_OPTION_IDS.WHITELIST,
      VALUE_ACTION_OPTION_IDS.BLACKLIST,
      VALUE_ACTION_OPTION_IDS.REMEDIATE_PAN,
      // VALUE_ACTION_OPTION_IDS.REMEDIATE_AWS, // not doing this until device DB stuff is done
      // VALUE_ACTION_OPTION_IDS.ALERTS
    ])
  },
  DOMAIN: {
    id: 'domain',
    display: "Domain",
    getCardId: value => `domain:${value}`,
    getExplorerQuery: (value, startTime, endTime) => {
      try {
        const urlObj = new URL('http://' + value)
        return urlObj.host ? {
          family: 'observations',
          clauses: [
            {name: 'occurredAt', op: 'between', from: startTime, to: endTime},
            {name: 'observationInfo.domains', op: 'eq', value: urlObj.host},
          ]
        } : null
      } catch (e) {
        return null
      }
    },
    intelType: INTEL_TYPE_OPTIONS.DOMAIN,
    actions: _.compact([
      VALUE_ACTION_OPTION_IDS.WHITELIST,
      VALUE_ACTION_OPTION_IDS.BLACKLIST
    ])
  },
  FILEHASH: {
    id: 'filehash',
    display: "File",
    getCardId: value => `file:${value}`,
    getExplorerQuery: (value, startTime, endTime) => {
      return {
        family: 'observations',
        clauses: [
          {name: 'occurredAt', op: 'between', from: startTime, to: endTime},
          {name: 'fileId', op: 'eq', value: value}
        ]
      }
    },
    intelType: INTEL_TYPE_OPTIONS.FILEHASH,
    actions: _.compact([
      VALUE_ACTION_OPTION_IDS.WHITELIST,
      VALUE_ACTION_OPTION_IDS.BLACKLIST,
      VALUE_ACTION_OPTION_IDS.REMEDIATE_CB
    ])
  },
  CERTIFICATE: {
    id: 'certificate',
    display: "Certificate",
    getCardId: value => `certificate:${value}`,
    // TODO: getExplorerQuery: (value, startTime, endTime) => ...
    intelType: INTEL_TYPE_OPTIONS.CERTIFICATE,
    actions: _.compact([
      VALUE_ACTION_OPTION_IDS.WHITELIST,
      VALUE_ACTION_OPTION_IDS.BLACKLIST
    ])
  },
  PAYLOAD: {
    id: 'payload', // AKA IDS
    display: "Payload / IDS",
    intelType: INTEL_TYPE_OPTIONS.IDS,
    actions: _.compact([
      VALUE_ACTION_OPTION_IDS.WHITELIST,
      // VALUE_ACTION_OPTION_IDS.BLACKLIST
    ])
  },
  DEVICE: {
    id: 'device',
    display: 'Device',
    getCardId: value => `device:${value}`,
    intelType: INTEL_TYPE_OPTIONS.IP,
    actions: _.compact([
      VALUE_ACTION_OPTION_IDS.IP
    ])
  },
  AWS_DEVICE: {
    id: 'aws_device',
    display: 'Device',
    intelType: INTEL_TYPE_OPTIONS.IP,
    actions: _.compact([
      VALUE_ACTION_OPTION_IDS.REMEDIATE_AWS
    ])
  },
  SENSOR: {
    id: 'sensor',
    display: 'Sensor',
    getCardId: value => `sensor:${value}`,
  }
}


export const VALUE_TYPE_OPTIONS = _.mapValues(VALUE_TYPES, v => v.id)
export const VALUE_TYPE_OPTIONS_BY_ID = _.mapKeys(VALUE_TYPES, v => v.id)

export const VALUE_TYPE_ACTIONS_BY_ID = _.mapValues(_.cloneDeep(VALUE_TYPE_OPTIONS_BY_ID), type => {
  type.actions = _.map(type.actions, actionId => VALUE_ACTION_OPTIONS_BY_ID[actionId])
  return type
})

import T from 'prop-types'
import React from 'react'
import { TextInput, AnimateHeight, CircleLoader, StoreProvider } from 'react-base'
import ReactDOM from 'react-dom'
import CommonViewActions from 'actions/CommonViewActions'
import UserProvider from 'components/UserProvider'
import UserAccountStore from 'stores/UserAccountStore'

const validatePassword = v => {
  return v ? null : 'Password is required'
}

class InstallToken extends React.Component {

  static displayName = "InstallToken";

  static propTypes = {
    onRequestToken: T.func.isRequired,
    token: T.string,
    isSubmitting: T.bool,
    error: T.any,
    isLoading: T.bool
  };

  static defaultProps = {
    isSubmitting: false
  }

  state = {
    formActive: false
  }

  _onTokenCopy = () => {
    let el = this._inputRef
    el.select()
    try {
      document.execCommand('copy')
      CommonViewActions.Notification.add({
        type: 'success',
        heading: 'Token copied to clipboard',
        dismissTimer: 2000
      })
    } catch(err) {
      console.log('Copy to clipboard failed')
    }
  };

  _onTokenFocus = (e) => {
    e.target.select()
  };

  _bindInputRef = (input) => {
    this._inputRef = input
  };

  _updateFormState = (value, stateKey) => {
    this.setState(_.set({}, stateKey, value))
  }

  _onSubmitForm = (e) => {
    e.preventDefault()
    const form = ReactDOM.findDOMNode(this.refs.genTokenForm)
    if (form.password.value) {
      this.props.onRequestToken(form.password.value)
    }
  }

  toggleFormActive = () => {
    const toActive = !this.state.formActive
    // const newState = {formActive: toActive}
    if (!toActive) {
      // Reset
    }
    this.setState({
      formActive: toActive
    })
  }

  _forceGenerate = (e) => {
    e.preventDefault()
    this.props.onRequestToken()
  }

  render() {
    let {props} = this
    const {isSubmitting} = this.state
    let isSaml = props.userInfo.customer.customer_settings ? !!props.userInfo.customer.customer_settings.saml_id : false

    return props.token ? (
      <div className="token">
        <input
          className="form-control"
          onFocus={ this._onTokenFocus }
          readOnly
          ref={ this._bindInputRef }
          value={ props.token }
        />

      </div>
    ) : (
      <div className="token">
        <AnimateHeight>
          {this.state.formActive &&
            <form
              className="pw_form"
              onSubmit={this._onSubmitForm}
              ref="genTokenForm"
            >
              <div className="fade_children_in_from_top">
                <div className="cell anim">
                  <label>Password</label>
                  <TextInput
                    className="form-control"
                    id="password"
                    name="password"
                    placeholder="Password"
                    ref="passwordInput"
                    type={'password'}
                    initialValue={''}
                    validator={validatePassword}
                    saveOnChange
                    validatorOnChange
                    onSave={this._updateFormState}
                    className="grid_input cell_content"
                  />
                </div>
              </div>
              <CircleLoader loading={isSubmitting} overlay />
              <div className="required_fields">
                {props.error && typeof props.error.additional && props.error.additional.length > 0 && (
                  <div className="password_change_error">
                    <span className="icon icon-warning" />
                    { props.error.additional[0] }
                  </div>
                )}
              </div>
              <div className="form_btn_wrap">
              <span
                className={`btn btn-default`}
                onClick={this.toggleFormActive}
                >Cancel</span>
                <button
                  className="btn gen_token_btn"
                  disabled={isSubmitting}
                  type="submit"
                >{isSubmitting ? 'Please wait...' : 'Create'}</button>
              </div>
            </form>
          }
          {!this.state.formActive &&
            <button
              className={`btn btn-default`}
              onClick={!isSaml ? this.toggleFormActive : this._forceGenerate}
            ><span className="icon icon-key" />Generate Access Token</button>
          }
        </AnimateHeight>
      </div>
    )
  }
}

const InstallTokenWithStores = props => {
  return (
    <StoreProvider store={UserAccountStore}>
      <UserProvider>
        <InstallToken {...props}/>
      </UserProvider>
    </StoreProvider>
  )
}

InstallTokenWithStores.displayName = "InstallTokenWithStores"
export default InstallTokenWithStores

import React, {
  useEffect,
  useRef
} from 'react'
import _ from 'lodash'
import T from 'prop-types'
import {
  Button,
  MenuItem,
  Tabs,
  MenuOwner,
  AnimateHeight,
  TextOverflow,
  SimpleTooltips
} from 'react-base'
import moment from 'moment'
import AppcuesActions from 'actions/AppcuesActions'
import UserWidget from './UserWidget'
import HelpWidget from './HelpWidget'
import AccessibilityWidget from './AccessibilityWidget'
import Clock from '../Clock'
import UserProvider from '../UserProvider'
import { DownloadIcon } from 'components/download-manager/DownloadsIcon'
import { DownloadManager } from 'components/download-manager/DownloadManager'
import { XrayPairedIconWrapper } from './XrayPairedIconWrapper.js'
import { XrayPairingManager } from './XrayPairingManager.js'

const TRIAL_WARNING_THRESHOLD = 14 // days

const getTrialInfo = userInfo => {
  const isTrialCustomer = userInfo.customer.status === 'TrialActive' && userInfo.customer.timing.ends
  const trialDaysRemaining = isTrialCustomer ? moment(userInfo.customer.timing.ends).diff(Date.now(), 'days') : null

  return {
    trialDaysRemaining,
    needsTrialBanner: _.isNumber(trialDaysRemaining) && trialDaysRemaining > 0,
    trialNeedsWarning: trialDaysRemaining <= TRIAL_WARNING_THRESHOLD
  }
}

const APP_CUES_ELEM_ID = 'appcuesWidgetEl'
const AppCues = () => {
  const appCuesInitializedRef = useRef(false)
  useEffect(() => {
    if (!appCuesInitializedRef.current) {
      AppcuesActions.initWidget(APP_CUES_ELEM_ID)
      appCuesInitializedRef.current = true // Flag this instance as initialized
    }
  })

  return (
    <div
      id={APP_CUES_ELEM_ID}
      className={`appcues_menu_item`}
    >
      <div className="appcues-widget-icon">Tours</div>
    </div>
  )
}

class MetaNav extends React.PureComponent {
  static displayName = 'MetaNav'

  static propTypes = {
    userInfo: T.object,
    emulatingCustomer: T.bool,
  }

  state = {
    activeTab: null
  }

  setActiveTab = tabKey => {
    this.setState({
      activeTab: tabKey === this.state.activeTab ? null : tabKey
    })
  }

  closeMenu = () => {
    this.setState({
      activeTab: null
    })
  }

  getContents = activeTab => {
    const {
      userInfo,
      emulatingCustomer
    } = this.props
    const {
      trialDaysRemaining,
      needsTrialBanner,
      trialNeedsWarning
    } = getTrialInfo(userInfo)

    switch (activeTab) {
      case 'user':
        return (
          <UserWidget
            {...{
              userInfo,
              emulatingCustomer,
              trialDaysRemaining,
              needsTrialBanner,
              trialNeedsWarning
            }}
          />
        )
      case 'preferences':
        return (
          <AccessibilityWidget />
        )
      case 'help':
        return (
          <HelpWidget />
         )
      case 'xray-status':
        return (
          <XrayPairingManager />
         )
      case 'downloads':
        return (
          <DownloadManager closeMenu={this.closeMenu} />
        )
      default:
        return null
    }
  }

  renderMenu = () => {
    const {
      activeTab
    } = this.state

    return (
      <div className="meta_nav_menu">
        <Button
          className={`btn-icon icon-close`}
          onClick={this.closeMenu}
        />
        <AnimateHeight className="meta_nav_menu_inner" heightAtRest="auto">
          <SimpleTooltips>
            {this.getContents(activeTab)}
          </SimpleTooltips>
        </AnimateHeight>
      </div>
    )
  }

  render() {
    const {
      userInfo,
      emulatingCustomer
    } = this.props
    const {
      activeTab
    } = this.state
    const isMenuOpen = !!activeTab
    const {
      trialDaysRemaining,
      needsTrialBanner,
      trialNeedsWarning
    } = getTrialInfo(userInfo)

    return (
      <MenuOwner
        className={`meta_nav ${isMenuOpen ? 'menu_open' : 'menu_closed'}`}
        onClose={this.closeMenu}
        renderMenu={this.renderMenu}
        isMenuOpen={isMenuOpen}
      >
        <Tabs
          activeTabKey={activeTab}
          className={`meta_nav_tabs`}
        >
          <MenuItem
            key="user"
            onClick={this.setActiveTab}
            className={`user_nav_tab`}
            allowActiveClick
          >
            <span className={`icon icon-user`} />
            <div className="user_name">
              <TextOverflow>
                {userInfo.firstname} {userInfo.lastname}
                {emulatingCustomer &&
                  <span
                    className="emulated_customer"
                    data-tooltip={`Emulating Customer ID ${userInfo.customer.id}`}
                  >{` as ${userInfo.customer.name}`}</span>
                }
              </TextOverflow>
            </div>
            {needsTrialBanner &&
              <span
                className={`trial_reminder reminder_sm ${trialNeedsWarning ? 'warn' : ''}`}
                data-tooltip={`Remaining days in trial`}
              >
                <span className={`icon icon-${trialNeedsWarning ? 'warning' : 'info'}`} />
              {trialDaysRemaining}
              </span>
            }
          </MenuItem>
          {window._pw.enableImmersive &&
            <MenuItem
              key="xray-status"
              className={`xray_status_menu_item`}
              onClick={this.setActiveTab}
              allowActiveClick
            >
              <XrayPairedIconWrapper />
            </MenuItem>
          }
          <MenuItem
            key="downloads"
            className={`download_menu_item`}
            onClick={this.setActiveTab}
            allowActiveClick
          >
            <DownloadIcon />
          </MenuItem>
          <MenuItem
            key="preferences"
            onClick={this.setActiveTab}
            data-tooltip={`Adjust text size and color theme.`}
            allowActiveClick
          >
            <span className="small">A</span>A
          </MenuItem>
          <MenuItem
            key="help"
            onClick={this.setActiveTab}
            data-tooltip={`Get Assistance`}
            allowActiveClick
          >
            <span className="icon icon-help" />
          </MenuItem>
        </Tabs>
        <Clock />
        <AppCues />
      </MenuOwner>
    )
  }
}

export default props => (
  <UserProvider>
    <MetaNav {...props} />
  </UserProvider>
)

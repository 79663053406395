import _ from 'lodash'
import T from 'prop-types'
import React from 'react'
// import cx from 'classnames'
import { DropdownMenu, MenuItem, ComboBox } from 'react-base'
import {
  IMS_KILLCHAIN_STAGES_TO_NAMES,
  IMS_KILLCHAIN_STAGES_TO_SHORT_NAMES,
  IMS_KILLCHAIN_STAGES,
  IMS_KILLCHAIN_STAGES_THREAT,
  IMS_THREAT_CATEGORIES_TO_NAMES,
  IMS_THREAT_CATEGORIES,
  NUMBER_PRESETS,
  REQUIRED_THREAT_MAPPINGS
} from 'constants/intelManagementConstants'
import ThreatRatingInput from './ThreatRatingInput'
import { DESC_KILLCHAIN, DESC_CATEGORY } from 'constants/descriptions'
import { getIntRangeValidator } from 'pw-validators'

const validateOneToOneHundred = getIntRangeValidator(1, 100)

const NO_SELECTION_TEXT = 'Select...'

// currentMappings: T.shape({
//   killchainStage: T.oneOf(IMS_KILLCHAIN_STAGES),
//   category: T.oneOf(IMS_THREAT_CATEGORIES),
//   threatSubCategory: T.oneOf(INTEL_THREAT_SUB_CATEGORIES),
//   confidence: T.number,
//   severity: T.number
// }),

function _stopPropagation(e) {
  e.stopPropagation()
}

export default class extends React.Component {
  static displayName = 'IMS.ThreatMappingInput'

  static propTypes = {
    allMappingsRequired: T.bool,
    currentValue: T.any,
    extraArgs: T.array,
    fieldName: T.oneOf(REQUIRED_THREAT_MAPPINGS.concat('rating')).isRequired,
    onChange: T.func,
    readOnly: T.bool
  }

  static contextTypes = {
    userIsPWThreatTeam: T.bool
  }

  static defaultProps = {
    allMappingsRequired: true,
    extraArgs: []
  }

  render() {
    const {
      currentValue,
      extraArgs,
      fieldName,
      onChange,
      allMappingsRequired,
      readOnly
    } = this.props

    let kcStages = null
    let currentKCLower = null
    let currentCatLower = null

    switch (fieldName) {
      case 'killchainStage':
        kcStages = this.context.userIsPWThreatTeam
          ? IMS_KILLCHAIN_STAGES_THREAT
          : IMS_KILLCHAIN_STAGES
        currentKCLower =
          currentValue && currentValue.toLowerCase
            ? currentValue.toLowerCase()
            : currentValue
        return (
          <div>
            <DropdownMenu
              activeMenuKeys={[currentValue]}
              buttonClasses={`stealth_dropdown ${allMappingsRequired &&
              !currentValue
                ? 'warning'
                : ''}`}
              closeOnItemClick
              disabled={readOnly}
              label={
                <span>
                  <span
                    className={`icon icon-kc_${currentKCLower} ${currentKCLower}`}
                  />{' '}
                  {IMS_KILLCHAIN_STAGES_TO_SHORT_NAMES[currentValue] ||
                    (readOnly ? '--' : NO_SELECTION_TEXT)}
                </span>
              }
              menuClasses={`pw_mapping_options`}
              preventBtnClickPropagation
              scrollable
              tooltip={allMappingsRequired && !currentValue ? 'Required' : null}
            >
              {_.map(kcStages, kcStage =>
                <MenuItem
                  args={[kcStage, 'killchainStage'].concat(extraArgs)}
                  key={kcStage}
                  onClick={onChange}
                >
                  <div>
                    <span
                      className={`icon icon-kc_${kcStage.toLowerCase()} ${kcStage.toLowerCase()}`}
                    />
                    {IMS_KILLCHAIN_STAGES_TO_NAMES[kcStage] || kcStage}
                  </div>
                  <div className="muted_row">
                    {DESC_KILLCHAIN[kcStage.toUpperCase()]}
                  </div>
                </MenuItem>
              )}
            </DropdownMenu>
          </div>
        )
      case 'category':
        currentCatLower =
          currentValue && currentValue.toLowerCase
            ? currentValue.toLowerCase()
            : currentValue
        return (
          <DropdownMenu
            activeMenuKeys={[currentValue]}
            buttonClasses={`stealth_dropdown ${allMappingsRequired &&
            !currentValue
              ? 'warning'
              : ''}`}
            closeOnItemClick
            disabled={readOnly}
            label={
              <span>
                <span className={`icon icon-cat_${currentCatLower}`} />{' '}
                {IMS_THREAT_CATEGORIES_TO_NAMES[currentValue] ||
                  (readOnly ? '--' : NO_SELECTION_TEXT)}
              </span>
            }
            menuClasses={`pw_mapping_options`}
            preventBtnClickPropagation
            scrollable
            tooltip={allMappingsRequired && !currentValue ? 'Required' : null}
          >
            {_.map(IMS_THREAT_CATEGORIES, category =>
              <MenuItem
                args={[category, 'category'].concat(extraArgs)}
                key={category}
                onClick={onChange}
              >
                <div>
                  <span
                    className={`icon icon-cat_${category.toLowerCase()} ${category.toLowerCase()}`}
                  />
                  {IMS_THREAT_CATEGORIES_TO_NAMES[category] || category}
                </div>
                <div className="muted_row">
                  {DESC_CATEGORY[category.toUpperCase()]}
                </div>
              </MenuItem>
            )}
          </DropdownMenu>
        )
      // case 'threatSubCategory':
      //   return (
      //     <IntelSubCategoryDropdown
      //       currentValue={ currentValue }
      //       onChange={ onChange }
      //       disabled={ readOnly } // FIXME add support
      //       onChangeArgs={ ['threatSubCategory'].concat(extraArgs) }
      //       required={ allMappingsRequired }
      //     />
      //   )
      case 'confidence':
        return (
          <ComboBox
            cancelOnEsc
            className={`grid_input stealth cell_content ${allMappingsRequired &&
            !currentValue
              ? 'pw_text_input_warning'
              : ''}`}
            dataType="number"
            initialValue={currentValue}
            onClick={_stopPropagation}
            onSave={onChange}
            onSaveArgs={['confidence'].concat(extraArgs)}
            options={NUMBER_PRESETS}
            placeholder={`--`}
            readOnly={readOnly}
            saveOnBlur
            validator={validateOneToOneHundred}
          />
        )
      case 'severity':
        return (
          <ComboBox
            cancelOnEsc
            className={`grid_input stealth cell_content ${allMappingsRequired &&
            !currentValue
              ? 'pw_text_input_warning'
              : ''}`}
            dataType="number"
            initialValue={currentValue}
            onClick={_stopPropagation}
            onSave={onChange}
            onSaveArgs={['severity'].concat(extraArgs)}
            options={NUMBER_PRESETS}
            placeholder={`--`}
            readOnly={readOnly}
            saveOnBlur
            validator={validateOneToOneHundred}
          />
        )
      case 'rating':
        return (
          currentValue &&
          <ThreatRatingInput
            currentConfidence={currentValue.confidence}
            currentSeverity={currentValue.severity}
            defaultUnlocked={this.context.userIsPWThreatTeam}
            onChange={onChange}
            onChangeArgs={['rating'].concat(extraArgs)}
            readOnly={readOnly}
            required={allMappingsRequired}
          />
        )
      default:
        return null
    }
  }
}

import React from 'react'
import {TextOverflow} from 'react-base'
import ThreatCategories from './ThreatCategories'
import ThreatLevel from './ThreatLevel'
import DeviceIdenticon from 'components/DeviceIdenticon'
import { getMostRelevantHostname } from 'utils/deviceUtils'

export default props => {
  const {queryValue, data} = props
  const deviceDbData = data('deviceDbData').valueOf()
  const hostName = deviceDbData && getMostRelevantHostname(deviceDbData)

  return <React.Fragment>
    <DeviceIdenticon deviceId={queryValue} size={50} />

    <h4>
      <TextOverflow>{hostName && hostName.value || queryValue}</TextOverflow>
    </h4>

    <h4><span className="int_ext">Internal Device</span></h4>

    <ThreatCategories data={data} />
    <ThreatLevel data={data} />
  </React.Fragment>
}

/**
 * Adapter/Bridge between legacy Reflux Actions and our custom Mobx "Store" action dispatcher.
 *
 * Example usage:
 *
 * const myActions = {
 *   Foo: Reflux.createActions([
 *     'updateFoo',
 *     'deleteFoo',
 *   ])
 * }
 *
 * forwardActionsToStore(myActions.Foo, MyNewMobxFooStore) // Pass the store class/constructor reference as the second argument
 */
import { StoreConstructor } from "./StoreManager"
import { rootStoreManager } from 'stores/mobx/Root'

interface RefluxActions {
  [key: string]: {
    preEmit: any
  }
}

export default function forwardActionsToStore (refluxActions: RefluxActions, targetStoreClass: StoreConstructor) : void {
  for (const action in refluxActions) {
    const actionFn = refluxActions[action]
    actionFn.preEmit = function (...args) {
      const storeInstance = rootStoreManager.getInstance(targetStoreClass)
      if (!storeInstance) {
        console.warn('forwardActionsToStore: Store instance not available, it may not have any observers yet.')
        return
      }
      storeInstance[action].apply(storeInstance, args)
    }
  }
}
import _ from 'lodash'
import React from 'react'
import {
  capitalizeString,
  formatBytes,
  formatDurationHuman
} from 'pw-formatters'
import constants from 'pwConstants'
import SensorStore from 'stores/SensorStore'
import jsonpack from 'jsonpack'
import {
  getCurrentTimeFormat,
  formatDate,
  formatDateWith
} from 'utils/timeUtils'
import IpValue from 'components/values/IpValue'
import UrlValue from 'components/values/UrlValue'
import SearchableValue from 'components/values/SearchableValue'
import DeviceValue from 'components/values/DeviceValue'
import { CountryValue, getCountryLabel } from 'components/values/CountryValue'
import SensorValue from 'components/values/SensorValue'


const FIELD_RENAMES = { ips: 'ip', ports: 'port' }

const simpleValueExportFormatter = function(value) {
  return value
}

class Column {
  constructor(config) {
    _.assign(
      this,
      {
        header: '',
        queryField: null,
        sortable: true,
        filterable: true,
        resizable: true,
        id: config.id || config.sortField || config.queryField
      },
      config
    )
  }

  getValue(dataItem) {
    return dataItem[this.queryField]
  }

  formatValue(value, dataItem, schema) {
    return value || null
  }

  setWidth(width) {
    this.width = width
  }

  _getQueryFieldName(queryFamily, schema) {
    let name = this.queryField
    let fieldDef = schema.fieldsByFamily[queryFamily][name]
    if (!fieldDef) {
      // try looking in the opposite family for a translation
      fieldDef =
        schema.fieldsByFamily[
          queryFamily === 'netflows' ? 'observations' : 'netflows'
        ][name]
      name =
        (fieldDef && fieldDef[queryFamily.replace(/s$/, '') + 'Field']) || null
    }
    return name
  }

  getAvailableQueryActions(query, value, schema) {
    const actions = []
    if (this.canExcludeFromQuery(query, value, schema)) {
      actions.push({
        text: 'Exclude',
        buildQuery: this.excludeFromQuery.bind(this, query, value, schema),
        flags: { columnPivot: 'exclude' }
      })
    }
    if (this.canExpandQuery(query, value, schema)) {
      actions.push({
        text: 'Expand',
        buildQuery: this.expandQuery.bind(this, query, value, schema),
        flags: { columnPivot: 'expand' }
      })
    }
    if (this.canNarrowQuery(query, value, schema)) {
      actions.push({
        text: 'Narrow',
        buildQuery: this.narrowQuery.bind(this, query, value, schema),
        flags: { columnPivot: 'narrow' }
      })
    }
    if (this.canRemoveFromQuery(query, value, schema)) {
      actions.push({
        text: 'Remove from query',
        buildQuery: this.removeFromQuery.bind(this, query, value, schema),
        flags: { columnPivot: 'remove' }
      })
    }
    actions.push({
      text: 'New query',
      buildQuery: this.newQuery.bind(this, query, value, schema),
      flags: { isNew: true }
    })
    return actions
  }

  isFilterable(query, value, schema) {
    return (
      this.filterable &&
      (_.isNumber(value) || !_.isEmpty(value)) &&
      !!this._getQueryFieldName(query.family, schema)
    )
  }

  canExpandQuery(query, value, schema) {
    // If there is at least one clause for this field, but none with it in their value
    const name = this._getQueryFieldName(query.family, schema)
    if (!name || _.isArray(value)) {
      return false
    }
    const clauses = query.clauses.filter(clause => clause.name === name)
    return clauses.length
      ? !clauses.some(
          clause =>
            (clause.op === 'eq' && clause.value === value) ||
            (clause.op === 'in' && clause.value.indexOf(value) >= 0)
        )
      : false
  }

  canNarrowQuery(query, value, schema) {
    // If there are no existing clauses for this field
    const name = this._getQueryFieldName(query.family, schema)
    if (!name) {
      return false
    }
    return !query.clauses.some(clause => clause.name === name)
  }

  canRemoveFromQuery(query, value, schema) {
    // If there are any clauses for this field with this value
    const name = this._getQueryFieldName(query.family, schema)
    if (!name || _.isArray(value)) {
      return false
    }
    const clauses = query.clauses.filter(clause => clause.name === name)
    return clauses.length
      ? clauses.some(
          clause =>
            (clause.op === 'eq' && clause.value === value) ||
            (clause.op === 'in' && clause.value.indexOf(value) >= 0)
        )
      : false
  }

  canExcludeFromQuery(query, value, schema) {
    // If there isn't already a clause excluding it
    const name = this._getQueryFieldName(query.family, schema)
    if (!name || _.isArray(value)) {
      return false
    }
    const clauses = query.clauses.filter(clause => clause.name === name)
    return clauses.length
      ? !clauses.some(clause => {
          const notClause = clause.not
          return (
            notClause &&
            ((notClause.op === 'eq' && notClause.value === value) ||
              (notClause.op === 'in' && notClause.value.indexOf(value) >= 0))
          )
        })
      : true
  }

  newQuery(query, value, schema) {
    const name = this._getQueryFieldName(query.family, schema)
    if (!name) {
      return
    }
    query = {
      family: query.family,
      clauses: _.where(query.clauses, {
        name: query.family === 'netflows' ? 'startTime' : 'occurredAt'
      })
    }
    query.clauses.push({
      name: name,
      op: _.isArray(value) ? 'in' : 'eq',
      value: value
    })
    return query
  }

  expandQuery(query, value, schema) {
    // Find the first clause for this field, convert it to an "in", and add the value to its "or" array
    const name = this._getQueryFieldName(query.family, schema)
    if (!name) {
      return
    }
    query = _.cloneDeep(query)
    const clause = _.find(query.clauses, cl => cl.name === name)
    if (clause.op === 'eq') {
      clause.op = 'in'
      clause.value = [clause.value, value]
    } else if (clause.op === 'in') {
      clause.value.push(value)
    }
    return query
  }

  narrowQuery(query, value, schema) {
    // Add as a new top-level "eq" clause
    const name = this._getQueryFieldName(query.family, schema)
    if (!name) {
      return
    }
    query = _.assign({}, query)
    query.clauses = query.clauses.slice()
    query.clauses.push({
      name: name,
      op: _.isArray(value) ? 'in' : 'eq',
      value: value
    })
    return query
  }

  removeFromQuery(query, value, schema) {
    // Remove this value from each clause for this field
    const name = this._getQueryFieldName(query.family, schema)
    if (!name) {
      return
    }
    query = _.assign({}, query)
    query.clauses = _(query.clauses)
      .map(clause => {
        if (clause.name === name) {
          if (clause.op === 'eq' && clause.value === value) {
            clause = null
          } else if (clause.op === 'in') {
            clause = _.defaults(
              {
                value: _.without(clause.value, value)
              },
              clause
            )
            if (!clause.value.length) {
              clause = null
            }
          }
        }
        return clause
      })
      .compact()
      .value()
    return query
  }

  excludeFromQuery(query, value, schema) {
    // Add as a new top-level negated clause
    // TODO must removeFromQuery() first if it is already in the query
    const name = this._getQueryFieldName(query.family, schema)
    if (!name) {
      return
    }
    query = _.assign({}, query)
    query.clauses = query.clauses.slice()
    query.clauses.push({
      not: {
        name: name,
        op: _.isArray(value) ? 'in' : 'eq',
        value: value
      }
    })
    return query
  }
}

const formatTimeField = val => {
  return val ? formatDate(val, true) : ''
}

const formatTimeFieldExport = val => {
  return formatDateWith(val, 'iso')
}

const formatObsIpValue = (val, obs) => {
  if (_.isArray(val)) {
    if (val.length < 2) {
      val = val[0]
    } else {
      return (
        <span data-tooltip={val.join(', ')}>
          ({val.length} IPs)
        </span>
      )
    }
  }
  return (
    <IpValue
      noQuery
      ip={val}
      start={obs.occurredAt - 60 * 60 * 1000}
      end={obs.occurredAt + 60 * 60 * 1000}
      sensorId={obs.sensorId}
    />
  )
}

const formatNetflowIpValue = (val, netflow) =>
  <IpValue
    noQuery
    ip={val}
    start={netflow.startedAt}
    end={netflow.endedAt || Date.now()}
    sensorId={netflow.sensorId}
  />

const formatDeviceIdValue = (val, netflow) => {
  const sensorId = netflow.sensorId || netflow.associatedId.flowId.sensorId
  if (_.isArray(val)) {
    if (val.length < 2) {
      val = val[0]
    } else {
      return `${val.length} Devices`
    }
  }
  return val
    ? <DeviceValue
        deviceId={val}
        sensorId={sensorId}
        size={20}
        start={netflow.startedAt}
        end={netflow.endedAt || Date.now()}
      />
    : ''
}

const formatObsPortValue = val => {
  if (_.isArray(val)) {
    if (val.length < 2) {
      val = val[0]
    } else {
      val = (
        <span data-tooltip={val.join(', ')}>
          ({val.length} ports)
        </span>
      )
    }
  }
  return val
}

const formatCountry = val => {
  return getCountryLabel(val)
}
const formatCountryWithFlag = val => {
  return val ? <CountryValue code={val} /> : ''
}

const formatUrlValue = val => (
  <UrlValue
    key={val}
    url={val}
    noQuery
    handleTextOverflow
  />
)

const formatFileHashValue = (val, obs) => {
  const sha256 = _.get(obs, 'data.fileReputation.hashes.sha256')
  return sha256
    ? <SearchableValue intelCard={`file:${sha256}`} noQuery>
        {val}
      </SearchableValue>
    : val
}

const TIME_OPTION_COL_WIDTHS = {
  classic: 185,
  classicWithTZOffset: 230,
  standard: 185,
  standardWithTZOffset: 220,
  compact: 180,
  compactWithTZOffset: 220,
  iso: 200
}

function getTimeFieldWidth() {
  return TIME_OPTION_COL_WIDTHS[getCurrentTimeFormat()] || 200
}

const COLUMNS = {
  observations: [
    new Column({
      queryField: 'tags',
      header: <span className="icon icon-tags" data-tooltip="Tags" />,
      headerText: 'Tags',
      filterable: false,
      sortable: false,
      defaultVisible: true,
      width: 30,
      formatValue: tags =>
        _.isEmpty(tags)
          ? null
          : <span data-tooltip={tags.join(', ')} className="icon icon-tags" />,
      formatExportValue: tags => (tags || []).join(', ')
    }),
    new Column({
      queryField: 'occurredAt',
      header: 'Occurred At',
      filterable: false,
      defaultVisible: true,
      width: getTimeFieldWidth(),
      getValue(obs) {
        return obs.occurredAt
      },
      formatExportValue: formatTimeFieldExport,
      formatValue: formatTimeField,
      initialSortDir: 'desc'
    }),
    new Column({
      queryField: 'observedAt',
      header: 'Observed At',
      filterable: false,
      defaultVisible: false,
      width: getTimeFieldWidth(),
      getValue(obs) {
        return obs.observedAt
      },
      formatExportValue: formatTimeFieldExport,
      formatValue: formatTimeField,
      initialSortDir: 'desc'
    }),
    new Column({
      queryField: 'threatScore',
      header: 'Score',
      headerTooltip: 'Threat Score',
      defaultVisible: true,
      width: 70,
      getCellClass(obs) {
        return `threat_level ${obs.threatLevelFormatted}`
      },
      initialSortDir: 'desc'
    }),
    new Column({
      queryField: 'threatLevel',
      header: 'Level',
      headerTooltip: 'Threat Level',
      defaultVisible: true,
      width: 90,
      getCellClass(obs) {
        return `threat_level ${obs.threatLevelFormatted}`
      },
      getValue(obs) {
        return obs[this.queryField].toLowerCase()
      },
      formatValue: capitalizeString,
      initialSortDir: 'desc'
    }),
    new Column({
      queryField: 'killChainStage',
      header: 'Killchain',
      defaultVisible: true,
      width: 100,
      getValue(obs) {
        return obs[this.queryField].toLowerCase()
      },
      formatValue(val, obs) {
        return obs.killchainStageFormattedHuman
      }, //TODO use icons instead?
      initialSortDir: 'desc'
    }),
    new Column({
      header: 'Category',
      queryField: 'threatCategory',
      defaultVisible: true,
      width: 150,
      getValue(obs) {
        return obs.threatCategoryFormatted
      },
      formatValue(val, obs) {
        return obs.threatCategoryFormattedHuman
      } //TODO use icons instead?
    }),
    new Column({
      header: 'Type',
      headerTooltip: 'Observation Type',
      sortable: false,
      queryField: 'type',
      defaultVisible: true,
      width: 90,
      getValue(obs) {
        return obs.observationType
      },
      formatValue(val, obs) {
        return obs.observationTypeFormatted
      }
    }),
    new Column({
      id: 'description',
      header: 'Description',
      sortable: false,
      filterable: false,
      defaultVisible: true,
      width: 280,
      getValue(obs) {
        return obs.formattedTitleRich
      },
      formatExportValue: (richTitle, obs) => obs.formattedTitle
    }),
    new Column({
      id: 'srcIp',
      queryField: 'ip',
      sortable: false,
      header: 'Src IP',
      defaultVisible: true,
      width: 140,
      getValue(obs) {
        let ip = obs.connectionInfo && obs.connectionInfo.srcIp
        if (!ip) {
          //likely a CBH observation; try conversationId.src then look through info.hostIds for those that have srcPorts
          ip = _.get(obs, 'associatedId.conversationId.src.host.ip')
        }
        if (!ip) {
          ip =
            obs.info &&
            obs.info.hostIds &&
            obs.info.hostIds
              .reduce((ips, hostId) => {
                if (
                  hostId.host &&
                  hostId.host.ip &&
                  !_.isEmpty(hostId.srcPorts)
                ) {
                  ips.push(hostId.host.ip)
                }
                return ips
              }, [])
              .sort()
        }
        return _.isEmpty(ip) ? null : ip
      },
      formatValue: formatObsIpValue,
      formatExportValue: simpleValueExportFormatter
    }),
    new Column({
      id: 'srcPort',
      queryField: 'port',
      sortField: 'srcPort',
      header: 'Src Port',
      defaultVisible: false,
      width: 90,
      getValue(obs) {
        let port = obs.connectionInfo && obs.connectionInfo.srcPort
        if (!port) {
          //likely a CBH observation; collect srcPorts from info.hostIds
          port =
            obs.info &&
            obs.info.hostIds &&
            _.uniq(
              obs.info.hostIds.reduce((ports, hostId) => {
                if (!_.isEmpty(hostId.srcPorts)) {
                  ports.push(...hostId.srcPorts)
                }
                return ports
              }, [])
            ).sort()
        }
        return !_.isNumber(port) && _.isEmpty(port) ? null : port
      },
      formatValue: formatObsPortValue,
      formatExportValue: simpleValueExportFormatter
    }),
    new Column({
      id: 'srcDeviceId',
      queryField: 'devices',
      sortable: false,
      header: 'Src Device',
      defaultVisible: true,
      width: 150,
      getValue(obs) {
        let deviceId = _.get(obs, 'associatedId.flowId.srcDeviceId')
        if (!deviceId) {
          //likely a CBH observation; try conversationId.src then look through info.hostIds for those that have srcPorts
          deviceId = _.get('associatedId.conversationId.src.deviceId')
        }
        if (!deviceId) {
          deviceId = obs.info && obs.info.hostIds && obs.info.hostIds.reduce((ids, hostId) => {
            if (hostId.deviceId && !_.isEmpty(hostId.srcPorts)) {
              ids.push(hostId.deviceId)
            }
            return ids
          }, []).sort()
        }
        return _.isEmpty(deviceId) ? null : deviceId
      },
      formatValue: formatDeviceIdValue,
      formatExportValue: simpleValueExportFormatter
    }),
    new Column({
      id: 'srcNatIp',
      queryField: 'ip',
      sortable: false,
      header: 'Src NAT IP',
      defaultVisible: false,
      width: 140,
      getValue(obs) {
        return _.get(obs, 'associatedId.flowId.nat.srcIp') || null
      },
      formatValue: formatObsIpValue,
      formatExportValue: simpleValueExportFormatter
    }),
    /* Disabled until platform starts populating it:
    new Column({
      id: 'srcNatPort',
      queryField: 'port',
      sortable: false, //TODO???
      header: 'Src NAT Port',
      defaultVisible: false,
      width: 90,
      getValue(obs) {
        return _.get(obs, 'associatedId.flowId.nat.srcPort') || null
      },
      formatValue: formatObsPortValue,
      formatExportValue: simpleValueExportFormatter
    }),
    */
    new Column({
      id: 'srcCountryIsoCode',
      queryField: 'observationInfo.countryIsoCodes',
      sortable: false,
      header: 'Src Country',
      defaultVisible: false,
      width: 140,
      getValue(obs) {
        return _.get(obs, 'associatedId.flowId.srcCountryIsoCode') || null
      },
      formatValue: formatCountryWithFlag,
      formatExportValue: formatCountry
    }),
    new Column({
      id: 'dstIp',
      queryField: 'ip',
      sortable: false,
      header: 'Dst IP',
      defaultVisible: true,
      width: 140,
      getValue(obs) {
        let ip = obs.connectionInfo && obs.connectionInfo.dstIp
        if (!ip) {
          //likely a CBH observation; try conversationId.dst then look through info.hostIds for those that have dstPorts
          ip = _.get(obs, 'associatedId.conversationId.dst.host.ip')
        }
        if (!ip) {
          ip =
            obs.info &&
            obs.info.hostIds &&
            obs.info.hostIds
              .reduce((ips, hostId) => {
                if (
                  hostId.host &&
                  hostId.host.ip &&
                  !_.isEmpty(hostId.dstPorts)
                ) {
                  ips.push(hostId.host.ip)
                }
                return ips
              }, [])
              .sort()
        }
        return _.isEmpty(ip) ? null : ip
      },
      formatValue: formatObsIpValue,
      formatExportValue: simpleValueExportFormatter
    }),
    new Column({
      id: 'dstPort',
      queryField: 'port',
      sortField: 'dstPort',
      header: 'Dst Port',
      defaultVisible: false,
      width: 90,
      getValue(obs) {
        let port = obs.connectionInfo && obs.connectionInfo.dstPort
        if (!port) {
          //likely a CBH observation; collect dstPorts from info.hostIds
          port =
            obs.info &&
            obs.info.hostIds &&
            _.uniq(
              obs.info.hostIds.reduce((ports, hostId) => {
                if (!_.isEmpty(hostId.dstPorts)) {
                  ports.push(...hostId.dstPorts)
                }
                return ports
              }, [])
            ).sort()
        }
        return !_.isNumber(port) && _.isEmpty(port) ? null : port
      },
      formatValue: formatObsPortValue,
      formatExportValue: simpleValueExportFormatter
    }),
    new Column({
      id: 'dstDeviceId',
      queryField: 'devices',
      sortable: false,
      header: 'Dst Device',
      defaultVisible: true,
      width: 150,
      getValue(obs) {
        let deviceId = _.get(obs, 'associatedId.flowId.dstDeviceId')
        if (!deviceId) {
          //likely a CBH observation; try conversationId.dst then look through info.hostIds for those that have dstPorts
          deviceId = _.get('associatedId.conversationId.dst.deviceId')
        }
        if (!deviceId) {
          deviceId = obs.info && obs.info.hostIds && obs.info.hostIds.reduce((ids, hostId) => {
            if (hostId.deviceId && !_.isEmpty(hostId.dstPorts)) {
              ids.push(hostId.deviceId)
            }
            return ids
          }, []).sort()
        }
        return _.isEmpty(deviceId) ? null : deviceId
      },
      formatValue: formatDeviceIdValue,
      formatExportValue: simpleValueExportFormatter
    }),
    /* Disabled until platform starts populating it:
    new Column({
      id: 'dstNatIp',
      queryField: 'ip',
      sortable: false,
      header: 'Dst NAT IP',
      defaultVisible: false,
      width: 140,
      getValue(obs) {
        return _.get(obs, 'associatedId.flowId.nat.dstIp') || null
      },
      formatValue: formatObsIpValue,
      formatExportValue: simpleValueExportFormatter
    }),
    new Column({
      id: 'dstNatPort',
      queryField: 'port',
      sortable: false, //TODO???
      header: 'Dst NAT Port',
      defaultVisible: false,
      width: 90,
      getValue(obs) {
        return _.get(obs, 'associatedId.flowId.nat.dstPort') || null
      },
      formatValue: formatObsPortValue,
      formatExportValue: simpleValueExportFormatter
    }),
    */
    new Column({
      id: 'dstCountryIsoCode',
      queryField: 'observationInfo.countryIsoCodes',
      sortable: false,
      header: 'Dst Country',
      defaultVisible: false,
      width: 140,
      getValue(obs) {
        return _.get(obs, 'associatedId.flowId.dstCountryIsoCode') || null
      },
      formatValue: formatCountryWithFlag,
      formatExportValue: formatCountry
    }),
    new Column({
      id: 'url',
      header: 'URL/Domain',
      sortable: false,
      filterable: false,
      defaultVisible: true,
      width: 250,
      getValue(obs) {
        var url
        var data = obs.data
        if (data) {
          url = _.get(data, 'httpTransaction.request.url')
          if (url) {
            url = `${url.hostname}${url.path}${url.queryString
              ? '?' + url.queryString
              : ''}`
          } else {
            url =
              _.get(data, 'urlReputation.url') ||
              _.get(data, 'dnsReputation.dns')
          }
        }
        return url || null
      },
      formatValue: formatUrlValue,
      formatExportValue: simpleValueExportFormatter,
      explorerTextOverflowStrategy: 'none'
    }),
    new Column({
      queryField: 'httpMethod',
      header: 'HTTP Method',
      sortable: false,
      filterable: true,
      defaultVisible: true,
      width: 100,
      getValue(obs) {
        return _.get(obs, 'data.httpTransaction.request.method') || null
      }
    }),
    new Column({
      queryField: 'httpResponseCode',
      header: 'HTTP Resp Code',
      sortable: true,
      filterable: true,
      defaultVisible: false,
      width: 100,
      getValue(obs) {
        return _.get(obs, 'data.httpTransaction.response.code') || null
      }
    }),
    /* These headers aren't yet populated...?
    new Column({
      queryField: 'X-Forwarded-For',
      header: 'X-Forwarded-For',
      sortable: false,
      filterable: true,
      defaultVisible: false,
      width: 250,
      getValue(obs) {
        const headers = _.get(obs, 'data.httpTransaction.request.header')
        const header = headers && _.find(headers, {name: 'x-forwarded-for'})
        return header ? header.value : null
      }
    }),
    new Column({
      queryField: 'X-Requested-With',
      header: 'X-Requested-With',
      sortable: false,
      filterable: true,
      defaultVisible: false,
      width: 250,
      getValue(obs) {
        const headers = _.get(obs, 'data.httpTransaction.request.header')
        const header = headers && _.find(headers, {name: 'x-requested-with'})
        return header ? header.value : null
      }
    }),
    new Column({
      queryField: 'Referer',
      header: 'Referer',
      sortable: false,
      filterable: true,
      defaultVisible: false,
      width: 250,
      getValue(obs) {
        const headers = _.get(obs, 'data.httpTransaction.request.header')
        const header = headers && _.find(headers, {name: 'referer'})
        return header ? header.value : null
      }
    }),
    */
    new Column({
      queryField: 'user-agent',
      header: 'HTTP User Agent',
      sortable: false,
      filterable: true,
      defaultVisible: false,
      width: 250,
      getValue(obs) {
        const headers = _.get(obs, 'data.httpTransaction.request.header')
        const header = headers && _.find(headers, {name: 'user-agent'})
        return header ? header.value : null
      }
    }),
    new Column({
      queryField: 'agentId',
      header: 'Sensor',
      defaultVisible: true,
      width: 150,
      getValue(obs) {
        return obs.agentId
      },
      formatValue(val) {
        return <SensorValue sensorId={val} />
      },
      formatExportValue: simpleValueExportFormatter
    }),
    new Column({
      queryField: 'fileExtractedName',
      header: 'File Name',
      sortable: true,
      filterable: true,
      defaultVisible: false,
      width: 150,
      getValue(obs) {
        return _.get(obs, 'data.fileReputation.extractedName') || null
      },
      explorerTextOverflowStrategy: 'middle'
    }),
    new Column({
      queryField: 'fileAdvertisedFileType',
      header: 'File Advertised Type',
      sortable: true,
      filterable: false, //TODO until queries can accept and parse the string value rather than 'mimetypefield' enums
      defaultVisible: false,
      width: 180,
      getValue(obs) {
        return _.get(obs, 'data.fileReputation.advertisedType') || null
      }
    }),
    new Column({
      queryField: 'fileDetectedFileType',
      header: 'File Detected Type',
      sortable: true,
      filterable: false, //TODO until queries can accept and parse the string value rather than 'mimetypefield' enums
      defaultVisible: false,
      width: 180,
      getValue(obs) {
        return _.get(obs, 'data.fileReputation.detectedType') || null
      }
    }),
    // File hashes: expose as individual unsortable columns that all filter using the same fileHash queryField
    new Column({
      id: '_fileHashMD5',
      queryField: 'fileHash',
      header: 'File Hash (MD5)',
      sortable: false,
      filterable: true,
      defaultVisible: false,
      width: 280,
      getValue(obs) {
        return _.get(obs, 'data.fileReputation.hashes.md5') || null
      },
      formatValue: formatFileHashValue,
      formatExportValue: simpleValueExportFormatter
    }),
    new Column({
      id: '_fileHashSHA1',
      queryField: 'fileHash',
      header: 'File Hash (SHA1)',
      sortable: false,
      filterable: true,
      defaultVisible: false,
      width: 340,
      getValue(obs) {
        return _.get(obs, 'data.fileReputation.hashes.sha1') || null
      },
      formatValue: formatFileHashValue,
      formatExportValue: simpleValueExportFormatter
    }),
    new Column({
      id: '_fileHashSHA256',
      queryField: 'fileHash',
      header: 'File Hash (SHA256)',
      sortable: false,
      filterable: true,
      defaultVisible: false,
      width: 520,
      getValue(obs) {
        return _.get(obs, 'data.fileReputation.hashes.sha256') || null
      },
      formatValue: formatFileHashValue,
      formatExportValue: simpleValueExportFormatter
    }),
    new Column({
      id: '_fileHashSHA512',
      queryField: 'fileHash',
      header: 'File Hash (SHA512)',
      sortable: false,
      filterable: true,
      defaultVisible: false,
      width: 520, //will ellipsize
      getValue(obs) {
        return _.get(obs, 'data.fileReputation.hashes.sha512') || null
      },
      formatValue: formatFileHashValue,
      formatExportValue: simpleValueExportFormatter
    }),
    new Column({
      queryField: 'id',
      sortable: false,
      header: 'Observation ID',
      defaultVisible: false,
      width: 280,
      getValue(obs) {
        return obs.id
      }
    })
  ],
  netflows: [
    new Column({
      queryField: 'startTime',
      header: 'Start Time',
      filterable: false,
      defaultVisible: true,
      width: getTimeFieldWidth(),
      getValue(netflow) {
        return netflow.details.startTime
      },
      formatExportValue: formatTimeFieldExport,
      formatValue: formatTimeField,
      initialSortDir: 'desc'
    }),
    new Column({
      queryField: 'endTime',
      header: 'End Time',
      defaultVisible: false,
      width: getTimeFieldWidth(),
      getValue(netflow) {
        return netflow.details.endTime
      },
      formatExportValue: formatTimeFieldExport,
      formatValue: formatTimeField,
      initialSortDir: 'desc'
    }),
    new Column({
      queryField: 'duration',
      filterable: false,
      header: 'Dur',
      headerTooltip: 'Netflow Duration',
      defaultVisible: true,
      width: 75,
      getValue(netflow) {
        const start = netflow.details.startTime
        const end = netflow.details.endTime
        return end && start ? end - start : null
      },
      formatValue(val) {
        return _.isNumber(val) && val >= 0
          ? formatDurationHuman(val)
          : 'Pending'
      },
      initialSortDir: 'desc'
    }),
    new Column({
      queryField: 'protocols',
      header: 'Apps',
      sortable: false, //TODO
      defaultVisible: true,
      width: 150,
      getValue(netflow) {
        const data = netflow.details.applicationProtocols
        return data
          ? _.pluck(data, 'knownProtocol').filter(n => _.isNumber(n))
          : null
      },
      formatExportValue(val, netflow, schema) {
        return val
          ? `"${val.map(id => schema.protocolNamesById[id]).join(', ')}"`
          : ''
      },
      formatValue(val, netflow, schema) {
        return val ? val.map(id => schema.protocolNamesById[id]).join(', ') : ''
      }
    }),
    new Column({
      id: 'srcIp',
      queryField: 'ip',
      sortable: false,
      header: 'Src IP',
      defaultVisible: true,
      width: 140,
      getValue(netflow) {
        return netflow.id.srcIp
      },
      formatValue: formatNetflowIpValue,
      formatExportValue: simpleValueExportFormatter
    }),
    new Column({
      id: 'srcPort',
      queryField: 'port',
      sortField: 'srcPort',
      header: 'Src Port',
      defaultVisible: true,
      width: 90,
      getValue(netflow) {
        return netflow.id.srcPort
      },
      // formatValue(val) { return val || '<Unknown Port>'
      formatExportValue: simpleValueExportFormatter
    }),
    new Column({
      id: 'srcDeviceId',
      queryField: 'device',
      sortable: false,
      header: 'Src Device',
      defaultVisible: true,
      width: 150,
      getValue(netflow) {
        return _.get(netflow, 'details.srcDeviceId')
      },
      formatValue: formatDeviceIdValue,
      formatExportValue: simpleValueExportFormatter
    }),
    new Column({
      id: 'srcNatIp',
      queryField: 'ip',
      sortable: false,
      header: 'Src NAT IP',
      defaultVisible: false,
      width: 140,
      getValue(netflow) {
        return _.get(netflow, 'details.nat.srcIp') || null
      },
      formatValue: formatNetflowIpValue,
      formatExportValue: simpleValueExportFormatter
    }),
    /* Disabled until platform starts populating it:
    new Column({
      id: 'srcNatPort',
      queryField: 'port',
      sortable: false, //TODO???
      header: 'Src NAT Port',
      defaultVisible: false,
      width: 90,
      getValue(netflow) {
        return _.get(netflow, 'details.nat.srcPort') || null
      },
      formatExportValue: simpleValueExportFormatter
    }),
    */
    new Column({
      id: 'srcCountryIsoCode',
      queryField: 'countryIsoCodes',
      sortable: false,
      header: 'Src Country',
      defaultVisible: false,
      width: 140,
      getValue(netflow) {
        return _.get(netflow, 'details.srcGeo.countryIsoCode') || null
      },
      formatValue: formatCountryWithFlag,
      formatExportValue: formatCountry
    }),
    new Column({
      id: 'dstIp',
      queryField: 'ip',
      sortable: false,
      header: 'Dst IP',
      defaultVisible: true,
      width: 140,
      getValue(netflow) {
        return netflow.id.dstIp
      },
      formatValue: formatNetflowIpValue,
      formatExportValue: simpleValueExportFormatter
    }),
    new Column({
      id: 'dstPort',
      queryField: 'port',
      sortField: 'dstPort',
      header: 'Dst Port',
      defaultVisible: true,
      width: 90,
      getValue(netflow) {
        return netflow.id.dstPort
      },
      // formatValue(val) { return val || '<Unknown Port>'
      formatExportValue: simpleValueExportFormatter
    }),
    new Column({
      id: 'dstDeviceId',
      queryField: 'device',
      sortable: false,
      header: 'Dst Device',
      defaultVisible: true,
      width: 150,
      getValue(netflow) {
        return _.get(netflow, 'details.dstDeviceId')
      },
      formatValue: formatDeviceIdValue,
      formatExportValue: simpleValueExportFormatter
    }),
    /* Disabled until platform starts populating it:
    new Column({
      id: 'dstNatIp',
      queryField: 'ip',
      sortable: false,
      header: 'Dst NAT IP',
      defaultVisible: false,
      width: 140,
      getValue(netflow) {
        return _.get(netflow, 'details.nat.dstIp') || null
      },
      formatValue: formatNetflowIpValue,
      formatExportValue: simpleValueExportFormatter
    }),
    new Column({
      id: 'dstNatPort',
      queryField: 'port',
      sortable: false, //TODO???
      header: 'Dst NAT Port',
      defaultVisible: false,
      width: 90,
      getValue(netflow) {
        return _.get(netflow, 'details.nat.dstPort') || null
      },
      formatExportValue: simpleValueExportFormatter
    }),
    */
    new Column({
      id: 'dstCountryIsoCode',
      queryField: 'countryIsoCodes',
      sortable: false,
      header: 'Dst Country',
      defaultVisible: false,
      width: 140,
      getValue(netflow) {
        return _.get(netflow, 'details.dstGeo.countryIsoCode') || null
      },
      formatValue: formatCountryWithFlag,
      formatExportValue: formatCountry
    }),
    new Column({
      queryField: 'totalBytes',
      header: 'Bytes',
      filterable: false,
      defaultVisible: true,
      width: 95,
      getValue(netflow) {
        return netflow.stats.bytesSrc + netflow.stats.bytesDst
      },
      formatExportValue(val) {
        return val
      },
      formatValue(val) {
        return _.isNumber(val) ? formatBytes(val, true) : ''
      },
      initialSortDir: 'desc'
    }),
    new Column({
      queryField: 'srcBytes',
      header: 'Src Bytes',
      filterable: false,
      sortable: false,
      defaultVisible: false,
      width: 95,
      getValue(netflow) {
        return netflow.stats.bytesSrc
      },
      formatExportValue(val) {
        return val
      },
      formatValue(val) {
        return _.isNumber(val) ? formatBytes(val, true) : ''
      },
      initialSortDir: 'desc'
    }),
    new Column({
      queryField: 'dstBytes',
      header: 'Dst Bytes',
      filterable: false,
      sortable: false,
      defaultVisible: false,
      width: 95,
      getValue(netflow) {
        return netflow.stats.bytesDst
      },
      formatExportValue(val) {
        return val
      },
      formatValue(val) {
        return _.isNumber(val) ? formatBytes(val, true) : ''
      },
      initialSortDir: 'desc'
    }),
    new Column({
      queryField: 'ntuple.layer3Protocol',
      header: 'L3 Proto',
      filterable: true,
      sortable: false,
      defaultVisible: false,
      width: 60,
      getValue(netflow) {
        return netflow.id.layer3Protocol
      },
      formatValue(val) {
        return val ? val.toUpperCase() : ''
      }
    }),
    new Column({
      queryField: 'ntuple.layer4Protocol',
      header: 'L4 Proto',
      filterable: true,
      sortable: false,
      defaultVisible: false,
      width: 60,
      getValue(netflow) {
        return netflow.id.layer4Protocol
      },
      formatValue(val) {
        return val ? val.toUpperCase() : ''
      }
    }),
    new Column({
      queryField: 'agentId',
      header: 'Sensor',
      defaultVisible: true,
      width: 150,
      getValue(netflow) {
        return netflow.agentId
      },
      formatValue(val) {
        return <SensorValue sensorId={val} />
      },
      formatExportValue: simpleValueExportFormatter
    }),
    new Column({
      id: 'srcLat',
      filterable: false,
      sortable: false,
      header: 'Src Latitude',
      defaultVisible: false,
      width: 130,
      getValue(netflow) {
        return netflow.details.srcGeo ? netflow.details.srcGeo.lat : null
      }
    }),
    new Column({
      id: 'srcLon',
      filterable: false,
      sortable: false,
      header: 'Src Longitude',
      defaultVisible: false,
      width: 130,
      getValue(netflow) {
        return netflow.details.srcGeo ? netflow.details.srcGeo.lon : null
      }
    }),
    new Column({
      id: 'dstLat',
      filterable: false,
      sortable: false,
      header: 'Dst Latitude',
      defaultVisible: false,
      width: 130,
      getValue(netflow) {
        return netflow.details.dstGeo ? netflow.details.dstGeo.lat : null
      }
    }),
    new Column({
      id: 'dstLon',
      filterable: false,
      sortable: false,
      header: 'Dst Longitude',
      defaultVisible: false,
      width: 130,
      getValue(netflow) {
        return netflow.details.dstGeo ? netflow.details.dstGeo.lon : null
      }
    }),
    new Column({
      queryField: 'id',
      sortable: false,
      header: 'Netflow ID',
      defaultVisible: false,
      width: 280,
      getValue(netflow) {
        return netflow.key
      }
    }),
    new Column({
      queryField: 'vlan',
      sortable: false,
      header: 'VLAN ID',
      defaultVisible: false,
      width: 130,
      getValue(netflow) {
        return netflow.details.vlan.toString()
      }
    })
  ]
}

const COLUMNS_BY_HEADER = {
  netflows: _.indexBy(COLUMNS.netflows, 'header'),
  observations: _.indexBy(COLUMNS.observations, 'header')
}

// id is usually the queryField, but may be different e.g. srcIp vs ips
const COLUMNS_BY_ID = {
  netflows: _.indexBy(COLUMNS.netflows, 'id'),
  observations: _.indexBy(COLUMNS.observations, 'id')
}

const getDefaultColumns = () => {
  const reduceToDefaultVisible = (keysOut, col, key) => {
    if (col.defaultVisible) {
      keysOut.push(key)
    }
    return keysOut
  }
  return {
    netflows: _.reduce(COLUMNS_BY_ID.netflows, reduceToDefaultVisible, []),
    observations: _.reduce(
      COLUMNS_BY_ID.observations,
      reduceToDefaultVisible,
      []
    )
  }
}

const transformCsvData = (data, family, schema) => {
  if (!family || !data) return
  return _.map(data, itm => {
    const out = {}
    _.forEach(COLUMNS[family], col => {
      const _formatMethod =
        col.formatExportValue && _.isFunction(col.formatExportValue)
          ? 'formatExportValue'
          : 'formatValue'
      const key = col.headerText || col.header
      out[key] = col[_formatMethod](col.getValue(itm), itm, schema)
    })
    return out
  })
}

const walkClauses = (clauses, fn) => {
  if (!_.isArray(clauses)) clauses = [clauses]
  _.forEach(clauses, clause => {
    const sub = clause.not || clause.and || clause.or
    if (sub) {
      walkClauses(sub, fn)
    } else {
      fn(clause)
    }
  })
}

const flattenClauses = clauses => {
  const flat = []
  walkClauses(clauses, clause => flat.push(clause))
  return flat
}

const formatFieldName = (schema, family, name) => {
  const fieldDef = schema.fieldsByFamily[family][name]
  return (fieldDef && fieldDef.label) || name
}

const formatClause = (schema, family, clause, returnConcat = true) => {
  if (_.isString(clause)) {
    clause = JSON.parse(clause)
  } else {
    clause = _.clone(clause)
  }
  const schemaDef = schema.fieldsByFamily[family][clause.name]
  const name = formatFieldName(schema, family, clause.name)
  let value = clause.value
  if (typeof value === 'symbol') {
    value = value.toString()
  }
  if (!_.isArray(value)) {
    value = [value]
  }
  if (schemaDef.type === 'enum') {
    value = value.map(v => {
      const opt = _.find(schemaDef.options, { value: v })
      return (opt && opt.text) || value
    })
  } else if (schemaDef.type === 'time') {
    clause.from = formatDate(clause.from)
    clause.to = formatDate(clause.to)
  }
  let op = clause.op
  if (op === 'between') {
    value = `${clause.from} and ${clause.to}`
  } else {
    op = (schema.operators[op] && schema.operators[op].abbr) || op
    value = value.join('/\u200B') //zero-width space char to allow line breaking after slash
  }
  return returnConcat ? `${name} ${op} ${value}` : {name, op, value}
}

const formatClausesAsText = (schema, family, clauses, operator = 'AND') => {
  return clauses
    .map(clause => {
      const subClauses = clause.and || clause.or
      return subClauses
        ? `(${formatClausesAsText(
            schema,
            family,
            subClauses,
            clause.or ? 'OR' : 'AND'
          )})`
        : clause.not
          ? formatClause(
              schema,
              family,
              _.defaults({ op: '!' + clause.not.op }, clause.not)
            )
          : formatClause(schema, family, clause)
    })
    .join(` ${operator} `)
}

const formatQueryAsText = (schema, query) => {
  const family = _.capitalize(query.family)
  return _.isEmpty(query.clauses)
    ? `All ${family}`
    : `${family} where ${formatClausesAsText(
        schema,
        query.family,
        query.clauses
      )}`
}

const columnPivotPrefixes = {
  exclude: 'Excluded',
  expand: 'Expanded by',
  narrow: 'Narrowed by',
  remove: 'Removed'
}

const generateQueryDiffDescription = (
  schema,
  fromQuery,
  toQuery,
  flags = {}
) => {
  let toQueryFlat = flattenClauses(toQuery.clauses)
  if (flags.isSaved) {
    return `Ran saved query "${flags.savedQueryName}"`
  } else if (flags.isTemplate) {
    return `Ran query preset "${flags.templateQueryName}"`
  } else if (!fromQuery || flags.isNew || fromQuery.family !== toQuery.family) {
    let text = `${!fromQuery || flags.isNew
      ? 'New'
      : 'Switched to'} ${toQuery.family} query`
    toQueryFlat = _.filter(
      toQueryFlat,
      clause =>
        clause.name !==
        (toQuery.family === 'netflows' ? 'startTime' : 'occurredAt')
    )
    if (toQueryFlat.length) {
      text += ` by ${_.uniq(
        toQueryFlat.map(clause =>
          formatFieldName(schema, toQuery.family, clause.name)
        )
      ).join(', ')}`
    }
    return text
  } else {
    const toClausesByName = _.groupBy(toQueryFlat, 'name')
    const fromClausesByName = _.groupBy(
      flattenClauses(fromQuery.clauses),
      'name'
    )
    let added = []
    let removed = []
    let changed = []
    _.union(
      _.keys(toClausesByName),
      _.keys(fromClausesByName)
    ).forEach(name => {
      const fromClauses = fromClausesByName[name]
      const toClauses = toClausesByName[name]
      if (fromClauses && toClauses) {
        const toDiff = toClauses.filter(
          clause => !_.findWhere(fromClauses, clause)
        )
        const fromDiff = fromClauses.filter(
          clause => !_.findWhere(toClauses, clause)
        )
        if (toDiff.length && fromDiff.length) changed.push(name)
        else if (toDiff.length) added.push(name)
        else if (fromDiff.length) removed.push(name)
      } else if (toClauses) added.push(name)
      else if (fromClauses) removed.push(name)
    })
    const formatName = fieldName =>
      formatFieldName(schema, toQuery.family, fieldName)
    added = _.uniq(added).map(formatName)
    removed = _.uniq(removed).map(formatName)
    changed = _.uniq(changed).map(formatName)

    // Flagged column pivots get special text
    if (flags.columnPivot) {
      const prefix = columnPivotPrefixes[flags.columnPivot]
      const names = added.concat(removed).concat(changed)
      if (prefix && names.length) {
        return `${prefix} ${names.join(', ')}`
      }
    }

    const phrases = []
    if (added.length) phrases.push(`Added ${added.join(', ')}`)
    if (removed.length) phrases.push(`Removed ${removed.join(', ')}`)
    if (changed.length) phrases.push(`Changed ${changed.join(', ')}`)
    return phrases.length ? phrases.join('; ') : 'Moved things around'
  }
}

const ipFields = { ips: 1, ip: 1, srcIp: 1, dstIp: 1 }
const getIpsInQuery = query =>
  _(flattenClauses(query.clauses))
    .filter(clause => ipFields.hasOwnProperty(clause.name))
    .pluck('value')
    .flatten()
    .value()

const deviceFields = { devices: 1, srcDeviceId: 1, dstDeviceId: 1 }
const getDevicesInQuery = query =>
  _(flattenClauses(query.clauses))
    .filter(clause => deviceFields.hasOwnProperty(clause.name))
    .pluck('value')
    .flatten()
    .value()

//NOTE: jsonpack returns and accepts a JS obj, JSON.parse not required
const stringifyQuery = query => {
  if (!query) return ''
  let str = jsonpack.pack(query)
  // JSONPack's dictionary delimiter is |, which url-encodes to 3 chars; to keep the URL shorter
  // let's swap that with a dash, and then swap them back when parsing.
  str = str.replace(/[|-]/g, s => (s === '-' ? '|' : '-'))
  return encodeURIComponent(str)
}

const parseStringifiedQuery = encQueryString => {
  if (!encQueryString) return null
  let str = decodeURIComponent(encQueryString)
  str = str.replace(/[|-]/g, s => (s === '-' ? '|' : '-'))
  return jsonpack.unpack(str)
}

// Recursively port clauses from one schema family to the other
const portClauses = (srcClauses, srcFamily, dstFamily, schema) => {
  const dstClauses = []
  const droppedClauses = []
  if (!_.isEmpty(srcClauses)) {
    srcClauses.forEach(clause => {
      if ((clause.not || clause).name) {
        const oldField =
          schema.fieldsByFamily[srcFamily][(clause.not || clause).name]
        const otherFieldName =
          oldField && oldField[dstFamily.replace(/s$/, '') + 'Field'] //netflowField,observationField
        clause = _.cloneDeep(clause)
        delete clause._clauseKey
        if (otherFieldName) {
          ;(clause.not || clause).name = otherFieldName
          dstClauses.push(clause)
        } else {
          droppedClauses.push(clause.not || clause)
        }
      } else if (clause.or) {
        const subPorted = portClauses(clause.or, srcFamily, dstFamily, schema)
        dstClauses.push({ or: subPorted.clauses })
        droppedClauses.push(...subPorted.droppedClauses)
      } else if (clause.and) {
        const subPorted = portClauses(clause.and, srcFamily, dstFamily, schema)
        dstClauses.push({ and: subPorted.clauses })
        droppedClauses.push(...subPorted.droppedClauses)
      }
    })
  }
  return {
    clauses: dstClauses,
    droppedClauses: droppedClauses
  }
}


/**
 * Given a possibly outdated query object, attempt to upgrade it to the latest format. If any changes were
 * made it will return a new object; if no upgraded were necessary it will return the original object.
 * - If it's an old {netflows:[],observations:[]} combined family query, convert it using the
 *   family that matches most closely.
 * - If it has old `ips`/`ports` query fields, rename them to `ip`/`port`
 */
const upgradeQuery = (query, schema) => {
  // If it's an old {netflows:[],observations:[]} combined family query, convert it using the
  // family that matches most closely.
  if (!query.family) {
    const nfOnlyClauses = flattenClauses(query.netflows || []).filter(
      clause =>
        !_.get(schema, [
          'fieldsByFamily',
          'netflows',
          clause.name,
          'observationField'
        ])
    )
    const obsOnlyClauses = flattenClauses(query.observations || []).filter(
      clause =>
        !_.get(schema, [
          'fieldsByFamily',
          'observations',
          clause.name,
          'netflowField'
        ])
    )
    const targetFamily =
      nfOnlyClauses.length > obsOnlyClauses.length ? 'netflows' : 'observations'
    const ported =
      targetFamily === 'netflows'
        ? portClauses(query.observations, 'observations', targetFamily, schema)
        : portClauses(query.netflows, 'netflows', targetFamily, schema)
    query = {
      family: targetFamily,
      clauses:
        targetFamily === 'netflows'
          ? (query.netflows || []).concat(ported.clauses)
          : ported.clauses.concat(query.observations || []),
      droppedClauses: ported.droppedClauses
    }
  }

  // Convert old field names
  const clonedQuery = _.cloneDeep(query)
  let changed = false
  walkClauses(clonedQuery.clauses, clause => {
    if (FIELD_RENAMES.hasOwnProperty(clause.name)) {
      clause.name = FIELD_RENAMES[clause.name]
      changed = true
    }
  })

  return changed ? clonedQuery : query
}

const tryUpgradeQueries = (items, schema) => {
  let itemsNeededUpgrade = false
  items.forEach(item => {
    // Upgrade old entries if needed
    const upgradedQuery = upgradeQuery(item.query, schema)
    if (upgradedQuery !== item.query) {
      item.query = upgradedQuery
      itemsNeededUpgrade = true
    }
  })

  return {
    items,
    itemsNeededUpgrade
  }
}

/**
 * Port a query from one family to another. Clauses which could not be converted will be dropped.
 * TODO: track the dropped fields so we can notify the user about it
 */
const portQueryFamily = (origQuery, targetFamily, schema) => {
  const ported = portClauses(
    origQuery.clauses,
    origQuery.family,
    targetFamily,
    schema
  )
  return {
    family: targetFamily,
    clauses: ported.clauses,
    droppedClauses: ported.droppedClauses
  }
}

const IP_CLAUSE_NAMES = {
  ip: 1,
  srcIp: 1,
  dstIp: 1,
  ips: 1 //legacy
}

const DEVICE_CLAUSE_NAMES = {
  devices: 1,
  srcDeviceId: 1,
  dstDeviceId: 1
}

export default {
  columns: COLUMNS,
  columnsByHeader: COLUMNS_BY_HEADER,
  columnsById: COLUMNS_BY_ID,
  getDefaultColumns: getDefaultColumns,
  flattenClauses: flattenClauses,
  formatQueryAsText: formatQueryAsText,
  formatClause: formatClause,
  formatClausesAsText: formatClausesAsText,
  generateQueryDiffDescription: generateQueryDiffDescription,
  getIpsInQuery: getIpsInQuery,
  getDevicesInQuery: getDevicesInQuery,
  stringifyQuery: stringifyQuery,
  parseStringifiedQuery: parseStringifiedQuery,
  upgradeQuery: upgradeQuery,
  tryUpgradeQueries: tryUpgradeQueries,
  portQueryFamily: portQueryFamily,
  ipClauseFields: IP_CLAUSE_NAMES,
  deviceClauseFields: DEVICE_CLAUSE_NAMES,
  search: () => {throw 'explorerUtils.search() is now searchUtils#requestSearch()'},
  transformCsvData: transformCsvData,
  walkClauses: walkClauses
}

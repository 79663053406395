import T from 'prop-types'
/**
 * Component wrapper that provides data related to the current user to its child's props.
 *
 * Props passed down:
 *
 *   - `userInfo`: general data about the user (name, customer, etc.)
 *   - `userPermissions`: index of the user's allowed permissions
 *   - `userFeatures`: index of the user's available features
 */

import React from 'react'
import {StoreProvider} from 'react-base'
import UserStore from 'stores/UserStore'

class UserProvider extends React.Component {
  static displayName = 'UserProvider'

  static propTypes = {
    children: T.any,
    mapping: T.oneOfType([
      T.func,
      T.string,
      T.arrayOf(T.string),
      T.objectOf(T.string)
    ])
  }

  render() {
    return (
      <StoreProvider
        { ...this.props }
        mapping={ this.props.mapping || null }
        store={ UserStore }
      >
        { this.props.children }
      </StoreProvider>
    )
  }
}

// The PropTypes passed down to the child.
UserProvider.PROP_TYPES = {
  currentUserId: T.number,
  currentCustomerId: T.number,
  hasPermission: T.func,
  userInfo: T.object,
  userPermissions: T.object,
  userFeatures: T.object,
  userPreferences: T.object,
  isAuthenticated: T.bool,
  isDemoCustomer: T.bool,
  isSupportCustomer: T.bool,
  isMSSPCustomer: T.bool,
  emulatingCustomer: T.bool
}

export default UserProvider

export function UserProviderHOC(TargetCmp) {
  const Wrapper = props =>
    <UserProvider>
      <TargetCmp {...props} />
    </UserProvider>
  Wrapper.displayName = `UserProvider(${TargetCmp.displayName || 'Component'})`
  return Wrapper
}

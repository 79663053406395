import _ from 'lodash'
import React from 'react'
import T from 'prop-types'
import memoize from 'memoize-one'
import { formatNumber } from 'pw-formatters'
import SensorTimeline from './SensorTimeline'


const formatter = val => formatNumber(val, false)

class NetflowGraph extends React.PureComponent {

  // Note: all props treated as immutable
  static propTypes = {
    netflowsCaptured: T.arrayOf(
      T.shape({timestamp: T.number, value: T.number})
    ),
    netflowsSeen: T.arrayOf(
      T.shape({timestamp: T.number, value: T.number})
    ),
    onTimeHover: T.func,
    rulerTime: T.number,
    yAxis: T.bool,
    title: T.string
  }

  static defaultProps = {
    title: 'Netflows'
  }

  _getDataItems = memoize((netflowsCaptured, netflowsSeen) => _.compact([
    netflowsSeen ? {
      data: netflowsSeen,
      className: 'seen',
      shape: 'step',
      formatter
    } : null,
    netflowsCaptured ? {
      data: netflowsCaptured,
      className: 'captured',
      shape: 'step',
      formatter
    } : null
  ]))

  render() {
    const {netflowsCaptured, netflowsSeen, onTimeHover, rulerTime, yAxis, title} = this.props
    return <SensorTimeline
      xAxis
      yAxis={yAxis}
      title={title}
      className="sensor_netflow_timeline"
      items={this._getDataItems(netflowsCaptured, netflowsSeen)}
      onTimeHover={onTimeHover}
      rulerTime={rulerTime}
    />
  }
}

export default NetflowGraph

import _ from 'lodash'
import React from 'react'
import T from 'prop-types'
import cx from 'classnames'
import {
  axisLeft,
  axisBottom,
  axisRight,
  axisTop
} from 'd3-axis'
import {
  TextOverflow
} from 'react-base'
import {
  TransitionGroup,
  CSSTransition
} from 'react-transition-group'

export const AXIS_SIDE_OPTIONS = {
  top: axisTop,
  right: axisRight,
  bottom: axisBottom,
  left: axisLeft
}

export const AXIS_UNITS = {
  TIME: {},
  SIZE: {}
}

export default class Axis extends React.Component {
  static propTypes = {
    scale: T.any.isRequired,
    side: T.oneOf(Object.keys(AXIS_SIDE_OPTIONS)),
    tickFormat: T.func,
    isTimeScale: T.bool,
    ticks: T.oneOfType([T.func, T.number]),
    tickValues: T.array,
    transitionName: T.string,
    tickFormatArgs: T.array
  }

  static defaultProps = {
    side: 'left',
    isTimeScale: false,
    ticks: null,
    transitionName: 'fade_down',
    tickFormatArgs: []
  }

  constructor(props) {
    super(props)
    this.state = {
      // transitionName: props.transitionNameOverride ||
    }
  }

  render() {
    const {
      scale,
      side,
      transitionName,
      tickValues,
      tickFormat,
      tickFormatArgs,
      ticks
    } = this.props

    if (!scale) {
      return null
    }

    const _scaleTicks = tickValues
      ? tickValues
      : scale.copy().nice().ticks(ticks)
    // const _ticks = _.uniq(_scaleTicks.concat(scale.domain())).sort(
    //   (a, b) => a - b
    // )
    const _ticks = _scaleTicks

    // const _tickFormat = scale.tickFormat(tickFormat)
    const isHorizontalAxis = side === 'bottom' || side === 'top'

    return (
      <TransitionGroup
        className={`axis side_${side}`}
        component="div"
      >
        {(_ticks || []).map((tick, i) => {
          const transform = isHorizontalAxis
            ? `translate(${Math.floor(scale(tick))}px, 0px)`
            : `translate(0px, ${Math.floor(scale(tick))}px)`
          const text = tickFormat ? tickFormat(tick, ...tickFormatArgs) : tick
          return (
            <CSSTransition
              classNames={transitionName}
              timeout={{
                enter: 500,
                exit: 300
              }}
              key={i}
            >
              <div
                className={cx(
                  `axis_tick tick_side_${side}`,
                  isHorizontalAxis ? 'tick_horizontal' : 'tick_vertical',
                  {
                    tick_floor: i === 0,
                    tick_ceil: i === _ticks.length - 1
                  }
                )}
                style={{
                  transform: transform,
                  WebkitTransform: transform
                }}
              >
                <TextOverflow className="axis_text">
                  {text}
                </TextOverflow>
              </div>
            </CSSTransition>
          )
        })}
      </TransitionGroup>
    )
  }
}

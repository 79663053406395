import {
  observable,
  autorun,
  computed,
  action
} from 'mobx'

import CommonViewActions from 'actions/CommonViewActions'
import { RouteStore } from 'stores/mobx/data/global/RouteStore'
import { Store } from 'stores/mobx/StoreManager'

export enum SortDir {
  Asc = 'asc',
  Desc = 'desc'
}

export enum ExplorerViews {
  Netflows = "netflows",
  Observations = "observations"
}

export interface ExplorerQueryParams {
  q?: string // Current Query
  pageSize?: number
}

// const DEFAULT_SORT_DIR = SortDir.Desc
const DEFAULT_PAGE_SIZE = 100


export class ExplorerManagementStore extends Store {
  static dependencies = [RouteStore]

  routeStore: RouteStore

  constructor(deps) {
    super(deps)
    const [routeStore] = deps
    this.routeStore = routeStore
    this.destroy = autorun(() => {
      // if (ExplorerViews.hasOwnProperty(this.activeView)) {
        // const viewConfig = this.subViews[this.activeView]
      CommonViewActions.General.setPageTitle('Explorer Duex (beta)')
    })
  }

  // @computed
  // get subViews () {
  //   return Object.entries(ExplorerViews).map(([key]) => {

  //   })
  // }

  // @computed
  // get activeView () {
  //   const {pathArr} = this.routeStore.currentRoute
  //   return !pathArr.length || pathArr[0] !== EXPLORER_DUEX_ROOT_PATH ? null : pathArr[1]
  // }

  @computed
  get listParams () : ExplorerQueryParams|null {
    const urlParams = this.routeStore.currentRoute.queryParams || {}
    return {
      q: urlParams.q,
      pageSize: +urlParams.pageSize || DEFAULT_PAGE_SIZE
    }
  }

  @action.bound
  commitQuery (uiQuery) {
    // // Switch back to inventory tab if not already there
    // const newQueryParams = {q: uiQuery}
    // if (this.routeStore.currentRoute.pathArr[1] !== 'inventory') {
    //   this.routeStore.updatePath('inventory', 1, newQueryParams)
    // }
    // else {
    //   this.routeStore.mergeParams(newQueryParams)
    // }
  }

  // @action.bound
  // changeSort (newSortField, newSortDir) {
  //   this.routeStore.mergeParams({
  //     sortBy: newSortField,
  //     sortDir: newSortDir
  //   })
  // }
}

import _ from 'lodash'
import React, { Component } from 'react'
import T from 'prop-types'
import {
  CircleLoader,
  DropdownMenu,
  ErrorState,
  MenuItem,
  ScrollBars,
  LoadingValue,
  StoreProviderHOC
} from 'react-base'
import { formatBytes, formatBytesToBits, formatNumber } from 'pw-formatters'
import PerceptiveProfileGraphData from 'components/perceptive-profile-graph/PerceptiveProfileGraphData'
import SensorRestartButton from 'components/sensor-config-form/SensorRestartButton'
import BackbufferLossGraph from 'components/sensor-graphs/BackbufferLossGraph'
import BandwidthGraph from 'components/sensor-graphs/BandwidthGraph'
import NetflowGraph from 'components/sensor-graphs/NetflowGraph'
import Tally from 'components/Tally'
import SensorPerformanceCardStore from 'stores/intelcard/SensorPerformanceCardStore'
import IntelCardActions from 'actions/IntelCardActions'
import memoize from 'memoize-one'
import { SensorGraphsDialogButton } from 'components/sensor-graphs/SensorGraphsDialog'
import Sensor2HealthGraph from 'components/sensor-graphs/Sensor2HealthGraph'
import AnalyticsActions from 'actions/AnalyticsActions'


const TIMEFRAMES = [
  {
    key: 'hour',
    label: 'Past Hour'
  },
  {
    key: 'day',
    label: 'Past Day'
  },
  {
    key: 'week',
    label: 'Past Week'
  },
  {
    key: 'month',
    label: 'Past Month'
  }
]

const formatBytesTally = v => formatBytes(v, false)
const formatBitrate = v => {
  const out = formatBytesToBits(v, false)
  //out.label += ' AVG'
  return out
}
const formatNumPerSec = v => {
  const out = formatNumber(v, false)
  out.label += '/s'
  return out
}


class SensorPerformance extends Component {
  static propTypes = {
    data: T.func, //main intel card accessor

    // From SensorPerformanceCardStore:
    perfStoreState: T.shape({
      isLoading: T.bool,
      error: T.any,
      timeframe: T.string, //month|week|day|hour
      data: T.object
    })
  }

  state = {
    rulerTime: null
  }

  UNSAFE_componentWillMount () {
    this._loadIfNeeded(this.props)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    this._loadIfNeeded(nextProps)
  }

  _loadIfNeeded = (props) => {
    const memo = this._loadMemo || (this._loadMemo = memoize((sensorId, timeframe) => {
      if (sensorId && timeframe) {
        IntelCardActions.loadSensorPerformance(sensorId, timeframe)
      }
    }))
    const sensorId = props.data('sensor.id').valueOf()
    const timeframe = _.get(props, 'perfStoreState.timeframe')
    memo(sensorId, timeframe)
  }

  _onTimeHover = rulerTime => {
    this.setState({rulerTime})
  }

  _onTimeframeChange = timeframe => {
    IntelCardActions.loadSensorPerformance(
      this.props.data('sensor.id').valueOf(),
      timeframe
    )
  }

  render () {
    const sensor = this.props.data('sensor').valueOf()
    if (!sensor) return null

    const {rulerTime} = this.state
    let {isLoading, sensorId:storeSensorId, error, timeframe, data} = this.props.perfStoreState || {}

    // Handle race condition where the sensor passed down in props is changed but
    // the perfStoreState still holds the previous sensor's data
    if (!isLoading && storeSensorId !== sensor.id) {
      isLoading = true
    }

    const {
      backbuffer,
      loss,
      truncation,
      bytesSeen,
      bytesCaptured,
      netflowsCaptured,
      netflowsSeen,
      totalHealth,
      captureHealth,
      intakeHealth,
      performanceHealth,
      processingHealth
    } = data || {}

    let bwCap = _.get(sensor, 'sensor_config.bandwidth_limit')
    if (bwCap) bwCap *= 1000000 / 8 //Mb/s --> B/s to match other time series data (this matches the math the sensor does)

    return sensor &&
      <div className="intel_card intel_data_card intel_data_sensor_perf">
        <div className="title_and_timeframe">
          <h3>Sensor Performance</h3>

          <DropdownMenu
            className="timeframe_dd"
            closeOnItemClick
            label={_.find(TIMEFRAMES, {key: timeframe}).label}
            activeMenuKeys={[timeframe]}
          >
            {TIMEFRAMES.map(({key, label}) =>
              <MenuItem
                key={key}
                args={[key]}
                onClick={this._onTimeframeChange}
              >
                {label}
              </MenuItem>
            )}
          </DropdownMenu>
        </div>

        <div className="intel_card_content">
          <ScrollBars
            classes="pw_scrollbars_light"
            outside
            slimShady
            slimShadyDarker
          >
            {_.get(sensor, 'sensor_config.adaptive_profile_id') != null
              ? <React.Fragment>
                <h4>Perceptive Capture</h4>
                <PerceptiveProfileGraphData
                  sensorId={sensor.id}
                  className="list_pane_ppc_graph"
                />
              </React.Fragment>
              : null}

            {error ? <ErrorState error={error} /> : <React.Fragment>
              {sensor.isVersion2
                ? <React.Fragment>
                  <h4>Sensor Health</h4>
                  <div className="graph_ct">
                    {isLoading
                      ? <CircleLoader loading transparent />
                      : <React.Fragment>
                        <Sensor2HealthGraph
                          xAxis
                          title=""
                          totalHealth={totalHealth.timeSeries}
                          captureHealth={captureHealth.timeSeries}
                          intakeHealth={intakeHealth.timeSeries}
                          performanceHealth={performanceHealth.timeSeries}
                          processingHealth={processingHealth.timeSeries}
                          onTimeHover={this._onTimeHover}
                          rulerTime={rulerTime}
                        />
                        { /*TODO <SensorGraphsDialogButton
                          initialSensorId={sensor.id}
                          initialTimeframe={timeframe}
                          initialGraphType="backbufferLoss"
                          usageId="intelcard"
                        />*/}
                      </React.Fragment>
                    }
                  </div>
                </React.Fragment>
                : <React.Fragment>
                <h4>Capture Health</h4>
                <div className="graph_ct">
                  {isLoading
                    ? <CircleLoader loading transparent />
                    : <React.Fragment>
                        <BackbufferLossGraph
                          backbuffer={backbuffer.timeSeries}
                          loss={loss.timeSeries}
                          truncation={truncation.timeSeries}
                          onTimeHover={this._onTimeHover}
                          rulerTime={rulerTime}
                        />
                        <SensorGraphsDialogButton
                          initialSensorId={sensor.id}
                          initialTimeframe={timeframe}
                          initialGraphType="backbufferLoss"
                          usageId="intelcard"
                        />
                      </React.Fragment>
                  }
                </div>
                <div className="tallies">
                  <Tally
                    name="All Loss"
                    value={isLoading ? <LoadingValue /> : loss.total + truncation.total}
                    formatter={formatBytesTally}
                  />
                  <Tally
                    name="Avg Loss"
                    className="rate"
                    value={isLoading ? <LoadingValue /> : loss.mean + truncation.mean}
                    formatter={formatBitrate}
                  />
                  <Tally
                    name="Avg Backbuffer"
                    value={isLoading ? <LoadingValue /> : {textData: '' + (backbuffer.mean || 0), label: '% full'}}
                  />
                </div>
              </React.Fragment>}

              <h4>Bandwidth</h4>
              <div className="graph_ct">
                {isLoading
                  ? <CircleLoader loading transparent />
                  : <React.Fragment>
                      <BandwidthGraph
                        yAxis
                        title=""
                        bytesSeen={bytesSeen.timeSeries}
                        bytesCaptured={bytesCaptured.timeSeries}
                        bandwidthCap={bwCap}
                        onTimeHover={this._onTimeHover}
                        rulerTime={rulerTime}
                      />
                      <SensorGraphsDialogButton
                        initialSensorId={sensor.id}
                        initialTimeframe={timeframe}
                        initialGraphType="bandwidth"
                        usageId="intelcard"
                      />
                    </React.Fragment>
                }
              </div>
              <div className="tallies">
                <Tally
                  name={<React.Fragment><span className="sensor_graph_swatch bandwidth_seen" /> All Seen</React.Fragment>}
                  value={isLoading ? <LoadingValue /> : bytesSeen.total}
                  formatter={formatBytesTally}
                />
                <Tally
                  name={<React.Fragment><span className="sensor_graph_swatch bandwidth_captured" /> Captured</React.Fragment>}
                  value={isLoading ? <LoadingValue /> : bytesCaptured.total}
                  formatter={formatBytesTally}
                />
                <Tally
                  name="Avg Capture"
                  className="rate"
                  value={isLoading ? <LoadingValue /> : bytesCaptured.mean}
                  formatter={formatBitrate}
                />
              </div>

              <h4>Netflows</h4>
              <div className="graph_ct">
                {isLoading
                  ? <CircleLoader loading transparent />
                  : <React.Fragment>
                      <NetflowGraph
                        yAxis
                        title=""
                        netflowsCaptured={netflowsCaptured.timeSeries}
                        netflowsSeen={netflowsSeen.timeSeries}
                        onTimeHover={this._onTimeHover}
                        rulerTime={rulerTime}
                      />
                      <SensorGraphsDialogButton
                        initialSensorId={sensor.id}
                        initialTimeframe={timeframe}
                        initialGraphType="netflow"
                        usageId="intelcard"
                      />
                    </React.Fragment>
                }
              </div>
              <div className="tallies">
                <Tally
                  name={<React.Fragment><span className="sensor_graph_swatch netflows_seen" /> All Seen</React.Fragment>}
                  value={isLoading ? <LoadingValue /> : netflowsSeen.total}
                />
                <Tally
                  name={<React.Fragment><span className="sensor_graph_swatch netflows_captured" /> Captured</React.Fragment>}
                  value={isLoading ? <LoadingValue /> : netflowsCaptured.total}
                />
                <Tally
                  name="Avg Capture"
                  className="rate"
                  value={isLoading ? <LoadingValue /> : netflowsCaptured.mean}
                  formatter={formatNumPerSec}
                />
              </div>
            </React.Fragment>
            }

            {/*<SensorStats
              isVisible
              isCapLineVisible
              requestedTimeSpan="day"
              sensorId={this.props.queryValue}
              parentId={'card'}
            />*/}
          </ScrollBars>

          {!sensor._config_applied && !isLoading &&
            <SensorRestartButton sensorId={sensor.id} />
          }
        </div>
      </div>
  }
}

export default StoreProviderHOC(
  SensorPerformance,
  SensorPerformanceCardStore,
  perfStoreState => ({perfStoreState})
)

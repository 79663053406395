import _ from 'lodash'
import T from 'prop-types'
import React from 'react'
import ReactDOM from 'react-dom'
import cx from 'classnames'
import {
  AnimateHeight,
  Checkbox,
  TextInput,
  DropdownMenu,
  Tabs,
  ConfirmMenuItem,
  MenuItem,
  ScrollBars,
  CircleLoader,
  StoreProviderHOC,
  stepFocus
} from 'react-base'
import ipaddr from 'pw-ipaddr.js'
import SensorActions from '../../actions/SensorActions'
import {
  isFullyQualifiedDomainName
} from 'pw-validators'
import BPFEditorDialog from './BPFEditorDialog'
import { ListInput, IpListInput } from 'pw-drafty'
import { UserProviderHOC } from '../UserProvider'
import SensorConfigStore from '../../stores/SensorConfigStore'
import SensorStore from '../../stores/SensorStore'
import SensorRestartButton from './SensorRestartButton'
import InfoHint from 'components/InfoHint.jsx'
import ExternalLink from 'components/ExternalLink.jsx'

const ipAddressUtil = ipaddr.util

// const RETENTION_DURATIONS = [1, 3, 6, 9, 12]
const BUFFER_LOCATIONS = {
  Memory: 'In Memory',
  Disk: 'On Disk'
}
const RELATED_MODES = {
  IpOnly: 'Either source or dest IP',
  IpPair: 'Both source and dest IP',
}

const TIME_UNITS_HINT = <p>Specify time including units, e.g. <b>60s</b> or <b>30min</b>. <ExternalLink href="https://docs.rs/humantime/2.1.0/humantime/">[details]</ExternalLink></p>
const SIZE_UNITS_HINT = <p>Specify bytes including units, e.g. <b>1GB</b> or <b>50MiB</b>. <ExternalLink href="https://docs.rs/byte-unit/4.0.9/byte_unit/">[details]</ExternalLink></p>
const AGGREGATION_RELATED_TTL_HINT = <>
  <p>When a threat is observed, the sensor will immediately identify related flows held
    in the Aggregation Buffer, and will continue to look for related flows for this duration afterward.</p>
  {TIME_UNITS_HINT}
</>
const BW_CAP_HINT = <>
  <p>Sets a per-second limit on the number of bytes uploaded to the cloud platform.</p>
  {SIZE_UNITS_HINT}
</>

function validateProxy(val) {
  return (!_.trim(val)
    || ipAddressUtil.isValidIp(val)
    || ipAddressUtil.isValidCIDR(val)
    || isFullyQualifiedDomainName(val)
  ) ? null : 'Invalid IP, CIDR, or domain name'
}

class SensorConfigForm extends React.Component {
  static displayName = 'SensorConfig'

  static propTypes = {
    sensorId: T.string,
    isVersion2: T.bool,

    // From UserProvider
    hasPermission: T.func,
    isSupportCustomer: T.bool,

    // From SensorConfigStore
    isLoading: T.bool,
    isSaving: T.bool,
    data: T.object, //This struct will vary between v1 and v2 sensors; see SensorConfigStore
    interfaces: T.array
  }

  static defaultProps = {
    data: {}
  }
  // componentDidMount() {
  //   this.setState({
  //     sensorConfig: _.clone(data)
  //   })
  // }
  constructor(props) {
    super(props)
    this.state = {
      sensorConfig: {},
      editingBpf: false,
      sensorSetFilterText: ''
    }
    SensorActions.loadSensorConfig(props.sensorId)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      isSaving,
      data = {}
    } = nextProps
    let _newSensorConfig = null

    // Only update some things if it's freshly loaded data
    if (isSaving || !this.props.data || data.revisionId !== this.props.data.revisionId) {
      _newSensorConfig = _.clone(data)
    }

    if (_newSensorConfig) {
      this.setState({
        sensorConfig: _newSensorConfig,
        editingBpf: false
      })
    }
  }

  _onFieldsCtRef(cmp) {
    // When mounting the fields, put focus in the first focusable field.
    // This is mostly to work around DraftJS's behavior where it forces focus into
    // the last editor, which is unexpected and may cause unwanted scrolling.
    const el = cmp && ReactDOM.findDOMNode(cmp)
    if (el) {
      stepFocus(el, 1)
    }
  }

  _onInputChanged = (inputName, newVal) => {
    if (_.isArray(newVal)) {
      newVal = _.compact(newVal)
    }
    let newConfig = _.assign(
      {},
      this.state.sensorConfig,
      {[inputName]: newVal}
    )
    this.setState({
      sensorConfig: newConfig
    })
  }

  _isSensorConfigDirty = () => {
    let initial = this.props.data || {}
    let current = this.state.sensorConfig || {}
    return Object.keys(current).some(
      key => !_.isEqual(current[key], initial[key])
    )
  }

  // _onRetentionChanged(newVal) {
  //   this._onInputChanged('retention_months', +newVal)
  // },

  _onSensorSetSelected = newVal => {
    this._onInputChanged('agent_set_id', newVal)
  }

  _onInterfacesChanged = newVals => {
    this._onInputChanged('interfaces', newVals)
  }

  _onInternalIpsChanged = newVals => {
    this._onInputChanged('internal_ips', newVals)
  }

  _onProxiesChanged = newVals => {
    this._onInputChanged('proxies', newVals)
  }

  _onHeadersChanged = newVals => {
    this._onInputChanged('proxy_header_list', newVals)
  }

  _onSensorModeChanged = newVal => {
    this._onInputChanged('sensor_mode', newVal)
  }

  _onOpenBpfEditor = () => {
    this.setState({ editingBpf: true })
  }

  _onBpfEditorSave = newVal => {
    this._onInputChanged('bpf', newVal)
    this.setState({ editingBpf: false })
  }

  _validateInterface = value => {
    let interfaces = this.props.interfaces
    return (!_.trim(value) || _.isEmpty(interfaces) || _.contains(interfaces, value)) ? null :
      `Not a known network interface. Possible values are: ${interfaces.join(', ')}`
  }

  _handleSaveClick = () => {
    const { data, sensorId } = this.props
    const { sensorConfig } = this.state

    const sensorConfigDiff = _.omit(
      sensorConfig,
      (value, key) => _.isEqual(value, data[key])
    )

    SensorActions.saveSensorConfig(sensorId, sensorConfigDiff)
  }

  _canWrite = () => {
    return this.props.hasPermission('sensors:update')
  }

  render() {
    let { props, state } = this
    const { sensorConfig } = this.state

    return (
      <div className={cx(
        'sensor_config_form',
        props.status === 'dirty' && 'dirty',
        props.status === 'saving' && 'saving'
      )}>
        {sensorConfig && !props.isLoading
          ? <React.Fragment>
            {props.isVersion2 || !sensorConfig.downloaded ? null : this.renderModeToggle()}
            {this.renderFields()}
            {this.renderSubmitBtn()}
          </React.Fragment>
          : <CircleLoader loading />
        }
      </div>
    )
  }

  renderModeToggle = () => {
    return <Tabs
      radio
      flex
      className="sensor_mode"
      activeTabKey={this.state.sensorConfig.sensor_mode}
    >
      <ConfirmMenuItem
        key="profiling"
        onClick={this._onSensorModeChanged}
        disabled={!this._canWrite()}
        className="profiling"
        indicatorClassName="profiling"
        confirmMessage="Are you sure you want to switch this sensor to Profiling mode? Packet data seen by this sensor will no longer be captured."
      >
        Profiling <span className="explain">Testing Only</span>
      </ConfirmMenuItem>
      <ConfirmMenuItem
        key="normal"
        onClick={this._onSensorModeChanged}
        disabled={!this._canWrite()}
        className="live"
        indicatorClassName="live"
        confirmMessage="Are you sure you want to switch this sensor to Live Capture mode? Packet data seen by this sensor will be captured and stored according to the applied capture profile and data retention policy."
      >
        Live Capture <span className="explain">Data Retention Active</span>
      </ConfirmMenuItem>
    </Tabs>
  }

  renderFields = () => {
    let props = this.props
    const { isVersion2 } = props
    const { sensorConfig } = this.state
    const canWrite = this._canWrite()
    const canWriteRuntimeFields = canWrite && (!isVersion2 || true) //TODO check manager is online for v2
    const canWriteBackbuffer = canWriteRuntimeFields && props.isSupportCustomer //backbuffer fields only editable by pw support
    const backbufferTooltip = canWrite && !canWriteBackbuffer ? 'Contact Support to modify' : null

    return (
      <ScrollBars className="sensor_config_fields pw_scrollbars_light" slimShady slimShadyDarker outside
        ref={this._onFieldsCtRef}
      >
        <section className="meta_fields">
          <div className="cell">
            <span>Name</span>
            <TextInput
              className="grid_input cell_content"
              id="sensor_name"
              readOnly={!canWrite}
              cancelOnEsc
              blurOnSave
              initialValue={sensorConfig.friendly_name}
              onSave={_.partial(this._onInputChanged, 'friendly_name')}
            />
          </div>

          <div className="cell">
            <span>Sensor Set</span>
            {this.renderSensorSetDropdown()}
          </div>
        </section>

        {sensorConfig.downloaded && <>
          <h4 className="runtime">Runtime Parameters</h4>

          {isVersion2 && canWrite && !canWriteRuntimeFields &&
            <p className="info_notice">
              <span className="icon icon-info" /> This sensor's runtime parameters can not currently be edited because its Sensor Manager is offline.
            </p>
          }

          {isVersion2 ? (
            <fieldset>
              <legend>Capture Strategy</legend>
              <div className="fieldset_body">
                <div className="cell" data-tooltip="These items are always captured.">
                  <Checkbox checked disabled />
                  Observations, Netflows, and PCAP for alerts
                </div>
                <div className="cell">
                  <Checkbox
                    disabled={!canWriteRuntimeFields}
                    checked={sensorConfig['aggregation.relation_filter.mode'] !== 'Disabled'}
                    onChange={_.partial(
                      this._onInputChanged,
                      'aggregation.relation_filter.mode',
                      sensorConfig['aggregation.relation_filter.mode'] === 'Disabled' ? 'IpOnly' : 'Disabled'
                    )}
                  />
                  Netflows and PCAP related to alerts
                  <AnimateHeight style={{paddingLeft: 26}}>
                    {sensorConfig['aggregation.relation_filter.mode'] !== 'Disabled' ? <>
                      <div className="cell">
                        <span>Correlate by:</span>
                        <div className="cell_content">
                          <DropdownMenu
                            label={RELATED_MODES[sensorConfig['aggregation.relation_filter.mode']]}
                            disabled={!canWriteRuntimeFields}
                            closeOnItemClick
                          >
                            {_.map(RELATED_MODES, (label, key) =>
                              <MenuItem
                                key={key}
                                onClick={this._onInputChanged}
                                args={['aggregation.relation_filter.mode', key]}
                              >
                                {label}
                              </MenuItem>
                            )}
                          </DropdownMenu>
                        </div>
                      </div>
                      <div className="cell">
                        <span>
                          Duration after alert
                          <InfoHint message={AGGREGATION_RELATED_TTL_HINT} />
                        </span>
                        <div className="cell_content">
                          <TextInput
                            className="grid_input cell_content"
                            id="sensor2_related_ttl"
                            readOnly={!canWriteRuntimeFields}
                            cancelOnEsc
                            blurOnSave
                            autoComplete="off"
                            initialValue={sensorConfig['aggregation.relation_filter.ttl']}
                            placeholder="(unlimited time)"
                            onSave={_.partial(this._onInputChanged, 'aggregation.relation_filter.ttl')}
                          />
                        </div>
                      </div>
                    </> : null}
                  </AnimateHeight>
                </div>
              </div>
            </fieldset>
          ) : (
            <div className="cell">
              <span>Capture Profile</span>
              <div className="cell_content">
                <a
                  href={`#sensors/capture-profiles/protocol?sensorId=${props.sensorId}`}
                  data-tooltip="Edit Capture Profile"
                  className="capture_profile_link"
                >
                  {sensorConfig.capture_profile_name || <span className="none_set">No Profile Applied</span>}
                  <span className="btn-sm icon icon-pencil" />
                </a>
              </div>
            </div>
          )}

          {isVersion2 &&
            <fieldset>
              <legend>Aggregation Buffer</legend>
              <div className="fieldset_body">
                <div className="cell">
                  <span>Packet storage</span>
                  <div className="cell_content">
                    <DropdownMenu
                      label={BUFFER_LOCATIONS[sensorConfig['aggregation.buffer.location']] || BUFFER_LOCATIONS.Memory}
                      disabled={!canWriteRuntimeFields}
                      closeOnItemClick
                    >
                      {_.map(BUFFER_LOCATIONS, (label, key) =>
                        <MenuItem
                          key={key}
                          onClick={this._onInputChanged}
                          args={['aggregation.buffer.location', key]}
                        >
                          {label}
                        </MenuItem>
                      )}
                    </DropdownMenu>
                  </div>
                </div>
                <div className="cell">
                  <span>
                    Hold packets for duration:
                    <InfoHint message={TIME_UNITS_HINT} />
                  </span>
                  <div className="cell_content">
                    <TextInput
                      className="grid_input cell_content"
                      id="sensor2_buffer_time"
                      readOnly={!canWriteRuntimeFields}
                      cancelOnEsc
                      blurOnSave
                      autoComplete="off"
                      initialValue={sensorConfig['aggregation.buffer.retain_packets']}
                      placeholder="(unlimited time)"
                      onSave={_.partial(this._onInputChanged, 'aggregation.buffer.retain_packets')}
                    />
                  </div>
                  <span>
                    Or up to size:
                    <InfoHint message={SIZE_UNITS_HINT} />
                  </span>
                  <div className="cell_content">
                    <TextInput
                      className="grid_input cell_content"
                      id="sensor2_buffer_size"
                      readOnly={!canWriteRuntimeFields}
                      cancelOnEsc
                      blurOnSave
                      autoComplete="off"
                      initialValue={sensorConfig[sensorConfig['aggregation.buffer.location'] === 'Disk' ?
                        'aggregation.buffer.disk_size' : 'aggregation.buffer.memory_size']}
                      placeholder="(unlimited bytes)"
                      onSave={_.partial(this._onInputChanged, sensorConfig['aggregation.buffer.location'] === 'Disk' ?
                        'aggregation.buffer.disk_size' : 'aggregation.buffer.memory_size')}
                    />
                  </div>
                </div>
              </div>
            </fieldset>
          }

          {/*
          <h5>Months Retention</h5>
          <Tabs radio flex activeTabKey={ this.sensorConfig.retention_months }>
            {
              RETENTION_DURATIONS.map((key) ->
                <MenuItem onClick={ this._onRetentionChanged } args={key} key={key} disabled={ !canWriteRuntimeFields }>&nbsp;{key}&nbsp;</MenuItem>
              , this)
            }
          </Tabs>
          */}

          <div className="cell">
            <span>
              Bandwidth Cap
              {isVersion2 && <InfoHint message={BW_CAP_HINT} />}
            </span>
            {isVersion2 ? (
              <TextInput
                className="grid_input cell_content"
                id="sensor_bandwidth_cap"
                readOnly={!canWriteRuntimeFields}
                cancelOnEsc
                blurOnSave
                initialValue={sensorConfig.bandwidth_cap}
                placeholder="(unlimited)"
                onSave={_.partial(this._onInputChanged, 'bandwidth_cap')}
              />
            ) : (
              <TextInput
                className="grid_input cell_content"
                id="sensor_bandwidth_limit"
                readOnly={!canWriteRuntimeFields}
                cancelOnEsc
                blurOnSave
                initialValue={sensorConfig.bandwidth_limit}
                dataType="number"
                units="Mb/s"
                placeholder="1024Mb/s"
                onSave={_.partial(this._onInputChanged, 'bandwidth_limit')}
              />
            )}
          </div>

          <div className="cell internal_ips_cell">
            <span>Internal CIDR Ranges</span>
            {this.renderListInput(
              IpListInput,
              'internal_ips',
              this._onInternalIpsChanged,
              'Add a CIDR range or IP...',
              !canWriteRuntimeFields,
              null
            )}
          </div>

          {isVersion2 ? null : <>
            <div className="cell proxies_cell">
              <span>Proxies</span>
              {this.renderListInput(
                ListInput,
                'proxies',
                this._onProxiesChanged,
                'Add an IP/CIDR/domain...',
                !canWriteRuntimeFields,
                validateProxy
              )}
            </div>
            <div className="cell header_cell">
              <span>Proxy Header List</span>
              {this.renderListInput(
                ListInput,
                'proxy_header_list',
                this._onHeadersChanged,
                'Add a header name...',
                !canWriteRuntimeFields,
                null
              )}
            </div>
          </>}

          <div className="cell capture_interface_cell">
            <span>Capture Interfaces</span>
            {this.renderListInput(
              ListInput,
              'interfaces',
              this._onInterfacesChanged,
              'Add a network interface...',
              !canWriteRuntimeFields,
              this._validateInterface,
              this.props.interfaces || null
            )}
          </div>

          <div className="cell bpf_cell">
            <span>BPF</span>
            <div
              className={cx("cell_content val", canWriteRuntimeFields || 'readonly')}
              data-tooltip={canWriteRuntimeFields ? `Click to ${sensorConfig.bpf ? 'edit' : 'add'}` : null}
              onClick={canWriteRuntimeFields ? this._onOpenBpfEditor : null}
            >
              {sensorConfig.bpf || null}
            </div>
            {this.state.editingBpf &&
              <BPFEditorDialog
                initialValue={sensorConfig.bpf || ''}
                readOnly={!canWriteRuntimeFields}
                onSave={this._onBpfEditorSave}
              />
            }
          </div>

          {isVersion2 ? null :
            <div className="grid">
              <div className="grid_cell flex_1">
                <span>Backbuffer Mem</span>
                <TextInput
                  className="grid_input cell_content"
                  id="sensor_backbuffer_mem"
                  readOnly={!canWriteBackbuffer}
                  tooltip={backbufferTooltip}
                  cancelOnEsc
                  blurOnSave
                  initialValue={sensorConfig.backbuffer_memory_size}
                  dataType="number"
                  units="MB"
                  placeholder="2048MB"
                  onSave={_.partial(this._onInputChanged, 'backbuffer_memory_size')}
                />
              </div>
              <div className="grid_cell flex_1 align_end">
                <span>Backbuffer Disk</span>
                <TextInput
                  className="grid_input cell_content"
                  id="sensor_backbuffer_disk"
                  readOnly={!canWriteBackbuffer}
                  tooltip={backbufferTooltip}
                  cancelOnEsc
                  blurOnSave
                  initialValue={sensorConfig.backbuffer_disk_size}
                  dataType="number"
                  units="MB"
                  placeholder="0MB"
                  onSave={_.partial(this._onInputChanged, 'backbuffer_disk_size')}
                />
              </div>
            </div>
          }
        </>}
      </ScrollBars>
    )
  }

  _filterSensorSets = sensorSet => {
    const filterText = this.state.sensorSetFilterText
    return (
      !filterText ||
      sensorSet.name.toLowerCase().indexOf(filterText.toLowerCase()) >= 0
    )
  }

  _onSensorSetFilterChange = value => {
    this.setState({
      sensorSetFilterText: value
    })
  }

  renderSensorSetDropdown = () => {
    const { sensorConfig } = this.state
    if (!sensorConfig) return null
    let _sensorSets = SensorStore.getSensorSets().filter(this._filterSensorSets)
    let selectedGroup = _.find(_sensorSets, {
      id: sensorConfig.agent_set_id
    })
    let menuItems = _sensorSets.map(
      sensorSet =>
        <MenuItem
          key={sensorSet.id}
          onClick={this._onSensorSetSelected}
          args={sensorSet.id}
        >
          {sensorSet.name}
        </MenuItem>,
      this
    )

    return (
      <DropdownMenu
        label={(selectedGroup && selectedGroup.name) || 'None'}
        disabled={!this._canWrite()}
        closeOnItemClick
        activeMenuKeys={[selectedGroup && selectedGroup.id]}
        filterable
        onFilterChange={this._onSensorSetFilterChange}
        scrollable
        menuClasses={`sensor_cfg_set_dropdown_menu`}
      >
        {menuItems}
      </DropdownMenu>
    )
  }

  renderListInput = (InputCmp, dataProp, onChange, placeholder, readOnly, lineValidator, options) => {
    return this.props.data ? (
      <InputCmp
        //Note: must use value from props, not current form state, due
        // to way ListInput handles initialValue
        initialValue={(this.props.data[dataProp] || []).join('\n')}
        className={cx(readOnly && 'readonly')}
        onChange={onChange}
        placeholder={readOnly ? 'None Defined' : placeholder}
        lineValidator={lineValidator}
        typeaheadOptions={options}
        readOnly={readOnly}
        monospace={false}
      />
    ) : null
  }

  renderSubmitBtn = () => {
    let props = this.props
    const { sensorConfig } = this.state

    const canWrite = this._canWrite()
    const isDirty = this._isSensorConfigDirty()

    const content = isDirty && canWrite
      ? <button
        className="btn btn-block btn-lg btn-primary fade_in_down"
        onClick={this._handleSaveClick}
      >
        Save Changes
        </button>
      : props.isSaving && canWrite
        ? <span className="btn btn-block btn-lg btn-saving loading fade_in_down">
          <svg className="loading_circle_mini">
            <circle r="10" cx="50%" cy="50%" />
          </svg>
            Saving Changes...
          </span>
        : <SensorRestartButton sensorId={+props.sensorId} />
    return content && <div className="cfg_btn_ct">{content}</div>
  }
}

export default UserProviderHOC(
  StoreProviderHOC(
    props => <SensorConfigForm {...props} key={props.sensorId} />, //key by sensorId to ensure fresh component if id changes
    SensorConfigStore, state => _.omit(state, ['sensorId'])
  )
)

import T from 'prop-types'
/**
 * Convenience wrapper for a SearchableValue that is a sensor. Handles
 * automatically building the intelcard and explorer query props.
 *
 *   <SensorValue sensorId={ id } start={ startTime } end={ endTime } />
 *
 * If the start/end props are omitted, it defaults to the past hour.
 *
 * All other props supported by SearchableValue can be included and will be passed through.
 */

import React from 'react'
import SearchableValue from 'components/values/SearchableValue'
import { VALUE_TYPE_OPTIONS } from 'constants/searchableValueConstants'
import SensorStore from 'stores/SensorStore'


const defaultDuration = 60 * 60 * 1000

class SensorValue extends React.Component {
  static displayName = 'SensorValue'

  static propTypes = {
    sensorId: T.oneOfType([T.number, T.string]),
    start: T.number,
    end: T.number,
    noIntelCard: T.bool,
    noQuery: T.bool,
    handleTextOverflow: T.bool
    // ...plus any others accepted by SearchableValue
  }

  render() {
    const {props} = this
    let {end, start, sensorId} = props

    if (!sensorId) {
      return <span />
    }

    const sensorName = SensorStore.getSensorName(sensorId) || `Sensor ${sensorId}`

    // Default timeframe
    if (!end) {
      end = start ? start + defaultDuration : Date.now()
    }
    if (!start) {
      start = end - defaultDuration
    }

    let query = props.noQuery ? null : {
      family: 'observations',
      clauses: [
        {
          name: 'occurredAt',
          op: 'between',
          from: start,
          to: end
        },
        {
          name: 'agentId',
          op: 'eq',
          value: sensorId
        }
      ]
    }

    return (
      <SearchableValue
        {...props}
        sensorId={null} //means something different in SearchableValue
        className={'sensor_value ' + (props.className || '')}
        intelCard={props.noIntelCard ? null : `sensor:${sensorId}`}
        intelCardTooltip="View Sensor Info"
        query={query}
        value={'' + sensorId}
        copyValue={sensorName}
        valueType={VALUE_TYPE_OPTIONS.SENSOR}
      >
        {props.children || sensorName}
      </SearchableValue>
    )
  }
}

export default SensorValue

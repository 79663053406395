import {
  observable,
  action,
  autorun,
  flow
} from 'mobx'
import { requestGet, requestPatch } from 'utils/restUtils'
import { Store } from 'stores/mobx/StoreManager'

export class SecuritySettingsStore extends Store {
  @observable ipSecurityList = []
  @observable isLoading = false
  @observable isSaving = false
  @observable error = null

  constructor (deps) {
    super(deps)
    this.fetchSettings() // Fetch settings once when store is initialized.
    this.destroy = () => {}
  }

  fetchSettings = flow(function * () {
    this.isLoading = true
    try {
      const settings = yield requestGet('fetch_security_settings', 'account-settings')
      this.ipSecurityList = settings.login_ip_whitelist
    } catch (error) {
      this.error = error
    } finally {
      this.isLoading = false
    }
  })

  updateIpSecurityList = flow(function * (list) {
    this.isSaving = true
    try {
      const newSettings = yield requestPatch('update_ip_security_list', 'account-settings', { login_ip_whitelist: list })
      this.ipSecurityList = newSettings.login_ip_whitelist
    } catch (error) {
      this.error = error
    } finally {
      this.isSaving = false
    }
  })
}

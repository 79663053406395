// String enum values are suitable for display
import getConfig from "utils/uiConfig";

export enum DeviceAttributeSource {
  Protectwise = "ProtectWise",
  CustomerBulk = "CustomerBulk",
  CustomerDirect = "CustomerDirect",
  ProtectwiseGeoMaxmind = "ProtectwiseGeoMaxmind",
  NarkCrowdstrike = "NarkCrowdstrike",
  NarkCarbonblackDefense = "NarkCarbonblackDefense",
  ProtectwiseAnalytics = "ProtectwiseAnalytics"
}

export function getAbbreviation (source: DeviceAttributeSource) {
  switch (source) {
    case DeviceAttributeSource.Protectwise:
      return "VZ"
    case DeviceAttributeSource.CustomerBulk:
      return "CSV"
    case DeviceAttributeSource.CustomerDirect:
      return "Direct"
    case DeviceAttributeSource.ProtectwiseGeoMaxmind:
      return "MM"
    case DeviceAttributeSource.NarkCrowdstrike:
      return "CS"
    case DeviceAttributeSource.NarkCarbonblackDefense:
      return "CB"
    case DeviceAttributeSource.ProtectwiseAnalytics:
      return "VZA"
    default:
      return ""
  }
}

export function getDisplayVal (source: DeviceAttributeSource) {
  const {mainBrand} = getConfig()
  switch (source) {
    case DeviceAttributeSource.Protectwise:
      return mainBrand
    case DeviceAttributeSource.CustomerBulk:
      return "Customer Bulk"
    case DeviceAttributeSource.CustomerDirect:
      return "Customer Direct"
    case DeviceAttributeSource.ProtectwiseGeoMaxmind:
      return `${mainBrand} Geo (Maxmind)`
    case DeviceAttributeSource.NarkCrowdstrike:
      return "Crowdstrike"
    case DeviceAttributeSource.NarkCarbonblackDefense:
      return "CarbonBlack Defense"
    case DeviceAttributeSource.ProtectwiseAnalytics:
      return `${mainBrand} Analytics`
    default:
  }
}

export function getDescription (source: DeviceAttributeSource) {
  switch (source) {
    case DeviceAttributeSource.Protectwise:
      return ""
    case DeviceAttributeSource.CustomerBulk:
      return ""
    case DeviceAttributeSource.CustomerDirect:
      return ""
    case DeviceAttributeSource.ProtectwiseGeoMaxmind:
      return ""
    case DeviceAttributeSource.NarkCrowdstrike:
      return ""
    case DeviceAttributeSource.NarkCarbonblackDefense:
      return ""
    case DeviceAttributeSource.ProtectwiseAnalytics:
      return ""
    default:
      return ""
  }
}
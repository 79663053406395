import 'mobx-react-lite/batchingForReactDom'
import './compatibility'
import './accessibility'
import {patchXHRForCSRF} from 'ui-base/src/util/csrfUtil'
import 'd3-selection-multi' //make the deprecated selection.attrs/styles/etc methods available everywhere
import { setConfig } from 'react-hot-loader' // https://github.com/gaearon/react-hot-loader/blob/master/docs/Troubleshooting.md#react-hot-loader-fatal-error-caused-by-xxx---no-instrumentation-found
import React from 'react'
import ReactDOM from 'react-dom'
import App from 'components/App'
import PwUiRouter from 'app/PwUiRouter'
import DataManager from 'data/DataManager'
import getConfig, {fetchUiConfig} from 'utils/uiConfig'
import {initGoogleAnalytics} from 'utils/analyticsUtils'
import FastClick from 'fastclick'
import {polyfill} from "mobile-drag-drop"
import {configure as configureMobx, autorun} from 'mobx'
import { RouteStore } from 'stores/mobx/data/global/RouteStore'
import { StoreProvider } from 'stores/mobx/StoreManager'
import { rootStoreManager } from 'stores/mobx/Root'
// import 'lodash-migrate' //TODO use for lodash 4 upgrade

// https://mobx.js.org/refguide/api
configureMobx({
  enforceActions: "observed"
})


setConfig({ logLevel: 'error' })

FastClick.attach(document.body, {})

// Init polyfill for HTML5 drag-drop in touch browsers
// https://github.com/timruffles/ios-html5-drag-drop-shim#polyfill-requires-dragenter-listener
polyfill({
  iterationInterval: 20 //faster than default for smoother tracking
})

patchXHRForCSRF()


// window.extend = _.assign

window.EXPLORER_ERR_MESSAGE = "Sorry, we were unable to process your request."
window.isProduction = process.env.NODE_ENV === 'production'

class VisualizerUI {
  start = () => {
    const uiConfig = getConfig()

    initGoogleAnalytics(uiConfig.apiKeys.googleAnalytics || null)

    DataManager.init()
    this.router = new PwUiRouter()

    autorun(() => {
      const routeStore = rootStoreManager.stores.get(RouteStore).current()

      this.router.subscribe(currentRoute => {
        routeStore.onRoute(currentRoute) // Push route changes to Mobx store
      })
    })

    // ensure we have a route before rendering anything
    this.router.subscribe(this.initLayout)
  }

  initLayout = () => {
    this.router.unsubscribe(this.initLayout)

    ReactDOM.render(
      <StoreProvider value={rootStoreManager}>
        <App
          routeSubscriber={ this.router.subscribe.bind(this.router) }
        />
      </StoreProvider>,
      document.getElementById('main')
    )
  }
}

window.app = new VisualizerUI()

fetchUiConfig().then(() => {
  window.app.start()
})

export default window.app

import { Set, fromJS } from 'immutable'

// Use with filter/filterNot like lodash `pick`
// https://github.com/facebook/immutable-js/wiki/Predicates#pick--omit
export const keyIn = (keys=[]) => {
  var keySet = Set.isSet(keys) ? keys : Set(keys)
  return function (v, k) {
    return keySet.has(k)
  }
}

export const convertToIdKeyedMapDeep = itemArr => {
  // Key by `item.id` and deeply convert to Immutable objects
  return fromJS(itemArr.map(item => [item.id, item]))
}

/**
 * Freeze an object and all its sub-objects recursively
 */
export function deepFreeze(obj) {
  if (obj) {
    for (let prop in obj) {
      if (obj.hasOwnProperty(prop) && obj[prop] && typeof obj[prop] === 'object') {
        deepFreeze(obj[prop])
      }
    }
  }
  return Object.freeze(obj)
}

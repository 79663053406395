import {
  observable,
  flow,
  autorun,
  computed,
  action
} from 'mobx'
import {
  Store
} from 'stores/mobx/StoreManager'
import { requestGet } from 'utils/restUtils'
import eventUtils from 'utils/eventUtils'
import { requestSearch } from 'utils/searchUtils'
import SitRepSummaryStore from '../SitRepSummaryStore'

const MAX_DISPLAYABLE = 100
const isValidTime = time => !!+time && !isNaN(+time)

export default class ResolvedPriorityEventsStore extends Store {
  static dependencies = [SitRepSummaryStore]

  @observable events = []
  @observable isLoading = false
  @observable error = null
  @observable showUnresolvedEvents = false

  constructor(deps) {
    super(deps)
    const [sitRepSummaryStore] = deps

    this.sitRepSummaryStore = sitRepSummaryStore

    this.cleanupWatcher = autorun(() => {
      // Reload any time start/end change in parent store
      this.loadEvents(sitRepSummaryStore.startTime, sitRepSummaryStore.endTime, this.showUnresolvedEvents)
    })
  }

  destroy() {
    this.cleanupWatcher()
    this.events = []
    this.isLoading = false
    this.error = null
  }

  _getBaseQuery = (startTime, endTime) => {
    return {
      search: [
        {
          name: 'observedAt',
          op: 'between',
          from: startTime,
          to: endTime
        },
        {
          name: 'threatLevel',
          op: 'in',
          value: ['high', 'medium', 'low']
        }
      ],
      options: {
        limit: 40,
        sort: { name: 'endedAt', direction: 'Desc' }, // Most recent events first
        facets: {}
      },
    }
  }

  loadEvents = flow(function* (startTime, endTime, showUnresolvedEvents) {
    if (!isValidTime(startTime) || !isValidTime(endTime)) {
      return
    }

    this.events = []
    this.isLoading = true
    this.error = null

    const query = this._getBaseQuery(startTime, endTime)

    const newClauses = [
      {
        name: 'workflow.priority',
        op: 'eq',
        value: 'High'
      }
    ]

    if (!showUnresolvedEvents) {
      newClauses.push({
        name: 'workflow.status',
        op: 'eq',
        value: 'Resolved'
      })
    }

    query.search = query.search.concat(newClauses)

    try {
      const resp = yield requestSearch('sitrep_summary_resolved_priority_evts', 'events', query)
      const withNotes = yield Promise.all(
        resp.results.map(event => requestGet(`sitrep_evt_notes_${event.id}`, `events/${event.id}`, { useSocket: true }))
      )

      this.events = withNotes
        .filter(event => event.notes && event.notes.length > 0)
        .map(event => eventUtils.formatSingle(event))
    }
    catch (error) {
      this.error = error
    }

    this.isLoading = false
  })

  @action.bound
  toggleShowUnresolvedEvents() {
    this.showUnresolvedEvents = !this.showUnresolvedEvents
  }

  @computed
  get hasEvents() {
    return this.events.length > 0
  }
}

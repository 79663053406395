import _ from 'lodash'
import T from 'prop-types'
import React from 'react'
import { pluralize } from 'pw-formatters'
import CommonViewActions from 'actions/CommonViewActions'
import SupportActions from 'actions/SupportActions'
import SupportNotification from './SupportNotification'
import {ErrorBoundary} from 'react-base'
import { SUPPORT_NOTIFICATION_SHAPE } from 'constants/supportConstants'
import { NOTIFICATION_SHAPE } from 'constants/notificationConstants'
import { NotificationsList } from './NotificationsList'

export default class NotificationPane extends React.Component {
  static displayName = 'NotificationPane'

  static propTypes = {
    // From provided SupportStore
    activeNotifications: T.arrayOf(SUPPORT_NOTIFICATION_SHAPE),
    activeNotificationsError: T.any,
    activeNotificationsLoading: T.bool,
    allActiveNotifications: T.arrayOf(SUPPORT_NOTIFICATION_SHAPE),

    forceAllActiveSystemNotifications: T.bool,

    // From NotificationsStore
    notifications: T.arrayOf(NOTIFICATION_SHAPE),
    isSupportCustomer: T.bool
  }

  UNSAFE_componentWillMount() {
    SupportActions.loadActiveNotifications()
  }

  clearAllNotifications = () => {
    CommonViewActions.Notification.dismissAll()
  }

  render() {
    const {
      activeNotificationsLoading,
      activeNotifications,
      allActiveNotifications,
      notifications,
      forceAllActiveSystemNotifications,
      isSupportCustomer
    } = this.props

    const systemNotifications = forceAllActiveSystemNotifications
      ? allActiveNotifications
      : activeNotifications
    const numSupportNotifications = systemNotifications
      ? systemNotifications.length
      : 0
    const numNotifications = notifications ? notifications.length : 0
    const hasNotifications = notifications && numNotifications > 0

    return (
      <ErrorBoundary>
        <div className="notification_pane">
          {numSupportNotifications > 0 ? (
            <SupportNotification
              activeNotificationsLoading={activeNotificationsLoading}
              notifications={systemNotifications}
              preventDismiss={forceAllActiveSystemNotifications}
            />
          ) : null}
          {hasNotifications ? (
            <div
              className={`notification_pane_header ${hasNotifications > 0
                ? 'active'
                : ''}`}
            >
              <span
                className="btn btn-link btn-mini pull-right"
                onClick={this.clearAllNotifications}
              >
                Clear all
              </span>
              <h5>
                {numNotifications || 0}{' '}
                {pluralize(numNotifications, 'notification')}
              </h5>
            </div>
          ) : null}
          <NotificationsList isSupportCustomer={isSupportCustomer} />
        </div>
      </ErrorBoundary>
    )
  }
}

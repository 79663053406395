/**
 * Convenience wrapper for a SearchableValue that is a single Domain. Handles
 * automatically building the intelcard and explorer query props.
 *
 *   <DomainValue url={ myUrl } start={ startTime } end={ endTime } />
 *
 * If the start/end props are omitted, it defaults to the past hour.
 *
 * All other props supported by SearchableValue can be included and will be passed through.
 */

import React from 'react'
import T from 'prop-types'
import SearchableValue from 'components/values/SearchableValue'
import ValueActions from 'actions/ValueActions'
import { VALUE_TYPE_OPTIONS } from 'constants/searchableValueConstants'

const defaultDuration = 60 * 60 * 1000

class DomainValue extends React.Component {
  static displayName = 'DomainValue'

  static propTypes = {
    className: T.string,
    domain: T.string,
    end: T.number,
    noIntelCard: T.bool,
    noQuery: T.bool,
    start: T.number
    // ...plus any others accepted by SearchableValue
  }

  render() {
    let { className, end, noIntelCard, noQuery, start, domain } = this.props

    if (!domain) {
      return <span />
    }

    // Default timeframe
    if (!end) {
      end = start ? start + defaultDuration : Date.now()
    }
    if (!start) {
      start = end - defaultDuration
    }

    // Parse the Domain into its constituent parts and add query clauses for each
    let obsClauses = [
      {
        name: 'occurredAt',
        op: 'between',
        from: start,
        to: end
      }
    ]
    let urlObj
    try {
      urlObj = new URL('http://' + domain)
      if (urlObj.host)
        obsClauses.push({ name: 'observationInfo.domains', op: 'eq', value: urlObj.host })
    } catch (e) {
      console.warn(`DomainValue: could not parse Domain ${domain}`)
      // Couldn't parse the Domain; just render a plain span
      return (
        <span>
          {domain}
        </span>
      )
    }

    let query = noQuery
      ? null
      : {
          family: 'observations',
          clauses: obsClauses
        }

    return (
      <SearchableValue
        {...this.props}
        className={'domain_value ' + (className || '')}
        intelCard={noIntelCard ? null : `domain:${urlObj.host}`}
        intelCardTooltip={`View Domain Info`}
        query={query}
        value={domain}
        copyValue={domain}
        valueType={VALUE_TYPE_OPTIONS.DOMAIN}
      >
        {domain}
      </SearchableValue>
    )
  }
}

export default DomainValue

import _ from 'lodash'
import Reflux from 'reflux'
import CommonViewActions from 'actions/CommonViewActions'
import AgreementActions from 'actions/AgreementActions'
import {requestGet, requestPost, requestPut} from 'utils/restUtils'
import getConfig from 'utils/uiConfig'
import {customerAgreementVersion} from 'version'


const OFFLINE_VERSION_STRING = 'CUSTOM'


let _state = {
  isLoading: false,
  isSaving: false,
  error: null,
  activeAgreement: null,
  revokedAgreements: null
}

function confirmSave(message) {
  CommonViewActions.Notification.add({
    type: 'success',
    heading: 'Saved',
    message: message,
    dismissTimer: 5000
  })
}

export default Reflux.createStore({
  listenables: AgreementActions,

  offlineVersionString: OFFLINE_VERSION_STRING,

  init() {
  },

  getInitialState() {
    return _state
  },

  _notify() {
    this.trigger(_state)
  },


  /* No-op here; handled in AuthStore
  onAcceptCustomerAgreement() {
  },
  */

  onLoadAgreements() {
    _state.isLoading = true
    this._notify()
    this._doLoad()
  },

  _doLoad() {
    requestGet('agreements', 'agreements?all=true')
      .then(data => {
        _state = {
          isLoading: false,
          isSaving: false,
          activeAgreement: data && _.find(data, a => !a.revoked),
          revokedAgreements: data && _.filter(data, a => a.revoked)
        }
        this._notify()
      })
      .catch(restError => {
        _state = {
          isLoading: false,
          isSaving: false,
          error: restError
        }
        this._notify()
      })
  },

  onRevokeAgreement() {
    let agreement = _state.activeAgreement
    if (agreement) {
      _state.isSaving = true
      this._notify()
      requestPut(null, `agreements/${ agreement.id }`, _.defaults({revoked: true}, agreement))
        .then(() => {
          confirmSave('Successfully updated agreement status. The customer will be prompted to accept the Customer Agreement upon their next login.')
          this._doLoad()
        })
        .catch(this._handleWriteFailure.bind(this))
    }
  },

  onSetOfflineAgreement(comment) {
    let agreement = _state.activeAgreement
    let createOfflineAgreement = () => {
      requestPost(null, 'agreements', {
        comment: comment || null,
        version: OFFLINE_VERSION_STRING
      })
        .then(() => {
          confirmSave('Successfully set agreement method to Offline.')
          AgreementActions.loadAgreements()
        })
        .catch(this._handleWriteFailure.bind(this))
    }
    _state.isSaving = true
    this._notify()

    // No active agreement: just create one
    if (!agreement) {
      createOfflineAgreement()
    }
    // Active offline agreement: just update the comment
    else if (agreement.version === OFFLINE_VERSION_STRING) {
      requestPut(null, `agreements/${ agreement.id }`, _.assign({}, agreement, {comment: comment || null}))
        .then(() => {
          confirmSave('Successfully updated the agreement comments.')
          this._doLoad()
        })
        .catch(this._handleWriteFailure.bind(this))
    }
    // Active online agreement: we must revoke it first and then create the new one
    else {
      requestPut(null, `agreements/${ agreement.id }`, _.defaults({revoked: true}, agreement))
        .then(createOfflineAgreement)
        .catch(this._handleWriteFailure.bind(this))
    }
  },

  _handleWriteFailure(restError) {
    CommonViewActions.Notification.add({
      type: 'error',
      heading: 'Error',
      message: restError.message
    })
    this._doLoad()
  }

})


export function getAgreementDocUrl() {
  let url = getConfig('urls.customerAgreement')
  if (url) {
    url = url.replace(/\[VERSION]/g, customerAgreementVersion)
  }
  return url
}

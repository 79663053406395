import React, { FunctionComponent } from 'react'
import { observer } from 'mobx-react'
import { NotificationStore } from 'stores/notifications/NotificationStore'
import { useStore } from 'stores/mobx/StoreManager'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { NotificationCard } from './NotificationCard'

export interface NotificationsListProps {
  isSupportCustomer: boolean
}

export const NotificationsList: FunctionComponent<NotificationsListProps> = observer(({ isSupportCustomer }) => {
  const notificationStore: NotificationStore = useStore(NotificationStore)

  const { notifications, dismiss } = notificationStore
  const effect = { enter: 500, exit: 300 }

  return (
    <TransitionGroup className="notification_list">
      {
        notifications.map(nfx => {
          return (
            <CSSTransition classNames="notification" timeout={effect} key={nfx.id} >
              <NotificationCard
                notification={nfx}
                renderInternalLink={isSupportCustomer}
                onDismiss={dismiss}
              />
            </CSSTransition>
          )
        })
      }
    </TransitionGroup>
  )
})


import React, { FunctionComponent } from 'react'
import * as _ from 'lodash'
import { observer } from 'mobx-react'
import { TextOverflow } from 'react-base'
import { convertCoordsToArcMinutes } from 'utils/geoDataUtil'

export interface LatLonValueProps {
  lat?: number // Latitude
  lon?: number // Longitude
}

export const LatLonValue: FunctionComponent<LatLonValueProps> = observer(props => {
  return (
    <TextOverflow className={`arc_minutes_value`}>
      {props.lat && convertCoordsToArcMinutes(props.lat, true)}
      {props.lat && props.lon && ', '}
      {props.lon && convertCoordsToArcMinutes(props.lon, false)}
    </TextOverflow>
  )
})

LatLonValue.displayName = "ArcMinutesValue"
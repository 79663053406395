import React from 'react'
import T from 'prop-types'
import { observer } from 'mobx-react'
import getConfig from 'utils/uiConfig'
import UserStore from 'stores/UserStore'
import ExternalLink from 'components/ExternalLink'
import {getTraceUrl} from 'utils/restUtils'

export const DownloadError = observer(props => {
  const {traceId, heading, body} = props.error
  const isSupport = UserStore.isSupportCustomer()
  const link = isSupport
    ? getTraceUrl(traceId)
    : false

  return (
    <div
      className={`dl_err`}
    >
      <div className={`dl_err_heading`}>{heading}</div>
      <div className={`dl_err_body`}>{body}</div>
      {traceId &&
        <div className="trace_id_wrap">
          Request ID: {link ?
            <ExternalLink className="trace_id_link" href={link}>
              {traceId}
            </ExternalLink>
            : `${traceId}`
          }
        </div>
        }
    </div>
  )
})

// Subset of full RestError
export const ErrorShape = T.shape({
    // type: T.string,
    heading: T.string,
    body: T.string,
    // message: T.string,
    // additional: T.arrayOf(T.string),
    // isAbort: T.bool,
    traceId: T.string,
    // statusCode: T.number,
  })

DownloadError.propTypes = {
  error: ErrorShape.isRequired
}

DownloadError.displayName = "DownloadError"

export const DESC_METADATA = {
  "THREAT_MAPPING": `Fields used by Verizon to classify and utilize indicators.`,
  "SEVERITY": `Damage that the detected activity may inflict if successful.`,
  "CONFIDENCE":`Confidence that a given detection is a true positive.`,
  "KILLCHAIN_STAGE": `Stages where malicious activity can be detected.`,
  "CATEGORY": `...`, // TODO
}

export const DESC_KILLCHAIN = {
  "RECON": `Searching for vulnerabilities on a target network or system. eg, scanning for open ports, applications, or online systems.`,
  "DELIVERY": `Mechanism by which an exploit can reach its target. eg, phishing emails and websites, exploit kit landing pages, etc.`,
  "EXPLOIT": `Detection of the actual exploit payload. eg, malicious JavaScript, Trojans, etc.`,
  "BEACON": `Malware communication back to the control server.`,
  "CNC": `Communication from the control server to the infected machine.`,
  "FORTIFICATION": `Lateral movement and other activity related to gaining access to additional systems in the network.`,
  "DATATHEFT": `Loss of data, whether exfiltrated from the network or leaked through phishing.`,
}

export const DESC_CATEGORY = {
  "EXPLOITSANDATTACKS": `Exploitation of vulnerabilities or other attack techniques intended to disrupt or compromise the targeted system.`,
  "DENIALOFSERVICE": `Attempt to prevent legitimate use of a network resource or service.`,
  "MALWARE": `Malicious program that attempts to disrupt operations, gather sensitive information, utilize resources, or otherwise cause harm to the targeted system.`,
  "APT": `Advanced Persistent Threats are dedicated groups of malicious actors that target specific entities, such as private organizations or government for business or political motives.`,
  "SCANNING": `Network reconnaissance of hosts and services, often performed to identify targets for potential attacks.`,
  "PHISHING": `Fraudulent messages intended to bait a victim into providing sensitive information or infecting themselves with malware.`,
  "BOTNET": `A set of infected hosts controlled from a single source.`,
  "MALICIOUSHOST": `Infrastructure known to be associated with malicious activity.`,
  "SUSPICIOUS": `Activity that may be malicious or risky.`,
  "MISC": `Known activity that does not fit within the above categories.`,
  "UNKNOWN": `Unknown activity that cannot be categorized.`,
}

export const PERCEPTIVE_PROFILE_DESC = `The Perceptive Capture Profile will dynamically adjust capture policy based on available bandwidth.`

export const FILE_FLAGS_TO_BIT = {
  'Mismatched': '2',
  'Truncated': '3',
  'Archived': '0',
  'Encrypted': '1'
}

export const DEVICE_DB_VISION = `Asset context is a crucial component for translating the countless number of threats that an organization sees to the risk that these threats represent to the business. Additionally, DHCP configured devices are constantly changing IP addresses within customer networks. These changing IPs make it more difficult to correlate security incidents over time and make it more difficult to triage/investigate incidents. Through integrations and passive monitoring, Verizon developed a unique solution to solve these issues - the Network Detection and Response device database actively tracks devices activity and polls integrations to maintain an updated asset inventory. This information is fused with detections and can be used to help explore our dataset. Because of the unique value of this aggregated information, the device database has been exposed for query capability, allowing analysts to find out everything that the Network Detection and Response platform knows about an IP address.`

export const DEVICE_DB_SOURCES = {
  DHCP: {
    title: 'DHCP',
    desc: `Verizon Network Detection and Response performs passive DHCP monitoring, which is the most reliable method for keeping dynamic devices to IP mappings up to date. In order to utilize this method, the sensor must have visibility into DHCP traffic. Because IP address overlap can occur in enterprise networks, deviceID -> IP mappings are stored on a per sensor basis (i.e. the sensor that saw the DHCP request, will be the only sensor that has record of the device/only network traffic captured by that sensor will be fused with the device context information).`,
  },
  ReverseDNS: {
    title: 'Reverse DNS',
    desc: `Reverse DNS actively polls the configured DNS server for IP to hostname resolutions and creates or updates a device record when a single response is given. The refresh period on ReverseDNS is 24 hours.`,
  },
  VerizonAnalytics: {
    title: 'Verizon Analytics',
    desc: `
      Consistent/Static IP Analysis:
      If an IP address shows consistent period over an extended period of time, Verizon identifies this as a static (or consistent) device. When identified as a consistent device, it will receive a Verizon deviceID, which brings it in scope for Verizon anomaly detection monitoring. It also becomes a candidate for passive role identification, which attempts to assign asset roles based on the type of communication recorded for that device.

      Passive DNS Analysis:
      While reverse DNS configuration is still recommended, passive DNS device identification can be a good extension to existing device classifiers. It passively monitors internal DNS requests over 30 days and generates device IDS (with hostnames) when a 1-1 IP to hostname mapping is seen for a given sensor. This is re-evaluated daily and if the 1-1 IP to hostname mapping no longer exists, the device will be removed from the device database as Verizon can no longer confidently differentiate traffic for the specific device. If an IP change did occur for the device, and the 1-1 mapping remains consistent for long enough, the device will be added back to the deviceDB.

      MAC Address Analysis
      Similar to passive DNS identification, when a 1-1 mapping exists between a MAC address and an IP address, Verizon can confidently differentiate traffic for a particular device. If this 1-1 mapping persists for long enough, a deviceID is generated. This 1-1 mapping is re-evaluated daily and the device is removed if Verizon starts seeing multiple IP addresses associated with a single MAC. Success of this monitoring method will depend on the tap/span location as switches and routers can re-write MAC addresses. If switches or routers are re-writing MAC addresses, it will appear as if a single MAC address has multiple IP addresses and they will be left out of the device database.
    `
  },
  CarbonBlack: {
    title: 'Carbon Black',
    desc: `Carbon Black Defense integration (requires admin configuration) polls the endpoint solution’s API to get updated host and IP information about a device. Because of API rate limits, new polls are only done every 24 hours. If a device is not on the network for more than 24 hours, a refresh will occur when the device returns to the network. Endpoint solutions can cover different devices with the same IP addresses (different subnets). If multiple hostnames are returned by the endpoint solution for the same IP address, the Network Detection and Response device database will NOT be updated with this information - as there is no way for Verizon to differentiate between the devices at the network level.`,
  },
  CrowdStrike: {
    title: 'CrowdStrike',
    desc: `CrowdStrike integration (requires admin configuration) polls the endpoint solution’s API to get updated host and IP information about a device. Because of API rate limits, new polls are only done every 24 hours. If a device is not on the network for more than 24 hours, a refresh will occur when the device returns to the network. Endpoint solutions can cover different devices with the same IP addresses (different subnets). If multiple hostnames are returned by the endpoint solution for the same IP address, the Network Detection and Response device database will NOT be updated with this information - as there is no way for Verizon to differentiate between the devices at the network level.`
  }
}
/**
 * Utility for interfacing with the browser history and URL bar
 */

import _ from 'lodash'
import util from 'ui-base/src/util/genericUtil'
import querystringUtil from 'ui-base/src/util/querystringUtil'
import genericUtil from 'ui-base/src/util/genericUtil'



const valueIsValid = (value) => {
  return !!value && (typeof value === 'object' ? !_.isEmpty(value) : true)
}

const parseUrl = () => {
  //Set up for BB style hash routes eg "/#killbox?test=123"
  let hash = window.location.hash
  let qIndex = hash.indexOf('?')
  let hashPath = qIndex === -1 ? hash.substr(1) : hash.substr(1, qIndex - 1)
  let pathArr = hashPath.split('/')
  return (qIndex === -1) ? {
    hashPath: hashPath,
    pathArr: pathArr,
    queryStr: ''
  } : {
    hashPath: hashPath,
    pathArr: pathArr,
    queryStr: hash.substr(qIndex)
  }
}


const setQueryParams = (newParams, replaceState = true, hashPathOverride) => {
  let { hashPath } = parseUrl()
  if (hashPathOverride) {
    hashPath = hashPathOverride
  }
  newParams = util.compactObject(newParams) //Strip out any keys with falsey values
  let newQueryStr = querystringUtil.stringify(newParams)
  let newRoute = newQueryStr ? `#${hashPath}?${newQueryStr}` : `#${hashPath}` //generate a full routeString(URL fragment)

  if (newRoute !== location.hash) {
    if (replaceState) {
      // if replaceState is an object, pass it as the state object
      history.replaceState(_.isObject(replaceState) ? _.assign({}, history.state, replaceState) : history.state, null, newRoute)
      // replaceState won't trigger a hashchange event, so fire a fake one so the router can respond
      let e = document.createEvent('Event')
      e.initEvent('hashchange', false, true)
      window.dispatchEvent(e)
    } else {
      location.hash = newRoute
    }
  }

  return newParams
}

const getQueryParams = () => {
  return querystringUtil.parse(parseUrl().queryStr)
}

const addParam = (key, value) => {
  let newObj = {
    [key]: value
  }
  return _.assign(getQueryParams(), newObj)
}

const removeParam = (key) => {
  return _.omit(getQueryParams(), key)
}

const updateParam = (key, value) => {
  let current = valueIsValid(value) ? addParam(key, value) : removeParam(key)
  setQueryParams(current)
}

// Update a query param whose value should be treated as an array
const updateParamArray = (paramName, newValue) => {
  let current = getQueryParams()
  let currentValueArr = util.parseAsArray(current[paramName])

  if (currentValueArr.indexOf(newValue) === -1) {
    currentValueArr.push(newValue) //Add selection
  } else {
    _.pull(currentValueArr, newValue) //Remove selection
  }

  let newParamValue = _.compact(currentValueArr)
  return updateParam(paramName, newParamValue)
}

const getParamsWithRequired = (requiredParams) => {
  if (!requiredParams) {
    requiredParams = {}
    console.error('urlHistoryUtil.getParamsWithDefaults: no defaults were passed')
  }
  let currentWithRequired = _.defaults(getQueryParams(), requiredParams)
  return setQueryParams(currentWithRequired)
}

const mergeParams = (newParams, replaceState = true, removeEmpty = false) => {
  let params = _.assign(getQueryParams(), newParams)
  // Remove params for values with a length property of zero (arrays or strings),
  // but allow other falsey values like `0` and `false` to remain
  if (removeEmpty) {
    params = _.omit(params, val => {
      return val && !_.isUndefined(val.length) ? val.length === 0 : false
    })
  }
  return setQueryParams(params, replaceState)
}

const removeParams = (paramKeysToRemove, replaceState = true) => {
  return setQueryParams(_.omit(getQueryParams(), paramKeysToRemove), replaceState)
}

const resetToDefault = (paramsToRetain) => {
  if (!_.isArray(paramsToRetain)) {
    console.error("urlHistoryUtil._resetToDefault: argument must be an array of keys to retain")
  }
  return setQueryParams(_.pick(getQueryParams(), paramsToRetain))
}

const updatePath = (newValue, pathIdx = 0, replaceState = false, newQueryParams) => {
  let {pathArr} = parseUrl()
  pathArr.length = pathIdx //truncate
  pathArr.push(newValue)
  return setQueryParams(newQueryParams ? newQueryParams : getQueryParams(), replaceState, pathArr.join('/'))
}

export default {
  parseUrl,
  getParamsWithRequired,
  getQueryParams,
  updateParam,
  updateParamArray,
  mergeParams,
  removeParams,
  resetToDefault,
  setQueryParams,
  updatePath // Update hash path
}

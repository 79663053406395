import _ from 'lodash'
import Reflux from 'reflux'
import {PropTypes as T, checkPropTypes} from 'prop-types'
import {addGlobalRequestHandler} from 'utils/restUtils'
import {INTERNAL_DOMAIN_REGEX} from 'pwConstants'

const AnalyticsActions = Reflux.createActions([
  // See proptype blocks below for their object signatures...
  'pageView',
  'event',
  'timing',
  'exception',
  'setCustomerId',
  'setBetaOptIn',
  'setUserId',
  'setIsPWUser'
])

// The ids of the custom dimensions set in the GA admin
const CUST_ID_DIMENSION = 'dimension1'
const BETA_OPT_IN_DIMENSION = 'dimension2'

let _vars = {}

AnalyticsActions.pageView.preEmit = (function() {
  let doSend = _createHandler('pageview', {
    page: T.string.isRequired,
    // NOTE: ga also accepts 'location' for the full URL but that's never relevant in this app
    title: T.string,
    // sessionControl: A value of 'start' forces a new session to start with this hit and 'end' forces the current session to end with this hit 
    sessionControl: T.oneOf(['start', 'end'])
  })
  return function(fieldsObj) {
    // For pageviews we must also set the page in the local tracking state, or events
    // won't roll up to the correct page context.
    _setVar('page', fieldsObj.page)
    doSend(fieldsObj)
  }
})()

AnalyticsActions.event.preEmit = _createHandler('event', {
  eventCategory: T.string.isRequired,
  eventAction: T.string.isRequired,
  eventLabel: T.string,
  eventValue: T.number,
  nonInteraction: T.bool
})

AnalyticsActions.timing.preEmit = _createHandler('timing', {
  timingCategory: T.string.isRequired,
  timingVar: T.string.isRequired,
  timingValue: T.number.isRequired,
  timingLabel: T.string
})

AnalyticsActions.exception.preEmit = _createHandler('exception', {
  exDescription: T.string.isRequired, //not required by GA but why not?
  exFatal: T.bool
})

AnalyticsActions.setCustomerId.preEmit = _.partial(_setVar, CUST_ID_DIMENSION)
AnalyticsActions.setBetaOptIn.preEmit = _.partial(_setVar, BETA_OPT_IN_DIMENSION)
AnalyticsActions.setUserId.preEmit = _.partial(_setVar, 'userId')

let _isPWUser = false
AnalyticsActions.setIsPWUser.preEmit = (isPWUser) => {_isPWUser = isPWUser}




function _createHandler(hitType, shape) {
  return function(fieldsObj) {
    // Validate against the defined shape
    if (_.isObject(fieldsObj)) {
      checkPropTypes(shape, fieldsObj, 'prop', `AnalyticsActions.${hitType}`)
    } else {
      console.error(`Analytics ${ hitType } passed a non-object fieldsObj:`, fieldsObj)
    }
    if (fieldsObj.nonInteractive) {
      console.error('misspelled nonInteraction')
    }

    // Fill out the object
    fieldsObj = _.assign({
      hitType: hitType,
      [CUST_ID_DIMENSION]: _vars[CUST_ID_DIMENSION], //always send custom customer id dimension (hit-level)
      [BETA_OPT_IN_DIMENSION]: _vars[BETA_OPT_IN_DIMENSION] //always send custom beta opt-in dimension (hit-level)
    }, fieldsObj)

    // Send and log
    const isInternal = INTERNAL_DOMAIN_REGEX.test(location.hostname)
    const ga = window.ga
    if (ga && !isInternal && !_isPWUser) {
      ga('send', fieldsObj)
    }

    let message = `Analytics ${ hitType }`
    if (isInternal) {
      message += ' (not sent for PW internal domain)'
    } else if (_isPWUser) {
      message += ' (not sent for PW user)'
    } else if (!ga) {
      message += ' (not sent, ga lib not loaded)'
    }
    console.info(message, JSON.stringify(fieldsObj))
  }
}

function _setVar(name, value) {
  const ga = window.ga
  if (ga) {
    ga('set', name, value)
  }
  _vars[name] = value
  console.info(`Analytics variable set: ${ name } = ${ value }`)
}


/**
 * Inject global handlers into restUtils, to handle tracking from every request.
 * The restUtils request functions will now accept two new `options`:
 * - {Boolean} trackErrors - whether errors should be sent to analytics. Defaults to `true`.
 * - {Object<{timingCategory, timingVar, timingLabel}>} trackTiming - if specified, will
 *   track the duration of the request and send a timing analytics event with those params
 */
addGlobalRequestHandler((promise, url, requestInit, options) => {
  // Send timing to analytics
  if (options.trackTiming) {
    const startTime = Date.now()
    promise.then(() => { //out-of-band
      AnalyticsActions.timing(_.assign({timingValue: Date.now() - startTime}, options.trackTiming))
    }).catch(() => {})
  }

  // Send exception event to analytics
  if (options.trackErrors !== false) {
    promise.catch(restError => { //out-of-band
      if (restError.response.status >= 400) {
        AnalyticsActions.exception({exDescription: `${ requestInit.method } ${ url }: ${ restError.response.status }`})
      }
    })
  }

  // Return original promise
  return promise
})


export default AnalyticsActions

import color from 'color'

// UI Theme utility functions

const getNewLightness = darkness => Math.round(100 - darkness)

// https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
const componentToHex = c => {
  var hex = Math.round(c).toString(16)
  return hex.length === 1 ? "0" + hex : hex
}

const rgbToHex = ([r, g, b]) => {
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b)
}

export const getThemeColor = darkness => {
  const newLightnessVar = getNewLightness(darkness)
  return [
    `hsl(`,
    `${getComputedStyle(document.body).getPropertyValue("--baseHue").trim()}, `,
    `${getComputedStyle(document.body).getPropertyValue("--baseSaturation").trim()}%, `,
    `${getComputedStyle(document.body).getPropertyValue("--lightness-" + newLightnessVar).trim()}%`,
    `)`
  ].join('')
}

export const getThemeColorHex = darkness => {
  const themeColor = getThemeColor(darkness)
  const rgb = color(themeColor).rgb().array()
  const hex = rgbToHex(rgb)

  return hex
}
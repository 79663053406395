import T from 'prop-types'
import React from 'react'


export const NOTIFICATION_TYPE_OPTIONS = [
  'info',
  'success',
  'warning',
  'error'
]

export const NOTIFICATION_SHAPE = T.shape({
  id: T.string.isRequired,
  type: T.oneOf(NOTIFICATION_TYPE_OPTIONS).isRequired,
  heading: T.string.isRequired,
  message: T.string,
  messageComponent: T.object, //Pass in a react component as the message
  dismissTimer: T.number,
  messageDetails: T.oneOfType([T.string, T.array]),
  traceId: T.string, // Optional traceID
  testId: T.string // Optional string testId to be rendered with the Notification markup in the `data-testid` attribute
})
import {
  observable,
  computed,
  action,
  flow,
  autorun
} from 'mobx'
import _ from 'lodash'
import {
  requestGet,
  requestPost
} from 'utils/restUtils'
import { Store } from 'stores/mobx/StoreManager'
import { hasPermission } from 'stores/UserStore'
import CommonViewActions from 'actions/CommonViewActions'

const dismissTimer = 3000

export default class CustomerStore extends Store {
  @observable customers = []
  @observable isLoading = false
  @observable error = null

  @observable savingCustomerIds = new Set()
  @observable isCreatingCustomer = false

  @observable canEditCustomers = false
  @observable canCreateCustomers = false

  constructor(deps) {
    super(deps)
    this.destroy = autorun(() => this.load())
  }

  fetch = () => {
    return requestGet(
      'get_customers',
      `customers?includeDisabled=true`,
      {
        baseURL: 'api/v1/'
      }
    )
  }

  load = flow(function * _load () {
    this.totalCount = null
    this.isLoading = true

    this.canEditCustomers = hasPermission('customers:update')
    this.canCreateCustomers = hasPermission('customers:create')

    let customers
    try {
      customers = yield this.fetch()
    }
    catch (restError) {
      this.error = restError
    }
    if (customers) {
      this.customers = customers
    }
    this.isLoading = false
  })

  doCreateCustomer = flow(function * (newCustomerName) {
    if (!this.canCreateCustomers) return
    this.isCreatingCustomer = true
    let resp
    try {
      resp = yield requestPost('create_cust', 'customers', {
        name: newCustomerName
      })
    }
    catch (restError) {
      CommonViewActions.Notification.addRestError(restError)
    }
    if (resp) {
      this.load()
      this.handleSuccess('Customer Created', `New customer "${newCustomerName}" created with ID ${resp.id}.`)
    }
    this.isCreatingCustomer = false
  })

  doEnableCustomer = flow(function * (id) {
    if (!this.canEditCustomers) return
    this.savingCustomerIds.add(id)
    let resp
    try {
      resp = yield requestPost(`cust_${id}_enable`, `customers/${id}/enable`)
    }
    catch (restError) {
      CommonViewActions.Notification.addRestError(restError)
    }
    if (resp) {
      this.handleSuccess('Customer Enabled', `Customer "${resp.name}" enabled.`, resp)
    }
    this.savingCustomerIds.delete(id)
  })

  doDisableCustomer = flow(function * (id) {
    if (!this.canEditCustomers) return
    this.savingCustomerIds.add(id)
    let resp
    try {
      resp = yield requestPost(`cust_${id}_disable`, `customers/${id}/disable`)
    }
    catch (restError) {
      CommonViewActions.Notification.addRestError(restError)
    }
    if (resp) {
      this.handleSuccess('Customer Disabled', `Customer "${resp.name}" disabled.`, resp)
    }
    this.savingCustomerIds.delete(id)
  })


  doSetCustomerName = flow(function * (id, name) {
    if (!this.canEditCustomers) return
    this.savingCustomerIds.add(id)
    let resp
    try {
      resp = yield requestPost(`cust_${id}_set_name`, `customers/${id}/name`, {name
      })
    }

    catch (restError) {
      CommonViewActions.Notification.addRestError(restError)
    }
    if (resp) {
      this.handleSuccess('Customer Name Updated', `Customer name set to ${name}`, resp)
    }
    this.savingCustomerIds.delete(id)
  })


  doSetRetention = flow(function * (id, retentionDays) {
    if (!this.canEditCustomers) return
    this.savingCustomerIds.add(id)
    let resp
    try {
      resp = yield requestPost(`cust_${id}_set_retention`, `customers/${id}/retention`, {
        retentionDays
      })
    }
    catch (restError) {
      CommonViewActions.Notification.addRestError(restError)
    }
    if (resp) {
      this.handleSuccess('Retention Updated', `Customer "${resp.name}" retention set to ${retentionDays} days.`, resp)
    }
    this.savingCustomerIds.delete(id)
  })

  doSetBandwidthLimit = flow(function * (id, bandwidth) {
    if (!this.canEditCustomers) return
    this.savingCustomerIds.add(id)
    let resp
    try {
      resp = yield requestPost(`cust_${id}_set_bandwidth`, `customers/${id}/bandwidth`, {
        allocatedAggregateBandwidth: bandwidth
      })
    }
    catch (restError) {
      CommonViewActions.Notification.addRestError(restError)
    }
    if (resp) {
      this.handleSuccess('Bandwidth Limit Updated', `Customer "${resp.name}" Bandwidth Limit updated`, resp)
    }
    this.savingCustomerIds.delete(id)
  })

  doSetStatus = flow(function * (id, status) {
    if (!this.canEditCustomers) return
    this.savingCustomerIds.add(id)
    let resp
    try {
      resp = yield requestPost(`cust_${id}_set_status`, `customers/${id}/status`, {
        status
      })
    }
    catch (restError) {
      CommonViewActions.Notification.addRestError(restError)
    }
    if (resp) {
      this.handleSuccess('Status Updated', `Customer "${resp.name}" Status updated`, resp)
    }
    this.savingCustomerIds.delete(id)
  })

  @action.bound
  doUpdateName = flow(function * (id, name) {
    if (!this.canEditCustomers)
    this.savingCustomerIds.add(id)
    let resp
    try {
      resp = yield requestPost(`cust_${id}_set_name`, `customers/${id}/name`, { name })
    }
    catch (restError) {
      CommonViewActions.Notification.addRestError(restError)
    }
    if (resp) {
      this.handleSuccess('Name Updated', `Customer is now named "${resp.name}".`, resp)
    }
    this.savingCustomerIds.delete(id)
  })

  @action.bound
  handleSuccess (heading, message, updatedCustomer) {
    CommonViewActions.Notification.add({
      heading,
      message,
      type: 'success',
      dismissTimer : dismissTimer
    })
    if (updatedCustomer) {
      // Update customer in place (TODO verify)
      this.customers = this.customers.map(cust => cust.id === updatedCustomer.id ? updatedCustomer : cust)
      window.setTimeout(function(){location.reload()},dismissTimer)
    }
  }

  @computed
  get customerCount () {
    return this.customers.length || 0
  }
}

import React from 'react'
import IntelCardActions from 'actions/IntelCardActions'

class ErroredValue extends React.Component {
  static displayName = 'ErroredValue'

  shouldComponentUpdate() {
    return false
  }

  _onClick = () => {
    IntelCardActions.retryQuery()
  }

  render() {
    return (
      <span
        className="icon icon-warning errored_value"
        data-tooltip={'Error encountered during query.\nClick to retry.'}
        data-tooltip-class="light"
        onClick={this._onClick}
      />
    )
  }
}

export default ErroredValue

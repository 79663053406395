import {
  observable,
  autorun,
  action,
  reaction,
  intercept,
  flow,
  computed
} from 'mobx'
import {
  requestGet
} from 'utils/restUtils'
import DeviceModel from 'models/devices/Device'
import { DeviceManagementStore } from 'stores/mobx/viewStores/devices/DeviceManagementStore'
import { Store } from 'stores/mobx/StoreManager'
import { DeviceQueryStore } from 'stores/mobx/data/devices/DeviceQueryStore'
import { RestError } from 'typings/restError'
import { IBasePromiseBasedObservable, fromPromise } from 'mobx-utils';

const EVENT_COUNT_PIVOT = 'devices,threatLevel'

export class DeviceListStore extends Store {
  deviceManagementStore: DeviceManagementStore
  deviceQueryStore: DeviceQueryStore

  static dependencies = [DeviceManagementStore, DeviceQueryStore]

  constructor (deps: [DeviceManagementStore, DeviceQueryStore]) {
    super(deps)
    const [deviceManagementStore, deviceQueryStore] = deps
    this.deviceManagementStore = deviceManagementStore
    this.deviceQueryStore = deviceQueryStore

    this.destroy = autorun(() => {
      if (
        deviceManagementStore.listParams
        && !deviceQueryStore.isLoading
      ) {
        this.load(deviceManagementStore.listParams, deviceQueryStore.parsedLuceneQuery || "")
      }
    })
  }

  @observable devices : Map<string, DeviceModel> = new Map()
  @observable count: number|null = null // null is equivalent to "unknown"
  @observable totalCount: number|null = null // Unfiltered global total number of devices
  @observable isLoadingTotal = false
  @observable isLoading = false
  @observable isLoadingMore = false
  @observable error?: RestError = null
  @observable currentPageIndex = 0

  @computed
  get isReadyForNextPage () {
    const numLoaded = this.devices.size
    return this.count !== null && numLoaded > 0 && numLoaded < this.count && !this.isLoading && !this.isLoadingMore
  }

  @action
  clearDevices () {
    this.devices = new Map()
  }

  @action
  addDevices (rawDevices) {
    for (let i = 0; i < rawDevices.length; i++) {
      const rawDevice = rawDevices[i]
      this.devices.set(
        rawDevice.id,
        new DeviceModel(rawDevice)
      )
    }
  }

  @action
  updateDeviceEventCounts (eventCountsResponse, deviceIdsQueried: string[]=[]) {
    const counts = eventCountsResponse.facetCounts.facetPivot[EVENT_COUNT_PIVOT]
    counts.forEach((count) => {
      const deviceId = count.value
      const device = this.devices.get(deviceId)
      if (!device) { return }
      const deviceEventCount = {
        low: 0,
        medium: 0,
        high: 0
      }
      count.pivot.forEach(({value, count}) => {
        const key = value.toLowerCase()
        deviceEventCount[key] = count
      })
      device.eventCount = deviceEventCount
    })
    deviceIdsQueried.forEach((deviceId) => {
      const device = this.devices.get(deviceId)
      if (device && device.eventCount == null) {
        device.eventCount = {low: 0, medium: 0, high: 0}
      }
    })
  }

  fetch = (pageIndex, listParams, parsedLuceneQuery) => {
    const {
      sortBy = 'risk',
      sortDir = 'desc',
      pageSize = 100
    } = listParams || this.deviceManagementStore.listParams
    let luceneQuery = parsedLuceneQuery || this.deviceQueryStore.parsedLuceneQuery

    const params = [
      `start=${pageIndex * +pageSize}`,
      `rows=${pageSize}`,
      `sort=${sortBy}%20${sortDir}`
      // TODO facets
    ]

    // when sorting by risk, need to add a secondary filter and sort in order to avoid
    // getting back device's with a risk score that has been TTLed away
    // const hour = 60 * 60 * 1000
    // const now = Date.now()
    // const occuredAtFilter = `occurredAt:[${now - 2 * hour} TO ${now}]`

    // if (luceneQuery) {
    //   if (sortBy == 'risk') {
    //     luceneQuery += ` AND ${occuredAtFilter}`
    //   }
    //   params.unshift(`q=${encodeURIComponent(luceneQuery)}`)
    // } else if (!luceneQuery && sortBy == 'risk') {
    //   params.unshift(`q=${occuredAtFilter}`)
    // }

    if (luceneQuery) {
      params.unshift(`q=${encodeURIComponent(luceneQuery)}`)
    }


    return requestGet(
      'get_devices_list',
      `devices?${params.join('&')}`,
      {
        baseURL: 'api/v2/'
      }
    )
  }

  loadTotal = flow(function * () {
    this.totalCount = null
    this.isLoadingTotal = true
    let ct
    try {
      ct = yield requestGet(
        'get_devices_total',
        `devices?rows=0`,
        { baseURL: 'api/v2/'}
      )
    }
    catch (error) {
      this.error = error
    }
    if (ct) {
      this.totalCount = ct.total
    }
    this.isLoadingTotal = false
  })

  loadEventCounts = flow(function * (docs) {
    const length = Math.min(docs.length, 100)
    const deviceIds = []
    for (let i = 0; i < length; i++) {
      deviceIds.push(docs[i].id)
    }
    if (deviceIds.length === 0) { return }
    const query = `devices:(${deviceIds.join(' OR ')})`

    const params = [
      `q=${encodeURIComponent(query)}`,
      'rows=0',
      'start=0',
      `facetPivot=${EVENT_COUNT_PIVOT}`,
      'facetMinCount=1',
      'facetLimit=100'
    ]
    const queryString = params.join('&')

    let counts
    try {
      counts = yield requestGet(
        `get_devices_event_counts_p-${this.currentPageIndex}`,
        `events/search?${queryString}`,
        { baseURL: 'api/v2/'}
      )
    }
    catch (error) {
      this.error = error
    }
    if (counts) {
      this.updateDeviceEventCounts(counts, deviceIds)
    }
  })

  load = flow(function * (listParams, parsedLuceneQuery) {
    this.currentPageIndex = 0
    this.clearDevices()
    this.count = null
    this.isLoading = true
    this.error = null

    this.loadTotal() // Update unfiltered total whenever a new query is issued

    let devices
    try {
      devices = yield this.fetch(this.currentPageIndex, listParams, parsedLuceneQuery)
    }
    catch (error) {
      this.error = error
    }
    if (devices) {
      this.count = devices.total
      this.addDevices(devices.docs)
      this.loadEventCounts(devices.docs)
    }
    this.isLoading = false
  })

  loadNextPage = flow(function * () {
    this.isLoadingMore = true
    this.error = null
    this.currentPageIndex++

    let devices
    try {
      devices = yield this.fetch(this.currentPageIndex)
    }
    catch (error) {
      this.error = error
    }
    if (devices) {
      this.count = devices.total
      this.addDevices(devices.docs)
      this.loadEventCounts(devices.docs)
    }
    this.isLoadingMore = false
  })
}

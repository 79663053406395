import {
  observable,
  flow
} from 'mobx'
import {
  requestGet
} from 'utils/restUtils'
import { Store } from 'stores/mobx/StoreManager'

export default class CustomerStatusesStore extends Store {
  @observable statusOptions = []
  @observable isLoading = false
  @observable error = null

  constructor(deps) {
    super(deps)
    this.load()
  }

  fetch = () => {
    return requestGet(
      'get_customer_status_options',
      `customers/statuses`
    )
  }

  load = flow(function* _load() {
    this.isLoading = true

    let statusOptions
    try {
      statusOptions = yield this.fetch()
    }
    catch (restError) {
      this.error = restError
    }
    if (statusOptions) {
      this.statusOptions = statusOptions
    }
    this.isLoading = false
  })
}

import Reflux from 'reflux'

export default Reflux.createActions([
  'loadProxies',
  'loadDevices',
  'testConnections',
  'createProxy', //(config)
  'updateProxy', //(id, config)
  'deleteProxy', //(id)
  'requestProxyInstallToken', //(id)
  'createDevice', //(config)
  'updateDevice', //(id, config)
  'deleteDevice', //(id)
  'changeConnections', //(...{action='connect'/'disconnect', proxyId, deviceId})
  'loadBlockLists',
  'blockIp',
  'remediateFile',
  'setAvailableBlockLists',
  'removeBlockListFav',
  'addAwsTag',
  'clearRemediationErrors',

  // actions for API Key (PIP-less) integrations
  'loadApiCredentials',
  'saveApiCredentials',
  'deleteApiCredentials',
  'testApiConnection'
])

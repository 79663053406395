import _ from 'lodash'
import T from 'prop-types'
import React from 'react'
import ReactDOM from 'react-dom'
import {
  Button,
  MenuItem,
  Timing,
  RouteAware,
  StoreProvider,
  AnimateHeight,
  CircleLoader,
  ErrorState,
  Tabs,
  DataTable,
  ErrorBoundary
} from 'react-base'
import cx from 'classnames'
import genericUtil from 'ui-base/src/util/genericUtil'
import urlHistoryUtil from 'utils/urlHistoryUtil'
import LogViewerActions from 'actions/LogViewerActions'
import LogViewerStore, { DEVICE_TYPE_TABS } from 'stores/LogViewerStore'
import SensorStore from 'stores/SensorStore'
import TimeRangeInput from 'components/TimeRangeInput'
import IpValue from 'components/values/IpValue'


const LOG_VIEWER_PARAMS = [
  'ip',
  'start',
  'end',
  'sensorId',
  'deviceId',
  'logType'
]
const NUMERIC_PARAMS = { start: 1, end: 1, sensorId: 1 }

class LogViewerPane extends React.Component {
  static displayName = 'LogViewerPane'

  static propTypes = {
    // From LogViewerStore:
    isLoading: T.bool,
    isLoadingNextPage: T.bool,
    nextPage: T.string,
    error: T.object,
    ip: T.string,
    activeDeviceId: T.string,
    activeLogType: T.oneOf(['traffic', 'threat', 'alert', 'anomaly']),
    start: T.number,
    end: T.number,
    sensorIds: T.array,

    devices: T.shape({
      isLoading: T.bool,
      data: T.arrayOf(
        T.shape({
          id: T.string,
          name: T.string,
          sensorIds: T.array
        })
      )
    }),

    data: T.objectOf(
      T.shape({
        isLoading: T.bool,
        isLoadingNextPage: T.bool,
        nextPageToken: T.string,
        error: T.any,
        results: T.array
      })
    ),

    allColumns: T.array,
    columnOrder: T.array,

    route: T.object,
    clearTimer: T.func,
    setTimer: T.func
  }

  constructor(props) {
    super(props)
    this.state = {
      isOpen: false
    }
  }

  componentDidMount() {
    ;['mousedown', 'touchstart'].forEach(name => {
      window.addEventListener(name, this._onWinMouseDown, false)
    })
    window.addEventListener('keydown', this._onGlobalKeydown)
    this._handleNewRoute(this.props.route)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { route } = nextProps
    // if we default to the first device ID, add it to the url
    // and check wether we need to default the logType
    if (this.props.activeDeviceId !== nextProps.activeDeviceId && nextProps.activeDeviceId) {
      const logParams = this._getLogParams(route)
      const defaultDevice = _.find(nextProps.devices.data, {
        id: nextProps.activeDeviceId
      })

      this._updateLogUrlParam({
        deviceId: nextProps.activeDeviceId,
        logType: logParams.logType || DEVICE_TYPE_TABS[defaultDevice.type][0]
      })
    }

    if (nextProps.route !== this.props.route) {
      this._handleNewRoute(nextProps.route)
    }
  }

  componentWillUnmount() {
    ;['mousedown', 'touchstart'].forEach(name => {
      window.removeEventListener(name, this._onWinMouseDown, false)
    })
    window.removeEventListener('keydown', this._onGlobalKeydown)
  }

  _handleNewRoute = nextRoute => {
    // Only one log source (PAN) so far, but in the future this is where we'd check
    // the type and call the appropriate log loading action.

    let logParams = this._getLogParams(nextRoute)
    let prevParams = this._lastUrlParams || {}
    if (
      logParams &&
      logParams.ip &&
      LOG_VIEWER_PARAMS.some(
        param => !_.isEqual(logParams[param], prevParams[param])
      )
    ) {
      this.props.clearTimer('mousedownclose')
      LogViewerActions.queryLogs(
        logParams.ip,
        logParams.start,
        logParams.end,
        logParams.sensorId ? [logParams.sensorId] : null,
        logParams.deviceId,
        logParams.logType
      )
    }
    this._lastUrlParams = logParams
    this.setState({ isOpen: !!logParams })
  }

  _getLogParams = route => {
    let logParam = route && route.logViewerParam
    if (logParam) {
      let parsedParams = _.zipObject(_.chunk(logParam.split('-'), 2))
      return _.mapValues(
        parsedParams,
        (val, key) =>
          val && NUMERIC_PARAMS.hasOwnProperty(key)
            ? +val
            : decodeURIComponent(val)
      )
    }
    return null
  }

  _updateLogUrlParam = changedParams => {
    const { route } = this.props
    let logParams = this._getLogParams(route)

    if (logParams) {
      _.assign(logParams, changedParams)
      let queryString = []
      LOG_VIEWER_PARAMS.forEach(param => {
        if (param in logParams && logParams[param] !== null) {
          queryString.push(
            `${param}-${encodeURIComponent(logParams[param]).replace(
              /-/g,
              '%2D'
            )}`
          )
        }
      })
      urlHistoryUtil.mergeParams({ logviewer: queryString.join('-') }, false)
    }
  }

  _onCloseClick = e => {
    e.preventDefault()
    this.close()
  }

  close = () => {
    urlHistoryUtil.removeParams(['logviewer'], false)
  }

  _onWinMouseDown = e => {
    // Close when clicking outside the log viewer modal
    if (
      this.state.isOpen &&
      !genericUtil.isNodeInEl(e.target, ReactDOM.findDOMNode(this)) &&
      !genericUtil.isNodeInElByClass(e.target, 'data_table_cfg_dialog') &&
      !genericUtil.isNodeInElByClass(e.target, 'pw_popup_menu')
    ) {
      // Wait a bit to allow a new logviewer route to cancel the close
      this.props.setTimer(this.close, 200, 'mousedownclose')
    }
  }

  _onRemoveSensorFilter = () => {
    this._updateLogUrlParam({ sensorId: null })
  }

  _onTimeRangeChange = ([start, end]) => {
    this._updateLogUrlParam({ start, end })
  }

  _onDeviceChange = deviceId => {
    const newActiveDevice = _.find(this.props.devices.data, { id: deviceId })
    const oldActiveDevice = _.find(this.props.devices.data, {
      id: this.props.activeDeviceId
    })
    if (newActiveDevice.type !== oldActiveDevice.type) {
      // if device.type changes, change log type possible
      this._updateLogUrlParam({
        deviceId,
        logType: DEVICE_TYPE_TABS[newActiveDevice.type][0]
      })
    } else {
      this._updateLogUrlParam({ deviceId })
    }
  }

  _onLogTypeChange = logType => {
    this._updateLogUrlParam({ logType })
  }

  _onColumnOrderChange = newOrder => {
    LogViewerActions.setColumnOrder(newOrder)
  }

  _onColumnOrderReset = () => {
    LogViewerActions.resetColumnOrder()
  }

  _onColumnResize = (colDef, width) => {
    LogViewerActions.setColumnWidth(colDef.key, width)
  }

  _onRequestNextPage = () => {
    LogViewerActions.loadNextPage()
  }

  _onGlobalKeydown = e => {
    // Close on escape key
    if (e.keyCode === 27 && this.state.isOpen) {
      this.close()
    }
  }

  _canDeviceHaveResults = deviceId => {
    let filteredSensors = this.props.sensorIds
    if (_.isEmpty(filteredSensors)) return true
    let device = _.find(this.props.devices.data, { id: deviceId })
    return (
      device &&
      _.intersection(device.sensorIds, this.props.sensorIds).length > 0
    )
  }

  render() {
    let { props, state } = this
    let { activeDeviceId } = props
    let allDevices = props.devices.data || []
    let activeDevice =
      activeDeviceId && _.find(allDevices, { id: activeDeviceId })
    let activeDeviceData = activeDeviceId && props.data[activeDeviceId]

    return state.isOpen
      ? <div className="log_viewer open" data-tooltip-class="light">
          <a
            href="#"
            className="remove_btn"
            onClick={this._onCloseClick}
            data-tooltip="Close Log Viewer"
          />

          <section className="log_control">
            <h3>Log Data</h3>

            <h4>
              <IpValue {..._.pick(props, 'ip', 'start', 'end', 'sensorIds')} />
              <span className="int_ext">
                {SensorStore.isInternalIp(props.ip, _.first(props.sensorIds))
                  ? 'Internal'
                  : 'External'}
              </span>
            </h4>

            <AnimateHeight>
              {props.sensorIds
                ? <div className="on_sensor">
                    On Sensor:{' '}
                    <span className="val">
                      {props.sensorIds
                        .map(SensorStore.getSensorName)
                        .join(', ')}
                      <span
                        className="icon icon-close"
                        onClick={this._onRemoveSensorFilter}
                        data-tooltip="Remove Sensor Filter"
                        data-tooltip-class="light"
                      />
                    </span>
                  </div>
                : null}
            </AnimateHeight>

            <div className="timeframe">
              <h5>Timeframe</h5>
              <TimeRangeInput
                initialValue={[props.start, props.end]}
                onSave={this._onTimeRangeChange}
                calendarAlign="bottom"
                calendarClass="log_viewer_calendar_popup"
              />
            </div>

            <div className="devices">
              <h5>Log Providers</h5>
              {_.isEmpty(allDevices)
                ? props.devices.isLoading
                  ? <CircleLoader loading transparent />
                  : <ErrorState error="No Log Providers Available" />
                : <ul>
                    {allDevices.map(device => {
                      let deviceData = props.data[device.id]
                      let iconType = 'fire'
                      return (
                        deviceData &&
                        <MenuItem
                          key={device.id}
                          className={cx(
                            'device',
                            device.id === activeDeviceId && 'active',
                            deviceData.hasResults && 'has_results',
                            (deviceData.isLoading || deviceData.isPreviewing) &&
                              'loading'
                          )}
                          args={[device.id]}
                          onClick={this._onDeviceChange}
                        >
                          <span
                            className="indicator"
                            data-tooltip={
                              deviceData.isLoading || deviceData.isPreviewing
                                ? 'Querying log...'
                                : deviceData.hasResults
                                  ? 'Has some matching logs'
                                  : 'No matching logs'
                            }
                          />
                          <div className="device_name">
                            <span className={`icon icon-${iconType}`} />{' '}
                            {device.name}
                          </div>
                          <div className="device_sensors">
                            {_.isEmpty(device.sensorIds)
                              ? '(No Associated Sensors)'
                              : device.sensorIds
                                  .map(SensorStore.getSensorName)
                                  .join(', ')}
                          </div>
                        </MenuItem>
                      )
                    })}
                  </ul>}
            </div>
          </section>

          <section className="log_data">
            {activeDeviceData
              ? [
                  <Tabs
                    key="tabs"
                    activeTabKey={
                      props.activeLogType ||
                      DEVICE_TYPE_TABS[activeDevice.type][0]
                    }
                  >
                    {DEVICE_TYPE_TABS[activeDevice.type].map(tab => {
                      return (
                        <MenuItem key={tab} onClick={this._onLogTypeChange}>
                          {tab}
                        </MenuItem>
                      )
                    })}
                  </Tabs>,

                  <DataTable
                    key="table"
                    columns={props.allColumns}
                    columnOrder={props.columnOrder}
                    onColumnOrderChange={this._onColumnOrderChange}
                    onColumnOrderReset={this._onColumnOrderReset}
                    onColumnResize={this._onColumnResize}
                    data={activeDeviceData && activeDeviceData.results}
                    isLoading={activeDeviceData && activeDeviceData.isLoading}
                    isLoadingNextPage={
                      activeDeviceData && activeDeviceData.isLoadingNextPage
                    }
                    hasNextPage={
                      activeDeviceData && !!activeDeviceData.paginationToken
                    }
                    onRequestNextPage={this._onRequestNextPage}
                    error={activeDeviceData && activeDeviceData.error}
                    noResultsText={
                      this._canDeviceHaveResults(activeDeviceId)
                        ? 'No Logs Found'
                        : <div>
                            The selected log provider is not associated with the
                            sensor{' '}
                            <b>
                              {SensorStore.getSensorName(props.sensorIds[0])}
                            </b>.<br />
                            {allDevices.length > 1
                              ? 'Select a different log provider, or '
                              : ''}
                            <Button
                              className="btn-link"
                              onClick={this._onRemoveSensorFilter}
                            >
                              remove the sensor filter
                            </Button>
                          </div>
                    }
                    className="log_table"
                  />
                ]
              : props.sensorIds &&
                !_.some(allDevices, d => this._canDeviceHaveResults(d.id))
                ? <div className="no_items">
                    None of the log providers are associated with the sensor{' '}
                    <b>{SensorStore.getSensorName(props.sensorIds[0])}</b>.<br />
                    <Button
                      className="btn-link"
                      onClick={this._onRemoveSensorFilter}
                    >
                      remove the sensor filter
                    </Button>
                  </div>
                : null}
          </section>
        </div>
      : <div className="log_viewer closed" />
  }
}

const EnhancedLogViewerPane = RouteAware(Timing(LogViewerPane))

export default props =>
  <ErrorBoundary>
    <StoreProvider store={LogViewerStore}>
      <EnhancedLogViewerPane {...props} />
    </StoreProvider>
  </ErrorBoundary>

import { get } from 'lodash'
import moment from 'moment'

// These constants give us some buffer time surrounding a request
// to make sure we find the pcap requested. This will not work for
// some edge cases but this will fix missing pcap contents for specified
// observations/netflows some of the time.
export const PCAP_FORWARD_WINDOW = 5 * 60000 // minutes

export function observationsRequest (obs) {
  const request = obs.map(o => observationRequest(o))
  return request
}
/**
 * Build a pcap request from an observation, strictly downloading the pcap associated
 * with the timeframe surrounding the observation, not the netflow
 */
export function observationRequest (observation) {
  // NOTICE : New PCAP apis should be able to support every association type, they need to be implemented here
  const flowId = get(observation, 'associatedId.flowId')
  const info = get(observation, 'info')
  const { agentId, occurredAt, endedAt } = observation
  const startTime = moment.utc(occurredAt)
  let endTime = endedAt ?
    moment.utc(endedAt) : moment.utc(startTime.clone().add(PCAP_FORWARD_WINDOW, 'ms'))
  if (endTime.isSame(startTime)) {
    endTime = endTime.add(1, 'ms')
  }
  const baseRequest = {
    sensorId: agentId,
    startTime: startTime.toISOString(),
    endTime: endTime.toISOString()
  }

  if (flowId && flowId.key) {
    // flowId association request
    const key = flowId.key
    return Object.assign(baseRequest, {
      filter: {
        flow: key
      }
    })
  } else {
    // Fall through request
    const filter = {
      filter: {
        ips: info.ips,
        ports: info.ports
      }
    }
    console.warn('Trying to build a pcap request for observation that is not tied to a flowId', filter)
    return Object.assign(baseRequest, filter)
  }
}

/**
 * Build a pcap request from a netflow object
 */
export function netflowRequest (netflow)  {
  const key = netflow.key
  const details = netflow.details || {}
  const startTime = moment.utc(details.startTime)
  const stats = netflow.stats || {}
  const agentId = netflow.agentId
  let updatedTime, endTime = 0
  if (details && details.endTime) {
    endTime = moment.utc(details.endTime)
  }
  if (stats && stats.updatedTime) {
    updatedTime = moment.utc(stats.updatedTime)
  }
  endTime = (endTime && endTime.isValid())
    ? endTime : (updatedTime && updatedTime.isValid())
      ? updatedTime : startTime.clone().add(PCAP_FORWARD_WINDOW, 'ms')
  if (endTime.isSame(startTime)) {
    endTime = endTime.add(1, 'ms')
  }
  return {
    sensorId: agentId,
    startTime: startTime.toISOString(),
    endTime: endTime.toISOString(),
    filter: {
      flow: key
    }
  }
}

export function netflowsRequest(netflows) {
  const request = netflows.map(n => netflowRequest(n))
  return request
}

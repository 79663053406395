import React, { FunctionComponent } from 'react'
import {LoadingValue} from 'react-base'
import {
  observer
} from 'mobx-react'
import {ThreatCount} from '../../../typings/devices/device'
import ThreatLevelBars from 'components/ThreatLevelBars'

export interface ThreatCountProps {
  value: ThreatCount
}

export const ThreatCountFragment: FunctionComponent<ThreatCountProps> = observer(props => {
  if (!props.value) {
    return <LoadingValue />
  }
  const {high, medium, low} = props.value
  const total: number = high + medium + low
  return (<span className="threat_count_fragment">
    <span>
      {total}
    </span>
    <ThreatLevelBars low={low} medium={medium} high={high} />
  </span>)
})

ThreatCountFragment.displayName = "ThreatCount"

import _ from 'lodash'
import Reflux from 'reflux'

import IntelManagementActions from 'actions/IntelManagementActions'
import {
  areOrderedListsEqual,
  convertSubscriptionsAPItoUI
} from 'utils/intelManagementUtils'
import {
  DEFAULT_SUBSCRIPTION_SENSOR_ID,
  INTEL_LIST_PURPOSE_IDS
} from 'constants/intelManagementConstants'
import IntelListStore from './IntelListStore'
import AnalyticsActions from 'actions/AnalyticsActions'
import {requestGet, requestPost} from 'utils/restUtils'
import {listenToStore} from 'utils/storeUtils'

const DEFAULT_STATE = {
  isLoadingSubscriptions: false,
  isSavingSubscriptions: false,
  subscriptionError: null,
  subscriptions: [],
  autoCapListId: null,
  subscriptionsBySensorId: {},
  subscribedSensorsByListId: {},
  defaultSubscription: [],
  editingSubForSensors: [], // All sensors that will be affected by the edited subscription order
  editingSubscriptionForSensorId: -1, // SensorId correlating to the currently-editing subscription order
  allEditedSensorSubscriptionsMatch: true // true if the current subscription for all `editingSubForSensors` is identical
}

let _state = _.cloneDeep(DEFAULT_STATE)

export default Reflux.createStore({
  listenables: [IntelManagementActions],

  init() {
    // _state.autoCapListId = null
    listenToStore(this, IntelListStore, this._onIntelListStoreUpdate)
  },

  getInitialState() {
    return _state
  },

  _onIntelListStoreUpdate(listStoreState) {
    const autoCapList = _.find(
      (listStoreState || []).lists,
      list => list.listPurpose === INTEL_LIST_PURPOSE_IDS.RULECAPPINGWHITELIST
    )
    if (autoCapList) {
      _state.autoCapListId = autoCapList.id
      this._notify()
    }
  },

  _notify() {
    // if (_state.autoCapListId) {
    //   _state.subscriptions = _.map(_state.subscriptions, sub => {
    //     if (sub.lists.indexOf(_state.autoCapListId) !== 0) {
    //       sub.lists.unshift(_state.autoCapListId)
    //       sub.listCount = sub.lists.length
    //     }
    //     return sub
    //   })
    // }

    _state.subscriptionsBySensorId = _.indexBy(_state.subscriptions, 'sensorId')

    _state.subscribedSensorsByListId = _.reduce(
      _state.subscriptions,
      (out, sub) => {
        for (var i = 0; i < sub.lists.length; i++) {
          if (sub.sensorId !== DEFAULT_SUBSCRIPTION_SENSOR_ID) {
            const listId = sub.lists[i]
            if (!out[listId]) {
              out[listId] = []
            }
            out[listId].push(sub.sensorId)
          }
        }
        return out
      },
      {}
    )
    _state.allEditedSensorSubscriptionsMatch = this._checkIfAllEditedSensorSubsMatch()
    if (
      _state.allEditedSensorSubscriptionsMatch &&
      _state.editingSubForSensors.length > 0 &&
      _state.editingSubscriptionForSensorId === -1
    ) {
      // Multiple sensor subs selected and they all match
      _state.editingSubscriptionForSensorId = _state.editingSubForSensors[0]
    }
    _state.defaultSubscription = _.get(
      _state.subscriptionsBySensorId,
      '0.lists',
      []
    )
    this.trigger(_state)
  },

  _checkIfAllEditedSensorSubsMatch() {
    return areOrderedListsEqual(
      _.map(_state.editingSubForSensors, sensorId =>
        _.get(_state.subscriptionsBySensorId[sensorId], 'lists', [])
      )
    )
  },

  onLoadAllSubscriptions() {
    _state.subscriptionError = null
    _state.isLoadingSubscriptions = true
    _state.subscriptions = []
    this._notify()

    const req = requestGet(
      `ims_get_subscriptions`,
      'intel/subscriptions' /*?sensorIds=[]'*/
    )

    req.then(data => {
      _state.isLoadingSubscriptions = false
      _state.subscriptions = convertSubscriptionsAPItoUI(data)
      this._notify()
    }, restError => {
      _state.isLoadingSubscriptions = false
      _state.subscriptionError = restError
      this._notify()
    })
  },

  onEditSubscriptionForSensors(sensorsIds = []) {
    if (_state.editingSubForSensors.length === 0 && sensorsIds.length > 0) {
      IntelManagementActions.closeRuleDetail() // Close rule detail panel if open
      IntelManagementActions.cancelNewRuleInput() // Close new rule input
    }
    this._resetEditor()
    _state.editingSubForSensors = sensorsIds
    // _state.editingSubscriptionForSensorId = -1 // Reset
    this._notify()
  },

  _resetEditor() {
    _state.editingSubForSensors = []
    _state.editingSubscriptionForSensorId = -1
    _state.isSavingSubscriptions = false
    _state.subscriptionError = null
    this._notify()
  },

  toggleEditSubscriptionForSensor(sensorId) {
    const _editedSensors = _.clone(_state.editingSubForSensors)
    if (_editedSensors.indexOf(sensorId) === -1) {
      _editedSensors.push(sensorId)
    } else {
      _.pull(_editedSensors, sensorId)
    }
    _state.editingSubForSensors = _editedSensors
    _state.editingSubscriptionForSensorId =
      _editedSensors.length === 1 ? _editedSensors[0] : -1
    this._notify()
  },

  onChooseBaseSubscriptionToEdit(sensorId) {
    _state.editingSubscriptionForSensorId = sensorId
    this._notify()
  },

  onCancelSubscriptionEdit() {
    this._resetEditor()
  },

  onReset() {
    _state = _.cloneDeep(DEFAULT_STATE)
    this._notify()
  },

  onSaveSubscription(newSubscriptionOrder, sensorIds) {
    if (!newSubscriptionOrder || !sensorIds || sensorIds.length === 0) {
      return
    }

    // If the _state.autoCapListId somehow got into the subscription, remove it here.
    if (
      _state.autoCapListId &&
      newSubscriptionOrder.indexOf(_state.autoCapListId) !== -1
    ) {
      const removeIdx = newSubscriptionOrder.indexOf(_state.autoCapListId)
      newSubscriptionOrder.splice(removeIdx, 1)
    }

    _state.isSavingSubscriptions = true
    this._notify()

    if (window._pw.isDemoCustomer) {
      console.warn('WARNING: Subscription save is mocked for Demo Customers')
      _state.isLoadingSubscriptions = true
      this._notify()
      setTimeout(() => {
        _state.isSavingSubscriptions = false
        _state.editingSubForSensors = []

        // Mock save
        for (var i = 0; i < sensorIds.length; i++) {
          const sid = sensorIds[i]
          const idx = _.findIndex(_state.subscriptions, 'sensorId', sid)
          const newTempSub = {
            sensorId: sid,
            lists: newSubscriptionOrder,
            listCount: newSubscriptionOrder.length
          }
          if (idx !== -1) {
            _state.subscriptions[idx] = newTempSub
          } else {
            _state.subscriptions.push(newTempSub)
          }
        }
        _state.isLoadingSubscriptions = false
        this._notify()
      }, 1200)
    } else {
      requestPost(null, 'intel/subscriptions/set', {
          sensorIds,
          listIds: newSubscriptionOrder
        })
        .then(data => {
          _state.isSavingSubscriptions = false
          _state.editingSubForSensors = []
          this.onLoadAllSubscriptions() // reload
          this._notify()
        }, restError => {
          _state.isSavingSubscriptions = false
          _state.subscriptionError = restError
          // this.onLoadAllSubscriptions() // reload
          this._notify()
        })
      AnalyticsActions.event({
        eventCategory: 'intel',
        eventAction: 'subscription_save'
      })
    }
  },

  getAllSubscriptions() {
    return _.cloneDeep(_state.subscriptions)
  },

  getDefaultSubscription() {
    return _.cloneDeep(_state.defaultSubscription)
  }
})

import {observable} from 'mobx'
import {schemer} from 'pw-schemer-mobx'
import StringAttribute from './StringAttribute'
import { IpAddress } from 'components/devices/fragments/IpAddress'

export class IpAddrAttr extends StringAttribute {
  @observable
  @schemer.ip({
    label: 'IP',
    formatDisplay: IpAddress
  })
  value: string
}
import _ from 'lodash'
import {
  observable,
  flow,
  autorun,
  computed
} from 'mobx'
import {
  Store
} from 'stores/mobx/StoreManager'
import constants, { SENSOR_SET_COLORS } from 'pwConstants'
import querystringUtil from 'ui-base/src/util/querystringUtil'
import { requestSearch } from 'utils/searchUtils'
import SitRepSummaryStore from '../SitRepSummaryStore'

const isValidTime = time =>!!+time && !isNaN(+time)

export default class EventResolutionsStore extends Store {
  static dependencies = [SitRepSummaryStore]

  @observable resolutionFacets = []
  @observable totalEvents = 0
  @observable isLoading = false
  @observable error = null
  @observable baseKillboxHref = `#killbox/events`

  constructor(deps) {
    super(deps)
    const [sitRepSummaryStore] = deps

    this.sitRepSummaryStore = sitRepSummaryStore

    this.cleanupWatcher = autorun(() => {
      // Reload any time start/end change in parent store
      if (isValidTime(sitRepSummaryStore.startTime) && isValidTime(sitRepSummaryStore.endTime)) {
        this.loadFacets(sitRepSummaryStore.startTime, sitRepSummaryStore.endTime)
        this.baseKillboxHref = `#killbox/events?start=${sitRepSummaryStore.startTime}&end=${sitRepSummaryStore.endTime}`
      }
    })
  }

  destroy() {
    this.cleanupWatcher()
    this.resolutionFacets = []
    this.isLoading = false
    this.error = null
  }

  _getBaseQuery = (startTime, endTime) => {
    return {
      search: [
        {
          name: 'observedAt',
          op: 'between',
          from: startTime,
          to: endTime
        },
        {
          name: 'threatLevel',
          op: 'in',
          value: ['high', 'medium', 'low']
        }
      ],
      options: {
        limit: 0,
        sort: { name: 'threatScore', direction: 'Desc' },
        facets: {}
      },
    }
  }

  loadFacets = flow(function* (startTime, endTime) {
    this.resolutionFacets = []
    this.totalEvents = 0
    this.isLoading = true
    this.error = null

    const query = this._getBaseQuery(startTime, endTime)

    query.options.facets = {
      ...query.options.facets,
      pivots: ['workflow.resolution,threatLevel'],
      minCount: 1
    }

    try {
      const resp = yield requestSearch('sitrep_summary_events_by_resolution', 'events', query)
      this.resolutionFacets = resp.facets.pivots['resolution,threatLevel']
      this.totalEvents = resp.count || 0
    }
    catch (error) {
      this.error = error
    }
    this.isLoading = false
  })

  @computed
  get resolvedEventsCount() {
    return this.resolutionFacets ? this.resolutionFacets.reduce((out, res) => out + res.count, 0) : 0
  }

  @computed
  get hasResolvedEvents() {
    return this.resolvedEventsCount > 0
  }

  @computed
  get formattedResolutionData() {
    return this.hasResolvedEvents ? this.resolutionFacets.map((resolution, i) => {
      const href = `${this.baseKillboxHref}&${querystringUtil.stringify({ resolvedReason: resolution.value })}`

      return {
        id: resolution.value,
        groupId: `g:${resolution.value}`,
        value: resolution.count,
        color: SENSOR_SET_COLORS[i],
        display: constants.resolvedReasons[_.camelCase(resolution.value)] || resolution.value,
        killboxHref: `${this.baseKillboxHref}&${querystringUtil.stringify({ resolvedReason: resolution.value })}`,
        threatLevels: resolution.pivot.reduce((out, threatLevel) => {
          out[threatLevel.value.toLowerCase()] = {
            count: threatLevel.count,
            killboxHref: `${href}&threatLevel=${threatLevel.value}`
          }
          return out
        }, { high: { count: 0 }, medium: { count: 0 }, low: { count: 0 } })
      }
    }) : []
  }
}
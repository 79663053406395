import Reflux from 'reflux'

export default Reflux.createActions([
  'openCommandConsole', // (optional sensorIds=[]) Optionally pass pre-set sensorIds
  'closeCommandConsole',

  'loadCommands', // Load all available control channel commands
  'issueCommand', // (commandId, [senorIds])

  'clearResult', // (commandTimestamp)
  'clearAllResults',

  'setSelectedSensorIds' // ([sensorIds])
])

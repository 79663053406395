
import {observable} from 'mobx'
import {schemer} from 'pw-schemer-mobx'
import DeviceAttribute from './DeviceAttribute'

export default class StringAttribute extends DeviceAttribute {
  @observable
  @schemer.string
  value: string
}


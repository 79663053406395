import React, { FunctionComponent } from 'react'
import {
  observer
} from 'mobx-react'
import IpValue from 'components/values/IpValue'

export interface IpAddressProps {
  value: string
}

export const IpAddress: FunctionComponent<IpAddressProps> = observer(props => {
  return (
    <span className={`ip_address`}>
      <IpValue ip={props.value} />
    </span>
  )
})

IpAddress.displayName = "IpAddress"



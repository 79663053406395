import React from 'react'
import T from 'prop-types'
import { observer } from 'mobx-react'
import {
  ScrollBars,
  ErrorState,
  CircleLoader
} from 'react-base'
import { useStore } from 'stores/mobx/StoreManager'
import DownloadStore from 'stores/mobx/viewStores/download-manager/DownloadStore'
import { openPcapDownloadDialog } from 'actions/pcapDownloadActions'
import { Download } from './Download'

const NOMINAL_HEIGHT = 120
const ERROR_EXTRA_HEIGHT = 140

export const DownloadManager = observer(({closeMenu}) => {
  const downloadStore = useStore(DownloadStore)
  const numDownloads = downloadStore.downloads.size
  const listHeight = (numDownloads * NOMINAL_HEIGHT) + (downloadStore.errorCount * ERROR_EXTRA_HEIGHT)
  const hasDownloads = numDownloads !== 0


  return (
    <div className="meta_menu">
      <h3>Downloads<span className={`new_stuff`}>BETA</span></h3>
      <h4>
        <span>{`${numDownloads} Download${numDownloads === 1 ? '' : 's'}`}</span>
        <div className={`meta_subheading_controls`}>
            <button
              className={`btn btn-xs btn-link`}
              onClick={() => {
                openPcapDownloadDialog()
                closeMenu()
              }}
              data-tooltip-class='light'
              data-tooltip={`Manually download PCAP data...`}
            ><span className={`icon icon-plus`} /> Download PCAP...</button>
          {hasDownloads &&
            <button
              className={`btn btn-xs btn-link`}
              onClick={downloadStore.clearAll}
              data-tooltip-class='light'
              data-tooltip={`Clear and Cancel all downloads`}
            ><span className={`icon icon-trash`} /> Clear All</button>
          }
        </div>
      </h4>
      <div className="meta_info">
        {!hasDownloads &&
          <div className={`no_results`}>Your downloads will appear here</div>
        }
        <div
          className={`download_list`}
          style={{
            height: Math.max(listHeight, NOMINAL_HEIGHT),
            maxHeight: NOMINAL_HEIGHT * 5
          }}
        >
          {downloadStore.isLoading ? (
            <CircleLoader loading overlay />
          ) : downloadStore.error ? (
            <ErrorState error={downloadStore.error} />
          ) : (
            <ScrollBars outside slimShady slimShadyDarker>
              {Array.from(downloadStore.downloads, ([key, download]) => (
                <Download
                  key={key}
                  download={download}
                />
              ))}
            </ScrollBars>
          )}
        </div>
      </div>
    </div>
  )
})

DownloadManager.propTypes = {
  closeMenu: T.func.isRequired
}

DownloadManager.displayName = "DownloadManager"

import {getThemeColorHex} from 'utils/themeUtils'

export function getGoogleMapStyles () {
  return [
    {
      "featureType": "all",
      "elementType": "labels.text.stroke",
      "stylers": [
        { "color": getThemeColorHex(20) }
      ]
    },
    {
      "featureType": "all",
      "elementType": "labels.text.fill",
      "stylers": [
        { "color": getThemeColorHex(90) }
      ]
    },
    {
      "featureType": "water",
      "elementType": "geometry.fill",
      "stylers": [
        { "color": getThemeColorHex(83) }
      ]
    },
    {
      "featureType": "landscape.natural.terrain",
      "elementType": "geometry.fill",
      "stylers": [
        { "visibility": "off" }
      ]
    },
    {
      "featureType": "landscape.man_made",
      "elementType": "geometry.fill",
      "stylers": [
        { "visibility": "off" }
      ]
    },
    {
      "featureType": "landscape",
      "elementType": "geometry.fill",
      "stylers": [
        { "color": getThemeColorHex(73) }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry",
      "stylers": [
        { "color": getThemeColorHex(40) }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "labels.text.fill",
      "stylers": [
        { "invert_lightness": true },
        { "color": getThemeColorHex(13) }
      ]
    },
    {
      "featureType": "landscape",
      "elementType": "labels.text",
      "stylers": [
        { "invert_lightness": true }
      ]
    },
    {
      "featureType": "poi",
      "stylers": [
        { "visibility": "off" }
      ]
    },
    {
      "elementType": "labels",
      "stylers": [
        { "invert_lightness": true }
      ]
    },
    {
      "featureType": "administrative",
      "elementType": "geometry.fill",
      "stylers": [
        { "visibility": "off" }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "labels.icon",
      "stylers": [
        { "invert_lightness": true }
      ]
    },
    {
      "featureType": "transit.station",
      "elementType": "geometry",
      "stylers": [
        { "color": getThemeColorHex(83) }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "geometry.fill",
      "stylers": [
        { "color": getThemeColorHex(26) }
      ]
    },
    {
      "featureType": "road.local",
      "elementType": "geometry",
      "stylers": [
        { "color": getThemeColorHex(40) }
      ]
    },
    {
      "featureType": "administrative.locality",
      "elementType": "labels.text.fill",
      "stylers": [
        { "color": getThemeColorHex(13) }
      ]
    },
    {
      "featureType": "administrative.neighborhood",
      "elementType": "labels.text.fill",
      "stylers": [
        { "color": getThemeColorHex(20) }
      ]
    },

    {
      "featureType": "landscape",
      "elementType": "labels.text",
      "stylers": [
        { "invert_lightness": true }
      ]
    },
    {
    }
  ]
}

import {observable, computed} from 'mobx'
import {
  SchemerModel,
  schemer
} from 'pw-schemer-mobx'
import { Country } from 'components/devices/fragments/Country'
import { City } from 'components/devices/fragments/City'
import { Latitude } from 'components/devices/fragments/Latitude'
import { Longitude } from 'components/devices/fragments/Longitude'

export class Coordinates extends SchemerModel {
  @observable
  @schemer.number({
    label: 'Lat',
    tooltip: 'Latitude',
    formatDisplay: Latitude
  })
  lat: number

  @observable
  @schemer.number({
    label: 'Long',
    tooltip: 'Longitude',
    formatDisplay: Longitude
  })
  lon: number

  @observable
  @schemer.string({
    label: 'Country',
    tooltip: 'Country Code',
    formatDisplay: Country
  })
  countryIsoCode: string

  @observable
  @schemer.string({
    label: 'City',
    tooltip: 'City ID',
    formatDisplay: City
  })
  cityId: string
}
import _ from 'lodash'
import React from 'react'
import T from 'prop-types'
import SensorStore from 'stores/SensorStore'
import { DropdownMenu, MenuItem } from 'react-base'


const lcSensorName = d => (d.friendly_name || 'zzzz').toLowerCase()


class SensorDropdown extends React.Component {
  static propTypes = {
    label: T.string,
    onSelect: T.func,
    tooltip: T.string,
    preFilter: T.func
  }

  state = {
    filterText: ''
  }

  _onFilterChange = filterText => {
    this.setState({filterText})
  }

  _filterSensor = sensor => {
    const preFilter = this.props.preFilter
    if (preFilter && !preFilter(sensor)) return false

    // TODO use fuzzaldrin and apply bold to matches
    const filterText = this.state.filterText
    return !filterText || sensor.friendly_name.toLowerCase().indexOf(filterText.toLowerCase()) >= 0
  }

  render() {
    const {props} = this

    return <DropdownMenu
      closeOnItemClick
      filterable
      scrollable
      menuClasses="sensor_menu_dropdown"
      {...(_.omit(props, 'onSelect'))}
      onFilterChange={this._onFilterChange}
    >
      {SensorStore.getSensorSets().reduce((out, sensorSet) => {
        sensorSet.enabledSensors.filter(this._filterSensor).forEach((s, i) => {
          out.push(
            <MenuItem
              key={s.id}
              onClick={props.onSelect}
              args={[s.id]}
              headingText={i === 0 ? sensorSet.name : null}
            >
              {s.friendly_name}
            </MenuItem>
          )
        })
        return out
      }, [])}
    </DropdownMenu>
  }
}

export default SensorDropdown

import {Coordinates} from '../geo'
import {IpAddr} from '../common'
import {DeviceAttribute} from './deviceAttribute'
import {DeviceAttributeSource} from './deviceAttributeSource'

export enum DeviceRole {
  Other = "Other",
  IcsProcessController = "IcsProcessController",
  BackupHistorianServer = "BackupHistorianServer",
  BastionServer = "BastionServer",
  IcsOperatorWorkstationHmi = "IcsOperatorWorkstationHmi",
  IcsDeviceSwitch = "IcsDeviceSwitch",
  IcsDeviceRouter = "IcsDeviceRouter",
  Database = "Database",
  Proxy = "Proxy",
  Firewall = "Firewall",
  DnsServer = "DnsServer",
  DomainController = "DomainController",
  VunlerabilityScanner = "VunlerabilityScanner", // SIC
  HoneyPot = "HoneyPot",
  WebServer = "WebServer",
  Workstation = "Workstation",
  Switch = "Switch",
  SecurityDevice = "SecurityDevice",
  EmailServer = "EmailServer",
  DistributedControlServer = "DistributedControlServer",
  Directory = "Directory",
  FileShare = "FileShare",
  Emr = "Emr",
  PointOfSale = "PointOfSale",
  WebSales = "WebSales",
  BackupServer = "BackupServer",
  TicketingSystem = "TicketingSystem",
  Ntp = "Ntp",
  Ftp = "Ftp",
  Plc = "Plc",
  Dhcp = "Dhcp",
  OnlinePaymentServer = "OnlinePaymentServer",
  Vpn = "Vpn",
  Wireless = "Wireless",
  LoadBalancer = "LoadBalancer",
  SerialGateway = "SerialGateway",
  Hypervisor = "Hypervisor",
  ApplicationFirewall = "ApplicationFirewall",
  MalwareSandbox = "MalwareSandbox",
  Dlp = "Dlp",
  Ids = "Ids",
  Ips = "Ips",
  Av = "Av",
  Etdr = "Etdr",
  EmailFilter = "EmailFilter",
  EndpointManagement = "EndpointManagement",
  Idm = "Idm",
  AccessManagement = "AccessManagement",
  DualFactorAuthentication = "DualFactorAuthentication",
  PacketCapture = "PacketCapture",
  Server = "Server"
  // Zunassiged_051 = "Zunassiged_051",
  // Zunassiged_052 = "Zunassiged_052",
  // Zunassiged_053 = "Zunassiged_053",
  // Zunassiged_054 = "Zunassiged_054",
  // Zunassiged_055 = "Zunassiged_055"
}

export enum OsType {
  Unknown = "Unknown",
  Windows = "Windows",
  MacOs = "MacOs",
  Bsd = "Bsd",
  Linux = "Linux",
  Sunos = "Sunos",
  Embedded = "Embedded",
  CiscoIos = "CiscoIos",
  Junos = "Junos",
  Android = "Android",
  AppleIos = "AppleIos"
}

export enum Priority {
  Low = "Low",
  Normal = "Normal",
  High = "High",
  Critical = "Critical"
}

export enum NetworkType {
  Unknown = "Unknown",
  Vpn = "Vpn",
  Core = "Core",
  Pcn = "Pcn",
  Dmz = "Dmz",
  Guest = "Guest",
  Pci = "Pci",
  PointOfSale = "PointOfSale",
  Security = "Security",
  Pin = "Pin"
}

export type Os = {
  osType: OsType
  osVersion: string
  osName?: string
}

export type ExpirationInfo = {
  updatedTime: number // timestamp
  ttl?: number // millis
}

export type GeoDetails = {
  sensorId: number
  ip: string,
  coords: Coordinates
}

export type TimestampValue = {
  timestamp: number
}

export type SensorIdWithIpAddress = {
  sensorId: number
  attribute: IpAddr
}

export type AwsId = {
  instanceId: string
  accountId: string
}

export type AwsInterface = {
  macAddress: string
  ipAddress?: IpAddr
  subnetId?: string
  vpcId?: string
  privateIpAddresses: IpAddr[]
  ipv6Addresses: IpAddr[]
}

export type Interface = {
  ip: SensorIdWithIpAddress
  mac: string
  networkType: NetworkType
}

export type AwsDetails = {
  id: AwsId
  availiablityZone: string // SIC
  imageId: string
  instanceType: string
  tags: string[]
  interfaces: AwsInterface[]
}

export type UserDetails = {
  userId: string
  firstName?: string
  lastName?: string
  email?: string
  description?: string
  city?: string
  state?: string
  country?: string
  title?: string
  department?: string
  company?: string
  highValueTarget?: boolean
}


export type RawDevice = {
  readonly id: string,
  [key: string]: any
}

export type FlattenedDeviceAttribute = {
  attributeId: DeviceAttribute
  value: any
  source: DeviceAttributeSource
  updatedAt: Date
  ttl?: number // millis
}

export type ThreatCount = {
  low: number,
  medium: number,
  high: number
}

// export type FlattenedDeviceAttributeSet = {
//   // latestValue: FlattenedDeviceAttribute
//   values: FlattenedDeviceAttribute[]
// }

export type FullDevice = {
  readonly id: string
  readonly cid: number
  occurredAt: number // TODO get ben to rename this to `updatedAt` or similar
  tags: string[] //Set<string>
  additionalAttributes: {[key: string]: string}
  attributes: FlattenedDeviceAttribute[]
  previousDeviceIds: string[],
  eventCount?: ThreatCount
}

export type MigratedDevice = {
  readonly migrated_device_id: string
  readonly cid: number
  readonly id: string
}

export type Device = MigratedDevice|FullDevice

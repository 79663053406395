import Reflux from 'reflux'
import IntelCardActions from 'actions/IntelCardActions'
import { loadSensorGraphsData } from 'stores/SensorGraphsDialogStore'


const _state = {
  isLoading: true,
  sensorId: null,
  error: null,
  timeframe: 'day',
  data: null
}


export default Reflux.createStore({
  listenables: IntelCardActions,

  getInitialState() {
    return _state
  },

  _notify() {
    this.trigger(_state)
  },

  async onLoadSensorPerformance(sensorId, timeframe) {
    _state.timeframe = timeframe || _state.timeframe || 'day'
    _state.isLoading = true
    _state.sensorId = sensorId
    this._notify()

    try {
      _state.data = await loadSensorGraphsData(sensorId, timeframe, 60) //where the magic happens
    } catch(restError) {
      _state.error = restError
    }

    _state.isLoading = false
    this._notify()
  }
})

import _ from 'lodash'
import React from 'react'
import T from 'prop-types'
// import ReactDOM from 'react-dom'
import {
  Slider,
  Button,
  Tabs,
  MenuItem,
  TextInput,
  DropdownMenu
} from 'react-base'

const CONTROL_SHAPE = {
  controlProps: T.object, // Additional props to merge into the generated control component
  initialValue: T.any,
  stateKey: T.string.isRequired,
  type: T.oneOf([
    'slider',
    'tabs',
    'bool',
    'input',
    'select'
  ]).isRequired,
}

export default class SimpleStateTweaker extends React.Component {
  static displayName = 'SimpleStateTweaker'

  static propTypes = {
    currentState: T.object.isRequired,
    controls: T.arrayOf(T.shape(CONTROL_SHAPE)),
    onChange: T.func.isRequired
  }

  state = {
    expanded: false
  }

  _onChange = ({stateKey}, newValue) => {
    console.log('tweaker change', stateKey, newValue)
    this.props.onChange(_.set({}, stateKey, newValue))
  }

  handleToggleExpanded = () => {
    this.setState({
      expanded: !this.state.expanded
    })
  }

  renderControl = (control, i) => {
    const {
      currentState
    } = this.props
    let controlComponent = null
    const currentValue = (currentState || {})[control.stateKey]

    if (control.type === 'slider') {
      controlComponent = (
        <Slider
          { ...control.controlProps }
          isRange={ control.controlProps.initialRange.length === 2 }
          labelA="0"
          labelB="1"
          onChange={ _.partial(this._onChange, control) }
          orientation="horizontal"
        />
      )
    }
    else if (control.type === 'bool') {
      controlComponent = (
        <Button
          { ...control.controlProps }
          args={ [!currentValue] }
          onClick={ _.partial(this._onChange, control) }
        >{ currentValue ? 'ON' : 'OFF' }</Button>
      )
    }
    else if (control.type === 'select') {
      controlComponent = (
        <DropdownMenu
          { ...control.controlProps }
          activeMenuKeys={[currentValue]}
          label={ currentValue }
        >
        { _.map(control.controlProps.options, opt => (
          <MenuItem
            args={ [control, opt] }
            onClick={ this._onChange }
            key={opt}
          >{opt}</MenuItem>
        )) }
        </DropdownMenu>
      )
    }
    else {
      controlComponent = `Unknown Control Type: ${ control.type }`
    }

    return (
      <div
        className={ `control control_type_${ control.type }` }
        key={ i }
      >
        <div className="tweaker_label">{ control.stateKey }</div>
        <div>{ controlComponent }</div>
      </div>
    )
  }

  render() {
    const {props} = this
    const {expanded} = this.state
    return (
      <div className="simple_state_tweaker">
        <header onClick={this.handleToggleExpanded}>Adjustments...</header>
        { expanded && _.map(props.controls, this.renderControl) }
      </div>
    )
  }
}

import _ from 'lodash'
import React from 'react'
import T from 'prop-types'
import cx from 'classnames'
import { ConfirmAction, TextOverflow, StoreProviderHOC } from 'react-base'
import SensorStore from 'stores/SensorStore'
import SensorActionsMenu from 'components/SensorActionsMenu'
import { getIntelCardHref } from 'components/values/SearchableValue'
import SensorDropdown from 'components/SensorDropdown'
import { hasPermission } from 'stores/UserStore'
import SensorActions from 'actions/SensorActions'
import SensorHealthStore from 'stores/SensorHealthStore'
import AnalyticsActions from 'actions/AnalyticsActions'

class SensorHeader extends React.PureComponent {
  static propTypes = {
    sensorId: T.number, //.isRequired (causes warning when wrapped with StoreProviderHOC)
    sensorsById: T.object, //from SensorStore
    sensorsWithCurrentLoss: T.object, //from SensorHealthStore
    largeIcon: T.bool,
    hideCardIcon: T.bool,
    hideProfile: T.bool,
    hideSet: T.bool,
    hideVersion: T.bool,
    hideIp: T.bool,
    usageId: T.string, //identifier for page/location using this header, e.g. 'intelcard' - used for analytics scoping
    onChangeSensor: T.func, //(newSensorId) optional: if present then sensor name will become dropdown for selecting sensor
    onChangeSensorFilter: T.func //if onChangeSensor is present, this will be run to filter sensors available in the dropdown
  }

  _enableSensor = () => {
    SensorActions.setSensorEnabled(this.props.sensorId, true)
    AnalyticsActions.event({
      eventCategory: 'sensor',
      eventAction: 'enable_sensor',
      eventLabel: `from_${this.props.usageId || 'unknown'}`
    })
  }

  _onPivotToProfiler = () => {
    AnalyticsActions.event({
      eventCategory: 'sensor',
      eventAction: 'pivot_to_profiler',
      eventLabel: `from_${this.props.usageId || 'unknown'}`
    })
  }

  render() {
    const {props} = this
    const {sensorId} = props
    const sensor = props.sensorsById[sensorId]
    if (!sensor) {
      return <header className="sensor_header">Sensor Not Found</header>
    }
    const sensorName = SensorStore.getSensorName(sensorId)

    /*
    Disabled:
      - Only possible action is to re-enable, no other data displayed
    Offline:
      - Should this be presented as unhealthy? It's a tricky one because a sensor could go offline either unexpectedly or due to intentional stopping, but we can't tell the  difference AFAIK
    Stopping:
      - Transient state between Online and Offline
    Awaiting Installation:
      - Probably implies both Offline and Profiling, but no need to call those out
    Online:
      - All good
     */
    const isOffline = !sensor.is_active
    const isDisabled = !sensor.enabled
    const isProfiling = _.get(sensor, 'sensor_config.sensor_mode') === 'profiling' && !sensor.isVersion2
    const needsInstall = !sensor.downloaded
    const needsRestart = !sensor._config_applied && sensor.downloaded && !sensor.isVersion2
    const hasLoss = !!props.sensorsWithCurrentLoss[sensor.id]

    const statusClasses = cx(
      isProfiling && 'profiling',
      isOffline && 'offline',
      hasLoss && 'has_loss',
      isDisabled && 'disabled',
      needsInstall && 'needs_install'
    )

    const statusLabel = isDisabled ? 'Disabled' :
      sensor.isStopping ? 'Stopping' :
      needsInstall ? 'Needs Install' :
      isOffline ? 'Offline' :
      isProfiling ? 'Profiling' :
      'Online'

    return <header className="sensor_header">
      <div className={cx(
        "sensor_icon icon icon-sensor-small",
        props.largeIcon && 'large',
        sensor.isVersion2 && 'v2',
        statusClasses
      )} />

      <div className="info">
        <div className="name_row row">
          <TextOverflow className="name">
            {sensorName}
          </TextOverflow>

          {props.onChangeSensor &&
            <SensorDropdown
              label=""
              tooltip="Choose a different sensor"
              buttonClasses="btn sensor_menu_btn"
              onSelect={props.onChangeSensor}
              preFilter={props.onChangeSensorFilter}
            />
          }

          <span className="icons">
            {!props.hideCardIcon && !isDisabled &&
              <a
                href={getIntelCardHref(`sensor:${sensorId}`)}
                className="btn-icon icon-info"
                data-tooltip="View Sensor Info"
              />
            }
            <span data-tooltip="Sensor Actions">
              <SensorActionsMenu sensor={sensor} usageId={props.usageId} />
            </span>
          </span>
        </div>

        <div className="status_row row">
          <span className={cx('status_badge', statusClasses)}>{statusLabel}</span>
          {hasLoss &&
            <span className="has_loss_icon icon icon-loss" data-tooltip="Sensor has experienced data loss in the past 5 minutes" />
          }
          {needsRestart && !isOffline &&
            <a
              href={getIntelCardHref(`sensor:${sensorId}`)}
              className="needs_restart"
              data-tooltip="This sensor has saved config changes and requires a restart to apply them"
            >Needs Restart</a>
          }
          {isDisabled && hasPermission('sensors:enable') &&
            <ConfirmAction
              buttonMode
              buttonClassName="btn btn-link btn-xs"
              buttonText="Re-Enable Sensor"
              message="Are you sure you want to enable this Sensor?"
              confirmText="Yes, Enable it"
              onConfirmed={this._enableSensor}
            />
          }
        </div>

        <div className="set_row row">
          {!props.hideSet &&
            <TextOverflow>
              <span className="icon icon-sensor-set mini_icon" />
              {SensorStore.getSensorSetName(sensor.agent_set_id) || 'None'}
            </TextOverflow>
          }
          {sensor.ip_address && !props.hideIp && !isDisabled && !needsInstall &&
            <span className="ip">{sensor.ip_address}</span>
          }
          {sensor.version && !props.hideVersion && !isDisabled && !needsInstall
            ? <div className="version">v{sensor.version}</div>
            : null
          }
        </div>

        {!props.hideProfile && !isDisabled && !sensor.isVersion2 && !needsInstall &&
          <div className="profile_row row">
            <TextOverflow>
              <span className="icon icon-bubbles mini_icon" />
              Profile:
              <a className="profile" href={`#sensors/capture-profiles?sensorId=${sensorId}`}
                onClick={this._onPivotToProfiler}
              >
                {sensor.captureProfileName || 'None Applied'}
              </a>
            </TextOverflow>
          </div>
        }
      </div>
    </header>
  }
}

export default StoreProviderHOC(
  SensorHeader,
  [
    {
      store: SensorStore,
      mapping: ['sensorsById']
    },
    {
      store: SensorHealthStore,
      mapping: state => ({
        sensorsWithCurrentLoss: _.get(state, 'hasCurrentLoss.bySensor') || {}
      })
    }
  ]
)

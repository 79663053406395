import _ from 'lodash'
import Reflux from 'reflux'
import CommonViewActions from 'actions/CommonViewActions'
import IntelManagementActions from 'actions/IntelManagementActions'
import urlHistoryUtil from 'utils/urlHistoryUtil'
import {
  API_ALLOWED_PROPS_INTEL_LIST,
  INTEL_NEW_LIST_ID,
  PROTECTWISE_OWNED_CID,
  THREAT_TEAM_CID,
  INTEL_STATE_OPTIONS,
  INTEL_MODES,
  MAX_LIST_NAME_LENGTH,
  INTEL_LIST_QUICK_ADD_DEFAULT_TAG,
  INTEL_LIST_PURPOSE_ENUM_TO_ID
  // INTEL_LIST_PURPOSE_IDS
} from 'constants/intelManagementConstants'
import constants from 'pwConstants'
import { validateNonEmptyString } from 'pw-validators'
import AnalyticsActions from 'actions/AnalyticsActions'
import getTestId from 'ui-test-ids'
import { requestDelete, requestGet, requestPost, requestPut } from 'utils/restUtils'

const { protocolFamilyColors } = constants

const COUNT_FACET_PIVOT = `listId,status,mode`

const TEST_ID_CREATE_LIST_ERROR = getTestId(
  'intel.createListForm.errorNotification'
)

// const listColors = [
//   '#A69F21', '#9AA621', '#21A662', '#5AA69E', '#2189A6', '#524FA1', '#7533A6',
//   '#807C40', '#798033', '#40805F', '#458079', '#407280', '#413F80', '#654080',
//   '#8C8962', '#9FA664', '#638C76', '#638C87', '#6398A6', '#64638C', '#7B638C',
//   '#9FA664', '#779183'
// ]

const DEFAULT_STATE = {
  error: null,
  isLoading: false,
  lists: [],
  listIdsToColors: {},
  nonEditableSelectedListIds: [], // All selected listIds that are not editable by the current user
  userListCount: 0,
  savingListIds: [], // Array of listIds that are being saved
  selectedListIds: [],
  currentCustomerId: -1
}

let _state = _.cloneDeep(DEFAULT_STATE)

export default Reflux.createStore({
  listenables: [IntelManagementActions],

  init() { },

  getInitialState() {
    return _state
  },

  _notify() {
    _state.userListCount = _.filter(_state.lists || [], {
      _isUserList: true
    }).length
    const maxColorIdx = protocolFamilyColors.length - 1

    // If all lists are implicitly selected (empty selectedListIds), populate with all non-editable list ids
    _state.nonEditableSelectedListIds =
      _state.selectedListIds.length === 0
        ? _.map(
          _.map(_state.lists, list => !list._isEditableByCustomer),
          list => list.id
        )
        : []

    _state.listIdsToColors = _.reduce(
      _.sortBy(_state.lists, list => list.name),
      (out, list, i) => {
        const color =
          protocolFamilyColors[i > maxColorIdx ? i % maxColorIdx : i]

        if (
          _state.selectedListIds.indexOf(list.id) !== -1 &&
          !list._isEditableByCustomer
        ) {
          _state.nonEditableSelectedListIds.push(list.id)
        }

        out[list.id] = color
        list._color = color
        return out
      },
      {}
    )

    this.trigger(_state)
  },

  canCurrentCustomerEditList(listId) {
    return _state.currentCustomerId !== -1 &&
      _state.currentCustomerId === THREAT_TEAM_CID
      ? true
      : _.get(this.getListById(listId), '_isUserList', true)
  },

  _queueNotify() {
    clearTimeout(this._queueNotifyTimer)
    this._queueNotifyTimer = setTimeout(this._notify.bind(this), 100)
  },

  _addData(data, countData) {
    const listIdsToRuleCounts = {}
    const listFacets = _.get(countData, `facetCounts.facetPivot.${COUNT_FACET_PIVOT}`, [])

    for (var i = 0; i < listFacets.length; i++) {
      const listFacet = listFacets[i]
      const listId = listFacet.value.replace(/"/g, '')
      // const enabledPublished = _.find(_.get(_.find(listFacet.pivot, {value: `${ INTEL_STATES.enabled.enum }`}), 'pivot', []), {value: `${ INTEL_MODE_OPTIONS.published.enum }`})
      const enabledPublished = _.find(
        _.get(
          _.find(listFacet.pivot, { value: `${INTEL_STATE_OPTIONS.ENABLED}` }),
          'pivot',
          []
        ),
        { value: `${INTEL_MODES.PUBLISHED}` }
      )

      listIdsToRuleCounts[listId] = {
        count: listFacet.count,
        enabledPublishedCount: enabledPublished ? enabledPublished.count : 0,
        statusCounts: _.reduce(
          INTEL_STATE_OPTIONS,
          (output, status) => {
            const facetCount = _.find(listFacet.pivot, { value: status })
            output[status] = facetCount ? facetCount.count : 0
            return output
          },
          {}
        )
      }
    }

    this._nonEditableListIds = []
    let _hasDefault = false
    _state.lists = _.map(_state.lists.concat(data.lists), list => {
      list._isUserList = list.owner !== PROTECTWISE_OWNED_CID
      if (
        list.forCustomers &&
        list.forCustomers.indexOf(THREAT_TEAM_CID) !== -1
      ) {
        // This is a threat team private list
        list._isUserList = true
      }
      list._isEditableByCustomer = this.canCurrentCustomerEditList(list.id)
      if (!list._isEditableByCustomer) {
        this._nonEditableListIds.push(list.id)
      }
      list._ruleCount = listIdsToRuleCounts[list.id]
        ? listIdsToRuleCounts[list.id].count
        : 0
      list._enabledPublishedCount = listIdsToRuleCounts[list.id]
        ? listIdsToRuleCounts[list.id].enabledPublishedCount
        : 0
      list._ruleCountsByStatus = listIdsToRuleCounts[list.id]
        ? listIdsToRuleCounts[list.id].statusCounts
        : {}
      // list._type = "ids" // FIXME?
      list._isDefaultTargetList =
        !_hasDefault &&
        list.tags &&
        list.tags.indexOf(INTEL_LIST_QUICK_ADD_DEFAULT_TAG) !== -1
      _hasDefault = list._isDefaultTargetList
      list.listPurpose =
        typeof list.listPurpose === 'string'
          ? list.listPurpose
          : INTEL_LIST_PURPOSE_ENUM_TO_ID[list.listPurpose] || null
      return list
    })

    // If no user-set default exists, use the auto-cap list if it's present
    // if (!_hasDefault) {
    //   let _autoCapList = _.find(_state.lists, list => list.listPurpose === INTEL_LIST_PURPOSE_IDS.RULECAPPINGWHITELIST)
    //   if (_autoCapList) {
    //     _autoCapList._isDefaultTargetList = true
    //   }
    // }
  },

  _setIsSavingListId(listId, isSaving) {
    if (isSaving) {
      _state.savingListIds.push(listId)
      _state.savingListIds = _.uniq(_state.savingListIds)
    } else {
      _state.savingListIds = _.uniq(_.pull(_state.savingListIds, listId))
    }
    this._queueNotify()
  },

  // Action handlers
  onInit(customerId) {
    _state.currentCustomerId = customerId
    this._notify()
  },

  _getRuleCountRequest() {
    return requestGet(
      null,
      `intel/lists/rules?rows=0&start=0&facetPivot=${COUNT_FACET_PIVOT}`
    )
  },

  onCheckingRuleCounts() {
    // Poll for list rule counts whenever ruleTable is polling
    this._getRuleCountRequest().then(data => {
      this._addData({ lists: [] }, data) // Only update list counts
      this._queueNotify()
    })
    // .catch((xhrOnFail, textStatus) => {
    //   // Ignore poll failures?
    // })
  },

  onLoadAllLists() {
    _state.lists = []
    _state.error = null
    _state.isLoading = true
    this._queueNotify()

    Promise
      .all([
        requestGet(`ims_get_lists`, 'intel/lists?rows=200&sort=listId'),
        this._getRuleCountRequest()
      ])
      .then(([listData, countData]) => {
        this._addData(listData, countData)
        // Auto-select first lists

        // if (_state.selectedListIds.length === 0 && _state.lists.length > 0) {
        //   // const sortedLists = _.sortBy(_state.lists, 'name').reverse()
        //   const userLists = _.filter(_state.lists, {_isUserList: true})
        //   if (userLists.length > 0) {
        //     // Default selection is all user lists
        //     this.onSetSelectedLists(
        //       _.map(_.take(userLists, MAX_OVERVIEW_SELECTED_LISTS), list => list.id)
        //     )
        //   }
        //   else {
        //     // Fallback to all other lists
        //     this.onSetSelectedLists(
        //       _.map(_.take(_state.lists, MAX_OVERVIEW_SELECTED_LISTS), list => list.id)
        //     )
        //   }
        // }
      })
      .catch(restError => {
        _state.error = restError
        this._queueNotify()
      })
      .then(() => {
        _state.isLoading = false
        this._queueNotify()
      })
  },

  onRoute(queryParams = {}) {
    const { listId = [] } = queryParams
    _state.selectedListIds = _.isArray(listId) ? listId : [listId]
    this._notify()

    let pwCt = 0
    _.forEach(_state.selectedListIds, _listId => {
      const list = this.getListById(_listId)
      if (list && !list._isUserList) {
        pwCt += 1
      }
    })
    AnalyticsActions.event({
      eventCategory: 'intel',
      eventAction: 'view_lists',
      eventLabel:
        pwCt === 0
          ? 'customer_lists'
          : pwCt === _state.selectedListIds.length
            ? 'protectwise_lists'
            : 'mixed_selection',
      eventValue: _state.selectedListIds.length,
      nonInteraction: true
    })
  },

  onSwitchMainTab(newTab, extraParams = {}) {
    const { listId, activeType } = urlHistoryUtil.getQueryParams()
    const newParams = {
      activeType: activeType || null,
      listId: listId
      // listId: newTab === 'overview' && listId && listId.length ? _.take(listId, MAX_OVERVIEW_SELECTED_LISTS) : listId
    }
    urlHistoryUtil.updatePath(
      newTab,
      1,
      false,
      _.assign(extraParams, newParams)
    )
    if (newTab === 'overview') {
      // Pick up any changes just made in "manage" tab
      IntelManagementActions.refreshRecentChanges()
    }
  },

  onReset() {
    _state = _.cloneDeep(DEFAULT_STATE)
    this._notify()
  },

  onToggleListSelected(listId) {
    // if (!listId || (_state.selectedListIds.length === 1 && _state.selectedListIds[0] === listId)) {
    //   // Do not allow removing the only selected list
    //   return
    // }
    urlHistoryUtil.updateParamArray('listId', listId)
  },

  onSetSelectedLists(listId = []) {
    if (listId.length === 0) {
      urlHistoryUtil.removeParams(['listId'], false)
    } else {
      urlHistoryUtil.mergeParams({ listId }, false)
    }
  },

  onSelectEditableLists(cb = _.noop) {
    this.onSetSelectedLists(
      _.map(
        _.filter(_state.lists, list => list._isEditableByCustomer),
        list => list.id
      )
    )
    cb()
  },

  onCreateList(listName, forCustomers) {
    if (!listName) {
      return
    }

    // INTEL_NEW_LIST_ID is pseudo-id for the "new" list being modified
    this._setIsSavingListId(INTEL_NEW_LIST_ID, true)

    const newListPostData = {
      name: listName,
      description: '--', // TODO?
      vendor: '--' // TODO?
    }

    if (forCustomers) {
      console.warn('Creating list with a forCustomers value', forCustomers)
      newListPostData.forCustomers = forCustomers
    }

    const req = requestPost(
      `ims_create_list`,
      'intel/lists',
      newListPostData
    )

    req.then(data => {
      // _state.selectedListId = data.id // Select new list
      IntelManagementActions.toggleListSelected(data.id)
      this.onLoadAllLists() // refresh all user lists
      this._setIsSavingListId(INTEL_NEW_LIST_ID, false)
      this._queueNotify()
    },
      restError => {
        CommonViewActions.Notification.addRestError(
          restError,
          null,
          TEST_ID_CREATE_LIST_ERROR
        )
        this._setIsSavingListId(INTEL_NEW_LIST_ID, false)
        this.onLoadAllLists() // refresh all user lists
        this._queueNotify()
      })
    AnalyticsActions.event({
      eventCategory: 'intel',
      eventAction: 'list_save_new',
      eventLabel:
        _state.nonEditableSelectedListIds.length === _state.lists.length
          ? 'first_new'
          : null
    })
  },

  onUpdateList(listId, updatedList, canChangeAvailability) {
    if (!listId || !updatedList) {
      return
    }

    const allowedProps = _.clone(API_ALLOWED_PROPS_INTEL_LIST)
    if (canChangeAvailability) {
      allowedProps.push('forCustomers')
    }

    const _sanitizedList = _.pick(
      _.assign({}, this.getListById(listId), updatedList),
      allowedProps
    )

    this._setIsSavingListId(listId, true)

    const req = requestPut(null, `intel/lists/${listId}`, _sanitizedList)

    req.then(() => {
      this.onLoadAllLists() // refresh all user lists
      this._setIsSavingListId(listId, false)
      this._queueNotify()
    },
      restError => {
        CommonViewActions.Notification.add({
          type: 'error',
          heading: restError.heading,
          message: restError.message,
          dismissTimer: 15000
        })
        this.onLoadAllLists() // refresh all user lists
        this._setIsSavingListId(listId, false)
        this._queueNotify()
      })
    AnalyticsActions.event({
      eventCategory: 'intel',
      eventAction: 'list_update'
    })
  },

  onDeleteList(listId) {
    if (!listId) {
      return
    }
    if (_state.selectedListIds.indexOf(listId) !== -1) {
      IntelManagementActions.toggleListSelected(listId) // toggle
      // _state.selectedListId = null
    }
    this._setIsSavingListId(listId, true)

    const req = requestDelete(null, `intel/lists/${listId}`)

    req.then(() => {
      this.onLoadAllLists() // refresh all user lists
      IntelManagementActions.loadAllSubscriptions() // Reload subscriptions
      this._setIsSavingListId(listId, false)
      this._queueNotify()
    },
      restError => {
        CommonViewActions.Notification.add({
          type: 'error',
          heading: restError.heading,
          message: restError.message,
          dismissTimer: 15000
        })
        this.onLoadAllLists() // refresh all user lists
        this._setIsSavingListId(listId, false)
        this._queueNotify()
      })
    AnalyticsActions.event({
      eventCategory: 'intel',
      eventAction: 'list_delete'
    })
  },

  // Utils/getters
  validateListName(input) {
    const { lists } = _state
    const basicValidation = validateNonEmptyString(input)
    if (basicValidation) {
      return basicValidation
    }
    if (input && input.length > MAX_LIST_NAME_LENGTH) {
      return `Must be less than ${MAX_LIST_NAME_LENGTH} characters long.`
    }
    const listNames = _.map(lists, list => list.name.toLowerCase())
    return lists && listNames.indexOf(input.toLowerCase()) === -1
      ? null
      : `A list named "${input}" already exists. Please choose a unique name.`
  },

  getListById(listId) {
    return _.find(_state.lists, { id: listId })
  },

  getListsWithTag(tagName) {
    return _.filter(_state.lists, list => {
      return list.tags && list.tags.indexOf(tagName) !== -1
    })
  }
})

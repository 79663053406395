import _ from 'lodash'
import Reflux from 'reflux'
import ProfilerActions from 'actions/ProfilerActions'
import CommonViewActions from 'actions/CommonViewActions'
import AnalyticsActions from 'actions/AnalyticsActions'
import {requestDelete, requestGet, requestPost, requestPut} from 'utils/restUtils'
import SensorStore from 'stores/SensorStore'
import UserStore from 'stores/UserStore'
import {MIN_SENSOR_VERSION_FOR_PERCEPTIVE_CAPTURE} from 'pwConstants'
import semverCompare from 'semver-compare'

// If true, exception changes will be applied to the current profile immediately, anticipating a successful HTTP request
const OPTIMISTIC_EDITS = false

const DEFAULT_NEW_PROFILE = {
  'name': 'Default Profile',
  'default_mode': 'packet'
}

const DEFAULT_STATE = {
  isLoadingProfiles: false,
  isLoadingCurrent: false,
  isSaving: false,
  currentProfileId: null,
  currentProfile: {},
  captureProfiles: [],
  scrollToNewExceptionId: null,
  highlightDraft: false,
  totalProfileCount: 0 // Includes children, used for height
}

let _state = _.cloneDeep(DEFAULT_STATE)

export const PERCEPTIVE_PSEUDO_PROFILE_ID = -2

export const PERCEPTIVE_PSEUDO_PROFILE = {
  children: [],
  count: 0,
  default_mode: "none",
  exceptions: [],
  id: PERCEPTIVE_PSEUDO_PROFILE_ID,
  name: "Perceptive Capture (beta)",
  parent_id: null,
  sensor_ids: [],
  stream_head_cutoff: null,
  updated_at: +new Date(`July 1, 2017`)
}




// const _add = (a, b) => (a || 0) + (b || 0)

const _getDraftName = name => `Draft of ${ name }`


function _getCopyName (profile) {
  let _nameBase = `${ profile.name }`
  if (profile.parent_id) {
    // This is a copy of a draft. Re-form the name based on the parent's latest name
    let _parent = _.find(_state.captureProfiles, { id: profile.parent_id })
    _nameBase = `Draft of ${ _parent.name }`
  }
  // Check against other names
  let _name = _nameBase
  let i = 0
  while(_nameExists(_name)) {
    _name = `${ _nameBase }(${ ++i })`
  }
  return _name
}

function _nameExists (name) {
  // Only comparing against top level (non-drafts)
  let _names = _.pluck(_state.captureProfiles, 'name')
  return _names.indexOf(name) !== -1
}


function _updateException (newException, notifyErrors) {
  _state.isSaving = true
  CaptureProfileStore._queueNotify()
  let existingException = null

  if (newException.capture_profile_id) {
    // This was a raw exception update
    existingException = _.find(_state.currentProfile.exceptions, { id: newException.id })
  }
  else {
    newException.capture_profile_id = _state.currentProfile.id
    let _existingMatcher = newException.hasOwnProperty('protocol_id') ? {'protocol_id': newException.protocol_id} : {'ip_address': newException.ip_address}
    existingException = _.find(_state.currentProfile.exceptions, _existingMatcher)
  }

  if (existingException && existingException.id) {
    if (OPTIMISTIC_EDITS) {
      _state.currentProfile.exceptions = _.map(_state.currentProfile.exceptions, itm => {
        return itm.id === existingException.id ? _.assign(existingException, newException) : itm
      })
      CaptureProfileStore._queueNotify(true)
    }

    return requestPut(null, `capture-profiles/${ _state.currentProfile.id }/exceptions/${ existingException.id }`, {
      'mode': newException.mode
    })
      .then(data => {
        // replace existing exception in local collection with the newly updated version
        _state.currentProfile.exceptions = _.map(_state.currentProfile.exceptions, itm => {
          return itm.id === existingException.id ? data : itm
        })
        _state.isSaving = false
        CaptureProfileStore._queueNotify(true)
      })
      .catch(restError => {
        if (notifyErrors) {
          CommonViewActions.Notification.add({
            heading: "Error updating exception",
            message: `Could not update exception ID ${ existingException.id } to the mode "${ newException.mode }"`,
            messageDetails: restError.message,
            type: 'warning',
            dismissTimer: 60000
          })
        }
        _state.isSaving = false
        CaptureProfileStore._queueNotify()
      })
  }
  else {
    if (OPTIMISTIC_EDITS) {
      _state.currentProfile.exceptions.push(_.assign(newException, {}))
      CaptureProfileStore._queueNotify(true)
    }

    return requestPost(null, `capture-profiles/${ _state.currentProfile.id }/exceptions`, newException)
      .then(data => {
        _state.currentProfile.exceptions.push(data)
        _state.isSaving = false
        _state.scrollToNewExceptionId = data.ip_address || data.protocol_id
        CaptureProfileStore._queueNotify(true)
      })
      .catch(restError => {
        if (notifyErrors) {
          let _resp = {}
          try {
            _resp = JSON.parse(restError.responseText)
          }
          catch (e) {
            //continue...
          }

          // Handle Planck Validation errors and DB INET constraint errors
          const statusCode = restError.response.status
          if (statusCode === 400 || (statusCode === 500 && _resp && _resp.error && _resp.error.message && _resp.error.message.indexOf('inet') !== -1)) {
            // Special handling for manual input IP/CIDR rule validation errors
            CommonViewActions.Notification.add({
              heading: "Invalid IP/CIDR Excpetion",
              message: `"${ newException.ip_address || newException.protocol_id }" is not a valid IP Address or CIDR Block`,
              messageDetails: restError.message,
              type: 'warning',
              dismissTimer: 60000
            })
          }
          else {
            CommonViewActions.Notification.add({
              heading: "Error saving new exception",
              message: `Could not save new exception for "${ newException.ip_address || newException.protocol_id }" with mode "${ newException.mode }"`,
              messageDetails: restError.message,
              type: statusCode >= 500 ? 'error' : 'warning',
              dismissTimer: 60000
            })
          }
        }
        _state.isSaving = false
        CaptureProfileStore._queueNotify()
      })
  }
}






function _deleteException (itm) {
  _state.isSaving = true
  CaptureProfileStore._queueNotify()

  let exceptionId = false
  if (itm.capture_profile_id) {
    // Clicks from the list will include an capture_profile_id
    exceptionId = itm.id
  }
  else {
    let _fallbackMatcher = itm.isIp ? { ip_address: itm.exceptionVal } : { protocol_id: itm.id }
    let _existing = _.find(_state.currentProfile.exceptions, _fallbackMatcher)
    if (_existing) {
      exceptionId = _existing.id
    }
  }

  if (exceptionId) {
    if (OPTIMISTIC_EDITS) {
      _state.currentProfile.exceptions = _.reject(_state.currentProfile.exceptions, { 'id': exceptionId })
      CaptureProfileStore._queueNotify(true)
    }

    return requestDelete(null, `capture-profiles/${ _state.currentProfile.id }/exceptions/${ exceptionId }`)
      .then(() => {
        _state.currentProfile.exceptions = _.reject(_state.currentProfile.exceptions, { 'id': exceptionId })
        _state.isSaving = false
        CaptureProfileStore._queueNotify(true)
      })
      .catch(() => {
        _state.isSaving = false
        CaptureProfileStore._queueNotify()
      })
  }
}



// ###
// Datastore
// ###

const CaptureProfileStore = Reflux.createStore({
  getInitialState() {
    return _state
  },

  listenables: [
    ProfilerActions.Profiles,
    ProfilerActions.Exceptions,
    ProfilerActions.Sensor
  ],

  init() {
  },


  onGetAll() {
    this._queryList().then(() => {
      if (!_state.currentProfileId && !_state.captureProfiles.length > 0 && _state.captureProfiles[0].id) {
        this.onSelect(_state.captureProfiles[0].id) // Select the applied profile
      }
    })
    // this._getAveragePcapCompressionRatio(sensorId) if sensorId
  },

  _queryList() {
    _state.isLoadingProfiles = true
    this._queueNotify()
    // Return XHR promise in for callers
    const _req = requestGet('get_capture_profile_list', `capture-profiles?include=sensor_count,sensor_ids,children`)
    _req
      .then(data => {
        _state.captureProfiles = _.sortBy(data, 'name')
        _state.totalProfileCount = data.length + _.reduce(data, ((total, p) => total + p.children.length), 0)
        this._queueNotify()
      })
      .catch((err) => {
        throw err
      })
      .then(() => {
        _state.isLoadingProfiles = false
        this._queueNotify()
      })
    return _req
  },

  _queryCaptureProfile(profileId) {
    if ((!profileId && profileId !== 0) || profileId === PERCEPTIVE_PSEUDO_PROFILE_ID) {
      console.warn('Tried to request an undefined profile: ', profileId)
      return
    }
    return requestGet('get_capture_profile_' + profileId, `capture-profiles/${ profileId }?include=exceptions,children,sensor_count,sensor_ids`)
  },

  onSelect(profileId) {
    if (profileId === PERCEPTIVE_PSEUDO_PROFILE_ID) {
      _state.currentProfile = PERCEPTIVE_PSEUDO_PROFILE
      this._queueNotify(true)
      return
    }

    _state.currentProfileId = profileId
    _state.isLoadingCurrent = true
    this._queueNotify()
    const _req = this._queryCaptureProfile(profileId)
    _req
      .then(newProfile => {
        _state.currentProfile = newProfile
        this._queueNotify(true)
      })
      .catch(() => {})
      .then(() => {
        _state.isLoadingCurrent = false
        this._queueNotify()
      })
    return _req
  },


  _updateProfile(profile) {
    _state.isSaving = true
    this._queueNotify()
    return requestPut('update_capture_profile_' + profile.id, `capture-profiles/${ profile.id }`, profile)
      .then(updatedProfile => {
        this._queryList().then(() => {
          this.onSelect(updatedProfile.id) // Select new profile
        })
      })
      .catch(() => {})
      .then(() => {
        _state.isSaving = false
        this._queueNotify()
      })
  },

  _prepareProfileForUpdate(profile, includeExceptions = false) {
    let _fields = ['default_mode', 'name', 'id', 'parent_id']
    if (includeExceptions) {
      _fields.push('exceptions')
    }
    return _.pick(profile, _fields)
  },

  onRename(newName) {
    if (!newName) {
      return
    }
    let _primaryId = _state.currentProfile.id
    let _draftId = null
    if (_state.currentProfile.parent_id) {
      // This is a draft
      _primaryId = _state.currentProfile.parent_id
      _draftId = _state.currentProfile.id
    }
    else if (_state.currentProfile.children.length > 0) {
      // This is a parent
      _draftId = _state.currentProfile.children[0].id
    }
    // Rename currentProfile and any drafts
    if (_draftId != null) {
      // Rename both parent and child if parentId supplied
      return this._updateProfile({
        id: _draftId,
        name: _getDraftName(newName)
      })
    }
    return this._updateProfile({
      id: _primaryId,
      name: newName
    })
  },

  // Update the current profile with a partial set of options
  onUpdate(partialProfileSettings, includeExceptions = false, skipDraftCheck = false) {
    // return unless _state.currentProfile and _state.isSaving is false
    if (_state.isSaving || !_state.currentProfile) {
      return
    }
    if (partialProfileSettings.default_mode) {
      AnalyticsActions.event({
        eventCategory: 'profiler',
        eventAction: 'capturemode',
        eventLabel: `default-${ partialProfileSettings.default_mode }`
      })
    }
    if (skipDraftCheck) {
      let _profileToSave = _.assign(_state.currentProfile, partialProfileSettings)
      return this._updateProfile(this._prepareProfileForUpdate(_profileToSave, includeExceptions))
    }
    else {
      return this._checkIfDraftNeeded(_state.currentProfile, verifiedProfile => {
        let _profileToSave = _.assign(verifiedProfile, partialProfileSettings)
        return this._updateProfile(this._prepareProfileForUpdate(_profileToSave, includeExceptions))
      })
    }
  },

  onSave(newProfile) {
    if (_state.isSaving || !newProfile) {
      return
    }
    _state.isSaving = true
    this._queueNotify()
    let profileToSave = this._prepareProfileForUpdate(newProfile)
    profileToSave.name = _getCopyName(profileToSave) // Prevent manual name collision
    return requestPost('save_new_capture_profile', "capture-profiles", profileToSave)
      .then(updatedProfile => {
        this._queryList().then(() => {
          // updatedProfile.exceptions = [] #empty excpetions for new insert
          this.onSelect(updatedProfile.id) // Select new profile
          this._queueNotify(true)
        })
      })
      .catch(() => {})
      .then(() => {
        _state.isSaving = false
        this._queueNotify()
      })
  },

  onDelete(profileId, isOverwrite = false) {
    if (!profileId) {
      return
    }
    _state.isSaving = true
    this._queueNotify()

    if (!isOverwrite) {
      AnalyticsActions.event({
        eventCategory: 'profiler',
        eventAction: 'delete_profile'
      })
    }

    let _profile = _.find(_state.captureProfiles, { id: profileId }) // If this is a top-level profile, find its record
    // Delete drafts first
    let _reqs = []
    if (_profile && _profile.children) {
      _reqs = _.map(_profile.children, p => {
        return requestDelete('delete_capture_profile' + p.id, `capture-profiles/${ p.id }`)
      })
    }
    _reqs.push(requestDelete('delete_capture_profile', `capture-profiles/${ profileId }`))
    // requestDelete('delete_capture_profile', "capture-profiles/${ profileId }")
    return Promise.all(_reqs)
      .then(() => {
        // Reload list and select the first item in the list
        this._queryList().then(() => {
          if (_state.currentProfile.id === profileId) {
            _state.currentProfile = {} // if deleted, select the first profile in the list
            if (_state.captureProfiles.length > 0) {
              this.onSelect(_state.captureProfiles[0].id)
            }
          }
        })
      })
      .catch(restError => {
        console.error('Failed Capture Profile Delete:', restError)
      })
      .then(() => {
        _state.isSaving = false
        this._queueNotify()
      })
  },

  // "Save As New" -> save a draft as a new top-level
  onExtractDraft(updateName = true) {
    if (!_.isEmpty(_state.currentProfile)) {
      if (_state.currentProfile.parent_id == null) {
        console.warn('Draft extraction attempted on a top-level profile', _state.currentProfile)
      }
      else {
        let _parent = _.find(_state.captureProfiles, { id: _state.currentProfile.parent_id })
        if (!_parent) {
          console.warn('No parent to overwrite!')
        }
        let _newName = _parent.name
        if (updateName) {
          _newName = _getCopyName({ name: "(New) " + _parent.name })
        }
        AnalyticsActions.event({
          eventCategory: 'profiler',
          eventAction: 'promote_draft_profile',
          eventLabel: updateName ? 'new' : 'overwrite'
        })
        return this.onUpdate({ parent_id: null, name: _newName }, false, true)
      }
    }
  },

  // Save the current selected draft profile, overwriting the parent profile
  onSaveDraftOverwrite() {
    if (!_.isEmpty(_state.currentProfile)) {
      if (_state.currentProfile.parent_id == null) {
        console.warn('Draft overwrite attempted on a top-level profile', _state.currentProfile)
      }
      else {
        // Continue overwrite
        // Copy latest parent title, Delete parent, and remove parent_id from former draft version
        let _parent = _.find(_state.captureProfiles, {id: _state.currentProfile.parent_id})
        if (!_parent) {
          console.warn('No parent to overwrite!')
        }
        else if (_parent.count === 0) {
          this.onExtractDraft(false).then(() => {
            // Update was successful, safe to delete former parent
            return this.onDelete(_parent.id, true)
          })
        }
        else {
          console.error('Attempting to overwrite an applied profile!', _parent)
        }
      }
    }
  },

  onCopy(srcProfile, isDraft = false, callback) {
    if (srcProfile && _state.isSaving === false) {
      _state.isSaving = true
      this._queueNotify()
      // If not copying current profile, we must first retrieve its exceptions.
      if (srcProfile.id != null && srcProfile.id !== _state.currentProfile.id) {
        this._queryCaptureProfile(srcProfile.id).then((srcProfileData) => {
          return this._saveCopy(srcProfileData, isDraft, callback)
        })
      }
      else {
        return this._saveCopy(_state.currentProfile, isDraft, callback)
      }
    }
  },

  _saveCopy(srcProfile, isDraft = false, callback) {
    let copiedProfile = _.assign(_.pick(srcProfile, "default_mode", "stream_head_cutoff", ""), {
      name: isDraft ? _getDraftName(srcProfile.name) : _getCopyName(srcProfile),
      default_mode: srcProfile.default_mode,
      exceptions: _.map(srcProfile.exceptions, _.partialRight(_.omit, "id", "capture_profile_id"))
    })

    if (isDraft) {
      copiedProfile.parent_id = srcProfile.id // New draft of a parent
      // Enforce only a single draft per capture profile
    }

    AnalyticsActions.event({
      eventCategory: 'profiler',
      eventAction: isDraft ? 'create_draft' : 'copy_profile'
    })

    requestPost('save_new_capture_profile_copy', "capture-profiles", copiedProfile)
    .then((updatedProfile) => {
      this._queryList().then(() => {
        this.onSelect(updatedProfile.id).then(() => {
          if (callback) {
            callback(updatedProfile)
          }
        })
      })
    })
    .catch(() => {
      // TODO handle fail
      // _state.isSaving = false
      // this._queueNotify()
    })
    .then(() => {
      _state.isSaving = false
      this._queueNotify()
    })
  },

  _checkIfDraftNeeded(profile, callbackAction) {
    if (!callbackAction || !profile || !profile.id) {
      return
    }
    // Prevent any updates from modifying currently in-use capture profiles.
    // Run this method before any action that could invalidate a capture profile that is in-use by one or more sensors.
    // If need be, create a new draft, or return an existing draft for pending edit actions
    this._queryCaptureProfile(profile.id).then((latestProfile) => {
      // profileSensorCount = _.find(_state.captureProfiles, {id: _state.currentProfile.id}).count
      if (latestProfile.parent_id != null || latestProfile.count === 0) {
        // Zero or falsy value should allow the action
        callbackAction(latestProfile) // modify at will. Pass bool value to callback indicating that no copy was required
      }
      else {
        // Cannot modify this profile, need to apply change to a draft
        if (latestProfile.children.length > 0) {
          // Draft already exists, apply the update to the existing draft after selecting it
          return this.onSelect(latestProfile.children[0].id).then((verifiedDraftProfile) => {
            _state.highlightDraft = true
            callbackAction(verifiedDraftProfile)
          })
        }
        else {
          return this.onCopy(latestProfile, true, (newDraftProfile) => {
            _state.highlightDraft = true
            callbackAction(newDraftProfile)
          })
        }
      }
    })
  },


  // ###
  // Handle exception actions
  // ###

  onUpdateException(newException, notifyErrors = false) {
    if (!_state.currentProfile) {
      return
    }

    AnalyticsActions.event({
      eventCategory: 'profiler',
      eventAction: 'capturemode',
      eventLabel: `${ newException.ip_address ? (newException.ip_address.indexOf('/') >= 0 ? 'subnet' : 'ip') : 'protocol' }-${ newException.mode }`
    })

    this._checkIfDraftNeeded(_state.currentProfile, () => {
      return _updateException(newException, notifyErrors)
    })
  },

  onDeleteException(proto) {
    if (!proto) {
      return
    }
    this._checkIfDraftNeeded(_state.currentProfile, () => {
      _deleteException(proto)
    })
  },

  onBulkUpdateException(family, mode) {
    // return unless _state.currentProfile and family and mode
    if (!_state.currentProfile || !family || !mode) {
      return
    }

    AnalyticsActions.event({
      eventCategory: 'profiler',
      eventAction: 'capturemode',
      eventLabel: `family-${ mode }`
    })

    this._checkIfDraftNeeded(_state.currentProfile, (verifiedProfile) => {
      _state.isSaving = true
      this._queueNotify()
      let _actionPromise = null
      if (family.modeCounts && family.modeCounts[mode] === family.protocols.length) {
        // This was a full family dot. Delete all child exceptions
        _actionPromise = requestDelete(null, `capture-profiles/${ verifiedProfile.id }/exceptions`, {
          "family_id": family.id
        })
      }
      else {
        // Bulk family insert
        _actionPromise = requestPost(null, `capture-profiles/${ verifiedProfile.id }/exceptions`, {
          "family_id": family.id,
          "mode": mode
        })
      }

      _actionPromise
      .then(() => {
        // this._getCurrentProfileExceptions(verifiedProfile.id)
        this.onSelect(verifiedProfile.id) // Select to re-query the full exception list
      })
      .catch(() => {
      })
      .then(() => {
        _state.isSaving = false
        this._queueNotify()
      })
    })
  },

  onReset() {
    _state.currentProfile = {}
  },

  _notify(exceptionsUpdated) {
    _state.exceptionsUpdated = exceptionsUpdated
    _state._updated = Date.now()
    try {
      this.trigger(_state)
    } finally {
      _state.exceptionsUpdated = false // Always reset to false after emitting
      _state.highlightDraft = false
      _state.scrollToNewExceptionId = null // Always reset this
    }
  },

  _queueNotify(exceptionsUpdated) {
    this._notify(exceptionsUpdated)
    // #Separate times for exceptionsUpdated (don't allow non-updated notifys to clobber ones that need to get through)
    // // if exceptionsUpdated
    // //   this._notify(true)
    // // else
    // //   clearTimeout(this._queueNotifyTimer)
    // //   this._queueNotifyTimer = setTimeout(this._notify.bind(this., false), 100)
  },

  getState() {
    return _state
  }
})

export default CaptureProfileStore

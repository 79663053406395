/**
 * Parent viewStore for all child SitRep Summary components/views
 */
import {
  observable,
  action
} from 'mobx'
import {
  Store
} from 'stores/mobx/StoreManager'

export default class SitRepSummaryStore extends Store {
  @observable startTime
  @observable endTime

  @action.bound
  loadTrialReportData ([startTime, endTime]) {
    this.startTime = startTime
    this.endTime = endTime
  }
}
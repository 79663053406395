
import {observable} from 'mobx'
import {schemer} from 'pw-schemer-mobx'
import DeviceAttribute from './DeviceAttribute'
import {Priority} from 'typings/devices/device'

export class PriorityAttr extends DeviceAttribute {
  @observable
  @schemer.enum({
    options: Priority
  })
  value: Priority
}
import T from 'prop-types'
import React from 'react'

export const SUPPORT_DISMISSED_NOTIFICATIONS_LS_KEY = "dismissedNotifications"

export const SUPPORT_NOTIFICATION_SEVERITY_OPTIONS = [
  'low',
  'medium',
  'high'
]

export const SUPPORT_NOTIFICATION_SHAPE = T.shape({
  created_at: T.number,
  created_by: T.number, // UserId
  end_at: T.number,
  id: T.number,
  link: T.string,
  message: T.string,
  severity: T.oneOf(SUPPORT_NOTIFICATION_SEVERITY_OPTIONS),
  silo: T.string,
  start_at: T.number,
  updated_at: T.number,
  updated_by: T.number, // UserId
})
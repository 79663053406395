import _ from 'lodash'
import React from 'react'
import T from 'prop-types'
import { AnimateHeight, TextOverflow } from 'react-base'
import DataCell from './DataCell'
import IntelSourceTag from './IntelSourceTag'
import { VALUE_TYPE_OPTIONS } from 'constants/searchableValueConstants'
import { formatDate } from 'utils/timeUtils'
import SearchableValue from 'components/values/SearchableValue'



class AwsDeviceData extends React.Component {
  static propTypes = {
    data: T.func, //data accessor from IntelCardStore
    sensorId: T.string
  }

  _formatInstanceId = instanceId =>
    <SearchableValue
      value={instanceId}
      valueType={VALUE_TYPE_OPTIONS.AWS_DEVICE}
      relatedData={{sensorIds: [this.props.sensorId]}}
    >
      {instanceId}
    </SearchableValue>

  _formatTags = tags =>
    _.sortBy(tags, tag => tag.key.toLowerCase())
      .map(tag =>
        <TextOverflow key={tag.key}>
          <span className="keyval_lbl">{tag.key}:</span> {tag.value}
        </TextOverflow>
      )

  _formatInterfaces = iFaces =>
    iFaces.map(iFace =>
      <AwsInterface
        key={iFace.id}
        interface={iFace}
        startOpen={iFaces.length === 1}
      />
    )

  _renderField = (propName, label, formatter, defaultVal='Unknown') =>
    <DataCell
      label={label}
      value={this.props.data('aws.' + propName)}
      formatter={formatter}
      default={defaultVal}
    />

  render() {
    const renderField = this._renderField
    const root = this.props.data('aws')

    // Display nothing until we have an answer
    if (!root.isReady()) {
      return null
    }

    return <div className="device_source_summary">
      <h5>AWS Summary <IntelSourceTag source="AWS" /></h5>

      { // If the result object is empty, display a collapsed rollup message
        _.isEmpty(root.valueOf())
          ? <p className="no_data">No AWS data found for this device.</p>
          : <React.Fragment>
            {renderField('instanceId', 'Instance ID', this._formatInstanceId)}
            {renderField('imageId', 'Image ID')}
            <div className="grid">
              {renderField('instanceType', 'Instance Type')}
              {renderField('instanceState', 'Instance State')}
            </div>
            {renderField('launchTime', 'Launch Time', formatDate)}
            {renderField('availabilityZone', 'Availability Zone')}
            {renderField('tags', 'AWS Resource Tags', this._formatTags)}
            {renderField('networkInterfaces', '', this._formatInterfaces, null)}
          </React.Fragment>
      }
    </div>
  }
}


class AwsInterface extends React.Component {
  static propTypes = {
    interface: T.object,
    startOpen: T.bool
  }

  UNSAFE_componentWillMount () {
    this.setState({open: this.props.startOpen})
  }

  _toggleOpen = () => {
    this.setState({open: !this.state.open})
  }

  render() {
    const iFace = this.props.interface
    const open = this.state.open

    return <div className="aws_interface">
      <h6 onClick={this._toggleOpen} data-tooltip={`Click to ${open ? 'collapse' : 'expand'}`}>
        <span className={ `icon icon-triangle-r ${open ? 'open' : null}` } />
        {` Network Interface: ${iFace.id}`}
      </h6>
      <AnimateHeight>
        {open ? this.renderData(iFace) : null}
      </AnimateHeight>
    </div>
  }

  renderData(iFace) {
    const renderField = (label, content) =>
      <div className="cell">
        <span>{label}</span>
        <div className="cell_content">
          {_.isEmpty(content)
            ? 'Unknown'
            : _.isArray(content)
              ? content.map((item, i) => <TextOverflow key={i}>{item}</TextOverflow>)
              : <TextOverflow>{content}</TextOverflow>
          }
        </div>
      </div>

    return <React.Fragment>
      {renderField('Description', iFace.description)}
      {renderField('Owner ID', iFace.ownerId)}
      <div className="grid">
        {renderField('Subnet ID', iFace.subnetId)}
        {renderField('VPC ID', iFace.vpcId)}
      </div>
      {renderField('MAC Address', iFace.macAddress)}
      {renderField('Security Groups', _.pluck(iFace.groups, 'name'))}
      {renderField('IPV6', iFace.ipv6Addresses)}
      <div className="grid">
        {renderField('Private IPs', iFace.privateIpAddresses)}
        {renderField('Public IPs', iFace.ipAddress)}
      </div>
      <div className="grid">
        {renderField('Private DNS', iFace.privateDnsName)}
        {renderField('Public DNS', iFace.publicDnsName)}
      </div>
    </React.Fragment>
  }
}


export default AwsDeviceData

import Reflux from 'reflux'
import {graphSubscriber} from 'data/DataManager'
import {requestGet} from 'utils/restUtils'
import * as Immutable from 'immutable'
import _ from 'lodash'
import genericUtil from 'ui-base/src/util/genericUtil'
import moment from 'moment'
import CountersActions from 'actions/CountersActions'
import { keyIn } from 'utils/immutableUtils'

const {durations} = genericUtil
const COUNTER_STATE_UPDATE_INTERVAL = 5000 // all counter data will be refreshed at this interval, if each graph determines that it needs an update

function parseDurationString (timeString) {
  return timeString.replace(/-/g, '').split(' ')
}

function normalizeOptions (subscription) {
  const {
    endTime,
    windowSize,
    interval,
  } = subscription

  let _interval = interval
  if (typeof interval !== 'string') {
    const p = genericUtil.getIntervalFromTimespan(interval)
    _interval = `${p.intervalSize} ${p.intervalUnit}s` // Normalize space-delimited format
  }

  const [
    intervalSize,
    intervalUnit
  ] = parseDurationString(_interval)

  let _endTime = endTime //=== 0 ? +moment().startOf(intervalUnit) : endTim
  if (typeof endTime === 'string') {
    if (endTime === 'now') {
      _endTime = 0
    }
    else {
      // Snap "down" to interval floor
      const endInterval = +moment().startOf(intervalUnit)
      // Subtract relative endTime
      const [val, unit] = parseDurationString(endTime)
      _endTime = endInterval - durations(val, unit)
    }
  }

  let _windowSize = windowSize
  if (typeof windowSize === 'string') {
    const [val, unit] = parseDurationString(windowSize)
    _windowSize = durations(val, unit)
  }

  return {
    windowMs: _windowSize,
    intervalMs: durations(intervalSize, intervalUnit),
    endTimestamp: _endTime,
    intervalSize,
    intervalUnit
  }
}

function _fetchRestCounters (subscription, successHandler) {
  const {
    sensorIds,
    counterNames,
    stackBy='type',
    aggregate='sum'
  } = subscription

  const {
    windowMs,
    endTimestamp,
    intervalSize,
    intervalUnit
  } = normalizeOptions(subscription)

  const graphName = getGraphIdString(subscription)

  const end = endTimestamp === 0 ? Date.now() : endTimestamp

  const params = [
    `start=${end - windowMs}`,
    `end=${end}`,
    `counterType=${counterNames.join()}`,
    `interval=${intervalSize}${intervalUnit}`,
    `stackBy=${stackBy}`,
    `aggregate=${aggregate}`
  ]

  if (sensorIds) {
    params.push(`sensorId=${sensorIds.join()}`)
  }

  return requestGet(graphName, `counters?${params.join('&')}`)
    .then(successHandler)
}

function getGraphGroupOptions (subscription) {
  const {
    windowMs,
    endTimestamp
  } = normalizeOptions(subscription)

  const groupOptions = {
    windowSize: windowMs,
    endTime: endTimestamp
  }
  if (subscription.sensorIds && subscription.sensorIds.length > 0) {
    groupOptions.agentIds = subscription.sensorIds
  }
  return groupOptions
}

function getGraphOptions (subscription) {
  const {
    windowMs,
    intervalMs
  } = normalizeOptions(subscription)

  return {
    drawOptions: {
      type: 'stackedBar'
    },
    dataOptions: {
      type: 'counter',
      counterTypes: subscription.counterNames,
      stackBy: subscription.stackBy || 'type', // type, sensor
      aggregate: subscription.aggregate || 'sum', // mean, sum, p90?
      numPoints: Math.floor(windowMs / intervalMs),
      minWindowSize: windowMs,
      holdUntilDataLoaded: true
    }
  }
}

function getGraphIdString (subscription) {
  return _.values(subscription)
    .map(v => Array.isArray(v) ? v.join() : v)
    .join('_')
}

let timer = null // Global clock that syncs all subscriber updates to reduce the number of state changes
let raf = null
const FORCE_UPDATES = false

function queueTick () {
  clearTimeout(timer)

  timer = setTimeout(
    function requestUpdate () {
      cancelAnimationFrame(raf)
      raf = requestAnimationFrame(
        function requestUpdateRaF () {
          const now = Date.now()
          const stateSubs = _state.get('subscriptions')
          if (stateSubs && stateSubs.size > 0) {
            stateSubs.forEach((sub, subKey) => {
              // Only update graphs if another interval has transpired
              if (FORCE_UPDATES || now - +sub.get('lastUpdated') >= +sub.get('updateInterval')) {
                if (sub.get('usingGraphSubscriber')) {
                  // Live graph, use graphSubscriber's update mechanism
                  graphSubscriber.requestUpdate(sub.get('graphName'))
                }
                else {
                  // "Static" graph, re-query rest API
                  _fetchRestCounters(subKey.toJS(), sub.get('valueHandler'))
                }
              }
            })
            queueTick()
          }
        }
      )
    },
    COUNTER_STATE_UPDATE_INTERVAL
  )
}

function resetTimer () {
  clearTimeout(timer)
  cancelAnimationFrame(raf)
  timer = null
  raf = null
}

function createRestValueHandler (valueHandlerFn /*, aggregateType*/) {
  // Format REST value like a graphSubscriber message
  return function (counters=[]) {
    let maxValue = 0
    if (!counters || !counters.length) {
      return
    }

    const history = _.map(counters, tick => {
      if (!tick.value) {
        // if (aggregateType === 'mean') {
        //   // For mean counters that do not compute a total tick value
        //   tick.value = _.max(tick, (v, k) => k !== 'timestamp' ? v : -1)
        //   console.log('mean max!!', tick, tick.value)

        // }
        // else if (aggregateType === 'sum') {
        //   tick.value = _.reduce(tick, (o, v, k) => {return k !== 'timestamp' ? o + v : o}, 0)
        // }
        tick.value = _.reduce(tick, (o, v, k) => k === 'timestamp' ? o : v + o, 0)
      }
      tick.total = tick.total || tick.value // Match "total" key from graphSubscriber
      delete tick.value
      if (tick.total > maxValue) {
        maxValue = tick.total
      }
      return tick
    })

    const lastIdx = history.length - 1
    const last = history.length > 0 && history[lastIdx]
    const befLast = history.length > 1 && history[lastIdx - 1]
    const first = history.length > 0 && history[0]
    const interval = befLast !== last ? last.timestamp - befLast.timestamp : 0

    return valueHandlerFn({
      endTime: last.timestamp,
      history: history,
      interval: interval,
      maxValue: maxValue,
      startTime: first.timestamp,
      transitionDuration: 1000, //interval,
      transitionType: "fixed" //"play"
    })
  }
}

function _createNewSubscription (subscription) {
  const subscriptionJS = subscription.toJS()
  const useGraphSubscriber = subscriptionJS.useGraphSubscriber //subscription.endTime === 0

  const graphName = getGraphIdString(subscriptionJS)
  const graphGroupName = `group_${graphName}`

  const valueHandler = function (value) {
    CountersActions.dataUpdate(subscription, value)
  }

  const {
    intervalMs
  } = normalizeOptions(subscriptionJS)

  if (useGraphSubscriber) {
    // Use websocket graphsubscriber
    const streamGroup = graphSubscriber.createGroup(
      graphGroupName,
      getGraphGroupOptions(subscriptionJS)
    )
    const dataStream = graphSubscriber.subscribe(
      getGraphOptions(subscriptionJS, intervalMs),
      graphName,
      graphGroupName
    )

    return Immutable.Map({
      graphName: graphName,
      graphGroupName: graphGroupName,
      streamGroup: streamGroup,
      dataStream: dataStream,
      subscriberCt: 0,
      unsub: dataStream.onValue(valueHandler),
      updateInterval: intervalMs,
      lastUpdated: 0,
      usingGraphSubscriber: true
    })
  }
  else {
    // Use standard REST counters API, as graphSubscriber doesn't work properly for long time windows
    const restValueHandler = createRestValueHandler(valueHandler, subscriptionJS.aggregate)

    // Fire off initial GET request
    _fetchRestCounters(subscriptionJS, restValueHandler)

    return Immutable.Map({
      graphName: graphName,
      valueHandler: restValueHandler,
      subscriberCt: 0,
      updateInterval: intervalMs,
      lastUpdated: 0,
      usingGraphSubscriber: false
    })
  }
}

let _state = Immutable.Map({
  subscriptions: Immutable.Map(),
  counterData: Immutable.Map(),
  countersError: null
})

// Export as Reflux store
export default Reflux.createStore({
  getInitialState() {
    return _state
  },

  listenables: [
    CountersActions
  ],

  init() {
  },

  _normalizeArgs (subscriptions) {
    if (!Immutable.Iterable.isIterable(subscriptions)) {
      throw new Error("CountersStore._normalizeArgs: subscriptions should be an Immutable Iterable")
    }
    // Convert single-map payloads to a Set
    return Immutable.Map.isMap(subscriptions) ? Immutable.Set([subscriptions]) : subscriptions
  },

  onSubscribe (subscriptions) {
    subscriptions = this._normalizeArgs(subscriptions)
    const newSubs = subscriptions.reduce((outSubs, subscription) => {
      // Re-use subscription if already present, otherwise create a new one
      let sub = outSubs.has(subscription) ? outSubs.get(subscription) : _createNewSubscription(subscription, outSubs)
      sub = sub.set('subscriberCt', sub.get('subscriberCt') + 1) // Update subscriber count

      return outSubs.set(subscription, sub)
    }, _state.get('subscriptions'))
    this._updateSubs(newSubs)
  },

  onUnsubscribe (subscriptions) {
    subscriptions = this._normalizeArgs(subscriptions)
    const newSubs = subscriptions.reduce((outSubs, subscription) => {
      if (!_state.get('subscriptions').has(subscription)) {
        throw new Error("CountersStore.onUnsubscribe: Subscription not found")
      }

      let sub = outSubs.get(subscription)
      sub = sub.set('subscriberCt', sub.get('subscriberCt') - 1) // Update subscriber count

      if (sub.get('subscriberCt') === 0) {
        if (sub.get('usingGraphSubscriber') === true) {
          sub.get('unsub')() // Call unsubscribe function for graphSubscriber
          graphSubscriber.destroyGroup(sub.get('graphGroupName'))
        }

        return outSubs.delete(subscription)
      }
      else {
        return outSubs
      }
    }, _state.get('subscriptions'))

    // Remove pending data
    _state = _state.set('counterData', _state.get('counterData').filterNot(keyIn(subscriptions)))
    this._updateSubs(newSubs)
  },

  onDataUpdate (subscription, counterData) {
    _state = _state.setIn(['counterData', subscription], Immutable.fromJS(counterData))
    _state = _state.setIn(['subscriptions', subscription, 'lastUpdated'], Date.now())
    this._queueNotify()
  },

  _updateSubs (newSubs) {
    if (newSubs !== _state.get('subscriptions')) {
      _state = _state.set('subscriptions', newSubs)
      this._notify()
    }

    if (newSubs.size > 0 && timer === null) {
      // Start ticks
      queueTick()
    }
    else if (newSubs.size === 0) {
      resetTimer()
    }
  },

  _notify () {
    this.trigger(_state)
  },

  _queueNotify () {
    this._notify()
  },

  getState () {
    return _state
  }
})

import React from 'react'
import T from 'prop-types'
import cx from 'classnames'
import { getNameForISOCountryCode } from 'utils/geoDataUtil'

/*
 * This is some Webpack magic using their require.context() API to pre-resolve and import URLs
 * for all country flag images. These URLs can then be used as the src for images. Depending
 * on the webpack config, it may either be a URL loadable from the server or a base64 encoded data URI.
 */
const requireFlag = require.context("flag-icon-css/flags/4x3/", false, /\/[a-z]{2}\.svg$/)
const flagIcons = requireFlag.keys().reduce((out, key) => {
  const k = key.replace(/\.\/([a-z]{2})\.svg$/, "$1")
  const v = requireFlag(key)
  return Object.assign(out, { [k]: v.default })
}, {})

/**
 * Return a URL to the flag image for the given country code
 * @param countryCode - ISO alpha2 format
 * @return {string}
 */
export function getFlagIconUrl(countryCode) {
  return countryCode && flagIcons[countryCode.toLowerCase()]
}


/**
 * Format the label for a country. Includes the full name plus the country code in parens.
 * @param countryCode
 * @return {string}
 */
export function getCountryLabel(countryCode) {
  const name = getNameForISOCountryCode(countryCode)
  return name ? `${name} (${countryCode})` : countryCode
}


/**
 * React component for rendering a country's flag icon in a <img>
 */
export class CountryFlagIcon extends React.PureComponent {
  static propTypes = {
    code: T.string,
    showTooltip: T.bool
  }

  render() {
    const {code, showTooltip} = this.props
    return <img
      className="country_flag_icon"
      src={getFlagIconUrl(code)}
      data-tooltip={showTooltip ? getCountryLabel(code) : null}
    />
  }
}


/**
 * React component for rendering a country with flag icon and formatted name.
 */
export class CountryValue extends React.PureComponent {
  static propTypes = {
    code: T.string, //ISO alpha-2 country code
    customText: T.any, //allow overriding text part
    omitFlag: T.bool,
    className: T.string
  }

  render() {
    const {code, customText, omitFlag, className} = this.props
    return <span className={cx('country_value', className)}>
      {omitFlag ? null : <CountryFlagIcon code={code} />}
      {customText || getCountryLabel(code)}
    </span>
  }
}

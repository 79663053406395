import React from 'react'
import SensorLocationsForm from '../../sensor-config-form/SensorLocationsForm'


function SensorLocations(props) {
  const sensor = props.data('sensor').valueOf()

  return sensor &&
    <div className="intel_card intel_data_card intel_data_sensor_locations">
      <h3>Sensor Location Mappings</h3>

      <div className="intel_card_content">

        <p className="help_text">Here you can associate certain internal CIDR ranges as seen by this sensor to geographic locations. This can be used to plot internal hosts on maps in Killbox and Explorer, for example.</p>

        <SensorLocationsForm
          sensorId={sensor.id}
        />

      </div>
    </div>
}


export default SensorLocations

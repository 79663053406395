import _ from 'lodash'
import T from 'prop-types'
import React from 'react'
import GoogleMap from 'components/world-map/GoogleMap'
import {TextInput, CircleLoader, ScrollBars, Button} from 'react-base'
import {reverseGeocodeLatLon, reverseGeocodeBounds, geocodeAddress} from 'utils/geoDataUtil'
import {formatAddress} from 'utils/locationUtils'

const SELECTION_MARKER_ID = '_tempMarker'

export default class extends React.Component {
  static displayName = 'GoogleMapSelection';

  static propTypes = {
    initialValueMarker: T.shape({
      address: T.string,
      id: T.oneOfType([
        T.string,
        T.number
      ]),
      latitude: T.number,
      longitude: T.number
    }),
    inputMode: T.bool,
    markers: T.arrayOf(T.shape({
      text: T.string,
      latitude: T.number,
      longitude: T.number
    })),
    onCancelSelection: T.func,
    onMapClick: T.func,
    onSelect: T.func,
    selectionModeActive: T.bool, // If true, allows location selection
  };

  static defaultProps = {
    inputMode: true
  };

  state = {
    results: null,
    mapMarkers: [],
    isSearching: false,
    selectedResultIdx: null,
    drawMode: false
  };

  UNSAFE_componentWillMount() {
    this._debouncedSearchAddress = _.debounce(this.onSearchAddress, 1000)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.selectionModeActive || _.get(this.props.initialValueMarker, 'id') !== _.get(nextProps.initialValueMarker, 'id')) {
      this._resetSearch()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.inputMode && this.state.selectedResultIdx !== prevState.selectedResultIdx) {
      this._onSelect()
    }
  }

  _onSearchInputKeyup = (e) => {
    this._debouncedSearchAddress(e.target.value)
  };

  _onSaveClick = () => {
    this._onSelect()
  };

  _onSelect = () => {
    let {selectedResultIdx} = this.state
    if (!this.props.inputMode) {
      this._resetSearch()
    }

    let {state, props} = this
    if (selectedResultIdx === null || state.results.length === 0) { return }
    // if (state.selectedResultIdx === null || state.results.length === 0) { return }
    const onSelectFn = props.onSelect
    let selectedResult = state.results[selectedResultIdx]
    if (_.isFunction(onSelectFn) && selectedResult) {
      onSelectFn(selectedResult)
    }
  };

  _onCancelSelectionClicked = () => {
    this._removeSelectionMarker()
    this.props.onCancelSelection()
  };

  _resetSearch = () => {
    this.setState({
      selectedResultIdx: null,
      results: null,
      isSearching: false
    })
    this._removeSelectionMarker()
  };

  onSearchAddress = (addressString) => {
    if (addressString === this._latestAddressString) {
      return // Do not run duplicate searches
    }
    this._latestAddressString = addressString
    this._debouncedSearchAddress.cancel() // Cancel any pending debounced calls
    this._removeSelectionMarker()
    this.setState({
      results: null,
      isSearching: true,
      selectedResultIdx: null
    })
    var trimmed = addressString.trim()
    if (trimmed.length === 0) {
      return
    }
    geocodeAddress(trimmed)
      .then(this._handleSearchSuccess)
      .catch(this._handleSearchFailure)
  };

  _onMapClick = (latitude, longitude) => {
    if (this.props.selectionModeActive) {
      this._removeSelectionMarker()
      this.setState({
        results: null,
        isSearching: true
      })
      reverseGeocodeLatLon(latitude, longitude)
        .then(this._handleSearchSuccess)
        .catch(this._handleSearchFailure)
    }
    if (_.isFunction(this.props.onMapClick)) {
      this.props.onMapClick(latitude, longitude)
    }
  };

  _handleSearchSuccess = (results) => {
    let newSelectedIdx = null
    // Preselect first result
    if (results.length > 0) {
      this._addSelectionMarker(results[0])
      newSelectedIdx = 0
    }
    this.setState({
      selectedResultIdx: newSelectedIdx,
      results: results || [],
      isSearching: false
    })
  };

  _handleSearchFailure = (err) => {
    this.setState({
      results: null,
      isSearching: false
    })
  };

  _onResultClick = (result, resultIdx) => {
    this._addSelectionMarker(result)
    this.setState({
      selectedResultIdx: resultIdx
    })
  };

  _onInitialValueClick = () => {
    this._removeSelectionMarker()
    this.setState({
      selectedResultIdx: null
    })
  };

  _addSelectionMarker = (result) => {
    this.setState({
      mapMarkers: [
        {
          id: SELECTION_MARKER_ID,
          text: 'Selected Option: ' + result.formatted_address,
          latitude: result.lat,
          longitude: result.lng,
          className: "selected_option_marker",
          validBoundary: true,
          label: <span className="icon icon-checkmark" />
        }
      ]
    })

  };

  _removeSelectionMarker = () => {
    this.setState({
      mapMarkers: _.reject(this.state.mapMarkers, {id: SELECTION_MARKER_ID})
    })
  };

  _getAllMarkers = () => {
    let {props, state} = this

    let propMarkers = _.map(props.markers, marker => {
      // If selectionModeActive, prop markers should be subdued and not
      // used for bound calculation
      if (!!props.selectionModeActive) {
        marker.validBoundary = false
        marker.className = marker.className || ""
        marker.className += " subdued_marker"
      }
      return marker
    })

    let _markers = state.mapMarkers.concat(propMarkers)

    if (props.initialValueMarker) {
      _.pull(_markers, {id: props.initialValueMarker.id}) // Prevent duplicate if passed in standard props.markers
      _markers.push(_.assign(props.initialValueMarker, {
        text: 'Current Address: ' + props.initialValueMarker.address,
        className: 'initial_value_marker',
        validBoundary: true,
        label: <span className="icon icon-pushpin" />
      }))
    }

    return _markers
  };

  render() {
    let {state, props} = this

    return (
      <div className={ `map_selection_wrap ${ props.selectionModeActive ? 'selection_active' : '' }` }>
        { props.selectionModeActive ? (
          <div className="map_selection_column">
            { props.initialValueMarker ? (
              <div
                className="initial_value_address fade_in_right"
                onClick={ this._onInitialValueClick }
              >
                <div className="icon icon-pushpin" />
                <div>
                  <div className="initial_value_label">Current Location</div>
                  { _.map(formatAddress(props.initialValueMarker.address), (p, i) => <div key={ i }>{ p }</div>) }
                </div>
              </div>
            ) : null }
            <p><span className="muted">1</span> Click the map or search for addresses</p>
            <TextInput
              className="form-control"
              clearOnSave={ false }
              onKeyUp={ this._onSearchInputKeyup }
              onSave={ this.onSearchAddress }
              placeholder="Find Address or Cross-Streets"
              saveOnBlur
            />
            <p><span className="muted">2</span> Select a specific location below</p>
            <ScrollBars slimShady>
              { state.isSearching ? (
                <CircleLoader
                  loading
                  overlay
                />
              ) : (
                state.results === null ? null : (
                  state.results.length === 0 ? (
                    <div className="no_items">No Matching Locations</div>
                  ) : (
                    <div>
                      { _.map(state.results, (result, i) => (
                        <Button
                          args={ [result, i] }
                          className={ `geo_result fade_in_right ${ state.selectedResultIdx === i ? 'selected_result' : '' }` }
                          key={ `result_${ i }` }
                          onClick={ this._onResultClick }
                          style={ {
                            animationDelay: i * 100 + 'ms',
                            WebkitAnimationDelay: i * 100 + 'ms'
                          } }
                        >
                          <span className="icon icon-checkmark" />
                          { result.formatted_address }
                        </Button>
                      )) }
                    </div>
                  )
                )
              ) }
            </ScrollBars>
            { !this.props.inputMode ? (
              <div className="map_selection_btns">
                <button
                  className="btn"
                  onClick={ this._onCancelSelectionClicked }
                >Cancel</button>
                <button
                  className={ `btn btn-${ state.selectedResultIdx === null ? 'default' : 'primary' }` }
                  data-tooltip={ state.selectedResultIdx === null ? 'Please select a location' : null }
                  disabled={ state.selectedResultIdx === null }
                  onClick={ this._onSaveClick }
                >Save Selected Location</button>
              </div>
            ) : null }
          </div>
        ) : null }
        <GoogleMap
          autoZoomOffset={ -1 }
          clickable={ props.selectionModeActive }
          markers={ this._getAllMarkers() }
          onMapClick={ this._onMapClick }
          drawMode={ false }
        />
      </div>
    )
  }
}

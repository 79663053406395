import { fromPromise, IBasePromiseBasedObservable } from 'mobx-utils'
import { LRUCache } from 'lru-fast'
import { requestGet } from 'utils/restUtils'
import { Store } from 'stores/mobx/StoreManager'
import { RestError } from 'typings/restError'

export interface City {
  countryCode: string
  countryName: string
  cityName: string
  id: string
  subdiv1Name?: string
  subdiv2Name?: string
}

export class CityStore extends Store {
  private cache: LRUCache<string, IBasePromiseBasedObservable<City|RestError>> = new LRUCache(100)

  private fetch = (cityId: string) => requestGet(
    `get_city_${cityId}`,
    `geo-location/city/${cityId}`,
    { baseURL: 'api/v2/' }
  )

  getById = (cityId: string) => {
    if (!this.cache.find(cityId)) {
      this.cache.put(cityId, fromPromise(this.fetch(cityId)))
    }
    return this.cache.get(cityId)
  }
}


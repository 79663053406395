import _ from 'lodash'
import React from 'react'
import T from 'prop-types'
import cx from 'classnames'
import {
  Button,
  ScrollBars,
  LoadingValue
} from 'react-base'
import { pluralize } from 'pw-formatters'
import IntelRuleQuickAddActions from 'actions/IntelRuleQuickAddActions'

const LIST_POS = {
  AT_TOP: 'at-top',
  NOT_PRESENT: 'not-present',
  NOT_AT_TOP: 'not-at-top'
}

class QuickSetSubscriptions extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      sensorIdsToListStatus: this._attachStatusToSensors(props)
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      sensorIdsToListStatus: this._attachStatusToSensors(nextProps)
    })
  }

  _attachStatusToSensors = props => {
    const { enabledSensors, selectedListId, subscriptionsBySensorId } = props

    return _.reduce(
      enabledSensors,
      (out, sensor) => {
        const sensorSubLists = _.get(
          subscriptionsBySensorId,
          `[${sensor.id}].lists`,
          []
        )
        let listPositionInSub = LIST_POS.NOT_PRESENT
        if (sensorSubLists.length > 0 && sensorSubLists[0] === selectedListId) {
          listPositionInSub = LIST_POS.AT_TOP
        } else if (
          sensorSubLists.length > 0 &&
          sensorSubLists.indexOf(selectedListId) !== -1
        ) {
          listPositionInSub = LIST_POS.NOT_AT_TOP
        }
        out[sensor.id] = listPositionInSub
        return out
      },
      {}
    )
  }

  handleSensorClick = sensorId => {
    IntelRuleQuickAddActions.toggleSensorNeedsSubUpdate(sensorId)
  }

  handleFixAllClick = () => {
    const { sensorIdsToListStatus } = this.state
    IntelRuleQuickAddActions.setSensorsNeedSubUpdate(
      _.uniq(
        _.compact(
          _.map(sensorIdsToListStatus, (status, sensorId) => {
            return status === LIST_POS.AT_TOP ? null : +sensorId
          })
        )
      )
    )
  }

  render() {
    const {
      actionTitle,
      enabledSensors,
      isSavingSubscriptions,
      sensorSets,
      selectedListName,
      sensorIdsNeedSubUpdate
    } = this.props
    const { sensorIdsToListStatus } = this.state

    const numSensorsInvalid = _.reduce(
      sensorIdsToListStatus,
      (out, status, sensorId) => {
        return status !== LIST_POS.AT_TOP &&
        sensorIdsNeedSubUpdate.indexOf(+sensorId) === -1
          ? out + 1
          : out
      },
      0
    )

    return (
      <div className="quick_set_subscriptions">
        <h6>Review Sensor Subscriptions for the selected Intel List</h6>
        <div className="quick_set_subscriptions_content">
          <ScrollBars slimShady slimShadyDarker>
            {isSavingSubscriptions ? (
              <div className="fade_in_left">
                <LoadingValue /> Saving Subscriptions
              </div>
            ) : null}
            {sensorSets.length > 0 && enabledSensors.length > 0 ? (
              <ul className="list-unstyled">
                {_.map(sensorSets, sensorSet => {
                  const { enabledSensorCount, name, id } = sensorSet

                  return enabledSensorCount > 0 ? (
                    <li className={`quick_add_sensor_set`} key={id}>
                      <div className="sensor_set_name">{name}</div>
                      <div className="sensor_set_sensors">
                        {_.map(sensorSet.enabledSensors, sensor => {
                          const sensorWillBeUpdated =
                            sensorIdsNeedSubUpdate.indexOf(+sensor.id) !== -1
                          const listPositionInSub =
                            sensorIdsToListStatus[`${sensor.id}`]
                          const canEdit = listPositionInSub !== LIST_POS.AT_TOP
                          return (
                            <Button
                              args={[sensor.id]}
                              className={cx(`quick_add_sensor`, {
                                will_be_updated: sensorWillBeUpdated,
                                can_edit: canEdit
                              })}
                              key={sensor.id}
                              onClick={
                                canEdit ? this.handleSensorClick : _.noop
                              }
                            >
                              <span
                                className={cx(`icon`, {
                                  'icon-checkbox-unchecked':
                                    listPositionInSub ===
                                      LIST_POS.NOT_PRESENT &&
                                    !sensorWillBeUpdated,
                                  'icon-checkbox-checked':
                                    listPositionInSub === LIST_POS.AT_TOP ||
                                    sensorWillBeUpdated,
                                  'icon-checkbox-partial':
                                    listPositionInSub === LIST_POS.NOT_AT_TOP &&
                                    !sensorWillBeUpdated
                                })}
                              />
                              <div>
                                {sensor.friendly_name}
                                {listPositionInSub !== LIST_POS.AT_TOP &&
                                !sensorWillBeUpdated ? (
                                  <span
                                    className="icon icon-warning"
                                    data-tooltip={
                                      listPositionInSub ===
                                      LIST_POS.NOT_PRESENT ? (
                                        `List is not in this Sensor's Subscription`
                                      ) : listPositionInSub ===
                                      LIST_POS.NOT_AT_TOP ? (
                                        `List is in this Sensor's Subscription, but not at the highest priority`
                                      ) : null
                                    }
                                  />
                                ) : null}
                              </div>
                            </Button>
                          )
                        })}
                      </div>
                    </li>
                  ) : null
                })}
              </ul>
            ) : (
              <div className="no_items">No active Sensors found</div>
            )}
          </ScrollBars>
        </div>
        <div className="quick_set_subscriptions_validation">
          <h6>More Information</h6>
          {numSensorsInvalid > 0 ? (
            <div className="info_warn">
              <div>
                <div>
                  <span className={`icon icon-warning`} />{' '}
                  {`${numSensorsInvalid} ${pluralize(
                    numSensorsInvalid,
                    'Sensor'
                  )} might need attention`}
                </div>
                <p>
                  Any sensors that do not have this Intel List subscription at
                  their highest priority could be subject to a superceding rule
                  in a higher priority list.
                </p>
                <p>
                  Any sensors that are not subscribed to this Intel List will
                  not be affected by the new {actionTitle} Intel Rule.
                </p>
              </div>
              <button
                className={`btn btn-sm btn-default`}
                onClick={this.handleFixAllClick}
              >
                Fix all
              </button>
            </div>
          ) : (
            <div className="info_ok">
              <div>
                <div>
                  <span className={`icon icon-checkmark`} /> Looks good
                </div>
                <p>
                  All enabled sensors will be subscribed to the &quot;{selectedListName}&quot;
                  Intel List at the highest priority.
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

QuickSetSubscriptions.displayName = 'QuickSetSubscriptions'
QuickSetSubscriptions.propTypes = {
  actionTitle: T.string,
  currentCustomerId: T.number.isRequired,
  enabledSensors: T.array,
  isSavingSubscriptions: T.bool,
  selectedListId: T.string,
  selectedListName: T.string,
  sensorIdsNeedSubUpdate: T.array,
  sensorSets: T.array,
  subscriptionsBySensorId: T.object
}

export default QuickSetSubscriptions

import _ from 'lodash'
import React from 'react'
import T from 'prop-types'
import { Timing } from 'react-base'

const LOADING_ADJECTIVES = [
  'Italicizing',
  'Resolving',
  'Absorbing',
  'Loading',
  'Energizing',
  'Vectorizing',
  'Truncating',
  'Sideloading',
  'Colorizing',
  'Waiting for',
  'Connecting',
  'Locating',
  'Deep Frying',
  'I can haz',
  'Jumpstarting'
]
const LOADING_NOUNS = [
  'Configuration',
  'Threat Engine',
  'Dependencies',
  'Walruses',
  'Signatures',
  'Spectrum',
  'Packet Scrapers',
  'Definitions',
  'Category Library',
  'Sparkly Things',
  'Unicorns',
  'System',
  'The Danger Zone',
  'Cheeseburgers',
  'Flux Capacitor'
]

function _pluckRandomValue(arr) {
  if (arr.length > 1) {
    let rdmIdx = _.random(0, arr.length - 1)
    let newVal = arr[rdmIdx]
    arr.splice(rdmIdx, 1)
    return newVal
  }
  return ''
}

class AppLoadingIndicator extends React.PureComponent {
  static displayName = 'AppLoadingIndicator'

  static propTypes = {
    setTimer: T.func
  }

  UNSAFE_componentWillMount() {
    this._cycleText()
  }

  _cycleText = () => {
    if (LOADING_ADJECTIVES.length) {
      let text =
        _pluckRandomValue(LOADING_ADJECTIVES) +
        ' ' +
        _pluckRandomValue(LOADING_NOUNS)
      this.setState({ text })
      this.props.setTimer(this._cycleText, _.random(1100, 1800))
    }
  }

  render() {
    return (
      <div id="loading_indicator" className="loading_indicator loading">
        <div className="loading_chi large">
          <h4 className="loading_text">
            {this.state.text}
          </h4>
        </div>
      </div>
    )
  }
}

export default Timing(AppLoadingIndicator)

import Protocols from 'data/Protocols'

// Pre-canned query templates
// Sources:
// - https://protectwise.atlassian.net/browse/FLD-409
// - (Slack Thread) https://protectwise.slack.com/archives/C03AWSKEF/p1576611824031700

/**
 * V1 Clause
 * // FIXME flesh out this typedef
 *
 * @typedef {object} ClauseV1
 */

/**
 * V1 Query
 *
 * @typedef {object} QueryV1
 * @property {"observations"|"netflows"} family Query family
 * @property {Array<ClauseV1>} clauses Top-level query clauses
 */

/**
 * Query Preset
 *
 * @typedef {object} QueryPreset
 * @property {number} version Preset version, will possibly be used in the future for upgraded queries
 * @property {QueryV1} query The JSON-formatted V1 Query object
 */

/**
 * Query Template Type
 *
 * @typedef {object} QueryTemplate
 * @property {string} id Template ID
 * @property {string} title Short title
 * @property {string} description Longer descriptive text
 * @property {QueryPreset} preset The actual query preset
 * @property {boolean} hasTokens If this preset contains tokens that the user must provide values for
 */


/*
Example of a field that has a token value (that must be filled in by the user)
{
  name: 'protocols',
  op: 'eq',
  value: null, // <== note
  token: 'Subject Source IP' // <== note
}
*/

const initTemplates = () => ([
  // {
  //   id: 'token1',
  //   title: 'Test Token Preset',
  //   description: 'Testing a token in the preset',
  //   hasTokens: true,
  //   preset: {
  //     version: 1, // Legacy JSON-style query
  //     query: {
  //       family: 'netflows',
  //       clauses: [
  //         {
  //           value: 'last_1_hour',
  //           op: '_timerange-preset',
  //           name: 'startTime'
  //         },
  //         {
  //           name: 'protocols',
  //           op: 'eq',
  //           value: null,
  //           token: "Protocol A"
  //         },
  //         {
  //           name: 'totalBytes',
  //           op: 'gt',
  //           value: 10000000
  //         }
  //       ]
  //     }
  //   }
  // },
  {
    id: 'tgrn-topical-2021-q1-1',
    title: 'Q1 2021 Topical Search 1',
    description: 'Exchange CVEs and Hafnium IDS Observations',
    hasTokens: false,
    preset: {
      version: 1, // Legacy JSON-style query
      query: {
        family: 'observations',
        clauses: [
          {
            value: 'last_7_days',
            op: '_timerange-preset',
            name: 'occurredAt'
          },
          {
            name: 'type',
            op: 'eq',
            value: 'Ids'
          },
          {
            name: 'signatureId',
            op: 'eq',
            value: [
              2847418,
              2847419,
              2847420,
              2847421,
              2847422,
              2847423,
              2031812
            ]
          }
        ]
      }
    }
  },
  {
    id: 'tgrn-topical-2021-q1-2',
    title: 'Q1 2021 Topical Search 2',
    description: 'IDS Hunting',
    hasTokens: false,
    preset: {
      version: 1, // Legacy JSON-style query
      query: {
        family: 'observations',
        clauses: [
          {
            value: 'last_7_days',
            op: '_timerange-preset',
            name: 'occurredAt'
          },
          {
            name: 'type',
            op: 'eq',
            value: 'Ids'
          },
          {
            name: 'signatureId',
            op: 'gte',
            value: 2610000
          },
          {
            name: 'signatureId',
            op: 'lte',
            value: 2619999
          }
        ]
      }
    }
  },
  {
    id: 'rgr-ssh-bytes',
    title: 'Large SSH Transfer',
    description: 'App Protocol is SSH and Total Bytes is more than 10MB',
    hasTokens: false,
    preset: {
      version: 1, // Legacy JSON-style query
      query: {
        family: 'netflows',
        clauses: [
          {
            value: 'last_1_hour',
            op: '_timerange-preset',
            name: 'startTime'
          },
          {
            name: 'protocols',
            op: 'eq',
            value: [Protocols.getProtocolByName('SSH').id],
          },
          {
            name: 'totalBytes',
            op: 'gt',
            value: 10000000
          }
        ]
      }
    }
  },
  {
    id: 'rgr-ssh-port-not-22',
    title: 'SSH on Non-Standard Port',
    description: 'App Protocol is SSH and Port is not 22',
    hasTokens: false,
    preset: {
      version: 1, // Legacy JSON-style query
      query: {
        family: 'netflows',
        clauses: [
          {
            value: 'last_1_hour',
            op: '_timerange-preset',
            name: 'startTime'
          },
          {
            name: 'protocols',
            op: 'eq',
            value: [Protocols.getProtocolByName('SSH').id],
          },
          {
            name: 'port',
            op: '!eq',
            value: 22
          }
        ]
      }
    }
  },
  {
    id: 'rgr-port-443-no-ssl',
    title: 'Non-SSL Traffic on Port 443',
    description: 'Port is 443 and App Protocol is not SSL',
    hasTokens: false,
    preset: {
      version: 1, // Legacy JSON-style query
      query: {
        family: 'netflows',
        clauses: [
          {
            value: 'last_1_hour',
            op: '_timerange-preset',
            name: 'startTime'
          },
          {
            name: 'protocols',
            op: '!eq',
            value: [Protocols.getProtocolByName('SSL').id],
          },
          {
            name: 'port',
            op: 'eq',
            value: 443
          }
        ]
      }
    }
  },
  {
    id: 'rgr-ua-not-moz',
    title: 'Non-Mozilla User-Agent',
    description: 'HTTP traffic where User Agent is not "Mozilla"',
    hasTokens: false,
    preset: {
      version: 1, // Legacy JSON-style query
      query: {
        family: 'observations',
        clauses: [
          {
            value: 'last_1_hour',
            op: '_timerange-preset',
            name: 'occurredAt'
          },
          {
            name: 'user-agent',
            op: '!eq',
            value: 'Mozilla',
          },
          {
            name: 'type',
            op: 'eq',
            value: 'Http',
          }
        ]
      }
    }
  },
  {
    id: 'rgr-unusual-ssh-rdp',
    title: 'Unusual SSH or RDP Traffic',
    description: 'App Protocol is SSH or RDP and Destination IP is not 10.0.0.0/8, 172.16.0.0/12, or 192.168.0.0/16',
    hasTokens: false,
    preset: {
      version: 1, // Legacy JSON-style query
      query: {
        family: 'netflows',
        clauses: [
          {
            value: 'last_1_hour',
            op: '_timerange-preset',
            name: 'startTime'
          },
          {
            name: 'protocols',
            op: 'eq',
            value: [
              Protocols.getProtocolByName('SSH').id,
              Protocols.getProtocolByName('RDP').id
            ]
          },
          {
            name: 'dstIp',
            op: '!eq',
            value: [
              '10.0.0.0/8',
              '172.16.0.0/12',
              '192.168.0.0/16'
            ]
          }
        ]
      }
    }
  },
  {
    id: 'rgr-long-requests',
    title: 'Long Running HTTP or SSL Requests',
    description: 'App Protocol is HTTP or SSL and request duration is more than 10 minutes',
    hasTokens: false,
    preset: {
      version: 1, // Legacy JSON-style query
      query: {
        family: 'netflows',
        clauses: [
          {
            value: 'last_1_hour',
            op: '_timerange-preset',
            name: 'startTime'
          },
          {
            name: 'protocols',
            op: 'eq',
            value: [
              Protocols.getProtocolByName('HTTP').id,
              Protocols.getProtocolByName('SSL').id
            ]
          },
          {
            name: 'duration',
            op: 'gte',
            value: 600000
          }
        ]
      }
    }
  },
  {
    id: 'tor-bittorrent-traffic',
    title: 'TOR or BitTorrent Traffic',
    description: 'App Protocol is TOR or BitTorrent',
    hasTokens: false,
    preset: {
      version: 1, // Legacy JSON-style query
      query: {
        family: 'netflows',
        clauses: [
          {
            value: 'last_1_hour',
            op: '_timerange-preset',
            name: 'startTime'
          },
          {
            name: 'protocols',
            op: 'eq',
            value: [
              Protocols.getProtocolByName('BitTorrent').id,
              Protocols.getProtocolByName('TOR').id
            ]
          }
        ]
      }
    }
  },
])

export default async function loadTemplates () {
  return await Promise.resolve(initTemplates())
}

export enum NotificationType {
  ERROR = 'error',
  WARNING = 'warning',
  SUCCESS = 'success',
  INFO = 'info'
}

export interface Notification {
  id?: number|string
  heading: string,
  message?: string,
  messageComponent?: any,
  messageDetails?: string|Array<string>,
  traceId?: string,
  type: NotificationType,
  dismissTimer?: number
}

import T from 'prop-types'
/**
 * Convenience wrapper for a SearchableValue that is a single URL. Handles
 * automatically building the intelcard and explorer query props.
 *
 *   <UrlValue url={ myUrl } start={ startTime } end={ endTime } />
 *
 * If the start/end props are omitted, it defaults to the past hour.
 *
 * All other props supported by SearchableValue can be included and will be passed through.
 */

import React from 'react'
import SearchableValue from 'components/values/SearchableValue'
import ipaddr from 'pw-ipaddr.js'
import {
  TextOverflowCenterLite
} from 'react-base'
import {
  VALUE_TYPE_OPTIONS
} from 'constants/searchableValueConstants'

const ipAddressUtil = ipaddr.util

const defaultDuration = 60 * 60 * 1000

class UrlValue extends React.Component {
  static displayName = 'UrlValue'

  static propTypes = {
    url: T.string,
    start: T.number,
    end: T.number,
    noIntelCard: T.bool,
    noQuery: T.bool,
    handleTextOverflow: T.bool
    // ...plus any others accepted by SearchableValue
  }

  render() {
    let {
      children,
      className,
      end,
      noIntelCard,
      noQuery,
      start,
      url,
      handleTextOverflow
    } = this.props

    if (!url) {
      return <span />
    }

    // Strip protocol if it was passed in, for things like domain searches that assume it's not present
    const urlNoProto = url.replace(/^https?:\/\//, '')

    // Default timeframe
    if (!end) {
      end = start ? start + defaultDuration : Date.now()
    }
    if (!start) {
      start = end - defaultDuration
    }

    // Parse the URL into its constituent parts and add query clauses for each
    let obsClauses = [{
      name: 'occurredAt',
      op: 'between',
      from: start,
      to: end
    }]
    let urlObj
    try {
      urlObj = new URL('http://' + urlNoProto)
      if (urlObj.host) obsClauses.push({name: 'httpHost', op: 'eq', value: urlObj.host})
      if (urlObj.pathname && urlObj.pathname !== '/') obsClauses.push({name: 'httpPath', op: 'eq', value: urlObj.pathname})
      if (urlObj.search) obsClauses.push({name: 'httpQueryString', op: 'eq', value: urlObj.search.substring(1)})
    } catch(e) {
      console.warn(`UrlValue: could not parse URL ${ url }`)
      // Couldn't parse the URL; just render a plain span
      return <span>{ url }</span>
    }

    let query = noQuery ? null : {
      family: 'observations',
      clauses: obsClauses
    }

    // Some "URLs" may have an IP address for the domain; treat these as an IP for intel card queries or they will error
    let type = ipAddressUtil.isValidIp(urlObj.host) ? 'ip' : 'domain'
    const content = (children || url)
    return (
      <SearchableValue
        { ...this.props }
        className={ 'url_value ' + (className || '') }
        intelCard={ noIntelCard ? null : `${ type }:${ urlObj.host }` }
        intelCardTooltip={ `View ${type} Info` }
        query={ query }
        value={ urlNoProto }
        copyValue={ url }
        valueType={ VALUE_TYPE_OPTIONS.URL }
      >
        { handleTextOverflow ? (
          <TextOverflowCenterLite
            value={content}
          />
        ) : content }
      </SearchableValue>
    )
  }
}

export default UrlValue

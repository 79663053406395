
import {observable} from 'mobx'
import {
  SchemerModel,
  schemer
} from 'pw-schemer-mobx'
import DeviceAttribute from './DeviceAttribute';
import {Coordinates} from '../../geo/Coordinates'
import { Sensor } from 'components/devices/fragments/Sensor'

export class GeoDetails extends SchemerModel {
  @observable
  @schemer.model({
    model: Coordinates
  })
  coords: Coordinates

  @observable
  @schemer.string({
    label: 'Sensor',
    formatDisplay: Sensor
  })
  sensorId: number

  @observable
  @schemer.ip({
    label: 'IP'
  })
  ip: string
}

export class GeoDetailsAttr extends DeviceAttribute {
  @observable
  @schemer.model({
    model: GeoDetails
  })
  value: GeoDetails
}
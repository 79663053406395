
import {observable, action, computed} from 'mobx'
import {
  SchemerModel,
  schemer
} from 'pw-schemer-mobx'
import DeviceAttribute from './DeviceAttribute'
import { HvtIcon } from 'components/devices/fragments/IconHeaders';
import { HighValueTarget } from 'components/devices/fragments/HighValueTarget';
import { UserDetailsHeader } from 'components/devices/fragments/UserDetailsHeader';

const DEFAULT_COMPACT_VIEW_COLS = new Map([
  ['highValueTarget', {}],
  ['lastName', {}],
  ['firstName', {}],
  ['email', {}],
  ['location', {}]
])

export class UserDetails extends SchemerModel {
  @observable
  @schemer.bool({
    label: 'High Value Target',
    formatDisplay: HighValueTarget,
    table: {
      header: HvtIcon
    }
  })
  highValueTarget?: boolean

  @observable
  @schemer.id({
    label: 'ID',
    tooltip: 'User ID'
  })
  userId: string

  @observable
  @schemer.string({
    label: 'Last',
    tooltip: 'Last Name'
  })
  lastName?: string

  @observable
  @schemer.string({
    label: 'First',
    tooltip: 'First Name'
  })
  firstName?: string

  @observable
  @schemer.string({
    label: 'Email',
    tooltip: 'Email Address',
    table: {
      width: 200
    }
  })
  email?: string

  @observable
  @schemer.string({
    label: 'Desc.',
    tooltip: 'Description'
  })
  description?: string

  @observable
  @schemer.string({
    label: 'Title',
    tooltip: 'Title'
  })
  title?: string

  @observable
  @schemer.string({
    label: 'Dept.' ,
    tooltip: "Department"
  })
  department?: string

  @observable
  @schemer.string({
    label: 'CO',
    tooltip: "Company"
  })
  company?: string

  @observable
  @schemer.string({
    label: 'City',
    table: false
  })
  city?: string

  @observable
  @schemer.string({
    label: 'ST',
    tooltip: 'State',
    table: false
  })
  state?: string

  @observable
  @schemer.string({
    label: 'CN',
    tooltip: 'Country',
    table: false
  })
  country?: string

  @computed
  @schemer.string({
    label: 'Location'
  })
  get location () {
    return [this.city, this.state, this.country].reduce((o, s) => !s ? o : `${o}, ${s}`, '')
  }


  @observable
  private isFullView: boolean = false

  @action
  toggleFullView () {
    this.isFullView = !this.isFullView
  }

  @computed
  get columnPrefs () {
    return this.isFullView ? undefined : DEFAULT_COMPACT_VIEW_COLS
  }
}

export class UserDetailsAttr extends DeviceAttribute {
  @observable
  @schemer.model({
    model: UserDetails
    // label: 'User',
    // table: {
    //   header: UserDetailsHeader
    // }
  })
  value: UserDetails
}
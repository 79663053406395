import _ from 'lodash'
import T from 'prop-types'
import React from 'react'
import {TextInput, ConfirmAction, Tabs, MenuItem} from 'react-base'
import GoogleMapSelection from 'components/world-map/GoogleMapSelection'
import {locationShape, getMarkerForLocation, getDeleteConfirmationMessage} from 'utils/locationUtils'
import LocationActions from 'actions/LocationActions'
import getConfig from 'utils/uiConfig'
import ManualLocationInput from './ManualLocationInput'

const MIN_NAME_LENGTH = 2

function _isValidLocation (location) {
  let {latitude, longitude} = location
  return (latitude && latitude >= -90 && latitude <= 90)
    && (longitude && longitude >= -180 && longitude <= 180)

}

function _isValidLocationName (value) {
  return value && value.length > MIN_NAME_LENGTH
}

// Extract 2-character ISO 3166-1 country code from google results
function _getCountryCode (googleAddress) {
  let countryComponent = _.find(googleAddress.address_components, component => component.types.indexOf('country') !== -1)
  return countryComponent && countryComponent.short_name ? countryComponent.short_name : null
}

export default class extends React.Component {
  static displayName = 'LocationEditor';

  static propTypes = {
    dialogMode: T.bool,
    hasPermission: T.func.isRequired,
    location: locationShape,
    onClose: T.func.isRequired
  };

  state = {
    isValid: true,
    hasTriedSave: false,
    pendingLocation: this.props.location || {},
    manualEntry: false
  };

  UNSAFE_componentWillMount() {
    this.setState({
      pendingLocation: this.props.location || {}
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.location, nextProps.location)) {
      this.setState({
        pendingLocation: nextProps.location || {}
      })
    }
  }

  _saveChanges = (partialLocation) => {
    let {pendingLocation} = this.state
    let _pendingLocation = _.assign({}, pendingLocation, partialLocation)
    this.setState({
      pendingLocation: _pendingLocation,
    })
  };

  _onNameChanged = (value) => {
    this._saveChanges({ name: value })
  };

  _onDescChanged = (value) => {
    this._saveChanges({ description: value })
  };

  _onLocationDelete = () => {
    let {location} = this.props
    if (location.id >= 0) {
      LocationActions.deleteLocation(location.id)
    }
    this._close()
  };

  _close = () => {
    this.props.onClose()
  };

  _onSaveClick = () => {
    let {pendingLocation} = this.state
    let newState = {
      hasTriedSave: true,
      isValid: _isValidLocation(pendingLocation) && _isValidLocationName(pendingLocation.name),
    }
    if (newState.isValid) {
      LocationActions.saveLocation(pendingLocation)
      this._close()
    } else {
      this.setState(newState)
    }
  };

  _onMapSelection = (googleAddress) => {
    // Apply selected address/geoSelectionation to geoSelectionation being edited
    this._saveChanges({
      address: googleAddress.formatted_address,
      country_code: _getCountryCode(googleAddress),
      latitude: googleAddress.lat,
      longitude: googleAddress.lng
    })
  }

  _setGmapMode = () => {
    this.setState({manualEntry: false})
  }

  _setManualMode = () => {
    this.setState({manualEntry: true})
  }

  render() {
    const uiConfig = getConfig()
    const {props, state} = this
    const {pendingLocation = {}, manualEntry} = state

    const canDeleteLocations = props.hasPermission("locations:delete")
    const canUseGoogleMaps = _.get(uiConfig, 'apiKeys.googleMaps') != null
    return (
      <div className="location_editor">
        <div>
          <h1>{ pendingLocation.id ? `Editing Location: ${ props.location.name }` : "New Location" }</h1>
          <div className="grid">
            <div className="grid_cell">
              <label htmlFor={ 'location_input_name' }>Name</label>
              <TextInput
                blurOnSave
                className={ `form-control cell_content location_name ${ state.hasTriedSave && !_isValidLocationName(pendingLocation.name) ? "invalid" : "" }` }
                data-tooltip={ state.hasTriedSave && !_isValidLocationName(pendingLocation.name) ? `Location name of at least ${ MIN_NAME_LENGTH } characters is required.` : ""  }
                id={ 'location_input_name' }
                initialValue={ pendingLocation.name }
                onSave={ this._onNameChanged }
                placeholder={ `Location Name` }
                validator={ null }
              />
            </div>
            <div className="grid_cell">
              <label htmlFor={ 'location_input_description' }>Description</label>
              <TextInput
                blurOnSave
                className={ `form-control cell_content location_desc` }
                id={ 'location_input_description' }
                initialValue={ pendingLocation.description }
                onSave={ this._onDescChanged }
                placeholder={ `Location Description` }
              />
            </div>
          </div>
        </div>
        <div className="map_selection_overlay_content">
          <span className="form_label">Geolocation</span>
          { state.hasTriedSave && !_isValidLocation(pendingLocation) ? (
            <div className="location_required">Geolocation is required.</div>
          ) : null }
          {canUseGoogleMaps ? (
            <Tabs activeTabKey={ manualEntry ? 'manual' : 'gmap' }>
              <MenuItem key="gmap" onClick={this._setGmapMode}>Choose On Map</MenuItem>
              <MenuItem key="manual" onClick={this._setManualMode}>Manual Entry</MenuItem>
            </Tabs>
          ) : null }
          {!canUseGoogleMaps || manualEntry ? (
            <ManualLocationInput
              initialValue={ pendingLocation }
              onChange={ this._onMapSelection }
            />
          ) : (
            <GoogleMapSelection
              autoZoomOffset={ -1 }
              initialValueMarker={ getMarkerForLocation(props.location) }
              inputMode
              onSelect={ this._onMapSelection }
              selectionModeActive
            />
          ) }
        </div>
        <div className="dialog_buttons">
          <button
            className="btn btn-large"
            onClick={ this._close }
          >Cancel</button>
          { canDeleteLocations ? (
            !pendingLocation.id || pendingLocation.id === -1 ? null : (
              <ConfirmAction
                buttonClassName="btn btn-icon icon-trash"
                buttonMode
                buttonText=""
                className="delete_location_confirmation"
                confirmText="Yes, Delete it"
                menuClassName="delete_location_menu"
                message={ getDeleteConfirmationMessage(pendingLocation) }
                onConfirmed={ this._onLocationDelete }
              />
            )
          ) : null }
          { props.dialogMode ? (
            <div className="dialog_primary_button_wrap">
              {/*<Button
                args={ [true] }
                className={ `btn btn-primary` }
                onClick={ this._onSaveClick }
              >Save and Select</Button>*/}
              <button
                className={ `btn btn-primary` }
                onClick={ this._onSaveClick }
              >Save</button>
            </div>            ) : (
            <div className="dialog_primary_button_wrap">
              <button
                className={ `btn btn-primary` }
                onClick={ this._onSaveClick }
              >Save and Close</button>
            </div>
          ) }
        </div>
      </div>
    )
  }
}


import {observable} from 'mobx'
import {
  SchemerModel,
  schemer
} from 'pw-schemer-mobx'
import DeviceAttribute from './DeviceAttribute'

export class AwsId extends SchemerModel {
  @observable
  @schemer.string({
    label: 'Inst ID',
    tooltip: 'Instance ID'
  })
  instanceId: string

  @observable
  @schemer.string({
    label: 'Acct ID',
    tooltip: 'Account ID'
  })
  accountId: string
}

export class AwsIdAttr extends DeviceAttribute {
  @observable
  @schemer.model({
    model: AwsId
  })
  value: AwsId
}
import _ from 'lodash'
import Reflux from 'reflux'

import ValueActions from 'actions/ValueActions'
import IntegrationsActions from 'actions/IntegrationsActions'
import UserStore from 'stores/UserStore'
import {
  VALUE_TYPE_ACTIONS_BY_ID
} from 'constants/searchableValueConstants'
import {
  getAvailableIntelTypes
} from 'utils/intelManagementUtils'
import {listenToStore} from 'utils/storeUtils'

import IntegrationsStore from 'stores/IntegrationsStore'

const VALUE_ID_TO_VENDOR = {
  firewall_cb: 'CarbonBlack',
  firewall_aws: 'AWS',
  firewall_pan: 'PAN'
}

const DEFAULT_STATE = {
  isDialogOpen: false,
  action: null,
  valueType: null,
  value: null,
  availableValueActions: {}, // Value actions by type, filtered by user permissions
  devices: { data: [] }
}

let _state = _.cloneDeep(DEFAULT_STATE)

export default Reflux.createStore({
  listenables: [
    ValueActions,
    IntegrationsActions
  ],

  init() {
    listenToStore(this, IntegrationsStore, this._onIntegrationsStoreUpdate)
    listenToStore(this, UserStore, this._onUserStoreUpdate)
  },

  getInitialState() {
    return _state
  },

  _notify() {
    this.trigger(_state)
  },

  filterActionsByPermissions() {
    const availableIntelTypes = getAvailableIntelTypes().map(intelType => intelType.id)
    return _.mapValues(VALUE_TYPE_ACTIONS_BY_ID, valueType => {
      return _.reduce(_.clone(valueType.actions), (outActions, action) => {
        const hasAllActionPermissions = _.every(action.permissions, perm => {
          // Permissions can be strings or functions
          if (action.id.includes('firewall')) { // for all three firewall vendors
            // EndpointKnowledge is now more than one vendor type
            const availableDeviceTypes = _state.devices.data.map(dev => dev.vendor)
            var intelType = VALUE_ID_TO_VENDOR[action.id]
            var availableTypes = availableDeviceTypes
          } else {
            var intelType = valueType.intelType
            var availableTypes = availableIntelTypes
          }

          return typeof perm === 'function' ? perm(intelType, availableTypes) : UserStore.hasPermission(perm)
        })
        if (hasAllActionPermissions) {
          outActions.push(action)
        }
        return outActions
      }, [])
    })
  },

  _onUserStoreUpdate(userStoreState) {
    if (userStoreState.isAuthenticated) {
      _state.availableValueActions = this.filterActionsByPermissions()
    }
  },

  _onIntegrationsStoreUpdate(storeState) {
    if (storeState.devices && storeState.devices.data) {
      _state.devices = storeState.devices
    }
  },

  onShow(type, value, actionType=null, relatedData=null) {
    _state.valueType = type
    _state.value = value
    _state.relatedData = relatedData
    _state.isDialogOpen = true
    _state.action = actionType // Optionally allow opening the dialog with a preset
    this._notify()
  },

  onReset() {
    _state.isDialogOpen = false
    _state.action = null
    _state.valueType = null
    _state.value = null
    this._notify()
  },

  onBackToActions() {
    _state.isDialogOpen = true
    _state.action = null
    this._notify()
  },

  onSetActionType(actionName) {
    _state.action = actionName
    this._notify()
  },

  // Getters
  getValueActionsByValueType(valueType) {
    return _state.availableValueActions[valueType]
  }
})

import React from 'react'
import * as ReactBase from 'react-base'
import _ from 'lodash'
import IntelCardPropTypes from '../PropTypes'
import IpValue from 'components/values/IpValue'
import UrlValue from 'components/values/UrlValue'
import ThreatMeter from 'components/ThreatMeter'
import DataCell from './DataCell'
import { formatDay } from 'utils/timeUtils'
import { formatNumber } from 'pw-formatters'
import AnalyticsActions from 'actions/AnalyticsActions'


import addtlPropUtils from './additionalPropertiesUtil'
import ExternalLink from 'components/ExternalLink'

const PAGE_SIZE = 100

class Dns extends React.Component {
  static displayName = 'Dns'
  static propTypes = IntelCardPropTypes.dataCardShape

  state = {
    resolutionsLimit: PAGE_SIZE
  }

  _onMoreDnsResolutionsClick = () => {
    this.setState({ resolutionsLimit: this.state.resolutionsLimit + PAGE_SIZE })
  }

  _trackRiskIQLink = () => {
    AnalyticsActions.event({
      eventCategory: 'intelcard',
      eventAction: 'external-link-clicked',
      eventLabel: 'riskiq-report'
    })
  }

  _trackRecordedFutureLinks = () => {
    AnalyticsActions.event({
      eventCategory: 'intelcard',
      eventAction: 'external-link-clicked',
      eventLabel: 'recorded-future-reference'
    })
  }

  _trackRecordedFutureAllLink = () => {
    AnalyticsActions.event({
      eventCategory: 'intelcard',
      eventAction: 'external-link-clicked',
      eventLabel: 'recorded-future-report'
    })
  }

  render() {
    return (
      <div className="intel_card intel_data_card intel_data_dns">
        <h3>DNS Intel</h3>
        <div className="intel_card_content">
          {this.renderData()}
        </div>
      </div>
    )
  }

  componentDidUpdate() {
    if (this._threatMeter && this.props.data) {
      let recordedFutureInfo = () => addtlPropUtils.getDomainInfo(this.props.data)
      let recordedFuturesDomainInfo = addtlPropUtils.getDomainInfoValue(this.props.data)

      if (!recordedFuturesDomainInfo.error && recordedFuturesDomainInfo.value) {
        var recordedFuturesData = addtlPropUtils.getAdditionalProperties(recordedFuturesDomainInfo.value)

        var riskData = addtlPropUtils.getCategory(recordedFuturesData, 'Recorded Future Risk Score')
        var riskScore = _.find(riskData, data => data.name === 'score')

        this._threatMeter._onDataUpdate(riskScore)
      }
    }
  }

  componentDidMount() {
    if (this._threatMeter && this.props.data) {
      let recordedFutureInfo = () => addtlPropUtils.getDomainInfo(this.props.data)
      let recordedFuturesDomainInfo = addtlPropUtils.getDomainInfoValue(this.props.data)

      if (!recordedFuturesDomainInfo.error && recordedFuturesDomainInfo.value) {
        var recordedFuturesData = addtlPropUtils.getAdditionalProperties(recordedFuturesDomainInfo.value)

        var riskData = addtlPropUtils.getCategory(recordedFuturesData, 'Recorded Future Risk Score')
        var riskScore = _.find(riskData, data => data.name === 'score')

        this._threatMeter._onDataUpdate(riskScore)
      }
    }
  }

  renderData = () => {
    let { props, state } = this
    let data = props.data

    let resolveData = data('domain.resolveData')
    let resolveCount = data('domain.resolveData.length')

    let resolveDataToShow = resolveData.valueOf() || []
    if (resolveDataToShow.length > state.resolutionsLimit) {
      resolveDataToShow = resolveDataToShow.slice(0, state.resolutionsLimit)
    }

    let recordedFutureInfo = () => addtlPropUtils.getDomainInfo(props.data)
    let recordedFuturesDomainInfo = addtlPropUtils.getDomainInfoValue(props.data)

    if (!recordedFuturesDomainInfo.error && recordedFuturesDomainInfo.value) {
      var recordedFuturesData = addtlPropUtils.getAdditionalProperties(recordedFuturesDomainInfo.value)

      var riskData = addtlPropUtils.getCategory(recordedFuturesData, 'Recorded Future Risk Score')
      var riskScore = _.find(riskData, data => data.name === 'score')
      var activeRiskColor = addtlPropUtils.setActiveRiskScore(riskScore && riskScore.value)
      var riskCriticality = _.find(riskData, data => data.name === 'category')

      var metricData = addtlPropUtils.getCategory(recordedFuturesData, 'Metrics')
      var totalHits =  _.find(metricData, data => data.name === 'totalHits')
      var oneDayHits = _.find(metricData, data => data.name === 'oneDayHits')
      var sevenDaysHits = _.find(metricData, data => data.name === 'sevenDaysHits')
      var sixtyDaysHits = _.find(metricData, data => data.name === 'sixtyDaysHits')
      var socialMediaHits = _.find(metricData, data => data.name === 'socialMediaHits')
      var infoSecHits = _.find(metricData, data => data.name === 'infoSecHits')
      var maliciousHits = _.find(metricData, data => data.name === 'maliciousHits')

      var referencesData = addtlPropUtils.getCategory(recordedFuturesData, 'Sightings')

      var linkData = recordedFuturesData
        ? _.find(recordedFuturesData.data, data => data.label === 'Recorded Future Intel Card')
        : null

      var allMetrics = [totalHits, oneDayHits, sevenDaysHits, sixtyDaysHits, socialMediaHits, infoSecHits, maliciousHits]
      var hasOneMetric = allMetrics.some(_.identity)
    }

    return (
      recordedFutureInfo().isReady()
      ? (
        <ReactBase.ScrollBars
          classes="pw_scrollbars_light"
          outside
          slimShady
          slimShadyDarker
        >
        {
          riskScore && riskCriticality
          ? (
            <div className="cell">
              <span>Recorded Future Risk Score</span>
              <div className="cell_content flex space_between">
                <div className="rf_risk">
                  <span className="risk_text">
                    { `${riskScore.value} - ${riskCriticality.value}` }
                  </span>
                  <div className="risk_meter">
                    <ThreatMeter
                      ref={ c => this._threatMeter = c }
                      enableLiveData={false}
                      showSubviewLabel={false}
                      liveDataGroupName={'none'}
                      liveDataGraphId={'none'}
                      liveDataOptions={ { dataOptions: { counterTypes: ['value'] }, drawOptions: {} } }
                    />
                  </div>
                </div>
                { addtlPropUtils.recordedFutureSourceTag() }
              </div>
            </div>
          ) : null
        }

          <DataCell
            label="Resource Records"
            value={resolveCount}
            default="0"
          />

          {resolveDataToShow.length
            ? props.queryType === 'ip'
              ? <DataCell label="Domains Resolved">
                  <ul className="resolved_domains">
                    {resolveDataToShow.map(({ resolvesTo: domain }, i) =>
                      <li key={i}>
                        <ReactBase.TextOverflow>
                          <UrlValue
                            url={domain.replace(/\.$/, '')}
                            menuClassName="light"
                          />
                        </ReactBase.TextOverflow>
                      </li>
                    )}
                    {resolveDataToShow.length < resolveCount
                      ? <li>
                          <a
                            className="btn-link"
                            onClick={this._onMoreDnsResolutionsClick}
                          >
                            more...
                          </a>
                        </li>
                      : null}
                  </ul>
                </DataCell>
              : <table className="resolved_ips">
                  <thead>
                    <tr>
                      <th>IP Addresses</th>
                      <th>First Seen</th>
                      <th>Last Seen</th>
                    </tr>
                  </thead>
                  <tbody>
                    {resolveDataToShow.map(
                      ({ resolvesTo: ip, firstSeen, lastSeen }, i) =>
                        <tr key={i}>
                          <td>
                            <IpValue ip={ip} menuClassName="light" />
                          </td>
                          <td>
                            {formatDay(firstSeen)}
                          </td>
                          <td>
                            {formatDay(lastSeen)}
                          </td>
                        </tr>
                    )}
                    {resolveDataToShow.length < resolveCount
                      ? <tr>
                          <td>
                            <a
                              className="btn-link"
                              onClick={this._onMoreDnsResolutionsClick}
                            >
                              more...
                            </a>
                          </td>
                        </tr>
                      : null}
                  </tbody>
                </table>
            : null}

            {
              recordedFuturesDomainInfo.value.length
              ? (
                <section>
                  {
                    hasOneMetric
                    ? (
                      <div className="cell">
                        <div className="flex space_between">
                          <h5 className="rf_title">Recorded Future Reference Summary</h5>
                          { addtlPropUtils.recordedFutureSourceTag() }
                        </div>
                        <div className="cell_content flex col rf_refs">
                          { totalHits && <div>{`Total References - ${formatNumber(totalHits.value, true)}`}</div> }
                          { oneDayHits && <div>{`Today - ${formatNumber(oneDayHits.value, true)}`}</div> }
                          { sevenDaysHits && <div>{`Last 7 days - ${formatNumber(sevenDaysHits.value, true)}`}</div> }
                          { sixtyDaysHits && <div>{`Last 60 days - ${formatNumber(sixtyDaysHits.value, true)}`}</div> }
                          { socialMediaHits && <div>{`In Social Media - ${formatNumber(socialMediaHits.value, true)}`}</div> }
                          { infoSecHits && <div>{`From InfoSec Sources - ${formatNumber(infoSecHits.value, true)}`}</div> }
                          { maliciousHits && <div>{`Including Malicious Language - ${formatNumber(maliciousHits.value, true)}`}</div> }
                        </div>
                      </div>
                    ) : null
                  }

                  <div className="cell">
                    <h5 className="rf_title">Recent References</h5>
                      { !hasOneMetric ? addtlPropUtils.recordedFutureSourceTag() : null }
                      {
                        referencesData.map(reference => {
                          const title = _.find(reference.sighting, data => data.name === 'title')
                          const source = _.find(reference.sighting, data => data.name === 'source')
                          const published = _.find(reference.sighting, data => data.name === 'published')
                          const url = _.find(reference.sighting, data => data.name === 'url')
                          const type = _.find(reference.sighting, data => data.name === 'type')
                          return (
                            <div className="cell_content" key={`${title.value}-${type.value}`}>
                              <p className="fragment">{title.value}</p>
                              <p className="sighting_type"><span className="sighting_type_title">Type:</span> {type.value}</p>
                                <ReactBase.TextOverflow>
                                  <ExternalLink onClick={this._trackRecordedFutureLinks} href={url.value} className="btn btn-link external_link">
                                    {source.value} | {published.value}
                                    <span className="icon icon-arrow-up-right"></span>
                                  </ExternalLink>
                                </ReactBase.TextOverflow>
                            </div>
                          )
                        })
                      }
                  </div>
                </section>
              ) : null

            }

            <section>
              {
                linkData
                ? (
                  <ExternalLink href={ linkData.value.value } onClick={ this._trackRecordedFutureAllLink } className="btn btn-link external_link"
                     data-tooltip="View Intel for this file hash in Recorded Future">
                    All Recorded Future Intel <span className="icon icon-arrow-up-right"></span>
                  </ExternalLink>
                ) : null
              }
              <ExternalLink onClick={ this._trackRiskIQLink } href={ `https://community.riskiq.com/search/${ props.queryValue }` } className="btn btn-link external_link"
                 data-tooltip="Performs a search for this file in RiskIQ, and requires Free or paid account creation.">
                View RiskIQ Intel <span className="icon icon-arrow-up-right"></span>
              </ExternalLink>
              <div className="help_text">Free or paid account required</div>
            </section>
        </ReactBase.ScrollBars>
      ) : null
    )
  }
}

export default Dns

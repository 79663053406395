import _ from 'lodash'
import ReactDOM from 'react-dom'
import T from 'prop-types'
import React from 'react'
import {
  CircleLoader,
  DragSelectionContainer,
  DimensionAware
} from 'react-base'
import GoogleMapReact from 'google-map-react' // Lots of undocumented options for GoogleMapReact: https://github.com/istarkov/google-map-react/blob/master/src/google_map.js
import { fitBounds } from 'google-map-react/utils'
import { getGoogleMapStyles } from './googleMapStyles'
import { getGoogleMapsAPI } from 'utils/geoDataUtil'
import GoogleMapMarker from './GoogleMapMarker'
import WorldMap from 'components/world-map/WorldMap'
import getConfig from 'utils/uiConfig'
import ThemeProvider from 'components/ThemeProvider'

const _convertMarkersToPwMap = markers => {
  return _.map(markers, marker => {
    return {
      coords: [
        {
          longitude: marker.longitude,
          latitude: marker.latitude,
          highlight: false
        }
      ],
      countryCodes: [marker.country_code],
      highlights: [false]
    }
  })
}

const QueryBounds = props => {
  return (
    <DragSelectionContainer
      className="bounding_box_container"
      onSelection={props.setDrawBoundaries}
      selectOnMouseMove={false}
      tagName="div"
      coordinateSelection
      preserveSelectionBox
    />
  )
}

QueryBounds.displayName = 'QueryBounds'

QueryBounds.propTypes = {
  setDrawBoundaries: T.func.isRequired
}

class GoogleMap extends React.Component {
  static displayName = 'GoogleMap'

  static propTypes = {
    autoZoomOffset: T.number, // Offset the zoom returned by fitBounds when markers are changed
    backgroundColor: T.string,
    clickable: T.bool,
    fitMarkerBounds: T.bool,
    initialCenter: T.shape({
      lat: T.number,
      lng: T.number
    }),
    initialZoom: T.number,
    markers: T.array,
    onMapChange: T.func,
    onMapClick: T.func,
    onMapBoundsDrawn: T.func,
    onMarkerClick: T.func,
    height: T.number,
    width: T.number,
    themeId: T.string
  }

  static defaultProps = {
    autoZoomOffset: 0,
    clickable: false,
    fitMarkerBounds: true,
    initialCenter: {
      lat: 37.09024,
      lng: -95.712891
    },
    initialZoom: 3,
    markers: []
  }

  state = {
    hasGoogleMapsAPI: false,
    height: 100,
    width: 100
  }

  UNSAFE_componentWillMount() {
    getGoogleMapsAPI(() => {
      this.setState(
        {
          hasGoogleMapsAPI: true
        },
        this._onResize
      )
    })
  }

  componentDidMount() {
    this._onResize()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.height !== this.props.height ||
      nextProps.width !== this.props.width
    ) {
      this._onResize()
    }
  }

  _onResize = () => {
    const { width, height } = ReactDOM.findDOMNode(this).getBoundingClientRect()
    this.setState({ width, height })
  }

  _onMapClick = clickData => {
    const clickFn = this.props.onMapClick
    if (clickFn && _.isFunction(clickFn)) {
      const { x, y, lat, lng } = clickData
      clickFn(lat, lng, x, y)
    }
  }

  _onMapChange = changeData => {
    const clickFn = this.props.onMapChange
    if (clickFn && _.isFunction(clickFn)) {
      const { x, y, lat, lng } = changeData
      clickFn(lat, lng, x, y)
    }
  }

  _getViewport = () => {
    const { props } = this
    let center = props.initialCenter
    let zoom = props.initialZoom
    if (props.fitMarkerBounds) {
      const validBoundMarkers = _.filter(props.markers, { validBoundary: true }) // If marker has validBoundary: false, it will not be used in bounds computation

      if (validBoundMarkers.length === 1) {
        // For a single marker, jsut use the default zoom
        center = {
          lat: validBoundMarkers[0].latitude,
          lng: validBoundMarkers[0].longitude
        }
      } else if (validBoundMarkers.length > 1) {
        const bounds = {
          nw: {
            lat: _.max(validBoundMarkers, 'latitude').latitude,
            lng: _.min(validBoundMarkers, 'longitude').longitude
          },
          se: {
            lat: _.min(validBoundMarkers, 'latitude').latitude,
            lng: _.max(validBoundMarkers, 'longitude').longitude
          }
        }
        const result = fitBounds(bounds, _.pick(this.state, 'height', 'width'))
        center = result.center
        zoom = result.zoom + props.autoZoomOffset
      }
    }
    return { center, zoom }
  }

  _onMarkerClick = markerId => {
    const fn = this.props.onMarkerClick
    const clickedMarker = _.find(this.props.markers, { id: markerId })
    if (_.isFunction(fn) && clickedMarker) {
      fn(clickedMarker)
    }
  }

  _startBoxDraw = coords => {
    const startX = coords.startX - coords.boundary.left
    const startY = coords.startY - coords.boundary.top
    const endX = coords.endX - coords.boundary.left
    const endY = coords.endY - coords.boundary.top

    if (this.props.onMapBoundsDrawn) {
      const start = this.refs.gmap_react.geoService_.unproject(
        { x: startX, y: startY },
        true
      )
      const end = this.refs.gmap_react.geoService_.unproject(
        { x: endX, y: endY },
        true
      )

      const ne = {
        lat: start.lat > end.lat ? start.lat : end.lat,
        lng: start.lng > end.lng ? start.lng : end.lng
      }

      const sw = {
        lat: start.lat < end.lat ? start.lat : end.lat,
        lng: start.lng < end.lng ? start.lng : end.lng
      }
      const bounds = {
        upperRight: {
          lat: ne.lat,
          lon: ne.lng
        },
        lowerLeft: {
          lat: sw.lat,
          lon: sw.lng
        }
      }

      this.props.onMapBoundsDrawn(bounds)
    }
  }

  _renderMap = () => {
    const { state, props } = this
    const { center, zoom } = this._getViewport()
    const uiConfig = getConfig()

    const canRenderGoogleMaps =
      _.get(uiConfig, 'apiKeys.googleMaps', null) != null

    if (canRenderGoogleMaps && !state.hasGoogleMapsAPI) {
      return <CircleLoader loading />
    }

    return canRenderGoogleMaps
      ? <GoogleMapReact
          ref={'gmap_react'}
          center={center}
          defaultCenter={props.initialCenter}
          defaultZoom={props.initialZoom}
          onChange={this._onMapChange}
          onClick={this._onMapClick}
          options={{
            styles: getGoogleMapStyles(),
            minZoom: 1
          }}
          zoom={zoom}
        >
          {_.map(props.markers, (marker, i) =>
            <GoogleMapMarker
              className={marker.className}
              id={marker.id}
              index={i}
              key={marker.id}
              lat={marker.lat || marker.latitude}
              lng={marker.lng || marker.longitude}
              onClick={this._onMarkerClick}
              text={marker.label || marker.text}
              tooltip={marker.label ? marker.text : null}
            />
          )}
        </GoogleMapReact>
      : <WorldMap
          centerCoordScale={6}
          isReady
          pathAnimationStyle={'none'}
          markers={_convertMarkersToPwMap(props.markers)}
        />
  }

  render() {
    const { state, props } = this

    return state.hasGoogleMapsAPI
      ? <div
          className={`google_map world_map_section ${props.clickable
            ? 'clickable_google_map'
            : ''}`}
        >
          {props.drawMode &&
            <div className="draw_mode">
              <span className="draw_mode_status" onClick={props.setDrawMode}>
                <span>Clear Selection Boundaries</span>
                <span className="draw_mode_dismiss">
                  <span className="icon icon-close" />
                </span>
              </span>
            </div>
          }
          {props.drawMode &&
            <QueryBounds setDrawBoundaries={this._startBoxDraw} />
          }
          {this._renderMap()}
        </div>
      : <CircleLoader loading />
  }
}

const DimensionAwareMap = DimensionAware(GoogleMap)

export default props => (
  <ThemeProvider>
    <DimensionAwareMap {...props} />
  </ThemeProvider>
)

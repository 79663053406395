import React from 'react'
import T from 'prop-types'
import Timeline from 'components/timeline/v2/Timeline'
import _ from 'lodash'
import {
  DimensionAware,
  TextOverflow
} from 'react-base'
import IT from 'react-immutable-proptypes'

const EASE_OUT_EXPO_CSS = 'cubic-bezier(0.190, 1.000, 0.220, 1.000)'
const EASE_LINEAR_CSS = 'cubic-bezier(0.250, 0.250, 0.750, 0.750)'

class PerceptiveProfileGraphSingle extends React.PureComponent {
  static displayName = 'PerceptiveProfileGraphSingle'

  static propTypes = {
    subscription: IT.map,
    currentProfile: IT.map,

    data: IT.map,
    className: T.string,
    yScale: T.func,
    xScaleExponent: T.number,

    isMaximized: T.bool,

    // From DimensionAware
    width: T.number
  }

  static defaultProps = {
    width: 10
  }

  _timestampToX = (timestamp, width, start, end, exponent) => {
    let v = (timestamp - start) / (end - start)
    if (exponent) {
      v = Math.pow(v, exponent)
    }
    return width * v
  }

  getBlockVolumes = () => {
    const {
      data,
      width,
      currentProfile,
      xScaleExponent
    } = this.props

    if (!currentProfile || !data) {
      return []
    }

    const start = +data.get('startTime')
    const end = +data.get('endTime')

    const currentProfileJS = currentProfile.toJS()
    const historyJS = data.get('history').toJS()
    const valClasses = _.indexBy(currentProfileJS.profiles, 'id')


    return historyJS.reduce((out, tick, i) => {
      const prevVol = out.length > 0 ? out[out.length - 1] : null
      const tickVal = Math.min(Math.max(Math.ceil(tick.total / 100) * 100, 100), 600)

      const hasNewRange = prevVol && prevVol.val !== tickVal
      const volLeft = this._timestampToX(tick.timestamp, width, start, end, xScaleExponent)

      if (prevVol) {
        prevVol.widthPx = volLeft - prevVol.leftPx
      }
      if (i === 0 || hasNewRange) {
        const profile = valClasses[tickVal]
        out.push({
          val: tickVal,
          valClassName: profile.name.toLowerCase(),
          leftPx: volLeft,
          widthPx: 0,
          bottomPct: `${profile.backbufferLowThreshold}%`,
          heightPct: `${profile.backbufferHighThreshold - profile.backbufferLowThreshold}%`
        })
      }
      return out
    }, [])
  }

  render() {
    const {
      className,
      subscription,
      data,
      yScale,
      xScaleExponent,
      isMaximized
    } = this.props

    const isRecent = subscription.get('endTime') === 'now'

    const timeTransitionEase = data && data.get('transitionType') === 'play' ? EASE_LINEAR_CSS : EASE_OUT_EXPO_CSS
    const timeTransitionDur = data && data.get('transitionDuration')
    const transitionXStr = `${timeTransitionDur}ms ${timeTransitionEase}`

    return (
      <div className={`perceptive_graph_single ${className}`}>
        <div className="graph_wrap">
          <Timeline
            className="perceptive_timeline"
            data={data}
            lineInterpolation={"monotone"}
            yTicks={6}
            xTicks={1}
            xScaleExponent={xScaleExponent}
            overrideYScale={yScale}
            graphStyleInitial={'MONOTONE'/*'STEP_AFTER'*/}
            strokeColor={'#dddddd'}
            fillColor={'#222222'}
            strokeWidth={2}
            fillOpacity={0.75}
            innerAxes
          />
          <div className="perceptive_volume_fill_graph">
            {this.getBlockVolumes().map((vol, i) => {
              return (
                <div
                  key={i}
                  className={`ppc_profile_state_fill`}
                  style={{
                    width: `${Math.floor(vol.widthPx)}px`,
                    left: `${Math.floor(vol.leftPx)}px`,
                    transition: transitionXStr,
                    WebkitTransition: transitionXStr
                  }}
                >
                  <div
                    className={`ppc_profile_state_fill_inner state_${vol.valClassName}`}
                    style={{
                      height: vol.heightPct,
                      bottom: vol.bottomPct
                    }}
                  />
                </div>
              )
            })}
          </div>
        </div>
        {data &&
          <div className="perceptive_time_axis">
            <div className="time_floor">
              <TextOverflow>-{subscription.get('windowSize')}</TextOverflow>
            </div>
            {isRecent && isMaximized &&
              <div className="time_ceil">
                Now
              </div>
            }
          </div>
        }
      </div>
    )
  }
}

export default DimensionAware(PerceptiveProfileGraphSingle)

import React from 'react'
import T from 'prop-types'
import cx from 'classnames'
import memoize from 'memoize-one'
import { formatBytesToBits } from 'pw-formatters'
import SensorTimeline from './SensorTimeline'


const valueFormatter = val => formatBytesToBits(val, false, 1)

class BandwidthGraph extends React.PureComponent {

  // Note: all props treated as immutable
  static propTypes = {
    bytesCaptured: T.arrayOf(
      T.shape({timestamp: T.number, value: T.number})
    ),
    bytesSeen: T.arrayOf(
      T.shape({timestamp: T.number, value: T.number})
    ),
    bandwidthCap: T.number,
    onTimeHover: T.func,
    rulerTime: T.number,
    yAxis: T.bool,
    title: T.string
  }

  static defaultProps = {
    title: 'Avg Bandwidth'
  }

  _getDataItems = memoize((bytesCaptured, bytesSeen, bandwidthCap) => {
    const items = [
      {
        data: bytesCaptured,
        className: 'captured',
        label: 'Captured'
      },
      {
        data: bytesSeen,
        className: 'seen',
        label: 'Seen'
      }
    ]
    // Add bandwidth cap as a horizontal line if we have one
    if (typeof bandwidthCap === 'number') {
      const hasReachedCap = bytesCaptured && bytesCaptured.some(d => d.value >= bandwidthCap)
      items.push({
        data: (bytesCaptured || bytesSeen || []).map(d => ({timestamp: d.timestamp, value: bandwidthCap})),
        className: cx('cap', hasReachedCap && 'has_reached_cap'),
        label: 'Cap',
        affectsScale: false,
        showInRuler: false
      })
    }
    return items
  })

  render() {
    const {bytesCaptured, bytesSeen, bandwidthCap, onTimeHover, rulerTime, yAxis, title} = this.props
    return <SensorTimeline
      xAxis
      yAxis={yAxis}
      title={title}
      className="sensor_bw_timeline"
      items={this._getDataItems(bytesCaptured, bytesSeen, bandwidthCap)}
      valueFormatter={valueFormatter}
      onTimeHover={onTimeHover}
      rulerTime={rulerTime}
    />
  }
}

export default BandwidthGraph

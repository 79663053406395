
import {observable} from 'mobx'
import {
  SchemerModel,
  schemer
} from 'pw-schemer-mobx'
import DeviceAttribute from './DeviceAttribute'
import {NetworkType} from 'typings/devices/device'
import {SensorIdWithIpAddress} from './SensorIdWithIpAddress'

export class Interface extends SchemerModel {
  @observable
  @schemer.model({
    model: SensorIdWithIpAddress
  })
  ip: SensorIdWithIpAddress

  @observable
  @schemer.macAddress({
    label: 'MAC',
    tooltip: 'MAC Address'
  })
  mac: string

  @observable
  @schemer.enum({
    label: 'Network Type',
    options: NetworkType
  })
  networkType: NetworkType
}

export class InterfaceAttr extends DeviceAttribute {
  @observable
  @schemer.model({
    model: Interface
  })
  value: Interface
}
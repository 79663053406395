import React from 'react'
import T from 'prop-types'
import { AnimateHeight, Button, LoadingValue, StoreProviderHOC } from 'react-base'
import { hasPermission } from 'stores/UserStore'
import SensorActions from 'actions/SensorActions'
import SensorStore from 'stores/SensorStore'

const RESTART_PERMISSION = 'sensors:update'


function SensorRestartButton({sensorId, sensorsById, restartingSensors}) {
  const sensor = sensorsById[sensorId]
  const isRestarting = restartingSensors.includes(sensorId)
  const needsRestart = sensor && !sensor.isVersion2 && !sensor._config_applied && sensor.downloaded && sensor.is_active

  const content = isRestarting
    ? <span className="btn btn-block btn-lg restarting">
        Restarting Sensor <LoadingValue />
      </span>
    : needsRestart
      ? (hasPermission(RESTART_PERMISSION)
        ? <React.Fragment>
            <Button
              className="btn btn-block btn-lg"
              onClick={SensorActions.restartSensor}
              args={[sensorId]}
            >
              Restart Sensor
            </Button>
            <p>
              To apply config changes please restart this sensor. This may result
              in momentary data loss and clearing of backbuffer.
            </p>
          </React.Fragment>
        : <p>
            To apply config changes this sensor must be restarted by an account
            administrator.
          </p>
      ) : null

  return content ? <div className="sensor_restart_btn fade_in_down"><AnimateHeight>{content}</AnimateHeight></div> : null
}
SensorRestartButton.propTypes = {
  sensorId: T.number,
  sensorsById: T.object,
  restartingSensors: T.arrayOf(T.number)
}


export default StoreProviderHOC(
  SensorRestartButton, SensorStore, ['restartingSensors', 'sensorsById']
)

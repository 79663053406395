import _ from 'lodash'
import timelineGradVertexShader from './shaders/timelineGrad.vs.glsl'
import timelineGradFragmentShader from './shaders/timelineGrad.fs.glsl'
import {
  Object3DFacade
} from 'troika-3d'
import {
  // Vector2,
  // Vector3,
  // Face3,
  // SplineCurve,
  // CatmullRomCurve3,
  // Line,
  // Path,
  Mesh,
  Color,
  // LineBasicMaterial,
  MeshBasicMaterial,
  // Geometry,
  BufferGeometry,
  BufferAttribute,
  // MeshLambertMaterial,
  // Shape,
  // ShapeGeometry,
  ShaderMaterial,
  DoubleSide,
  // FlatShading,
  Group as ThreeGroup
} from 'three'
import {
  Line2D,
  solidLineShader
  // dashedLineShader
} from 'utils/threejs/THREE.Line2D'
import {
  curveLinear,
  curveMonotoneX,
  curveBasis,
  curveStep,
  curveStepBefore,
  curveStepAfter
} from 'd3-shape'
import PathBuilder from 'utils/troika-line-graph-3d/PathBuilder'

const SMOOTH_CURVE = true
const USE_GRADIENTS = false

const NUM_CURVE_POINTS_DEFAULT = 500
const DEFAULT_STROKE_WIDTH = 1.0
const DEFAULT_GRADIENT_MAX_OPACITY = 0.5

const SCALE_MARGIN_BOTTOM = 10
const SCALE_MARGIN_TOP = 5

const DEFAULT_COLOR = new Color(0x3ba7db)


const D3_CURVE_INTERPOLATORS = {
  LINEAR: curveLinear,
  MONOTONE: curveMonotoneX, // Preferred
  BASIS: curveBasis,
  STEP: curveStep,
  STEP_BEFORE: curveStepBefore,
  STEP_AFTER: curveStepAfter
}

export const CURVE_OPTIONS = _.mapValues(D3_CURVE_INTERPOLATORS, (v, key) => key)

const FILL_MATERIAL = new ShaderMaterial({
  uniforms: {
    color: { type: 'c', value: DEFAULT_COLOR },
    opacity: { type: 'f', value: 1.0 },
    graphMaxY: { type: 'f', value: 10.0 },
    gradientTopOpacityStop: { type: 'f', value: 1.0 }, // (0.0-1.0) Upper (opacity 1.0) gradient "stop" position (percentage)
    gradientBottomOpacityStop: { type: 'f', value: 0.5 }, // (0.0-1.0) Lower (opacity 0.0) gradient "stop" position (percentage)
    gradientMaxOpacity: { type: 'f', value: DEFAULT_GRADIENT_MAX_OPACITY } // (0.0-1.0) maximum possible gradient opacity
  },
  vertexShader: timelineGradVertexShader,
  fragmentShader: timelineGradFragmentShader,
  transparent: true,
  // side: DoubleSide,
  // shading: FlatShading, // SmoothShading
  wireframe: false
})

const STROKE_MATERIAL = new ShaderMaterial(solidLineShader({
  fog: false,
  side: DoubleSide,
  thickness: DEFAULT_STROKE_WIDTH,
  opacity: 1.0
}))


const FILL_MATERIAL_FLAT = new MeshBasicMaterial({
  color: DEFAULT_COLOR,
  transparent: true,
  opacity: 0.0,
  wireframe: true,
  fog: false
})

const STROKE_MATERIAL_FLAT = new MeshBasicMaterial({
  fog: false,
  side: DoubleSide,
  // thickness: TOP_STROKE_WIDTH,
  opacity: 1.0,
  color: DEFAULT_COLOR,
  transparent: true,
  wireframe: true
})

let _fillMaterial, _strokeMaterial
if (USE_GRADIENTS) {
  _fillMaterial = FILL_MATERIAL
  _strokeMaterial = STROKE_MATERIAL
}
else {
  _fillMaterial = FILL_MATERIAL_FLAT
  _strokeMaterial = STROKE_MATERIAL_FLAT
}


function getCurvePoints (pointVec3s, interpolateFn) {
  const pathBuilder = new PathBuilder({
    bezierNumPoints: 6
  })
  let interpolator = D3_CURVE_INTERPOLATORS[interpolateFn] || curveLinear

  interpolator = interpolator(pathBuilder)
  interpolator.lineStart()
  if (pointVec3s) {
    for (let i = 0, len = pointVec3s.length; i < len; i += 2) {
      interpolator.point(pointVec3s[i], pointVec3s[i + 1])
    }
  }
  interpolator.lineEnd()
  return pathBuilder.getPoints()
}

export default class StreamArea extends Object3DFacade {
  constructor(parent) {
    const group = new ThreeGroup()

    const mesh = new Mesh(
      new BufferGeometry(),
      _fillMaterial.clone()
    )
    // mesh.renderOrder = 20 //prevent occlusion of translucent Host meshes
    group.add(mesh)

    const upperStroke = new Mesh(
      Line2D(
        [],
        {
          distances: false,
          closed: false
        }
      ),
      _strokeMaterial.clone()
    )
    group.add(upperStroke)



    super(parent, group)
  }


  _timestampToX(timestamp) {
    const exponent = this.xScaleExponent
    let v = (timestamp - this.startTime) / (this.endTime - this.startTime)
    if (exponent) {
      v = Math.pow(v, exponent)
    }
    return this.width * v
  }

  _valueToY(value) {
    const {
      minValue,
      maxValue,
      strokeWidth,
      yScale
    } = this
    let output = 0
    if (yScale) {
      output = yScale(value)
    }
    else {
      const yMargin = Math.floor(strokeWidth / 2)
      const topY = this.height - yMargin
      const botY = yMargin
      output = maxValue === minValue ? botY : botY - (botY - topY) * (value - minValue) / (maxValue - minValue)
    }
    return isNaN(output) ? 0 : output
  }


  afterUpdate() {
    const {
      values,
      // xScale,
      // yScale,
      color,
      fillColor,
      strokeColor,
      // numCurvePoints = NUM_CURVE_POINTS_DEFAULT,
      strokeWidth = DEFAULT_STROKE_WIDTH,
      fillOpacity = DEFAULT_GRADIENT_MAX_OPACITY,
      // startTime,
      // endTime,
      // minValue,
      // maxValue,
      pathInterpolate
    } = this // Troika pseudo-props

    const _fillColor = fillColor || color
    const _strokeColor = strokeColor || color

    // console.log('In StreamArea', values)

    // if (pathInterpolate !== this._currentPathInterpolate) {
    //   this._fillGeometryInitialized = false // number of vertices will change, refresh these buffers
    // }
    // this._currentPathInterpolate = pathInterpolate

    const [fill, stroke] = this.threeObject.children
    const {geometry: fillGeometry, material: fillMaterial} = fill
    const {geometry: strokeGeometry, material: strokeMaterial} = stroke

    const _values = Object.values(values)

    const upperPoints = new Array(_values.length * 2) // Flat 2D array of x/y points
    const lowerPoints = new Array(_values.length * 2) // Flat 2D array of x/y points

    // const maxY = yScale.domain()[1]
    for (var i = 0; i < _values.length; i++) {
      const val = _values[i]
      const x = this._timestampToX(val.timestamp) //xScale(val.timestamp)
      upperPoints[i * 2] = x
      upperPoints[i * 2 + 1] = this._valueToY(val.y0)
      lowerPoints[i * 2] = x
      lowerPoints[i * 2 + 1] = this._valueToY(val.y1)
    }

    const pathVertices = getCurvePoints(
      upperPoints,
      SMOOTH_CURVE ? pathInterpolate : 'linear'
    ).concat(
      getCurvePoints(
        lowerPoints,
        SMOOTH_CURVE ? pathInterpolate : 'linear'
      ).reverse() // Clockwise drawing order
    )

    // Simple triangulation based on known counterclockwise layout of vertices
    const endIdx = pathVertices.length - 1
    const midPoint = Math.floor(endIdx / 2)
    const numTriangles = endIdx - 1
    const flatTriangles = new Uint16Array(numTriangles * 3)
    for (var t = 0; t < numTriangles; t++) {
      // Skip "midpoint" that would result in a duplicate triangle
      const vI = t >= midPoint ? t + 1 : t
      flatTriangles[t * 3] = vI
      flatTriangles[t * 3 + 1] = vI + 1
      flatTriangles[t * 3 + 2] = endIdx - (vI + 1)
    }

    // Flatten vertices for BufferGeometry
    const numVerts = pathVertices.length
    const flatVertices = new Float32Array(numVerts * 3)
    const flatNormals = new Float32Array(numVerts * 3)
    const maxYs = new Float32Array(numVerts)
    const minYs = new Float32Array(numVerts)
    let graphMaxY = 0 // Max y value across whole x-span
    const strokeVertices = []

    for (var k = 0; k < numVerts; k++) {
      const [x, y] = pathVertices[k]
      const z = 0
      flatVertices[k * 3] = x
      flatVertices[k * 3 + 1] = y
      flatVertices[k * 3 + 2] = z

      flatNormals[k * 3] = 1.0
      flatNormals[k * 3 + 1] = 1.0
      flatNormals[k * 3 + 2] = 1.0

      // Store max/min y-values per x "column" for shader pseudo-gradient generation
      const isTopRow = k >= numVerts / 2
      const oppositeY = pathVertices[(numVerts - 1) - k][1]
      maxYs[k] = isTopRow ? y : oppositeY
      minYs[k] = isTopRow ? oppositeY : y

      graphMaxY = Math.max(graphMaxY, y)

      if (isTopRow) {
        strokeVertices.push([x, y, z])
      }
    }

    // Update stroke
    strokeGeometry.update(strokeVertices, false)
    strokeGeometry.computeBoundingSphere()
    strokeGeometry.verticesNeedUpdate = true
    strokeGeometry.elementsNeedUpdate = true

    if (USE_GRADIENTS) {
      strokeMaterial.uniforms.diffuse.value = new Color(_strokeColor)
      strokeMaterial.uniforms.thickness.value = strokeWidth
    } else {
      strokeMaterial.color = new Color(_strokeColor)
    }

    // Update fill
    if (this._fillGeometryInitialized) {
      fillGeometry.attributes.position.setArray(flatVertices)
      fillGeometry.attributes.normal.setArray(flatNormals)
      fillGeometry.attributes.vertexMaxY.setArray(maxYs)
      fillGeometry.attributes.vertexMinY.setArray(minYs)

      fillGeometry.attributes.position.needsUpdate = true
      fillGeometry.attributes.normal.needsUpdate = true
      fillGeometry.attributes.vertexMaxY.needsUpdate = true
      fillGeometry.attributes.vertexMinY.needsUpdate = true

      fillGeometry.index.setArray(flatTriangles)
      fillGeometry.index.needsUpdate = true
    }
    else {
      this._fillGeometryInitialized = true
      // Create buffer attributes
      fillGeometry.addAttribute('position', new BufferAttribute(flatVertices, 3))
      fillGeometry.addAttribute('normal', new BufferAttribute(flatNormals, 3))
      fillGeometry.addAttribute('vertexMaxY', new BufferAttribute(maxYs, 1))
      fillGeometry.addAttribute('vertexMinY', new BufferAttribute(minYs, 1))

      fillGeometry.setIndex(new BufferAttribute(flatTriangles, 1))
    }

    fillGeometry.computeBoundingSphere()
    fillGeometry.verticesNeedUpdate = true
    fillGeometry.elementsNeedUpdate = true

    if (USE_GRADIENTS) {

      fillMaterial.uniforms.color.value = new Color(_fillColor)
      fillMaterial.uniforms.graphMaxY.value = graphMaxY // emulates SVG "userSpaceOnUse"
      fillMaterial.uniforms.gradientMaxOpacity.value = fillOpacity
    } else {
      fillMaterial.color = new Color(_fillColor)
    }

    super.afterUpdate()
  }

  destructor() {
    super.destructor()
    // TODO dispose fillGeometry/fillMaterial?
  }
}

import _ from 'lodash'
import Reflux from 'reflux'
import moment from 'moment'
import { requestGet } from 'utils/restUtils'
import SensorStore from 'stores/SensorStore'
import { listenToStore } from 'utils/storeUtils'
import SensorActions from 'actions/SensorActions'
import querystringUtil from 'ui-base/src/util/querystringUtil'


const POLL_INTERVAL = 60 * 1000


let _queryableSensors = []
let _pollTimer = null

const _state = {
  hasCurrentLoss: {
    isLoading: true,
    all: [], //[...sensorObjectsWithLoss]
    bySensorSet: Object.create(null), //setId => [...sensorObjectsWithLoss]
    bySensor: Object.create(null) //sensorId => true/false
  }
}

const SensorHealthStore = Reflux.createStore({
  listenables: SensorActions,

  init() {
    listenToStore(this, SensorStore, this._onSensorStoreUpdate)
  },

  getInitialState() {
    return _state
  },

  _notify() {
    this.trigger(_state)
  },

  _onSensorStoreUpdate(newState) {
    let newSensors = _.reduce(newState.sensorSets, (out, {enabledSensors}) => {
      if (enabledSensors) {
        enabledSensors.forEach(sensor => {
          if (sensor.downloaded) {
            out.push(sensor)
          }
        })
      }
      return out
    }, [])
    newSensors = _.sortBy(newSensors, 'id')
    if (!_.isEqual(_.pluck(newSensors, 'id'), _.pluck(_queryableSensors, 'id'))) {
      _queryableSensors = newSensors
      SensorActions.loadSensorHealth()
    }
  },

  // Listener for SensorActions.loadSensorHealth()
  async onLoadSensorHealth() {
    // TODO disabling sensor health loads for now until we can verify the query timeframe is appropriate
    return

    clearTimeout(_pollTimer)

    await this._loadCurrentLoss()

    // Schedule next update
    // TODO this feels like the wrong place to do this, but where should it?
    _pollTimer = setTimeout(SensorActions.loadSensorHealth, POLL_INTERVAL)
  },

  async _loadCurrentLoss() {
    // Note: we set isLoading but don't clear the previous results right away, to avoid flashes of no-loss while loading
    _state.hasCurrentLoss = _.assign({}, _state.hasCurrentLoss, {isLoading: true})

    const start = +moment.utc().startOf('minute').subtract(4, 'minutes')
    const end = +moment.utc().startOf('minute').add(1, 'minute')
    const interval = '5minutes'

    const newData = {isLoading: false, all: [], bySensorSet: Object.create(null), bySensor: Object.create(null)}

    if (_queryableSensors.length) {
      try {
        await Promise.all(
          _.chunk(_queryableSensors, 25).map(async (sensors, i) => {
            let result = await requestGet(
              `sensor_dash_cur_loss_${i}`,
              `counters?${querystringUtil.stringify({
                sensorId: _.pluck(sensors, 'id'),
                start,
                end,
                interval,
                counterType: 'bytesDropped',
                stackBy: 'sensor'
              })}`,
              {useSocket: true}
            )

            // result's first time interval should span entire requested period so we can drop any trailing ones
            result = result && result[0] || {}

            sensors.forEach(sensor => {
              let hasLoss = (result[sensor.id] || 0) > 0

              // DEBUG
              if (window.pwDev && (sensor.id === 3100 || sensor.id === 3130)) {
                hasLoss = true
              }

              newData.bySensor[sensor.id] = hasLoss
              if (hasLoss) {
                const setSensors = newData.bySensorSet[sensor.agent_set_id] || (newData.bySensorSet[sensor.agent_set_id] = [])
                setSensors.push(sensor)
                newData.all.push(sensor)
              }
            })
          })
        )
      } catch(restError) {
        // no-op, just let state get replaced with empty data
      }
    }

    Object.freeze(newData.all)
    Object.freeze(newData.bySensor)
    Object.freeze(newData.bySensorSet)
    _state.hasCurrentLoss = newData
    this._notify()
  }
})


export default SensorHealthStore

import _ from 'lodash'
import {
  observable,
  flow,
  toJS
} from 'mobx'
import {
  Store
} from 'stores/mobx/StoreManager'
import explorerUtils from 'utils/explorerUtils'
import {
  getMoment
} from 'utils/timeUtils'
import { RELATIVE_TIME_PRESETS_BY_ID } from 'utils/relativeTimePresets'
import loadStaticTemplates from './templates.js'

export default class QueryTemplateStore extends Store {
  @observable templates = [] //TEMPLATES
  @observable isLoading = false
  @observable error = null

  static resolveTemplate (template) {
    const templateJS = toJS(template)
    explorerUtils.walkClauses(templateJS.preset.query.clauses, clause => {
      // Resolve relative time presets to an actual time range
      if (clause.op === '_timerange-preset') {
        const timePreset = RELATIVE_TIME_PRESETS_BY_ID[clause.value]
        if (!timePreset) {
          throw new Error('Invalid time preset specified in Query Template')
        }
        const {
          start,
          end
        } = timePreset.getRange(getMoment())

        clause.from = +start,
        clause.to = +end
        clause.op = 'between'
        delete clause.value
      }
    })
    return templateJS
  }

  loadTemplates = flow(function * () {
    this.templates = []
    this.isLoading = true
    this.error = null
    try {
      this.templates = yield loadStaticTemplates()
    }
    catch (error) {
      this.error = error
    }
    this.isLoading = false
  })
}
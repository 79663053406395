import T from 'prop-types'
import React from 'react'
import {StoreProvider} from 'react-base'
import PreferencesStore from 'stores/PreferencesStore'

class ThemeProvider extends React.Component {
  static displayName = 'ThemeProvider'

  static propTypes = {
    children: T.node,
  }

  _mapPrefsToThemeId = (prefsStoreState) => {
    return {
      themeId: prefsStoreState.preferences.theme
    }
  }

  render() {
    return (
      <StoreProvider
        mapping={ this._mapPrefsToThemeId }
        store={ PreferencesStore }
      >
        { this.props.children }
      </StoreProvider>
    )
  }
}

export default ThemeProvider

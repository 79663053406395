import _ from 'lodash'
import cx from 'classnames'
import React from 'react'
import { MenuItem, Tabs } from 'react-base'
import QuickSearch from '../QuickSearch'
import StaticAsset from '../StaticAsset'
import T from 'prop-types'
import {
  topLevelNavItems
} from 'pwConstants'
import UserStore from 'stores/UserStore'
import MetaNav from './MetaNav'
import getConfig from 'utils/uiConfig'

export default class extends React.PureComponent {
  static displayName = 'NavBar'

  static propTypes = {
    activeRoute: T.string,
    userInfo: T.object
  }

  static defaultProps = {
    activeRoute: null
  }

  _getNavOptions = () => {
    const { userInfo, activeRoute } = this.props

    const { userCanAccessIntelManagement } = userInfo

    return _.compact(
      _.map(topLevelNavItems, (route, key) => {
        switch (key) {
          case 'hud': {
            const hasSensorGroupRole = userInfo.roles.some(role => !!role.sensor_group_ids.length)
            if (hasSensorGroupRole) {
              return null
            }
            return {
              display: key,
              key: key,
              route: route
            }
          }
          case 'intel':
            if (userCanAccessIntelManagement) {
              const output = {
                display: 'Intel',
                icon: 'satellite',
                key: key,
                route: route,
                className: 'nav_itm_secondary'
              }
              return output
            } else {
              return null
            }
          case 'sensors':
            return {
              display: 'Sensors',
              icon: 'sensor-small',
              key: key,
              route: route,
              className: 'nav_itm_secondary'
            }
          case 'devices':
            return {
              display: 'Devices',
              icon: 'device',
              key: key,
              route: route,
              className: 'nav_itm_secondary'
            }
          case 'settings':
            return {
              display: 'Settings',
              icon: 'cogs',
              key: key,
              route: route,
              className: 'nav_itm_secondary'
            }
          case 'grid':
            if (getConfig().enableGridDemo !== true
              || UserStore.isTestDriveCustomer()
              || (!UserStore.isDemoCustomer() && !localStorage.enableGridTabNonDemo)) {
              return null // no grid for you!
            }
          default:
            return {
              display: key,
              key: key,
              route: route
            }
        }
      })
    )
  }

  _mapNavItems = itm => {
    return (
      <MenuItem
        className={`nav_bar_itm nav_itm_${itm.key} ${itm.className || ''}`}
        disabled={itm.disabled}
        href={'#' + itm.route}
        key={itm.key || itm.route}
        onClick={itm.onClick}
        data-cueid={`mainnav_${itm.key}`}
      >
        {itm.icon && (<span className={`icon icon-${itm.icon}`} />)}{itm.display}
      </MenuItem>
    )
  }

  render() {
    const { props } = this
    const uiConfig = getConfig()
    return (
      <div
        className={cx('nav_region_main pull_up')}
      >
        <div className="brand_wrap">
          <StaticAsset asset="mainLogo" />
        </div>
        <Tabs activeTabKey={props.activeRoute} flex data-hotkey="n">
          {_.map(this._getNavOptions(), this._mapNavItems)}
        </Tabs>
        <QuickSearch />
        <MetaNav />
      </div>
    )
  }
}

export enum DeviceAttribute {
  interface = "interface", // Interface
  geo = "geo", // GeoDetails
  os = "os", // device.Os
  host = "host", // StringValue
  mac = "mac", // BytesValue
  ip = "ip", // SensorIdWithIpAddress
  awsId = "awsId", // AwsId
  awsDetails = "awsDetails", // AwsDetails
  role = "role", // device.DeviceRole
  priority = "priority", // device.Priority
  description = "description", // StringValue
  department = "department", // StringValue
  networkDescription = "networkDescription", // StringValue
  customerDeviceId = "customerDeviceId", // StringValue
  transactionId = "transactionId", // StringValue
  customerIp = "customerIp", // common.IpAddr
  firstSeen = "firstSeen", // TimestampValue
  lastSeen = "lastSeen", // TimestampValue
  externalIp = "externalIp", // common.IpAddr
  userDetails = "userDetails", // UserDetails
  vendor = "vendor", // StringValue
  risk = "risk" // Risk
}

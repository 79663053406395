import Reflux from 'reflux'

export default Reflux.createActions([
  'authenticateUser', // ({email, password})
  'authenticateUserMfa', // ({otp, token})
  'authenticateUserGoogleOAuth', // ({id_token,acess_token})

  'googleOAuthError',  // (errorReason)
  'setUserObject', //(User) - called when DataManager creates the User instance
  'verifySession', // (bool reloadOnFail)

  'setEmulatedCustomer', // (customerId)
  'unsetEmulatedCustomer', //([href])
  'unsetEmulatedCustomerIfEmulating',
  'logout',

  'createCustomer' // (customerData)
])

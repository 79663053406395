import _ from 'lodash'
import React, { useCallback, useState } from 'react'
import BrandMain from 'components/BrandMain'
import { TabContent, TabContentPane } from 'react-base'
import { requestPost } from 'utils/restUtils'
import LoginCardFooter from './LoginCardFooter'

function Signup () {

  const [token, setToken] = useState('')
  const [email, setEmail] = useState('')
  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const [signupStage, setSignupStage] = useState('register_email')
  const [error, setError] = useState(null)

  function validate (form) {
    const hasAll = Object.values(form).every(v => v && v.length)
    if (!hasAll) return "All fields are required"
    return null
  }

  const signupSubmit = useCallback(async event => {
    event.preventDefault()
    const form = { email, firstname, lastname }
    const invalidMsg = validate(form)
    if (invalidMsg) {
      return setError({ message: invalidMsg, level: "warning" })
    }
    try {
      setSubmitting(true)
      setError(null)
      const response = await requestPost(
        'signup',
        'users/register',
        { email, firstname, lastname },
        { redirect401: false, resolveWithParsedBody: false }
      )
      console.debug(response)
      return setSignupStage('confirm_sent')
    } catch (error) {
      console.error('signup error', error)
      switch(error.statusCode) {
        case 401:
          return setError({
            level: 'warning',
            message: 'You are not allowed to register with the email address provided.'
          })
        case 409:
          return setError({
            level: 'warning',
            message: 'User with this email address already exists.'
          })
        default:
          return setError({
            level: 'danger',
            message: 'Could not register your email. Please try again later.'
          })
      }
    } finally {
      setSubmitting(false)
    }
  }, [email, firstname, lastname])

  const tokenSubmit = useCallback(event => {
    event.preventDefault()
    setSubmitting(true)
    location.hash = `complete_registration?token=${token}`
  }, [token])

  return (
    <div className="modal_wrap" id="login_form">
      <div className="accountform_wrap">
        <BrandMain />
        <div className="slide_container">
          <div className={`slide_card login_card signup_card ${ signupStage == 'register_email' ? 'extended_fields' : '' } ${error ? 'has_error_alert' : ''}`}>
            <TabContent activeTabKey={signupStage} onlyRenderActivePane>
              <TabContentPane key="register_email">
                <form className="signup_form pw_form" onSubmit={signupSubmit}>
                  <h6 className="signup_heading">Sign Up</h6>
                  {!error ? (
                    <div className={`alert alert-info`}>
                      <h4>Note:</h4>
                      <span>
                        Registration is currently limited to Verizon employees.
                      </span>
                    </div>
                  ) : (
                    <div className={`alert alert-${error?.level || 'none'} ${error ? 'has_errors' : '' }`}>
                      <h4>Error:</h4>
                      <span>
                        {' '}{error?.message || '&nbsp;'}
                      </span>
                    </div>
                  )}
                  <div className="form_fields">
                    <input
                      className="form-control"
                      id="signup_firstname"
                      name="firstname"
                      onChange={event => setFirstname(event.target.value)}
                      placeholder="first name"
                      type="text"
                      autoComplete="given-name" />
                    <input
                      className="form-control"
                      id="signup_lastname"
                      name="lastname"
                      onChange={event => setLastname(event.target.value)}
                      placeholder="last name"
                      type="text"
                      autoComplete="family-name" />
                    <input
                      className="form-control"
                      id="signup_email"
                      name="email"
                      onChange={event => setEmail(event.target.value)}
                      placeholder="email address"
                      type="email"
                      autoComplete="username" />
                  </div>
                  <button id="signup_submit" className="btn btn-block btn-info btn-lg" disabled={submitting} type="submit">
                    { submitting ? 'Please wait...' : 'Register' }
                  </button>
                </form>
              </TabContentPane>
              <TabContentPane key="confirm_sent">
                <form className="registration_token_form pw_form" onSubmit={tokenSubmit}>
                  <h5 className="signup_heading">Registration Successful</h5>
                  <p className="form_text">Please enter the registration token sent to the provided email to complete registration.</p>
                  <div className="form_fields">
                    <input
                      className="form-control"
                      id="signup_token"
                      name="token"
                      onChange={event => setToken(event.target.value)}
                      placeholder="registration token"
                      type="text"
                    />
                  </div>
                  <button id="complete_registration" className="btn btn-info btn-lg btn-block" disabled={submitting} type="submit">
                    { submitting ? 'Please wait...' : 'Complete Registration' }
                  </button>
                </form>
              </TabContentPane>
            </TabContent>
          </div>
          <LoginCardFooter />
        </div>
      </div>
    </div>
  )
}

export default Signup

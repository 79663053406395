import _ from 'lodash'
import T from 'prop-types'
import React from 'react'
import cx from 'classnames'
import {
  ScrollBars,
  CircleLoader,
  StoreProviderHOC
} from 'react-base'
import SensorActions from '../../actions/SensorActions'
import { UserProviderHOC } from '../UserProvider'
import SensorLocationsStore from '../../stores/SensorLocationsStore'
import SensorLocationsInput from './SensorLocationsInput'



class SensorLocationsForm extends React.Component {
  static displayName = 'SensorLocationsForm'

  static propTypes = {
    sensorId: T.number.isRequired,

    // From UserProvider
    hasPermission: T.func,
    isSupportCustomer: T.bool,

    // From SensorLocationsStore
    isLoading: T.bool,
    isSaving: T.bool,
    data: T.arrayOf(T.shape({
      "id": T.number,
      "cidr_range": T.string,
      "location_id": T.number
    })),
    error: T.any
  }

  constructor(props) {
    super(props)
    this.state = {
      subnetLocations: []
    }
    SensorActions.loadSensorLocations(props.sensorId)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.data !== this.props.data) {
      this.setState({
        subnetLocations: _.clone(nextProps.data) || []
      })
    }

  }

  _onLocationsChanged = subnetLocations => {
    this.setState({subnetLocations})
  }

  _isFormDirty = () => {
    return !_.isEqual(this.props.data, this.state.subnetLocations)
  }

  _handleSaveClick = () => {
    SensorActions.saveSensorLocations(this.props.sensorId, this.state.subnetLocations)
  }

  _canWrite = () => {
    return this.props.hasPermission('sensor-locations:update')
  }

  render() {
    let { props } = this

    return (
      <div className={cx(
        'sensor_config_form',
        // props.status === 'dirty' && 'dirty',
        // props.status === 'saving' && 'saving'
      )}>
        {props.isLoading
          ? <CircleLoader loading />
          : <React.Fragment>
            {this.renderFields()}
            {this.renderSubmitBtn()}
          </React.Fragment>
        }
      </div>
    )
  }

  renderFields = () => {
    let props = this.props

    return (
      <ScrollBars className="sensor_config_fields pw_scrollbars_light" slimShady slimShadyDarker outside>

        <SensorLocationsInput
          hasPermission={props.hasPermission}
          subnetLocations={this.state.subnetLocations}
          onChange={this._onLocationsChanged}
          sensorId={+props.sensorId}
        />

      </ScrollBars>
    )
  }

  renderSubmitBtn = () => {
    let props = this.props

    const canWrite = this._canWrite()
    const isDirty = this._isFormDirty()

    const content = isDirty && canWrite
      ? <button
          className="btn btn-block btn-lg btn-primary fade_in_down"
          onClick={this._handleSaveClick}
        >
          Save Changes
        </button>
      : props.isSaving && canWrite
        ? <span className="btn btn-block btn-lg btn-saving loading fade_in_down">
            <svg className="loading_circle_mini">
              <circle r="10" cx="50%" cy="50%" />
            </svg>
            Saving Changes...
          </span>
        : null
    return content && <div className="cfg_btn_ct">{content}</div>
  }
}

export default UserProviderHOC(
  StoreProviderHOC(
    props => <SensorLocationsForm {...props} key={props.sensorId} />, //key by sensorId to ensure fresh component if id changes
    SensorLocationsStore,
    state => _.omit(state, ['sensorId']) //don't clobber sensorId prop from parent component
  )
)

import _ from 'lodash'
import Reflux from 'reflux'
import ProfilerActions from 'actions/ProfilerActions'
import {requestGet} from 'utils/restUtils'
import {
  ppcSchema
} from 'constants/captureProfiles'

const DEFAULT_STATE = {
  isLoading: false,
  error: null,
  recommendedPerceptiveProfile: null
}

const _state = _.cloneDeep(DEFAULT_STATE)

export default Reflux.createStore({
  getInitialState() {
    return _state
  },

  listenables: [
    ProfilerActions.PerceptiveProfiles
  ],

  init() {
  },

  onLoadRecommended () {
    _state.recommendedPerceptiveProfile = null
    _state.isLoading = true
    _state.error = null

    requestGet(
      `get_perceptive_profile`,
      `adaptive-capture-profiles/recommended`,
      {
        baseURL: `/api/alpha/`
      }
    )
    .then(
      recommendedProfile => {
        _state.isLoading = false
        _state.recommendedPerceptiveProfile = ppcSchema.convertInbound(recommendedProfile)
        this._notify()
      },
      restError => {
        _state.isLoading = false
        _state.error = restError
        this._notify()
      }
    )
  },

  _notify() {
    this.trigger(_state)
  },

  _queueNotify(exceptionsUpdated) {
    this._notify(exceptionsUpdated)
  },

  getState() {
    return _state
  }
})

import { action, computed, flow, observable, reaction, toJS } from 'mobx'
import genericUtil from 'ui-base/src/util/genericUtil'
import querystringUtil from 'ui-base/src/util/querystringUtil'
import { requestGet, requestPost } from 'utils/restUtils'
import { Store } from 'stores/mobx/StoreManager'
import { RouteStore } from 'stores/mobx/data/global/RouteStore'
import { LocalStoragePrefsStore } from 'stores/mobx/data/global/LocalStoragePrefsStore'
import urlHistoryUtil from 'utils/urlHistoryUtil'

import {
  Query,
  QueryClause,
  QueryColumn,
  QueryColumnTypes,
  QueryConjunction,
  QueryFamily,
  QueryOperator,
  QueryOperators,
  QueryOperatorsList,
  QueryResult,
  QueryTask,
  QueryTaskState
} from 'typings/query'
import { columns as netflowColumns } from './Netflow'
import { columns as observationColumns } from './Observation'

const POLL_INTERVAL = 5000
const MAX_POLL_COUNT = 350
const SOFT_LIMIT = 1000
const DURATION_LIMIT_VALUE = 24
const DURATION_LIMIT_INTERVAL = 'hours'
const HISTORY_KEY = 'EX_2_Q_HISTORY'
const HISTORY_LENGTH_MAX = 5
const PREFS_KEY = 'EX_2_QUERY_PREFS'

interface TaskCreate {
  id: string
}

interface TaskStatus {
  id: string
  state: "SUCCEEDED" | "FAILED" | "RUNNING"
  bytes: number
  bytesScanned: number
  runtimeMillis: number
  total?: number
}

interface TaskResults {
  next?: string
  rows: Array<Record<string, any>>
}

export function sleep (seconds: number): Promise<void> {
  return new Promise((resolve) =>  setTimeout(resolve, seconds))
}

/**
 * @throws {RestError}
 */
export async function createQueryTask (query: Query): Promise<QueryTask> {
  console.debug('submitQuery start', JSON.stringify(query))
  // TODO: extract to common place
  let initialQuery: any = { from : null, to: null, clauses: [], }
  if (query.limit && query.limit > 0) {
    initialQuery.limit = query.limit
  }
  if(query.sort && query.direction) {
    switch (query.sort) {
      case 'startTime':
        initialQuery.sort = 'from'
        break
      case 'endTime':
        initialQuery.sort = 'to'
        break
      case 'occurredAt':
        initialQuery.sort = 'to'
        break
      default:
        initialQuery.sort = query.sort
    }
    initialQuery.direction = query.direction
  }
  let type = null
  const payload = query.where.clauses.reduce((q, clause) => {
    // TODO: Skip subgroups for now!
    if ("conjunction" in clause) {
      return q
    }
    // this ends up in the url path
    if(query.from === 'observations' && clause.column.key === 'type') {
      type = clause.value
      return q
    }
    // TODO: Skip, we want validation?
    if (clause.column.key == "any" || clause.value === null) {
      return q
    }
    switch(clause.column.type) {
      case QueryColumnTypes.Datetime: {
        // TODO: Hardcoded operator arity
        let [from, to] = clause.value as string|number[]
        q.from = new Date(from).toISOString()
        q.to = new Date(to).toISOString()
        return q
      }
      default:
        // TODO: Hardcoded operator, type, + arity
        q.clauses.push({ name: clause.column.key, operator: clause.operator.symbol, value: clause.value })
        return q
    }
  }, initialQuery)

  console.debug('clauses', payload)

  const path = query.from === 'netflows' ? `/query/${query.from}` : `/query/${query.from}/${type}`

  const task: TaskCreate = await requestPost('query_submit', path, payload, { baseURL: `/api/alpha` })

  const result: QueryTask = {
    id: task.id,
    state: QueryTaskState.RUNNING,
    statistics: {
      scannedBytes: 0,
      resultBytes: 0,
      rowCount: 0,
      execMillis: 0
    }
  }
  console.debug('submitQuery result', result)
  return result
}

export async function getQueryTaskStatus (id: string): Promise<QueryTask> {
  const params = querystringUtil.stringify({ id })
  console.debug('getQueryStatus', id, params)
  const task: TaskStatus = await requestGet('query_status', `query?${params}`, { baseURL: '/api/alpha/' })
  const state = QueryTaskState[task.state]
  console.debug('getQueryStatus response', task, state)
  const result: QueryTask = {
    id: task.id,
    state: state,
    statistics: {
      scannedBytes: task.bytesScanned,
      resultBytes: task.bytes || 0,
      rowCount: 0,
      execMillis: task.runtimeMillis,
      total: task.total
    }
  }
  return result
}

export async function getQueryNetflowsResultsPage(queryId: string, pageSize: number,  offset: string|null): Promise<TaskResults> {
  const query = Object.assign({ id: queryId, max: pageSize }, offset && { token: offset })
  const params = querystringUtil.stringify(query)
  const response: TaskResults = await requestGet('get_query_result_page', `query/netflows/results?${params}`, { baseURL: '/api/alpha/' })
  return response
}

export async function getQueryObservationsResultsPage(queryId: string, type: string, pageSize: number,  offset: string|null): Promise<TaskResults> {
  const query = Object.assign({ id: queryId, max: pageSize }, offset && { token: offset })
  const params = querystringUtil.stringify(query)
  const response: TaskResults = await requestGet('get_query_result_page', `query/observations/${type}/results?${params}`, { baseURL: '/api/alpha/' })
  return response
}

async function cancelQuery(queryId: string) {
  return requestGet('cancel_query', `query/cancel?id=${queryId}`, { baseURL: '/api/alpha/' })
}

interface IQueryTimeBounds {
  from: number
  to: number
}

export class QueryStore extends Store {

  static dependencies = [RouteStore, LocalStoragePrefsStore]
  routeStore: RouteStore
  localStore: LocalStoragePrefsStore
  constructor(deps) {
    super(deps)
    const [routeStore, localStore] = deps
    this.routeStore = routeStore
    this.localStore = localStore
    this.init()
    this.destroy = reaction(
      () => this.family,
      () => {
        this.resetQuery()
        this.resetResult()
    })
  }

  readonly familyColumns: Map<QueryFamily, Array<QueryColumn>> = new Map([
    [QueryFamily.Netflows, netflowColumns],
    [QueryFamily.Observations, observationColumns]
  ])

  readonly familyPartitionColumnKeys: Map<QueryFamily, string> = new Map([
    [QueryFamily.Netflows, 'endTime'],
    [QueryFamily.Observations, 'occurredAt']
  ])

  timeLimitsAreValid (): boolean {
    if (this.query.limit > 0 && this.query.limit <= SOFT_LIMIT) {
      return true
    }
    // let bounds = this.timeBounds
    return (this.timeBounds.to - this.timeBounds.from) <= genericUtil.durations(DURATION_LIMIT_VALUE, DURATION_LIMIT_INTERVAL)
  }

  getJsQuery () {
    return toJS(this.query)
  }

  @observable family: QueryFamily = QueryFamily.Netflows
  @observable query: Query
  @observable result: QueryResult

  @computed get timeBounds(): IQueryTimeBounds {
    let timeFilter
    for (let c of this.query.where.clauses) {
      if ("conjunction" in c) {
        continue
      }
      if (c.column.type === QueryColumnTypes.Datetime) {
        timeFilter = c.value as string|number[]
        break
      }
    }
    let [from, to] = timeFilter
    return { from, to }
  }

  @computed get observationType(): string {
    let type
    for (let c of this.query.where.clauses) {
      if ("conjunction" in c) {
        continue
      }
      if (c.column.key === "type") {
        type = c.value
        break
      }
    }
    return type
  }

  get partitionColumnKey(): string {
    return this.familyPartitionColumnKeys.get(this.family)
  }

  get columns(): Array<QueryColumn> {
    return this.familyColumns.get(this.family)
  }

  @computed get isLoading() {
    if (!this.result) {
      return false
    }
    switch(this.result.state) {
      case QueryTaskState.NEW:
      case QueryTaskState.EXISTING:
      case QueryTaskState.CANCELED:
      case QueryTaskState.FAILED:
      case QueryTaskState.SUCCEEDED:
      case QueryTaskState.DOWNLOADING:
      case QueryTaskState.COMPLETE:
        return false
      case QueryTaskState.RUNNING:
      case QueryTaskState.CANCELING:
      case QueryTaskState.SUBMITTING:
      case QueryTaskState.QUEUED:
        return true
    }
  }

  @computed get isDownloading() {
    if (!this.result) {
      return false
    }
    return this.result.state === QueryTaskState.DOWNLOADING
  }

  @computed get columnByKey(): Map<string, QueryColumn> {
    return new Map(this.columns.map((column) => {
      return [ column.key, column ]
    }))
  }

  @computed get hasNextPage(): boolean {
    return this.result.state != QueryTaskState.FAILED && this.result.nextPage != null
  }

  @computed get defaultColumnOrder(): Array<string> {
    return this.columns.map(column => column.key)
  }

  @computed get activeColumns(): Array<string> {
    const prefs = this.localStore.get(PREFS_KEY)
    return prefs && prefs[this.family] ? prefs[this.family] : this.columns.map(column => column.key)
  }

  @action.bound updateActiveColumns = (activeColumns) => {
    let prefs = this.localStore.get(PREFS_KEY) || {}
    prefs[this.family] = activeColumns
    this.localStore.set(PREFS_KEY, prefs)
  }

  canLoadFromUrl ():boolean {
    return !!this.routeStore.currentRoute.queryParams
  }

  @action.bound
  updateRoute() {
    let params:any = {
      family: this.family,
      id: this.result && this.result.id,
      limit: this.query && this.query.limit,
      from: this.timeBounds.from,
      to: this.timeBounds.to,
      type: this.observationType,
      sort: this.query.sort,
      direction: this.query.direction
    }

    let clauses = this.query.where.clauses
      .filter((c:any) => c.column.type !== QueryColumnTypes.Datetime && c.column.key !== 'type')

    if (clauses.length) {
      params.clauses = JSON.stringify(
        clauses.map((c: any) => ({
          column: c.column.key,
          symbol: c.operator.symbol,
          value: c.value
        }))
      )
    }
    this.routeStore.updatePath('', 0, params, true)
  }

  @action.bound loadExisting = (params?) => {
    const urlParams:any = params || this.routeStore.currentRoute.queryParams || {}
    this.routeStore.mergeParams(urlParams)
    const clauses = urlParams.clauses ? JSON.parse(urlParams.clauses) : []
    if (urlParams.family) {
      this.family = urlParams.family as QueryFamily
    }
    this.resetQuery(Number(urlParams.from), Number(urlParams.to), urlParams.type, false)
    if(urlParams.id) {
      this.resetResult(urlParams.id)
    }
    if(urlParams.limit) {
      this.query.limit = Number(urlParams.limit)
    }
    if(urlParams.sort) {
      this.query.sort = urlParams.sort
    }
    if(urlParams.direction) {
      this.query.direction = urlParams.direction
    }
    if(clauses.length) {
      clauses.forEach(c => {
        c.column = {
          key: c.column,
          type: this.columns.find(o => o.key === c.column).type
        }
        c.operator = QueryOperatorsList.find(o => o.symbol === c.symbol)
        this.addClause(c, false)
      })
    }

    if(urlParams.id) {
      this.updateHistory()
      this.getNextPage()
    } else {
      this.submitQuery()
    }
  }

  @action.bound getHistory = () => {
    const history = this.localStore.get(HISTORY_KEY) || {}
    return history[this.family] || []
  }

  // oldest queries at the end of the list
  // previous query selection places item in the front of the list, timestamp stays the same
  @action.bound updateHistory = () => {
    const history = this.localStore.get(HISTORY_KEY) || { netflows: [], observations: [] }
    const item = {
      id: this.result.id,
      timestamp: Date.now(),
      value: urlHistoryUtil.parseUrl().queryStr
    }
    if (!history[this.family].find(h => h.id === item.id)) {
      const len = history[this.family].unshift(item)
      if (len > HISTORY_LENGTH_MAX) {
        history[this.family].pop()
      }
    } else {
      const idx = history[this.family].findIndex(h => h.id === item.id)
      const old = history[this.family].splice(idx, 1)
      history[this.family].unshift(old[0])
    }
    console.log(history[this.family])
    this.localStore.set(HISTORY_KEY, history)
  }

  @action.bound setFamily = (next: QueryFamily): void => {
    console.debug('setFamily', next)
    this.family = next
    this.updateRoute()
  }

  @action.bound
  setId(id: string): void {
    this.result.id = id
    // this side effect is tricky...
    this.result.state = QueryTaskState.EXISTING
    this.updateRoute()
  }

  @action.bound
  updateClauseColumn(current: QueryClause, next: QueryColumn): void {
    console.debug(current, next)
    const where = this.query.where
    const clauses = where.clauses
    this.query = {
      ...this.query,
      where: {
        ...where,
        clauses: clauses.map((c) => c == current ? Object.assign(current, { column: next, value: null }) : c)
      }
    }
    this.updateRoute()
  }

  @action.bound
  updateClauseOperator(current: QueryClause, next: QueryOperator): void {
    const where = this.query.where
    const clauses = where.clauses
    this.query = {
      ...this.query,
      where: {
        ...where,
        clauses: clauses.map((c) => c == current ? { ...c, operator: next } : c)
      }
    }
    this.updateRoute()
  }

  @action.bound
  updateClauseValue(clause: QueryClause, value: any): void {
    const where = this.query.where
    const clauses = where.clauses
    this.query = {
      ...this.query,
      where: {
        ...where,
        clauses: clauses.map((c) => c == clause ? { ...c, value } : c)
      }
    }
    if (!this.timeLimitsAreValid()) {
      this.query = {
        ...this.query,
        limit: SOFT_LIMIT
      }
    }
    this.updateRoute()
  }

  @action.bound
  removeClause(clause: QueryClause): void {
    const where = this.query.where
    const clauses = where.clauses
    this.query = {
      ...this.query,
      where: {
        ...where,
        clauses: clauses.flatMap((c) => c == clause ? [] : c)
      }
    }
    this.updateRoute()
  }

  @action.bound
  addClause(clause: QueryClause|null, update = true): void {
    const where = this.query.where
    const clauses = where.clauses
    const newClause: QueryClause = {
      column: {
        header: '',
        key: 'any',
        type: QueryColumnTypes.Any,
        filterable: false,
        sortable: false
      },
      operator: QueryOperators.Equal,
      value: null,
    }
    this.query = {
      ...this.query,
      where: {
        ...where,
        clauses: clauses.concat(clause ? clause: newClause)
      }
    }
    if (update) {
      this.updateRoute()
    }
  }

  @action.bound
  resetQuery(start?, end?, type?, update = true) {
    end = end || Date.now()
    start = start || (end - genericUtil.durations(1, 'hours'))

    const timeClause = {
      column: this.columns.find(f => f.key === this.partitionColumnKey),
      operator: QueryOperators.Between,
      value: [ start, end ],
      required: true
    }

    const clauses = [timeClause]
    if (this.family === QueryFamily.Observations) {
      clauses.push({
        column: this.columns.find(f => f.key === 'type'),
        operator: QueryOperators.Equal,
        value: type || 'dns',
        required: true
      })
    }

    this.query = {
      select: this.columns,
      from: this.family,
      where: {
        conjunction: QueryConjunction.And,
        clauses,
        required: true,
      },
      limit: SOFT_LIMIT,
      // these are weird because of the limitations we impose
      sort: this.family == QueryFamily.Netflows ? 'endTime' : 'startTime',
      direction: this.family == QueryFamily.Netflows ? 'desc' : 'asc'
    }
    if (update) {
      this.updateRoute()
    }
  }

  @action.bound
  resetResult(id = null) {
    this.result = {
      state: id ? QueryTaskState.EXISTING : QueryTaskState.NEW,
      statistics: {
        scannedBytes: 0,
        resultBytes: 0,
        execMillis: 0,
        rowCount: 0
      },
      rows: [],
      pageSize: 100,
      nextPage: null,
      id
    }
  }

  @action.bound
  init() {
    if (this.canLoadFromUrl()) {
      this.loadExisting()
    } else {
      this.resetQuery()
      this.resetResult()
    }
  }

  // you're allowed to query for up to a day with no limit
  @action.bound
  setLimit (limit: number) {
    this.query = {
      ...this.query,
      limit: limit
    }
    if (!this.timeLimitsAreValid()) {
      this.query = {
        ...this.query,
        limit: SOFT_LIMIT
      }
    }
    this.updateRoute()
  }

  @action.bound
  setSort(name, direction) {
    this.query = {
      ...this.query,
      sort: name,
      direction
    }
  }

  @action.bound
  submitQuery = flow(function* () {
    console.debug('action start')
    const _query = toJS(this.query)
    this.resetResult()
    this.result.statistics.submittedAt = new Date()
    this.result.state = QueryTaskState.SUBMITTING
    try {
      const submitResult: QueryTask = yield createQueryTask(_query)
      // this.result.id = submitResult.id
      this.setId(submitResult.id)
      this.updateHistory()
      this.result.state = QueryTaskState.RUNNING
      this.result.statistics = Object.assign(this.result.statistics, submitResult.statistics)
    } catch (error) {
      console.error('submitQuery error', error)
      this.result.state = QueryTaskState.FAILED
      this.result.error = error as Error
    }
    this.pollQuery(this.result.id)
  })

  @action.bound
  pollQuery = flow(function* (id: string) {
    console.debug('poll query start', id)
    // Poll for results until complete, failed, or timeout
    let count = 1;
    while (this.result.state == QueryTaskState.RUNNING || this.result.state == QueryTaskState.QUEUED) {
      const time = Math.min(200 * count, POLL_INTERVAL)
      console.debug('poll attempt', count, time)
      yield sleep(time)
      try {
        const update: QueryTask = yield getQueryTaskStatus(this.result.id)
        this.result.state = update.state
        this.result.statistics = { ...this.result.statistics, ...update.statistics }
      } catch (error) {
        console.error('pollQuery error', error)
        this.result.state = QueryTaskState.FAILED
        this.result.error = error as Error
      }
      count++
      if (count >= MAX_POLL_COUNT) {
        this.result.state = QueryTaskState.CANCELED
        this.result.error = new Error("Max polls reached. Query has timed out")
      }
    }

    if (this.result.state == QueryTaskState.SUCCEEDED) {
      console.debug('download results')
      this.result.rows = []
      this.result.nextPage = null
      this.getNextPage()
    }

    this.result.statistics.completedAt = new Date()
    console.debug('action complete')
  })

  @action.bound
  getNextPage = flow(function * () {
    console.debug('action get next page')
    this.result.state = QueryTaskState.DOWNLOADING
    let data: TaskResults
    try {
      if (this.family === 'netflows') {
        data = yield getQueryNetflowsResultsPage(this.result.id, this.result.pageSize, this.result.nextPage)
      } else {
        const type = this.query.where.clauses.find(c => c.column.key === 'type').value.toLowerCase()
        data = yield getQueryObservationsResultsPage(this.result.id, type, this.result.pageSize, this.result.nextPage)
      }

      this.result.rows = this.result.rows.concat(data.rows)
      this.result.nextPage = data.next || null
      this.result.state = QueryTaskState.COMPLETE
    } catch (error) {
      console.error('downloadResults Error', error)
      this.result.error = error
      this.result.state = QueryTaskState.FAILED
    }
  })

  @action.bound
  cancelQuery = flow(function * () {
    try {
      this.result.state = QueryTaskState.CANCELING
      yield cancelQuery(this.result.id)
      this.result.state = QueryTaskState.CANCELED
      this.resetQuery()
      this.resetResult()
    }
    catch (error) {
      console.error('submitQuery error', error)
      this.result.error = error as Error
    }
  })
}

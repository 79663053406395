import React from 'react'
import SensorConfigForm from 'components/sensor-config-form/SensorConfigForm'
import SensorInstallToken from 'components/SensorInstallToken'


function SensorConfig(props) {
  const sensor = props.data('sensor').valueOf()

  return sensor &&
    <div className="intel_card intel_data_card intel_data_sensor_config">
      <h3>Sensor Config</h3>
      <div className="intel_card_content">
        {!sensor.downloaded &&
          <SensorInstallToken
            sensorId={sensor.id}
            usageId="intelcard"
          />
        }

        <SensorConfigForm
          sensorId={'' + sensor.id}
          isVersion2={sensor.isVersion2}
        />
      </div>
    </div>
}

export default SensorConfig

import {requestGet} from 'utils/restUtils'
import StreamingClient from 'pw-streaming-client'
import {immersiveData} from 'pw-immersive-data'

const { protocol, hostname, port } = window.location
const wsProtocol = protocol === 'http:' ? 'ws' : 'wss'

const wsUrl = hostname === 'localdev.protectwise.com'
  ? 'ws://localhost:3000/xray'
  : `${wsProtocol}://${hostname}:${port}/xr/xray`

let currentClient = null

export function initImmersiveData () {
  if (!window._pw.enableImmersive) { return }
  if (currentClient) { return currentClient }
  currentClient = StreamingClient({
    url: async () => {
      await new Promise(r => setTimeout(r, 1000)) // let aether client connect first
      const {streamingEndpoint} = await requestGet(null, 'login')
      const endpointParts = streamingEndpoint.split("/")
      const token = endpointParts[endpointParts.length - 1]
      const url = `${wsUrl}/${token}`
      return url
    }
  })
  immersiveData.init(currentClient)

  return currentClient
}

export function destroyImmersiveData () {
  if (currentClient) { currentClient.destroy() }
  currentClient = null
}

import _ from 'lodash'
import Reflux from 'reflux'

import PreferencesActions from 'actions/PreferencesActions'
import {
  preferenceDefaults,
  preferenceOptions,
  availablePreferences,
  immuneToResetPrefKeys,
  THEME_OPTIONS
} from 'pwConstants'
import {requestGet, requestPost, requestPut} from 'utils/restUtils'




// Fallback translations for old letter-id theme options
const LEGACY_THEME_IDS = {
  a: 'default',
  b: 'contrast1',
  c: 'contrast2'
}

const LATEST_THEME_ID_LS_KEY = 'latestThemeId'
const validThemeIds = Object.keys(THEME_OPTIONS)

const getLatestThemeId = () => {
  const id = localStorage.getItem(LATEST_THEME_ID_LS_KEY) || 'default'
  return validThemeIds.includes(id) ? id : 'default'
}
const setLatestThemeId = themeId => {
  localStorage.setItem(LATEST_THEME_ID_LS_KEY, themeId)
}

const handleThemeIdChanged = themeId => {
  if (getLatestThemeId() !== themeId) {
    setLatestThemeId(themeId)
  }
}


const initialPreferenceDefaults = _.cloneDeep(preferenceDefaults)
// Default to latest stored themeID (so theme applies even when not logged-in)
initialPreferenceDefaults.theme = getLatestThemeId()


/**
 * Preferences state
 * Consumers should only use the `preferences` state object, which will contain
 * a flattened/merged list of user and customer preferences.
 */
const _state = {
  isReady: false,
  error: null,
  preferences: initialPreferenceDefaults, // Merged preferences that should be used by UI components
  _userPreferences: {}, // Private copy of raw user preferences
  _customerPreferences: {}, // Private copy of raw customer preferences
  preferenceOptions: _.cloneDeep(preferenceOptions),
  isLoading: false
}


const _doPrefsNeedReload = prefKeysChanged => {
  return _.some(prefKeysChanged, prefKey => {
    return (
      availablePreferences[prefKey] &&
      availablePreferences[prefKey].reloadOnChange === true
    )
  })
}

/*
A Reflux Store that contains the full set of persisted preferences for the current
user, and triggers its event when any of the preferences are modified. It handles
persisting the values when they are requested to be modified.

Currently this simply persists to localStorage, but it could use a remote API in
the future.
*/
const PreferencesStore = Reflux.createStore({
  listenables: PreferencesActions,

  init() {

  },

  getInitialState() {
    return _state
  },

  setInitialPreferences(preferences) {
    return this._receivePreferences(preferences)
  },

  _notify() {
    _state._updated = Date.now()
    this.trigger(_state)
    if (_state._reloadNextNotify) {
      window.location.reload()
    }
    _state._reloadNextNotify = false // Always resets after a single notify
  },

  /**
   * Commit preferences to server
   * API currently accepts a "user" preference group, and a "customer" group.
   * Putting to "customer" is restricted
   */
  _commit(partialPrefsObj, prefGroup = 'user') {
    if (!prefGroup || _.isEmpty(partialPrefsObj)) return
    _state._reloadNextNotify = false
    _state.isLoading = true
    this._notify()
    const _reloadOnSuccess = _doPrefsNeedReload(Object.keys(partialPrefsObj))
    const updatedPrefs = _.set({}, prefGroup, partialPrefsObj)
    this._receivePreferences(updatedPrefs) // Optimistically set the new preferences
    requestPut(null, 'preferences', updatedPrefs)
      .then(this._getAPISuccessHandler(_reloadOnSuccess))
      .catch(this._handleAPIFail)
  },

  _getAPISuccessHandler(reloadOnSuccess) {
    return updatedPreferences => {
      _state.isLoading = false
      _state.isReady = true
      _state._reloadNextNotify = reloadOnSuccess
      this._receivePreferences(updatedPreferences)
    }
  },

  _handleAPIFail(restError) {
    console.warn('Error saving preferences', restError)
    _state.isLoading = false
    this._notify()
  },


  /**
   * Get flattened/merged preferences from the user, customer, and default preference objects
   *
   * TODO if we need to customize how preferences are merged, that should be done here
   * e.g. If certain customer prefs cannot be overridden
   */
  _getMergedPreferences(userPrefs, custPrefs, defaultPrefs) {
    return _.defaults({}, userPrefs, custPrefs, defaultPrefs)
  },

  _receivePreferences(newPartialPrefs = {}) {
    // Apply the partial changeset to the user and customer preferences. Treat null values as explicit
    // removal from that level in the hierarchy so it falls back to the next level.
    const userPrefs = _.omit(
      _.assign({}, _state._userPreferences, newPartialPrefs.user),
      _.isNull
    )
    const custPrefs = _.omit(
      _.assign({}, _state._customerPreferences, newPartialPrefs.customer),
      _.isNull
    )

    // Merge the prefs hierarchy together with the defaults.
    let mergedPrefs = this._getMergedPreferences(
      userPrefs,
      custPrefs,
      preferenceDefaults
    )

    mergedPrefs = _.mapValues(mergedPrefs, (prefVal, prefKey) => {
      // Map old alpha-style themes to full IDs
      if (prefKey === 'theme') {
        const themeId = LEGACY_THEME_IDS[prefVal] || prefVal
        handleThemeIdChanged(themeId)
        return themeId
      }
      return prefVal
    })
    // Persist and notify
    _state._customerPreferences = custPrefs
    _state._userPreferences = userPrefs
    _state.preferences = mergedPrefs
    this._notify()
    return mergedPrefs
  },

  onSetSinglePreference(prefKey, newValue, prefGroup = 'user') {
    this.onSetPreferences({[prefKey]: newValue}, prefGroup)
  },

  onSetPreferences(modifiedPrefs, prefGroup = 'user') {
    this._commit(modifiedPrefs, prefGroup)
  },

  onSetViewedHint(hintKey) {
    const current = _state.preferences.viewedHints
    if (current.includes(hintKey)) return

    PreferencesActions.setSinglePreference('viewedHints', [...current, hintKey])
  },

  onReset(preferenceKey, prefGroup = 'user') {
    if (!preferenceKey || !prefGroup) {
      return
    }
    const out = {}
    if (_.isArray(preferenceKey)) {
      for (var i = 0; i < preferenceKey.length; i++) {
        out[preferenceKey[i]] = null
      }
    } else {
      out[preferenceKey] = null
    }
    this._commit(out, prefGroup)
  },

  onResetAll(prefGroup = 'user') {
    _state.isLoading = true
    const prev = _state[`_${prefGroup}Preferences`]
    const newState = _.pick(prev, immuneToResetPrefKeys) // Any prefs with `immuneToReset: true` will never be automatically reset by this mechanism

    _state[`_${prefGroup}Preferences`] = newState // reset group
    requestPost(null, 'preferences', _.set({}, prefGroup, newState)) // Replace prefs with empty object
      .then(this._getAPISuccessHandler(true))
      .catch(this._handleAPIFail)
  },

  onReload() {
    _state.isLoading = true
    this._notify()
    requestGet(null, 'preferences')
      .then(this._getAPISuccessHandler(false))
      .catch(() => {})
  },

  /**
   * Getters
   */
  isAnimationEnabled() {
    return _state.preferences.animationsEnabled !== 'disabled'
  },

  isUTC() {
    return _state.preferences.timeFormat === 'utc'
  },

  getCurrentTimeFormat() {
    return _state.preferences.timeDisplayFormat
  },

  isRelativeTimeEnabled() {
    return _state.preferences.relativeTime === 'enabled'
  },

  hasSeenHint (hintKey) {
    return _state.preferences.viewedHints.includes(hintKey)
  }
})

export default PreferencesStore

import protos from './AppProtos'
import { IpOperators, QueryColumn, QueryColumnTypes, IntOperators, StringOperators, BoolOperators, AppProtocolOperators } from "typings/query"

const stringToEnum = e => ({ text: e, value: e })

export const columns: Array<QueryColumn> = [
  { key: 'startTime', type: QueryColumnTypes.Datetime, header: "Start Time", filterable: false, sortable: true, resizable: true },
  { key: 'endTime', type: QueryColumnTypes.Datetime, header: "End Time", filterable: false, sortable: true, resizable: true },
  { key: 'srcIp', type: QueryColumnTypes.Ip, header: "Src IP", filterable: true, sortable: false, operators: IpOperators.operators, resizable: true },
  { key: 'dstIp', type: QueryColumnTypes.Ip, header: "Dst IP", filterable: true, sortable: false, operators: IpOperators.operators, resizable: true },
  { key: 'srcPort', type: QueryColumnTypes.Int, header: "Src Port", filterable: true, sortable: true, operators: IntOperators.operators, resizable: true },
  { key: 'dstPort', type: QueryColumnTypes.Int, header: "Dst Port", filterable: true, sortable: true, operators: IntOperators.operators, resizable: true },
  { key: 'srcMac', type: QueryColumnTypes.String, header: "Src MAC", filterable: true, sortable: false, operators: StringOperators.operators, resizable: true },
  { key: 'dstMac', type: QueryColumnTypes.String, header: "Dst MAC", filterable: true, sortable: false, operators: StringOperators.operators, resizable: true },
  { key: 'applicationProtocolNames',
    type: QueryColumnTypes.Enum,
    // subtype: QueryColumnTypes.Enum,
    header: "Applications",
    filterable: true,
    sortable: false,
    operators: AppProtocolOperators.operators,
    resizable: true,
    enums: protos.map(p => ({ text: p.name, value: p.name}))
  },
  { key: 'proto',
    type: QueryColumnTypes.Enum,
    header: "Protocol",
    filterable: true,
    sortable: false,
    operators: StringOperators.operators,
    enums: ['TCP', 'UDP', 'ICMP'].map(stringToEnum),
    resizable: true
  },
  { key: 'directionName',
    type: QueryColumnTypes.Enum,
    header: "Direction",
    filterable: true,
    sortable: false,
    operators: StringOperators.operators,
    enums: ['InternalInternal', 'InternalExternal', 'ExternalInternal', 'ExternalExternal'].map(stringToEnum),
    resizable: true
  },
  { key: 'srcDeviceId', type: QueryColumnTypes.String, header: "Src Device ID", filterable: true, sortable: false, operators: StringOperators.operators, resizable: true },
  { key: 'dstDeviceId', type: QueryColumnTypes.String, header: "Dst Device ID", filterable: true, sortable: false, operators: StringOperators.operators, resizable: true },
  { key: 'packetsSrcObserved', type: QueryColumnTypes.Int, header: "Src Packets Observed", filterable: true, sortable: true, operators: IntOperators.operators, resizable: true },
  { key: 'packetsDstObserved', type: QueryColumnTypes.Int, header: "Dst Packets Observed", filterable: true,  sortable: true, operators: IntOperators.operators, resizable: true },
  { key: 'packetsSrcCaptured', type: QueryColumnTypes.Int, header: "Src Packets Captured", filterable: true, sortable: true, operators: IntOperators.operators, resizable: true },
  { key: 'packetsDstCaptured', type: QueryColumnTypes.Int, header: "Dst Packets Captured", filterable: true, sortable: true, operators: IntOperators.operators, resizable: true },
  { key: 'bytesSrcObserved', type: QueryColumnTypes.Int, header: "Src Bytes Observed", filterable: true, sortable: true, operators: IntOperators.operators, resizable: true },
  { key: 'bytesDstObserved', type: QueryColumnTypes.Int, header: "Dst Bytes Observed", filterable: true, sortable: true, operators: IntOperators.operators, resizable: true },
  { key: 'bytesSrcCaptured', type: QueryColumnTypes.Int, header: "Src Bytes Captured", filterable: true, sortable: true, operators: IntOperators.operators, resizable: true },
  { key: 'bytesDstCaptured', type: QueryColumnTypes.Int, header: "Dst Bytes Captured", filterable: true, sortable: true, operators: IntOperators.operators, resizable: true },
  { key: 'isEncrypted', type: QueryColumnTypes.Bool, header: "Encrypted", filterable: true, sortable: false, operators: BoolOperators.operators, resizable: true },
  { key: 'srcGeoCityId', type: QueryColumnTypes.Int, header: 'Src City', filterable: true, sortable: false, operators: IntOperators.operators, resizable: true },
  { key: 'dstGeoCityId', type: QueryColumnTypes.Int, header: 'Dst City', filterable: true, sortable: false, operators: IntOperators.operators, resizable: true },
  { key: 'srcGeoCountryCode', type: QueryColumnTypes.String, header: 'Src Country', filterable: true, sortable: false, operators: StringOperators.operators, resizable: true },
  { key: 'dstGeoCountryCode', type: QueryColumnTypes.String, header: 'Dst Country', filterable: true, sortable: false, operators: StringOperators.operators, resizable: true },
  {
    key: 'sensorId',
    type: QueryColumnTypes.Enum,
    header: "Sensor ID",
    filterable: true,
    sortable: false,
    operators: IntOperators.operators,
    enums: [], // filled in by the component via store
    resizable: true
  },
  { key: 'flowId', type: QueryColumnTypes.String, header: "Flow ID", filterable: true, sortable: false, operators: StringOperators.operators, resizable: true },
]

import React from 'react'
import {Canvas3D, Object3DFacade, OrthographicCamera3DFacade, ListFacade, Group3DFacade} from 'troika-3d'
import {Mesh, ShaderMaterial, Color, DoubleSide} from 'three'
import {Line2D, solidLineShader} from 'utils/threejs/THREE.Line2D.js'
import T from 'prop-types'
import constants from 'pwConstants'


const getPointsOnPath = function() {
  let ct = document.createElement('div')
  ct.innerHTML = '<svg><path /></svg>'
  let pathEl = ct.querySelector('path')

  return function(path, numPoints) {
    pathEl.setAttribute('d', path)
    let totalLength = pathEl.getTotalLength()
    let coords = []
    for (let i = 0; i < numPoints; i++) {
      let {x, y} = pathEl.getPointAtLength(totalLength * i / (numPoints - 1))
      coords.push([x, y])
    }
    return coords
  }
}()


function createArcMaterial() {
  return new ShaderMaterial({
    uniforms: {
      animationProgress: {value: 0},
      thickness: {value: 1},
      opacity: {value: 1},
      diffuse: {value: new Color()}
    },

    transparent: true,
    side: DoubleSide,

    vertexShader: `
      uniform float animationProgress;
      uniform float thickness;
      attribute float lineDistance;
      attribute float lineMiter;
      attribute vec2 lineNormal;
      varying float opacity;
      
      float leadingGrowDist = 0.025;
      float trailingGrowDist = 0.2;
      float growMult = 3.0; 
      float minOpacity = 0.25;
      float maxOpacity = 0.8;
      
      void main() {
        // Determine local thickness based on animation progress
        float apex = -leadingGrowDist + (animationProgress * (1.0 + trailingGrowDist + leadingGrowDist));
        float distFromApex = lineDistance - apex;
        float growPct = distFromApex > 0.0 ?
          smoothstep(leadingGrowDist, 0.0, distFromApex) :
          smoothstep(trailingGrowDist, 0.0, -distFromApex);
        float localThickness = thickness + (thickness * growMult * growPct);
        
        // Set local opacity for fragment shader
        opacity = minOpacity + (maxOpacity - minOpacity) * growPct;
        
        vec3 pointPos = position.xyz + vec3(lineNormal * localThickness / 2.0 * lineMiter, 0.0);
        gl_Position = projectionMatrix * modelViewMatrix * vec4(pointPos, 1.0);
      }
    `,

    fragmentShader: `
      uniform vec3 diffuse;
      varying float opacity;
      void main() {
        gl_FragColor = vec4(diffuse, opacity);
      }
    `
  })
}


class ArcFacade extends Object3DFacade {
  constructor(parent) {
    let mesh = new Mesh(
      new Line2D({
        distances: true
      }),
      createArcMaterial()
    )
    super(parent, mesh)
  }

  afterUpdate() {
    let uniforms = this.threeObject.material.uniforms

    uniforms.thickness.value = this.thickness

    if (this.color !== this._lastColor) {
      this._lastColor = this.color
      uniforms.diffuse.value.set(this.color)
    }

    uniforms.animationProgress.value = this.animationProgress

    if (this.path !== this._lastPath) {
      // translate path string to Line2D geometry
      let coords = getPointsOnPath(this.path, 32)
      this.threeObject.geometry.update(coords)
      this._lastPath = this.path
    }

    super.afterUpdate()
  }

  destructor() {
    this.threeObject.geometry.dispose()
    super.destructor()
  }
}





export default class extends React.Component {
  static displayName = 'Arcs_Troika';

  static propTypes = {
    arcs: T.arrayOf(T.shape({
      id: T.string,
      threatLevel: T.string,
      d: T.string
    })),
    arcAnimationDuration: T.number,
    arcAnimationGap: T.number,
    translateX: T.number,
    translateY: T.number,
    scale: T.number
  };

  state = {
    width: 0,
    height: 0
  };

  onDomRef = (ref) => {
    this._dom = ref
  };

  componentDidUpdate() {
    let {width, height} = this._dom.getBoundingClientRect()
    let state = this.state
    if (width !== state.width || height !== state.height) {
      this.setState({width, height})
    }
  }

  render() {
    let {props, state} = this
    let arcDuration = props.arcAnimationDuration

    return (
      <div className="arcs_troika" ref={ this.onDomRef }>
        { state.width && state.height ? (
          <Canvas3D
            antialias
            width={ state.width }
            height={ state.height }
            camera={ {
              facade: OrthographicCamera3DFacade,
              x: 0, y: 0, z: 1, lookAt: {x: 0, y: 0, z: 0},
              left: 0,
              right: state.width,
              top: 0,
              bottom: state.height
            } }
            objects={ {
              key: 'main',
              facade: Group3DFacade,
              x: props.translateX,
              y: props.translateY,
              scaleX: props.scale,
              scaleY: props.scale,
              children: {
                key: 'arcs',
                facade: ListFacade,
                data: props.arcs,
                template: {
                  key: d => d.id,
                  facade: Group3DFacade,
                  children: (arc, i, allArcs) => {
                    let totalDuration = (allArcs.length + 2) * (arcDuration /*+ props.arcAnimationGap*/)
                    let subPaths = arc.d.replace(/^M/, '').split('M')
                    return subPaths.map((path, j) => ({
                      key: j + '',
                      facade: ArcFacade,
                      path: 'M' + path,
                      color: constants.threatLevelColors[arc.threatLevel || 'none'],
                      thickness: 1 / props.scale,
                      animation: {
                        0: {animationProgress: 0},
                        [arcDuration / subPaths.length / totalDuration * 100]: {animationProgress: 1},
                        delay: i * arcDuration + j * arcDuration / subPaths.length,
                        iterations: Infinity,
                        duration: totalDuration
                      }
                    }))
                  }
                }
              }
            } }
          />
        ) : null }
      </div>
    )
  }
}

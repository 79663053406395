import React, { useRef, useEffect } from 'react'
import T from 'prop-types'
import { observer } from 'mobx-react'
import { useStore } from 'stores/mobx/StoreManager'
import DownloadStore from 'stores/mobx/viewStores/download-manager/DownloadStore'

export const DownloadIcon = observer(() => {
  const downloadStore = useStore(DownloadStore)
  const instanceRef = useRef(null)
  useEffect(() => {
    downloadStore.loadExisting()
    return () => {}
  }, [instanceRef])

  return (
    <div className="download_icon">
      <div className={`download_count ${downloadStore.downloads.size > 0 ? 'has_count' : ''}`}>
        <span className={`icon icon-download`} />
        {downloadStore.remainderCount > 0 &&
          <div
            className={`dl_ct ct_remainder`}
          >
            <span>{downloadStore.remainderCount}</span>
          </div>
        }
        {downloadStore.errorCount > 0 &&
          <div
            className={`dl_ct ct_errors`}
            data-tooltip={`${downloadStore.errorCount} Failed Download${downloadStore.errorCount === 1 ? '' : 's'}`}
          >
            <span>{downloadStore.errorCount}</span>
          </div>
        }
        {downloadStore.pendingCount > 0 &&
          <div
            className={`dl_ct ct_pending`}
            data-tooltip={`${downloadStore.pendingCount} Pending Download${downloadStore.pendingCount === 1 ? '' : 's'}`}
          >
            <span>{downloadStore.pendingCount}</span>
          </div>
        }
      </div>
      <div className={`download_notice pw_tooltip pw_tooltip_simple ${downloadStore.isTooltipVisible === null ? 'is_hidden' : downloadStore.isTooltipVisible ? 'fade_in_down' : 'fade_out_up'}`}>
        Download Started
      </div>
    </div>
  )
})

DownloadIcon.propTypes = {}

DownloadIcon.displayName = "DownloadsIcon"

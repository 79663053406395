import React from 'react'
import ErroredValue from 'components/intelcard/data/ErroredValue'
import {LoadingValue, TextOverflow} from 'react-base'
import constants from 'pwConstants'


function getSortedCategories(cats, subcats) {
  const allCats = _(cats)
    .keys()
    .sortBy(key => -cats[key])
    .map(key => key.toLowerCase())
    .map(key => constants.threatCategoriesToNames[key] || key)
    .concat(_(subcats).keys().sortBy(key => -subcats[key]).value())
    .value()
  if (!allCats[0]) allCats[0] = 'Unknown'
  return allCats
}


export default ({data}) => {
  const categories = data('threat.events.threatCategory')
  const subCategories = data('threat.events.threatSubCategory')

  return <h5>
    <TextOverflow>
      Category:{' '}
      {categories.isLoading() || subCategories.isLoading()
        ? <LoadingValue />
        : categories.isError() || subCategories.isError()
          ? <ErroredValue />
          : getSortedCategories(
            categories.valueOf(),
            subCategories.valueOf()
          ).join(', ')}
    </TextOverflow>
  </h5>
}

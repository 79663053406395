import _ from 'lodash'
import T from 'prop-types'
import React from 'react'
import SensorActions from 'actions/SensorActions'
// import StoreProvider from 'components/StoreProvider'
import SensorStore from 'stores/SensorStore'
import {AnimateHeight, StoreProvider} from 'react-base'
import InstallToken from 'components/InstallToken'
import getConfig from 'utils/uiConfig'
import ExternalLink from 'components/ExternalLink'
import { hasPermission } from 'stores/UserStore'
import AnalyticsActions from 'actions/AnalyticsActions'

class SensorInstallToken extends React.Component {
  static displayName = "SensorInstallToken";

  static propTypes = {
    sensorId: T.number.isRequired,
    sensors: T.array,
    usageId: T.string
  };

  _onRequestToken = () => {
    SensorActions.generateInstallToken(this.props.sensorId)
    AnalyticsActions.event({
      eventCategory: 'sensor',
      eventAction: 'generate_token',
      eventLabel: `from_${this.props.usageId || 'unknown'}`
    })
  };

  render() {
    let {props} = this
    let installToken = null
    let canGenerateToken = hasPermission('sensors:update') // NOTE this should be allowed for regular users (not just support accounts)
    let sensor = _.find(props.sensors, {id: props.sensorId})
    const uiConfig = getConfig()
    if (sensor) {
      installToken = sensor.installToken
    }

    return <div className="sensor_install_token_wrap">
      <div className="title">This sensor has not yet been installed.</div>
      {canGenerateToken &&
        <AnimateHeight>
          <InstallToken
            onRequestToken={ this._onRequestToken }
            token={ installToken }
          />
          {installToken &&
            <div>
              <p>Copy the install token above and use it when installing the Sensor package. The token is valid for one week.</p>
              <p>See the <ExternalLink href={ uiConfig.urls.sensorInstallationGuide }>Sensor Deployment Guide</ExternalLink> for more details.</p>
            </div>
          }
        </AnimateHeight>
      }
    </div>
  }
}

export default (props) => (
  <StoreProvider
    mapping={ 'sensors' }
    store={ SensorStore }
  >
    <SensorInstallToken {...props} />
  </StoreProvider>
)

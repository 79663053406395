import _ from 'lodash'
import querystringUtil from 'ui-base/src/util/querystringUtil'
import CommonViewActions from 'actions/CommonViewActions'
import UserStore from 'stores/UserStore'
import AuthStore from 'stores/AuthStore'
import {SENSOR_RESTRICTED_ROLENAME} from 'constants/sensors'

// Object format that will be passed to all RouteAware components:
class RouteObject {
  constructor(hash, historyIndex) {
    let [path, queryString] = hash.split('?')
    let pathArr = path.split('/')
    this.fullHash = hash
    this.fullPath = path
    this.fullQuery = queryString
    this.basePath = pathArr[0]
    this.pathArr = pathArr
    this.historyIndex = historyIndex

    if (queryString) {
      const qp = this.queryParams = querystringUtil.parse(queryString)
      // Pre-separate the overlay-related params so they don't interfere with other view params:
      if (qp) {
        this.intelCardParam = qp.intelcard
        delete qp.intelcard
        this.logViewerParam = qp.logviewer
        delete qp.logviewer
        this.cards = qp.cards
        delete qp.cards
      }
    }
  }
}

// These paths shouldn't attempt to check authentication
const SKIP_AUTH_PATHS = {
  "login": true,
  "signup": true,
  "complete_registration": true,
  "password_reset": true
}

const NDR_SUPPORT_AND_MSSP_PATHS = {
  'customer-admin': 1
}

class PwUiRouter {
  constructor() {
    this._subscribers = []
    this._lastHistoryIndex = -1
    AuthStore.listen(authStoreState => {
      if (authStoreState.needsRedirect) {
        this._onUserAuthentication() // Handle redirect flag
      }
    })
    window.addEventListener('hashchange', this._onHashChange.bind(this), false)
    this._onHashChange()
  }

  _onUserAuthentication() {
    // Only called on initial login, not reloads for already authorized users
    let defaultRoute = UserStore.getDefaultRoute() || 'hud'
    this.navigate(this._originalRoute || defaultRoute)
    delete this._originalRoute
  }

  /**
   * Determine whether the given route should be redirected to another. Add logic here for
   * any pages that are moved/renamed to avoid breaking links.
   * @param routeObj
   * @return {String|null} If a redirect should occur, returns the new URL hash; otherwise returns null
   * @private
   */
  _getRedirectPath(routeObj) {
    const {fullPath, basePath, pathArr} = routeObj
    // Default route when no hash present:
    if (!fullPath) {
      return 'hud'
    }
    if (basePath === 'sitrep') {
      return 'reporting/dashboard'
    }
    if (fullPath === 'settings/sensors') {
      return 'sensors/dashboard'
    }
    if (fullPath === 'settings/locations') {
      return 'sensors/locations'
    }
    if (basePath === 'profiler') {
      return `sensors/capture-profiles/${pathArr.slice(1).join('/')}`
    }
    return null
  }

  _onHashChange() {
    // Ensure every history item tracks its index in the history stack
    let historyState = _.clone(window.history.state || {})
    let historyIndex = historyState.historyIndex
    if (!_.isNumber(historyIndex)) {
      historyIndex = this._lastHistoryIndex + 1
      historyState.historyIndex = historyIndex
      window.history.replaceState(historyState, null)
    }
    this._lastHistoryIndex = historyIndex
    //console.log(`--> History Index: ${historyIndex}`)

    let hash = location.hash.substring(1)
    let routeObj = new RouteObject(hash, historyIndex)

    // Handle redirects
    const redirectTo = this._getRedirectPath(routeObj)
    if (redirectTo) {
      this._wasPathRedirect = true // Don't store this as an _originalRoute
      this.navigate(redirectTo)
      return
    }

    // Some paths we don't need to authenticate
    if (SKIP_AUTH_PATHS[routeObj.fullPath]) {
      this._updateRoute(routeObj)
      return
    }

    if (NDR_SUPPORT_AND_MSSP_PATHS[routeObj.basePath]) {
      AuthStore.checkAuthenticated().then(isAuthenticated => {
        if (isAuthenticated && (AuthStore.canAccessNDRSupportRoutes() || AuthStore.canAccessMSSPRoutes())) {
          this._updateRoute(routeObj)
        } else {
          this.navigate("login") // Redirect to login
        }
      })
      return
    }

    // Check authentication for everything else
    AuthStore.checkAuthenticated().then(isAuthenticated => {
      if (isAuthenticated && !AuthStore.getSupportCustomerList()) {
        if (routeObj.basePath === 'hud') {
          // no hud for sensor_restricted roleswew!
          const userInfo = UserStore.getCurrentUserInfo()
          const hasSensorGroupRole = userInfo.roles.some(role => !!role.sensor_group_ids.length)
          if (hasSensorGroupRole) {
            this.navigate("killbox")
          }
        }
        this._updateRoute(routeObj)
      } else {
        // remember path for redirect after successful login
        if (!this._originalRoute && !this._wasPathRedirect) {
          this._originalRoute = routeObj.fullHash
        }
        this._wasPathRedirect = false
        this.navigate("login")
      }
    })
  }

  _updateRoute(routeObj) {
    this._updatePageTitle(routeObj.basePath)
    this._currentRouteObject = routeObj
    this._subscribers.forEach(fn => fn(routeObj))
  }

  _updatePageTitle(rootPage) {
    let rootPageFormatted
    switch (rootPage) {
      case 'hud':
        rootPageFormatted = 'HUD'
        break
      case 'reporting':
        rootPageFormatted = 'Reporting'
        break
      default:
        rootPageFormatted = _.startCase(rootPage)
    }
    CommonViewActions.General.setPageTitle(rootPageFormatted)
  }

  getCurrentRoute() {
    return this._currentRouteObject
  }

  navigate(path) {
    location.hash = '#' + path
  }

  subscribe(fn) {
    // Add to subscribers list
    let subs = this._subscribers
    if (!_.contains(subs, fn)) {
      subs.push(fn)
    }

    // Invoke the handler if we already have a route
    let route = this._currentRouteObject
    if (route) fn(route)

    // Return the unsubscriber
    return () => this.unsubscribe(fn)
  }

  unsubscribe(fn) {
    _.pull(this._subscribers, fn)
  }
}

export default PwUiRouter

// Event utilities

import _ from 'lodash'
import { lowerCase } from 'pw-formatters'
import constants from 'pwConstants'
import observationUtils from './observationUtils'
import netflowUtils from './netflowUtils'
import { formatDate } from 'utils/timeUtils'
import { mapSearchEnumV2ToV1 } from 'utils/searchUtils'

function createdAtComparator(a, b) {
  return +a.createdAt - +b.createdAt
}

const _formatEvent = evt => {
  const killchainStageFormatted = lowerCase(evt.killChainStage)
  let kcStages = evt.killChainStages
  if (!kcStages) { //for non v2, try building kc stages from observations if we have them
    if (evt.observations) {
      kcStages = _.uniq(evt.observations.map(obs => obs.killChainStage))
    } else {
      kcStages = [evt.killChainStage]
    }
  }
  const categoryFormatted = lowerCase(evt.category)
  let _startedAtActual = evt.startedAt // Fallback to provided fields
  let _endedAtActual = evt.endedAt // Fallback to provided fields

  if (evt.netflows && evt.netflows.length > 0) {
    const minNetflowStartTime = _.min(
      evt.netflows.map(flow => flow.details.startTime)
    )
    if (minNetflowStartTime && minNetflowStartTime > 0) {
      _startedAtActual = minNetflowStartTime
    }
    const maxNetflowEndTime = _.max(
      evt.netflows.map(flow => flow.details.endTime)
    )
    if (maxNetflowEndTime && maxNetflowEndTime > 0) {
      _endedAtActual = maxNetflowEndTime
    }
  }

  const _startedAtFormatted = formatDate(evt.startedAt)

  if (Array.isArray(evt.notes)) {
    evt.notes.sort(createdAtComparator)
  }

  return _.assign(evt, {
    formattedTitle:
      evt.message ||
      `${constants.killchainStagesToNames[killchainStageFormatted]} Event`,
    threatLevelFormatted: lowerCase(evt.threatLevel),
    killchainStageFormatted: killchainStageFormatted,
    killchainStageFormattedHuman:
      constants.killchainStagesToNames[killchainStageFormatted],
    killChainStages: kcStages,
    killChainStagesFormatted: kcStages.map(lowerCase),
    killChainStagesFormattedHuman: kcStages.map(kc => constants.killchainStagesToNames[lowerCase(kc)]),
    threatCategoryFormatted: categoryFormatted,
    threatCategoryFormattedHuman:
      constants.threatCategoriesToNames[categoryFormatted],
    observedAtFormatted: formatDate(evt.observedAt),
    startedAtFormatted: _startedAtFormatted,
    startedAtActual: _startedAtActual,
    startedAtActualFormatted: formatDate(_startedAtActual),
    endedAtFormatted: formatDate(evt.endedAt),
    endedAtActual: _endedAtActual,
    endedAtActualFormatted: formatDate(_endedAtActual),
    typeFormatted:
      constants.eventTypesToNames[evt.type] || _.startCase(evt.type), // Fallback splits camel case
    subCategoryFormatted:
      evt.threatSubCategory === 'None'
        ? null
        : _.startCase(evt.threatSubCategory),
    occurredAt: evt.startedAt, // NOTE: for counter purposes, an event's `startedAt` property is analogous to an observation's `occurredAt` time
    occurredAtFormatted: _startedAtFormatted // NOTE: for counter purposes, an event's `startedAt` property is analogous to an observation's `occurredAt` time

    // priority: _.sample([true, false]),
    // assignee: _.sample([3346, 3335, 3347, 1512]),
    // state: _.sample(['open', 'resolved']),
    // resolvedReason: _.sample(Object.keys(constants.resolvedReasons))
  })
}

const resolveDownloadableNetflows = (observations) => {
  if (!observations) { return [] }
  const flows = {}
  observations.forEach((observation) => {
    if (observation.netflow) { // just use the full netflow object when we have it
      flows[observation.netflow.key] = observation.netflow
      return
    }
    const flowId = _.get(observation, 'associatedId.flowId.key')
    if (!flowId) { return } // currently only observations with a flowId are downloadable. This could change in the future
    const existingFlow = flows[flowId]
    if (!existingFlow) {
      flows[flowId] = observationUtils.createMissingNetflow(observation)
      observation.netflow = flows[flowId]
      return
    }
    observation.netflow = existingFlow
    if (!existingFlow.missingRecord) { return } // we somehow already have the full netflow record from another observation (not sure if this can ever happen)
    observationUtils.updateMissingNetflow(existingFlow, observation)
  })
  return Object.values(flows)
}

// export functions
export default {
  formatList(rawEvents) {
    return _.each(rawEvents, _.partial(_formatEvent))
  },

  formatSingle(rawEvent) {
    const downloadableNetflows = resolveDownloadableNetflows(rawEvent.observations)
    const formatted = _.assign(_formatEvent(rawEvent), {
      formattedObservations: _.map(
        rawEvent.observations,
        observationUtils.formatSingle
      ),
      formattedNetflows: _.map(rawEvent.netflows, netflowUtils.formatSingle),
      downloadableNetflows
    })
    return formatted
  }
}

export function getKillboxEventUrl(event) {
  return `#killbox/events?id=${event.id}&start=${event.startedAt}&end=${event.endedAt}&timeMode=occurredAt`
}



// Map of returned V2 key formats to old/expected v1 data[key] formats
const EVENT_V2_KEYS_TO_V1_KEYS = {
  threatCategory: 'category',
  customerId: 'cid'
}

export function convertEventsV2toV1(events=[]) {
  const translateKcStage = val => val === 'DataTheft' ? 'Data_theft' : val

  return events.map(v2Event => _.reduce(
    v2Event,
    (out, val, key) => {
      key = EVENT_V2_KEYS_TO_V1_KEYS[key] || key
      switch (key) {
        // translate killchain enum
        case 'killChainStage':
          out[key] = translateKcStage(val)
          break
        case 'killChainStages':
          out[key] = val.map(translateKcStage)
          break
        case 'category':
          out[key] = mapSearchEnumV2ToV1('events', 'threatCategory', val)
          break
        case 'workflow': //substruct to flat properties
          if (val) {
            out.assignee = val.assignedTo ? _.assign({id: val.assignedTo}, val.assignee) : null
            out.state = val.status ? mapSearchEnumV2ToV1('events', 'workflow.status', val.status) : null
            out.resolvedReason = val.resolution ? mapSearchEnumV2ToV1('events', 'workflow.resolution', val.resolution) : null
            out.priority = val.priority === 'High' //enum to bool, ignoring Normal and Low values
          }
          break
        default:
          out[key] = val
      }
      return out
    },
    {
      agentId: _.first(v2Event.sensorIds)
    }
  ))
}

import _ from 'lodash'
import T from 'prop-types'
import React from 'react'
import moment from 'moment'
import {
  MenuItem,
  TextOverflow,
  StoreProvider,
} from 'react-base'
import {
  pluralize
} from 'pw-formatters'
import TimingStore from 'stores/TimingStore'
import UserActions from 'actions/UserActions'

const padZero = num => (num < 10 ? `0${num}` : num)

const UserWidget = ({
  userInfo,
  loginDuration,
  emulatingCustomer,
  needsTrialBanner,
  trialDaysRemaining,
  trialNeedsWarning
}) => {
  const loginTime = moment.duration(loginDuration)
  if (!userInfo) {
    return null
  }

  return (
    <div className="meta_menu">
      <h3>Hi {userInfo.firstname}!</h3>
      {needsTrialBanner &&
        <div
          className={`trial_reminder ${trialNeedsWarning ? 'warn' : ''}`}
        >
          <span className={`icon icon-${trialNeedsWarning ? 'warning' : 'info'}`} /> There {trialDaysRemaining === 1 ? 'is' : 'are'} <strong>{trialDaysRemaining} {pluralize(trialDaysRemaining, 'day')}</strong> remaining in your trial
        </div>
      }
      {emulatingCustomer &&
        <h4>Support</h4>
      }
      {emulatingCustomer &&
        <ul>
          <MenuItem onClick={UserActions.unsetEmulatedCustomer}>
            <span className="icon icon-key" /> Change Customer
          </MenuItem>
          <MenuItem onClick={() => UserActions.unsetEmulatedCustomer(`#customer-admin/customers`)}>
            <span className="icon icon-users" /> Customer Administration
          </MenuItem>
          <MenuItem href={`/#customer-admin/notifications`}>
            <span className="icon icon-chat_message" /> Manage Notifications
          </MenuItem>
        </ul>
      }
      <h4>Quick Links</h4>
      <ul>
        <MenuItem href="#settings/account">
          <span className="icon icon-lock" /> Security Settings
        </MenuItem>
        <MenuItem href="#settings/account">
          <span className="icon icon-cog" /> Your Preferences
        </MenuItem>
        <MenuItem onClick={UserActions.logout}>
          <span className="icon icon-exit" /> Log Out
        </MenuItem>
      </ul>
      <h4>Information</h4>
      <div className="meta_info">
        <div className="user_role">Role: { userInfo.roles[0].name || '' }</div>

        <div className="session_time">
          Logged in for{' '}
          {loginTime
            ? `${padZero(loginTime.hours())}:${padZero(loginTime.minutes())}:${padZero(loginTime.seconds())}`
            : '--:--:--'}
        </div>

        {emulatingCustomer &&
          <TextOverflow className="emulated_customer">
            as {userInfo.customer.name} (CID: {userInfo.customer.id})
          </TextOverflow>
        }
      </div>
    </div>
  )
}

UserWidget.propTypes = {
  userInfo: T.object,
  emulatingCustomer: T.bool,
  loginDuration: T.number,
  needsTrialBanner: T.bool,
  trialDaysRemaining: T.number,
  trialNeedsWarning: T.bool
}


export default function(props) {
  return (
    <StoreProvider store={TimingStore} mapping="loginDuration">
      <UserWidget {...props} />
    </StoreProvider>
  )
}

import React from 'react'
import {
  AnimateHeight,
  TextOverflow,
  LoadingValue
} from 'react-base'
import T from 'prop-types'
import IpValue from 'components/values/IpValue'
import SensorStore from 'stores/SensorStore'
import ErroredValue from './data/ErroredValue'
import DeviceIdenticon from 'components/DeviceIdenticon'
import { getMostRelevantHostname } from 'utils/deviceUtils'

import IpHeader from './headers/IpHeader'
import DeviceHeader from './headers/DeviceHeader'
import DomainHeader from './headers/DomainHeader'
import CertificateHeader from './headers/CertificateHeader'
import FileHeader from './headers/FileHeader'
import SensorHeader from './headers/SensorHeader'


const HEADER_COMPONENTS_BY_TYPE = {
  ip: IpHeader,
  device: DeviceHeader,
  domain: DomainHeader,
  certificate: CertificateHeader,
  file: FileHeader,
  sensor: SensorHeader
}


class Header extends React.Component {
  static displayName = 'Header'

  static propTypes = {
    queryType: T.string,
    queryValue: T.string,
    data: T.func
  }

  render() {
    const props = this.props
    const { queryType, queryValue, data } = props
    const HeaderComponent = HEADER_COMPONENTS_BY_TYPE[queryType]

    return <AnimateHeight className="intel_card_header">
      {queryType && queryValue && data && HeaderComponent
        ? <HeaderComponent {...props} />
        : <h4>Nothing Selected</h4>
      }
    </AnimateHeight>
  }
}

export default Header

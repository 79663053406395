import _ from 'lodash'
import T from 'prop-types'
import React from 'react'
import ReactDOM from 'react-dom'
import {TextOverflow} from 'react-base'
import querystringUtil from 'ui-base/src/util/querystringUtil'
import RelativeTime from 'components/RelativeTime'

// import IpValue from 'components/IpValue'
// import UrlValue from 'components/UrlValue'

class ObservationListItem extends React.Component {
  static displayName = "ObservationListItem"

  static propTypes = {
    className: T.string,
    commonParams: T.object,
    'data-index': T.number,
    obs: T.object.isRequired,
    onItemClicked: T.func,
    onItemMouseEnter: T.func,
    onItemMouseLeave: T.func,
    timeProp: T.oneOf(['observedAt', 'occurredAt'])
  }

  static defaultProps = {
    obs: {},
    commonParams: {},
    className: '',
    timeProp: 'observedAt'
  }

  componentDidUpdate(prevProps) {
    if (this._isActive() && this.props.commonParams.id !== prevProps.commonParams.id) {
      this.focus()
    }
  }

  // shouldComponentUpdate(nextProps) {
  //   return this.props.obs.id !== nextProps.obs.id || !_.isEqual(this.props.commonParams, nextProps.commonParams) || !_.isEqual(this.props.obs.tags, nextProps.obs.tags)
  // },

  _getUrl = () => {
    let fullparams = _.assign(_.clone(this.props.commonParams), {
      id: this.props.obs.id,
      sid: this.props.obs.agentId
    })
    return `/#killbox/observations?${ querystringUtil.stringify(fullparams) }`
  }

  _isActive = () => {
    return this.props.commonParams.id === this.props.obs.id
  }

  _onObservationClick = (e) => {
    this.setState({isActive: true})
    if (this.props.onItemClicked) {
      e.preventDefault()
      this.props.onItemClicked(this.props.obs, e)
    }
  }

  _onMouseEnter = (e) => {
    if (this.props.onItemMouseEnter) {
      this.props.onItemMouseEnter(this.props.obs, e)
    }
  }

  _onMouseLeave = (e) => {
    if (this.props.onItemMouseLeave) {
      this.props.onItemMouseLeave(this.props.obs, e)
    }
  }

  focus = () => {
    let link = this.refs.link
    if (link) {
      ReactDOM.findDOMNode(link).focus()
    }
  }

  render() {
    let obs = this.props.obs
    return (
      <li className={ `observation_item glow_borders_h ${ (this._isActive() ? 'active' : '') } ${ this.props.className }` }
        data-index={ this.props['data-index'] }
        onMouseEnter={ this._onMouseEnter }
        onMouseLeave={ this._onMouseLeave }
      >
        <a
          className="event_content"
          draggable={ false }
          href={ this._getUrl() }
          onClick={ this._onObservationClick }
          ref="link"
        >
          <span className={ `threat_level ${ obs.threatLevelFormatted }` }>
            { obs.threatScore || '0' }
            {
              !obs.tags || obs.tags.length === 0 ? null : (
                <span
                  className="icon icon-tag"
                  data-tooltip={ obs.tags.join(', ') }
                />
              )
            }
          </span>
          <span
            className={ `kill_chain_icon icon icon-kc_${ obs.killchainStageFormatted }` }
            data-tooltip={ `Killchain Stage: ${ obs.killchainStageFormattedHuman }` }
          />
          <div className="inner_content">
            <div className="line">
              <TextOverflow className="title_row">{ obs.formattedTitleRich }</TextOverflow>
              <span className="obs_type">
                { obs.observationTypeFormatted }
              </span>
            </div>
            <div className="line">
              <div className="flex_1"></div>
              <div className="time_stamp">
                <div
                  className="time_label"
                  data-tooltip={ this.props.timeProp.replace('At', ' Time') }
                >{ this.props.timeProp.substr(0, 3) }</div>
                <RelativeTime
                  data-tooltip={ this.props.timeProp.replace('At', '') + ': ' + obs[this.props.timeProp + 'Formatted'] }
                  time={ obs[this.props.timeProp] }
                />
              </div>
            </div>
          </div>
          <span
            className={ `threat_icon icon icon-cat_${ obs.threatCategoryFormatted }` }
            data-tooltip={ `Category: ${ obs.threatCategoryFormattedHuman}` }
          />
        </a>
      </li>
    )
  }
}

export default ObservationListItem

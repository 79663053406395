import 'inobounce' // Prevent overscroll viewport bouncing
import 'add-to-homescreen'

window._pw = {}

const _isStagingOrBeta = () => (
  window &&
  window.location &&
  window.location.host &&
  (
    window.location.host.indexOf('staging-visualizer') !== -1 ||
    window.location.host.indexOf('beta-visualizer') !== -1 ||
    window.location.host.indexOf('localdev') !== -1
  )
)

const _isDemo = () => (
  window &&
  window.location &&
  window.location.host &&
  (
    window.location.host.indexOf('demo.') !== -1
  )
)

// UI feature flags
window._pw.enableImmersive = localStorage.getItem('enableImmersive') || false
window._pw.enableKillboxMethodology = localStorage.getItem('enableKillboxMethodologyObs') || false
window._pw.enlightened = localStorage.getItem('enlightened') || false

if (process.env.DEV_SERVER_ACTIVE) {
  const LOCAL_DEV_PROXY_HOST_HEADER = 'X-PW-Localdev-Proxy-Host'
  const PROXY_HOST_LOCALSTORAGE_KEY = 'localdevProxyHost'
  const PROXY_HOST_OPTIONS = ['staging', 'demo', 'beta', 'production', 'local']

  window.pwDev = {
    PROXY_HOST_OPTIONS,
    setProxyHost: (host) => {
      host = host.toLowerCase()
      if (PROXY_HOST_OPTIONS.includes(host)) {
        localStorage.setItem(PROXY_HOST_LOCALSTORAGE_KEY, host)
        window.location.reload()
      }
      else {
        console.warn('Invalid Proxy Host option. Available options: ', PROXY_HOST_OPTIONS.join(', '))
      }
    },
    proxyHost: localStorage.getItem(PROXY_HOST_LOCALSTORAGE_KEY) || PROXY_HOST_OPTIONS[0],
    // proxyHostHeader: LOCAL_DEV_PROXY_HOST_HEADER,
    getProxyHeaders: () => {
      const _headers = {}
      // Local dev server needs the proxy host set as early as possible in a session
      if (process.env.DEV_SERVER_ACTIVE && window && window.pwDev.proxyHost) {
        _headers[LOCAL_DEV_PROXY_HOST_HEADER] = window.pwDev.proxyHost
      }
      return _headers
    }
  }

  console.log('LOCALDEV: Proxying API calls to ' + window.pwDev.proxyHost)
}



const NARROW_SCREEN_THRESHOLD = 1450 // Below 1400px wide considered narrow screen. Also reflected in _pw-variables.sass

// Test if this is an iOS browser
function iOS() {
  let iDevices = [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ]

  if (!!navigator.platform) {
    while (iDevices.length) {
      if (navigator.platform === iDevices.pop()) { return true }
    }
  }
  return false
}


window._pwCompatibility = {
  standaloneApp: window.navigator.standalone,
  isNarrowScreen() {
    return window.innerWidth <= NARROW_SCREEN_THRESHOLD
  },
  ios: iOS(),
  isTouchscreen: false,
  supportsNonScalingStroke: false //document.documentElement.style.vectorEffect !== undefined // Firefox currently reports `true`, but does not actually support the non-scaling-stroke value
}

// Cribbed from Modernizr: https://github.com/Modernizr/Modernizr/blob/master/feature-detects/touchevents.js
if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
  window._pwCompatibility.isTouchscreen = true
}


if (!window._pwCompatibility.standaloneApp) {
  window.addToHomescreen({
    // startDelay: 1,
    // mandatory: true,
    // modal: true,
    // debug: true,
    // skipFirstVisit: false,
    // maxDisplayCount: 3,
    // lifespan: 0
    skipFirstVisit: false,
    maxDisplayCount: 2,
    modal: true
  })
}

// console.log('compatibility:', window._pwCompatibility)

import _ from 'lodash'
import React from 'react'
import T from 'prop-types'
import { ScrollBars } from 'react-base'
import { RULE_MAX_DESCRIPTION_CHARACTERS } from 'constants/intelManagementConstants'
import IntelRuleQuickAddActions from 'actions/IntelRuleQuickAddActions'
import ThreatMappingInputs from '../ThreatMappingInputs'
import InfoHint from 'components/InfoHint'
import IntelRuleTagInput from '../IntelRuleTagInput'
import { TextareaInput } from 'pw-drafty'

import { getRuleTagComponent } from 'utils/intelManagementUtils'
import { getStringLengthValidator } from 'pw-validators'

const descriptionValidator = getStringLengthValidator(
  null,
  RULE_MAX_DESCRIPTION_CHARACTERS
)

class QuickSetSubscriptions extends React.Component {
  handleFieldUpdate = (value, fieldName) => {
    IntelRuleQuickAddActions.updateThreatMappings(
      _.isObject(value) ? value : _.set({}, fieldName, value)
    )
  }

  handleTagCommit = ({ create = [], add = [], remove = [] }) => {
    const { tags = [] } = this.props.intelRule
    const addTags = create.concat(add)

    let newTags = _.clone(tags || [])
    newTags = newTags.concat(addTags.map(tag => tag.name))
    newTags = _.without(newTags, ...remove.map(tag => tag.name))

    IntelRuleQuickAddActions.updateTags(_.uniq(newTags))
  }

  render() {
    const { intelRule, isWhitelist, allTags } = this.props

    const { threatMappings = {}, tags, description } = intelRule

    const allRuleTags = _.map(allTags, tag => {
      tag.getMenuItemContent = getRuleTagComponent(tag)
      return tag
    })

    return (
      <div className="quick_set_threat_mappings">
        <h6>
          {isWhitelist ? (
            `Optionally set metadata`
          ) : (
            `Set Threat Mappings and metadata`
          )}
        </h6>
        <div className="quick_set_mappings_content">
          <ScrollBars outside slimShady slimShadyDarker>
            {!isWhitelist ? (
              <section>
                <h5>Threat Mappings</h5>
                <div className="mappings_wrap flex_1">
                  <InfoHint
                    className={`mappings_info_window`}
                    icon={`killchain-spiral`}
                    type={`threatMapping`}
                  >
                    <span className="icon icon-killchain-spiral" />
                  </InfoHint>
                  <div className="grid_cell flex_2">
                    <span>
                      Killchain Stage <InfoHint type={`killchain`} />
                    </span>
                    <div>
                      <ThreatMappingInputs
                        allMappingsRequired
                        currentValue={threatMappings.killchainStage}
                        fieldName="killchainStage"
                        onChange={this.handleFieldUpdate}
                      />
                    </div>
                  </div>
                  <div className="grid_cell flex_2">
                    <span>
                      Category
                      {/* <InfoHint type={ `category` } /> */}
                    </span>
                    <ThreatMappingInputs
                      allMappingsRequired
                      currentValue={threatMappings.category}
                      fieldName="category"
                      onChange={this.handleFieldUpdate}
                    />
                  </div>
                  <div className="grid_cell rating_cell">
                    <span>
                      Threat Rating <InfoHint type={`rating`} />
                    </span>
                    <div>
                      <ThreatMappingInputs
                        allMappingsRequired
                        currentValue={_.pick(
                          threatMappings,
                          'severity',
                          'confidence'
                        )}
                        fieldName="rating"
                        onChange={this.handleFieldUpdate}
                      />
                    </div>
                  </div>
                </div>
              </section>
            ) : null}
            <section>
              <h5>Tags</h5>
              <IntelRuleTagInput
                allTags={allRuleTags || []}
                className={`flex_1`}
                currentTags={tags || []}
                handleTagCommit={this.handleTagCommit}
                readOnly={false}
              />
            </section>
            <section>
              <h5>Description</h5>
              <TextareaInput
                className={`description_container`}
                initialValue={description}
                onChange={IntelRuleQuickAddActions.updateDescription}
                placeholder={`Rule description...`}
                readOnly={false}
                validator={descriptionValidator}
              />
            </section>
          </ScrollBars>
        </div>
      </div>
    )
  }
}

QuickSetSubscriptions.displayName = 'QuickSetSubscriptions'
QuickSetSubscriptions.propTypes = {
  allTags: T.array,
  intelRule: T.shape({
    threatMappings: T.object,
    tags: T.array,
    description: T.string
  }),
  isWhitelist: T.bool,
  selectedListId: T.string,
  selectedListName: T.string
}

export default QuickSetSubscriptions

import _ from 'lodash'
import T from 'prop-types'
import Reflux from 'reflux'
import ipaddr from 'pw-ipaddr.js'

import SensorActions from 'actions/SensorActions'
import CommonViewActions from 'actions/CommonViewActions'
import constants, {
  MIN_SENSOR_VERSION_FOR_PERCEPTIVE_CAPTURE,
  SENSOR_SET_COLORS
} from 'pwConstants'
import LocationStore from 'stores/LocationStore'
import {requestDelete, requestPost, requestPut} from 'utils/restUtils'
import semverCompare from 'semver-compare'
import UserStore from 'stores/UserStore'
import { PERCEPTIVE_PSEUDO_PROFILE } from 'stores/CaptureProfileStore'
import AnalyticsActions from 'actions/AnalyticsActions'

const ipAddressUtil = ipaddr.util

const liveCollectionEventNames = constants.liveCollectionEventNames

//Channels/collection ids that will have broadcast changes from the worker/server
const COLLECTIONS = {
  sensors: {
    sortBy: 'friendly_name'
  },
  sensorSets: {
    sortBy: 'name'
  }
}

const COLLECTION_IDS = _.keys(COLLECTIONS)

let _state = {
  isSaving: false,
  error: null,
  latestVersion: '1.0.1', //TODO update from server when available
  sensors: [],
  sensorsById: Object.create(null),

  enabledSensors: [],
  disabledSensors: [],

  sensorSets: [],
  sensorSetsById: Object.create(null),
  activeSensorIds: [],
  selectedSensorIds: [],
  selectedActiveSensorIds: [],
  selectedSensorId: null,

  stoppingSensors: [], // Sensor IDs that are in the process of stopping (is_active: true => false)
  restartingSensors: [] // Sensor IDs that are in the process of being restarted
}

export const SENSOR_STORE_PROP_TYPES = {
  isSaving: T.bool,
  error: T.any,
  latestVersion: T.string,
  sensors: T.array,
  sensorsById: T.object,

  enabledSensors: T.array,
  disabledSensors: T.array,

  sensorSets: T.array,
  sensorSetsById: T.object,
  activeSensorIds: T.arrayOf(T.number),
  selectedSensorIds: T.arrayOf(T.number),
  selectedActiveSensorIds: T.arrayOf(T.number),
  selectedSensorId: T.number,

  stoppingSensors: T.arrayOf(T.number),
  restartingSensors: T.arrayOf(T.number)
}

// Reflux store for managing sensors.
// Can be subscribed to in typical bacon-reflux fashion, but also
// supplies static getters for integration with legacy modules.
export default Reflux.createStore({
  listenables: SensorActions,

  unsubs: [], // Store bacon bus unsub functions

  init() {

  },

  getInitialState() {
    return _state
  },

  // Listen to the worker event bus/aggregator for changes that should produce a _state update
  // NOTE: When this was triggered using a Reflux action there was a rare race condition where
  // the listeners were not bound in time if the UI tab was not currently selected when the page was initializing.
  registerViewLink(uiToWorkerLinkBus, workerToUiLinkBus) {
    this._uiToWorkerLinkBus = uiToWorkerLinkBus

    this.unsubs = _.map(COLLECTION_IDS, collectionId => {
      return workerToUiLinkBus
        .map(s => s.message)
        .filter(evt => evt.modelName === collectionId)
        .onValue(this._onLinkUpdate.bind(this))
    })
  },

  // Ever going to need this?
  // stopDataLink() {
  //   _.map(this.unsubs, (unsub) -> unsub())

  // ###
  // Action listeners
  // ###
  onToggleSelected(sensorId) {
    // Only allow a single selected sensor
    _state.selectedSensorId =
      _state.selectedSensorId === sensorId ? null : sensorId
    this._notify()
  },

  // onToggleSelectAllInSet(sensorSetId) {
  //   let sensorSet = this.getSensorSet(sensorSetId)
  //   let newSetting = !_.every(sensorSet.sensors, 'is_selected')
  //   _.forEach(sensorSet.sensors, sensor => {
  //     sensor.is_selected = newSetting
  //   })
  //   this._notify()
  // },

  onSaveSensorSet(updatedSet) {
    if (!updatedSet) return
    this._startSaving()
    let _req
    if (updatedSet.id) {
      _req = requestPut(
        null,
        'sensor-sets/' + updatedSet.id,
        _.omit(updatedSet, 'id')
      )
    } else {
      _req = requestPost(null, 'sensor-sets', updatedSet)
    }
    _req
      .then(this._handleSaveSensorSetSuccess.bind(this))
      .catch(this._handleRestError.bind(this))
  },

  onDeleteSensorSet(sensorSetId) {
    if (!sensorSetId) {
      return
    }
    requestDelete(null, 'sensor-sets/' + sensorSetId)
      .then(data => {
        _state.sensorSets = _.reject(_state.sensorSets, { id: sensorSetId })
        _state.isSaving = false
        this._queueNotify()
      })
      .catch(() => {
        _state.isSaving = false
        this._queueNotify()
        CommonViewActions.Notification.add({
          heading: 'Error deleting Sensor Set',
          type: 'error',
          dismissTimer: 15000
        })
      })
  },

  onSaveSensor(updatedSensor) {
    if (!updatedSensor) return
    this._startSaving()
    let _req
    if (updatedSensor.id) {
      _req = requestPut(
        null,
        'sensors/' + updatedSensor.id,
        _.omit(updatedSensor, 'id')
      )
    } else {
      _req = requestPost(null, 'sensors', updatedSensor)
    }
    _req
      .then(data => {
        // We don't use the response directly since it doesn't include the `sensor_config`
        SensorActions.reloadSensors()
        _state.isSaving = false
        this._queueNotify()
      })
      .catch(this._handleRestError.bind(this))
  },

  // Start a fast poll to "watch" for a stopped sensor to go offline,
  _pollForSensorStopped(sensorId) {
    this._testSimStopTime = Date.now()
    // Not polling for this sensor yet
    if (_state.stoppingSensors.indexOf(sensorId) === -1) {
      _state.stoppingSensors.push(sensorId)
      this._queueNotify()
    }
    this._fastPoll() // start/restart fast-polling loop
  },

  _fastPoll() {
    clearTimeout(this._fastPollTimer)
    let continuePoll = _.some(_state.stoppingSensors, sensorId => {
      let sensor = this.getSensor(sensorId)
      // if (!sensor.is_active) {
      if (Date.now() - this._testSimStopTime > 20000) {
        // sensor stopped, remove from stoppingSensors list
        _.pull(_state.stoppingSensors, sensorId)
        this._queueNotify()
      }
      return sensor.is_active
    })
    if (continuePoll) {
      this._fastPollTimer = setTimeout(() => {
        this._fastPoll()
        SensorActions.reloadSensors()
      }, 2000)
    }
  },

  _handleSensorCommandShortcut(sensorId, command, humanReadableCommand) {
    if (!sensorId || !command) {
      return
    }

    requestPost(`s_c_${sensorId}_${command}`, `sensor-commands/${command}`, {
        ids: [sensorId]
      })
      .then(data => {
        let resp = _.find(data, { id: sensorId })
        if (resp.error) {
          // Handle control-channel errors that still result in a successful sensor-commands request
          CommonViewActions.Notification.add({
            heading: `Error ${humanReadableCommand} sensor`,
            message: _.get(resp, 'error.message'),
            type: 'error',
            dismissTimer: 15000
          })
        } else if (command === 'stopsensor') {
          this._pollForSensorStopped(sensorId)
        }
        SensorActions.reloadSensors()
        _state.isSaving = false
        this._queueNotify()
      })
      .catch(() => {
        _state.isSaving = false
        this._queueNotify()
        CommonViewActions.Notification.add({
          heading: `Error ${humanReadableCommand} sensor`,
          type: 'error',
          dismissTimer: 15000
        })
      })
  },

  onStartSensor(sensorId) {
    this._handleSensorCommandShortcut(sensorId, 'startsensor', 'starting')
  },

  onStopSensor(sensorId) {
    this._handleSensorCommandShortcut(sensorId, 'stopsensor', 'stopping')
  },

  onRestartSensor(sensorId) {
    sensorId = +sensorId
    const sensor = this.getSensor(sensorId)
    if (sensor) {
      const {restartingSensors} = _state
      if (!restartingSensors.includes(sensorId)) {
        restartingSensors.push(sensorId)
        this._notify()

        AnalyticsActions.event({
          eventCategory: 'profiler',
          eventAction: 'restart_sensor',
          eventLabel: 'single'
        })

        requestPost('sensor_apply', "sensors/config", {
          action: 'apply',
          sensor_id: [sensorId]
        })
          .catch(restError => [{success: false}])
          .then((response) => {
            const result = response && response[0]
            if (result && result.success) {
              CommonViewActions.Notification.add({
                type: 'success',
                heading: 'Sensor Restarted',
                message: 'The sensor successfully restarted with the updated settings.',
                dismissTimer: 10000
              })
              // Update flag in memory right away
              sensor._config_applied = true
            } else {
              CommonViewActions.Notification.add({
                type: 'error',
                heading: 'Sensor Restart Failed',
                message: 'Could not restart sensor. Check your sensor manager logs or contact your support representative for more details.',
                dismissTimer: 10000
              })
            }
            _state.restartingSensors = _.without(restartingSensors, sensorId)
            this._notify()
          })
      }
    }
  },

  onSetSensorEnabled(sensorId, enableSensor) {
    if (!sensorId) return
    let actionName = enableSensor ? 'enable' : 'disable'
    requestPost(null, `sensors/${sensorId}/${actionName}`)
      .then(data => {
        SensorActions.reloadSensors()
        _state.isSaving = false
        this._queueNotify()
      })
      .catch(restError => {
        SensorActions.reloadSensors()
        if (restError.response.status === 400) {
          let _sensor = _.find(_state.sensors, { id: sensorId })
          CommonViewActions.Notification.add({
            heading: `Could not disable the "${_sensor.friendly_name}" sensor`,
            message: `The "${_sensor.friendly_name}" sensor is still active. Please stop the sensor, wait at least 20 seconds, and try again.`,
            type: 'warning',
            dismissTimer: 20000
          })
        } else {
          CommonViewActions.Notification.add({
            heading: `Error: Could not disable sensor`,
            type: 'error',
            dismissTimer: 15000
          })
        }
        _state.isSaving = false
        this._queueNotify()
      })
  },

  onReloadSensors() {
    this._uiToWorkerLinkBus.push({
      action: 'reloadSensors',
      args: [true] // Also reload sensorSets
    })
  },

  onResetSensor(sensorId) {
    if (!sensorId) {
      return
    }
    requestPost(null, `sensors/${sensorId}/reset`)
      .then(data => {
        SensorActions.reloadSensors()
        _state.isSaving = false
        this._queueNotify()
      })
      .catch((restError) => {
        SensorActions.reloadSensors()
        if (restError.response.status === 400) {
          let _sensor = _.find(_state.sensors, { id: sensorId })
          CommonViewActions.Notification.add({
            heading: `Could not reset the "${_sensor.friendly_name}" sensor`,
            message: `The "${_sensor.friendly_name}" sensor is still active. Please stop the sensor, wait at least 20 seconds, and try again.`,
            type: 'warning',
            dismissTimer: 20000
          })
        } else {
          CommonViewActions.Notification.add({
            heading: `Error: Could not reset sensor`,
            type: 'error',
            dismissTimer: 15000
          })
        }
        _state.isSaving = false
        this._queueNotify()
      })
  },

  onResetConfig(sensorId) {
    if (!sensorId) {
      return
    }
    _state.isSaving = true
    this._queueNotify()

    Promise.all([
      requestPut('sensor_settings_reset', `sensors/${sensorId}`, {}),
      requestPut('sensor_config_reset', `sensors/${sensorId}/config`, {
        "capture_profile_id": null,
        "adaptive_profile_id": null,
        "bandwidth_limit": null,
        "backbuffer_memory_size": null,
        "backbuffer_disk_size": null,
        "filters": null,
        "proxies": [],
        // "interfaces": "",
        "proxy_header_list": []
      }),
      requestPut('sensor_locations_reset', `sensors/${ sensorId }/locations`, [])
    ])
    .then(
      responses => {
        SensorActions.reloadSensors()
        _state.isSaving = false
        this._queueNotify()
      },
      restError => {
        SensorActions.reloadSensors()
        CommonViewActions.Notification.addRestError(
          restError,
          'Error resetting sensor configuration'
        )
        _state.isSaving = false
        this._queueNotify()
      }
    )
  },

  onGenerateInstallToken(sensorId) {
    if (sensorId) {
      requestPost(null, `sensors/${sensorId}/generate-token`)
        .then(data => {
          let sensor = this.getSensor(sensorId)
          if (sensor) {
            sensor.installToken = data.installToken
          } else {
            console.warn('Unable to find sensor', sensorId)
          }
          this._queueNotify()
        })
        .catch(restError => {
          CommonViewActions.Notification.add({
            heading: 'Error',
            message: 'Unable to generate token. Please try again later',
            type: 'info',
            dismissTimer: 15000
          })
        })
    }
  },

  // ###
  // Conventional Getters
  // ###
  getSensor(sensorId) {
    return _state.sensorsById[sensorId]
  },

  getSensorSet(sensorSetId) {
    return _state.sensorSetsById[sensorSetId]
  },

  getSensorSets() {
    return _state.sensorSets
  },

  getSensorName(sensorId) {
    let _sensor = this.getSensor(sensorId)
    let name = _sensor != null ? _sensor.friendly_name : `ID:${sensorId} (removed)`
    if (_sensor && _sensor.isVersion2 && _sensor.downloaded) {
      name += ' (v2)'
    }
    return name
  },

  getSensorSetName(sensorSetId) {
    let _sensorSet = this.getSensorSet(sensorSetId)
    return _sensorSet != null ? _sensorSet.name : `ID:${sensorSetId} (removed)`
  },

  getSensorIds() {
    return _.pluck(_state.sensors, 'id')
  },

  getSelectedSensorIds() {
    return _state.selectedSensorIds
  },

  // query by either 1 sensor or all sensors
  getSelectedSensorsParam() {
    if (_state.selectedSensorIds && _state.selectedSensorIds.length === 1) {
      return _state.selectedSensorIds[0]
    }
    // not including any sensor ID means query by all sensors
    return null
  },

  getActiveSensorIds() {
    return _state.activeSensorIds
  },

  getSelectedActiveSensorIds() {
    return _state.selectedActiveSensorIds
  },

  isSelectedSensor(sensorId) {
    return _state.selectedSensorIds.indexOf(sensorId) !== -1
  },

  //Check if the passed IP is internal to any sensors. If a `sensorId` is passed, only that sensor will be checked
  //TODO add option for returning the sensors the IP is internal to
  isInternalIp(ip, sensorId, ipAddressUtilObj = ipAddressUtil) {
    let _sensors =
      sensorId && sensorId != null ? [this.getSensor(sensorId)] : _state.sensors
    return _.some(_sensors, sensor => {
      return (
        sensor &&
        sensor.internal_ips &&
        _.some(
          sensor.internal_ips || [],
          _.partial(ipAddressUtilObj.isIpInSubnet, ip)
        )
      )
    })
  },

  // Return a bound isInternalIp function that uses an internal cache for better performance when used over many IPs
  isInternalIpBatch() {
    let batch = ipaddr.batch()
    return (ip, sensorId) => {
      return this.isInternalIp(ip, sensorId, batch)
    }
  },

  getInternalGeolocation(ip, sensorId) {
    if (!ip || !sensorId) {
      console.error(
        'SensorStore.getInternalGeolocation: Invalid Arguments',
        ip,
        sensorId
      )
      return
    }
    return this._getInternalGeolocation(ip, sensorId, this.isInternalIp)
  },

  getInternalGeolocationBatch(isInternalIp) {
    if (!isInternalIp) {
      isInternalIp = this.isInternalIpBatch()
    }
    let cache = {}

    return (ip, sensorId) => {
      let _cacheId = `${ip}_${sensorId}`

      if (!cache.hasOwnProperty(_cacheId)) {
        window._internalGeoCacheMisses++
        cache[_cacheId] = this._getInternalGeolocation(
          ip,
          sensorId,
          isInternalIp
        )
      } else {
        window._internalGeoCacheHits++
      }
      return cache[_cacheId]
    }
  },

  // Internal method for looking up internal geolocations. Expects to be provided with a function to check if the supplied IP is internal to the supplied SensorID
  _getInternalGeolocation(ip, sensorId, isInternalIpFunc) {
    if (!isInternalIpFunc(ip, sensorId)) {
      console.info(
        'SensorStore.getInternalGeolocation: IP is not internal to passed SensorID',
        ip,
        sensorId
      )
      return
    }
    let _sensor = this.getSensor(sensorId)
    let subnetLocations = _sensor.locations || []

    for (var i = 0; i < subnetLocations.length; i++) {
      let subnetLocation = subnetLocations[i]
      if (ipAddressUtil.isIpInSubnet(ip, subnetLocation.cidr_range)) {
        if (!subnetLocation.location_id) {
          console.warn(
            'SensorStore.getInternalGeolocation: Sensor.SubnetLocation: location_id',
            subnetLocation
          )
          return
        }
        // Lookup actual Location object from sensor SubnetLocation,
        // now that we have a match
        // TODO either cache this or attach Locations to Sensor records when both stores are loaded/synced
        let fullLocation = LocationStore.getLocationById(
          subnetLocation.location_id
        )
        return _.assign(subnetLocation, {
          location: fullLocation
        })
      }
    }
  },

  // ###
  // Internal methods
  // ###
  _notify() {
    // Apply consistent sorting of sensors and sets, alphabetic by name
    _state.sensors = _.sortBy(_state.sensors, d => (d.friendly_name || 'zzzz').toLowerCase())
    _state.sensorSets = _.sortBy(_state.sensorSets, d => (d.name || 'zzzz').toLowerCase())

    // Update separate sub-states for commonly requested sensor set properties
    // Update per-sensor selected flag
    _state.sensors = _.map(_state.sensors, sensor => {
      sensor['is_selected'] =
        _state.selectedSensorId === null
          ? true
          : _state.selectedSensorId === sensor.id
      sensor.isStopping = _state.stoppingSensors.indexOf(sensor.id) !== -1
      sensor.isRestarting = _state.restartingSensors.indexOf(sensor.id) !== -1
      sensor.isVersion2 = semverCompare(sensor.version || "0.1", "2.0.0") >= 0
      sensor.supportsPerceptiveCapture = semverCompare(sensor.version || "0.1", MIN_SENSOR_VERSION_FOR_PERCEPTIVE_CAPTURE) >= 0
      sensor.captureProfileName = _.get(sensor, 'sensor_config.adaptive_profile_id') === 1
        ? PERCEPTIVE_PSEUDO_PROFILE.name
        : _.get(sensor, 'sensor_config.capture_profile.name')
      // Demo sensors don't request their config from Planck, so ignore the fact that
      // they report as needing a restart:
      if (UserStore.isDemoCustomer()) {
        sensor._config_applied = true
      }
      return sensor
    })
    _state.enabledSensors = _.filter(_state.sensors, { enabled: true })
    _state.disabledSensors = _.filter(_state.sensors, { enabled: false })

    _state.activeSensorIds = this._filterSensorIds({
      is_active: true,
      enabled: true
    })
    // _state.activeSensorIds = this._filterSensorIds({enabled: true})
    _state.selectedSensorIds = this._filterSensorIds({
      is_selected: true,
      enabled: true
    })
    _state.selectedActiveSensorIds = this._filterSensorIds({
      is_active: true,
      is_selected: true,
      enabled: true
    })
    this._attachSensorsToSets()
    this._updateIndexes()
    _state._updated = Date.now()
    this.trigger(_state)
  },

  _queueNotify() {
    clearTimeout(this._queueNotifyTimer)
    this._queueNotifyTimer = setTimeout(this._notify.bind(this), 100)
  },

  _startSaving() {
    _state.isSaving = true
    _state.error = null
    this._notify()
  },

  _handleSaveSensorSetSuccess(data) {
    this._updateCollection('sensorSets', data)
    _state.isSaving = false
    this._queueNotify()
  },

  _handleRestError(restError) {
    _state.isSaving = false
    _state.error = restError
  },

  _attachSensorsToSets() {
    // if (_.isEmpty(_state.sensorSets) || _.isEmpty(_state.sensors)) return

    // let _sensors = _state.enabledSensors
    // let _sensors = _state.sensors
    let _groupedSensors = _.groupBy(_state.sensors || [], 'agent_set_id')
    let _groupedEnabledSensors = _.groupBy(
      _state.enabledSensors || [],
      'agent_set_id'
    )
    let _groupedDisabledSensors = _.groupBy(
      _state.disabledSensors || [],
      'agent_set_id'
    )

    _state.sensorSets = _.map(_state.sensorSets || [], (sensorSet, i) => {
      sensorSet.sensors = _groupedSensors[sensorSet.id] || []
      sensorSet.enabledSensors = _groupedEnabledSensors[sensorSet.id] || []
      sensorSet.disabledSensors = _groupedDisabledSensors[sensorSet.id] || []
      sensorSet.sensorCount = sensorSet.sensors.length
      sensorSet.enabledSensorCount = sensorSet.enabledSensors.length
      sensorSet.disabledSensorCount = sensorSet.disabledSensors.length
      sensorSet.color = SENSOR_SET_COLORS[i % SENSOR_SET_COLORS.length]
      return sensorSet
    })

    // for (var i = 0, setLen = _state.sensorSets.length; i < setLen; i++) {
    //   let set = _state.sensorSets[i]
    //   set.sensors = _groupedSensors[set.id] || []
    //   set.sensorCount = set.sensors.length
    // }
  },

  _updateIndexes() {
    const sensorIdx = _state.sensorsById = Object.create(null)
    _state.sensors.forEach(sensor => {
      sensorIdx[sensor.id] = sensor
    })
    Object.freeze(sensorIdx)

    const setIdx = _state.sensorSetsById = Object.create(null)
    _state.sensorSets.forEach(sensorSet => {
      setIdx[sensorSet.id] = sensorSet
    })
    Object.freeze(setIdx)
  },

  _filterSensorIds(filter) {
    if (filter && _state.sensors) {
      return _(_state.sensors).filter(filter).pluck('id').value()
    } else {
      return []
    }
  },

  _processCollectionItems(collectionId, items) {
    if (collectionId === 'sensors') {
      return _.map(items, item => {
        let prev = this.getSensor(item.id)
        // Hang on to installTokens during polled sensor syncs
        if (prev && prev.installToken) {
          item.installToken = prev.installToken
        }
        item.is_selected = item.is_selected == null ? true : item.is_selected // All selected by default
        item.last_seen = Date.parse(item.last_seen)
        item.updated_at = Date.parse(item.updated_at)
        return item
      })
    } else if (collectionId === 'sensorSets') {
      return _.map(items, item => {
        item.sensors = item.sensors == null ? [] : item.sensors
        return item
      })
    }
  },

  _updateCollection(collectionId, newItems) {
    let _newItems = _.isArray(newItems) ? newItems : [newItems]
    let _newIds = _.pluck(_newItems, 'id')
    // Remove any old versions of items in the current collection
    let _currItems = _.reject(
      _state[collectionId] || [],
      itm => _newIds.indexOf(itm.id) !== -1
    )
    let _tmp = this._processCollectionItems(
      collectionId,
      _currItems.concat(_newItems)
    )
    _state[collectionId] = _(_tmp)
      .sortBy(COLLECTIONS[collectionId].sortBy)
      .value()
  },

  _onLinkUpdate(msg) {
    switch (msg.linkEventType) {
      case liveCollectionEventNames.add:
        console.warn('TODO implement live collection add')
        break
      case liveCollectionEventNames.update:
        console.warn('TODO implement live collection update')
        break
      case liveCollectionEventNames.delete:
        console.warn('TODO implement live collection delete')
        break
      case liveCollectionEventNames.sync:
        this._updateCollection(msg.modelName, msg.data)
        this._notify()
        break
      default:
        console.error('Unknown linkUpdate event', msg)
    }
  }
})

import _ from 'lodash'
import WorkerEventDispatcher from 'ui-base/src/data/messages/WorkerEventDispatcher'
import GraphSubscriber from 'ui-base/src/graph-data/GraphSubscriber'
import StreamSubscriber from 'ui-base/src/graph-data/StreamSubscriber'
import { Bus } from 'baconjs'
import SensorStore from 'stores/SensorStore'
import UiSocketRestDispatcher from 'ui-base/src/data/socket-rest/UiSocketRestDispatcher'
import SocketRestClient from 'ui-base/src/data/socket-rest/SocketRestClient'
import events from 'events'
import Protocols from './Protocols'
import UserActions from 'actions/UserActions'
import UserStore from 'stores/UserStore'
import LocationStore from 'stores/LocationStore'
import geoDataUtil from 'utils/geoDataUtil'
import { PassThrough, Writable } from 'stream'
import MapStream from 'pw-map-stream'
import genericUtil from 'ui-base/src/util/genericUtil'
import {registerSocketRestClient} from 'utils/restUtils'
import {initImmersiveData } from './immersiveData.js'
import MainWorker from '../main.worker.js'

const { EventEmitter } = events

// The DataManager's main jobs are to start the data webworker and hook up all of the
// receivers, dispatchers, and event namespaces.
class DataManager extends EventEmitter {

  /**
   * Must be called on app startup. Sets up the data worker and various client subscribers/dispatchers.
   */
  init() {
    // this.workerUrl = document.getElementById(
    //   'workerMainScript'
    // ).dataset.workerSrc
    // this.worker = new Worker(this.workerUrl)
    this.worker = new MainWorker()
    this.workerSink = Writable({
      objectMode: true,
      write: (data, enc, next) => {
        this.worker.postMessage(data)
        next()
      }
    })

    // Socket.IO initial config needs some explicit location settings to work with the BrowserSync server
    this.worker.postMessage({
      mainThreadContext: {
        windowLocation: _.pick(window.location, [
          'host',
          'protocol',
          'port',
          'hostname',
          'origin'
        ])
      }
    })

    this.uiToWorkerLinkBus = new Bus()
    this.workerToUiLinkBus = new Bus()

    this.workerToUiGraphBus = new Bus()
    this.uiToWorkerGraphBus = new Bus()

    this.workerToUiDataStream = PassThrough({ objectMode: true })
    this.uiToWorkerDataSink = MapStream(message => ({
      eventType: 'streaming_data',
      message
    }))
    this.uiToWorkerDataSink.pipe(this.workerSink)

    // Start the SensorStore linked data listener
    SensorStore.registerViewLink(this.uiToWorkerLinkBus, this.workerToUiLinkBus)

    this.uiToWorkerLinkBus.onValue(val => {
      switch (val.action) {
        case 'reloadSensors':
          this.postReloadSensorsMessage(val.args)
          break
        default:
        // No default action
      }
    })

    // socketRest
    let socketRestDispatcher = new UiSocketRestDispatcher({
      domain: 'ui',
      worker: this.worker
    })

    this.socketRestClient = SocketRestClient.create({
      domain: 'ui',
      socketRestDispatcher: socketRestDispatcher
    })

    // inject it into the restUtils
    registerSocketRestClient(this.socketRestClient)


    // A map of objects that can receive messages from the dispatcher.
    // Be sure to add this as the workerDataManager receiver.
    this.receivers = {
      viewLink: this.workerToUiLinkBus,
      graph_data: this.workerToUiGraphBus,
      stream_data: this.workerToUiDataStream,
      workerDataManager: this,
      rest: socketRestDispatcher.receiver
    }

    graphSubscriber = this.graphSubscriber = new GraphSubscriber({
      workerToUiGraphBus: this.workerToUiGraphBus,
      uiToWorkerGraphBus: this.uiToWorkerGraphBus,
      worker: this.worker,
      sensorStore: SensorStore
    })

    streamSubscriber = this.streamSubscriber = new StreamSubscriber({
      uiToWorkerDataSink: this.uiToWorkerDataSink,
      workerToUiDataStream: this.workerToUiDataStream
    })

    this.dispatcher = new WorkerEventDispatcher({
      worker: this.worker,
      receivers: this.receivers
    })

    // When websocket is no longer authorized, log out the current session
    this.on('socketReconnect', () => {
      UserActions.verifySession(true) // Check that the current user is still authorized, reload if mismatched
    })

    // This event is fired after all of the worker data has been loaded.
    this.on('workerDataStarted', this.onWorkerDataStarted.bind(this))

    // If the DataManager start function was called before the worker was finished initializing,
    // then go ahead and start the worker data. Otherwise do nothing.
    this.on('workerInitialized', () => {
      this.workerInitialized = true
      if (this.liveDataPromise) {
        this.postWorkerStartMessage()
      }
    })
  }

  // If the promise already exists, return it. This way the worker data is only started
  // once. Every time after that the function will return the same promise.
  startLiveData() {
    initImmersiveData()
    var worker = this.worker

    if (!this.liveDataPromise) {
      // Create the deferred that we will return later.
      // If the workerDataManager has already been initialized, go ahead and send it a message to start.
      // Otherwise the @on 'initialized' event will call postWorkerStartMessage because we have
      // just created the @liveDataPromise. Starting the workerDataManager this way allows either
      // the UI dataManager or the workerDataManager to initialize first and remove any race conditions.
      this.workerDataStartDeferred = genericUtil.defer()
      if (this.workerInitialized) {
        this.postWorkerStartMessage()
      }

      // Combine that deferred's promise with any others that we need to wait for
      this.liveDataPromise = Promise.all([
        this.workerDataStartDeferred.promise,
        new Promise(function(resolve, reject) {
          Protocols.registerWorker(worker)
          Protocols.load().then(resolve).catch(reject)
        }),
        LocationStore.onLoadAll(),
        geoDataUtil.initCountryLookupData()
      ])
    }
    return this.liveDataPromise
  }

  // After both the dataManager and the workerDataManager have been initialized, send
  // a message to the workerDataManager telling it to load all of its initial data.
  postWorkerStartMessage() {
    var worker = this.worker
    worker.postMessage({
      eventType: 'workerDataManager',
      eventName: 'startLiveData',
      message: { streamingEndpoint: UserStore.getStreamingEndpoint() }
    })
  }

  // Simply resolve the deferred.
  onWorkerDataStarted() {
    if (this.workerDataStartDeferred) {
      this.workerDataStartDeferred.resolve()
    }
  }

  postReloadSensorsMessage(includeSensorSets = true) {
    this.worker.postMessage({
      eventType: 'workerDataManager',
      eventName: 'reloadSensors',
      message: { includeSensorSets }
    })
  }

  // An experimental function net yet being used anywhere in production. Could potentially
  // be used to restart the data thread if it locks up.
  // restartWorker() {
  //   // c:restartWorker1*
  //   //FIXME Agent Latency and Agent Bandwidth graphs do not properly reinitialize on worker restart -RT
  //   this.worker.terminate()
  //   this.worker = new Worker(this.workerUrl)
  //   this.dispatcher.setWorker(this.worker)
  //   this.viewLinkSender.worker = this.worker
  // }
}


// Export a singleton instance, not the class
const manager = new DataManager()
export default manager

// Convenience bindings for subscriber objects - these will be bound once manager.init() is called
export let graphSubscriber = null
export let streamSubscriber = null

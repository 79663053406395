import UserStore from 'stores/UserStore'
import { addGlobalRequestHandler, TRACEID_HEADER } from 'utils/restUtils'
import getConfig from 'utils/uiConfig'

/**
 * List of most recent zipkin trace ids, with most recent first
 */
export const mostRecentErrorTraceIds = []

// Add global request handler that collects trace ids from API error responses
addGlobalRequestHandler((promise, url, requestInit, options) => {
  if (options.trackErrors !== false) {
    promise.catch(restError => { //out-of-band
      const traceId = restError.response.headers.get(TRACEID_HEADER)
      if (traceId) {
        mostRecentErrorTraceIds.unshift(traceId)
        mostRecentErrorTraceIds.length = Math.min(50, mostRecentErrorTraceIds.length)
      }
    })
  }
})

/**
 * Generate a common footer with error/debugging info to be included in support email bodies
 */
export function getSupportEmailFooterText() {
  const userId = UserStore.getCurrentUserInfo().id
  const custId = UserStore.getCurrentCustomerID()
  return `
-----------------------------
Data for ${getConfig().mainBrand} Support (do not delete):
-----
${location.hash}
-----
UID: ${userId}
CID: ${custId}
${mostRecentErrorTraceIds.length ? '\\nRecent error traces:\\n' + mostRecentErrorTraceIds.slice(0, 8).join('\\n') : ''}
`
}

import { observable, action } from 'mobx'
import { Notification, NotificationType } from './Notification'
import { RestError } from 'typings/restError'
import { Store } from 'stores/mobx/StoreManager'

/**
 * Create sequence of ids for new notifications
 */
function * genId () {
  let id = 0
  while(true) {
    id += 1
    yield id
  }
}

export class NotificationStore extends Store {
  idGen = genId()
  timers: WeakMap<Notification, number> = new WeakMap()

  constructor() {
    super()
  }

  @observable
  notifications: Array<Notification> = []

  @action.bound
  add (notification: Notification, id?: number|string) {
    notification.id = id || String(this.idGen.next().value)
    if (notification.dismissTimer > 0) {
      let dismiss = this.dismiss.bind(this, notification.id)
      let timer = window.setTimeout(dismiss, notification.dismissTimer)
      this.timers.set(notification, timer)
    }
    this.notifications.push(notification)
  }

  @action.bound
  addRestError (error: RestError, headingOverride?: string) {
    const notification : Notification = {
      dismissTimer: 60000,
      heading: headingOverride || error.heading,
      message: error.body,
      traceId: error.traceId,
      type: error.response.status >= 500 ? NotificationType.ERROR : NotificationType.WARNING
    }

    if (error.additional && error.additional.length > 0) {
      notification.messageDetails = error.additional.join('\n')
    }

    this.add(notification)
  }

  @action.bound
  update (id: number|string, patch: Partial<Notification>) {
    let notification = this.notifications.find(note => note.id == id)
    Object.assign(notification, patch)
    if (notification.dismissTimer > 0) {
      let timer = this.timers.get(notification)
      let dismiss = this.dismiss.bind(this, notification.id)
      window.clearTimeout(timer)
      timer = window.setTimeout(dismiss, notification.dismissTimer)
      this.timers.set(notification, timer)
    }
  }

  @action.bound
  dismiss (id: number|string) {
    if (id) {
      this.notifications = this.notifications.filter(notification => notification.id !== id)
    }
  }

  @action.bound
  dismissAll () {
    this.notifications = []
  }

}

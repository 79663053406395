import {
  observable,
  flow
} from 'mobx'
import { requestGet } from 'utils/restUtils'
import { Store } from 'stores/mobx/StoreManager'
import UserStore from 'stores/UserStore'
import moment from 'moment'

const URL_TEMPLATE = 'reporting/components/upload_traffic/rollups/cid/intervals/P1M/dates/:date/:format'
export function buildUrl (opts = { format: 'json', date: null }) {
  const date = opts.date ? opts.date : moment.utc().startOf('month').toISOString()
  const format = opts.format ? opts.format : 'json'
  return URL_TEMPLATE.replace(':date', date).replace(':format', format)
}

export default class CustomerUsageStore extends Store {
  @observable raw = {}
  @observable isLoading = false
  @observable error = null
  @observable canSeeReport = false
  @observable customerUsageById = new Map()

  constructor(deps) {
    super(deps)
    this.canSeeReport = UserStore.isSupportCustomer()
    if (this.canSeeReport) {
      this.load()
    }
  }

  fetch = () => {
    const url = buildUrl()
    return requestGet(
      `get_customer_usage`,
      url,
      {
        baseURL: 'api/alpha/'
      }
    )
  }

  load = flow(function * _load () {
    this.isLoading = true
    try {
      let raw = yield this.fetch()
      if (raw) {
        this.raw = raw
        this.raw.data.forEach(data => {
          const id = parseInt(data.customer_id, 10)
          this.customerUsageById.set(id, data)
        })
      }
    }
    catch (restError) {
      this.error = restError
    } finally {
      this.isLoading = false
    }
  })
}


import {observable} from 'mobx'
import {
  SchemerModel,
  schemer
} from 'pw-schemer-mobx'
import {OsType} from '../../../typings/devices/device'
import DeviceAttribute from './DeviceAttribute';

export class Os extends SchemerModel {
  @observable
  @schemer.enum({
    label: 'Type',
    tooltip: 'Operating System Type',
    options: OsType
  })
  osType: OsType

  @observable
  @schemer.string({
    label: 'Version',
    tooltip: 'Operating System Version'
  })
  osVersion: string

  @observable
  @schemer.string({
    label: 'Name',
    tooltip: 'Operating System Name'
  })
  osName?: string
}

export class OsAttr extends DeviceAttribute {
  @observable
  @schemer.model({
    model: Os
  })
  value: Os
}
import _ from 'lodash'
import {
  INTEL_TYPE_OPTIONS,
  INTEL_TYPE_DEFAULT,
  INTEL_STATES,
  INTEL_STATE_OPTIONS,
  THREAT_LEVEL_ENUM,
  INTEL_TYPES,
  INTEL_TYPE_AVAILABILITY
  // HASH_TYPES,
  // MAX_LIST_NAME_LENGTH,
  // INTEL_TYPES_AVAILABLE,
} from 'constants/intelManagementConstants'
import React from 'react'
import UserStore from 'stores/UserStore'

/**
 * Deep comparison of an array of ordered lists(arrays)
 * @method areOrderedListsEqual
 * @param  {Array}              [listsToCompare=[]] set of lists to compare with each other
 * @return {bool}                                   true if all list values & orders match strict value comparison
 */
export function areOrderedListsEqual(listsToCompare = []) {
  if (!listsToCompare || (listsToCompare.length && listsToCompare.length === 0)) {
    return true
  }
  const _numLists = listsToCompare.length
  let _latestLength = -1

  for (var i = 0; i < _numLists; i++) {
    const _list = listsToCompare[i]
    if (_list && _list.length) {
      const _listLength = _list.length
      if (_latestLength > -1 && (_latestLength !== _listLength)) {
        return false // List lengths do not match
      }
      _latestLength = _listLength
    }
  }

  // If all lengths match, do a deep ordered comparison among all lists
  for (var j = 0; j < _latestLength; j++) {
    const _orderVal = listsToCompare[0][j] //
    for (var k = 0; k < _numLists; k++) {
      if (listsToCompare && listsToCompare[k] && listsToCompare[k][j] !== _orderVal) {
        return false
      }
    }
  }

  return true
}



const RULE_UIKEY_DELIMITER = '|'

// Construct a globally unique key for a rule (matches CIA's primaryKey parts)
const RULE_UI_KEY_COMPONENTS = [
  'listId',
  'ruleType',
  'key', // actual CIA rule.key
  'mode',
  'requestKey' // UI's "key", will not match when CIA updates a rule's gid/sid to no longer match the key. (cross-list upload)
]

export function getGlobalUniqueRuleKey(rule) {
  let ruleRequestKey = ""
  if (rule.deleted) {
    ruleRequestKey = rule.key
  }
  return _.map(RULE_UI_KEY_COMPONENTS, p => {
    // return p === 'key' ? ruleKey : rule[p]
    return encodeURIComponent(p === 'requestKey' ? ruleRequestKey : rule[p]) // Encode since some ruleKeys will be URIs
  }).join(RULE_UIKEY_DELIMITER)
}


// export function getGlobalUniqueRuleKeyFormat2 (rule) {
//   return _.map(RULE_UI_KEY_COMPONENTS, p => rule.id[p === 'mode' ? 'ruleMode' : p]).join(RULE_UIKEY_DELIMITER)
// }


export function parseGlobalUniqueRuleKey(uiRuleKey) {
  const spl = uiRuleKey.split(RULE_UIKEY_DELIMITER)
  return _.reduce(RULE_UI_KEY_COMPONENTS, (out, part, i) => {
    out[part] = decodeURIComponent(spl[i])
    return out
  }, {})
}

/**
 * Return lucene query parameters for the given Intel Rule `key` (raw key stored on intel rule, _not_ the unique UI Key)
 */
export function getQueryParamsFromRuleAPIKey(ruleType, ruleApiKey) {
  return `key:"${ruleApiKey}"`
  // switch (ruleType) {
  //   case INTEL_TYPE_OPTIONS.IDS:
  //     // const [
  //     //   gid,
  //     //   sid
  //     // ] = ruleApiKey.split(":")
  //     // return `ids.gid:${ gid } AND ids.sid:${ sid }`
  //     return `key:"${ ruleApiKey }"`
  //   case INTEL_TYPE_OPTIONS.DOMAIN:
  //     // return `domain.domain_name:"${ ruleApiKey }"`
  //     return `key:"${ ruleApiKey }"`
  //   case INTEL_TYPE_OPTIONS.URI:
  //     // return `uri.uri_name:"${ ruleApiKey }"` // Would match partial, bad when multiple rules contain the same substring.
  //     return `key:"${ ruleApiKey }"`
  //   case INTEL_TYPE_OPTIONS.FILEHASH:
  //     return `key:"${ ruleApiKey }"`
  //   default:
  //     console.warn('getQueryParamsFromRuleKey() Unsupported ruleType:', ruleType)
  //     return ""
  // }
}


const REV_REGEX = /(\srev:)(\d+);/g

// Increment the rev:1; part of a set of suricata rules
export function bumpRevisionForRules(uiRulesArr) {
  return uiRulesArr.map(rule => {
    const ruleBody = rule.ruleBody
    if (rule.ruleType !== INTEL_TYPE_OPTIONS.IDS || !ruleBody) {
      return rule
    }
    else {
      let _ruleBody = ruleBody
      const revMatch = ruleBody.match(REV_REGEX)
      if (revMatch && revMatch[0]) {
        const revPart = revMatch[0]
        let revNum = revPart.match(/\d+/)[0]
        if (revNum) {
          revNum = parseInt(revNum)
          if (!isNaN(revNum)) {
            _ruleBody = _ruleBody.replace(REV_REGEX, ` rev:${revNum + 1};`)
          }
        }
      }
      else {
        // No rev in rule, let's add one!
        const closeParenIdx = _ruleBody.lastIndexOf(')')
        _ruleBody = _ruleBody.substring(0, closeParenIdx) + ` rev:1;)`
      }
      return _.assign({}, rule, {
        ruleBody: _ruleBody
      })
    }
  })
}


function _getNeedsThreatMappings(apiRule) {
  return _.some([
    'killchainStage',
    'category',
    'confidence',
    'severity'
  ], f => _.get(apiRule, `threatMapping.${f}`, null) === null)
}

export function convertRuleAPItoUI(apiRule) {
  let _baseRuleParts = {
    _needsThreatMappings: _getNeedsThreatMappings(apiRule),
    _isInSelectionSet: false,

    // build: T.any, // FIXME
    listId: apiRule.id.listId,
    ruleType: apiRule.id.ruleType,
    key: apiRule.id.key,
    mode: apiRule.id.ruleMode,

    ruleBehavior: apiRule.metadata.ruleBehavior,
    targetMode: apiRule.metadata.targetMode,
    status: apiRule.metadata.status,
    // targetStatus: apiRule.metadata.targetStatus,

    tags: apiRule.metadata.tags || [],
    threatMapping: apiRule.metadata.threatMapping,
    vendor: apiRule.metadata.vendor,
    uploadId: apiRule.metadata.uploadId,
    transactionId: apiRule.metadata.transactionId,
    created: apiRule.metadata.created,
    updated: apiRule.metadata.updated,

    intelCreatedAt: apiRule.metadata.intelCreatedAt ? new Date(apiRule.metadata.intelCreatedAt).getTime() : null,
    intelUpdatedAt: apiRule.metadata.intelUpdatedAt ? new Date(apiRule.metadata.intelUpdatedAt).getTime() : null,
    intelObservedAt: apiRule.metadata.intelObservedAt ? new Date(apiRule.metadata.intelObservedAt).getTime() : null,

    validations: apiRule.metadata.validations,
    description: apiRule.metadata.description,

    // subCategory: _.get(apiRule, 'metadata.threatMapping.subCategory'),
    category: _.get(apiRule, 'metadata.threatMapping.category', null), // 'ExploitsAndAttacks', 'DenialOfService', 'Malware', 'Scanning', 'Botnet', 'Phishing', 'Suspicious', 'MaliciousHost', 'APT', 'Misc', 'Unknown'
    confidence: _.get(apiRule, 'metadata.threatMapping.confidence', null),
    severity: _.get(apiRule, 'metadata.threatMapping.severity', null),
    killchainStage: _.get(apiRule, 'metadata.threatMapping.killChainStage', null), // 'METHODOLOGY', 'RECON', 'DELIVERY', 'EXPLOIT', 'BEACON', 'CNC', 'FORTIFICATION', 'DATA_THEFT']
    // threatScore: _.get(apiRule, 'metadata.threatMapping.threatScore'),
    // threatLevel: _.get(apiRule, 'metadata.threatMapping.threatLevel'),
    title: "",

    // _needsThreatMappings: !apiRule.metadata.threatMapping,
    // listIds: T.arrayOf(T.number).isRequired,
    // description: T.string.isRequired,
    // name: T.string.isRequired,
    // sensorIds: T.arrayOf(T.number).isRequired,
    // customerId: ,
    // customerIds: T.arrayOf(T.number).isRequired,
    // isImmuneToCircuitBreaker: T.bool.isRequired,

    // Default values for all rule types
    classtype: null,
    gid: null,
    msg: null,
    references: [],
    ruleBody: null,
    sid: null,

    domainName: null,

    uriName: null,

    fileHash: null,
    fileHashType: null,
    fileType: null,
    fileName: null,
    fileIntelName: null, // File Intel Name

    certHash: null,
    certHashType: null,
    certIssued: null,
    certExpires: null,
    certSslVersion: null,
    certSubject: null,
    certIssuer: null,
    certSubjectCommonName: null,
    certIssuerCommonName: null,
    certAltNames: [],
    certName: null,
    certSerialNumber: null,

    yaraName: null,
    yaraDescription: null,
    yaraScore: null,

    ip: null
  }


  switch (apiRule.id.ruleType) {
    case INTEL_TYPE_OPTIONS.IDS:
      _baseRuleParts = _.assign(_baseRuleParts, {
        classtype: apiRule.intel.ids.classtype,
        gid: apiRule.intel.ids.gid,
        msg: apiRule.intel.ids.msg,
        references: apiRule.intel.ids.references,
        ruleBody: apiRule.intel.ids.rule,
        sid: apiRule.intel.ids.sid,
        title: apiRule.intel.ids.msg
      })
      break
    case INTEL_TYPE_OPTIONS.DOMAIN:
      _baseRuleParts = _.assign(_baseRuleParts, {
        domainName: apiRule.intel.domain.name,
        title: apiRule.intel.domain.name
      })
      break
    case INTEL_TYPE_OPTIONS.URI:
      _baseRuleParts = _.assign(_baseRuleParts, {
        uriName: apiRule.intel.uri.name,
        title: apiRule.intel.uri.name
      })
      break
    case INTEL_TYPE_OPTIONS.FILEHASH:
      _baseRuleParts = _.assign(_baseRuleParts, {
        fileHash: apiRule.intel.file.hash,
        fileHashType: apiRule.intel.file.hashType,
        fileType: apiRule.intel.file.fileType && apiRule.intel.file.fileType.toUpperCase(),
        fileName: apiRule.intel.file.fileName,
        fileIntelName: apiRule.intel.file.name, // File Intel Name
        title: apiRule.intel.file.name || apiRule.intel.file.fileName || apiRule.intel.file.hash
      })
      break
    case INTEL_TYPE_OPTIONS.CERTIFICATE:
      _baseRuleParts = _.assign(_baseRuleParts, {
        references: apiRule.intel.cert.references,
        title: apiRule.intel.cert.name || apiRule.intel.cert.subjectCommonName || apiRule.intel.cert.hash,
        certHash: apiRule.intel.cert.hash,
        certHashType: apiRule.intel.cert.hashType,
        certIssued: apiRule.intel.cert.issued,
        certExpires: apiRule.intel.cert.expires,
        certSslVersion: apiRule.intel.cert.sslVersion,
        certSubject: apiRule.intel.cert.subject,
        certIssuer: apiRule.intel.cert.issuer,
        certSubjectCommonName: apiRule.intel.cert.subjectCommonName,
        certIssuerCommonName: apiRule.intel.cert.issuerCommonName,
        certAltNames: apiRule.intel.cert.alternateNames || [],
        certName: apiRule.intel.cert.name,
        certSerialNumber: apiRule.intel.cert.serialNumber
      })
      break
    case INTEL_TYPE_OPTIONS.YARA:
      _baseRuleParts = _.assign(_baseRuleParts, {
        title: apiRule.intel.yara.name,
        yaraName: apiRule.intel.yara.name,
        yaraDescription: apiRule.intel.yara.description,
        yaraScore: apiRule.intel.yara.score,
        ruleBody: apiRule.intel.yara.ruleBody,
      })
      break
    case INTEL_TYPE_OPTIONS.IP:
      _baseRuleParts = _.assign(_baseRuleParts, {
        ip: apiRule.intel.ip.ip,
        title: apiRule.intel.ip.ip
      })
      break
    default:
      console.warn('Could not convert rule', apiRule)
  }

  // Generate UI key now that we have a normalized structure
  // const uiKey = getGlobalUniqueRuleKeyFormat2(_baseRuleParts)
  const uiKey = getGlobalUniqueRuleKey(_baseRuleParts)

  _baseRuleParts._uiKey = uiKey
  _baseRuleParts.selectableKey = uiKey // For DataTable dragSelection

  const threatLevelInt = getThreatLevelInt(_baseRuleParts.severity, _baseRuleParts.confidence)
  _baseRuleParts.threatLevel = THREAT_LEVEL_ENUM[threatLevelInt - 1]
  _baseRuleParts.threatScore = getThreatScore(_baseRuleParts.severity, _baseRuleParts.confidence)


  return _baseRuleParts
}

export function convertRulesAPItoUI(apiRules) {
  return _.map(apiRules, convertRuleAPItoUI)
}

export function convertRuleSearchAPItoUI(apiRule) {
  // const uiKey = getGlobalUniqueRuleKey(apiRule)
  let _baseRuleParts = {
    // _uiKey: uiKey,
    // selectableKey: uiKey, // For DataTable dragSelection
    _needsThreatMappings: _getNeedsThreatMappings(apiRule),
    _isInSelectionSet: false,

    // build: T.any, // FIXME
    listId: apiRule.listId,
    ruleType: apiRule.ruleType,
    key: apiRule.key,
    mode: apiRule.mode,

    ruleBehavior: apiRule.ruleBehavior,
    targetMode: apiRule.targetMode,
    status: apiRule.status,
    // targetStatus: apiRule.targetStatus,

    tags: apiRule.tags || [],
    threatMapping: apiRule.threatMapping,
    vendor: apiRule.vendor,
    uploadId: apiRule.uploadId,
    transactionId: apiRule.transactionId,
    userId: apiRule.userId, // For ruleHistory

    created: apiRule.created ? new Date(apiRule.created).getTime() : null,
    updated: apiRule.updated ? new Date(apiRule.updated).getTime() : null,

    intelCreatedAt: apiRule.intelCreatedAt ? new Date(apiRule.intelCreatedAt).getTime() : null,
    intelUpdatedAt: apiRule.intelUpdatedAt ? new Date(apiRule.intelUpdatedAt).getTime() : null,
    intelObservedAt: apiRule.intelObservedAt ? new Date(apiRule.intelObservedAt).getTime() : null,

    category: _.get(apiRule, 'threatMapping.category', null), // 'ExploitsAndAttacks', 'DenialOfService', 'Malware', 'Scanning', 'Botnet', 'Phishing', 'Suspicious', 'MaliciousHost', 'APT', 'Misc', 'Unknown'
    // threatSubCategory: _.get(apiRule, 'threatMapping.threatSubCategory'),
    confidence: _.get(apiRule, 'threatMapping.confidence', null),
    severity: _.get(apiRule, 'threatMapping.severity', null),
    killchainStage: _.get(apiRule, 'threatMapping.killChainStage', null), // 'METHODOLOGY', 'RECON', 'DELIVERY', 'EXPLOIT', 'BEACON', 'CNC', 'FORTIFICATION', 'DATA_THEFT']
    // threatScore: _.get(apiRule, 'threatMapping.threatScore'),
    // threatLevel: _.get(apiRule, 'threatMapping.threatLevel'),

    description: apiRule.description,
    validations: apiRule.validations,

    // _detailKey: getGlobalUniqueRuleKey(apiRule), // Uniqueified UI key used for "single" rule lookups via search
    title: "",

    // listIds: T.arrayOf(T.number).isRequired,
    // description: T.string.isRequired,
    // name: T.string.isRequired,
    // sensorIds: T.arrayOf(T.number).isRequired,
    // customerId: ,
    // customerIds: T.arrayOf(T.number).isRequired,
    // isImmuneToCircuitBreaker: T.bool.isRequired,
    // Default values for all rule types
    classtype: null,
    gid: null,
    msg: null,
    references: [],
    ruleBody: null,
    sid: null,

    domainName: null,

    uriName: null,

    fileHash: null,
    fileHashType: null,
    fileType: null,
    fileName: null,
    fileIntelName: null, // File Intel Name

    certHash: null,
    certHashType: null,
    certIssued: null,
    certExpires: null,
    certSslVersion: null,
    certSubject: null,
    certIssuer: null,
    certSubjectCommonName: null,
    certIssuerCommonName: null,
    certAltNames: [],
    certName: null,
    certSerialNumber: null,

    yaraName: null,
    yaraDescription: null,
    yaraScore: null,

    ip: null
  }

  if (!apiRule.deleted) {
    switch (apiRule.ruleType) {
      case INTEL_TYPE_OPTIONS.IDS:
        _baseRuleParts = _.assign(_baseRuleParts, {
          classtype: apiRule.ids.classtype,
          gid: apiRule.ids.gid,
          msg: apiRule.ids.msg,
          ruleBody: apiRule.ids.rule,
          sid: apiRule.ids.sid,
          references: apiRule.ids.references,
          title: apiRule.ids.msg
        })
        break
      case INTEL_TYPE_OPTIONS.DOMAIN:
        _baseRuleParts = _.assign(_baseRuleParts, {
          references: apiRule.domain.references,
          domainName: apiRule.domain.domain_name,
          title: apiRule.domain.domain_name
        })
        break
      case INTEL_TYPE_OPTIONS.URI:
        _baseRuleParts = _.assign(_baseRuleParts, {
          references: apiRule.uri.references,
          uriName: apiRule.uri.uri_name,
          title: apiRule.uri.uri_name
        })
        break
      case INTEL_TYPE_OPTIONS.FILEHASH:
        _baseRuleParts = _.assign(_baseRuleParts, {
          references: apiRule.file.references,
          fileHash: apiRule.file.file_hash,
          fileHashType: apiRule.file.file_hash_type,
          fileName: apiRule.file.file_name,
          fileType: apiRule.file.file_type && apiRule.file.file_type.toUpperCase(),
          fileIntelName: apiRule.file.file_intel_name,
          title: apiRule.file.file_intel_name || apiRule.file.file_name || apiRule.file.file_hash
        })
        break
      case INTEL_TYPE_OPTIONS.CERTIFICATE:
        _baseRuleParts = _.assign(_baseRuleParts, {
          references: apiRule.cert.references,
          title: apiRule.cert.cert_name,
          certHash: apiRule.cert.cert_hash,
          certHashType: apiRule.cert.cert_hash_type,
          certIssued: apiRule.cert.cert_issued,
          certExpires: apiRule.cert.cert_expires,
          certSslVersion: apiRule.cert.cert_ssl_version,
          certSubject: apiRule.cert.cert_subject,
          certIssuer: apiRule.cert.cert_issuer,
          certSubjectCommonName: apiRule.cert.cert_subject_common_name,
          certIssuerCommonName: apiRule.cert.cert_issuer_common_name,
          certAltNames: apiRule.cert.cert_alt_names || [],
          certName: apiRule.cert.cert_name,
          certSerialNumber: apiRule.cert.cert_serial_number
        })
        break
      case INTEL_TYPE_OPTIONS.YARA:
        _baseRuleParts = _.assign(_baseRuleParts, {
          title: apiRule.yara.name,
          yaraName: apiRule.yara.name,
          yaraDescription: apiRule.yara.description,
          yaraScore: apiRule.yara.score,
          ruleBody: apiRule.yara.ruleBody
        })
        break
      case INTEL_TYPE_OPTIONS.IP:
        _baseRuleParts = _.assign(_baseRuleParts, {
          references: apiRule.ip.references,
          ip: apiRule.ip.ip,
          title: apiRule.ip.ip
        })
        break
      default:
        console.warn('Could not convert rule', apiRule)
    }
  }
  else {
    _baseRuleParts.deleted = true
  }

  const uiKey = getGlobalUniqueRuleKey(_baseRuleParts)

  _baseRuleParts._uiKey = uiKey
  _baseRuleParts.selectableKey = uiKey // For DataTable dragSelection

  const threatLevelInt = getThreatLevelInt(_baseRuleParts.severity, _baseRuleParts.confidence)
  _baseRuleParts.threatLevel = THREAT_LEVEL_ENUM[threatLevelInt - 1]
  _baseRuleParts.threatScore = getThreatScore(_baseRuleParts.severity, _baseRuleParts.confidence)

  return _baseRuleParts
}

export function convertRulesSearchAPItoUI(apiRules) {
  return _.map(apiRules, convertRuleSearchAPItoUI)
}


export function convertRuleUItoAPI(uiRule) {
  const metadata = {
    status: uiRule.status,
    targetMode: uiRule.targetMode,
    tags: uiRule.tags || [],
    threatMapping: convertThreatMappingUItoAPI(uiRule),
    description: uiRule.description,
    vendor: uiRule.vendor,
    uploadId: uiRule.uploadId,
    created: uiRule.created,
    updated: uiRule.updated,
    intelCreatedAt: uiRule.intelCreatedAt,
    intelUpdatedAt: uiRule.intelUpdatedAt,
    ruleBehavior: uiRule.ruleBehavior
  }
  if (uiRule.intelObservedAt) {
    metadata.intelObservedAt = uiRule.intelObservedAt
  }

  let intel = {}

  if (!uiRule.deleted) {
    switch (uiRule.ruleType) {
      case INTEL_TYPE_OPTIONS.IDS:
        intel = {
          ids: {
            rule: uiRule.ruleBody,
            sid: uiRule.sid,
            gid: uiRule.gid,
            msg: uiRule.msg,
            classtype: uiRule.classtype
          }
        }
        break
      case INTEL_TYPE_OPTIONS.DOMAIN:
        intel = {
          domain: {
            name: uiRule.domainName
          }
        }
        break
      case INTEL_TYPE_OPTIONS.URI:
        intel = {
          uri: {
            name: uiRule.uriName
          }
        }
        break
      case INTEL_TYPE_OPTIONS.FILEHASH:
        intel = {
          file: {
            hash: uiRule.fileHash,
            hashType: uiRule.fileHashType,
            fileName: uiRule.fileName,
            fileType: uiRule.fileType,
            name: uiRule.fileIntelName
          }
        }
        break
      case INTEL_TYPE_OPTIONS.CERTIFICATE:
        intel = {
          cert: {
            references: uiRule.references,
            hash: uiRule.certHash,
            hashType: uiRule.certHashType,
            issued: uiRule.certIssued,
            expires: uiRule.certExpires,
            sslVersion: uiRule.certSslVersion,
            subject: uiRule.certSubject,
            issuer: uiRule.certIssuer,
            subjectCommonName: uiRule.certSubjectCommonName,
            issuerCommonName: uiRule.certIssuerCommonName,
            alternateNames: uiRule.certAltNames,
            name: uiRule.certName,
            serialNumber: uiRule.certSerialNumber
          }
        }
        break
      case INTEL_TYPE_OPTIONS.YARA:
        intel = {
          yara: {
            name: uiRule.yaraName,
            ruleBody: uiRule.ruleBody
            // NOTE: description and score are stored in yara.metadata's generic string map
            // TODO: re-enable these if we need to start writing to yara.metadata
            // description: uiRule.yaraDescription,
            // score: uiRule.yaraScore
          }
        }
        break
      case INTEL_TYPE_OPTIONS.IP:
        intel = {
          ip: {
            ip: uiRule.ip
          }
        }
        break
      default:
      //
    }
  }

  return {
    id: {
      listId: uiRule.listId,
      ruleType: uiRule.ruleType,
      key: uiRule.key,
      ruleMode: uiRule.mode
    },
    metadata: metadata,
    intel: intel
  }
}

export function convertRulesUItoAPI(uiRules) {
  return _.map(uiRules, convertRuleUItoAPI)
}

/**
 * Collapse all passed ruleHistories by `uploadId`,
 * to unify duplicate records when a rule is
 * moved from status:Pending -> status:<final> by the Mario service.
 * @param {array} ruleHistoryRecors array of UI-converted rule history entries
 */
export function collapseIntelRuleHistoryRecords(ruleHistoryRecords, keyProp = "uploadId") {
  return _.reduce(ruleHistoryRecords, (out, historyRule) => {
    if (out[historyRule[keyProp]]) {
      // Use first human userId associated with this uploadId
      out[historyRule[keyProp]].userId = historyRule.userId || out[historyRule[keyProp]].userId
      // Use first non-pending (final) status if available
      out[historyRule[keyProp]].status = historyRule.status === INTEL_STATE_OPTIONS.PENDING ? out[historyRule[keyProp]].status : historyRule.status
      out[historyRule[keyProp]].validations = _.isEmpty(historyRule.validations) ? out[historyRule[keyProp]].validations : historyRule.validations
    }
    else {
      out[historyRule[keyProp]] = historyRule
    }
    return out
  }, {})
}


export function convertThreatMappingUItoAPI(uiMapping) {
  return {
    category: uiMapping.category,
    confidence: uiMapping.confidence,
    severity: uiMapping.severity,
    killChainStage: uiMapping.killchainStage
    // killChainStage: uiMapping.killchainStage === 'DataTheft' ? 'Data_Theft' : uiMapping.killchainStage,
    // threatSubCategory: uiMapping.threatSubCategory,
    // subCategory: uiMapping.threatSubCategory || 'Unknown', // Default to unknown since hidden
    // threatScore: 1, // TEMP UNTIL REMOVED FROM VALIDATION
    // threatLevel: uiMapping.threatLevel
  }
}

export function convertSubscriptionsAPItoUI(apiSubs) {
  return _.map(apiSubs, sub => ({
    sensorId: sub.sensorId,
    lists: _.map(sub.lists, 'id'),
    listCount: sub.lists.length,
    isDefault: !sub.lists || !sub.lists.length
  }))
}


// Reference: https://gitlab.int.protectwise.net/platform/scala_common/blob/master/messaging-kafka/src/main/scala/com/protectwise/messaging/kafka/ObservationPublisher.scala#L51
export function getThreatScore(severity = 0, confidence = 0) {
  return Math.ceil(severity * (confidence / 100))
}

// Reference https://gitlab.int.protectwise.net/platform/scala_common/blob/master/messaging-kafka/src/main/scala/com/protectwise/messaging/kafka/ObservationPublisher.scala#L59
// Returns enum-matching int corresponding to the four threatLevel values: none, low, medium, high
export function getThreatLevelInt(severity, confidence) {
  const threatScore = getThreatScore(severity, confidence)
  return Math.round(threatScore / 33.3) + 1
}

export function getThreatLevelIntFromThreatScore(threatScore) {
  return Math.round(threatScore / 33.3) + 1
}

export function getThreatLevelFromThreatScore(threatScore) {
  const threatLevelInt = getThreatLevelIntFromThreatScore(threatScore)
  return THREAT_LEVEL_ENUM[threatLevelInt - 1]
}

export function validateRuleType(ruleType = "") {
  if (!ruleType || _.values(INTEL_TYPE_OPTIONS).indexOf(ruleType) === -1) {
    return INTEL_TYPE_DEFAULT
  }
  return ruleType
}

export function getIntelRuleHref(intelUIKey) {
  const {
    listId
  } = parseGlobalUniqueRuleKey(intelUIKey)
  return `/#intel/manage?listId=${encodeURIComponent(listId)}&detail=${encodeURIComponent(intelUIKey)}`
}

export function getRuleTagComponent(tag) {
  const outputFn = function (matchedText) {
    return (
      <div className="intel_rule_tag_option">
        {matchedText}
        <div className="muted_row">{tag.count.toLocaleString()}</div>
      </div>
    )
  }
  outputFn.displayName = "RuleTagComponent"
  return outputFn
}

export function getAvailableIntelTypes() {
  const cid = UserStore.getCurrentCustomerID()
  return _.filter(INTEL_TYPES, type => {
    return type.isAvailable(cid) !== INTEL_TYPE_AVAILABILITY.HIDDEN
  })
}

export function getAvailableIntelTypesForInput() {
  const cid = UserStore.getCurrentCustomerID()
  return _.filter(INTEL_TYPES, type => {
    return type.isInputAvailable(cid) !== INTEL_TYPE_AVAILABILITY.HIDDEN
  })
}
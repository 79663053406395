import T from 'prop-types'
import React from 'react'
import {
  InfoWindow
} from 'react-base'
import {
  DESC_METADATA,
} from 'constants/descriptions'

const INFO_TYPES = [
  'threatMapping',
  'killchain',
  'category',
  'severity',
  'confidence',
  'rating'
]

const BUILT_IN_TYPES = {
  'threatMapping': {
    heading: "Threat Mapping",
    message: DESC_METADATA.THREAT_MAPPING,
    icon: "killchain-spiral",
    additional: "These fields influence how Observations and Events are generated."
  },
  'killchain': {
    heading: "Killchain Stage",
    message: DESC_METADATA.KILLCHAIN_STAGE,
    icon: "killchain-spiral"
  },
  'category': {
    heading: "Threat Category",
    message: DESC_METADATA.CATEGORY,
    icon: "killchain-spiral"
  },
  'severity': {
    heading: "Severity",
    message: DESC_METADATA.SEVERITY,
    icon: "killchain-spiral"
  },
  'confidence': {
    heading: "Confidence",
    message: DESC_METADATA.CONFIDENCE,
    icon: "killchain-spiral"
  },
  'rating': {
    heading: "Threat Rating",
    message: DESC_METADATA.CONFIDENCE,
    icon: "killchain-spiral"
  }
}


export default class extends React.PureComponent {
  static displayName = "InfoHint"

  static propTypes = {
    additional: T.string,
    children: T.any,
    className: T.string,
    style: T.object,
    getMessage: T.func, // Getter that should return a React Component
    heading: T.string,
    icon: T.string,
    menuAlign: T.string,
    menuClassName: T.string,
    message: T.oneOfType([T.string, T.object]),
    type: T.oneOf(INFO_TYPES) // Specify one of the built-in types
  }

  static defaultProps = {
    icon: 'info',
    menuClassName: ""
  }

  _getInfoHintContents = () => {
    let {
      type,
      message,
      getMessage,
      heading,
      additional,
      icon
    } = this.props

    if (type && BUILT_IN_TYPES[type]) {
      const builtIn = BUILT_IN_TYPES[type]
      message = builtIn.message
      heading = builtIn.heading
      icon = builtIn.icon
      additional = builtIn.additional
    }

    return (
      <div>
        <div className="info_window_header"><span className={ `icon icon-${ icon }` } /> { heading }</div>
        <div className="info_window_content">{ getMessage ? getMessage() : message }</div>
        { additional ? (
          <div className="info_window_minor">{ additional }</div>
        ) : null }
      </div>
    )
  }

  render() {
    let {
      children,
      className,
      menuClassName,
      menuAlign,
      style = {}
    } = this.props

    return (
      <InfoWindow
        className={ className }
        menuClassName={ `info_window_guide ${ menuClassName }` }
        windowContents={ this._getInfoHintContents() }
        menuAlign={ menuAlign }
        style={style}
      >
        { children || <span className={ `btn-icon btn icon-info` } /> }
      </InfoWindow>
    )
  }
}

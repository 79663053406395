import { createContext, useContext } from 'react'
import {
  observable,
  action,
  ObservableMap
} from 'mobx'
import { fromResource } from 'mobx-utils'
import { ObservableValue } from 'mobx/lib/internal'

export interface StoreInterface {
  destroy?: () => void // Called when store instance is torn down
  [key: string]: any
}

export interface StoreConstructor {
  new (resolvedDependencies?: StoreInterface[])
}

export abstract class Store implements StoreInterface {
  static keepAlive?: boolean = false // If true this store instance will never be destroyed
  static dependencies?: StoreInterface[] = []

  constructor(resolvedDependencies?: StoreInterface[]) {}

  destroy = () => {}
}

const createStore = (storeCtor: StoreConstructor, resolvedDependencies: StoreInterface[]) : StoreInterface => {
  return new storeCtor(resolvedDependencies)
}

/**
 * Manager using `mobx` observables to track deep usage
 * of Stores by React Components or other Stores.
 * When used (observed), Store classes that have previously
 * been `register`ed will be instantiated, along with any
 * `dependencies` (other Stores) listed. There should probably
 * only be a single StoreManager for the whole app, whose Context
 * is Provided at a root level.
 *
 * Notes:
 *
 * - Store constructors will be called with an array of all
 *   instantiated dependency Stores listed in their `dependencies`
 *   e.g.
 *   ```
 *   class Foo extends Store {
 *     static dependencies = [<StoreClass1>, <StoreClass2>]
 *     constructor (deps) {
 *       super(deps)
 *       const [storeClass1Instance, storeClass2Instance] = deps
 *       // ... Other constructor code. mobx `autorun` works very well for reacting to dependency store state
 *       this.destroy = autorun(() => {
 *         if (storeClass1Instance.someObservableOrComputedProperty === 'cool') {
 *           this.refreshCool()
 *         }
 *       })
 *     }
 *   }
 *   ```
 *
 * - Stores are still singletons, a store that is
 *   already instantiated will not be re-initialized
 *   when a new dependent Store is being constructed
 */
export class StoreManager {
  static Context = createContext(null)

  @observable stores: ObservableMap<StoreConstructor, StoreInterface> = new ObservableMap()

  @action.bound
  register (storeClass: StoreConstructor) {
    this.stores.set(
      storeClass, // Key by Class identifier/constructor
      this.initObservableStore(storeClass)
    )
  }

  getInstance (storeClass: StoreConstructor) {
    if (!this.stores.has(storeClass)) {
      throw new Error(`Unknown Store passed to StoreManager.getInstance. It might need to be registered. ${storeClass}!`)
    }
    return this.stores.get(storeClass).current()
  }

  /**
   * @description dispatch an "Action" that should match a store's method name exactly
   * @memberof StoreManager
   */
  dispatchAction (actionName: string, ...args) {
    let hadListeners = false
    this.stores.forEach((store, storeClass) => {
      const inst = store.current()
      if (inst && inst[actionName]) {
        hadListeners = true
        inst[actionName].apply(inst, args)
      }
    })
    if (!hadListeners) {
      console.warn(`StoreManager.dispatchAction -- No instantiated listeners were called for action: ${actionName}`)
    }
  }

  initObservableStore = storeClass => {
    let currentInstance = null
    return fromResource(
      // Function called when this store is observed (used) anywhere
      (sink) => {
        const deps = storeClass.dependencies || []
        const resolvedDependencies = deps.map(dep => {
          return this.stores.get(dep).current() // return observable store instance
        })
        currentInstance = new storeClass(resolvedDependencies)
        sink(currentInstance) // Return instantiated store to observer
      },
      // Function called when a store is no longer observed anywhere
      () => {
        if (!storeClass.keepAlive) {
          currentInstance.destroy()
          currentInstance = null
        }
      }
    )
  }
}


export const StoreProvider = StoreManager.Context.Provider

/**
 * React Hook used to simplify store subscriptions in FunctionComponents
 */
export const useStore = (storeClass) => {
  const storeMgrContext = useContext(StoreManager.Context)
  return storeMgrContext.stores.get(storeClass).current()
}

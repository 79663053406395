import React, { FunctionComponent } from 'react'

export interface IconHeaderProps {
  icon?: string
  tooltip?: string,
  className?: string
}

export const IconHeader: FunctionComponent<IconHeaderProps> = props => {
  return (
    <span
      className={`device_attr_icon_header icon icon-${props.icon}${props.className ? ' ' + props.className : ''}`}
      data-tooltip={props.tooltip}
    />
  )
}

IconHeader.displayName = "IconHeader"

export const SrcIcon: FunctionComponent<IconHeaderProps> = () => <IconHeader
  icon={`compass`}
  tooltip={`Source that provided this Attribute value`}
/>

IconHeader.displayName = "SrcIcon"

export const ExpiresIcon: FunctionComponent<IconHeaderProps> = () => <IconHeader
  icon={`clock2`}
  tooltip={`Expiration information for each Attribute value.`}
/>

IconHeader.displayName = "ExpiresIcon"

export const HvtIcon: FunctionComponent<IconHeaderProps> = props => <IconHeader
  icon={`flag`}
  className={props.className}
  tooltip={props.tooltip || `High Value Target`}
/>

IconHeader.displayName = "HvtIcon"
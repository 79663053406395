import React from 'react'
import T from 'prop-types'
import DataSourceTag from 'components/DataSourceTag'


const SOURCES = {
  'NarkCrowdstrike': {
    name: 'CrowdStrike',
    abbr: 'CS'
  },
  'Protectwise': {
    name: 'Verizon',
    abbr: 'VZ'
  },
  'ProtectwiseAnalytics': {
    name: 'Verizon Analytics',
    abbr: 'VZA'
  },
  'CarbonBlack': {
    name: 'Carbon Black Response',
    abbr: 'CBR'
  },
  'NarkCarbonblackDefense': {
    name: 'Carbon Black Defense',
    abbr: 'CBD'
  },
  'CustomerBulk': {
    name: 'Upload',
    abbr: 'UPL'
  },
  'AWS': {
    name: 'AWS',
    abbr: 'AWS'
  }
}

export default function IntelSourceTag(props) {
  const srcInfo = SOURCES[props.source]
  return srcInfo ? (
    <DataSourceTag tooltip={`Data via ${srcInfo.name}`}>
      { srcInfo.abbr }
    </DataSourceTag>
  ) : null
}

IntelSourceTag.propTypes = {
  source: T.string.isRequired
}


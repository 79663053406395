import {
  observable,
  computed
} from 'mobx'
import { RouteStore } from 'stores/mobx/data/global/RouteStore'
import { Store } from 'stores/mobx/StoreManager'
import {
  CUSTOMER_ADMIN_ROOT_PATH,
  CUSTOMER_ADMIN_VIEWS,
  DEFAULT_CUSTOMER_ADMIN_VIEW,
} from 'pwConstants'

export enum CustomerAdminViews {
  Customers = "customers",
  Notifications = "notifications"
}

export default class CustomerAdminViewStore extends Store {
  static dependencies = [RouteStore]

  routeStore: RouteStore
  @observable subViews = CUSTOMER_ADMIN_VIEWS

  constructor(deps) {
    super(deps)
    const [routeStore] = deps

    this.routeStore = routeStore
  }

  @computed
  get activeView () {
    const {pathArr} = this.routeStore.currentRoute
    return !pathArr.length || pathArr[0] !== CUSTOMER_ADMIN_ROOT_PATH ? null : pathArr[1] || DEFAULT_CUSTOMER_ADMIN_VIEW
  }
}

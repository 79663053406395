import _ from 'lodash'
import { rgb } from 'd3-color'
import { interpolateRgb } from 'd3-interpolate'
import { select as d3Select } from 'd3-selection'
import { scaleLinear } from 'd3-scale'
import { symbol, symbolTriangle } from 'd3-shape'
import T from 'prop-types'
import React from 'react'
import ReactDOM from 'react-dom'
import GraphSubscriberManager from './GraphSubscriberManager'

const h = 50
const w = 300
const maxValue = 100
const barHeight = 20
const HIGHLIGHT_WIDTH = 50

const bgGradientId = 'threatmeter_bggradient'
const gradientId = 'threatmeter_gradient'

const paddingSides = 15
const padding = {
  top: 0,
  right: paddingSides,
  bottom: 0,
  left: paddingSides
}

const lowColor = rgb('#3dabdc')
const mediumColor = rgb('#FFB625')
const highColor = rgb('#F80213')

const colorStops = [
  { stop: 0, color: lowColor },
  //{stop: .25, color: lowColor},
  //{stop: .40, color: mediumColor},
  { stop: 0.5, color: mediumColor },
  //{stop: .80, color: highColor},
  { stop: 1.0, color: highColor }
]

const scale = scaleLinear().domain([0, maxValue]).rangeRound([0, w]).clamp(true)

function createGradientColorScales(_colorStops, _maxValue) {
  let scales = []
  for (let i = 0; i < _colorStops.length - 1; i++) {
    let lowStop = _colorStops[i]
    let highStop = _colorStops[i + 1]
    scales.push(
      scaleLinear()
        .domain([lowStop.stop * _maxValue, highStop.stop * _maxValue])
        .interpolate(interpolateRgb)
        .range([lowStop.color, highStop.color])
    )
  }
  return scales
}

function getGradColor(colorScales, val) {
  let _scale = _.find(
    colorScales,
    colorScale => val >= colorScale.domain()[0] && val <= colorScale.domain()[1]
  )
  return _scale ? _scale(val) : null
}

const gradientColors = createGradientColorScales(colorStops, maxValue)
const findColor = _.partial(getGradColor, gradientColors)

function createGraphData(threatLevel) {
  return {
    transX: scale(threatLevel),
    fill: findColor(threatLevel)
  }
}

const bgDarkenFactor = 2.5

const bgColorStops = colorStops.map(colorStop => ({
  stop: colorStop.stop,
  color: colorStop.color.darker(bgDarkenFactor)
}))

function appendGradient(id, _colorStops) {
  if (d3Select(`#${id}`).empty()) {
    let defs = d3Select('#global_defs')
    let gradient = defs.append('svg:linearGradient').attrs({
      id: id,
      x1: '0%',
      y1: '0%',
      x2: '100%',
      y2: '0%'
    })
    _colorStops.forEach(colorStop => {
      gradient.append('svg:stop').attrs({
        offset: colorStop.stop,
        'stop-color': colorStop.color
      })
    })
    return gradient
  }
}

export default class extends React.Component {
  static displayName = 'ThreatMeter'

  static propTypes = {
    enableTransitions: T.bool,
    enableLiveData: T.bool,
    showSubviewLabel: T.bool,

    liveDataGroupName: T.string.isRequired,
    liveDataGraphId: T.string.isRequired,
    liveDataOptions: T.shape({
      dataOptions: T.object.isRequired,
      drawOptions: T.object.isRequired
    })
  }

  static defaultProps = {
    enableTransitions: true,
    enableLiveData: true,
    showSubviewLabel: true
  }

  componentDidMount() {
    this.drawInitialSvg()
  }

  componentWillUnmount() {
    d3Select(ReactDOM.findDOMNode(this.refs.svgCt)).selectAll('*').remove()
  }

  drawInitialSvg = () => {
    appendGradient(bgGradientId, bgColorStops)
    appendGradient(gradientId, colorStops) //TODO only need to append one gradient for these
    this.svgElement = d3Select(ReactDOM.findDOMNode(this.refs.svgCt)) //.append('svg')

    this.svgElement.attrs({
      height: '100%',
      width: '100%',
      viewBox: `0 0 ${w + padding.left + padding.right} ${h +
        padding.top +
        padding.bottom}`,
      preserveAspectRatio: 'none'
    })

    let svg = this.svgElement.append('g').attrs({
      transform: `translate(${padding.left}, ${padding.top})`
    })

    let defs = svg.append('defs')

    svg.append('rect').attrs({
      x: 0,
      y: h - barHeight,
      width: w,
      height: barHeight,
      fill: `url(#${bgGradientId})`
    })

    let clipId = `threatmeter_clip_agent_${this.props.liveDataGraphId}`
    this.clipRect = defs
      .append('clipPath')
      .attr('id', clipId)
      .append('rect')
      .attrs({
        width: HIGHLIGHT_WIDTH,
        height: barHeight,
        y: h - barHeight,
        x: 0,
        transform: `translate(-${HIGHLIGHT_WIDTH / 2}, 0)`
      })

    let clipGroup = svg.append('g').attrs({
      'clip-path': `url(#${clipId})`
    })

    clipGroup.append('rect').attrs({
      x: 0,
      y: h - barHeight,
      width: w,
      height: barHeight,
      fill: `url(#${gradientId})`
    })

    this.trianglePath = svg.append('path').attrs({
      class: 'threatmeter_triangle',
      d: symbol().type(symbolTriangle),
      transform: `translate(0, ${barHeight - 20}) scale(2, 4) rotate(180)`,
      fill: lowColor
    })
  }

  _onDataUpdate = graphUpdate => {
    let value =
      graphUpdate[this.props.liveDataOptions.dataOptions.counterTypes[0]] || 0
    // console.log 'DATA UPDATE', graphUpdate,  value
    let graphData = createGraphData(value)
    let transX = graphData.transX
    if (transX !== this._lastTransX) {
      this._lastTransX = transX
      if (this.props.enableTransitions) {
        this.trianglePath.transition().duration(1000).attrs({
          transform: `translate(${transX}, ${barHeight -
            2}) scale(2, 3) rotate(180)`,
          fill: graphData.fill
        })
        this.clipRect.transition().duration(1000).attrs({
          transform: `translate(${transX - HIGHLIGHT_WIDTH / 2}, 0)`
        })
      } else {
        this.trianglePath.attrs({
          transform: `translate(${transX}, ${barHeight -
            2}) scale(2, 3) rotate(180)`,
          fill: graphData.fill
        })
        this.clipRect.attrs({
          transform: `translate(${transX - HIGHLIGHT_WIDTH / 2}, 0)`
        })
      }
    }
  }

  render() {
    return (
      <div className="threatMeter_agent_subview agent_subview">
        {
          this.props.enableLiveData
          ? (
            <GraphSubscriberManager
              onUpdate={this._onDataUpdate}
              liveDataGroupName={this.props.liveDataGroupName}
              liveDataGraphId={this.props.liveDataGraphId}
              liveDataOptions={this.props.liveDataOptions}
            />
          ) : null
        }
        <div>
          <div className="agent_subview_left">
            <div className="agent_data_view">
              <svg ref="svgCt" />
            </div>
            {
              this.props.showSubviewLabel
                ? <div className="agent_subview_label">THREAT METER</div>
                : null
            }
          </div>
        </div>
      </div>
    )
  }
}

import React, { useState } from 'react'
import { ScrollBars } from 'react-base'
import { Notification } from 'stores/notifications/Notification'
import { getTraceUrl } from 'utils/restUtils'
import ExternalLink from 'components/ExternalLink'
import { observer } from 'mobx-react'

interface NotificationCardProps {
  notification: Notification,
  renderInternalLink: boolean,
  onDismiss: (id: number|string) => void
}

export const NotificationCard = observer((props: NotificationCardProps) => {
  const { notification, renderInternalLink } = props
  const { type, traceId, messageDetails, heading, dismissTimer } = notification
  const message = notification.messageComponent || notification.message
  const extraPadding = !!traceId && !messageDetails
  const link = renderInternalLink
    ? getTraceUrl(traceId)
    : false
  const dismissFn = props.onDismiss.bind(null, notification.id)

  let [expanded, setExpanded] = useState(false)

  return (
    <div className={`notification type_${type} ${extraPadding ? 'extra_padding' : ''}`}>
      <div>
        <h6>{heading}</h6>
        {message ? <p>{message} </p> : null}
        {traceId ?
          <div className="trace_id_wrap">
            {link ?
              <ExternalLink className="trace_id_link" href={link}>
                {traceId}
              </ExternalLink>
              : `${traceId}`
            }
          </div>
          : null
        }
        {messageDetails ?
          <ScrollBars className="more_details_wrap pw_scrollbars_light" horizontal={false} slimShady>
            <div className={`more_details ${expanded ? 'expanded' : 'collapsed'}`}>
              {
                Array.isArray(messageDetails)
                  ? messageDetails.map((m, i) => <div key={i}>{m}</div>)
                  : messageDetails
              }
            </div>
            <span  className="btn btn-mini btn-link" onClick={() => setExpanded(!expanded) }>
              {expanded ? 'hide' : 'more'} details
            </span>
          </ScrollBars>
          : null
        }
        <div
          className={`notification_timer ${dismissTimer ? 'active' : '' }`}
          style={{ transitionDuration: `${dismissTimer - 750}ms` }}
        />
      </div>
      <i
        className="btn btn-icon icon icon-close"
        onClick={dismissFn}
      />
    </div>
  )
})

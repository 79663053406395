import React, { FunctionComponent } from 'react'
import {
  DropdownMenu,
  MenuItem
} from 'react-base'
import {
  observer
} from 'mobx-react'
import ExternalLink from '../../ExternalLink'
import { SchemerModel } from 'pw-schemer-mobx/scripts'
import {Actions, FeedbackLinks, getFeedbackClickHandler} from '../../feedback/NewFeatureBanner'

export interface DeviceRowActionsProps {
  row: SchemerModel
}

export const DeviceRowActions: FunctionComponent<DeviceRowActionsProps> = observer(props => {
  return (
    <span className={`device_row_actions`}>
      <DropdownMenu
        buttonClasses="device_row_dot_menu dropdown-toggle btn-icon btn icon-menu-dots"
        closeOnItemClick
        label={ '' }
        omitCaret
        tooltip="Device Actions..."
      >
        <MenuItem
          key={'flag'}
          href={FeedbackLinks[Actions.IncorrectData]}
          target={"_blank"}
          ignoreOnClick
        >
          <span onClick={getFeedbackClickHandler(Actions.IncorrectData)}>
            <span className={`icon icon-flag`} /> Report Incorrect Data <span className={`icon icon-arrow-up-right`} />
          </span>
        </MenuItem>
      </DropdownMenu>
    </span>
  )
})

DeviceRowActions.displayName = "DeviceRowActions"

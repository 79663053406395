import _ from 'lodash'
import React from 'react'
import T from 'prop-types'
import {graphSubscriber} from 'data/DataManager'

class GraphSubscriberManager extends React.Component {
  static displayName = 'MisterManager'

  static propTypes = {
    onUpdate: T.func.isRequired,

    updateInterval: T.number,
    useTransitionTimeForUpdate: T.bool,

    liveDataGroupName: T.string.isRequired,
    liveDataGraphId: T.string.isRequired,
    liveDataOptions: T.shape({
      dataOptions: T.object.isRequired,
      drawOptions: T.object.isRequired
    })
  }

  static defaultProps = {
    updateInterval: 1000,
    useTransitionTimeForUpdate: false
  }

  UNSAFE_componentWillMount() {
    this._subscribeToUpdates()
    this._triggerUpdateRequest()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { liveDataGraphId } = this.props
    if (
      !_.isEqual(
        nextProps.liveDataOptions.dataOptions,
        this.props.liveDataOptions.dataOptions
      )
    ) {
      graphSubscriber.changeGraphDataOptions(
        liveDataGraphId,
        nextProps.liveDataOptions.dataOptions
      )
    }
  }

  componentWillUnmount() {
    const { liveDataGraphId } = this.props

    this._isUnmounting = true
    clearTimeout(this._timer)
    cancelAnimationFrame(this._updateRaf)
    this._tryUnplugStream()
    graphSubscriber.unsubscribe(liveDataGraphId)
  }

  _subscribeToUpdates = overrideDataOptions => {
    const {
      liveDataOptions,
      liveDataGraphId,
      liveDataGroupName
    } = this.props

    let _dataOptions = overrideDataOptions || liveDataOptions
    this.dataStream = graphSubscriber.subscribe(
      _dataOptions,
      liveDataGraphId,
      liveDataGroupName
    )
    this._tryUnplugStream()
    this._updateTimerPending = false

    this.unplugDataStream = this.dataStream.onValue(this._onDataStreamValue)
  }

  _pauseUpdates = () => {
    this._tryUnplugStream()
  }

  _resumeUpdates = () => {
    if (this.unplugDataStream) {
      this.unplugDataStream()
    }
    this.unplugDataStream = this.dataStream.onValue(this._onDataStreamValue)
    this._triggerUpdateRequest()
  }

  _tryUnplugStream = () => {
    if (this.unplugDataStream) {
      this.unplugDataStream()
    }
  }

  _onDataStreamValue = data => {
    const { onUpdate, useTransitionTimeForUpdate, updateInterval } = this.props

    onUpdate(data) //Call the parent's data receiver method
    if (
      useTransitionTimeForUpdate &&
      data.transitionDuration &&
      data.transitionDuration > 100
    ) {
      this._requestUpdate(data.transitionDuration)
    } else if (updateInterval !== 0 && !this._updateTimerPending) {
      this._requestUpdate()
    }
  }

  _triggerUpdateRequest = () => {
    const { liveDataGraphId } = this.props
    if (!this._isUnmounting) {
      this._updateTimerPending = false
      graphSubscriber.requestUpdate(liveDataGraphId)
    }
  }

  _requestUpdate = overrideInterval => {
    const { updateInterval } = this.props
    this._updateTimerPending = true
    clearTimeout(this._timer)
    cancelAnimationFrame(this._updateRaf)
    this._timer = setTimeout(() => {
      this._updateRaf = requestAnimationFrame(this._triggerUpdateRequest)
    }, overrideInterval || updateInterval)
  }

  render() {
    return null
  }
}

export default GraphSubscriberManager

import _ from 'lodash'
import genericUtil from 'ui-base/src/util/genericUtil'

const DEFAULT_MAX_ENTRIES = 100
const DEFAULT_TTL = genericUtil.durations(3, "minutes")

export default class TTLCache {
  constructor(options) {
    this._ttl = options.ttl || DEFAULT_TTL
    this._maxEntries = options.maxEntries || DEFAULT_MAX_ENTRIES
    this._cache = {}
  }

  _cleanCache() {
    let keys = _.keys(this._cache)
    // evict by expiration time
    keys.forEach(key => {
      if (this._cache[key].timestamp + this._cache[key].ttl < Date.now()) {
        // console.log(`Deleting by TTL: ${key}`)
        delete this._cache[key]
      }
    })
    // evict by oldest over the max entries limit
    keys = _.keys(this._cache)
    if (keys.length > this._maxEntries) {
      // console.log(`Deleting ${keys.length - this._maxEntries} entries by count`)
      keys = _.sortBy(keys, key => this._cache[key].timestamp)
      while (keys.length > this._maxEntries) {
        delete this._cache[keys.shift()]
      }
    }
    if (keys.length) {
      clearTimeout(this._cacheLoopTimer)
      // TODO bind to requestAnimationFrame?  OK that this timer runs while tab not focused?
      this._cacheLoopTimer = setTimeout(this._cleanCache.bind(this), 10000)
    }
  }

  set(cacheKey, value, ttlOverride) {
    this._cache[cacheKey] = {
      ttl: ttlOverride || this._ttl,
      timestamp: Date.now(),
      data: value
    }
    this._cleanCache()
    return value
  }

  get(cacheKey) {
    return this._cache[cacheKey] ? this._cache[cacheKey].data : undefined
  }

  delete(cacheKey) {
    delete this._cache[cacheKey]
  }

  clear() {
    this._cache = {}
  }
}

// Netflow utilities

import _ from 'lodash'
import Protocols from 'data/Protocols'
import { formatDurationHuman, formatBytes } from 'pw-formatters'
import { formatTime } from 'utils/timeUtils'

const _formatNetflow = netflow => {
  if (!netflow) return null

  const hasEndTime = netflow.details.endTime && netflow.details.startTime
  const dur = formatDurationHuman(
    netflow.details.endTime - netflow.details.startTime
  )

  return _.assign(netflow, {
    // key: netflow.key,
    startTime: netflow.details.startTime
      ? formatTime(netflow.details.startTime)
      : '-',
    endTime: netflow.details.endTime
      ? formatTime(netflow.details.endTime)
      : 'Pending',
    duration: hasEndTime && dur ? `${dur.textData} ${dur.label}` : '-',
    bytesSrc: formatBytes(netflow.stats.bytesSrc),
    bytesDst: formatBytes(netflow.stats.bytesDst),
    // if netflow has no endtime, we can't trust the stats that say there are 0 bytes. Always attempt to download pcap from server when endTime not defined
    hazPcapDownload: !netflow.details.endTime ||
      netflow.missingRecord || // missing netflow records will also have 0 bytes
      netflow.stats.bytesDstIncluded + netflow.stats.bytesSrcIncluded > 0,
    firstAppProtocolId: _.get(netflow, 'details.applicationProtocols[0].knownProtocol')
  })
}

const MIN_SINGLE_SIDED_THRESHOLD = 300
const isOneSidedFlow = netflow => {
  // Blame James if this is wrong
  const { bytesSrc, bytesDst } = netflow.stats
  return (
    ((bytesSrc === 0 && bytesDst > MIN_SINGLE_SIDED_THRESHOLD) ||
      (bytesDst === 0 && bytesSrc > MIN_SINGLE_SIDED_THRESHOLD)) &&
    netflow.id.proto === 'TCP' &&
    netflow.endTime !== null
  )
}

const _formatFilledGeoObj = (latLongStr) => {
  let latLon = latLongStr || ''
  let [ lat, lon ] = latLon.split(',')
  if (!lat || !lon) {
    return null
  }
  return { lat, lon, countryIsoCode: null, cityId: null }
}

// export functions
export default {
  formatList(rawNetflow) {
    return _.each(rawNetflow, _.partial(_formatNetflow))
  },

  formatSingle(rawNetflow) {
    // util.flattenObject(_formatNetflow(rawNetflow))
    return _formatNetflow(rawNetflow)
  },

  isOneSidedFlow: isOneSidedFlow
}


export const convertNetflowsV2toV1 = (netflows=[]) => {
  return _.map(netflows, netflow => {
    const srcGeo = _formatFilledGeoObj(netflow.details.srcGeo)
    const dstGeo = _formatFilledGeoObj(netflow.details.dstGeo)

    _.assign(netflow,
      {
        key: netflow.id, // Important! v1 was key, v2 is id.
        id: _.assign(netflow.ntuple, {
          proto: _.get(netflow, `ntuple.layer4Protocol`, "").toUpperCase()
        }),
        sensorId: netflow.agentId,
        details: _.assign(netflow.details, {
          endTime: netflow.details.endTime ? +new Date(netflow.details.endTime) : netflow.details.endTime,
          srcGeo: srcGeo,
          dstGeo: dstGeo,
          startTime: +new Date(netflow.startTime),
          applicationProtocols: _.map(netflow.details.protocols || [], protoId => {
            return {
              "knownProtocol": typeof p === "string" ? Protocols.getProtocolIdFromName(protoId) : protoId,
            }
          })
        }),
        stats: _.assign(netflow.stats, {
          bytesDst: netflow.stats.dstBytes,
          bytesDstIncluded: netflow.stats.dstBytesTx,
          bytesSrc: netflow.stats.srcBytes,
          bytesSrcIncluded: netflow.stats.srcBytesTx,
          packetsDst: netflow.stats.dstPackets,
          packetsDstIncluded: netflow.stats.dstPacketsTx,
          packetsSrc: netflow.stats.srcPackets,
          packetsSrcIncluded: netflow.stats.srcPacketsTx
        })
      }
    )
    delete netflow.ntuple
    return netflow
  })
}

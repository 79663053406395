import Reflux from 'reflux'

export default Reflux.createActions([
  'loadActiveNotifications',
  'loadAllNotifications',
  'setShowDeleted',

  'createNotification',
  'updateNotification',
  'deleteNotification',

  'dismissNotification',
])

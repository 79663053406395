import _ from 'lodash'
import Reflux from 'reflux'
import UserAccountActions from 'actions/UserAccountActions'
import CommonViewActions from 'actions/CommonViewActions'
import { requestDelete, requestGet, requestPost } from 'utils/restUtils'

const SETTINGS_UPDATED_DISMISS_TIME = 10000

const _state = {
  error: null,
  accessToken: null,
  //MFA
  multifactorEnabled: false,
  confirmMFA: null,
  isSubmitting: false
}

export default Reflux.createStore({
  listenables: UserAccountActions,

  _notify() {
    this.trigger(_state)
  },

  //Mulitfactor Auth
  onCheckMFA() {
    requestGet('mfa_check', 'mfa')
      .then(data => {
        _state.multifactorEnabled = data.enabled
        this._notify()
      })
      .catch(this._handleSettingsError.bind(this))
  },

  cancelMFA() {
    _state.error = null
    _state.confirmMFA = null
    this._notify()
  },

  onEnableMFA(token, password) {
    _state.error = null
    _state.isSubmitting = true
    const _postData = {}
    if (token != null && password != null) {
      _postData.token = token //step 2, Confirm secret
      _postData.password = password
    }
    requestPost('mfa_enable', 'mfa', _postData)
      .then(data => {
        _state.isSubmitting = false
        if (data.enabled) {
          //Step 2 complete
          _state.multifactorEnabled = true
          _state.confirmMFA = null
          this._settingsUpdated('Multi-factor Authentication Activated')
        } else {
          _state.confirmMFA = data
        }
        this._notify()
      })
      .catch(this._handleSettingsError.bind(this))
  },

  onDisableMFA() {
    _state.error = null
    _state.confirmMFA = null
    requestDelete('mfa_disable', 'mfa')
      .then(() => {
        _state.multifactorEnabled = false
        this._settingsUpdated('Multi-factor Authentication Disabled')
        this._notify()
      })
      .catch(this._handleSettingsError.bind(this))
  },

  onGenerateAccessToken(password) {
    _state.error = null
    _state.accessToken = null
    requestPost('generate_access_token', 'token/generate', { password })
      .then(response => {
        _state.isSubmitting = false
        _state.accessToken = response.token
        this._notify()
      })
      .catch(error => {
        _state.error = error
        this._handleSettingsError()
      })
  },

  _handleSettingsError(/* restError */) {
    _state.isSubmitting = false
    this._notify()
  },

  _settingsUpdated(msg) {
    CommonViewActions.Notification.add({
      type: 'success',
      heading: 'Settings saved',
      message: msg,
      dismissTimer: SETTINGS_UPDATED_DISMISS_TIME
    })
  }
})

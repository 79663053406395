import _ from 'lodash'
import React from 'react'
import {LoadingValue, TextOverflow} from 'react-base'
import ThreatLevel from './ThreatLevel'
import SearchableValue from 'components/values/SearchableValue'
import { VALUE_TYPE_OPTIONS } from 'constants/searchableValueConstants'
import { pluralize } from 'pw-formatters'

const QUERY_BACK = 30 * 24 * 60 * 60 * 1000

export default props => {
  const {queryValue, data} = props
  let fileNames = data('observations.facets.fields.extractedName')
  const sortedFileNames = fileNames.isReady()
    ? _(fileNames.valueOf() || {}).keys().sortBy(name => -fileNames[name]).value()
    : null

  return <React.Fragment>
    <h4>
      <TextOverflow>
        <SearchableValue
          query={{
            family: 'observations',
            clauses: [
              {
                name: 'occurredAt',
                op: 'between',
                from: Date.now() - QUERY_BACK,
                to: Date.now()
              },
              { name: 'fileHash', op: 'eq', value: queryValue }
            ]
          }}
          value={ _.first(sortedFileNames) }
          valueType={VALUE_TYPE_OPTIONS.FILEHASH}
          relatedData={ data('info.hashes').valueOf() }
          menuClassName="light"
          menuAlign="bottom"
        >
          {queryValue}
        </SearchableValue>
      </TextOverflow>
    </h4>

    <h5>
      {fileNames.isLoading()
        ? <span>File name: <LoadingValue /></span>
        : _.isEmpty(fileNames.valueOf())
        ? 'No known file name'
        : <TextOverflow>
          {pluralize(_.size(sortedFileNames), 'File name')}:{' '}
          {sortedFileNames.reduce((out, name, i) => {
            if (i > 0) out.push(', ')
            out.push(
              <SearchableValue
                key={i}
                query={{
                  family: 'observations',
                  clauses: [
                    {
                      name: 'occurredAt',
                      op: 'between',
                      from: Date.now() - QUERY_BACK,
                      to: Date.now()
                    },
                    {
                      name: 'fileExtractedName',
                      op: 'eq',
                      value: name
                    }
                  ]
                }}
              >
                {name}
              </SearchableValue>
            )
            return out
          }, [])}
        </TextOverflow>
      }
    </h5>

    <ThreatLevel data={data} />
  </React.Fragment>
}

import React from 'react'
import Reflux from 'reflux'
import CommonViewActions from 'actions/CommonViewActions'
import {version, customerAgreementVersion} from 'version'
import getConfig from 'utils/uiConfig'
import {requestGet} from 'utils/restUtils'

// omit branch name from UI-displayed version numbers
// assuming branch name is the string contents up to the first hyphen
const VERSION_POLL_INTERVAL = 60000

let _state = {
  currentVersion: version,
  currentVersionDisplay: version.replace(/production-/gi, ""),
  newVersionAvailable: false,
  customerAgreementVersion
}


const _reloadApp = () => {
  window.location.reload()
}

export default Reflux.createStore({

  init() {
    this._hasNotifiedOfNewVersion = false

    this.queueVersionPoll() // Initial check
  },

  getInitialState() {
    return _state
  },

  _notify() {
    this.trigger(_state)
    this._updateNotification()
  },

  queueVersionPoll() {
    clearTimeout(this._timer)
    cancelAnimationFrame(this._updateRaf)
    this._timer = setTimeout(() => {
      this._updateRaf = requestAnimationFrame(this.checkVersion.bind(this))
    }, VERSION_POLL_INTERVAL)
  },

  checkVersion() {
    requestGet('ver_check', `version.json?t=${ Date.now() }`, { baseURL: '' })
    .then((data) => {
      // Only how the reload notification once to avoid spamming the user
      if (data.version !== _state.currentVersion) {
        _state.newVersionAvailable = true
        this._notify()
      }
    })
    .catch(() => {}) //silent
    this.queueVersionPoll()
  },

  _updateNotification() {
    const {newVersionAvailable} = _state
    if (!!newVersionAvailable && !this._hasNotifiedOfNewVersion) {
      const uiConfig = getConfig()
      this._hasNotifiedOfNewVersion = true // Only notify once
      CommonViewActions.Notification.add({
        type: 'info',
        heading: 'New version available',
        messageComponent: (
          <span className="version_update_notification">
            The { uiConfig.productName } Visualizer has been updated, please reload to get the newest version.<br />
            <span
              className="btn btn-xs"
              onClick={ _reloadApp }
            >
              reload now
            </span>
          </span>
        )
      })
    }
  }
})

// import 'whatwg-fetch' //polyfill fetch() and Request/Response/Header classes into global scope
import _ from 'lodash'
import {readCSRFCookie, isXHRPatchedForCSRF, CSRF_HEADER} from 'ui-base/src/util/csrfUtil'
import getConfig from 'utils/uiConfig'


const DEFAULT_BASE_URL = '/api/v1/'

const TRACEID_HEADER = 'X-B3-TraceId'

const REQUESTKEY_HEADER = 'X-PW-UIRequestKey'

/**
 * @typedef {Object} RestUtilsOptions - Supported configuration properties for requests.
 *
 * @property {string} baseURL - A prefix to add to each request's URL. Defaults to `/api/v1/`
 *           but can be overridden per request.
 * @property {string} dataType - The expected type of the response body data. Defaults to `'json'`
 *           which triggers automatic JSON parsing; other supported types are: `'text'`
 * @property {boolean} retry429 - Whether `429 Rate Limit` responses should be automatically
 *           retried after waiting an amount of time specified in the response's `Retry-After`
 *           header. Defaults to `false`.
 * @property {boolean} redirect401 - Whether `401 Unauthorized` responses should trigger
 *           automatic logout and redirect to the login screen. Defaults to `true`.
 * @property {boolean} rejectOnAbort - Whether a manual abort to a request should trigger that
 *           request's promise to reject. Defaults to `false` so that the promise is left in pending
 *           state and therefore `catch` handlers do not have to all explicitly ignore aborts.
 * @property {string} requestBodyType - The content type of the request's body. Defaults to
 *           `application/json` which tells the server it should try parsing it as JSON.
 * @property {boolean} resolveWithParsedBody - Whether the response's body should be automatically
 *           parsed according to the `dataType` option and used as the promise's resolution value.
 *           If set to `false`, the promise will resolve with the full `Response` object instead.
 * @property {boolean} useSocket - Whether the REST request should be made using a `SocketRestClient`
 *           instead of XHR/fetch. That client must have been injected via `registerSocketRestClient`.
 * @property {object} extraHeaders - Key: Value pairs of additional headers to set on the request.
 */
const DEFAULT_OPTIONS = {
  retry429: false,
  redirect401: true,
  useSocket: false,
  useFetch: true,
  baseURL: DEFAULT_BASE_URL,
  dataType: 'json',
  requestBodyType: 'application/json',
  resolveWithParsedBody: true,
  rejectOnAbort: false,
  extraHeaders: {}
}


// Mappings of `options.dataType` values to appropriate 'Accept' headers
const dataTypeAccepts = {
  text: 'text/plain, */*;q=0.01',
  json: 'application/json, text/javascript, */*;q=0.01'
}

function isNullBodyStatus(code) {
  return code === 204 || code === 205 || code === 304
}


// Injected at runtime via registerSocketRestClient
let socketRestClient = null


// Container for AbortController-esque objects for active keyed requests
const activeAbortControllers = new Map()

// Injected global response handlers
const globalPromiseHandlers = []

// Some things fail within workers
const isWorker = !self.document

export function getTraceUrl(traceId) {
  const url = _.get(getConfig(), 'urls.zipkin')
  return url ? url.replace('[TRACEID]', traceId) : ''
}

function getTraceIdLogMessage(requestKey, url, requestInit, response) {
  let traceMsg = null
  const traceId = response.headers.get(TRACEID_HEADER)
  if (traceId) {
    traceMsg = `${requestInit.method} ${url} (${response.statusText}) [requestKey:${requestKey || 'null'}] -> Trace ID: ${traceId}`
    if (self._pw && self._pw.isSupportCustomer) { //TODO can we get this without accessing the global?
      traceMsg += ` [${getTraceUrl(traceId)}]`
    }
  }
  return traceMsg
}



/**
 * Custom Error subclass for REST failures. All .catch() handlers will be passed an instance
 * of this error type with a populated `response` object, regardless of whether the source
 * of the failure is a non-OK response or a true network or runtime error.
 *
 * Note: Error subclassing pattern that creates stacktract at point of instantiation taken from:
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error
 *
 * @param {Response} response
 * @param {String} responseText
 */
export function RestError(response, responseText) {
  const instance = new Error() //stacktrace will be initialized here
  Object.setPrototypeOf(instance, Object.getPrototypeOf(this))
  instance.name = 'RestError'
  instance._initProps(response, responseText)
  return instance
}
RestError.prototype = Object.create(Error.prototype, {
  constructor: {
    value: Error,
    enumerable: false,
    writable: true,
    configurable: true
  }
})
Object.setPrototypeOf(RestError, Error)

RestError.prototype._initProps = function(response, responseText) {
  const isAbort = response.error && response.error.name === 'AbortError'
  const statusCode = response.status

  // Build user-facing message properties
  let heading, body, additional, traceId, type, message
  if (responseText) {
    // Basics
    heading = `${response.status} Error`
    body = response.statusText
    type = response.status >= 500 ? 'error' : 'warning'

    // Try parsing the response body for error details
    let responseObj
    try {
      responseObj = JSON.parse(responseText)
    } catch (e) {
      // continue...
    }
    if (responseObj && responseObj.error) {
      body = responseObj.error.message || response.statusText
      const info = responseObj.error.info || []
      additional = _.isArray(info) ? info : [info]
    }

    // Stringified message
    message = `${heading}: ${body}`
    if (additional) {
      message += ` ${additional.join(', ')}`
    }

    // Trace ID
    traceId = response.headers.get(TRACEID_HEADER) || null
    if (traceId) {
      message += ` (Req ID: ${traceId})`
    }
  }
  else if (isAbort) {
    heading = 'Abort'
    body = message = 'The request was aborted.'
    type = 'warning'
  }
  else {
    // Fallback for http connection errors or runtime errors
    heading = 'Unknown error'
    body = 'Please check your connection and try again.'
    message = `${heading}: ${body}`
    type = 'error'
  }

  _.assign(this, {response, responseText, isAbort, heading, body, additional, traceId, statusCode, type, message})
}


/**
 * Minimal AbortController impl. Not a complete/compliant implementation, just intended to
 * match the shape closely enough to eventually swap out seamlessly with a native impl
 */
// class PwAbortController {
//   signal = {
//     aborted: false,
//     onabort: () => {}
//   }
//   abort() {
//     const signal = this.signal
//     if (!signal.aborted) {
//       signal.aborted = true
//       signal.onabort()
//     }
//   }
// }



function createNetworkError() {
  return new TypeError('Network error')
}

function createAbortError() {
  let error
  try {
    error = new DOMException('Aborted', 'AbortError')
  } catch (e) { //IE11 throws on DOMException constructor
    error = new Error('Aborted')
    error.name = 'AbortError'
  }
  return error
}

/**
 * Convenience error handler that ensures a rejection value is wrapped in a RestError,
 * and rethrows that RestError. Can be handy in promise chains based off promises from
 * this module, where other handlers or composed promises may result in non-RestError
 * rejections. Example usage:
 *
 *     requestGet(...).then(data => {
 *       return promiseThatMayThrowNonRestError(data)
 *     })
 *     .catch(rethrowAsRestError)
 *     .catch(restError => {
 *       // guaranteed to always be a RestError here
 *     })
 *
 * @param {*} error
 */
export function rethrowAsRestError(error) {
  if (!(error instanceof RestError)) {
    const response = Response.error()
    response.error = error
    error = new RestError(response, null)
  }
  throw error
}



/**
 * Issue a request, using SocketRestClient as the backend. The resulting Promise follows the
 * contracts of the `fetch` API.
 * @param {String} url
 * @param {RequestInit} requestInit
 * @return {Promise<Response, Error>}
 */
function fetchViaSocket(url, requestInit) {
  let socketRequest = {
    method: requestInit.method,
    path: url,
    headers: requestInit.headers,
    body: requestInit._rawBodyData //don't need to encode over the socket
  }
  const socketPromise = socketRestClient(socketRequest)
  if (requestInit.signal) {
    requestInit.signal.onabort = socketPromise.abort
  }

  function wrapSocketResponse(socketResponse) {
    const response = new Response(null, {
      status: +socketResponse.code,
      headers: socketResponse.headers
    })
    // Override the body content accessors to sidestep parsing, since the result is pre-parsed
    response.json = function() {return Promise.resolve(socketResponse.result)}
    response.text = function() {return Promise.resolve(JSON.stringify(socketResponse.result))}
    return response
  }

  function logSocketResponse(socketResponse) {
    console.info(
      `[${socketResponse.requestDuration} ms]${isAbort(socketResponse) ? ' (aborted)' : ''} - Socket ${requestInit.method} "${url}"`,
      {
        request: socketRequest,
        response: socketResponse.result,
        responseCode: socketResponse.code,
        responseHeaders: socketResponse.headers
      }
    )
  }

  function isAbort(socketResponse) {
    return socketResponse.code === 0 && _.get(socketResponse, 'result.error.type') === 'abort'
  }

  // Wrap the SocketRestClient's promise to match our promise contract
  const promise = socketPromise
    .then((socketResponse) => {
      // Log the result
      logSocketResponse(socketResponse)

      // Assemble the result into a standard Response object
      return wrapSocketResponse(socketResponse)
    })
    .catch((socketResponse) => {
      // Log the failure
      logSocketResponse(socketResponse)

      // Aborts should throw an AbortError
      if (isAbort(socketResponse)) {
        throw createAbortError()
      }
      // We can assume anything else will be a fulfilled response that we can resolve
      // the promise with, i.e. no network/runtime errors will get us here
      else {
        return wrapSocketResponse(socketResponse)
      }
    })

  return promise
}


/**
 * Issue a request, using XMLHttpRequest as the backend. The resulting Promise follows the
 * contracts of the `fetch` API.
 * @param {String} url
 * @param {RequestInit} requestInit
 * @return {Promise<Response, Error>}
 */
function fetchViaXHR(url, requestInit) {
  let resolve, reject
  const promise = new Promise((_resolve, _reject) => {
    resolve = _resolve
    reject = _reject
  })

  const xhr = _.assign(
    new XMLHttpRequest(),
    {
      onload() {
        // Raw header parsing stolen from whatwg-fetch
        const rawHeaders = xhr.getAllResponseHeaders() || ''
        const headers = new Headers()
        rawHeaders.split(/\r?\n/).forEach(function(line) {
          const parts = line.split(':')
          const key = parts.shift().trim()
          if (key) {
            headers.append(key, parts.join(':').trim())
          }
        })

        const response = new Response(
          isNullBodyStatus(xhr.status) ? undefined : ('response' in xhr) ? xhr.response : xhr.responseText,
          {
            status: xhr.status,
            statusText: xhr.statusText,
            headers: headers
          }
        )
        resolve(response)
      },

      onerror() {
        reject(xhr.statusText === 'abort' ? createAbortError() : createNetworkError())
      },

      ontimeout() {
        reject(createNetworkError())
      }
    }
  )

  xhr.open(requestInit.method, url, true)

  _.forOwn(requestInit.headers, (value, name) => {
    xhr.setRequestHeader(name, value)
  })

  if (requestInit.signal) {
    requestInit.signal.onabort = xhr.abort.bind(xhr)
  }

  xhr.send(requestInit.body)

  return promise
}

/**
 * Issue a request, using `fetch` as the backend. The resulting Promise follows the
 * contracts of the `fetch` API.
 * TODO - use this in place of `fetchViaXHR` once abortable fetch lands in browsers
 * @param {String} url
 * @param {RequestInit} requestInit
 * @return {Promise<Response, Error>}
 */
function fetchViaFetch(url, requestInit) {
  // Add the CSRF token header, unless using the XHR-backed polyfill which will already have it
  if (!(self.fetch.polyfill && isXHRPatchedForCSRF)) {
    const csrfToken = readCSRFCookie()
    if (csrfToken) {
      requestInit.headers = _.assign({}, requestInit.headers, {[CSRF_HEADER]: csrfToken})
    }
  }
  return self.fetch(url, requestInit)
}


/**
 * Issue a request, using a given fetch backend impl, with automatic silent retrying of
 * rate-limit 429 responses. The resulting Promise follows the contracts of the `fetch` API.
 * @param {String} url
 * @param {RequestInit} requestInit
 * @param {Function} fetchImpl
 */
function fetchWith429Retry(url, requestInit, fetchImpl) {
  return new Promise((resolve, reject) => {
    function tryRequest() {
      fetchImpl(url, requestInit)
        .then(response => {
          // If we got a 429 rate limit error, queue up to retry after the limit period expires
          if (response.status === 429) {
            console.info(`Exceeded API rate limit for ${url} - retrying.`)
            let retryAfter = +response.headers.get('Retry-After')
            if (!isNaN(retryAfter) && retryAfter > 0) {
              let retryTimer = setTimeout(tryRequest, retryAfter * 1000)

              // Aborting during the interim will cancel the timeout
              if (requestInit.signal) {
                requestInit.signal.onabort = () => {
                  clearTimeout(retryTimer)
                  reject(createAbortError())
                }
              }
            }
          } else {
            resolve(response)
          }
        })
        .catch(reject)
    }
    tryRequest()
  })
}


/**
 * Inject a function that will be invoked on creation of every request Promise, allowing
 * external consumers to respond to or manipulate all responses globally.
 *
 * The injected function will be invoked and passed the following:
 *   - {Promise} requestPromise - the initial Promise, prior to body content parsing, that
 *     will either resolve with a Response or reject with a RestError.
 *   - {String} url - the request url
 *   - {RequestInit} requestInit - the request initialization params
 *   - {RestUtilsOptions} options - the options passed to the initial request function, including defaults.
 *
 * The function will typically add .then()/.catch() handlers to the promise, to be handled
 * out-of-band from the main promise chain. It _may_ return a new Promise, e.g. if it needs
 * to manipulate the response or error in some way, but if so then that Promise _must_ adhere
 * to the same contract (resolves with a Response, rejects with a RestError.)
 *
 * @param {Function} fn
 */
function addGlobalRequestHandler(fn) {
  globalPromiseHandlers.push(fn)
}


/**
 * Common entry point for all requests. Handles the shared logic for building Requests
 * and handling Responses, delegates to the appropriate backend `fetch`-like implementations,
 * and converts the backend `fetch` promise contracts to the following Promise contract:
 *
 * - If the response succeeds with an "OK" status (code in the 200s), then the promise will
 *   be resolved with the response body, parsed as appropriate depending on `options.dataType`.
 *   Unless the `resolveWithParsedBody` option is set to `false` in which case it will be
 *   resolved with the full Response object.
 *
 * - If the response succeeds with a non-"OK" status, encounters a network error, or throws
 *   a runtime error when attempting to parse the response body, then the promise will be
 *   rejected with an instance of `RestError`. That RestError's `response` and various messaging
 *   properties will always be populated, making it much simpler to write robust `catch` handlers
 *   without complex type checking logic branches.
 *
 * - If the response is aborted before finishing (due to `abortRequest` being called or a new
 *   request being issued with the same `requestKey`), then the promise will neither be resolved
 *   nor rejected. This is usually the expected behavior, but if you do want to catch aborts you
 *   can set the `rejectOnAbort` option to `true`. The resulting RestError's `isAbort` property
 *   can be checked in the catch handler to treat it specially.
 *
 * @param {String} requestKey - If specified, only allows one request for a given key to be
 *        active at a time; a second request for a given key will abort the first request.
 *        You can also pass this to the `abortRequest` method to manually abort the request.
 * @param {String} url - The url of the request. This will be prepended with the `baseURL` option.
 * @param {String} method - The method of the request ("GET", "POST", etc.)
 * @param {*} data - The data to be sent in the request body. Not used for GET requests.
 * @param {RestUtilsOptions} options - Any custom options for this request to override defaults.
 *
 * @return {Promise<*, RestError>}
 */
export function initiateRequest(requestKey, url, method, data, options) {
  options = _.defaults(options || {}, DEFAULT_OPTIONS)

  // Initialize the Request object
  url = options.baseURL + url
  const bodyString = data && method !== 'GET' ? JSON.stringify(data) : null

  const headers = Object.assign({}, {
    'Accept': dataTypeAccepts[options.dataType] || '*/*',
    'Content-Type': options.requestBodyType,
    [REQUESTKEY_HEADER]: requestKey || 'none'
  }, options.extraHeaders)

  const requestInit = {
    method,
    headers,
    credentials: 'same-origin',
    body: bodyString,
    _rawBodyData: data //for fetchViaSocket
  }

  if (requestKey) {
    // Abort any pending requests with the same requestKey
    abortRequest(requestKey)

    // Create and register a new AbortController, and add its signal to the Request
    // const abortController = new PwAbortController()
    const abortController = new AbortController()
    activeAbortControllers.set(requestKey, abortController)
    requestInit.signal = abortController.signal
  }

  // Issue the request
  const fetchImpl = options.useSocket
    ? fetchViaSocket
    : options.useFetch
      ? fetchViaFetch
      : fetchViaXHR
  let promise = options.retry429 ?
    fetchWith429Retry(url, requestInit, fetchImpl) :
    fetchImpl(url, requestInit)

  // Clean up AbortControllers
  if (requestKey) {
    promise = promise.then(
      response => {
        activeAbortControllers.delete(requestKey)
        return response
      },
      error => {
        activeAbortControllers.delete(requestKey)
        throw error
      }
    )
  }

  // Turn non-OK responses and thrown errors into RestError rejections
  promise = promise.then(
    response => {
      return response.ok || response.created ?
        response :
        response.text()
          .then(responseText => {
            throw new RestError(response, responseText)
          })
          .catch(rethrowAsRestError)
    },
    rethrowAsRestError
  )

  // Swallow aborts unless the consumer explicitly says it wants to handle them
  if (!options.rejectOnAbort) {
    promise = promise.catch(restError => {
      if (restError.isAbort) {
        return new Promise(() => {}) //never resolves
      } else {
        throw restError
      }
    })
  }

  // Let any injected global handlers manipulate the promise
  if (globalPromiseHandlers.length) {
    globalPromiseHandlers.forEach(fn => {
      promise = fn(promise, url, requestInit, options) || promise
    })
  }

  // Force a redirect to login when a 401 Unauthorized is returned
  if (options.redirect401 && !isWorker) {
    promise.catch(restError => { //out-of-band
      if (restError.response.status === 401 && self.location) {
        self.location.href = '/api/v1/logout'
      }
    })
  }

  // Log the traceId
  promise.catch( //out-of-band
    restError => restError.response
  ).then(response => {
    const traceMsg = getTraceIdLogMessage(requestKey, url, requestInit, response)
    if (traceMsg) { console.info(traceMsg) }
  })

  // Parse response body if needed
  if (options.resolveWithParsedBody) {
    promise = promise.then(response => {
      if (isNullBodyStatus(response.status)) {
        return Promise.resolve(null)
      } else {
        return (options.dataType === 'json' ? response.json() : response.text())
          .catch(rethrowAsRestError)
      }
    })
  }

  return promise
}




/* === Exports === */

export {
  TRACEID_HEADER,
  DEFAULT_BASE_URL as baseURL,
  addGlobalRequestHandler
}

/**
 * Inject a SocketRestClient for use with the `useSocket` request option
 * @param {SocketRestClient} client
 */
export function registerSocketRestClient(client) {
  socketRestClient = client
}

/**
 * @see {@link initiateRequest}
 */
export function requestGet(requestKey, url, options) {
  return initiateRequest(requestKey, url, 'GET', null, options)
}

/**
 * @see {@link initiateRequest}
 */
export function requestPost(requestKey, url, data, options) {
  return initiateRequest(requestKey, url, 'POST', data, options)
}

/**
 * @see {@link initiateRequest}
 */
export function requestPut(requestKey, url, data, options) {
  return initiateRequest(requestKey, url, 'PUT', data, options)
}

/**
 * @see {@link initiateRequest}
 */
export function requestPatch(requestKey, url, data, options) {
  return initiateRequest(requestKey, url, 'PATCH', data, options)
}

/**
 * @see {@link initiateRequest}
 */
export function requestDelete(requestKey, url, data, options) {
  return initiateRequest(requestKey, url, 'DELETE', data, options)
}

/**
 * Abort an active request by its requestKey
 * @param {String} requestKey
 */
export function abortRequest(requestKey) {
  if (requestKey && activeAbortControllers.has(requestKey)) {
    activeAbortControllers.get(requestKey).abort()
    activeAbortControllers.delete(requestKey)
  }
}

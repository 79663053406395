import React from 'react'
import SensorHeader from 'components/SensorHeader'
import { getIntelCardHref } from 'components/values/SearchableValue'


function onChangeSensor(sensorId) {
  location.href = getIntelCardHref(`sensor:${sensorId}`)
}


// Mostly delegated to common SensorHeader component
export default props => {
  const sensor = props.data('sensor').valueOf()
  return sensor
    ? <SensorHeader
      largeIcon
      hideCardIcon
      sensorId={+props.queryValue}
      usageId="intelcard"
      onChangeSensor={onChangeSensor}
    />
    : <h4>Sensor Not Found</h4>
}

import { observable, action, computed } from "mobx"
import { computedFn } from "mobx-utils"
import { Store } from 'stores/mobx/StoreManager'
// import * as lodash from 'lodash'

const LS_KEY = 'simple_prefs_store'
const DEBOUNCE_MS = 3000

export class LocalStoragePrefsStore extends Store {
  @observable
  prefs: Map<string, any> = new Map()

  constructor() {
    super()
    const stored: string = localStorage.getItem(LS_KEY)

    try {
      this.prefs = new Map(stored ? JSON.parse(stored) : undefined)
    } catch (error) {
      console.warn(`LocalStoragePrefsStore: Invalid stored preferences`, stored)
      localStorage.removeItem(LS_KEY) // Reset
    }

    // this.destroy = () => void
  }

  destroy

  @action
  private commit () {
    localStorage.setItem(LS_KEY, JSON.stringify([...this.prefs]))
  }

  // @action
  // queueUpdate = lodash.debounce(this.commit, DEBOUNCE_MS)

  @action
  set (prefKey: string, prefValue: any) {
    this.prefs.set(prefKey, prefValue)
    this.commit()
  }

  get = computedFn(function getPrefByKey (prefKey: string) {
    return this.prefs.get(prefKey)
  })

  @action
  remove (prefKey: string) {
    if (this.prefs.has(prefKey)) {
      this.prefs.delete(prefKey)
      this.commit()
    }
  }
}

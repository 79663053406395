import Reflux from 'reflux'

export default Reflux.createActions([
  'init',
  'reset',
  'route', // (queryParams)
  'switchMainTab', // ('overview'|'manage')

  'loadAllIntelTags',

  'loadAllLists',
  'createList', // (listName)
  'toggleListSelected', // (listId) // Add/remove list from selection
  'setSelectedLists', // (listIds) // New selected listIds
  'selectEditableLists', // Select only user-editable lists
  'deleteList', // (listId)
  'updateList', // (listId, listType, {partialListPatch})

  'loadAllSubscriptions',
  'editSubscriptionForSensors', // ([sesnorIds])
  'toggleEditSubscriptionForSensor', // (sensorId) : Add/Remove sensor to currently edited sensors
  'chooseBaseSubscriptionToEdit', // (sensorId) : When editing subscription for multiple sensors, this selects a single sensor subscription to use for all of them
  'saveSubscription', // (orderedSubscriptionList, sensorIds)
  'cancelSubscriptionEdit',
  'filterRulesBySubscription', // (listIds) // Sets list view to only listIds passed, and filters for enabled+published only

  'requestNextRulePage', // (pageOffset)
  'changeRuleSort', // (columnKey, isDesc)
  'toggleRuleSelected', // (ruleUIKey)
  'toggleRulesSelected', // ([selectedRuleUIKeys])
  'selectAllRules',
  'selectNoRules',
  'setSelectedRules',

  'toggleNewRuleInput',
  'showNewRuleInput',
  'cancelNewRuleInput',
  'saveNewRules',  // (rulesArray, listId, (optional)targetMode) // Separated by newlines
  'saveRuleChanges',  // (rulesArray)
  'saveChangesWithNewMode', // (newMode, rule)
  'updateRulesPartial', // (ruleUIKeys, listId, {partialRule})
  'updateRulesStatus', // (ruleUIKeys, listId, newStatus)
  'deleteRules', // (ruleUIKeys, listId)
  'checkingRuleCounts',

  'updateMoveRulesDialog', // (dialogState[closed|move|copy], ruleUIKeys, sourceListId)
  'copyRules', //(ruleUIKeys, sourceListId, targetListId)
  'moveRules', //(ruleUIKeys, sourceListId, targetListId)
  'fixMappings',
  'setEditMode', // (compare|mappings)
  'updateActiveRuleType', // (ruleType)

  'loadSchema',
  'searchRules', // (luceneQueryString)

  'selectRuleDetail', //(rule)
  'closeRuleDetail',
  'loadNextHistoryPage',

  // Overview actions
  // 'loadDeviationGraphData',
  'updateActiveOverviewRuleType', // (ruleType)
  'setSelectedDetailListId', // (listId)
  'setSelectedIndicator', // (listId, indicatorId)
  'expandHistorySummary', // (uploadIs)
  'getNextExpandedHistorySummaryPage',
  'loadNextTopContributors',
  'setHistoryFilters', // ({} partialFiltersUpdate)
  'removeHistoryFilter', // (filterKey)
  'refreshRecentChanges',

  'addJobId',
  'refreshSearch'
])

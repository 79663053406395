import React from 'react'
import T from 'prop-types'
import IT from 'react-immutable-proptypes'
import { Set } from 'immutable'
import CountersStore from 'stores/CountersStore'
import CountersActions from 'actions/CountersActions'
import { StoreProvider } from 'react-base'
import { keyIn } from 'utils/immutableUtils'

export default WrappedComponent => {
  class Subscriber extends React.PureComponent {
    static displayName = `Subscriber(${WrappedComponent.displayName || 'Component'})`

    static propTypes = {
      getRef: T.func,
      counterData: IT.mapOf(
        IT.map,
        T.object
      ),
    }

    static defaultProps = {
      counterData: null
    }

    state = {
      subscriptions: Set()
    }

    componentWillUnmount() {
      this.unsubscribeAll()
    }

    handleSubscribe = newSubscriptions => {
      const {
        subscriptions
      } = this.state

      const newSubs = Set(newSubscriptions)

      const oldSubs = subscriptions.filterNot(keyIn(newSubs))

      if (oldSubs.size > 0) {
        CountersActions.unsubscribe(oldSubs) // Unsubscribe from old items
      }
      CountersActions.subscribe(newSubs.filterNot(keyIn(subscriptions))) // Only subscribe to new items

      this.setState({
        subscriptions: newSubs
      })
    }

    unsubscribeAll = () => {
      const {
        subscriptions
      } = this.state

      CountersActions.unsubscribe(subscriptions)
    }

    filterBySubscription = storeState => {
      return {
        counterData: storeState && storeState.get('counterData').filter(keyIn(this.state.subscriptions))
      }
    }

    render() {
      const { subscriptions } = this.state
      const refProps = {}
      refProps[
        WrappedComponent.propTypes && WrappedComponent.propTypes.getRef
          ? 'getRef'
          : 'ref'
      ] = this.props.getRef

      return (
        <StoreProvider
          store={CountersStore}
          mapping={this.filterBySubscription}
        >
          <WrappedComponent
            {...refProps}
            {...this.props}
            subscriptions={subscriptions}
            subscribe={this.handleSubscribe}
          />
        </StoreProvider>
      )
    }
  }

  return Subscriber
}

import React from 'react'
import T from 'prop-types'
import cx from 'classnames'
import {
  DataTable,
  StoreProvider,
  Checkbox,
  TypeaheadInput,
  LoadingValue
} from 'react-base'
import UserProvider from 'components/UserProvider'
import IntegrationsActions from 'actions/IntegrationsActions'
import IntegrationsStore from 'stores/IntegrationsStore'
import { pluralize } from 'pw-formatters'

const makeCols = col => {
  return {
    fixed: true,
    header: col.header,
    key: col.key,
    resizable: false,
    sortable: false,
    width: col.key === 'checkbox' ? 30 : 200,
    renderValue: col.renderValue
  }
}

class RemediationDialog extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      dirtyAvailableList: []
    }
  }

  UNSAFE_componentWillMount() {
    IntegrationsActions.clearRemediationErrors()
    IntegrationsActions.loadBlockLists()
  }

  setDirtyAvailableLists = (list, all) => {
    if (
      this.state.dirtyAvailableList.map(i => i.id).includes(list.id) &&
      !all
    ) {
      this.setState({
        dirtyAvailableList: this.state.dirtyAvailableList.filter(
          l => l.id !== list.id
        )
      })
    } else {
      this.setState({
        dirtyAvailableList: this.state.dirtyAvailableList.concat(list)
      })
    }
  }

  saveBlockedResource = () => {
    if (this.props.type === 'ip') {
      IntegrationsActions.blockIp(this.props.resource, this.state.dirtyAvailableList)
    } else {
      // block file hash
    }
  }

  handleDataTableRef = inst => {
    this.remediation_table = inst
  }

  handleTypeaheadInputRef = r => {
    this.lists_input = r
  }

  render() {
    const isDirty =
      this.state.dirtyAvailableList.length
    const selectAll = () =>
      this.setDirtyAvailableLists(this.props.remediation.available, true)

    const renderCheckboxes = (column, rowIndex) => {
      return (
        <Checkbox
          checked={this.state.dirtyAvailableList
            .map(i => i.id)
            .includes(this.props.remediation.available[rowIndex].id)}
          onChange={this.setDirtyAvailableLists.bind(
            this,
            this.props.remediation.available[rowIndex],
            false
          )}
        />
      )
    }

    const checkbox = {
      key: 'checkbox',
      fixed: true,
      header: <Checkbox checked={false} onChange={selectAll} />,
      renderValue: renderCheckboxes
    }

    const wrappedColumns = [
      checkbox,
      { key: 'name', header: 'LIST NAME' },
      { key: 'id', header: 'LIST ID' }
    ]
      .map(makeCols)
      .map(baseCol => {
        const wrapped = Object.create(baseCol)
        if (!wrapped.renderValue) {
          wrapped.renderValue = dataItem => {
            return baseCol.format ? (
              baseCol.format(dataItem)
            ) : (
              <p>{dataItem[baseCol.key]}</p>
            )
          }
        }
        return wrapped
      })

    return (
      <div className={`remediation_dialog_body`}>
        <div className="input_row">
          <label htmlFor={ 'search_block_lists' }>Find Block Lists</label>
          <TypeaheadInput
            maxOptsToShow={10}
            menuClassName="block_input"
            menuFullWidth
            onChange={this.setDirtyAvailableLists}
            options={this.props.remediation.available}
            placeholder=""
            ref={this.handleTypeaheadInputRef}
          />
        </div>
        <div className="block_lists_row">
          <DataTable
            className="remediation_table"
            columns={wrappedColumns}
            data={this.props.remediation.available}
            error={null}
            hasNextPage={false}
            isLoading={this.props.remediation.isLoading}
            isLoadingNextPage={false}
            noResultsText="No Block Lists Available"
            getRef={this.handleDataTableRef}
            sortedColumn={null}
            style={{ right: 0 }}
          />
        </div>
        <div className="error_container">
          {
            this.props.remediation.errors
            ? (
              <section className="validation_errors mappings_wrap fade_in_right">
                {
                  this.props.remediation.errors.map(err => {
                    return (
                      <div  key={ err.error }>
                        <div className="mappings_info_window">
                          <span
                            className={`btn-icon btn icon-warning`}
                          />
                        </div>
                        <div className="grid_cell flex_1 xs">
                          <div className="validation_error">
                            <div className="validation_name">
                              { err.details || err.error }
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </section>
            ) : null
          }
        </div>
        <footer>
          <button
            className={'btn btn-default'}
            onClick={this.props.onBack}
          >
            <span className="icon icon-arrow-left" /> Back
          </button>
          <button
            className={cx('btn', isDirty ? 'btn-primary' : 'btn-default')}
            disabled={!isDirty || this.props.remediation.errors}
            onClick={this.saveBlockedResource}
          >
            {this.props.remediation.isSaving
              ? <LoadingValue />
            : !this.props.remediation.errors &&
                this.props.remediation.blocked.length
                ? <span>
                    <span className="icon icon-checkmark" /> { 'IP Blocked' }
                  </span>
                : `Add to block ${pluralize(
                    this.state.dirtyAvailableList.length,
                    'list'
                  )}`}
          </button>
        </footer>
      </div>
    )
  }
}

RemediationDialog.displayName = 'RemediationDialog'
RemediationDialog.propTypes = {
  resource: T.string.isRequired,
  onCloseClick: T.func.isRequired,
  remediation: T.object,
  type: T.string
}

/**
 * Export with stores wrapped
 * @param {*props} props
 */
function RemediationDialogWithStores(props) {
  return (
    <UserProvider>
      <StoreProvider store={IntegrationsStore}>
        <RemediationDialog {...props} />
      </StoreProvider>
    </UserProvider>
  )
}

RemediationDialogWithStores.displayName = 'RemediationDialogWithStores'
RemediationDialogWithStores.propTypes = RemediationDialog.propTypes

export default RemediationDialogWithStores

import worldMapUtil from 'ui-base/src/util/worldMapUtil'
import geoDataUtil from 'utils/geoDataUtil'
import {geoMercator} from 'd3-geo'

export default worldMapUtil.createRegionOptions({
  regionName: 'earth',
  maxX: 180,
  minX: -180,
  maxY: 90,
  minY: -90,
  padding: 0,
  // mapScale: 58,
  preserveAspectRatio: 'xMidYMid',
  mapProjection: geoMercator(),
  regionDataPromise: geoDataUtil.getWorld('low')
})

import React from 'react'
import T from 'prop-types'
import IT from 'react-immutable-proptypes'
import _ from 'lodash'
import {
  Map,
  fromJS
} from 'immutable'
import {
  Maximizable,
  StoreProvider
} from 'react-base'
import {
  PPC_SHAPE
} from 'constants/captureProfiles'
import ProfilerActions from 'actions/ProfilerActions'
import PerceptiveCaptureProfileStore from 'stores/PerceptiveCaptureProfileStore'
import Subscriber from 'components/Subscriber'
import PerceptiveProfileGraph from './PerceptiveProfileGraph'
import InfoHint from 'components/InfoHint'

class PerceptiveProfileGraphData extends React.PureComponent {
  static displayName = 'PerceptiveProfileGraphData'

  static propTypes = {
    sensorId: T.number.isRequired,

    // From CountersStore
    subscriptions: IT.set,
    counterData: IT.mapOf(
      IT.map,
      T.object
    ),
    countersError: T.any,

    // From PerceptiveCaptureProfileStore
    recommendedPerceptiveProfile: PPC_SHAPE,
    isLoading: T.bool,
    error: T.any,

    // From Maximizable
    isMaximized: T.bool
  }

  static defaultProps = {
    counterData: Map(),
  }

  state = {
    subscriptionKeys: Map()
  }

  UNSAFE_componentWillMount() {
    ProfilerActions.PerceptiveProfiles.loadRecommended()
    this._handleSensorChanged(this.props)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.sensorId !== this.props.sensorId || nextProps.isMaximized !== this.props.isMaximized) {
      this._handleSensorChanged(nextProps)
    }
  }

  _handleSensorChanged = props => {
    const {
      // subscribe,
      sensorId,
      isMaximized
    } = props

    const _sharedSubscriptionJS = {
      counterNames: [
        // 'bytesCompressedSentPcap',
        // 'bytesOptimized',
        // 'bytesCaptured'
        'healthState'
      ],
      aggregate: 'mean',
      stackBy: 'type'
    }

    if (sensorId) {
      _sharedSubscriptionJS['sensorIds'] = [sensorId]
    }

    const _sharedSubscription = fromJS(_sharedSubscriptionJS)

    const graphSubs = {
      month: _sharedSubscription.merge({
        windowSize: '31 days',
        endTime: '-8 days',
        interval: '1 days', //isMaximized ? '1 days' : '1 days',
        useGraphSubscriber: false,
        // aggregate: 'sum'
      }),
      week: _sharedSubscription.merge({
        windowSize:  '7 days',
        endTime: '-24 hours',
        interval: isMaximized ? '4 hours' : '8 hours',
        useGraphSubscriber: false,
        // aggregate: 'sum'
      }),
      day: _sharedSubscription.merge({
        windowSize: '24 hours',
        endTime: '-2 minutes',
        interval: isMaximized ? '30 minutes' : '60 minutes',
        useGraphSubscriber: false,
        // aggregate: 'sum'
      }),
      recent: _sharedSubscription.merge({
        windowSize: '2 minutes',
        endTime: 'now',
        interval: '1 seconds',
        useGraphSubscriber: true, // TODO make this automatic
        aggregate: 'sum'
      })
    }

    this.setState({
      subscriptionKeys: Map(graphSubs)
    })

    props.subscribe(_.values(graphSubs))
  }

  render() {
    const {
      counterData,
      isLoading,
      error,
      recommendedPerceptiveProfile,
      isMaximized
    } = this.props
    const {
      subscriptionKeys
    } = this.state

    return (
      <div className={`perceptive_wrap ${isMaximized ? 'in_modal' : ''}`}>
        {isMaximized ? (
          <header>
             Perceptive Capture State
          </header>
        ) : (
          <InfoHint
            heading={`Perceptive Capture State`}
            menuAlign={`bottom`}
            message={"Perceptive Capture Profile State"}
          />
        )}
        <PerceptiveProfileGraph
          data={counterData}
          subscriptionKeys={subscriptionKeys}
          isMaximized={isMaximized}
          error={error}
          isLoading={isLoading}
          currentProfile={recommendedPerceptiveProfile}
        />
      </div>
    )
  }
}

const WithStores = props => (
  <StoreProvider store={PerceptiveCaptureProfileStore}>
    <PerceptiveProfileGraphData {...props} />
  </StoreProvider>
)

export default Maximizable(Subscriber(WithStores))

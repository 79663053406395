import T from 'prop-types'
/*
PW Large number.

Feed me large numbers and I will format them with a tooltip showing the exact value (only when value is over 1000)
*/

import React from 'react'
import { formatNumber } from 'pw-formatters'


class LargeNumber extends React.PureComponent {
  static displayName = "LargeNumber";

  static propTypes = {
    val: T.number,
    className: T.string,
    extraText: T.string
  };

  static defaultProps = (() => ({
    className: '',
    val: 0,
    extraText: '',
  }))();

  render() {
    let {props} = this
    return (
      <span className={ props.className } data-tooltip={ props.val > 1000 ? props.val.toLocaleString() : null }>
        { formatNumber(props.val, true) }
        { props.extraText }
      </span>
    )
  }
}

export default LargeNumber


import {observable, computed} from 'mobx'
import {
  SchemerModel,
  schemer
} from 'pw-schemer-mobx'
import { SchemerModelOptions } from 'pw-schemer-mobx/scripts/core/SchemerModel'
import {DeviceAttributeSource} from 'typings/devices/deviceAttributeSource'
import {DeviceAttrSource} from 'components/devices/fragments/DeviceAtrtributeSource'
import {ExpiresAt} from 'components/devices/fragments/ExpiresAt'
import {SrcIcon, ExpiresIcon} from 'components/devices/fragments/IconHeaders'

export default class DeviceAttribute extends SchemerModel {
  constructor(initial, opts?: SchemerModelOptions) {
    super(initial, opts)
  }

  @observable
  @schemer.string
  value: any

  @observable
  @schemer.enum({
    label: 'Src',
    options: DeviceAttributeSource,
    formatDisplay: DeviceAttrSource,
    table: {
      header: SrcIcon
    }
  })
  source: DeviceAttributeSource

  @observable
  @schemer.datetime({
    label: 'Updated At',
    table: false
  })
  updatedAt: Date

  @observable
  @schemer.durationMs({
    label: 'TTL',
    description: 'Duration in milliseconds after the "Updated At" time that this attribute is valid',
    table: false
  })
  ttl?: number

  @computed
  @schemer.datetime({
    label: "Timing",
    formatDisplay: ExpiresAt,
    table: {
      header: ExpiresIcon
    }
  })
  get expiresAt () {
    if (this.ttl === 0) {
      return "never"
    }
    else {
      return new Date(+this.updatedAt + this.ttl)
    }
  }

  @computed
  get isExpired () {
    return +this.expiresAt < Date.now()
  }

  @computed
  get classNames () {
    return `device_attr_row${this.isExpired ? ' is_expired' : ''}`
  }
}
import _ from 'lodash'
import React from 'react'
import {
  ScrollBars,
  TextOverflow,
  LoadingValue,
  Tabs,
  MenuItem
} from 'react-base'
import IntelCardPropTypes from '../PropTypes'
import DataCell from './DataCell'
import { formatDate } from 'utils/timeUtils'
import { pluralize } from 'pw-formatters'
import { VALUE_TYPE_OPTIONS } from 'constants/searchableValueConstants'
import IpValue from 'components/values/IpValue'
import SearchableValue from 'components/values/SearchableValue'
import AnalyticsActions from 'actions/AnalyticsActions'
import IntelSourceTag from './IntelSourceTag'
import CrowdStrikeDeviceData from './CrowdStrikeDeviceData'
import CarbonBlackResponseDeviceData from './CarbonBlackResponseDeviceData'
import CarbonBlackDefenseDeviceData from './CarbonBlackDefenseDeviceData'
import BulkUploadDeviceData from './BulkUploadDeviceData'
import AwsDeviceData from './AwsDeviceData'
import {UserProviderHOC} from 'components/UserProvider'
import { formatDeviceAttributeValue } from 'utils/deviceUtils'
import getConfig from 'utils/uiConfig'
import DataSourceTag from 'components/DataSourceTag'


const DEVICE_DB_SOURCE_KEYS = {
  crowdstrike: 'NarkCrowdstrike',
  carbonBlackDefense: 'NarkCarbonblackDefense'
}

const buildIpTag = (type) => {
  const typeAbbr = type === 'internal' ? 'INT' : 'EXT'
  return (
    <DataSourceTag tooltip={`${type} IP`}>
      { typeAbbr }
    </DataSourceTag>
  )
}

const getSource = (source) => {
  return <IntelSourceTag source={source} />
}

const deviceIdFormatter = (deviceId, type, sensorIds) =>
  <SearchableValue
    value={deviceId}
    valueType={type}
    relatedData={{ sensorIds }}
    query={{
      family: 'observations',
      clauses: [
        {
          name: 'occurredAt',
          op: 'between',
          from: Date.now() - 60 * 60 * 1000,
          to: Date.now()
        },
        {
          name: 'devices',
          op: 'eq',
          value: deviceId
        }
      ]
    }}
  >
    {deviceId}
  </SearchableValue>

class Device extends React.Component {
  static displayName = 'Device'
  static propTypes = IntelCardPropTypes.dataCardShape

  state = {
    activeDetailTab: 'summary'
  }

  render() {
    const { props } = this
    return (
      <div className="intel_card intel_data_card intel_data_device">
        <h3>Device Intel</h3>
        <div className="intel_card_content">
          {props.data ? this.renderData() : null}
        </div>
      </div>
    )
  }

  setDetailsTab = (tab) => {
    this.setState({
      activeDetailTab: tab
    })

    if (tab === 'summary') {
      this._trackInternalTab('device-summary')
    } else if (tab === 'history') {
      this._trackInternalTab('device-ip-history')
    }
  }

  _trackInternalTab = (label) => {
    AnalyticsActions.event({
      eventCategory: 'intelcard',
      eventAction: 'device',
      eventLabel: label
    })
  }

  getHostnames = () => {
    const hostsFromAllSources = []

    const deviceDbData = this.props.data('deviceDbData').valueOf() || {}
    const narkDevices = this.props.data('devices').valueOf() || []


    // From customer-uploaded data:
    const bulkAttrHost = _.get(deviceDbData, 'bulkAttributes.hostName')
    if (bulkAttrHost) {
      hostsFromAllSources.push({value: bulkAttrHost, source: 'CustomerBulk'})
    }

    // From PW device DB:
    const pwAttrHosts = _.get(deviceDbData, 'protectWiseAttributes.hostName')
    if (pwAttrHosts) {
      hostsFromAllSources.push(...pwAttrHosts)
    }

    // From Nark query:
    if (narkDevices) {
      narkDevices.forEach(narkDevice => {
        const narkCbHost = _.get(narkDevice, 'extendedInfo.cbDeviceInfo.hostName')
        if (narkCbHost) {
          hostsFromAllSources.push({value: narkCbHost, source: 'CarbonBlack'})
        }
      })
    }

    // From DHCP:
    const dhcpHostname = _.get(deviceDbData, 'additionalAttributes[DHCP:AssignedHostname]')
    if (dhcpHostname) {
      hostsFromAllSources.push({value: dhcpHostname, source: getConfig().mainBrand})
    }

    return _.uniq(hostsFromAllSources, (d) => {
      return `${d.value}_${d.source}`
    })
  }

  renderData = () => {
    const { activeDetailTab } = this.state
    const { props } = this
    const {data, isLoading} = props

    // Show nothing until all sources are loaded
    if (isLoading) {
      return <LoadingValue />
    }

    /* NOTE: device data comes from multiple possible sources, each wrapped in the ValueAccessor abstraction:
      - data('devices') / data('devicesBySource') / data('aws') etc. with response from reputations API/Nark,
      - data('deviceDbData') with response from Device DB
    */
    const deviceDbData = data('deviceDbData').valueOf() || {}

    // Collect an index of which intel sources have data in the deviceDB response:
    const deviceDbSources = _.reduce(deviceDbData.protectWiseAttributes || {}, (out, vals) => {
      for (let v of vals) { out[v.source] = true }
      return out
    }, {})

    const crossSourceHostnames = this.getHostnames()
    const macs = _.get(deviceDbData, 'protectWiseAttributes.mac')
    const bulkUploadData = deviceDbData.bulkAttributes

    const hasBulkUploadData = !_.isEmpty(bulkUploadData)
    const hasBulkUploadIpMapping = bulkUploadData && !_.isEmpty(bulkUploadData.ips)
    const hasAwsData = !_.isEmpty(data('aws').valueOf())
    const hasCBResponseData = !_.isEmpty(data('devicesBySource.CarbonBlack').valueOf())
    const hasCBDefenseData = deviceDbSources[DEVICE_DB_SOURCE_KEYS.carbonBlackDefense]
    const hasCrowdStrikeData = deviceDbSources[DEVICE_DB_SOURCE_KEYS.crowdstrike]
    
    const vendorsSet = _.get(this.props, 'userFeatures.endpointKnowledgeVendors') || new Set()

    const hasSummaryTabData = hasBulkUploadData || hasAwsData || hasCBResponseData || hasCBDefenseData || hasCrowdStrikeData || vendorsSet.size > 0

    return <ScrollBars
          classes="pw_scrollbars_light"
          outside
          slimShady
          slimShadyDarker
        >
        <DataCell label={`${getConfig().mainBrand} Device ID`}>
          {deviceIdFormatter(props.queryValue, VALUE_TYPE_OPTIONS.DEVICE)}
        </DataCell>

        <DataCell label={pluralize(crossSourceHostnames.length, 'Hostname')}>
          {crossSourceHostnames.length
            ? <table width="100%">
              <tbody>
                {crossSourceHostnames.map((d, i) =>
                  <tr key={i}>
                    <td><TextOverflow>{d.value}</TextOverflow></td>
                    <td>{getSource(d.source)}</td>
                  </tr>
                )}
              </tbody>
            </table>
            : 'Unknown'
          }
        </DataCell>

        { // If bulk IP info, display some of it up top - rest is in Summary section (see BulkUploadDeviceData.jsx)
          hasBulkUploadData && <React.Fragment>
            {/* TODO need new field for asset id? customerDeviceId is something different
            bulkUploadData.??? &&
              <DataCell label="Device Asset ID">
                <span>{bulkUploadData.???}</span>
                {getSource('CustomerBulk')}
              </DataCell>
            */}
            {hasBulkUploadIpMapping &&
              <DataCell label="IP Address">
                <IpValue ip={_.get(bulkUploadData, 'ips[0].ip')} />
                {getSource('CustomerBulk')}
              </DataCell>
            }
          </React.Fragment>
        }

        { // If we only have a MAC address from the "Protectwise" source, display it here
          !(bulkUploadData && bulkUploadData.mac) && macs && macs.length === 1 && macs[0].source === 'Protectwise'
            ? <DataCell label="MAC Address">
              {formatDeviceAttributeValue('mac', macs[0].value)}
              {getSource('Protectwise')}
            </DataCell>
            : null
        }

        {hasSummaryTabData
          ? <Tabs activeTabKey={activeDetailTab}>
              <MenuItem key="summary" onClick={this.setDetailsTab}>
                Summary
              </MenuItem>
              {hasBulkUploadIpMapping ? null :
                <MenuItem key="history" onClick={this.setDetailsTab}>
                  IP History
                </MenuItem>
              }
            </Tabs>
          : null
        }

        <section className="body">
          {!hasSummaryTabData
            ? (
              <div className="device_history">
                <p className="no_integrations">
                  To add additional device context, use <a href="/#settings/context-fusion/integrations">context fusion</a> to configure integrations
                </p>
                <div className="cell">
                  <span className="bold">
                    IP History
                  </span>
                </div>
                {this._renderIpHistory()}
              </div>
            ) : (
              (activeDetailTab === 'summary' || hasBulkUploadIpMapping)
                ? (
                  <div className="summary_details">
                    {hasBulkUploadData &&
                      <BulkUploadDeviceData
                        deviceData={deviceDbData}
                      />
                    }
                    {(hasCBDefenseData || vendorsSet.has('carbonblack.defense')) &&
                      <CarbonBlackDefenseDeviceData
                        deviceData={deviceDbData}
                        startTime={props.startTime}
                        endTime={props.endTime}
                      />
                    }
                    {(hasCrowdStrikeData || vendorsSet.has('crowdstrike')) &&
                      <CrowdStrikeDeviceData
                        deviceData={deviceDbData}
                        startTime={props.startTime}
                        endTime={props.endTime}
                      />
                    }
                    {(hasCBResponseData || vendorsSet.has('CarbonBlack')) &&
                      <CarbonBlackResponseDeviceData
                        data={props.data}
                      />
                    }
                    {(hasAwsData || vendorsSet.has('AWS')) &&
                      <AwsDeviceData
                        data={props.data}
                        sensorId={props.sensorId}
                      />
                    }
                </div>
                )
                : activeDetailTab === 'history'
                  ? <div className="device_history">{this._renderIpHistory()}</div>
                  : null
            )
          }
        </section>
      </ScrollBars>
  }


  _renderIpHistory() {
    return <React.Fragment>
      <div className="cell">
        <span>Timeframe</span>
        <div className="cell_content multiline">
          <div>{ formatDate(this.props.startTime) }</div>
          <div>{ formatDate(this.props.endTime) }</div>
        </div>
      </div>
      <div className="cell">
        <div className="cell_content multiline">
          <table className="net_adapters">
            <thead>
            <tr>
              <th>IP</th>
              <th>Last Updated</th>
              <th />
              <th />
            </tr>
            </thead>
            <tbody>
              {(this.props.data('deviceDbData.protectWiseAttributes.ips').valueOf() || [])
                .sort((a, b) => _.get(a, 'expirationInfo.updatedTime', 1) - _.get(b, 'expirationInfo.updatedTime', 1))
                .map((ip, i) => {
                  return (
                    <tr key={`ips_${ip.value.ip}_${i}`}>
                      <td>{ip.value.ip ? <IpValue ip={ip.value.ip} /> : 'Unknown'}</td>
                      <td>
                        {ip.expirationInfo &&
                          <TextOverflow>
                            {formatDate(ip.expirationInfo.updatedTime)}
                          </TextOverflow>
                        }
                      </td>
                      <td>{buildIpTag(ip.value.type)}</td>
                      <td>{getSource(ip.source)}</td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </React.Fragment>
  }
}

export default UserProviderHOC(Device)

import React from 'react'
import T from 'prop-types'
import cx from 'classnames'


let idCtr = 0

/**
 * Pure component for rendering a single data item's path/fill.
 */
class PathSvg extends React.PureComponent {
  static propTypes = {
    className: T.string,
    fillPath: T.string, //fill path or null for no fill
    strokePath: T.string, //stroke path or null for no stroke
    clipPath: T.string, //optional path for clipping both the stroke and fill shapes
  }

  static defaultProps = {
  }

  _id = `PathSvg_${idCtr++}_`

  render() {
    const {props, _id} = this
    const {fillPath, strokePath, clipPath} = props
    const gradientId = fillPath && `${_id}_gradient`
    const clipPathId = clipPath && `${_id}_clip`

    return <g className={cx('stream_path', props.className)}>
      {clipPath &&
        <clipPath id={clipPathId}>
          <path d={clipPath} />
        </clipPath>
      }
      {fillPath &&
        // Gradient definition
        <linearGradient id={gradientId} x1="0" x2="0" y1="0" y2="100%">
          <stop className="gradient_stop top" offset="0%" />
          <stop className="gradient_stop bottom" offset="100%" />
        </linearGradient>
      }
      {fillPath &&
        // Path for the fill
        <path
          className="fill_path"
          d={fillPath}
          stroke="none"
          fill={`url(#${gradientId})`}
          clipPath={clipPath && `url(#${clipPathId})`}
        />
      }
      {strokePath &&
        // Path for the stroke; its stroke properties can be styled vai CSS
        <path
          d={strokePath}
          stroke="currentColor"
          fill="none"
          clipPath={clipPath && `url(#${clipPathId})`}
        />
      }
    </g>
  }
}

export default PathSvg

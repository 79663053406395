import React, { FunctionComponent } from 'react'
import {observer} from 'mobx-react'
import {DeviceAttributeSource, getAbbreviation, getDisplayVal} from 'typings/devices/deviceAttributeSource'
import DataSourceTag from 'components/DataSourceTag';

export interface DeviceAttrSourceProps {
  value: DeviceAttributeSource
}

export const DeviceAttrSource: FunctionComponent<DeviceAttrSourceProps> = observer(props => {
  const enumVal = DeviceAttributeSource[props.value]
  return (
    <DataSourceTag tooltip={getDisplayVal(enumVal)}>
      {getAbbreviation(enumVal)}
    </DataSourceTag>
  )
})

DeviceAttrSource.displayName = "DeviceAttrSource"
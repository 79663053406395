/**
 * Convenience wrapper for a SearchableValue that is a single Device ID. Handles
 * automatically building the intelcard and explorer query props.
 *
 *   <DeviceValue deviceId="sdfa345kjhkj" start={ startTime } end={ endTime } />
 *
 * If the start/end props are omitted, it defaults to the past hour.
 *
 * All other props supported by SearchableValue can be included and will be passed through.
 */

import _ from 'lodash'
import T from 'prop-types'
import React from 'react'
import { VALUE_TYPE_OPTIONS } from 'constants/searchableValueConstants'
import SearchableValue from 'components/values/SearchableValue'
import DeviceIdenticon from 'components/DeviceIdenticon'
import {
  getDeviceAttributes,
  formatDeviceAttributeValue,
  getMostRelevantHostname
} from 'utils/deviceUtils'
import { LoadingValue } from 'react-base'

import urlHistoryUtil from 'utils/urlHistoryUtil'

const defaultDuration = 60 * 60 * 1000

class DeviceValue extends React.Component {
  static displayName = 'DeviceValue'

  static propTypes = {
    deviceAttributes: T.arrayOf(T.object), // host name, mac address, etc.
    deviceId: T.string,
    end: T.number,
    iconOnly: T.bool,
    multiLine: T.bool,
    noIntelCard: T.bool,
    noQuery: T.bool,
    sensorId: T.number,
    size: T.number, // Passed to DeviceIdenticon
    start: T.number,
    testId: T.string,
    validateDeviceId: T.bool // If true, will only show the proper identicon for deviceIds that are known by the API
    // ...plus any others accepted by SearchableValue
  }

  static defaultProps = {
    multiLine: false, // Currently unused
    validateDeviceId: false
  }

  state = {
    deviceAttributes: null,
    isValid: true
  }

  UNSAFE_componentWillMount() {
    this._checkForDeviceAttributes(this.props)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this._checkForDeviceAttributes(nextProps)
  }

  componentWillUnmount() {
    this._isUnmounting = true
  }

  _checkForDeviceAttributes = (props = {}) => {
    if (props.deviceId && _.isEmpty(props.deviceAttributes)) {
      this.setState({
        deviceAttributes: null,
        loadingDeviceAttributes: true
      })
      getDeviceAttributes(props.deviceId)
        .then(data => {
          if (!this._isUnmounting) {
            this.setState({
              isValid: true,
              deviceAttributes: data,
              loadingDeviceAttributes: false
            })
          }
        })
        .catch(() => {
          if (!this._isUnmounting) {
            this.setState({
              isValid: false,
              deviceAttributes: null,
              loadingDeviceAttributes: false
            })
          }
        })
    } else {
      this.setState({
        deviceAttributes: props.deviceAttributes,
        loadingDeviceAttributes: false
      })
    }
  }

  _getMainDeviceText = () => {
    const { props, state } = this
    const hostName = getMostRelevantHostname(this.state.deviceAttributes)
    return hostName ? hostName.value : props.deviceId || ''
  }

  render() {
    const { props, state } = this
    let { deviceId, size, start, end, sensorId } = props
    if (!deviceId) {
      return <span />
    }
    if (!end) {
      end = start ? start + defaultDuration : Date.now()
    }
    if (!start) {
      start = end - defaultDuration
    }
    const query = props.noQuery
      ? null
      : {
          family: 'observations',
          clauses: [
            {
              name: 'occurredAt',
              op: 'between',
              from: start,
              to: end
            },
            {
              name: 'devices', // srcDeviceId, dstDeviceId
              op: 'eq',
              value: deviceId
            }
          ]
        }
    const invalid = props.validateDeviceId && !state.isValid
    const mainText = this._getMainDeviceText()
    let intelCardString = `device:${deviceId}`
    if (sensorId != null) {
      intelCardString += `|${sensorId}`
    }

    return (
      <SearchableValue
        {...props}
        className={`device_value ${props.className || ''} ${invalid
          ? 'device_invalid'
          : ''}`}
        data-testid={props['data-testid']}
        intelCard={props.noIntelCard ? null : `device:${deviceId}|${sensorId}`}
        intelCardTooltip="View Device Info"
        query={query}
        value={mainText}
        copyValue={mainText}
        valueType={VALUE_TYPE_OPTIONS.DEVICE}
      >
        {invalid
          ? <span
              className="icon icon-warning"
              data-tooltip="Unknown Device ID"
              data-tooltip-align={'bottom-right'}
              style={{
                fontSize: Math.round(size / 1.5) + 'px'
              }}
            />
          : <DeviceIdenticon
              data-tooltip={
                props.iconOnly
                  ? state.loadingDeviceAttributes
                    ? 'Loading...'
                    : mainText
                  : null
              }
              deviceId={deviceId}
              size={size}
            />}
        {props.iconOnly
          ? null
          : <span className="device_value_text">
              {state.loadingDeviceAttributes
                ? <LoadingValue />
                : mainText}
            </span>}
      </SearchableValue>
    )
  }
}

export default DeviceValue

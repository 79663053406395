
import {observable} from 'mobx'
import {schemer} from 'pw-schemer-mobx'
import DeviceAttribute from './DeviceAttribute'
import {DeviceRole} from 'typings/devices/device'

export class DeviceRoleAttr extends DeviceAttribute {
  @observable
  @schemer.enum({
    options: DeviceRole
  })
  value: DeviceRole
}


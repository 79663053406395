import React from 'react'
import T from 'prop-types'
import { TextOverflow } from 'react-base'
import IntelSourceTag from './IntelSourceTag'
import { formatDeviceAttributeValue } from 'utils/deviceUtils'


class BulkUploadDeviceData extends React.Component {
  static propTypes = {
    deviceData: T.object //response from entities API
  }

  render() {
    const {props} = this
    const bulkData = props.deviceData.bulkAttributes

    function renderAttribute(label, value) {
      return <div className="cell">
        <span>{label}</span>
        <div className="cell_content">
          <TextOverflow>{value}</TextOverflow>
        </div>
      </div>
    }

    // Render nothing if no bulk data exists for this device
    return _.isEmpty(bulkData) ? null : (
      <div className="device_source_summary">
        <h5>Uploaded Data Summary <IntelSourceTag source="CustomerBulk" /></h5>

        {renderAttribute('Description', bulkData.description || 'None')}
        {renderAttribute('MAC Address', formatDeviceAttributeValue('mac', bulkData.mac) || 'Unknown')}
        {renderAttribute('Device Type', bulkData.type || 'Unknown')}
        {renderAttribute('Device Role', bulkData.roles && bulkData.roles.join(', ') || 'Unknown')}
        {/*TODO Department?*/}
        {/*TODO Location?*/}
        {renderAttribute('Network Description', bulkData.networkDescription || 'Unknown')}
        {renderAttribute(
          'Operating System',
          (bulkData.osType || bulkData.osName || bulkData.osVersion)
            ? <React.Fragment>
                <div>Type: {bulkData.osType || 'Unknown'}</div>
                <div>Name: {bulkData.osName || 'Unknown'}</div>
                <div>Version: {bulkData.osVersion || 'Unknown'}</div>
              </React.Fragment>
            : 'Unknown'
        )}
        {/*TODO Zone?*/}
        {/*TODO Vendor?*/}
        {renderAttribute('Customer Device ID', bulkData.customerDeviceId || 'None')}
      </div>
    )
  }
}

export default BulkUploadDeviceData

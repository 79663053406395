import React from 'react'
import T from 'prop-types'
import cx from 'classnames'
import memoize from 'memoize-one'
import { formatDate } from 'utils/timeUtils'



class Ruler extends React.PureComponent {
  static propTypes = {
    items: T.array, //See shape in SensorTimeline.jsx propTypes
    time: T.number,
    xScale: T.func,
    yScale: T.func,
    valueFormatter: T.func,
    timeFormatter: T.func
  }

  // Build a cached index of values by timestamp for fast lookup
  _valuesByTime = memoize(items => {
    const index = Object.create(null)
    for (let {className, label, data, showInRuler, rulerYScale} of items) {
      if (data) {
        for (let {timestamp, value} of data) {
          const values = index[timestamp] || (index[timestamp] = [])
          if (showInRuler !== false && !(showInRuler === 'nonzero' && value === 0)) {
            values.push({value, className, label, yScale: rulerYScale})
          }
        }
      }
    }
    return index
  })

  render() {
    const {time, items, xScale, yScale, valueFormatter, timeFormatter} = this.props

    // Bail if no active time
    if (time == null) {
      return null
    }

    // Find all item values at this time
    const values = this._valuesByTime(items)[time]
    if (!values) {
      //console.warn(`Ruler time not in data`)
      return null
    }
    const [minX, maxX] = xScale.range()
    const [botY, topY] = yScale.range()

    // Sort values by y position
    values.sort((a, b) => (a.yScale || yScale)(a.value) - (b.yScale || yScale)(b.value))

    /* Strategy for layout/positioning of the ruler values:
       Each value is a flex item in a vertical flexbox, with its `height` set to the distance between
       it and the next lower value, with an additional empty strut box at the top to complete the remainder.
       This makes each box's top edge line up with its y value exactly by default; however if the distance
       between any two items would be make their labels overlap, then the flexbox rules will prevent that
       by shrinking the other boxes' heights proportionally. The "dot" for each item is absolutely positioned
       so it always lines up exactly even if the label positions are adjusted.
    */
    const valueEls = []
    for (let i = 0, len = values.length; i < len; i++) {
      const {value, className, label, yScale:itemYScale} = values[i]
      const itemY = (itemYScale || yScale)(value)
      const {textData:num, label:unit} = valueFormatter(value, values[i])
      if (i === 0) {
        valueEls.push(
          <div key="strut" style={{height: Math.max(0, Math.round(itemY - topY))}} />
        )
      }
      const nextValueY = i < len - 1
        ? (values[i + 1].yScale || yScale)(values[i + 1].value)
        : botY
      valueEls.push(
        <div
          key={i}
          className={cx('ruler_val', className)}
          style={{
            height: Math.max(0, Math.round(nextValueY - itemY))
          }}
        >
          {itemY <= botY && itemY >= topY && //hide dot outside bounds
            <span className="dot" style={{top: itemY}} />
          }
          <span className="text">{num + unit} {/*label*/}</span>
        </div>
      )
    }

    // Add one for the timestamp - will be absolutely positioned below the graph so it doesn't push the rest up
    valueEls.push(
      <div
        key='timestamp'
        className={cx('ruler_val', 'timestamp')}
      >
        <span className="text">{timeFormatter ? timeFormatter(time) : formatDate(time)}</span>
      </div>
    )

    const x = xScale(time)
    return <div
      className={cx('ruler', `${x > (minX + maxX) / 2 ? 'right' : 'left'}_side`)}
      style={{left: x}}
    >
      {valueEls}
    </div>
  }
}

export default Ruler

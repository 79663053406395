import React from 'react'
import { observer } from 'mobx-react'
import {
  ScrollBars,
  DialogContainer
} from 'react-base'
import {
  IpListInput,
  PortListInput
} from 'pw-drafty'
import SensorDropdown from 'components/SensorDropdown'
import TimeRangeInput from 'components/TimeRangeInput'
import SensorStore from 'stores/SensorStore'
import { useStore } from 'stores/mobx/StoreManager'
import PcapDownloaderStore, { MAX_DURATION_MS } from 'stores/mobx/viewStores/pcap-downloader/PcapDownloaderStore'
import { isUTC } from 'utils/timeUtils'
import { formatDurationHuman } from 'pw-formatters'

export const PcapDownloader = observer(() => {
  const {
    closeDialog,
    handleSensorChange,
    handleTimeRangeChange,
    handleIpsChange,
    handlePortsChange,
    submit,
    reset,
    hasIpOrPort,
    isTimeRangeValid,
    isValid,
    isDialogOpen,
    currentQuery
  } = useStore(PcapDownloaderStore)

  return isDialogOpen ? (
    <DialogContainer modal onMaskClick={closeDialog}>
      <div className={`global_dialog modal_dialog pcap_downloader`}>
        <header>
          <h1>
            <span className={`icon icon-download`} /> PCAP Downloader
            <span className="remove_btn" onClick={closeDialog} />
          </h1>
          <p>Manually download stored PCAP data.</p>
        </header>
        <ScrollBars slimShadyDarker vertical outside className="pcap_download_inputs">
          <div className={`pcap_field`}>
            <span className={`pcap_label`}>Sensor <b className={`required`}>*</b></span>
            <div className={`sensor_wrap`}>
              <SensorDropdown
                label={currentQuery.sensorId ? SensorStore.getSensorName(currentQuery.sensorId) : 'Choose a Sensor'}
                activeMenuKeys={[currentQuery.sensorId]}
                onSelect={handleSensorChange}
                buttonClasses={`btn dropdown-toggle btn-${currentQuery.sensorId ? 'primary' : 'default'}`}
              />
            </div>
          </div>
          <div className={`pcap_field`}>
            <span className={`pcap_label`}>Time Range <b className={`required`}>*</b></span>
            <div className={`time_range_wrap`}>
              <TimeRangeInput
                initialValue={ [currentQuery.startTime, currentQuery.endTime] }
                onSave={ handleTimeRangeChange }
                utc={ isUTC() }
              />
            </div>
          </div>
          <div className={`pcap_field`}>
            <span className={`pcap_label`}>IP Addresses</span>
            <IpListInput
              allowCIDR={false}
              initialValue={currentQuery.ips.join('\r\n')}
              onChange={handleIpsChange}
            />
          </div>
          <div className={`pcap_field`}>
            <span className={`pcap_label`}>Ports</span>
            <PortListInput
              initialValue={currentQuery.ports.join('\r\n')}
              onChange={handlePortsChange}
            />
          </div>
        </ScrollBars>
        <div className={`validation_summary`}>
          {!hasIpOrPort &&
            <div className={`validation_summary_msg`}>
              Please enter at least one IP Address or Port
            </div>
          }
          {!isTimeRangeValid &&
            <div className={`validation_summary_msg`}>
              A time range of less than {formatDurationHuman(MAX_DURATION_MS)} is required.
            </div>
          }
        </div>
        <footer>
          <button
            className={`btn btn-default`}
            onClick={reset}
          >Reset</button>
          <button
            className={`btn btn-${isValid ? 'primary' : 'error'}`}
            onClick={submit}
            disabled={!isValid}
          >Download</button>
        </footer>
      </div>
    </DialogContainer>
  ) : null
})

PcapDownloader.propTypes = {}

PcapDownloader.displayName = "PcapDownloader"

import Reflux from 'reflux'

export default Reflux.createActions([
  // Customers:
  'acceptCustomerAgreement',
  
  // PW Admins:
  'loadAgreements',
  'revokeAgreement',
  'setOfflineAgreement' //(comments)
])

import _ from 'lodash'
import React from 'react'
import cx from 'classnames'
import {
  TabContent,
  TabContentPane,
  TypeaheadInput,
  StoreProvider,
  CircleLoader
} from 'react-base'
import VersionStore from 'stores/VersionStore'
import UserActions from 'actions/UserActions'
import AgreementActions from 'actions/AgreementActions'
import {getAgreementDocUrl} from 'stores/AgreementStore'
import { login } from 'ids'
import GoogleOAuthButton from 'components/GoogleOAuthButton'
import AuthStore from 'stores/AuthStore'
import UserProvider from 'components/UserProvider'
import T from 'prop-types'
// import constants from 'pwConstants'
import getConfig from 'utils/uiConfig'
import BrandMain from 'components/BrandMain'
import { canGoogleAuth } from 'utils/googleAuthUtils'
import {requestPost} from 'utils/restUtils'
import ExternalLink from 'components/ExternalLink'
import LoginCardFooter from './LoginCardFooter'

const version = VersionStore.getInitialState().currentVersionDisplay

class Login extends React.Component {
  static displayName = 'Login'

  static propTypes = {
    error: T.any,
    errorLevel: T.string,
    loginFormState: T.oneOf([
      'login',
      'agreement',
      'multifactor',
      'customerSelect',
      'settingSupportCust'
    ]),
    submitting: T.bool,
    isSupport: T.bool,
    isMSSP: T.bool,
    agreementJobTitle: T.string,
    agreementSignedFor: T.string
  }

  state = {
    samlUrl: null,
    supportCustomerFilter: null,
    agreementJobTitle: '',
    agreementSignedFor: ''
  }

  UNSAFE_componentWillMount() {
    this._refs = {}
  }

  componentDidMount() {
    // When login screen is rendered, hook up Google Auth button
    if (this.props.loginFormState === 'customerSelect') {
      this._focusCustomerSelectInput()
    }
  }

  componentDidUpdate(prevProps) {
    const newTab = this.props.loginFormState
    const oldTab = prevProps.loginFormState
    if (newTab !== oldTab) {
      if (newTab === 'multifactor') {
        this._refs.tokenInput.focus()
      } else if (newTab === 'customerSelect') {
        // this._refs.custFilterInput.focus()
        this._focusCustomerSelectInput()
      }
    }
  }

  componentWillUnmount() {
    if (
      this._debouncedCheckSaml &&
      this._debouncedCheckSaml.cancel &&
      typeof this._debouncedCheckSaml.cancel === 'function'
    ) {
      this._debouncedCheckSaml.cancel()
    }
    this._refs = {}
    this._checkSaml = _.noop
  }

  _focusCustomerSelectInput = () => {
    clearTimeout(this._focusCustInputTimeout)
    this._focusCustInputTimeout = setTimeout(
      this._refs.custFilterInput.focus,
      510
    ) // Delay to allow the TabContent transition to complete
  }

  _onUserAuthentication = () => {
    if (this._refs.loginForm) {
      this._refs.loginForm.reset()
    }
  }

  _checkSaml = () => {
    const val = this._refs.emailInput.value
    if (/.+@.+/.test(val) === false) return //don't make requests for invalid emails
    requestPost(
      'saml_login_check',
      'login/saml',
      { email: val },
      { redirect401: false, trackErrors: false }
    )
    .then(data => {
      if (data && data.url) {
        this.setState({
          samlUrl: data.url
        })
      }
    })
    .catch(() => {
      this.setState({
        samlUrl: null
      })
    })
  }

  _onEmailInputChange = () => {
    if (!this._debouncedCheckSaml) {
      this._debouncedCheckSaml = _.debounce(this._checkSaml, 300)
    }
    this._debouncedCheckSaml()
  }

  _onLoginSubmit = (e) => {
    e.preventDefault()
    const { samlUrl } = this.state
    if (samlUrl) {
      window.location = samlUrl
    } else {
      UserActions.authenticateUser({
        email: this._refs.loginForm.email.value,
        password: this._refs.loginForm.password.value
      })
    }
  }

  _onMultifactorSubmit = (e) => {
    e.preventDefault()
    const form = this._refs.multifactorForm
    UserActions.authenticateUserMfa({
      otp: form.otp.value,
      token: form.token.value
    })
  }

  _onSupportCustomerFilterChange = val => {
    this.setState({ supportCustomerFilter: val })
  }

  _onCustomerSelect = customer => {
    if (customer && !!customer.id) {
      UserActions.setEmulatedCustomer(customer)
    }
  }

  _onAcceptAgreement = (e) => {
    e.preventDefault()
    const { agreementJobTitle, agreementSignedFor } = this.state
    this.setState({ acceptingTerms: true })
    AgreementActions.acceptCustomerAgreement(agreementJobTitle, agreementSignedFor)
  }

  _bindLoginFormRef = form => {
    this._refs.loginForm = form
  }

  _bindEmailInputRef = input => {
    this._refs.emailInput = input
  }

  _bindTokenInputRef = input => {
    this._refs.tokenInput = input
  }

  _bindCustFilterInputRef = input => {
    this._refs.custFilterInput = input
  }

  _bindMfaFormRef = form => {
    this._refs.multifactorForm = form
  }

  _bindNewCustomerInputRef = input => {
    this._refs.newCustomerInput = input
  }

  _bindAgreementFormRef = form => {
    this._refs.agreementForm = form
  }

  _renderLoginTab = isSaml => {
    const { props } = this
    const btnMsg = props.submitting
      ? 'Please wait...'
      : isSaml ? 'Continue to Login...' : 'Log In'
    return (
      <TabContentPane key="login">
        <div
          className={
            'alert alert-' +
            props.errorLevel +
            (props.error ? ' has_errors' : '')
          }
          id={login.errorMsg}
        >
          <h4>Error:</h4>
          <span>
            {' '}{props.error || '&nbsp;'}
          </span>
        </div>
        <form
          className="login_form pw_form"
          onSubmit={this._onLoginSubmit}
          ref={this._bindLoginFormRef}
        >
          <div className="form_fields">
            <input
              className="form-control"
              id={login.email}
              name="email"
              onChange={this._onEmailInputChange}
              placeholder="email address"
              ref={this._bindEmailInputRef}
              type="email"
              autoComplete="username"
            />
            {!isSaml
              ? <input
                  className="form-control"
                  id={login.password}
                  name="password"
                  placeholder="password"
                  type="password"
                  autoComplete="current-password"
                />
              : null}
          </div>
          <button
            className="btn btn-block btn-info btn-lg"
            disabled={props.submitting}
            id={login.submitBtn}
            type="submit"
          >
            {btnMsg}
          </button>
          <div className="links">
            <a
              className="btn btn-link btn-xs"
              href="/#password_reset"
              id={login.resetPwLink}
            >
              Reset Password
            </a>
            {canGoogleAuth()
              ? <GoogleOAuthButton
                  onError={UserActions.googleOAuthError}
                  onSuccess={UserActions.authenticateUserGoogleOAuth}
                />
              : null}
          </div>
        </form>
      </TabContentPane>
    )
  }

  _renderMfaTab = () => {
    const { props } = this
    return (
      <TabContentPane key="multifactor">
        <div>
          <h5>2-Step Verification</h5>
          <p className="form_text">
            Please enter the verification code displayed in your 2FA
            authenticator app.
          </p>
          <div
            className={
              'alert alert-' +
              props.errorLevel +
              (props.error ? ' has_errors' : '')
            }
          >
            <h4>Error:</h4>
            <span>
              {' '}{props.error || '&nbsp;'}
            </span>
          </div>
          <form
            className="login_form pw_form"
            onSubmit={this._onMultifactorSubmit}
            ref={this._bindMfaFormRef}
          >
            <div className="form_fields">
              <input
                className="form-control"
                id="token"
                name="token"
                placeholder="Verification Code"
                ref={this._bindTokenInputRef}
                type="text"
              />
            </div>
            <input id="otp" name="otp" type="hidden" value={props.otp || ''} />
            <button
              className="btn btn-block btn-info btn-lg"
              disabled={props.submitting}
              type="submit"
            >
              {props.submitting ? 'Please wait...' : 'Submit Code'}
            </button>
          </form>
        </div>
      </TabContentPane>
    )
  }

  _renderCustomerSelectTab = () => {
    const { props } = this
    let _options = []
    if (props.supportCustomerList) {
      _options = _.sortBy(props.supportCustomerList, c =>
        (c.name || '').toLowerCase()
      )
    }

    return (
      <TabContentPane key="customerSelect">
        <div className="customer_select_tab">
          <div
            className={
              'alert alert-' +
              props.errorLevel +
              (props.error ? ' has_errors' : '')
            }
            id={login.errorMsg}
          >
            <h4>Error:</h4>
            <span>
              {' '}{props.error || '&nbsp;'}
            </span>
          </div>
          {props.settingEmulatedCustomerTo !== null
            ? <div className="customer_selection">
                <CircleLoader loading />
                <div className="loading_msg">
                  Logging in as{' '}
                  <span className="cust_name">
                    {props.settingEmulatedCustomerTo}
                  </span>
                </div>
              </div>
            : <div className="customer_selection">
                <div className="support_management_links">
                  <h5>Administration</h5>
                  {AuthStore.canAccessMSSPRoutes() &&
                    <a
                      className={`btn btn-link`}
                      href={`#customer-admin/customers`}
                    >
                      Customer Administration
                    </a>
                  }
                  {AuthStore.canAccessNDRSupportRoutes() &&
                    <a
                      className={`btn btn-link`}
                      href={'#customer-admin/notifications'}
                    >
                      Support Notifications
                    </a>
                    }
                  </div>
                <h5>
                  Log in as Customer
                  <button
                    className={`btn btn-link logout_button`}
                    onClick={UserActions.logout}
                  >
                    <span className="icon icon-exit" /> Log Out
                  </button>
                </h5>
                <div className="customer_select_input_wrap">
                  <TypeaheadInput
                    maxOptsToShow={10}
                    menuFullWidth
                    onChange={this._onCustomerSelect}
                    options={_options}
                    placeholder="Log in as..."
                    ref={this._bindCustFilterInputRef}
                  />
                </div>
              </div>}
        </div>
      </TabContentPane>
    )
  }

  _renderCustomerAgreementTab = () => {
    const { agreementJobTitle, agreementSignedFor } = this.state
    const uiConfig = getConfig()
    const canAcceptAgreement = agreementSignedFor.length && agreementJobTitle.length

    return (
      <TabContentPane className="agreement" key="agreement">
        <h5>Customer Agreement</h5>
        <p className="form_text agreement_text">
          You must accept the
          <ExternalLink
            className="agreement_link"
            href={getAgreementDocUrl()}
          >
            {uiConfig.brandedProductName} Customer Agreement{' '}
            <span className="icon icon-arrow-up-right" />
          </ExternalLink>{' '}
          before continuing.
        </p>
        <form
          className="login_form pw_form"
          onSubmit={this._onAcceptAgreement}
          ref={this._bindAgreementFormRef}
        >
          <div className="form_fields">
            <input
              className={`form-control`}
              name="agreementJobTitle"
              type="text"
              value={ agreementJobTitle }
              placeholder="Job Title"
              autoComplete="off"
              onChange={ this._onInputChange }
            />
            <input
              className={`form-control`}
              name="agreementSignedFor"
              type="text"
              value={ agreementSignedFor }
              placeholder="On Behalf Of (company name)"
              autoComplete="off"
              onChange={ this._onInputChange }
            />
          </div>
          <button
            className="btn btn-primary btn-block btn-lg accept_btn"
            disabled={!canAcceptAgreement}
            type="submit"
          >
            Accept
          </button>
        </form>
        <button
          className="btn btn-block btn-sm decline_btn"
          onClick={UserActions.logout}
        >
          Decline
        </button>

        {this.state.acceptingTerms ? <CircleLoader loading /> : null}
      </TabContentPane>
    )
  }

  _onInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  render() {
    const { state, props } = this
    const isSaml = !!state.samlUrl
    const uiConfig = getConfig()

    return (
      <div className="modal_wrap" id="login_form">
        <div className="accountform_wrap">
          <BrandMain />
          <div className="slide_container">
            <div
              className={cx(
                `slide_card login_card form_state_${props.loginFormState}`,
                { has_error_alert: props.error, saml: isSaml },
                { login_card_agreements: props.loginFormState === 'agreement' }
              )}
            >
              <TabContent
                activeTabKey={props.loginFormState}
                onlyRenderActivePane
              >
                {this._renderLoginTab(isSaml)}
                {this._renderMfaTab()}
                {this._renderCustomerSelectTab()}
                {this._renderCustomerAgreementTab()}
              </TabContent>
            </div>
            <LoginCardFooter hidden={props.loginFormState === 'customerSelect'} />
          </div>
        </div>
      </div>
    )
  }
}

const WrappedLogin = props =>
  <StoreProvider store={AuthStore}>
    <UserProvider mapping={'userPermissions'}>
      <Login {...props} />
    </UserProvider>
  </StoreProvider>

WrappedLogin.displayName = 'WrappedLogin'

export default WrappedLogin

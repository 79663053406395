import _ from 'lodash'
import T from 'prop-types'
import React from 'react'
// import cx from 'classnames'
import {scaleLinear} from 'd3-scale'
import {rgb} from 'd3-color'
import {interpolateHcl} from 'd3-interpolate'
import {
  Button,
  ComboBox,
} from 'react-base'
import {
  RATING_PRESETS,
  NUMBER_PRESETS
} from 'constants/intelManagementConstants'
import {
  getThreatScore
} from 'utils/intelManagementUtils'
import {
  getIntRangeValidator
} from 'pw-validators'
import InfoHint from 'components/InfoHint'

const validateOneToOneHundred = getIntRangeValidator(1, 100)

function _stopPropagation(e) {
  e.stopPropagation()
}

const threatRatingScale = scaleLinear()
  .domain([0, 100]) // Width percentage
  .interpolate(interpolateHcl)
  .range([
    rgb("#dbbb47"),
    rgb("#ff0000")
  ])


export default class extends React.PureComponent {
  static displayName = "IMS.ThreatRatingInput";

  static propTypes = {
    currentConfidence: T.number,
    currentSeverity: T.number,
    defaultUnlocked: T.bool, // If true, will show discrete sev/conf inputs
    onChange: T.func,
    onChangeArgs: T.array,

    readOnly: T.bool, // TODO handle this
    required: T.bool,
  };

  static defaultProps = {
    defaultUnlocked: false,
    onChangeArgs: [],
    readOnly: false,
    required: true
  };

  state = {
    manualInputActive: this.props.defaultUnlocked,
    severityVal: this.props.currentSeverity,
    confidenceVal: this.props.currentConfidence
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      currentConfidence,
      currentSeverity
    } = nextProps
    const {
      confidenceVal,
      severityVal
    } = this.state
    let newState = {}
    let needsStateChange = false
    if (currentConfidence !== confidenceVal) {
      newState.confidenceVal = currentConfidence
      needsStateChange = true
    }
    if (currentSeverity !== severityVal) {
      newState.severityVal = currentSeverity
      needsStateChange = true
    }
    if (needsStateChange) {
      this.setState(newState)
    }
  }

  _toggleLocked = () => {
    this.setState({
      manualInputActive: !this.state.manualInputActive
    })
  };

  _handleInputChange = (newValue, fieldName) => {
    const {
      onChange,
      onChangeArgs
    } = this.props
    onChange(_.set({}, fieldName, +newValue), ...onChangeArgs)
  };

  _toggleManualEntry = () => {
    this.setState({
      manualInputActive: !this.state.manualInputActive
    })
  };

  render() {
    const {
      onChange,
      onChangeArgs,
      readOnly,
      required,
    } = this.props
    const {
      manualInputActive,
      severityVal,
      confidenceVal
    } = this.state
    const currentThreatScore = getThreatScore(severityVal, confidenceVal)

    return manualInputActive ? (
      <div className="intel_rating_scale manual_entry_active">
        <div className="rating_text_input_wrap">
          <InfoHint type={ `confidence` }>C</InfoHint>
          <ComboBox
            cancelOnEsc
            className={ `grid_input cell_content ${ !confidenceVal ? 'pw_text_input_warning' : '' }` }
            dataType="number"
            initialValue={ confidenceVal }
            onClick={ _stopPropagation }
            onSave={ this._handleInputChange }
            onSaveArgs={ ['confidence'] }
            options={ NUMBER_PRESETS }
            placeholder={ `--` }
            saveOnBlur
            validator={ validateOneToOneHundred }
          />
        </div>
        <div className="rating_text_input_wrap">
          <InfoHint type={ `confidence` }>S</InfoHint>
          <ComboBox
            cancelOnEsc
            className={ `grid_input cell_content ${ !severityVal ? 'pw_text_input_warning' : '' }` }
            dataType="number"
            initialValue={ severityVal }
            onClick={ _stopPropagation }
            onSave={ this._handleInputChange }
            onSaveArgs={ ['severity'] }
            options={ NUMBER_PRESETS }
            placeholder={ `--` }
            saveOnBlur
            validator={ validateOneToOneHundred }
          />
        </div>
        <div
          className="rating_input_buttons btn-icon icon-checkmark"
          data-tooltip={ `Hide Conf/Sev inputs` }
          onClick={ this._toggleManualEntry }
        />
      </div>
    ) : (
      <div className={ `intel_rating_scale ${ required && (!severityVal || !confidenceVal) ? 'invalid' : '' } ${ readOnly ? "read_only" : "editable" }` }>
        <div className="rating_line_wrap">
          { _.map(RATING_PRESETS, (preset, presetKey) => {
            const [
            presetSeverity,
            presetConfidence
            ] = presetKey.split(';')
            const isActive = severityVal === +presetSeverity && confidenceVal === +presetConfidence
            const threatScore = getThreatScore(presetSeverity, presetConfidence)

            return (
              <Button
                args={ [{severity: +presetSeverity, confidence: +presetConfidence}].concat(onChangeArgs) }
                className={ `rating_preset preset_${ preset.name.toLowerCase() } ${ isActive ? "is_active" : '' }` }
                data-tooltip={ `Threat Rating ${ threatScore }: ${ preset.name }\nConfidence: ${ presetConfidence } Severity: ${ presetSeverity }\n${ preset.desc }` }
                data-tooltip-class={ `rating_preset_tooltip preset_${ name.toLowerCase() }` }
                key={ presetKey }
                onClick={ !readOnly ? onChange : _.noop }
                style={ {
                  left: `${ threatScore }%`,
                  color: `${ threatRatingScale(threatScore) }`
                } }
              />
            )
          }) }
          { currentThreatScore && currentThreatScore > 0 ? (
            <div
              className="rating_scale_current_indicator"
              data-tooltip={ `Current Threat Rating: ${ currentThreatScore }\nConfidence: ${ confidenceVal }, Severity: ${ severityVal }` }
              data-tooltip-class={ `rating_preset_tooltip` }
              style={ {
                left: `${ currentThreatScore }%`,
                borderColor: `${ threatRatingScale(currentThreatScore) }`
              } }
            />
          ) : null }
        </div>
        { !readOnly && (
          <div
            className="rating_input_buttons btn-icon icon-zoomin"
            data-tooltip={ `Advanced: Show numeric inputs` }
            onClick={ this._toggleManualEntry }
          />
        ) }
      </div>
    )
  }
}

import * as _ from 'lodash'
import {
  observable,
  flow,
  computed
} from 'mobx'
import {
  RestError,
  rethrowAsRestError
} from 'utils/restUtils'
import { Store } from 'stores/mobx/StoreManager'

const READ_ONLY_TOKEN = "b3e8ae893dc563487dac466752c7c3e3a4ebbc070fb840abc3c79cef1305b42d"

export class HelpVideosStore extends Store {

  @observable isLoading: boolean = false
  @observable isLoadingProject: boolean = false
  @observable project: any = {}
  @observable projects: any = null
  @observable error: RestError = null

  fetchProjects = () => {
    let req = self.fetch(`https://api.wistia.com/v1/projects.json?access_token=${READ_ONLY_TOKEN}`, {
      method: 'GET'
    })

    return req.then(
      response => {
        return response.ok ?
          response :
          response.text()
            .then(responseText => {
              throw new RestError(response, responseText)
            })
            .catch(rethrowAsRestError) as any // (╯ರ ~ ರ）╯︵ ┻━┻
      },
      rethrowAsRestError
    )
  }

  fetchProject = (hashedId) => {
    let req = self.fetch(`https://api.wistia.com/v1/projects/${hashedId}.json?access_token=${READ_ONLY_TOKEN}`, {
      method: 'GET'
    })

    return req.then(
      response => {
        return response.ok ?
          response :
          response.text()
            .then(responseText => {
              throw new RestError(response, responseText)
            })
            .catch(rethrowAsRestError) as any // (╯ರ ~ ರ）╯︵ ┻━┻
      },
      rethrowAsRestError
    )
  }

  fetchMediaForProject = (hashedId) => {
    let req = self.fetch(`https://api.wistia.com/v1/medias.json?project_id=${hashedId}&type=video&access_token=${READ_ONLY_TOKEN}`, {
      method: 'GET'
    })

    return req.then(
      response => {
        return response.ok ?
          response :
          response.text()
            .then(responseText => {
              throw new RestError(response, responseText)
            })
            .catch(rethrowAsRestError) as any // (╯ರ ~ ರ）╯︵ ┻━┻
      },
      rethrowAsRestError
    )
  }

  loadProjects = flow(function * () {
    if (this.isLoading) {
      console.warn(`Tried to reload projects while still loading`)
      return
    }
    this.projects = []
    this.isLoading = true
    this.error = null
    let projects
    try {
      projects = yield this.fetchProjects()
    }
    catch (error) {
      this.error = error
    }

    if (projects) {
      this.projects = yield projects.json()
    }
    this.isLoading = false
  })

  loadProject = flow(function * (projectId) {
    if (this.isLoadingProject || !projectId || projectId === this.project.hashedId) {
      console.warn(`Cant load project`)
      return
    }
    this.project = {}
    this.isLoadingProject = true
    this.error = null
    let project: Response
    let media: Response
    try {
      project = yield this.fetchProject(projectId)
      media = yield this.fetchMediaForProject(projectId)
    }
    catch (error) {
      this.error = error
    }

    if (project) this.project = yield project.json()
    if (media && project) {
      this.project.media = yield media.json()
    }

    this.isLoadingProject = false
  })

  @computed
  get projectCount () {
    return this.projects.length
  }
}
import _ from 'lodash'
import React from 'react'
import {ScrollBars, TextOverflow} from 'react-base'
import IntelCardPropTypes from '../PropTypes'
import DataCell from './DataCell'
import UrlValue from 'components/values/UrlValue'
import IpValue from 'components/values/IpValue'
import ipaddr from 'pw-ipaddr.js'

const ipAddressUtil = ipaddr.util

class FileInfo extends React.Component {
  static displayName = 'FileInfo';
  static propTypes = IntelCardPropTypes.dataCardShape;

  render() {
    let {props} = this
    const source = props.data('behavior.source')._value
    const verdict = props.data('behavior.verdict')._value
    return (
      <div className="intel_card intel_data_card intel_data_fileanalysis">
        <h3>Dynamic Analysis</h3>
        <div className="intel_card_content">
          { props.data ? this.renderData() : null }
        </div>
      </div>
    )
  }

  renderData = () => {
    let {props} = this
    let data = props.data

    let showData = ['hosts', 'tcp', 'udp', 'http'].some(netType => {
      let d = data(`behavior.network.${ netType }`)
      return d.isLoading() || !_.isEmpty(d.valueOf())
    })

    return showData ? (
      <ScrollBars
        outside
        classes="pw_scrollbars_light"
        slimShady
        slimShadyDarker
      >
        <DataCell
          animateHeight
          label="IP Addresses Associated"
          value={ data('behavior.network.hosts') }
          default="None"
          formatter={ ips =>
            <ul>{ _(ips).filter(ip => !ipAddressUtil.isSpecialRange(ip)).map((ip, i) =>
              <li key={ i }><IpValue ip={ ip } /></li>
            ).value() }</ul>
          }
        />

        <DataCell
          animateHeight
          label="TCP Connections"
          value={ data('behavior.network.tcp') }
          default="None"
          formatter={ this.renderConnectionList }
        />

        <DataCell
          animateHeight
          label="UDP Connections"
          value={ data('behavior.network.udp') }
          default="None"
          formatter={ this.renderConnectionList }
        />

        <DataCell
          animateHeight
          label="HTTP Requests"
          value={ data('behavior.network.http') }
          default="None"
          className="http"
          formatter={ requests =>
            _.isEmpty(requests) ? 'None' : (
              <ul>{ requests.map((req, i) =>
                <li key={ i }>
                  <TextOverflow><span>{ req.method }</span> <UrlValue url={ req.uri.replace(/^https?:\/\//, '') } /></TextOverflow>
                  <TextOverflow>
                    {
                      req['user-agent']
                        ? (
                          <span>
                            <span className="lbl" data-tooltip="User Agent">UA:
                            </span> {req['user-agent']}
                          </span>
                        ): null
                    }
                  </TextOverflow>
                </li>
              ) }</ul>
            )
          }
        />

        { /* future bonus...
        <DataCell
          animateHeight
          label="Files Written"
          value={ data('behavior.behavior.summary.files') }
          default="None"
          formatter={ files =>
            _.isEmpty(files) ? 'None' : (
              <ul>{ files.map((file, i) =>
                <TextOverflow key={ i } tagName="li">{ file }</TextOverflow>
              ) }</ul>
            )
          }
        />
        */ }
      </ScrollBars>
    ) : (
      <h5>No File Analysis Information Available</h5>
    )
  };

  renderConnectionList = (connections) => {
    if (_.isEmpty(connections)) {
      return 'None'
    }

    let uniques = {}

    connections.forEach(({src, sport, dst, dport}) => {
      let key = `${src}:${sport}-${dst}:${dport}`
      if (!uniques[key]) {
        let srcInternal = ipAddressUtil.isSpecialRange(src)
        let dstInternal = ipAddressUtil.isSpecialRange(dst)
        uniques[key] = {
          count: 0,
          reverse: (dstInternal && !srcInternal) || (dstInternal && srcInternal && dport < sport),
          src: srcInternal ? <span className="specialrange">{srcInternal}</span> : <IpValue ip={src} />,
          dst: dstInternal ? <span className="specialrange">{dstInternal}</span> : <IpValue ip={dst} />,
          sport,
          dport
        }
      }
      uniques[key].count++
    })

    return (
      <table>
        <tbody>
          { _.map(uniques, ({src, sport, dst, dport, count, reverse}, key) => {
            // possible to be missing src and could be missing port port:-1
            if (!src.length) {
              return (
                <tr key={key}>
                  <td>{dst}{dport > 0 ? `:${dport}` : ''}</td>
                  <td>({count})</td>
                </tr>
              )
            }

            return (
              <tr key={ key }>
                <td>{ reverse ? dst : src }:{ reverse ? dport : sport }</td>
                <td className="arrow"><span className={ `icon icon-arrow-${ reverse ? 'left': 'right' }` } /></td>
                <td>{ reverse ? src : dst }:{ reverse ? sport : dport }</td>
                <td>({ count })</td>
              </tr>
            )
            }
          )}
        </tbody>
      </table>
    )
  };
}


export default FileInfo

import _ from 'lodash'
import AnalyticsActions from 'actions/AnalyticsActions'
import CommonViewActions from 'actions/CommonViewActions'
import {json2csv} from 'json-2-csv'
import {saveAs} from 'filesaver.js'
import {formatDate, formatDateWith} from 'utils/timeUtils'
import {readCSRFCookie, CSRF_HEADER} from 'ui-base/src/util/csrfUtil'
import {baseURL} from 'utils/restUtils'

const PCAP_FILENAME_TIME_FORMAT = "YYYY-MM-DD-HHmmssSSSZZ"


function _onDownloadError(iframe) {
  let err = {
    type: 'error',
    dismissTimer: 180000
  }
  try {
    let iframeDoc = iframe.contentDocument || iframe.contentWindow.document
    let message = JSON.parse(iframeDoc.body.innerText)
    err.heading = _.get(message, 'error.message') || "Download Error"
    err.message = _.get(message, 'error.info') || "Please try again soon."
  } catch (e) {
    // Could not access iframe content, use fallback notification
    err.heading = "Download Error"
    err.message = "Unknown error occurred while downloading. Please try again soon."
  }
  CommonViewActions.Notification.add(err)
}


/**
 * Generate a default file name for a PCAP download, based on the included netflows.
 * @param {Array} flows
 * @returns {String}
 */
export function getDefaultPcapFileNameForNetflows(flows) {
  if (flows.length === 0) { return 'PW' }
  let output = "PW_"
  let earliestFlow = _.min(flows, f => f.details.startTime)
  if (earliestFlow) {
    output += formatDateWith(earliestFlow.details.startTime, PCAP_FILENAME_TIME_FORMAT, true) + "_"
  }
  let latestFlow = _.max(flows, f => f.details.endTime)
  if (latestFlow) {
    output += formatDateWith(latestFlow.details.endTime, PCAP_FILENAME_TIME_FORMAT, true)
  }
  return output
}

/**
 * Generate a default file name for a PCAP download, based on the included observations.
 * @param {Array} observations
 * @returns {String}
 */
export function getDefaultPcapFileNameForObservations(observations) {
  if (observations.length === 1) {
    return `PW_obs_${ observations[0].id }`
  }
  let times = _.pluck(observations, 'occurredAt')
  let earliest = _.min(times)
  let latest = _.max(times)
  let output = `PW_obs_${ formatDateWith(earliest, PCAP_FILENAME_TIME_FORMAT, true) }`
  if (earliest !== latest) {
    output += `_${ formatDateWith(latest, PCAP_FILENAME_TIME_FORMAT, true) }`
  }
  return output
}

/**
 * Generate a default file name for a PCAP download.
 * @param {Object} pcapRequest
 * @returns {String}
 */
export const getDefaultPcapFileName = pcapRequest => {
  return `PW_${
    formatDateWith(pcapRequest.startTime, PCAP_FILENAME_TIME_FORMAT, true)
  }_${
    formatDateWith(pcapRequest.endTime, PCAP_FILENAME_TIME_FORMAT, true)
  }`
}

export const getDefaultExplorerDeauxFileName = id => {
  return `vz_${id}.csv`
}

/**
 * Trigger a download from an API endpoint using a background iframe
 * @param url
 * @param method
 * @param params
 */
function _download(url, method, params = {}) {
  console.log(`Starting download in iframe: ${method} ${url}`, params)

  let iframe = document.getElementById('download_frame')
  if (!iframe) {
    iframe = document.createElement('iframe')
    iframe.name = iframe.id = iframe.className = 'download_frame'
    document.body.appendChild(iframe)
    // onload should only fire when an error response is received by the iframe
    iframe.onload = () => _onDownloadError(iframe)
  }

  // Insert a temporary form element and trigger its submission to get the browser
  // to treat the POST resposne as a file download
  let form = document.createElement('form')
  form.method = method
  form.action = url
  form.target = 'download_frame'

  // Always add the _csrf token for POSTs
  if (method === 'POST') {
    params = _.assign({
      '_csrf': readCSRFCookie()
    }, params)
  }
  _.forOwn(params, (value, name) => {
    let input = document.createElement('input')
    input.type = 'hidden'
    input.name = name
    input.value = value
    form.appendChild(input)
  })

  document.body.appendChild(form)
  form.submit()
  document.body.removeChild(form)
}


/**
 * Trigger download of a FileRep observation's file content.
 * @param {String} observationId
 * @param {String} sensorId
 */
export function downloadObservationFile(observationId, sensorId) {
  if (observationId) {
    _download(`${baseURL}observations/${observationId}/file/download`, 'GET', {sensorId})

    // Track
    AnalyticsActions.event({
      eventCategory: 'observation',
      eventAction: 'download',
      eventLabel: 'file'
    })
  }
}


/**
 * Trigger download of a a list of objects as a .csv document
 * @param {Object} data - Data to be converted into the CSV file's contents
 * @param {String} fileName
 * @param {Bool} appendTimeStamp
 */
export function downloadAsCsv(data, fileName = 'pw_download', appendTimeStamp = false) {
  if (appendTimeStamp) {
    fileName += '_' + formatDateWith(Date.now(), PCAP_FILENAME_TIME_FORMAT, true)
  }
  fileName = fileName.indexOf('.csv') === -1 ? fileName + '.csv' : fileName
  try {
    // let data2 = _.map(data, d => {
    //   return genericUtil.flattenObject(d)
    // })
    // console.log('before', data2)
    json2csv(data, (err, csv) => {
      if (err) throw err
      let blobData = new Blob([csv], {type: "text/csv;charset=utf-8"})
      saveAs(blobData, fileName)
    })
  } catch (e) {
    console.error('Unable to convert data to CSV and download', data)
  }
}


// export {getDefaultPcapFileNameForNetflows}
// export {getDefaultPcapFileNameForObservations}
// export {downloadPcapForNetflows}
// export {downloadPcapForObservations}
// export {downloadObservationFile}
// export {downloadAsCsv}

import _ from 'lodash'
import T from 'prop-types'
import IT from 'react-immutable-proptypes'
import {
  Schema,
  TYPES
} from 'pw-schemer'

export const RECOMMENDED_PSEUDO_ID = -1

export const CAPTURE_MODES = _.mapValues({
  NETFLOW: 1,
  PACKET: 1,
  STREAM_HEAD: 1,
  NONE: 1
}, (v, k) => k.toLowerCase())

export const CAPTURE_MODE_OPTIONS = _.values(CAPTURE_MODES)

const profileExceptionSchema = new Schema({
  protocolId: {
    display: "Protocol ID",
    type: TYPES.INT,
    path: 'id'
  },
  mode: {
    display: "Capture Mode",
    type: TYPES.ENUM,
    propType: T.string,
    options: CAPTURE_MODES,
    path: 'mode'
  }
})

const ppcProfileSchema = new Schema({
  id: {
    display: "State ID",
    type: TYPES.INT,
    path: 'id'
  },
  name: {
    display: "State Name",
    type: TYPES.STRING,
    path: 'name'
  },
  defaultMode: {
    display: "State Default Mode",
    type: TYPES.ENUM,
    options: CAPTURE_MODES,
    path: 'default_mode',
    propType: T.string,
  },
  backbufferHighThreshold: {
    display: "State Backbuffer Upper Threshold",
    type: TYPES.INT,
    path: 'backbuffer_high_threshold'
  },
  backbufferLowThreshold: {
    display: "State Backbuffer Lower Threshold",
    type: TYPES.INT,
    path: 'backbuffer_low_threshold'
  },
  streamHeadCutoff: {
    display: "State Stream Head Cutoff",
    type: TYPES.INT,
    path: 'stream_head_cutoff'
  },
  exceptions: {
    display: "State Protocol Exceptions",
    path: 'exceptions',
    schema: profileExceptionSchema
  }
})

export const ppcSchema = new Schema({
  id: {
    display: "PPC Profile ID",
    type: TYPES.INT,
    formatInbound: () => RECOMMENDED_PSEUDO_ID // TODO no real IDs yet
  },
  name: {
    display: "PPC Profile Name",
    type: TYPES.STRING,
    path: 'name'
  },
  profiles: {
    display: "PPC Profile States",
    path: 'profiles',
    schema: ppcProfileSchema,
  }
})

export const PPC_SHAPE = IT.mapContains(ppcSchema.getPropTypes())

import {observable} from 'mobx'
import {schemer} from 'pw-schemer-mobx'
import DeviceAttribute from './DeviceAttribute'

export class TimestampAttr extends DeviceAttribute {
  constructor(initial) {
    super(initial, {
      transform: (attr = {}) => ({
        ...attr,
        value: attr.value && attr.value.timestamp
      })
    })
  }

  @observable
  @schemer.datetime
  value: number
}
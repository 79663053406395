import _ from 'lodash'
import T from 'prop-types'
import IT from 'react-immutable-proptypes'
import {
  Schema,
  TYPES
} from 'pw-schemer'

const normalizeDate = inputStr => {
  return inputStr ? +new Date(inputStr) : -1
}


export const SENSOR_MODES = {
  profiling: {
    display: 'Profiling',
    desc: 'Testing Only',
    confirmMessage: 'Are you sure you want to switch this sensor to Profiling mode? Packet data seen by this sensor will no longer be captured.'
  },
  normal: {
    display: 'Live Capture',
    desc: 'Data Retention Active',
    confirmMessage: 'Are you sure you want to switch this sensor to Live Capture mode? Packet data seen by this sensor will be captured and stored according to the applied capture profile and data retention policy.'
  }
}


export const sensorSetSchema = new Schema({
  id: {
    type: TYPES.INT,
    required: true,
    path: 'id'
  },
  name: {
    type: TYPES.STRING,
    required: true,
    path: 'name',
    display: "Sensor Set Name",
    editable: true
  },
  isDefault: {
    type: TYPES.BOOL,
    required: true,
    path: 'default_set',
    display: "Default Sensor Set",
    editable: true
  }
})



export const sensorLocationSchema = new Schema({
  id: {
    type: TYPES.INT,
    required: true,
    path: 'id'
  },
  locationId: {
    type: TYPES.INT,
    required: true,
    path: 'location_id',
    display: "Sensor Location",
    editable: true
  },
  cidrRange: {
    type: TYPES.STRING,
    required: true,
    path: 'cidr_range',
    display: "Location CIDR Range",
    editable: true
  }
})


export const sensorSchema = new Schema({
  id: {
    type: TYPES.INT,
    required: true,
    path: 'id'
  },
  name: {
    type: TYPES.STRING,
    required: true,
    path: 'friendly_name',
    display: "Sensor Name",
    editable: true
  },
  sensorSetId: {
    type: TYPES.ENUM,
    propType: T.number,
    required: true,
    path: 'agent_set_id',
    display: "Sensor Set",
    options: [],
    editable: true,
  },
  ipAddress: {
    type: TYPES.STRING,
    path: 'ip_address'
  },
  version: {
    type: TYPES.STRING,
    path: 'version'
  },
  internalIps: {
    type: TYPES.LIST,
    itemType: TYPES.SUBNET,
    path: 'internal_ips',
    display: "Internal IP Addresses & CIDR Ranges",
    editable: true
  },
  // uuid: T.string.isRequired,
  // customerId: T.number.isRequired,
  isEnabled: {
    type: TYPES.BOOL,
    required: true,
    path: 'enabled'
  },
  isDownloaded: {
    type: TYPES.BOOL,
    required: true,
    path: 'downloaded',
  },
  isActive: {
    type: TYPES.BOOL,
    required: true,
    path: 'is_active'
  },
  isConfigApplied: {
    type: TYPES.BOOL,
    required: true,
    path: '_config_applied'
  },
  // policyId: T.any,
  // env: T.string.isRequired,
  updatedAt: {
    type: TYPES.DATETIME,
    required: true,
    path: 'updated_at',
  },
  lastSeenAt: {
    type: TYPES.DATETIME,
    required: true,
    path: 'last_seen',
  },
  installToken: {
    type: TYPES.STRING
  },

  // Sensor Config fields
  // sensorConfigId: T.number.isRequired,
  sensorMode: {
    path: 'sensor_config.sensor_mode',
    type: TYPES.ENUM,
    propType: T.number,
    options: Object.keys(SENSOR_MODES),
    editable: true,
    bulkEditable: true
  },

  bandwidthLimit: {
    path: 'sensor_config.bandwidth_limit',
    display: "Bandwidth Cap/Limit",
    type: TYPES.BYTES_PER_SEC,
    editable: true,
    bulkEditable: true
  },
  backbufferMemory: {
    type: TYPES.BYTES,
    path: 'sensor_config.backbuffer_memory_size',
    display: "Memory Backbuffer",
    editable: true,
    bulkEditable: true
  },
  backbufferDisk: {
    type: TYPES.INT, // MB
    path: 'sensor_config.backbuffer_disk_size',
    display: "Disk Backbuffer",
    editable: {
      type: TYPES.SIZE,
    },
    bulkEditable: true
  },
  filters: {
    type: TYPES.LIST,
    itemType: TYPES.STRING,
    propType: IT.listOf(IT.mapContains({
      bpf: T.string
    })),
    path: 'sensor_config.filters',
    display: "BPF Filters",
    editable: true,
    bulkEditable: true
  },
  proxies: {
    type: TYPES.LIST,
    itemType: TYPES.STRING,
    propType: IT.listOf(T.string),
    path: 'sensor_config.proxies',
    display: "Proxies",
    editable: true,
    bulkEditable: true
  },
  interfaces: {
    type: TYPES.LIST,
    itemType: TYPES.STRING,
    propType: IT.listOf(T.string),
    path: 'sensor_config.interfaces',
    display: "Capture Interfaces",
    editable: true,
    bulkEditable: true
  },
  // suricataProfileId: T.number, // TODO?
  proxyHeaderList: {
    type: TYPES.LIST,
    itemType: TYPES.STRING,
    propType: IT.listOf(T.string),
    path: 'sensor_config.proxy_header_list',
    display: "Proxy Header List",
    editable: true,
    bulkEditable: true
  },

  configUpdatedAt: {
    type: TYPES.DATETIME,
    path: 'sensor_config.updated_at',
    display: 'Config Updated At',
  },
  configAppliedAt: {
    type: TYPES.DATETIME,
    path: 'sensor_config.applied_at',
    display: 'Config Applied At',
  },

  // adaptiveProfileId: T.number,
  captureProfileId: {
    type: TYPES.INT,
    path: 'sensor_config.capture_profile_id',
    editable: true,
    bulkEditable: true
  },

  // From Sensor Config > Capture profile (not refreshed with capture profile store changes)
  captureProfileName: {
    type: TYPES.STRING,
    display: "Capture Profile",
    path: 'sensor_config.capture_profile.name'
  },

  // Sensor Location fields
  sensorLocations: {
    type: TYPES.SCHEMA,
    propType: IT.listOf(
      IT.contains({
        id: T.number.isRequired,
        // sensorId: T.number.isRequired,
        locationId: T.number.isRequired,
        cidrRange: T.string.isRequired
      })
    ),
    path: 'locations',
    schema: sensorLocationSchema,
    display: 'Sensor Locations',
    editable: true
  }
})

// React object shapes
export const SENSOR_SHAPE = IT.mapContains(sensorSchema.getPropTypes())
export const SENSOR_SET_SHAPE = IT.mapContains(sensorSetSchema.getPropTypes())
export const SENSOR_LOCATION_SHAPE = IT.mapContains(sensorLocationSchema.getPropTypes())

export const SENSOR_MAX_NAME_LENGTH = 50
export const SENSOR_SET_MAX_NAME_LENGTH = 50

export const PERMS = {
  SENSORS: {
    INDEX: 'sensors:index',
    READ: 'sensors:read',
    UPDATE: 'sensors:update',
    CREATE: 'sensors:create',
    DELETE: 'sensors:delete',
    APPLY: 'sensors:apply',
    COMMANDS: 'sensors:commands',
    CONFIG: 'sensors:config',
    DISABLE: 'sensors:disable',
    ENABLE: 'sensors:enable'
  },
  SENSOR_SETS: {
    INDEX: 'sensor-sets:index',
    READ: 'sensor-sets:read',
    UPDATE: 'sensor-sets:update',
    CREATE: 'sensor-sets:create',
    DELETE: 'sensor-sets:delete',
  },
  SENSOR_LOCATIONS: {
    INDEX: 'sensor-locations:index',
    READ: 'sensor-locations:read',
    UPDATE: 'sensor-locations:update',
    CREATE: 'sensor-locations:create',
    DELETE: 'sensor-locations:delete',
  },
  LOCATIONS: {
    INDEX: 'locations:index',
    READ: 'locations:read',
    UPDATE: 'locations:update',
    CREATE: 'locations:create',
    DELETE: 'locations:delete',
  },
  CAPTURE_PROFILES: {
    INDEX: 'capture-profiles:index',
    READ: 'capture-profiles:read',
    UPDATE: 'capture-profiles:update',
    CREATE: 'capture-profiles:create',
    DELETE: 'capture-profiles:delete',
  }
}

// Commands that may be programatically issued (e.g. start, stop)
export const ISSUABLE_COMMANDS = {
  START: {
    apiCommand: 'startsensor',
    display: 'start'
  },
  STOP: {
    apiCommand: 'stopsensor',
    display: 'stop'
  }
}

export const ISSUABLE_COMMAND_OPTIONS = _.mapValues(ISSUABLE_COMMANDS, (v, k) => k)

export const SENSOR_RESTRICTED_ROLENAME = 'sensor_restricted'

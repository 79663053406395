import React, { FunctionComponent, useCallback, useRef } from 'react'
import {
  DropdownMenu,
  MenuItem,
  ScrollBars
} from 'react-base'
import RELATIVE_TIME_PRESETS, {Preset} from 'utils/relativeTimePresets'

export interface RelativeTimePresetDropdownProps {
  onChange: (presetFn) => void
}

export const RelativeTimePresetDropdown: FunctionComponent<RelativeTimePresetDropdownProps> = props => {
  const menuRef = useRef(null)
  const handleOptionClick = useCallback((presetFn) => {
    props.onChange(presetFn)
    menuRef.current.closeMenu()
  }, [menuRef.current])

  return (
    <span className={`relative_time_input`}>
      <DropdownMenu
        buttonClasses="dropdown-toggle btn btn-xs btn-default"
        menuClasses={`relative_time_presets_dropdown`}
        closeOnItemClick
        label={ <span><span className={`icon icon-clock`} /> Time Range...</span> }
        omitCaret
        ref={menuRef}
        tooltip="Choose a preset time range"
      >
        <li className={`relative_time_picker`}>
          <h5>Time Range Presets</h5>
          <div className={`preset_cols`}>
            {RELATIVE_TIME_PRESETS.map((presetCol: Preset[], i) => (
              <ul className={`preset_col`} key={`col_${i}`}>
                {presetCol.map((preset: Preset) => (
                  <MenuItem
                    key={preset.id}
                    args={[preset]}
                    onClick={handleOptionClick}
                  >{preset.display}</MenuItem>
                ))}
              </ul>
            ))}
          </div>
        </li>
      </DropdownMenu>
    </span>
  )
}

RelativeTimePresetDropdown.displayName = "RelativeTimePresetDropdown"

import React from 'react'
import ReactDOM from 'react-dom'
import T from 'prop-types'
import cx from 'classnames'
import { formatNumber } from 'pw-formatters'
import memoize from 'memoize-one'
import { getTimelineTickFormat } from '../../utils/timeUtils'
import { path } from 'd3-path'
import {DimensionAware} from 'react-base'
import AxisScaleAware from './AxisScaleAware'
import Ruler from './SensorTimelineRuler'

const valueFormatter = (val, item) =>
  (val == null ? {textData: '', label:''} : {textData: Math.floor(val), label: '%' + (item.className === 'total' ? ' Overall' : '')})
const timeSeriesShape = T.arrayOf(T.shape({timestamp: T.number, value: T.number}))

// Wrapper component that assembles the individual counters into a single memoized
// `items` array which is needed by AxisScaleAware, Ruler, etc.
class Sensor2HealthGraphWithData extends React.PureComponent {
  // Note: all props treated as immutable
  static propTypes = {
    captureHealth: timeSeriesShape,
    intakeHealth: timeSeriesShape,
    performanceHealth: timeSeriesShape,
    processingHealth: timeSeriesShape,
    totalHealth: timeSeriesShape,

    rulerTime: T.number, //position for ruler, or null for no ruler
    onTimeHover: T.func, //(number|null) callback for mouseover
    className: T.string,
    xAxis: T.bool,
    title: T.string,

    // From DimensionAware HOC:
    width: T.number,
    height: T.number
  }

  static defaultProps = {
    title: 'Sensor Health'
  }

  _getItemsData = memoize(
    (totalHealth, captureHealth, intakeHealth, performanceHealth, processingHealth, height) => {
      const labels = ['Overall Sensor Health', 'Performance Health', 'Capture Health', 'Processing Health', 'Upload Health']
      const labelAbbrs = ['Total', 'Perf', 'Capt', 'Proc', 'Send']
      const classNames = ['total', 'performance', 'capture', 'processing', 'intake']
      const items = [totalHealth, captureHealth, performanceHealth, processingHealth, intakeHealth].reduce((out, d, i, all) => {
        if (d) {
          out.push({
            data: d,
            showInRuler: true, //d !== totalHealth,
            rulerYScale: () => i * height / all.length,
            label: labels[i],
            labelAbbr: labelAbbrs[i],
            className: classNames[i]
          })
        }
        return out
      }, [])
      return items
    }
  )

  render() {
    const {totalHealth, captureHealth, intakeHealth, performanceHealth, processingHealth, height} = this.props
    const items = this._getItemsData(totalHealth, captureHealth, intakeHealth, performanceHealth, processingHealth, height)
    return <AxisScaleAwareSensor2HealthGraphRendering
      {...this.props}
      items={items}
    />
  }
}

class Sensor2HealthGraphRendering extends React.PureComponent {
  _getXAxisInfo = memoize((xScale, xTickCount) => {
    const [minTime, maxTime] = xScale.domain()
    const xTicks = xScale.ticks(xTickCount)
    // Ensure start/end times are included
    if (!xTicks[0] || xTicks[0] !== minTime) {
      xTicks.unshift(minTime)
    }
    if (xTicks[xTicks.length - 1] !== maxTime) {
      xTicks.push(maxTime)
    }
    return {
      xAxisLabels: <div className="axis_labels x">
        {xTicks.map((time, i) =>
          <span key={i} style={{left: xScale(time)}} className="axis_label x">{getTimelineTickFormat()(time)}</span>
        )}
      </div>
    }
  })

  _getAllTimes = memoize((items) => {
    const allTimes = new Set()
    for (let {data} of items) {
      if (data) {
        for (let {timestamp} of data) {
          allTimes.add(timestamp)
        }
      }
    }
    return allTimes
  })

  _getItemLabels = memoize((items, height) => {
    const itemHeight = height / items.length
    return <div className="axis_labels y">
      {items.map((item, i) =>
        item.className === 'total' ? null : <span
          key={i}
          style={{ top: itemHeight * i }}
          className="axis_label y"
          data-tooltip={item.label}
        >{item.labelAbbr}</span>
      )}
    </div>
  })

  _onMouseMove = e => {
    const fn = this.props.onTimeHover
    if (fn) {
      const {xScale, items} = this.props
      let xPos = e.clientX - ReactDOM.findDOMNode(this).getBoundingClientRect().left
      const range = xScale.range()
      xPos = Math.max(range[0], Math.min(range[1], xPos)) //constrain to time range
      const allTimes = this._getAllTimes(items)
      const exactTime = +xScale.invert(xPos)
      let closestTime = 0
      allTimes.forEach(t => {
        if (Math.abs(exactTime - t) < Math.abs(exactTime - closestTime)) {
          closestTime = t
        }
      })
      fn(closestTime)
    }
  }

  _onMouseLeave = e => {
    const fn = this.props.onTimeHover
    if (fn) fn(null)
  }


  render() {
    const {props} = this
    const {
      xScale,
      yScale,
      xTickCount,
      xAxis,
      yAxis,
      items,
      height,
      rulerTime,
      title
    } = props

    let contents
    if (xScale && yScale) {
      const {xAxisLabels} = xAxis ? this._getXAxisInfo(xScale, xTickCount) : {}

      contents = <React.Fragment>
        <svg width="100%" height="100%">
          <HealthDataSVG
            {...props}
          />
        </svg>

        {xAxisLabels}
        {this._getItemLabels(items, height)}

        <Ruler
          time={rulerTime}
          items={items}
          xScale={xScale}
          yScale={yScale}
          valueFormatter={valueFormatter}
        />
      </React.Fragment>
    }

    return <div
      className={cx(
        'sensor_timeline sensor_health_timeline',
        xAxis && 'has_x_axis',
        yAxis && 'has_y_axis',
        rulerTime && 'has_ruler',
        props.className
      )}
      onMouseMove={this._onMouseMove}
      onMouseLeave={this._onMouseLeave}
    >
      {title && <div className="title">{title}</div>}
      {contents}
    </div>
  }
}


const levelThresholds = {
  healthy: 76,
  iffy: 51,
  unhealthy: 0
}
const levelHeights = {
  healthy: 1,
  iffy: 5,
  unhealthy: 13,
  offline: 1
}

/**
 * Pure component for rendering the data
 */
const HealthDataSVG = React.memo(function({items, xScale, height}) {
  if (!items || !items.length) return null
  const itemHeight = height / items.length
  const xScaleRound = v => Math.round(xScale(v))

  return items.map(({className, data, shape}, i) => {
    const isTotal = className === 'total'
    const segments = []
    let currentSegment
    for (let j = 0; j < data.length; j++) {
      const intervalWidth = data.length > 1 ?
        xScaleRound(data[Math.min(j + 1, data.length - 1)].timestamp) - xScaleRound(data[Math.min(j, data.length - 2)].timestamp) : 1
      const {timestamp, value} = data[j]
      const level = value == null ? 'offline' : value < levelThresholds.iffy ? 'unhealthy' : value < levelThresholds.healthy ? 'iffy' : 'healthy'
      if (currentSegment && level === currentSegment.level) {
        currentSegment.width += intervalWidth
      } else {
        currentSegment = {
          level,
          x: xScaleRound(timestamp),
          y: itemHeight * i,
          width: intervalWidth
        }
        segments.push(currentSegment)
      }
    }

    return <g key={i} className={cx('health_group', className)}>
      {segments.map((seg, i) => {
        return isTotal
          ? <rect
            key={i}
            className={`health_val ${seg.level}`}
            x={seg.x}
            y={8}
            width={seg.width}
            height={Math.max(1, height - 16)}
          />
          : <path
            key={i}
            className={`health_val ${seg.level}`}
            d={`M${seg.x},${Math.round(seg.y) + 0.5} h${Math.max(1, seg.width - 2)}`}
          />
      })}
    </g>
  })
})

const AxisScaleAwareSensor2HealthGraphRendering = AxisScaleAware(Sensor2HealthGraphRendering)

export default DimensionAware(Sensor2HealthGraphWithData)


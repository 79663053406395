/**
 * Handles login and user authentication
 */

import _ from 'lodash'
import Reflux from 'reflux'
import UserActions from 'actions/UserActions'
import AgreementActions from 'actions/AgreementActions'
import genericUtil from 'ui-base/src/util/genericUtil'
import {customerAgreementVersion} from 'version'
import {requestDelete, requestGet, requestPost, baseURL} from 'utils/restUtils'

const {durations} = genericUtil
const SESSION_CHECK_INTERVAL = durations(1, 'hours')

const needToAcceptAgreement = loginData =>
  loginData.agreement_requested &&
  !loginData.emulatingCustomer && //don't let support users accept the customer agreement
  _(loginData.user.roles).pluck('permissions').flatten().find({id: 'agreements:create'})

const URLS = {
  loginUrl: `login`,
  logoutUrl: `${baseURL}logout`,
  oauthUrl: `login/oauth`,
  supportCustomerUrl: `support-account/customer-id`,
  msspCustomerUrl: `mssp-account/emulate-customer`,
  agreementUrl: 'agreements'
}

let _state = {
  errorLevel: undefined,
  error: undefined,
  loginFormState: 'login',
  otp: undefined,
  submitting: false,
  settingEmulatedCustomerTo: null,
  supportCustomerList: undefined,
  emulatingCustomer: false,
  needsRedirect: false,
  loginData: null,
  isSupport: false,
  isMSSP: false
}


const AuthStore = Reflux.createStore({
  listenables: [UserActions, AgreementActions],

  init() {
  },

  getInitialState() {
    return _state
  },

  _notify() {
    this.trigger(_state)
    _state.needsRedirect = false // Always reset after emitting once
  },

  _setAccessLevel(loginData) {
    _state.isSupport = _(loginData.user.roles).pluck('permissions').flatten().find({id: 'support:emulate-customer'}) != null
    _state.isMSSP = _(loginData.user.roles).pluck('permissions').flatten().find({id: 'mssp:emulate-customer'}) != null
  },

  _getEmulateUrl(loginData) {
    return _state.isSupport ? URLS.supportCustomerUrl : _state.isMSSP ? URLS.msspCustomerUrl : null
  },

  _getExtraDevHeaders() {
    return window.pwDev && window.pwDev.getProxyHeaders()
  },

  _authenticateUser(formData, authUrl = URLS.loginUrl) {
    _state.error = null
    _state.errorLevel = null
    _state.submitting = true
    this._notify()

    // Get the csrf token cookie set before we can make a post request
    requestGet(null, URLS.loginUrl, {redirect401:false, trackErrors:false, extraHeaders: this._getExtraDevHeaders()}).then(() => {
      requestPost(null, authUrl, formData, {redirect401:false, trackErrors:false})
      .then(response => {
        if (response.otp) {
          _state.loginFormState = 'multifactor'
          _state.otp = response.body.otp
          return this._notify()
        }
        this._setAuthenticatedUser(response, true)
      }).catch(restError => {
        console.log('auth error', restError)
        _state.isAuthenticated = false
        _state.submitting = false
        // Backwards compatible checking for MFA challenge
        let _oneTimePassword = restError.response && restError.response.headers.get('X-ProtectWise-OTP')
        // if _oneTimePassword?
        const statusCode = restError.response && restError.response.status
        if (_oneTimePassword && _oneTimePassword != null) {
          _state.loginFormState = 'multifactor'
          _state.otp = _oneTimePassword
          this._notify()
        } else if (statusCode >= 400 && statusCode < 500) {
          if (statusCode === 403 || statusCode === 423) { //Forbidden or Locked
            _state.error = restError.additional
            _state.errorLevel = "danger"
            this._notify()
          } else if (_state.loginFormState === 'multifactor') {
            //This was a multifactor auth rejection
            _state.error = "Invalid Code"
            _state.errorLevel = "warning"
            this._notify()
          } else {
            //initial login attempt rejection
            // Unauthorized, possibly due to IP whitelist
            if (restError.responseText && restError.responseText.indexOf('IP address is not authorized') >= 0) {
              _state.error = restError.additional
            } else {
              _state.error = "Invalid Credentials"
            }
            _state.errorLevel = "warning"
            this._notify()
          }
        } else {
          // any other error
          _state.error = "Network or Server Error"
          _state.errorLevel = "danger"
          this._notify()
        }
      })
    })
    .catch(restError => {
      console.log('auth error 2', restError)
      _state.isAuthenticated = false
      _state.submitting = false
      const statusCode = restError.response && restError.response.status
      if (statusCode === 401) {
        // Unauthorized, possibly due to IP whitelist
        if (restError.responseText && restError.responseText.indexOf('IP address is not authorized') >= 0) {
          _state.error = restError.additional
        } else {
          _state.error = "Unauthorized"
        }
        _state.errorLevel = "warning"
        this._notify()
      } else {
        if (statusCode === 403 || statusCode === 423) { //Forbidden or Locked
          _state.error = restError.additional
          _state.errorLevel = "danger"
          this._notify()
        } else {
          _state.error = "Network or Server Error"
          _state.errorLevel = "danger"
          this._notify()
        }
      }
    })
  },

  _setAuthenticatedUser(loginData, needsRedirect) {
    _state.emulatingCustomer = !!loginData.emulatingCustomer
    _state.currentUserId = loginData.user.id
    _state.currentCustomerId = loginData.user.customer_id

    _state.submitting = false
    _state.error = null
    _state.errorLevel = null
    _state.isAuthenticated = true

    _state.loginData = loginData

    // Poll for session validity
    clearInterval(this.sessionValidityCheckTimer)
    this.sessionValidityCheckTimer = setInterval(this._isSessionValid.bind(this), SESSION_CHECK_INTERVAL)

    let loginFormState = 'login'
    // Support customers may require selecting a customer
    this._setAccessLevel(loginData)
    if (loginData.needToSetCid) {
      _state.supportCustomerList = loginData.customers
      loginFormState = 'customerSelect'
      needsRedirect = false
    }
    // If customer hasn't accepted current agreement, make them
    else if (needToAcceptAgreement(loginData)) {
      loginFormState = 'agreement'
      needsRedirect = false
    }

    _state.loginFormState = loginFormState

    if (needsRedirect) {
      _state.needsRedirect = true
    }

    //Reset multifactor form state
    _state.otp = null
    this._notify()
  },


  /**
   * Action Handlers
   */

  onLogout() {
    clearInterval(this.sessionValidityCheckTimer)
    window.cancelAnimationFrame(this.sessionVerificationRequest)
    window.location.href = URLS.logoutUrl
  },

  onAuthenticateUser(credentials) {
    this._authenticateUser(credentials)
  },

  onAuthenticateUserMfa(mfaCredentials) {
    this._authenticateUser(mfaCredentials)
  },

  onAuthenticateUserGoogleOAuth(oauthCredentials) {
    this._authenticateUser(oauthCredentials, URLS.oauthUrl)
  },

  onGoogleOAuthError(reason) {
    _state.errorLevel = "warning"
    _state.error = reason
    this._notify()
  },

  onSetEmulatedCustomer({id, name}) {
    if (!id || !name) { return }
    _state.settingEmulatedCustomerTo = name
    this._notify()
    requestPost(null, this._getEmulateUrl(), { customer_id: id }, {redirect401:false, trackErrors:false}).then((response) => {
      this._setAuthenticatedUser(response, true)
      _state.supportCustomerList = null
      _state.settingEmulatedCustomerTo = null
      _state.loginFormState = 'login'
      this._notify()
    }).catch((restError) => {
      _state.settingEmulatedCustomerTo = null

      if (restError.response.statusText === 'Forbidden') {
        _state.error = restError.additional
        _state.errorLevel = "danger"
        this._notify()
      } else {
        this.onLogout()
      }
    })
  },

  onUnsetEmulatedCustomer(href) {
    if (!href) href = '#hud'
    if(!href.startsWith('#')) href = `#${href}`
    requestDelete(null, this._getEmulateUrl(), null, {redirect401:false, trackErrors:false})
    .then(() => {
      window.location.href = href
      window.location.reload()
    }).catch(() => {
      this.onLogout()
    })
  },

  onUnsetEmulatedCustomerIfEmulating() {
    if (_state.emulatingCustomer) {
      this.onUnsetEmulatedCustomer()
    }
  },

  onAcceptCustomerAgreement(jobTitle, signedFor) {
    // Don't let support users accept the agreement for a customer
    if (!_state.emulatingCustomer) {
      requestPost(null, URLS.agreementUrl, {
        version: customerAgreementVersion,
        job_title: jobTitle,
        signed_for: signedFor
      }).then(() => {
        return requestGet(null, URLS.loginUrl).then((response) => {
          this._setAuthenticatedUser(response, true)
        })
      }).catch(() => {
        this.onLogout()
      })
    }
  },

  /**
   * Verify that the current session is still valid on the server
   * @method verifySession
   * @param  {bool}      softRedirect   Pass true to softly redirect to /login (prevent logout thrashing for other tabs)
   */
  onVerifySession(reloadOnFail = false) {
    // TODO update feature flags here when implemented?
    requestGet(null, 'login', {redirect401:false, trackErrors:false})
      .then(data => {
        // Logout if no valid session OR if the user changed
        if (data.success === false
          || (data.user && +data.user.id !== _state.currentUserId)
          || (data.needToSetCid)
          || needToAcceptAgreement(data)
          /*|| (data.emulatingCustomer && data.user && data.user.customer_id !== _state.customer_id)*/
        ) {
          this._handleInvalidSession(reloadOnFail)
        }
      })
      .catch(() => {
        this._handleInvalidSession(reloadOnFail)
      })
  },

  // onCreateCustomer(customerName) {
  //   _state.errorLevel = undefined
  //   _state.error = undefined
  //   this._notify()
  //   requestPost('create-customer', 'customers', { name: customerName }) //, {baseURL: 'api/v1/internal/'})
  //     .then(data => {
  //       this.onSetEmulatedCustomer(data)
  //     })
  //     .catch(restError => {
  //       _state.errorLevel = 'danger'
  //       _state.error = restError.message
  //       this._notify()
  //     })
  // },

  _handleInvalidSession(reloadOnFail) {
    if (reloadOnFail) {
      window.location.reload()
    } else {
      this.onLogout()
    }
  },

  _isSessionValid() {
    window.cancelAnimationFrame(this.sessionVerificationRequest)
    this.sessionVerificationRequest = requestAnimationFrame(this.onVerifySession) // Queue up another check
  },

  /**
   * Getters
   */

  checkAuthenticated() {
    let deferred = genericUtil.defer()
    if (_state.isAuthenticated) {
      deferred.resolve(true)
    } else {
      requestGet(null, URLS.loginUrl, {extraHeaders: this._getExtraDevHeaders()}).then((response) => {
        if (response.success && !needToAcceptAgreement(response)) {
          this._setAuthenticatedUser(response)
          deferred.resolve(true)
        } else if (response.url) {
          // SAML Redirect
          window.location = response.url
        } else {
          deferred.resolve(false)
        }
      }).catch(() => {
        deferred.resolve(false)
      })
    }
    return deferred.promise
  },

  getSupportCustomerList() {
    return _state.supportCustomerList
  },

  canAccessNDRSupportRoutes() {
    return _state.isSupport
  },

  canAccessMSSPRoutes() {
    return _state.isSupport || _state.isMSSP
  }
})


export default AuthStore

import _ from 'lodash'
import T from 'prop-types'
import React from 'react'
import GoogleMap from 'components/world-map/GoogleMap'
import {ScrollBars, DialogContainer} from 'react-base'
import GoogleMapDialogItem from './GoogleMapDialogItem'

class GoogleMapDialog extends React.Component {
  static displayName = 'GoogleMapDialog';

  static propTypes = {
    heading: T.string,
    markers: T.arrayOf(T.shape({
      additionalText: T.string,
      text: T.string.isRequired,
      id: T.oneOfType([
        T.string,
        T.number
      ]).isRequired,
      latitude: T.number.isRequired,
      longitude: T.number.isRequired
    })),
    onClose: T.func.isRequired
  };

  static defaultProps = {
    heading: 'World Map'
  };

  state = {
    hoveredMarkerId: null
  };

  _getMarkersWithIndexLabels = () => {
    return _.map(this.props.markers, (m, i) => {
      let out = _.clone(m)
      out.label = i + 1
      out.validBoundary = true
      if (out.id === this.state.hoveredMarkerId) {
        out.className += " highlighted"
      }
      return out
    })
  };

  _onMarkerMouseEnter = (marker) => {
    this.setState({
      hoveredMarkerId: marker.id
    })
  };

  _onMarkerMouseLeave = () => {
    this.setState({
      hoveredMarkerId: null
    })
  };

  render() {
    let {props} = this

    return (
      <DialogContainer
        modal
        onMaskClick={ props.onClose }
      >
        <div className="google_map_dialog modal_dialog">
          <h1>
            { props.heading }
            <span
              className="remove_btn"
              onClick={ props.onClose }
            />
          </h1>
          <div className="google_map_dialog_content grid flex_1">
            <div className="google_map_dialog_key_column">
              <ScrollBars slimShady>
                { _.map(props.markers, (marker, i) => (
                  <GoogleMapDialogItem
                    index={ i }
                    key={ i }
                    marker={ marker }
                    onMouseEnter={ this._onMarkerMouseEnter }
                    onMouseLeave={ this._onMarkerMouseLeave }
                  />
                )) }
              </ScrollBars>
            </div>
            <div className="google_map_dialog_map_column">
              <GoogleMap
                markers={ this._getMarkersWithIndexLabels() }
              />
            </div>
          </div>
          <div className="dialog_buttons">
            <div className="dialog_primary_button_wrap">
              <button
                className={ `btn btn-primary` }
                onClick={ this.props.onClose }
              >Done</button>
            </div>
          </div>
        </div>
      </DialogContainer>

    )
  }
}

export default GoogleMapDialog

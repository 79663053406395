// TEMP utils for dealing with Nark's integration json until the framework is further
import _ from 'lodash'
import React from 'react'
import CommonViewActions from 'actions/CommonViewActions'
import DataSourceTag from 'components/DataSourceTag'

const tryForJSON = (blob) => {
  try {
    return JSON.parse(blob)
  } catch(e) {
    CommonViewActions.Notification.add({
      type: 'error',
      heading: 'Error',
      message: `Error parsing file data.`,
      dismissTimer: 5000
    })
  }
}

const util = {
  tryForJSON: tryForJSON,
  getIpInfo: (data) => data('ipInfo'),
  getFileInfo: (data) => data('fileHash'),
  getDomainInfo: (data) => data('domainInfo'),
  getDeviceInfo: (data) =>  data('device'),
  getIpInfoValue: (data) => {
    return data('ipInfo').isReady()
      ? {
        value: data('ipInfo').valueOf(),
        error: tryForJSON(_.get(data('ipInfo').valueOf(), '[0].hostInfoError', null))
      }
      : {
        value: [],
        error: null
      }
  },
  getFileInfoValue: (data) => {
    return data('fileHash').isReady()
      ? {
        value: data('fileHash').valueOf().fileInfo || [],
        error: tryForJSON(_.get(data('fileHash').valueOf(), 'fileInfo[0].hostInfoError', null))
      }
      : {
        value: [],
        error: null
      }
  },
  getDomainInfoValue: (data) => {
    return data('domainInfo').isReady()
      ? {
        value: data('domainInfo').valueOf(),
        error: tryForJSON(_.get(data('domainInfo').valueOf(), '[0].hostInfoError', null))
      } : {
        value: [],
        error: null
      }
  },
  getDeviceInfoValue: (data) => {
    return data('device').isReady()
      ? {
        value: data('device').valueOf(),
        error: tryForJSON(_.get(data('device').valueOf(), '[0].hostInfoError', null))
      } : {
        value: [],
        error: null
      }
  },
  getAdditionalProperties: (infoValue, source = 'nark-recordedfuture-hostinfoservice') => {
    const intelSource = infoValue.filter(s => s.intelSource === source)
    return tryForJSON(_.get(intelSource, `[0].additionalProperties[0].values[0].json`, null))
  },
  getIntelSource: (fileInfoArr, intelSource) => {
    const source = _.find(fileInfoArr, data => data.intelSource === intelSource)
    return tryForJSON(_.get(source, `additionalProperties[0].values[0].json`, null))
  },
  getCategory: (data, label, version='data') => {
    return data
      ? _.flatten(
        data[version]
          .filter(data => data.label === label)
          .map(type => type.values || type.value) // not sure why its value sometimes??
      )
      : []
  },
  setActiveRiskScore: (score) => {
    if (score > 89) {
      return 'red'
    } else if (score > 64) {
      return 'orange'
    } else if (score > 24) {
      return 'yellow'
    } else if (score < 5) {
      return 'green'
    }

    return null
  },
  recordedFutureSourceTag: () => (
    <DataSourceTag tooltip="Data via Recorded Future">RF</DataSourceTag>
  ),
  autoFocusTag: () => (
    <DataSourceTag tooltip="Data via AutoFocus">AF</DataSourceTag>
  )
}

export default util

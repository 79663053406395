import React, { FunctionComponent, useState, useEffect } from 'react'
import { observer } from "mobx-react"
import ExternalLink from 'components/ExternalLink'
import { Button } from 'react-base'
import DataSourcesTooltip from 'components/devices/DataSourcesTooltip'
import { LocalStoragePrefsStore } from 'stores/mobx/data/global/LocalStoragePrefsStore'
import AnalyticsActions from 'actions/AnalyticsActions'
import getConfig from "utils/uiConfig"
import { useStore } from 'stores/mobx/StoreManager'

const DISMISSED_KEY = 'deviceInventoryFeedbackDismissed'

export enum Actions {
  Useful = "Useful",
  NotUseful = "NotUseful",
  IncorrectData = "IncorrectData"
}

export const FeedbackLinks = {
  [Actions.Useful]: "https://docs.google.com/forms/d/e/1FAIpQLSf8cv923N1pSnSGhZ1XijMDf8w_y-9x2VvgUnU0ms34I8tu6A/viewform?usp=pp_url&entry.1184821108=This+View+is+Useful",
  [Actions.NotUseful]: "https://docs.google.com/forms/d/e/1FAIpQLSf8cv923N1pSnSGhZ1XijMDf8w_y-9x2VvgUnU0ms34I8tu6A/viewform?usp=pp_url&entry.1184821108=This+View+is+NOT+useful",
  [Actions.IncorrectData]: "https://docs.google.com/forms/d/e/1FAIpQLSf8cv923N1pSnSGhZ1XijMDf8w_y-9x2VvgUnU0ms34I8tu6A/viewform?usp=pp_url&entry.1184821108=Report+Incorrect+Data"
}

const messageExpired = new Date() > new Date("2019-09-01") // Hard expiration in case we forget to remove this

const emitAnalyticsAction = (action: string, label?: string) => {
  void AnalyticsActions.event({
    eventCategory: 'device_inventory_feedback',
    eventAction: action,
    eventLabel: label || null
  })
}

export const getFeedbackClickHandler = (action: Actions) => () => emitAnalyticsAction('form_clickthrough', action)

const FeedbackMessage: FunctionComponent<{action: Actions}> = ({action}) => {
  const link = FeedbackLinks[action]
  switch (action) {
    case Actions.Useful:
      return (
        <p>
          Thanks! Please tell us <ExternalLink withIcon href={link} onClick={getFeedbackClickHandler(action)}>why this is useful</ExternalLink>
        </p>
      )
    case Actions.NotUseful:
      return (
        <p>
          Thanks! Please tell us <ExternalLink withIcon href={link} onClick={getFeedbackClickHandler(action)}>why this needs work</ExternalLink>
        </p>
      )
    case Actions.IncorrectData:
      return (
        <p>
          Thanks for reporting! Please help us fix it by providing <ExternalLink withIcon href={link} onClick={getFeedbackClickHandler(action)}>more details</ExternalLink>
        </p>
      )
    default:
      return null
  }
}


const NewFeatureBanner: FunctionComponent<{}> = observer(() => {
  const prefsStore = useStore(LocalStoragePrefsStore)
  const [chosenAction, setChosenAction] = useState(undefined)

  const isDismissed = Boolean(prefsStore.get(DISMISSED_KEY))


  // When chosenAction changes, emit to GA
  useEffect(
    () => {
      emitAnalyticsAction('feedback_provided', chosenAction)
    },
    [chosenAction]
  )

  // When dismissed changes (from LS store), emit to GA
  useEffect(
    () => {
      emitAnalyticsAction('dismissed', `${!isDismissed}`)
    },
    [isDismissed]
  )

  if (messageExpired) {
    prefsStore.remove(DISMISSED_KEY)
    return null
  }

  const toggleDismissed = () => {
    prefsStore.set(DISMISSED_KEY, !isDismissed)
  }

  return isDismissed ? (
    <div className={`new_feature_banner is_dismissed`}>
      <button
        type={'button'}
        className={`btn btn-link btn_provide_feedback`}
        onClick={toggleDismissed}
      >
        <span className={`icon icon-chat_message`} /> Provide Feedback
      </button>
    </div>
  ) : (
    <div className={`new_feature_banner ${isDismissed ? 'is_dismissed' : ''}`}>
      <div className={`feature_msg`}>
        <h6>Beta Feature</h6>
        <p>The Device Inventory view provides a current and historical view of all devices seen by {getConfig().mainBrand} during your retention period.</p>
        <DataSourcesTooltip className={`btn btn-link`}>
          Where is this data coming from?
        </DataSourcesTooltip>
      </div>
      {chosenAction ? (
        <div className={`feature_actions`}>
          <FeedbackMessage action={chosenAction} />
        </div>
      ) : (
        <div className={`feature_actions`}>
          <Button
            args={[Actions.Useful]}
            onClick={setChosenAction}
            className={`btn btn-primary`}
          >
            <span className={`icon icon-thumbs-up`} /> Useful
          </Button>
          <Button
            args={[Actions.NotUseful]}
            onClick={setChosenAction}
            className={`btn btn-warning`}
          >
            <span className={`icon icon-thumbs-down`} /> Needs Work
          </Button>
          <Button
            args={[Actions.IncorrectData]}
            onClick={setChosenAction}
            className={`btn btn-error`}
          >
            <span className={`icon icon-flag`} /> Incorrect Data
          </Button>
        </div>
      )}
      <Button
        className={`dismiss_button btn-icon icon-close`}
        onClick={toggleDismissed}
      />
    </div>
  )
})

export default NewFeatureBanner
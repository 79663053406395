/**
 * Convenience wrapper for a SearchableValue that is a single IP Address. Handles
 * automatically building the intelcard and explorer query props.
 *
 *   <IpValue ip="10.1.2.3" start={ startTime } end={ endTime } />
 *
 * If the start/end props are omitted, it defaults to the past hour.
 *
 * All other props supported by SearchableValue can be included and will be passed through.
 */

import _ from 'lodash'
import React from 'react'
import T from 'prop-types'
import SearchableValue from 'components/values/SearchableValue'
import UserProvider from 'components/UserProvider'
import { VALUE_TYPE_OPTIONS } from 'constants/searchableValueConstants'

const defaultDuration = 60 * 60 * 1000

class IpValue extends React.Component {
  static displayName = 'IpValue'

  static propTypes = _.assign(
    {
      children: T.any,
      ip: T.string,
      start: T.number,
      end: T.number,
      sensorId: T.number,
      noQuery: T.bool,
      noIntelCard: T.bool,
      noLog: T.bool
      // ...plus any others accepted by SearchableValue
    },
    UserProvider.PROP_TYPES
  )

  render() {
    let { props } = this
    let { children, ip, start, end, sensorId } = props
    if (!ip) {
      return <span />
    }
    if (!end) {
      end = start ? start + defaultDuration : Date.now()
    }
    if (!start) {
      start = end - defaultDuration
    }
    let query = props.noQuery
      ? null
      : {
          family: 'observations',
          clauses: [
            {
              name: 'occurredAt',
              op: 'between',
              from: start,
              to: end
            },
            {
              name: 'ip',
              op: 'eq',
              value: ip
            }
          ]
        }

    return (
      <SearchableValue
        {...props}
        className={'ip_value ' + (props.className || '')}
        data-testid={props['data-testid']}
        intelCard={props.noIntelCard ? null : 'ip:' + ip}
        intelCardTooltip="View IP Info"
        logParams={
          !props.noLog &&
          (props.userFeatures.hasFirewallDevice ||
            props.userFeatures.hasLoggerDevice ||
            props.isDemoCustomer)
            ? { ip, start, end, sensorId }
            : null
        }
        query={query}
        value={props.ip}
        copyValue={props.ip}
        valueType={VALUE_TYPE_OPTIONS.IP}
        pcapDownloadFilter={{
          sensorId,
          startTime: start,
          endTime: end,
          ips: [props.ip]
        }}
      >
        {children || ip}
      </SearchableValue>
    )
  }
}

let WrappedIpValue = props =>
  <UserProvider>
    <IpValue {...props} />
  </UserProvider>

WrappedIpValue.displayName = 'WrappedIpValue'

export default WrappedIpValue

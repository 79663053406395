import _ from 'lodash'
import T from 'prop-types'
import cx from 'classnames'
import React from 'react'
import { formatNumber } from 'pw-formatters'

const Tally = props => {
  const val = props.value || 0
  const num = _.isNumber(val) ? (props.formatter ? props.formatter(val) : formatNumber(val, false)) : val
  return (
    <div className={cx('tally', props.className, props.primary && 'primary')}>
      <span className="name">
        {props.name}
      </span>
      <span
        className="value"
        data-tooltip={_.isNumber(val) && val > 999 ? val.toLocaleString() : null}
      >
        {_.isString(num) || React.isValidElement(num)
          ? num
          : <React.Fragment>
            <span className="number">
              {num.textData}
            </span>
            <span className="unit">
              {num.label}
            </span>
          </React.Fragment>
        }
      </span>
    </div>
  )
}

Tally.displayName = 'Tally'
Tally.propTypes = {
  name: T.node,
  className: T.string,
  primary: T.bool,
  value: T.oneOfType([
    T.number, //numeric value, will be formatted via `formatter`
    T.node, //raw string or element, will not be formatted
    T.shape({textData: T.string, label: T.string}) //preformatted number+units
  ]),
  formatter: T.func //formatter function for the `value`; expected to return a {textData, label} object or a string
}

export default Tally

import _ from 'lodash'
import T from 'prop-types'
import React from 'react'
import moment from 'moment'
import { pluralize } from 'pw-formatters'
import IntelCardPropTypes from '../PropTypes'
import TimelineSingle from 'components/timeline/TimelineSingle'
import CommonViewActions from 'actions/CommonViewActions'
import { LoadingValue } from 'react-base'
import ErroredValue from './ErroredValue'
import { formatDay } from 'utils/timeUtils'
import ThemeProvider from 'components/ThemeProvider'

// Convert time series data from API to format expected by timelines
function formatTimeSeriesData(timeData) {
  const range = timeData.timeRange
  return {
    history: timeData.values,
    interval: moment.duration(range.intervalSize, range.interval.toLowerCase()),
    startTime: range.start,
    endTime: range.end,
    maxValue: _.max(timeData.values, 'count').count
  }
}

const typeLabels = {
  ip: 'IP',
  device: 'Device',
  domain: 'Domain'
}

class Activity extends React.Component {
  static displayName = 'Activity'

  static propTypes = _.assign(
    {
      noEvents: T.bool
    },
    IntelCardPropTypes.dataCardShape
  )

  componentDidMount() {
    this._updateGraphs()
  }

  componentDidUpdate() {
    this._updateGraphs()
  }

  _updateGraphs = () => {
    const { data } = this.props
    const eventsByTime = data('threat.events.count.timeSeries')
    const obsByTime = data('threat.observations.timeSeries')
    //let netflowsByTime = data('threat.netflows.timeSeries')
    const { eventsGraph, obsGraph, netflowGraph } = this.refs
    if (eventsGraph && eventsByTime.valueOf()) {
      eventsGraph.updateGraph(
        formatTimeSeriesData(eventsByTime.valueOf()),
        false,
        true
      )
    }
    if (obsGraph && obsByTime.valueOf()) {
      obsGraph.updateGraph(
        formatTimeSeriesData(obsByTime.valueOf()),
        false,
        true
      )
    }
    //if (netflowGraph && netflowsByTime.valueOf()) {
    //  netflowGraph.updateGraph(formatTimeSeriesData(netflowsByTime.valueOf()), false, true)
    //}
  }

  _onBarMouseOver = (mode, data, e) => {
    const rect = _.pick(
      e.target.getBoundingClientRect(),
      'top',
      'left',
      'width'
    )
    rect.height = 6 //align to top-ish
    const content = (
      <div className="timeline_tooltip pw_tooltip light">
        <div className={'tooltip_heading'}>
          {data.count} {pluralize(data.count, mode)}
        </div>
        <div>
          On {formatDay(data.fromTime)}
        </div>
      </div>
    )
    CommonViewActions.Tooltip.updateTooltip(content, rect)
  }

  _onEvtBarMouseOver = (data, e) => {
    this._onBarMouseOver('event', data, e)
  }

  _onObsBarMouseOver = (data, e) => {
    this._onBarMouseOver('observation', data, e)
  }

  _onItemMouseOut = () => {
    CommonViewActions.Tooltip.hideTooltip()
  }

  _onBarClick = (mode, data) => {
    CommonViewActions.Tooltip.hideTooltip()
    const { queryType, queryValue } = this.props
    // Can't pivot on domain/url
    if (queryType === 'ip') {
      location.href = `#killbox/${mode}s?start=${data.fromTime}&end=${data.toTime}&timeMode=occurredAt&ip=${queryValue}&intelcard=${queryType}:${queryValue}`
    } else if (queryType === 'device') {
      location.href = `#killbox/${mode}s?start=${data.fromTime}&end=${data.toTime}&timeMode=occurredAt&devices=${queryValue}&intelcard=${queryType}:${queryValue}`
    }
  }

  _onEvtBarClick = (data, e) => {
    this._onBarClick('event', data, e)
  }

  _onObsBarClick = (data, e) => {
    this._onBarClick('observation', data, e)
  }

  // _onNetflowMouseMove(data, e) {
  //   let {clientX: left, clientY: top} = e.nativeEvent
  //   let content = (
  //     <div className="timeline_tooltip pw_tooltip light">
  //       <div className={"tooltip_heading"}>
  //         { data.count } { pluralize(data.count, 'netflow') }
  //       </div>
  //       <div>{ formatDate(data.fromTime) }</div>
  //     </div>
  //   )
  //   CommonViewActions.Tooltip.updateTooltip(content, {left, top}, true)
  // },

  render() {
    return (
      <div className="intel_card intel_data_card intel_data_activity">
        <h3>Activity Intel</h3>
        <span className="timeframe">Last 30 Days</span>
        <div className="intel_card_content">
          {this.renderData()}
        </div>
      </div>
    )
  }

  renderData = () => {
    const { props } = this
    const type = typeLabels[props.queryType] || 'Asset'
    const data = props.data

    const eventsByTime = data('threat.events.count.timeSeries.values')
    const obsByTime = data('threat.observations.timeSeries.values')
    const startTime =
      data('threat.events.count.timeSeries.timeRange.start').valueOf() ||
      data('threat.observations.timeSeries.timeRange.start').valueOf()

    return (
      <div>
        {props.noEvents
          ? null
          : [
              <h5 key="h">
                Events For This {type}
              </h5>,
              <div key="b" className="graph">
                {eventsByTime.isLoading()
                  ? <div className="no_items">
                      <LoadingValue />
                    </div>
                  : eventsByTime.isError()
                    ? <div className="no_items">
                        <ErroredValue />
                      </div>
                    : _.isEmpty(eventsByTime.valueOf()) ||
                      _.max(eventsByTime.valueOf(), 'count').count === 0
                      ? <div className="no_items">No Events</div>
                      : (
                        <ThemeProvider>
                          <TimelineSingle
                            ref="eventsGraph"
                            graphName="events"
                            barWidthByInterval
                            barWidthRatio={0.4}
                            onItemMouseOver={this._onEvtBarMouseOver}
                            onItemMouseOut={this._onItemMouseOut}
                            onItemClick={this._onEvtBarClick}
                            noAxes
                          />
                        </ThemeProvider>
                      )}
              </div>
            ]}

        <h5>
          Observations For This {type}
        </h5>
        <div className="graph">
          {obsByTime.isLoading()
            ? <div className="no_items">
                <LoadingValue />
              </div>
            : obsByTime.isError()
              ? <div className="no_items">
                  <ErroredValue />
                </div>
              : _.isEmpty(obsByTime.valueOf()) ||
                _.max(obsByTime.valueOf(), 'count').count === 0
                ? <div className="no_items">No Observations</div>
                : (
                  <ThemeProvider>
                    <TimelineSingle
                      ref="obsGraph"
                      graphName="observations"
                      barWidthByInterval
                      barWidthRatio={0.4}
                      onItemMouseOver={this._onObsBarMouseOver}
                      onItemMouseOut={this._onItemMouseOut}
                      onItemClick={this._onObsBarClick}
                      noAxes
                    />
                  </ThemeProvider>
                )}
        </div>

        {/*
      <h5>Netflows For This { type }</h5>
      <div className="graph">
        <TimelineSingle
          ref="netflowGraph"
          graphName="netflow"
          initialGraphMode="Stream"
          onItemMouseMove={ this._onNetflowMouseMove }
          onItemMouseOut={ this._onItemMouseOut }
          noAxes
        />
      </div>
      */}

        {startTime
          ? <div className="time_axis">
              <span className="start">
                {formatDay(startTime)}
              </span>
              <span className="end">Today</span>
            </div>
          : null}
      </div>
    )
  }
}

export default Activity

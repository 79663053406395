/**
 * Store containing data about the currently logged in user.
 */

import _ from 'lodash'
import React from 'react'
import Reflux from 'reflux'

import CommonViewActions from 'actions/CommonViewActions'
import { topLevelNavItems } from 'pwConstants'
import AnalyticsActions from 'actions/AnalyticsActions'
import AuthStore from 'stores/AuthStore'
import moment from 'moment'
import IntegrationsActions from 'actions/IntegrationsActions'
import IntegrationsStore from 'stores/IntegrationsStore'
import PreferencesStore from 'stores/PreferencesStore'
import { INTEL_LISTS_READ_PERMISSION } from 'constants/intelManagementConstants'
import getConfig from 'utils/uiConfig'
import AppcuesActions from 'actions/AppcuesActions'
import {listenToStore} from 'utils/storeUtils'

const DEFAULT_RETENTION_DAYS = 182 //6 months-ish

const THREAT_TEAM_CID = 1796 // FIXME GROSS - should be supplied by planck as a perm/role

let _state = {
  isAuthenticated: false,
  isDemoCustomer: false,
  isSupportCustomer: false,
  isMSSPCustomer: false,

  emulatingCustomer: false,
  currentUserId: -1,
  currentCustomerId: -1,

  userInfo: {},
  userPermissions: {},
  userFeatures: {},
  userPreferences: {},
  streamingEndpoint: null,

  hasPermission: null,
  hasNotifiedStatus: false
}

window._pw = window._pw || {}

const WARNING_STATUS = {
  TrialEnded: {
    heading: 'Your trial has ended.'
  },
  CustomerDelinquent: {
    heading: 'Your subscription is overdue.'
  }
}

const UserStore = Reflux.createStore({
  init() {

    listenToStore(this, AuthStore, this._onAuthStoreUpdate)
    listenToStore(this, IntegrationsStore, this._onIntegrationsStoreUpdate)
    listenToStore(this, PreferencesStore, this._onPreferencesUpdate)
  },

  getInitialState() {
    return _state
  },

  _notify() {
    _state.hasPermission = this.hasPermission
    this.trigger(_state)
  },

  _onAuthStoreUpdate(authStoreState) {
    const {
      isSupport,
      isMSSP,
      isAuthenticated,
      emulatingCustomer,
      currentUserId,
      currentCustomerId,
      loginData
    } = authStoreState

    _state = _.assign(_state, {
      isAuthenticated,
      emulatingCustomer,
      currentUserId,
      currentCustomerId,
      isSupportCustomer: isSupport,
      isMSSPCustomer: isMSSP
    })
    if (isAuthenticated) {
      this._setAuthenticatedUser(loginData)
    }
    this._notify()
  },

  _onIntegrationsStoreUpdate(storeState) {
    const userFeatures = _.assign(_state.userFeatures || (_state.userFeatures = {}), {
      hasFirewallDevice: false,
      hasLoggerDevice: false,
      endpointKnowledgeVendors: new Set()
    })
    const devices = _.get(storeState, 'devices.data')
    if (devices) {
      devices.forEach(d => {
        if (d.type === 'Firewall') {
          userFeatures.hasFirewallDevice = true
        }
        else if (d.type === 'NetworkAnalytics') {
          userFeatures.hasLoggerDevice = true
        }
        else if (d.type === 'EndpointKnowledge') {
          userFeatures.endpointKnowledgeVendors.add(d.vendor)
        }
      })
    }
    this._notify()
  },

  _onPreferencesUpdate(prefStoreState) {
    // Grab merged prefs; cloned for immutable behavior
    _state.userPreferences = _.cloneDeep(prefStoreState.preferences)
    this._notify()
  },

  _setAuthenticatedUser(loginData) {
    const userData = loginData.user
    const custId = userData.customer_id
    const isDemoCust = _.get(userData, 'customer.status') === 'Demo'
    _state.isDemoCustomer = isDemoCust

    window._pw = window._pw || {}
    window._pw.isSupportCustomer = _state.isSupportCustomer
    window._pw.isDemoCustomer = isDemoCust

    if (!loginData.needToSetCid) {
      // Track for analytics
      if (custId && custId !== _.get(_state, 'customer.id')) {
        AnalyticsActions.setCustomerId(custId)
      }
      if (userData.id && !_state.id) {
        AnalyticsActions.setUserId(userData.id)
        AnalyticsActions.event({
          eventCategory: 'login',
          eventAction: _state.isSupportCustomer ? 'support' : 'customer',
          eventLabel: _state.isSupportCustomer ? userData.email : userData.id + '',
          nonInteraction: true
        })
      }
      AnalyticsActions.setIsPWUser(_state.isSupportCustomer || (isDemoCust && !userData.is_test_drive) || +custId === 1)

      // Track for Appcues
      AppcuesActions.setUser(userData)

      // Trigger query for integration devices to determine device-related userFeatures (e.g. hasFirewallDevice)
      IntegrationsActions.loadDevices()
    }

    window._pw.isPwThreatTeam = custId === THREAT_TEAM_CID // FIXME read something supplied by planck

    _state.userInfo = userData
    _state.userFeatures = loginData.features
    // Let the Preferences store know about initial data
    _state.userPreferences = PreferencesStore.setInitialPreferences(loginData.preferences)
    _state.streamingEndpoint = loginData.streamingEndpoint

    // Flatten some properties for convenience
    let retentionDays = _.get(userData, 'customer.policies.customerPolicy.retentionDays')
    if (!_.isNumber(retentionDays)) {
      retentionDays = DEFAULT_RETENTION_DAYS
    }
    _state.userInfo.retentionMillis = retentionDays * 24 * 60 * 60 * 1000
    _state.userInfo.retentionStartTime = +moment.utc(Date.now() - _state.userInfo.retentionMillis).startOf('day')

    // Create index of permissions for easier lookup
    _state.userPermissions = _(userData.roles).pluck('permissions').flatten().reduce((out, {id}) => {
      out[id] = true
      return out
    }, Object.create(null))

    this._attachIntelManagementAccess()

    const warningStatus = Object.keys(WARNING_STATUS)
    if (_.includes(warningStatus, userData.customer.status) && !_state.hasNotifiedStatus) {
      const uiConfig = getConfig()
      CommonViewActions.Notification.add({
        type: 'warning',
        heading: WARNING_STATUS[userData.customer.status].heading,
        messageComponent: (
          <span className="version_update_notification">
            Please contact your Sales Representative or { uiConfig.sales.email } if you need assistance.
          </span>
        )
      })
      _state.hasNotifiedStatus = true
    }

    CommonViewActions.Time.setLoginTime(Date.now())
  },

  _attachIntelManagementAccess() {
    _state.userInfo.userCanAccessIntelManagement = this.hasPermission(INTEL_LISTS_READ_PERMISSION)
  },

  /**
   * Getters
   */
  hasPermission(permissionName) {
    return _state.isAuthenticated && _state.userPermissions[permissionName]
  },

  getPermissions() {
    return _state.userPermissions
  },

  hasFeature(featureName) {
    const features = _state.userFeatures
    return !!(features && features[featureName])
  },

  getDefaultRoute() {
    let route = 'hud'
    // User preference overrides customer
    const preference = _.get(_state, 'userPreferences.user.defaultRoute') || _.get(_state, 'userPreferences.customer.defaultRoute')
    if (topLevelNavItems && preference && topLevelNavItems[preference]) {
      route = topLevelNavItems[preference]
    }
    return route
  },

  getStreamingEndpoint () {
    return _state.streamingEndpoint
  },

  getRetentionMillis() {
    return _state.userInfo.retentionMillis
  },

  getCurrentCustomerID() {
    return _state.currentCustomerId
  },

  isSupportCustomer() {
    return _state.isSupportCustomer
  },

  isDemoCustomer() {
    return _state.isDemoCustomer
  },

  isTestDriveCustomer() {
    return _state.userInfo.is_test_drive
  },

  isEmulatingCustomer() {
    return _state.emulatingCustomer
  },

  getCurrentUserInfo() {
    return _state.userInfo
  },

  getCurrentUserFeatures() {
    return _state.userFeatures
  }
})

export default UserStore

export var hasPermission = UserStore.hasPermission

export var hasFeature = UserStore.hasFeature

export var isTestDriveCustomer = UserStore.isTestDriveCustomer

import _ from 'lodash'
import Reflux from 'reflux'
import getConfig from 'utils/uiConfig'
import { NotificationStore } from 'stores/notifications/NotificationStore'
import forwardActionsToStore from 'stores/mobx/forwardActionsToStore'

/*
Create all view actions
*/
const common = {
  Tooltip: Reflux.createActions([
    'updateTooltip',
    'updateTooltipPosition',
    'hideTooltip'
  ]),
  Protocol: Reflux.createActions([
    'requestProtocolFamilies'
  ]),
  Notification: Reflux.createActions([
    'add',
    'addRestError',
    'dismiss',
    'dismissAll',
    'update'
  ]),
  General: Reflux.createActions([
    'keydown',
    'setPageTitle'
  ]),
  Time: Reflux.createActions([
    'setLoginTime',
    'updateDvr',
    'startLive',
    'stop'
  ])
}

forwardActionsToStore(common.Notification, NotificationStore)

common.General.setPageTitle.preEmit = function(...args) {
  const uiConfig = getConfig()
  const basePageTitle = `${ uiConfig.brandedProductName } Visualizer`
  let title = []
  for (var i = 0; i < args.length; i++) {
    let part = args[i]
    if (_.isString(part)) {
      title.push(part)
    }
  }
  title.push(basePageTitle)
  document.title = title.join(' | ')
}

export default common

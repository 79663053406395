import {
  observable,
  autorun,
  action,
  flow
} from 'mobx'
import urlHistoryUtil from 'utils/urlHistoryUtil'
import { Store } from 'stores/mobx/StoreManager'

export interface QueryParams {
  [key: string]: string|null //|number|null
}

export interface Route {
  basePath: string
  pathArr: string[]
  queryParams: QueryParams
}

export class RouteStore extends Store {
  @observable currentRoute: Route = {
    basePath: "",
    pathArr: [],
    queryParams: {}
  }

  constructor () {
    super()
  }

  @action
  onRoute(routeObject) {
    this.currentRoute = routeObject
  }

  @action
  mergeParams(params: {[key: string]: string|number}) {
    urlHistoryUtil.mergeParams(params, false)
  }

  @action
  updatePath(newValue: string, targetIdx: number, newQueryParams?: object, replaceState?: boolean) {
    urlHistoryUtil.updatePath(newValue, targetIdx, replaceState, newQueryParams)
  }
}

import {observable} from 'mobx'
import {
  SchemerModel,
  schemer
} from 'pw-schemer-mobx'
import DeviceAttribute from './DeviceAttribute'
import {AwsId} from './AwsId'

export class AwsInterface extends SchemerModel {
  @observable
  @schemer.macAddress({
    label: 'MAC',
    tooltip: 'MAC Address'
  })
  macAddress: string

  @observable
  @schemer.ip({
    label: 'IP',
    tooltip: 'IP Address'
  })
  ipAddress?: string

  @observable
  @schemer.string({
    label: 'Private IPs',
    tooltip: 'Subnet ID',
  })
  subnetId?: string

  @observable
  @schemer.string({
    label: 'VPC',
    tooltip: 'VPC ID'
  })
  vpcId?: string

  @observable
  @schemer.arrayOf(
    schemer.ip({
      label: 'Private IPs',
      tooltip: 'Private IP Addresses'
    })
  )
  privateIpAddresses: string[]

  @observable
  @schemer.arrayOf(
    schemer.ipV6({
      label: 'IPv6',
      tooltip: 'IPv6 Addresses'
    })
  )
  ipv6Addresses: string[]
}

export class AwsDetails extends SchemerModel {
  @observable
  @schemer.model({
    model: AwsId
  })
  id: AwsId

  @observable
  @schemer.string({
    label: "AZ",
    tooltip: 'Availability Zone'
  })
  availiablityZone: string // SIC

  @observable
  @schemer.string({
    label: 'Img ID',
    tooltip: 'Image ID'
  })
  imageId: string

  @observable
  @schemer.string({
    label: "Type",
    tooltip: 'Instance Type'
  })
  instanceType: string

  @observable
  @schemer.tags({ label: 'Tags' })
  tags: string[]

  @observable
  @schemer.arrayOf(
    schemer.model({
      // label: 'AWS Interface',
      model: AwsInterface
    })
  )
  interfaces: AwsInterface[]
}

export class AwsDetailsAttr extends DeviceAttribute {
  @observable
  @schemer.model({
    model: AwsDetails
  })
  value: AwsDetails
}
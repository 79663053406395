import T from 'prop-types'
import React from 'react'

export default class extends React.Component {
  static displayName = "GoogleMapDialogItem";

  static propTypes = {
    index: T.number.isRequired,
    marker: T.object.isRequired,
    onMouseEnter: T.func,
    onMouseLeave: T.func,
  };

  _onMouseEnter = () => {
    if (this.props.onMouseEnter) {
      this.props.onMouseEnter(this.props.marker)
    }
  };

  _onMouseLeave = () => {
    if (this.props.onMouseLeave) {
      this.props.onMouseLeave(this.props.marker)
    }
  };

  render() {
    let {marker, index} = this.props
    return (
      <div
        className={ `marker_key fade_in_left` }
        onMouseEnter={ this._onMouseEnter }
        onMouseLeave={ this._onMouseLeave }
        style={ {
          animationDelay: index * 150 + 'ms',
          WebkitAnimationDelay: index * 150 + 'ms'
        } }
      >
        <div className="key_label">
          <div>{ index + 1 }</div>
        </div>
        <div className="key_text">
          <div>{ marker.text }</div>
          { marker.additionalText ? (
            <div className="key_text_additional">{ marker.additionalText }</div>
          ) : null }
        </div>
      </div>
    )
  }
}

import React, { useCallback } from 'react'
import T from 'prop-types'
import { observer } from 'mobx-react'
import {
  TextOverflowCenterLite,
  LoadingValue,
  MenuItem,
  DropdownMenu
} from 'react-base'
import { formatBytes, formatDurationHuman, formatDuration } from 'pw-formatters'
import { useStore } from 'stores/mobx/StoreManager'
import DownloadStore, { DOWNLOAD_STATE } from 'stores/mobx/viewStores/download-manager/DownloadStore'
import { DownloadError, ErrorShape } from './DownloadError'
import { formatDate } from 'utils/timeUtils'
import RelativeTime from 'components/RelativeTime'
import Stopwatch from 'components/Stopwatch'

export const Download = observer(props => {
  const { download } = props
  const downloadStore = useStore(DownloadStore)
  const handleRestart = useCallback(
    () => {
      downloadStore.restart(download.key)
    },
    [download.key],
  )
  const handleRestartSync = useCallback(
    () => {
      downloadStore.restartSync(download.key)
    },
    [download.key],
  )
  const handleRedownload = useCallback(
    () => {
      downloadStore.redownload(download.key)
    },
    [download.key],
  )
  const handleCancel = useCallback(
    () => {
      downloadStore.cancel(download.key)
    },
    [download.key],
  )
  const handleClear = useCallback(
    () => {
      downloadStore.clear(download.key)
    },
    [download.key],
  )

  let dlProgressPercent = 0
  if (download.bytesExpected > 0 || download.bytesExpectedEst > 0) {
    const expected = download.bytesExpected > 0 ? download.bytesExpected : download.bytesExpectedEst
    dlProgressPercent = Math.min(100, Math.max(0, Math.round((download.bytesDownloaded / expected) * 100)))
  }
  if (download.bytesExpected === -2 || download.status === DOWNLOAD_STATE.COMPLETED) {
    dlProgressPercent = 100
  }
  const duration = downloadStore.getDuration(download.key)
  const canRedownload = download.async && download.expiresAt && download.expiresAt > Date.now()
  const isActive = download.status === DOWNLOAD_STATE.PENDING || download.status === DOWNLOAD_STATE.PREPARING

  return (
    <div
      className={`download_item status_${download.status.toLowerCase()}`}
    >
      <header>
        <div className={`download_status status_${download.status.toLowerCase()}`} />
        <div
          className={`download_name`}
          data-tooltip={`File Type: ${download.mimeType}`}
          data-tooltip-class='light'
        >
          <TextOverflowCenterLite className="file_name" value={download.fileName} />
        </div>
        <div className={`download_actions`}>
          <div className={`btn-group`}>
            {isActive ? (
              <button
                className={`btn btn-icon icon-blocked`}
                data-tooltip={`Cancel Download`}
                data-tooltip-class='light'
                onClick={handleCancel}
              />
            ) : (
              <>
                {canRedownload ? (
                  <button
                    className={`btn btn-icon icon-download`}
                    data-tooltip={`Download again`}
                    data-tooltip-class='light'
                    onClick={handleRedownload}
                  />
                ) : (
                  <button
                    className={`btn btn-icon icon-spinner`}
                    data-tooltip={`Restart download`}
                    data-tooltip-class='light'
                    onClick={handleRestart}
                  />
                )}
                <button
                  className={`btn btn-icon icon-trash`}
                  data-tooltip={`Clear Download`}
                  data-tooltip-class='light'
                  onClick={handleClear}
                />
                {download.async &&
                  <DropdownMenu
                    label=""
                    omitCaret
                    closeOnItemClick
                    buttonClasses="btn btn-icon icon-menu-dots"
                  >
                    <MenuItem
                      key="restart"
                      onClick={handleRestart}
                    >
                      <span className="icon icon-spinner" /> Restart download
                    </MenuItem>
                    <MenuItem
                      key="restartSync"
                      onClick={handleRestartSync}
                    >
                      <span className="icon icon-warning" /> Fallback download
                    </MenuItem>
                  </DropdownMenu>
                }
              </>
            )}
          </div>
        </div>
      </header>
      <div className={`download_progress`}>
        {download.addedAt &&
          <div className={`dl_progress_details`}>
            <div
              className={`dl_timing`}
            >
              {`Added `}
              <RelativeTime
                data-tooltip={
                  `Download added at ${formatDate(download.addedAt)}.${download.expiresAt ? ' Prepared file available until ' + formatDate(download.expiresAt) : ''}`
                }
                data-tooltip-class='light'
                time={download.addedAt}
              />
            </div>
            {duration && duration > 0 &&
              <div
                className={`dl_timing_duration`}
                data-tooltip={`${formatDuration(duration)}`}
                data-tooltip-class='light'
              >
                {isActive ? (
                  <Stopwatch
                    startAt={download.startedAt}
                    endAt={download.completedAt}
                  />
                ) : formatDurationHuman(duration)}
              </div>
            }
          </div>
        }
        <div className={`progress progress-striped`}>
          <div
            className={`progress-bar`}
            style={{
              width: `${dlProgressPercent}%`
            }}
          />
        </div>
        <div className={`dl_progress_details`}>
          {download.statusMsg &&
            <div className={`download_status_msg`}>{download.statusMsg}</div>
          }
          {download.bytesExpected > 0 || download.bytesExpectedEst > 0 ? (
            <div className={`dl_progress progress_determinate`}>
              <span>{formatBytes(download.bytesDownloaded, true, 2)}</span>
              {download.status !== DOWNLOAD_STATE.COMPLETED &&
                <>
                  <span> / </span>
                  <span>
                    {download.bytesExpected > 0 ? (
                      <span>
                        {download.status === DOWNLOAD_STATE.PREPARING &&
                          <LoadingValue />
                        }
                        {formatBytes(download.bytesExpected, true, 2)}&nbsp;
                        {download.status !== DOWNLOAD_STATE.PREPARING &&
                          <span>{`(${dlProgressPercent}%)`}</span>
                        }
                      </span>
                    ) : (
                      <span>
                        ~{formatBytes(download.bytesExpectedEst, true, 1)} (est.)
                      </span>
                    )}
                  </span>
                </>
              }
            </div>
          ) : (
            <div className={`dl_progress progress_indeterminate`}>
              {formatBytes(download.bytesDownloaded, true, 2)}
            </div>
          )}
        </div>
        {download.error &&
          <DownloadError error={download.error} />
        }
      </div>
    </div>
  )
})

const Req = T.shape({
  method: T.oneOf(['POST', 'GET']).isRequired,
  url: T.string.isRequired,
  params: T.object
})

Download.propTypes = {
  download: T.shape({
    key: T.string,
    title: T.string,
    fileName: T.string.isRequired,
    status: T.oneOf(Object.values(DOWNLOAD_STATE)).isRequired,
    bytesDownloaded: T.number,
    bytesExpected: T.number,
    bytesExpectedEst: T.number,
    addedAt: T.number,
    expiresAt: T.number,
    mimeType: T.string,
    statusMsg: T.string,
    request: Req.isRequired,
    originalRequest: Req,
    error: ErrorShape
  }).isRequired
}

Download.displayName = "Download"

import _ from 'lodash'
import {rgb} from 'd3-color'
import T from 'prop-types'
import React from 'react'
import {TextInput, DropdownMenu, MenuItem, ScrollBars, TagInput, StoreProvider, Button} from 'react-base'
import LocationStore from 'stores/LocationStore'
import {locationShape, formatAddress, getMarkerForLocation} from 'utils/locationUtils'
import UserProvider from 'components/UserProvider'
import LocationManagementDialog from './LocationManagementDialog'


class LocationSelectionDropdown extends React.Component {
  static displayName = 'LocationSelectionDropdown';

  static propTypes = {
    currentLocationId: T.number,
    locations: T.arrayOf(locationShape),
    onSelect: T.func.isRequired,
    hasPermission: T.func,
    readOnly: T.bool
  };

  state = {
    filterString: null
  };

  _onLocationSelect = (location) => {
    if (_.isFunction(this.props.onSelect)) {
      this.props.onSelect(location)
    }
  };

  _onFilterChange = (filterString) => {
    this.setState({ filterString })
  };

  _filterLocations = (location) => {
    let filterText = this.state.filterString
    return !filterText
      || location.name.toLowerCase().indexOf(filterText.toLowerCase()) >= 0
      || location.address.toLowerCase().indexOf(filterText.toLowerCase()) >= 0
  };

  _renderAddress = (address) => {
    return _.map(formatAddress(address), (line, i) => (<div key={ i }>{ line }</div>))
  };

  _onDialogClosed = () => {
    this.setState({
      dialogOpen: false
    })
  };

  _showEditor = () => {
    this.setState({
      dialogOpen: true
    })
  };

  render() {
    let {props, state} = this

    let currentLocation = props.currentLocationId ? _.find(props.locations, {id: props.currentLocationId}) : {}

    let filteredLocations = _.filter(props.locations || [], this._filterLocations).reverse()

    let canCreateLocations = props.hasPermission('locations:create')
    let canUpdateLocations = props.hasPermission('locations:update')
    let canDeleteLocations = props.hasPermission('locations:delete')

    return (
      <div className="location_selection_dropdown">
        <DropdownMenu
          activeMenuKeys={ [currentLocation.id] }
          buttonClasses="btn filter_dropdown_btn dropdown-toggle"
          disabled={props.readOnly}
          closeOnItemClick
          filterable
          label={ state.dialogOpen ? `...` : (currentLocation.name || 'Choose a Location') }
          menuClasses={ `location_selection_dropdown_menu` }
          onFilterChange={ this._onFilterChange }
          scrollable
        >
          { _.map(filteredLocations || [], location => {
            return (
              <MenuItem
                args={ [location] }
                className="location_dropdown_option"
                key={ location.id }
                onClick={ this._onLocationSelect }
              >
                <div className="flex_1">
                  <div>{ location.name }</div>
                  <div className="muted">{ this._renderAddress(location.address) }</div>
                </div>

              </MenuItem>
            )
          }) }
          { canUpdateLocations || canDeleteLocations || canCreateLocations ? (
            <MenuItem
              className="manage_locations_option"
              onClick={ this._showEditor }
            >Manage & Add Locations <span className="icon icon-arrow-up-right" /></MenuItem>
          ) : null }
        </DropdownMenu>
        { state.dialogOpen ? (
          <LocationManagementDialog
            currentSelectedLocation={ currentLocation }
            hasPermission={ props.hasPermission }
            onClose={ this._onDialogClosed }
            onSelect={ this._onLocationSelect }
          />
        ) : null }
      </div>
    )
  }
}

function locationManagementWithStore (props) {
  return (
    <UserProvider>
      <StoreProvider store={ LocationStore }>
        <LocationSelectionDropdown { ...props } />
      </StoreProvider>
    </UserProvider>
  )
}

locationManagementWithStore.displayName= 'LocationSelectionDropdown.Wrapped'

export default locationManagementWithStore

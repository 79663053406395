import React from 'react'
import {XrayPairedIcon} from './XrayPairedIcon.js'
import {useHeadsetStatusStream} from 'stores/XrayHeadsetStatusStore.js'
import cx from 'classnames'

export const XrayPairedIconWrapper = () => {
  const {paired, connected} = useHeadsetStatusStream()
  return (<span className={cx('xray_paired_icon_wrapper', {paired, connected})}>
    <XrayPairedIcon />
  </span>)
}

import _ from 'lodash'
import Reflux from 'reflux'
import CustomerSettingsActions from 'actions/CustomerSettingsActions'
import UserStore from 'stores/UserStore'
import CommonViewActions from 'actions/CommonViewActions'
import {requestPut} from 'utils/restUtils'
import {listenToStore} from 'utils/storeUtils'


const _state = {
  customerId: null,
  data: null,
  isLoading: true,
  isSaving: false,
  error: null
}

export default Reflux.createStore({
  listenables: CustomerSettingsActions,

  init() {
    listenToStore(this, UserStore, this._onUserStoreStateUpdate)
  },

  getInitialState() {
    return _state
  },

  _notify() {
    this.trigger(_state)
  },

  _onUserStoreStateUpdate(userStoreState) {
    const settings = _.get(
      userStoreState,
      'userInfo.customer.customer_settings'
    )
    _state.customerId = userStoreState.currentCustomerId
    if (settings) {
      _state.data = settings
      _state.isLoading = false
      this._notify()
    }
  },

  onSaveCustomerSettings(data) {
    _state.isSaving = true
    _state.error = null
    this._notify()
    requestPut('cust_settings_save', `customers/${_state.customerId}/settings`, data)
     .then(this._onSaveSuccess.bind(this))
     .catch(this._onSaveFailure.bind(this))
  },

  _onSaveSuccess(data) {
    _state.isSaving = false
    _state.data = Object.assign({}, _state.data, data)
    CommonViewActions.Notification.add({
      type: 'success',
      heading: 'Settings were saved successfully.',
      dismissTimer: 5000
    })
    this._notify()
  },

  _onSaveFailure(restError) {
    _state.isSaving = false
    _state.error = restError.message
    CommonViewActions.Notification.addRestError(restError)
    this._notify()
  }
})

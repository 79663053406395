import React, { FunctionComponent } from 'react'

export interface IconHeaderProps {
  value: string
  tooltip: string,
  className?: string
}

export const HeaderWithTooltip: FunctionComponent<IconHeaderProps> = props => {
  return (
    <span
      className={`{props.className ? ' ' + props.className : ''}`}
      data-tooltip={props.tooltip}
    >{props.value}</span>
  )
}

HeaderWithTooltip.displayName = "HeaderWithTooltip"

export const withTooltip = (tooltip: string) => (val: string) => (<HeaderWithTooltip
  tooltip={tooltip}
  value={val}
/>)

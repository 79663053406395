import _ from 'lodash'
import T from 'prop-types'
import React from 'react'
import {TextOverflow} from 'react-base'
import cx from 'classnames'


const calcChangePct = (change) => {
  let changePct = Math.abs(change * 100)
  return changePct < 10 ? (Math.round(changePct * 10) / 10) : Math.round(changePct)
}


/*
Utility component for the killchain/category bar lists
*/
class BarList extends React.Component {
  static displayName = 'BarList';

  static propTypes = {
    items: T.arrayOf(
      T.shape({
        label: T.renderable,
        value: T.number.isRequired,
        subValue: T.number,
        change: T.number,
        icon: T.string,
        tooltip: T.string,
        className: T.string,
        iconClassName: T.string,
        barClassName: T.string
      })
    ).isRequired,
    valueFormatter: T.func,
    mirror: T.bool,
    sort: T.oneOf(['asc', 'desc']),
    className: T.string,
    columns: T.number,
    maxValue: T.number //forces the max; calculated from the max of items[].value otherwise
  };

  static defaultProps = (() => ({
    valueFormatter: _.identity,
    mirror: false,
    sort: null,
    className: '',
    columns: 1,
  }))();

  render() {
    let {props} = this
    let {items, columns} = props
    let maxValue = _.isNumber(props.maxValue) ? props.maxValue : Math.max.apply(Math, _.pluck(items, 'value'))
    let cols

    // Optional sort
    if (props.sort) {
      items = _.sortBy(items, 'value')
      if (props.sort === 'desc') {
        items.reverse()
      }
    }

    if (columns === 1) {
      cols = this.renderColumn(items, maxValue)
    } else {
      cols = []
      let colSize = Math.ceil(items.length / props.columns)
      for (let i = 0; i < columns; i++) {
        cols.push(this.renderColumn(items.splice(0, colSize), maxValue, i))
      }
    }

    return (
      <div className={ cx('bar_list', props.className, {mirror: props.mirror}) }>
        { cols }
      </div>
    )
  }

  renderColumn = (items, maxValue, key) => {
    let {props} = this
    return (
      <ul key={key}>
        {
          items.map((item, i) => {
            let hasSubValue = _.isNumber(item.subValue)
            let cells = _.compact([
              item.icon ? <span className={ cx('icon', 'icon-' + item.icon, item.iconClassName) } key="icon" data-tooltip={ item.tooltip }></span> : null,
              hasSubValue ? <span className="name" key="subValLabel">{ item.label }</span> : null,
              <span className="bar_group" key="mid">
                <span className="bar">
                  <span className={ cx('bar_value', item.barClassName) } style={{
                    'width': maxValue ? Math.round(item.value / maxValue * 100) + '%' : 0,
                    'minWidth': item.value > 0 ? '1px' : '0' //distinguish very small nonzero values from zero values
                  }}></span>
                  {
                    hasSubValue ? (
                      <span className={ cx('bar_subvalue', item.barClassName) } style={{
                        'width': maxValue ? Math.round(item.subValue / maxValue * 100) + '%' : 0,
                        'minWidth': item.subValue > 0 ? '2px' : '0' //distinguish very small nonzero values from zero values
                      }}></span>
                    ) : null
                  }
                </span>
                {
                  hasSubValue ? (
                    <span className="subvalue">{ props.valueFormatter(item.subValue) }</span>
                  ) : (
                    <TextOverflow className="name">{ item.label }</TextOverflow>
                  )
                }
                {
                  _.isNumber(item.change) ? (
                    <span className="change">
                      {
                        item.change !== 0 ? (
                          <span className={ cx('sign', item.change < 0 ? 'neg' : 'pos') }>{ item.change < 0 ? '\u2212' : '+' }</span>
                        ) : null
                      }
                      { calcChangePct(item.change) + '%' }
                    </span>
                  ) : null
                }
              </span>,
              <span className="value" key="val">{ props.valueFormatter(item.value) }</span>
            ])
            if (props.processCells) {
              props.processCells(cells, item, i)
            }
            if (props.mirror) {
              cells.reverse()
            }

            return (
              <li key={ i } className={ cx(item.className, {has_icon: !!item.icon, has_subvalue: hasSubValue}) }>{ cells }</li>
            )
          })
        }
      </ul>
    )
  };
}

export default BarList

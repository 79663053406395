import _ from 'lodash'
import React from 'react'
import {
  ScrollBars,
  Tabs,
  MenuItem,
  LoadingValue
} from 'react-base'
import cx from 'classnames'
import T from 'prop-types'
import constants from 'pwConstants'
import LargeNumber from 'components/LargeNumber'
import BarList from 'components/BarList'
import EventListItem from 'components/EventListItem'
import ObservationListItem from 'components/ObservationListItem'
import IntelCardPropTypes from '../PropTypes'
import DataCell from './DataCell'
import IntelCardActions from 'actions/IntelCardActions'
import ErroredValue from './ErroredValue'
import ThreatMeter from 'components/ThreatMeter'

import addtlPropUtils from './additionalPropertiesUtil'

const OBS_TYPES = [
  'Ids',
  'IpReputation',
  'UrlReputation',
  'DnsReputation',
  'CertReputation',
  'FileReputation',
  'AnomalousFlow'
]

let getInfoTypeFns = function(type) {
  switch (type) {
    case 'ip':
      return {
        getInfo: addtlPropUtils.getIpInfo,
        getInfoValue: addtlPropUtils.getIpInfoValue
      }
    case 'domain':
      return {
        getInfo: addtlPropUtils.getDomainInfo,
        getInfoValue: addtlPropUtils.getDomainInfoValue
      }
    case 'file':
      return {
        getInfo: addtlPropUtils.getFileInfo,
        getInfoValue: addtlPropUtils.getFileInfoValue
      }
    default:
      return {
        getInfo: () => ({ isReady: () =>  true }),
        getInfoValue: () => {
          return {
            value: [],
            error: null
          }
        }
      }
  }
}

class Threat extends React.Component {
  static displayName = 'Threat'

  static propTypes = _.assign(
    {
      noEvents: T.bool
    },
    IntelCardPropTypes.dataCardShape
  )

  state = {
    topItemsFamily: this.props.noEvents ? 'observations' : 'events'
  }

  _onLoadProtosClick = e => {
    e.preventDefault()
    IntelCardActions.queryProtocols()
  }

  _onTopItemsFamilyChange = topItemsFamily => {
    this.setState({ topItemsFamily })
  }

  render() {
    return (
      <div className="intel_card intel_data_card intel_data_threat">
        <h3>Threat Intel</h3>
        <span className="timeframe">Last 30 Days</span>
        <div className="intel_card_content">
          {this.renderData()}
        </div>
      </div>
    )
  }

  componentDidUpdate() {
    if (this._threatMeter && this.props.data) {
      let infoFn = getInfoTypeFns(this.props.queryType)

      let recordedFutureInfo = () => infoFn.getInfo(this.props.data)
      let recordedFuturesValInfo = infoFn.getInfoValue(this.props.data)

      if (!recordedFuturesValInfo.error  && recordedFuturesValInfo.value) {
        var recordedFuturesData = addtlPropUtils.getAdditionalProperties(recordedFuturesValInfo.value)

        var riskData = addtlPropUtils.getCategory(recordedFuturesData, 'Recorded Future Risk Score')
        var riskScore = _.find(riskData, data => data.name === 'score')

        this._threatMeter._onDataUpdate(riskScore)
      }
    }
  }

  componentDidMount() {
    if (this._threatMeter && this.props.data) {
      let infoFn = getInfoTypeFns(this.props.queryType)

      let recordedFutureInfo = () => infoFn.getInfo(this.props.data)
      let recordedFuturesValInfo = infoFn.getInfoValue(this.props.data)

      if (!recordedFuturesValInfo.error  && recordedFuturesValInfo.value) {
        var recordedFuturesData = addtlPropUtils.getAdditionalProperties(recordedFuturesValInfo.value)

        var riskData = addtlPropUtils.getCategory(recordedFuturesData, 'Recorded Future Risk Score')
        var riskScore = _.find(riskData, data => data.name === 'score')

        this._threatMeter._onDataUpdate(riskScore)
      }
    }
  }

  renderData = () => {
    let { props, state } = this
    let data = props.data

    let maxThreatLevel = data(`threat.${state.topItemsFamily}.maxThreatLevel`)
    let maxThreatScore = data(`threat.${state.topItemsFamily}.maxThreatScore`)
    let eventCount = data('threat.events.count.total')
    let topEvents = data('threat.events.top')
    let topObs = data('threat.observations.top')
    let obsByType = data('threat.observations.types')
    let ipAddresses = data('threat.ipAddresses')

    let commonKbParams =
      props.queryType === 'ip'
        ? `start=${props.startTime}&end=${props.endTime}&timeMode=occurredAt&ip=${props.queryValue}&intelcard=${props.queryType}:${props.queryValue}`
        : props.queryType === 'device'
          ? `start=${props.startTime}&end=${props.endTime}&timeMode=occurredAt&devices=${props.queryValue}&intelcard=${props.queryType}:${props.queryValue}`
          : null

    let protoCounts = props.protocols || null
    let canQueryProtocols =
      props.queryType === 'ip' || !_.isEmpty(ipAddresses.valueOf())

    let infoFn = getInfoTypeFns(props.queryType)

    let recordedFutureInfo = () => infoFn.getInfo(props.data)
    let recordedFuturesValInfo = infoFn.getInfoValue(props.data)

    if (!recordedFuturesValInfo.error && recordedFuturesValInfo.value) {
      var recordedFuturesData = addtlPropUtils.getAdditionalProperties(recordedFuturesValInfo.value)

      var riskData = addtlPropUtils.getCategory(recordedFuturesData, 'Recorded Future Risk Score')
      var riskScore = _.find(riskData, data => data.name === 'score')
      var activeRiskColor = addtlPropUtils.setActiveRiskScore(riskScore && riskScore.value)
      var riskCriticality = _.find(riskData, data => data.name === 'category')
    }

    return (
      recordedFutureInfo().isReady()
      ? (
        <div className="threat_data">
          <div className="grid stats">
            <div className="cell">
              <DataCell
                large
                label="Max Lvl"
                className={
                  maxThreatLevel.isReady()
                    ? 'lvl_' + (maxThreatLevel.valueOf() || 'none').toLowerCase()
                    : ''
                }
              >
                {maxThreatScore.isLoading()
                  ? <LoadingValue />
                  : maxThreatScore.isError()
                    ? <ErroredValue />
                    : maxThreatScore.valueOf() || 0}
              </DataCell>
              {props.noEvents
                ? <DataCell large label="Total Obs">
                    {obsByType.isLoading()
                      ? <LoadingValue />
                      : obsByType.isError()
                        ? <ErroredValue />
                        : obsByType.valueOf() && commonKbParams
                          ? <a href={`#killbox/observations?${commonKbParams}`}>
                              <LargeNumber val={_.sum(obsByType.valueOf())} />
                            </a>
                          : <LargeNumber val={_.sum(obsByType.valueOf())} />}
                  </DataCell>
                : <DataCell large label="Events">
                    {eventCount.isLoading()
                      ? <LoadingValue />
                      : eventCount.isError()
                        ? <ErroredValue />
                        : eventCount.valueOf() && commonKbParams
                          ? <a href={`#killbox/events?${commonKbParams}`}>
                              <LargeNumber val={eventCount.valueOf()} />
                            </a>
                          : <LargeNumber val={eventCount.valueOf() || 0} />}
                  </DataCell>}
            </div>
            <div className="cell obs_counts">
              <span>Observations</span>
              {obsByType.isLoading()
                ? <div className="cell_content lg">
                    <LoadingValue />
                  </div>
                : obsByType.isError()
                  ? <div className="cell_content lg">
                      <ErroredValue />
                    </div>
                  : <table>
                      <tbody>
                        {_(OBS_TYPES)
                          .sortByAll(
                            type =>
                              -(
                                obsByType.valueOf() && obsByType.valueOf()[type]
                              ) || 0,
                            type => type
                          )
                          .map(type => {
                            let count =
                              (obsByType.valueOf() &&
                                obsByType.valueOf()[type]) ||
                              0
                            // TODO handle url/domain value - can't link, or link to explorer?
                            return (
                              <tr key={type}>
                                <th>
                                  {constants.observationTypesToNames[type]}
                                </th>
                                <td>
                                  {count && commonKbParams
                                    ? <a
                                        href={`#killbox/observations?type=${type}&${commonKbParams}`}
                                      >
                                        <LargeNumber val={count} />
                                      </a>
                                    : <LargeNumber val={count || 0} />}
                                </td>
                              </tr>
                            )
                          })
                          .value()}
                      </tbody>
                    </table>}
            </div>
          </div>

          {
            riskScore && riskCriticality
            ? (
              <div className="cell">
                <span>Recorded Future Risk Score</span>
                { addtlPropUtils.recordedFutureSourceTag() }
                <div className="cell_content flex space_between">
                  <div className="rf_risk">
                    <span className="risk_text">
                      { `${riskScore.value} - ${riskCriticality.value}` }
                    </span>
                    <div className="risk_meter">
                      <ThreatMeter
                        ref={ c => this._threatMeter = c }
                        enableLiveData={false}
                        showSubviewLabel={false}
                        liveDataGroupName={'none'}
                        liveDataGraphId={'none'}
                        liveDataOptions={ { dataOptions: { counterTypes: ['value'] }, drawOptions: {} } }
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : null
          }

          {props.noEvents
            ? <h5 className="top_items_hdr">Top Observations</h5>
            : <h5 className="top_items_hdr">
                Top{' '}
                <Tabs
                  activeTabKey={state.topItemsFamily}
                  className="top_items_tabs"
                >
                  <MenuItem
                    key="events"
                    onClick={this._onTopItemsFamilyChange}
                  >
                    Events
                  </MenuItem>
                  <MenuItem
                    key="observations"
                    onClick={this._onTopItemsFamilyChange}
                  >
                    Observations
                  </MenuItem>
                </Tabs>
              </h5>}
          <ScrollBars
            classes="pw_scrollbars_light events_list"
            outside
            slimShady
          >
            <ul className="list-unstyled">
              {props.noEvents || state.topItemsFamily === 'observations'
                ? topObs.isLoading()
                  ? <li className="no_items">
                      <LoadingValue />
                    </li>
                  : topObs.isError()
                    ? <li className="no_items">
                        <ErroredValue />
                      </li>
                    : topObs.valueOf() && topObs.valueOf().length
                      ? topObs
                          .valueOf()
                          .map((obs, i) =>
                            <ObservationListItem obs={obs} key={i} />
                          )
                      : <li className="no_items">No Observations Found</li>
                : topEvents.isLoading()
                  ? <li className="no_items">
                      <LoadingValue />
                    </li>
                  : topEvents.isError()
                    ? <li className="no_items">
                        <ErroredValue />
                      </li>
                    : topEvents.valueOf() && topEvents.valueOf().length
                      ? topEvents
                          .valueOf()
                          .map((evt, i) => <EventListItem evt={evt} key={i} />)
                      : <li className="no_items">No Events Found</li>}
            </ul>
          </ScrollBars>

          {/* TODO - temporarily removed until coldstore supports facet queries - see PW-1754
            <h5 className={ cx('protocols_hdr', protoCounts ? '' : 'loadable', canQueryProtocols ? '' : 'hidden') } onClick={ protoCounts ? null : this._onLoadProtosClick }>
            { protoCounts ? 'Protocols' : 'Protocols...' }
            { protoCounts && protoCounts.data ? 'Last 24 Hours' : null }
            </h5>
            <AnimateHeight className="protocols">
            { protoCounts ? (
              protoCounts.data ? (
                _.isEmpty(protoCounts.data) ? (
                  <span className="message">No Results</span>
                ) : (
                  <ScrollBars outside classes="pw_scrollbars_light">
                    <BarList
                      items={ _.map(protoCounts.data, (val, key) => ({
                        label: key,
                        value: val
                      })) }
                      sort="desc"
                      />
                  </ScrollBars>
                )
              ) : protoCounts.isLoading ? (
                <LoadingValue />
              ) : protoCounts.error ? (
                <ErroredValue />
              ) : null
            ) : null }
          </AnimateHeight>
          */}
        </div>
      ) : <LoadingValue />
    )
  }
}

export default Threat

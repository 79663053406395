import Reflux from 'reflux'

export default Reflux.createActions([
  /**
   * Trigger an info card query and open the info card if it is closed.
   * @param {String} type - the type of the data being searched: 'ip', 'url', 'domain'
   * @param {String} value - the value to be searched, e.g. '186.24.64.144'
   */
  'query',

  /**
   * Retry the last executed query.
   */
  'retryQuery',

  /**
   * Initiate a query for protocol counts, based on the last queried type/value if present.
   */
  'queryProtocols',

  /**
   * Open the info card if it is closed.
   */
  'openCard',

  /**
   * Close the info card if it is open. Does not clear its data.
   */
  'closeCard',

  /**
   * Load sensor performance tab data
   * @param {number} sensorId
   * @param {"month"|"week"|"day"|"hour"} timeframe
   */
  'loadSensorPerformance'
])

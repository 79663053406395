import Reflux from 'reflux'

export default Reflux.createActions([
  'checkMFA',
  'disableMFA',
  'enableMFA', //(optional token, password)
  'cancelMFA',
  'generateAccessToken',

  'changePassword',
])

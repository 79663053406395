import React, { FunctionComponent } from 'react'
import {
  observer
} from 'mobx-react'
import { CountryValue } from 'components/values/CountryValue'

export interface CountryProps {
  value: string // ISO-A2 CountryCode
}

export const Country: FunctionComponent<CountryProps> = observer(props => {
  return (
    <CountryValue
      code={props.value}
    />
  )
})

Country.displayName = "Country"
import React, {Suspense} from 'react'
import {
  CircleLoader,
  ErrorBoundary
} from 'react-base'

const Loadable = (importFunc) => {
  const LazyWrappedCmp = React.lazy(importFunc)

  const Wrapped = props => (
    <ErrorBoundary>
      <Suspense fallback={<CircleLoader loading />}>
        <LazyWrappedCmp {...props} />
      </Suspense>
    </ErrorBoundary>
  )

  Wrapped.displayName = `Loadable(${LazyWrappedCmp.displayName || '<Unknown>'})`

  return Wrapped
}

export default Loadable

import _ from 'lodash'
import React from 'react'
import T from 'prop-types'
import { StoreProvider, TextOverflow } from 'react-base'
import IntelSourceTag from './IntelSourceTag'
import AnalyticsActions from 'actions/AnalyticsActions'
import { formatDeviceAttributeValue } from 'utils/deviceUtils'
import IntegrationsStore from 'stores/IntegrationsStore'
import ExternalLink from 'components/ExternalLink'


const INTEL_SOURCE_KEY = 'NarkCarbonblackDefense'

function formatColonSeparatedValue(val) {
  // Some values come with parts separated by a colon; we want to remove it (?)
  val = (val || '').replace(/\s*:\s*/g, ' ')
  return _.trim(val) ? val : 'Unknown'
}

const formatMac = _.partial(formatDeviceAttributeValue, 'mac')



class CarbonBlackDefenseDeviceData extends React.Component {
  static propTypes = {
    deviceData: T.object, //response from entities API
    dashboardHost: T.string, //the CBD dashboard hostname configured via integrations
    startTime: T.number,
    endTime: T.number
  }

  _onExtLink() {
    AnalyticsActions.event({
      eventCategory: 'intelcard',
      eventAction: 'external-link-clicked',
      eventLabel: 'carbonblack-defense-dashboard'
    })
  }

  _renderAttribute(label, values, formatter) {
    return <div className="cell">
      <span>{label}</span>
      <div className="cell_content">
        {_.isEmpty(values)
          ? 'Unknown'
          : values.map((val, i) =>
            <TextOverflow key={i}>
              {formatter ? formatter(val) : val}
            </TextOverflow>
          )
        }
      </div>
    </div>
  }

  render() {
    const {props} = this
    const renderAttribute = this._renderAttribute

    // Extract all NarkCrowdstrike-sourced data from the entities response
    const cbDefenseAttrs = {}
    _.forEach(props.deviceData.protectWiseAttributes, (values, key) => {
      const filteredValues = _.pluck(values.filter(d => d.source === INTEL_SOURCE_KEY), 'value')
      if (filteredValues.length) {
        cbDefenseAttrs[key] = filteredValues
      }
    })

    const cbDefenseDeviceId = cbDefenseAttrs.customerDeviceId && cbDefenseAttrs.customerDeviceId[0]

    return <div className="device_source_summary">
      <h5>Carbon Black Defense Summary <IntelSourceTag source={INTEL_SOURCE_KEY} /></h5>

      { // If the result object is empty, display a collapsed rollup message
        _.isEmpty(cbDefenseAttrs)
          ? <p className="no_data">No Carbon Black Defense data found for this device.</p>
          : <React.Fragment>
              {renderAttribute('Description', cbDefenseAttrs.description, formatColonSeparatedValue)}
              {renderAttribute('MAC Address', cbDefenseAttrs.mac, formatMac)}
              {renderAttribute('Network Description', cbDefenseAttrs.networkDescription, formatColonSeparatedValue)}
              {renderAttribute('OS Type', cbDefenseAttrs.osType)}
              {renderAttribute('OS Name/Version', cbDefenseAttrs.osName)}
              {renderAttribute('Customer Device ID', cbDefenseAttrs.customerDeviceId)}
              {renderAttribute('Priority', cbDefenseAttrs.priority)}
              {cbDefenseDeviceId && props.dashboardHost &&
                <section>
                  <ExternalLink
                    onClick={ this._onExtLink }
                    href={ `https://${props.dashboardHost}/investigate?selected[deviceId]=${cbDefenseDeviceId}&selected‌[selectedTab]=DEVICE&activeTab=EVENTS&s[endTime]=${Math.floor(props.endTime / 1000)}&s[startTime‌]=${Math.floor(props.startTime / 1000)}&s[fromRow]=1&s[sortDefinition][fieldName]=TIME&s[sortDefinition][sortOrder]=DESC&s[version]=1&s[searchWindow]=CUSTOM&s[type]=INVESTIGATE&s[maxRows]=20&s[c][DEVICE_ID][0]=${cbDefenseDeviceId}` } className="btn btn-link external_link"
                    data-tooltip="View Full Carbon Black Report"
                  >
                    View Dashboard in Carbon Black <span className="icon icon-arrow-up-right" />
                  </ExternalLink>
                </section>
              }
          </React.Fragment>
      }
    </div>
  }
}

// Grab the CBD dashboard URL from its configuration, to use in building the dashboard link
const cbdUrlMapper = storeState => {
  let url = null
  const cbdDevice = _.find(storeState.devices.data || [], {vendor: 'carbonblack.defense'})
  if (cbdDevice) {
    url = _.get(cbdDevice, 'integrationJsonData.dashboardUri')
    if (url) { //strip protocol if present
      url = url.replace(/^https?:\/\//, '')
    }
  }
  return {dashboardHost: url}
}

export default props =>
  <StoreProvider store={IntegrationsStore} mapping={cbdUrlMapper}>
    <CarbonBlackDefenseDeviceData {...props} />
  </StoreProvider>

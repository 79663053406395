
import {observable} from 'mobx'
import {
  SchemerModel,
  schemer
} from 'pw-schemer-mobx'
import DeviceAttribute from './DeviceAttribute'
import {Sensor} from 'components/devices/fragments/Sensor'
import { IpAddress } from 'components/devices/fragments/IpAddress'

export class SensorIdWithIpAddress extends SchemerModel {
  constructor (initial) {
    super(initial, {
      transform: (v = {}) => {
        return {
          ip: v.attribute,
          sensorId: v.sensorId
        }
      }
    })
  }

  @observable
  @schemer.ip({
    label: 'IP',
    tooltip: 'IP Address',
    formatDisplay: IpAddress
  })
  ip: string

  @observable
  @schemer.string({
    label: 'Sensor',
    formatDisplay: Sensor
  })
  sensorId: number
}

export class SensorIdWithIpAddressAttr extends DeviceAttribute {
  @observable
  @schemer.model({
    model: SensorIdWithIpAddress
  })
  value: SensorIdWithIpAddress
}
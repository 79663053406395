import React from 'react'
import _ from 'lodash'
import cx from 'classnames'
import T from 'prop-types'
import {
  ErrorState,
  StoreProvider,
  LoadingValue
} from 'react-base'
import IntegrationsActions from 'actions/IntegrationsActions'
import IntegrationsStore from 'stores/IntegrationsStore'
import {requestGet} from 'utils/restUtils'

class FileRemediation extends React.Component {
  static displayName = 'FileRemediation'

  static propTypes = {
    fileId: T.string.isRequired,
    onCloseClick: T.func.isRequired
  }

  state = {
    isLoadingHashes: false,
    hashes: {},
    error: null
  }

  UNSAFE_componentWillMount() {
    IntegrationsActions.clearRemediationErrors()
    IntegrationsActions.loadBlockLists()
    this._loadFileHashes()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.fileId !== nextProps.fileId) {
      this._loadFileHashes(nextProps)
    }
  }

  _loadFileHashes = props => {
    const {fileId} = props || this.props
    this.setState({
      isLoadingHashes: true,
      hashes: {},
      error: null
    })
    requestGet(
      `rem_file_id_to_hashes`,
      `observations/file/metadata?ids=${fileId}`,
      {
        useSocket: false
      }
    )
    .then(
      fileResp => {
        if (fileResp && fileResp.length > 0) {
          this.setState({
            isLoadingHashes: false,
            hashes: fileResp[0].hashes
          })
        } else {
          this.setState({
            isLoadingHashes: false,
            error: "Unable to retrieve File Information"
          })
        }
      },
      (/*restError*/) => {
        this.setState({
          isLoadingHashes: false,
          error: "Unable to retrieve File Information"
        })
      }
    )
  }

  getCBLists = (devices=[], remediationLists=[]) => {
    const cbDeviceIds = devices.data
      .filter(device => device.type === 'EndpointKnowledge' && device.vendor === 'CarbonBlack')
      .map(d => d.id)
      return remediationLists.filter(list => cbDeviceIds.includes(list.device_id))
  }

  saveBlockedResource = (enabled=true) => {
    // const hash = this.props.hash
    const hash = _.get(this.state, `hashes.md5`, "") //this.props.hash
    let cbLists = this.getCBLists(this.props.devices, this.props.remediation.lists)
    cbLists.enabled = enabled
    IntegrationsActions.remediateFile(hash, cbLists)
  }

  render() {
    const {
      isLoadingHashes,
      hashes,
      error
    } = this.state
    const hash = hashes.md5 || "" //this.props.hash
    const notes = this.props.notes || ''
    const remediationErrors = this.props.remediation.errors || []
    const cbLists = _.flatten(this.getCBLists(this.props.devices, this.props.remediation.lists)
      .map(d => d.data), true)
      .filter(file => file.enabled === 'true')

    const alreadyRemediated = _.pluck(cbLists, 'fileHash').includes(hash)
    if (alreadyRemediated) {
      remediationErrors.push({
        error: '409 Conflict',
        details: <span className="validation_feedback">
          <span>This file hash is banned in Carbon Black. </span>
          <span className="remove" onClick={ this.saveBlockedResource.bind(null, false) }>Remove from banned list</span>
        </span>
      })
    }
    /*
    Notes section tbd
    <div className="file_remediation_notes">
      <label htmlFor="remediation_notes">Notes:</label>
      <textarea id="remediation_notes" className="form-control" onChange={ console.log } value={ notes } rows="5" />
    </div>
    */

    return (
      <div className="remediation_dialog_body file_hash">
        <p className="file_hash_text">Carbon Black accepts MD5 file hashes. <br /> Add the following file hash to the banned list?</p>

        <div className="file_hashes">
          <p className="hash_title">MD5</p>
          <p className="hash">{ hash }</p>
        </div>
        <div className="error_container">
          {
            remediationErrors.length
            ? (
              <section className="validation_errors mappings_wrap fade_in_right">
                {
                  remediationErrors.map(err => {
                    return (
                      <div  key={ err.error }>
                        <div className="mappings_info_window">
                          <span
                            className={`btn-icon btn icon-warning`}
                          />
                        </div>
                        <div className="grid_cell flex_1 xs">
                          <div className="validation_error">
                            <div className="validation_name">
                              { err.details || err.error }
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </section>
            ) : null
          }
          {error &&
            <section className="validation_errors mappings_wrap fade_in_right">
              <div className="mappings_info_window">
                <span
                  className={`btn-icon btn icon-warning`}
                />
              </div>
              <div className="grid_cell flex_1 xs">
                <div className="validation_error">
                  <div className="validation_name">
                    { error }
                  </div>
                </div>
              </div>
            </section>
          }
        </div>
        <footer>
          <button
            className={'btn btn-default'}
            onClick={this.props.onBack}
          >
            <span className="icon icon-arrow-left" /> Back
          </button>
          <button
            className={cx('btn', !alreadyRemediated ? 'btn-primary' : 'btn-default')}
            onClick={ this.saveBlockedResource.bind(null, true) }
            disabled={ alreadyRemediated }
          >
          {(this.props.remediation.isSaving || this.props.remediation.isLoading || isLoadingHashes)
            ? <LoadingValue />
            : alreadyRemediated
              ? 'File Banned'
              : 'Add to banned list'}
          </button>
        </footer>
      </div>
    )
  }
}


/**
 * Export with stores wrapped
 * @param {*props} props
 */
function RemediationDialogWithStores(props) {
  return (
    <StoreProvider store={IntegrationsStore}>
      <FileRemediation {...props} />
    </StoreProvider>
  )
}

RemediationDialogWithStores.displayName = 'RemediationDialogWithStores'
RemediationDialogWithStores.propTypes = FileRemediation.propTypes

export default RemediationDialogWithStores

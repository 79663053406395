import React, { FunctionComponent } from 'react'
import {
  observer
} from 'mobx-react'
import DeviceValue from 'components/values/DeviceValue'

export interface DeviceIdProps {
  value: string
}

export const DeviceId: FunctionComponent<DeviceIdProps> = observer(props => {
  return props.value ? (
    <DeviceValue
      deviceId={props.value}
      deviceAttributes={[{}]}
      size={20} />
  ) : null
})

DeviceId.displayName = "DeviceId"



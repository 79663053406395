import _ from 'lodash'
import React from 'react'
import T from 'prop-types'
import {
  DropdownMenu,
  MenuItem
} from 'react-base'


const TIMEFRAMES = [
  {
    key: 'hour',
    label: 'Past Hour'
  },
  {
    key: 'day',
    label: 'Past Day'
  },
  {
    key: 'week',
    label: 'Past Week'
  },
  {
    key: 'month',
    label: 'Past Month'
  }
]





class TimeframeSelector extends React.PureComponent {
  static propTypes = {
    timeframe: T.oneOf(_.pluck(TIMEFRAMES, 'key')),
    onChange: T.func //(newTimeframe)
  }

  render() {
    const {timeframe, onChange} = this.props

    return <div className="timeframe_selector">
      <h3><span className='icon icon-calendar' /> Timeframe</h3>
      <DropdownMenu
        closeOnItemClick
        label={_.find(TIMEFRAMES, {key: timeframe}).label}
        activeMenuKeys={[timeframe]}
      >
        {TIMEFRAMES.map(({key, label}) =>
          <MenuItem
            key={key}
            args={[key]}
            onClick={onChange}
          >
            {label}
          </MenuItem>
        )}
      </DropdownMenu>

      {/*<Tabs
        flex
        //activeTabKey={activePreset ? activePreset.label : null}
        className="timeframe_dropdown"
      >
        {presets.map((p, i) =>
          <MenuItem
            args={[p.start, p.end]}
            key={i}
            onClick={this.onTimeframePresetSelect}
          >
            {p.label}
          </MenuItem>
        )}
        {!activePreset &&
          <MenuItem key="custom" onClick={this._toggleTimeframe}>
            <span className="icon icon-calendar" data-tooltip="Custom Timeframe" />
          </MenuItem>
        }
      </Tabs>

      <TimeRangeInput
        initialValue={[start, end]}
        onSave={this._onTimeframeInputChange}
        utc={isUTC()}
      />*/}
    </div>
  }
}

export default TimeframeSelector

import _ from 'lodash'
import T from 'prop-types'
import React from 'react'
import {
  ScrollBars,
  LoadingValue
} from 'react-base'
import LargeNumber from 'components/LargeNumber'
import ObservationListItem from 'components/ObservationListItem'
import IntelCardPropTypes from '../PropTypes'
import DataCell from './DataCell'
import IntelCardActions from 'actions/IntelCardActions'
import ErroredValue from './ErroredValue'
import ThreatMeter from 'components/ThreatMeter'

import addtlPropUtils from './additionalPropertiesUtil'


class FileThreat extends React.Component {
  static displayName = 'FileThreat';
  static propTypes = IntelCardPropTypes.dataCardShape;

  _onLoadProtosClick = (e) => {
    e.preventDefault()
    IntelCardActions.queryProtocols()
  };

  render() {
    return (
      <div className="intel_card intel_data_card intel_data_threat">
        <h3>Threat Intel</h3>
        <span className="timeframe">Last 30 Days</span>
        <div className="intel_card_content">
          { this.renderData() }
        </div>
      </div>
    )
  }

  componentDidUpdate() {
    if (this._threatMeter && this.props.data) {
      let recordedFutureInfo = () => addtlPropUtils.getFileInfo(this.props.data)
      let recordedFuturesFileInfo = addtlPropUtils.getFileInfoValue(this.props.data)

      if (!recordedFuturesFileInfo.error) {
        var recordedFuturesData = addtlPropUtils.getAdditionalProperties(recordedFuturesFileInfo.value)

        var riskData = addtlPropUtils.getCategory(recordedFuturesData, 'Recorded Future Risk Score')
        var riskScore = _.find(riskData, data => data.name === 'score')

        this._threatMeter._onDataUpdate(riskScore)
      }
    }
  }

  componentDidMount() {
    if (this._threatMeter && this.props.data) {
      let recordedFutureInfo = () => addtlPropUtils.getFileInfo(this.props.data)
      let recordedFuturesFileInfo = addtlPropUtils.getFileInfoValue(this.props.data)

      if (!recordedFuturesFileInfo.error) {
        var recordedFuturesData = addtlPropUtils.getAdditionalProperties(recordedFuturesFileInfo.value)

        var riskData = addtlPropUtils.getCategory(recordedFuturesData, 'Recorded Future Risk Score')
        var riskScore = _.find(riskData, data => data.name === 'score')

        this._threatMeter._onDataUpdate(riskScore)
      }
    }
  }

  renderData = () => {
    let {props} = this
    let data = props.data

    let maxThreatLevel = data('maxThreatLevel')
    let maxThreatScore = data('maxThreatScore')
    let obsCount = data('observations.count')
    let topObs = data('observations.results')

    let recordedFutureInfo = () => addtlPropUtils.getFileInfo(data)
    let recordedFuturesFileInfo = addtlPropUtils.getFileInfoValue(data)

    if (!recordedFuturesFileInfo.error && recordedFuturesFileInfo.value) {
      var recordedFuturesData = addtlPropUtils.getAdditionalProperties(recordedFuturesFileInfo.value)

      var riskData = addtlPropUtils.getCategory(recordedFuturesData, 'Recorded Future Risk Score')
      var riskScore = _.find(riskData, data => data.name === 'score')
      var activeRiskColor = addtlPropUtils.setActiveRiskScore(riskScore && riskScore.value)
      var riskCriticality = _.find(riskData, data => data.name === 'category')
    }

    return (
      recordedFutureInfo().isReady()
      ? (
        <div className="threat_data">
          <div className="grid stats">
            <div className="cell">
              <DataCell large label="Max Lvl" className={ maxThreatLevel.isReady() ? 'lvl_' + (maxThreatLevel.valueOf() || 'none').toLowerCase() : '' }>
                { maxThreatScore.isLoading() ? (
                  <LoadingValue />
                ) : maxThreatScore.isError() ? (
                  <ErroredValue />
                ) : (
                  maxThreatScore.valueOf() || 0
                ) }
              </DataCell>
            </div>
            <div className="cell">
              <DataCell large label="Observations">{
                obsCount.isLoading() ? (
                  <LoadingValue />
                ) : obsCount.isError() ? (
                  <ErroredValue />
                ) : obsCount.valueOf() ? (
                  <a href={ `#killbox?mode=observations&start=${ props.startTime }&timeMode=occurredAt&end=${ props.endTime }&fileHash=${ props.queryValue }&intelcard=${ props.queryType }:${ props.queryValue }` }>
                    <LargeNumber val={ obsCount.valueOf() } />
                  </a>
                ) : (
                  <LargeNumber val={ obsCount.valueOf() || 0 } />
                )
              }</DataCell>
            </div>
          </div>

          {
            riskScore && riskCriticality
            ? (
              <div className="cell">
                <span>Recorded Future Risk Score</span>
                { addtlPropUtils.recordedFutureSourceTag() }
                <div className="cell_content flex space_between">
                  <div className="rf_risk">
                    <span className="risk_text">
                      { `${riskScore.value} - ${riskCriticality.value}` }
                    </span>
                    <div className="risk_meter">
                      <ThreatMeter
                        ref={ c => this._threatMeter = c }
                        enableLiveData={false}
                        showSubviewLabel={false}
                        liveDataGroupName={'none'}
                        liveDataGraphId={'none'}
                        liveDataOptions={ { dataOptions: { counterTypes: ['value'] }, drawOptions: {} } }
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : null
          }

          <h5 className="file_threat_obs_title">Top Observations</h5>
          <ScrollBars
            classes="pw_scrollbars_light events_list"
            outside
            slimShady
            slimShadyDarker
          >
            <ul className="list-unstyled">
              { topObs.isLoading() ? (
                <li className="no_items"><LoadingValue /></li>
              ) : topObs.isError() ? (
                <li className="no_items"><ErroredValue /></li>
              ) : topObs.valueOf() && topObs.valueOf().length ? (
                topObs.valueOf().map((obs, i) => <ObservationListItem obs={ obs } key={ i } />)
              ) : (
                <li className="no_items">No Observations Found</li>
              ) }
            </ul>
          </ScrollBars>
        </div>
      ) : null
    )
  };
}

export default FileThreat

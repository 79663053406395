
import _ from 'lodash'
import { deepFreeze } from 'utils/immutableUtils'

let _uiConfig = null

export function fetchUiConfig () {
  return new Promise(resolve => {
    // Using raw XHR here, to avoid circular dependency with restUtils
    const xhr = new XMLHttpRequest()
    xhr.onload = () => {
      try {
        const data = JSON.parse(xhr.responseText)
        _uiConfig = deepFreeze(data) //make immutable
        resolve()
      } catch(e) {
        fail()
      }
    }
    function fail() {
      // This is a hard stop.
      throw new Error("uiConfig.json could not be loaded")
    }
    xhr.onerror = xhr.ontimeout = fail
    xhr.open('GET', `uiConfig.json?t=${ Date.now() }`, true)
    const devHeaders = window.pwDev && window.pwDev.getProxyHeaders()
    for (const headerKey in devHeaders) {
      xhr.setRequestHeader(headerKey, devHeaders[headerKey])
    }
    xhr.send()
  })
}


export default function getConfig (path) {
  if (!_uiConfig) {
    throw new Error('getConfig() called before uiConfig.json was loaded')
  }
  if (typeof path === 'string') {
    return _.get(_uiConfig, path, null)
  }
  return _uiConfig
}


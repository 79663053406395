import _ from 'lodash'
import T from 'prop-types'
import React from 'react'
import {TextInput} from 'react-base'
import {getStringLengthValidator, getNumberRangeValidator} from 'pw-validators'

const sanitizeCountryCode = input => input.trim().toLowerCase()

export default class extends React.Component {
  static displayName = 'ManualLocationInput'

  static propTypes = {
    initialValue: T.shape({}),
    onChange: T.func.isRequired
  }

  constructor(props) {
    super(props)
    const {initialValue} = props
    this.state = {
      address: initialValue.address || '',
      country_code: initialValue.country_code || '',
      latitude: initialValue.latitude || null,
      longitude: initialValue.longitude || null
    }
  }

  _emitChange = () => {
    const {onChange} = this.props
    const {countryCode, latitude, longitude, address} = this.state
    // Emulate partial set of GoogleMap.jsx's location output format
    onChange({
      address_components: [
        {
          types: ['country'],
          short_name: countryCode
        }
      ],
      formatted_address: address,
      lat: latitude,
      lng: longitude
    })
  }

  _handleFieldChange = (stateProp, value) => {
    this.setState(_.set({}, stateProp, value), this._emitChange)
  }

  render() {
    const {countryCode, latitude, longitude, address} = this.state

    return (
      <div className="manual_location_form">
        <div>
          <label htmlFor="address">Address<b>*</b></label>
          <div>
            <TextInput
              className="input-sm form-control"
              id="address"
              placeholder={"123 Main St. New York, New York, USA"}
              validator={getStringLengthValidator(4)}
              initialValue={address}
              onSave={_.partial(this._handleFieldChange, 'address')}
            />
          </div>
        </div>
        <div className="short_fields">
          <div>
            <label htmlFor="country_code">Country Code (ISO-A2)<b>*</b></label>
            <div style={{width: '100px'}}>
              <TextInput
                className="input-sm form-control"
                id="country_code"
                placeholder="us"
                  validator={getStringLengthValidator(2, 2)}
                initialValue={countryCode}
                sanitizer={sanitizeCountryCode}
                onSave={_.partial(this._handleFieldChange, 'countryCode')}
              />
            </div>
          </div>
          <div>
            <label htmlFor="latitude">Latitude<b>*</b></label>
            <div style={{width: '150px'}}>
              <TextInput
                className="input-sm form-control"
                id="latitude"
                placeholder={"-35.1"}
                validator={getNumberRangeValidator(-90,90)}
                initialValue={latitude}
                onSave={_.partial(this._handleFieldChange, 'latitude')}
              />
            </div>
          </div>
          <div>
            <label htmlFor="longitude">Longitude<b>*</b></label>
            <div style={{width: '150px'}}>
              <TextInput
                className="input-sm form-control"
                id="longitude"
                placeholder={"-101.1"}
                validator={getNumberRangeValidator(-180, 180)}
                initialValue={longitude}
                onSave={_.partial(this._handleFieldChange, 'longitude')}
              />
          </div>
          </div>
        </div>
      </div>
    )
  }
}

import {
  observable,
  action,
  computed,
} from 'mobx'
import util from 'ui-base/src/util/genericUtil'
import { Store } from 'stores/mobx/StoreManager'
import AnalyticsActions from 'actions/AnalyticsActions'
import { DownloadPcaps } from 'actions/DownloadActions'

const getDefaultQuery  = () => {
  const defaultEnd = Date.now()
  const defaultStart = defaultEnd - util.durations(1, 'hours')

  return {
    sensorId: null,
    startTime: defaultStart,
    endTime: defaultEnd,
    ips: [],
    ports: []
  }
}

export const MAX_DURATION_MS = util.durations(4, 'hours')

export default class PcapDownloaderStore extends Store {
  @observable isDialogOpen = false
  @observable currentQuery = getDefaultQuery()

  @observable _hasInvalidIps = false
  @observable _hasInvalidPorts = false

  @action.bound
  openPcapDownloadDialog (initialQuery = {}) {
    this.isDialogOpen = true
    const withDefaults = Object.assign({}, getDefaultQuery(), initialQuery)
    this.initialQuery = withDefaults
    this.currentQuery = withDefaults
  }

  @action.bound
  closeDialog () {
    this.isDialogOpen = false
  }

  @action.bound
  reset () {
    this.currentQuery = this.initialQuery
  }

  @action.bound
  submit () {
    if (this.isValid) {
      const _filter = {}
      if (this.currentQuery.ips && this.currentQuery.ips.length > 0) {
        _filter.ips = this.currentQuery.ips
      }
      if (this.currentQuery.ports && this.currentQuery.ports.length > 0) {
        _filter.ports = this.currentQuery.ports
      }
      DownloadPcaps({
        sensorId: this.currentQuery.sensorId,
        startTime: this.currentQuery.startTime,
        endTime: this.currentQuery.endTime,
        filter: _filter
      })

      AnalyticsActions.event({
        eventCategory: 'pcap',
        eventAction: 'pcap_download',
        eventLabel: 'manual_entry'
      })

      this.isDialogOpen = false
    }
  }

  @action.bound
  handleIpsChange (newVal, blockData) {
    this._hasInvalidIps = blockData.some(d => d.invalid)
    if (!this._hasInvalidIps) {
      this.currentQuery.ips = newVal.filter(v => !!v)
    }
  }

  @action.bound
  handlePortsChange (newVal, blockData) {
    this._hasInvalidPorts = blockData.some(d => d.invalid)
    if (!this._hasInvalidPorts) {
      this.currentQuery.ports = newVal.filter(v => !!v).map(port => +port)
    }
  }

  @action.bound
  handleSensorChange (sensorId) {
    this.currentQuery.sensorId = +sensorId
  }

  @action.bound
  handleTimeRangeChange ([startTime, endTime]) {
    this.currentQuery.startTime = startTime
    this.currentQuery.endTime = endTime
  }

  @computed
  get isTimeRangeValid () {
    return Boolean(
      this.currentQuery.startTime
      && this.currentQuery.endTime
      && this.currentQuery.endTime > this.currentQuery.startTime
      && (this.currentQuery.endTime - this.currentQuery.startTime) <= MAX_DURATION_MS
    )
  }

  @computed
  get hasIpOrPort () {
    return Boolean(
      this.currentQuery.ips.length + this.currentQuery.ports.length > 0
    )
  }

  @computed
  get isValid () {
    return Boolean(
      this.isTimeRangeValid
      && this.currentQuery.sensorId
      && this.hasIpOrPort
      && !this._hasInvalidIps
      && !this._hasInvalidPorts
    )
  }
}


import _ from 'lodash'
import genericUtil from 'ui-base/src/util/genericUtil'

import md5 from 'md5'
import stringHash from 'string-hash'
import TTLCache from './TTLCache'
import {requestGet} from 'utils/restUtils'

// Stroke width relative to visible dimensions of the image. Can be thought of as "A/B where
// the stroke will appear as A pixels wide when the image is displayed at B pixels wide"
const STROKE_WIDTH_PERCENT = 1 / 20

const _requests = {}

const _deviceAttributeLookupCache = new TTLCache({
  ttl: genericUtil.durations(3, 'minutes'),
  maxEntries: 100
})

const _deviceCanvasDataUrlCache = new TTLCache({
  ttl: genericUtil.durations(5, 'minutes'),
  maxEntries: 500
})

/**
 * Jdenticon
 * https://github.com/dmester/jdenticon
 * Copyright © Daniel Mester Pirttijärvi
 */

function _decToHex(v) {
  v |= 0 // Ensure integer value
  return v < 0
    ? '00'
    : v < 16 ? '0' + v.toString(16) : v < 256 ? v.toString(16) : 'ff'
}

function _hueToRgb(m1, m2, h) {
  h = h < 0 ? h + 6 : h > 6 ? h - 6 : h
  return _decToHex(
    255 *
      (h < 1
        ? m1 + (m2 - m1) * h
        : h < 3 ? m2 : h < 4 ? m1 + (m2 - m1) * (4 - h) : m1)
  )
}

const color = {
  /**
   * @param {number} r Red channel [0, 255]
   * @param {number} g Green channel [0, 255]
   * @param {number} b Blue channel [0, 255]
   */
  rgb: function(r, g, b) {
    return '#' + _decToHex(r) + _decToHex(g) + _decToHex(b)
  },
  /**
   * @param h Hue [0, 1]
   * @param s Saturation [0, 1]
   * @param l Lightness [0, 1]
   */
  hsl: function(h, s, l) {
    // Based on http://www.w3.org/TR/2011/REC-css3-color-20110607/#hsl-color
    if (s === 0) {
      const partialHex = _decToHex(l * 255)
      return '#' + partialHex + partialHex + partialHex
    } else {
      const m2 = l <= 0.5 ? l * (s + 1) : l + s - l * s
      const m1 = l * 2 - m2
      return (
        '#' +
        _hueToRgb(m1, m2, h * 6 + 2) +
        _hueToRgb(m1, m2, h * 6) +
        _hueToRgb(m1, m2, h * 6 - 2)
      )
    }
  },

  // This function will correct the lightness for the "dark" hues
  correctedHsl: function(h, s, l) {
    // The corrector specifies the perceived middle lightnesses for each hue
    const correctors = [0.55, 0.5, 0.5, 0.46, 0.6, 0.55, 0.55]
    const corrector = correctors[(h * 6 + 0.5) | 0]

    // Adjust the input lightness relative to the corrector
    l =
      l < 0.5 ? l * corrector * 2 : corrector + (l - 0.5) * (1 - corrector) * 2

    return color.hsl(h, s, l)
  }
}

const lightnessConfig = {
  color: [0.5, 0.9]
  // grayscale: [0.1, 0.5]
}

function lightness(configName, defaultMin, defaultMax) {
  const range =
    lightnessConfig[configName] instanceof Array
      ? lightnessConfig[configName]
      : [defaultMin, defaultMax]

  /**
   * Gets a lightness relative the specified value in the specified lightness range.
   */
  return function(value) {
    value = range[0] + value * (range[1] - range[0])
    return value < 0 ? 0 : value > 1 ? 1 : value
  }
}
const saturation = 0.2

const colorConfig = {
  saturation: typeof saturation === 'number' ? saturation : 0.5,
  colorLightness: lightness('color', 0.4, 0.8)
  // grayscaleLightness: lightness("grayscale", 0.3, 0.9)
}

/*
Client-side Canvas tag based Identicon rendering code
https://github.com/donpark/identicon/blob/master/identicon-canvas/identicon_canvas.js
@author  Don Park
@version 0.2
@date    January 21th, 2007
*/

const BASE_SHAPES = [
  [0, 4, 7, 13], // 0
  [0, 8, 20], // 1
  [2, 24, 20], // 2
  [0, 18, 9], // 3
  [2, 14, 22, 10], // 4
  [0, 12, 24, 22], // 5
  [2, 24, 22, 13], // 6
  [0, 14, 22], // 7
  // [6, 8, 10, 18, 16],
  // [4, 20, 10, 12, 2],
  [0, 5, 12, 14], // 8
  [10, 14, 22], // 9
  [20, 10, 12], // 10
  // [10, 2, 12],
  // [0, 2, 10],
  [10, 20, 18] // 11
]

function _renderShape(
  ctx,
  x,
  y,
  totalSize,
  shapeSize,
  shape,
  turn,
  invert,
  foreColor,
  backColor,
  rotate,
  fillMode
) {
  shape %= BASE_SHAPES.length
  turn %= 4
  if (shape === 15) {
    invert = !invert
  }

  const vertices = BASE_SHAPES[shape]
  // let offset = size / 2
  // let scale = size / 4
  const offset = 0
  const scale = shapeSize / 4

  ctx.save()

  const _mode = fillMode ? 'fill' : 'stroke'

  // paint background
  ctx[`${_mode}Style`] = invert ? foreColor : backColor
  // ctx.fillRect(x, y, size, size)

  if (_mode === 'stroke') {
    ctx.lineWidth = totalSize * STROKE_WIDTH_PERCENT
  }

  // build shape path
  // ctx.translate(x + offset, y + offset)
  ctx.translate(x + offset, y + offset)
  if (rotate) {
    ctx.rotate(rotate)
  }
  ctx.rotate(turn * Math.PI / 2)
  ctx.beginPath()
  ctx.moveTo(
    vertices[0] % 5 * scale - offset,
    Math.floor(vertices[0] / 5) * scale - offset
  )
  for (let i = 1; i < vertices.length; i++) {
    const x = vertices[i] % 5 * scale - offset
    const y = Math.floor(vertices[i] / 5) * scale - offset
    ctx.lineTo(x, y)
    // ctx.quadraticCurveTo(x / 2, y / 4, x, y)
  }
  ctx.closePath()

  // offset and rotate coordinate space by shape position (x, y) and
  // 'turn' before rendering shape

  // render rotated shape using fore color (back color if inverted)
  ctx[`${_mode}Style`] = invert ? backColor : foreColor
  ctx[_mode]()

  // restore rotation
  ctx.restore()
}

const degreesToRadians = degrees => degrees * Math.PI / 180

function _renderDeviceIdenticon(node, string, size) {
  if (!node || !string || !size) {
    return
  }
  const ctx = node.getContext('2d')

  const len = string.length / 2 // Split at this length, two components will be individually hashed for inner/outer BASE_SHAPES
  const split1 = string.slice(len)
  const split2 = string.slice(0, len)

  const hash1 = md5(split1)
  const hash2 = md5(split2)
  const code1 = stringHash(hash1)
  const code2 = stringHash(hash2)

  // Available colors for this icon
  const innerHue = parseInt(hash1.substr(-7), 16) / 0xfffffff
  const outerHue = parseInt(hash2.substr(-7), 16) / 0xfffffff
  const innerColor = color.correctedHsl(
    innerHue,
    colorConfig.saturation,
    colorConfig.colorLightness(0.2)
  )
  const outerColor = color.correctedHsl(
    outerHue,
    colorConfig.saturation,
    colorConfig.colorLightness(0.2)
  )

  const maxShapeIdx = BASE_SHAPES.length - 1
  const corner1Invert = ((code1 >> 7) & 1) !== 0
  const corner2Invert = ((code2 >> 7) & 1) !== 0
  const outerShape = (code1 >> (corner1Invert ? 9 : 10)) & maxShapeIdx
  const innerShape = (code2 >> (corner2Invert ? 9 : 10)) & maxShapeIdx
  const radialOffset = ((code1 >> 14) & 1) !== 0 ? 1 : 2
  const centerOffset = size / 2

  // console.log('BASE_SHAPES', outerShape, innerShape) //, rotation, radialFrequency, availableColors)

  const shapeSize = size / 4
  const innerShapeSize = shapeSize //* 1.5
  const outerShapeSize = shapeSize

  const radiusInner = size / 4
  const radiusOuter = size / 3

  const RADIUS_PADDING_INNER = size / 8
  const RADIUS_PADDING_OUTER = 0

  const rotations = [-60, -30, -15, 0, 15, 30, 60, 90, 120]
  // let rotations = [-60, -30, 0, 30, 60]
  const rotationIndex = 6
  const innerRotation =
    rotations[parseInt(hash1.charAt(rotationIndex), 16) % rotations.length]
  const outerRotation = -rotations[
    parseInt(hash2.charAt(rotationIndex + 1), 16) % rotations.length
  ]

  const radialFrequencies = [3, 5, 6, 7]
  const radialFrequencyIndex = 2
  const radialFrequency =
    radialFrequencies[
      parseInt(hash1.charAt(radialFrequencyIndex), 16) %
        radialFrequencies.length
    ]

  const angles = _.range(0, 360, 360 / radialFrequency)
  let split = (angles[2] - angles[1]) / 2 // Offset for inner rotations
  split /= radialOffset

  for (let i = 0; i < angles.length; i++) {
    const angle = angles[i]
    const angleRadians = degreesToRadians(angle) // base angle tangent to ring

    const xOuter = (radiusOuter - RADIUS_PADDING_OUTER) * Math.cos(angleRadians)
    const yOuter = (radiusOuter - RADIUS_PADDING_OUTER) * Math.sin(angleRadians)
    const xInner =
      (radiusInner - RADIUS_PADDING_INNER) * Math.cos(angleRadians + split)
    const yInner =
      (radiusInner - RADIUS_PADDING_INNER) * Math.sin(angleRadians + split)

    // Alternate colors if total is even
    // let colorIdx = angles.length % 2 === 0 ? i % 2 : 0
    // let colorIdx = 0
    // let _foreColor = availableColors[selectedColorIndexes[colorIdx]]
    // let _foreColor = availableColors[colorIdx]

    _renderShape(
      ctx,
      xOuter + centerOffset,
      yOuter + centerOffset,
      size,
      innerShapeSize,
      innerShape,
      0,
      false,
      innerColor,
      null,
      degreesToRadians(angle + innerRotation),
      false
    )
    _renderShape(
      ctx,
      xInner + centerOffset,
      yInner + centerOffset,
      size,
      outerShapeSize,
      outerShape,
      0,
      false,
      outerColor,
      null,
      degreesToRadians(angle + outerRotation),
      false
    )
  }
}

function _getDeviceIdenticonImgData(deviceId, size = 32, pixelRatio = 1) {
  if (!deviceId) {
    return ''
  }
  const _correctedSize = size * pixelRatio

  let _canvas = document.createElement('canvas')
  _canvas.setAttribute('height', _correctedSize + 'px')
  _canvas.setAttribute('width', _correctedSize + 'px')
  const ctx = _canvas.getContext('2d')
  ctx.setTransform(1, 0, 0, 1, 0, 0) // Reset Transform
  ctx.clearRect(0, 0, _canvas.width, _canvas.height)
  ctx.beginPath()

  _renderDeviceIdenticon(_canvas, deviceId, _correctedSize)

  const _data = _canvas.toDataURL()
  _canvas = null // release element
  return _data
}

/**
 *
 * Begin exported utility functions
 */

// device sources can be Customer Upload, External Integration, or PW Device DB
// the strategy is to prefer Customer Upload over any other source, and
// to prefer any integration source over PW, integration sources are ranked as
// they are returned from nark today
export function getMostRelevantHostname(data) {

  if (!data) { return }
  const hostsBySource = {
    pw: _.get(data.protectWiseAttributes, 'hostName', []), //pw return a list of each attr
    bulk: data.bulkAttributes,
    customer: data.customerAttributes,
    dhcp: data.additionalAttributes
  }

  if (hostsBySource.bulk && hostsBySource.bulk.hostName) {
    return { value: hostsBySource.bulk.hostName }
  } else if (hostsBySource.customer && hostsBySource.customer.hostName) {
    return { value: hostsBySource.customer.hostName }
  } else if (hostsBySource.pw.length) {
    return _.first(hostsBySource.pw)
  } else if (hostsBySource.dhcp && hostsBySource.dhcp['DHCP:AdvertisedFQDN']) {
    return { value: hostsBySource.dhcp['DHCP:AdvertisedFQDN'] }
  } else {
    return null
  }

}

export function getDeviceAttributes(deviceId, forceRefresh = false) {
  const deferred = genericUtil.defer()
  if (!deviceId) {
    deferred.reject(
      new Error('deviceUtils.getDeviceAttributes :: deviceId is required')
    )
  }

  if (_requests[deviceId]) {
    return _requests[deviceId] // return the pending request promise
  }

  const _cached = _deviceAttributeLookupCache.get(deviceId)

  if (forceRefresh || !_cached) {
    _requests[deviceId] = deferred.promise // Cache this pending request for any parallel requests for the same deviceId
    requestGet(`get_device_data_${deviceId}`, `entities/${deviceId}`, {useSocket: true})
      .then(data => {
        // attributes will now include a list of hosts keyed by upload source
        _deviceAttributeLookupCache.set(deviceId, data)
        deferred.resolve(data)
        delete _requests[deviceId]
      })
      .catch((restError) => {
        console.error(`Device Load Error:`, restError)

        _deviceAttributeLookupCache.set(deviceId, null) // FIXME Cache the request error?
        deferred.reject(
          new Error('Error loading device attributes: ' + deviceId)
        )
        delete _requests[deviceId]
      })
  } else if (_cached) {
    deferred.resolve(_cached)
  }

  return deferred.promise
}

// For when you want the attributes if they're already cached, but don't want to trigger a query if not
export function getCachedDeviceAttributes(deviceId) {
  const entry = _deviceAttributeLookupCache.get(deviceId)
  return (entry && entry.attributes) || null
}

export function formatDeviceAttributeValue(type, value) {
  switch (type) {
    case 'mac':
      return _.map(
        value,
        (c, i) => (i % 2 === 0 && i !== 0 ? `:${c}` : c)
      ).join('')
    default:
      return value
  }
}

export var deviceCanvasDataUrlCache = {
  getImgData(deviceId, size, pixelRatio) {
    const key = `${deviceId}-${size}x${pixelRatio}`
    let data = _deviceCanvasDataUrlCache.get(key)
    if (!data) {
      data = _getDeviceIdenticonImgData(deviceId, size, pixelRatio)
      _deviceCanvasDataUrlCache.set(key, data)
    }
    return data
  }
}

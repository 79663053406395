import { INTERNAL_DOMAIN_REGEX } from 'pwConstants'

export function initGoogleAnalytics(gaAccountKey=null) {
  // If there is no GA account configured, or this is an internal domain, don't even attempt to load GA.
  if (!gaAccountKey || INTERNAL_DOMAIN_REGEX.test(location.hostname) || process.env.NODE_ENV === 'development') {
    console.info(`Analytics: no GA account or internal PW domain, will not load GA scripts`)
  } else {
    // Typical Google Analytics init snippet:
    (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
    (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
    m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
    })(window,document,'script','//www.google-analytics.com/analytics.js','ga');

    ga('create', gaAccountKey, 'auto')
  }
}
import React from 'react'
import _ from 'lodash'
import T from 'prop-types'
import cx from 'classnames'
import {
  DataTable,
  StoreProvider,
  Checkbox,
  TypeaheadInput,
  TextOverflow,
  LoadingValue
} from 'react-base'
import UserProvider from 'components/UserProvider'
import InfoHint from 'components/InfoHint'
import IntegrationsActions from 'actions/IntegrationsActions'
import IntegrationsStore from 'stores/IntegrationsStore'
import { DEVICE_VENDORS } from 'constants/integrationsConstants'
import { pluralize } from 'pw-formatters'

class AwsRemediationDialog extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  UNSAFE_componentWillMount() {
    IntegrationsActions.clearRemediationErrors()
  }

  getInfoHintMessage = () => {
    return <InfoHintMessage onClick={ this.props.onCloseClick } />
  }

  saveBlockedResource = () => {
    const applianceId = _.find(this.props.devices.data, ((dev) => dev.vendor === DEVICE_VENDORS.AWS), 'id').id
    IntegrationsActions.addAwsTag(this.props.resource, applianceId)
  }

  render() {
    let errors = this.props.remediation.errors || []
    if (!this.props.tag.key || !this.props.tag.value) {
      errors.push({
        details: (
          <p onClick={ this.props.onCloseClick }>You must set a remediation tag in <a href="/#settings/context-fusion/integrations">context fusion</a> in order to add an AWS tag.</p>
        )
      })
    }
    return (
      <div className={`remediation_dialog_body aws_ip`}>

        <div className="title_text">
          <p>
            Add the following EC2 tag to device
            <InfoHint
              getMessage={ this.getInfoHintMessage }
            />
          </p>
        </div>

        <div className="aws_remediation_tag">
          <div className="grid_cell">
            <p>KEY</p>
              <TextOverflow>
                <p className="tag_key">
                  { this.props.tag.key || 'Not Set' }
                </p>
              </TextOverflow>
          </div>
          <div className="grid_cell">
            <p>VALUE</p>
              <TextOverflow>
                <p className="tag_val">
                  { this.props.tag.value || 'Not Set' }
                </p>
              </TextOverflow>
          </div>
        </div>

        <div className="error_container">
          {
            errors.length
            ? (
              <section className="validation_errors mappings_wrap fade_in_right">
                {
                  errors.map((err, i) => {
                    return (
                      <div  key={ `err_key_${i}` }>
                        <div className="mappings_info_window">
                          <span
                            className={`btn-icon btn icon-warning`}
                          />
                        </div>
                        <div className="grid_cell flex_1 xs">
                          <div className="validation_error">
                            <div className="validation_name">
                              { err.details || err.error }
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </section>
            ) : null
          }
        </div>

        <footer>
          <button
            className={'btn btn-default'}
            onClick={this.props.onBack}
          >
            <span className="icon icon-arrow-left" /> Back
          </button>
          <button
            className={cx('btn', 'btn-default', this.props.remediation.blocked.length && 'btn-success' )}
            disabled={errors.length}
            onClick={this.saveBlockedResource}
          >
            {this.props.remediation.isSaving
              ? <LoadingValue />
            : !this.props.remediation.errors &&
                this.props.remediation.blocked.length
                ? <span>
                    <span className="icon icon-checkmark" /> { 'Tag Added' }
                  </span>
                : 'Add Tag'}
          </button>
        </footer>
      </div>
    )
  }
}

AwsRemediationDialog.displayName = 'AwsRemediationDialog'
AwsRemediationDialog.propTypes = {
  resource: T.string.isRequired,
  onCloseClick: T.func.isRequired,
  remediation: T.object,
  tag: T.object
}

/**
 * Export with stores wrapped
 * @param {*props} props
 */
function AwsRemediationDialogWithStores(props) {
  return (
    <UserProvider>
      <StoreProvider store={IntegrationsStore}>
        <AwsRemediationDialog {...props} />
      </StoreProvider>
    </UserProvider>
  )
}

AwsRemediationDialogWithStores.displayName = 'AwsRemediationDialogWithStores'
AwsRemediationDialogWithStores.propTypes = AwsRemediationDialog.propTypes
AwsRemediationDialogWithStores.defaultProps = {
  tag: {
    key: null,
    value: null
  }
}


class InfoHintMessage extends React.Component {
  render() {
    return (
      <p onClick={ this.props.onClick }>To edit your EC2 tag, go to <a href="/#settings/context-fusion/integrations">context fusion</a> and edit the tag option under configuration for the appropriate device.</p>
    )
  }
}

export default AwsRemediationDialogWithStores

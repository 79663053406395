import _ from 'lodash'
import React from 'react'
import T from 'prop-types'
import {
  Button,
  ScrollBars,
  StoreProvider,
  CircleLoader,
  ErrorState
} from 'react-base'
import UserProvider from 'components/UserProvider'
import IntelListStore from 'stores/intel-management/IntelListStore'
import IntelSubscriptionStore from 'stores/intel-management/IntelSubscriptionStore'
import IntelRuleQuickAddStore from 'stores/intel-management/IntelRuleQuickAddStore'
import IntelRuleStore from 'stores/intel-management/IntelRuleStore'
import IntelManagementActions from 'actions/IntelManagementActions'
import IntelRuleQuickAddActions from 'actions/IntelRuleQuickAddActions'
import SensorStore from 'stores/SensorStore'
import {
  LIST_STORE_STATE_SHAPE,
  RULE_STORE_STATE_SHAPE,
  SUBSCRIPTION_STORE_STATE_SHAPE
} from 'constants/intelManagementConstants'
import QuickAddLists from './QuickAddLists'
import QuickSetSubscriptions from './QuickSetSubscriptions'
import QuickSetThreatMappings from './QuickSetThreatMappings'
import {
  VALUE_ACTION_OPTIONS_BY_ID,
  VALUE_ACTION_OPTION_IDS,
  VALUE_TYPE_OPTIONS
} from 'constants/searchableValueConstants'
import {requestGet} from 'utils/restUtils'

const BASE_STAGES = {
  CHOOSE_LIST: {
    id: 'chooseList',
    title: 'Select Intel List',
    tooltip: 'Choose the Intel List that this new Intel Rule will be added to.'
  },
  ACCEPT_SUBSCRIPTION: {
    id: 'acceptSubscriptions',
    title: 'Review Sensor Subscriptions',
    tooltip:
      'Verify that the Intel List you are adding to is subscribed to by your Sensors.'
  },
  ACCEPT_MAPPINGS: {
    id: 'acceptThreatMappings',
    title: 'Set Threat Mappings',
    tooltip: 'Set the Intel Threat mappings for this new Intel Rule.'
  },
  FINALIZE: {
    id: 'finalize',
    title: 'Finish',
    tooltip: 'Verify that the new rule was created successfully.'
  }
}

function _getStoreMapper(propName) {
  return function(storeState) {
    const _out = []
    _out[propName] = storeState
    return _out
  }
}

function getSaveStageText(percent) {
  if (percent === 0) {
    return 'Saving Intel List'
  } else if (percent === 33) {
    return 'Saving Intel Rule'
  } else if (percent === 66) {
    return 'Saving Subscriptions'
  } else if (percent === 99) {
    return 'Wrapping Up'
  } else if (percent === 100) {
    return 'Done'
  }
}

const COMMON_PROP_TYPES = {
  hasPermission: T.func.isRequired,
  isDialogOpen: T.bool,
  value: T.string,
  valueType: T.oneOf(_.values(VALUE_TYPE_OPTIONS))
}

class IntelRuleQuickAddWizard extends React.Component {
  static displayName = 'IntelRuleQuickAddWizard'

  static propTypes = _.assign(
    {
      onClose: T.func.isRequired,
      action: T.oneOf(_.values(VALUE_ACTION_OPTION_IDS)),
      currentCustomerId: T.number, // From UserProvider
      listStoreState: LIST_STORE_STATE_SHAPE,
      ruleStoreState: RULE_STORE_STATE_SHAPE,
      sensorStoreState: T.object,
      subscriptionStoreState: SUBSCRIPTION_STORE_STATE_SHAPE,
      quickAddStoreState: T.shape({
        selectedListId: T.string,
        newListName: T.string,
        setDefaultListTag: T.bool,
        sensorIdsNeedSubUpdate: T.array,
        intelRule: T.object
      })
    },
    COMMON_PROP_TYPES
  )

  constructor(props) {
    super(props)
    const { action } = props
    this.state = {
      hasAcceptedSubscriptions: false,
      hasAcceptedThreatMappings: action !== VALUE_ACTION_OPTION_IDS.BLACKLIST, // Default to true for non-blacklist, to skip that stage
      stageId: _.first(_.values(BASE_STAGES)).id,
      fileHashLoading: false,
      fileHashError: null
    }
  }

  UNSAFE_componentWillMount() {
    const {
      action,
      valueType,
      value
    } = this.props
    IntelManagementActions.loadAllLists()
    IntelManagementActions.loadAllSubscriptions()
    IntelManagementActions.loadAllIntelTags()
    if (valueType === VALUE_TYPE_OPTIONS.FILEHASH) {
      // File value
      this._loadFileHashes()
    }
    else {
      IntelRuleQuickAddActions.initWizard(action, valueType, value)
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      saveProgress: nextSaveProgress,
      saveError
    } = nextProps.quickAddStoreState
    const { saveProgress } = this.props.quickAddStoreState
    if (nextProps.valueType === VALUE_TYPE_OPTIONS.FILEHASH && this.props.value !== nextProps.value) {
      // File value changed
      this._loadFileHashes(nextProps)
    }
    if (!saveError && saveProgress === 100 && nextSaveProgress === -1) {
      // All done here...
      this.closeSelf()
    }
  }

  _loadFileHashes = props => {
    const {
      value,
      valueType,
      action
    } = props || this.props
    const fileId = value
    this.setState({
      fileHashLoading: true,
      fileHashError: null
    })
    requestGet(
      `intelrule_quickadd_get_file_id_to_hashes`,
      `observations/file/metadata?ids=${fileId}`,
      {
        useSocket: false
      }
    )
    .then(
      fileResp => {
        const md5hash = _.get(fileResp, `0.hashes.md5`, null)
        if (md5hash) {
          this.setState({
            fileHashLoading: false
          })
          IntelRuleQuickAddActions.initWizard(action, valueType, md5hash)
        } else {
          this.setState({
            fileHashLoading: false,
            fileHashError: "Unable to retrieve File Information"
          })
        }
      },
      restError => {
        this.setState({
          fileHashLoading: false,
          fileHashError: restError
        })
      }
    )
  }

  componentWillUnmount() {
    IntelRuleQuickAddActions.reset()
  }

  closeSelf = () => {
    this.props.onClose()
  }

  handleSaveClick = () => {
    IntelRuleQuickAddActions.save()
    this.setStage(BASE_STAGES.FINALIZE.id)
  }

  setStage = stageId => {
    this.setState({
      stageId: stageId
    })
  }

  renderStage = (stageId, selectedListName) => {
    const {
      action,
      currentCustomerId,
      listStoreState,
      subscriptionStoreState,
      sensorStoreState,
      quickAddStoreState,
      ruleStoreState
    } = this.props
    const {
      newListName,
      saveError,
      setDefaultListTag,
      selectedListId,
      sensorIdsNeedSubUpdate,
      intelRule,
      saveProgress,
      intelRuleValidationMsg
    } = quickAddStoreState
    const { lists } = listStoreState
    const {
      isLoadingSubscriptions,
      isSavingSubscriptions,
      subscriptionsBySensorId
    } = subscriptionStoreState
    const { enabledSensors, sensorSets } = sensorStoreState
    const { allTags } = ruleStoreState
    const actionTitle = VALUE_ACTION_OPTIONS_BY_ID[action].title

    let stage = null
    let continueBtnValidationMsg = null
    let continueBtn = null
    let doneSaving = null


    switch (stageId) {
      case BASE_STAGES.CHOOSE_LIST.id:
        stage = (
          <QuickAddLists
            {...{
              currentCustomerId,
              lists,
              selectedListId,
              newListName,
              setDefaultListTag
            }}
          />
        )
        continueBtnValidationMsg = !selectedListId
          ? 'Choose a list for the new Intel Rule.'
          : null
        break
      case BASE_STAGES.ACCEPT_SUBSCRIPTION.id:
        stage = (
          <QuickSetSubscriptions
            {...{
              selectedListId,
              selectedListName,
              currentCustomerId,
              enabledSensors,
              sensorSets,
              subscriptionsBySensorId,
              isLoadingSubscriptions,
              isSavingSubscriptions,
              sensorIdsNeedSubUpdate,
              actionTitle
            }}
          />
        )
        continueBtnValidationMsg = null
        break
      case BASE_STAGES.ACCEPT_MAPPINGS.id:
        stage = (
          <QuickSetThreatMappings
            {...{
              allTags,
              selectedListId,
              selectedListName,
              intelRule
            }}
            isWhitelist={action === VALUE_ACTION_OPTION_IDS.WHITELIST}
          />
        )
        continueBtnValidationMsg = intelRuleValidationMsg // Valid mappings OR is whitelist and has no reqiured mappings
        continueBtn = (
          <Button
            args={[]}
            className={`btn btn-${!continueBtnValidationMsg
              ? 'primary'
              : 'error'}`}
            data-tooltip={continueBtnValidationMsg}
            onClick={
              continueBtnValidationMsg != null ? _.noop : this.handleSaveClick
            }
          >
            <span>
              <span className="icon icon-checkmark" /> Save
            </span>
          </Button>
        )
        break
      case BASE_STAGES.FINALIZE.id:
        doneSaving = saveProgress === -1
        stage = (
          <div className="finalize_wrap">
            <h3>{getSaveStageText(saveProgress || 0)}...</h3>
            <div className="progress progress-striped active">
              <div
                className="progress-bar"
                style={{ width: saveProgress || 3 + '%' }}
              >
                &nbsp;Saving... {saveProgress || 0}%
              </div>
            </div>
            {saveError ? <ErrorState error={saveError} /> : null}
          </div>
        )
        continueBtnValidationMsg = null // Done saving
        continueBtn = (
          <Button
            args={[]}
            className={`btn btn-${doneSaving ? 'primary' : 'default'}`}
            data-tooltip={
              doneSaving ? null : 'Please wait for the Intel Rule to be saved'
            }
            disabled={!doneSaving}
            onClick={!doneSaving ? _.noop : this.closeSelf}
          >
            <span>
              <span className="icon icon-close" /> Finished
            </span>
          </Button>
        )
        break
      default:
        stage = <div>No Matching Stage</div>
    }

    return {
      stage,
      continueBtn,
      continueBtnValidationMsg
    }
  }

  render() {
    const {
      listStoreState,
      subscriptionStoreState,
      quickAddStoreState
    } = this.props
    const { newListName, selectedListId } = quickAddStoreState
    const { lists, isLoading: isLoadingLists } = listStoreState
    const { subscriptionError, isLoadingSubscriptions } = subscriptionStoreState
    const {
      stageId,
      fileHashLoading,
      fileHashError
    } = this.state

    const isLoading = fileHashLoading || isLoadingLists || isLoadingSubscriptions
    const _error = fileHashError || listStoreState.error || subscriptionError
    const stageIds = _.map(BASE_STAGES, stage => stage.id)
    const currentStageIdx = stageIds.indexOf(stageId)
    const maxStageIdx = stageIds.length - 1
    const selectedListName = !selectedListId
      ? ''
      : selectedListId === 'new'
        ? newListName
        : _.get(_.find(lists, list => list.id === selectedListId), 'name', '')
    const { stage, continueBtn, continueBtnValidationMsg } = this.renderStage(
      stageId,
      selectedListName
    )

    return (
      <div className={`intel_rule_quick_add_wizard`}>
        <div className="wizard_stages">
          <h6>Steps</h6>
          <ol>
            {_.map(BASE_STAGES, stageDef => {
              const stageStatus =
                stageIds.indexOf(stageDef.id) > currentStageIdx
                  ? 'not_done'
                  : stageDef.id === stageId ? 'current' : 'done'
              return (
                <li
                  className={`wizard_stage ${stageStatus}`}
                  data-tooltip={stageDef.tooltip}
                  key={stageDef.id}
                >
                  <Button
                    args={[stageDef.id]}
                    className={'wizard_stage_link'}
                    onClick={stageStatus === 'done' ? this.setStage : _.noop}
                  >
                    {stageStatus === 'done' ? (
                      <span className="icon icon-checkmark" />
                    ) : null}
                    {stageDef.title}
                  </Button>
                </li>
              )
            })}
          </ol>
        </div>
        <div className="wizard_step_wrap">
          <div className="wizard_inner">
            <ScrollBars slimShady slimShadyDarker>
              <div>{!isLoading ? stage : null}</div>
              <CircleLoader loading={isLoading} />
              <ErrorState error={_error} />
            </ScrollBars>
          </div>
          <footer>
            <Button
              args={currentStageIdx > 0 ? [stageIds[currentStageIdx - 1]] : []}
              className={`btn btn-default`}
              disabled={currentStageIdx === maxStageIdx}
              onClick={currentStageIdx === 0 ? this.closeSelf : this.setStage}
            >
              {currentStageIdx === 0 ? (
                <span>
                  <span className="icon icon-close" /> Cancel
                </span>
              ) : (
                <span>
                  <span className="icon icon-arrow-left" /> Back
                </span>
              )}
            </Button>
            {continueBtn ? (
              continueBtn
            ) : (
              <Button
                args={
                  currentStageIdx < maxStageIdx ? (
                    [stageIds[currentStageIdx + 1]]
                  ) : (
                    []
                  )
                }
                className={`btn btn-${!continueBtnValidationMsg
                  ? 'primary'
                  : 'error'}`}
                data-tooltip={continueBtnValidationMsg}
                onClick={
                  continueBtnValidationMsg != null ? (
                    _.noop
                  ) : currentStageIdx === maxStageIdx ? (
                    this.closeSelf
                  ) : (
                    this.setStage
                  )
                }
              >
                {currentStageIdx === maxStageIdx ? (
                  <span>
                    <span className="icon icon-checkmark" /> Done
                  </span>
                ) : (
                  <span>
                    <span className="icon icon-arrow-right" /> Continue
                  </span>
                )}
              </Button>
            )}
          </footer>
        </div>
      </div>
    )
  }
}

/**
 * Export with stores wrapped
 * @param {*props} props
 */
function IntelRuleQuickAddWizardWithStores(props) {
  return (
    <UserProvider>
      <StoreProvider
        mapping={_getStoreMapper('listStoreState')}
        store={IntelListStore}
      >
        <StoreProvider
          mapping={_getStoreMapper('subscriptionStoreState')}
          store={IntelSubscriptionStore}
        >
          <StoreProvider
            mapping={_getStoreMapper('ruleStoreState')}
            store={IntelRuleStore}
          >
            <StoreProvider
              mapping={_getStoreMapper('sensorStoreState')}
              store={SensorStore}
            >
              <StoreProvider
                mapping={_getStoreMapper('quickAddStoreState')}
                store={IntelRuleQuickAddStore}
              >
                <IntelRuleQuickAddWizard {...props} />
              </StoreProvider>
            </StoreProvider>
          </StoreProvider>
        </StoreProvider>
      </StoreProvider>
    </UserProvider>
  )
}

IntelRuleQuickAddWizardWithStores.displayName =
  'IntelRuleQuickAddWizardWithStores'
IntelRuleQuickAddWizardWithStores.propTypes = COMMON_PROP_TYPES

export default IntelRuleQuickAddWizardWithStores

import {observable, computed} from 'mobx'
import {
  SchemerModel,
  schemer
} from 'pw-schemer-mobx'

export class RiskAttr extends SchemerModel {
  constructor(initial) {
    super(initial, {
      transform: (attr = {}) => ({
        ...attr,
        value: attr.value && Math.round(attr.value.attribute).toLocaleString()
      })
    })
  }

  @observable
  @schemer.number
  value: number
  
  @computed
  get classNames () {
    return `device_attr_risk_row`
  }
}

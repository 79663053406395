import _ from 'lodash'
import { geoPath } from 'd3-geo'
import { zoom, zoomIdentity } from 'd3-zoom'
import { event as d3Event, select as d3Select } from 'd3-selection'
import { extent } from 'd3-array'
import { scaleLinear } from 'd3-scale'
import { easeExpOut } from 'd3-ease'
import { CircleLoader, Timing, DimensionAware } from 'react-base'
import ReactDOM from 'react-dom'
import T from 'prop-types'
import React from 'react'
import worldMapUtil from 'ui-base/src/util/worldMapUtil'
import worldMapOptions from './worldMapOptions'
import geoDataUtil from 'utils/geoDataUtil'
import Arcs_Troika from './Arcs_Troika'
import AnimatedPath from './AnimatedPath'
import SVGPathWithLength from './SVGPathWithLength'
import { pluralize } from 'pw-formatters'

const moveToFront = function(d3elem) {
  const elem = d3elem.node()
  if (elem) {
    elem.parentNode.appendChild(elem)
  }
}

function _setNonScalingStroke(el) {
  if (!window._pwCompatibility.supportsNonScalingStroke) {
    return
  }

  if (el && el.setAttribute) {
    el.setAttribute('vector-effect', 'non-scaling-stroke')
  }
}

function getEventCoordinate(evt, coordName) {
  if (!evt || !coordName) {
    return
  }
  return evt.type.indexOf('touch') !== -1
    ? evt.changedTouches[0][coordName]
    : evt[coordName]
}

function cloneProjection(srcProjection) {
  return worldMapOptions.mapProjection
    .center(srcProjection.center())
    .scale(srcProjection.scale())
    .translate(srcProjection.translate())
    .precision(srcProjection.precision())
}

const MAX_ZOOM = 28

const ZOOM_EASE_METHOD = easeExpOut
const ZOOM_EASE_DURATION = 1500

const ARC_DURATION = 1000
const ARC_GAP = -200

class WorldMap extends React.PureComponent {
  static displayName = 'WorldMap'

  static propTypes = {
    centerCoordScale: T.number,
    countryBaseStrokeWidth: T.number,
    dotRadius: T.number,
    drawDetails: T.bool,
    highRes: T.bool,
    isReady: T.bool,
    markers: T.arrayOf(
      T.shape({
        coords: T.arrayOf(
          T.oneOfType([
            // Each coord may be a simple array, or an object including highlight info
            T.arrayOf(T.number),
            T.shape({
              longitude: T.number,
              latitude: T.number,
              highlight: T.bool
            })
          ])
        ),
        countryCodes: T.arrayOf(T.string), // 2-character ISO 3166-1 country codes
        highlights: T.arrayOf(T.bool)
      })
    ),
    onClick: T.func,
    pathAnimationStyle: T.oneOf(['none', 'simple', 'full', 'webgl']),
    threatLevel: T.string,
    setTimer: T.func,
    clearTimer: T.func,
    height: T.number,
    width: T.number
  }

  static defaultProps = {
    dotRadius: 5,
    centerCoordScale: 10,
    drawDetails: false,
    threatLevel: 'low',
    countryBaseStrokeWidth: 0.7,
    highRes: false,
    pathAnimationStyle: localStorage.enableTroikaMapArcs
      ? 'webgl'
      : window._pwCompatibility.ios ? 'simple' : 'full'
  }

  state = {
    markers: [],
    markerArcs: [],

    translateX: 0,
    translateY: 0,
    scale: 1
  }

  UNSAFE_componentWillMount() {
    this.svgTransformTargets = []
    this.cssTransformTargets = []
    this.zoom = zoom()
      .touchable('*') //default touchable filter fails in Chrome 70+ on desktop touchscreens
      .scaleExtent([1, MAX_ZOOM])
      .on('zoom', this._onZoomed)
  }

  componentDidMount() {
    this._onResize()

    d3Select(ReactDOM.findDOMNode(this)).call(this.zoom)

    this.projection = worldMapOptions.mapProjection
      .center([0, 0])
      .scale(Math.min(this.width, this.height) / 2 / Math.PI)
      // .scale(this.width / 2 / Math.PI)
      .translate([this.width / 2, this.height / 2])
      .precision(0.01) // For path arc segmentation

    this.geoPath = geoPath().projection(this.projection)

    this.dotRadiusScale = scaleLinear().range([
      this.props.dotRadius / 2,
      this.props.dotRadius
    ])

    this.animationTimeScale = scaleLinear()

    if (this.props.isReady) {
      this.highlightRegions()
      this.tryFitBounds()
    }

    this._updateMarkers(this.props.markers || [])
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.height !== this.props.height ||
      nextProps.width !== this.props.width
    ) {
      this._onResize()
    }
    if (!_.isEqual(nextProps.markers, this.props.markers)) {
      this._updateMarkers(nextProps.markers)
    }
  }

  componentDidUpdate(oldProps) {
    // this._onResize() // FIXME need this?
    if (this.props.isReady && !oldProps.isReady) {
      this.highlightRegions()
      this.tryFitBounds()
    }
  }

  componentWillUnmount() {
    this.svgTransformTargets = []
    this.cssTransformTargets = []

    this._isUnmounted = true
  }

  _onGeoContainerRef = el => {
    if (el && el !== this._geoContainer) {
      // Countries container just came into the world; draw the geo layers into it
      const { props } = this
      const mapEntities = ['world']
      if (props.drawDetails) {
        mapEntities.push('rivers', 'lakes', 'states', 'places', 'grid')
      }

      geoDataUtil.getAll(mapEntities).then(geoData => {
        if (!el.parentNode) {
          return
        } //in case it was destroyed in the interim
        const drawOptions = {
          svg: d3Select(el),
          graphOptions: worldMapOptions,
          geoPath: this.geoPath,
          countriesData: geoData[0],
          geoLayers: [],
          selectedCountryCodes: _.flatten(
            _.map(props.markers, set => set.countryCodes)
          ),
          applyNonScalingStroke:
            window._pwCompatibility.supportsNonScalingStroke
        }
        if (props.drawDetails && geoData.length > 1) {
          drawOptions.geoLayers = _.map(_.drop(mapEntities), (entity, i) => {
            return _.assign(geoData[i + 1], { className: 'geo_' + entity })
          })
        }
        worldMapUtil.drawCountryData(drawOptions)
      })
    }
    this._geoContainer = el
  }

  _onZoomed = () => {
    if (
      !this._isUnmounted &&
      this.cssTransformTargets &&
      this.svgTransformTargets
    ) {
      const { x, y, k } = d3Event.transform

      //this.zoom.translate(t)

      this.setState({
        translateX: x,
        translateY: y,
        scale: k
      })
    }
  }

  // _onContainerRef(containerEl) {
  //   this._selfContainer = containerEl
  // },

  _onResize = () => {
    const node = ReactDOM.findDOMNode(this)
    this.width = node.offsetWidth
    this.height = node.offsetHeight
    this.zoom.translateExtent([[0, 0], [this.width, this.height]])
  }

  resetHighlightedRegions = () => {
    const ct = this._geoContainer
    if (ct) {
      d3Select(ct)
        .selectAll('.selected_country')
        .classed('selected_country', false)
    }
  }

  highlightRegions = () => {
    const ct = this._geoContainer
    if (ct) {
      this.resetHighlightedRegions()
      _.forEach(this.props.markers, markerSet => {
        _.forEach(markerSet.countryCodes, countryCode => {
          const region = d3Select(ct).select(`#cc${countryCode}`)
          region.classed('selected_country', true)
          moveToFront(region)
        })
      })
    }
  }

  tryFitBounds = () => {
    // try to figure out the most viable zoom boundaries
    const markerCoords = [] //candidates
    const dotRadius = this.props.dotRadius

    _.forEach(this.props.markers, markerGroup => {
      markerGroup.coords.forEach(d => {
        const projected = this.projection(
          _.isArray(d) ? d : [d.longitude, d.latitude]
        )
        markerCoords.push(projected)
      })
    })

    if (markerCoords.length > 1) {
      const [minX, maxX] = extent(markerCoords, d => d[0])
      const [minY, maxY] = extent(markerCoords, d => d[1])
      const totalBounds = [
        [minX - dotRadius, minY - dotRadius],
        [maxX + dotRadius, maxY + dotRadius]
      ]
      this.fitBounds(totalBounds)
    } else if (markerCoords[0]) {
      //Zoom to the first dot found
      this.centerCoords(
        [markerCoords[0][0], markerCoords[0][1]],
        this.props.centerCoordScale
      )
    }
  }

  centerCoords = (projCoords, toScale = 10, doTransition = true) => {
    const ct = ReactDOM.findDOMNode(this)
    if (ct) {
      const translateX = this.width / 2 - toScale * projCoords[0]
      const translateY = this.height / 2 - toScale * projCoords[1]
      let sel = d3Select(ct)
      if (doTransition) {
        sel = sel
          .transition()
          .ease(ZOOM_EASE_METHOD)
          .duration(ZOOM_EASE_DURATION)
      }
      sel.call(
        this.zoom.transform,
        zoomIdentity.translate(translateX, translateY).scale(toScale)
      )
    }
  }

  fitBoundsByElement = targetEl => {
    const element = targetEl.node()
    const bbox = element.getBBox()
    // manually construct 2d array that matches the return value of d3 geoBounds()
    this.fitBoundsByBBox(bbox)
  }

  fitBoundsByRegionName = regionName => {
    const ct = this._geoContainer
    if (ct) {
      const region = d3Select(ct).select('.' + regionName)
      this.fitBoundsByRegion(region)
    }
  }

  fitBoundsByRegion = selectedRegion => {
    const bounds = this.geoPath.bounds(selectedRegion) // get Bounds of clicked region
    this.fitBounds(bounds)
  }

  _getBoundsFromBBox = bbox => {
    return [[bbox.x, bbox.y + bbox.height], [bbox.x + bbox.width, bbox.y]]
  }

  fitBoundsByBBox = bbox => {
    this.fitBounds(this._getBoundsFromBBox(bbox))
  }

  fitBounds = bounds => {
    const ct = ReactDOM.findDOMNode(this)
    if (ct && bounds) {
      const dx = Math.max(bounds[1][0] - bounds[0][0], this.width / 6)
      const dy = Math.max(bounds[1][1] - bounds[0][1], this.height / 6)
      const x = (bounds[0][0] + bounds[1][0]) / 2
      const y = (bounds[0][1] + bounds[1][1]) / 2
      const scale = 0.8 / Math.max(dx / this.width, dy / this.height)
      const translateX = this.width / 2 - scale * x
      const translateY = this.height / 2 - scale * y
      d3Select(ct)
        .transition()
        .ease(ZOOM_EASE_METHOD)
        .duration(ZOOM_EASE_DURATION)
        .call(
          this.zoom.transform,
          zoomIdentity.translate(translateX, translateY).scale(scale)
        )
    }
  }

  _updateMarkers = markers => {
    const { props } = this
    const newMarkers = []
    const newMarkerArcs = []
    const markerPairs = []

    _.forEach(markers, markerGroup => {
      const projectedCoords = _.map(markerGroup.coords, d => {
        const latLon = _.isArray(d) ? d : [d.longitude, d.latitude]
        const [x, y] = this.projection(latLon)
        return {
          x,
          y,
          id: 'geo_' + x + '_' + y,
          ips: [d.ip],
          internal: d.internal,
          coords: latLon,
          connCt: 1, // Connection Count
          r: props.dotRadius,
          highlight: !!d.highlight
        }
      })

      // markerPairs.push(markerGroup.tra)

      if (projectedCoords.length > 1) {
        markerPairs.push(projectedCoords)
      }

      projectedCoords.forEach(marker => {
        // de-dupe by coordinates
        const existingMarker = _.find(newMarkers, 'id', marker.id)
        if (existingMarker) {
          existingMarker.connCt++ // Increment connection count
          existingMarker.ips = _.uniq(existingMarker.ips.concat(marker.ips))
          if (!existingMarker.highlight) {
            existingMarker.highlight = marker.highlight
          }
        } else {
          newMarkers.push(marker)
        }
      })

      // Generate arc ID
      if (projectedCoords.length > 1) {
        const [src, dst] = projectedCoords
        const newId = `arc_${src.id}_${dst.id}`
        const threatLevel = markerGroup.threatLevel || props.threatLevel
        if (!_.some(newMarkerArcs, { id: newId })) {
          newMarkerArcs.push({
            id: newId,
            threatLevel: threatLevel ? threatLevel.toLowerCase() : null,
            d: this.geoPath({
              type: 'LineString',
              coordinates: [src.coords, dst.coords]
            }) // Great Arc route
          })
        }
      }
    })

    this.setState(
      {
        markers: newMarkers,
        markerArcs: newMarkerArcs
      },
      () => {
        this._restartArcsAnim(true)
      }
    )
  }

  // _handleClick(e) {
  // const {onClick} = this.props
  // const {translateX, translateY, scale} = this.state
  // const {top, left} = ReactDOM.findDOMNode(this).getBoundingClientRect()
  // const x = getEventCoordinate(e, 'pageX') - left
  // const y = getEventCoordinate(e, 'pageY') - top
  // const transformedProjection = cloneProjection(this.projection)
  // // const transformedProjection = worldMapOptions.mapProjection
  //   // .center(this.projection.center())
  //   .translate(this.zoom.translate())
  //   .scale(this.zoom.scale())
  //   // .precision(this.projection.precision())

  // console.log('WorldMap click!', x, y)

  // const [lon, lat] = transformedProjection.invert([x, y])
  // console.log('WorldMap click! ---> ', lon, lat)

  // if (onClick) {
  //  onClick({
  //   lat, lon
  // })
  // }
  // },

  _restartArcsAnim = (isFirst = false) => {
    const animStyle = this.props.pathAnimationStyle
    if (animStyle === 'none' || animStyle === 'webgl') {
      return
    }
    if (!this._isUnmounted) {
      if (!isFirst) {
        const el = ReactDOM.findDOMNode(this)
        el.classList.add('reset_anim')
        this.props.setTimer(
          x => {
            x = el.offsetWidth //force reflow
            el.classList.remove('reset_anim')
          },
          200,
          'animReset'
        )
      }
      const numArcs = this.state.markerArcs.length

      // Set the whole anim loop to restart at the appropriate time
      this.props.setTimer(
        this._restartArcsAnim,
        (ARC_DURATION + ARC_GAP) * (numArcs + 2),
        'animLoop'
      )
    }
  }

  render() {
    const { props, state } = this
    const { translateX, translateY, scale } = state
    const svgStyles = window._pwCompatibility.supportsNonScalingStroke
      ? null
      : {
          strokeWidth: props.countryBaseStrokeWidth / scale
        }
    const svgTransform = `translate(${translateX},${translateY}) scale(${scale})`
    const cssStyles = {
      transform: `translate(${translateX}px, ${translateY}px) scale(${scale})`,
      WebkitTransform: `translate(${translateX}px, ${translateY}px) scale(${scale})`
    }

    return (
      <div className={worldMapOptions.regionName + '_map world_map_section'}>
        <svg className="svg_container world_map_svg" height="100%" width="100%">
          <g style={svgStyles} transform={svgTransform}>
            <g ref={this._onGeoContainerRef} />
          </g>
        </svg>
        {props.isReady
          ? props.pathAnimationStyle === 'webgl'
            ? <Arcs_Troika
                arcs={state.markerArcs}
                arcAnimationDuration={ARC_DURATION}
                arcAnimationGap={ARC_GAP}
                translateX={translateX}
                translateY={translateY}
                scale={scale}
              />
            : <svg
                className="svg_container world_map_svg world_map_markers disable_pointer_events"
                height="100%"
                width="100%"
              >
                <g style={svgStyles} transform={svgTransform}>
                  {_.map(state.markerArcs, (arc, i) =>
                    <g key={'bg_' + arc.id}>
                      <path
                        className={`geo_connector_arc lvl_${arc.threatLevel}`}
                        d={arc.d}
                        ref={_setNonScalingStroke}
                      />
                      {props.pathAnimationStyle === 'simple'
                        ? <SVGPathWithLength
                            className={`geo_connector_arc simple_animated lvl_${arc.threatLevel}`}
                            d={arc.d}
                            scale={scale}
                            style={{
                              animationDelay:
                                i * (ARC_DURATION + ARC_GAP) + 'ms',
                              WebkitAnimationDelay:
                                i * (ARC_DURATION + ARC_GAP) + 'ms',
                              animationDuration: ARC_DURATION + 'ms',
                              WebkitAnimationDuration: ARC_DURATION + 'ms'
                            }}
                          />
                        : null}
                    </g>
                  )}
                </g>
              </svg>
          : null}
        {props.isReady
          ? <div className="world_map_markers_ct disable_pointer_events">
              <div className="world_map_markers" style={cssStyles}>
                {props.pathAnimationStyle === 'full'
                  ? _.map(state.markerArcs, (arc, i) =>
                      <AnimatedPath
                        animationDuration={ARC_DURATION}
                        animationOffset={i * (ARC_DURATION + ARC_GAP)}
                        className={`lvl_${arc.threatLevel}`}
                        d={arc.d}
                        dotInterval={0.8}
                        dotRadius={0.8}
                        key={arc.id}
                        taperStroke={false}
                      />
                    )
                  : null}
                {_.map(state.markers, (marker, i) => {
                  const r = marker.r
                  return (
                    <div
                      className={`map_dot ${marker.highlight
                        ? 'highlight'
                        : ''} ${marker.internal ? 'internal_map_dot' : ''}`}
                      data-tooltip={`${marker.connCt} ${pluralize(
                        marker.connCt,
                        'connection'
                      )} (${marker.ips.join(', ')})`}
                      key={marker.id}
                      style={{
                        height: r * 2,
                        width: r * 2,
                        transform: `translate(${marker.x - r}px, ${marker.y -
                          r}px) scale(${1 / scale})`,
                        WebkitTransform: `translate(${marker.x -
                          r}px, ${marker.y - r}px) scale(${1 / scale})`
                        //animationDelay: i * 1000 + 'ms',
                        //WebkitAnimationDelay: i * 1000 + 'ms'
                      }}
                    />
                  )
                })}
              </div>
            </div>
          : null}
        <CircleLoader loading={!props.isReady} />
      </div>
    )
  }
}

export default DimensionAware(Timing(WorldMap))

import Reflux from 'reflux'

// Return all reflux action references
export default {
  Families: Reflux.createActions([
    'getLatestFamilies',
    'selectItem',
    'deselectItem'
  ]),
  Profiles: Reflux.createActions([
    'getAll',
    'select',
    'save',
    'update',
    'rename',
    'delete',
    'copy',
    'setApplied',
    'reset',
    'extractDraft',
    'saveDraftOverwrite'
  ]),
  Exceptions: Reflux.createActions([
    'updateException',
    'deleteException',
    'bulkUpdateException',
    'bulkDeleteException'
  ]),
  PerceptiveProfiles: Reflux.createActions([
    'loadRecommended'
  ])
}

import _ from 'lodash'
import T from 'prop-types'
import React from 'react'
import cx from 'classnames'
import {TextInput} from 'react-base'
import LocationSelectionDropdown from '../locations/LocationSelectionDropdown'

const LOCATION_SHAPE = {
  cidr_range: T.string,
  location_id: T.number,
  id: T.oneOfType([T.number, T.string]),
  sensor_id: T.number
}


export default class extends React.Component {
  static displayName = "SensorLocation";

  static propTypes = {
    canDelete: T.bool.isRequired,
    canEdit: T.bool.isRequired,
    hasPermission: T.func.isRequired,
    invalid: T.bool,
    location: T.shape(_.assign({}, LOCATION_SHAPE, {
      _isCidrRangeValid: T.bool,
      _isAddressValid: T.bool,
    })),
    onChange: T.func.isRequired,
    onDelete: T.func.isRequired,
    typeaheadOptions: T.arrayOf(T.string)
  };

  static defaultProps = {
    invalid: false,
    location: null,
    canDelete: false,
    canEdit: false
  };

  state = {
    wasEdited: false
  };

  componentDidMount() {
    if (this.props.location && this.props.location._isNew && this._inputRef) {
      this._inputRef.focus()
    }
  }

  _onInputKeyUp = (e) => {
    this._onCidrChanged(e.target.value)
  };

  _onInputSave = (newValue) => {
    this._onCidrChanged(newValue)
  };

  _onCidrChanged = (newValue) => {
    this._handleEdit({
      cidr_range: newValue
    })
  };

  _handleEdit = (partialLocation) => {
    let {props} = this
    let updatedLocation = _.assign({}, props.location, partialLocation)
    this.setState({
      wasEdited: true
    })
    if (_.isFunction(props.onChange)) {
      props.onChange(updatedLocation)
    }
  };

  _onRemoveClicked = () => {
    this.props.onDelete(this.props.location)
  };

  _bindInputRef = (input) => {
    this._inputRef = input
  };

  _onLocationChanged = (location) => {
    this._handleEdit({
      location_id: location.id
    })
  };

  render() {
    let {props, state} = this
    let location = props.location || {}
    let validCidrRange = location._isCidrRangeValid
    let validAddress = location._isAddressValid
    let invalid = !validCidrRange || !validAddress || props.invalid
    let classes = cx('sensor_location', `fade_${ location._isNew ? 'in_left' : 'in' }`, {
      'read_only': !props.canEdit,
      'invalid': invalid && (!location._isNew || !!state.wasEdited),
      'soft_invalid': invalid && location._isNew && !state.wasEdited
    })

    return (
      <div
        className={ classes }
      >
        <div className="fields">
          <TextInput
            blurOnSave
            cancelOnEsc
            className={ cx(`grid_input stealth cell_content`, { 'invalid_cidr': !validCidrRange }) }
            id={ location.id + "_input" }
            initialValue={ location.cidr_range || '' }
            onKeyUp={ this._onInputKeyUp }
            onSave={ this._onInputSave }
            placeholder="CIDR Range"
            readOnly={ !props.canEdit }
            ref={ this._bindInputRef }
          />
          <LocationSelectionDropdown
            currentLocationId={ location.location_id }
            hasPermission={ props.hasPermission }
            readOnly={ !props.canEdit }
            onSelect={ this._onLocationChanged }
          />
        </div>
        { props.canDelete ? (
          <span
            className="btn-icon icon-trash remove_location_btn"
            data-tooltip="Remove location"
            onClick={ this._onRemoveClicked }
          />
        ) : null }
      </div>
    )
  }
}

import _ from 'lodash'
import T from 'prop-types'
import { pluralize } from 'pw-formatters'

export var locationShape = T.shape({
  _isNew: T.bool,
  id: T.number,
  name: T.string,
  description: T.string,
  latitude: T.number,
  longitude: T.number,
  address: T.string,
  country_code: T.string,
  shape: T.string,
  sensors: T.arrayOf(T.shape({
    id: T.number,
    friendly_name: T.string
  }))
})

export function formatAddress (address) {
  if (!address) {
    return "No Address"
  }
  // Split address using first comma only
  let splitI = address.indexOf(',') + 1
  return [
    address.slice(0, splitI),
    address.slice(splitI)
  ]
}

export function getMarkerForLocation (location, i = 0) {
  if (!location) {
    return null
  }
  return _.assign({}, location, {
    text: location.name,
    label: `${ location.id === -1 ? `+` : i + 1 }`,
    latitude: location.latitude,
    longitude: location.longitude,
  })
}

export function getDeleteConfirmationMessage (location) {
  let numSensors = location.sensors && location.sensors.length
  let sensorNames = _.pluck(location.sensors || [], 'friendly_name').join(', ')
  let confirmMessage = "Are you sure you want to delete this location?"

  if (numSensors > 0) {
    confirmMessage += `\n Location CIDR ranges for the following ${ pluralize(numSensors, 'sensor') } will also be removed: ${ sensorNames }.`
  }

  return confirmMessage
}

import T from 'prop-types'
import React from 'react'
import {DialogContainer} from 'react-base'
import {ListInput} from 'pw-drafty'

class BPFEditorDialog extends React.Component {
  static displayName = 'BPFEditorDialog';

  static propTypes = {
    initialValue: T.string,
    readOnly: T.bool,
    onSave: T.func.isRequired
  }

  UNSAFE_componentWillMount() {
    this.setState({
      currentValue: this.props.initialValue || ''
    })
  }

  _onValChange = (lines) => {
    this.setState({
      currentValue: _.trim(lines.join('\n')) || null
    })
  }

  _onSave = () => {
    this.props.onSave(this.state.currentValue)
  }

  render() {
    let {props} = this

    return (
      <DialogContainer
        modal
        onMaskClick={this._onSave}
      >
        <div className="modal_dialog bpf_dialog">
          <h1>Edit BPF Rules</h1>

          <ListInput
            flex
            focusOnMount
            initialValue={props.initialValue}
            placeholder="Add a new BPF rule..."
            onChange={this._onValChange}
            readOnly={props.readOnly}
          />

          <div className="dialog_buttons">
            <div className="dialog_primary_button_wrap">
              <button
                className="btn btn-primary"
                onClick={this._onSave}
              >Done</button>
            </div>
          </div>
        </div>
      </DialogContainer>
    )
  }
}

export default BPFEditorDialog

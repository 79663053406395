import React, { FunctionComponent } from 'react'
import {
  observer
} from 'mobx-react'
import { LatLonValue } from 'components/values/LatLonValue'

export interface LatitudeProps {
  value: number
}

export const Latitude: FunctionComponent<LatitudeProps> = observer(props => {
  return (
    <LatLonValue
      lat={props.value}
    />
  )
})

Latitude.displayName = "Latitude"
import genericUtil from 'ui-base/src/util/genericUtil'
import _ from 'lodash'
import React from 'react'
import {Loader as GoogleMapsLoader} from 'google-maps'
import getConfig from 'utils/uiConfig'
import {quadtree} from 'd3-quadtree'
import {requestGet} from 'utils/restUtils'

import ne50mAdmin0BoundaryLinesLand from 'ui-geojson/geojson/ne_50m_admin_0_boundary_lines_land.json'
import ne50mAdmin0CountriesLakes from 'ui-geojson/geojson/ne_50m_admin_0_countries_lakes.json'
// import ne50mAdmin0Countries from 'ui-geojson/geojson/ne_50m_admin_0_countries.json'
import ne50mGraticules30 from 'ui-geojson/geojson/ne_50m_graticules_30.json'
import ne50mLakes from 'ui-geojson/geojson/ne_50m_lakes.json'
import ne50mRiversLakeCenterline from 'ui-geojson/geojson/ne_50m_rivers_lake_centerlines.json'

import ne110mAdmin0BoundaryLinesLand from 'ui-geojson/geojson/ne_110m_admin_0_boundary_lines_land.json'
import ne110mAdmin0CountriesLakes from 'ui-geojson/geojson/ne_110m_admin_0_countries_lakes.json'
// import ne110mAdmin0Countries from 'ui-geojson/geojson/ne_110m_admin_0_countries.json'
import ne110mAdmin1StatesProvinces from 'ui-geojson/geojson/ne_110m_admin_1_states_provinces.json'
import ne110mGraticules30 from 'ui-geojson/geojson/ne_110m_graticules_30.json'
import ne110mLakes from 'ui-geojson/geojson/ne_110m_lakes.json'
import ne110mLand from 'ui-geojson/geojson/ne_110m_land.json'
import ne110mPopulatedPlaces from 'ui-geojson/geojson/ne_110m_populated_places.json'
import ne110mRiversLakeCenterline from 'ui-geojson/geojson/ne_110m_rivers_lake_centerlines.json'
import isoCountries from 'country-list/data.json'

// File paths by data resolution (110m=low, 50m=high)
const FILE_PATHS = {
  'high': {
    countries: ne50mAdmin0CountriesLakes,
    bounds: ne50mAdmin0BoundaryLinesLand,
    lakes: ne50mLakes,
    rivers: ne50mRiversLakeCenterline,
    grid30: ne50mGraticules30 // 30 Deg intervals
  },
  'low': {
    countries: ne110mAdmin0CountriesLakes,
    bounds: ne110mAdmin0BoundaryLinesLand,
    lakes: ne110mLakes,
    rivers: ne110mRiversLakeCenterline,
    grid30: ne110mGraticules30, // 30 Deg intervals
    states: ne110mAdmin1StatesProvinces,
    places: ne110mPopulatedPlaces,
    land: ne110mLand
  }
}

class GeoDataUtil {
  constructor() {
    this.dataPromises = {}
    this.cache = {}
  }

  _loadJson(res, file) {
    const promises = this.dataPromises
    const filePath = _.get(FILE_PATHS, `${res}.${file}`)
    if (!filePath) {
      // throw new Error('Invalid GeoJSON file path requested: ' + res, file)
      console.warn('Invalid GeoJSON file path requested: ', res, file)
    }
    if (!promises[filePath]) {
      promises[filePath] = new Promise((resolve, reject) => {
        requestGet(null, filePath, {baseURL: ''})
          .then(resolve)
          .catch(restError => {
            console.error("Error getting map geodata", restError)
          })
      })
    }
    return promises[filePath]
  }

  getWorld(res = 'low', omitAntarctica = false) {
    // pass 'false' to get earth.json
    let key = 'world_' + res
    if (this.cache[key]) {
      return Promise.resolve(omitAntarctica ? _.reject(this.cache[key], {pw_region: 'antarctica'}) : this.cache[key])
    } else {
      // Load earth.json (stitch existing region json into a single collection)
      return this._loadJson(res, 'countries').then((geoJson) => {
        let earthData = _.reduce(geoJson, (output, regionData) => {
          return output.concat(regionData)
        }, [])
        this.cache[key] = earthData
        return omitAntarctica ? _.reject(earthData, {pw_region: 'antarctica'}) : earthData
      })
    }
  }

  getRegions(res = 'low') {
    return this._loadJson(res, 'countries')
  }

  getBoundaries(res = 'low') {
    return this._loadJson(res, 'bounds')
  }

  getLakes(res = 'low') {
    return this._loadJson(res, 'lakes')
  }

  getRivers(res = 'low') {
    return this._loadJson(res, 'rivers')
  }

  getStates(res = 'low') {
    return this._loadJson(res, 'states')
  }

  getPlaces(res = 'low') {
    return this._loadJson(res, 'places')
  }

  getGrid(res = 'med', degs = 30) {
    return this._loadJson(res, 'grid' + degs)
  }

  getAll(entities = [], res = 'low') {
    let _promises = _.reduce(entities, (out, entity) => {
      let getterName = `get${ _.startCase(entity) }`
      if (this[getterName] && _.isFunction(this[getterName])) {
        out.push(this[getterName].call(this, res)) // Push the getter promise response onto the output stack
      } else {
        console.warn('GeoDataUtil: Unrecognized option passed to @getAll:', entity, getterName)
      }
      return out
    }, [])
    return Promise.all(_promises)
  }

  /**
   * Load countries data and optimize it for later use by `getCountryFromLatLon()`.
   * Should be called on startup.
   */
  initCountryLookupData() {
    return this.getWorld("low").then(data => {
      const inf = Infinity

      // Collect all country polygons and their bounding rectangles
      const countryPolygons = _.flatten(data).reduce((polys, countryData) => {
        const geom = countryData.geometry
        const resultData = {
          code: countryData.properties.iso_a2,
          name: countryData.properties.name,
          pwRegion: countryData.pw_region
        }
        const polygons = geom.type === 'MultiPolygon' ? geom.coordinates : [geom.coordinates]
        for (let i = polygons.length; i--;) {
          const bounds = [inf, inf, -inf, -inf]
          for (let j = polygons[i].length; j--;) {
            for (let k = polygons[i][j].length; k--;) {
              let [lon, lat] = polygons[i][j][k]
              if (lon < bounds[0]) bounds[0] = lon
              if (lat < bounds[1]) bounds[1] = lat
              if (lon > bounds[2]) bounds[2] = lon
              if (lat > bounds[3]) bounds[3] = lat
            }
          }
          polys.push({
            bounds,
            polygon: polygons[i],
            resultData
          })
        }
        return polys
      }, [])

      // Build the quadtree; the x/y values used here don't really matter, just the polygon bounds calculated below
      const tree = quadtree(countryPolygons, d => d.bounds[0], d => d.bounds[1])

      // Walk tree in bottom-up order, storing largest bounds from descendants on each parent quad
      tree.visitAfter(quad => {
        if (quad.data) {
          quad.bounds = quad.data.bounds
        } else {
          let quadBounds = quad.bounds = [inf, inf, -inf, -inf]
          for (let i = 0; i < 4; i++) {
            let subBounds = quad[i] && quad[i].bounds
            if (subBounds) {
              if (subBounds[0] < quadBounds[0]) quadBounds[0] = subBounds[0]
              if (subBounds[1] < quadBounds[1]) quadBounds[1] = subBounds[1]
              if (subBounds[2] > quadBounds[2]) quadBounds[2] = subBounds[2]
              if (subBounds[3] > quadBounds[3]) quadBounds[3] = subBounds[3]
            }
          }
        }
      })

      this.countriesQuadtree = tree
    })
  }

  /**
   * Given a lat/lon, look up the country that point falls within
   * @param {Number} lat
   * @param {Number} lon
   * @return {Object} {code, name, pwRegion}, or `null` if no match
   */
  getCountryFromLatLon(lat, lon) {
    if (this.countriesQuadtree) {
      //console.time('quadtree lookup')
      let countryMatch = null
      //let visits = 0
      //let polyChecks = 0
      this.countriesQuadtree.visit(quad => {
        //visits++
        if (countryMatch) return true //short-circuit if already found a match

        // If the point lies outside the quad's maximum rect bounds, ignore this branch of the tree
        let [minLon, minLat, maxLon, maxLat] = quad.bounds
        if (lat < minLat || lat > maxLat || lon < minLon || lon > maxLon) {
          return true
        }

        // Leaf node: do full point-in-polygon check
        let leafData = quad.data
        //if (leafData) polyChecks++
        if (leafData && isPointInPolygon(lon, lat, leafData.polygon)) {
          countryMatch = leafData.resultData
        }
      })
      //console.log(`${visits} quadtree visits, ${polyChecks} polygon checks`)
      //console.timeEnd('quadtree lookup')
      return countryMatch
    }
    else {
      console.warn('NOT READY FOR LAT/LON QUERIES YET')
    }

    function isPointInPolygon(lon, lat, polyWithHoles) {
      let inside = false
      for (let i = polyWithHoles.length; i--;) {
        let coords = polyWithHoles[i]
        for (let j = coords.length - 1; j--;) { //expect last coord is same as first coord in the data
          let [segLon1, segLat1] = coords[j]
          let [segLon2, segLat2] = coords[j + 1]
          if (
            ((segLat1 > lat) !== (segLat2 > lat)) && //one end is north and the other is south of the point, and...
            (segLon1 + (segLon2 - segLon1) * (lat - segLat1) / (segLat2 - segLat1) > lon) //point where it crosses north/south is to the east of the point
          ) {
            inside = !inside
          }
        }
      }
      return inside
    }
  }

}


// function setpixelated(context){
//     context['imageSmoothingEnabled'] = false;       /* standard */
//     context['mozImageSmoothingEnabled'] = false;    /* Firefox */
//     context['oImageSmoothingEnabled'] = false;      /* Opera */
//     context['webkitImageSmoothingEnabled'] = false; /* Safari */
//     context['msImageSmoothingEnabled'] = false;     /* IE */
// }



// default export is the GeoDataUtilLoader util
let geoDataUtil = new GeoDataUtil()
export default geoDataUtil

let googleApi = null
export function getGoogleMapsAPI (callback) {
  if (googleApi) {
    callback(googleApi)
    return
  }
  const apiKey = getConfig('apiKeys.googleMaps')
  if (apiKey) {
    const loader = new GoogleMapsLoader(apiKey, {
      libraries: ['geometry', 'places']
    })
    loader.load().then(
      (google) => {
        googleApi = google
        callback(google)
      },
      () => {
        console.warn('Could not load google maps lib')
        callback(null)
      }
    )
  }
  else {
    console.warn('apiKeys.googleMaps not found in uiConfig.json. Google Maps unavailable.')
    callback(null)
  }
}

/**
 * Return a 3D spherical coordinate from a passed lat/lon/alt(radius)
 * @method getSphericalCoordFromLonLat
 * @param  {[type]}                    lon          [longitude]
 * @param  {[type]}                    lat          [latitude]
 * @param  {[type]}                    sphereRadius [altitude from center of sphere]
 * @return {[type]}                                 [returns an object comosed of THREEjs normalized x, y, and z properties]
 */
export function getSphericalCoordFromLonLat (lon, lat, sphereRadius = 1000) {
  if (!lat || !lon) {
    console.warn('getSphericalCoordFromLonLat: invalid arguments', lat, lon)
    return {}
  }
  /**
   * Normalized to default threejs "up"
   * x -> z
   * y -> x
   * z -> y
   */
  return {
    z: Math.cos(lat * Math.PI/180) * Math.cos(lon * Math.PI/180) * sphereRadius, // x
    x: Math.cos(lat * Math.PI/180) * Math.sin(lon * Math.PI/180) * sphereRadius, // y
    y: Math.sin(lat * Math.PI/180) * sphereRadius // z
  }
}

// TODO swap Google Maps Javascript API out for our own when one exists
// https://developers.google.com/maps/documentation/javascript/geocoding#GeocodingResults
// address: string,
// location: LatLng,
// placeId: string,
// bounds: LatLngBounds,
// componentRestrictions: GeocoderComponentRestrictions,
// region: string
function _googleGeocode (geocodeParams = {}) {
  if (!geocodeParams.address && !geocodeParams.lat && !geocodeParams.lng && !geocodeParams.boundsObj) {
    console.error('_googleGeocode: Invalid Arguments. address OR lat/lng OR bounds must be provided', geocodeParams)
    return
  }

  let deferred = genericUtil.defer()

  function _handleGeoCode (_google) {
    if (!_google) {
      deferred.reject("Google Maps API unavailable")
    }
    if (geocodeParams.lat && geocodeParams.lng) {
      geocodeParams.location = new _google.maps.LatLng(geocodeParams.lat, geocodeParams.lng)
      geocodeParams = _.omit(geocodeParams, ['lat', 'lng'])
    }
    if (geocodeParams.boundsObj) {
      geocodeParams.location = new _google.maps.LatLng(geocodeParams.boundsObj.southwest.lat, geocodeParams.boundsObj.southwest.lng)
      geocodeParams.bounds = new _google.maps.LatLngBounds(
        new _google.maps.LatLng(geocodeParams.boundsObj.southwest.lat, geocodeParams.boundsObj.southwest.lng),
        new _google.maps.LatLng(geocodeParams.boundsObj.northeast.lat, geocodeParams.boundsObj.northeast.lng)
      )
      geocodeParams = _.omit(geocodeParams, ['boundsObj'])
    }
    let geocoder = new _google.maps.Geocoder()
    geocoder.geocode(geocodeParams, (results, statusCode) => {
      switch (statusCode) {
        case 'OK':
          let transformedResults = _.map(results, result => {
            if (_.get(result, 'geometry.location')) {
              result.lat = result.geometry.location.lat()
              result.lng = result.geometry.location.lng()
            }
            return result
          })
          deferred.resolve(transformedResults)
          break
        case 'ZERO_RESULTS':
          deferred.resolve([])
          break
        default:
          deferred.reject(statusCode) // Handle all other failure codes
      }
    })
  }

  // Use loaded maps instance if available
  if (window.google.maps) {
    _handleGeoCode(window.google)
  } else {
    getGoogleMapsAPI(_handleGeoCode)
  }

  // GoogleMapsLoader.load(function(google) {
  //   // Convert lat/lng into a Google Maps LatLng Instance
  // })
  return deferred.promise
}

/**
 * Return the Lat/Long for a given address string
 * @method geocodeAddress
 * @param  {[type]}       inputString [String address (e.g. 123 Main Street or Broadway and 8th)]
 * @return {[type]}                   [returns a promise with a list of results]
 */
export function geocodeAddress (inputString) {
  if (!inputString) {
    console.error('geocodeFromString: inputString is required', inputString)
    return
  }
  return _googleGeocode({
    address: inputString
  })
}

/**
 * Return the human-readable address for a given Lat and Long
 * @method geocodeAddress
 * @param  {[type]}       lat [Latitude]
 * @param  {[type]}       lon [Longitude]
 * @return {[type]}           [returns a promise with a list of results]
 */
export function reverseGeocodeLatLon (lat, lng) {
  if (_.isUndefined(lat) || _.isUndefined(lng)) {
    console.error('geocodeFromString: lat and lng are required', lat, lng)
    return
  }
  return _googleGeocode({
    lat,
    lng
  })
}

export function reverseGeocodeBounds (bounds) {
  if (_.isUndefined(bounds) || _.isEmpty(bounds)) {
    console.error('reverseGeocodeBounds: bounds are required', bounds)
    return
  }

  return _googleGeocode(bounds)
}


// Tests
// geocodeAddress('3055 Wright St. Wheat Ridge CO 80215')
// .then(function(res) {
//   console.log('got geocodeAddress results', res)
// }).catch(err => {
//   console.log('err', err)
// })
//
//
// reverseGeocodeLatLon(39.759712, -105.13897199999997)
// .then(function(res) {
//   console.log('got reverseGeocodeLatLon results', res)
// }).catch(err => {
//   console.log('err', err)
// })



export function getMapMarkers (data, getInternalLocation, highlightIP) {
  if (data && data.length > 0) {
    return _.map(data, item => {
      let geo = item.geo
      let output = {
        id: "geo_flow_" + item.id,
        coords: [],
        countryCodes: [],
        // startTime: item.startTime,
        // endTime: item.endTime
      }
      if (item.color) {
        output.color = item.color
      }
      _.forEach(['src', 'dst'], (point) => {
        // let lat = _.get(geo, `${point}.location.latitude`)
        // let lng = _.get(geo, `${point}.location.longitude`)
        let lat = _.get(geo, `${point}.lat`) || _.get(geo, `${point}.latitude`) || _.get(geo, `${point}.location.latitude`)
        let lon = _.get(geo, `${point}.lon`) || _.get(geo, `${point}.longitude`) || _.get(geo, `${point}.location.longitude`)

        let ip = item.ips[point]
        let sensorId = item.sensorId

        if (lat && lon) {
          // output.countryCodes.push(_.get(geo, `${point}.country.isoCode`))

          let country = geoDataUtil.getCountryFromLatLon(lat, lon)

          if (country) {
            output.countryCodes.push(country.code)
          }

          output.coords.push({
            longitude: lon,
            latitude: lat,
            ip: ip,
            internal: false,
            highlight: ip === highlightIP
          })
        }
        else if (ip && sensorId && getInternalLocation && _.isFunction(getInternalLocation)) {
          // If sensor is internal, use the configured sensor geolocation that matches this IP
          let internalGeo = getInternalLocation(ip, sensorId)
          if (internalGeo && internalGeo.location) {
            output.countryCodes.push(internalGeo.location.country_code)
            output.coords.push({
              longitude: internalGeo.location.longitude,
              latitude: internalGeo.location.latitude,
              ip: ip,
              internal: true,
              highlight: ip === highlightIP
            })
          }
        }
      })
      return output
    })
  }
}

/**
 * Given an array of netflows, returns markers and connection arcs formatted for the WorldMap component
 * @method getMapMarkersFromNetflows
 * @param  {[array]}    netflows            Array of netflow objects
 * @param  {[function]} getInternalLocation  Function called to look up geolocation for Netflow IPs that lack provided geospatial data (e.g. Internal IPs)
 * @return {[array]}                        Array of marker objects consisting of map markers and ISO 3166-1 country codes to be highlighted
 */
export function getMapMarkersFromNetflows (netflows, getInternalLocation, highlightIP) {
  let convertedNetflows = _.map(netflows, netflow => {
    return {
      id: netflow.key,
      sensorId: netflow.sensorId,
      geo: netflow.geo || {},
      ips: {
        src: netflow.id.srcIp,
        dst: netflow.id.dstIp
      },
      // startTime: netflow.details.startTime,
      // endTime: netflow.details.endTime
    }
  })
  return getMapMarkers(convertedNetflows, getInternalLocation, highlightIP)
}


/**
 * Given an array of observations, returns markers and connection arcs formatted for the WorldMap component
 * @method getMapMarkersFromObservations
 * @param  {[array]}    observations        Array of observation objects
 * @param  {[function]} getInternalLocation  Function called to look up geolocation for Netflow IPs that lack provided geospatial data (e.g. Internal IPs)
 * @return {[array]}                        Array of marker objects consisting of map markers and ISO 3166-1 country codes to be highlighted
 */
export function getMapMarkersFromObservations (observations, getInternalLocation, highlightIP) {
  let markerData = []
  _.forEach(observations, obs => {
    if (obs.connectionInfo) {
      // Normal single-netflow observation
      let {srcIp, dstIp} = obs.connectionInfo
      if (srcIp && dstIp) {
        markerData.push({
          id: obs.id,
          sensorId: obs.sensorId,
          ips: {src: srcIp, dst: dstIp},
          // geo: obs.geo
          geo: {src: obs.srcGeo, dst: obs.dstGeo}
        })
      }
    }
    else if (obs.info && obs.info.hostIds) {

      // CBH observation; we can only render accurate connections if it's a single src or single dst.
      let srcHostIds = []
      let dstHostIds = []
      obs.info.hostIds.forEach(hostId => {
        if (!_.isEmpty(hostId.srcPorts)) {
          srcHostIds.push(hostId)
        }
        if (!_.isEmpty(hostId.dstPorts)) {
          dstHostIds.push(hostId)
        }
      })
      if (srcHostIds.length === 1 && dstHostIds.length > 0) {
        dstHostIds.forEach(dstHostId => {
          markerData.push({
            id: `${ obs.id }|${ dstHostId }`,
            sensorId: obs.sensorId,
            ips: {src: srcHostIds[0].host.ip, dst: dstHostId.host.ip},
            geo: {
// <<<<<<< HEAD
//               src: { location: srcHostIds[0].geo ? {latitude: srcHostIds[0].geo.lat, longitude: srcHostIds[0].geo.lon} : null },
//               dst: { location: dstHostId.geo ? {latitude: dstHostId.geo.lat, longitude: dstHostId.geo.lon} : null }
// =======
              // src: { location: srcHostIds[0].geo ? {latitude: srcHostIds[0].geo.lat, longitude: srcHostIds[0].geo.lng} : null },
              // dst: { location: dstHostId.geo ? {latitude: dstHostId.geo.lat, longitude: dstHostId.geo.lng} : null }
              src: srcHostIds[0].geo || null,
              dst: dstHostId.geo || null
            }
          })
        })
      }
      else if (srcHostIds.length > 0 && dstHostIds.length === 1) {
        srcHostIds.forEach(srcHostId => {
          markerData.push({
            id: `${ obs.id }|${ srcHostId }`,
            sensorId: obs.sensorId,
            ips: {src: srcHostId.host.ip, dst: dstHostIds[0].host.ip},
            geo: {
// <<<<<<< HEAD
//               src: { location: srcHostId.geo ? {latitude: srcHostId.geo.lat, longitude: srcHostId.geo.lon} : null },
//               dst: { location: dstHostIds[0].geo ? {latitude: dstHostIds[0].geo.lat, longitude: dstHostIds[0].geo.lon} : null }
// =======
              // src: { location: srcHostId.geo ? {latitude: srcHostId.geo.lat, longitude: srcHostId.geo.lng} : null },
              // dst: { location: dstHostIds[0].geo ? {latitude: dstHostIds[0].geo.lat, longitude: dstHostIds[0].geo.lng} : null }
              src: srcHostId.geo || null,
              dst: dstHostIds[0].geo || null
            }
          })
        })
      }
    }
    else if (obs.associatedId.hostId) {
      console.log('GEO GENERIC HOSTID')

      markerData.push({
        id: `${ obs.id }|${ obs.associatedId.hostId }`,
        sensorId: obs.sensorId,
        ips: {
          src: obs.associatedId.hostId.host || obs.associatedId.hostId.deviceId,
          dst: null
        },
        geo: {
          src: obs.associatedId.hostId.geo || null,
          dst: null
        }
      })
    }
  })
  return getMapMarkers(markerData, getInternalLocation, highlightIP)
}

export function convertCoordsToArcMinutes(coord, isLat) {
  var [whole, decimal] = coord.toString().split('.')
  if (Math.sign(whole) === 1) {
    var dir = isLat ? 'N' : 'E'
  } else {
    var dir = isLat ? 'S' : 'W'
  }

  decimal = `0.${ decimal }`
  var mins = 60 * decimal
  var [minsWhole, minsDecimal] = mins.toString().split('.')
  var sec = Math.round(60 * `0.${ minsDecimal || 0 }`)
  return `${ whole }°${ dir } ${ minsWhole }'${ sec }"`
}



/*
=== Utilities for dealing with ISO3166-1 Alpha-2 country codes: ===

We use a specialized data source for this (https://github.com/fannarsh/country-list) because
the GeoJSON files do not contain the complete standardized list.
*/

const _isoCodesToNames = isoCountries.reduce((index, {code, name}) => {
  index[code] = name
  return index
}, Object.create(null))

const _isoCodes = Object.freeze(Object.keys(_isoCodesToNames))

export function getISOCountryCodes() {
  return _isoCodes
}

export function getNameForISOCountryCode(code) {
  return _isoCodesToNames[code] || null
}


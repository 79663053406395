import _ from 'lodash'
import React from 'react'
import T from 'prop-types'
import { TextOverflow } from 'react-base'
import IntelSourceTag from './IntelSourceTag'
import AnalyticsActions from 'actions/AnalyticsActions'
import { formatDeviceAttributeValue } from 'utils/deviceUtils'
import ExternalLink from 'components/ExternalLink'


const INTEL_SOURCE_KEY = 'NarkCrowdstrike'

function formatColonSeparatedValue(val) {
  // Some values come with parts separated by a colon; we want to remove it (?)
  val = (val || '').replace(/\s*:\s*/g, ' ')
  return _.trim(val) ? val : 'Unknown'
}

const formatMac = _.partial(formatDeviceAttributeValue, 'mac')



class CrowdStrikeDeviceData extends React.Component {
  static propTypes = {
    deviceData: T.object, //response from entities API
    startTime: T.number,
    endTime: T.number
  }

  _onExtLink() {
    AnalyticsActions.event({
      eventCategory: 'intelcard',
      eventAction: 'external-link-clicked',
      eventLabel: 'crowdstrike-dashboard'
    })
  }

  _renderAttribute(label, values, formatter) {
    return <div className="cell">
      <span>{label}</span>
      <div className="cell_content">
        {_.isEmpty(values)
          ? 'Unknown'
          : values.map((val, i) =>
            <TextOverflow key={i}>
              {formatter ? formatter(val) : val}
            </TextOverflow>
          )
        }
      </div>
    </div>
  }

  render() {
    const {props} = this
    const renderAttribute = this._renderAttribute

    // Extract all NarkCrowdstrike-sourced data from the entities response
    const crowdStrikeAttrs = {}
    _.forEach(props.deviceData.protectWiseAttributes, (values, key) => {
      const filteredValues = _.pluck(values.filter(d => d.source === INTEL_SOURCE_KEY), 'value')
      if (filteredValues.length) {
        crowdStrikeAttrs[key] = filteredValues
      }
    })

    const crowdStrikeHost = crowdStrikeAttrs.hostName && crowdStrikeAttrs.hostName[0]

    return <div className="device_source_summary">
      <h5>CrowdStrike Summary <IntelSourceTag source={INTEL_SOURCE_KEY} /></h5>

      { // If the result object is empty, display a collapsed rollup message
        _.isEmpty(crowdStrikeAttrs)
          ? <p className="no_data">No CrowdStrike data found for this device.</p>
          : <React.Fragment>
              {renderAttribute('Description', crowdStrikeAttrs.description, formatColonSeparatedValue)}
              {renderAttribute('MAC Address', crowdStrikeAttrs.mac, formatMac)}
              {renderAttribute('Network Description', crowdStrikeAttrs.networkDescription, formatColonSeparatedValue)}
              {renderAttribute('OS Type', crowdStrikeAttrs.osType)}
              {renderAttribute('OS Version', crowdStrikeAttrs.osVersion)}
              {renderAttribute('Roles', crowdStrikeAttrs.roles)}
              {crowdStrikeHost &&
                <section>
                  <ExternalLink
                    onClick={ this._onExtLink }
                    href={ `https://falcon.crowdstrike.com/investigate/events/en-US/app/eam2/investigate__computer?form.computer=${crowdStrikeHost}&earliest=${Math.floor(props.startTime / 1000)}&latest=${Math.floor(props.endTime / 1000)}` }
                    className="btn btn-link external_link"
                    data-tooltip="View Full CrowdStrike Report"
                  >
                    View Dashboard in CrowdStrike <span className="icon icon-arrow-up-right" />
                  </ExternalLink>
                </section>
              }
          </React.Fragment>
      }
    </div>
  }
}


export default CrowdStrikeDeviceData

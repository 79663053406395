import React from 'react'
import ErroredValue from 'components/intelcard/data/ErroredValue'
import {LoadingValue} from 'react-base'


export default ({data}) => {
  const maxThreatLevel = data('maxThreatLevel')
  const maxThreatScore = data('maxThreatScore')

  return <h5>
    {'Threat: '}
    {
      maxThreatLevel.isLoading() ? <LoadingValue /> :
      maxThreatLevel.isError() ? <ErroredValue /> :
      <span>
        <span className={`lvl_${(maxThreatLevel.valueOf() || 'none').toLowerCase()}`}>
          {maxThreatScore.valueOf() || 0}
        </span>
        {' ' + (maxThreatLevel.valueOf() || 'Unknown')}
      </span>
    }
  </h5>
}

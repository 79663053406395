import React, { FunctionComponent } from 'react'
import {
  observer
} from 'mobx-react'
import { CityValue } from 'components/values/CityValue'

export interface CityProps {
  value: number // MaxMind City ID
}

export const City: FunctionComponent<CityProps> = observer(props => {
  return (
    <CityValue
      cityId={props.value}
    />
  )
})

City.displayName = "City"
import T from 'prop-types'
import React from 'react'
import cx from 'classnames'
import moment from 'moment'
import TimingStore from 'stores/TimingStore'
import PreferencesStore from 'stores/PreferencesStore'
import { formatDateWith, isUTC } from 'utils/timeUtils'
import { StoreProvider, InfoWindow, MenuItem, Tabs } from 'react-base'
import {availablePreferences} from 'pwConstants'
import PreferencesActions from 'actions/PreferencesActions'

class Clock extends React.PureComponent {
  static displayName = 'Clock'

  static propTypes = {
    isLive: T.bool,
    clockTime: T.number
  }

  static defaultProps = {
    forceUTC: false,
    clockTime: Date.now()
  }

  _onSetTimeFormat = val => {
    PreferencesActions.setSinglePreference('timeFormat', val)
  }

  _getWindowContents = () => {
    const { clockTime, timeFormat } = this.props
    return <div className="clock_popup">
      <div><span className="icon icon-clock" /> All times displayed in:</div>
      <Tabs
        radio
        activeTabKey={timeFormat}
      >
        {availablePreferences.timeFormat.options.map(opt =>
          <MenuItem key={opt} onClick={this._onSetTimeFormat}>
            {opt === 'local' ? `local time (UTC${moment().format('Z')})` : 'UTC'}
          </MenuItem>
        )}
      </Tabs>
    </div>
  }

  render() {
    const { forceUTC, clockTime } = this.props

    const _isUTC = forceUTC || isUTC()
    return (
      <InfoWindow
        className="clock_wrap"
        menuAlign="bottom"
        windowContents={this._getWindowContents}
      >
        <div className={cx('clock', _isUTC && 'utc')}>
          <div className="main_time">
            <span className="current_time">
              {formatDateWith(clockTime, 'HH:mm', _isUTC)}
            </span>
            <span className="seconds_display">
              {formatDateWith(clockTime, ':ss', _isUTC)}
            </span>
          </div>
          <div className={cx('main_date')}>
            <span className="current_day">
              {formatDateWith(clockTime, 'ddd', _isUTC)}
            </span>
            <span className="current_date">
              {formatDateWith(clockTime, 'MMM D', _isUTC)}
            </span>
          </div>
        </div>
      </InfoWindow>
    )
  }
}

function mapTimingStoreData(timingData) {
  return timingData
    ? {
        clockTime: timingData.currentTime
      }
    : null
}

const mapPrefsStoreData = state => ({timeFormat: state.preferences.timeFormat})

export default props =>
  <StoreProvider store={PreferencesStore} mapping={mapPrefsStoreData}>
    <StoreProvider store={TimingStore} mapping={mapTimingStoreData}>
      <Clock {...props} />
    </StoreProvider>
  </StoreProvider>

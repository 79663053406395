import {
  autorun,
  computed,
  action,
} from 'mobx'
import { Store } from 'stores/mobx/StoreManager'
import { RouteStore } from 'stores/mobx/data/global/RouteStore'
import { HelpVideosStore } from 'stores/mobx/data/help/HelpVideosStore'

interface Asset {
  url: string,
  width: number,
  height: number,
  fileSize: number,
  contentType: string,
  type: string
}

function getAsset (assets: Array<Asset>, overrideAssetType?: string) {
  /**
   * Ordered list of fallback formats by quality preference
   */
  let assetTypesOrdered = [
    'HdMp4VideoFile',
    'MdMp4VideoFile',
    'Mp4VideoFile',
    'IphoneVideoFile',
    'OriginalFile'
  ]

  if (overrideAssetType) {
    assetTypesOrdered = [overrideAssetType]
  }

  if (!assets || !assets.length) return null

  let assetURL = undefined
  while (assetURL === undefined) {
    if (assetTypesOrdered.length === 0) {
      break
    }
    const format = assetTypesOrdered.shift()
    assetURL = assets.find(a => a.type === format)
  }

  if (assetURL) {
    return assetURL.url.replace('http:', 'https:').replace('embed', 'embed-ssl')
  }
}

export class HelpStore extends Store {
  static dependencies = [RouteStore, HelpVideosStore]

  routeStore: RouteStore
  videoStore: HelpVideosStore

  constructor(deps) {
    super(deps)
    const [routeStore, videoStore] = deps

    this.routeStore = routeStore
    this.videoStore = videoStore

    this.videoStore.loadProjects() // Load all

    const cleanupProjectWatcher = autorun(() => {
      this.videoStore.loadProject(this.activeProjectId)
    })

    this.destroy = () => {
      cleanupProjectWatcher()
    }
  }

  @computed
  get projects () {
    return this.videoStore.projects
  }

  @computed
  get project () {
    return this.videoStore.project
  }

  @computed
  get error () {
    return this.videoStore.error
  }

  @computed
  get isLoading () {
    return this.videoStore.isLoading
  }

  @computed
  get isLoadingProject () {
    return this.videoStore.isLoadingProject
  }

  @computed
  get params () {
    return this.routeStore.currentRoute.queryParams ?? {}
  }

  @computed
  get activeProjectId () {
    const fallback = (this.projects || [])[0] // Fallback to the first playlist
    return this.params.projectId || fallback?.hashedId || null
  }

  @computed
  get activeMediaId () {
    const fallback = (this.project?.media || [])[0]  // Fallback to the first video
    return this.params.mediaId || fallback?.hashed_id || null
  }

  @computed
  get activeMedia () {
    const mediaId = this.activeMediaId
    const media = (this.project?.media || []).find(m => m.hashed_id === mediaId)
    if (media) {
      return media
    }
    return null
  }

  @computed
  get activeMediaUrl () {
    return getAsset(this.activeMedia?.assets || [])
  }

  @computed
  get activeMediaPosterUrl () {
    return getAsset(this.activeMedia?.assets || [], 'StillImageFile')
  }

  @action.bound
  handleProjectClick (projectId) {
    this.routeStore.mergeParams({projectId, mediaId: null})
  }

  @action.bound
  handleMediaClick (mediaId) {
    this.routeStore.mergeParams({mediaId})
  }
}

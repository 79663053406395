import Loadable from 'components/Loadable'

/**
 * Loadables: Logical split points in the app to reduce initial load time
 * and memory consumption. Modules loaded using the format below will be
 * fetched on-demand _at runtime_.
 */

export const Hud = Loadable(() => import(/* webpackChunkName: "hud" */ './Hud'))
export const Killbox = Loadable(() => import(/* webpackChunkName: "killbox" */ './killbox/Killbox'))
export const Explorer = Loadable(() => import(/* webpackChunkName: "explorer" */ './explorer/Explorer'))
export const ExplorerDuex = Loadable(() => import(/* webpackChunkName: "explorer" */ './explorer-duex/ExplorerDuex'))
export const SitRep = Loadable(() => import(/* webpackChunkName: "sitrep" */ './sitrep/SitRep'))
export const Grid = Loadable(() => import(/* webpackChunkName: "grid" */ './grid/Grid'))
export const Grid2 = Loadable(() => import(/* webpackChunkName: "grid2" */ './grid2/Grid2'))
export const Zoomy = Loadable(() => import(/* webpackChunkName: "zoomy" */ './grid2/Zoomy'))
export const IntelManagement = Loadable(() => import(/* webpackChunkName: "intel-mgmt" */ './intel-management/IntelManagement'))
export const SensorManagement = Loadable(() => import(/* webpackChunkName: "sensor-settings" */ './SensorManagement'))
export const DeviceManagement = Loadable(() => import(/* webpackChunkName: "device-mgmt" */ './devices/DeviceManagement'))
export const Settings = Loadable(() => import(/* webpackChunkName: "settings" */ './Settings'))

export const PasswordReset = Loadable(() => import(/* webpackChunkName: "password-reset" */ './account/PasswordReset'))
export const CompleteRegistration = Loadable(() => import(/* webpackChunkName: "complete-registration" */ './account/CompleteRegistration'))
export const CustomerAdministration = Loadable(() => import(/* webpackChunkName: "customer-admin" */ './customer-admin/CustomerAdministration'))

export const AuditLogs = Loadable(() => import(/* webpackChunkName: "audit-logs" */ './audit-logs/AuditLogs'))
export const TagManagement = Loadable(() => import(/* webpackChunkName: "tag-mgmt" */ './TagManagement'))
export const UserManagement = Loadable(() => import(/* webpackChunkName: "user-mgmt" */ './user-management/UserManagement'))
export const RoleManagement = Loadable(() => import(/* webpackChunkName: "role-mgmt" */ './user-management/RoleManagement'))
export const SecuritySettings = Loadable(() => import(/* webpackChunkName: "security-settings" */ './user-management/SecuritySettings'))
export const UserAccountSettings = Loadable(() => import(/* webpackChunkName: "user-acct-settings" */ './user/UserAccountSettings'))
export const NotificationSettings = Loadable(() => import(/* webpackChunkName: "notification-settings" */ './notification-settings/NotificationSettings'))
export const EmitterSettings = Loadable(() => import(/* webpackChunkName: "emitter-settings" */ './emitter-settings/EmitterSettings'))
export const Integrations = Loadable(() => import(/* webpackChunkName: "context-fusion-integrations" */ './context-fusion/integrations/Integrations'))
export const ApiKeys = Loadable(() => import(/* webpackChunkName: "context-fusion-apikeys" */ './context-fusion/ApiSettings'))
export const UserPreferences = Loadable(() => import(/* webpackChunkName: "user-prefs" */ './user/UserPreferences'))
export const AgreementSettings = Loadable(() => import(/* webpackChunkName: "agreement-settings" */ './account/AgreementSettings'))
export const About = Loadable(() => import(/* webpackChunkName: "about" */ './About'))
export const AllSystemNotifications = Loadable(() => import(/* webpackChunkName: "all-system-notifications" */ './notifications/AllSystemNotifications'))
export const HelpVideos = Loadable(() => import(/* webpackChunkName: "help-videos" */ './help/HelpVideos'))
export const HelpGuides = Loadable(() => import(/* webpackChunkName: "help-guides" */ './help/HelpGuides'))
export const Downloads = Loadable(() => import(/* webpackChunkName: "help-downloads" */ './help/Downloads'))

export const SensorDashboard = Loadable(() => import(/* webpackChunkName: "sensor-dashboard" */ './sensor-dashboard/SensorDashboard'))
export const TrafficProfiler = Loadable(() => import(/* webpackChunkName: "profiler" */ './traffic-profiler/TrafficProfiler'))
export const LocationManagement = Loadable(() => import(/* webpackChunkName: "location-mgmt" */ './locations/LocationManagement'))

export const DeviceInventory = Loadable(() => import(/* webpackChunkName: "device-inventory" */ './devices/DeviceInventory'))
export const Docs = Loadable(() => import(/* webpackChunkName: "docs" */ './docs/Docs'))

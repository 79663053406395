import React from 'react'
import ReactDOM from 'react-dom'
import T from 'prop-types'
import cx from 'classnames'
import { formatNumber } from 'pw-formatters'
import memoize from 'memoize-one'
import { getTimelineTickFormat } from '../../utils/timeUtils'
import { curveMonotoneX, curveStep } from 'd3-shape'
import { path } from 'd3-path'
import AxisScaleAware from './AxisScaleAware'
import Ruler from './SensorTimelineRuler'
import SensorTimelinePathSVG from './SensorTimelinePathSVG'

const defaultFormatter = val => formatNumber(val, false)

class SensorTimeline extends React.PureComponent {

  // Note: all props treated as immutable
  static propTypes = {
    title: T.node,
    items: T.arrayOf(
      T.shape({
        className: T.string,
        label: T.string,
        data: T.arrayOf(T.shape({ timestamp: T.number, value: T.number })),
        affectsScale: T.bool, //defaults to true if omitted
        showInRuler: T.oneOfType([T.bool, T.oneOf(['nonzero'])]), //defaults to true if omitted
        rulerValueFormatter: T.func, //defaults to overall valueFormatter
        rulerYScale: T.func, //defaults to yScale from AxisScaleAware
        shape: T.oneOf(['smooth', 'step']) //defaults to 'smooth'
      })
    ),
    valueFormatter: T.func, //(value) => {textData, label}
    rulerTime: T.number, //position for ruler, or null for no ruler
    rulerTimeFormatter: T.func, //optional formatting override for time within ruler
    onTimeHover: T.func, //(number|null) callback for mouseover
    className: T.string,
    xAxis: T.bool,
    yAxis: T.bool,

    // From AxisScaleAware HOC:
    xScale: T.func,
    yScale: T.func,
    xTickCount: T.number,
    yTickCount: T.number,
    width: T.number,
    height: T.number,
    fixedMaxValue: T.number, //optional fixed y max domain value
    defaultMaxValue: T.number, //optional default y max domain value to use instead of zero when no data available
  }

  static defaultProps = {
    valueFormatter: defaultFormatter
  }

  // Memoized function for calculating SVG paths for each data item
  _getItemPaths = memoize((items, xScale, yScale) => {
    return items.map(({ className, data, shape }) => {
      if (!data) return null
      const curveFn = shape === 'step' ? curveStep : curveMonotoneX

      const ctx = path()
      const curve = curveFn(ctx)
      curve.lineStart()
      for (let { timestamp, value } of data) {
        curve.point(xScale(timestamp), yScale(value || 0) + (value ? -0.5 : 1.5)) //if value=0, move below bottom so it's clipped
      }
      curve.lineEnd()

      const strokePath = ctx.toString()
      const [minX, maxX] = xScale.range()
      let [botY, topY] = yScale.range()
      const fillPath = `${strokePath}L${maxX},${botY + 1.5}L${minX},${botY + 1.5}Z`

      const clipPath = `M${minX},${botY}L${minX},${topY}L${maxX},${topY}L${maxX},${botY}Z`

      return {
        className,
        strokePath,
        fillPath,
        clipPath
      }
    })
  })

  _getXAxisInfo = memoize((xScale, xTickCount) => {
    const [minTime, maxTime] = xScale.domain()
    const xTicks = xScale.ticks(xTickCount)
    // Ensure start/end times are included
    if (!xTicks[0] || xTicks[0] !== minTime) {
      xTicks.unshift(minTime)
    }
    if (xTicks[xTicks.length - 1] !== maxTime) {
      xTicks.push(maxTime)
    }
    return {
      xAxisLabels: <div className="axis_labels x">
        {xTicks.map((time, i) =>
          <span key={i} style={{ left: xScale(time) }} className="axis_label x">{getTimelineTickFormat()(time)}</span>
        )}
      </div>
    }
  })

  _getYAxisInfo = memoize((xScale, yScale, yTickCount, valueFormatter) => {
    // For Y axis, we want to force a single unit across the whole axis. We take the formatter-chosen
    // unit from the largest value, and multiply all other values by the same multiplier.
    const [, maxVal] = yScale.domain()
    const [xMin, xMax] = xScale.range()
    const { textData: topYNum, label: yUnits } = valueFormatter(maxVal)
    const yMult = +topYNum / maxVal
    let yTicks = yScale.ticks(yTickCount)
    if (yTicks.length && yTicks[yTicks.length - 1] !== maxVal) { //ensure we have a top-line tick
      yTicks.push(maxVal)
    }
    yTicks = yTicks.map((val, i) => {
      const multVal = val * yMult
      return {
        y: Math.round(yScale(val)) + (i ? .5 : -.5),
        text: i ? multVal.toFixed(multVal < .1 ? 2 : multVal < 10 ? 1 : 0) : yUnits
      }
    })
    return {
      yAxisLabels: <div className="axis_labels y">
        {yTicks.map(({ y, text }, i) =>
          <span key={i} style={{ top: y }} className="axis_label y">{text}</span>
        )}
      </div>,
      yAxisPath: <path className="axis_lines y" d={yTicks.map(d => `M${xMin},${d.y}L${xMax},${d.y}`).join('')} />
    }
  })

  _getAllTimes = memoize((items) => {
    const allTimes = new Set()
    for (let { data } of items) {
      if (data) {
        for (let { timestamp } of data) {
          allTimes.add(timestamp)
        }
      }
    }
    return allTimes
  })

  _onMouseMove = e => {
    const fn = this.props.onTimeHover
    if (fn) {
      const { xScale, items } = this.props
      let xPos = e.clientX - ReactDOM.findDOMNode(this).getBoundingClientRect().left
      const range = xScale.range()
      xPos = Math.max(range[0], Math.min(range[1], xPos)) //constrain to time range
      const allTimes = this._getAllTimes(items)
      const exactTime = +xScale.invert(xPos)
      let closestTime = 0
      allTimes.forEach(t => {
        if (Math.abs(exactTime - t) < Math.abs(exactTime - closestTime)) {
          closestTime = t
        }
      })
      fn(closestTime)
    }
  }

  _onMouseLeave = e => {
    const fn = this.props.onTimeHover
    if (fn) fn(null)
  }


  render() {
    const { props } = this
    const {
      xScale,
      yScale,
      xTickCount,
      yTickCount,
      xAxis,
      yAxis,
      items,
      rulerTime,
      valueFormatter,
      rulerTimeFormatter,
      title
    } = props

    let contents
    if (xScale && yScale) {
      const itemPaths = this._getItemPaths(items, xScale, yScale)
      const { xAxisLabels } = xAxis ? this._getXAxisInfo(xScale, xTickCount) : {}
      const { yAxisLabels, yAxisPath } = yAxis ? this._getYAxisInfo(xScale, yScale, yTickCount, valueFormatter) : {}

      contents = <React.Fragment>
        <svg width="100%" height="100%">
          {yAxisPath}
          {itemPaths.map((pathProps, i) =>
            pathProps
              ? <SensorTimelinePathSVG
                key={`${i}.${pathProps.className}`}
                {...pathProps}
              />
              : null
          )}
        </svg>

        {xAxisLabels}
        {yAxisLabels}

        <Ruler
          time={rulerTime}
          items={items}
          xScale={xScale}
          yScale={yScale}
          valueFormatter={valueFormatter}
          timeFormatter={rulerTimeFormatter}
        />
      </React.Fragment>
    }

    return <div
      className={cx(
        'sensor_timeline',
        xAxis && 'has_x_axis',
        yAxis && 'has_y_axis',
        rulerTime && 'has_ruler',
        props.className
      )}
      onMouseMove={this._onMouseMove}
      onMouseLeave={this._onMouseLeave}
    >
      {title && <div className="title">{title}</div>}
      {contents}
    </div>
  }
}


export default AxisScaleAware(SensorTimeline)

/**
 * Unwrapped version in case you want to build your own scales or do the
 * AxisScaleAware HOC wrapping at a higher level:
 */
export const SensorTimelineWithoutScales = SensorTimeline

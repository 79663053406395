import React from 'react'
import IpValue from 'components/values/IpValue'
import SensorStore from 'stores/SensorStore'
import ThreatCategories from './ThreatCategories'
import ThreatLevel from './ThreatLevel'

export default props => {
  const {queryValue, data} = props
  return <React.Fragment>
    <h4>
      <div>
        <IpValue
          noIntelCard
          ip={queryValue}
          menuClassName="light"
          menuAlign="bottom"
        />
        <span className="int_ext">
          {SensorStore.isInternalIp(queryValue) ? 'Internal IP' : 'External IP'}
        </span>
      </div>
    </h4>
    <ThreatCategories data={data} />
    <ThreatLevel data={data} />
  </React.Fragment>
}

import React from 'react'
import {TextOverflow} from 'react-base'

export default props => {
  const {queryValue, data} = props

  return <React.Fragment>
    <h4>
      <TextOverflow>Certificate: {queryValue}</TextOverflow>
    </h4>
  </React.Fragment>
}

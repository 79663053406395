import {observable} from 'mobx'
import {schemer} from 'pw-schemer-mobx'
import StringAttribute from './StringAttribute'

const formatMacFromBytes = (v: string) => {
  if (!v) return v
  const out = []
  let lastI = 0
  for (let i = 2; i <= 12; i += 2) {
    out.push(v.slice(lastI, i))
    lastI = i
  }
  return out.join(':')
}

export class MacAddressAttr extends StringAttribute {
  constructor(initial) {
    super(initial, {
      transform: (attr: any = {}) => ({
        ...attr,
        value: formatMacFromBytes(attr.value)
      })
    })
  }

  @observable
  @schemer.macAddress
  value: string
}